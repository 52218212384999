import React, { useState } from 'react';
import { Loader } from '@alfalab/core-components/loader';
import { Field } from '@alfalab/core-components/select/components/field';
import {
  SelectDesktop,
  SelectDesktopProps,
} from '@alfalab/core-components/select/desktop';
import { FieldProps } from '@alfalab/core-components/select/typings';
import { Space } from '@alfalab/core-components/space';

import { useInit } from '@terminal/lk/legacy/hooks';
import { useFeatureSettings } from '@terminal/lk/shared';
import {
  getDocumentsThemesGI,
  getReportsThemesGI,
  Treaty,
} from '@terminal/lk-core';
import {
  FinancialDocumentsSubpage,
  OrderFormApplied,
  ReportsForm,
  useDocumentsFormSelect,
} from '@terminal/lk-desktop-common';
import { TradingCertificateLock } from '@terminal/widgets';

import { DocumentOrderForm } from '../../../../features/FinancialDocuments';
import { getObtainHint } from '../../lib/getObtainHint';

export interface OrderProps {
  treaty: Treaty | null;
  onSubpageChange: (subpage: FinancialDocumentsSubpage) => void;
}

export const Order: React.FC<OrderProps> = (props) => {
  const { treaty, onSubpageChange } = props;

  const [formApplied, setFormApplied] = useState(false);
  const { documents, reports, activeForm, shouldDisplayReportSelector } =
    useDocumentsFormSelect(treaty, getDocumentsThemesGI, getReportsThemesGI);

  const { data: init } = useInit();
  const { data: featureSettings } = useFeatureSettings();

  const handleDocumentChange: SelectDesktopProps['onChange'] = ({
    selected,
  }) => {
    documents.setSelectedId(selected?.key || '');
  };

  const handleReportChange: SelectDesktopProps['onChange'] = ({ selected }) => {
    reports.setSelectedId(selected?.key || '');
  };

  if (formApplied) {
    return (
      <OrderFormApplied
        caption="Мы подготовим его в течение нескольких дней. Максимальный срок исполнения —&nbsp;30 дней. Отслеживать статус заказа вы можете в истории."
        primaryButtonLabel="Заказать другой документ"
        onPrimaryButtonClick={() => {
          documents.setSelectedId('');
          setFormApplied(() => false);
        }}
        secondaryButtonLabel="Перейти в историю"
        onSecondaryButtonClick={() => {
          setFormApplied(() => false);
          onSubpageChange(FinancialDocumentsSubpage.History);
        }}
      />
    );
  }

  return (
    <TradingCertificateLock fullHeight>
      <Space direction="vertical" fullWidth>
        <SelectDesktop
          label="Документ"
          labelView="outer"
          placeholder="Выберите из списка"
          size="s"
          block
          disabled={documents.loading}
          options={documents.options}
          selected={documents.selectedId}
          onChange={handleDocumentChange}
          hint={documents.selectedTheme?.tooltip}
          Field={(props: FieldProps) => (
            <Field {...props} leftAddons={documents.loading && <Loader />} />
          )}
        />

        {shouldDisplayReportSelector && (
          <SelectDesktop
            label="Тип отчёта"
            labelView="outer"
            placeholder="Выберите из списка"
            size="s"
            block
            disabled={reports.loading}
            options={reports.options}
            selected={reports.selectedId}
            onChange={handleReportChange}
            hint={reports.selectedTheme?.tooltip}
            Field={(props: FieldProps) => (
              <Field {...props} leftAddons={reports.loading && <Loader />} />
            )}
          />
        )}

        {activeForm === 'report' && (
          <ReportsForm
            treaty={treaty}
            theme={reports.selectedId}
            reportsTheme={reports.rawData?.themes}
            address={reports.rawData?.address}
            email={reports.rawData?.email}
            emailConfirmed={reports.rawData?.emailConfirmed || false}
            featureSettings={featureSettings}
            getObtainHint={getObtainHint}
          />
        )}

        {activeForm === 'depoReport' && (
          <DocumentOrderForm
            treaty={treaty}
            theme={reports.selectedId}
            documentsTheme={reports.rawData?.themes}
            documentsThemePending={reports.loading}
            address={reports.rawData?.address}
            depoAccounts={reports.rawData?.depoAccounts}
            email={reports.rawData?.email}
            emailConfirmed={reports.rawData?.emailConfirmed || false}
            onFormApplied={setFormApplied}
            formType={activeForm}
            getObtainHint={getObtainHint}
          />
        )}

        {activeForm === 'document' && (
          <DocumentOrderForm
            treaty={treaty}
            theme={documents.selectedId}
            documentsTheme={documents.rawData?.themes}
            documentsThemePending={documents.loading}
            address={documents.rawData?.address}
            depoAccounts={documents.rawData?.depoAccounts}
            email={init?.email}
            emailConfirmed={init?.emailConfirmed || false}
            formType={activeForm}
            getObtainHint={getObtainHint}
          />
        )}
      </Space>
    </TradingCertificateLock>
  );
};
