import { useCallback, useMemo, useState } from 'react';
import { ButtonDesktop as Button } from '@alfalab/core-components/button/desktop';
import { InputDesktop as Input } from '@alfalab/core-components/input/desktop';
import { Typography } from '@alfalab/core-components/typography';

import { useStore } from '@terminal/core/store';
import { WatchList } from '@terminal/core/types/watchList';

import styles from './AddWatchlist.module.css';

export type WatchlistModalType = 'create' | 'edit' | 'delete';

interface AddWatchlistProps {
  list: WatchList | undefined;
  type: WatchlistModalType;
  onClose: () => void;
  onSubmit: (name: string) => void;
}

export const AddWatchlist = (props: AddWatchlistProps) => {
  const { list, type, onClose, onSubmit } = props;

  const watchLists = useStore((state) => state.watchLists);
  const [value, setValue] = useState(list?.name ?? '');

  const isDuplicate = useMemo(() => {
    return Boolean(watchLists.find((item) => item.name === value));
  }, [value, watchLists]);

  const isDisabled = useMemo(() => {
    if (type === 'delete') {
      return false;
    }

    return isDuplicate || !value;
  }, [isDuplicate, type, value]);

  const getTitle = useCallback(() => {
    switch (type) {
      case 'create':
        return 'Создание списка';
      case 'edit':
        return 'Редактирование списка';
      case 'delete':
        return 'Удаление списка';

      default:
        break;
    }
  }, [type]);

  const getSubmitButtonName = useCallback(() => {
    switch (type) {
      case 'create':
        return 'Создать';
      case 'edit':
        return 'Сохранить';
      case 'delete':
        return 'Удалить';

      default:
        break;
    }
  }, [type]);

  return (
    <div className={styles.container}>
      <Typography.TitleMobile view="xsmall" tag="h3">
        {getTitle()}
      </Typography.TitleMobile>
      {type !== 'delete' ? (
        <Input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onClear={() => setValue('')}
          block
          clear
        />
      ) : (
        <Typography.Text view="primary-medium">
          Вы действительно хотите удалить список <i>{list!.name}</i>?
        </Typography.Text>
      )}
      <div className={styles.buttonWrapper}>
        <Button view="tertiary" onClick={() => onClose()} size="s">
          Отмена
        </Button>
        <Button
          onClick={() => {
            onSubmit(value);
            onClose();
          }}
          size="s"
          view="secondary"
          disabled={isDisabled}
        >
          {getSubmitButtonName()}
        </Button>
      </div>
    </div>
  );
};
