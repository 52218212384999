import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ButtonDesktop as Button } from '@alfalab/core-components/button/desktop';
import { IconButton } from '@alfalab/core-components/icon-button';
import {
  Tab,
  TabsDesktop as Tabs,
} from '@alfalab/core-components/tabs/desktop';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import HeartMIcon from '@alfalab/icons-glyph/HeartMIcon';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';
import PencilSIcon from '@alfalab/icons-glyph/PencilSIcon';
import TrashCanMIcon from '@alfalab/icons-glyph/TrashCanMIcon';

import { Dropdown } from '@terminal/common/components/Dropdown';
import { Menu, MenuGroup } from '@terminal/common/components/Menu';
import { useClickAwayExceptTarget } from '@terminal/core/hooks';
import { trackBondScreenerWidget } from '@terminal/core/lib/analytics/bondScreener/bondScreener';
import { BondScreenerTemplate } from '@terminal/core/types/bondScreener';
import { Filter } from '@terminal/core/types/layout';

import {
  Feature,
  useOneTimeShowing,
} from '../../../../features/FeatureOnboarding';
import { defaultFiltersTemplates } from '../../model/consts';

import styles from './BondScreenerTemplates.module.css';

interface Props {
  setFilters: (filters: Record<string, Filter | undefined>) => void;
  templates: BondScreenerTemplate[];
  onEditTemplate: (id: string) => void;
  onDeleteTemplate: (id: string) => void;
}

export const BondScreenerTemplates = (props: Props) => {
  const { setFilters, templates, onEditTemplate, onDeleteTemplate } = props;

  const [isOpenPopover, setIsOpenPopover] = useState<boolean>(false);
  const { isVisible } = useOneTimeShowing(Feature.BOND_SCREENER_TEMPLATES);

  const buttonRef = useRef<HTMLDivElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [isUserTemplates, setIsUserTemplates] = useState(false);

  useEffect(() => {
    if (templates.length > 0) {
      return;
    }

    setIsUserTemplates(false);
  }, [setIsUserTemplates, templates]);

  const isHaveTemplates = useMemo(() => {
    return templates.length > 0;
  }, [templates]);

  const userTemplates: MenuGroup[] = useMemo(
    () => [
      {
        key: '1',
        items: templates.map((template) => ({
          key: template.id,
          type: 'button',
          label: template.name,
          hideMenu: true,
          onClick: () => {
            setFilters(template.filters);
            setIsOpenPopover(false);
            trackBondScreenerWidget.applyTemplate(template.name, 'Мои шаблоны');
          },
          actions: (
            <>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  onEditTemplate(template.id);
                  setIsOpenPopover(false);
                }}
                icon={PencilSIcon}
                size="xs"
              />
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteTemplate(template.id);
                  setIsOpenPopover(false);
                }}
                icon={TrashCanMIcon}
                size="xs"
              />
            </>
          ),
        })),
      },
    ],
    [templates, setFilters, setIsOpenPopover, onDeleteTemplate, onEditTemplate]
  );

  useClickAwayExceptTarget(dropdownRef, buttonRef, () => {
    setIsOpenPopover(false);
  });

  useEffect(() => {
    if (isVisible) {
      setIsOpenPopover(true);
    }
  }, [isVisible]);

  return (
    <div ref={buttonRef}>
      <Button
        size="xxs"
        onClick={() => {
          setIsOpenPopover((prev) => !prev);
          trackBondScreenerWidget.openTemplate();
        }}
        leftAddons={<HeartMIcon width={16} height={16} />}
        className={styles.popoverButton}
      >
        <Typography.Text view="secondary-large" weight="medium">
          Шаблоны поиска
        </Typography.Text>
      </Button>

      <Dropdown
        ref={dropdownRef}
        anchorElement={buttonRef.current}
        open={isOpenPopover}
        offset={[0, 4]}
        position="bottom-start"
      >
        <div className={styles.templatesWrapper}>
          <div className={styles.templatesHeader}>
            {isHaveTemplates ? (
              <Tabs
                size="xs"
                view="secondary"
                selectedId={Number(isUserTemplates)}
                onChange={(_, { selectedId }) => {
                  const isUserTemplatesOption = Boolean(selectedId);

                  setIsUserTemplates(isUserTemplatesOption);
                  trackBondScreenerWidget.choseTemplateTab(
                    isUserTemplatesOption ? 'Мои шаблоны' : 'Готовые шаблоны'
                  );
                }}
                tagView="filled"
                className={styles.tabs}
              >
                <Tab title="Мои шаблоны" id={1} />
                <Tab title="Готовые шаблоны" id={0} />
              </Tabs>
            ) : (
              <Typography.Text
                className={styles.templatesTitle}
                view="secondary-large"
                weight="bold"
                tag="div"
              >
                Начните с готовых шаблонов
              </Typography.Text>
            )}
          </div>
          {isUserTemplates ? (
            <Menu className={styles.templatesList} groups={userTemplates} />
          ) : (
            <div className={styles.defaultTemplatesWrapper}>
              {defaultFiltersTemplates.map((item) => (
                <Button
                  key={item.title}
                  size="xl"
                  className={styles.defaultTemplateItem}
                  view="secondary"
                  onClick={() => {
                    setFilters(item.filter);
                    setIsOpenPopover(false);
                    trackBondScreenerWidget.applyTemplate(
                      item.title,
                      'Готовые шаблоны'
                    );
                  }}
                >
                  <item.icon
                    width={18}
                    height={18}
                    style={{ marginBottom: '8px' }}
                    color="#B8B9C0"
                  />
                  <br />
                  <Typography.Text view="secondary-large">
                    {item.title}{' '}
                    <TooltipDesktop
                      targetClassName={styles.tooltipTarget}
                      contentClassName={styles.tooltipContent}
                      trigger="hover"
                      position="top-start"
                      offset={[-20, 16]}
                      content={
                        <>
                          <Typography.Text weight="bold" view="secondary-small">
                            {item.title}
                          </Typography.Text>
                          <Typography.Text view="secondary-small">
                            {item.description}
                          </Typography.Text>
                        </>
                      }
                    >
                      <InformationCircleLineSIcon
                        width={10}
                        height={10}
                        color="#8D8D93"
                      />
                    </TooltipDesktop>
                  </Typography.Text>
                </Button>
              ))}
            </div>
          )}
        </div>
      </Dropdown>
    </div>
  );
};
