import {
  addYears,
  differenceInCalendarDays,
  differenceInYears,
  format,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import isNull from 'lodash/isNull';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AmountInput } from '@alfalab/core-components/amount-input';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { CalendarRange } from '@alfalab/core-components/calendar-range';
import { Divider } from '@alfalab/core-components/divider';
import { Gap } from '@alfalab/core-components/gap';
import { Space } from '@alfalab/core-components/space';
import { TagDesktop as Tag } from '@alfalab/core-components/tag/desktop';
import { Typography } from '@alfalab/core-components/typography';
import CalendarMIcon from '@alfalab/icons-glyph/CalendarMIcon';

import {
  YEAR_INTERVALS,
  YearsIntervalIds,
} from '@terminal/core/constants/filters';
import { getValueForDateRange } from '@terminal/core/lib/helpers/getValueForDateRange';
import {
  DateRange as DateRangeValue,
  SimpleDateRange,
} from '@terminal/core/types/ranges';

import styles from './ComplexDateRange.module.css';

interface Props {
  filterValue?: DateRangeValue;
  onChange: (value: SimpleDateRange) => void;
}

export const ComplexDateRange = (props: Props) => {
  const { filterValue, onChange } = props;

  const [isCalendarMode, setIsCalendarMode] = useState(false);
  const [dateFrom, setDateFrom] = useState<Date | undefined>(
    filterValue?.dateFrom
  );
  const [dateTo, setDateTo] = useState<Date | undefined>(filterValue?.dateTo);

  const getExactYearDifference = (date?: Date): string | number => {
    if (!date) {
      return '';
    }

    const currentDate = new Date();
    const yearDifference = differenceInYears(
      startOfDay(currentDate),
      startOfDay(date)
    );

    const exactYearDate = addYears(date, yearDifference);

    if (differenceInCalendarDays(currentDate, exactYearDate) === 0) {
      return Math.abs(yearDifference);
    }

    return '';
  };

  const from = useMemo(() => getExactYearDifference(dateFrom), [dateFrom]);
  const to = useMemo(() => getExactYearDifference(dateTo), [dateTo]);

  const handleChangeDates = useCallback(
    (dateFrom?: Date, dateTo?: Date) => {
      setDateFrom(dateFrom);
      setDateTo(dateTo);
    },
    [setDateTo, setDateFrom]
  );

  const selectedInterval = useMemo<YearsIntervalIds | undefined>(
    () => getValueForDateRange(dateFrom, dateTo)?.id,
    [dateFrom, dateTo]
  );

  useEffect(() => {
    onChange({ dateFrom, dateTo });
  }, [dateFrom, dateTo, onChange]);

  return (
    <div className={styles.wrapper} tabIndex={0}>
      {!isCalendarMode ? (
        <>
          <Space direction="horizontal" size={[8, 16]} wrap>
            {YEAR_INTERVALS.map(({ id, title, startDate, endDate }) => (
              <Tag
                key={id}
                size="xs"
                view="filled"
                checked={id === selectedInterval}
                onClick={() => handleChangeDates(startDate, endDate)}
              >
                {title}
              </Tag>
            ))}
          </Space>
          <div className={styles.inputs}>
            <AmountInput
              className={styles.customInput}
              fieldClassName={styles.customInputField}
              block
              suffix=""
              placeholder="От"
              value={typeof from === 'number' ? Number(from) : ''}
              minority={1}
              onChange={(e, { value }) =>
                setDateFrom(
                  isNull(value)
                    ? undefined
                    : addYears(new Date(), Number(value))
                )
              }
            />
            <span className={styles.dash}>–</span>
            <AmountInput
              className={styles.customInput}
              fieldClassName={styles.customInputField}
              block
              suffix=""
              placeholder="До"
              value={typeof to === 'number' ? Number(to) : ''}
              minority={1}
              onChange={(e, { value }) => {
                setDateTo(
                  isNull(value)
                    ? undefined
                    : addYears(new Date(), Number(value))
                );
              }}
            />
          </div>
          <div>
            <Divider />
            <Gap size="s" />
            <ButtonDesktop
              className={styles.calendarModeButton}
              size="xxs"
              leftAddons={<CalendarMIcon width={16} height={16} />}
              view="ghost"
              onClick={() => setIsCalendarMode(true)}
            >
              <Typography.Text view="secondary-large">
                {dateFrom && dateTo
                  ? `${format(dateFrom, 'dd.MM.yyyy')} – ${format(
                      dateTo,
                      'dd.MM.yyyy'
                    )}`
                  : 'Указать даты'}
              </Typography.Text>
            </ButtonDesktop>
            <Gap size="s" />
            <Divider />
          </div>
        </>
      ) : (
        <CalendarRange
          valueFrom={dateFrom ? format(dateFrom, 'dd.MM.yyyy') : undefined}
          valueTo={dateTo ? format(dateTo, 'dd.MM.yyyy') : undefined}
          onChange={({ dateFrom, dateTo }) =>
            handleChangeDates(
              dateFrom ? new Date(dateFrom) : undefined,
              dateTo ? new Date(dateTo) : undefined
            )
          }
          defaultMonth={startOfMonth(new Date()).getTime()}
        />
      )}
    </div>
  );
};
