import { FC, useMemo } from 'react';

import { BlueprintTable } from '@terminal/common/ui/Table';
import { useColumnsWidth } from '@terminal/common/ui/Table/hooks/useColumnsWidth';
import { useFilterTable } from '@terminal/core/hooks/useFilterTable';
import { useSortTable } from '@terminal/widgets/shared/hooks/useSortTable';

import { defaultColumnFilterRender } from '../../../../shared/ui/ColumnFilter';
import { ActionsCell } from './ActionsCell';
import {
  ACTIONS_COLUMN,
  COLUMNS,
  DISPLAY_NAME_COLUMN,
  MESSAGE_COLUMN,
  PRICE_COLUMN,
  STATUS_COLUMN,
} from './columns';
import { PriceCell } from './PriceCell';
import { StatusCell } from './StatusCell';
import { SymbolCell } from './SymbolCell';
import { useTrackPricesExtended } from './useTrackPricesExtended';

export const ListTable: FC = () => {
  const trackPrices = useTrackPricesExtended();
  const unsortedTrackPrices = useMemo(() => trackPrices.slice(), [trackPrices]);
  const { sortedData, sort, onSort } = useSortTable({
    data: unsortedTrackPrices,
    sort: { key: STATUS_COLUMN.key, asc: false },
  });
  const { filteredData, filter, onFilter } = useFilterTable({
    data: sortedData,
  });
  const { resizedColumns, onChangeWidth } = useColumnsWidth(COLUMNS);

  return (
    <BlueprintTable
      sort={sort}
      data={filteredData}
      columnSetting={resizedColumns}
      enableColumnReordering={false}
      filter={filter}
      onSort={onSort}
      onFilter={onFilter}
      onChangeWidth={onChangeWidth}
      columnFilterRender={defaultColumnFilterRender}
      cellRender={(rowIndex, columnIndex, columnKey, data) => {
        const trackPrice = data[rowIndex];

        if (columnKey === DISPLAY_NAME_COLUMN.key) {
          return <SymbolCell trackPrice={trackPrice} />;
        }

        if (columnKey === PRICE_COLUMN.key) {
          return <PriceCell trackPrice={trackPrice} />;
        }

        if (columnKey === MESSAGE_COLUMN.key) {
          return trackPrice.settings?.title;
        }

        if (columnKey === STATUS_COLUMN.key) {
          return <StatusCell trackPrice={trackPrice} />;
        }

        if (columnKey === ACTIONS_COLUMN.key) {
          return <ActionsCell trackPrice={trackPrice} />;
        }

        return null;
      }}
    />
  );
};
