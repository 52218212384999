import {
  isBefore,
  isEqual,
  isValid,
  parse,
  startOfDay,
  subDays,
} from 'date-fns';

import { DEFAULT_VIEW_DATE_FORMAT } from '@terminal/core/lib/dateToRequestFormat';
import {
  SecuritiesActive,
  SecuritiesActiveIn,
  SecuritiesDocumentType,
  SecuritiesLimit,
  SecuritiesPosition,
  SecuritiesReasonDocument,
} from '@terminal/core/lib/rest/lkSecurities';

import { SecurityDeponentPlaceCode, SecurityPlaceCode } from './shared';

export interface ValidateResult {
  code: number;
  message: string;
}

// Security
export function isSecurityPaperValid(
  active: SecuritiesActive | SecuritiesActiveIn | null
): boolean {
  return Boolean(active && active.pCode);
}

export function isSecurityPaperEnabled(
  active: SecuritiesActive | SecuritiesActiveIn | null
): boolean {
  return Boolean(active && active.transferEnabled);
}

// Amount
export function isAmountValid(amount: number | null): boolean {
  return Boolean(amount && amount > 0 && !isNaN(amount));
}

export function isAmountFulfill(
  amount: number | null,
  limits?: SecuritiesLimit
): boolean {
  return Boolean(
    amount &&
      (amount === 0 ||
        amount % 1 === 0 ||
        (limits && (limits.limit - amount) % 1 === 0)) &&
      (!limits || amount <= limits.limit)
  );
}

// Market
// TODO: Кандидат на удаление
export const isMarketFitPositions = (
  market: string,
  positions: SecuritiesPosition[] | undefined
): boolean => {
  if (!positions) {
    return false;
  }

  return Boolean(positions.find((p) => p.placeCode === market));
};

// Номер счёта депо
export function validateDepoAccount(account: string, market: string) {
  const pattern = getDepoAccountPattern(market);

  if (!pattern.test(account)) {
    return getDepoAccountErrorMsg(market);
  }

  return '';
}

export function isDepoAccountValid(account: string, market: string): boolean {
  const pattern = getDepoAccountPattern(market);

  return pattern.test(account);
}

export function getDepoAccountPattern(market: string) {
  switch (market) {
    case SecurityPlaceCode.MICEX_SHR: {
      return /^[0-9A-ZА-Я]{12}$/;
    }
    case SecurityPlaceCode.SPBEX_FSHR: {
      return /^[0-9A-Z]{8}$/;
    }
    case SecurityPlaceCode.EUROTRADE: {
      return /^[0-9A-Z]{2,20}$/;
    }
    case SecurityPlaceCode.REESTR: {
      return /^[0-9A-ZА-Я]{1,30}$/;
    }
    case SecurityPlaceCode.OTHER: {
      return /^[0-9A-ZА-Я]{1,25}$/;
    }

    default: {
      return /^[0-9A-ZА-Я]{1,25}$/;
    }
  }
}

export function getDepoAccountErrorMsg(market: string): string {
  switch (market) {
    case SecurityPlaceCode.MICEX_SHR: {
      return '12 знаков. Цифры, латинские и русские буквы';
    }
    case SecurityPlaceCode.SPBEX_FSHR: {
      return '8 знаков. Цифры и латинские буквы';
    }
    case SecurityPlaceCode.EUROTRADE: {
      return 'Цифры и латинские буквы до 20 символов';
    }
    case SecurityPlaceCode.REESTR: {
      return 'До 30 знаков. Цифры, латинские и русские буквы';
    }
    case SecurityPlaceCode.OTHER: {
      return 'Цифры, латинские и русские буквы';
    }

    default: {
      return 'Цифры, латинские и русские буквы';
    }
  }
}

// Раздел счёта депо
export function validateDepoSection(
  section: string,
  contrMarket: string,
  alfaMarket: string,
  contrDeponetPlaceCode: SecurityDeponentPlaceCode
) {
  const pattern = getDepoSectionPattern(
    contrMarket,
    alfaMarket,
    contrDeponetPlaceCode
  );

  if (!pattern.test(section)) {
    return getDepoSectionErrorMsg(
      contrMarket,
      alfaMarket,
      contrDeponetPlaceCode
    );
  }

  return '';
}

export function isDepoSectionValid(
  section: string,
  contrMarket: string,
  alfaMarket: string,
  contrDeponetPlaceCode: SecurityDeponentPlaceCode
): boolean {
  const pattern = getDepoSectionPattern(
    contrMarket,
    alfaMarket,
    contrDeponetPlaceCode
  );

  return pattern.test(section);
}

export function getDepoSectionPattern(
  contrMarket: string,
  alfaMarket: string,
  contrDeponetPlaceCode: SecurityDeponentPlaceCode
) {
  if (
    alfaMarket === SecurityPlaceCode.OTC_SPBEX_NOTRADE &&
    contrDeponetPlaceCode === SecurityDeponentPlaceCode.SPB
  ) {
    return /^BP[0-9A-Z]+$/;
  }

  switch (contrMarket) {
    case 'SPBEX_FSHR':
    case 'SPB':
    case 'RDC':
      return /^[0-9A-Z]+$/;
    case 'EUROTRADE':
    case 'DTC':
      return /^[0-9]{4,5}$/;

    default: {
      return /^[0-9A-Z]{17}$/;
    }
  }
}

export function getDepoSectionErrorMsg(
  contrMarket: string,
  alfaMarket: string,
  contrDeponetPlaceCode: SecurityDeponentPlaceCode
): string {
  if (
    alfaMarket === SecurityPlaceCode.OTC_SPBEX_NOTRADE &&
    contrDeponetPlaceCode === SecurityDeponentPlaceCode.SPB
  ) {
    return 'Должен быть указан неторговый раздел';
  }

  switch (contrMarket) {
    case 'SPBEX_FSHR':
    case 'SPB':
    case 'RDC':
      return 'Цифры или цифры/латинские буквы';
    case 'EUROTRADE':
    case 'DTC':
      return '4 или 5 цифр';

    default: {
      return '17 знаков. Цифры или цифры/латинские буквы';
    }
  }
}

// Код депонента (итендификатор)
export function validateDepoCode(code: string, market: string) {
  const pattern = getDepoCodePattern(market);

  if (!pattern.test(code)) {
    return getDepoCodeErrorMsg(market);
  }

  return '';
}

export function isDepoCodeValid(code: string, market: string): boolean {
  const pattern = getDepoCodePattern(market);

  return pattern.test(code);
}

export function getDepoCodePattern(market: string) {
  switch (market) {
    case 'SPBEX_FSHR':
    case 'SPB':
    case 'RDC':
      return /^[A-Z]{5}$/;
    case 'EUROTRADE':
    case 'DTC':
      return /^[0-9]{4,5}$/;

    default: {
      return /^[0-9A-Z]{12}$/;
    }
  }
}

export function getDepoCodeErrorMsg(market: string): string {
  switch (market) {
    case 'SPBEX_FSHR':
      return '5 знаков. Латинские буквы';
    case 'EUROTRADE':
    case 'DTC':
      return '4 или 5 цифр';

    default: {
      return '12 знаков. Цифры и латинские буквы';
    }
  }
}

// Депо счет контрагента
export function validateConteragentAccDepo(acc: string) {
  return /^[0-9A-ZА-Я]{8,11}$/.test(acc)
    ? ''
    : '8-11 знаков. Цифры, латинские и русские буквы';
}

// Swift код
export function validateSwift(swift: string) {
  return /^[0-9A-Z]{8,12}$/.test(swift)
    ? ''
    : 'Латинские буквы или цифры до 12 символов';
}

export function isSwiftValid(swift: string) {
  return validateSwift(swift) === '';
}

// Гербовый сбор
export function validateGbox(gbox: string) {
  return isFieldFilled(gbox) || gbox === '' ? '' : 'Укажите гербовый сбор';
}

export function isGboxValid(gbox: string) {
  return validateGbox(gbox) === '';
}

export function validateCounterpartyPlace(name: string) {
  return isFieldFilled(name) ? '' : 'Укажите наименование контрагента';
}

export function validateCounterpartyPlaceOther(name: string) {
  return isFieldFilled(name) ? '' : 'Укажите наименование места хранения';
}

// Реквизиты
export function validateFieldFill(value: string, errorStr = 'Заполните поле') {
  return isFieldFilled(value) ? '' : errorStr;
}

export function isFieldFilled(value: string): boolean {
  return value.length > 2;
}

export function isDateValid(date: string) {
  return isValid(parse(date, DEFAULT_VIEW_DATE_FORMAT, new Date()));
}

export function isAccountTypeValid(type: string): boolean {
  return type.length > 0;
}

// Референс
export function validateReference(reference: string): string {
  const pattern = getReferencePattern();

  if (!pattern.test(reference)) {
    return getReferenceErrorMsg();
  }

  return '';
}

export function isReferenceValid(reference: string): boolean {
  const pattern = getReferencePattern();

  return pattern.test(reference);
}

export function getReferencePattern() {
  return /^[0-9A-Z]{1,16}$/;
}

export function getReferenceErrorMsg(): string {
  return 'Цифры/латинские буквы. Не более 16 символов';
}

// Документы
export function isReasonDocumentsValid(
  documents: SecuritiesReasonDocument[],
  availableTypes?: SecuritiesDocumentType[]
): boolean {
  if (!availableTypes) {
    return false;
  }

  return (
    documents.length > 0 &&
    documents
      .map((doc) => isDocumentValid(doc, availableTypes))
      .reduce((all, next) => all && next)
  );
}

export function isDocumentValid(
  doc: SecuritiesReasonDocument,
  availableTypes: SecuritiesDocumentType[]
): boolean {
  return (
    isDocumentTypeValid(doc, availableTypes) &&
    isDocumentNumberValid(doc.number) &&
    isDateValid(doc.date) &&
    (doc.docType === 'BASE' ||
      (doc.docType === 'OTHER' && isFieldFilled(doc.docName)))
  );
}

export function isDocumentTypeValid(
  doc: SecuritiesReasonDocument,
  availableTypes: SecuritiesDocumentType[]
): boolean {
  return Boolean(availableTypes.find((type) => type.id === doc.id));
}

export function isDocumentNumberValid(num: string): boolean {
  return num.length > 0 && /^[^#]+$/.test(num);
}

// Actives
export function isTradeDateFit(date: string) {
  const d = parse(date, DEFAULT_VIEW_DATE_FORMAT, new Date());
  const MAX_DIFF_DAYS = 29;
  const minDay = startOfDay(subDays(new Date(), MAX_DIFF_DAYS));

  return isValid(d) && (isBefore(d, minDay) || isEqual(d, minDay));
}

// Transforms
const curLetters = ['А', 'В', 'Е', 'К', 'М', 'Н', 'О', 'Р', 'С', 'Т', 'Х'];
const latLetters = ['A', 'B', 'E', 'K', 'M', 'H', 'O', 'P', 'C', 'T', 'X'];

export function toSimilarLat(source: string) {
  let result = source;

  curLetters.forEach((char: string, index: number) => {
    result = result.replace(char, latLetters[index]);
  });

  return result;
}

export function removeUnwantedChar(str: string): string {
  return str.replace(/#/g, '');
}

export function upperCase(str: string): string {
  return str.toLocaleUpperCase();
}
