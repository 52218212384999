import React, { VFC } from 'react';
import { LightningMIcon } from '@alfalab/icons-glyph/LightningMIcon';

import { useBrokerChat } from '@terminal/chat/hooks/useBrokerChat';

import { RoomLayout } from '../../../../entities/Chat';
import { MessagesList } from '../MessagesList';

import styles from './BrokerChat.module.css';

export type BrokerChatProps = {
  id: string;
  onBack: () => void;
};

export const BrokerChat: VFC<BrokerChatProps> = (props) => {
  const { id, onBack } = props;
  const { title, sendMessage, messages } = useBrokerChat(id);

  return (
    <RoomLayout
      chatRoomTitle={title}
      chatRoomConnection={
        <>
          <LightningMIcon
            height={12}
            width={12}
            className={styles.onlineBadge}
          />
          На связи
        </>
      }
      sendMessage={sendMessage}
      onBack={onBack}
    >
      <MessagesList readMessages={messages} />
    </RoomLayout>
  );
};
