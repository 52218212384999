import { useCallback, useEffect, useMemo, useRef } from 'react';

import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';
import { BlueprintTable, CellRenderProps } from '@terminal/common/ui/Table';
import { DEFAULT_TRADE_FEED_TIMER } from '@terminal/core/constants/tradesFeed';
import { TableColumnKey } from '@terminal/core/types/tableColumn';
import { AbstractTradesService } from '@terminal/core/types/TradesService';

import {
  defaultColumnFilterRender,
  useWidgetContext,
} from '../../../../shared';
import { TradesTableRow } from '../../model/tradesTableRow';
import { TradesTableCell, TradeTableCellProps } from '../TradesTableCell';
import { calculateDirection } from '../TradesTableCell/lib/calculateDirection';

import { useAllTrades } from '../../hooks/useAllTrades';
import { useTradesFeedContext } from '../../hooks/useTradesFeedContext';

export function TradesTable(): JSX.Element {
  const { getTradesFeedSettings, useSettings } = useWidgetContext();
  const { TradesService } = useAlfaDirectContext();

  const settings = useSettings();

  const tradesFeedsSettings = getTradesFeedSettings();

  const {
    columnsSetting,
    saveColumnSetting,
    idFi,
    nodeId,
    timer,
    timerActive,
  } = useTradesFeedContext();

  const { highlightActive, highlightFrom } =
    settings.tickers?.[String(idFi)]?.highlight || {};
  const tradesService = useRef<AbstractTradesService>();
  const tradesFeedSettings = tradesFeedsSettings?.[idFi!];

  const currentFilterVolume = useMemo(() => {
    const tradesFeedSettingsByNodeId = tradesFeedSettings?.find(
      (item) => item.nodeId === nodeId
    );

    return tradesFeedSettingsByNodeId?.volume;
  }, [nodeId, tradesFeedSettings]);

  const [allTrades, addTrades, resetTrades] = useAllTrades(currentFilterVolume);

  useEffect(() => {
    addTrades([]);

    if (!tradesService.current) {
      tradesService.current = new TradesService();
    }

    const tradesServiceCurrent = tradesService.current;

    const subscribeReturnData = tradesServiceCurrent?.subscribeToFI(
      idFi!,
      (data) => {
        if (data.length) {
          addTrades(data);
        }
      },
      500
    );

    return () => {
      if (subscribeReturnData) {
        tradesServiceCurrent?.unsubscribe(idFi!, subscribeReturnData);
      }

      resetTrades();
    };
  }, [idFi, addTrades, TradesService, resetTrades]);

  const tradesPresented = allTrades.length > 0;

  // Таймер автоочистки начинает свой отсчет с момента появления в ленте хотя бы одной сделки
  useEffect(() => {
    if (!tradesPresented) {
      return;
    }

    if (!timerActive) {
      return;
    }

    const timerId = setTimeout(
      () => resetTrades(),
      (timer || DEFAULT_TRADE_FEED_TIMER) * 1000
    );

    return () => clearTimeout(timerId);
  }, [timer, timerActive, tradesPresented, resetTrades]);

  const normalizedTrades: TradesTableRow[] = allTrades.map<TradesTableRow>(
    (trade) => {
      const normalizedTrade: TradesTableRow = {
        ...trade,
        Qty: trade.Qty.toString(),
        TradeTime: trade.TradeTime.toLocaleTimeString(),
      };

      if (
        highlightActive &&
        highlightFrom &&
        Number(trade.Qty) >= highlightFrom
      ) {
        return {
          ...normalizedTrade,
          highlight: true,
        };
      }

      return normalizedTrade;
    }
  );

  const cellRender: CellRenderProps<TradesTableRow> = useCallback(
    (rowIndex, _, columnKey, data) => {
      const dataRow = data[rowIndex];
      const currentPrice = dataRow.Price;
      const prevPrice = data[rowIndex + 1]?.Price || currentPrice;

      const isSelectedPriceColumn =
        columnKey === TableColumnKey.TradePrice &&
        !columnsSetting.find(
          ({ key, selected }) => key === TableColumnKey.TradeBuySell && selected
        );
      const showDirection =
        isSelectedPriceColumn || columnKey === TableColumnKey.TradeBuySell;

      return (
        <TradesTableCell
          isBuying={dataRow.BuySell === 1}
          highlighted={Boolean(dataRow.highlight)}
          value={dataRow[columnKey]}
          columnKey={columnKey as TradeTableCellProps['columnKey']}
          {...(showDirection && {
            direction: calculateDirection(currentPrice, prevPrice),
          })}
        />
      );
    },
    [columnsSetting]
  );

  return (
    <BlueprintTable
      data={normalizedTrades}
      columnSetting={columnsSetting}
      cellRender={cellRender}
      columnFilterRender={defaultColumnFilterRender}
      onReorder={saveColumnSetting}
      onChangeWidth={saveColumnSetting}
      isBorderedRows
    />
  );
}
