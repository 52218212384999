import qs from 'query-string';

import { fetchLkFile } from '@terminal/core/lib/rest/lkApi';

import { BrokerRequisitesFileParams } from './types/BrokerRequisitesFileParams';

export async function fetchBrokerRequisitesPDF(
  params: BrokerRequisitesFileParams
) {
  return await fetchLkFile(
    qs.stringifyUrl({
      url: '/api/requisites/broker/pdf',
      query: {
        treaty: params.treaty,
        currency: params.currency,
        inline: true,
        requisiteId: params.requisiteId,
        placeCode: params.placeCode,
      },
    })
  );
}
