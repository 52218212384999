import { isValid, parse } from 'date-fns';

import { DEFAULT_VIEW_DATE_FORMAT } from '@terminal/core/lib/dateToRequestFormat';
import { MoneyHistoryFilter } from '@terminal/core/types/money';

import { areDatesOverlapping } from '../../components/Filter';

import { CustomHistoryFilter } from '../../shared/types';

export const setFilterProp = (
  filter: CustomHistoryFilter,
  key: keyof CustomHistoryFilter,
  value: string
): CustomHistoryFilter => {
  const prop = filter[key];

  if (Array.isArray(prop)) {
    const newProp = [...prop];
    const index = newProp.indexOf(value);

    if (index >= 0) {
      newProp.splice(index, 1);
    } else {
      newProp.push(value);
    }

    return {
      ...filter,
      [key]: newProp,
    };
  } else {
    return {
      ...filter,
      [key]: value,
    };
  }
};

export const isDateValid = (date: string = ''): boolean => {
  return isValid(parse(date, DEFAULT_VIEW_DATE_FORMAT, new Date()));
};

export const isFilterValid = (filter: MoneyHistoryFilter): boolean => {
  return (
    isDateValid(filter.startDate) &&
    isDateValid(filter.endDate) &&
    !areDatesOverlapping(filter.startDate, filter.endDate)
  );
};
