import { FC, PropsWithChildren, useMemo } from 'react';

import { QuotesService } from '../../lib/services/quotes';
import { TradingService } from '../../lib/services/trading';

import { Context, ServicesContext } from '../../hooks/useServicesContext';

interface Props {
  tradingService: TradingService;
  quotesService: QuotesService;
}

/**
 * Контекст сервисов приложения
 * */
export const ServicesProvider: FC<PropsWithChildren<Props>> = (props) => {
  const { children, tradingService, quotesService } = props;

  const contextValue = useMemo<ServicesContext>(
    () => ({
      tradingService,
      quotesService,
    }),
    [tradingService, quotesService]
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};
