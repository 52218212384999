import { CurrencyCodes, formatAmount } from '@alfalab/utils';

import { MINORITY } from '../../constants/ui';
import { ObjectGroup, OrderType } from '../../lib/client/entities';
import { getAssetUnits } from '../../lib/format';
import { getLimitsValue } from '../../lib/helpers/getLimitsValue';

import { TSide } from '../../types/common';
import { TradingLimitsShowMode } from '../../types/layout';
import {
  LimitLongFormatter,
  LimitSortFormatter,
  TradeLimits,
} from '../../types/tradeLimits';
import { OrderDimensionEnum } from '../../types/ui';

const DEFAULT_LONG_FORMATTER: LimitLongFormatter = (amount, ownAmount) =>
  ownAmount ? `${ownAmount} (${amount})` : amount;

const DEFAULT_SHORT_FORMATTER: LimitSortFormatter = (amount) => String(amount);

const ALLOWED_ORDER_TYPES: OrderType[] = [
  OrderType.MKT,
  OrderType.LMT,
  OrderType.STP,
  OrderType.TRS,
];

export type TradingLimitsMarkdownOptions = {
  showMode?: TradingLimitsShowMode;
  /**
   * Подсказка в случае покупки
   * - none — без подсказки
   * - margin — сообщение о том, что сделка будет совершена в маржу
   * - money — количество денег на счете
   * @default none
   * */
  hint?: 'margin' | 'money' | 'none';
  /**
   * Направление сделки
   * @default buy
   * */
  side?: TSide;
  /**
   * Количество. Используется для отображения подсказки `margin`
   * @default 0
   * */
  quantity?: number;
  /**
   * Функция для форматирования текста лимита на покупку
   * */
  formatLong?: LimitLongFormatter;
  /**
   * Функция для форматирования текста лимита на продажу
   * */
  formatShort?: LimitSortFormatter;
  /**
   * Признак ЕФР
   * @default false
   * */
  isSingleFORTSMarket?: boolean;
  /**
   * Настройка объема
   * @default LOT
   * */
  orderDimension?: OrderDimensionEnum;
  /**
   * Количество в лоте
   * */
  lot?: number;
  /**
   * Валюта
   * */
  currencyCode?: CurrencyCodes;
  /**
   * Идентификатор группы объекта
   * */
  idObjectGroup?: ObjectGroup;
  /**
   * Тип заявки. Если указан, то используется для определения доступности
   * лимитов для фьючерсов
   * */
  orderType?: OrderType;
  /**
   * Определяет возможность отображения лимитов для фьючерсов
   * @default false
   * */
  showForFutures?: boolean;
};

/**
 * @returns строку с информацией о лимитах на сделку в формате markdown
 * */
export function useTradingLimitsMarkdown(
  tradeLimits: TradeLimits,
  options: TradingLimitsMarkdownOptions = {}
): string {
  const {
    showMode = TradingLimitsShowMode.MONEY,
    hint = 'none',
    side = 'buy',
    quantity = 0,
    formatLong = DEFAULT_LONG_FORMATTER,
    formatShort = DEFAULT_SHORT_FORMATTER,
    isSingleFORTSMarket = false,
    orderDimension = OrderDimensionEnum.LOT,
    lot,
    idObjectGroup,
    currencyCode,
    orderType,
    showForFutures = false,
  } = options;

  const isMoney = showMode === TradingLimitsShowMode.MONEY;
  const isQuantity = showMode === TradingLimitsShowMode.QUANTITY;
  const isFutures = idObjectGroup === ObjectGroup.Futures;
  const minority = isMoney ? MINORITY : 1;
  const currency = isMoney ? currencyCode : undefined;

  // Для фьючерсов сервис возвращает лимиты только на ЕФР
  if (isFutures && !isSingleFORTSMarket && !showForFutures) {
    return '';
  }

  if (isFutures && orderType && !ALLOWED_ORDER_TYPES.includes(orderType)) {
    return '';
  }

  if (side === 'sell' && tradeLimits.short) {
    const value = getLimitsValue(
      showMode,
      tradeLimits.short.amount,
      tradeLimits.short.quantity,
      lot,
      orderDimension
    );

    const { formattedWithCurrency } = formatAmount({
      value: value * minority,
      minority,
      currency,
      view: 'withZeroMinorPart',
    });

    const suffix = isQuantity
      ? `&nbsp;${getAssetUnits(value, orderDimension)}`
      : '';

    return formatShort(`**${formattedWithCurrency}**${suffix}`, value);
  }

  if (side === 'buy' && tradeLimits.long) {
    const ownValue = getLimitsValue(
      showMode,
      tradeLimits.long.freeMoney,
      tradeLimits.long.quantityForOwnAssets,
      lot,
      orderDimension
    );

    const ownAmount = formatAmount({
      value: ownValue * minority,
      minority,
      currency,
      view: 'withZeroMinorPart',
    });

    const ownSuffix = isQuantity
      ? `&nbsp;${getAssetUnits(ownValue, orderDimension)}`
      : '';

    const value = getLimitsValue(
      showMode,
      tradeLimits.long.amount,
      tradeLimits.long.quantity,
      lot,
      orderDimension
    );

    const amount = formatAmount({
      value: value * minority,
      minority,
      currency,
      view: 'withZeroMinorPart',
    });

    const suffix = isQuantity
      ? `&nbsp;${getAssetUnits(value, orderDimension)}`
      : '';

    const limits = formatLong(
      `**${amount.formattedWithCurrency}**${suffix}`,
      isFutures && !showForFutures
        ? undefined
        : `**${ownAmount.formattedWithCurrency}**${ownSuffix}`,
      value,
      ownValue
    );

    if (hint === 'margin' && quantity > tradeLimits.long.quantityForOwnAssets) {
      return `${limits}\n\nСделка будет совершена с маржой`;
    }

    if (hint === 'money') {
      const { formattedWithCurrency } = formatAmount({
        value: tradeLimits.long.freeMoney * minority,
        minority,
        currency: currencyCode,
        view: 'withZeroMinorPart',
      });

      return `${limits}\n\nНа счете **${formattedWithCurrency}**`;
    }

    return limits;
  }

  return '';
}
