import { EventEmitter } from 'eventemitter3';
import log from 'loglevel';

import {
  InitFlagType,
  MessageUnitedType,
  OrderBookEntity,
  OrderBookWithYieldEntity,
} from '../client/entities';
import { EntityType } from '../client/entityTypes';
import { StreamingService, SubscribeReturnData } from './streaming';

import { OrderBookLine } from '../../types/orderBook';

type LineMap = Map<number, OrderBookLine>;

type Entity = OrderBookEntity | OrderBookWithYieldEntity;

type OrderBookInfo = {
  IsInit: boolean;
  Lines: LineMap;
  IdBoard?: number;
  IdGate?: number;
  LinesType?: 'OrderBookWithYieldEntity' | 'OrderBookEntity';
};

/**
 * OrderBook (книга заявок, стакан) - это публичная информация о зарегистрированных на рынке заявках по инструменту.
 * Документация по работе с данными: https://confluence.moscow.alfaintra.net/display/ADIR/OrderBook
 * */
export class OrderBookService extends EventEmitter {
  /**
   * Мы предоставляем пользователям 40 лучших уровней стакана (20 на покупку и 20 на продажу)
   * */
  private readonly maxLines = 40;

  /**
   * Обновление приходит в виде объекта того же самого типа как имеющийся стакан, линии из него берутся по одной и применяются к имеющемуся стакану.
   * У нас реализовано два механизма идентификации уровней стакана, через поле LineId и через цену (в этом случае LineId равен -1)
   * */
  private readonly idFIToOrderBook: Map<number, OrderBookInfo> = new Map();

  private readonly idFIToListenersCount: Map<number, number> = new Map();

  /**
   * @param fi идентификатор фин. инструмента
   * */
  public subscribe(fi: number): SubscribeReturnData<Entity> {
    if (!this.idFIToOrderBook.has(fi)) {
      this.idFIToOrderBook.set(fi, {
        IsInit: false,
        Lines: new Map(),
      });
      this.idFIToListenersCount.set(fi, 0);
    }

    const listenersCount = this.idFIToListenersCount.get(fi) ?? 0;
    const streamingEventHandler = this.createStreamingEventHandler(fi);

    this.idFIToListenersCount.set(fi, listenersCount + 1);

    StreamingService.addClientListener(
      EntityType.OrderBookEntity,
      streamingEventHandler
    );
    StreamingService.addClientListener(
      EntityType.OrderBookWithYieldEntity,
      streamingEventHandler
    );

    const subscribeReturnData = StreamingService.subscribe({
      entity: EntityType.AvailableOrderBookEntity,
      fi: [fi],
    });

    return {
      ...subscribeReturnData,
      subscribeHandler: streamingEventHandler,
    };
  }

  public unsubscribe(
    fi: number,
    subscribeReturnData: SubscribeReturnData<Entity>
  ) {
    const listenersCount = this.idFIToListenersCount.get(fi) ?? 0;
    const updatedListenersCount = Math.max(listenersCount - 1, 0);
    const { subscribeHandler } = subscribeReturnData;

    this.idFIToListenersCount.set(fi, updatedListenersCount);

    if (updatedListenersCount === 0) {
      this.idFIToOrderBook.delete(fi);
      this.idFIToListenersCount.delete(fi);
    }

    StreamingService.unsubscribe(
      { fi: [fi], entity: EntityType.AvailableOrderBookEntity },
      subscribeReturnData
    );

    if (subscribeHandler) {
      StreamingService.removeClientListener(
        EntityType.OrderBookEntity,
        subscribeHandler
      );
      StreamingService.removeClientListener(
        EntityType.OrderBookWithYieldEntity,
        subscribeHandler
      );
    }
  }

  public getInitialData(fi: number): OrderBookLine[] {
    return this.getLines(fi);
  }

  private getLines(fi: number): OrderBookLine[] {
    return Array.from(
      this.idFIToOrderBook.get(fi)?.Lines ?? new Map(),
      ([, value]) => ({
        ...value,
      })
    );
  }

  private checkMessage(message: MessageUnitedType<Entity>) {
    const { data, type } = message;

    data.forEach((entity) => {
      // Групповая команда очистки характерна тем, что в пришедшем стакане IdFI=0.
      // В этом случае IdGate ДОЛЖЕН быть !=0 и IdMarketBoard МОЖЕТ быть !=0.
      // Флаг IsInit, если он вдруг установлен следует игнорировать.
      if (entity.IdFI === 0 && entity.IdGate !== 0) {
        this.idFIToOrderBook.forEach((info, fi) => {
          // IdBoard МОЖЕТ быть = 0, тогда совпадение не проверяем
          const boardMatch = entity.IdBoard
            ? entity.IdBoard === info.IdBoard
            : true;

          // Клиент должен пройти по всем стаканам с совпадающим IdGate , и IdMarketBoard если !=0,  удалить все линии и установить у стаканов Revision=0
          if (info.IdGate === entity.IdGate && boardMatch) {
            info.IsInit = false;
            info.LinesType = type as
              | 'OrderBookWithYieldEntity'
              | 'OrderBookEntity';
            info.Lines = new Map();

            this.emit('clear', fi);
          }
        });
      } else {
        this.processOrderbookEntity(
          entity,
          type as 'OrderBookWithYieldEntity' | 'OrderBookEntity'
        );
      }
    });
  }

  private createStreamingEventHandler(idFi: number) {
    return (message: MessageUnitedType<Entity>) => {
      this.checkMessage(message);

      // Если придет соответстующий стакана со значением IsInit в поле Flags, что означает получение снапшота,
      // тогда клиент должен применить к снапшоту отложенные обновления и результат показать.
      if (this.idFIToOrderBook.get(idFi)?.IsInit) {
        this.emit('update', {
          idFI: idFi,
          lines: this.getLines(idFi),
        });
      }
    };
  }

  private processOrderbookEntity(
    { IdFI, IdBoard, IdGate, Lines, Flags }: Entity,
    type: 'OrderBookWithYieldEntity' | 'OrderBookEntity'
  ) {
    if (!this.idFIToOrderBook.has(IdFI)) {
      // Это данные другого фин. инструмента
      return;
    }

    if (!Lines || Lines.length < 1) {
      return;
    }

    const info = this.idFIToOrderBook.get(IdFI)!;

    if (!info.LinesType) {
      info.LinesType = type;
    } else if (info.LinesType !== type) {
      // При получении стаканов другого типа, игнорировать их
      return;
    }

    info.IdBoard = IdBoard;
    info.IdGate = IdGate;

    // Если флаг IsInit ещё не установлен, то пытаемся обновить его
    if (!info.IsInit) {
      info.IsInit = Flags === InitFlagType.IsInit;
    }

    // Получаем ссылку на имеющиеся уровни или создаем новые
    const lines: LineMap = info.Lines ?? new Map();

    // Метод построения будет одинаков с момента подписки/очистки
    const byLineId = Lines.some((line) => line.LineId > -1);

    Lines.forEach((line) => {
      // У нас реализовано два механизма идентификации уровней стакана, через поле LineId и через цену (в этом случае LineId равен -1, ищем по OldPrice)
      const oldKey = byLineId ? line.LineId : line.OldPrice;
      const newKey = byLineId ? line.LineId : line.Price;

      // Если у линии обновления BuyQty=0 и SellQty=0, то это команда удаления, иначе это новые данные
      if (line.BuyQty === 0 && line.SellQty === 0) {
        if (lines.has(oldKey)) {
          lines.delete(oldKey);
        } else {
          // Если такой нет, то это ошибка
          log.debug('Некорректный ценовой уровень стакана', lines, line);
        }
      } else if (lines.has(oldKey)) {
        const localLine = lines.get(oldKey)!;

        // Cравниваем Revision из обновления с имеющимся, если у линии обновления Revision больше, то копируем её поля в имеющуюся линию
        if (line.Revision > localLine.Revision) {
          localLine.Price = line.Price;
          localLine.BuyQty = line.BuyQty;
          localLine.SellQty = line.SellQty;
          localLine.Revision = line.Revision;
          localLine.Yield = line.Yield;
          localLine.OldPrice = line.OldPrice;
        }

        lines.delete(oldKey);
        lines.set(newKey, localLine);
      } else {
        // Если линии с таким ключом ещё нет, то просто добавляем её
        lines.set(newKey, line);
      }
    });
  }
}

export const orderBookService = new OrderBookService();
