import cn from 'classnames';
import React, {
  ComponentPropsWithoutRef,
  ElementType,
  HTMLAttributes,
  ReactElement,
} from 'react';

import styles from './MenuItem.module.css';

type Props<C extends ElementType> = Pick<
  HTMLAttributes<HTMLElement>,
  'className'
> & {
  /**
   * Компонент, который будет отображен. По умолчанию `div`.
   * */
  Component?: C;
};

type MenuItemProps<C extends ElementType> = Props<C> &
  Omit<ComponentPropsWithoutRef<C>, keyof Props<C>>;

export function MenuItem<C extends ElementType>(
  props: MenuItemProps<C>
): ReactElement | null {
  const { Component = 'div', className, ...restProps } = props;
  const rootClassName = cn(styles.root, className);

  return <Component className={rootClassName} {...restProps} />;
}
