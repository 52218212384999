import { IdentityDocumentType } from '../../model/types/IdentityDocumentType';

export const IDENTITY_DOCUMENT_NUMBER_MASK = {
  [IdentityDocumentType.RUSSIAN_PASSPORT]: [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  [IdentityDocumentType.INTERNATIONAL_PASSPORT]: [
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
};
