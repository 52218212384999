import { format, startOfMonth } from 'date-fns';
import isEqual from 'lodash/isEqual';
import { useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDebounce } from 'use-debounce';
import { Loader } from '@alfalab/core-components/loader';
import { FilterMIcon } from '@alfalab/icons-glyph/FilterMIcon';

import { FunctionalModalSidebar } from '@terminal/common/components/FunctionalModal';
import { Portal } from '@terminal/common/components/Portal';
import {
  dateToRequestFormat,
  DEFAULT_VIEW_DATE_FORMAT,
} from '@terminal/core/lib/dateToRequestFormat';
import { Treaty } from '@terminal/core/lib/rest/lkCommon';
import {
  SecuritiesHistoryFilter,
  SecuritiesHistoryParams,
} from '@terminal/core/lib/rest/lkSecurities';

import { Result } from '../../../../shared/ui/Result';
import { SelectTreaty } from '../../../components/SelectTreaty';
import {
  HistoryFilter,
  HistoryFilterLine,
} from '../../components/HistoryFilter';
import { HistoryList } from '../../components/HistoryList';

import { useSecuritiesHistory } from '../../hooks';

import styles from './SecuritiesHistory.module.css';

const PAGE_SIZE = 20;

export const defaultFilter: SecuritiesHistoryFilter = {
  startDate: format(startOfMonth(new Date()), DEFAULT_VIEW_DATE_FORMAT),
  endDate: format(new Date(), DEFAULT_VIEW_DATE_FORMAT),
  searchString: '',
};

export const SecuritiesHistory = () => {
  const [treaty, setTreaty] = useState<Treaty | null>(null);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState<SecuritiesHistoryFilter>(defaultFilter);
  const [openFilter, setOpenFilter] = useState(false);

  const historyParams = useMemo((): SecuritiesHistoryParams => {
    return {
      ...filter,
      startDate: dateToRequestFormat(filter.startDate),
      endDate: dateToRequestFormat(filter.endDate),
      treaty: treaty?.treaty || 0,
      page,
      count: PAGE_SIZE,
    };
  }, [page, treaty, filter]);

  const [debouncedHistoryParams] = useDebounce<SecuritiesHistoryParams>(
    historyParams,
    250
  );

  const {
    data: history,
    isFetching: historyPending,
    isFetchingNextPage: historyPendingNext,
    hasNextPage: hasMoreHistory,
    fetchNextPage: fetchMoreHistory,
    refetch,
  } = useSecuritiesHistory(debouncedHistoryParams);

  const [loaderRef, loaderInView] = useInView();

  useEffect(() => {
    if (loaderInView) {
      fetchMoreHistory();
    }
  }, [loaderInView, fetchMoreHistory]);

  const historyItems = useMemo(() => {
    if (history) {
      return history.pages.flatMap((page) => page.transfers);
    }

    return null;
  }, [history]);

  // При изменении фильтра устанавливаем 1-ю страницу
  useEffect(() => {
    setPage(1);
  }, [treaty]);

  const isFilterActive = useMemo<boolean>(() => {
    return !isEqual(filter, defaultFilter);
  }, [filter]);

  const onCloseFilter = () => {
    setOpenFilter(false);
  };

  const refetchHistory = async () => {
    await refetch();
  };

  return (
    <div className={styles.container}>
      <div className={styles.treaty}>
        <SelectTreaty onSelect={(treaty) => setTreaty(treaty)} />
      </div>
      <div className={styles.filter}>
        <HistoryFilterLine
          filter={filter}
          active={isFilterActive}
          onFilterOpen={() => setOpenFilter(true)}
          onSetFilter={(filter) => setFilter(filter)}
        />
      </div>
      <div className={styles.history}>
        {historyItems && historyItems.length === 0 && (
          <Result
            title="Результатов нет"
            text={
              <>
                По заданным параметрам не&nbsp;найдено ни&nbsp;одного перевода
              </>
            }
            icon={<FilterMIcon />}
          />
        )}

        {historyItems && historyItems.length !== 0 && (
          <HistoryList
            history={historyItems}
            treaty={treaty}
            refetchHistory={refetchHistory}
          />
        )}

        {(historyPending || historyPendingNext) && (
          <Loader dataTestId="history-loader" />
        )}

        {hasMoreHistory && !(historyPending || historyPendingNext) && (
          <div className={styles.loader} ref={loaderRef}></div>
        )}
      </div>

      <Portal id="securities-transfer-modals">
        <FunctionalModalSidebar
          open={openFilter}
          backLabel="История"
          onClose={onCloseFilter}
        >
          <HistoryFilter
            filter={filter}
            onSetFilter={(filter) => setFilter(filter)}
            onResetFilter={() => setFilter(defaultFilter)}
            onClose={onCloseFilter}
          />
        </FunctionalModalSidebar>
      </Portal>
    </div>
  );
};
