import { useState } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Link } from '@alfalab/core-components/link';
import { Space } from '@alfalab/core-components/space';
import {
  Tab,
  TabsDesktop,
  TabsDesktopProps,
} from '@alfalab/core-components/tabs/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { CopyLineMIcon } from '@alfalab/icons-glyph/CopyLineMIcon';
import { DocumentLinesMIcon } from '@alfalab/icons-glyph/DocumentLinesMIcon';
import { OutsideMIcon } from '@alfalab/icons-glyph/OutsideMIcon';

import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';
import { Changelog } from '@terminal/common/components/Changelog/Changelog';
import {
  FunctionalModal,
  FunctionalModalSidebar,
} from '@terminal/common/components/FunctionalModal';
import { Portal } from '@terminal/common/components/Portal';
import { APP_VERSION } from '@terminal/core/env';
import { useAppContext } from '@terminal/core/hooks/useAppContext';
import { getBrowserInfo } from '@terminal/core/lib/info';
import { ChangelogEntry } from '@terminal/core/types/changelog';

import styles from './About.module.css';

interface AboutProps {
  brokerLink: string;
  onClose: () => void;
}

export const About = (props: AboutProps) => {
  const { brokerLink, onClose } = props;
  const [agreementOpen, setAgreementOpen] = useState(false);
  const [changelogEntry, setChangelogEntry] = useState<null | ChangelogEntry>(
    null
  );
  const { user } = useAlfaDirectContext();
  const [selectedTab, setSelectedTab] = useState('about');

  const changelog = useAppContext()?.changelog;

  const browserInfo = getBrowserInfo();

  const infoToClipboard = () => {
    navigator.clipboard.writeText(
      `Логин: ${user?.login}\nБраузер: ${browserInfo.name} ${browserInfo.version}\nВерсия приложения: ${APP_VERSION}`
    );
  };

  const handleChange: TabsDesktopProps['onChange'] = (_, { selectedId }) =>
    setSelectedTab(selectedId as string);

  return (
    <FunctionalModal
      title="О приложении"
      onClose={onClose}
      drawerId="about-modals"
      innerClassname={styles.modalInner}
    >
      <div className={styles.container}>
        <TabsDesktop
          selectedId={selectedTab}
          onChange={handleChange}
          scrollable={false}
          size="s"
          className={styles.tabs}
        >
          <Tab title="Общая информация" id="about" className={styles.tab}>
            <Space fullWidth>
              <Row>
                <Col width={6}>
                  <div>
                    <Typography.Text
                      view="secondary-medium"
                      color="secondary"
                      className={styles.label}
                    >
                      Логин
                    </Typography.Text>
                    <Typography.Text view="primary-small" color="primary">
                      {user?.login}
                    </Typography.Text>
                  </div>
                </Col>
                <Col width={6}>
                  <div>
                    <Typography.Text
                      view="secondary-medium"
                      color="secondary"
                      className={styles.label}
                    >
                      Браузер
                    </Typography.Text>
                    <Typography.Text view="primary-small" color="primary">
                      {browserInfo.name} {browserInfo.version}
                    </Typography.Text>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col width={6}>
                  <div>
                    <Typography.Text
                      view="secondary-medium"
                      color="secondary"
                      className={styles.label}
                    >
                      Версия приложения
                    </Typography.Text>
                    <Typography.Text
                      view="primary-small"
                      color="primary"
                      onClick={
                        // хендлер исмпользуется для запуска скрипта переключения версии
                        // @ts-expect-error
                        window?.__onVersionChange
                      }
                    >
                      {APP_VERSION}
                    </Typography.Text>
                  </div>
                </Col>
              </Row>
            </Space>

            <div className={styles.copy}>
              <Row>
                <Col width={6}>
                  <ButtonDesktop
                    block
                    view="tertiary"
                    size="xxs"
                    leftAddons={
                      <CopyLineMIcon
                        width={18}
                        height={18}
                        color="var(--color-light-graphic-secondary-inverted)"
                      />
                    }
                    onClick={infoToClipboard}
                  >
                    Скопировать данные
                  </ButtonDesktop>
                </Col>
              </Row>
            </div>

            <div className={styles.actions}>
              <div className={styles.header}>
                <Typography.Title
                  view="xsmall"
                  tag="div"
                  color="primary"
                  weight="bold"
                  className={styles.title}
                >
                  Документы
                </Typography.Title>
              </div>
              <div className={styles.documentList}>
                <Link
                  leftAddons={<DocumentLinesMIcon width={18} height={18} />}
                  onClick={() => setAgreementOpen(true)}
                >
                  Пользовательское соглашение
                </Link>
                <Link
                  leftAddons={<DocumentLinesMIcon width={18} height={18} />}
                  rightAddons={
                    <OutsideMIcon
                      width={16}
                      height={16}
                      color="var(--color-light-graphic-secondary-inverted)"
                    />
                  }
                  target="_blank"
                  href={brokerLink}
                >
                  О брокере
                </Link>
              </div>
            </div>
          </Tab>
          <Tab id="versions" title="История версий" className={styles.tab}>
            <Changelog
              changelog={changelog}
              source="pro"
              onEntryClick={(entry: ChangelogEntry) => {
                setChangelogEntry(entry);
              }}
            />
          </Tab>
        </TabsDesktop>
      </div>
      <Portal id="about-modals">
        <FunctionalModalSidebar
          open={agreementOpen}
          backLabel="Назад"
          onClose={() => setAgreementOpen(false)}
        >
          <div className={styles.container}>
            <Typography.Title
              view="xsmall"
              tag="div"
              className={styles.agreementTitle}
            >
              Пользовательское соглашение
            </Typography.Title>
            <Typography.Text
              view="primary-small"
              className={styles.agreementText}
            >
              Условия настоящего Соглашения определяют правила и порядок
              предоставления биржевой информации.
              <br />
              <br />
              Пользователи имеют право использовать, хранить и обрабатывать
              биржевую информацию, но не могут без письменного согласия
              Московской биржи осуществлять ее дальнейшую передачу в любом виде
              и любыми средствами, включая электронные, механические,
              фотокопировальные, записывающие или другие, ее трансляцию, в том
              числе средствами телевизионного и радиовещания, ее демонстрацию на
              интернет-сайтах, а также ее использование в игровых, тренажерных и
              иных системах, предусматривающих демонстрацию и/или передачу
              биржевой информации, и для расчета производной информации,
              предназначенной для дальнейшего публичного распространения.
              <br />
              <br />
              Продолжая работу, вы принимаете в полном объеме все изложенные
              условия Соглашения и обязуетесь их выполнять.
              <br />
            </Typography.Text>
          </div>
        </FunctionalModalSidebar>

        {changelogEntry ? (
          <FunctionalModalSidebar
            open={Boolean(changelogEntry)}
            backLabel="История версий"
            onClose={() => setChangelogEntry(null)}
          >
            <div className={styles.container}>
              <div className={styles.changelogEntryHeader}>
                <Typography.Text weight="bold" color="primary">
                  Версия {changelogEntry?.version}
                </Typography.Text>

                <Typography.Text view="secondary-large" color="secondary">
                  {new Date(changelogEntry?.date).toLocaleDateString()}
                </Typography.Text>
              </div>
              <div className={styles.changelogEntryBody}>
                {changelogEntry ? (
                  <div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: changelogEntry.contents,
                      }}
                    ></div>
                  </div>
                ) : null}
              </div>
            </div>
          </FunctionalModalSidebar>
        ) : null}
      </Portal>
    </FunctionalModal>
  );
};
