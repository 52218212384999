import { ReactNode, VFC } from 'react';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowBackMIcon } from '@alfalab/icons-glyph/ArrowBackMIcon';

import { InputControl } from '../InputControl';

import styles from './RoomLayout.module.css';

export type RoomLayoutProps = {
  children: ReactNode;
  chatRoomTitle: string;
  chatRoomConnection: ReactNode;
  sendMessage?: (text: string) => Promise<void>;
  onBack: () => void;
};

export const RoomLayout: VFC<RoomLayoutProps> = (props) => {
  const { children, chatRoomTitle, chatRoomConnection, sendMessage, onBack } =
    props;

  return (
    <div className={styles.pcRoomWindow}>
      <div className={styles.chatRoomHeader}>
        <ArrowBackMIcon
          height={24}
          width={24}
          color="#8D8D93"
          onClick={onBack}
          className={styles.backButton}
        />
        <div className={styles.pcHeaderTitle}>
          <div>
            <Typography.Text
              tag="div"
              view="secondary-large"
              weight="medium"
              className={styles.itemTitle}
            >
              {chatRoomTitle}
            </Typography.Text>
            <Typography.Text tag="div" view="secondary-small" color="secondary">
              {chatRoomConnection}
            </Typography.Text>
          </div>
        </div>
      </div>
      <div className={styles.main}>{children}</div>
      {sendMessage && (
        <div className={styles.footer}>
          <InputControl sendMessage={sendMessage} disabled />
        </div>
      )}
    </div>
  );
};
