import cn from 'classnames';
import { FC } from 'react';
import { Amount } from '@alfalab/core-components/amount';
import { Loader } from '@alfalab/core-components/loader';
import { Typography } from '@alfalab/core-components/typography';

import { getSymbolToShow } from '@terminal/core/lib/currencies';
import { getMinority } from '@terminal/core/lib/format';
import { calculateDerivedDataForInstrument } from '@terminal/core/lib/formulas/calculateDerivedDataForIstrument';
import { ObjectTypeCategory } from '@terminal/core/types/objectTypes';

import { SymbolCell } from '../../../../features/SymbolCell';
import { useWidgetContext } from '../../../../shared';

import { useInstrumentInfo } from '../../hooks/useInstrumentInfo';
import { useQuote } from '../../hooks/useQuote';

import classes from './InstrumentTitle.module.css';

interface Props {
  idFi: number;
}

const InstrumentTitle: FC<Props> = ({ idFi }) => {
  const { useIssuer } = useWidgetContext();
  const { isin, fullFI, objectTypeCategory, finInfoExt } =
    useInstrumentInfo(idFi);

  const { quoteData } = useQuote(idFi);
  const { isLoading, data: issuerData } = useIssuer(isin);

  const { country, economicSector } = issuerData ?? {};

  if (isLoading) {
    return <Loader />;
  }

  const isBond = objectTypeCategory === ObjectTypeCategory.Bond;
  const isCurrency = objectTypeCategory === ObjectTypeCategory.Currency;

  const { change, lastPrice = 0 } = calculateDerivedDataForInstrument({
    ...fullFI,
    ...finInfoExt,
    last: quoteData?.last,
  });

  const lastPriceMinority = getMinority(lastPrice);

  return (
    <div className={classes.instrumentTitleContainer}>
      <SymbolCell
        idObject={fullFI?.idObject}
        symbolObject={fullFI?.symbolObject}
        onlyIcon
        isDesktop
      />
      <div className={classes.instrumentTitleTextContainer}>
        <Typography.Text
          className={classes.instrumentTitle}
          view="primary-medium"
        >
          <span className={classes.instrumentName}>{fullFI?.nameObject}</span>

          {quoteData && (
            <>
              <Amount.Pure
                value={lastPrice * lastPriceMinority}
                minority={lastPriceMinority}
                view="withZeroMinorPart"
                currency={getSymbolToShow(
                  fullFI?.currencyCode,
                  fullFI?.idObjectGroup
                )}
              />
              {isBond && ' %'}
            </>
          )}
          {change ? (
            <span
              className={cn(
                classes.price,
                change > 0 ? classes.pricePositive : classes.priceNegative
              )}
            >
              <Amount.Pure
                value={change * 100}
                minority={100}
                view="withZeroMinorPart"
                showPlus
              />
              %
            </span>
          ) : null}
        </Typography.Text>
        <Typography.Text
          className={classes.instrumentSubTitle}
          view="secondary-large"
          color="secondary"
        >
          {fullFI?.nameObjectType}
        </Typography.Text>
        <Typography.Text
          className={classes.instrumentCountryInfo}
          view="secondary-small"
          color="secondary"
        >
          {country?.text && !isCurrency && (
            <img
              className={classes.flag}
              src={`https://alfabank.servicecdn.ru/icons/${country.iconName}.svg`}
              alt={country.text}
            />
          )}
          {country?.text &&
            !isCurrency &&
            `${country.text}${
              economicSector?.text ? ` • ${economicSector?.text}` : ''
            }`}
        </Typography.Text>
      </div>
    </div>
  );
};

export { InstrumentTitle };
