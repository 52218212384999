import { OrderType } from '../../lib/client/entities';

import { NewOrderType, OrderItem } from '../../types/order';

// Возвращает цену для разные типов ордеров для сравнения изменения в заявке
export function getOrderEditPrice(order: NewOrderType | OrderItem) {
  switch (order.idOrderType) {
    case OrderType.MKT:
    case OrderType.LMT:
    case OrderType.LIT:
    case OrderType.BRS:
    case OrderType.BSL:
    case OrderType.TRL:
    case OrderType.TBRS:
      return order.limitPrice;
    case OrderType.STL:
    case OrderType.TSL:
      return order.stopPrice || order.limitLevelAlternative;
    case OrderType.STP:
    case OrderType.TRS:
      return order.stopPrice;
  }
}
