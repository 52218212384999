import cn from 'classnames';
import { FC, memo, ReactNode } from 'react';

import { WidgetSettings, WidgetSettingsProps } from '../../../WidgetSettings';

import styles from './WidgetHeader.module.css';

interface Props {
  inputs?: ReactNode;
  filters?: ReactNode;
  widgetSettings?: WidgetSettingsProps;
  controls?: ReactNode;
  withMultiline?: boolean;
}

const Header: FC<Props> = (props) => {
  const { inputs, filters, widgetSettings, controls, withMultiline } = props;

  return (
    <div
      className={cn(
        styles.container,
        !withMultiline && styles.withoutMultiline
      )}
    >
      <div className={styles.left}>
        {inputs && (
          <div className={cn(styles.inputs, withMultiline && styles.wraps)}>
            {inputs}
          </div>
        )}

        {filters && <div className={styles.filters}>{filters}</div>}
      </div>

      {(widgetSettings || controls) && (
        <div className={styles.controls}>
          {controls}

          {widgetSettings && <WidgetSettings {...widgetSettings} />}
        </div>
      )}
    </div>
  );
};

export const WidgetHeader = memo(Header);
