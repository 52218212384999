import React, { FC } from 'react';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';

interface NoResultsProps {
  mainText: string;
  descriptionText: string;
  icon: JSX.Element;
  actions?: JSX.Element;
}

export const InfoMessage: FC<NoResultsProps> = (props) => {
  const { mainText, descriptionText, actions, icon } = props;

  return (
    <Space direction="vertical" size={16} align="center">
      <SuperEllipse size={48} backgroundColor="var(--color-dark-bg-secondary)">
        {icon}
      </SuperEllipse>
      <Space direction="vertical" size={4} align="center">
        <Typography.Text view="primary-medium" weight="bold">
          {mainText}
        </Typography.Text>
        <Typography.Text color="secondary" view="secondary-large">
          {descriptionText}
        </Typography.Text>
      </Space>
      {actions && <div>{actions}</div>}
    </Space>
  );
};
