import { getStepDecimals } from '../format';

/**
 * Проверяет, кратна ли цена шагу
 * @param priceToCheck цена для проверки
 * @param priceTouchedToCheck включает проверку на кратность
 * @param priceStep шаг цены
 * */
export const getPriceError = (
  priceToCheck: number,
  priceTouchedToCheck: boolean,
  priceStep?: number
): string | undefined => {
  const { decimalsNumber } = getStepDecimals(priceStep);
  const toInteger = Math.pow(10, decimalsNumber);

  if (priceStep && priceTouchedToCheck) {
    //Проверяем кратность шагу цены
    if (
      //С дробными числами траблы в js, поэтому домножаем до целого
      Math.round(priceToCheck * toInteger) %
        Math.round(priceStep * toInteger) !==
      0
    ) {
      return 'Не кратно шагу';
    }
    //TODO: Должна быть проверка на лимиты, а не в рамках значений стакана
    // else if (priceToCheck < minPrice || priceToCheck > maxPrice) {
    //   return <>Невалидная цена</>;
    // }
  }

  return undefined;
};
