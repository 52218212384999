import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

const MOBILE_NUMBER_LEN = 11;
const PHONE_ERROR_MESSAGE = 'Неправильно введён номер';

const isFieldEmpty = (str: string | null | undefined) => {
  return isEmpty(str) || isNil(str);
};

function isPhoneComplete(phoneNumber: string) {
  return phoneNumber.length !== MOBILE_NUMBER_LEN ? PHONE_ERROR_MESSAGE : '';
}

function isSameDigits(phoneNumber: string) {
  const digitsToCheck = phoneNumber.slice(1);

  return digitsToCheck.split('').every((digit) => digit === digitsToCheck[1])
    ? PHONE_ERROR_MESSAGE
    : '';
}

function isWrongPhoneDigitSequence(phoneNumber: string) {
  const sequences = ['1234567890', '9876543210'];
  const digitsToCheck = phoneNumber.slice(1);

  return sequences.some((sequence) => sequence === digitsToCheck)
    ? PHONE_ERROR_MESSAGE
    : '';
}

function isWrongPhonePrefix(phoneNumber: string) {
  const sequences = ['0', '1', '2', '5'];
  const phonePrefix = phoneNumber[1];

  return sequences.some((prefix) => prefix === phonePrefix)
    ? PHONE_ERROR_MESSAGE
    : '';
}

function minLength(length: number, errorText = 'Используйте кириллицу') {
  return function (str: string) {
    if (isNil(str) || str.length < length) {
      return errorText;
    }

    return '';
  };
}

function hasRussianLetters(str: string) {
  const validRegex = /^(?:[А-яЁё]+-)*[А-яЁё]+-?$/g;

  return validRegex.test(str) ? '' : 'Используйте кириллицу';
}

type ValidatorFn = (data: string) => string;

function wrapValidators(validators: ValidatorFn[]) {
  return function (data: string) {
    for (const validator of validators) {
      const result = validator(data);

      if (result) {
        return result;
      }
    }

    return '';
  };
}

/**
 * @deprecated используйте `validateFullNameLoginForm` из `@terminal/lk-core`
 * */
export function validateFullNameForm(values: {
  firstName: string;
  lastName: string;
  surName?: string;
}) {
  const { firstName, lastName, surName } = values;

  return {
    firstName: wrapValidators([
      minLength(1, 'Напишите имя'),
      hasRussianLetters,
    ])(firstName),
    lastName: wrapValidators([
      minLength(1, 'Напишите фамилию'),
      hasRussianLetters,
    ])(lastName),
    surName: isFieldEmpty(surName)
      ? ''
      : wrapValidators([minLength(1), hasRussianLetters])(surName ?? ''),
  };
}

/**
 * @deprecated используйте `validatePhoneLoginForm` из `@terminal/lk-core`
 * */
export function validatePhoneForm(values: { phone: string }) {
  const { phone } = values;

  return {
    phone: isFieldEmpty(phone)
      ? ''
      : wrapValidators([
          isPhoneComplete,
          isSameDigits,
          isWrongPhoneDigitSequence,
          isWrongPhonePrefix,
        ])(phone),
  };
}
