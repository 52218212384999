import { useQuery } from 'react-query';

import { ObjectGroup } from '../../lib/client/entities';
import { calculateMarginParamsWarranty } from '../../lib/formulas/calculateMarginParamsWarranty';
import { calculateMarketWarranty } from '../../lib/formulas/calculateMarketWarranty';
import { MarginParamsFetcher } from '../../lib/rest/fetchMarginParams';

import { AccountItem } from '../../types/account';
import { TSide } from '../../types/common';
import { FinInfoExt } from '../../types/quotes';

export type WarrantyOptions = {
  finInfoExt?: FinInfoExt;
  /**
   * Направление сделки
   * @default buy
   * */
  side?: TSide;
  /**
   * Группа актива
   * */
  idObjectGroup?: ObjectGroup;
  /**
   * ISIN
   * */
  isin?: string;
  /**
   * Код рынка
   * */
  universalMarketCode?: string;
  /**
   * Признак ЕФР
   * @default false
   * */
  isSingleFORTSMarket?: boolean;
  /**
   * Функция для получения маржинальных параметров
   * */
  marginParamsFetcher?: MarginParamsFetcher<number, string>;
};

/**
 * @returns гарантийное обеспечение
 * */
export function useWarranty(
  idAccount: AccountItem['idAccount'],
  price: number,
  options: WarrantyOptions = {}
): number {
  const {
    side = 'buy',
    finInfoExt,
    idObjectGroup,
    universalMarketCode = '',
    isin = '',
    isSingleFORTSMarket,
    marginParamsFetcher,
  } = options;

  const { data, isError, isIdle } = useQuery(
    ['Account', 'marginParams', idAccount, isin, universalMarketCode],
    () => marginParamsFetcher?.(idAccount, isin, universalMarketCode),
    {
      enabled:
        isSingleFORTSMarket &&
        idObjectGroup === ObjectGroup.Futures &&
        Boolean(marginParamsFetcher),
    }
  );

  if (isIdle || isError) {
    return calculateMarketWarranty(finInfoExt, side);
  }

  if (!data) {
    return 0;
  }

  return calculateMarginParamsWarranty(data, price, side);
}
