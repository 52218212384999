import log from '@terminal/core/lib/loglevel-mobile-debug';
import { BondScreenerTemplate } from '@terminal/core/types/bondScreener';

class BondScreenerTemplatesLS {
  private keyLS = 'bondScreenerTemplates';

  get() {
    const value = localStorage.getItem(this.keyLS);

    if (value) {
      try {
        return JSON.parse(value) as BondScreenerTemplate[];
      } catch (e) {
        log.error('BondScreenerTemplates is not valid object string');

        return [];
      }
    }

    return [];
  }

  set(value: BondScreenerTemplate[]) {
    localStorage.setItem(this.keyLS, JSON.stringify(value));
  }
}

export const bondScreenerTemplatesLS = new BondScreenerTemplatesLS();
