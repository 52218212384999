import { useMutation } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

import { getIiaConvertText } from '../api/getIiaConvertText';

export function useIiaConvertText() {
  const addNotification = useNotification();

  return useMutation(getIiaConvertText, {
    onError: (error: Error) => {
      const defaultError = 'При запросе текста поручения произошла ошибка';
      const serviceError = 'Сервис конвертации недоступен';

      const title = error.message || defaultError;
      const text = title === serviceError ? 'Попробуйте позднее' : undefined;

      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: title,
        text: text,
      });
    },
  });
}
