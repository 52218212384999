import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { GetManagerResponse } from './types/GetManagerResponse';

export async function getManager(): Promise<GetManagerResponse> {
  const res = await fetchLkResult<GetManagerResponse & LKResult>(
    `/api/settings/manager`
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}
