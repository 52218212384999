import cn from 'classnames';
import head from 'lodash/head';
import { memo, useMemo } from 'react';
import { DraggableEventHandler } from 'react-draggable';
import {
  Rnd,
  RndDragCallback,
  RndResizeCallback,
  RndResizeStartCallback,
} from 'react-rnd';
import { IconButton } from '@alfalab/core-components/icon-button';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { PushpinMIcon } from '@alfalab/icons-glyph/PushpinMIcon';
import { PushpinVerticalMIcon } from '@alfalab/icons-glyph/PushpinVerticalMIcon';

import {
  FlexLayoutTabset,
  FlexLayoutWidget,
} from '@terminal/core/types/layout';

import { DRAG_HANDLE_CLASSNAME } from '../../const';
import { WidgetFactory } from '../WidgetFactory';
import { WidgetTab } from '../WidgetTab';

import styles from './WidgetTabset.module.css';

const RESIZE_HANDLE_CLASSES = {
  bottom: styles.resizeHandleBottom,
  top: styles.resizeHandleTop,
  left: styles.resizeHandleLeft,
  right: styles.resizeHandleRight,
  topLeft: styles.resizeHandleTopLeft,
  topRight: styles.resizeHandleTopRight,
  bottomLeft: styles.resizeHandleBottomLeft,
  bottomRight: styles.resizeHandleBottomRight,
};

interface Props extends FlexLayoutTabset {
  activeTabsetId: FlexLayoutTabset['id'];
  onResizeStart: RndResizeStartCallback;
  onResize: RndResizeCallback;
  onResizeStop: RndResizeCallback;
  onDragStart: DraggableEventHandler;
  onDragStop: RndDragCallback;
  onDrag: RndDragCallback;
  onClick: (e: MouseEvent, id: string) => void;
  onCloseNode: (id: string, widget: FlexLayoutWidget) => void;
  onTogglePin: (id: string, pinned: boolean) => void;
}

export const WidgetTabset = memo((props: Props) => {
  const {
    activeTabsetId,
    onResizeStart,
    onResize,
    onResizeStop,
    onDragStart,
    onDragStop,
    onClick,
    onCloseNode,
    onTogglePin,
    onDrag,
    ...tabset
  } = props;
  const { id, children, weight, pinned, position, size } = tabset;

  const visibleWidget = useMemo(
    () => children.find(({ visible }) => Boolean(visible)) ?? head(children)!,
    [children]
  );

  return (
    <Rnd
      key={id}
      id={id}
      bounds="parent"
      position={position}
      size={size}
      className={styles.rndTabset}
      style={{ zIndex: weight }}
      onResizeStart={onResizeStart}
      onResize={onResize}
      onResizeStop={onResizeStop}
      onDragStart={onDragStart}
      onDrag={onDrag}
      onDragStop={onDragStop}
      onMouseDown={(e) => onClick(e, id)}
      dragHandleClassName={DRAG_HANDLE_CLASSNAME}
      minHeight={200}
      minWidth={200}
      resizeHandleClasses={RESIZE_HANDLE_CLASSES}
    >
      <div
        className={cn(styles.tabset, {
          [styles.activeTabset]: activeTabsetId === id,
        })}
      >
        <div className={styles.tabsHeader}>
          {children.map((widget) => (
            <WidgetTab
              key={widget.nodeId}
              {...widget}
              onCloseNode={() => onCloseNode(id, widget)}
            />
          ))}
          <div className={cn(styles.dragHandle, DRAG_HANDLE_CLASSNAME)} />
          <TooltipDesktop
            position="top"
            fallbackPlacements={['bottom-end']}
            offset={[0, 10]}
            contentClassName={styles.tooltipContent}
            popoverClassName={styles.tooltip}
            arrowClassName={styles.tooltipArrow}
            content={
              <Typography.Text view="secondary-small">
                Закрепить поверх всех окон
              </Typography.Text>
            }
          >
            <IconButton
              size="xs"
              view="secondary"
              className={cn(styles.pinButton, {
                [styles.pinnedButton]: pinned || activeTabsetId === id,
              })}
              onMouseDown={(e) => e.stopPropagation()}
              onClick={() => onTogglePin(id, !pinned)}
              icon={
                pinned ? (
                  <PushpinVerticalMIcon
                    width={16}
                    height={16}
                    fill="var(--layout-color-background-accent)"
                  />
                ) : (
                  <PushpinMIcon width={16} height={16} />
                )
              }
            />
          </TooltipDesktop>
        </div>
        <WidgetFactory
          component={visibleWidget.component}
          nodeId={visibleWidget.nodeId}
          visible={visibleWidget.visible}
          config={visibleWidget}
        />
      </div>
    </Rnd>
  );
});

WidgetTabset.displayName = 'WidgetTabset';
