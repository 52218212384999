import { useEffect, useState } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Typography } from '@alfalab/core-components/typography';
import { KeysMIcon } from '@alfalab/icons-glyph/KeysMIcon';
import { PlusMIcon } from '@alfalab/icons-glyph/PlusMIcon';

import { LOCATIONS } from '@terminal/core/constants/analytics';
import { trackMainMenu } from '@terminal/core/lib/analytics';
import { TradingCertificateLock } from '@terminal/widgets';

import { CodeTableList } from '../CodeTableList';

import styles from './CodeTable.module.css';

export const CodeTable = () => {
  const [showTable, setShowTable] = useState(false);

  useEffect(() => {
    trackMainMenu(LOCATIONS.LK_CODES);
  }, []);

  if (showTable) {
    return <CodeTableList />;
  }

  return (
    <TradingCertificateLock fullHeight>
      <div className={styles.root}>
        <div className={styles.icon}>
          <SuperEllipse size={48}>
            <KeysMIcon />
          </SuperEllipse>
        </div>
        <Typography.Text
          view="primary-small"
          color="primary"
          weight="bold"
          className={styles.title}
        >
          Для получения кодов
          <br />
          сформируйте новую таблицу
        </Typography.Text>
        <div>
          <ButtonDesktop
            view="secondary"
            size="xs"
            onClick={() => setShowTable(true)}
            block
            leftAddons={<PlusMIcon />}
          >
            Сформировать
          </ButtonDesktop>
        </div>
      </div>
    </TradingCertificateLock>
  );
};
