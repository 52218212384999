import { format, parseISO } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { Link } from '@alfalab/core-components/link';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';
import { TrashCanLineMIcon } from '@alfalab/icons-glyph/TrashCanLineMIcon';

import { FunctionalModalSidebar } from '@terminal/common/components/FunctionalModal';
import { Portal } from '@terminal/common/components/Portal';
import { DEFAULT_VIEW_DATE_FORMAT } from '@terminal/core/lib/dateToRequestFormat';
import {
  ActiveTransfer,
  SecuritiesContragentRequisites,
  SecuritiesPlaceItem,
  SecuritiesPosition,
  SecuritiesReasonDocument,
  SecuritiesReferenceInfoRequest,
} from '@terminal/core/lib/rest/lkSecurities';

import { Plug } from '../../../../shared';
import { ConfirmModal } from '../../../components/ConfirmModal';
import { EXTERNAL_LINKS } from '../../../const/externalUrls';
import { isIisAccount } from '../../../utils';
import { SecuritiesFaq } from '../../components/SecuritiesFaq';
import { SecurityControlSection } from '../../components/SecurityControlSection';
import { SecurityCounterpartySection } from '../../components/SecurityCounterpartySection';
import { SecurityPlaceSection } from '../../components/SecurityPlaceSection';
import { SecurityReasonSection } from '../../components/SecurityReasonSection';
import { SecurityRequisitesSection } from '../../components/SecurityRequisitesSection';
import { SecuritySection } from '../../components/SecuritySection/';
import { StepFooter } from '../../components/StepFooter';
import { StepProgress } from '../../components/StepProgress';
import {
  SummaryCounterparty,
  SummaryPosition,
  SummaryReason,
  SummarySecurities,
} from '../../components/Summary';
import {
  COUNTERAGENT_TYPES,
  filtSortDocumentTypes,
  isDocumentsRequired,
  isLegalFieldsRequired,
  OTHER_CONTERAGENT,
  SecurityDeponentPlaceCode,
  securityPaperDescription,
  SecurityPlaceCode,
} from '../../shared';
import {
  buildTransferData,
  validateForm,
  validateSecurityStep,
  validateSourceStep,
  validateTargetStep,
} from './helpers';

import { useTreaties } from '../../../hooks';
import {
  useSecuritiesConteragents,
  useSecuritiesCurrencyList,
  useSecuritiesDocumentTypes,
  useSecuritiesManager,
  useSecuritiesPlaceList,
  useSecuritiesPositions,
  useSecuritiesReferenceInfo,
  useSecuritiesTransferIn,
  useSecuritiesTransferPackageIn,
  useSecuritiesUserTypeList,
} from '../../hooks';

import { TransferCompleteParams } from '../../types';

import styles from './SecuritiesIn.module.css';

const enum STEPS {
  Target = 0,
  Source = 1,
  Security = 2,
  Summary = 3,
}

const STEPS_DESCRIPTION = [
  'Шаг 1 из 4: Куда зачисляем',
  'Шаг 2 из 4: Откуда зачисляем',
  'Шаг 3 из 4: Что зачисляем',
  'Шаг 4 из 4: Подача поручения',
];

interface SecuritiesInProps {
  onSuccess?: (params: TransferCompleteParams) => void;
}

export const SecuritiesIn = ({ onSuccess }: SecuritiesInProps) => {
  const [step, setStep] = useState<STEPS>(STEPS.Target);
  const [deleteSecurityIndex, setDeleteSecurityIndex] = useState<number | null>(
    null
  );
  const [openFaq, setOpenFaq] = useState(false);

  const [selectedActives, setSelectedActives] = useState<ActiveTransfer[]>([]);
  const [positionTo, setPositionTo] = useState<SecuritiesPosition | null>(null);
  const [ownershipChange, setOwnershipChange] = useState<boolean>(false);
  const [placeFrom, setPlaceFrom] = useState<SecuritiesPlaceItem | null>(null);
  const [accountType, setAccountType] = useState('OWNER');
  const [counteragentType, setCounteragentType] = useState<COUNTERAGENT_TYPES>(
    COUNTERAGENT_TYPES.NOT_SELECTED
  );
  const [counterpartyFrom, setCounterpartyFrom] = useState('');
  const [counterpartyFromOther, setCounterpartyFromOther] = useState('');
  const [conteragentKey, setConteragentKey] = useState('');
  const [requisiteKey, setRequisiteKey] = useState('');
  const [depoCodeFrom, setDepoCodeFrom] = useState('');
  const [depoAccountFrom, setDepoAccountFrom] = useState('');
  const [depoSectionFrom, setDepoSectionFrom] = useState('');
  const [contragentAccDepoFrom, setContragentAccDepoFrom] = useState('');
  const [contrAlfaBank, setContrAlfaBank] = useState(false);
  const [swift, setSwift] = useState('');
  const [persDocNumber, setPersDocNumber] = useState('');
  const [persDocDate, setPersDocDate] = useState('');
  const [persDocOrg, setPersDocOrg] = useState('');
  const [orgName, setOrgName] = useState('');
  const [orgOGRN, setOrgOGRN] = useState('');
  const [orgWhen, setOrgWhen] = useState('');
  const [orgWhom, setOrgWhom] = useState('');
  const [requisitesCorrect, setRequisitesCorrect] = useState(false);
  const [reasonDocuments, setReasonDocuments] = useState<
    SecuritiesReasonDocument[]
  >([]);
  const [reasonOther, setReasonOther] = useState('');
  const [dealSumm, setDealSumm] = useState<number | null>(0);
  const [dealCurrency, setDealCurrency] = useState('RUB');

  const marketFrom = placeFrom ? placeFrom.placeCode : '';

  const { data: treaties } = useTreaties();
  const { isFetching: positionsPending, data: positions } =
    useSecuritiesPositions();
  const { isFetching: placeListPending, data: placeList } =
    useSecuritiesPlaceList(positionTo?.treaty);
  const { isFetching: documentTypesPending, data: documentTypes } =
    useSecuritiesDocumentTypes();
  const { isFetching: currencyListPending, data: currencyList } =
    useSecuritiesCurrencyList();
  const { isFetching: conteragentsPending, data: conteragents } =
    useSecuritiesConteragents('in');
  const { isFetching: userTypeListPending, data: userTypeList } =
    useSecuritiesUserTypeList();
  const { isFetching: managerPending, data: manager } = useSecuritiesManager(
    positionTo?.treaty
  );

  const [debouncedContragentAccDepoFrom] = useDebounce<string>(
    contragentAccDepoFrom,
    500
  );
  const [debouncedDepoCodeFrom] = useDebounce<string>(depoCodeFrom, 500);
  const referenceInfoParams = useMemo<
    SecuritiesReferenceInfoRequest | undefined
  >(() => {
    if (positionTo && placeFrom && selectedActives.length > 0) {
      return {
        clientAccDepo: positionTo.depoAccount,
        clientDeponetPlaceCode: positionTo.deponetPlaceCode,
        contrDeponetPlaceCode: placeFrom.deponetPlaceCode,
        isTrans: ownershipChange,
        counteragentAccDepo: contrAlfaBank
          ? debouncedContragentAccDepoFrom
          : undefined,
        targetDeponentCode: contrAlfaBank ? undefined : debouncedDepoCodeFrom,
        isin: selectedActives[0].paper.isin,
        transferType: 'in',
        isInternal: contrAlfaBank,
      };
    }
  }, [
    positionTo,
    placeFrom,
    ownershipChange,
    contrAlfaBank,
    debouncedContragentAccDepoFrom,
    debouncedDepoCodeFrom,
    selectedActives,
  ]);

  const { data: referenceInfo } =
    useSecuritiesReferenceInfo(referenceInfoParams);

  const {
    mutateAsync: securitiesTransferIn,
    isLoading: securitiesTransferInPending,
  } = useSecuritiesTransferIn();
  const {
    mutateAsync: securitiesTransferPackageIn,
    isLoading: securitiesTransferPackageInPending,
  } = useSecuritiesTransferPackageIn();

  const userTypeListFiltred = useMemo(() => {
    if (!userTypeList) {
      return;
    }

    const isLegal = isLegalFieldsRequired({
      manager,
      counteragentType,
      ownershipChange,
    });

    const list = userTypeList.filter((type) =>
      isLegal ? true : type.name === 'OWNER'
    );

    return list;
  }, [userTypeList, manager, counteragentType, ownershipChange]);

  // Автовыбор типа счёта
  useEffect(() => {
    if (userTypeListFiltred?.length) {
      setAccountType(userTypeListFiltred[0].name);
    }
  }, [userTypeListFiltred]);

  // При выборе реестра контрагент не может быть в Альфа Банке
  useEffect(() => {
    if (marketFrom === SecurityPlaceCode.REESTR) {
      setContrAlfaBank(false);
    }
  }, [marketFrom]);

  // При смене позиции сбрасываем место хранения на 2-м шаге
  useEffect(() => {
    setPlaceFrom(null);
  }, [positionTo]);

  useEffect(() => {
    if (
      positionTo &&
      positionTo.deponetPlaceCode === SecurityDeponentPlaceCode.EUROCLEAR
    ) {
      // На Euroclear можно зачислять только с переходом права собственности
      setOwnershipChange(true);
      // Переводы Euroclear только внутри банка
      setContrAlfaBank(true);
    }
  }, [positionTo]);

  // При отключении ППС выключаем перевод внутри брокера
  useEffect(() => {
    if (!ownershipChange) {
      setContrAlfaBank(false);
    }
  }, [ownershipChange]);

  const treaty = useMemo(() => {
    if (positionTo && treaties) {
      return treaties.find((t) => t.treaty === positionTo.treaty);
    }

    return;
  }, [treaties, positionTo]);

  const availableDocumentTypes = useMemo(() => {
    if (documentTypes) {
      return filtSortDocumentTypes(documentTypes, ownershipChange);
    }

    return;
  }, [documentTypes, ownershipChange]);

  const availablePlaceList = useMemo(() => {
    if (placeList && documentTypes) {
      // Убираем реестр из списка, если выбран документ не для реестра
      const selectedDocsId = reasonDocuments.map((doc) => doc.id);
      const nonReestrDoc = documentTypes.some(
        (docType) => !docType.forReestr && selectedDocsId.includes(docType.id)
      );

      return placeList.filter(
        (place) => !nonReestrDoc || place.placeCode !== SecurityPlaceCode.REESTR
      );
    }
  }, [placeList, reasonDocuments, documentTypes]);

  // При смене прав собственности сбрасываем выбранные документы и активы
  useEffect(() => {
    setSelectedActives([]);
    setReasonDocuments([]);
  }, [ownershipChange]);

  // При смене места хранения сбрасываем реквизиты контрагента
  useEffect(() => {
    if (placeFrom) {
      setCounterpartyFrom('');
      setCounterpartyFromOther('');
      setDepoCodeFrom('');
      setDepoAccountFrom('');
      setDepoSectionFrom('');
      setContragentAccDepoFrom('');
      setSwift('');
    }
  }, [placeFrom]);

  const counteragentListAvailable = useMemo(() => {
    return (
      conteragents &&
      conteragents.length &&
      placeFrom?.deponetPlaceCode === positionTo?.deponetPlaceCode
    );
  }, [conteragents, placeFrom, positionTo]);

  // Если доступен список контрагентов - нужно чтобы пользователь выбрал
  // Если не доступен - выбираем автоматом "Иной", т.к. выбора будет скрыт
  useEffect(() => {
    if (counteragentListAvailable) {
      setConteragentKey('');
    } else {
      setConteragentKey(OTHER_CONTERAGENT);
    }
  }, [counteragentListAvailable]);

  useEffect(() => {
    // При смене прав собственности данные нужно заполнять самостоятельно
    if (ownershipChange) {
      setPersDocNumber('');
      setPersDocDate('');
      setPersDocOrg('');
      setOrgName('');
      setOrgOGRN('');
      setOrgWhen('');
      setOrgWhom('');
    } else if (manager) {
      setPersDocNumber(manager.managerDocSerNo || '');

      setPersDocDate(
        manager.managerDocDate
          ? format(parseISO(manager.managerDocDate), DEFAULT_VIEW_DATE_FORMAT)
          : ''
      );

      setPersDocOrg(manager.managerDocOrg || '');

      if (manager.requisites) {
        setOrgName(manager.requisites.orgName);
        setOrgOGRN(manager.requisites.ogrn || '');
        setOrgWhen(
          format(parseISO(manager.requisites.regDate), DEFAULT_VIEW_DATE_FORMAT)
        );
        setOrgWhom(manager.requisites.regOrg);
      } else {
        setOrgName('');
        setOrgOGRN('');
        setOrgWhen('');
        setOrgWhom('');
      }
    }
  }, [manager, ownershipChange]);

  const [dealSummRequired, dealSummShow] = useMemo(() => {
    if (!isDocumentsRequired(ownershipChange) || !documentTypes) {
      return [false, false];
    }

    const fileredTypes = documentTypes.filter((type) =>
      reasonDocuments.some((doc) => doc.id === type.id)
    );

    const required = fileredTypes.some((type) => type.isTradeVolumeRequired);

    return [required, required];
  }, [ownershipChange, documentTypes, reasonDocuments]);

  const handlePositionToChange = useCallback(
    (position: SecuritiesPosition | null) => {
      setPositionTo(position);
      setSelectedActives([]);
    },
    []
  );

  const onSaveSecurity = useCallback(
    (security: ActiveTransfer, index?: number | null) => {
      setSelectedActives((prevSecurities) => {
        const securities = [...prevSecurities];

        if ((index || index === 0) && prevSecurities[index]) {
          securities[index] = security;
        } else {
          securities.push(security);
        }

        return securities;
      });
    },
    []
  );

  const onDeleteSecurity = (index: number) => {
    setDeleteSecurityIndex(index);
  };

  const onDeleteSecuritySilent = useCallback((index: number) => {
    setSelectedActives((prevSecurities) => {
      if (!prevSecurities[index]) {
        return prevSecurities;
      }

      const securities = [...prevSecurities];

      securities.splice(index, 1);

      return securities;
    });
  }, []);

  const onDeleteSecurityConfirm = () => {
    if (deleteSecurityIndex !== null) {
      onDeleteSecuritySilent(deleteSecurityIndex);
    }

    setDeleteSecurityIndex(null);
  };

  const onReasonDocumentChange = (
    index: number,
    doc: SecuritiesReasonDocument
  ) => {
    setReasonDocuments((prevDocuments) => {
      let documents = [...prevDocuments];

      documents.splice(index, 1, doc);

      return documents;
    });
  };

  const onAddReasonDocument = (doc: SecuritiesReasonDocument) => {
    setReasonDocuments((documents) => [...documents, doc]);
  };

  const onDeleteReasonDocument = (index: number) => {
    setReasonDocuments((prevDocuments) => {
      let documents = [...prevDocuments];

      documents.splice(index, 1);

      return documents;
    });
  };

  const onConteragentRequisiteChange = useCallback(
    (conteragent: string, requisites: SecuritiesContragentRequisites) => {
      setCounterpartyFrom(conteragent);
      setDepoAccountFrom(requisites.srcContragentAccount);
      setDepoCodeFrom(requisites.srcDeponentCode || '');
      setDepoSectionFrom(requisites.srcDepoAccountPart || '');
    },
    []
  );

  const positionsToList = useMemo(() => {
    if (positions && treaties) {
      return positions.filter((pos) => {
        const treaty = treaties.find((t) => t.treaty === pos.treaty);

        return !isIisAccount(treaty);
      });
    }

    return [];
  }, [positions, treaties]);

  const formReady = validateForm({
    selectedActives,
    positionTo,
    ownershipChange,
    placeFrom,
    marketFrom,
    accountType,
    counterpartyFrom,
    counterpartyFromOther,
    depoCodeFrom,
    depoAccountFrom,
    depoSectionFrom,
    contragentAccDepoFrom,
    contrAlfaBank,
    swift,
    persDocNumber,
    persDocDate,
    persDocOrg,
    orgName,
    orgOGRN,
    orgWhen,
    orgWhom,
    requisitesCorrect,
    reasonDocuments,
    dealSumm,
    dealCurrency,

    manager,
    counteragentType,
    availableDocumentTypes,
    dealSummRequired,
  });

  const sendTransferRequest = () => {
    if (!formReady) {
      return;
    }

    if (!positions || !placeList || !treaty) {
      return;
    }

    const target = positionTo;
    const source = placeList.find((place) => place.placeCode === marketFrom);
    const userType = userTypeList
      ? userTypeList.find((type) => type.name === accountType)
      : undefined;

    if (!target || !source) {
      return;
    }

    const data = buildTransferData({
      selectedActives,
      positionTo,
      ownershipChange,
      marketFrom,
      accountType,
      counterpartyFrom,
      counterpartyFromOther,
      depoCodeFrom,
      depoAccountFrom,
      depoSectionFrom,
      contragentAccDepoFrom,
      contrAlfaBank,
      swift,
      persDocNumber,
      persDocDate,
      persDocOrg,
      orgName,
      orgOGRN,
      orgWhen,
      orgWhom,
      reasonDocuments,
      reasonOther,
      dealSumm,
      dealCurrency,

      treaty: treaty.treaty,
      target,
      source,
      userType,
      manager,
      counteragentType,
      dealSummShow,
    });

    if (data.length === 1) {
      securitiesTransferIn({
        params: data[0],
        signOptions: {
          successTitle: `Поручение принято к исполнению`,
          errorTitle: 'Ошибка подачи поручения',
          successCallback: (status) =>
            onSuccess &&
            onSuccess({
              type: 'success',
              counteragent: counterpartyFrom,
              status: status?.data?.orderStatus,
            }),
        },
      });
    } else {
      securitiesTransferPackageIn({
        params: data,
        signOptions: {
          successTitle: `Поручение принято к исполнению`,
          errorTitle: 'Ошибка подачи поручения',
          successCallback: (status) =>
            onSuccess &&
            onSuccess({
              type: 'success',
              counteragent: counterpartyFrom,
              status: status?.data?.orderStatus,
              multiple: true,
            }),
        },
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.progress}>
        <StepProgress
          percentage={Math.floor((step / STEPS_DESCRIPTION.length) * 100)}
          title={STEPS_DESCRIPTION[step]}
        />
      </div>
      <Typography.Text
        view="secondary-small"
        tag="p"
        color="secondary"
        defaultMargins={false}
        className={styles.faq}
      >
        Зачисление ценных бумаг в Go Invest бесплатное.{' '}
        <Link onClick={() => setOpenFaq(true)}>
          Подробнее о процессе перевода.
        </Link>
      </Typography.Text>
      {(() => {
        switch (step) {
          case STEPS.Target: {
            const stepReady = validateTargetStep({
              positionTo,
              ownershipChange,
              reasonDocuments,
              dealSumm,
              dealCurrency,
              availableDocumentTypes,
              dealSummRequired,
            });

            const isTreatyOk = !(treaty?.isRepo || treaty?.isForeignMarket);

            return (
              <>
                <Space fullWidth direction="vertical" size={20}>
                  <SecurityPlaceSection
                    title="Куда"
                    positions={positionsToList}
                    positionsPending={positionsPending}
                    selectedPosition={positionTo}
                    onPositionSelect={handlePositionToChange}
                  />
                  {treaty?.isRepo && (
                    <Plug
                      title="Перевод ценных бумаг на счёт РЕПО возможен только через произвольное поручение"
                      text={
                        <>
                          Обратитесь к своему менеджеру, чтобы получить
                          <br />
                          детальную информацию
                        </>
                      }
                    />
                  )}
                  {treaty?.isForeignMarket && (
                    <Plug
                      title={
                        <>
                          Переводы ценных бумаг на счёте
                          <br /> Иностранный рынок недоступны
                        </>
                      }
                    />
                  )}
                  {isTreatyOk && (
                    <>
                      <SecurityReasonSection
                        ownershipChange={ownershipChange}
                        ownershipDisabled={Boolean(
                          positionTo &&
                            positionTo.deponetPlaceCode ===
                              SecurityDeponentPlaceCode.EUROCLEAR
                        )}
                        onOwnershipChange={(ownership) =>
                          setOwnershipChange(ownership)
                        }
                        reasonDocuments={reasonDocuments}
                        onReasonDocumentChange={onReasonDocumentChange}
                        onAddReasonDocument={onAddReasonDocument}
                        onDeleteReasonDocument={onDeleteReasonDocument}
                        reasonOther={reasonOther}
                        documentTypes={availableDocumentTypes}
                        documentTypesPending={documentTypesPending}
                        onReasonOtherChange={(reason) => setReasonOther(reason)}
                        operationDirection="in"
                      />
                      <SecurityControlSection
                        dealSummRequired={dealSummRequired}
                        dealSummShow={dealSummShow}
                        currencyList={currencyList}
                        currencyListPending={currencyListPending}
                        dealSumm={dealSumm}
                        onDealSummChange={(summ) => setDealSumm(summ)}
                        dealCurrency={dealCurrency}
                        onDealCurrencyChange={(currency) =>
                          setDealCurrency(currency)
                        }
                      />
                      <StepFooter
                        next={{
                          action: () => setStep(STEPS.Source),
                          disabled: !stepReady,
                        }}
                      />
                    </>
                  )}
                </Space>
              </>
            );
          }
          case STEPS.Source: {
            const stepReady = validateSourceStep({
              positionTo,
              placeFrom,
              marketFrom,
              accountType,
              counterpartyFrom,
              counterpartyFromOther,
              depoCodeFrom,
              depoAccountFrom,
              depoSectionFrom,
              contragentAccDepoFrom,
              contrAlfaBank,
              swift,
              persDocNumber,
              persDocDate,
              persDocOrg,
              orgName,
              orgOGRN,
              orgWhen,
              orgWhom,
              requisitesCorrect,
              manager,
              counteragentType,
              ownershipChange,
            });

            return (
              <Space fullWidth direction="vertical">
                <SecurityCounterpartySection
                  transferType="in"
                  positions={positions}
                  placeList={availablePlaceList}
                  placeListPending={placeListPending}
                  ownershipChange={ownershipChange}
                  userTypeList={userTypeListFiltred}
                  userTypeListPending={userTypeListPending}
                  conteragents={conteragents}
                  conteragentsPending={conteragentsPending}
                  onConteragentRequisiteChange={onConteragentRequisiteChange}
                  postionAlfa={positionTo}
                  selectedCounterpartyPlace={placeFrom}
                  onSelectedCounterpartyPlaceChange={setPlaceFrom}
                  userType={accountType}
                  onUserTypeChange={setAccountType}
                  counteragentType={counteragentType}
                  onCounteragentTypeChange={setCounteragentType}
                  depoAccount={depoAccountFrom}
                  onDepoAccountChange={setDepoAccountFrom}
                  depoSection={depoSectionFrom}
                  onDepoSectionChange={setDepoSectionFrom}
                  depoCode={depoCodeFrom}
                  onDepoCodeChange={setDepoCodeFrom}
                  counterpartyPlace={counterpartyFrom}
                  onCounterpartyPlaceChange={setCounterpartyFrom}
                  counterpartyPlaceOther={counterpartyFromOther}
                  onCounterpartyPlaceOtherChange={setCounterpartyFromOther}
                  conteragentAccDepo={contragentAccDepoFrom}
                  onConteragentAccDepoChange={setContragentAccDepoFrom}
                  counteragentAlfaBank={contrAlfaBank}
                  onCounteragentAlfaBankChange={setContrAlfaBank}
                  swift={swift}
                  onSwiftChange={setSwift}
                  selectedConteragentKey={conteragentKey}
                  onConteragentKeyChange={setConteragentKey}
                  selectedRequisiteKey={requisiteKey}
                  onRequisiteKeyChange={setRequisiteKey}
                  requisitesSection={
                    <SecurityRequisitesSection
                      accountType={accountType}
                      counteragentType={counteragentType}
                      ownershipChange={ownershipChange}
                      market={marketFrom}
                      manager={manager}
                      managerPending={managerPending}
                      orgName={orgName}
                      onOrgNameChange={setOrgName}
                      orgOGRN={orgOGRN}
                      onOrgOGRNChange={setOrgOGRN}
                      orgWhen={orgWhen}
                      onOrgWhenChange={setOrgWhen}
                      orgWhom={orgWhom}
                      onOrgWhomChange={setOrgWhom}
                      docNumber={persDocNumber}
                      onDocNumberChange={setPersDocNumber}
                      docDate={persDocDate}
                      onDocDateChange={setPersDocDate}
                      docWhom={persDocOrg}
                      onDocWhomChange={setPersDocOrg}
                      requisitesCorrect={requisitesCorrect}
                      onRequisitesCorrectChange={setRequisitesCorrect}
                    />
                  }
                />
                <StepFooter
                  next={{
                    action: () => setStep(STEPS.Security),
                    disabled: !stepReady,
                  }}
                  prev={{
                    action: () => setStep(STEPS.Target),
                  }}
                />
              </Space>
            );
          }
          case STEPS.Security: {
            const stepReady = validateSecurityStep({
              selectedActives,
              marketFrom,
            });

            return (
              <Space fullWidth direction="vertical">
                <SecuritySection
                  type="in"
                  actives={selectedActives}
                  alfaMarket={positionTo ? positionTo.placeCode : ''}
                  counterpartyMarket={marketFrom}
                  contrAlfaBank={contrAlfaBank}
                  multiply={false}
                  onSaveSecurity={onSaveSecurity}
                  onDeleteSecurity={onDeleteSecurity}
                  onDeleteSecuritySilent={onDeleteSecuritySilent}
                  dealParams={true}
                  generatedReference={referenceInfo?.reference || ''}
                />
                <StepFooter
                  next={{
                    action: () => setStep(STEPS.Summary),
                    disabled: !stepReady,
                  }}
                  prev={{
                    action: () => setStep(STEPS.Source),
                  }}
                />
              </Space>
            );
          }
          case STEPS.Summary: {
            return (
              <Space fullWidth direction="vertical">
                <SummaryPosition
                  position={positionTo}
                  clientReferenceInfo={
                    referenceInfo ? referenceInfo.clientRequisites : undefined
                  }
                  counteragentAlfaBank={contrAlfaBank}
                  title="Куда"
                />
                <SummaryCounterparty
                  selectedCounterpartyPlace={placeFrom}
                  userTypeList={userTypeList}
                  userTypeListPending={userTypeListPending}
                  marketAlfa={positionTo ? positionTo.placeCode : ''}
                  accountType={accountType}
                  counteragentType={counteragentType}
                  depoAccount={depoAccountFrom}
                  depoSection={depoSectionFrom}
                  depoCode={depoCodeFrom}
                  counterpartyPlace={counterpartyFrom}
                  counterpartyPlaceOther={counterpartyFromOther}
                  conteragentAccDepo={contragentAccDepoFrom}
                  counteragentAlfaBank={contrAlfaBank}
                  counteragentReferenceInfo={
                    referenceInfo
                      ? referenceInfo.counteragentRequisites
                      : undefined
                  }
                  swift={swift}
                  ownershipChange={ownershipChange}
                  manager={manager}
                  docNumber={persDocNumber}
                  docDate={persDocDate}
                  docWhom={persDocOrg}
                  orgName={orgName}
                  orgOGRN={orgOGRN}
                  orgWhen={orgWhen}
                  orgWhom={orgWhom}
                />
                <SummarySecurities selectedActives={selectedActives} />
                <SummaryReason
                  documentTypes={documentTypes}
                  reasonDocuments={reasonDocuments}
                  ownershipChange={ownershipChange}
                  reasonOther={reasonOther}
                  referenceInfo={referenceInfo}
                  comissionMultiplier={selectedActives.length}
                  operationDirection="in"
                />
                <StepFooter
                  next={{
                    disabled: !formReady,
                    action: sendTransferRequest,
                    loading:
                      securitiesTransferInPending ||
                      securitiesTransferPackageInPending,
                    label: 'Подать поручение',
                    hint: (
                      <Typography.Text
                        tag="p"
                        view="secondary-small"
                        color="secondary"
                        defaultMargins={false}
                        className={styles.agree}
                      >
                        Продолжая, я подтверждаю, что ознакомлен с&nbsp;
                        <Link
                          view="secondary"
                          href={EXTERNAL_LINKS.SECURITIES_DEPO_TARIFFS}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          депозитарными тарифами
                        </Link>
                      </Typography.Text>
                    ),
                  }}
                  prev={{
                    action: () => setStep(STEPS.Security),
                  }}
                />
              </Space>
            );
          }
        }
      })()}

      <ConfirmModal
        open={
          deleteSecurityIndex !== null &&
          selectedActives[deleteSecurityIndex] !== undefined
        }
        title="Удалить ценную бумагу"
        text={
          <>
            Вы уверены, что хотите удалить ценную бумагу{' '}
            <span className={styles.nowrap}>
              {deleteSecurityIndex !== null &&
                Boolean(selectedActives[deleteSecurityIndex]) &&
                securityPaperDescription(
                  selectedActives[deleteSecurityIndex].paper
                )}
              ?
            </span>
          </>
        }
        confirmLabel="Удалить"
        confirmIcon={<TrashCanLineMIcon width={18} height={18} />}
        onClose={() => setDeleteSecurityIndex(null)}
        onConfirm={onDeleteSecurityConfirm}
      />

      <Portal id="securities-transfer-modals">
        <FunctionalModalSidebar
          open={openFaq}
          backLabel="Зачисление"
          onClose={() => setOpenFaq(false)}
        >
          <SecuritiesFaq />
        </FunctionalModalSidebar>
      </Portal>
    </div>
  );
};
