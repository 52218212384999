import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

/**
 * Возвращает id аккаунта переданный в query string
 * @returns id аккаунта
 */
export const useSelectedAccountId = (param: string = 'to') => {
  const [params] = useSearchParams();

  return useMemo(() => {
    const value = params.get(param);

    if (!value) {
      return;
    }

    const result = parseInt(value);

    if (!isNaN(result)) {
      return result;
    }
  }, [params, param]);
};
