import isNull from 'lodash/isNull';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';

import { BrokerageFeeService } from './brokerageFee';

import { AccountItem } from '../../types/account';

//Хук для получения комиссии в компоненте
export function useBrokerageFee(
  idFi: number,
  price: number,
  quantity: number,
  account?: AccountItem
) {
  const brokerageFeeService = useRef<BrokerageFeeService | null>(null);
  const [fee, setFee] = useState<number | undefined>();

  const { quotesService } = useAlfaDirectContext();

  const getBrokerageFeeService = useCallback(() => {
    if (isNull(brokerageFeeService.current)) {
      brokerageFeeService.current = new BrokerageFeeService(
        idFi,
        quotesService,
        price,
        quantity,
        undefined,
        account
      );
    }

    return brokerageFeeService.current;
  }, [account, idFi, price, quantity, quotesService]);

  useEffect(() => {
    setFee(undefined);
  }, [idFi]);

  useEffect(() => {
    const [quoteUnsubscribeData, extUnsubscribeData] =
      getBrokerageFeeService().subscribe(idFi);

    return () =>
      getBrokerageFeeService().unsubscribe(
        quoteUnsubscribeData,
        extUnsubscribeData
      );
  }, [getBrokerageFeeService, idFi]);

  useEffect(() => {
    const updateFee = (fee?: number) => setFee(fee);

    getBrokerageFeeService().on('updateFee', updateFee);
  }, [getBrokerageFeeService]);

  useEffect(() => {
    getBrokerageFeeService().setPrice(price);
  }, [getBrokerageFeeService, price]);

  useEffect(() => {
    getBrokerageFeeService().setQuantity(quantity);
  }, [getBrokerageFeeService, quantity]);

  useEffect(() => {
    getBrokerageFeeService().setAccount(account);
  }, [getBrokerageFeeService, account]);

  useEffect(
    () => () => {
      getBrokerageFeeService().removeAllListeners();
    },
    [getBrokerageFeeService]
  );

  return fee;
}
