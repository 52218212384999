import { addDays, addYears, format, getTime, startOfDay } from 'date-fns';
import { Form, FormikConfig, FormikProvider, useFormik } from 'formik';
import { Input } from 'formik-alfalab-core-components/Input';
import { MaskedInput } from 'formik-alfalab-core-components/MaskedInput';
import { Select } from 'formik-alfalab-core-components/Select';
import { FC } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { CalendarDesktop } from '@alfalab/core-components/calendar/desktop';
import { Divider } from '@alfalab/core-components/divider';
import { Grid } from '@alfalab/core-components/grid';
import { Link } from '@alfalab/core-components/link';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';
import { PenNibMIcon } from '@alfalab/icons-glyph/PenNibMIcon';

import { UniversalDateInput } from '@terminal/lk/shared';
import { SubAccount } from '@terminal/lk-core';

import { AccessLevelInfo } from '../../../../entities/AccountsOperations';
import { IDENTITY_DOCUMENT_NUMBER_MASK } from '../../lib/const/identityDocumentNumberMask';
import { IDENTITY_DOCUMENT_TYPE_MAP } from '../../lib/const/mappings';
import { validate } from './validators';

import { CreateAttorneyFormValues } from '../../model/types/CreateAttorneyFormValues';
import { IdentityDocumentType } from '../../model/types/IdentityDocumentType';

import styles from './AttorneyForm.module.css';

const IDENTITY_DOCUMENT_TYPES_OPTIONS = Object.entries(
  IDENTITY_DOCUMENT_TYPE_MAP
).map(([key, content]) => ({
  key,
  value: key,
  content,
}));

interface AttorneyFormProps {
  treaty: number;
  onAccessLevelsClick: () => void;
  subAccounts: SubAccount[];
  onSubmit: FormikConfig<CreateAttorneyFormValues>['onSubmit'];
  disabled?: boolean;
  withSubAccountSelect?: boolean;
  accessLevels: AccessLevelInfo[];
}

export const AttorneyForm: FC<AttorneyFormProps> = ({
  treaty,
  onAccessLevelsClick,
  subAccounts,
  onSubmit,
  disabled,
  withSubAccountSelect = false,
  accessLevels,
}) => {
  const subAccountsOptions = subAccounts.map((subAccount) => ({
    key: subAccount.accCode,
    value: subAccount.accCode,
    content: subAccount.accCode,
  }));

  const accessLevelsOptions = accessLevels.map((level) => ({
    key: level.id,
    value: level.id,
    content: level.name,
  }));

  const initialSubAccountValue = subAccounts.find((account) =>
    account.accCode.includes('-000')
  )?.accCode;

  const initialEndDate = format(addYears(new Date(), 1), 'dd.MM.yyyy');

  const form = useFormik<CreateAttorneyFormValues>({
    initialValues: {
      fullName: '',
      login: '',
      identityDocumentType: '',
      identityDocumentNumber: '',
      accessLevel: '',
      endDate: initialEndDate,
      subAccount: initialSubAccountValue ?? '',
    },
    onSubmit,
    validate,
  });

  return (
    <>
      <Typography.Text
        view="primary-small"
        weight="bold"
        className={styles.title}
      >
        Оформление доверенности на управление счётом {treaty}
      </Typography.Text>
      <FormikProvider value={form}>
        <Form>
          <Grid.Row gutter={16} className={styles.fields}>
            <Grid.Col width="6">
              <Input
                name="fullName"
                label="Ф.И.О. уполномоченного лица"
                placeholder="Введите Ф.И.О."
                labelView="outer"
                block
                size="s"
              />
            </Grid.Col>
            <Grid.Col width="6">
              <Input
                name="login"
                label={
                  <>
                    Логин уполномоченного лица{' '}
                    <TooltipDesktop
                      targetClassName={styles.target}
                      position="top"
                      trigger="hover"
                      fallbackPlacements={['left', 'right']}
                      content="Логин лица, которому выдаются права на управление счетом. Узнать логин можно в главном меню терминала"
                    >
                      <InformationCircleLineSIcon className={styles.icon} />
                    </TooltipDesktop>
                  </>
                }
                placeholder="Введите логин"
                labelView="outer"
                block
                size="s"
              />
            </Grid.Col>
            <Grid.Col width="6">
              <Select
                name="identityDocumentType"
                type="select"
                label="Документ уполномоченного лица"
                placeholder="Выберите документ"
                labelView="outer"
                block
                size="s"
                options={IDENTITY_DOCUMENT_TYPES_OPTIONS}
              />
            </Grid.Col>
            <Grid.Col width="6">
              <MaskedInput
                mask={
                  IDENTITY_DOCUMENT_NUMBER_MASK[
                    Number(
                      form.values.identityDocumentType
                    ) as IdentityDocumentType
                  ]
                }
                name="identityDocumentNumber"
                label="Серия и номер документа"
                placeholder={
                  Number(form.values.identityDocumentType) ===
                  IdentityDocumentType.USSR_PASSPORT
                    ? 'XI-АА 123456'
                    : 'Введите серию и номер'
                }
                labelView="outer"
                block
                size="s"
              />
            </Grid.Col>
            {withSubAccountSelect && (
              <Grid.Col width="6">
                <Select
                  name="subAccount"
                  type="select"
                  label="Субсчёт, к которому предоставляется доступ"
                  placeholder="Выберите субсчёт"
                  labelView="outer"
                  block
                  options={subAccountsOptions}
                  size="s"
                />
              </Grid.Col>
            )}
            <Grid.Col width="6">
              <UniversalDateInput
                name="endDate"
                label="Срок действия доверенности"
                placeholder="Выберите дату"
                labelView="outer"
                block
                size="s"
                minDate={getTime(startOfDay(addDays(new Date(), 1)))}
                view="date"
                picker
                Calendar={CalendarDesktop}
                calendarProps={{
                  selectorView: 'full',
                }}
              />
            </Grid.Col>
            <Grid.Col width="6">
              <Select
                name="accessLevel"
                type="select"
                label="Уровень доступа"
                placeholder="Выберите уровень доступа"
                labelView="outer"
                block
                options={accessLevelsOptions}
                size="s"
                hint={
                  <Typography.Text view="secondary-small">
                    <Link onClick={onAccessLevelsClick} view="secondary">
                      Подробнее об уровнях доступа
                    </Link>
                  </Typography.Text>
                }
              />
            </Grid.Col>
          </Grid.Row>
          <Divider className={styles.divider} />
          <Grid.Row gutter={16}>
            <Grid.Col width="6">
              <ButtonDesktop
                size="xs"
                block
                leftAddons={<PenNibMIcon width={18} height={18} />}
                loading={form.isSubmitting}
                disabled={!(form.isValid && form.dirty) || disabled}
                type="submit"
              >
                Подписать
              </ButtonDesktop>
            </Grid.Col>
            <Grid.Col width="6">
              <div />
            </Grid.Col>
          </Grid.Row>
        </Form>
      </FormikProvider>
    </>
  );
};
