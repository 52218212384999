import sumBy from 'lodash/sumBy';
import React, { useMemo, useState } from 'react';
import { useMount } from 'react-use';
import { Gap } from '@alfalab/core-components/gap';
import { TagDesktop } from '@alfalab/core-components/tag/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { ChartPieLineMIcon } from '@alfalab/icons-glyph/ChartPieLineMIcon';
import { formatAmount } from '@alfalab/utils';

import {
  useAlfaDirectContext,
  useAllSubAccountsIds,
} from '@terminal/alfadirect/hooks';
import { currenciesFI } from '@terminal/core/constants/FIModal';
import { NAME_BY_OBJECT_GROUP } from '@terminal/core/constants/objectGroupNames';
import { MINORITY } from '@terminal/core/constants/ui';
import {
  useBalance,
  useIdFiBalances,
  useOrders,
  usePosition,
  usePositions,
  useSubAccountRazdel,
} from '@terminal/core/hooks/domain';
import { useSingleFORTSMarket } from '@terminal/core/hooks/useSingleFORTSMarket';
import { ObjectGroup, OrderStatus } from '@terminal/core/lib/client/entities';
import { getSymbolByCurrency } from '@terminal/core/lib/currencies';
import { makeOrderKey } from '@terminal/core/lib/domain/makeOrderKey';
import { roundPrice } from '@terminal/core/lib/format';
import { Sector } from '@terminal/core/lib/rest/industriesApi';
import { OrderItem } from '@terminal/core/types/order';

import {
  CompositionTabsNameMap,
  CompositionType,
  usePortfolioCompositionMetrics,
} from '../../../../entities/PortfolioComposition';
import {
  PortfolioAnalyticsWarningContainer,
  Spinner,
} from '../../../../shared';
import { ASSETS_COLORS_MAPPED, CustomAssetId } from '../../lib/const';
import { getDerivativesMarketCurrency } from '../../lib/getDerivativesMarketCurrency';
import { PortfolioAssetsTable } from '../PortfolioAssetsTable';
import { PortfolioHistogram } from '../PortfolioHistogram';

import { useGroupedAssets } from '../../hooks/useGroupedAssets';

import { Asset } from '../../model/types/PortfolioComposition';

import styles from './PortfolioComposition.module.css';

interface Props {
  selectedAccountId: number;
}

export const PortfolioComposition = ({ selectedAccountId }: Props) => {
  const {
    trackClickPortfolioCompositionAssetChip,
    trackOpenPortfolioComposition,
  } = usePortfolioCompositionMetrics();

  useMount(trackOpenPortfolioComposition);

  const [activeTab, setActiveTab] = useState<CompositionType>(
    CompositionType.ASSETS
  );

  const handleChangeTab = (type: CompositionType) => {
    setActiveTab(type);
    trackClickPortfolioCompositionAssetChip(type);
  };

  const {
    accounts: clientAccounts,
    subGTAccounts,
    useQuotes,
    useFinInfoExt,
    useIndustries,
  } = useAlfaDirectContext();
  const allPositions = usePositions();
  const currenciesQuotes = useQuotes(currenciesFI);
  const currenciesFinInfoExts = useFinInfoExt(currenciesFI);

  const positionsToIdFiBalances = useIdFiBalances(allPositions);
  const fi = useMemo(
    () => Array.from(new Set(positionsToIdFiBalances.values())),
    [positionsToIdFiBalances]
  );
  const finInfoExts = useFinInfoExt(fi);
  const quotes = useQuotes(fi);
  const orders = useOrders();

  const selectedSubAccounts = useAllSubAccountsIds(selectedAccountId);

  const filteredOrders = useMemo(() => {
    return orders.filter(
      (order) =>
        //clientAccounts - счета пользователя, но без Инвесткопилок
        clientAccounts.some(
          (account) => account.idAccount === order.idAccount
        ) &&
        //Также отсеиваем заявки, которые не в статусе Активные и Ожидают, они не учавствуют в рассчетах
        ![
          OrderStatus.Filled,
          OrderStatus.Cancelled,
          OrderStatus.Rejected,
        ].includes(order.idOrderStatus)
    );
  }, [clientAccounts, orders]);

  const ordersMap = useMemo(() => {
    return filteredOrders.reduce((acc, order) => {
      const key = makeOrderKey(order, order.buySell);

      if (!acc[key]) {
        acc[key] = [];
      }

      acc[key].push(order);

      return acc;
    }, {} as Record<string, OrderItem[]>);
  }, [filteredOrders]);

  const subAccountRazdels = useSubAccountRazdel();

  const computedPositions = usePosition(
    {
      positions: allPositions,
      finInfoExts,
      subAccountRazdels,
      ordersMap,
      currenciesQuotes,
      currenciesFinInfoExts,
      quotes,
      positionsToIdFiBalances,
    },
    { selectedSubAccounts }
  );

  const filteredPositions = useMemo(
    () => computedPositions.filter((position) => position.torgPos !== 0),
    [computedPositions]
  );

  const positions = filteredPositions;

  const isSingleFORTSMarketAvailable = useSingleFORTSMarket();

  const getGroupedAssets = useGroupedAssets(
    filteredPositions,
    isSingleFORTSMarketAvailable
  );

  const stocksIsins: string[] = useMemo(() => {
    return filteredPositions
      .filter(
        (position) =>
          // (position.objectType.idObjectGroup === ObjectGroup.Stocks ||
          //   position.objectType.idObjectGroup === ObjectGroup.Receipts) &&
          position.object?.isin
      )
      .map((position) => position.object?.isin ?? '');
  }, [filteredPositions]);

  const { isFetching, data } = useIndustries(
    stocksIsins,
    activeTab === CompositionType.INDUSTRIES
  );

  const industries = useMemo(() => {
    const result = new Map<string, Asset>();

    if (!data) {
      return result;
    }

    const isinsSectors: Record<string, Sector> = {};

    for (let sector of data.sectors) {
      sector.isins.forEach((isin) => {
        isinsSectors[isin] = sector;
      });
    }

    for (let isin of stocksIsins) {
      const position = filteredPositions.find(
        (item) => item.object?.isin === isin
      );

      if (!position?.object) {
        continue;
      }

      let sectorId: string;
      let sectorName: string;

      if (isinsSectors[isin]) {
        sectorId = isinsSectors[isin].sectorId.toString();
        sectorName = isinsSectors[isin].sector;
      } else {
        const isCurrency = [ObjectGroup.Currency, ObjectGroup.Metals].includes(
          position.objectType.idObjectGroup
        );

        if (isCurrency) {
          sectorId = CustomAssetId.CURRENCY;
          sectorName = 'Валюта и металлы';
        } else {
          sectorId = CustomAssetId.OTHER;
          sectorName = 'Прочее';
        }
      }

      if (!result.has(sectorId)) {
        result.set(sectorId, {
          id: position.object!.idObject,
          assetId: sectorId,
          name: sectorName,
          price: 0,
          percent: 0,
          isins: [],
        });
      }

      result.get(sectorId)!.price += position.torgPosCostRur || 0;
      position.object.isin &&
        result.get(sectorId)!.isins?.push(position.object.isin);
    }

    return result;
  }, [data, filteredPositions, stocksIsins]);

  const assets: Asset[] = useMemo(() => {
    let list: Asset[];

    switch (activeTab) {
      case CompositionType.ASSETS: {
        let groupedAssets = getGroupedAssets();

        const derivativesCurrency =
          getDerivativesMarketCurrency(filteredPositions);

        groupedAssets = groupedAssets.map((asset) => {
          if (asset.id === ObjectGroup.Futures) {
            asset.price += derivativesCurrency?.balance || 0;
          }

          return asset;
        });

        const hasNoFutures = !groupedAssets.some(
          (asset) => asset.id === ObjectGroup.Futures
        );

        const hasDerivativesCurrencies =
          derivativesCurrency &&
          derivativesCurrency.currencyPositions.length > 0;

        if (hasDerivativesCurrencies && hasNoFutures) {
          groupedAssets.push({
            id: ObjectGroup.Futures,
            assetId: ObjectGroup.Futures.toString(),
            name: NAME_BY_OBJECT_GROUP.get(ObjectGroup.Futures)!.toString(),
            price: derivativesCurrency.balance!,
            percent: 0,
          });
        }

        list = groupedAssets;
        break;
      }

      case CompositionType.CURRENCIES: {
        list = getGroupedAssets(ObjectGroup.Currency.toString());

        break;
      }

      case CompositionType.INDUSTRIES: {
        list = Array.from(industries.values());
        break;
      }

      default:
        list = [];
    }

    const sum = sumBy(list, (i) => i.price);

    list.forEach((item) => {
      item.percent = roundPrice(((item.price || 0) * 100) / sum, 0.01);
    });

    return list
      .sort((a, b) => b.price - a.price)
      .map((asset, index) => ({
        ...asset,
        color: ASSETS_COLORS_MAPPED.get(index + 1),
      }));
  }, [activeTab, getGroupedAssets, filteredPositions, industries]);

  const isNoData = assets.length === 0;

  const accountBalances = useBalance(
    {
      positions,
      currenciesQuotes,
      currenciesFinInfoExts,
      subGTAccounts,
    },
    { selectedSubAccounts }
  );
  const accountBalance = accountBalances[0]?.balance || 0;

  const formattedAccountBalance = formatAmount({
    value: accountBalance * MINORITY,
    currency: 'RUB',
    minority: MINORITY,
    view: 'withZeroMinorPart',
  }).formatted;

  return (
    <div className={styles.container}>
      <div className={styles.filtersWrapper}>
        {[
          CompositionType.ASSETS,
          CompositionType.CURRENCIES,
          CompositionType.INDUSTRIES,
        ].map((type) => (
          <TagDesktop
            key={type}
            size="xs"
            view="filled"
            checked={type === activeTab}
            onClick={() => handleChangeTab(type)}
            className={styles.tag}
          >
            {CompositionTabsNameMap[type]}
          </TagDesktop>
        ))}
      </div>
      <Gap size="xl" />
      {!isFetching ? (
        <Typography.Title tag="div" view="small" color="primary" weight="bold">
          {formattedAccountBalance}&nbsp;
          <span className={styles.symbol}>{getSymbolByCurrency('RUB')}</span>
        </Typography.Title>
      ) : null}

      {isFetching ? <Spinner /> : null}

      {isNoData && !isFetching ? (
        <PortfolioAnalyticsWarningContainer
          Icon={ChartPieLineMIcon}
          title="Тут ничего нет"
          description="На вашем счёте нет активов"
        />
      ) : null}

      <Gap size="l" />
      <PortfolioHistogram assets={assets} isFetching={isFetching} />
      <Gap size="l" />
      <PortfolioAssetsTable
        activeTab={activeTab}
        assets={assets}
        positions={positions}
        industries={industries}
        isFetching={isFetching}
      />
    </div>
  );
};
