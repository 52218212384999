import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ButtonDesktop as Button } from '@alfalab/core-components/button/desktop';
import { TagDesktop as Tag } from '@alfalab/core-components/tag/desktop/Component.desktop';
import { TooltipDesktop as Tooltip } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { CrossCircleMIcon } from '@alfalab/icons-glyph/CrossCircleMIcon';
import HeartMIcon from '@alfalab/icons-glyph/HeartMIcon';
import PlusCompactMIcon from '@alfalab/icons-glyph/PlusCompactMIcon';
import SlidersCompactMIcon from '@alfalab/icons-glyph/SlidersCompactMIcon';

import { DropdownMenu } from '@terminal/common/components/DropdownMenu';
import { trackBondScreenerWidget } from '@terminal/core/lib/analytics/bondScreener/bondScreener';
import { Filter, FilterTypes } from '@terminal/core/types/layout';
import { TableColumnKey } from '@terminal/core/types/tableColumn';

import { ColumnFilter, useWidgetContext } from '../../../../shared';
import {
  getChipsTextByKey,
  getFullNameByKey,
  getSettingsByKey,
} from '../../helpers';
import { defaultFilters, defaultFiltersVisible } from '../../model/consts';

import { useMenuGroups } from '../../hooks/useMenuGroups';

import styles from './BondScreenerFilters.module.css';

interface Props {
  filters: Record<string, Filter | undefined>;
  setFilters: (filters: Record<string, Filter | undefined>) => void;
  filtersIdsVisible: string[];
  setFiltersIdsVisible: (filtersIds: string[]) => void;
  isVisibleMultiHeader: boolean;
  setIsVisibleMultiHeader: (visible: boolean) => void;
  onCreateTemplate: () => void;
  nodeId?: string;
}

type RefsOfFilters = Record<string, { setIsOpen: (isOpen: boolean) => void }>;

const TOTAL_WIDTHS_ALL_HEADER_ELEMENTS = 490;
const ACTIONS_BUTTONS_WIDTH = 155;

export const BondScreenerFilters = ({
  filters,
  setFilters,
  filtersIdsVisible,
  setFiltersIdsVisible,
  nodeId,
  setIsVisibleMultiHeader,
  isVisibleMultiHeader,
  onCreateTemplate,
}: Props) => {
  const { getNode } = useWidgetContext();
  const [widths, setWidths] = useState<Record<string, number>>({});
  const refs = useRef<Record<string, HTMLDivElement | null>>({});
  const refsOfFilters = useRef<RefsOfFilters>({});
  const nodeWidth = useMemo(
    () => getNode(nodeId)?.getRect().width || 0,
    [getNode, nodeId]
  );

  const activeFiltersKeys = useMemo(() => {
    return Object.keys(filters).filter((key) => filters[key]);
  }, [filters]);

  const notHiddenFilters = useMemo(() => {
    const visibles: string[] = [];
    let totalWidth = TOTAL_WIDTHS_ALL_HEADER_ELEMENTS;

    totalWidth += activeFiltersKeys.length > 0 ? ACTIONS_BUTTONS_WIDTH : 0;

    for (const key of filtersIdsVisible) {
      if (widths[key]) {
        totalWidth += widths[key];
      }

      if (totalWidth < nodeWidth) {
        visibles.push(key);
      } else {
        return visibles;
      }
    }

    return visibles;
  }, [filtersIdsVisible, widths, nodeWidth, activeFiltersKeys]);

  const setFilterByKey = useCallback(
    (key: string, filter?: Filter) => {
      setFilters({
        ...filters,
        [key]: filter,
      });

      trackBondScreenerWidget.applyFilter(
        getFullNameByKey(key),
        JSON.stringify(filter?.value)
      );
    },
    [setFilters, filters]
  );

  const onClickItemFromSelect = useCallback(
    (key: string) => {
      if (!filtersIdsVisible.includes(key)) {
        setFiltersIdsVisible([...filtersIdsVisible, key]);
      }

      requestAnimationFrame(() => {
        refsOfFilters.current[key]?.setIsOpen(true); // Откроется после рендера
      });

      trackBondScreenerWidget.addFilter(getFullNameByKey(key));
    },
    [filtersIdsVisible, setFiltersIdsVisible]
  );

  const onDeleteFilterClick = useCallback(
    (key) => {
      if (!defaultFiltersVisible.includes(key)) {
        setFiltersIdsVisible(filtersIdsVisible.filter((x) => key !== x));
      }

      refsOfFilters.current[key]?.setIsOpen(false);

      if (activeFiltersKeys.includes(key)) {
        setFilters({ ...filters, [key]: undefined });
      }

      trackBondScreenerWidget.removeFilter(
        getFullNameByKey(key),
        JSON.stringify(filters[key]?.value)
      );
    },
    [
      setFiltersIdsVisible,
      filtersIdsVisible,
      filters,
      setFilters,
      activeFiltersKeys,
    ]
  );

  const groups = useMenuGroups(filtersIdsVisible, onClickItemFromSelect);

  const getColumnFilterById = useCallback(
    (key) => {
      const filter = filters[key];
      const filterSettings = getSettingsByKey(key);

      // Скрываем все фильтры которые не помещаются в строчку
      return (
        <div
          ref={(el) => {
            refs.current[key] = el;
          }}
          className={
            !isVisibleMultiHeader && !notHiddenFilters.includes(key)
              ? styles.hiddenFilter
              : ''
          }
        >
          {(() => {
            switch (key) {
              case TableColumnKey.BsBondTypeName:
              case TableColumnKey.BsAgencyRating:
              case TableColumnKey.BsSectorName:
              case TableColumnKey.BsCountryName:
              case TableColumnKey.BsCurrency:
              case TableColumnKey.BsOffertType: {
                const filterSettingsMappedForCurrency =
                  key === TableColumnKey.BsCurrency
                    ? {
                        ...filterSettings,
                        filterItems: filterSettings?.filterItems?.map((item) =>
                          typeof item === 'string'
                            ? item
                            : {
                                ...item,
                                content: (
                                  <div>
                                    {item.name}
                                    &nbsp;
                                    <Typography.Text
                                      view="secondary-large"
                                      color="secondary"
                                    >
                                      {item.value}
                                    </Typography.Text>
                                  </div>
                                ),
                              }
                        ),
                      }
                    : filterSettings;

                return (
                  <ColumnFilter
                    key={key}
                    columnKey={key}
                    filterSettings={filterSettingsMappedForCurrency}
                    filterType={FilterTypes.Array}
                    filter={filter}
                    onFilter={(newFilter?: Filter) =>
                      setFilterByKey(key, newFilter)
                    }
                    getChipsText={getChipsTextByKey}
                    ref={(el) => {
                      if (el) {
                        refsOfFilters.current[key] = el;
                      }
                    }}
                    onDeleteFilterClick={onDeleteFilterClick}
                    isShowDeleteIcon={
                      !defaultFiltersVisible.includes(key) ||
                      activeFiltersKeys.includes(key)
                    }
                    isChips
                    filterName={getFullNameByKey(key, true)}
                  />
                );
              }
              case TableColumnKey.BsYield:
              case TableColumnKey.BsPrice:
              case TableColumnKey.BsNominal:
              case TableColumnKey.BsYieldToOffert:
              case TableColumnKey.BsCupYield:
              case TableColumnKey.BsCupSize:
              case TableColumnKey.BsCouponPaymentPerYear:
                return (
                  <ColumnFilter
                    key={key}
                    columnKey={key}
                    filterSettings={filterSettings}
                    filterType={FilterTypes.NumberRangeDigits}
                    filter={filter}
                    onFilter={(newFilter?: Filter) =>
                      setFilterByKey(key, newFilter)
                    }
                    getChipsText={getChipsTextByKey}
                    ref={(el) => {
                      if (el) {
                        refsOfFilters.current[key] = el;
                      }
                    }}
                    onDeleteFilterClick={onDeleteFilterClick}
                    isShowDeleteIcon={
                      !defaultFiltersVisible.includes(key) ||
                      activeFiltersKeys.includes(key)
                    }
                    isChips
                    filterName={getFullNameByKey(key, true)}
                  />
                );
              case TableColumnKey.BsIsFloatingRate:
              case TableColumnKey.BsAmortization:
              case TableColumnKey.BsIsOffert:
              case TableColumnKey.BsIsStructuredNote:
              case TableColumnKey.BsIsReplacement:
              case TableColumnKey.BsIsSubordinatedDebt:
                return (
                  <ColumnFilter
                    key={key}
                    columnKey={key}
                    filterSettings={filterSettings}
                    filterType={FilterTypes.RadioSelect}
                    filter={filter}
                    onFilter={(newFilter?: Filter) =>
                      setFilterByKey(key, newFilter)
                    }
                    getChipsText={getChipsTextByKey}
                    ref={(el) => {
                      if (el) {
                        refsOfFilters.current[key] = el;
                      }
                    }}
                    onDeleteFilterClick={onDeleteFilterClick}
                    isShowDeleteIcon={
                      !defaultFiltersVisible.includes(key) ||
                      activeFiltersKeys.includes(key)
                    }
                    isChips
                    filterName={getFullNameByKey(key, true)}
                  />
                );
              case TableColumnKey.BsAllowNonQualInvest:
                return (
                  <ColumnFilter
                    key={key}
                    columnKey={key}
                    filterSettings={filterSettings}
                    filterType={FilterTypes.Toggle}
                    filter={filter}
                    onFilter={(newFilter?: Filter) =>
                      setFilterByKey(key, newFilter)
                    }
                    getChipsText={getChipsTextByKey}
                    ref={(el) => {
                      if (el) {
                        refsOfFilters.current[key] = el;
                      }
                    }}
                    onDeleteFilterClick={onDeleteFilterClick}
                    isShowDeleteIcon={
                      !defaultFiltersVisible.includes(key) ||
                      activeFiltersKeys.includes(key)
                    }
                    isChips
                    filterName={getFullNameByKey(key, true)}
                  />
                );
              case TableColumnKey.BsOffertDate:
                return (
                  <ColumnFilter
                    key={key}
                    columnKey={key}
                    filterType={FilterTypes.CalendarRange}
                    filter={filter}
                    onFilter={(newFilter?: Filter) =>
                      setFilterByKey(key, newFilter)
                    }
                    getChipsText={getChipsTextByKey}
                    ref={(el) => {
                      if (el) {
                        refsOfFilters.current[key] = el;
                      }
                    }}
                    onDeleteFilterClick={onDeleteFilterClick}
                    isShowDeleteIcon={
                      !defaultFiltersVisible.includes(key) ||
                      activeFiltersKeys.includes(key)
                    }
                    isChips
                    filterName={getFullNameByKey(key, true)}
                  />
                );
              case TableColumnKey.BsMatDate:
                return (
                  <ColumnFilter
                    key={key}
                    columnKey={key}
                    filterSettings={filterSettings}
                    filterType={FilterTypes.ComplexDateRange}
                    filter={filter}
                    onFilter={(newFilter?: Filter) =>
                      setFilterByKey(key, newFilter)
                    }
                    getChipsText={getChipsTextByKey}
                    ref={(el) => {
                      if (el) {
                        refsOfFilters.current[key] = el;
                      }
                    }}
                    onDeleteFilterClick={onDeleteFilterClick}
                    isShowDeleteIcon={
                      !defaultFiltersVisible.includes(key) ||
                      activeFiltersKeys.includes(key)
                    }
                    isChips
                    filterName={getFullNameByKey(key, true)}
                  />
                );
              default:
                return null;
            }
          })()}
        </div>
      );
    },
    [
      setFilterByKey,
      filters,
      notHiddenFilters,
      isVisibleMultiHeader,
      onDeleteFilterClick,
      activeFiltersKeys,
    ]
  );

  useEffect(() => {
    const newWidths: Record<string, number> = {};

    filtersIdsVisible.forEach((key) => {
      const element = refs.current[key];

      newWidths[key] = element?.offsetWidth ? element.offsetWidth + 8 : 0;
    });
    setWidths(newWidths);
  }, [filtersIdsVisible]);

  return (
    <>
      {filtersIdsVisible.map((key) => {
        return getColumnFilterById(key);
      })}
      {!isVisibleMultiHeader &&
      activeFiltersKeys.some((key) => !notHiddenFilters.includes(key)) ? (
        <Tag
          size="xs"
          view="filled"
          checked={true}
          className={styles.popoverButton}
          leftAddons={<SlidersCompactMIcon width={16} height={16} />}
          onClick={() => setIsVisibleMultiHeader(true)}
        >
          <Typography.Text view="secondary-large" weight="medium">
            Еще{' '}
            {
              activeFiltersKeys.filter((key) => !notHiddenFilters.includes(key))
                .length
            }
          </Typography.Text>
        </Tag>
      ) : (
        <DropdownMenu
          groups={groups}
          popoverProps={{
            availableHeight: true,
            preventOverflow: false,
          }}
          withSearch
        >
          <Button
            size="xxs"
            leftAddons={<PlusCompactMIcon width={16} height={16} />}
            className={styles.popoverButton}
            onClick={() => {
              setIsVisibleMultiHeader(true);
              trackBondScreenerWidget.filterButton();
            }}
          >
            <Typography.Text view="secondary-large" weight="medium">
              Фильтр
            </Typography.Text>
          </Button>
        </DropdownMenu>
      )}
      {activeFiltersKeys.length > 0 && (
        <>
          <Tooltip content="Сохранить" trigger="hover" position="top">
            <Button
              size="xxs"
              leftAddons={<HeartMIcon width={16} height={16} />}
              className={styles.popoverButton}
              onClick={onCreateTemplate}
            >
              <Typography.Text view="secondary-large" weight="medium">
                Сохранить
              </Typography.Text>
            </Button>
          </Tooltip>
          <Tooltip content="Удалить все" trigger="hover" position="top">
            <Button
              size="xxs"
              leftAddons={<CrossCircleMIcon width={16} height={16} />}
              className={styles.popoverButton}
              onClick={() => {
                setFiltersIdsVisible(defaultFiltersVisible);
                setFilters(defaultFilters);

                trackBondScreenerWidget.removeFilters();
              }}
            />
          </Tooltip>
        </>
      )}
    </>
  );
};
