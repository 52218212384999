import { fetchLkResult } from '@terminal/core/lib/rest/lkApi';

import { SignCreated } from '../../sign';

import { IiaConvertSendParams } from './types/IiaConvertSendParams';

export async function sendIiaConvert(params: IiaConvertSendParams) {
  return await fetchLkResult<SignCreated>(
    `/api/iia-convert/send/${params.operationId}`,
    'POST',
    params
  );
}
