import { LayoutType, Widget } from '../../types/layout';
import { WorkspaceConfiguration } from '../../types/workspace';

export const findAllWatchlistIds = (configuration: WorkspaceConfiguration) => {
  const ids = new Set();
  const { layouts } = configuration.lastAutoSavedConfig;

  function traverse(node) {
    if (node.component === Widget.WATCHLIST && node.config?.selectedListId) {
      ids.add(node.config.selectedListId);
    }

    if (node.children) {
      node.children.forEach((child) => traverse(child));
    }
  }

  for (let index = 0; index < layouts.length; index++) {
    const layout = layouts[index];

    if (layout.type === LayoutType.Flex) {
      for (let index = 0; index < layout.layout.length; index++) {
        const tabset = layout.layout[index];

        for (let index = 0; index < tabset.children.length; index++) {
          const node = tabset.children[index];

          if (node.component === Widget.WATCHLIST && node['selectedListId']) {
            ids.add(node['selectedListId']);
          }
        }
      }
    } else {
      traverse({
        children: layout.layout.children,
      });
    }
  }

  return [...ids];
};
