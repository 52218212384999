import mapValues from 'lodash/mapValues';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useEffectOnce } from 'react-use';
import { v4 } from 'uuid';
import { BaseModal } from '@alfalab/core-components/base-modal';
import { ButtonDesktop as Button } from '@alfalab/core-components/button/desktop';
import { MagnifierMIcon } from '@alfalab/icons-glyph/MagnifierMIcon';
import ScrewdriverWrenchMIcon from '@alfalab/icons-glyph/ScrewdriverWrenchMIcon';

import { AlfaDirectProvider } from '@terminal/alfadirect/provider/react';
import { Spinner } from '@terminal/common/components/Spinner';
import {
  BlueprintTable,
  CellRenderProps,
  IMenuContext,
} from '@terminal/common/ui/Table';
import { defaultBondScreenerColumnSettings } from '@terminal/core/constants/tableProps';
import { useTableColumns } from '@terminal/core/hooks/useTableColumns';
import { trackBondScreenerWidget } from '@terminal/core/lib/analytics/bondScreener/bondScreener';
import { ScreenerBondEntity } from '@terminal/core/types/bondScreener';
import {
  BondScreenerProps,
  Filter,
  Sort,
  TableColumnSetting,
  Widget,
} from '@terminal/core/types/layout';
import { TableColumnKey } from '@terminal/core/types/tableColumn';
import { WatchListItem } from '@terminal/core/types/watchList';

import { AddWatchlistModal } from '../../../../features/AddWatchlist';
import { ContextMenu } from '../../../../features/ContextMenu';
import {
  defaultColumnFilterRender,
  InfoMessage,
  Provider as WidgetContextProvider,
  useAlfaDirectContext,
  useWidgetContext,
} from '../../../../shared';
import { bondScreenerTemplatesLS } from '../../helpers';
import { defaultFilters, defaultFiltersVisible } from '../../model/consts';
import { BSTableTab, Header } from '../../ui';
import { BondScreenerModal, BondScreenerModalType } from '../BondScreenModal';

import { useSortTableWithoutReorder } from '../../../../shared/hooks/useSortTableWithoutReorder';
import { useBsColumns } from '../../hooks/useBsColumns';
import { useCellRender } from '../../hooks/useCellRender';
import { useFiltersFormatted } from '../../hooks/useFiltersFormatted';
import { useGetBondsData } from '../../hooks/useGetBondsData';

import styles from './bondScreener.module.css';

export function BondScreener(props: BondScreenerProps): JSX.Element {
  const { nodeId, tableProps, idFi, link, selectedData } = props;

  const [isOpenColumnSettings, setIsOpenColumnSettings] =
    useState<boolean>(false);
  const widgetContext = useWidgetContext();
  const { updateNode } = widgetContext;
  const filters = useMemo(
    () =>
      mapValues(tableProps?.filters || defaultFilters, (filter) => {
        return (
          filter && {
            ...filter,
            value:
              typeof filter.value === 'object' && !Array.isArray(filter.value)
                ? mapValues(filter.value, (v, k) =>
                    ['dateFrom', 'dateTo'].includes(k) && v ? new Date(v) : v
                  )
                : filter.value,
          }
        );
      }),
    [tableProps?.filters]
  );

  const isSelectedConfigurationPutted = useRef<boolean>(false);

  const [query, setQuery] = useState('');
  const [filtersIdsVisible, setFiltersIdsVisible] = useState<string[]>(
    defaultFiltersVisible
  );
  const ref = useRef<HTMLDivElement>(null);
  const [templates, setTemplates] = useState(bondScreenerTemplatesLS.get());
  const [bondScreenerModalType, setBondScreenerModalType] =
    useState<BondScreenerModalType>(BondScreenerModalType.Create);
  const [currentTemplateId, setCurrentTemplateId] = useState<string>();
  const [isWatchlistModalOpen, setIsWatchlistModalOpen] = useState(false);
  const [instrumentToAdd, setInstrumentToAdd] = useState<WatchListItem[]>();

  const [isBondScreenerModalOpen, setIsBondScreenerModalOpen] = useState(false);

  const onCreateTemplate = useCallback(() => {
    setIsBondScreenerModalOpen(true);
    setCurrentTemplateId(undefined);
    setBondScreenerModalType(BondScreenerModalType.Create);
  }, [
    setIsBondScreenerModalOpen,
    setCurrentTemplateId,
    setBondScreenerModalType,
  ]);

  const onEditTemplate = useCallback(
    (id: string) => {
      setIsBondScreenerModalOpen(true);
      setCurrentTemplateId(id);
      setBondScreenerModalType(BondScreenerModalType.Edit);
    },
    [setIsBondScreenerModalOpen, setCurrentTemplateId, setBondScreenerModalType]
  );

  const onDeleteTemplate = useCallback(
    (id: string) => {
      setIsBondScreenerModalOpen(true);
      setCurrentTemplateId(id);
      setBondScreenerModalType(BondScreenerModalType.Delete);
    },
    [setIsBondScreenerModalOpen, setCurrentTemplateId, setBondScreenerModalType]
  );

  useEffect(() => {
    setFiltersIdsVisible((ids) => [
      ...ids,
      ...Object.keys(filters).filter(
        (key) => filters[key] && !ids.includes(key)
      ),
    ]);
  }, [filters, setFiltersIdsVisible]);

  const [isVisibleMultiHeader, setIsVisibleMultiHeader] = useState(true);
  const handleSubmitBondScreener = (name: string) => {
    switch (bondScreenerModalType) {
      case BondScreenerModalType.Create:
        const id = v4();
        const newTemplates = [
          ...templates,
          {
            id,
            name,
            filters,
          },
        ];

        setTemplates(newTemplates);
        bondScreenerTemplatesLS.set(newTemplates);
        trackBondScreenerWidget.newTemplate(name);
        break;
      case BondScreenerModalType.Edit:
        const updatedTemplate = templates.find(
          (item) => item.id === currentTemplateId
        )!;
        const updatedTemplates = [
          ...templates.filter((item) => item.id !== currentTemplateId),
          {
            ...updatedTemplate,
            name,
          },
        ];

        setTemplates(updatedTemplates);
        bondScreenerTemplatesLS.set(updatedTemplates);
        trackBondScreenerWidget.changeTemplate(name);
        break;
      case BondScreenerModalType.Delete:
        const templatesWithoutDeleted = templates.filter(
          (item) => item.id !== currentTemplateId
        );

        setTemplates(templatesWithoutDeleted);
        bondScreenerTemplatesLS.set(templatesWithoutDeleted);
        trackBondScreenerWidget.removeTemplate(name);
        break;
      default:
        break;
    }
  };

  const filtersFormatted = useFiltersFormatted(filters);

  const sort = useMemo(() => tableProps?.sort, [tableProps?.sort]);
  const { data, isLoading, getMoreData, isError, retry } = useGetBondsData({
    sort,
    filters: filtersFormatted,
    query,
  });

  const updateNodeHandler = useCallback(
    (config) => {
      nodeId && updateNode(nodeId, config);
    },
    [nodeId, updateNode]
  );

  const setSelectedData = useCallback(
    (newSelectedData: ScreenerBondEntity[]) => {
      updateNodeHandler({ selectedData: newSelectedData });
    },
    [updateNodeHandler]
  );

  const [selectedHash, setSelectedHash] = useState<Record<string, boolean>>({});

  const onChangeCheck = useCallback(
    (isChecked: boolean, item: ScreenerBondEntity) => {
      const keyId = item.idfi;

      setSelectedHash((prev) => {
        const newSelectedHash = { ...prev };

        if (isChecked) {
          newSelectedHash[keyId] = true;
        } else {
          delete newSelectedHash[keyId];
        }

        return newSelectedHash;
      });
      setSelectedData(
        isChecked
          ? [...selectedData, item]
          : selectedData.filter((x) => x.idfi !== keyId)
      );
    },
    [setSelectedData, selectedData]
  );

  const onChangeCheckAll = useCallback(
    (isCheckAll: boolean) => {
      if (!isCheckAll) {
        setSelectedHash({});
        setSelectedData([]);
      } else {
        const newSelectedHash = data.reduce(
          (reducer: Record<string, boolean>, item) => {
            reducer[item.idfi] = true;

            return reducer;
          },
          {}
        );

        setSelectedHash(newSelectedHash);
        setSelectedData(data);
      }
    },
    [setSelectedData, data]
  );

  useEffectOnce(() => {
    setSelectedHash(
      selectedData.reduce<Record<string, boolean>>((reducer, item) => {
        reducer[item.idfi] = true;

        return reducer;
      }, {})
    );
    isSelectedConfigurationPutted.current = true;
  });

  const [selectedTab, selectTab] = useState<BSTableTab>(
    BSTableTab.AllSearchResults
  );

  const dataForSelectedTab = useMemo(
    () => (selectedTab === BSTableTab.AllSearchResults ? data : selectedData),
    [data, selectedData, selectedTab]
  );

  useEffect(() => {
    if (selectedData.length === 0) {
      selectTab(BSTableTab.AllSearchResults);
    }
  }, [selectedData]);

  const onScrolledToEnd = useCallback(() => {
    if (selectedTab === BSTableTab.OnlySelected) {
      return;
    }

    getMoreData();
  }, [getMoreData, selectedTab]);

  const alfaDirectProps = useAlfaDirectContext();

  const bsColumns = useBsColumns(tableProps?.columns ?? []);

  const defaultBsColumns = useBsColumns(defaultBondScreenerColumnSettings);

  const columnsSetting = useTableColumns(
    tableProps?.columns ? bsColumns : undefined,
    defaultBsColumns
  );

  const filteredColumnsSetting = useMemo(
    () => columnsSetting.filter((column) => Boolean(column.key)),
    [columnsSetting]
  );

  const onRowClick = useCallback(
    (event: React.MouseEvent, row: ScreenerBondEntity) => {
      if (event.detail === 1) {
        const idFi = row.idfi;

        if (link && nodeId) {
          updateNode(nodeId, { idFi });
        }
      } else if (event.detail === 2) {
        onChangeCheck(selectedHash[row.idfi], row);
      }
    },
    [link, nodeId, updateNode, onChangeCheck, selectedHash]
  );

  const cellRender: CellRenderProps<ScreenerBondEntity> =
    useCellRender(onRowClick);

  const selectedRowIndex = useMemo(() => {
    if (idFi && data) {
      return [
        ...data.reduce((acc, row, index) => {
          if (row.idfi === idFi) {
            return [...acc, index];
          } else {
            return acc;
          }
        }, [] as number[]),
      ];
    }
  }, [idFi, data]);

  const setSort = useCallback(
    (sort?: Sort) => {
      updateNodeHandler({ tableProps: { sort } });
    },
    [updateNodeHandler]
  );

  const setFilters = useCallback(
    (newFilters: Record<string, Filter | undefined>) => {
      updateNodeHandler({ tableProps: { filters: newFilters } });
    },
    [updateNodeHandler]
  );

  const { onSort } = useSortTableWithoutReorder({
    sort,
    setSort,
  });

  const saveColumnSetting = useCallback(
    (columns: TableColumnSetting[]) => {
      if (nodeId) {
        if (!(columns[0].key === TableColumnKey.BsName)) {
          return;
        }

        updateNode(nodeId, { tableProps: { columns } });
      }
    },
    [nodeId, updateNode]
  );

  const bodyContextMenuRenderer = useCallback(
    (context: IMenuContext) => {
      const target = context.getTarget();
      const { rows } = target;

      if (rows) {
        const index = rows[0];
        const dataItem = data![index];

        // TODO: Требуется доработка таблички или меню
        // blueprint v4 не видит контекста для этого был сделан этот костыль
        // https://github.com/palantir/blueprint/issues/1121
        return (
          <AlfaDirectProvider value={alfaDirectProps}>
            <WidgetContextProvider value={widgetContext}>
              <ContextMenu
                idFi={dataItem.idfi}
                widget={Widget.BOND_SCREENER}
                nodeId={nodeId}
                link={link}
                setIsOpenColumnSettings={setIsOpenColumnSettings}
                setIsOpenLimitsModal={() => {}}
                setIsOpenContextMenuModal={() => {}}
                setIsOpenSlippageModal={() => {}}
                setIsOpenQuantityModal={() => {}}
                setIsWatchlistModalOpen={setIsWatchlistModalOpen}
                setInstrumentToAdd={(item) =>
                  item && setInstrumentToAdd([item])
                }
                onClickOption={(name) =>
                  trackBondScreenerWidget.contextMenu(name, dataItem.isin)
                }
                onClickColumnsSettings={trackBondScreenerWidget.settingsColumns}
                onClickClone={trackBondScreenerWidget.duplicate}
                onClickClose={trackBondScreenerWidget.close}
              />
            </WidgetContextProvider>
          </AlfaDirectProvider>
        );
      } else {
        return <></>;
      }
    },
    [widgetContext, nodeId, link, data, alfaDirectProps]
  );

  return (
    <div className={styles.container} ref={ref}>
      <Header
        isOpenColumnSettings={isOpenColumnSettings}
        setIsOpenColumnSettings={setIsOpenColumnSettings}
        nodeId={nodeId}
        columnsSetting={filteredColumnsSetting}
        selectedTab={selectedTab}
        selectTab={selectTab}
        setQuery={setQuery}
        filtersIdsVisible={filtersIdsVisible}
        setFiltersIdsVisible={setFiltersIdsVisible}
        query={query}
        selectedData={selectedData}
        setInstrumentToAdd={setInstrumentToAdd}
        setIsWatchlistModalOpen={setIsWatchlistModalOpen}
        filters={filters}
        setFilters={setFilters}
        setIsVisibleMultiHeader={setIsVisibleMultiHeader}
        isVisibleMultiHeader={isVisibleMultiHeader}
        templates={templates}
        onCreateTemplate={onCreateTemplate}
        onEditTemplate={onEditTemplate}
        onDeleteTemplate={onDeleteTemplate}
        onChangeCheckAll={onChangeCheckAll}
      />
      {data.length > 0 && !isError && (
        <BlueprintTable<ScreenerBondEntity>
          sort={sort}
          onSort={onSort}
          data={dataForSelectedTab}
          columnSetting={columnsSetting}
          cellRender={cellRender}
          columnFilterRender={defaultColumnFilterRender}
          onReorder={saveColumnSetting}
          onChangeWidth={saveColumnSetting}
          defaultSelectedRows={selectedRowIndex}
          bodyContextMenuRenderer={bodyContextMenuRenderer}
          selectedHash={selectedHash}
          selectedHashKey="idfi"
          onChangeCheck={onChangeCheck}
          onChangeCheckAll={onChangeCheckAll}
          onScroll={(_: Event) => setIsVisibleMultiHeader(false)}
          onScrolledToEnd={onScrolledToEnd}
        />
      )}
      {!isLoading && !isError && data.length === 0 && (
        <div className={styles.infoContainer}>
          <InfoMessage
            mainText="Нет результатов"
            descriptionText="Попробуйте изменить параметры поиска"
            actions={
              <Button
                onClick={() => {
                  setFilters(defaultFilters);
                  setQuery('');
                }}
                size="xxs"
              >
                Сбросить фильтры
              </Button>
            }
            icon={<MagnifierMIcon width={24} height={24} />}
          />
        </div>
      )}
      {isError && (
        <div className={styles.infoContainer}>
          <InfoMessage
            mainText="Не получилось загрузить"
            descriptionText="Уже знаем, в чём дело, и чиним.
            Попробуйте обновить или зайти позже"
            actions={
              <Button onClick={retry} size="xxs">
                Повторить
              </Button>
            }
            icon={<ScrewdriverWrenchMIcon width={24} height={24} />}
          />
        </div>
      )}
      {isLoading && (
        <div className={styles.infoContainer}>
          <Spinner size="44px" color="var(--color-light-text-secondary)" />
        </div>
      )}
      <BaseModal
        open={isBondScreenerModalOpen}
        onClose={() => setIsBondScreenerModalOpen(false)}
        container={
          ref.current ? () => ref.current as HTMLDivElement : undefined
        }
        wrapperClassName={styles.modalWrapper}
      >
        <BondScreenerModal
          templateId={currentTemplateId}
          templates={templates}
          type={bondScreenerModalType}
          onClose={() => {
            setIsBondScreenerModalOpen(false);
          }}
          onSubmit={(name) => handleSubmitBondScreener(name)}
        />
      </BaseModal>
      <AddWatchlistModal
        isOpen={isWatchlistModalOpen}
        setIsOpen={setIsWatchlistModalOpen}
        instrumentsToAdd={instrumentToAdd}
        setInstrumentToAdd={setInstrumentToAdd}
        container={
          ref.current ? () => ref.current as HTMLDivElement : undefined
        }
        type="create"
        nodeId={nodeId}
        onWatchlistCreated={trackBondScreenerWidget.addToList}
      />
    </div>
  );
}
