import { useCallback, useEffect } from 'react';
import shallow from 'zustand/shallow';

import { trackPersBrokerChatEnter } from '@terminal/core/lib/analytics';

import { messagesListEventEmitter } from '../lib/messagesListEventEmitter';
import { BrokerRecommendationService } from '../services/broker';

import { useStore } from '../store/useStore';

import { BrokerChatMessage } from '../types';

type ReturnType = {
  title: string;
  messages: BrokerChatMessage[];
  sendMessage?: (text: string) => Promise<void>;
};

const PERS_BROKER_CHAT_ID = '142';

const PERS_BROKER_READ_ONLY_CHAT_ID = '100';

export function useBrokerChat(id: string): ReturnType {
  const [
    messages,
    setOpenedChatId,
    setUnreadMsgCount,
    setNewMessages,
    chatGroups,
  ] = useStore(
    (store) => [
      store.messages[id],
      store.setOpenedChatId,
      store.setUnreadMsgCount,
      store.setNewMessages,
      store.chatGroups,
    ],
    shallow
  );

  const setLastVisit = useCallback((id: string) => {
    const currentLastVisits = localStorage.getItem('chatLastVisit');

    localStorage.setItem(
      'chatLastVisit',
      JSON.stringify({
        ...(currentLastVisits ? JSON.parse(currentLastVisits) : {}),
        [id]: new Date(),
      })
    );
  }, []);

  useEffect(() => {
    if (id === PERS_BROKER_CHAT_ID) {
      trackPersBrokerChatEnter();
    }
  }, [id]);

  const handleSendMessage = useCallback(
    async (text: string) => {
      await BrokerRecommendationService.sendMessage(text, id, setNewMessages);
      messagesListEventEmitter.emit('scroll-bottom');
    },
    [id, setNewMessages]
  );

  // При входе в комнату сбрасываем непрочитанные
  useEffect(() => {
    setUnreadMsgCount(id, 0);
  }, [setUnreadMsgCount, id]);

  useEffect(() => {
    setOpenedChatId(id);

    return () => setOpenedChatId(null);
  }, [id, setOpenedChatId]);

  // Обновляем дату последнего посещения комнаты
  useEffect(() => {
    setLastVisit(id);

    return () => setLastVisit(id);
  }, [id, setLastVisit]);

  return {
    title: chatGroups[id]?.nameChatGroup || 'Брокер РФ',
    messages,
    sendMessage:
      id !== PERS_BROKER_READ_ONLY_CHAT_ID ? handleSendMessage : undefined,
  };
}
