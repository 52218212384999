import {
  Cell,
  Column,
  ColumnHeaderCell,
  Region,
  RegionCardinality,
  Regions,
  SelectionModes,
  Table2,
  TableProps,
  TruncatedFormat,
  Utils,
} from '@blueprintjs/table';
import cn from 'classnames';
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMountedState } from 'react-use';
import { useThrottledCallback } from 'use-debounce';
import { Checkbox } from '@alfalab/core-components/checkbox';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { ArrowUpCompactXsIcon } from '@alfalab/icons-glyph/ArrowUpCompactXsIcon';

import { usePrevious } from '@terminal/core/hooks/usePrevious';
import { getColumnDescription } from '@terminal/core/lib/helpers/getColumnDescription';
import { getColumnName } from '@terminal/core/lib/helpers/getColumnName';
import { getIsColumnStatic } from '@terminal/core/lib/helpers/getIsColumnStatic';
import { isNotUndefined } from '@terminal/core/lib/isNotUndefined';
import {
  ColumnFilterRenderProps,
  Filter,
  Sort,
  TableColumnSetting,
} from '@terminal/core/types/layout';
import { TableColumnKey } from '@terminal/core/types/tableColumn';

import './BlueprintTable.css';
import styles from './BlueprintTable.module.css';

export type CellRenderProps<T> = (
  rowIndex: number,
  columnIndex: number,
  columnKey: TableColumnKey,
  data: T[],
  setHoverIndex: (rowIndex: number | null) => void,
  columnName: string | JSX.Element,
  isDoubleRowMode?: boolean,
  setContextMenuRow?: (rowIndex: number) => void
) => ReactNode;

type StringOrNumberKeys<T> = {
  [K in keyof T]: T[K] extends string | number ? K : never;
}[keyof T];

type SelectedHash = Record<string | number, boolean>;

interface Props<T> extends TableProps {
  columnSetting: TableColumnSetting[];
  data: T[];
  cellRender: CellRenderProps<T>;
  columnFilterRender: ColumnFilterRenderProps;
  onReorder?: (newColumnSetting: TableColumnSetting[]) => void;
  onChangeWidth?: (newColumnSetting: TableColumnSetting[]) => void;
  //Индексы выбранных по умолчанию строк в таблице
  defaultSelectedRows?: number[];
  onRowClick?: (row: T) => void;
  isMultiSelect?: boolean;
  fixedColumns?: TableColumnKey[]; // массив индексов колонок, которые нельзя реордерить
  sort?: Sort;
  onSort?: (key: string) => void;
  filter?: Filter;
  onFilter?: (filter?: Filter) => void;
  rerenderDepend?: any;
  hoverActions?: (
    data: T,
    onHover: (state: boolean) => void,
    viewportWidth?: number
  ) => JSX.Element | null;
  isDoubleRowMode?: boolean;
  contextMenuRow?: number | null;
  setContextMenuRow?: (rowIndex: number) => void;
  getIsSelectedRow?: (item: T) => boolean;
  onScroll?: (event: WheelEvent) => void;
  // Если "true" - строки выделяются подчеркиваниями
  // Если "false" - строки выделяются разными цветами
  isBorderedRows?: boolean;
  // Для работы с чекбоксами
  selectedHash?: SelectedHash;
  onChangeCheck?: (isChecked: boolean, item: T) => void;
  onChangeCheckAll?: (isCheckedAll: boolean) => void;
  selectedHashKey?: StringOrNumberKeys<T>;
  onScrolledToEnd?: () => void;
}

export const BlueprintTable = <T,>(props: Props<T>) => {
  const {
    columnSetting,
    data,
    columnFilterRender,
    cellRender,
    onReorder,
    defaultSelectedRows,
    onRowClick,
    isMultiSelect,
    fixedColumns,
    sort,
    onSort,
    filter,
    onFilter,
    onChangeWidth,
    rerenderDepend,
    hoverActions,
    isDoubleRowMode,
    contextMenuRow,
    setContextMenuRow,
    isBorderedRows,
    className,
    selectedHash,
    onChangeCheck,
    onChangeCheckAll,
    selectedHashKey,
    onScroll,
    onScrolledToEnd,
    ...tableProps
  } = props;

  const isMounted = useMountedState();
  const tableRef = useRef<Table2>(null);
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);
  const [selectedRows, setSelectedRows] = useState<number[]>(
    defaultSelectedRows || []
  );

  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const wrapperElement = wrapperRef.current;

    if (wrapperElement && onScroll) {
      const handleScroll = (event: WheelEvent) => {
        onScroll(event);
      };

      wrapperElement.addEventListener('wheel', handleScroll);

      return () => {
        wrapperElement.removeEventListener('wheel', handleScroll);
      };
    }
  }, [onScroll]);

  const isHaveCheckboxesColumn = isNotUndefined(selectedHash);

  useEffect(() => {
    if (defaultSelectedRows) {
      setSelectedRows(defaultSelectedRows);
    }
  }, [defaultSelectedRows]);

  const prevFilteredColumns = usePrevious(
    columnSetting.filter(
      (column) => Boolean(column.selected) && Boolean(column.key)
    )
  );
  const filteredColumns = useMemo(
    () =>
      columnSetting.filter(
        (column) => Boolean(column.selected) && Boolean(column.key)
      ),
    [columnSetting]
  );

  useEffect(() => {
    //Проверяем что таблица смонтировалась
    if (isMounted()) {
      const prevColumns = prevFilteredColumns?.map((column) => column.key);
      const columns = filteredColumns.map((column) => column.key);

      //Скроллим к началу таблицы если изменился набор колонок или их наполнение
      if (
        prevColumns &&
        !(
          columns.length === prevColumns.length &&
          prevColumns.every((key) => columns.includes(key))
        )
      ) {
        tableRef.current?.scrollToRegion(Regions.column(0));
      }
    }
  }, [isMounted, filteredColumns, prevFilteredColumns]);

  const handleColumnsReordered = useCallback(
    (oldI: number, newI: number, length: number) => {
      const [oldIndex, newIndex] = [oldI, newI].map((x) =>
        isHaveCheckboxesColumn ? x - 1 : x
      );

      if (oldIndex === newIndex) {
        return;
      }

      const nonSelectedColumns = columnSetting.filter(
        (column) => !column.selected
      );
      const nextFilteredColumns =
        Utils.reorderArray(filteredColumns, oldIndex, newIndex, length) || [];

      let nonReorderable = false;

      // Колонка с тикером должна быть всегда первой
      if (
        filteredColumns[oldIndex].key === TableColumnKey.SymbolObject ||
        filteredColumns[oldIndex].key === TableColumnKey.NameObject
      ) {
        nonReorderable = true;
      }

      // Колонки для которых явно отключен реордер
      if (
        fixedColumns &&
        fixedColumns.includes(filteredColumns[oldIndex].key)
      ) {
        nonReorderable = true;
      }

      if (nonReorderable) {
        return;
      }

      onReorder?.([...nextFilteredColumns, ...nonSelectedColumns]);
    },
    [
      isHaveCheckboxesColumn,
      columnSetting,
      filteredColumns,
      fixedColumns,
      onReorder,
    ]
  );

  const onSelection = (regions: Region[]) => {
    if (regions.length > 0 && onRowClick) {
      const { rows } = regions[0];

      if (rows) {
        const index = rows[0];
        const row = data[index];
        const isPrevSelected = selectedRows.indexOf(index);

        if (isMultiSelect) {
          if (isPrevSelected === -1) {
            setSelectedRows((prevSelected) => [...prevSelected, index]);
          } else {
            setSelectedRows((prevSelected) =>
              prevSelected.filter((i) => i !== index)
            );
          }
        } else {
          setSelectedRows([index]);
        }

        onRowClick(row);
      }
    }
  };

  const onColumnWidthChanged = useCallback(
    (index: number, size: number) => {
      //Если есть функция сохранения размеров колонок
      if (onChangeWidth) {
        const newIndex = isHaveCheckboxesColumn ? index - 1 : index;

        const changedColumn = filteredColumns[newIndex];

        if (!changedColumn || (isHaveCheckboxesColumn && newIndex === -1)) {
          return;
        }

        const newSettings = columnSetting.map((col) => {
          if (col.key === changedColumn.key) {
            //Сохраняем ширину в настройки
            return { ...col, width: size };
          } else {
            return col;
          }
        });

        onChangeWidth(newSettings);
      }
    },
    [isHaveCheckboxesColumn, columnSetting, filteredColumns, onChangeWidth]
  );

  //Собираем размеры колонок из настроек
  const { columnWidths, columnTotalWidth } = useMemo(() => {
    let { columnWidths, columnTotalWidth } = filteredColumns.reduce<{
      columnWidths: number[];
      columnTotalWidth: number;
    }>(
      (acc, col) => {
        const width = col.width || 0;

        return {
          columnTotalWidth: acc.columnTotalWidth + width,
          columnWidths: [...acc.columnWidths, width],
        };
      },
      { columnWidths: [], columnTotalWidth: 0 }
    );

    if (isHaveCheckboxesColumn) {
      columnWidths.unshift(32);
      columnTotalWidth -= 32;
    }

    return { columnWidths, columnTotalWidth };
  }, [filteredColumns, isHaveCheckboxesColumn]);

  // Blueprint не умеет динамически менять высоту строки и не дает никакого API для этого,
  // поэтому мы вызываем remount при изменении режима двухстрочный/однострочный
  // P.S. добавил rerenderDepend в зависимость
  const [forceTableUpdateKey, setForceTableUpdateKey] = useState(0);

  useEffect(() => {
    setForceTableUpdateKey((prev) => (prev === 1 ? 0 : 1));
  }, [isDoubleRowMode, rerenderDepend, filteredColumns]);

  useEffect(() => {
    tableRef.current?.forceUpdate();
  }, [hoverIndex, contextMenuRow]);

  const viewportWidth = tableRef.current?.state.viewportRect?.width ?? 0;
  const offset = tableRef.current?.state.viewportRect?.left ?? 0;

  const fixedColumnWidths: number[] = useMemo(() => {
    return columnWidths.concat(
      columnTotalWidth < viewportWidth ? viewportWidth - columnTotalWidth : 0
    );
  }, [columnTotalWidth, columnWidths, viewportWidth]);

  const [firstVisibleColumnIndex, setFirstVisibleColumnIndex] = useState(0);
  const invisibleColumnsWidth = useMemo(() => {
    return columnWidths
      .slice(0, firstVisibleColumnIndex)
      .reduce((a, b) => a + b, 0);
  }, [columnWidths, firstVisibleColumnIndex]);

  const onVisibleCellsChange = useThrottledCallback(
    (...args: Parameters<NonNullable<TableProps['onVisibleCellsChange']>>) => {
      const [rowIndices, columns] = args;

      setFirstVisibleColumnIndex(columns.columnIndexStart);

      if (
        onScrolledToEnd &&
        data.length > 0 &&
        rowIndices.rowIndexEnd === data.length - 1
      ) {
        onScrolledToEnd();
      }
    },
    100
  );

  const actionPos =
    columnTotalWidth <= viewportWidth
      ? columnTotalWidth
      : viewportWidth + offset - invisibleColumnsWidth;

  const getIsFirstColumn = useCallback(
    (column: TableColumnSetting, columnIndex: number) => {
      return (
        columnIndex === 0 ||
        [
          TableColumnKey.SymbolObject,
          TableColumnKey.NameObject,
          TableColumnKey.NameBalanceGroup,
          TableColumnKey.MarketNameMarketBoard,
          TableColumnKey.BsName,
        ].includes(column.key) ||
        fixedColumns?.includes(column.key)
      );
    },
    [fixedColumns]
  );

  const isEveryoneSelected = useMemo(() => {
    if (!isHaveCheckboxesColumn) {
      return false;
    } else {
      return (
        data.length > 0 && data.length === Object.keys(selectedHash).length
      );
    }
  }, [data, selectedHash, isHaveCheckboxesColumn]);

  const defaultColumns = useMemo(
    () =>
      filteredColumns.map((column, index) => (
        <Column
          key={`${column.key}-${index}`}
          id={`${column.key}-${index}`}
          cellRenderer={(rowIndex: number, columnIndex: number) => {
            const isFirstColumn = getIsFirstColumn(column, columnIndex);
            const isSelected =
              selectedRows.includes(rowIndex) ||
              hoverIndex === rowIndex ||
              contextMenuRow === rowIndex;
            const isEven = (rowIndex + 1) % 2 === 0;
            const withBorder = isBorderedRows && rowIndex < data.length - 1;

            return (
              <Cell
                className={cn(styles.cell, column.cellClassName, {
                  [styles.firstColumnCell]: isFirstColumn,
                  [styles.rowSelected]: isSelected,
                  [styles.doubleRowCell]: isDoubleRowMode,
                  [styles.borderedRowCell]: withBorder,
                  [styles.evenRowCell]: !isBorderedRows && isEven,
                })}
              >
                {cellRender(
                  rowIndex,
                  columnIndex,
                  column.key,
                  data,
                  setHoverIndex,
                  getColumnName(column),
                  isDoubleRowMode,
                  setContextMenuRow
                )}
                {hoverActions &&
                  columnIndex === firstVisibleColumnIndex &&
                  hoverIndex === rowIndex &&
                  hoverActions(
                    data[rowIndex],
                    (hover: boolean) =>
                      hover ? setHoverIndex(rowIndex) : setHoverIndex(null),
                    actionPos
                  )}
              </Cell>
            );
          }}
          columnHeaderCellRenderer={(columnIndex: number) => {
            const className = column.headerCellClassName;
            const columnName = getColumnName(column);
            const columnDescription = getColumnDescription(column);
            const isFirstColumn = getIsFirstColumn(column, columnIndex);

            return (
              <ColumnHeaderCell
                //Скрываем перенос для фиксированных колонок
                className={cn(
                  'headerCell',
                  column.withSort === false && 'headerCell_disabledSorting',
                  isDoubleRowMode && 'alignStartColumnHeader',
                  isDoubleRowMode && 'doubleRowHeaderCell',
                  isFirstColumn && 'firstColumnHeaderCell',
                  (getIsColumnStatic(column) ||
                    fixedColumns?.includes(column.key)) &&
                    'noReorder'
                )}
              >
                {typeof column.renderHeader === 'undefined' ? (
                  <div
                    className={className}
                    onClick={
                      column.withSort !== false
                        ? () => onSort?.(column.key)
                        : undefined
                    }
                  >
                    {/* Фильтр для колонки */}
                    {column.filterType &&
                      columnFilterRender(
                        column.key,
                        column.filterType,
                        filter,
                        column.filterSettings,
                        onFilter
                      )}
                    {/* Сортировки колонки (не отображаем для удаления и выделения) */}
                    {onSort &&
                      column.key !== TableColumnKey.IsSelected &&
                      column.withSort !== false && (
                        <ArrowUpCompactXsIcon
                          className={cn(
                            'sortIcon',
                            sort?.key === column.key && styles.activeSort,
                            !sort?.asc &&
                              sort?.key === column.key &&
                              styles.sortDesc
                          )}
                          height={12}
                          width={12}
                        />
                      )}
                    {columnDescription ? (
                      <TooltipDesktop
                        targetClassName={styles.tooltipTarget}
                        contentClassName={styles.tooltipContent}
                        content={columnDescription}
                        trigger="hover"
                        position="top-start"
                        onOpenDelay={500}
                        offset={[-20, 16]}
                      >
                        <TruncatedFormat>{columnName}</TruncatedFormat>
                      </TooltipDesktop>
                    ) : (
                      <TruncatedFormat>{columnName}</TruncatedFormat>
                    )}
                  </div>
                ) : (
                  <div className={className}>{column.renderHeader}</div>
                )}
                {/* Second row goes here */}
                {isDoubleRowMode && column.secondRow && (
                  <div
                    className={cn(
                      styles.doubleRowHeaderCellSecondRow,
                      'bp4-table-truncated-format-text'
                    )}
                  >
                    {getColumnName(column.secondRow)}
                  </div>
                )}
              </ColumnHeaderCell>
            );
          }}
        />
      )),
    [
      actionPos,
      cellRender,
      columnFilterRender,
      contextMenuRow,
      data,
      filter,
      filteredColumns,
      firstVisibleColumnIndex,
      fixedColumns,
      getIsFirstColumn,
      hoverActions,
      hoverIndex,
      isBorderedRows,
      isDoubleRowMode,
      onFilter,
      onSort,
      selectedRows,
      setContextMenuRow,
      sort,
    ]
  );

  const columns = useMemo(() => {
    const allColumns = isHaveCheckboxesColumn
      ? [
          <Column
            key="is--1"
            id="empty"
            cellRenderer={(rowIndex: number) => {
              const isEven = (rowIndex + 1) % 2 === 0;
              // Тип должен ограничивать до строки или числа, но не работает
              const key = data[rowIndex][selectedHashKey!];
              const isSelected =
                selectedRows.includes(rowIndex) ||
                hoverIndex === rowIndex ||
                contextMenuRow === rowIndex;

              return (
                <Cell
                  className={cn(styles.cell, {
                    [styles.doubleRowCell]: isDoubleRowMode,
                    [styles.rowSelected]: isSelected,
                    [styles.evenRowCell]: !isBorderedRows && isEven,
                  })}
                >
                  <Checkbox
                    onChange={(_, { checked }) =>
                      onChangeCheck!(checked, data[rowIndex])
                    }
                    boxClassName={styles.checkbox}
                    value={key as string | number}
                    checked={selectedHash[key]}
                    block
                  />
                </Cell>
              );
            }}
            columnHeaderCellRenderer={() => (
              <ColumnHeaderCell
                className={cn(
                  'headerCell',
                  isDoubleRowMode && 'alignStartColumnHeader',
                  isDoubleRowMode && 'doubleRowHeaderCell',
                  'noReorder',
                  'firstColumnHeaderCell'
                )}
              >
                <Checkbox
                  onChange={(_, { checked }) => onChangeCheckAll!(checked)}
                  boxClassName={styles.checkbox}
                  checked={isEveryoneSelected}
                />
              </ColumnHeaderCell>
            )}
          />,
        ]
      : [];

    const emptyColumn = (
      <Column
        key="empty--1"
        id="empty"
        cellRenderer={(rowIndex: number) => {
          const isEven = (rowIndex + 1) % 2 === 0;

          return (
            <Cell
              className={cn(styles.cell, {
                [styles.doubleRowCell]: isDoubleRowMode,
                [styles.evenRowCell]: !isBorderedRows && isEven,
              })}
            ></Cell>
          );
        }}
        columnHeaderCellRenderer={() => (
          <ColumnHeaderCell
            className={cn(
              'headerCell',
              'headerCell_noResize',
              isDoubleRowMode && 'alignStartColumnHeader',
              isDoubleRowMode && 'doubleRowHeaderCell',
              'noReorder'
            )}
          />
        )}
      />
    );

    return [...allColumns, ...defaultColumns, emptyColumn];
  }, [
    contextMenuRow,
    data,
    defaultColumns,
    hoverIndex,
    isBorderedRows,
    isDoubleRowMode,
    isEveryoneSelected,
    isHaveCheckboxesColumn,
    onChangeCheck,
    onChangeCheckAll,
    selectedHash,
    selectedHashKey,
    selectedRows,
  ]);

  return (
    <div className={styles.tableContainer} ref={wrapperRef}>
      <Table2
        key={forceTableUpdateKey}
        ref={tableRef}
        numRows={data.length}
        enableRowResizing={false}
        enableRowHeader={false}
        enableMultipleSelection={false}
        defaultRowHeight={isDoubleRowMode ? 48 : 24}
        enableColumnReordering
        columnWidths={fixedColumnWidths}
        onColumnsReordered={handleColumnsReordered}
        selectionModes={
          onRowClick ? [RegionCardinality.CELLS] : SelectionModes.NONE
        }
        onVisibleCellsChange={onVisibleCellsChange}
        onSelection={onSelection}
        onColumnWidthChanged={onColumnWidthChanged}
        className={cn(styles.table, className)}
        {...tableProps}
      >
        {columns}
      </Table2>
    </div>
  );
};
