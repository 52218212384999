import { format, isValid, parse } from 'date-fns';
import { ru } from 'date-fns/locale';
import React, { useMemo } from 'react';
import { BaseModal } from '@alfalab/core-components/base-modal';
import { Button } from '@alfalab/core-components/button/modern';
import { Loader } from '@alfalab/core-components/loader';
import { ProgressBar } from '@alfalab/core-components/progress-bar';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';
import { SignMIcon } from '@alfalab/icons-glyph/SignMIcon';

import { useAppContext } from '@terminal/core/hooks/useAppContext';
import { shallow, useStore } from '@terminal/core/store';

import { FormatedText } from '../../../shared';
import { ConfirmModal } from '../ConfirmModal';

import { useSendAssignment, useSignCancel, useSignText } from './hooks';

import styles from './SigningDialog.module.css';

/**
 * @deprecated Актуальная версия компонента в пакетe `@terminal/lk-common-desktop`.
 * Этот компонент нужен, пока остаются "легаси" api в `@terminal/core/lib/rest` на основе функции `signOperation`
 * */
export const SigningDialog = () => {
  const [
    openDialog,
    operation,
    signText,
    signMultipleText,
    signProgress,
    signErrors,
    openSignDialog,
    closeSignOperation,
    confirmationDialog,
    closeConfirmation,
  ] = useStore(
    (state) => [
      state.lkSignDialogOpen,
      state.lkSignOperation,
      state.lkSignText,
      state.lkSignMultipleText,
      state.lkSignProgress,
      state.lkSignMultipleErrors,
      state.openLkSignDialog,
      state.closeLkSignOperation,

      state.lkSignConfirmationDialog,
      state.closeLkSignConfirmation,
    ],
    shallow
  );

  const { appLogo } = useAppContext();

  const { isFetching: textPending } = useSignText(operation?.url, openDialog);

  const { mutateAsync: sendAssignment, isLoading: signing } =
    useSendAssignment();

  const { mutateAsync: cancelSign } = useSignCancel();

  const signDate = useMemo(() => {
    if (signText) {
      const pattern =
        /Дата и время подписания поручения: (\d{2}\.\d{2}\.\d{4} \d{2}:\d{2})/im;
      const found = signText.match(pattern);

      if (found) {
        const dateStr = found[1];

        return parse(dateStr, 'dd.MM.yyyy HH:mm', new Date());
      }
    }
  }, [signText]);
  const showDate = signDate && isValid(signDate) ? signDate : new Date();

  const onConfirm = () => {
    closeConfirmation();
    openSignDialog();
  };

  const onDecline = () => {
    closeConfirmation();
    closeSignOperation();
  };

  const close = () => {
    if (operation?.url) {
      cancelSign(operation.url);
    }

    closeSignOperation();
  };

  const sign = async () => {
    if (signText && operation) {
      await sendAssignment({
        assignment: signText,
        messages: signMultipleText || undefined,
        operationUrl: operation.url,
      });
    }
  };

  return (
    <>
      <ConfirmModal
        open={confirmationDialog.open}
        title="Внимание!"
        text={confirmationDialog.text}
        confirmLabel="Продолжить"
        confirmIcon={<CheckmarkMIcon width={18} height={18} />}
        onClose={onDecline}
        onConfirm={onConfirm}
      />

      <BaseModal open={openDialog} onClose={close} className={styles.modal}>
        <div className={styles.container}>
          <div className={styles.modalHeader}>
            <Typography.Title tag="div" view="small" className={styles.title}>
              Текстовое поручение
            </Typography.Title>
            <CrossMIcon
              className={styles.closeIcon}
              width={18}
              height={18}
              onClick={close}
            />
          </div>
          <div>
            <div className={styles.top}>
              <div className={styles.logo}>{appLogo}</div>
              <span className={styles.date}>
                {format(showDate, 'dd MMMM yyyy HH:mm', { locale: ru })}{' '}
                {signDate ? 'Мск' : ''}
              </span>
            </div>
            {textPending ? (
              <Loader />
            ) : (
              <div>
                {signProgress && (
                  <div className={styles.multiplyProgress}>
                    <Typography.Text view="secondary-large">
                      Обработно: {signProgress.signed} из {signProgress.count}
                    </Typography.Text>
                    <ProgressBar
                      view="link"
                      value={Math.ceil(
                        (signProgress.signed / signProgress.count) * 100
                      )}
                    />
                  </div>
                )}

                {signErrors.length > 0 && (
                  <div className={styles.errors}>
                    <Typography.Text tag="p" view="primary-medium">
                      В некоторых поручениях возникли ошибки:
                    </Typography.Text>
                    {signErrors.map((err, index) => (
                      <Typography.Text
                        view="secondary-small"
                        key={index}
                        tag="p"
                      >
                        {err}
                      </Typography.Text>
                    ))}
                    <div className={styles.submit}>
                      <Button size="s" block onClick={close}>
                        Закрыть
                      </Button>
                    </div>
                  </div>
                )}

                {!signProgress && signErrors.length === 0 && (
                  <Space fullWidth={true} direction="vertical">
                    <Typography.Text
                      view="primary-small"
                      className={styles.text}
                      tag="div"
                    >
                      <FormatedText text={signText || ''} />
                    </Typography.Text>

                    <div className={styles.submit}>
                      <Button
                        size="s"
                        disabled={signing}
                        loading={signing}
                        onClick={sign}
                        leftAddons={<SignMIcon width={18} height={18} />}
                      >
                        Подписать поручение
                      </Button>
                    </div>
                  </Space>
                )}
              </div>
            )}
          </div>
        </div>
      </BaseModal>
    </>
  );
};
