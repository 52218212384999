import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import {
  AmountInput,
  AmountInputProps,
} from '@alfalab/core-components/amount-input';
import { Button } from '@alfalab/core-components/button/modern';
import { Gap } from '@alfalab/core-components/gap';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { SquareAcademicCapMIcon } from '@alfalab/icons-glyph/SquareAcademicCapMIcon';
import { TransferOfDebtMIcon } from '@alfalab/icons-glyph/TransferOfDebtMIcon';
import { CurrencyCodes, formatAmount } from '@alfalab/utils';

import { MINORITY } from '@terminal/core/constants/ui';
import { RazdelGroupType } from '@terminal/core/lib/client/entities';
import { wrapWithComponents } from '@terminal/core/lib/react/wrapWithComponents';
import { TradeAccount } from '@terminal/core/lib/rest/lkMoney';
import { useStore } from '@terminal/core/store';
import { TradingCertificateLock } from '@terminal/widgets';

import {
  AboutLimits,
  getAllBrokerAccountsFrom,
  getAvailableBrokerAccountsForTransfer,
  getLimit,
  getPriorityTradeAccount,
  getTradeAccountKey,
  isAmountFitLimit,
  MarginBanner,
  MarginTooltip,
  MoneyLimitProvider,
  MoneyLimitQueryParams,
  MoneyLimits,
  useClientAccounts,
  useMarginControl,
  useMoneyLimits,
  useMoneyTransferInternal,
} from '../../../../entities/TransferGoinvest';
import { FormSectionHeader } from '../../../../shared/ui/FormSectionHeader';
import { MarginIntegratedTest } from '../MarginIntegratedTest';
import { SelectTradeAccount } from '../SelectTradeAccount';

import styles from './MoneyInternal.module.css';

type Rule = (master: TradeAccount, slave: TradeAccount) => boolean;
// Список правил, описывающий разрешенные комбинации перевода с одного счета на другой
const rules: Rule[] = [
  // С фондового на фондовый можно переводить в любых комбинациях в рамках одного клиента
  (master, slave) =>
    master.placeCode === 'MICEX_SHR' &&
    master.placeCode === slave.placeCode &&
    master.subAccount !== slave.subAccount,
  // С фондового на валютный и срочный только в рамках субсчета
  // На срочный можно переводить только рубли
  (master, slave) =>
    master.placeCode === 'MICEX_SHR' &&
    master.placeCode !== slave.placeCode &&
    master.subAccount === slave.subAccount &&
    (master.currency === 'RUB' ||
      (master.currency !== 'RUB' && slave.placeCode !== 'FORTS')),
  // С срочного можно переводить только на фондовый в рамках одного субсчета
  (master, slave) =>
    master.placeCode === 'FORTS' &&
    slave.placeCode === 'MICEX_SHR' &&
    master.subAccount === slave.subAccount,
  // С валютного на фондовый в рамках одного субсчета
  (master, slave) =>
    master.placeCode === 'MICEX_SELT_TOD' &&
    slave.placeCode === 'MICEX_SHR' &&
    master.subAccount === slave.subAccount,
  // С валютного на валютный только между субсчетами
  (master, slave) => {
    const [treatyM] = master.subAccount.split('-');
    const [treatyS] = slave.subAccount.split('-');

    return (
      master.placeCode === 'MICEX_SELT_TOD' &&
      master.placeCode === slave.placeCode &&
      treatyM === treatyS &&
      master.subAccount !== slave.subAccount
    );
  },
];

// Список доступных площадок
const allowedPlaces = ['MICEX_SHR', 'MICEX_SELT_TOD', 'FORTS'];

const MoneyInternalComponent = React.memo(() => {
  const [accountFrom, setAccountFrom] = useState<TradeAccount | null>(null);
  const [accountTo, setAccountTo] = useState<TradeAccount | null>(null);
  const [amount, setAmount] = useState<number | null>(0);
  const [marginTestOpen, setMarginTestOpen] = useState(false);

  const marginCallAccount = useStore((state) => state.marginCallAccount);

  const {
    data: clientAccounts,
    isFetching: clientAccountsPending,
    isFetchingNextPage: clientAccountsPendingNext,
    hasNextPage: hasMoreClientAccounts,
    fetchNextPage: fetchMoreClienAccounts,
    refetch: refetchClientAccounts,
  } = useClientAccounts(false);

  const treaty = useMemo<number>(() => {
    if (accountFrom) {
      const [treaty] = accountFrom.subAccount.split('-');

      return parseInt(treaty);
    }

    return 0;
  }, [accountFrom]);

  const limitParams = useMemo<MoneyLimitQueryParams>(() => {
    return {
      treaty: treaty,
      accountFrom: accountFrom,
      accountTo: accountTo,
      type: 'INTRA',
      amount: (amount || 0) / MINORITY,
    };
  }, [treaty, accountFrom, accountTo, amount]);

  const [debouncedLimitParams] = useDebounce<MoneyLimitQueryParams>(
    limitParams,
    250
  );
  const { data: limits, isFetching: limitsPending } =
    useMoneyLimits(debouncedLimitParams);

  const {
    isTestPassed,
    showTestBanner,
    refetchTestingList,
    marginCurrency,
    showMarginTooltip,
    marginAvailable,
  } = useMarginControl({
    treaty,
    account: accountFrom,
    marginalOn: Boolean(limits?.marginalOn),
    currency: accountFrom?.currency || '',
    isDisallow: Boolean(accountFrom?.isDisallow),
  });

  const marginCurrencyWarning = marginCurrency
    ? null
    : 'Для выбранной валюты вывод с плечом недоступен';

  const { mutateAsync: moneyTransferMove, isLoading: transferPending } =
    useMoneyTransferInternal();

  // Выбираем предпочтительный счёт
  useEffect(() => {
    if (clientAccounts) {
      const allAccounts: TradeAccount[] =
        getAllBrokerAccountsFrom(clientAccounts);
      //Выбираем аккаунт с маржин коллом в качестве приоритетного
      const filteredByMargin =
        marginCallAccount &&
        marginCallAccount.idRazdelGroup !== RazdelGroupType.StocksAndBondsMarket
          ? allAccounts.filter(
              ({ subAccount }) => subAccount === marginCallAccount.code
            )
          : allAccounts;
      const priorityAccount = getPriorityTradeAccount(filteredByMargin);

      if (priorityAccount) {
        setAccountFrom(priorityAccount);
      }
    }
  }, [clientAccounts, marginCallAccount]);

  const onAccountFromSelect = (acc: TradeAccount) => {
    setAccountFrom(acc);
  };

  const onAccountToSelect = (acc: TradeAccount) => {
    setAccountTo(acc);
  };

  const onAmountChange: AmountInputProps['onChange'] = (e, { value }) => {
    if (value !== amount) {
      setAmount(value);
    }
  };

  const transferEverything = () => {
    const amount = limits ? getLimit(limits, false) * MINORITY : null;

    setAmount(amount);
  };

  const amountValid = isAmountFitLimit(
    (amount || 0) / MINORITY,
    limits,
    marginAvailable
  );

  const isReadyToGo = (): boolean => {
    if (!limits) {
      return false;
    }

    return amountValid && Boolean(accountFrom) && Boolean(accountTo);
  };

  const requestTransfer = async () => {
    if (!accountFrom || !amount || !accountTo) {
      return;
    }

    const transferAmount = formatAmount({
      value: amount,
      currency: accountFrom.currency as CurrencyCodes,
      minority: MINORITY,
      view: 'withZeroMinorPart',
    });

    await moneyTransferMove({
      params: {
        from: {
          subAccount: accountFrom.subAccount,
          placeCode: accountFrom.placeCode,
        },
        currency: accountFrom.currency,
        amount: amount / MINORITY,
        to: {
          subAccount: accountTo.subAccount,
          placeCode: accountTo.placeCode,
        },
      },
      signOptions: {
        successTitle: `Перевод на сумму ${transferAmount.formattedWithCurrency} выполнен`,
        successText: `Средства зачислены на счёт ${accountTo.subAccount} ${accountTo.placeName}`,
        successCallback: () => {
          refetchClientAccounts();
          setAmount(0);
        },
      },
    });
  };

  const tradeAccountsFrom = useMemo(() => {
    if (!clientAccounts) {
      return [];
    }

    const allAccounts: TradeAccount[] =
      getAllBrokerAccountsFrom(clientAccounts);

    return allAccounts.filter(
      (acc) => allowedPlaces.indexOf(acc.placeCode) >= 0
    );
  }, [clientAccounts]);

  const tradeAccountsTo = useMemo(() => {
    if (!clientAccounts || !accountFrom) {
      return [];
    }

    const accountsToAll = getAvailableBrokerAccountsForTransfer(
      accountFrom,
      clientAccounts
    );

    return accountsToAll
      .filter((acc) => allowedPlaces.indexOf(acc.placeCode) >= 0)
      .filter((acc) => acc.currency === accountFrom.currency)
      .filter((acc) => {
        return rules.some((rule) => rule(accountFrom, acc));
      });
  }, [clientAccounts, accountFrom]);

  // Проверка выбранного счёта для вывода на наличие в списке доступных
  useEffect(() => {
    if (
      accountFrom &&
      !tradeAccountsTo.some(
        (acc) => getTradeAccountKey(acc) === getTradeAccountKey(accountFrom)
      )
    ) {
      if (tradeAccountsTo.length) {
        const tradeAccountTo = marginCallAccount
          ? tradeAccountsTo.find(
              ({ subAccount, placeName }) =>
                subAccount === marginCallAccount.code &&
                placeName.includes(marginCallAccount.placeCode)
            ) || tradeAccountsTo[0]
          : tradeAccountsTo[0];

        setAccountTo(tradeAccountTo);
      } else {
        setAccountTo(null);
      }
    }
  }, [accountFrom, tradeAccountsTo, marginCallAccount]);

  const limitNode = (
    <MoneyLimits
      limits={limits}
      pending={limitsPending}
      currency={accountFrom ? accountFrom.currency : ''}
      marginAvailable={marginAvailable}
    />
  );

  const setNextTreaty = useCallback(() => {
    fetchMoreClienAccounts();
  }, [fetchMoreClienAccounts]);

  // Признак того, что сумма на счете и лимит одинаковые
  let limitDiffer =
    limits && accountFrom && limits.limit !== accountFrom.assetValue;

  return (
    <>
      <FormSectionHeader>Откуда</FormSectionHeader>
      <div className={styles.section}>
        <SelectTradeAccount
          selected={accountFrom}
          limitValue={limits && limits.limit}
          accounts={tradeAccountsFrom}
          accountsPending={clientAccountsPending || clientAccountsPendingNext}
          onAccountSelect={onAccountFromSelect}
          accountsFetcher={setNextTreaty}
          hasMore={hasMoreClientAccounts}
          hint={limitDiffer && <AboutLimits backLabel="Вывод" />}
        />
      </div>

      <FormSectionHeader>Куда</FormSectionHeader>
      <div className={styles.section}>
        <SelectTradeAccount
          selected={accountTo}
          accounts={tradeAccountsTo}
          onAccountSelect={onAccountToSelect}
        />
      </div>

      <FormSectionHeader tooltip={showMarginTooltip && <MarginTooltip />}>
        Сумма
      </FormSectionHeader>
      <div className={styles.section}>
        <Row>
          <Col width={{ mobile: 12, tablet: 6, desktop: 6 }}>
            <AmountInput
              block={true}
              autoComplete="off"
              size="s"
              bold={false}
              // @ts-expect-error
              currency={accountFrom?.currency || 'RUR'}
              value={amount}
              minority={MINORITY}
              placeholder="0"
              onChange={onAmountChange}
              integerLength={12}
              hint={
                isTestPassed
                  ? marginCurrencyWarning || limitNode
                  : marginCurrencyWarning
              }
              error={
                amount && amount > 0 && !amountValid ? limitNode : undefined
              }
            />
          </Col>
          <Col width={{ mobile: 12, tablet: 6, desktop: 6 }}>
            <Button
              size="xxs"
              view="tertiary"
              block
              disabled={!accountFrom || !limits || limits.limit < 0}
              onClick={transferEverything}
            >
              Перевести всё
            </Button>
          </Col>
        </Row>
      </div>

      {showTestBanner && (
        <div className={styles.section}>
          <MarginBanner
            title="Для вывода с плечом пройдите тест"
            btnLabel="Пройти тест"
            btnIcon={<SquareAcademicCapMIcon />}
            onClick={() => setMarginTestOpen(true)}
          />
        </div>
      )}

      <MarginIntegratedTest
        open={marginTestOpen}
        onClose={() => setMarginTestOpen(false)}
        backLabel="К переводам"
        onComplete={() => {
          setMarginTestOpen(false);
          refetchTestingList();
        }}
      />

      <Gap size="l" />
      <TradingCertificateLock>
        <Row>
          <Col width={{ mobile: 12, tablet: 6, desktop: 6 }}>
            <Button
              dataTestId="transfer-btn"
              size="xxs"
              block
              leftAddons={<TransferOfDebtMIcon width={18} height={18} />}
              loading={transferPending}
              disabled={!isReadyToGo()}
              onClick={requestTransfer}
            >
              Перевести
            </Button>
          </Col>
        </Row>
      </TradingCertificateLock>
    </>
  );
});

MoneyInternalComponent.displayName = 'MoneyInternalComponent';

export const MoneyInternal = React.memo(
  wrapWithComponents([MoneyLimitProvider])(MoneyInternalComponent)
);

MoneyInternal.displayName = 'MoneyInternal';
