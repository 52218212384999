import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { IiaConvertText } from '../model/types/IiaConvertText';
import { IiaConvertTextParams } from './types/IiaConvertTextParams';

export async function getIiaConvertText(
  params: IiaConvertTextParams
): Promise<IiaConvertText> {
  const res = await fetchLkResult<IiaConvertText | LKResult>(
    `/api/iia-convert/order-text/${params.treaty}`,
    'POST'
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}
