import React, { FC, ReactElement } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Gap } from '@alfalab/core-components/gap';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Typography } from '@alfalab/core-components/typography';

import styles from './ManagementResult.module.css';

interface ManagementResultProps {
  icon: ReactElement;
  title: string;
  description?: string;
  onClose: () => void;
}

export const ManagementResult: FC<ManagementResultProps> = (props) => {
  const { icon, title, description, onClose } = props;

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <SuperEllipse className={styles.icon}>{icon}</SuperEllipse>
        <Typography.Text
          view="primary-small"
          weight="bold"
          className={styles.title}
        >
          {title}
        </Typography.Text>
        {description && (
          <Typography.Text
            view="secondary-small"
            color="secondary"
            className={styles.description}
          >
            {description}
          </Typography.Text>
        )}
        <Gap size="2xl" />
        <div className={styles.action}>
          <ButtonDesktop block size="s" onClick={onClose}>
            Понятно
          </ButtonDesktop>
        </div>
      </div>
    </div>
  );
};
