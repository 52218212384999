import orderBy from 'lodash/orderBy';
import take from 'lodash/take';
import throttle from 'lodash/throttle';
import uniqBy from 'lodash/uniqBy';
import { useCallback, useRef, useState } from 'react';

import { TradeInfo } from '@terminal/core/types/TradesService';

const MAX_TRADE_FEED_LINES = 30;

export const useAllTrades = (minimum = 1, timeout = 500) => {
  const allTradesRef = useRef<TradeInfo[]>([]);
  const [renderTrades, setRenderTrades] = useState<TradeInfo[]>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledSetTrades = useCallback(
    throttle(() => {
      const filteredTrades = allTradesRef.current.filter(
        //По бизнес логике мы не должны показывать заявки с кол-вом = 0 ("не реальные" заявки)
        ({ Qty }) => Number(Qty) >= (minimum || 1)
      );

      //Если отфильтрованных по кол-ву заявок больше чем максимальное значение, то сбрасываем
      if (filteredTrades.length >= MAX_TRADE_FEED_LINES) {
        allTradesRef.current = take(filteredTrades, MAX_TRADE_FEED_LINES);
      }

      //Если кол-во всех заявок становится большим, то сбрасываем до значения отфильтрованных
      if (allTradesRef.current.length >= MAX_TRADE_FEED_LINES * 5) {
        allTradesRef.current = filteredTrades;
      }

      setRenderTrades(take(filteredTrades, MAX_TRADE_FEED_LINES));
    }, timeout),
    [timeout, minimum]
  );

  const addTrades = useCallback(
    (newTrades: TradeInfo[]) => {
      const merged = orderBy(
        [...newTrades, ...allTradesRef.current],
        'TradeTime',
        'desc'
      );
      const sanitized = uniqBy(merged, 'TradeNo');

      allTradesRef.current = sanitized;

      throttledSetTrades();
    },
    [throttledSetTrades]
  );

  const resetTrades = useCallback(() => {
    allTradesRef.current = [];
    setRenderTrades(allTradesRef.current);
  }, []);

  return [renderTrades, addTrades, resetTrades] as [
    TradeInfo[],
    (newTrades: TradeInfo[]) => void,
    () => void
  ];
};
