import { useMutation, UseMutationOptions } from 'react-query';

type FetchFunction<TParams, TData = unknown> = (
  params: TParams
) => Promise<TData>;

type MutationOptions<
  TParams,
  TData,
  TError,
  TContext = unknown,
  TSelected = TData
> = Omit<UseMutationOptions<TData, TError, TParams, TContext>, 'mutationFn'> & {
  mapper?: (data: TData) => Promise<TSelected> | TSelected;
};

export function createMutationFromRequest<TParams, TData, TError>(
  fetchFn: FetchFunction<TParams, TData>
) {
  return <TContext = unknown>(
    options?: MutationOptions<TParams, TData, TError, TContext>
  ) => {
    return useMutation<TData, TError, TParams, TContext>(
      async (params: TParams) => {
        const data = await fetchFn(params);

        if (options?.mapper) {
          return options.mapper(data);
        }

        return data;
      },
      options
    );
  };
}
