import { QuantityType } from '../../../lib/client/entities';

export type DetailedOrderAction = 'Error' | 'Start' | 'Success' | 'Confirm';

export interface DetailedOrderParams {
  orderDirection: string; // Buy | Sell
  orderSource: string; // Торговля | График | Стакан | Закрытие позициии
  ticker: string; // Тикер продукта (TSLA)
  productTitle: string; // Название продукта (Сбербанк БО-19)
  productGroup: string; // Акции, Облигации, Фьючерсы
  orderType?: string; // Лимитная, Рыночная, Стоп-маркет, Трейлинг стоп-маркет, Стоп-маркет и Тейк-профит, Тейлинг Стоп-маркет и Тейк-профи
  orderSum: number; // Сумма заявки
  quantityType: QuantityType; // Способ изменения количества
  activationDate?: number; // Дата активации заявки
  additionalInstructions?: string; // Дополнительные инструкции к заявке
  maturityDate: number; // Дата экспириации заявки
  isin?: string; // ISIN
  errorText?: string;
}

export const enum AnaliticsVolumeType {
  Volume = 'В лотах',
  Cash = 'На сумму',
}
