import { TagDesktop as Tag } from '@alfalab/core-components/tag/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkOnCircleMIcon } from '@alfalab/icons-glyph/CheckmarkOnCircleMIcon';
import { SelectionMaskMIcon } from '@alfalab/icons-glyph/SelectionMaskMIcon';

import { Dropdown } from '@terminal/common/components/Dropdown';
import { ObjectGroup } from '@terminal/core/lib/client/entities';

import { FILTERS_BY_TYPE } from '../../const/filters';

interface TypeOptionsProps {
  isShowChips: boolean;
  selectedTypes: ObjectGroup[];
  saveTypes: (positionTypes: ObjectGroup[]) => void;
}

export const TypeOptions = ({
  isShowChips,
  selectedTypes,
  saveTypes,
}: TypeOptionsProps) =>
  isShowChips ? (
    <>
      {FILTERS_BY_TYPE.map((props) => {
        const { name, idObjectGroup } = props;

        const checked = idObjectGroup.some((id) => selectedTypes.includes(id));

        return (
          <Tag
            checked={checked}
            key={name}
            size="xs"
            view="filled"
            onClick={() => {
              if (checked) {
                saveTypes(
                  selectedTypes.filter(
                    (statusFilter) => !idObjectGroup.includes(statusFilter)
                  )
                );
              } else {
                saveTypes([...selectedTypes, ...idObjectGroup]);
              }
            }}
          >
            {name}
          </Tag>
        );
      })}
    </>
  ) : (
    <>
      {FILTERS_BY_TYPE.map((props) => {
        const { name, idObjectGroup } = props;

        const checked = idObjectGroup.some((id) => selectedTypes.includes(id));

        return (
          <Dropdown.Item
            key={name}
            leftIcon={
              checked ? (
                <CheckmarkOnCircleMIcon
                  color="var(--control-accent-bg-color)"
                  height={18}
                  width={18}
                />
              ) : (
                <SelectionMaskMIcon
                  color="var(--color-dark-graphic-secondary)"
                  height={18}
                  width={18}
                />
              )
            }
            content={
              <Typography.Text view="secondary-large">{name}</Typography.Text>
            }
            onClick={() => {
              if (checked && selectedTypes.length !== 0) {
                saveTypes(
                  selectedTypes.filter(
                    (statusFilter) => !idObjectGroup.includes(statusFilter)
                  )
                );
              } else {
                saveTypes([...selectedTypes, ...idObjectGroup]);
              }
            }}
          />
        );
      })}
    </>
  );
