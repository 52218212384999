import {
  useAlfaDirectContext,
  useFullFI,
  useTradingLimitsMarkdown as useTradingLimitsMarkdownBase,
} from '@terminal/alfadirect/hooks';
import { OrderType } from '@terminal/core/lib/client/entities';
import { isSingleFORTSMarketAvailable } from '@terminal/core/lib/domain/isSingleFORTSMarketAvailable';
import { InstrumentItem } from '@terminal/core/types/core';
import {
  LimitLongFormatter,
  LimitSortFormatter,
  TradeLimits,
} from '@terminal/core/types/tradeLimits';
import { OrderSide } from '@terminal/core/types/trading';
import { OrderDimensionEnum } from '@terminal/core/types/ui';

import { useWidgetContext } from '../../../shared';
import { formatWithSuffix } from '../lib/formatWithSuffix';
import { getOneItemPrice } from '../lib/getOneItemPrice';
import { VolumeType } from '../model';

type Options = {
  idFi: InstrumentItem['idFI'];
  tradeLimits: TradeLimits;
  orderType: OrderType;
  side: OrderSide;
  bestAsk: number;
  bestBid: number;
  // Выбранная пользователем цена
  price: number;
  slippage: number;
  volumeType: VolumeType;
  // Сокращает отображение информации до (в шорт 100 лотов)
  isShortInfo: boolean;
};

export function useTradingLimitsHint(options: Options) {
  const {
    idFi,
    tradeLimits,
    bestAsk,
    bestBid,
    price,
    slippage,
    orderType,
    volumeType,
    side,
    isShortInfo,
  } = options;
  const { useSettings } = useWidgetContext();
  const { useFinInfoExt, accounts } = useAlfaDirectContext();
  const {
    defaultValues: { orderDimension },
  } = useSettings();

  const { currencyCode, idObjectGroup } = useFullFI(idFi) || {};
  const finInfoExt = useFinInfoExt(idFi);
  const finInfoExtItem = finInfoExt[idFi];

  const convertMoneyToOrderDimension = (
    money: number,
    withSuffix: boolean = true
  ) => {
    const oneItemPrice = getOneItemPrice({
      orderType,
      side,
      bestAsk,
      bestBid,
      price,
      slippage,
    });

    const oneLotPrice = oneItemPrice * (finInfoExtItem?.lot || 0);

    // Проверка на oneLotPrice, чтобы не получилось infinity
    const availableLots = Math.floor(money / (oneLotPrice || 1));

    if (orderDimension === OrderDimensionEnum.LOT) {
      return formatWithSuffix({
        value: availableLots,
        orderDimension,
        withSuffix,
      });
    }

    if (orderDimension === OrderDimensionEnum.PIECE) {
      return formatWithSuffix({
        value: availableLots * (finInfoExtItem?.lot || 0),
        orderDimension,
        withSuffix,
      });
    }

    return '';
  };

  const longAmountFormatter: LimitLongFormatter = (
    amount,
    ownAmount,
    rawAmount,
    rawOwnAmount
  ) => {
    if (volumeType === VolumeType.Volume) {
      if (rawOwnAmount) {
        const formattedOwnAmount = convertMoneyToOrderDimension(rawOwnAmount);
        const formattedAmount = convertMoneyToOrderDimension(rawAmount, false);

        return `доступно ${formattedOwnAmount} (в маржу ${formattedAmount})`;
      }

      const formattedAmount = convertMoneyToOrderDimension(rawAmount);

      return `доступно ${formattedAmount}`;
    }

    if (volumeType === VolumeType.Cash) {
      if (rawOwnAmount) {
        return `доступно ${ownAmount} (в маржу ${amount})`;
      }

      return `доступно ${amount}`;
    }

    return '';
  };

  const shortAmountFormatter: LimitSortFormatter = (amount, rawAmount) => {
    if (volumeType === VolumeType.Volume && rawAmount) {
      if (isShortInfo) {
        return `(в шорт ${convertMoneyToOrderDimension(rawAmount, false)})`;
      }

      return `доступно в шорт ${convertMoneyToOrderDimension(rawAmount)}`;
    }

    if (volumeType === VolumeType.Cash && rawAmount) {
      if (isShortInfo) {
        return `(в шорт ${amount})`;
      }

      return `доступно в шорт ${amount}`;
    }

    return '';
  };

  return useTradingLimitsMarkdownBase(tradeLimits, {
    orderDimension,
    lot: finInfoExtItem?.lot,
    currencyCode,
    idObjectGroup,
    isSingleFORTSMarket: isSingleFORTSMarketAvailable(accounts),
    formatLong: longAmountFormatter,
    formatShort: shortAmountFormatter,
    showForFutures: true,
    side,
    orderType,
  });
}
