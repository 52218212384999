import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { createLkSignSlice } from './createLkSignSlice';

import { LkSignSlice } from './types/LkSignSlice';

export type RootStore = LkSignSlice;

export const useSignStore = createWithEqualityFn<RootStore>()(
  createLkSignSlice,
  shallow
);
