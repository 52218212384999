import { useEffect, useRef, useState, VFC } from 'react';
import { Textarea } from '@alfalab/core-components/textarea';
import { SendMIcon } from '@alfalab/icons-glyph/SendMIcon';

import styles from './InputControl.module.css';

export type InputControlProps = {
  disabled?: boolean;
  sendMessage: (text: string) => Promise<void>;
};

export const InputControl: VFC<InputControlProps> = (props) => {
  const { sendMessage, disabled } = props;
  const [inputValue, setInputValue] = useState<string>('');
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleSendMessage = () => {
    sendMessage(inputValue).then(() => setInputValue(''));
  };

  return (
    <div className={styles.controlRow}>
      <Textarea
        maxRows={8}
        value={inputValue}
        placeholder="Введите сообщение..."
        onChange={(event) => setInputValue(event.target.value)}
        className={styles.input}
        ref={inputRef}
        disabled={disabled}
      />
      {inputValue.length > 0 && (
        <SendMIcon
          width={18}
          height={18}
          className={styles.send}
          onClick={handleSendMessage}
        />
      )}
    </div>
  );
};
