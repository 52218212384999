import { error } from 'loglevel';
import { z, ZodObject, ZodRawShape } from 'zod';

import { sessionStorage } from '../storages';

import { EmitHandler } from './types/EmitHandler';
import { UnsafeFlags } from './types/UnsafeFlags';

type Options = {
  /**
   * Имя функции установки фича-флагов, которая будет доступна через консоль
   * @default __setFeatureFlags
   * */
  setterName?: string;
  /**
   * Ключ для хранения состояния в sessionStorage. Если не задан, то состояние не сохраняется
   * */
  sessionStoreKey?: string;
  /**
   * Если `true`, то используются фича-флаги, переданные в url
   * @default false
   * */
  useGetParams?: boolean;
};

export class FeatureFlags<
  S extends ZodObject<ZodRawShape>,
  T extends Partial<z.infer<S>>
> {
  private readonly scheme: ZodObject<ZodRawShape>;
  private readonly featureFlags: T;
  private readonly emitHandlers: Record<keyof T, Set<EmitHandler>>;
  private readonly setterName: string;
  private readonly storeKey: string;

  /**
   * @param scheme - zod-схема используемых фича-флагов
   * @param defaultFeatureFlags - значения флагов по-умолчанию
   * @param {Options} options
   * */
  constructor(scheme: S, defaultFeatureFlags: T, options?: Options) {
    this.scheme = scheme;
    this.setterName = options?.setterName || '__setFeatureFlags';
    this.storeKey = options?.sessionStoreKey || '';

    this.featureFlags = defaultFeatureFlags;

    this.emitHandlers = Object.keys(this.featureFlags).reduce((acc, key) => {
      acc[key] = new Set([]);

      return acc;
    }, {} as Record<keyof T, Set<EmitHandler>>);

    const storedFlags = this.getFlagsFromStore();
    const urlFlags = options?.useGetParams ? this.getFlagsFromUrl() : {};
    const rawFlags = { ...defaultFeatureFlags, ...storedFlags, ...urlFlags };

    this.setFeatureFlags(rawFlags);

    if (typeof window !== 'undefined') {
      window[this.setterName] = (obj: T) => this.setFeatureFlags(obj);
    }
  }

  public emit<N extends keyof T, V extends T[N]>(name: N, value: V): void {
    const obj = { [name]: value };
    const parseResult = this.scheme.partial().safeParse(obj);

    if (!parseResult.success) {
      error(
        `invalid validation feature flag ${String(name)} with value ${value}`
      );
      error('feature flag error issues', parseResult.error?.issues);

      return;
    }

    if (!(name in parseResult.data)) {
      return;
    }

    const handlers = this.emitHandlers[name] ?? new Set([]);

    this.featureFlags[name] = value;
    handlers.forEach((handler) => handler(value));
  }

  public on<N extends keyof T, V>(name: N, callback: (value: V) => void) {
    if (!this.emitHandlers[name]) {
      this.emitHandlers[name] = new Set([]);
    }

    this.emitHandlers[name].add(callback);
  }

  public off<N extends keyof T, V>(name: N, callback: (value: V) => void) {
    const handlers = this.emitHandlers[name];

    handlers.delete(callback);
  }

  public get<N extends keyof T>(name: N) {
    return this.featureFlags[name];
  }

  public setFeatureFlags(obj: UnsafeFlags): void {
    Object.entries(obj).forEach(([name, value]) => {
      this.emit(name, value);
    });

    this.saveFlagsToStore();
  }

  private saveFlagsToStore(): void {
    if (this.storeKey) {
      sessionStorage.setItem(this.storeKey, this.featureFlags);
    }
  }

  private getFlagsFromStore(): UnsafeFlags {
    if (this.storeKey) {
      return sessionStorage.getItem<UnsafeFlags>(this.storeKey, {});
    }

    return {};
  }

  private getFlagsFromUrl(): UnsafeFlags {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlParams = Object.fromEntries(urlSearchParams);

    return this.urlParamsToJson(urlParams);
  }

  private urlParamsToJson(params: { [key: string]: string }) {
    const obj = {};

    for (let key in params) {
      try {
        obj[key] = JSON.parse(params[key] ?? '');
      } catch (_) {
        obj[key] = params[key];
      }
    }

    return obj;
  }
}
