import isNumber from 'lodash/isNumber';
import isUndefined from 'lodash/isUndefined';

import { ObjectGroup } from '../client/entities';
import { getDailyPriceChange } from './getDailyPriceChange';
import { getPrevPrice } from './getPrevPrice';
import { getPrice } from './getPrice';

type InstrumentData = {
  idObject: number;
  idObjectGroup?: ObjectGroup;
  prevLastDate?: Date;
  prevFairPriceDate?: Date;
  prevFairPrice?: number;
  prevQuote?: number;
  fairPrice?: number;
  prevLast?: number;
  last?: number;
};

type DerivedData = {
  lastPrice?: number;
  change?: number;
  close?: number;
};

/**
 * Функция высчитывает last, close и change который отображаются на экране.
 * */
export function calculateDerivedDataForInstrument({
  idObjectGroup,
  idObject,
  prevLastDate,
  prevFairPriceDate,
  prevFairPrice,
  prevQuote,
  fairPrice,
  prevLast,
  last,
}: InstrumentData): DerivedData {
  // Для сортировки в таблицах нужны все данные сразу, поэтому сразу добавляем вычисляемые ячейки
  let close: number | undefined;
  let lastPrice: number | undefined;
  let change: number | undefined;

  if (idObjectGroup) {
    if (
      !isUndefined(idObjectGroup) &&
      !isUndefined(prevLastDate) &&
      !isUndefined(prevFairPriceDate) &&
      !isUndefined(prevFairPrice) &&
      !isUndefined(prevQuote)
    ) {
      // Входящая цена
      close = getPrevPrice(
        prevLastDate,
        prevFairPriceDate,
        prevFairPrice,
        prevLast,
        prevQuote,
        idObject,
        idObjectGroup
      );
    }

    if (
      !isUndefined(prevLastDate) &&
      !isUndefined(prevFairPriceDate) &&
      !isUndefined(fairPrice) &&
      !isUndefined(idObjectGroup)
    ) {
      // Текущая цена
      lastPrice = getPrice(
        prevLastDate,
        prevFairPriceDate,
        fairPrice,
        last,
        prevLast,
        idObject,
        idObjectGroup
      )!;
    }

    if (isNumber(lastPrice) && isNumber(close)) {
      // Считаем процент изменения текущей цены от входящей
      // Документация по формуле - https://confluence.moscow.alfaintra.net/pages/viewpage.action?pageId=503369004
      change = getDailyPriceChange(lastPrice, close);
    }
  }

  return {
    lastPrice,
    change,
    close,
  };
}
