import cn from 'classnames';
import groupBy from 'lodash/groupBy';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { InputDesktopProps } from '@alfalab/core-components/input/desktop';
import {
  InputAutocompleteDesktop,
  InputAutocompleteDesktopProps,
} from '@alfalab/core-components/input-autocomplete/desktop';
import { Option } from '@alfalab/core-components/select/components/option';
import { OptionsList } from '@alfalab/core-components/select/components/options-list';
import { VirtualOptionsList } from '@alfalab/core-components/select/components/virtual-options-list';
import { useSelectWithLoading } from '@alfalab/core-components/select/presets';
import {
  GroupShape,
  OptionProps,
} from '@alfalab/core-components/select/typings';
import { TagDesktop } from '@alfalab/core-components/tag/desktop';
import { MagnifierMIcon } from '@alfalab/icons-glyph/MagnifierMIcon';

import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';
import {
  FILookupResult,
  lookupFininstrument,
  selectMarketBoard,
  selectObjects,
} from '@terminal/core/store/selectors';
import { MarketBoard } from '@terminal/core/types/core';
import { Widget } from '@terminal/core/types/layout';

import { useWidgetContext } from '../../shared/context';
import { SymbolCell } from '../SymbolCell/SymbolCell';

import styles from './FinInstrAutocomplete.module.css';

export interface FinInstrumentOption {
  key: string;
  content: FILookupResult;
}

export interface FinInstrumentGroupOption {
  label?: string;
  options: FinInstrumentOption[];
}

const OptionCard = (props: OptionProps) => {
  const { option } = props as OptionProps & { option: FinInstrumentOption };

  return (
    <Option {...props} size="s">
      <div className={styles.optionContent}>
        <div className={styles.optionName} title={option.content.nameObject}>
          {option.content.nameObject}
        </div>
        <TagDesktop
          size="xs"
          className={styles.optionMarketCode}
          title={
            option.content.placeCode + ' - ' + option.content.nameMarketBoard
          }
        >
          {option.content.codeMarketBoard}
        </TagDesktop>
        <TagDesktop
          size="xs"
          className={styles.optionSymbol}
          title={option.content.symbolObject}
        >
          {option.content.symbolObject}
        </TagDesktop>
      </div>
    </Option>
  );
};

export type FinInstrAutocompleteProps = Pick<
  InputAutocompleteDesktopProps,
  'onBlur' | 'label' | 'labelView' | 'error' | 'name' | 'block'
> & {
  idFi?: number;
  widget: Widget;
  nodeId?: string;
  onChange?: (fi: FILookupResult) => void;
  isSmall?: boolean;
};

export const FinInstrAutocomplete = ({
  idFi,
  nodeId,
  widget,
  onChange,
  onBlur,
  isSmall = true,
  ...restProps
}: FinInstrAutocompleteProps) => {
  const [selected, setSelected] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const [market, setMarket] = useState<string>('');
  const [options, setOptions] = useState<FinInstrumentGroupOption[]>([]);
  const [loading, setLoading] = useState(true);
  const [isFocused, setIsFocused] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const loadingProps = useSelectWithLoading({
    visibleOptions: 3,
    loading,
  });

  const { updateNode } = useWidgetContext();
  const {
    objectsTable: objects,
    finInstrumentsTable,
    marketBoardsTable: marketBoards,
  } = useAlfaDirectContext();

  const selectedMarketBoards = useMemo(
    () => selectMarketBoard(marketBoards),
    [marketBoards]
  );

  const selectedObjects = useMemo(() => selectObjects(objects), [objects]);
  const filterOptions = useDebouncedCallback((value: string = '') => {
    const lookupResults = lookupFininstrument(
      objects,
      finInstrumentsTable,
      marketBoards,
      value
    ).filter((item) => item.idMarketBoard !== MarketBoard.EUCLR);
    const options = lookupResults.map((result) => ({
      key: String(result.idFI),
      content: {
        ...result,
      },
    }));

    const groupedOptions: FinInstrumentGroupOption[] = Object.entries(
      groupBy(options, 'content.idObjectType.name')
    ).map(([key, value]) => ({
      label: key === 'N/A' ? 'Другое' : key,
      options: value,
    }));

    setOptions(groupedOptions);
    setLoading(false);
  }, 250);

  useEffect(() => {
    const finInstr = idFi ? finInstrumentsTable.get('idFI', idFi) : undefined;
    let object = finInstr
      ? selectedObjects.get('idObject', finInstr.idObject)
      : undefined;

    let market = finInstr
      ? selectedMarketBoards.get('idMarketBoard', finInstr.idMarketBoard)
      : undefined;

    const isExternalIdFiUpdate =
      finInstr && String(idFi) !== selected && (!object || !market);

    if (isExternalIdFiUpdate) {
      object = finInstr
        ? objects.get('idObject', finInstr.idObject)
        : undefined;

      market = finInstr
        ? marketBoards.get('idMarketBoard', finInstr.idMarketBoard)
        : undefined;
    }

    if (finInstr && object && market) {
      setValue(object.symbolObject);
      setMarket(market.nameMarketBoard);
      setSelected(String(idFi));
      filterOptions(object.nameObject);

      return;
    }

    filterOptions();
  }, [
    filterOptions,
    finInstrumentsTable,
    idFi,
    marketBoards,
    objects,
    selected,
    selectedMarketBoards,
    selectedObjects,
  ]);

  const handleOnInput = (value: string) => {
    setValue(value);

    setLoading(true);
    filterOptions(value);
  };

  const handleOnChange = useCallback(
    (selected: FinInstrumentOption) => {
      //TODO: Убрать проверку когда WorkspacePerf больше не будет использоваться
      if (nodeId) {
        if (widget === Widget.WATCHLIST) {
          setValue('');
          onChange?.(selected.content);
        } else {
          setSelected(selected.key);
          setValue(selected.content.symbolObject);
          setMarket(selected.content.nameMarketBoard);
          updateNode(
            nodeId,
            {
              idFi: selected.content.idFI,
              symbol: selected.content.symbolObject,
            },
            selected.content.symbolObject
          );
        }
      } else {
        setSelected(selected.key);
        setValue(selected.content.symbolObject);
        setMarket(selected.content.nameMarketBoard);
        onChange?.(selected.content);
        setIsFocused(false);
      }
    },
    [nodeId, onChange, updateNode, widget]
  );

  const inputProps: InputDesktopProps = useMemo(() => {
    const finInstr = idFi ? finInstrumentsTable.get('idFI', idFi) : undefined;

    const object = finInstr
      ? objects.get('idObject', finInstr.idObject)
      : undefined;

    return {
      ref: inputRef,
      rightAddons:
        selected && !isFocused ? (
          <MagnifierMIcon
            height={18}
            width={18}
            color="var(--color-dark-graphic-secondary)"
          />
        ) : null,
      leftAddons:
        selected && !isFocused ? (
          <>
            <SymbolCell
              symbolObject={object?.symbolObject}
              idObject={object?.idObject}
              onlyIcon
              key={idFi}
            />
            {market && (
              <div className={styles.marketBoardLabel}>
                <span className={styles.marketBoardPadding}>{value}</span>
                {market}
              </div>
            )}
          </>
        ) : (
          <MagnifierMIcon
            height={18}
            width={18}
            color="var(--color-dark-graphic-secondary)"
          />
        ),
    };
  }, [finInstrumentsTable, idFi, market, objects, isFocused, selected, value]);

  return (
    <InputAutocompleteDesktop
      block
      size="s"
      className={cn(styles.input, {
        [styles.customInput]: isSmall,
      })}
      // Передаем опшны с кастомными полями для использования в OptionCard
      options={options as unknown as GroupShape[]}
      optionsListClassName={styles.optionsList}
      value={value}
      selected={widget !== Widget.WATCHLIST ? selected : null}
      onInput={(e) => handleOnInput(e?.target?.value || '')}
      onChange={(payload) => {
        const value = payload.selected as FinInstrumentOption | null;

        value && handleOnChange(value);

        inputRef.current?.blur();
      }}
      Option={OptionCard}
      OptionsList={loading ? OptionsList : VirtualOptionsList}
      optionsSize="s"
      optionsListWidth="field"
      closeOnSelect
      zIndexPopover={3}
      preventFlip={false}
      inputProps={inputProps}
      onFocus={() => setIsFocused(true)}
      onBlur={(event) => {
        setIsFocused(false);
        onBlur?.(event);
      }}
      {...restProps}
      {...loadingProps}
    />
  );
};
