import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FC } from 'react';

import { Item } from '../Item';

import { ItemProps, Position } from '../../types';

export const SortableItem: FC<ItemProps> = (props) => {
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    over,
    index,
  } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };

  return (
    <Item
      ref={setNodeRef}
      style={style}
      withOpacity={isDragging}
      listeners={listeners}
      //Этот параметр нужен для расчета "указателя" дропа (место помечается вертикальной чертой)
      {...(over?.id === props.id
        ? {
            insertPosition:
              index > props.activeIndex! ? Position.After : Position.Before,
          }
        : {})}
      {...props}
      {...attributes}
    />
  );
};
