import { useStore } from '@terminal/core/store';

import { Treaty } from '../model/types/Treaty';

/**
 * @returns счёт по умолчанию
 * Сначала выбирает счёт по переданному id, затем — по требованиям
 * */
export function useDefaultTreaty(
  treaties: Treaty[],
  selectedAccountId?: number
): Treaty | undefined {
  const [treaty] = treaties;
  const marginCallAcc = useStore((state) => state.marginCallAccount);

  if (selectedAccountId) {
    return treaties.find((acc) => acc.treaty === selectedAccountId) || treaty;
  }

  if (marginCallAcc) {
    return treaties.find((acc) => acc.name === marginCallAcc.code) || treaty;
  }

  return treaties.find((acc) => acc.selected) || treaty;
}
