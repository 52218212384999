import { AttorneyStatus } from '@terminal/lk-core';

import { IdentityDocumentType } from '../../model/types/IdentityDocumentType';

export const ATTORNEY_STATUSES_MAP: Record<AttorneyStatus, string> = {
  [AttorneyStatus.ACTIVE]: 'Активные',
  [AttorneyStatus.ARCHIVE]: 'Архив',
};
export const IDENTITY_DOCUMENT_TYPE_MAP: Record<IdentityDocumentType, string> =
  {
    [IdentityDocumentType.RUSSIAN_PASSPORT]: 'Паспорт гражданина РФ',
    [IdentityDocumentType.FOREIGN_PASSPORT]: 'Иностранный паспорт',
    [IdentityDocumentType.DIPLOMATIC_PASSPORT]: 'Дип. паспорт гражданина РФ',
    [IdentityDocumentType.USSR_PASSPORT]: 'Паспорт гражданина СССР',
    [IdentityDocumentType.INTERNATIONAL_PASSPORT]:
      'Загранпаспорт гражданина РФ',
  };
