import { useSubAccountRazdel } from '@terminal/core/hooks/domain';
import { useInfiniteOperationsHistory } from '@terminal/core/hooks/useInfiniteOperationsHistory';

import { makeOperationHistoryTypes } from '../lib/makeOperationHistoryTypes';

export const useChapterDetailsTable = ({
  selectedAccountId,
  dateFilter,
  chapter,
}) => {
  const selectedSubAccount = useSubAccountRazdel().find(
    (subAccount) => subAccount.idAccount === selectedAccountId
  )?.codeSubAccount;

  const { data, ...other } = useInfiniteOperationsHistory({
    manual: true,
    subAccount: selectedSubAccount || '',
    dateFrom: new Date(dateFilter.valueFrom.date).toISOString(),
    dateTo: new Date(dateFilter.valueTo.date).toISOString(),
    operationTypes: makeOperationHistoryTypes(chapter),
  });

  return {
    data: (data?.pages || []).map((op) => ({
      ...op,
      key: op.operation.id,
    })),
    ...other,
  };
};
