import { useMemo } from 'react';
import { Amount, CurrencyCodes } from '@alfalab/core-components/amount';
import {
  MarkdownDesktop,
  MarkdownDesktopProps,
} from '@alfalab/core-components/markdown/desktop';
import { Typography } from '@alfalab/core-components/typography';

import { useAlfaDirectContext } from '@terminal/alfadirect/provider/react';
import {
  useFullFI,
  usePositionInfo,
  usePositions,
} from '@terminal/core/hooks/domain';
import { OrderType } from '@terminal/core/lib/client/entities';
import {
  getAssetUnits,
  getDimensionedUnit,
  getMinority,
} from '@terminal/core/lib/format';
import { ObjectTypes } from '@terminal/core/types/core';
import { ECurrencyId } from '@terminal/core/types/currencyId';
import { TradeLimits } from '@terminal/core/types/tradeLimits';
import { OrderSide } from '@terminal/core/types/trading';
import { OrderDimensionEnum } from '@terminal/core/types/ui';

import { useWidgetContext } from '../../../../shared';
import { VolumeType } from '../../model';

import { useTradingLimitsHint } from '../../hooks/useTradingLimitsHint';

import styles from './VolumeInputHint.module.css';

const MARKDOWN_OVERRIDES: MarkdownDesktopProps['overrides'] = {
  p: ({ children }) => children,
  strong: ({ children }) => children,
};

export type VolumeInputHintProps = {
  side: OrderSide;
  volumeType: VolumeType;
  idFi: number;
  selectedSubAccountId: number;
  selectedSubAccounts: string[];
  tradeLimits: TradeLimits;
  orderType: OrderType;
  bestAsk: number;
  bestBid: number;
  // Выбранная пользователем цена
  price: number;
  slippage: number;
};

export const VolumeInputHint = (props: VolumeInputHintProps) => {
  const {
    idFi,
    selectedSubAccountId,
    selectedSubAccounts,
    volumeType,
    side,
    tradeLimits,
    bestBid,
    bestAsk,
    slippage,
    price,
    orderType,
  } = props;

  const fullFi = useFullFI(idFi);
  const { subAccountRazdels } = useAlfaDirectContext();

  const positions = usePositions();

  const { position } = usePositionInfo(
    fullFi,
    selectedSubAccountId,
    selectedSubAccounts,
    positions,
    subAccountRazdels
  );

  const { useSettings } = useWidgetContext();
  const {
    defaultValues: { orderDimension },
  } = useSettings();
  const {
    torgPos = 0,
    torgPosCost = null,
    object,
    currency,
    finInfoExt,
  } = position ?? {};

  const tradingLimitsMarkdown = useTradingLimitsHint({
    idFi,
    tradeLimits,
    side,
    bestAsk,
    bestBid,
    price,
    slippage,
    orderType,
    volumeType,
    isShortInfo: Boolean(torgPos),
  });

  const positionsInfo = useMemo(() => {
    const dimensionedUnit = getDimensionedUnit(
      torgPos,
      finInfoExt?.lot,
      orderDimension
    );

    if (dimensionedUnit === 0) {
      return null;
    }

    // является ли текущий инструмент валютой, юзается чтоб показать валюту в кмопненте Amount где есть символ валюты
    // Те для баксов будет чет типа "15$ = 1000Р" а для акций "20 акций = 1000Р"
    const isCurrency = object?.idObjectType?.value === ObjectTypes.CC;
    // является ли текущий инструмент рублем
    const isRub = object?.idObject === ECurrencyId.RUR;

    if (volumeType === VolumeType.Volume && !isRub) {
      return (
        <>
          в портфеле{' '}
          {orderDimension === OrderDimensionEnum.LOT ? (
            <>
              <Amount.Pure
                value={dimensionedUnit * getMinority(dimensionedUnit)}
                minority={getMinority(dimensionedUnit)}
                view="withZeroMinorPart"
              />{' '}
              {getAssetUnits(dimensionedUnit, OrderDimensionEnum.LOT)}
            </>
          ) : (
            <>
              <Amount.Pure
                value={torgPos * getMinority(torgPos)}
                minority={getMinority(torgPos)}
                view="withZeroMinorPart"
                currency={
                  isCurrency
                    ? (object.symbolObject as CurrencyCodes)
                    : undefined
                }
              />
              {!isCurrency && ' шт'}
            </>
          )}
        </>
      );
    }

    if (volumeType === VolumeType.Cash && torgPosCost) {
      return (
        <>
          в портфеле на&nbsp;
          <Amount.Pure
            value={torgPosCost * getMinority(torgPosCost)}
            minority={getMinority(torgPosCost)}
            view="withZeroMinorPart"
            currency={currency}
          />
        </>
      );
    }
  }, [
    currency,
    finInfoExt?.lot,
    object?.idObject,
    object?.idObjectType.value,
    object?.symbolObject,
    orderDimension,
    torgPos,
    torgPosCost,
    volumeType,
  ]);

  return (
    <Typography.Text view="secondary-small">
      {side === 'sell' && (
        <>
          {positionsInfo}{' '}
          <MarkdownDesktop
            className={styles.inline}
            overrides={MARKDOWN_OVERRIDES}
          >
            {tradingLimitsMarkdown}
          </MarkdownDesktop>
        </>
      )}
      {side === 'buy' && (
        <>
          <div>{positionsInfo}</div>
          <MarkdownDesktop overrides={MARKDOWN_OVERRIDES}>
            {tradingLimitsMarkdown}
          </MarkdownDesktop>
        </>
      )}
    </Typography.Text>
  );
};
