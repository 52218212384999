import get from 'lodash/get';
import { useCallback } from 'react';
import { Amount } from '@alfalab/core-components/amount';
import { Typography } from '@alfalab/core-components/typography';
import { CurrencyCodes } from '@alfalab/utils';

import { Cell, CellRenderProps, SymbolCell } from '@terminal/common/ui';
import { Currencies } from '@terminal/core/constants/currencies';
import { MINORITY } from '@terminal/core/constants/ui';
import { getAvailableCurrency } from '@terminal/core/lib/currencies';
import { getStringDate } from '@terminal/core/lib/format';
import { isNotUndefined } from '@terminal/core/lib/isNotUndefined';
import { ScreenerBondEntity } from '@terminal/core/types/bondScreener';
import { TableColumnKey } from '@terminal/core/types/tableColumn';

import { alignLeftColumns } from '../model/consts';

import styles from './useCellRender.module.css';

export function useCellRender(
  onRowClick: (event: React.MouseEvent, row: ScreenerBondEntity) => void
): CellRenderProps<ScreenerBondEntity> {
  return useCallback(
    (rowIndex, columnIndex, columnKey, data, setHoverIndex) => {
      const cellValue = get(data[rowIndex], columnKey);
      const currency = get(data[rowIndex], 'currency');
      const isLeftAlign = alignLeftColumns.includes(columnKey);

      switch (columnKey) {
        case TableColumnKey.BsName:
          return (
            <Cell
              onClick={(e) => onRowClick(e, data[rowIndex])}
              onMouseEnter={() => setHoverIndex(rowIndex)}
              onMouseLeave={() => setHoverIndex(null)}
            >
              <SymbolCell
                idObject={data[rowIndex].objectId}
                symbolObject={data[rowIndex].name}
              />
            </Cell>
          );
        // Просто значение
        case TableColumnKey.BsUntilExpiration:
        case TableColumnKey.BsCouponPaymentPerYear:
        case TableColumnKey.BsSectorName:
        case TableColumnKey.BsISIN:
        case TableColumnKey.BsEmShortName:
        case TableColumnKey.BsCountryName:
        case TableColumnKey.BsDuration:
        case TableColumnKey.BsBondTypeName:
          return (
            <Cell
              onClick={(e) => onRowClick(e, data[rowIndex])}
              onMouseEnter={() => setHoverIndex(rowIndex)}
              onMouseLeave={() => setHoverIndex(null)}
              leftAlign={isLeftAlign}
            >
              {cellValue}
            </Cell>
          );
        // Даты
        case TableColumnKey.BsCupDate:
        case TableColumnKey.BsMatDate:
        case TableColumnKey.BsOffertDate:
        case TableColumnKey.BsRestructingDate:
          const date = isNotUndefined(cellValue)
            ? getStringDate(cellValue)
            : '';

          return (
            <Cell
              onClick={(e) => onRowClick(e, data[rowIndex])}
              onMouseEnter={() => setHoverIndex(rowIndex)}
              onMouseLeave={() => setHoverIndex(null)}
              leftAlign={isLeftAlign}
            >
              {date}
            </Cell>
          );
        // Да / Нет
        case TableColumnKey.BsAmortization:
        case TableColumnKey.BsIsOffert:
        case TableColumnKey.BsIsReplacement:
        case TableColumnKey.BsIsMortgageBonds:
        case TableColumnKey.BsIsRestructing:
        case TableColumnKey.BsIsStructuredNote:
        case TableColumnKey.BsAllowNonQualInvest:
        case TableColumnKey.BsIsSubordinatedDebt:
          let yesNoValue = '';

          if (isNotUndefined(cellValue)) {
            yesNoValue = cellValue ? 'Да' : 'Нет';
          }

          return (
            <Cell
              onClick={(e) => onRowClick(e, data[rowIndex])}
              onMouseEnter={() => setHoverIndex(rowIndex)}
              onMouseLeave={() => setHoverIndex(null)}
              leftAlign={isLeftAlign}
            >
              {yesNoValue}
            </Cell>
          );
        // С иконкой валюты или проценты
        case TableColumnKey.BsCupSize:
        case TableColumnKey.BsNominal:
        case TableColumnKey.BsNkd:
        case TableColumnKey.BsPrice:
        case TableColumnKey.BsYield:
        case TableColumnKey.BsCupYield:
        case TableColumnKey.BsYieldToOffert:
          const withPercentage = [
            TableColumnKey.BsPrice,
            TableColumnKey.BsYield,
            TableColumnKey.BsCupYield,
            TableColumnKey.BsYieldToOffert,
          ].includes(columnKey);

          const availableCurrency = withPercentage
            ? ''
            : getAvailableCurrency(currency);

          return (
            <Cell
              onClick={(e) => onRowClick(e, data[rowIndex])}
              onMouseEnter={() => setHoverIndex(rowIndex)}
              onMouseLeave={() => setHoverIndex(null)}
              leftAlign={isLeftAlign}
            >
              {cellValue ? (
                <>
                  <Amount
                    value={
                      cellValue ? Math.round(cellValue * MINORITY) : cellValue
                    }
                    view="withZeroMinorPart"
                    currency={availableCurrency as CurrencyCodes}
                    minority={MINORITY}
                    bold="none"
                    transparentMinor
                  />
                  {withPercentage ? ' %' : ''}
                </>
              ) : null}
            </Cell>
          );
        case TableColumnKey.BsAgencyRating:
          let classAgency: string | undefined = '';

          switch (cellValue) {
            case 'AAA':
            case 'AA':
            case 'A':
              classAgency = styles.agencyCellHigh;
              break;
            case 'BBB':
            case 'BB':
            case 'B':
              classAgency = styles.agencyCellMedium;
              break;
            case 'CCC':
            case 'CC':
            case 'C':
              classAgency = styles.agencyCellLow;
              break;
            default:
              break;
          }

          return (
            <Cell
              onClick={(e) => onRowClick(e, data[rowIndex])}
              onMouseEnter={() => setHoverIndex(rowIndex)}
              onMouseLeave={() => setHoverIndex(null)}
              leftAlign={isLeftAlign}
            >
              {cellValue ? (
                <div className={styles.alignLeftCell}>
                  <b className={classAgency}>• </b>
                  {cellValue}
                </div>
              ) : null}
            </Cell>
          );
        case TableColumnKey.BsCurrency:
          return (
            <Cell
              onClick={(e) => onRowClick(e, data[rowIndex])}
              onMouseEnter={() => setHoverIndex(rowIndex)}
              onMouseLeave={() => setHoverIndex(null)}
              leftAlign={isLeftAlign}
            >
              <div className={styles.alignLeftCell}>
                {cellValue}
                &nbsp;
                <Typography.Text view="secondary-large" color="secondary">
                  {Currencies[cellValue]}
                </Typography.Text>
              </div>
            </Cell>
          );
        case TableColumnKey.BsOffertType:
          const mapOffertType = {
            call: 'Call-оферта',
            put: 'Put-оферта',
            'доп. оферта': 'Доп. оферта',
          };

          return (
            <Cell
              onClick={(e) => onRowClick(e, data[rowIndex])}
              onMouseEnter={() => setHoverIndex(rowIndex)}
              onMouseLeave={() => setHoverIndex(null)}
              leftAlign={isLeftAlign}
            >
              {mapOffertType[cellValue]}
            </Cell>
          );
        case TableColumnKey.BsIsFloatingRate:
          let newCellValue = '';

          if (isNotUndefined(cellValue)) {
            newCellValue = cellValue ? 'Плавающий (флоатер)' : 'Фиксированный';
          }

          return (
            <Cell
              onClick={(e) => onRowClick(e, data[rowIndex])}
              onMouseEnter={() => setHoverIndex(rowIndex)}
              onMouseLeave={() => setHoverIndex(null)}
              leftAlign={isLeftAlign}
            >
              {newCellValue}
            </Cell>
          );
        default:
          return null;
      }
    },
    [onRowClick]
  );
}
