import cn from 'classnames';
import { format } from 'date-fns';
import { memo, PropsWithChildren, ReactNode } from 'react';
import { Typography } from '@alfalab/core-components/typography';

import { ChatMessage } from '@terminal/chat/types';

import styles from './Message.module.css';

export type MessageProps = PropsWithChildren<{
  isMe: boolean;
  message?: ChatMessage;
  hint?: ReactNode;
}>;

export const Message = memo<MessageProps>(function Message(props) {
  const { isMe, message, hint, children } = props;

  return (
    <div
      className={cn(
        styles.message,
        isMe ? styles.myMessage : styles.elseMessage,
        {
          [styles.withHint]: Boolean(hint),
        }
      )}
    >
      <div className={styles.messageBubbleContainer}>
        <div className={styles.messageBubble}>
          {message && (
            <>
              <Typography.Text view="secondary-large" className={styles.text}>
                {message.text}
              </Typography.Text>
              <span className={styles.timeFix} />
              <Typography.Text
                view="secondary-small"
                color="secondary"
                className={styles.time}
              >
                {format(message.dtChatMessage, 'HH:mm')}
              </Typography.Text>
            </>
          )}
          {!message && children}
        </div>
      </div>
      {hint && (
        <Typography.Text view="secondary-small" className={styles.hint}>
          {hint}
        </Typography.Text>
      )}
    </div>
  );
});
