import { v4 } from 'uuid';

import { getCharts } from '../../lib/getCharts';
import { getLinks } from '../../lib/getLinks';
import { Layout } from './layout';
import { LayoutLinks } from './layoutLinks';
import { Row } from './row';

// класс итоговой конфигурации
export class DefaultConfiguration {
  name: string;
  type: 'default' | 'custom';
  id: string;
  config: {
    layouts: Array<{
      global: Record<string, unknown>;
      borders: unknown[];
      layout: Row;
    }>;
    layoutLinks: Array<LayoutLinks>;
    layoutNames: Array<string>;
    activeLayoutKey: number;
    chart: Record<string, unknown>;
  };

  constructor(name: string) {
    this.name = name;
    this.type = 'default';
    this.id = v4();
    this.config = {
      layouts: [],
      layoutLinks: [],
      layoutNames: [],
      activeLayoutKey: 0,
      chart: {},
    };
  }

  addLayout(layout: Layout) {
    // получаем JSON экрана конфигурации, отрендерив все дочерние объекты
    const layoutElement = layout.render();

    // добавляем экран в конфигурацию
    this.config.layouts.push(layoutElement);
    // добавляем имя экрана в соответствующий массив
    this.config.layoutNames.push(layout.name);
    // собираем и добалвяем линковки виджетов для экрана
    const links = getLinks(layout.layout, layout.links);

    this.config.layoutLinks.push(links);
    // собираем и добавляем настройки для чартиков
    const charts = getCharts(layout.layout);

    this.config.chart = { ...this.config.chart, ...charts };
  }
}
