import { Bound, Colision, Position, Size } from '@terminal/core/types/layout';

import { DRAG_COLISION_SIZE } from '../const';

// Проверяем, совпадают ли координаты линии с другой с учетом погрешности, равной DRAG_COLISION_SIZE
const checkColision = (boundToCheck: number, line: number) =>
  boundToCheck - DRAG_COLISION_SIZE <= line &&
  line <= boundToCheck + DRAG_COLISION_SIZE
    ? boundToCheck
    : undefined;

export const detectDragOverlap = (
  bounds: Bound[],
  position: Position,
  size: Size
): Colision | undefined => {
  const { x: leftSide, y: topSide } = position;
  const rightSide = leftSide + size.width;
  const bottomSide = topSide + size.height;

  const hasColisions = bounds.some(
    ({ xLine, yLine }) =>
      (checkColision(xLine, leftSide) ||
        checkColision(xLine, rightSide) ||
        checkColision(yLine, topSide) ||
        checkColision(yLine, bottomSide)) !== undefined
  );

  if (hasColisions) {
    let { top, right, bottom, left } = {} as Colision;

    for (let i = 0; i < bounds.length; i++) {
      const { xLine, yLine } = bounds[i];

      const leftColision = checkColision(xLine, leftSide);

      if (leftColision !== undefined) {
        left = xLine;
      }

      const rightColision = checkColision(xLine, rightSide);

      if (rightColision !== undefined) {
        right = xLine;
      }

      const topColision = checkColision(yLine, topSide);

      if (topColision !== undefined) {
        top = yLine;
      }

      const bottomColision = checkColision(yLine, bottomSide);

      if (bottomColision !== undefined) {
        bottom = yLine;
      }
    }

    return {
      top,
      right,
      bottom,
      left,
    };
  } else {
    return;
  }
};
