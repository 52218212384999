import cn from 'classnames';
import { ReactNode } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { AddMIcon } from '@alfalab/icons-glyph/AddMIcon';
import { ShieldMIcon } from '@alfalab/icons-glyph/ShieldMIcon';

import { TradingStub } from '@terminal/common/components/TradingStub';

import styles from './DefaultStubComponent.module.css';

export interface CustomStubProps {
  title: ReactNode;
  isFullHeight: boolean;
  description: ReactNode;
  isAllowIssueCertificate: boolean;
  isCertificateExpired: boolean;
  buttonText: string;
  onActionClick: () => void;
  isDisabled?: boolean;
  size?: 's' | 'xs';
}

export const DefaultStubComponent = (props: CustomStubProps) => {
  const {
    title,
    isFullHeight,
    description,
    isAllowIssueCertificate,
    isCertificateExpired,
    buttonText,
    onActionClick,
    isDisabled,
    size,
  } = props;

  const isSizeS = size === 's';
  const isSizeXs = size === 'xs';

  return (
    <TradingStub
      size={size}
      icon={<ShieldMIcon />}
      title={title}
      isFullHeight={isFullHeight}
      description={description}
      actions={
        <>
          <div
            className={cn(styles.buttonWrapper, {
              [styles.buttonWrapper_size_s]: isSizeS,
              [styles.buttonWrapper_size_xs]: isSizeXs,
            })}
          >
            <ButtonDesktop
              onClick={onActionClick}
              disabled={isDisabled}
              loading={isDisabled}
              size="xs"
              block
              nowrap
              leftAddons={
                isAllowIssueCertificate &&
                !isSizeXs &&
                !isCertificateExpired ? (
                  <AddMIcon
                    width={16}
                    height={16}
                    color="var(--color-light-text-secondary)"
                  />
                ) : undefined
              }
            >
              {buttonText}
            </ButtonDesktop>
          </div>
        </>
      }
    />
  );
};
