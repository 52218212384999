import { MarginCallInfo } from '@terminal/core/types/marginCall';

export function getRequirementBySubAccount(
  marginCall: MarginCallInfo,
  code: string
) {
  const marginCallForAcc = marginCall[code];

  if (!marginCallForAcc) {
    return;
  }

  if (marginCallForAcc.immediateRequirements !== 0) {
    return 'immediateRequirements';
  }

  if (marginCallForAcc.requirements !== 0) {
    return 'requirements';
  }
}
