import { MarginParamsKey } from '../../../types/MarginParams';

/**
 * Создает ключ из заданных idAccount и isin.
 * Этот ключ используется в мапе MarginParamsMap для хранения маржинальных параметров.
 * Если isin не предоставлен, он устанавливается в пустую строку.
 * Результирующий ключ представляет собой строку формата <idAccount>__<isin>.
 * @param idAccount - idAccount, который будет использоваться в ключе.
 * @param {string} [isin=''] - isin, который будет использоваться в ключе.
 * @returns {MarginParamsKey<number, string>} Результирующий MarginParamsKey.
 * */
export const buildMarginParamsKey = (
  idAccount: number,
  isin: string = ''
): MarginParamsKey<number, string> => {
  return `${idAccount}__${isin}`;
};
