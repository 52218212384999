/* eslint-disable custom-eslint-plugins/files-export */

export const ANALYTICS = {
  CATEGORY: {
    APP_START: 'application_start',
    APP_ERROR: 'application_error',
    ORDER_TYPE: 'order_type',
    WATCH_LISTS: 'watchlists',
    TRADING_FORM: 'trading_form',
    TICKER_CATEGORY: 'ticker_category',
    SETTINGS_INSTRUMENT: 'setting_instrument',
    ADD_INSTRUMENT: 'add_instrument',
    TRANSFERS: 'transfers',
    TECH_TRACE: 'tech_trace',
    PERSBROKER: 'persbroker',
    MAIN_MENU: 'main_menu',
    CHARTIQ: 'chartiq',
    SUMMARY: 'Emitent',
    PORTFOLIO: 'portfolio',
    SHOWCASE: 'Showcase',
    IDEAS_LIST: 'Ideas list',
    IDEAS_PAGE: 'Ideas Page',
    MAIN_PANEL: 'mainPanel',
    MAIN_PANEL_HELP: 'mainPanelHelp',
    RUBBLES: 'Rubbles',
    PLACEMENT: 'Offering',
    PLACEMENT_CARD: 'Offering card',
    LONGREAD: 'Longread',
    PLACEMENT_ORDER: 'Offering order',
    SCREEN: 'Screen',
    DAILY_SUMMARY: 'Daily Summary',
    DFA: 'Dfa',
    DFA_PLACEMENTS: 'PlacementsDFA',
    GHOST_OFFER: 'Ghost Offer',
    CONTENT_PLATFORM: 'Content Platform',
    DOM_WIDGET: 'Dom widget',
    TRADING_WIDGET: 'Trading widget',
    CHART_WIDGET: 'Chart widget',
    TRADING_ORDER: 'Trading order',
    BOND_SCREENER_WIDGET: 'BondsScreenerWidget',
    TRADING_WINDOW: 'Trading Window',
  },
  ACTION: {
    APP_START_WT: 'ApplicationStartWt',
    AUTH: 'authorized',
    CREATE_ORDER_BUY: 'CreateOrderBuy',
    CREATE_ORDER_SELL: 'CreateOrderSell',
    CREATE_ORDER_BUY_WT: 'CreateOrderBuyWt',
    CREATE_ORDER_SELL_WT: 'CreateOrderSellWt',
    CHOOSE_WATCHLIST_WT: 'chooseWatchlistWt',
    ACTION_TAP_WT: 'ActionTapWt',
    DOM_TAP_WT: 'DomTapWt',
    TICKER_BUY_SELL_WT: 'tickerBuySellWt',
    SELECT_SIZE_WT: 'selectSizeWt',
    SELECT_SIZE: 'select_size',
    SELECT_NAME_WT: 'selectSizeWt',
    SELECT_NAME: 'select_name',
    ADD_INSTR_WT: 'AddInstrWt',
    ADD_INSTR: 'add_instr',
    EDIT_ORDER_SOURCE_WT: 'EditOrderSourseWt',
    EDIT_ORDER_SOURCE: 'EditOrderSourse',
    EDIT_ORDER_TYPE_WT: 'EditOrderTypeWt',
    EDIT_ORDER_TYPE: 'EditOrderType',
    REFILL_FROM_CARD: 'RefillFromCard',
    DICTIONARY_LOADED: 'dictionary_loaded',
    ALL_DICTIONARIES_LOADED: 'all_dictionary_loaded',
    PORTFOLIO_LOADED: 'portfolio_loaded',
    APP_IS_OPENED: 'app_is_opened',
    APP_IS_READY: 'app_is_ready',
    APP_ERROR: 'app_error',
    AUTH_PASSWORD: 'auth_password',
    SMS_SHOW: 'sms_show',
    SMS_ENTERED: 'sms_entered',
    LOGIN_ERROR: 'login_error',
    DOM: 'Dom',
    ACTION: 'Action',
    ISSUE_APPROVE_WT: 'issueApproveWt',
    ISSUE_APPROVE: 'issue_aprove',
    OPEN_CHAT: 'openChat',
    KEYS_CREATED: 'keys_created',
    READY_TO_SEND: 'ready_to_send',
    ENROLL_STARTED: 'enroll_started',
    ENTITY_SENT: 'entity_sent',
    MAIN_MENU_TAP_WT: 'mainMenuTapWt',
    TIMEFRAME: 'timeframe',
    CHART_STYLE: 'chartStyle',
    OPEN_ABOUT: 'Company',
    OPEN_INDICATORS: 'Issuers',
    OPEN_FINANCIALS: 'Financial',
    OPEN_NEWS: 'News',
    PORTFOLIO_PARTS: 'portfolio.parts',
    PORTFOLIO_PARTS_CHEEPS: 'portfolio.parts.cheeps',
    PORTFOLIO_FINANCES: 'portfolio.finances',
    PORTFOLIO_FINANCES_SORTING: 'portfolio.finances.sorting',
    PORTFOLIO_INDEX: 'portfolio.index',
    PORTFOLIO_INDEX_SORTING: 'portfolio.index.sorting',
    LOAD: 'Load',
    SHOW: 'Show',
    CLICK: 'Click',
    SWIPE: 'Swipe',
    CLOSE: 'Close',
    INSERT: 'Insert',
    LIKE: 'Like',
    DISLIKE: 'Dislike',
    SCREEN_VIEW: 'Screen view',
    CHANGE: 'Change',
    ERROR: 'Error',
    IMPRESSION: 'Impression',
    APPLY: 'Apply',
    START: 'Start',
    SUCCESS: 'Success',
    CONFIRM: 'Confirm',
    OPEN: 'Open',
  },
  LABEL: {
    USER: 'User',
    ALREADY: 'Already',
    TECH: 'Tech',
    ERROR: 'error',
    MENU: 'Menu',
    CONNTECTION_STATUS: 'ConnectionStatus',
    DESKTOP: 'Desktop',
    ADD_DESKTOP: 'AddDesktop',
    ADD_WIDGET: 'AddWidget',
    MONEY_TRANSFER: 'MoneyTransfer',
    CHAT: 'Chat',
    HELP: 'Help',
    MANUAL: 'Manual',
    ONBOARDING: 'Onboarding',
    MANUAL_LK: 'ManualLK',
    CERTIFICATES: 'certificates',
    IDEAS_WIDGET: 'Ideas wiget',
    IDEAS_WIDGET_CONTENT: 'Ideas wiget content',
    IDEAS_WIDGET_HEADER: 'Ideas wiget header',
    IDEA: 'Idea',
    FAVORITES_BS: 'Favorites bs',
    SWIPE_BUTTONS: 'Swipe buttons',
    CHIPS: 'Chips',
    SEARCH_CHIP: 'Search chip',
    SORT_CHIP: 'Sort chip',
    PAGE: 'Page',
    MARKDOWN_LINK: 'Markdown link',
    MORE_BUTTON: 'More button',
    BUY: 'Buy button',
    BANNER: 'Banner',
    LONGREAD: 'Longread',
    PLACEMENT_CARD: 'Offering card',
    PLACEMENT_WIDGET: 'Offering widget',
    ABOUT: 'About',
    ACTIVE_BUTTON: 'Active Button',
    SCROLL_DOWN: 'Scroll Down',
    LIKE: 'Like',
    DISLIKE: 'Dislike',
    PLACEMENT_BUY: 'buy',
    TOGGLE: 'toggle',
    OFFERING_INTEREST: 'Offering interest',
    OFFERING_VOLUME: 'Offering volume',
    SHOW_ON_SCREEN: 'Show on screen',
    SWITCH_TO_INDICATOR: 'Switching to the Indicator Detail Sceen',
    SEND_SHOWN_INDICATORS: 'Not everything is shown',
    SWITCH_TO_ARTICLE: 'Switching to the Article',
    SWITCH_TO_DETAILS: 'Switching to the Instrument Details',
    OPEN_FULL_INSTRUMENTS: 'Open full instruments',
    ARTICLE_SCREEN_CLOSED: 'Article screen closed',
    OPEN_EXTERNAL_RESOURCE: 'Open external resource',
    VOICE_OF_CLIENT: 'Voice of client',
    END_OF_THE_SCREEN: 'End of the Screen',
    HYPERLINK: 'Hyperlink',
    GHOST_OFFER_LOADED: 'Ghost Offer Loaded',
    TAKE_GHOST_OFFER: 'Take Ghost Offer',
    CLOSE_GHOST_OFFER: 'Close Ghost Offer',
    WIDGET: 'Widget',
    FEED: 'Feed',
    SECURITY_SELECTION: 'Security Selection',
    CATEGORY_SELECTION: 'Catergory Selection',
    REFRESH: 'Refresh',
    DOM: 'Dom',
    CENTER: 'Center',
    TRADING: 'Trading',
    CHART: 'Chart',
    CHART_TYPE: 'Chart Type',
    CHART_TIMEFRAME: 'Chart Timeframe',
    CHART_INSTRUMENTS: 'Chart Instruments',
    CHART_INDICATOR: 'Chart Indicator',
    CHART_DRAWING: 'Chart Drawing',
    CHART_ALERT: 'Chart Alert',
    VOLUME: 'VOLUME',
    EXPORT: 'Export',
    ORDER: 'Order',
    ADD: 'Add',
    ADD_FROM_INSTRUMENTS: 'AddFromInstruments',
    APPLY_FILTER: 'ApplyFilter',
    REMOVE_FILTER: 'RemoveFilter',
    ADD_FILTER: 'AddFilter',
    FILTER_BUTTON: 'FilterButton',
    OPEN_TEMPLATE: 'OpenTemplate',
    CHOSE_TEMPLATE_TAB: 'ChoseTemplateTab',
    APPLY_TEMPLATE: 'ApplyTemplate',
    CHANGE_TEMPLATE: 'ChangeTemplate',
    REMOVE_TEMPLATE: 'RemoveTemplate',
    NEW_TEMPLATE: 'NewTemplate',
    REMOVE_FILTERS: 'RemoveFilters',
    RESULT_TAB: 'ResultTab',
    ADD_TO_LIST: 'AddToList',
    REMOVE_CHOSEN: 'RemoveChosen',
    CONTEXT_MENU: 'СontextMenu',
    SETTINGS: 'Settings',
    SETTINGS_COLUMNS: 'SettingsColumns',
    SETTINGS_COLUMNS_SEARCH: 'SettingsColumnsSearch',
    SETTINGS_COLUMNS_SAVE: 'SettingsColumnsSave',
    SETTINGS_COLUMNS_CANCEL: 'SettingsColumnsCancel',
    DUPLICATE: 'Duplicate',
    CLOSE: 'Close',
    WINDOW: 'Window',
  },
} as const;

export const DICTIONARIES = {
  OBJECTS: 'objects',
  OBJECT_TYPES: 'object_types',
  FIN_INSTRUMENTS: 'fin_instruments',
  MARKET_BOARDS: 'market_boards',
  ORDER_PARAMS: 'order_params',
  CERTIFICATES: 'certificates',
} as const;

export const DIMENSIONS_SCHEMAS = {
  APP_VERSION: 'iglu:com.snowplowanalytics.mobile/application',
  CUSTOM: 'iglu:com.alfabank/custom_dimension/jsonschema/1-0-0',
  EXPERIMENTAL: 'iglu:com.alfabank/campaign_attribution',
  SCREEN_VIEW: 'iglu:com.snowplowanalytics.mobile/screen_view/jsonschema/1-0-0',
  STRATEGIES: 'iis/brokerage/advisor/advisorLite',
} as const;

export enum ActionSources {
  ISSUES,
  TRADINGFORM,
  DOM,
}

export const LOCATIONS = {
  PROFILE_SETTINGS: 'Настройки профиля',
  NEW_ACCOUNT: 'Открыть новый счет',
  MONEY_TRANSFER: 'Пополнение и переводы',
  LK_MAIN_INFO: 'ЛК-Операции со счетами-Осн. инфо',
  LK_ACCOUNTS: 'ЛК-Операции со счетами-Управление счетами',
  LK_TAXES_INFO: 'ЛК-Документы и налоги - Налоговая Инфо',
  LK_TAXES_REPORTS: 'ЛК-Документы и налоги - Отчеты',
  LK_TAXES_DOCS: 'ЛК-Документы и налоги - Заказ документов',
  LK_TAXES_HISTORY: 'ЛК-Документы и налоги - История',
  LK_SIGN: 'ЛК-Эл. подпись',
  LK_FREE_ORDER_NEW: 'ЛК-Произвольные поручения -  Новое',
  LK_FREE_ORDER_HISTORY: 'ЛК-Произвольные поручения - История',
  LK_CORP_MESSAGE: 'ЛК-Корп.действия - Сообщения',
  LK_CORP_HISTORY: 'ЛК-Корп.действия - История',
  LK_NOTIFICATIONS: 'ЛК-Уведомления',
  LK_CODES: 'ЛК-Кодовая таблица',
  PLACEMENT: 'Offering screen',
  PLACEMENT_SHOWCASE: 'Showcase screen',
  PLACEMENT_SHOWCASE_DETAILS: 'Offering card scereen',
  PLACEMENT_SHOWCASE_DETAILS_CARD: 'Offering card',
  PLACEMENT_SHOWCASE_DETAILS_ORDER: 'Offering order',
  CONTEST: 'Биржевой конкурс',
} as const;

export const ORDER_SOURCE = {
  TRADING_FORM: 'trading_form',
  DOM: 'dom',
  CHARTIQ: 'chartiq',
} as const;

export const COUNT_VISIBLE_ASSETS = 10;
