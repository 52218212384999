import {
  FEATURE_FLAG_CODE,
  featureFlags,
} from '@terminal/common/entities/ServiceNotifications';
import { FilterSettings } from '@terminal/core/types/layout';
import { TableColumnKey } from '@terminal/core/types/tableColumn';

export const getSettingsByKey = (key: string): FilterSettings | undefined => {
  const bondScreenerDictionaries = featureFlags.get(
    FEATURE_FLAG_CODE.BOND_SCREENER_DICTIONARIES
  );

  switch (key) {
    case TableColumnKey.BsYield:
    case TableColumnKey.BsYieldToOffert:
    case TableColumnKey.BsCupYield:
    case TableColumnKey.BsCouponPaymentPerYear:
      return {
        min: 0,
      };
    case TableColumnKey.BsIsFloatingRate:
      return {
        filterItems: [
          {
            value: false,
            name: 'Фиксированный',
            description:
              'Купонный платеж, который остается постоянным на протяжении всего срока облигации',
          },
          {
            value: true,
            name: 'Плавающий (флоатер)',
            description:
              'Купонная облигация, по которой размер купона заранее не известен',
          },
        ],
      };
    case TableColumnKey.BsAmortization:
      return {
        filterItems: [
          {
            value: 0,
            name: 'Без амортизации',
          },
          {
            value: 1,
            name: 'С амортизацией',
          },
        ],
      };
    case TableColumnKey.BsIsOffert:
      return {
        filterItems: [
          {
            value: false,
            name: 'Без оферты',
          },
          {
            value: true,
            name: 'С офертой',
          },
        ],
      };
    case TableColumnKey.BsIsStructuredNote:
      return {
        filterItems: [
          {
            value: true,
            name: 'Являются структурными',
          },
          {
            value: false,
            name: 'Не являются структурными',
          },
        ],
      };
    case TableColumnKey.BsIsReplacement:
      return {
        filterItems: [
          {
            value: true,
            name: 'Являются замещающими',
          },
          {
            value: false,
            name: 'Не являются замещающими',
          },
        ],
      };
    case TableColumnKey.BsIsSubordinatedDebt:
      return {
        filterItems: [
          {
            value: true,
            name: 'Являются субординированными',
          },
          {
            value: false,
            name: 'Не являются субординированным',
          },
        ],
      };
    case TableColumnKey.BsAllowNonQualInvest:
      return {
        toggleItem:
          'Показать облигации, доступные для неквалифицированных инвесторов',
      };
  }

  // Для справочников из фича тогла
  let newKey = [
    TableColumnKey.BsBondTypeName,
    TableColumnKey.BsSectorName,
    TableColumnKey.BsCountryName,
  ].includes(key as TableColumnKey)
    ? key.replace('Name', '')
    : key;

  const dictionary = bondScreenerDictionaries.find((x) => x.filter === newKey);

  if (!dictionary) {
    return;
  }

  return {
    filterItems: dictionary.values,
    withSearch: TableColumnKey.BsSectorName === key,
  };
};
