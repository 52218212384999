import cn from 'classnames';
import { useCallback, useState } from 'react';
import { Link } from '@alfalab/core-components/link';
import { Loader } from '@alfalab/core-components/loader';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { DocumentPdfMIcon } from '@alfalab/icons-glyph/DocumentPdfMIcon';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';

import { FunctionalModalSidebar } from '@terminal/common/components/FunctionalModal';
import { Portal } from '@terminal/common/components/Portal';
import { DownloadedFile } from '@terminal/core/lib/rest/lkApi';
import { EXTERNAL_LINKS, LK_API } from '@terminal/lk/legacy/const/externalUrls';
import { useDownloadFile } from '@terminal/lk/legacy/hooks';
import {
  isFirmAccount,
  isIisAccount,
  Treaty,
  useRiskDeclarationText,
  useScansList,
  useTradingPlatformsState,
} from '@terminal/lk-core';

import { FormatedText } from '../../../../shared';
import { getFatcaStateText } from '../../lib/getFatcaStateText';
import { DocumentItem } from '../DocumentItem';
import { FatcaForm } from '../FatcaForm';
import { FileDownloadLink } from '../FileDownloadLink';

import styles from './DocumentsList.module.css';

interface DocumentsListProps {
  treaty: Treaty | null;
  className?: string;
  showRiskDeclarationText?: boolean;
}

interface FileLoading {
  pending: boolean;
  file: DownloadedFile | null;
}

interface FileLoadList {
  [s: string]: FileLoading;
}

const FATCA_PDF_KEY = 'FATCA_PDF_KEY';
const ACCOUNT_ANKET_KEY = 'ACCOUNT_ANKET_KEY';
const DEPO_ANKET_KEY = 'DEPO_ANKET_KEY';

export const DocumentsList = (props: DocumentsListProps) => {
  const { treaty, className, showRiskDeclarationText = false } = props;

  const [ndflOpen, setNdflOpen] = useState(false);
  const [fortytwoOpen, setFortytwoOpen] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);
  const [fatcaOpen, setFatcaOpen] = useState(false);
  const [riskOpen, setRiskOpen] = useState(false);
  const [files, setFiles] = useState<FileLoadList>({});

  const { isFetching: riskDeclarationTextPending, data: riskDeclarationText } =
    useRiskDeclarationText(treaty?.treaty, showRiskDeclarationText);

  const {
    isFetching: platformsPending,
    data: platforms,
    refetch: platformsStateRefetch,
  } = useTradingPlatformsState(treaty?.treaty);

  const { isFetching: scansListPending, data: scansList } = useScansList(
    treaty?.treaty
  );

  const { mutateAsync: downloadFile } = useDownloadFile();

  const iis: boolean = isIisAccount(treaty);
  const firm: boolean = isFirmAccount(treaty);

  const requestFile = async (url: string, type: string) => {
    setFiles((prevFiles) => {
      const files = { ...prevFiles };

      files[type] = {
        pending: true,
        file: null,
      };

      return files;
    });

    const file = await downloadFile(url);

    setFiles((prevFiles) => {
      const files = { ...prevFiles };

      files[type] = {
        pending: false,
        file: file,
      };

      return files;
    });
  };

  const onFatcaClick = () => {
    if (!platforms) {
      return;
    }

    const fatca = platforms.fatca;

    if (
      fatca.canSignFatca &&
      (fatca.state === 'NotRegistred' || fatca.state === 'Registred')
    ) {
      setFatcaOpen(true);
    }
  };

  const onFatcaSignComplete = useCallback(() => {
    setFatcaOpen(false);
    platformsStateRefetch();
  }, [platformsStateRefetch]);

  return (
    <div className={cn(styles.container, className)}>
      <Typography.Text
        view="primary-small"
        color="primary"
        weight="bold"
        tag="p"
      >
        Документы
      </Typography.Text>

      <div className={styles.list}>
        <div className={styles.listHead}>
          <span className={styles.listHeadName}>Название</span>
          <span className={styles.listHeadCount}>Количество</span>
        </div>
        <DocumentItem
          name="Справка 2НДФЛ"
          count={
            scansListPending ? (
              <Loader className={styles.countLoader} />
            ) : (
              scansList?.['2ndfl'].length || '0'
            )
          }
          onClick={() => setNdflOpen(true)}
        />
        {!firm && (
          <DocumentItem
            view="odd"
            name="Об открытии брокерского счета"
            count="2"
            onClick={() => setAboutOpen(true)}
          />
        )}
        <DocumentItem
          name="FATCA (W-8BEN)"
          hint={platforms ? getFatcaStateText(platforms.fatca) : ''}
          tooltip={
            <TooltipDesktop
              contentClassName={styles.tooltipContent}
              position="top"
              preventOverflow={false}
              trigger="hover"
              content={
                <>
                  Для&nbsp;снижения налога с&nbsp;дивидендов
                  по&nbsp;американским акциям на&nbsp;СПб&nbsp;Бирже
                  {Boolean(EXTERNAL_LINKS.FATCA_ABOUT) && (
                    <Link
                      view="secondary"
                      href={EXTERNAL_LINKS.FATCA_ABOUT}
                      target="_blank"
                    >
                      Подробнее
                    </Link>
                  )}
                </>
              }
              fallbackPlacements={['left', 'right']}
            >
              <InformationCircleLineSIcon
                height={16}
                width={16}
                color="var(--color-light-graphic-secondary)"
              />
            </TooltipDesktop>
          }
          count={
            platformsPending ? <Loader className={styles.countLoader} /> : '1'
          }
          onClick={onFatcaClick}
        />
        <DocumentItem
          view="odd"
          name="1042-S"
          count={
            scansListPending ? (
              <Loader className={styles.countLoader} />
            ) : (
              scansList?.['1042s'].length || '0'
            )
          }
          onClick={() => setFortytwoOpen(true)}
        />
        {showRiskDeclarationText && (
          <DocumentItem
            name="Соглашение о рисках"
            count={
              riskDeclarationTextPending ? (
                <Loader className={styles.countLoader} />
              ) : (
                '1'
              )
            }
            onClick={() => setRiskOpen(true)}
          />
        )}
      </div>

      <Portal id="main-window-modals">
        <FunctionalModalSidebar
          open={ndflOpen}
          backLabel="Налоговая информация"
          onClose={() => setNdflOpen(false)}
        >
          <div className={styles.container}>
            {scansListPending && <Loader />}
            <Typography.Text
              view="primary-small"
              tag="p"
              weight="bold"
              color="primary"
            >
              Справка 2НДФЛ
            </Typography.Text>
            <div className={styles.files}>
              {scansList?.['2ndfl'].map((doc) => {
                const fileKey = `scan-${doc.anketScanId}`;

                return (
                  <div className={styles.file} key={fileKey}>
                    <FileDownloadLink
                      name={`Справка 2-НДФЛ за ${doc.year} год`}
                      pending={
                        Boolean(files[fileKey]) && files[fileKey].pending
                      }
                      leftAddon={<DocumentPdfMIcon width={18} height={18} />}
                      onDownload={() =>
                        requestFile(
                          `${LK_API.DOCUMENT_SCAN}/${doc.anketScanId}/Справка 2-НДФЛ за ${doc.year} год.pdf`,
                          fileKey
                        )
                      }
                      loadedUrl={
                        files[fileKey] && files[fileKey].file?.url
                          ? files[fileKey].file?.url
                          : ''
                      }
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </FunctionalModalSidebar>
      </Portal>

      <Portal id="main-window-modals">
        <FunctionalModalSidebar
          open={aboutOpen}
          backLabel="Налоговая информация"
          onClose={() => setAboutOpen(false)}
        >
          <div className={styles.container}>
            <Typography.Text
              view="primary-small"
              tag="p"
              weight="bold"
              color="primary"
            >
              Об открытии брокерского счета
            </Typography.Text>
            <div className={styles.files}>
              <div className={styles.file}>
                <FileDownloadLink
                  name={iis ? 'Анкета ИИС' : 'Анкета брокерского счета'}
                  pending={
                    Boolean(files[ACCOUNT_ANKET_KEY]) &&
                    files[ACCOUNT_ANKET_KEY].pending
                  }
                  leftAddon={<DocumentPdfMIcon width={18} height={18} />}
                  onDownload={() =>
                    requestFile(
                      `${LK_API.DOCUMENT_ACCOUNT_BROKER}/${treaty?.treaty}/Анкета счёта № ${treaty?.treaty}.pdf`,
                      ACCOUNT_ANKET_KEY
                    )
                  }
                  loadedUrl={
                    files[ACCOUNT_ANKET_KEY] &&
                    files[ACCOUNT_ANKET_KEY].file?.url
                      ? files[ACCOUNT_ANKET_KEY].file?.url
                      : ''
                  }
                />
              </div>
              <div className={styles.file}>
                <FileDownloadLink
                  name="Анкета счета депо"
                  pending={
                    Boolean(files[DEPO_ANKET_KEY]) &&
                    files[DEPO_ANKET_KEY].pending
                  }
                  leftAddon={<DocumentPdfMIcon width={18} height={18} />}
                  onDownload={() =>
                    requestFile(
                      `${LK_API.DOCUMENT_ACCOUNT_DEPO}/${treaty?.treaty}/Анкета счёта депо № ${treaty?.treaty}.pdf`,
                      DEPO_ANKET_KEY
                    )
                  }
                  loadedUrl={
                    files[DEPO_ANKET_KEY] && files[DEPO_ANKET_KEY].file?.url
                      ? files[DEPO_ANKET_KEY].file?.url
                      : ''
                  }
                />
              </div>
            </div>
          </div>
        </FunctionalModalSidebar>
      </Portal>

      <Portal id="main-window-modals">
        <FunctionalModalSidebar
          open={fortytwoOpen}
          backLabel="Налоговая информация"
          onClose={() => setFortytwoOpen(false)}
        >
          <div className={styles.container}>
            {scansListPending && <Loader />}
            <Typography.Text
              view="primary-small"
              tag="p"
              weight="bold"
              color="primary"
            >
              1042-S
            </Typography.Text>
            <div className={styles.files}>
              {scansList?.['1042s'].map((doc) => {
                const fileKey = `scan-${doc.anketScanId}`;

                return (
                  <div className={styles.file} key={fileKey}>
                    <FileDownloadLink
                      name={`1042-S за ${doc.year} год`}
                      pending={
                        Boolean(files[fileKey]) && files[fileKey].pending
                      }
                      leftAddon={<DocumentPdfMIcon width={18} height={18} />}
                      onDownload={() =>
                        requestFile(
                          `${LK_API.DOCUMENT_SCAN}/${doc.anketScanId}/1042-S за ${doc.year} год.pdf`,
                          fileKey
                        )
                      }
                      loadedUrl={
                        files[fileKey] && files[fileKey].file?.url
                          ? files[fileKey].file?.url
                          : ''
                      }
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </FunctionalModalSidebar>
      </Portal>

      <Portal id="main-window-modals">
        <FunctionalModalSidebar
          open={fatcaOpen}
          backLabel="Налоговая информация"
          onClose={() => setFatcaOpen(false)}
        >
          {platforms && platforms.fatca.state === 'Registred' && (
            <div className={styles.container}>
              <Typography.Text
                view="primary-small"
                weight="bold"
                color="primary"
                tag="p"
              >
                FATCA (W-8BEN)
              </Typography.Text>
              <div className={styles.files}>
                <div className={styles.file}>
                  <FileDownloadLink
                    name="Форма W-8BEN"
                    pending={
                      Boolean(files[FATCA_PDF_KEY]) &&
                      files[FATCA_PDF_KEY].pending
                    }
                    leftAddon={<DocumentPdfMIcon width={18} height={18} />}
                    onDownload={() =>
                      requestFile(
                        `${LK_API.FATCA_PDF_BASE}/${treaty?.treaty}/fatca.pdf`,
                        FATCA_PDF_KEY
                      )
                    }
                    loadedUrl={
                      files[FATCA_PDF_KEY] && files[FATCA_PDF_KEY].file?.url
                        ? files[FATCA_PDF_KEY].file?.url
                        : ''
                    }
                  />
                </div>
              </div>
            </div>
          )}
          {platforms && platforms.fatca.state === 'NotRegistred' && (
            <FatcaForm
              treaty={treaty}
              isOpen={fatcaOpen}
              onComplete={onFatcaSignComplete}
            />
          )}
        </FunctionalModalSidebar>
      </Portal>

      {showRiskDeclarationText && (
        <Portal id="main-window-modals">
          <FunctionalModalSidebar
            open={riskOpen}
            backLabel="Налоговая информация"
            onClose={() => setRiskOpen(false)}
          >
            <div className={styles.container}>
              {riskDeclarationTextPending && <Loader />}
              {riskDeclarationText && (
                <FormatedText text={riskDeclarationText.document || ''} />
              )}
            </div>
          </FunctionalModalSidebar>
        </Portal>
      )}
    </div>
  );
};
