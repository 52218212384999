import { WorkspaceConfiguration } from '@terminal/core/types/workspace';

import type { ConfigTemplate } from '../index';

export const createConfiguration = (template: ConfigTemplate) => {
  const configuration = {
    ...template.config,
    timeStamp: Math.floor(new Date().getTime()),
  };

  return {
    name: template.name,
    type: template.type,
    id: template.id,
    lastAutoSavedConfig: configuration,
    lastManualSavedConfig: configuration,
  } as WorkspaceConfiguration;
};
