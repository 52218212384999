import { DragEndEvent, DragStartEvent, UniqueIdentifier } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import cn from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import uniqBy from 'lodash/uniqBy';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 } from 'uuid';
import { BaseModal } from '@alfalab/core-components/base-modal';
import { ButtonDesktop as Button } from '@alfalab/core-components/button/desktop';
import { IconButton } from '@alfalab/core-components/icon-button';
import { Typography } from '@alfalab/core-components/typography';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';
import { PlusMIcon } from '@alfalab/icons-glyph/PlusMIcon';

import { WidgetsNameMap } from '@terminal/core/constants/Layout';
import {
  flattenColumns,
  makeDoubleColumns,
} from '@terminal/core/hooks/useDoubleRowMode';
import { getIsColumnStatic } from '@terminal/core/lib/helpers/getIsColumnStatic';
import { TableColumnSetting, Widget } from '@terminal/core/types/layout';
import { TableColumnKey } from '@terminal/core/types/tableColumn';

import { SortableGrid } from './components/SortableGrid';

import { Column, DoubleColumnSettingModalProps } from './types';

import styles from './DoubleColumnSettingModal.module.css';

export const DoubleColumnSettingModal = (
  props: DoubleColumnSettingModalProps
) => {
  const {
    isOpen,
    setIsOpen,
    columnsSetting,
    defaultColumnSetting,
    defaultTickerKey,
    defaultNameKey,
    widgetType,
    nodeId,
    updateNode,
    selectedDisplayInstrumentType,
  } = props;

  const isAnyRowDouble = Boolean(columnsSetting[0].secondRow);
  const startColumnSetting = (
    isAnyRowDouble
      ? (columnsSetting as Column[])
      : makeDoubleColumns(columnsSetting, widgetType)
  ).filter((item) => item.selected);

  const [items, setItems] = useState(startColumnSetting);
  const filteredItems = useMemo(
    () => items.filter(({ selected }) => Boolean(selected)),
    [items]
  );

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const flatColumnsSetting = flattenColumns(filteredItems, widgetType);

  const columnOptions = uniqBy(
    defaultColumnSetting.map((column) => {
      let result: TableColumnSetting = { ...column };

      if (
        column.key === TableColumnKey.SymbolObject ||
        column.key === TableColumnKey.NameObject
      ) {
        if (selectedDisplayInstrumentType === 'name') {
          result.key = defaultNameKey || TableColumnKey.NameObject;
        } else {
          result.key = defaultTickerKey || TableColumnKey.SymbolObject;
        }
      }

      return result;
    }),
    'key'
  );

  useEffect(() => {
    setItems(startColumnSetting);
    // если добавлять startColumnSetting будет бесконечный цикл
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnsSetting]);

  useEffect(() => {
    const result = cloneDeep(items);

    if (widgetType !== Widget.BALANCE) {
      if (selectedDisplayInstrumentType === 'name') {
        result[0].key = defaultNameKey || TableColumnKey.NameObject;
      } else {
        result[0].key = defaultTickerKey || TableColumnKey.SymbolObject;
      }
    }

    setItems(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDisplayInstrumentType, widgetType]);

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id);
  }, []);

  const handleDragEnd = useCallback(
    (event: DragEndEvent, widgetType: Widget) => {
      const { active, over } = event;

      if (active.id !== over?.id) {
        setItems((items) => {
          const oldIndex = items.findIndex(({ id }) => id === active.id);
          const newIndex = items.findIndex(({ id }) => id === over!.id);
          const newItem = items.find(({ id }) => id === over!.id);

          if (getIsColumnStatic(newItem, widgetType)) {
            return items;
          }

          return arrayMove(items, oldIndex, newIndex);
        });
      }

      setActiveId(null);
    },
    []
  );

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  const handleRemove = useCallback(
    (columnId: UniqueIdentifier) =>
      setItems((items) =>
        items.map((item) => {
          if (item.id !== columnId) {
            return item;
          }

          return merge(
            { ...item, selected: false },
            { secondRow: item.secondRow ? { selected: false } : undefined }
          );
        })
      ),
    []
  );

  const handleSelection = useCallback(
    (
      columnId: UniqueIdentifier,
      setting?: TableColumnSetting,
      secondRow?: boolean
    ) => {
      const alreadySelected = !setting;

      setItems((prevItems) => {
        return prevItems.reduce((acc, column) => {
          if (column.id === columnId) {
            if (secondRow) {
              if (alreadySelected) {
                //Если вторая строка уже была выбрана, сохраняем выключенное состояние
                acc.push({ ...column, secondRow: undefined });
                acc.push({ ...column.secondRow!, selected: false, id: v4() });
              } else {
                //Иначе перезаписываем вторую ячейку
                acc.push({
                  ...column,
                  secondRow: { ...setting, selected: true },
                });
              }
            } else {
              if (alreadySelected) {
                //Если у колонки есть вторая строка, то ставим ее на первою строчку
                if (column.secondRow) {
                  acc.push({ ...column.secondRow, selected: true, id: v4() });
                }

                //Cохраняем выключенное состояние
                acc.push({
                  ...column,
                  selected: false,
                  secondRow: undefined,
                });
              } else {
                acc.push({
                  ...(setting as TableColumnSetting),
                  id: column.id,
                  secondRow: column.secondRow,
                  selected: true,
                });
              }
            }

            return acc;
          }

          acc.push(column);

          return acc;
        }, [] as Column[]);
      });
    },
    []
  );

  const handleCloseModalWithoutSave = useCallback(() => {
    setIsOpen(false);
    let timer = setTimeout(() => {
      setItems(startColumnSetting as Column[]);
      clearTimeout(timer);
    }, 150);
  }, [setIsOpen, startColumnSetting]);

  return (
    <BaseModal
      open={isOpen}
      onClose={handleCloseModalWithoutSave}
      wrapperClassName={styles.containerModal}
    >
      <div className={styles.doubleRowWrapper}>
        <div className={styles.header}>
          <Typography.Text view="secondary-large" weight="bold">
            Настройка колонок {WidgetsNameMap.get(widgetType)}
          </Typography.Text>
          <IconButton
            size="xs"
            view="secondary"
            icon={CrossMIcon}
            onClick={handleCloseModalWithoutSave}
          />
        </div>
        <div className={styles.doubleContent}>
          <SortableGrid
            items={filteredItems}
            flatItems={flatColumnsSetting}
            activeId={activeId}
            handleDragStart={handleDragStart}
            handleDragEnd={(e) => handleDragEnd(e, widgetType)}
            handleDragCancel={handleDragCancel}
            handleRemove={handleRemove}
            handleSelection={handleSelection}
            columnsSettingOptions={columnOptions}
            widgetType={widgetType}
          />
          <Button
            onClick={() =>
              //Для пустой колонки достаточно только айди, далее через селекты наполняем
              setItems((prevItems) => [
                ...prevItems,
                { id: v4(), selected: true } as Column,
              ])
            }
            view="tertiary"
            size="xxs"
            block={false}
            className={cn(styles.customButton, styles.addColumnButton)}
            leftAddons={
              <PlusMIcon
                color="var(--color-dark-graphic-secondary)"
                width={18}
                height={18}
              />
            }
            disabled={filteredItems.length >= 12}
          >
            Добавить колонку
          </Button>
        </div>
        <div className={styles.footer}>
          <Button
            onClick={() =>
              setItems(makeDoubleColumns(defaultColumnSetting, widgetType))
            }
            view="ghost"
            size="xxs"
            className={cn(styles.customButton, styles.ghostButton)}
          >
            Настройки по умолчанию
          </Button>
          <div className={styles.buttonsWrapper}>
            <Button
              onClick={handleCloseModalWithoutSave}
              view="ghost"
              size="xxs"
              block
              className={styles.customButton}
            >
              Отмена
            </Button>
            <Button
              onClick={() => {
                const filledColumns = items.filter(
                  (column) => column.secondRow?.key || column.key
                );

                if (nodeId) {
                  updateNode(nodeId, {
                    tableProps: { columns: filledColumns },
                  });
                }

                setIsOpen(false);
              }}
              size="xxs"
              block
              className={styles.customButton}
              view="secondary"
            >
              Сохранить
            </Button>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};
