import { OrderType } from '@terminal/core/lib/client/entities';
import { OrderSide } from '@terminal/core/types/trading';

type Options = {
  orderType: OrderType;
  side: OrderSide;
  bestAsk: number;
  bestBid: number;
  // Выбранная пользователем цена
  price: number;
  slippage: number;
};

// Возвращает цену одной позиции в зависимости от типа заявки
export function getOneItemPrice(options: Options): number {
  const { orderType, side, bestAsk, bestBid, price, slippage } = options;
  let result = 0;

  switch (orderType) {
    case OrderType.LMT:
    case OrderType.STP:
    case OrderType.BRS:
    case OrderType.TRS:
    case OrderType.TBRS:
      result = price;

      break;
    case OrderType.MKT: {
      if (side === OrderSide.BUY) {
        result = bestAsk;
      }

      if (side === OrderSide.SELL) {
        result = bestBid;
      }

      break;
    }
    case OrderType.STL:
      result = price + (side === OrderSide.BUY ? 1 : -1) * slippage;

      break;

    default:
      break;
  }

  return result;
}
