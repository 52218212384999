import { useMutation } from 'react-query';

import { sendAssignment } from '@terminal/core/lib/rest/lkSignOperation';

/**
 * @deprecated используй решение на основе хука `useSignDialog`
 * */
export const useSendAssignment = () => {
  return useMutation(sendAssignment);
};
