import { FC, useEffect, useState } from 'react';
import { BaseModal } from '@alfalab/core-components/base-modal';
import { IconButton } from '@alfalab/core-components/icon-button';
import {
  Tab,
  TabsDesktop,
  TabsDesktopProps,
} from '@alfalab/core-components/tabs/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';

import { usePrevious } from '@terminal/core/hooks/usePrevious';

import {
  AnalyticsTab,
  AnalyticsTabMap,
  usePortfolioAnalyticsMetrics,
} from '../../entities/PortfolioAnalytics';
import { AccountPicker } from '../../features/AccountPicker';
import { PortfolioComposition } from '../../features/PortfolioComposition';
import { PortfolioReport } from '../../features/PortfolioReport';

import { useSelectedAccount } from '../../shared/hooks/useSelectedAccount';

import styles from './PortfolioAnalytics.module.css';

interface Props {
  nodeId?: string;
  isOpen: boolean;
  onClose: () => void;
  selectedSubAccounts: string[];
}

export const PortfolioAnalytics: FC<Props> = (props) => {
  const { isOpen, onClose, selectedSubAccounts } = props;

  const { subAccounts } = useSelectedAccount();
  const defaultAccountId = subAccounts[selectedSubAccounts[0]]?.[0]?.idAccount;

  const [selectedAccountId, setSelectedAccountId] =
    useState<number>(defaultAccountId);
  const [selectedTab, setSelectedTab] = useState<AnalyticsTab>(
    AnalyticsTab.REPORT
  );

  const {
    trackClickPortfolioAnalyticsTab,
    trackClosePortfolioAnalytics,
    trackOpenPortfolioAnalytics,
  } = usePortfolioAnalyticsMetrics();

  const handleChange: TabsDesktopProps['onChange'] = (_, { selectedId }) => {
    setSelectedTab(selectedId as AnalyticsTab);
    trackClickPortfolioAnalyticsTab(selectedId as AnalyticsTab);
  };

  const handleChangeSelectedAccount = (accountIds: number[]) =>
    setSelectedAccountId(accountIds[0]);

  const prevIsOpen = usePrevious(isOpen);

  useEffect(() => {
    if (prevIsOpen === isOpen) {
      return;
    }

    if (isOpen) {
      setSelectedAccountId(defaultAccountId);
      trackOpenPortfolioAnalytics();
    } else {
      trackClosePortfolioAnalytics();
    }
  }, [
    prevIsOpen,
    isOpen,
    trackOpenPortfolioAnalytics,
    trackClosePortfolioAnalytics,
    defaultAccountId,
  ]);

  useEffect(() => {
    setSelectedAccountId(defaultAccountId);
  }, [defaultAccountId]);

  return (
    <BaseModal open={isOpen} onClose={onClose} className={styles.modal}>
      {isOpen ? (
        <div className={styles.container}>
          <div className={styles.header}>
            <Typography.Text
              view="secondary-large"
              weight="bold"
              className={styles.quaternary}
            >
              Аналитика
            </Typography.Text>

            <div className={styles.rightButtons}>
              <AccountPicker
                selectedAccountsIds={[selectedAccountId]}
                onSelect={handleChangeSelectedAccount}
              />
              <IconButton
                size="xs"
                view="secondary"
                icon={CrossMIcon}
                onClick={onClose}
              />
            </div>
          </div>

          <div className={styles.body}>
            <TabsDesktop
              selectedId={selectedTab}
              onChange={handleChange}
              scrollable={false}
              size="s"
              className={styles.tabs}
            >
              <Tab
                id={AnalyticsTab.REPORT}
                title={AnalyticsTabMap[AnalyticsTab.REPORT]}
              >
                <PortfolioReport
                  selectedAccountId={selectedAccountId}
                  onClose={onClose}
                />
              </Tab>
              <Tab
                id={AnalyticsTab.COMPOSITION}
                title={AnalyticsTabMap[AnalyticsTab.COMPOSITION]}
              >
                <PortfolioComposition selectedAccountId={selectedAccountId} />
              </Tab>
            </TabsDesktop>
          </div>
        </div>
      ) : null}
    </BaseModal>
  );
};
