import cn from 'classnames';
import { useMemo, useRef, useState } from 'react';
import { TagDesktop as Tag } from '@alfalab/core-components/tag/desktop';
import { ChevronDownCompactMIcon } from '@alfalab/icons-glyph/ChevronDownCompactMIcon';

import { Dropdown } from '@terminal/common/components/Dropdown';
import { useOnClickOutside } from '@terminal/core/hooks/useOnClickOutside';
import { ObjectGroup } from '@terminal/core/lib/client/entities';

import { FILTERS_BY_TYPE } from '../../const/filters';
import { TypeOptions } from '../TypeOptions';

import styles from './TypeFilters.module.css';

const allTypes = [
  ObjectGroup.Stocks,
  ObjectGroup.Receipts,
  ObjectGroup.Bonds,
  ObjectGroup.Futures,
  ObjectGroup.EquityParticipations,
  ObjectGroup.Currency,
];

interface TypeFiltersProps {
  selectedTypes: ObjectGroup[];
  isShowChips: boolean;
  saveTypes: (positionTypes: ObjectGroup[]) => void;
}

export const TypeFilters = ({
  selectedTypes,
  isShowChips,
  saveTypes,
}: TypeFiltersProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const filtersButtonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside([dropdownRef, filtersButtonRef], () => setIsOpen(false));

  const isEverySelected = useMemo(
    () =>
      !selectedTypes.length ||
      allTypes.every((idObjectGroup) => selectedTypes.includes(idObjectGroup)),
    [selectedTypes]
  );

  const filtersCount = useMemo(() => {
    if (selectedTypes.length) {
      const firstType = selectedTypes[0];
      const firstName = FILTERS_BY_TYPE.find(({ idObjectGroup }) =>
        idObjectGroup.includes(firstType)
      )?.name;

      const restTypes =
        selectedTypes.length -
        1 -
        (selectedTypes.includes(ObjectGroup.Receipts) ? 1 : 0);

      if (restTypes) {
        return `${firstName} + ${restTypes}`;
      } else {
        return firstName ?? '';
      }
    } else {
      return '';
    }
  }, [selectedTypes]);

  return isShowChips ? (
    <TypeOptions
      isShowChips={isShowChips}
      selectedTypes={selectedTypes}
      saveTypes={saveTypes}
    />
  ) : (
    <>
      <Tag
        ref={filtersButtonRef}
        size="xs"
        view="filled"
        checked={selectedTypes.length > 0}
        onClick={() => setIsOpen((prev) => !prev)}
        onMouseDown={(e) => e.stopPropagation()}
        rightAddons={
          <ChevronDownCompactMIcon
            height={16}
            width={16}
            className={cn(styles.chevron, isOpen && styles.chevronUp)}
          />
        }
      >
        {isEverySelected ? 'Все инструменты' : filtersCount}
      </Tag>
      <Dropdown
        ref={dropdownRef}
        anchorElement={filtersButtonRef.current}
        open={isOpen}
        offset={[0, 4]}
        position="bottom-start"
        popperClassName={styles.poppover}
      >
        <TypeOptions
          isShowChips={isShowChips}
          selectedTypes={selectedTypes}
          saveTypes={saveTypes}
        />
      </Dropdown>
    </>
  );
};
