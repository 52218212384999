import cn from 'classnames';
import { VFC } from 'react';
import {
  MarkdownDesktop,
  MarkdownDesktopProps,
} from '@alfalab/core-components/markdown/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { BanknotesMIcon } from '@alfalab/icons-glyph/BanknotesMIcon';

import { TradingLimitsShowMode } from '@terminal/core/types/layout';
import { TradeLimitsResult } from '@terminal/core/types/tradeLimits';

import { useTradingLimitsMarkdown } from '../../hooks/useTradingLimitsMarkdown';

import styles from './LimitsBlock.module.css';

export type LimitsBlockProps = {
  tradeLimitsLong: TradeLimitsResult;
  tradeLimitsShort: TradeLimitsResult;
  idFi: number;
  tooltipView?: boolean;
  displaySetting?: TradingLimitsShowMode;
};

const MARKDOWN_OVERRIDES: MarkdownDesktopProps['overrides'] = {
  p: ({ children }) => <span>{children}</span>,
  strong: ({ children }) => <span className={styles.amount}>{children}</span>,
};

export const LimitsBlock: VFC<LimitsBlockProps> = (props) => {
  const {
    tradeLimitsLong,
    tradeLimitsShort,
    idFi,
    tooltipView = false,
    displaySetting,
  } = props;

  const tradingLimitsShortMarkdown = useTradingLimitsMarkdown(
    idFi,
    {
      short: tradeLimitsShort,
      long: tradeLimitsLong,
    },
    {
      side: 'sell',
      showMode: displaySetting,
    }
  );

  const tradingLimitsLongMarkdown = useTradingLimitsMarkdown(
    idFi,
    {
      short: tradeLimitsShort,
      long: tradeLimitsLong,
    },
    {
      side: 'buy',
      showMode: displaySetting,
    }
  );

  if (!tradingLimitsShortMarkdown && !tradingLimitsLongMarkdown) {
    return null;
  }

  return (
    <Typography.Text
      view="secondary-large"
      className={cn(styles.limitsBlock, { [styles.tooltipView]: tooltipView })}
    >
      <div className={styles.limitsBlockText}>
        <BanknotesMIcon width={16} height={16} className={styles.icon} />
      </div>
      <div className={styles.limitsWrapper}>
        <MarkdownDesktop overrides={MARKDOWN_OVERRIDES}>
          {tradingLimitsLongMarkdown}
        </MarkdownDesktop>
        <MarkdownDesktop overrides={MARKDOWN_OVERRIDES}>
          {tradingLimitsShortMarkdown}
        </MarkdownDesktop>
      </div>
    </Typography.Text>
  );
};
