import { VFC } from 'react';
import { PersonalManagerMIcon } from '@alfalab/icons-glyph/PersonalManagerMIcon';

import { useGetBrokerChatRooms } from '@terminal/chat/hooks/useGetBrokerChatRooms';
import { useStore as chatStore } from '@terminal/chat/store/useStore';
import { ChatGroupId } from '@terminal/chat/types';

import { ChatRoomListItem } from '../ChatRoomListItem';
import { SupportPanel } from '../SupportPanel';

import styles from './ChatRoomList.module.css';

export type ChatRoomListProps = {
  onEnterRoom: (id: string) => void;
  onClose?: () => void;
};

export const ChatRoomList: VFC<ChatRoomListProps> = (props) => {
  const { onEnterRoom, onClose } = props;

  const messages = chatStore((store) => store.messages);
  const chatGroups = chatStore((store) => store.chatGroups || {});
  const brokerChatList = useGetBrokerChatRooms(messages);

  return (
    <div className={styles.chatRoomList}>
      <SupportPanel onClose={onClose} />

      {brokerChatList
        .filter(
          ({ id }) => !Object.values(ChatGroupId).includes(id as ChatGroupId)
        )
        .map(({ id, description, missedCount }) => (
          <ChatRoomListItem
            key={id}
            id={id}
            title={chatGroups[id]?.nameChatGroup || 'Брокер РФ'}
            description={description}
            icon={PersonalManagerMIcon}
            missedCount={missedCount}
            onEnterRoom={onEnterRoom}
          />
        ))}
    </div>
  );
};
