import cn from 'classnames';
import { format, isBefore, startOfMonth } from 'date-fns';
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CalendarRange } from '@alfalab/core-components/calendar-range';
import { IconButton } from '@alfalab/core-components/icon-button';
import { Popover } from '@alfalab/core-components/popover';
import { TagDesktop as Tag } from '@alfalab/core-components/tag/desktop';
import { TooltipDesktop as Tooltip } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';
import { FilterMIcon } from '@alfalab/icons-glyph/FilterMIcon';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';

import { useOnClickOutside } from '@terminal/core/hooks/useOnClickOutside';
import { getColumnDescription } from '@terminal/core/lib/helpers/getColumnDescription';
import { getColumnName } from '@terminal/core/lib/helpers/getColumnName';
import { isNotUndefined } from '@terminal/core/lib/isNotUndefined';
import {
  Filter,
  FilterSettings,
  FilterType,
  FilterTypes,
  TableColumnSetting,
} from '@terminal/core/types/layout';

import {
  ComplexDateRange,
  DateRange,
  FilterButtons,
  MultiSelectFilter,
  NumberRange,
  NumberRangeDigits,
  prepareDateRange,
  prepareNumberRange,
  RadioSelectFilter,
  TextFilter,
  ToggleFilter,
} from '../../../Filter';

import styles from './ColumnFilter.module.css';

interface Props {
  columnKey: string;
  filterType: FilterType;
  filter?: Filter;
  filterSettings?: FilterSettings;
  onFilter?: (filter?: Filter) => void;
  isChips?: boolean;
  getChipsText?: (
    key: string,
    filter?: Filter,
    filterSettings?: FilterSettings
  ) => string;
  filterName?: string;
  isShowDeleteIcon?: boolean;
  onDeleteFilterClick?: (key: string) => void;
}

export const ColumnFilter = forwardRef(function ColumnFilter(
  {
    filter,
    columnKey,
    onFilter,
    filterType,
    filterSettings,
    isChips,
    getChipsText,
    isShowDeleteIcon = false,
    filterName,
    onDeleteFilterClick,
  }: Props,
  ref: ForwardedRef<{ setIsOpen: (isOpen: boolean) => void }>
) {
  const [isOpen, setIsOpen] = useState(false);
  const calendarFromRef = useRef<HTMLInputElement>(null);
  const calendarToRef = useRef<HTMLInputElement>(null);
  const anchorRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState(filter?.value);

  const isActive = useMemo(() => {
    return columnKey === filter?.key && filterType === FilterTypes.Toggle
      ? filter.value
      : isNotUndefined(filter?.value);
  }, [columnKey, filter, filterType]);

  useImperativeHandle(ref, () => ({ setIsOpen }));

  const onChange = useCallback(
    (value: any) => {
      switch (filterType) {
        case FilterTypes.NumberRangeDigits:
        case FilterTypes.NumberRange: {
          const { from, to } = value;

          if (typeof from === 'number' && typeof to === 'number' && from > to) {
            setValue({
              ...value,
              from: to,
              to: from,
            });
          } else {
            setValue(value);
          }

          break;
        }
        case FilterTypes.CalendarRange:
        case FilterTypes.ComplexDateRange:
        case FilterTypes.DateRange: {
          const { dateFrom, dateTo } = value;

          if (dateFrom && dateTo && isBefore(dateTo, dateFrom)) {
            setValue({
              ...value,
              dateFrom: dateTo,
              dateTo: dateFrom,
            });
          } else {
            setValue(value);
          }

          break;
        }

        default:
          setValue(value);
      }
    },
    [filterType]
  );

  const onCancel = useCallback(() => {
    onFilter?.();
    setValue(undefined);
    setIsOpen(false);
  }, [onFilter]);

  const handleSave = useCallback(() => {
    let innerValue = value;

    switch (filterType) {
      case FilterTypes.NumberRange: {
        innerValue = prepareNumberRange(value);
        break;
      }
      case FilterTypes.DateRange: {
        innerValue = prepareDateRange(value);
        break;
      }
    }

    onFilter?.({
      key: columnKey,
      value: innerValue,
      type: filterType,
    });

    setIsOpen(false);
  }, [filterType, columnKey, value, onFilter]);

  const filterView = useMemo(() => {
    if (filterType === FilterTypes.Text) {
      return <TextFilter filterValue={value} onChange={onChange} />;
    }

    if (filterType === FilterTypes.Array) {
      return (
        <MultiSelectFilter
          filterItems={filterSettings?.filterItems}
          filterValue={value}
          onChange={onChange}
          isChips={isChips}
          withSearch={filterSettings?.withSearch}
          listClassName={styles.multiSelectAutoScroll}
        />
      );
    }

    if (filterType === FilterTypes.DateRange) {
      return (
        <DateRange
          calendarFromRef={calendarFromRef}
          calendarToRef={calendarToRef}
          filterValue={value}
          maxDate={filterSettings?.maxDate}
          onChange={onChange}
        />
      );
    }

    if (filterType === FilterTypes.CalendarRange) {
      return (
        <CalendarRange
          valueFrom={
            value?.dateFrom ? format(value?.dateFrom, 'dd.MM.yyyy') : undefined
          }
          valueTo={
            value?.dateTo ? format(value?.dateTo, 'dd.MM.yyyy') : undefined
          }
          onChange={({ dateFrom, dateTo }) =>
            onChange({
              dateFrom: dateFrom ? new Date(dateFrom) : undefined,
              dateTo: dateTo ? new Date(dateTo) : undefined,
            })
          }
          defaultMonth={startOfMonth(new Date()).getTime()}
        />
      );
    }

    if (filterType === FilterTypes.ComplexDateRange) {
      return <ComplexDateRange filterValue={value} onChange={onChange} />;
    }

    if (filterType === FilterTypes.NumberRangeDigits) {
      return (
        <NumberRangeDigits
          filterValue={value}
          min={filterSettings?.min}
          max={filterSettings?.max}
          onChange={onChange}
        />
      );
    }

    if (filterType === FilterTypes.Toggle) {
      return (
        <ToggleFilter
          toggleItem={filterSettings?.toggleItem}
          filterValue={value}
          onChange={onChange}
        />
      );
    }

    if (filterType === FilterTypes.NumberRange) {
      return (
        <NumberRange
          filterValue={value}
          min={filterSettings?.min}
          max={filterSettings?.max}
          step={filterSettings?.step}
          onChange={onChange}
        />
      );
    }

    if (filterType === FilterTypes.RadioSelect) {
      return (
        <RadioSelectFilter
          filterItems={filterSettings?.filterItems}
          filterValue={value}
          onChange={onChange}
        />
      );
    }

    return null;
  }, [filterSettings, filterType, onChange, value, isChips]);

  useEffect(() => {
    setValue(filter?.value);
  }, [filter]);

  useOnClickOutside([dropdownRef, calendarFromRef, calendarToRef], (e) => {
    const anchor = anchorRef.current;

    //Закрываем только если это НЕ клик в кнопку управляющую дропдауном
    if (anchor && !anchor?.contains(e.target as Node)) {
      setIsOpen(false);
    }
  });

  return (
    //Предотвращаем всплытие, чтобы не сработала  функция сортировки
    <div onClick={(e) => e.stopPropagation()}>
      <div
        ref={anchorRef}
        onClick={() => setIsOpen(!isOpen)}
        style={{ display: 'flex' }}
      >
        {isChips ? (
          <Tag
            size="xs"
            view="filled"
            checked={isActive}
            rightAddons={
              isShowDeleteIcon && (
                <IconButton
                  className={styles.crossButton}
                  icon={CrossMIcon}
                  size="xxs"
                  onClick={(e) => {
                    e.stopPropagation();
                    onDeleteFilterClick?.(columnKey);
                  }}
                />
              )
            }
          >
            <>
              {getColumnName({ key: columnKey } as TableColumnSetting)}
              <Typography.Text
                className={styles.chipsDetail}
                view="secondary-large"
              >
                {getChipsText?.(columnKey, filter, filterSettings)}
              </Typography.Text>
            </>
          </Tag>
        ) : (
          <FilterMIcon
            className={cn(
              'filterIcon',
              isOpen && styles.filterIconOpen,
              isActive && styles.activeFilter
            )}
            height={12}
            width={12}
          />
        )}
      </div>
      <Popover
        popperClassName={cn(styles.popper, styles.filterPopover)}
        ref={dropdownRef}
        anchorElement={anchorRef.current}
        open={isOpen}
        offset={[0, 2]}
        position="bottom-start"
      >
        <div className={styles.popoverChildrenWrapper}>
          <FilterButtons value={value} onCancel={onCancel} onSave={handleSave}>
            <>
              <div className={styles.popoverTitle}>
                <Typography.Text
                  view="secondary-large"
                  color="secondary"
                  weight="bold"
                >
                  {filterName ??
                    getColumnName({ key: columnKey } as TableColumnSetting)}
                </Typography.Text>
                {getColumnDescription({
                  key: columnKey,
                } as TableColumnSetting) ? (
                  <Tooltip
                    contentClassName={styles.tooltipContent}
                    content={getColumnDescription({
                      key: columnKey,
                    } as TableColumnSetting)}
                    trigger="hover"
                    position="top-start"
                    offset={[-20, 16]}
                  >
                    <InformationCircleLineSIcon
                      style={{ marginLeft: '4px', marginBottom: '-2px' }}
                      height={16}
                      width={16}
                      color="var(--color-dark-graphic-secondary)"
                    />
                  </Tooltip>
                ) : (
                  <></>
                )}
              </div>
              {filterView}
            </>
          </FilterButtons>
        </div>
      </Popover>
    </div>
  );
});
