import { useQuery } from 'react-query';

import { fetchBrokerRequisitesQR } from '../api/fetchBrokerRequisitesQR';

type Params = Parameters<typeof fetchBrokerRequisitesQR> extends [infer P]
  ? P
  : never;

export const useBrokerRequisitesQR = (params: Params, enabled?: boolean) => {
  return useQuery(
    ['lk', 'requisites', 'file', 'qr', params],
    () => fetchBrokerRequisitesQR(params),
    {
      enabled,
    }
  );
};
