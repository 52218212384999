import { addYears } from 'date-fns';

import { PUShowMode } from '../types/subAccountPosition';

export enum IntervalIds {
  YESTERDAY = 'yesterday',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
  CUSTOM = 'custom',
}

export const INTERVALS = [
  {
    id: IntervalIds.YESTERDAY,
    title: 'Вчера',
  },
  {
    id: IntervalIds.WEEK,
    title: 'Неделя',
  },
  {
    id: IntervalIds.MONTH,
    title: 'Месяц',
  },
  {
    id: IntervalIds.QUARTER,
    title: 'Квартал',
  },
  {
    id: IntervalIds.YEAR,
    title: 'Год',
  },
  {
    id: IntervalIds.CUSTOM,
    title: 'Выбрать период',
  },
];

// Интервалы в будущее
export enum YearsIntervalIds {
  YEAR = 'year',
  YEAR_TO_FIVE = 'yearToFive',
  MORE_THEN_FIVE = 'moreThenFive',
}

export const YEAR_INTERVALS = [
  {
    id: YearsIntervalIds.YEAR,
    title: 'До 1 года',
    startDate: undefined,
    endDate: addYears(new Date(), 1),
  },
  {
    id: YearsIntervalIds.YEAR_TO_FIVE,
    title: '1-5 лет',
    startDate: addYears(new Date(), 1),
    endDate: addYears(new Date(), 5),
  },
  {
    id: YearsIntervalIds.MORE_THEN_FIVE,
    title: 'Более 5 лет',
    startDate: addYears(new Date(), 5),
    endDate: undefined,
  },
];

export const filtersByPU = [
  {
    name: 'Любая доходность',
    mode: PUShowMode.ANY,
  },
  {
    name: 'Прибыльные: день',
    mode: PUShowMode.UP_DAY,
  },
  {
    name: 'Прибыльные: все время',
    mode: PUShowMode.UP_ALL,
  },
  {
    name: 'Убыточные: день',
    mode: PUShowMode.DOWN_DAY,
  },
  {
    name: 'Убыточные: все время',
    mode: PUShowMode.DOWN_ALL,
  },
];
