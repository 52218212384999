import isEqual from 'lodash/isEqual';
import { useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import {
  AmountInput,
  AmountInputProps,
} from '@alfalab/core-components/amount-input';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { InputDesktop } from '@alfalab/core-components/input/desktop';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkCompactMIcon } from '@alfalab/icons-glyph/CheckmarkCompactMIcon';
import { CurrencyCodes } from '@alfalab/utils';

import { MINORITY } from '@terminal/core/constants/ui';
import { TradeAccount } from '@terminal/core/lib/rest/lkMoney';
import { TradingCertificateLock } from '@terminal/widgets';

import {
  CURRENCIES_COMMISSION,
  CurrencyComision,
  getLimit,
  isAmountFitLimit,
  MoneyLimitQueryParams,
  MoneyLimits,
  MoneyTaxDetail,
  TransferCompleteParams,
  useMoneyLimitContext,
  useMoneyLimits,
  useMoneyTransferFreeOrder,
} from '../../../../entities/TransferGoinvest';
import { FormControlSummary } from '../../../../shared/ui/FormControlSummary';
import * as Validation from './validation';

import { useTreaties } from '../../../../legacy/hooks';
import { useValidation } from '../../../../shared/hooks/useValidation';

import styles from './MoneyToOtherCurrency.module.css';

interface MoneyToOtherCurrencyProps {
  account: TradeAccount | null;
  onSuccess?: (params: TransferCompleteParams) => void;
}

export const MoneyToOtherCurrency = (props: MoneyToOtherCurrencyProps) => {
  const { account, onSuccess } = props;

  const [amount, setAmount] = useState<number | null>(null);
  const [bank, setBank] = useState('');
  const [bankSwift, setBankSwift] = useState('');
  const [beneficiary, setBeneficiary] = useState('');
  const [beneficiaryAccount, setBeneficiaryAccount] = useState('');
  const [beneficiaryAddress, setBeneficiaryAddress] = useState('');
  const [corrBank, setCorrBank] = useState('');
  const [corrBankSwift, setCorrBankSwift] = useState('');
  const [corrAccount, setCorrAccount] = useState('');
  const [paymentPurpose, setPaymentPurpose] = useState('');

  const treaty = useMemo<number>(() => {
    if (account) {
      const [treaty] = account.subAccount.split('-');

      return parseInt(treaty);
    }

    return 0;
  }, [account]);

  const { data: treaties } = useTreaties();
  const accountTreaty = useMemo(
    () => treaties?.find((t) => treaty === t.treaty),
    [treaties, treaty]
  );

  const values = useMemo(
    () => ({
      bank,
      bankSwift,
      beneficiary,
      beneficiaryAccount,
      beneficiaryAddress,
      corrBank,
      corrBankSwift,
      corrAccount,
      paymentPurpose,
    }),
    [
      bank,
      bankSwift,
      beneficiary,
      beneficiaryAccount,
      beneficiaryAddress,
      corrBank,
      corrBankSwift,
      corrAccount,
      paymentPurpose,
    ]
  );

  const validators = useMemo(
    () => ({
      bank: Validation.validateBank,
      bankSwift: Validation.validateBankSwift,
      beneficiary: Validation.validateBeneficiary,
      beneficiaryAccount: Validation.validateBeneficiaryAccount(accountTreaty),
      beneficiaryAddress: Validation.validateBeneficiaryAddress,
      corrBank: Validation.validateCorrBank,
      corrBankSwift: Validation.validateCorrBankSwift,
      corrAccount: Validation.validateCorrAccount,
      paymentPurpose: Validation.validatePaymentPurpose,
    }),
    [accountTreaty]
  );

  const { handleChange, handleBlur, touched, results, isValid } = useValidation(
    {
      values: values,
      validators: validators,
      handlers: {
        bank: setBank,
        bankSwift: setBankSwift,
        beneficiary: setBeneficiary,
        beneficiaryAccount: setBeneficiaryAccount,
        beneficiaryAddress: setBeneficiaryAddress,
        corrBank: setCorrBank,
        corrBankSwift: setCorrBankSwift,
        corrAccount: setCorrAccount,
        paymentPurpose: setPaymentPurpose,
      },
    }
  );

  const limitParams = useMemo<MoneyLimitQueryParams>(() => {
    return {
      treaty: treaty,
      accountFrom: account,
      type: 'EXTRA_OTHER',
      amount: (amount || 0) / MINORITY,
    };
  }, [treaty, account, amount]);

  const [debouncedLimitParams] = useDebounce<MoneyLimitQueryParams>(
    limitParams,
    250
  );
  const { data: limits, isFetching: limitsPending } =
    useMoneyLimits(debouncedLimitParams);

  const { limit: contextLimit, setLimit } = useMoneyLimitContext();

  useEffect(() => {
    if (limits && !isEqual(limits, contextLimit)) {
      setLimit(limits);
    }
  }, [limits, contextLimit, setLimit]);

  const { mutateAsync: moneyTransferFreeOrder, isLoading: transferPending } =
    useMoneyTransferFreeOrder();

  const pureAmount = useMemo(() => {
    return amount ? amount / MINORITY : 0;
  }, [amount]);

  const onAmountChange: AmountInputProps['onChange'] = (_, { value }) => {
    if (value !== amount) {
      setAmount(value);
    }
  };

  const transferEverything = () => {
    const amount = limits ? getLimit(limits, false) * MINORITY : null;

    setAmount(amount);
  };

  const formReady = useMemo(() => {
    return (
      Boolean(account) && isValid() && isAmountFitLimit(pureAmount, limits)
    );
  }, [account, isValid, pureAmount, limits]);

  const sendRequest = () => {
    if (formReady && account) {
      moneyTransferFreeOrder({
        params: {
          portfolio: account.subAccount,
          currency: account.currency,
          amount: pureAmount,
          placeCode: account.placeCode,
          beneficiary,
          beneficiaryAccount,
          beneficiaryAddress,
          bank,
          bankSwift,
          corrBank,
          corrBankSwift,
          corrAccount,
          paymentPurpose,
        },
        signOptions: {
          successCallback: (status) => {
            setAmount(0);
            onSuccess &&
              onSuccess({
                type: 'success',
                status: status?.data?.orderStatus,
                currency: account.currency as CurrencyCodes,
              });
          },
        },
      });
    }
  };

  const taxes = limits ? limits.taxes : undefined;

  const limitNode = (
    <MoneyLimits
      limits={limits}
      currency={account?.currency || ''}
      pending={limitsPending}
      marginAvailable={false}
    />
  );

  const amountValid = isAmountFitLimit(pureAmount, limits, false);

  return (
    <div className={styles.container}>
      <Typography.Text
        view="primary-small"
        weight="bold"
        tag="p"
        defaultMargins={false}
        className={styles.sectionTitle}
      >
        Куда
      </Typography.Text>
      <Space
        fullWidth
        direction="vertical"
        size={12}
        className={styles.section}
      >
        <Row>
          <Col width={6}>
            <InputDesktop
              label="Beneficiary’s bank / Банк получателя"
              labelView="outer"
              placeholder="Укажите наименование"
              size="s"
              block
              name="bank"
              value={bank}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.bank ? results.bank.message : ''}
            />
          </Col>
          <Col width={6}>
            <InputDesktop
              label="SWIFT / Код Банка получателя"
              labelView="outer"
              placeholder="Укажите код"
              size="s"
              block
              name="bankSwift"
              value={bankSwift}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.bankSwift ? results.bankSwift.message : ''}
            />
          </Col>
        </Row>
        <Row>
          <Col width={6}>
            <InputDesktop
              label="Beneficiary / Получатель"
              labelView="outer"
              placeholder="Укажите наименование"
              size="s"
              block
              name="beneficiary"
              value={beneficiary}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.beneficiary ? results.beneficiary.message : ''}
            />
          </Col>
          <Col width={6}>
            <InputDesktop
              label="Beneficiary’s account / Счёт получателя"
              labelView="outer"
              placeholder="Укажите номер счёта"
              size="s"
              block
              name="beneficiaryAccount"
              value={beneficiaryAccount}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.beneficiaryAccount
                  ? results.beneficiaryAccount.message
                  : ''
              }
              maxLength={20}
              hint="20 цифр"
            />
          </Col>
        </Row>
        <Row>
          <Col width={12}>
            <InputDesktop
              label="Beneficiary’s address / Адрес получателя платежа"
              labelView="outer"
              placeholder="Укажите адрес"
              size="s"
              block
              name="beneficiaryAddress"
              value={beneficiaryAddress}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.beneficiaryAddress
                  ? results.beneficiaryAddress.message
                  : ''
              }
              hint="Только латинские буквы и цифры"
            />
          </Col>
        </Row>
        <Row>
          <Col width={6}>
            <InputDesktop
              label="Correspondent bank / Банк посредника"
              labelView="outer"
              placeholder="Укажите наименование"
              size="s"
              block
              name="corrBank"
              value={corrBank}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.corrBank ? results.corrBank.message : ''}
            />
          </Col>
          <Col width={6}>
            <InputDesktop
              label="SWIFT / Код Банка посредника"
              labelView="outer"
              placeholder="Укажите код"
              size="s"
              block
              name="corrBankSwift"
              value={corrBankSwift}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.corrBankSwift ? results.corrBankSwift.message : ''}
            />
          </Col>
        </Row>
        <Row>
          <Col width={6}>
            <InputDesktop
              label="Correspondent account / Счёт посредника"
              labelView="outer"
              placeholder="Укажите номер счёта"
              size="s"
              block
              name="corrAccount"
              value={corrAccount}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.corrAccount ? results.corrAccount.message : ''}
            />
          </Col>
        </Row>
        <Row>
          <Col width={12}>
            <InputDesktop
              label="Payment details / Назначение платежа"
              labelView="outer"
              placeholder="Комментарий"
              size="s"
              block
              name="paymentPurpose"
              value={paymentPurpose}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.paymentPurpose ? results.paymentPurpose.message : ''
              }
            />
          </Col>
        </Row>
      </Space>

      <Space
        fullWidth
        direction="vertical"
        size={24}
        className={styles.section}
      >
        <Row>
          <Col width={6}>
            <AmountInput
              label="Сумма"
              labelView="outer"
              size="s"
              block
              bold={false}
              autoComplete="off"
              value={amount}
              currency={
                account ? (account.currency as CurrencyCodes) : undefined
              }
              minority={MINORITY}
              placeholder="0"
              clear={true}
              onChange={onAmountChange}
              onClear={() => setAmount(null)}
              integerLength={12}
              hint={limitNode}
              error={pureAmount > 0 && !amountValid ? limitNode : undefined}
            />
          </Col>
          <Col width={6}>
            <FormControlSummary label="&nbsp;">
              <ButtonDesktop
                size="s"
                view="tertiary"
                block
                disabled={!account || !limits || limits.limit < 0}
                onClick={transferEverything}
              >
                Перевести всё
              </ButtonDesktop>
            </FormControlSummary>
          </Col>
        </Row>

        {account && (
          <CurrencyComision>
            {CURRENCIES_COMMISSION[account.currency]}
          </CurrencyComision>
        )}

        {taxes && taxes.taxDetails && <MoneyTaxDetail taxes={limits?.taxes} />}
      </Space>

      <TradingCertificateLock>
        <Row>
          <Col width={6}>
            <ButtonDesktop
              id="send-money"
              block
              view="secondary"
              size="s"
              loading={transferPending}
              onClick={sendRequest}
              disabled={!formReady}
              leftAddons={
                <CheckmarkCompactMIcon className={styles.submitIcon} />
              }
            >
              Подать поручение
            </ButtonDesktop>
          </Col>
        </Row>
        <Typography.Text
          view="secondary-small"
          color="secondary"
          className={styles.hint}
          tag="div"
        >
          Подать поручение можно в любое время. Обработка поручений
          осуществляется только в рабочие дни до 18:00 по МСК. Если поручение
          подано в другое время, оно будет обработано только на следующий
          рабочий день (Т+1).
        </Typography.Text>
      </TradingCertificateLock>
    </div>
  );
};
