import isNumber from 'lodash/isNumber';
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  useAlfaDirectContext,
  useFullFI,
  useLast,
} from '@terminal/alfadirect/hooks';
import { TradingClosedDesktop } from '@terminal/common/components/TradingClosed';
import {
  ActionSources,
  ORDER_SOURCE,
} from '@terminal/core/constants/analytics';
import {
  activationOptions,
  ActivationTime,
  lifeTimeOptions,
  SetOrderParams,
} from '@terminal/core/constants/orderBook';
import {
  ExecutionType,
  LifeTime,
  ObjectGroup,
  OrderType,
  QuantityType,
} from '@terminal/core/lib/client/entities';
import { getSymbolToShow } from '@terminal/core/lib/currencies';
import { getStepDecimals, roundPrice } from '@terminal/core/lib/format';
import { getOrderSendPrice } from '@terminal/core/lib/formulas';
import { evaluatePricePosition } from '@terminal/core/lib/orderBook/evaluatePricePosition';
import {
  getTradingStatus,
  TradingStatus,
} from '@terminal/core/lib/services/finInfoExt/getTradingStatus';
import { getBracketOrderParams } from '@terminal/core/lib/services/trading/getBracketOrderParams';
import { getActiveOrders } from '@terminal/core/store/selectors';
import { AccountItem } from '@terminal/core/types/account';
import { MarketBoard } from '@terminal/core/types/core';
import {
  ContextMenuProps,
  LimitsProps,
  Widget,
} from '@terminal/core/types/layout';
import { OrderItem } from '@terminal/core/types/order';
import { FinInfo } from '@terminal/core/types/quotes';
import {
  InputSendOrderParams,
  OrderSide,
  SendOrderParams,
} from '@terminal/core/types/trading';
import { SlippageType } from '@terminal/core/types/ui';

import {
  OrderSource,
  useOrderAnalyticsMetrics,
} from '../../../../entities/Order';
import {
  getSLTPButtonText,
  SLTPInstruction,
  SLTPModal,
  TradingLimits,
  TradingLimitsSettings,
} from '../../../../features';
import { getBracketPrice } from '../../../../features/BracketPrice';
import { ContextMenu } from '../../../../features/ContextMenu';
import {
  OrderConfirm,
  OrderInfo,
  useActivationTime,
} from '../../../../features/Orders';
import { TradingCertificateLock } from '../../../../features/TradingCertificateLock';
import { useWidgetContext } from '../../../../shared';
import { useOrderBookContext } from '../../context';
import {
  ContextTradeButtons,
  OrderBookTable,
  OrderForm,
  OrderQtyInfo,
  TableRowProps,
} from './ui';

import { useIsLotCount } from '../../../../shared/hooks/useIsLotCount';
import {
  useCurrencyCodeByIdObject,
  useDefaultQuantity,
  useOrderBook,
  useOrderBookFinInfoExt,
  useOrderBookForm,
  useOrderBookOrders,
  useOrderBookPerfomanceMetric,
  useOrderBookScroll,
} from '../../hooks';

const quoteSelector = (quote: Partial<FinInfo>) => ({
  idTradePeriodStatus: quote.idTradePeriodStatus,
});

type OrderBookContentActions = {
  setOrderToCancel: Dispatch<SetStateAction<OrderItem | null>>;
  setIsOpenCancelModal: Dispatch<SetStateAction<boolean>>;
  setOrderToEdit: Dispatch<SetStateAction<OrderItem | null>>;
  setIsOpenEditModal: Dispatch<SetStateAction<boolean>>;
  setIsOpenLimitsModal: Dispatch<SetStateAction<boolean>>;
  setIsOpenSlippageModal: Dispatch<SetStateAction<boolean>>;
  setIsOpenQuantityModal: Dispatch<SetStateAction<boolean>>;
  setIsOpenContext: Dispatch<SetStateAction<boolean>>;
  setIsOpenContextMenuModal: Dispatch<SetStateAction<boolean>>;
  setContextPrice: Dispatch<SetStateAction<number>>;
};

export type OrderBookContentProps = {
  idFi: number;
  selectedSubAccountId: number;
  cancelOrder: (orderItem: OrderItem) => void;
  isAutoScrollPaused: boolean;
  sltp: SLTPInstruction | null;
  setSltp: (sltp: SLTPInstruction | null) => void;
  selectedAccount?: AccountItem;
  hideForm?: boolean;
  isOpenLimitsModal: boolean;
  limitsProps?: LimitsProps;
  selectedSubAccounts: string[];
  nodeId?: string;
  isOpenContext: boolean;
  sendOrder: (args: SendOrderParams) => void;
  contextPrice: number;
  contextMenuProps?: ContextMenuProps;
  uiActions: OrderBookContentActions;
};

export const OrderBookContent: FC<OrderBookContentProps> = ({
  idFi,
  selectedSubAccountId,
  cancelOrder,
  isAutoScrollPaused,
  sltp,
  setSltp,
  selectedAccount,
  hideForm,
  isOpenLimitsModal,
  limitsProps,
  selectedSubAccounts,
  nodeId,
  isOpenContext,
  sendOrder,
  contextPrice,
  contextMenuProps,
  uiActions,
}) => {
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  const {
    updateNode,
    analytics: { trackOrderEditSource, trackOrderEditType, trackOrder },
    useSettings,
    useTradeLimits,
  } = useWidgetContext();
  const { trackStartOrder, trackConfirmOrder } = useOrderAnalyticsMetrics(idFi);
  const { useOrders, finInstrumentsTable, useQuotes } = useAlfaDirectContext();
  const {
    autoCenter,
    showSpread,
    showYield,
    showEmptyPrice,
    sendTrallingOrders,
    stopOrderType,
    slippageType = SlippageType.TICK,
    slippageValue = 1,
  } = useOrderBookContext();
  const finInfoExt = useOrderBookFinInfoExt(idFi);
  const priceStep = finInfoExt?.priceStep || 0.01;

  const { lines, upSpreadIndex, downSpreadIndex, centerSpreadIndex } =
    useOrderBook(idFi, finInfoExt, showSpread, showEmptyPrice);

  const orders = useOrders();

  const fullFi = useFullFI(idFi);
  const defaultQuantity = useDefaultQuantity(idFi);

  const settings = useSettings();
  const isAlwaysConfirmOrders = settings.defaultValues.alwaysConfirmOrders;

  const holdInterval = useRef<ReturnType<typeof setInterval>>();
  const last = useLast(idFi);
  const currencyCode = useCurrencyCodeByIdObject(finInfoExt?.idObjectCurrency);
  const activeOrders = getActiveOrders({
    idFI: idFi,
    orders,
    selectedSubAccountId,
    finInstruments: finInstrumentsTable,
    withWaiting: true,
  });

  const {
    setOrderToCancel,
    setIsOpenCancelModal,
    setOrderToEdit,
    setIsOpenEditModal,
    setIsOpenLimitsModal,
    setIsOpenContext,
    setIsOpenContextMenuModal,
    setContextPrice,
    setIsOpenSlippageModal,
    setIsOpenQuantityModal,
  } = uiActions;

  const [isOpenInstruction, setIsOpenInstruction] = useState(false);

  const quotes = useQuotes(idFi, { selector: quoteSelector });
  const isLotCount = useIsLotCount();
  const [price, setPrice] = useState(0);
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });

  const [lifeTime, setLifeTime] = useState(
    () => lifeTimeOptions.find((opt) => opt.value === LifeTime.D30)!
  );
  const [activation, setActivation] = useState(
    () => activationOptions.find((opt) => opt.value === ActivationTime.NOW)!
  );
  const getActivationTime = useActivationTime();

  const tradingStatus = getTradingStatus(finInfoExt, quotes[idFi], fullFi);

  const isBond = fullFi?.idObjectGroup === ObjectGroup.Bonds;
  const isFuture = fullFi?.idObjectGroup === ObjectGroup.Futures;

  const handleCancelOrder = useCallback(
    (order: OrderItem) => {
      if (isAlwaysConfirmOrders) {
        setOrderToCancel(order);
        setIsOpenCancelModal(true);
      } else {
        cancelOrder(order);
      }
    },
    [isAlwaysConfirmOrders, cancelOrder, setOrderToCancel, setIsOpenCancelModal]
  );

  const handleEditOrder = useCallback(
    (order: OrderItem) => {
      setOrderToEdit(order);
      setIsOpenEditModal(true);
      trackOrderEditSource(ActionSources.DOM);
      trackOrderEditType(order.idOrderType);
    },
    [
      setOrderToEdit,
      setIsOpenEditModal,
      trackOrderEditSource,
      trackOrderEditType,
    ]
  );

  const { listRef, scrollToCenter } = useOrderBookScroll<TableRowProps>(
    idFi,
    centerSpreadIndex,
    lines,
    autoCenter,
    nodeId
  );

  //Держим центр стакана при его апдейтах пока юзер не отскролит сам или пока не появиться фокус
  useEffect(() => {
    //Если включили центрирование
    if (autoCenter && !isAutoScrollPaused) {
      scrollToCenter();
      holdInterval.current = setInterval(() => scrollToCenter(), 1000);
    } else {
      //Если пользователь скроллит или в фокусе на стакане, то останавливаем слежение за центром
      holdInterval.current && clearInterval(holdInterval.current);
    }

    return () => holdInterval.current && clearInterval(holdInterval.current);
  }, [autoCenter, scrollToCenter, isAutoScrollPaused]);

  const {
    upperOrders,
    upSpreadPrice,
    downSpreadPrice,
    lowerOrders,
    buyOrdersMap,
    sellOrdersMap,
    totalBuyQty,
    totalSellQty,
  } = useOrderBookOrders(
    activeOrders,
    lines,
    upSpreadIndex,
    downSpreadIndex,
    centerSpreadIndex
  );

  const { isBelowSpread } = evaluatePricePosition(price, {
    upperSpreadPrice: upSpreadPrice,
    maxBuyPrice: downSpreadPrice,
  });
  const isBuy = !isBelowSpread;

  const [selectedOrdersIds, setSelectedOrdersIds] = useState<string[]>([]);

  const upperOrdersFiltered = useMemo(() => {
    if (selectedOrdersIds.length) {
      return upperOrders.filter(({ clientNumEDocument }) =>
        selectedOrdersIds.includes(clientNumEDocument)
      );
    }

    return upperOrders;
  }, [selectedOrdersIds, upperOrders]);
  const lowerOrdersFiltered = useMemo(() => {
    if (selectedOrdersIds.length) {
      return lowerOrders?.filter(({ clientNumEDocument }) =>
        selectedOrdersIds.includes(clientNumEDocument)
      );
    }

    return lowerOrders;
  }, [selectedOrdersIds, lowerOrders]);

  const isOpenLowerOrders = useMemo(
    () =>
      lowerOrders?.some(({ clientNumEDocument }) =>
        selectedOrdersIds.includes(clientNumEDocument)
      ),
    [lowerOrders, selectedOrdersIds]
  );
  const isOpenUpperOrders = useMemo(
    () =>
      upperOrders?.some(({ clientNumEDocument }) =>
        selectedOrdersIds.includes(clientNumEDocument)
      ),
    [upperOrders, selectedOrdersIds]
  );
  const clearSelectedOrderIds = useCallback(() => setSelectedOrdersIds([]), []);

  const { quantity, setQuantity, bestBid, bestAsk } = useOrderBookForm(
    idFi,
    lines,
    setPrice,
    isLotCount,
    finInfoExt,
    defaultQuantity
  );

  useOrderBookPerfomanceMetric(idFi, tradingStatus, lines, finInfoExt, nodeId);

  const [sendOrderParams, setSendOrderParams] = useState<
    (SendOrderParams & InputSendOrderParams) | null
  >(null);

  const handleSendOrder = useCallback(() => {
    if (sendOrderParams) {
      sendOrder(sendOrderParams);
      trackStartOrder(OrderSource.ORDERBOOK, sendOrderParams);
      trackOrder(
        sendOrderParams.buy,
        sendOrderParams.idOrderType,
        ORDER_SOURCE.DOM
      );
      setSendOrderParams(null);
    }
  }, [sendOrder, sendOrderParams, trackOrder, trackStartOrder]);

  const setOrderParams = useCallback(
    (
      { idOrderType, buy, quantity, inputPrice, bestOffer }: SetOrderParams,
      orderSum?: number
    ) => {
      let sendParams: (SendOrderParams & InputSendOrderParams) | null = null;

      const commonSendParams = {
        idFi,
        idSubaccount: selectedSubAccountId,
        idOrderType,
        buy,
        quantity,
        quantityType: QuantityType.QTY,
        idLifeTime: lifeTime.value,
        idActivationTime: activation.value,
        activationTime: getActivationTime(activation.value),
        idExecutionType:
          idOrderType === OrderType.MKT
            ? ExecutionType.ASIS
            : ExecutionType.ASL,
        withDrawTime:
          //Пока тут обрабатываем GTC
          lifeTime.value === LifeTime.GTD ? new Date(2100, 0, 1) : undefined,
        last,
      };

      const { stopPrice, limitPrice } = getOrderSendPrice(
        idOrderType,
        inputPrice,
        last
      );

      const bestOfferPrice = buy ? bestBid : bestAsk;

      const slPrice = sltp
        ? getBracketPrice(
            inputPrice,
            sltp.slPriceType,
            sltp.slPrice,
            buy ? -1 : 1,
            priceStep
          )
        : undefined;

      const tpPrice = sltp
        ? getBracketPrice(
            inputPrice,
            sltp.tpPriceType,
            sltp.tpPrice,
            buy ? 1 : -1,
            priceStep
          )
        : undefined;

      let slSlippagePrice;

      if (sltp && slPrice && sltp.slOrderType === OrderType.STL) {
        const slippagePrice =
          (sltp.slSlippageType === SlippageType.TICK
            ? priceStep || 0.01
            : slPrice / 100) * sltp.slSlippageValue;

        slSlippagePrice = slPrice + (buy ? -1 : 1) * slippagePrice;
      }

      const bracket = getBracketOrderParams({
        slPrice,
        tpPrice,
        slOrderType: sltp?.slOrderType,
        slSlippagePrice,
      });

      switch (idOrderType) {
        case OrderType.LMT:
          sendParams = {
            ...commonSendParams,
            limitPrice: bestOffer ? bestOfferPrice : limitPrice,
            inputPrice: bestOffer ? bestOfferPrice : inputPrice,
            bracket,
          };
          break;

        case OrderType.STL:
          const orderPrice = bestOffer ? bestOfferPrice : inputPrice;

          let limitLevelAlternative;

          const slippagePrice =
            (slippageType === SlippageType.TICK
              ? priceStep || 0.01
              : inputPrice / 100) * slippageValue;

          limitLevelAlternative = roundPrice(
            orderPrice + (buy ? 1 : -1) * slippagePrice,
            priceStep
          );

          sendParams = {
            ...commonSendParams,
            inputPrice: orderPrice,
            limitLevelAlternative,
            bracket,
            limitPrice,
            stopPrice,
          };
          break;

        case OrderType.TRL:
          sendParams = {
            ...commonSendParams,
            limitPrice: bestOffer ? bestOfferPrice : limitPrice,
            inputPrice: bestOffer ? bestOfferPrice : inputPrice,
            stopPrice,
            limitLevelAlternative: 0,
          };
          break;

        case OrderType.STP:
          sendParams = {
            ...commonSendParams,
            stopPrice,
            inputPrice,
          };
          break;

        case OrderType.TRS:
          sendParams = {
            ...commonSendParams,
            stopPrice,
            limitPrice,
            inputPrice,
          };
          break;

        case OrderType.MKT:
          sendParams = {
            ...commonSendParams,
            inputPrice,
            bracket,
          };
          break;

        default:
          break;
      }

      if (sendParams) {
        if (isAlwaysConfirmOrders) {
          setSendOrderParams(sendParams);
          trackConfirmOrder(OrderSource.ORDERBOOK, sendParams, orderSum);
          setIsOpenConfirm(true);
        } else {
          trackStartOrder(OrderSource.ORDERBOOK, sendParams, orderSum);
          sendOrder(sendParams);
        }
      }
    },
    [
      idFi,
      selectedSubAccountId,
      lifeTime.value,
      activation.value,
      getActivationTime,
      last,
      bestBid,
      bestAsk,
      sltp,
      priceStep,
      slippageType,
      slippageValue,
      isAlwaysConfirmOrders,
      trackConfirmOrder,
      trackStartOrder,
      sendOrder,
    ]
  );

  const additionalButtons = useMemo(() => {
    if (
      contextPrice &&
      finInfoExt?.lot &&
      finInfoExt?.idBoard !== MarketBoard.EUCLR
    ) {
      return (
        <TradingCertificateLock hideMode>
          <ContextTradeButtons
            price={contextPrice}
            quantity={quantity}
            finInfoExt={finInfoExt}
            upSpreadPrice={upSpreadPrice}
            downSpreadPrice={downSpreadPrice}
            onClose={() => setIsOpenContext(false)}
            setOrderParams={setOrderParams!}
            contextMenuProps={contextMenuProps}
            defaultQuantity={defaultQuantity}
            tradingStatus={tradingStatus}
          />
        </TradingCertificateLock>
      );
    } else {
      return null;
    }
  }, [
    contextPrice,
    finInfoExt,
    quantity,
    upSpreadPrice,
    downSpreadPrice,
    setOrderParams,
    contextMenuProps,
    defaultQuantity,
    tradingStatus,
    setIsOpenContext,
  ]);

  const limitParams = useMemo(() => {
    return {
      idFi,
      orderType: OrderType.MKT,
      lifeTime: lifeTime.value,
      price,
    };
  }, [idFi, lifeTime.value, price]);

  const { tradeLimitsLong, tradeLimitsShort } = useTradeLimits(
    selectedSubAccountId,
    selectedSubAccounts,
    limitParams,
    selectedAccount?.idAccount
  );

  const { priceDecimals } = getStepDecimals(priceStep);
  const symbol = getSymbolToShow(fullFi?.currencyCode, fullFi?.idObjectGroup);

  const sltpConfirmationText = sltp ? (
    <>
      <span>
        {getSLTPButtonText(
          sltp,
          price,
          isBuy ? OrderSide.BUY : OrderSide.SELL,
          priceStep,
          symbol
        )}
      </span>
    </>
  ) : undefined;

  return (
    <>
      <TradingCertificateLock hideMode>
        <TradingLimits
          idFi={idFi}
          nodeId={nodeId}
          selectedSubAccountId={selectedSubAccountId}
          selectedSubAccounts={selectedSubAccounts}
          tradeLimitsLong={tradeLimitsLong}
          tradeLimitsShort={tradeLimitsShort}
          limitsProps={limitsProps}
        />
        {isOpenLimitsModal && (
          <TradingLimitsSettings
            nodeId={nodeId}
            limitsProps={limitsProps}
            open={isOpenLimitsModal}
            onClose={() => setIsOpenLimitsModal(false)}
          />
        )}
      </TradingCertificateLock>
      {upperOrdersFiltered && upperOrdersFiltered.length > 0 ? (
        <OrderInfo
          isOpen={isOpenUpperOrders}
          onClose={clearSelectedOrderIds}
          orders={upperOrdersFiltered}
          position="top"
          finInfoExt={finInfoExt}
          isBond={isBond}
          onCancelOrder={handleCancelOrder}
          onEditOrder={handleEditOrder}
          currencyCode={currencyCode}
        />
      ) : null}
      {tradingStatus === TradingStatus.CLOSED && <TradingClosedDesktop />}
      {tradingStatus === TradingStatus.OPEN && (
        <OrderBookTable
          nodeId={nodeId}
          idFI={idFi}
          lines={lines}
          upSpreadIndex={upSpreadIndex}
          downSpreadIndex={downSpreadIndex}
          price={price}
          listRef={listRef}
          onRowClick={(originalPrice: number) => {
            isNumber(originalPrice) && setPrice(originalPrice);

            if (nodeId) {
              updateNode(nodeId, { linkedPrice: originalPrice });
            }
          }}
          buyOrdersMap={buyOrdersMap}
          sellOrdersMap={sellOrdersMap}
          showSpread={finInfoExt && showSpread}
          contextPrice={contextPrice}
          setContextPrice={(originalPrice: number) => {
            isNumber(originalPrice) && setContextPrice(originalPrice);
          }}
          setIsOpenContext={setIsOpenContext}
          setAnchorPoint={setAnchorPoint}
          setSelectedOrdersIds={setSelectedOrdersIds}
          isBond={isBond}
          showYield={showYield}
        />
      )}
      {lowerOrdersFiltered && lowerOrdersFiltered.length > 0 ? (
        <OrderInfo
          isOpen={isOpenLowerOrders}
          onClose={clearSelectedOrderIds}
          orders={lowerOrdersFiltered}
          position="bottom"
          finInfoExt={finInfoExt}
          isBond={isBond}
          currencyCode={currencyCode}
          onCancelOrder={handleCancelOrder}
          onEditOrder={handleEditOrder}
        />
      ) : null}
      {tradingStatus === TradingStatus.OPEN && (
        <OrderQtyInfo totalBuyQty={totalBuyQty} totalSellQty={totalSellQty} />
      )}
      <OrderForm
        idFi={idFi}
        side={isBuy ? OrderSide.BUY : OrderSide.SELL}
        price={price}
        setPrice={setPrice}
        quantity={quantity}
        setQuantity={setQuantity}
        upSpreadPrice={upSpreadPrice}
        downSpreadPrice={downSpreadPrice}
        finInfoExt={finInfoExt}
        currencyCode={getSymbolToShow(currencyCode, fullFi?.idObjectGroup)}
        selectedAccount={selectedAccount}
        selectedSubAccounts={selectedSubAccounts}
        isLotCount={isLotCount}
        isBond={isBond}
        isFuture={isFuture}
        hide={hideForm || fullFi?.idMarketBoard === MarketBoard.EUCLR}
        nodeId={nodeId}
        setOrderParams={setOrderParams}
        lifeTime={lifeTime}
        activation={activation}
        setLifeTime={setLifeTime}
        setActivation={setActivation}
        setIsOpenInstruction={setIsOpenInstruction}
        sltp={sltp}
        setSltp={setSltp}
        tradingStatus={tradingStatus}
        fullFi={fullFi}
        tradeLimitsLong={tradeLimitsLong}
        tradeLimitsShort={tradeLimitsShort}
      />
      <ContextMenu
        nodeId={nodeId}
        idFi={idFi}
        widget={Widget.ORDERBOOK}
        isOpenContext={isOpenContext}
        setIsOpenContext={setIsOpenContext}
        anchorPoint={anchorPoint}
        autoCenter={autoCenter}
        hideForm={hideForm || fullFi?.idMarketBoard === MarketBoard.EUCLR}
        //Не можем показывать разреженный стакан и спред без шага цен (он в finInfoExt)
        showEmptyPrice={finInfoExt && showEmptyPrice}
        showSpread={showSpread}
        showYield={showYield}
        sendTrallingOrders={sendTrallingOrders}
        setIsOpenLimitsModal={setIsOpenLimitsModal}
        additionalButtons={additionalButtons}
        setIsOpenContextMenuModal={setIsOpenContextMenuModal}
        setIsOpenQuantityModal={setIsOpenQuantityModal}
        setIsOpenSlippageModal={setIsOpenSlippageModal}
      />

      {isOpenInstruction && (
        <SLTPModal
          side={isBuy ? OrderSide.BUY : OrderSide.SELL}
          mainPrice={price}
          stopOrderType={stopOrderType}
          sltp={sltp || undefined}
          onSubmit={(data) => {
            setSltp(data);
            setIsOpenInstruction(false);
          }}
          onClose={() => setIsOpenInstruction(false)}
          open={isOpenInstruction}
          priceStep={priceStep}
          symbol={symbol}
        />
      )}

      {isAlwaysConfirmOrders && sendOrderParams && (
        <OrderConfirm
          fullFi={fullFi}
          priceDecimals={priceDecimals}
          isOpen={isOpenConfirm}
          onClose={() => setIsOpenConfirm(false)}
          onConfirm={handleSendOrder}
          params={sendOrderParams}
          additionalInstruction={sltpConfirmationText}
        />
      )}
    </>
  );
};
