import React from 'react';

import { Certificate } from '@terminal/core/types/certificate';

import { InitObject, useInit } from '../../common';
import { defaultContext, LkContext } from './LkContext';

import { LkContextStore } from './types/LkContextStore';

export interface LkContextProviderProps {
  children: React.ReactNode;
  getInit: () => Promise<InitObject>;
  addNotification?: (arg: any) => void;
  certificate?: Certificate | null;
}

export const LkContextProvider: (
  p: LkContextProviderProps
) => React.ReactElement<LkContextProviderProps> = (props) => {
  const { children, getInit, addNotification, certificate } = props;

  const { data: init } = useInit(getInit);

  const context: LkContextStore = {
    ...defaultContext,
    init,
    addNotification: addNotification || defaultContext.addNotification,
    workingCertificate: certificate || defaultContext.workingCertificate,
  };

  return <LkContext.Provider value={context}>{children}</LkContext.Provider>;
};
