import { memo, useEffect } from 'react';
import { useUnmount } from 'react-use';
import { Badge } from '@alfalab/core-components/badge';
import { AlertCircleMIcon } from '@alfalab/icons-glyph/AlertCircleMIcon';
import { BriefcaseMIcon } from '@alfalab/icons-glyph/BriefcaseMIcon';
import { DebtMIcon } from '@alfalab/icons-glyph/DebtMIcon';
import { DocumentAddMIcon } from '@alfalab/icons-glyph/DocumentAddMIcon';
import { DocumentMIcon } from '@alfalab/icons-glyph/DocumentMIcon';
import { MailOpenLinesMIcon } from '@alfalab/icons-glyph/MailOpenLinesMIcon';
import { SignMIcon } from '@alfalab/icons-glyph/SignMIcon';
import { SquareAcademicCapMIcon } from '@alfalab/icons-glyph/SquareAcademicCapMIcon';

import { FunctionalModal } from '@terminal/common/components/FunctionalModal';
import {
  MainMenu,
  MainMenuItem,
} from '@terminal/common/components/MainMenu/MainMenu';
import { featureFlags } from '@terminal/core/featureFlags';
import { usePrevious } from '@terminal/core/hooks';
import { shallow, useStore } from '@terminal/core/store';
import { HeaderMenuItem } from '@terminal/core/types/ui';
import { Certificates } from '@terminal/lk-desktop-common';
import {
  AccountsOperations,
  FinancialDocuments,
} from '@terminal/lk-desktop-go';

import { CorporateActions } from '../corporateActions';
import { FreeOrders } from '../freeorders';
import { Notifications } from '../notifications';
import { QualTesting } from '../QualTesting';

import { useNotificationsCount } from '../notifications/hooks';

import { MainMenuType } from './types';

import styles from './Main.module.css';

export const Main = memo(() => {
  const [
    workingCertificate,
    headerMenu,
    setHeaderMenuOpen,
    lkSubMenuId,
    setLkSubMenuId,
    setHeaderMenuBadge,
    removeHeaderMenuBadge,
  ] = useStore(
    (state) => [
      state.workingCertificate,
      state.headerMenu,
      state.setHeaderMenuOpen,
      state.lkSubMenuId,
      state.setLkSubMenuId,
      state.setHeaderMenuBadge,
      state.removeHeaderMenuBadge,
    ],
    shallow
  );

  const { data: notificationsCount, refetch: updateNotificationsCount } =
    useNotificationsCount();

  // Устанавливаем badge в основном меню
  useEffect(() => {
    if (notificationsCount) {
      if (notificationsCount.count > 0) {
        setHeaderMenuBadge(HeaderMenuItem.MAIN_LK);
      } else {
        removeHeaderMenuBadge(HeaderMenuItem.MAIN_LK);
      }
    }
  }, [notificationsCount, setHeaderMenuBadge, removeHeaderMenuBadge]);

  const prevHeaderMenu = usePrevious(headerMenu);

  // При открытии меню ЛК делаем активным первый пункт
  useEffect(() => {
    if (
      prevHeaderMenu !== headerMenu &&
      headerMenu === HeaderMenuItem.MAIN_LK &&
      !lkSubMenuId
    ) {
      setLkSubMenuId({ id: MainMenuType.Accounts, options: undefined });
    }
  }, [lkSubMenuId, setLkSubMenuId, headerMenu, prevHeaderMenu]);

  useUnmount(() => {
    setLkSubMenuId({ id: null });
  });

  const menuItems: MainMenuItem<MainMenuType>[] = [
    {
      name: 'Операции со счетами',
      icon: <DebtMIcon />,
      type: MainMenuType.Accounts,
    },
    {
      name: 'Документы и налоги',
      icon: <DocumentMIcon />,
      type: MainMenuType.Documents,
    },
    {
      name: 'Электронная подпись',
      icon: <SignMIcon />,
      rightIcon: !workingCertificate ? (
        <AlertCircleMIcon color="var(--color-light-graphic-attention, #f6bf65)" />
      ) : null,
      type: MainMenuType.Certificates,
    },
    {
      name: 'Произвольные поручения',
      icon: <DocumentAddMIcon />,
      type: MainMenuType.FreeOrders,
    },
    {
      name: 'Тестирование',
      icon: <SquareAcademicCapMIcon />,
      type: MainMenuType.QualTesting,
      hidden: !featureFlags.PRO_CLIENT_TESTING_ENABLED,
    },
    {
      name: 'Корпоративные действия',
      icon: <BriefcaseMIcon />,
      type: MainMenuType.CorporateActions,
    },
    {
      name: 'Уведомления',
      icon: <MailOpenLinesMIcon />,
      type: MainMenuType.Notifications,
      rightIcon: notificationsCount ? (
        <Badge
          view="count"
          content={notificationsCount.count}
          className={styles.count}
        />
      ) : null,
    },
  ];

  return (
    <FunctionalModal
      title="Личный кабинет"
      width={812}
      onClose={() => setHeaderMenuOpen(null)}
      drawerId="main-window-modals"
      sidebar={
        <MainMenu
          items={menuItems}
          selected={lkSubMenuId.id}
          onItemSelect={(item) =>
            setLkSubMenuId({ id: item.type as MainMenuType })
          }
        />
      }
    >
      {(() => {
        switch (lkSubMenuId.id) {
          case MainMenuType.Accounts:
            return <AccountsOperations key={headerMenu} />;
          case MainMenuType.Documents:
            return <FinancialDocuments />;
          case MainMenuType.QualTesting:
            return <QualTesting />;
          case MainMenuType.Certificates:
            return <Certificates />;
          case MainMenuType.FreeOrders:
            return <FreeOrders />;
          case MainMenuType.CorporateActions:
            return <CorporateActions />;
          case MainMenuType.Notifications:
            return (
              <Notifications onNotificationRead={updateNotificationsCount} />
            );
          default:
            return null;
        }
      })()}
    </FunctionalModal>
  );
});

Main.displayName = 'Main';
