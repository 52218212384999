import { useQuery } from 'react-query';

import { requestSignText } from '@terminal/core/lib/rest/lkSignOperation';

/**
 * @deprecated используй решение на основе хука `useSignDialog`
 * */
export const useSignText = (
  operationUrl: string | undefined,
  open: boolean
) => {
  return useQuery(
    ['lk', 'sign', 'text', operationUrl],
    () => requestSignText(operationUrl || ''),
    {
      enabled: Boolean(operationUrl) && open,
    }
  );
};
