export const DEFAULT_BOND_SCREENER_DICTIONARIES = [
  {
    name: 'Валюта',
    filter: 'currency',
    values: [
      {
        name: 'Рубль',
        value: 'RUB',
      },
      {
        name: 'Доллар',
        value: 'USD',
      },
      {
        name: 'Евро',
        value: 'EUR',
      },
      {
        name: 'Швейцарский франк',
        value: 'CHF',
      },
      {
        name: 'Китайский юань',
        value: 'CNY',
      },
    ],
  },
  {
    name: 'Тип',
    filter: 'bondType',
    values: [
      {
        name: 'Государственный',
        value: 'Государственный',
      },
      {
        name: 'Корпоративный',
        value: 'Корпоративный',
      },
      {
        name: 'Муниципальный (субфедеральный)',
        value: 'Муниципальный',
      },
    ],
  },
  {
    name: 'Рейтинг эмитента',
    filter: 'agencyRating',
    values: [
      {
        name: 'AAA',
        value: 'AAA',
        group: 'Высокий',
      },
      {
        name: 'AA',
        value: 'AA',
        group: 'Высокий',
      },
      {
        name: 'A',
        value: 'A',
        group: 'Высокий',
      },
      {
        name: 'BBB',
        value: 'BBB',
        group: 'Средний и низкий',
      },
      {
        name: 'BB',
        value: 'BB',
        group: 'Средний и низкий',
      },
      {
        name: 'B',
        value: 'B',
        group: 'Средний и низкий',
      },
      {
        name: 'CCC',
        value: 'CCC',
        group: 'Очень низкий',
      },
      {
        name: 'CC',
        value: 'CC',
        group: 'Очень низкий',
      },
      {
        name: 'C',
        value: 'C',
        group: 'Очень низкий',
      },
    ],
  },
  {
    name: 'Тип оферты',
    filter: 'offertType',
    values: [
      {
        name: 'Call-оферта',
        value: 'call',
      },
      {
        name: 'Put-оферта',
        value: 'put',
      },
    ],
  },
  {
    name: 'Страна эмитента',
    filter: 'country',
    values: [
      {
        name: 'Россия',
        value: 'Россия',
      },
      {
        name: 'Беларусь',
        value: 'Беларусь',
      },
      {
        name: 'Казахстан',
        value: 'Казахстан',
      },
    ],
  },
  {
    name: 'Сектор эмитента',
    filter: 'sector',
    values: [
      {
        name: 'Финансы',
        value: 'Финансы',
      },
      {
        name: 'IT',
        value: 'IT',
      },
      {
        name: 'Энергетика',
        value: 'Энергетика',
      },
      {
        name: 'Добыча и обработка',
        value: 'Добыча и обработка',
      },
      {
        name: 'Промышленность',
        value: 'Промышленность',
      },
      {
        name: 'Бытовые товары',
        value: 'Бытовые товары',
      },
      {
        name: 'Повседневные товары',
        value: 'Повседневные товары',
      },
      {
        name: 'Медицина',
        value: 'Медицина',
      },
      {
        name: 'Коммунальные услуги',
        value: 'Коммунальные услуги',
      },
      {
        name: 'Недвижимость',
        value: 'Недвижимость',
      },
      {
        name: 'Государственный',
        value: 'Государственный',
      },
      {
        name: 'Разное',
        value: 'Разное',
      },
      {
        name: 'Образование',
        value: 'Образование',
      },
    ],
  },
];
