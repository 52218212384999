import React from 'react';
import { CrossHeavyMIcon } from '@alfalab/icons-glyph/CrossHeavyMIcon';
import { ExclamationMIcon } from '@alfalab/icons-glyph/ExclamationMIcon';

import { ManagementResultType } from '../../../../entities/AccountsOperations';

export const enum CreateAttorneyErrorCode {
  PASSPORT_DATA_OUTDATED = 3, // "Паспортные данные неактуальны"
  ATTORNEY_ALREADY_EXISTS = 5, // "Доверенность уже существует"
  RIGHTS_ALREADY_GRANTED = 6, // "Уже выданы права на субсчет"
}

export const CREATE_ATTORNEY_FULL_TEXT_ERROR_MAP: Record<
  CreateAttorneyErrorCode,
  ManagementResultType
> = {
  [CreateAttorneyErrorCode.PASSPORT_DATA_OUTDATED]: {
    icon: <CrossHeavyMIcon />,
    message: 'Мы не смогли оформить доверенность',
    description: 'Проверьте паспортные данные уполномоченного лица',
  },
  [CreateAttorneyErrorCode.ATTORNEY_ALREADY_EXISTS]: {
    icon: <ExclamationMIcon />,
    message: 'Доверенность на указанное уполномоченное лицо была создана ранее',
    description: 'Отзовите текущую, чтобы создать новую',
  },
  [CreateAttorneyErrorCode.RIGHTS_ALREADY_GRANTED]: {
    icon: <ExclamationMIcon />,
    message: 'Доверенность на указанное уполномоченное лицо была создана ранее',
    description: 'Отзовите текущую, чтобы создать новую',
  },
};
