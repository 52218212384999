import { useEffect, VFC } from 'react';

import { SupportService } from '@terminal/chat/api/chatService';
import { ChatProvider } from '@terminal/chat/app/providers/ChatProvider';
import { useStore } from '@terminal/chat/store/useStore';
import { useAppContext } from '@terminal/core/hooks/useAppContext';

import { ChatWindow } from '../ChatWindow';

export const Chat: VFC = () => {
  const { supportChat, supportPhone, supportEmail } = useAppContext();
  const setUnreadMsgCount = useStore((store) => store.setUnreadMsgCount);

  useEffect(
    () => SupportService.subscribeToUnreadMsgCount(setUnreadMsgCount),
    [setUnreadMsgCount]
  );

  return (
    <ChatProvider
      supportChat={supportChat}
      supportPhone={supportPhone}
      supportEmail={supportEmail}
    >
      <ChatWindow />
    </ChatProvider>
  );
};
