import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { CertificateActionType } from '@terminal/core/lib/client/entities';
import { LKResult } from '@terminal/core/lib/rest/lkApi';
import {
  certificateConfirm,
  certificateReject,
  smsSignCodeCheck,
  SmsSignCreated,
  SmsSigningInit,
  smsSignSendSms,
} from '@terminal/core/lib/rest/lkSmsSignOperation';
import { shallow, useStore } from '@terminal/core/store';
import { NotificationType } from '@terminal/core/types/ui';

export function useCertificateSmsConfirmModal() {
  const {
    certificateSMSWindowProps: {
      isVisible,
      certificateId: certificateIdProps,
      certificateActionType,
      onSuccess,
      onClose,
      onError,
    },
    certificateEnrollId,
    completeCertificateFlow,
    hideCertificateSMSWindow,
  } = useStore(
    (state) => ({
      certificateSMSWindowProps: state.certificateSMSWindowProps,
      certificateEnrollId: state.certificateEnrollId,
      completeCertificateFlow: state.completeCertificateFlow,
      hideCertificateSMSWindow: state.hideCertificateSMSWindow,
    }),
    shallow
  );

  const certificateId = certificateIdProps || certificateEnrollId;

  const [operation, setOperation] = useState<SmsSignCreated | null>(null);
  const [assignment, setAssignment] = useState<SmsSigningInit | null>(null);
  const [error, setError] = useState<any>(null);
  const [isSuccess, setIsSuccess] = useState(false);

  const addNotification = useNotification();

  const reset = useCallback(() => {
    setAssignment(null);
    setOperation(null);
    setError(null);
    setIsSuccess(false);
  }, [setAssignment, setOperation, setError, setIsSuccess]);

  const signSmsDialogProps = useMemo(() => {
    if (certificateActionType === CertificateActionType.Certify) {
      return {
        initAction: certificateConfirm,
        successMsg: {
          title: 'Успех',
          text: 'Сертификат подтвержден',
        },
        errorMsg: {
          title: 'Ошибка',
          text: 'Не удалось подтвердить сертификат',
        },
      };
    } else {
      return {
        initAction: certificateReject,
        successMsg: {
          title: 'Успех',
          text: 'Сертификат отозван',
        },
        errorMsg: {
          title: 'Ошибка',
          text: 'Не удалось отозвать сертификат',
        },
      };
    }
  }, [certificateActionType]);

  const sendSmsMutation = useMutation(smsSignSendSms, {
    onSuccess: (assignment) => {
      setAssignment(assignment);
    },
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка',
        text: error?.message || 'Произошла ошибка при отправке смс',
      });
      setAssignment(null);
      closeCodeWindow();
      onError && onError(error);
    },
  });

  const initMutation = useMutation(signSmsDialogProps.initAction, {
    onSuccess: (data: any) => {
      setOperation(data);
      sendSmsMutation.mutateAsync(data.url);
    },
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка',
        text: error?.message || 'При создании операции',
      });
      closeCodeWindow();
    },
  });

  const codeCheckMutation = useMutation(smsSignCodeCheck, {
    onSuccess: (result: LKResult) => {
      if (result.code === 0) {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'positive',
          title: signSmsDialogProps.successMsg?.title || 'Успех',
          text: signSmsDialogProps.successMsg?.text,
        });
        setIsSuccess(true);
        completeCertificateFlow();
        onSuccess && onSuccess(result);
      } else if (result.code === 1) {
        setError({
          code: result.code,
          message: result.message,
        });
      } else {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'attention',
          title: signSmsDialogProps.errorMsg?.title,
          text: signSmsDialogProps.errorMsg?.text,
        });
        reset();
        closeCodeWindow();
      }
    },
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'attention',
        title: signSmsDialogProps.errorMsg?.title,
        text: signSmsDialogProps.errorMsg?.text,
      });
      reset();
    },
  });

  const resendSms = useCallback(async () => {
    if (operation) {
      await sendSmsMutation.mutateAsync(operation.url);
    } else {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка запроса СМС',
        text: `CertificateId: ${certificateId}`,
      });
    }
  }, [operation, sendSmsMutation, addNotification, certificateId]);

  const checkSmsCode = useCallback(
    (code: string) => {
      if (operation && assignment) {
        codeCheckMutation.mutateAsync({
          smsReference: assignment.reference,
          smsCode: code,
          opText: assignment.opText,
          operationUrl: operation.url,
        });
      } else {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text: 'При исполнении операции произошла ошибка',
        });
      }
    },
    [operation, assignment, codeCheckMutation, addNotification]
  );

  const closeCodeWindow = useCallback(() => {
    reset();
    hideCertificateSMSWindow();
  }, [reset, hideCertificateSMSWindow]);

  const onCancel = useCallback(() => {
    closeCodeWindow();
    onClose && onClose();
  }, [closeCodeWindow, onClose]);

  useEffect(() => {
    if (isVisible && certificateId) {
      initMutation.mutateAsync(certificateId);
    }

    return reset;
    // Тут есть проблема, что initMutation по дефолту не мемоизирован,
    // а самому мемоизоровать нельзя потому что это хук
    // Как я понял initMutation.mutateAsync нужно вызывать по клику, а не в эффекте,
    // но в нашем случае подходит только эффект потому что CertificateSMSConfirmModal это синглтон в корне
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isVisible, reset, certificateId]);

  return {
    open: isVisible,
    codeSending: sendSmsMutation.isLoading,
    error,
    isError:
      initMutation.isError ||
      sendSmsMutation.isError ||
      codeCheckMutation.isError,
    isSuccess,
    assignment,
    onCancel,
    onResend: resendSms,
    signCheck: checkSmsCode,
  };
}
