import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getProfileAgreement } from '@terminal/core/lib/rest/lkRiskProfile';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../queryKeys';

/**
 * @deprecated используйте аналог из `@terminal/lk-core`
 * */
export const useGetProfileAgreement = (
  isDocumentOpen: boolean,
  isUnsigned: boolean
) => {
  const addNotification = useNotification();

  return useQuery(
    [LkQueryKeys.RiskProfileAgreement, isDocumentOpen, isUnsigned],
    async () => getProfileAgreement(isUnsigned),
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка загрузки справки',
          text: error?.message || '',
        });
      },
      enabled: isDocumentOpen,
    }
  );
};
