import React, { forwardRef } from 'react';
import {
  IconButton,
  IconButtonProps,
} from '@alfalab/core-components/icon-button';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { PlusMIcon } from '@alfalab/icons-glyph/PlusMIcon';

import styles from './AddWidgetPlusButton.module.css';

export type AddWidgetPlusButtonProps = Partial<Omit<IconButtonProps, 'icon'>>;

/**
 * Кнопка «+» для добавления виджета
 * */
export const AddWidgetPlusButton = forwardRef<
  HTMLButtonElement,
  AddWidgetPlusButtonProps
>((props, ref) => {
  return (
    <TooltipDesktop
      view="hint"
      offset={[0, 4]}
      content="Добавить виджет"
      trigger="hover"
      position="top"
      onOpenDelay={1000}
      preventOverflow={false}
    >
      <IconButton
        ref={ref}
        size="xs"
        view="secondary"
        icon={PlusMIcon}
        className={styles.plusButton}
        {...props}
      />
    </TooltipDesktop>
  );
});

AddWidgetPlusButton.displayName = 'AddWidgetPlusButton';
