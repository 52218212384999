import { VFC } from 'react';

import { defaultTradesFeedProps } from '@terminal/core/constants/tableProps';
import { Widget } from '@terminal/core/types/layout';

import {
  isBalanceProps,
  isChartProps,
  isHybridOrderProps,
  isInstrumentInfoProps,
  isNewsProps,
  isOperationProps,
  isOrderBookProps,
  isOrderTableProps,
  isPositionProps,
  isTradesProps,
  isWatchListProps,
} from '../../lib/widgetPropsValidation';
import {
  LazyBalance,
  LazyChart,
  LazyHybridOrder,
  LazyInstrumentInfo,
  LazyNews,
  LazyOperations,
  LazyOrderBook,
  LazyOrderTable,
  LazyPositions,
  LazyTradesFeed,
  LazyWatchlist,
} from './lazyWidgets';

interface LazyWidgetFactoryProps {
  config: any;
  component: Widget;
  visible?: boolean;
  nodeId?: string;
}

export const LazyWidgetFactory: VFC<LazyWidgetFactoryProps> = (props) => {
  const { config, component, visible, nodeId } = props;

  if (!visible) {
    return null;
  } else {
    switch (component) {
      case Widget.WATCHLIST:
        return isWatchListProps(config, component) ? (
          <LazyWatchlist {...config} nodeId={nodeId} />
        ) : null;

      case Widget.NEWS:
        return isNewsProps(config, component) ? (
          <LazyNews {...config} nodeId={nodeId} />
        ) : null;

      case Widget.TRADES:
        return isTradesProps(config, component) ? (
          <LazyTradesFeed
            {...defaultTradesFeedProps}
            {...config}
            nodeId={nodeId}
          />
        ) : null;

      case Widget.ORDERBOOK:
        return isOrderBookProps(config, component) ? (
          <LazyOrderBook {...config} nodeId={nodeId} />
        ) : null;

      case Widget.CHART:
        return isChartProps(config, component) ? (
          <LazyChart {...config} nodeId={nodeId} />
        ) : null;

      case Widget.POSITIONS:
        return isPositionProps(config, component) ? (
          <LazyPositions {...config} nodeId={nodeId} />
        ) : null;

      case Widget.OPERATIONS:
        return isOperationProps(config, component) ? (
          <LazyOperations {...config} nodeId={nodeId} />
        ) : null;

      case Widget.ORDERS:
        return isOrderTableProps(config, component) ? (
          <LazyOrderTable {...config} nodeId={nodeId} />
        ) : null;

      case Widget.BALANCE:
        return isBalanceProps(config, component) ? (
          <LazyBalance {...config} nodeId={nodeId} />
        ) : null;

      case Widget.HYBRIDORDER:
        return isHybridOrderProps(config, component) ? (
          <LazyHybridOrder {...config} nodeId={nodeId} />
        ) : null;

      case Widget.INSTRUMENT_INFO:
        return isInstrumentInfoProps(config, component) ? (
          <LazyInstrumentInfo {...config} nodeId={nodeId} />
        ) : null;
      default:
        return null;
    }
  }
};
