interface props {
  warranty: number;
  quantity: number;
  isLotCount: boolean;
  lot: number;
}

/**
 * Формула для получения количества штук/лотов из суммы в фьючерсах
 * @param options - параметры для формулы:
 * - warranty - гарантийное обеспечение (Price * Lot *  (PriceStepCost / PriceStep) * MK)
 * - quantity - количество лотов
 * - isLotCount - измеряется ли количество в лотах
 * - lot - количество штук в лоте
 * @returns Количество штук/лотов
 * */
export const getQuantityForFutures = (options: props) => {
  const { warranty, quantity, isLotCount, lot } = options;

  // сначала получаем в лотах
  let currentQuantity = Math.floor(quantity / warranty);

  // после этого переводим из лотов в штуки, если нужно
  currentQuantity = isLotCount ? currentQuantity : currentQuantity * lot;

  // проверка на NaN и Infinity
  return Number.isFinite(currentQuantity) && currentQuantity > 0
    ? currentQuantity
    : 0;
};
