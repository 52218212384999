export enum SignOperationStatus {
  New = 0,
  Read = 1,
  Accepted = 2,
  Failed = 3,
  Cancelled = 4,
  Executed = 5,
  CheckSignFailed = 6,
  SignChecked = 7,
  FailedOnExecution = 8,
  SqlCodeRunning = 9,
}
