import { memo, useCallback, useEffect, useMemo } from 'react';
import { Amount } from '@alfalab/core-components/amount';
import { Typography } from '@alfalab/core-components/typography';

import {
  useAlfaDirectContext,
  useBalance,
  useComputedPositions,
  usePositions,
} from '@terminal/alfadirect/hooks';
import {
  BlueprintTable,
  CellRenderProps,
} from '@terminal/common/ui/Table/ui/BlueprintTable';
import { Cell } from '@terminal/common/ui/Table/ui/Cell';
import { currenciesFI } from '@terminal/core/constants/FIModal';
import { defaultBalanceColumnSetting } from '@terminal/core/constants/tableProps';
import { MINORITY } from '@terminal/core/constants/ui';
import {
  makeDoubleColumns,
  useDoubleRowMode,
} from '@terminal/core/hooks/useDoubleRowMode';
import { usePrevious } from '@terminal/core/hooks/usePrevious';
import { useTableColumns } from '@terminal/core/hooks/useTableColumns';
import { quoteLastSelector } from '@terminal/core/lib/domain/quoteSelector';
import { BalanceItem } from '@terminal/core/types/balance';
import {
  Sort,
  TableColumnSetting,
  Widget,
  WidgetLinkProps,
} from '@terminal/core/types/layout';
import { TableColumnKey } from '@terminal/core/types/tableColumn';

import { defaultColumnFilterRender, useWidgetContext } from '../../../shared';
import { Header } from '../ui/Header';

import { useSelectedAccount } from '../../../shared/hooks/useSelectedAccount';
import { useSortTable } from '../../../shared/hooks/useSortTable';

import styles from './Balance.module.css';

export interface Props extends WidgetLinkProps {
  nodeId?: string;
}

const ACCOUNTS_CHANGED_METRIC = 'accountsChanged';
const DOUBLE_ROW_MODE_METRIC = 'Change Double Rows Mode';
const FIXED_COLUMNS: TableColumnKey[] = [TableColumnKey.IdAccount];

const BalanceWidget = (props: Props) => {
  const { nodeId, tableProps } = props;

  const initialColumns = useMemo(() => {
    if (!tableProps?.columns) {
      return makeDoubleColumns(defaultBalanceColumnSetting, Widget.BALANCE);
    }

    return tableProps.columns;
  }, [tableProps?.columns]);
  const columnsSetting = useTableColumns(
    initialColumns,
    defaultBalanceColumnSetting
  );

  const {
    updateNode,
    useLogWidgetPerformance,
    useLogWidgetUpdatePerformance,
    useTriggerOnConditionOrFirstRender,
    getActiveLayoutKey,
    onWidgetLoad,
    useTreaties,
  } = useWidgetContext();
  const { subGTAccounts, useQuotes, useFinInfoExt } = useAlfaDirectContext();
  const currenciesFinInfoExts = useFinInfoExt(currenciesFI);
  const currenciesQuotes = useQuotes(currenciesFI, {
    selector: quoteLastSelector,
  });

  const performanceMetric = useLogWidgetPerformance(
    Widget.BALANCE,
    getActiveLayoutKey()
  );
  const updateAccountsPerformanceMetric = useLogWidgetUpdatePerformance(
    Widget.BALANCE,
    getActiveLayoutKey()
  );
  const updateDoubleRowModePerformanceMetric = useLogWidgetUpdatePerformance(
    Widget.BALANCE,
    getActiveLayoutKey()
  );

  const { selectedSubAccounts } = useSelectedAccount(tableProps);
  const positions = usePositions();

  const computedPositions = useComputedPositions(
    selectedSubAccounts,
    positions
  );

  const { data: treaties } = useTreaties();

  const data = useBalance(
    {
      positions: computedPositions,
      currenciesQuotes,
      currenciesFinInfoExts,
      subGTAccounts,
      treaties,
    },
    { selectedSubAccounts, groupType: 'market', filterNullBalance: true }
  );

  const sort = useMemo(() => tableProps?.sort, [tableProps?.sort]);

  const updateNodeHandler = useCallback(
    (config) => {
      nodeId && updateNode(nodeId, config);
    },
    [nodeId, updateNode]
  );

  const setSort = useCallback(
    (sort?: Sort) => {
      updateNodeHandler({ tableProps: { sort } });
    },
    [updateNodeHandler]
  );

  const { sortedData, onSort } = useSortTable({
    data,
    sort,
    setSort,
  });

  const [isDoubleRowMode, switchDoubleRowMode] = useDoubleRowMode(
    Widget.BALANCE,
    columnsSetting,
    defaultBalanceColumnSetting,
    updateNodeHandler
  );

  const onReady = useCallback(
    (date) => {
      if (nodeId) {
        onWidgetLoad(nodeId);
      }

      performanceMetric.ready({ rowsNumber: sortedData.length }, date);
      updateAccountsPerformanceMetric.finish(
        ACCOUNTS_CHANGED_METRIC,
        { rowsNumber: sortedData.length },
        date
      );
      updateDoubleRowModePerformanceMetric.finish(
        DOUBLE_ROW_MODE_METRIC,
        { rowsNumber: sortedData.length },
        date
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      nodeId,
      updateAccountsPerformanceMetric,
      updateDoubleRowModePerformanceMetric,
      performanceMetric,
      sortedData.length,
      isDoubleRowMode,
    ]
  );

  const prevSelectedSubAccounts = usePrevious(selectedSubAccounts);

  useEffect(
    () => () => {
      if (prevSelectedSubAccounts) {
        updateAccountsPerformanceMetric.start(ACCOUNTS_CHANGED_METRIC);
      }
    },
    [
      prevSelectedSubAccounts,
      selectedSubAccounts,
      updateAccountsPerformanceMetric,
    ]
  );

  useTriggerOnConditionOrFirstRender(onReady, sortedData.length > 0);

  useEffect(
    () => () => {
      updateDoubleRowModePerformanceMetric.start(DOUBLE_ROW_MODE_METRIC);
    },
    [isDoubleRowMode, updateDoubleRowModePerformanceMetric]
  );

  const doubleRowCellRender = useCallback(
    ({
      secondRowColumnKey,
      firstRowColumnKey,
      firstRowContent,
      rowIndex,
      columnIndex,
      data,
      setHoverIndex,
      cellRender,
    }: {
      secondRowColumnKey: TableColumnKey | undefined;
      firstRowColumnKey: TableColumnKey;
      firstRowContent: React.ReactElement;
      rowIndex: number;
      columnIndex: number;
      data: BalanceItem[];
      setHoverIndex: (rowIndex: number | null) => void;
      cellRender: CellRenderProps<BalanceItem>;
    }) => (
      <div className={styles.cellDoubleRow}>
        <div className={styles.firstRowContent}>{firstRowContent}</div>
        <div className={styles.secondRowWrapper}>
          {firstRowColumnKey === secondRowColumnKey ? (
            firstRowContent
          ) : (
            <>
              {secondRowColumnKey && (
                <div className={styles.secondRowContent}>
                  {cellRender(
                    rowIndex,
                    columnIndex,
                    secondRowColumnKey,
                    data,
                    setHoverIndex,
                    secondRowColumnKey
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    ),
    []
  );

  const cellRender: CellRenderProps<BalanceItem> = useCallback(
    (
      rowIndex,
      columnIndex,
      columnKey,
      data,
      setHoverIndex,
      _columnName,
      isDoubleRowMode
    ) => {
      const secondRow: TableColumnSetting | undefined = isDoubleRowMode
        ? columnsSetting.find(({ key }) => key === columnKey)?.secondRow
        : undefined;

      const isIis = data[rowIndex].isIis;
      const cellValue = data[rowIndex][columnKey];

      const rowContent = (
        <span>
          {cellValue}
          {isIis && (
            <Typography.Text view="secondary-large" color="secondary">
              &nbsp;ИИС
            </Typography.Text>
          )}
        </span>
      );

      switch (columnKey) {
        case TableColumnKey.IdAccount:
          return (
            <Cell leftAlign>
              {isDoubleRowMode
                ? doubleRowCellRender({
                    secondRowColumnKey: secondRow?.key,
                    firstRowColumnKey: columnKey,
                    firstRowContent: rowContent,
                    rowIndex,
                    columnIndex,
                    setHoverIndex,
                    cellRender,
                    data,
                  })
                : rowContent}
            </Cell>
          );
        case TableColumnKey.NameBalanceGroup:
          return (
            <Cell leftAlign>
              {isDoubleRowMode ? (
                doubleRowCellRender({
                  secondRowColumnKey: secondRow?.key,
                  firstRowColumnKey: columnKey,
                  firstRowContent: <>{cellValue}</>,
                  rowIndex,
                  columnIndex,
                  setHoverIndex,
                  cellRender,
                  data,
                })
              ) : (
                <span>{cellValue}</span>
              )}
            </Cell>
          );
        case TableColumnKey.Balance:
        case TableColumnKey.PrevBalance:
        case TableColumnKey.LongBalance:
        case TableColumnKey.ShortBalance:
        case TableColumnKey.InitialMargin:
        case TableColumnKey.MinimumMargin:
        case TableColumnKey.Requirements:
        case TableColumnKey.ImmediateRequirements:
        case TableColumnKey.PortfolioValueWithOrders:
        case TableColumnKey.LiquedBalance:
        case TableColumnKey.InitialMarginWithOrders:
        case TableColumnKey.Money:
        case TableColumnKey.InitialMoney:
          return (
            <Cell>
              {isDoubleRowMode ? (
                doubleRowCellRender({
                  secondRowColumnKey: secondRow?.key,
                  firstRowColumnKey: columnKey,
                  firstRowContent: (
                    <Amount.Pure
                      value={cellValue * MINORITY}
                      minority={MINORITY}
                      view="withZeroMinorPart"
                    />
                  ),
                  rowIndex,
                  columnIndex,
                  setHoverIndex,
                  cellRender,
                  data,
                })
              ) : (
                <Amount.Pure
                  value={cellValue! * MINORITY}
                  minority={MINORITY}
                  view="withZeroMinorPart"
                />
              )}
            </Cell>
          );
        case TableColumnKey.DailyPLRur:
        case TableColumnKey.DailyPLRurPrecent:
        case TableColumnKey.NplRur:
        case TableColumnKey.NplRurPercent:
          return cellValue === null ? (
            <Cell></Cell>
          ) : (
            <Cell>
              {isDoubleRowMode ? (
                doubleRowCellRender({
                  secondRowColumnKey: secondRow?.key,
                  firstRowColumnKey: columnKey,
                  firstRowContent: (
                    <div className={cellValue >= 0 ? styles.up : styles.down}>
                      <Amount.Pure
                        value={cellValue * MINORITY}
                        minority={MINORITY}
                        view="withZeroMinorPart"
                      />
                    </div>
                  ),
                  rowIndex,
                  columnIndex,
                  setHoverIndex,
                  cellRender,
                  data,
                })
              ) : (
                <div className={cellValue >= 0 ? styles.up : styles.down}>
                  <Amount.Pure
                    value={cellValue * MINORITY}
                    minority={MINORITY}
                    view="withZeroMinorPart"
                  />
                </div>
              )}
            </Cell>
          );
        case TableColumnKey.NameClient:
          return (
            <Cell>
              {isDoubleRowMode
                ? doubleRowCellRender({
                    secondRowColumnKey: secondRow?.key,
                    firstRowColumnKey: columnKey,
                    firstRowContent: cellValue,
                    rowIndex,
                    columnIndex,
                    setHoverIndex,
                    cellRender,
                    data,
                  })
                : cellValue}
            </Cell>
          );
        default:
          return null;
      }
    },
    [columnsSetting, doubleRowCellRender]
  );

  const saveColumnSetting = useCallback(
    (columns: TableColumnSetting[]) => {
      if (columns[0].key !== TableColumnKey.IdAccount) {
        return;
      }

      updateNodeHandler({ tableProps: { columns } });
    },
    [updateNodeHandler]
  );

  return (
    <div className={styles.container}>
      <Header
        columnsSetting={columnsSetting}
        defaultColumnSetting={defaultBalanceColumnSetting}
        updateNode={updateNode}
        nodeId={nodeId}
        selectedSubAccounts={selectedSubAccounts}
        isDoubleRowMode={isDoubleRowMode}
        switchDoubleRowMode={switchDoubleRowMode}
      />
      <BlueprintTable<BalanceItem>
        sort={sort}
        onSort={onSort}
        data={sortedData}
        columnSetting={columnsSetting}
        cellRender={cellRender}
        columnFilterRender={defaultColumnFilterRender}
        onReorder={saveColumnSetting}
        onChangeWidth={saveColumnSetting}
        isDoubleRowMode={isDoubleRowMode}
        fixedColumns={FIXED_COLUMNS}
      />
    </div>
  );
};

export const Balance = memo(BalanceWidget);
