import { arrayBufferToString, toBase64 } from 'pvutils';
import { encode } from 'single-byte';

import { createCMS } from '@terminal/core/lib/crypto/cms.js';
import { getRSAKey } from '@terminal/core/lib/db/rsaKeys';
import { Certificate } from '@terminal/core/types/certificate';

import { AssignmentBody } from '../model/types/AssignmentBody';

export async function signMessage(
  assignment: string,
  certificate: Certificate
): Promise<AssignmentBody> {
  const rsaKeys = await getRSAKey(certificate?.idCertificate);

  if (!rsaKeys) {
    throw new Error('Не найден приватный ключ для выбранного сертификата');
  }

  const encoder = new TextEncoder();
  const assignmentUtf8 = encoder.encode(assignment);
  const assignmentWin1251 = encode('windows-1251', assignment);

  const signedAssignment = await createCMS(
    assignmentUtf8,
    certificate.certificate,
    rsaKeys.keys.privateKey,
    'SHA-512'
  );
  const signedAssignmentWin1251 = await createCMS(
    assignmentWin1251,
    certificate.certificate,
    rsaKeys.keys.privateKey,
    'SHA-512'
  );

  return {
    signedMessage: toBase64(arrayBufferToString(signedAssignment)),
    signedMessageWindows1251: toBase64(
      arrayBufferToString(signedAssignmentWin1251)
    ),
  };
}
