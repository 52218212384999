import { initialSettings } from '../uiSlice';

import { CommonSettings } from '../../../types/ui';

export const getSettingsFromLS = () => {
  let settingsObject = { ...initialSettings };

  const settingsJson = localStorage.getItem('settings');

  if (settingsJson) {
    const savedSettings = JSON.parse(settingsJson);

    settingsObject = {
      design: {
        ...initialSettings.design,
        ...savedSettings.design,
      },
      notifications: {
        ...initialSettings.notifications,
        ...savedSettings.notifications,
      },
      defaultValues: {
        ...initialSettings.defaultValues,
        ...savedSettings.defaultValues,
      },
      tickers: {
        ...savedSettings.tickers,
      },
    };
  }

  return settingsObject as CommonSettings;
};

export const saveSettingsToLS = (settings: CommonSettings) => {
  window?.requestAnimationFrame(() => {
    localStorage.setItem('settings', JSON.stringify(settings));
  });
};
