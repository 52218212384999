import { format, subYears } from 'date-fns';
import { useEffect, useState } from 'react';
import { Col } from '@alfalab/core-components/grid/col';
import { Row } from '@alfalab/core-components/grid/row';
import { Space } from '@alfalab/core-components/space';
import { Textarea, TextareaProps } from '@alfalab/core-components/textarea';

import { StaticField } from '../../../../shared/ui/StaticField';
import {
  ACCOUNTING_EXPENSES_TEXT,
  ACCOUNTING_EXPENSES_THEME_ID,
  WITHOLDING_NDFL_FOR_UNCOVERED_TEXT,
  WITHOLDING_NDFL_FOR_UNCOVERED_THEME_ID,
} from '../../constants';
import { SendButton } from '../SendButton';

interface FreeOrderFormDefaultProps {
  themeId: number;
  sending?: boolean;
  onSend: (text: string) => void;
}

const DISABLED_THEMES = [
  ACCOUNTING_EXPENSES_THEME_ID,
  WITHOLDING_NDFL_FOR_UNCOVERED_THEME_ID,
];

export const FreeOrderFormDefault = (props: FreeOrderFormDefaultProps) => {
  const { themeId, sending, onSend } = props;

  const [text, setText] = useState('');

  useEffect(() => {
    if (themeId === ACCOUNTING_EXPENSES_THEME_ID) {
      setText(ACCOUNTING_EXPENSES_TEXT);
    } else if (themeId === WITHOLDING_NDFL_FOR_UNCOVERED_THEME_ID) {
      const template = WITHOLDING_NDFL_FOR_UNCOVERED_TEXT;
      const year = format(subYears(new Date(), 1), 'yyyy');
      const text = template.replace('#YEAR#', year);

      setText(text);
    } else {
      setText('');
    }
  }, [themeId]);

  const handleTextChange: TextareaProps['onChange'] = (_, { value }) => {
    setText(value);
  };

  const disabled = DISABLED_THEMES.includes(themeId);

  return (
    <Space fullWidth size="l">
      {disabled ? (
        <StaticField label="Поручение">{text}</StaticField>
      ) : (
        <Textarea
          label="Поручение"
          labelView="outer"
          size="s"
          onChange={handleTextChange}
          value={text}
          block
          placeholder="Напишите текст поручения"
        />
      )}

      <Row>
        <Col width={6}>
          <SendButton
            block
            disabled={!text}
            loading={sending}
            onClick={() => onSend(text)}
          />
        </Col>
      </Row>
    </Space>
  );
};
