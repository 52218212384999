import { format, isBefore, isValid, parse, subYears } from 'date-fns';
import {
  forwardRef,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { mergeRefs } from 'react-merge-refs';
import {
  CalendarDesktop as Calendar,
  CalendarDesktopProps as CalendarProps,
} from '@alfalab/core-components/calendar/desktop';
import { CalendarInputDesktop as CalendarInput } from '@alfalab/core-components/calendar-input/desktop';
import { Space } from '@alfalab/core-components/space';
import { TagDesktop as Tag } from '@alfalab/core-components/tag/desktop';

import { IntervalIds, INTERVALS } from '@terminal/core/constants/filters';
import { useFilterDateRange } from '@terminal/core/hooks';
import { DateRange as DateRangeValue } from '@terminal/core/types/ranges';

import styles from './DateRange.module.css';

interface Props {
  calendarFromRef: RefObject<HTMLInputElement>;
  calendarToRef: RefObject<HTMLInputElement>;
  filterValue?: DateRangeValue;
  onChange: (value: DateRangeValue) => void;
  maxDate?: Date;
}

const minDate = subYears(new Date(), 20);

export const DateRange = (props: Props) => {
  const { calendarFromRef, calendarToRef, filterValue, maxDate, onChange } =
    props;

  const [dateFrom, setDateFrom] = useState<Date>(
    filterValue?.dateFrom ?? new Date()
  );
  const [dateTo, setDateTo] = useState<Date>(filterValue?.dateTo ?? new Date());

  const [isCustom, setIsCustom] = useState(false);
  const [selectedInterval, setSelectedInterval] = useState<IntervalIds>(
    filterValue?.selectedInterval ?? IntervalIds.WEEK
  );

  const handleChange = useCallback((from, to) => {
    setDateFrom(from);
    setDateTo(to);
  }, []);

  const toggleCalendar = useCallback(
    (value: boolean) => setIsCustom(value),
    []
  );

  useFilterDateRange({
    selectedInterval,
    onChange: handleChange,
    toggleCalendar,
  });

  const handleInputChange = useCallback(
    (value: string, isToDate?: boolean) => {
      const date = parse(value, 'dd.MM.yyyy', new Date());

      if (
        !isValid(date) ||
        !isBefore(minDate, date) ||
        (maxDate && !isBefore(date, maxDate))
      ) {
        return;
      }

      if (isToDate) {
        setDateTo(date);

        return;
      }

      setDateFrom(date);
    },
    [maxDate]
  );

  const handleInputFrom = useCallback(
    (_, { value }) => {
      handleInputChange(value);
    },
    [handleInputChange]
  );

  const handleInputTo = useCallback(
    (_, { value }) => {
      handleInputChange(value, true);
    },
    [handleInputChange]
  );

  const calendarFrom = useMemo(
    () =>
      forwardRef(function calendarFrom(props: CalendarProps, ref) {
        return <Calendar {...props} ref={mergeRefs([calendarFromRef, ref])} />;
      }),
    [calendarFromRef]
  );

  const calendarTo = useMemo(
    () =>
      forwardRef(function calendarTo(props: CalendarProps, ref) {
        return <Calendar {...props} ref={mergeRefs([calendarToRef, ref])} />;
      }),
    [calendarToRef]
  );

  useEffect(() => {
    onChange({ dateFrom, dateTo, selectedInterval });
  }, [dateFrom, dateTo, selectedInterval, onChange]);

  return (
    <div className={styles.wrapper} tabIndex={0}>
      <Space direction="horizontal" size={[8, 16]} wrap>
        {INTERVALS.map(({ id, title }) => (
          <Tag
            key={id}
            size="xs"
            view="filled"
            checked={id === selectedInterval}
            onClick={() => setSelectedInterval(id)}
          >
            {title}
          </Tag>
        ))}
      </Space>
      {isCustom && (
        <>
          <div className={styles.inputs}>
            <CalendarInput
              className={styles.input}
              value={format(dateFrom, 'dd.MM.yyyy')}
              minDate={minDate.getTime()}
              maxDate={maxDate?.getTime()}
              onChange={handleInputFrom}
              Calendar={calendarFrom}
            />
            <span className={styles.dash}>&mdash;</span>
            <CalendarInput
              className={styles.input}
              value={format(dateTo, 'dd.MM.yyyy')}
              minDate={minDate.getTime()}
              maxDate={maxDate?.getTime()}
              onChange={handleInputTo}
              Calendar={calendarTo}
            />
          </div>
        </>
      )}
    </div>
  );
};
