import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ButtonDesktop as Button } from '@alfalab/core-components/button/desktop';
import { InputDesktop as Input } from '@alfalab/core-components/input/desktop';
import { Typography } from '@alfalab/core-components/typography';

import { BondScreenerTemplate } from '@terminal/core/types/bondScreener';

import { BondScreenerModalType } from './types';

import styles from './BondScreenerModal.module.css';

interface Props {
  templateId?: string;
  templates: BondScreenerTemplate[];
  type: BondScreenerModalType;
  onClose: () => void;
  onSubmit: (name: string) => void;
}

export const BondScreenerModal = (props: Props) => {
  const { templateId, type, onClose, onSubmit, templates } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(
    templates.find(({ id }) => id === templateId)?.name ?? ''
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const isDuplicate = useMemo(() => {
    return Boolean(templates.find((item) => item?.name === value));
  }, [value, templates]);

  const isDisabled = useMemo(() => {
    if (type === 'delete') {
      return;
    }

    return isDuplicate || !value;
  }, [isDuplicate, type, value]);

  const getTitle = useCallback(() => {
    switch (type) {
      case BondScreenerModalType.Create:
        return 'Новый шаблон поиска';
      case BondScreenerModalType.Edit:
        return 'Изменить шаблон поиска';
      case BondScreenerModalType.Delete:
        return 'Удалить шаблон?';

      default:
        break;
    }
  }, [type]);

  const getSubmitButtonName = useCallback(() => {
    switch (type) {
      case BondScreenerModalType.Create:
        return 'Создать';
      case BondScreenerModalType.Edit:
        return 'Сохранить';
      case BondScreenerModalType.Delete:
        return 'Удалить';

      default:
        break;
    }
  }, [type]);

  return (
    <div className={styles.container}>
      <Typography.TitleMobile view="xsmall" tag="h3">
        {getTitle()}
      </Typography.TitleMobile>
      {type !== 'delete' ? (
        <Input
          ref={inputRef}
          value={value}
          className={styles.customInput}
          onChange={(e) => setValue(e.target.value)}
          onClear={() => setValue('')}
          block
          placeholder="Введите название"
          clear
        />
      ) : (
        <Typography.Text view="primary-medium">
          Удалить шаблон «{templates.find(({ id }) => id === templateId)?.name}
          »? Данное действие необратимо
        </Typography.Text>
      )}
      <div className={styles.buttonWrapper}>
        <Button
          block={true}
          view="tertiary"
          onClick={() => onClose()}
          size="xs"
        >
          Отмена
        </Button>
        <Button
          block={true}
          onClick={() => {
            onSubmit(value);
            onClose();
          }}
          size="xs"
          view="secondary"
          disabled={isDisabled}
        >
          {getSubmitButtonName()}
        </Button>
      </div>
    </div>
  );
};
