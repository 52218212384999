import { I18nLabel } from 'flexlayout-react';

export const i18nMapper = (id: I18nLabel, param?: string) => {
  if (id === I18nLabel.Move_Tab) {
    return param;
  }

  if (id === I18nLabel.Close_Tab) {
    return 'Закрыть';
  }

  if (id === I18nLabel.Overflow_Menu_Tooltip) {
    return 'Скрытые вкладки';
  }

  return id;
};
