import { ElementType, IJsonRowNode } from '@terminal/core/types/layout';

//Важная информация из библиотеки flex-layout
//row - rows contains a list of tabsets and child rows,
//the top level 'row' will render horizontally(unless the global attribute rootOrientationVertical is set),
//child 'rows' will render in the opposite orientation to their parent
const countRow = (
  el: IJsonRowNode,
  orientation: 'hor' | 'vert' = 'hor',
  row = 0
): number => {
  //Если это row с горизонтальной ориентацией и в ней только табы, то считаем все их за одну строку
  if (
    el.children.every(({ type }) => type === ElementType.TABSET) &&
    orientation === 'hor'
  ) {
    return ++row;
    //Если это row с вертикальной ориентацией и в ней только табы, то считаем сравнием с текущим кол-вом строк в этом блоке
  } else if (
    el.children.every(({ type }) => type === ElementType.TABSET) &&
    orientation === 'vert'
  ) {
    return row > el.children.length ? row : el.children.length;
  } else {
    //Если есть и строки и/или табсеты то сравниваем по колонкам, в какой будет больше строк
    return el.children?.reduce((acc, child) => {
      //Отдельный табсет в вертикальной ориентации считается за отдельную строку
      if (child.type === ElementType.TABSET && orientation === 'vert') {
        return ++acc;
      } else if (child.type === ElementType.ROW) {
        //Подсчитываем строки в блоке
        const childRow = countRow(
          child,
          orientation === 'hor' ? 'vert' : 'hor',
          acc
        );

        //Возвращаем наибольшее значение
        return childRow < acc ? acc : childRow;
      } else {
        return acc;
      }
    }, row);
  }
};

//Важная информация из библиотеки flex-layout
//row - rows contains a list of tabsets and child rows,
//the top level 'row' will render horizontally(unless the global attribute rootOrientationVertical is set),
//child 'rows' will render in the opposite orientation to their parent
const countCol = (
  el: IJsonRowNode,
  orientation: 'hor' | 'vert' = 'hor',
  col = 0
): number => {
  //Если это row с горизонтальной ориентацией и в ней только табы, то считаем сравнием с текущим кол-вом колонок в этом блоке
  if (
    el.children.every(({ type }) => type === ElementType.TABSET) &&
    orientation === 'hor'
  ) {
    return col > el.children.length ? col : el.children.length;
    //Если это row с вертикальной ориентацией и в ней только табы, то считаем все их за одну колонку
  } else if (
    el.children.every(({ type }) => type === ElementType.TABSET) &&
    orientation === 'vert'
  ) {
    return ++col;
  } else {
    //Если есть и строки и/или табсеты то сравниваем по колонкам, в какой будет больше строк
    return el.children?.reduce((acc, child) => {
      //Отдельный табсет в горизонтальной ориентации считается за отдельную строку
      if (child.type === ElementType.TABSET && orientation === 'hor') {
        return ++acc;
      } else if (child.type === ElementType.ROW) {
        //Подсчитываем строки в блоке
        const childRow = countCol(
          child,
          orientation === 'hor' ? 'vert' : 'hor',
          acc
        );

        //Возвращаем наибольшее значение
        return childRow < acc ? acc : childRow;
      } else {
        return acc;
      }
    }, col);
  }
};

export const countRowAndCol = (el: IJsonRowNode) => {
  const col = countCol(el);
  const row = countRow(el);

  return { row, col };
};
