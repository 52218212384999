import { TabNode } from 'flexlayout-react';
import { Suspense } from 'react';
import { Spinner } from '@alfalab/core-components/spinner';

import { Widget } from '@terminal/core/types/layout';

import { LazyWidgetFactory } from '../../../../shared/ui/LazyWidgetFactory';

interface WidgetFactoryProps {
  node: TabNode;
}

export const WidgetFactory = ({ node }: WidgetFactoryProps) => {
  const component = node.getComponent() as Widget;
  const config = node.getConfig();
  const nodeId = node.getId();
  const visible = node.isVisible();

  return (
    <Suspense
      fallback={
        <div
          style={{ display: 'grid', placeContent: 'center', height: '100%' }}
        >
          <Spinner size="m" visible />
        </div>
      }
    >
      <LazyWidgetFactory
        config={config}
        component={component}
        visible={visible}
        nodeId={nodeId}
      />
    </Suspense>
  );
};
