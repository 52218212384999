import { useMemo } from 'react';
import PlusMIcon from '@alfalab/icons-glyph/PlusMIcon';

import { MenuGroup } from '@terminal/common/src/components/Menu';
import { useFullFIs } from '@terminal/core/hooks/domain';
import { isNotUndefined } from '@terminal/core/lib/isNotUndefined';
import { useStore } from '@terminal/core/store';
import {
  EDITABLE_WATCH_LISTS,
  WatchList,
  WatchListItem,
  WatchListType,
} from '@terminal/core/types/watchList';

import { useWidgetContext } from '../context';

interface UseWatchlistsGroupsArgs {
  idFis: number[];
  onNewList: (watchListItems: WatchListItem[]) => void;
  onAddToList: (watchListItems: WatchListItem[], watchlist: WatchList) => void;
}

export function useWatchlistsGroups({
  idFis,
  onNewList,
  onAddToList,
}: UseWatchlistsGroupsArgs): MenuGroup[] {
  const { useWatchLists } = useWidgetContext();
  const addToWatchListMultiple = useStore(
    (state) => state.addToWatchListMultiple
  );

  const watchLists = useWatchLists();
  const fullFis = useFullFIs(idFis);

  const watchListsItemsFromFullFi = useMemo(() => {
    return fullFis
      .filter((fullFi) => isNotUndefined(fullFi))
      .map((fullFi) => ({
        idFI: fullFi!.idFI,
        idObject: fullFi!.idObject,
        codeMarketBoard: fullFi!.codeMarketBoard,
        symbolObject: fullFi!.symbolObject,
      }));
  }, [fullFis]);

  const filteredWatchLists = watchLists
    .filter(
      (list) =>
        EDITABLE_WATCH_LISTS.includes(list.type) &&
        list.type !== WatchListType.AFLA
    )
    .map((list) => ({
      key: list.id,
      content: list.name,
      value: list,
    }));

  return [
    {
      key: '1',
      items: [
        {
          key: '1.1',
          type: 'button',
          label: 'Новый список',
          Icon: PlusMIcon,
          onClick: () => onNewList(watchListsItemsFromFullFi),
          hideMenu: true,
        },
      ],
    },
    {
      key: '2',
      label: 'Мои списки',
      items: filteredWatchLists.map(({ content, key, value }) => ({
        key: key,
        type: 'button',
        label: content,
        hideMenu: true,
        onClick: () => {
          addToWatchListMultiple(
            key,
            watchListsItemsFromFullFi.filter(
              (item) =>
                !value.instruments.some(
                  (itemInList) => itemInList.idFI === item.idFI
                )
            )
          );
          onAddToList(watchListsItemsFromFullFi, value);
        },
      })),
    },
  ];
}
