import { useState } from 'react';

import { SmsSignCreated } from '@terminal/core/lib/rest/lkSmsSignOperation';

import { useSignSmsDialog } from '../../sign';
import { useAddAccountPreregister } from './useAddAccountPreregister';

import type { AddAccountPreregisterAI } from '../model/types/AddAccountPreregisterAI';
import type { AddAccountPreregisterGI } from '../model/types/AddAccountPreregisterGI';
import type { AddAccountTypeAI } from '../model/types/AddAccountTypeAI';
import type { AddAccountTypeGI } from '../model/types/AddAccountTypeGI';

function getAccountTypeName(type: AddAccountTypeAI | AddAccountTypeGI) {
  if (type === 'iia') {
    return 'ИИС';
  }

  if (type === 'REPO') {
    return 'счёт РЕПО';
  }

  return 'Брокерский счёт';
}

export function useAddAccountForm<
  T extends AddAccountTypeAI | AddAccountTypeGI,
  R extends AddAccountPreregisterAI | AddAccountPreregisterGI
>(
  type: T,
  requestPrereg: (type: T) => Promise<R>,
  requestRegistration: (operationId: string) => Promise<SmsSignCreated>
) {
  const [agree, setAgree] = useState(false);
  const [textOpen, setTextOpen] = useState(false);

  const {
    data: prereg,
    isFetching: preregPending,
    isError,
  } = useAddAccountPreregister(type, requestPrereg);

  const toggleAgree = () => {
    setAgree((agree) => !agree);
  };

  const smsSign = useSignSmsDialog({
    initAction: requestRegistration,
    successMsg: {
      title: `Вы открыли ${getAccountTypeName(type)}!`,
    },
    errorMsg: {
      title: 'Мы не смогли открыть новый счёт',
      text: 'Попробуйте ещё раз или напишите нам в чат',
    },
  });

  const failed = smsSign.isCreateError || isError;

  const signAddAccount = async () => {
    if (prereg?.id) {
      smsSign.create(prereg?.id);
    }
  };

  return {
    agree,
    toggleAgree,
    textOpen,
    setTextOpen,
    prereg,
    preregPending,
    failed,
    signAddAccount,
    smsSign,
  };
}
