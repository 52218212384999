import { v4 } from 'uuid';

import {
  defaultBalanceColumnSetting,
  defaultTradesFeedProps,
  getDefaultWatchListColumnSetting,
} from './tableProps';
import { defaultListId as selectedListId } from './WatchList';

import {
  ElementType,
  LayoutModel,
  LayoutType,
  LinkGroups,
  LinkName,
  Size,
  Widget,
} from '../types/layout';

export const WidgetsNameMap = new Map<Widget, string>([
  [Widget.WATCHLIST, 'Инструменты'],
  [Widget.TRADES, 'Лента сделок'],
  [Widget.ORDERBOOK, 'Стакан'],
  [Widget.CHART, 'График'],
  [Widget.POSITIONS, 'Позиции'],
  [Widget.OPERATIONS, 'Операции'],
  [Widget.ORDERS, 'Заявки'],
  [Widget.BALANCE, 'Балансы'],
  [Widget.HYBRIDORDER, 'Торговля'],
  [Widget.INSTRUMENT_INFO, 'Инфо'],
  [Widget.OPERATIONS_HISTORY, 'История операций'],
  [Widget.NEWS, 'Новости'],
  [Widget.BOND_SCREENER, 'Скринер облигаций'],
]);

export const WidgetsDefaultSizeMap = new Map<Widget, Size>([
  [Widget.WATCHLIST, { width: 420, height: 650 }],
  [Widget.TRADES, { width: 280, height: 650 }],
  [Widget.ORDERBOOK, { width: 280, height: 650 }],
  [Widget.CHART, { width: 750, height: 550 }],
  [Widget.POSITIONS, { width: 850, height: 250 }],
  [Widget.OPERATIONS, { width: 850, height: 250 }],
  [Widget.ORDERS, { width: 850, height: 250 }],
  [Widget.BALANCE, { width: 850, height: 250 }],
  [Widget.HYBRIDORDER, { width: 320, height: 500 }],
  [Widget.INSTRUMENT_INFO, { width: 350, height: 650 }],
  [Widget.NEWS, { width: 350, height: 650 }],
  [Widget.BOND_SCREENER, { width: 1100, height: 550 }],
]);

export const WidgetsDefaultConfigMap = new Map<string, any>([
  [
    Widget.WATCHLIST,
    { selectedListId, columns: getDefaultWatchListColumnSetting() },
  ],
  [
    Widget.ORDERBOOK,
    {
      idFi: 144950,
      symbol: 'SBER',
    },
  ],
  [
    Widget.CHART,
    {
      symbol: 'SBER',
      idFi: 144950,
    },
  ],
  [Widget.POSITIONS, null],
  [Widget.OPERATIONS, null],
  [Widget.ORDERS, null],
  [Widget.BALANCE, { columns: defaultBalanceColumnSetting }],
  [Widget.TRADES, defaultTradesFeedProps],
  [
    Widget.HYBRIDORDER,
    {
      idFi: 144950,
      symbol: 'SBER',
    },
  ],
  [
    Widget.INSTRUMENT_INFO,
    {
      idFi: 144950,
      symbol: 'SBER',
    },
  ],
  [
    Widget.NEWS,
    {
      symbol: 'SBER',
      idFi: 144950,
    },
  ],
  [Widget.BOND_SCREENER, null],
]);

export const createInitialFlexLayout = (
  layoutSize: Size
): LayoutModel<{ type: LayoutType.Flex }> => {
  const { width: layoutWidth, height: layoutHeight } = layoutSize;

  const FIRST_TABSET = {
    size: {
      width: Math.trunc(layoutWidth * 0.2),
      height: layoutHeight,
    },
    position: { x: 0, y: 0 },
  };

  const SECOND_TOP_TABSET = {
    size: {
      width: Math.trunc(layoutWidth * 0.6),
      height: Math.trunc(layoutHeight * 0.65),
    },
    position: { x: Math.trunc(layoutWidth * 0.2), y: 0 },
  };

  const SECOND_BOTTON_TABSET = {
    size: {
      width: Math.trunc(layoutWidth * 0.6),
      height: Math.trunc(layoutHeight * 0.35),
    },
    position: {
      x: Math.trunc(layoutWidth * 0.2),
      y: Math.trunc(layoutHeight * 0.65),
    },
  };

  const THIRD_TABSET = {
    size: {
      width: Math.trunc(layoutWidth * 0.2),
      height: layoutHeight,
    },
    position: { x: Math.trunc(layoutWidth * 0.8), y: 0 },
  };

  return {
    type: LayoutType.Flex,
    layout: [
      {
        id: v4(),
        ...FIRST_TABSET,
        weight: 1,
        children: [
          {
            nodeId: v4(),
            component: Widget.WATCHLIST,
            name: WidgetsNameMap.get(Widget.WATCHLIST),
            ...WidgetsDefaultConfigMap.get(Widget.WATCHLIST),
            visible: true,
          },
        ],
      },
      {
        id: v4(),
        ...SECOND_TOP_TABSET,
        weight: 1,
        children: [
          {
            nodeId: v4(),
            component: Widget.CHART,
            name: WidgetsNameMap.get(Widget.CHART),
            ...WidgetsDefaultConfigMap.get(Widget.CHART),
            visible: true,
          },
        ],
      },
      {
        id: v4(),
        ...SECOND_BOTTON_TABSET,
        weight: 1,
        children: [
          {
            nodeId: v4(),
            component: Widget.POSITIONS,
            name: WidgetsNameMap.get(Widget.POSITIONS),
            ...WidgetsDefaultConfigMap.get(Widget.POSITIONS),
            visible: true,
          },
        ],
      },
      {
        id: v4(),
        ...THIRD_TABSET,
        weight: 1,
        children: [
          {
            nodeId: v4(),
            component: Widget.ORDERBOOK,
            name: WidgetsNameMap.get(Widget.ORDERBOOK),
            ...WidgetsDefaultConfigMap.get(Widget.ORDERBOOK),
            visible: true,
          },
        ],
      },
    ],
  };
};

export const initialJson: LayoutModel<{ type: LayoutType.Grid }> = {
  type: LayoutType.Grid,
  global: {
    splitterSize: 4,
    splitterExtra: 0,
    tabSetTabStripHeight: 24,
    tabEnableRename: false,
    tabSetEnableDrag: false,
    tabSetEnableMaximize: false,
    tabSetMinHeight: 0,
    tabSetMinWidth: 0,
    enableEdgeDock: true,
  },
  layout: {
    type: ElementType.ROW,
    weight: 3,
    children: [
      {
        type: ElementType.TABSET,
        weight: 1,
        children: [
          {
            type: ElementType.TAB,
            name: WidgetsNameMap.get(Widget.TRADES),
            component: Widget.TRADES,
            config: {
              idFi: 144950,
              symbol: 'SBER',
            },
          },
          {
            type: ElementType.TAB,
            name: WidgetsNameMap.get(Widget.TRADES),
            component: Widget.TRADES,
            config: {
              idFi: 146986,
              symbol: 'GAZP',
            },
          },
        ],
      },
      {
        type: ElementType.ROW,
        weight: 2,
        children: [
          {
            type: ElementType.TABSET,
            weight: 1,
            children: [
              {
                type: ElementType.TAB,
                name: WidgetsNameMap.get(Widget.CHART),
                component: Widget.CHART,
                config: {
                  symbol: 'SBER',
                  idFi: 144950,
                },
              },
              {
                type: ElementType.TAB,
                name: WidgetsNameMap.get(Widget.CHART),
                component: Widget.CHART,
                config: {
                  symbol: 'GAZP',
                  idFi: 146986,
                },
              },
            ],
          },
          {
            type: ElementType.TABSET,
            weight: 1,
            children: [
              {
                type: ElementType.TAB,
                name: WidgetsNameMap.get(Widget.WATCHLIST),
                component: Widget.WATCHLIST,
                config: {
                  selectedListId,
                },
              },
            ],
          },
        ],
      },
      {
        type: ElementType.TABSET,
        weight: 1,
        children: [
          {
            type: ElementType.TAB,
            name: WidgetsNameMap.get(Widget.ORDERBOOK),
            component: Widget.ORDERBOOK,
            config: {
              idFi: 144950,
              symbol: 'SBER',
            },
          },
          {
            type: ElementType.TAB,
            name: WidgetsNameMap.get(Widget.ORDERBOOK),
            component: Widget.ORDERBOOK,
            config: {
              idFi: 146986,
              symbol: 'GAZP',
            },
          },
        ],
      },
    ],
  },
};

export const importOptions = {
  managePeriodicity: true,
  preserveTicksAndCandleWidth: true,
};

export const initialLayoutLink: LinkGroups = {
  [LinkName.BLUE]: {
    nodeIds: [],
  },
  [LinkName.GREEN]: {
    nodeIds: [],
  },
  [LinkName.RED]: {
    nodeIds: [],
  },
  [LinkName.YELLOW]: {
    nodeIds: [],
  },
};

export const WidgetWithName = [
  Widget.CHART,
  Widget.TRADES,
  Widget.ORDERBOOK,
  Widget.HYBRIDORDER,
];

export const DEFAULT_WIDGET_WIDTH = 0.5;
export const DEFAULT_WIDGET_HEIGHT = 0.35;
