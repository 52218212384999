import cn from 'classnames';
import sumBy from 'lodash/sumBy';
import { memo, useMemo, useRef } from 'react';
import { Amount } from '@alfalab/core-components/amount';
import { IconButton } from '@alfalab/core-components/icon-button';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowDownTriangleSIcon } from '@alfalab/icons-glyph/ArrowDownTriangleSIcon';
import { ArrowUpTriangleSIcon } from '@alfalab/icons-glyph/ArrowUpTriangleSIcon';
import { CashMoneyMIcon } from '@alfalab/icons-glyph/CashMoneyMIcon';
import { InformationCircleLineMIcon } from '@alfalab/icons-glyph/InformationCircleLineMIcon';
import { SumMIcon } from '@alfalab/icons-glyph/SumMIcon';

import {
  getDailyPLRurPrecent,
  getNPLRurPrecent,
  useAlfaDirectContext,
  useBalance,
} from '@terminal/alfadirect/hooks';
import { currenciesFI } from '@terminal/core/constants/FIModal';
import { MINORITY } from '@terminal/core/constants/ui';
import { quoteLastSelector } from '@terminal/core/lib/domain/quoteSelector';
import { BalanceItem } from '@terminal/core/types/balance';
import { BalanceSettingsProps } from '@terminal/core/types/layout';
import { PositionItem } from '@terminal/core/types/position';

import { useWidgetContext } from '../../../../shared';

import styles from './PortfolioBalance.module.css';

interface Props {
  positions: PositionItem[];
  selectedSubAccounts: string[];
  nodeId?: string;
  settings?: BalanceSettingsProps;
  getPositionWarranty: (idAccount: number, isin: string | undefined) => number;
}

const PUBlock = ({ balances }: { balances: BalanceItem[] }) => {
  const dailyPLRur = sumBy(balances, 'dailyPLRur');
  const dailyPLRurPrecent = getDailyPLRurPrecent(balances);

  const NPLRur = sumBy(balances, 'NPLRur');
  const NPLRurPercent = getNPLRurPrecent(balances);

  return (
    <Typography.Text view="secondary-large" className={styles.paramsWrapper}>
      {/* ПУ(дн) */}
      <div className={styles.valueWithLabel}>
        <span className={styles.label}>День</span>
        <Amount.Pure
          value={dailyPLRur * MINORITY}
          minority={MINORITY}
          view="withZeroMinorPart"
          currency="RUB"
          className={dailyPLRur >= 0 ? styles.up : styles.down}
        />
      </div>
      {/* ПУ(дн)% */}
      <div
        className={cn(
          styles.valueWithLabel,
          dailyPLRurPrecent >= 0 ? styles.up : styles.down
        )}
      >
        <Amount.Pure
          value={dailyPLRurPrecent * MINORITY}
          minority={MINORITY}
          view="withZeroMinorPart"
        />
        &nbsp;%
      </div>
      {/* НПУ */}
      <div className={styles.valueWithLabel}>
        <span className={styles.label}>За всё время</span>
        <Amount.Pure
          value={NPLRur * MINORITY}
          minority={MINORITY}
          view="withZeroMinorPart"
          currency="RUB"
          className={NPLRur >= 0 ? styles.up : styles.down}
        />
      </div>
      {/* НПУ% */}
      <div
        className={cn(
          styles.valueWithLabel,
          NPLRurPercent >= 0 ? styles.up : styles.down
        )}
      >
        <Amount.Pure
          value={NPLRurPercent * MINORITY}
          minority={MINORITY}
          view="withZeroMinorPart"
        />
        &nbsp;%
      </div>
    </Typography.Text>
  );
};

const BalanceBlock: React.FC<{ balance: number }> = ({ balance }) => (
  <Typography.Text view="secondary-large" className={styles.paramsWrapper}>
    {/* Баланс */}
    <SumMIcon
      height={18}
      width={18}
      color="var(--color-light-graphic-secondary)"
    />
    {balance >= 0 ? (
      <ArrowUpTriangleSIcon
        height={12}
        width={12}
        className={cn(styles.up, styles.arrow)}
      />
    ) : (
      <ArrowDownTriangleSIcon
        height={12}
        width={12}
        className={cn(styles.down, styles.arrow)}
      />
    )}
    <Amount.Pure
      value={balance * MINORITY}
      minority={MINORITY}
      view="withZeroMinorPart"
      currency="RUB"
      className={balance >= 0 ? styles.up : styles.down}
    />
  </Typography.Text>
);

const AdditionalBlock = memo(({ go }: { go: number }) => {
  return (
    <Typography.Text view="secondary-large" className={styles.paramsWrapper}>
      <CashMoneyMIcon height={18} width={18} color="#85858D" />
      <div className={styles.paramsWrapper}>
        <span className={styles.label}>ГО</span>
        <Amount.Pure
          value={go * MINORITY}
          minority={MINORITY}
          view="withZeroMinorPart"
          currency="RUB"
        />
      </div>
    </Typography.Text>
  );
});

AdditionalBlock.displayName = 'AdditionalBlock';

interface FullInfoProps {
  balance: number;
  go: number;
  balances: BalanceItem[];
  allowShowGO: boolean;
  settings?: BalanceSettingsProps;
}

const FullInfo: React.FC<FullInfoProps> = memo((props) => {
  const { balance, balances, settings, allowShowGO, go } = props;

  return (
    <TooltipDesktop
      position="top-end"
      trigger="hover"
      targetClassName={styles.tooltipTarget}
      contentClassName={styles.tooltipContent}
      content={
        <>
          {((settings?.showBalance ?? true) || allowShowGO) && (
            <div className={styles.paramsWrapper}>
              {(settings?.showBalance ?? true) && (
                <BalanceBlock balance={balance} />
              )}
              {allowShowGO && <AdditionalBlock go={go} />}
            </div>
          )}
          {(settings?.showPU ?? true) && <PUBlock balances={balances} />}
        </>
      }
      fallbackPlacements={[
        'top',
        'top-start',
        'bottom-end',
        'bottom',
        'bottom-start',
      ]}
      offset={[8, 8]}
    >
      <IconButton
        size="xs"
        view="secondary"
        icon={InformationCircleLineMIcon}
      />
    </TooltipDesktop>
  );
});

FullInfo.displayName = 'FullInfo';

export const PortfolioBalance = memo((props: Props) => {
  const {
    positions,
    selectedSubAccounts,
    nodeId,
    settings,
    getPositionWarranty,
  } = props;
  const contentRef = useRef<HTMLDivElement>(null);

  const { subGTAccounts, useQuotes, useFinInfoExt } = useAlfaDirectContext();
  const currenciesFinInfoExts = useFinInfoExt(currenciesFI);

  const { getNode } = useWidgetContext();

  const nodeWidth = useMemo(
    () => getNode(nodeId)?.getRect().width || 0,
    [getNode, nodeId]
  );

  const currenciesQuotes = useQuotes(currenciesFI, {
    selector: quoteLastSelector,
  });

  const allowShowGO = settings?.showGO ?? true;

  const balances = useBalance(
    {
      positions,
      currenciesQuotes,
      currenciesFinInfoExts,
      subGTAccounts,
    },
    { selectedSubAccounts, groupType: 'market' }
  );

  const balance = sumBy(balances, 'balance');

  const totalGo = useMemo(() => {
    return positions.reduce(
      (acc, position) =>
        acc +
        getPositionWarranty(position.position.idAccount, position.object?.isin),
      0
    );
  }, [positions, getPositionWarranty]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper} ref={contentRef}>
        {!Number.isNaN(balance) && (
          <>
            {(settings?.showBalance ?? true) && (
              <BalanceBlock balance={balance} />
            )}
            {(settings?.showPU ?? true) && <PUBlock balances={balances} />}
            {allowShowGO && <AdditionalBlock go={totalGo} />}
          </>
        )}
      </div>
      {!Number.isNaN(balance) &&
      nodeWidth < (contentRef.current?.getBoundingClientRect().width ?? 0) ? (
        <FullInfo
          go={totalGo}
          balance={balance}
          balances={balances}
          settings={settings}
          allowShowGO={allowShowGO}
        />
      ) : null}
    </div>
  );
});

PortfolioBalance.displayName = 'PortfolioBalance';
