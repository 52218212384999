import { LK_URI } from '@terminal/core/env';
import { joinPath } from '@terminal/core/lib/joinPath';
import { fetchThroughProxy } from '@terminal/core/lib/url';

import { getCommonHeaders } from './getCommonHeaders';
import { mergeHeaders } from './mergeHeaders';
import { prepareBody } from './prepareBody';

export async function fetchLkApi(
  url: string,
  method: string = 'GET',
  body?: unknown,
  encrypt?: boolean,
  customHeaders?: Headers
): Promise<Response> {
  const defaultHeaders = getCommonHeaders();
  const headers = customHeaders
    ? mergeHeaders(defaultHeaders, customHeaders)
    : defaultHeaders;
  let params: RequestInit = {
    credentials: 'include',
    cache: 'no-store',
    method,
    headers,
  };

  if (typeof body !== 'undefined') {
    const obj =
      body instanceof FormData ? body : await prepareBody(body, encrypt);

    params = {
      ...params,
      body: obj as BodyInit,
    };
  }

  const fetchUrl = url.includes(LK_URI) ? url : joinPath(LK_URI, url);

  return fetchThroughProxy(fetchUrl, params);
}
