import cn from 'classnames';
import { Amount } from '@alfalab/core-components/amount';
import { Typography } from '@alfalab/core-components/typography';

import { MINORITY } from '@terminal/core/constants/ui';
import { ObjectGroup } from '@terminal/core/lib/client/entities';
import { ExtendedFI } from '@terminal/core/types/extendedFI';
import { FinInfoExt } from '@terminal/core/types/quotes';

import styles from './WidgetForm.module.css';

interface Props {
  total: number;
  isBond: boolean;
  warranty: number;
  renderFee: () => JSX.Element;
  computedQuantity: number;
  finInfoExt?: FinInfoExt;
  fullFi?: ExtendedFI;
}

export const Summary = ({
  fullFi,
  warranty,
  finInfoExt,
  total,
  isBond,
  renderFee,
  computedQuantity,
}: Props) => {
  const isFuture = fullFi?.idObjectGroup === ObjectGroup.Futures;

  if (isFuture) {
    return (
      <>
        <div className={cn(styles.plate, styles.plateBond)}>
          <div>
            <Typography.Text view="secondary-large" color="secondary">
              ГО
            </Typography.Text>
            <Amount.Pure
              value={warranty * MINORITY * computedQuantity}
              minority={MINORITY}
              view="withZeroMinorPart"
              currency={fullFi?.currencyCode}
              dataTestId="sizeInput"
            />
          </div>
          <div>
            <Typography.Text view="secondary-large" color="secondary">
              Стоимость
            </Typography.Text>
            <Amount.Pure
              value={total}
              minority={MINORITY}
              view="withZeroMinorPart"
              currency={fullFi?.currencyCode}
              dataTestId="sizeInput"
            />
          </div>
        </div>
        <div className={styles.plate}>
          <Typography.Text view="secondary-large" color="secondary">
            Комиссия
          </Typography.Text>
          {renderFee()}
        </div>
      </>
    );
  }

  const NKD = (finInfoExt?.accruedInt ?? 0) * computedQuantity;

  return (
    <>
      <div className={cn(styles.plate, styles.plateBond)}>
        <div>
          <Typography.Text view="secondary-large" color="secondary">
            Итог
          </Typography.Text>
          <Amount.Pure
            value={total}
            minority={MINORITY}
            view="withZeroMinorPart"
            currency={fullFi?.currencyCode}
            dataTestId="sizeInput"
          />
        </div>
        {isBond && finInfoExt && (
          <div>
            <Typography.Text view="secondary-large" color="secondary">
              В т.ч. НКД
            </Typography.Text>
            <Amount.Pure
              value={NKD * MINORITY}
              minority={MINORITY}
              view="withZeroMinorPart"
              currency={fullFi?.currencyCode}
              dataTestId="sizeInput"
            />
          </div>
        )}
      </div>
      <div className={styles.plate}>
        <Typography.Text view="secondary-large" color="secondary">
          Комиссия
        </Typography.Text>
        {renderFee()}
      </div>
    </>
  );
};
