export enum Currencies {
  RUB = 'Рубль',
  USD = 'Доллар',
  EUR = 'Евро',
  CNY = 'Юань',
  HKD = 'Гонконгский доллар',
  AUD = 'Австралийский доллар',
  CAD = 'Канадский доллар',
  NOK = 'Норвежская крона',
  PLN = 'Польский злотый',
  SGD = 'Сингапурский доллар',
  GBP = 'Фунт стерлингов',
  SEK = 'Шведская крона',
  CHF = 'Швейцарский франк',
  BRL = 'Бразильский реал',
  IDR = 'Индонезийская рупия',
  INR = 'Индийская рупия',
  JPY = 'Японская иена',
  KRW = 'Южнокорейская вона',
  KZT = 'Казахстанский тенге',
  ZAR = 'Южноафриканский рэнд',
  BYN = 'Белорусский рубль',
  UAH = 'Украинская гривна',
  AED = 'Дирхам ОАЭ',
  TRY = 'Турецкая лира',
  ILS = 'Израильский шекель',
  ARS = 'Аргентинское песо',
  MXN = 'Мексиканское песо',
  AMD = 'Армянский драм',
  CLP = 'Чилийское песо',
  DKK = 'Датская крона',
  TWD = 'Тайваньский доллар',
  CZK = 'Чешская крона',
}
