import {
  initialJsons,
  initialLayoutNames,
} from '../../../../constants/workspaceConfigurations';

import { LayoutType } from '../../../../types/layout';
import { WorkspaceConfigLS } from '../../../../types/workspace';

export const filterFlexLayouts = (
  config: WorkspaceConfigLS
): WorkspaceConfigLS => {
  const { layouts, layoutNames } = config;

  const flexLayoutIndicies = layouts.reduce((acc, layout, index) => {
    if (layout.type === LayoutType.Flex) {
      acc.push(index);
    }

    return acc;
  }, [] as number[]);

  const filteredLayouts = layouts.filter(
    (_, index) => !flexLayoutIndicies.includes(index)
  );
  const filteredLayoutNames = layoutNames.filter(
    (_, index) => !flexLayoutIndicies.includes(index)
  );

  const activeLayoutKey = flexLayoutIndicies.includes(config.activeLayoutKey)
    ? 0
    : config.activeLayoutKey;

  return {
    ...config,
    activeLayoutKey,
    layouts: filteredLayouts.length ? filteredLayouts : initialJsons,
    layoutNames: filteredLayouts.length
      ? filteredLayoutNames
      : initialLayoutNames,
  };
};
