import { Widget } from '@terminal/core/types/layout';

import { selectSymbol } from '../lib/selectSymbol';

import { useSelectAccount } from '../hooks/useSelectAccount';

import { SelectFunction } from '../model/types';

type WidgetWithSubName = Exclude<
  Widget,
  | Widget.WATCHLIST
  | Widget.OPERATIONS_HISTORY
  | Widget.NEWS
  | Widget.BOND_SCREENER
>;

export const WIDGET_SUB_NAME_MAP: Record<WidgetWithSubName, SelectFunction> = {
  // Отображать в подписи выбранный инструмент для виджетов График, Торговля, Стакан, Лента сделок
  [Widget.CHART]: selectSymbol,
  [Widget.HYBRIDORDER]: selectSymbol,
  [Widget.INSTRUMENT_INFO]: selectSymbol,
  [Widget.ORDERBOOK]: selectSymbol,
  [Widget.TRADES]: selectSymbol,
  // Для виджетов Балансы, Позиции, Операции, Ордера - отображаем выбранные счета
  [Widget.BALANCE]: useSelectAccount,
  [Widget.POSITIONS]: useSelectAccount,
  [Widget.OPERATIONS]: useSelectAccount,
  [Widget.ORDERS]: useSelectAccount,
};
