import get from 'lodash/get';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useToggle } from 'react-use';
import { Amount } from '@alfalab/core-components/amount';
import { IconButton } from '@alfalab/core-components/icon-button';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { CrossCircleMIcon } from '@alfalab/icons-glyph/CrossCircleMIcon';

import {
  useAlfaDirectContext,
  useComputedPositions,
  usePortfolioWarranty,
  usePositions,
} from '@terminal/alfadirect/hooks';
import { AlfaDirectProvider } from '@terminal/alfadirect/provider/react';
import {
  BlueprintTable,
  Cell,
  CellRenderProps,
  IMenuContext,
} from '@terminal/common/ui/Table';
import { RUSSIAN_ROUBLE_FI_ID } from '@terminal/core/constants/common';
import { ActivationTime } from '@terminal/core/constants/orderBook';
import { getDefaultPositionColumnSetting } from '@terminal/core/constants/tableProps';
import { MINORITY } from '@terminal/core/constants/ui';
import { useDoubleRowMode } from '@terminal/core/hooks/useDoubleRowMode';
import { useFilterTable } from '@terminal/core/hooks/useFilterTable';
import { usePrevious } from '@terminal/core/hooks/usePrevious';
import { useTableColumns } from '@terminal/core/hooks/useTableColumns';
import {
  ExecutionType,
  LifeTime,
  OrderType,
} from '@terminal/core/lib/client/entities';
import {
  getMinority,
  getStepDecimals,
  roundToStep,
} from '@terminal/core/lib/format';
import { getFullFI } from '@terminal/core/lib/getFullFI';
import { useTradingService } from '@terminal/core/lib/services/trading/useTradingService';
import { ECurrencyId } from '@terminal/core/types/currencyId';
import {
  Filter,
  PositionProps,
  Sort,
  TableColumnSetting,
  Widget,
} from '@terminal/core/types/layout';
import { PositionItem } from '@terminal/core/types/position';
import { PUShowMode } from '@terminal/core/types/subAccountPosition';
import { TableColumnKey } from '@terminal/core/types/tableColumn';

import { ClosePositionModal } from '../../../../features';
import { ContextMenu } from '../../../../features/ContextMenu';
import { OrderConfirm } from '../../../../features/Orders';
import { SymbolCell } from '../../../../features/SymbolCell';
import { TradingCertificateLock } from '../../../../features/TradingCertificateLock';
import {
  defaultColumnFilterRender,
  FeatureFlagName,
  Provider as WidgetContextProvider,
  useWidgetContext,
} from '../../../../shared';
import { Header } from '../../ui';
import { onPositionTableOrder } from './helpers';

import { useIsLotCount } from '../../../../shared/hooks/useIsLotCount';
import { useSaveColumnSettings } from '../../../../shared/hooks/useSaveColumnSettings';
import { useSelectedAccount } from '../../../../shared/hooks/useSelectedAccount';
import { useSortTable } from '../../../../shared/hooks/useSortTable';

import styles from './position.module.css';

const RUB_ID_OBJECT = ECurrencyId.RUR;

const getPUcondition = (
  selectedPUMode: PUShowMode,
  dailyPLtoMarketCurPrice: number | null,
  NPLtoMarketCurPrice: number | null
) => {
  if (selectedPUMode === PUShowMode.UP_DAY) {
    return dailyPLtoMarketCurPrice !== null && dailyPLtoMarketCurPrice >= 0;
  }

  if (selectedPUMode === PUShowMode.UP_ALL) {
    return NPLtoMarketCurPrice !== null && NPLtoMarketCurPrice >= 0;
  }

  if (selectedPUMode === PUShowMode.DOWN_DAY) {
    return dailyPLtoMarketCurPrice !== null && dailyPLtoMarketCurPrice < 0;
  }

  if (selectedPUMode === PUShowMode.DOWN_ALL) {
    return NPLtoMarketCurPrice !== null && NPLtoMarketCurPrice < 0;
  }

  return true;
};

const ACCOUNTS_CHANGED_METRIC = 'accountsChanged';
const DOUBLE_ROW_MODE_METRIC = 'Change Double Rows Mode';

export function Positions(props: PositionProps) {
  const { nodeId, tableProps, idFi, link, balanceSettingsProps } = props;

  const [isOpenColumnSettings, setIsOpenColumnSettings] =
    useState<boolean>(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState<boolean>(false);

  const widgetContext = useWidgetContext();
  const {
    updateNode,
    addNotification,
    useSettings,
    useLogWidgetPerformance,
    useLogWidgetUpdatePerformance,
    getActiveLayoutKey,
    onWidgetLoad,
    useFeatureFlag,
    PortfolioAnalyticsComponent: PortfolioAnalytics,
    useTriggerOnConditionOrFirstRender,
    marginParamsFetcher,
  } = widgetContext;
  const alfaDirectProps = useAlfaDirectContext();
  const {
    finInstrumentsTable,
    objectsTable: objects,
    objectTypesTable: objectTypes,
    marketBoardsTable: marketBoards,
    accounts,
  } = alfaDirectProps;

  const isPortfolioEnabled = useFeatureFlag(
    FeatureFlagName.PORTFOLIO_ANALYTICS_ENABLED
  );

  const performanceMetric = useLogWidgetPerformance(
    Widget.POSITIONS,
    getActiveLayoutKey()
  );
  const updateAccountsPerformanceMetric = useLogWidgetUpdatePerformance(
    Widget.POSITIONS,
    getActiveLayoutKey()
  );
  const updateDoubleRowModePerformanceMetric = useLogWidgetUpdatePerformance(
    Widget.POSITIONS,
    getActiveLayoutKey()
  );

  const {
    defaultValues: { selectedDisplayInstrumentType },
  } = useSettings();

  const isLotCount = useIsLotCount();

  const defaultPositionColumnSetting = useMemo(
    () => getDefaultPositionColumnSetting(selectedDisplayInstrumentType),
    [selectedDisplayInstrumentType]
  );

  const columnsSetting = useTableColumns(
    tableProps?.columns,
    defaultPositionColumnSetting
  );

  const positionTypes = useMemo(
    () => tableProps?.positionTypes || [],
    [tableProps?.positionTypes]
  );

  const selectedPUMode = useMemo(
    () => tableProps?.selectedPUMode || PUShowMode.ANY,
    [tableProps?.selectedPUMode]
  );

  const [isPortfolioModalOpen, togglePortfolioModalOpen] = useToggle(false);

  const { selectedSubAccounts } = useSelectedAccount(tableProps);

  const positions = usePositions();

  /**
   * после того, как все сущности получены, проходимся циклом по позициям
   * и собираем/считаем все необходимые поля
   * */
  const computedData = useComputedPositions(selectedSubAccounts, positions);

  const filteredPositions = useMemo(() => {
    if (positionTypes.length) {
      //Если есть фильтр по типу актива
      return computedData.filter(
        ({ objectType, dailyPLtoMarketCurPrice, NPLtoMarketCurPrice }) => {
          //Заодно проверяем фильтр по прибыли
          const PUcondition = getPUcondition(
            selectedPUMode,
            dailyPLtoMarketCurPrice,
            NPLtoMarketCurPrice
          );

          return (
            positionTypes.includes(objectType.idObjectGroup) && PUcondition
          );
        }
      );
      //Иначе если задан фильтр по прибыли
    } else if (selectedPUMode !== PUShowMode.ANY) {
      return computedData.filter(
        ({ dailyPLtoMarketCurPrice, NPLtoMarketCurPrice }) =>
          getPUcondition(
            selectedPUMode,
            dailyPLtoMarketCurPrice,
            NPLtoMarketCurPrice
          )
      );
      //Иначе не фильтруем
    } else {
      return computedData;
    }
  }, [computedData, positionTypes, selectedPUMode]);

  const updateNodeHandler = useCallback(
    (config) => {
      nodeId && updateNode(nodeId, config);
    },
    [nodeId, updateNode]
  );

  const saveFilter = useCallback(
    (filter?: Filter) => {
      updateNodeHandler({ tableProps: { filter } });
    },
    [updateNodeHandler]
  );

  const filterState = useMemo(() => tableProps?.filter, [tableProps?.filter]);
  const { filteredData, filter, onFilter } = useFilterTable({
    data: filteredPositions,
    filterState,
    saveFilter,
  });
  const sort = useMemo(() => tableProps?.sort, [tableProps?.sort]);

  const setSort = useCallback(
    (sort?: Sort) => {
      updateNodeHandler({ tableProps: { sort } });
    },
    [updateNodeHandler]
  );

  const { getPositionWarranty } = usePortfolioWarranty(
    filteredData,
    accounts,
    marginParamsFetcher
  );

  const { sortedData, onSort } = useSortTable({
    data: filteredData,
    sort,
    setSort,
    onOrder: onPositionTableOrder,
  });

  const [isDoubleRowMode, switchDoubleRowMode] = useDoubleRowMode(
    Widget.POSITIONS,
    columnsSetting,
    defaultPositionColumnSetting,
    updateNodeHandler
  );

  const onReady = useCallback(
    (date) => {
      if (nodeId) {
        onWidgetLoad(nodeId);
      }

      performanceMetric.ready({ rowsNumber: sortedData.length }, date);
      updateAccountsPerformanceMetric.finish(
        ACCOUNTS_CHANGED_METRIC,
        { rowsNumber: sortedData.length },
        date
      );
      updateDoubleRowModePerformanceMetric.finish(
        DOUBLE_ROW_MODE_METRIC,
        { rowsNumber: sortedData.length },
        date
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      nodeId,
      updateAccountsPerformanceMetric,
      updateDoubleRowModePerformanceMetric,
      performanceMetric,
      sortedData.length,
      isDoubleRowMode,
    ]
  );

  const prevSelectedSubAccounts = usePrevious(selectedSubAccounts);

  useEffect(
    () => () => {
      if (prevSelectedSubAccounts) {
        updateAccountsPerformanceMetric.start(ACCOUNTS_CHANGED_METRIC);
      }
    },
    [
      prevSelectedSubAccounts,
      selectedSubAccounts,
      updateAccountsPerformanceMetric,
    ]
  );

  useTriggerOnConditionOrFirstRender(onReady, sortedData.length > 0);

  useEffect(
    () => () => {
      updateDoubleRowModePerformanceMetric.start(DOUBLE_ROW_MODE_METRIC);
    },
    [isDoubleRowMode, updateDoubleRowModePerformanceMetric]
  );

  const prevSelectedDisplayInstrumentType = usePrevious(
    selectedDisplayInstrumentType
  );

  useEffect(() => {
    if (prevSelectedDisplayInstrumentType !== selectedDisplayInstrumentType) {
      updateNodeHandler({
        tableProps: {
          columns: columnsSetting.map((column) => {
            if (
              column.key === TableColumnKey.SymbolObject ||
              column.key === TableColumnKey.NameObject
            ) {
              return {
                ...column,
                key:
                  selectedDisplayInstrumentType === 'name'
                    ? TableColumnKey.NameObject
                    : TableColumnKey.SymbolObject,
              };
            }

            return column;
          }),
        },
      });

      //Сбрасываем не совпадающие по ключу фильтры при переключении отображания тикера
      if (
        (filterState?.key === TableColumnKey.SymbolObject &&
          selectedDisplayInstrumentType === 'name') ||
        (filterState?.key === TableColumnKey.NameObject &&
          selectedDisplayInstrumentType !== 'name')
      ) {
        onFilter();
      }
    }
  }, [
    updateNodeHandler,
    selectedDisplayInstrumentType,
    prevSelectedDisplayInstrumentType,
    columnsSetting,
    filterState?.key,
    onFilter,
  ]);

  //Закрываемая позиция
  const [positionToClose, setPositionToClose] = useState<PositionItem | null>(
    null
  );
  //Удваемая позиция
  const [positionToDouble, setPositionToDouble] = useState<PositionItem | null>(
    null
  );

  const saveColumnSetting = useSaveColumnSettings(nodeId);

  const selectedRowIndex = useMemo(() => {
    if (idFi) {
      return [
        ...sortedData.reduce((acc, row, index) => {
          if (row.finInfoExt?.idFI === idFi) {
            return [...acc, index];
          } else {
            return acc;
          }
        }, [] as number[]),
      ];
    }
  }, [idFi, sortedData]);

  const onRowClick = useCallback(
    (row: PositionItem) => {
      const idFi = row.finInfoExt.idFI;

      if (idFi === RUSSIAN_ROUBLE_FI_ID) {
        return;
      }

      const symbol = row.object?.symbolObject;

      if (link && nodeId) {
        updateNode(nodeId, { idFi, symbol }, symbol);
      }
    },
    [link, nodeId, updateNode]
  );

  const cellRender: CellRenderProps<PositionItem> = useCallback(
    (
      rowIndex,
      columnIndex,
      columnKey,
      data,
      setHoverIndex,
      columnName,
      isDoubleRowMode
    ) => {
      const { object, isMoney, shortDescription, marketBoard } = data[rowIndex];

      const secondRow: TableColumnSetting | undefined = isDoubleRowMode
        ? columnsSetting.find(({ key }) => key === columnKey)?.secondRow
        : undefined;

      const doubleRowCellRender = (
        secondRowColumnKey: TableColumnKey | undefined,
        firstRowColumnKey: TableColumnKey,
        firstRowContent: React.ReactElement
      ) => (
        <div className={styles.cellDoubleRow}>
          <div className={styles.firstRowContent}>{firstRowContent}</div>
          <div className={styles.secondRowWrapper}>
            {firstRowColumnKey === secondRowColumnKey ? (
              firstRowContent
            ) : (
              <>
                {secondRowColumnKey && (
                  <div className={styles.secondRowContent}>
                    {cellRender(
                      rowIndex,
                      columnIndex,
                      secondRowColumnKey,
                      data,
                      setHoverIndex,
                      columnKey
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      );

      switch (columnKey) {
        case TableColumnKey.ShortDescription:
          return (
            <Cell
              onClick={() => onRowClick(data[rowIndex])}
              onMouseEnter={() => setHoverIndex(rowIndex)}
              onMouseLeave={() => setHoverIndex(null)}
            >
              {isDoubleRowMode ? (
                doubleRowCellRender(
                  secondRow?.key,
                  columnKey,
                  <div className={styles.symbolCellSecondRow}>
                    {shortDescription}
                  </div>
                )
              ) : (
                <div className={styles.symbolCellSecondRow}>
                  {shortDescription}
                </div>
              )}
            </Cell>
          );
        case TableColumnKey.ReleaseNameObject:
          return (
            <Cell
              onClick={() => onRowClick(data[rowIndex])}
              onMouseEnter={() => setHoverIndex(rowIndex)}
              onMouseLeave={() => setHoverIndex(null)}
              leftAlign
            >
              <span>{get(data[rowIndex], columnKey)}</span>
            </Cell>
          );
        case TableColumnKey.NameObject:
          return (
            <Cell
              onClick={() => onRowClick(data[rowIndex])}
              onMouseEnter={() => setHoverIndex(rowIndex)}
              onMouseLeave={() => setHoverIndex(null)}
              leftAlign
            >
              {isDoubleRowMode ? (
                <div className={styles.symbolCellDoubleRow}>
                  <SymbolCell
                    nameMarketBoard={marketBoard?.nameMarketBoard}
                    idObject={object?.idObject}
                    symbolObject={object?.symbolObject}
                    onlyIcon
                  />
                  <div className={styles.symbolCellDoubleRowRightSide}>
                    {doubleRowCellRender(
                      secondRow?.key,
                      columnKey,
                      <SymbolCell
                        nameMarketBoard={marketBoard?.nameMarketBoard}
                        idObject={object?.idObject}
                        symbolObject={object?.symbolObject}
                        noIcon
                      />
                    )}
                  </div>
                </div>
              ) : (
                <SymbolCell
                  nameMarketBoard={marketBoard?.nameMarketBoard}
                  idObject={object?.idObject}
                  symbolObject={object?.symbolObject}
                />
              )}
            </Cell>
          );
        case TableColumnKey.SymbolObject:
          return (
            <Cell
              onClick={() => onRowClick(data[rowIndex])}
              onMouseEnter={() => setHoverIndex(rowIndex)}
              onMouseLeave={() => setHoverIndex(null)}
              leftAlign
            >
              {isDoubleRowMode ? (
                <div className={styles.symbolCellDoubleRow}>
                  <SymbolCell
                    nameMarketBoard={marketBoard?.nameMarketBoard}
                    idObject={object?.idObject}
                    symbolObject={object?.symbolObject}
                    onlyIcon
                  />
                  <div className={styles.symbolCellDoubleRowRightSide}>
                    {doubleRowCellRender(
                      secondRow?.key,
                      columnKey,
                      <SymbolCell
                        nameMarketBoard={marketBoard?.nameMarketBoard}
                        idObject={object?.idObject}
                        symbolObject={object?.symbolObject}
                        noIcon
                      />
                    )}
                  </div>
                </div>
              ) : (
                <SymbolCell
                  nameMarketBoard={marketBoard?.nameMarketBoard}
                  idObject={object?.idObject}
                  symbolObject={object?.symbolObject}
                />
              )}
            </Cell>
          );
        case TableColumnKey.CodeSubAccount:
        case TableColumnKey.PositionNameType:
        case TableColumnKey.PositionCurSymbol:
        case TableColumnKey.PositionPriceSymbol:
        case TableColumnKey.NameBalanceGroup:
          return (
            <Cell
              onClick={() => onRowClick(data[rowIndex])}
              onMouseEnter={() => setHoverIndex(rowIndex)}
              onMouseLeave={() => setHoverIndex(null)}
            >
              {isDoubleRowMode ? (
                doubleRowCellRender(
                  secondRow?.key,
                  columnKey,
                  <>{get(data[rowIndex], columnKey)}</>
                )
              ) : (
                <span>{get(data[rowIndex], columnKey)}</span>
              )}
            </Cell>
          );
        case TableColumnKey.PositionTorgPos:
        case TableColumnKey.PositionBackPos:
          const posValue = get(data[rowIndex], columnKey);
          //Подсчитываем минорные единицы
          const posMinority =
            data[rowIndex]?.object?.idObject === RUB_ID_OBJECT
              ? MINORITY
              : getMinority(posValue);

          return (
            <Cell
              onClick={() => onRowClick(data[rowIndex])}
              onMouseEnter={() => setHoverIndex(rowIndex)}
              onMouseLeave={() => setHoverIndex(null)}
            >
              {isDoubleRowMode ? (
                doubleRowCellRender(
                  secondRow?.key,
                  columnKey,
                  <Amount.Pure
                    value={posValue * posMinority}
                    minority={posMinority}
                    view="withZeroMinorPart"
                  />
                )
              ) : (
                <Amount.Pure
                  value={posValue * posMinority}
                  minority={posMinority}
                  view="withZeroMinorPart"
                />
              )}
            </Cell>
          );

        case TableColumnKey.PositionConvertedPrice:
        case TableColumnKey.PositionInPrice:
        case TableColumnKey.PositionPrice:
        case TableColumnKey.PositionLiquidCost:
        case TableColumnKey.PositionSubAccNalPos:
          const value: number | null = get(data[rowIndex], columnKey);
          let amount: JSX.Element = <>-</>;

          if (value !== null) {
            const priceStep = data[rowIndex]?.finInfoExt?.priceStep;
            const valueRounded = priceStep
              ? roundToStep(value, data[rowIndex]?.finInfoExt?.priceStep)
              : value;

            //Подсчитываем минорные единицы
            const minority =
              data[rowIndex]?.object?.idObject === RUB_ID_OBJECT
                ? MINORITY
                : getMinority(valueRounded);

            amount = (
              <Amount.Pure
                value={valueRounded * minority}
                minority={minority}
                view="withZeroMinorPart"
              />
            );
          }

          return (
            <Cell
              onClick={() => onRowClick(data[rowIndex])}
              onMouseEnter={() => setHoverIndex(rowIndex)}
              onMouseLeave={() => setHoverIndex(null)}
            >
              {isDoubleRowMode
                ? doubleRowCellRender(secondRow?.key, columnKey, amount)
                : amount}
            </Cell>
          );
        case TableColumnKey.PositionTorgPosUchCost:
        case TableColumnKey.PositionBackPosCost:
        case TableColumnKey.PositionTorgPosCost:
        case TableColumnKey.PositionDeposits:
        case TableColumnKey.PositionWithdrawal:
        case TableColumnKey.Yield:
          return (
            <Cell
              onClick={() => onRowClick(data[rowIndex])}
              onMouseEnter={() => setHoverIndex(rowIndex)}
              onMouseLeave={() => setHoverIndex(null)}
            >
              {isDoubleRowMode ? (
                doubleRowCellRender(
                  secondRow?.key,
                  columnKey,
                  <Amount.Pure
                    value={get(data[rowIndex], columnKey) * MINORITY}
                    minority={MINORITY}
                    view="withZeroMinorPart"
                  />
                )
              ) : (
                <Amount.Pure
                  value={get(data[rowIndex], columnKey) * MINORITY}
                  minority={MINORITY}
                  view="withZeroMinorPart"
                />
              )}
            </Cell>
          );
        case TableColumnKey.PositionAssetsPart:
          return (
            <Cell
              onClick={() => onRowClick(data[rowIndex])}
              onMouseEnter={() => setHoverIndex(rowIndex)}
              onMouseLeave={() => setHoverIndex(null)}
            >
              {isDoubleRowMode ? (
                doubleRowCellRender(
                  secondRow?.key,
                  columnKey,
                  <div>
                    <Amount.Pure
                      value={data[rowIndex][columnKey] * MINORITY}
                      minority={MINORITY}
                      view="withZeroMinorPart"
                    />
                    &nbsp;%
                  </div>
                )
              ) : (
                <div>
                  <Amount.Pure
                    value={data[rowIndex][columnKey] * MINORITY}
                    minority={MINORITY}
                    view="withZeroMinorPart"
                  />
                  &nbsp;%
                </div>
              )}
            </Cell>
          );
        case TableColumnKey.PositionDailyPrice:
        case TableColumnKey.PositionNplPrice:
        case TableColumnKey.PositionNKD:
          const valuePU = data[rowIndex][columnKey];

          if (valuePU === null) {
            return <Cell></Cell>;
          }

          return (
            <Cell
              onClick={() => onRowClick(data[rowIndex])}
              onMouseEnter={() => setHoverIndex(rowIndex)}
              onMouseLeave={() => setHoverIndex(null)}
            >
              {isDoubleRowMode ? (
                doubleRowCellRender(
                  secondRow?.key,
                  columnKey,
                  <div className={valuePU >= 0 ? styles.buy : styles.sell}>
                    <Amount.Pure
                      value={valuePU * MINORITY}
                      minority={MINORITY}
                      view="withZeroMinorPart"
                    />
                  </div>
                )
              ) : (
                <div className={valuePU >= 0 ? styles.buy : styles.sell}>
                  <Amount.Pure
                    value={valuePU * MINORITY}
                    minority={MINORITY}
                    view="withZeroMinorPart"
                  />
                </div>
              )}
            </Cell>
          );
        case TableColumnKey.PositionDailyPercent:
        case TableColumnKey.PositionNplPercent:
        case TableColumnKey.PositionPSTNKD:
          const valuePUPerc = get(data[rowIndex], columnKey);

          //Подсчитываем минорные единицы
          return (
            <Cell
              onClick={() => onRowClick(data[rowIndex])}
              onMouseEnter={() => setHoverIndex(rowIndex)}
              onMouseLeave={() => setHoverIndex(null)}
            >
              {isDoubleRowMode ? (
                doubleRowCellRender(
                  secondRow?.key,
                  columnKey,
                  <div className={valuePUPerc >= 0 ? styles.buy : styles.sell}>
                    <Amount.Pure
                      value={valuePUPerc * MINORITY}
                      minority={MINORITY}
                      view="withZeroMinorPart"
                    />
                    &nbsp;%
                  </div>
                )
              ) : (
                <div className={valuePUPerc >= 0 ? styles.buy : styles.sell}>
                  <Amount.Pure
                    value={valuePUPerc * MINORITY}
                    minority={MINORITY}
                    view="withZeroMinorPart"
                  />
                  &nbsp;%
                </div>
              )}
            </Cell>
          );
        case TableColumnKey.PositionIsMoney:
          return (
            <Cell
              onClick={() => onRowClick(data[rowIndex])}
              onMouseEnter={() => setHoverIndex(rowIndex)}
              onMouseLeave={() => setHoverIndex(null)}
            >
              {isDoubleRowMode ? (
                doubleRowCellRender(
                  secondRow?.key,
                  columnKey,
                  <>{isMoney ? 'Да' : 'Нет'}</>
                )
              ) : (
                <>{isMoney ? 'Да' : 'Нет'}</>
              )}
            </Cell>
          );
        case TableColumnKey.NextCoupon:
        case TableColumnKey.MatDate:
        case TableColumnKey.PositionLongOrders:
        case TableColumnKey.PositionPlanLong:
        case TableColumnKey.PositionShortOrders:
        case TableColumnKey.PositionPlanShort:
        case TableColumnKey.PositionSessionBuyQty:
        case TableColumnKey.PositionSessionBuyVal:
        case TableColumnKey.PositionSessionSellQty:
        case TableColumnKey.PositionSessionSellVal:
        case TableColumnKey.PositionVariationMargin:
        case TableColumnKey.PositionSessionVal:
        case TableColumnKey.PositionSessionQty:
          return (
            <Cell
              onClick={() => onRowClick(data[rowIndex])}
              onMouseEnter={() => setHoverIndex(rowIndex)}
              onMouseLeave={() => setHoverIndex(null)}
            >
              {isDoubleRowMode ? (
                doubleRowCellRender(
                  secondRow?.key,
                  columnKey,
                  <>{get(data[rowIndex], columnKey)}</>
                )
              ) : (
                <span>{get(data[rowIndex], columnKey)}</span>
              )}
            </Cell>
          );
        case TableColumnKey.PositionGO:
          const go = getPositionWarranty(
            data[rowIndex].position.idAccount,
            data[rowIndex].object?.isin
          );

          const Component = (
            <Amount.Pure
              value={go * MINORITY}
              minority={MINORITY}
              view="withZeroMinorPart"
            />
          );

          return (
            <Cell
              onClick={() => onRowClick(data[rowIndex])}
              onMouseEnter={() => setHoverIndex(rowIndex)}
              onMouseLeave={() => setHoverIndex(null)}
            >
              {isDoubleRowMode
                ? doubleRowCellRender(secondRow?.key, columnKey, Component)
                : Component}
            </Cell>
          );
        default:
          return null;
      }
    },
    [columnsSetting, onRowClick, getPositionWarranty]
  );

  //Состояния для модальных окон отмены и редактирования заявок
  const [isOpenCloseModal, setIsOpenCloseModal] = useState(false);
  const [isLoadingCloseModal, setIsLoadingCloseModal] = useState(false);

  const onOrderResponse = useCallback(() => {
    setIsOpenCloseModal(false);
    setIsLoadingCloseModal(false);
  }, []);
  const onOrderError = useCallback(
    (message: string) => {
      addNotification({
        badge: 'negative',
        title: 'Ошибка',
        text: message,
      });

      setIsOpenCloseModal(false);
      setIsLoadingCloseModal(false);
    },
    [addNotification]
  );

  const { sendOrder } = useTradingService(onOrderError, onOrderResponse);

  useEffect(() => {
    setIsLoadingCloseModal(false);
  }, [positionToClose]);

  const filteredColumnsSetting = useMemo(
    () => columnsSetting.filter((column) => Boolean(column.key)),
    [columnsSetting]
  );

  const sendOrderParams = useMemo(() => {
    if (positionToDouble) {
      const { finInfoExt, position, torgPos } = positionToDouble;

      return {
        idFi: finInfoExt.idFI,
        idSubaccount: position.idSubAccount,
        idOrderType: OrderType.MKT,
        buy: Boolean(torgPos > 0),
        quantity: Math.abs(torgPos),
        idLifeTime: LifeTime.D30,
        idExecutionType: ExecutionType.ASIS,
        idActivationTime: ActivationTime.NOW,
        inputPrice: 0,
      };
    }
  }, [positionToDouble]);

  const handleDoubleOrder = () => {
    if (sendOrderParams) {
      sendOrder(sendOrderParams);
      setPositionToDouble(null);
    }
  };

  const { priceDecimals } = getStepDecimals(
    positionToDouble?.finInfoExt?.priceStep
  );

  const fullFi = useMemo(
    () =>
      positionToDouble
        ? getFullFI(
            positionToDouble.finInfoExt.idFI,
            objects,
            objectTypes,
            finInstrumentsTable,
            marketBoards
          )
        : undefined,
    [finInstrumentsTable, positionToDouble, marketBoards, objects, objectTypes]
  );

  const hoverActions = useCallback(
    (data: PositionItem, onHover, viewportWidth) => (
      <TradingCertificateLock hideMode>
        {data.canBeCanceled ? (
          <div
            className={styles.hoverActions}
            style={{
              left: viewportWidth ? viewportWidth - 56 : 0,
              width: 56,
            }}
            onMouseEnter={() => onHover(true)}
            onMouseLeave={() => onHover(false)}
          >
            <TooltipDesktop
              targetClassName={styles.tooltipTarget}
              contentClassName={styles.tooltipContent}
              content="Закрыть позицию"
              trigger="hover"
              position="top"
              preventOverflow={false}
            >
              <IconButton
                size="xs"
                view="secondary"
                icon={CrossCircleMIcon}
                onClick={(e: { stopPropagation: () => void }) => {
                  e.stopPropagation();
                  setPositionToClose(data);
                  setIsOpenCloseModal(true);
                  onHover(false);
                }}
              />
            </TooltipDesktop>
          </div>
        ) : null}
      </TradingCertificateLock>
    ),
    []
  );

  const bodyContextMenuRenderer = useCallback(
    (context: IMenuContext) => {
      const target = context.getTarget();
      const { rows } = target;

      if (rows) {
        const index = rows[0];
        const data = sortedData[index];

        // TODO: Требуется доработка таблички или меню
        // blueprint v4 не видит контекста для этого был сделан этот костыль
        // https://github.com/palantir/blueprint/issues/1121
        return (
          <AlfaDirectProvider value={alfaDirectProps}>
            <WidgetContextProvider value={widgetContext}>
              <ContextMenu
                idFi={data.finInfoExt.idFI}
                idSubAccount={data.position?.idSubAccount}
                widget={Widget.POSITIONS}
                nodeId={nodeId}
                nameMarketBoard={data.marketBoard?.nameMarketBoard}
                setIsOpenColumnSettings={setIsOpenColumnSettings}
                link={link}
                selectedSubAccounts={selectedSubAccounts}
                setIsPortfolioModalOpen={togglePortfolioModalOpen}
                onDeleteOrder={() => {
                  setPositionToClose(data);
                  setIsOpenCloseModal(true);
                }}
                onEditOrder={() => {
                  setPositionToDouble(data);
                  setIsOpenConfirm(true);
                }}
                enableClosePos={data.canBeCanceled}
                enableDoublePos={
                  data.torgPos > 0 && data.object?.idObject !== 174368
                }
                setIsOpenLimitsModal={() => {}}
                setIsOpenContextMenuModal={() => {}}
                setIsOpenSlippageModal={() => {}}
                setIsOpenQuantityModal={() => {}}
              />
            </WidgetContextProvider>
          </AlfaDirectProvider>
        );
      } else {
        return <></>;
      }
    },
    [
      sortedData,
      alfaDirectProps,
      widgetContext,
      nodeId,
      link,
      selectedSubAccounts,
      togglePortfolioModalOpen,
    ]
  );

  return (
    <div className={styles.container}>
      <Header
        isOpenColumnSettings={isOpenColumnSettings}
        setIsOpenColumnSettings={setIsOpenColumnSettings}
        isDoubleRowMode={isDoubleRowMode}
        switchDoubleRowMode={switchDoubleRowMode}
        nodeId={nodeId}
        columnsSetting={filteredColumnsSetting}
        selectedSubAccounts={selectedSubAccounts}
        balanceSettingsProps={balanceSettingsProps}
        positionTypes={positionTypes}
        positions={sortedData}
        togglePortfolioModalOpen={togglePortfolioModalOpen}
        selectedPUMode={selectedPUMode}
        defaultPositionColumnSetting={defaultPositionColumnSetting}
        selectedDisplayInstrumentType={selectedDisplayInstrumentType}
        getPositionWarranty={getPositionWarranty}
      />
      <BlueprintTable<PositionItem>
        filter={filter}
        onFilter={onFilter}
        sort={sort}
        onSort={onSort}
        data={sortedData}
        columnSetting={columnsSetting}
        cellRender={cellRender}
        columnFilterRender={defaultColumnFilterRender}
        onReorder={saveColumnSetting}
        onChangeWidth={saveColumnSetting}
        defaultSelectedRows={selectedRowIndex}
        hoverActions={hoverActions}
        isDoubleRowMode={isDoubleRowMode}
        bodyContextMenuRenderer={bodyContextMenuRenderer}
      />

      {isPortfolioEnabled && PortfolioAnalytics && (
        <PortfolioAnalytics
          nodeId={nodeId}
          isOpen={isPortfolioModalOpen}
          onClose={() => togglePortfolioModalOpen(false)}
          selectedSubAccounts={selectedSubAccounts}
        />
      )}

      {positionToClose && (
        <ClosePositionModal
          position={positionToClose}
          isLotCount={isLotCount}
          lot={positionToClose?.finInfoExt?.lot}
          sendOrder={sendOrder}
          isLoading={isLoadingCloseModal}
          setIsLoading={setIsLoadingCloseModal}
          isOpen={isOpenCloseModal}
          onCloseRequest={() => {
            setIsOpenCloseModal(false);
            setIsLoadingCloseModal(false);
            setPositionToClose(null);
          }}
        />
      )}

      {positionToDouble && sendOrderParams && (
        <OrderConfirm
          fullFi={fullFi}
          priceDecimals={priceDecimals}
          isOpen={isOpenConfirm}
          onClose={() => setIsOpenConfirm(false)}
          onConfirm={handleDoubleOrder}
          params={sendOrderParams}
          askForConfirmChange={false}
        />
      )}
    </div>
  );
}
