import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  useAlfaDirectContext,
  useFilteredPositions,
  useFullFI,
  usePositionInfo,
} from '@terminal/alfadirect/hooks';
import { RUSSIAN_ROUBLE_FI_ID } from '@terminal/core/constants/common';
import { MINORITY } from '@terminal/core/constants/ui';
import { OrderType } from '@terminal/core/lib/client/entities';
import { getDimensionedUnit } from '@terminal/core/lib/format';
import { getPositionQty } from '@terminal/core/lib/formulas';
import { TradeLimitsResult } from '@terminal/core/types/tradeLimits';
import { OrderDimensionEnum } from '@terminal/core/types/ui';

import { VolumeAdditionalType, VolumeType } from '../model';

import { useIsLotCount } from '../../../shared/hooks/useIsLotCount';

interface Props {
  idFi: number;
  side?: 'buy' | 'sell';
  orderType: OrderType;
  lot?: number;
  price: number;
  setQuantity: (value: number) => void;
  tradeLimits: TradeLimitsResult | null;
  selectedSubAccountId: number;
  selectedSubAccounts: string[];
  volumeType: VolumeType;
}

function getVolumeAdditionalSuffix(
  type: VolumeAdditionalType,
  percent: number | null
) {
  if (!percent) {
    return '';
  }

  switch (type) {
    case 'lim': {
      return ` [${percent}% Lim]`;
    }
    case 'pos': {
      return ` [${percent}% Pos]`;
    }
    case 'cash': {
      return ` [${percent}% Cash]`;
    }
  }

  return '';
}

export const useVolumeQuickControls = ({
  idFi,
  side,
  orderType,
  lot,
  price,
  setQuantity,
  tradeLimits,
  selectedSubAccountId,
  selectedSubAccounts,
  volumeType,
}: Props) => {
  const isLotCount = useIsLotCount();

  const positions = useFilteredPositions(idFi, selectedSubAccountId);
  const cashPositions = useFilteredPositions(
    RUSSIAN_ROUBLE_FI_ID,
    selectedSubAccountId
  );

  const fullFi = useFullFI(idFi);
  const { subAccountRazdels } = useAlfaDirectContext();

  const { position } = usePositionInfo(
    fullFi,
    selectedSubAccountId,
    selectedSubAccounts,
    positions,
    subAccountRazdels
  );
  const positionTorgPosCost = position?.torgPosCost || 0;

  const positionsCount = useMemo(
    () =>
      positions.reduce((sum, position) => sum + getPositionQty(position), 0),
    [positions]
  );

  const cashCount = useMemo(
    () =>
      cashPositions.reduce(
        (sum, position) => sum + getPositionQty(position),
        0
      ),
    [cashPositions]
  );

  const positionVolumeDisabled =
    (side === 'buy' && positionsCount > -1) ||
    (side === 'sell' && positionsCount < 1);
  const cashVolumeDisabled = side === 'sell' || !(cashCount > 0);

  const [volumeAdditionalType, setVolumeAdditionalType] =
    useState<VolumeAdditionalType>(null);
  const [volumeAdditionalPercent, setVolumeAdditionalPercent] = useState<
    number | null
  >(null);

  const volumeAdditional = useMemo(
    () => ({
      volumeAdditionalType,
      setVolumeAdditionalType,
      volumeAdditionalPercent,
      setVolumeAdditionalPercent,
    }),
    [volumeAdditionalType, volumeAdditionalPercent]
  );

  const handleClearVolumeAdditional = useCallback(() => {
    setVolumeAdditionalType(null);
    setVolumeAdditionalPercent(null);
  }, []);

  useEffect(() => {
    handleClearVolumeAdditional();
  }, [side, orderType, idFi, handleClearVolumeAdditional]);

  useEffect(() => {
    switch (volumeAdditionalType) {
      case 'lim': {
        if (
          volumeType === VolumeType.Volume &&
          volumeAdditionalPercent &&
          tradeLimits?.quantity
        ) {
          const newQuantity = Math.floor(
            (getDimensionedUnit(
              tradeLimits.quantity,
              lot,
              OrderDimensionEnum.LOT
            ) *
              volumeAdditionalPercent) /
              100
          );

          if (newQuantity) {
            setQuantity(newQuantity * (isLotCount ? 1 : lot || 1));
          }
        }

        if (
          volumeType === VolumeType.Cash &&
          volumeAdditionalPercent &&
          tradeLimits?.amount
        ) {
          const newQuantity = Math.floor(
            (tradeLimits.amount * MINORITY * volumeAdditionalPercent) / 100
          );

          if (newQuantity) {
            setQuantity(newQuantity);
          }
        }

        break;
      }
      case 'pos': {
        if (
          volumeType === VolumeType.Volume &&
          volumeAdditionalPercent &&
          positionsCount
        ) {
          const newQuantity = Math.floor(
            (getDimensionedUnit(
              Math.abs(positionsCount),
              lot,
              OrderDimensionEnum.LOT
            ) *
              volumeAdditionalPercent) /
              100
          );

          if (newQuantity) {
            setQuantity(newQuantity * (isLotCount ? 1 : lot || 1));
          }
        }

        if (
          volumeType === VolumeType.Cash &&
          volumeAdditionalPercent &&
          positionTorgPosCost
        ) {
          const newQuantity = Math.floor(
            (Math.abs(positionTorgPosCost) *
              MINORITY *
              volumeAdditionalPercent) /
              100
          );

          if (newQuantity) {
            setQuantity(newQuantity);
          }
        }

        break;
      }
      case 'cash': {
        if (
          volumeType === VolumeType.Volume &&
          volumeAdditionalPercent &&
          tradeLimits?.quantityForOwnAssets
        ) {
          const newQuantity = Math.floor(
            (getDimensionedUnit(
              tradeLimits?.quantityForOwnAssets || 0,
              lot,
              OrderDimensionEnum.LOT
            ) *
              volumeAdditionalPercent) /
              100
          );

          if (newQuantity) {
            setQuantity(newQuantity * (isLotCount ? 1 : lot || 1));
          }
        }

        if (
          volumeType === VolumeType.Cash &&
          volumeAdditionalPercent &&
          tradeLimits?.freeMoney
        ) {
          const newQuantity = Math.floor(
            (tradeLimits.freeMoney * MINORITY * volumeAdditionalPercent) / 100
          );

          if (newQuantity) {
            setQuantity(newQuantity);
          }
        }

        break;
      }
    }
  }, [
    lot,
    setQuantity,
    tradeLimits,
    positionsCount,
    price,
    volumeAdditionalType,
    volumeAdditionalPercent,
    isLotCount,
    volumeType,
    positionTorgPosCost,
  ]);

  return {
    volumeAdditional,
    clearVolumeAdditional: handleClearVolumeAdditional,
    volAdSuffix: getVolumeAdditionalSuffix(
      volumeAdditionalType,
      volumeAdditionalPercent
    ),
    limitsDisabled: !tradeLimits?.quantity,
    positionsCount,
    positionVolumeDisabled,
    cashVolumeDisabled,
  };
};
