import debounce from 'lodash/debounce';
import React, { FC, useMemo } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { ButtonDesktop as Button } from '@alfalab/core-components/button/desktop/Component.desktop';
import {
  Tab,
  TabsDesktop as Tabs,
} from '@alfalab/core-components/tabs/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';
import ListBulletedBoldMIcon from '@alfalab/icons-glyph/ListBulletedBoldMIcon';

import { DropdownMenu } from '@terminal/common/components/DropdownMenu';
import { ColumnSettingModal } from '@terminal/common/ui/Table';
import { trackBondScreenerWidget } from '@terminal/core/lib/analytics/bondScreener/bondScreener';
import {
  BondScreenerTemplate,
  ScreenerBondEntity,
} from '@terminal/core/types/bondScreener';
import {
  Filter,
  TableColumnSetting,
  Widget,
} from '@terminal/core/types/layout';
import { WatchListItem } from '@terminal/core/types/watchList';

import { WidgetHeader } from '../../../../features/WidgetHeader';
import { useWidgetContext } from '../../../../shared';
import { BondScreenerFilters } from '../../features/BondScreenerFilters';
import { BondScreenerSearch } from '../../features/BondScreenerSearch';
import { BondScreenerTemplates } from '../../features/BondScreenerTemplates';

import { useWatchlistsGroups } from '../../../../shared/hooks/useWatchlistsGroups';
import { useSettings as useTableSettings } from '../../hooks/useSettings';

import { BSTableTab } from './types';

import styles from './Header.module.css';

interface Props {
  nodeId?: string;
  isOpenColumnSettings: boolean;
  setIsOpenColumnSettings: (open: boolean) => void;
  columnsSetting: TableColumnSetting[];
  selectedTab: BSTableTab;
  selectTab: (tab: BSTableTab) => void;
  selectedData: ScreenerBondEntity[];
  filters: Record<string, Filter | undefined>;
  setFilters: (filters: Record<string, Filter | undefined>) => void;
  setQuery: (query: string) => void;
  query: string;
  filtersIdsVisible: string[];
  setFiltersIdsVisible: (filtersIds: string[]) => void;
  isVisibleMultiHeader: boolean;
  setIsVisibleMultiHeader: (visible: boolean) => void;
  templates: BondScreenerTemplate[];
  onCreateTemplate: () => void;
  onEditTemplate: (id: string) => void;
  onDeleteTemplate: (id: string) => void;
  setInstrumentToAdd: (item: WatchListItem[]) => void;
  setIsWatchlistModalOpen: (isOpen: boolean) => void;
  onChangeCheckAll: (isCheckedAll: boolean) => void;
}

export const Header: FC<Props> = ({
  nodeId,
  isOpenColumnSettings,
  setIsOpenColumnSettings,
  columnsSetting,
  selectedTab,
  selectTab,
  selectedData,
  filters,
  setFilters,
  setQuery,
  query,
  filtersIdsVisible,
  setFiltersIdsVisible,
  isVisibleMultiHeader,
  setIsVisibleMultiHeader,
  templates,
  onCreateTemplate,
  onEditTemplate,
  onDeleteTemplate,
  onChangeCheckAll,
  setInstrumentToAdd,
  setIsWatchlistModalOpen,
}) => {
  const { updateNode } = useWidgetContext();

  const widgetSettings = useTableSettings({
    setIsOpenColumnSettings,
  });

  const groups = useWatchlistsGroups({
    idFis: selectedData.map((item) => item.idfi),
    onNewList: (items) => {
      setInstrumentToAdd(items);
      setIsWatchlistModalOpen(true);
    },
    onAddToList: (instruments, watchlist) => {
      onChangeCheckAll(false);
      selectTab(BSTableTab.AllSearchResults);
      trackBondScreenerWidget.addToList(watchlist.name);
    },
  });

  const onColumnsSettingsSearch = useMemo(
    () => debounce(trackBondScreenerWidget.settingsColumnsSearch, 2000),
    []
  );

  return (
    <>
      <WidgetHeader
        withMultiline
        inputs={
          <>
            <BondScreenerTemplates
              setFilters={setFilters}
              templates={templates}
              onEditTemplate={onEditTemplate}
              onDeleteTemplate={onDeleteTemplate}
            />
            <BondScreenerSearch setQuery={setQuery} query={query} />
            <BondScreenerFilters
              filters={filters}
              setFilters={setFilters}
              filtersIdsVisible={filtersIdsVisible}
              setFiltersIdsVisible={setFiltersIdsVisible}
              isVisibleMultiHeader={isVisibleMultiHeader}
              setIsVisibleMultiHeader={setIsVisibleMultiHeader}
              nodeId={nodeId}
              onCreateTemplate={onCreateTemplate}
            />
          </>
        }
        widgetSettings={widgetSettings}
      />
      <div className={styles.infoActionsWrapper}>
        {/* Табы для двух таблиц*/}
        {selectedData.length > 0 && (
          <>
            <Tabs
              size="xs"
              selectedId={selectedTab}
              onChange={(_, { selectedId }) => {
                selectTab(selectedId as BSTableTab);
                trackBondScreenerWidget.resultTab(
                  selectedId === BSTableTab.AllSearchResults
                    ? 'Результаты поиска'
                    : 'Выбрано'
                );
              }}
              className={styles.tabs}
            >
              <Tab title="Результаты поиска" id={BSTableTab.AllSearchResults} />
              <Tab
                title={`Выбрано ${selectedData.length}`}
                id={BSTableTab.OnlySelected}
              />
            </Tabs>
            <div className={styles.actionsWrapper}>
              <DropdownMenu groups={groups}>
                <Button
                  size="xxs"
                  leftAddons={<ListBulletedBoldMIcon width={16} height={16} />}
                  className={styles.popoverButton}
                >
                  <Typography.Text view="secondary-large" weight="medium">
                    Добавить {selectedData.length} в список
                  </Typography.Text>
                </Button>
              </DropdownMenu>
              <ButtonDesktop
                className={styles.deleteChecked}
                size="xxs"
                leftAddons={<CrossMIcon width={16} height={16} />}
                view="ghost"
                onClick={() => {
                  onChangeCheckAll(false);
                  trackBondScreenerWidget.removeChosen();
                }}
              >
                <Typography.Text view="secondary-large" weight="medium">
                  Снять выделение
                </Typography.Text>
              </ButtonDesktop>
            </div>
          </>
        )}
      </div>
      <ColumnSettingModal
        isOpen={isOpenColumnSettings}
        withSearch={true}
        setIsOpen={setIsOpenColumnSettings}
        columnsSetting={columnsSetting}
        updateNode={updateNode}
        nodeId={nodeId}
        widgetType={Widget.BOND_SCREENER}
        onSearchChange={onColumnsSettingsSearch}
        onSearchClear={onColumnsSettingsSearch}
        onSave={trackBondScreenerWidget.settingsColumnsSave}
        onCancel={trackBondScreenerWidget.settingsColumnsCancel}
      />
    </>
  );
};
