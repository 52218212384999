// Документация https://confluence.moscow.alfaintra.net/pages/viewpage.action?pageId=1581894027
export enum TECH_MESSAGE_CODE {
  MAIN = 'terminal_main_tech_message', // Текст тех. сообщения для рабочего стола
  TRADE = 'terminal_trade_tech_message', // Текст тех. сообщения для виджетов с торговой формой (торговый приказ, стакан, график)
  POSITIONS_BALANCES = 'terminal_positions_balances_tech_message', // Текст тех. сообщения для виджетов Позиции, Балансы
  MONEY_TRANSFERS = 'terminal_money_transfers_tech_message', // Текст тех. сообщения для окна Перевод денежных средств
  LK_ELECTRONIC_SIGNATURE = 'terminal_lk_electronic_signature_tech_message', // Текст тех. сообщения для вкладки Электронная подпись в Личном кабинете терминала
  AUTHORIZATION = 'terminal_authorization_tech_message', // Текст тех. сообщения на экране Авторизация
  OPEN_ACCOUNT = 'terminal_open_account_tech_message', // Текст тех. сообщения для окна Открытие счета
  SUPPORT = 'terminal_support_tech_message', // Текст тех. сообщения для Поддержки
}

// Не для сообщений
export enum FEATURE_FLAG_CODE {
  BOND_SCREENER_DICTIONARIES = 'terminal_bonds_screener_dictionarys', // Массив справочников для скринера облигаций
  CHART_SCROLL_VELOCITY = 'terminal_chart_scroll_velocity', // Максимальная сила инерции при скролле графика
  DRAGGABLE_LAYOUT_ENABLED = 'terminal_draggable_layout_enabled', // Включает возможность добавления и использования рабочего стола с типом Гибкие окна
  DRAGGABLE_LAYOUT_MAX_AVAILABLE_WIDGETS = 'terminal_draggable_layout_max_available_widgets', // Максимально возможное количество добавленных окон в виде виджетов (групп виджетов) для 1 рабочего стола с типом Гибкие окна
}

export type RemoteConfigResponse = Record<
  TECH_MESSAGE_CODE | FEATURE_FLAG_CODE,
  string
>;
