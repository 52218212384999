import * as z from 'zod';

import { SettingsZod_V2 } from './version2';

export class SettingsZod_V3 extends SettingsZod_V2 {
  static override object = z.object({
    ...super.object.shape,
    tickers: z.record(
      z.object({
        highlight: z
          .object({
            highlightActive: z.boolean(),
            highlightFrom: z.number().optional(),
          })
          .optional(),
      })
    ),
  });
}
