import { WatchList } from '@terminal/core/types/watchList';
import { WorkspaceConfigurationLS } from '@terminal/core/types/workspace';

export enum WorkspaceConfigurationScreen {
  List = 'List',
  Export = 'Export',
  Add = 'Add',
  Cloud = 'Cloud',
}

export interface WorkspaceConfigurationExport {
  configuration: WorkspaceConfigurationLS;
  watchLists: WatchList[];
}
