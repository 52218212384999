import isNumber from 'lodash/isNumber';
import noop from 'lodash/noop';
import { useCallback, useEffect } from 'react';

import {
  useAdditionalInstruction,
  useAlfaDirectContext,
  usePriceToMarketCurrency,
} from '@terminal/alfadirect/hooks';
import { useStreamPrice } from '@terminal/alfadirect/hooks/useStreamPrice';
import { currenciesFI } from '@terminal/core/constants/FIModal';
import { MINORITY } from '@terminal/core/constants/ui';
import { useOrderBookService } from '@terminal/core/hooks/useOrderBookService';
import { usePrevious } from '@terminal/core/hooks/usePrevious';
import { AnaliticsVolumeType } from '@terminal/core/lib/analytics/model/order';
import { OrderType } from '@terminal/core/lib/client/entities';
import { quoteLastSelector } from '@terminal/core/lib/domain/quoteSelector';
import { getCurrencyPrice } from '@terminal/core/lib/formulas';
import { getCashFromQuantity } from '@terminal/core/lib/formulas/getCashFromQuantity';
import { getQuantityFromCash } from '@terminal/core/lib/formulas/getQuantityFromCash';
import { AccountItem } from '@terminal/core/types/account';
import { ExtendedFI } from '@terminal/core/types/extendedFI';
import { FinInfoExt } from '@terminal/core/types/quotes';
import { TradeLimitsResult } from '@terminal/core/types/tradeLimits';
import { OrderSide } from '@terminal/core/types/trading';
import { useWidgetContext } from '@terminal/widgets/shared';

import { MAX_PRICE_AMOUNT } from '../const/form';
import { FormType, VolumeType } from '../model';
import { usePriceQuickControls } from './usePriceQuickControls';

import { useIsLotCount } from '../../../shared/hooks/useIsLotCount';
import { useWarranty } from '../../../shared/hooks/useWarranty';

interface Props {
  formType: FormType;
  isHideQuickLimits?: boolean;
  idFi: number;
  price: number;
  setPrice: (value: number) => void;
  priceTouched: boolean;
  computedQuantity: number;
  setPriceTouched: (touched: boolean) => void;
  setSecondPrice?: (value: number) => void;
  secondPriceTouched?: boolean;
  setSecondPriceTouched?: (touched: boolean) => void;
  quantity: number;
  slippage: number;
  setQuantity: (quantity: number) => void;
  finInfoExt?: FinInfoExt;
  selectedAccount?: AccountItem;
  fullFi?: ExtendedFI;
  side: OrderSide;
  orderType: OrderType;
  last?: number;
  idAccount?: number;
  tradeLimits: TradeLimitsResult | null;
  volumeType: VolumeType;
}

export const useTradingForm = ({
  formType,
  isHideQuickLimits = false,
  price,
  setPrice,
  priceTouched,
  setPriceTouched,
  setSecondPrice = noop,
  secondPriceTouched = false,
  setSecondPriceTouched = noop,
  quantity,
  setQuantity,
  idFi,
  finInfoExt,
  selectedAccount,
  fullFi,
  side,
  orderType,
  last,
  tradeLimits,
  computedQuantity,
  slippage,
  volumeType,
}: Props) => {
  const {
    analytics: { trackOrderVolumeChanging },
  } = useWidgetContext();

  const { useBrokerageFee, useQuotes, useFinInfoExt } = useAlfaDirectContext();

  const { bestBid, bestAsk } = useOrderBookService(idFi, 2);

  const isLotCount = useIsLotCount();

  const isQuantityError =
    !isLotCount && finInfoExt && quantity % finInfoExt.lot !== 0;

  const { instructionsAvailability } = useAdditionalInstruction({
    idFi,
    side,
    orderType,
    isChart: formType === FormType.CHART,
    isLotCount,
    lot: finInfoExt?.lot,
  });

  const { isPriceStreamed, setPriceStreamed } = useStreamPrice({
    idFi,
    idMarketBoard: fullFi?.idMarketBoard,
    side,
    orderType,
    price,
    setPrice,
    last,
    bestAsk: bestAsk?.Price,
    bestBid: bestBid?.Price,
  });

  const { secondPriceAdditional, secPAdSuffix, clearPricesAdditional } =
    usePriceQuickControls({
      side,
      price,
      orderType,
      priceStep: finInfoExt?.priceStep,
      setPrice,
      isPriceStreamed,
      setSecondPrice,
      last,
      bestAsk: bestAsk?.Price,
      bestBid: bestBid?.Price,
      tradeLimits: tradeLimits,
      setPriceStreamed,
    });

  const handleSetPrice = useCallback(
    (value?: number, clear?: boolean) => {
      if (isNumber(value)) {
        setPrice(value);
        setPriceStreamed(false);
      }

      setPriceTouched(true);

      if (clear) {
        clearPricesAdditional();
      }
    },
    [setPrice, clearPricesAdditional, setPriceStreamed, setPriceTouched]
  );

  const handleSetSecondPrice = useCallback(
    (value?: number, clear?: boolean) => {
      if (isNumber(value)) {
        setSecondPrice(value);
      }

      setSecondPriceTouched(true);

      if (clear) {
        clearPricesAdditional();
      }
    },
    [setSecondPrice, setSecondPriceTouched, clearPricesAdditional]
  );

  const prevIdFi = usePrevious(idFi);

  useEffect(() => {
    if (prevIdFi && idFi && prevIdFi !== idFi) {
      setPriceTouched(false);
      setSecondPriceTouched(false);
    }
  }, [prevIdFi, idFi, setPriceTouched, setSecondPriceTouched]);

  const warranty = useWarranty(idFi, price, {
    side,
    finInfoExt,
    idAccount: selectedAccount?.idAccount,
  });

  useEffect(() => {
    if (quantity) {
      return;
    }

    if (isLotCount) {
      setQuantity(1);
    } else if (finInfoExt?.lot) {
      setQuantity(finInfoExt.lot);
    }
  }, [quantity, finInfoExt?.lot, isLotCount, setQuantity]);

  const prevLotCount = usePrevious(isLotCount);
  const prevVolumeType = usePrevious(volumeType);

  const currenciesQuotes = useQuotes(currenciesFI, {
    throttleTimeout: 1000,
    selector: quoteLastSelector,
  });

  const currenciesFinInfoExts = useFinInfoExt(currenciesFI);

  /**
   * Цена валюты номинала
   * */
  const nominalCurrencyPrice = getCurrencyPrice(
    currenciesQuotes,
    currenciesFinInfoExts,
    fullFi?.idObjectFaceUnit
  );

  /**
   * Цена валюты ликвидного рынка
   * */
  const marketCurrencyPrice = getCurrencyPrice(
    currenciesQuotes,
    currenciesFinInfoExts,
    fullFi?.idObjectCurrency
  );

  const resetQuantity = useCallback(() => {
    if (volumeType === VolumeType.Volume) {
      if (isLotCount) {
        setQuantity(1);
      } else if (finInfoExt?.lot) {
        setQuantity(finInfoExt.lot);
      }
    }
  }, [finInfoExt, isLotCount, setQuantity, volumeType]);

  // Выставляем дефолтное значение при переключении инструмента
  useEffect(() => {
    if (prevIdFi && idFi && prevIdFi !== idFi && finInfoExt?.lot) {
      resetQuantity();
    }
  }, [finInfoExt?.lot, prevIdFi, idFi, resetQuantity]);

  // конвертация при переключении лот/штук, лот/сумма
  useEffect(() => {
    if (
      prevLotCount === undefined ||
      prevVolumeType === undefined ||
      (prevLotCount === isLotCount && prevVolumeType === volumeType) ||
      !quantity
    ) {
      return;
    }

    const lot = finInfoExt?.lot || 1;

    // Если переключили с лотов на штуки или наоборот
    if (
      volumeType === VolumeType.Volume &&
      prevVolumeType === VolumeType.Volume
    ) {
      if (isLotCount) {
        setQuantity(quantity / lot);
      } else {
        setQuantity(quantity * lot);
      }
    }

    // Если переключили с суммы в штуки/лоты
    if (
      volumeType === VolumeType.Volume &&
      prevVolumeType === VolumeType.Cash
    ) {
      trackOrderVolumeChanging(AnaliticsVolumeType.Volume);

      const currentQuantity = getQuantityFromCash({
        nominalCurrencyPrice,
        marketCurrencyPrice,
        price: price || 1,
        nominal: fullFi?.nominal || 0,
        quantity: quantity / MINORITY,
        isLotCount,
        warranty,
        nkd: finInfoExt?.accruedInt || 0,
        lot,
        idObjectGroup: fullFi?.idObjectGroup || 0,
      });

      setQuantity(Math.max(Math.floor(currentQuantity), isLotCount ? 1 : lot));
    }

    // Если переключили на сумму
    if (volumeType === VolumeType.Cash) {
      trackOrderVolumeChanging(AnaliticsVolumeType.Cash);

      const currentQuantity = getCashFromQuantity({
        nominalCurrencyPrice,
        marketCurrencyPrice,
        price,
        nominal: fullFi?.nominal || 0,
        quantity,
        isLotCount,
        warranty,
        nkd: finInfoExt?.accruedInt || 0,
        lot,
        idObjectGroup: fullFi?.idObjectGroup || 0,
      });

      setQuantity(Math.min(currentQuantity * MINORITY, MAX_PRICE_AMOUNT));
    }
  }, [
    quantity,
    finInfoExt?.lot,
    finInfoExt?.accruedInt,
    idFi,
    warranty,
    trackOrderVolumeChanging,
    setQuantity,
    prevLotCount,
    isLotCount,
    orderType,
    side,
    bestAsk?.Price,
    bestBid?.Price,
    price,
    fullFi,
    nominalCurrencyPrice,
    marketCurrencyPrice,
    slippage,
    volumeType,
    prevVolumeType,
  ]);

  useEffect(() => {
    switch (orderType) {
      // Если рыночная заявка, то отлипаем цену
      case OrderType.MKT: {
        setPriceTouched(false);
        break;
      }

      // ситуация: открыли форму с дефолтной лимитной заявкой, просто ставим в цену last и залипаем цену
      case OrderType.LMT:
        if (last && !priceTouched) {
          setPrice(last);
          setPriceTouched(true);
        }

        break;
      // STP, TRS - надо заполнять как для маркета (bid\offer - в противоположную сторону)
      case OrderType.STP:
      case OrderType.TRS:
        if (!priceTouched && bestAsk && bestBid) {
          if (side === 'buy') {
            handleSetPrice(bestAsk?.Price);
          } else {
            handleSetPrice(bestBid?.Price);
          }
        }

        break;
      // Для BRS - в лимит цену тянуть bid\offer в сторону направления, а в стоп - в противоположную
      case OrderType.BRS:
      case OrderType.TBRS:
        if (!secondPriceTouched && bestAsk && bestBid) {
          if (side === 'buy') {
            handleSetSecondPrice(bestAsk?.Price);
          } else {
            handleSetSecondPrice(bestBid?.Price);
          }
        }

        if (!priceTouched && bestAsk && bestBid) {
          if (side === 'sell') {
            handleSetPrice(bestAsk?.Price);
          } else {
            handleSetPrice(bestBid?.Price);
          }
        }

        break;

      default:
        break;
    }
  }, [
    idFi,
    last,
    priceTouched,
    orderType,
    handleSetPrice,
    side,
    bestAsk,
    bestBid,
    secondPriceTouched,
    handleSetSecondPrice,
    price,
    setPrice,
    setPriceTouched,
  ]);

  const { displayedTotalPrice, reducedPrice } = usePriceToMarketCurrency(
    fullFi,
    price,
    MINORITY,
    fullFi?.nominal,
    computedQuantity,
    finInfoExt?.priceStep,
    finInfoExt?.priceStepCost
  );

  const fee = useBrokerageFee(
    idFi,
    reducedPrice,
    computedQuantity,
    selectedAccount
  );

  return {
    setSecondPrice: handleSetSecondPrice,
    secondPriceAdditional,
    secPAdSuffix,
    instructionsAvailability,
    total: displayedTotalPrice,
    fee,
    hasError: isQuantityError,
    tradeLimits,
    updatePrice: handleSetPrice,
    isPriceStreamed,
    setPriceStreamed,
    isHideQuickLimits,
  };
};
