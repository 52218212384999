import { NewsItem } from '@terminal/common/entities/News';
import { DataTable } from '@terminal/core/store/entities';
import { SubAccountPositionItem } from '@terminal/core/types/subAccountPosition';

import { WidgetApi } from '../model/WidgetApi';

export const defaultPropsMock: WidgetApi = {
  finInstrumentsTable: new DataTable([]),
  getNews(params: {
    cursor?: string;
    sources?: string[];
    isins?: string[];
    count?: number;
    importanceTypes?: string[];
    sentiment?: string;
  }): Promise<{ cursor: string; news: NewsItem[]; screenTitle: string }> {
    return Promise.resolve({ cursor: '', news: [], screenTitle: '' });
  },
  marketBoardsTable: new DataTable([]),
  objectTypesTable: new DataTable([]),
  objectsTable: new DataTable([]),
  quotesService: undefined,
  subscribe: () => {},
  unsubscribe(options: any, returnData: any): void {},
  usePositions(): SubAccountPositionItem[] {
    return [];
  },
  updateNode: () => {},
  accounts: [],
  subGTAccounts: [],
  orders: [],
  subAccountRazdels: [],
  useRequirement: () => ({}),
  // @ts-ignore
  useSettings: () => ({
    // @ts-ignore
    defaultValues: {
      selectedDisplayInstrumentType: 'symbol',
    },
  }),
  marketBoards: [],
  openTopUpModal: () => {},
  addNotification: function (_: any): void {},
  operations: [],
  finInstruments: [],
  objectTypes: [],
  objects: [],
  getActiveLayoutKey: () => 0,
  useFinInfoExtsList: () => ({}),
  useRemoteConfig: () => ({
    // @ts-ignore
    data: { entries: {}, packageName: '', configVersion: 0 },
    isLoading: false,
  }),
  getInstrumentIconURL: () => '',
  useInstrumentIcons: () => [],
  useTradeLimits: () => ({ tradeLimitsLong: null, tradeLimitsShort: null }),
  useFeatureFlag: () => true,
  PortfolioAnalyticsComponent: () => null,
};
