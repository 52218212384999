import { useCallback, useEffect, useRef } from 'react';

import { defaultTradesColumnSetting } from '@terminal/core/constants/tableProps';
import { useTableColumns } from '@terminal/core/hooks/useTableColumns';
import { TableColumnSetting } from '@terminal/core/types/layout';

import { useWidgetContext } from '../../../../shared';
import { TradesFeedContext } from '../../model/context';
import { TradesFeedWidgetProps } from '../../model/props';
import { Header, TradesTable } from '../../ui';

import styles from './tradeFeed.module.css';

export function TradesFeed({
  tableProps,
  idFi,
  nodeId,
  timer,
  timerActive,
}: TradesFeedWidgetProps): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null);

  const { updateNode, onWidgetLoad } = useWidgetContext();

  const columnsSetting = useTableColumns(
    tableProps?.columns,
    defaultTradesColumnSetting
  );

  const saveColumnSetting = useCallback(
    (columns: TableColumnSetting[]) => {
      if (nodeId) {
        updateNode(nodeId, { tableProps: { columns } });
      }
    },
    [nodeId, updateNode]
  );

  useEffect(() => {
    if (nodeId) {
      onWidgetLoad(nodeId);
    }
  }, [nodeId, onWidgetLoad]);

  const tradesFeedData = {
    idFi,
    timer,
    nodeId,
    timerActive,
    columnsSetting,
    saveColumnSetting,
  };

  return (
    <TradesFeedContext.Provider value={tradesFeedData}>
      <div className={styles.container} ref={containerRef}>
        <Header container={containerRef.current} />
        <TradesTable />
      </div>
    </TradesFeedContext.Provider>
  );
}
