import cn from 'classnames';
import React, {
  FC,
  HTMLAttributes,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Input } from '@alfalab/core-components/input/modern';
import { Typography } from '@alfalab/core-components/typography';
import { MagnifierMIcon } from '@alfalab/icons-glyph/MagnifierMIcon';

import { CheckboxItemComponent } from '../../shared/ui/Menu';
import { Dropdown, DropdownItemProps } from '../Dropdown';
import { ButtonItem } from './ButtonItem';
import { SwitchItem } from './SwitchItem';

import { MenuGroup } from './types/MenuGroup';
import { MenuItem } from './types/MenuItem';

import styles from './Menu.module.css';

export type MenuProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * Группы меню
   * @default []
   * */
  groups: MenuGroup[];
  /**
   * Функция, которая вызывается по нажатию на элемент меню
   * */
  onItemClick?: (menuItem: MenuItem) => void;
  withDivider?: boolean;
  withGap?: boolean;
  withSearch?: boolean;
};

/**
 * Базовое меню
 * */
export const Menu: FC<MenuProps> = (props) => {
  const {
    className,
    groups,
    onItemClick,
    withDivider = true,
    withGap = false,
    withSearch = false,
    ...restProps
  } = props;
  const rootClassName = cn(className, styles.root);
  const [searchInputValue, setSearchInputValue] = useState('');

  const searchedGroups = useMemo(() => {
    if (!withSearch) {
      return groups;
    }

    return groups
      .filter(({ items }) =>
        items.some(
          ({ label }) =>
            typeof label === 'string' &&
            label.toLowerCase().includes(searchInputValue.toLowerCase())
        )
      )
      .map((group) => ({
        ...group,
        items: group.items.filter(
          ({ label }) =>
            typeof label === 'string' &&
            label.toLowerCase().includes(searchInputValue.toLowerCase())
        ),
      }));
  }, [groups, searchInputValue, withSearch]);

  const handleMenuItemClick = useCallback(
    (menuItem: MenuItem) => () => {
      onItemClick?.(menuItem);
    },
    [onItemClick]
  );

  return (
    <div className={rootClassName} {...restProps}>
      {withSearch && (
        <>
          <Input
            className={styles.search}
            size="s"
            value={searchInputValue}
            onChange={(e) => setSearchInputValue(e.target.value)}
            onClear={() => setSearchInputValue('')}
            placeholder="Поиск"
            block
            clear
            leftAddons={
              <MagnifierMIcon
                width={16}
                height={16}
                color="var(--color-light-graphic-secondary)"
              />
            }
          />
          <Dropdown.Divider size="s" />
        </>
      )}
      {groups.length > 0 && searchedGroups.length === 0 ? (
        <Typography.Text
          view="secondary-medium"
          color="secondary"
          className={styles.title}
        >
          Совпадений не найдено
        </Typography.Text>
      ) : (
        searchedGroups
          .filter(
            ({ items }) =>
              items.length > 0 && items.some(({ hidden }) => !hidden)
          )
          .map(({ key, label, items }, groupIndex, groupsArray) => (
            <div key={key} className={styles.group}>
              {typeof label === 'string' ? (
                <Typography.Text
                  weight="bold"
                  tag="p"
                  view="secondary-small"
                  color="secondary"
                  defaultMargins={false}
                  className={styles.groupTitle}
                >
                  {label}
                </Typography.Text>
              ) : (
                label
              )}
              {items
                .filter(({ hidden }) => !hidden)
                .map((item, itemIndex, itemsArray) => {
                  const { key, Icon, hideMenu, hidden, ...restItem } = item;
                  const isWithDivider =
                    groupIndex < groupsArray.length - 1 &&
                    itemIndex === itemsArray.length - 1;

                  const dividerProps: Pick<
                    DropdownItemProps,
                    'withDivider' | 'withGap'
                  > = {};

                  if (isWithDivider && withGap) {
                    dividerProps.withGap = true;
                  } else if (isWithDivider && withDivider) {
                    dividerProps.withDivider = true;
                  }

                  if (restItem.type === 'element') {
                    return (
                      <div onClick={handleMenuItemClick(item)} key={key}>
                        {item.label}
                      </div>
                    );
                  }

                  return (
                    <Dropdown.Item
                      key={key}
                      {...dividerProps}
                      leftIcon={Icon && <Icon className={styles.icon} />}
                      onClick={handleMenuItemClick(item)}
                      renderContent={(renderProps) => {
                        if (restItem.type === 'button') {
                          return (
                            <ButtonItem
                              {...renderProps}
                              {...restItem}
                              className={cn(
                                renderProps.className,
                                restItem.className
                              )}
                            />
                          );
                        }

                        if (restItem.type === 'switch') {
                          return (
                            <SwitchItem
                              {...renderProps}
                              {...restItem}
                              className={cn(
                                renderProps.className,
                                restItem.className
                              )}
                            />
                          );
                        }

                        if (restItem.type === 'checkbox') {
                          return (
                            <CheckboxItemComponent
                              {...renderProps}
                              {...restItem}
                              className={cn(
                                renderProps.className,
                                restItem.className
                              )}
                            />
                          );
                        }

                        return null;
                      }}
                    />
                  );
                })}
            </div>
          ))
      )}
    </div>
  );
};
