import * as z from 'zod';

import { GridLayoutModel, LinkGroups } from '../../../../types/layout';
import {
  TrackPriceSettingLineOpacity,
  TrackPriceSettingLineType,
  TrackPriceSettingTitlePosition,
} from '../../../../types/trackPrice';
import { TradesFeedsSettings } from '../../../../types/tradeFeed';
import {
  ChartStorage,
  OrderBookFiltersSettings,
} from '../../../../types/workspace';

export class WorkspaceConfigurationsZod_V1 {
  static trackPriceCommonSettingsConfig = z.object({
    lineType: z.enum([
      TrackPriceSettingLineType.Solid,
      TrackPriceSettingLineType.Dashed,
    ]),
    lineOpacity: z.enum([
      TrackPriceSettingLineOpacity.Opacity100,
      TrackPriceSettingLineOpacity.Opacity75,
      TrackPriceSettingLineOpacity.Opacity50,
    ]),
    titlePosition: z.enum([
      TrackPriceSettingTitlePosition.Left,
      TrackPriceSettingTitlePosition.Middle,
      TrackPriceSettingTitlePosition.Right,
    ]),
    notify: z.boolean(),
  });

  /**
   * Схема конфигурации рабочего стола
   * */
  static config = z.object({
    timeStamp: z.number(),
    layouts: z.array(z.custom<GridLayoutModel>()),
    layoutLinks: z.array(z.custom<LinkGroups>()),
    layoutNames: z.array(z.string()),

    /**
     * Какие окна открыты в отдельном окне
     * */
    layoutWindows: z.array(z.boolean()),
    /**
     * Какие окна открыты в отдельном окне
     * */
    activeLayoutKey: z.number(),
    chart: z.custom<ChartStorage>(),
    tradesFeedsSettings: z.optional(z.custom<TradesFeedsSettings>()),
    orderBookFiltersSettings: z.optional(z.custom<OrderBookFiltersSettings>()),
    trackPriceSettings: z
      .object({
        defaultSettings: this.trackPriceCommonSettingsConfig.optional(),
        settingsByTrackPriceId: z
          .record(
            this.trackPriceCommonSettingsConfig.extend({
              title: z.string(),
            })
          )
          .optional(),
      })
      .optional(),
    favorites: z
      .object({
        studies: z.array(z.string()).optional(),
        drawings: z.array(z.string()).optional(),
      })
      .optional(),
    chartThemes: z
      .array(
        z.object({
          name: z.string(),
          axisText: z.string(),
          background: z.string(),
          gridDividers: z.string(),
          gridLines: z.string(),
          candleUpFill: z.string(),
          candleDownFill: z.string(),
          candleUpWick: z.string(),
          candleDownWick: z.string(),
          candleUpBorder: z.string(),
          candleDownBorder: z.string(),
          line: z.string(),
        })
      )
      .optional(),
  });

  /**
   * Схема хранения конкретной конфигурации
   * */
  static configuration = z.object({
    name: z.string(),
    type: z.enum(['custom', 'default']),
    id: z.string(),
    lastAutoSavedConfig: this.config,
    lastManualSavedConfig: this.config,
  });

  /**
   * Схема хранения списка конфигураций
   * */
  static configurations = z.object({
    saveTimestamp: z.number(),
    workspaceConfigurationActiveId: z.number(),
    workspaceConfigurations: z.array(this.configuration),
  });
}
