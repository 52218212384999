import { useState } from 'react';
import { AsterisksMIcon } from '@alfalab/icons-glyph/AsterisksMIcon';
import { ExclamationCircleMIcon } from '@alfalab/icons-glyph/ExclamationCircleMIcon';
import { LockClosedMIcon } from '@alfalab/icons-glyph/LockClosedMIcon';
import { UserMIcon } from '@alfalab/icons-glyph/UserMIcon';
import { BookOpenMIcon } from '@alfalab/icons-rocky/BookOpenMIcon';

import { FunctionalModal } from '@terminal/common/components/FunctionalModal';
import {
  MainMenu,
  MainMenuItem,
} from '@terminal/common/components/MainMenu/MainMenu';
import { useInit } from '@terminal/lk/legacy/hooks';

import {
  CodeTable,
  PassportData,
  PasswordChange,
  ProfileContacts,
} from '../../../../features/Profile';
import { ProfileSettingsMenuType } from '../../model';

import styles from './Profile.module.css';

interface ProfileProps {
  onClose: () => void;
}

export const Profile = ({ onClose }: ProfileProps) => {
  const [selected, setSelected] = useState<ProfileSettingsMenuType>(
    ProfileSettingsMenuType.Profile
  );

  const { data: init } = useInit();

  const menuItems: MainMenuItem<ProfileSettingsMenuType>[] = [
    {
      name: 'Основная информация',
      icon: <UserMIcon />,
      type: ProfileSettingsMenuType.Profile,
      rightIcon: !init?.emailConfirmed && (
        <ExclamationCircleMIcon
          height="16"
          color="var(--color-dark-graphic-attention)"
        />
      ),
    },
    {
      name: 'Паспортные данные',
      icon: <BookOpenMIcon />,
      type: ProfileSettingsMenuType.PassportData,
    },
    {
      name: 'Смена пароля',
      icon: <LockClosedMIcon />,
      type: ProfileSettingsMenuType.ChangePassword,
    },
    {
      name: 'Кодовая таблица',
      icon: <AsterisksMIcon />,
      type: ProfileSettingsMenuType.CodeTable,
    },
  ];

  return (
    <FunctionalModal
      title="Настройки профиля"
      width={812}
      onClose={onClose}
      drawerId="profile-settings-modals"
      sidebar={
        <MainMenu
          items={menuItems}
          selected={selected}
          onItemSelect={(item) =>
            setSelected(item.type as ProfileSettingsMenuType)
          }
        />
      }
    >
      <div className={styles.container}>
        {selected === ProfileSettingsMenuType.Profile && <ProfileContacts />}
        {selected === ProfileSettingsMenuType.PassportData && <PassportData />}
        {selected === ProfileSettingsMenuType.ChangePassword && (
          <PasswordChange />
        )}
        {selected === ProfileSettingsMenuType.CodeTable && <CodeTable />}
      </div>
    </FunctionalModal>
  );
};
