import { differenceInDays, format } from 'date-fns';
import { ru } from 'date-fns/locale';
import orderBy from 'lodash/orderBy';
import log from 'loglevel';

import { alfaDirectClient } from '../client/client';
import {
  ArchiveRequest3Entity,
  BaseTimeFrame,
  CandleType,
  ChartArchiveEntity,
  ChartSequenceBegin,
  ChartSequenceEnd,
  FrontEndType,
} from '../client/entities';
import { EntityType } from '../client/entityTypes';
import { getId } from './id';
import { TimeService } from './time';

import { ArchiveCandle } from '../../types/chart';
import {
  IChartArchiveService,
  PeriodicityParams,
} from '../../types/ChartArchiveService';

interface ChartArchiveFetchResponse {
  requestId: number;
  response: Promise<ArchiveCandle[]>;
}

export class ChartArchiveService implements IChartArchiveService {
  localLock = true;
  requestIdMap: Map<number, true>;
  callbacksMap: Map<number, (value: ArchiveCandle[]) => void>;

  dataPoints: ArchiveCandle[] = [];

  public destroy?: () => void;

  constructor() {
    this.requestIdMap = new Map();
    this.callbacksMap = new Map();

    this.init();
  }

  init() {
    this.destroy?.();

    // Тут все построено на предположении что ChartSequenceBegin и ChartSequenceEnd
    // прилетят в одном архиве и поэтому ивент последовательно сработают
    // в реальности конечно может что-то измениться
    const chartSequenceBeginListener = (message) => {
      (message.data as ChartSequenceBegin[]).forEach((begin) => {
        if (this.requestIdMap.has(begin.IdRequest)) {
          this.localLock = false;
        }
      });
    };

    alfaDirectClient.addListener(
      EntityType.ChartSequenceBegin,
      chartSequenceBeginListener
    );

    const chartArchiveEntityListener = (message) => {
      (message.data as ChartArchiveEntity[]).forEach((archive) => {
        if (this.localLock) {
          return;
        }

        // используем старый добрый for для максимального быстродействия
        for (let i = 0; i < archive.Candles.length; i++) {
          const candle = archive.Candles[i];

          this.dataPoints.push({
            Open: candle.Open,
            High: candle.High,
            Low: candle.Low,
            Close: candle.Close,
            DT: candle.DateTime,
            Date: candle.DateTime.toISOString(),
            Volume: Number(candle.Volume),
          });
        }
      });
    };

    alfaDirectClient.addListener(
      EntityType.ChartArchiveEntity,
      chartArchiveEntityListener
    );

    const chartSequenceEndListener = (message) => {
      (message.data as ChartSequenceEnd[]).forEach((end) => {
        const requestId = end.IdRequest;

        if (this.requestIdMap.has(requestId)) {
          this.requestIdMap.delete(requestId);

          if (this.callbacksMap.has(requestId)) {
            const quotes = this.sanitizeDataPoints();
            const High = quotes?.[0]?.High;
            const Open = quotes?.[0]?.Open;

            const formattedQuotes =
              (High > 0 && Open > 0) ||
              (quotes?.[0]?.DT &&
                format(quotes?.[0]?.DT, 'yyyy', {
                  locale: ru,
                  // 0001 - некорректный год, который проставляется в случае
                  // отсутствия данных по счечам
                }) !== '0001')
                ? quotes
                : [];

            const cb = this.callbacksMap.get(end.IdRequest);

            cb?.(formattedQuotes);
            this.callbacksMap.delete(requestId);
          } else {
            log.debug('Callback for chart historic data is not registered');
          }

          this.localLock = true;
          this.dataPoints = [];
        }
      });
    };

    alfaDirectClient.addListener(
      EntityType.ChartSequenceEnd,
      chartSequenceEndListener
    );

    this.destroy = () => {
      alfaDirectClient.removeListener(
        EntityType.ChartSequenceBegin,
        chartSequenceBeginListener
      );
      alfaDirectClient.removeListener(
        EntityType.ChartArchiveEntity,
        chartArchiveEntityListener
      );
      alfaDirectClient.removeListener(
        EntityType.ChartSequenceEnd,
        chartSequenceEndListener
      );
    };
  }

  public fetch(
    idFi: number,
    startDate: Date,
    endDate: Date,
    periodicityParams: PeriodicityParams
  ): ChartArchiveFetchResponse {
    // Сервер по умолчанию считает, что время приходит в московской таймзоне. Также чиним переведенное время
    const startDateMSK = TimeService.formatToServerTime(startDate);
    const endDateMSK = TimeService.formatToServerTime(endDate);

    const requestId = getId();

    this.requestIdMap.set(requestId, true);

    const request = this.createRequestEntity(
      idFi,
      requestId,
      startDateMSK,
      endDateMSK,
      periodicityParams
    );

    alfaDirectClient.send({
      frontend: FrontEndType.BirzArchAndMediaServer,
      isArray: false,
      payload: { type: EntityType.ArchiveRequest3Entity, data: request },
    });

    return {
      requestId,
      response: new Promise<ArchiveCandle[]>((resolve, reject) => {
        this.callbacksMap.set(requestId, resolve);

        setTimeout(() => {
          reject('Timeout');
        }, 120000);
      }),
    };
  }

  private createRequestEntity(
    id: number,
    requestId: number,
    startDate: Date,
    endDate: Date,
    periodicityParams: PeriodicityParams
  ): ArchiveRequest3Entity {
    const request = new ArchiveRequest3Entity();

    request.CandleType = CandleType.Standard;
    request.IdRequest = requestId;
    request.IdFI = id;
    request.IntervalInfo = [];
    request.TimeFrame = getTimeFrameForParams(periodicityParams);

    const caseString = `${periodicityParams.period} ${periodicityParams.interval}`;

    // что за волшебные числа с проверками: это ограничение по максимальному количеству свечек за раз
    // day - 720, minutes - 30, second - 1. Числа это дни
    switch (caseString) {
      case '1 second':
      case '5 second': {
        request.DaysCount = differenceInDays(endDate, startDate);

        // это для того чтоб гарантированно проскочить выходные
        // могут быть потенциальные баги если пользователь попытается отскролить через долгие праздники
        if (request.DaysCount < 1) {
          request.DaysCount = 1;
        }

        if (request.DaysCount >= 30) {
          request.DaysCount = 29;
        }

        request.DaysCount = request.DaysCount * -1;
        request.MaximumDate = endDate;
        break;
      }
      case '1 minute':
      case '5 minute':
      case '10 minute':
      case '15 minute':
      case '30 minute': {
        request.DaysCount = differenceInDays(endDate, startDate);

        // это для того чтоб гарантированно проскочить выходные
        // могут быть потенциальные баги если пользователь попытается отскролить через долгие праздники
        if (request.DaysCount < 3) {
          request.DaysCount = 3;
        }

        if (request.DaysCount >= 30) {
          request.DaysCount = 29;
        }

        request.DaysCount = request.DaysCount * -1;
        request.MaximumDate = endDate;
        break;
      }
      case '60 minute':
      case '120 minute':
      case '180 minute':
      case '240 minute':
      case '360 minute':
      case '480 minute': {
        request.DaysCount = differenceInDays(endDate, startDate); // если интрадэй(минута или секунад) надо дату и минус 1

        if (request.DaysCount < 3) {
          request.DaysCount = 3;
        }

        if (request.DaysCount > 720) {
          request.DaysCount = 719;
        }

        request.DaysCount = request.DaysCount * -1;
        request.MaximumDate = endDate;
        break;
      }
      case '1 day': {
        request.DaysCount = differenceInDays(endDate, startDate); // если интрадэй(минута или секунад) надо дату и минус 1

        if (request.DaysCount < 1) {
          request.DaysCount = 1;
        }

        if (request.DaysCount > 720) {
          request.DaysCount = 719;
        }

        request.DaysCount = request.DaysCount * -1;
        request.MaximumDate = endDate;
        break;
      }
      case '1 week': {
        request.DaysCount = differenceInDays(endDate, startDate); // если интрадэй(минута или секунад) надо дату и минус 1

        if (request.DaysCount < 1) {
          request.DaysCount = 1;
        }

        if (request.DaysCount > 720) {
          request.DaysCount = 719;
        }

        request.DaysCount = request.DaysCount * -1;
        request.MaximumDate = endDate;
        break;
      }
      case '1 month': {
        request.DaysCount = differenceInDays(endDate, startDate); // если интрадэй(минута или секунад) надо дату и минус 1

        if (request.DaysCount < 1) {
          request.DaysCount = 1;
        }

        if (request.DaysCount > 720) {
          request.DaysCount = 719;
        }

        request.DaysCount = request.DaysCount * -1;
        request.MaximumDate = endDate;
        break;
      }

      default: {
        request.DaysCount = differenceInDays(endDate, startDate);

        if (request.DaysCount < 1) {
          request.DaysCount = 1;
        }

        if (request.DaysCount > 720) {
          request.DaysCount = 719;
        }

        request.DaysCount = request.DaysCount * -1;
        request.MaximumDate = endDate;
      }
    }

    return request;
  }

  private sanitizeDataPoints(): ArchiveCandle[] {
    if (this.dataPoints.length === 0) {
      return [];
    }

    const result: ArchiveCandle[] = [];

    const orderedOHLC = orderBy(this.dataPoints, 'DT', 'asc');

    // бывают ситуации когда сервер может прислать данные за одинаковые промежутки времени
    // например запрос минуток с -3 днями в DayCount попавшие на нерабочие и праздничные дни или выходные
    // сервер может дополнить данные по одному из дней скажем прислав 24 фев 22, 25 фев 22 и 25 фев 22 тк биржа
    // не работала после 25 февраля, а 23 фев был выходной
    // те данные за 25 фев прилетят два раза
    // Здесь удаляются свечи с задублированными данными
    result.push(orderedOHLC[0]);

    let prevDT = result[0].DT?.getTime();

    for (let i = 1; i < orderedOHLC.length; i++) {
      const currentDT = orderedOHLC[i].DT?.getTime();

      if (prevDT !== currentDT) {
        result.push(orderedOHLC[i]);
        prevDT = currentDT;
      }
    }

    return result;
  }
}

export function getTimeFrameForParams(params: PeriodicityParams) {
  const timeframe = `${params.period} ${params.interval}`;

  // что за волшебные числа с проверками: это ограничение по максимальному количеству свечек за раз
  // day - 720, minutes - 30, second - 1. Числа это дни
  switch (timeframe) {
    case '1 second':
    case '5 second': {
      return BaseTimeFrame.Second;
    }
    case '1 minute':
    case '5 minute':
    case '10 minute':
    case '15 minute':
    case '30 minute': {
      return BaseTimeFrame.Minute;
    }
    case '60 minute':
    case '120 minute':
    case '180 minute':
    case '240 minute':
    case '360 minute':
    case '480 minute': {
      // Hour пока не подходит, потому что чартик не умеет в часы:
      // получая часовые свечки он сохраняет и использует для всех ТФ от 1M до 8H.
      // Переключаясь с часов на минуты, дозапрос не происходит автоматом
      // TODO Научить
      return BaseTimeFrame.Minute; // BaseTimeFrame.Hour
    }
    case '1 day': {
      return BaseTimeFrame.Day;
    }
    case '1 week': {
      return BaseTimeFrame.Week;
    }
    case '1 month': {
      return BaseTimeFrame.Month;
    }
  }

  return BaseTimeFrame.Hour;
}
