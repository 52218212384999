import { useCallback, useEffect, useState } from 'react';

import { useTradingService } from '@terminal/core/lib/services/trading/useTradingService';
import { OrderItem } from '@terminal/core/types/order';
import { SendOrderParams } from '@terminal/core/types/trading';
import { NotificationType } from '@terminal/core/types/ui';

import { OrderSource, useOrderAnalyticsMetrics } from '../../../entities/Order';
import { useWidgetContext } from '../../../shared';

export const useOrderBookTrading = (idFi: number) => {
  const { addNotification } = useWidgetContext();

  //Состояния для модальных окон отмены и редактирования заявок
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
  const [isLoadingCancelModal, setIsLoadingCancelModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isLoadingEditModal, setIsLoadingEditModal] = useState(false);
  //модалка редактирования заявки
  const [orderToEdit, setOrderToEdit] = useState<OrderItem | null>(null);
  //модалка отмены заявки
  const [orderToCancel, setOrderToCancel] = useState<OrderItem | null>(null);

  useEffect(() => {
    setIsLoadingCancelModal(false);
  }, [orderToCancel, setIsLoadingCancelModal]);

  useEffect(() => {
    setIsLoadingEditModal(false);
  }, [orderToEdit, setIsLoadingEditModal]);

  const { trackErrorOrder, trackSuccessOrder } = useOrderAnalyticsMetrics(idFi);

  const clearAllModals = useCallback(() => {
    setIsOpenCancelModal(false);
    setIsLoadingCancelModal(false);
    setIsOpenEditModal(false);
    setIsLoadingEditModal(false);
  }, []);

  const onOrderResponse = useCallback(
    (_, params?: SendOrderParams) => {
      if (params) {
        trackSuccessOrder(OrderSource.ORDERBOOK, params);
      }

      clearAllModals();
    },
    [clearAllModals, trackSuccessOrder]
  );

  const onOrderError = useCallback(
    (message: string, params?: SendOrderParams) => {
      if (params) {
        trackErrorOrder(OrderSource.ORDERBOOK, params, undefined, message);
      }

      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка',
        text: message,
      });

      clearAllModals();
    },
    [addNotification, clearAllModals, trackErrorOrder]
  );

  const tradingMethods = useTradingService(onOrderError, onOrderResponse);

  return {
    orderToEdit,
    setOrderToEdit,
    orderToCancel,
    setOrderToCancel,
    isOpenCancelModal,
    setIsOpenCancelModal,
    isLoadingCancelModal,
    setIsLoadingCancelModal,
    isOpenEditModal,
    setIsOpenEditModal,
    isLoadingEditModal,
    setIsLoadingEditModal,
    ...tradingMethods,
  };
};
