import { useMutation } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

import { getAttorneyPdf } from '../api/getAttorneyPdf';

import { GetAttorneyPdfParams } from '../api/types/GetAttorneyPdfParams';

export const useGetAttorneyPdf = () => {
  const addNotification = useNotification();

  return useMutation(
    async ({ attorneyId }: GetAttorneyPdfParams) => {
      const file = await getAttorneyPdf({ attorneyId });

      const link = document.createElement('a');

      link.href = file.url;
      link.download = file.filename;
      link.click();
      link.remove();
      file.destroy();
    },
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text: error?.message || 'Ошибка загрузки документа',
        });
      },
    }
  );
};
