import { StateCreator } from 'zustand';

import { LkSignProgress } from '../model/types/LkSignProgress';
import { LkSignSlice } from '../model/types/LkSignSlice';
import { SignCreated } from '../model/types/SignCreated';
import { SigningTextResponse } from '../model/types/SigningTextResponse';

export const createLkSignSlice: StateCreator<
  LkSignSlice,
  [],
  [],
  LkSignSlice
> = (set, get) => ({
  lkSignDialogOpen: false,
  lkSignOperation: null,
  lkSignText: '',
  lkSignMultipleText: null,
  lkSignProgress: null,
  lkSignMultipleErrors: [],
  lkSignServerCerts: [],
  lkSignOptions: null,
  lkSignConfirmationDialog: {
    open: false,
    text: '',
  },
  openLkSignDialog: () =>
    set(() => ({
      lkSignDialogOpen: true,
    })),
  setLkSignOperation: (operation: SignCreated) => {
    set(() => ({
      lkSignOperation: operation,
    }));
  },
  setLkSignOperationText: (data: SigningTextResponse) => {
    set(() => ({
      lkSignText: data.message,
      lkSignMultipleText: data.messages || null,
      lkSignServerCerts: data.certificates,
    }));
  },
  setLkSignProgress: (progress: LkSignProgress) => {
    set(() => ({
      lkSignProgress: progress,
    }));
  },
  setLkSignMultipleErrors: (errors: string[]) => {
    set(() => ({
      lkSignMultipleErrors: errors,
    }));
  },
  closeLkSignOperation: () => {
    get().resetLkSignStore();
  },
  resetLkSignStore: () => {
    const lkSignOptions = get().lkSignOptions;

    if (typeof lkSignOptions?.resetCallback !== 'undefined') {
      lkSignOptions.resetCallback();
    }

    set(() => ({
      lkSignDialogOpen: false,
      lkSignOperation: null,
      lkSignText: '',
      lkSignMultipleText: null,
      lkSignProgress: null,
      lkSignMultipleErrors: [],
      lkSignServerCerts: [],
      lkSignOptions: null,
      lkSignConfirmationDialog: {
        open: false,
        text: '',
      },
    }));
  },
  setLkSignOptions: (options) => {
    set(() => ({
      lkSignOptions: options,
    }));
  },
  openLkSignConfirmation: (text) => {
    set(() => ({
      lkSignConfirmationDialog: {
        open: true,
        text: text || '',
      },
    }));
  },
  closeLkSignConfirmation: () => {
    set(() => ({
      lkSignConfirmationDialog: {
        open: false,
        text: '',
      },
    }));
  },
});
