import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getClientProfile } from '@terminal/core/lib/rest/lkRiskProfile';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../queryKeys';

/**
 * @deprecated используйте аналог из `@terminal/lk-core`
 * */
export const useClientProfile = (enabled: boolean) => {
  const addNotification = useNotification();

  return useQuery([LkQueryKeys.RiskProfile], async () => getClientProfile(), {
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка запроса профиля риска',
        text: error?.message || '',
      });
    },
    enabled,
  });
};
