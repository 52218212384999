import { useEffect, useState } from 'react';
import { SliderInput } from '@alfalab/core-components/slider-input';

import { NumberRange as NumberRangeValue } from '@terminal/core/types/ranges';

import styles from './NumberRange.module.css';

type NumberRangeProps = {
  filterValue?: NumberRangeValue;
  min?: number;
  max?: number;
  step?: number;
  onChange: (value: NumberRangeValue) => void;
};

export const NumberRange = (props: NumberRangeProps) => {
  const { filterValue, min, max, step, onChange } = props;

  const [from, setFrom] = useState(filterValue?.from ?? 1);
  const [to, setTo] = useState(filterValue?.to ?? 1);

  useEffect(() => {
    onChange({ from, to });
  }, [from, to, onChange]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.inputs}>
        <SliderInput
          value={from}
          min={min}
          max={max}
          step={step}
          onChange={(_, { value }) => setFrom(value)}
        />
        <span className={styles.dash}>&mdash;</span>
        <SliderInput
          value={to}
          min={min}
          max={max}
          step={step}
          onChange={(_, { value }) => setTo(value)}
        />
      </div>
    </div>
  );
};
