import { CIQ } from 'chartiq';
import debounce from 'lodash/debounce';
import drop from 'lodash/drop';
import isArray from 'lodash/isArray';

import { CHART_DEFAULT_THEMES } from '@terminal/core/constants/chart';
import { importOptions } from '@terminal/core/constants/Layout';
import { useStore } from '@terminal/core/store';
import {
  chartCustomSettingsFields,
  ChartSetting,
  IChartCustomSettings,
  Layout,
  LayoutSymbol,
} from '@terminal/core/types/chart';
import { IChartSettingsController } from '@terminal/core/types/ChartSettingsController';

import { TChartStxx } from '../types';

export class ChartSettingsController implements IChartSettingsController {
  private _stx: TChartStxx;
  private _nodeId: string;
  public settings: ChartSetting;
  private _onChange: () => void;
  private _batchedSettings: ChartSetting | null = null;

  constructor(
    stx: TChartStxx,
    nodeId: string,
    settings: ChartSetting,
    onChange: () => void
  ) {
    this._stx = stx;
    this._nodeId = nodeId;
    this.settings = settings;
    this._onChange = onChange;
  }

  private debouncedApplyBatchedSettings = debounce(() => {
    if (!this._batchedSettings) {
      return;
    }

    const store = useStore.getState();

    store.chartSetChartSetting(this._nodeId, this._batchedSettings);
    this._onChange();
    this._batchedSettings = null;
  }, 300);

  /**
   * Сохраняет переданные настройки чарта в инстанс класса и в стор.
   * Переданные настройки не заменяют, а перезаписывают старые,
   * т.е. поля, которых нет в переданном объекте settings не удаляются а остаются старыми
   * @param nodeId - ID ноды лайаута
   * @param settings - новые настройки чарта
   * */
  public saveChartSettings(nodeId: string, settings: ChartSetting) {
    let prevSettings: ChartSetting | undefined;

    if (this._batchedSettings) {
      prevSettings = this._batchedSettings;
    } else {
      const store = useStore.getState();

      const chartsSettingsStore: Record<string, ChartSetting> =
        store.workspaceConfigurations[store.workspaceConfigurationActiveId]
          .lastAutoSavedConfig.chart;

      prevSettings = chartsSettingsStore[nodeId];
    }

    const newSettings: ChartSetting = {
      ...prevSettings,
      ...settings,
      customSettings: {
        panels:
          settings.customSettings?.panels ||
          prevSettings?.customSettings?.panels,
        ...chartCustomSettingsFields.reduce<IChartCustomSettings>(
          (newSettings, field) => {
            return {
              ...newSettings,
              [field]: settings?.customSettings?.hasOwnProperty(field)
                ? settings.customSettings?.[field]
                : prevSettings?.customSettings?.[field],
            };
          },
          {}
        ),
      },
    };

    this.settings = newSettings;
    this._batchedSettings = newSettings;
    this.debouncedApplyBatchedSettings();
  }

  /**
   * Получает настройки чата из стора
   * @param nodeId - ID ноды лайаута
   * */
  static getChartStoredSetting(nodeId: string): ChartSetting | undefined {
    const store = useStore.getState();

    const chartsSettingsStore: Record<string, ChartSetting> =
      store.workspaceConfigurations[store.workspaceConfigurationActiveId]
        .lastAutoSavedConfig.chart;

    return chartsSettingsStore[nodeId];
  }

  /**
   * Удаляет из стора настройки чата для переданного id
   * @param nodeId - ID ноды лайаута
   * */
  public static deleteChartStoredSettings(nodeId: string) {
    const store = useStore.getState();

    store.chartDeleteChartSetting(nodeId);
  }

  /**
   * Получает настройки чарта из поля инстанса чарта
   * @param setting - имя поля объекта настроек
   * */
  public getChartCurrentSetting<K extends keyof ChartSetting>(
    setting: K
  ): ChartSetting[K] | undefined {
    return this.settings[setting];
  }

  /**
   * Сохраняет поле customSettings настроек чарта в инстанс чарта и в стор.
   * Остальные настройки остаются как есть
   * @param customPreferences - объект customSettings
   * */
  public chartSaveCustomSettings(customPreferences?: Record<string, any>) {
    if (this._stx.panels) {
      const customSettings = Object.values<CIQ.ChartEngine.Panel>(
        this._stx.panels
      ).reduce(
        (acc: IChartCustomSettings, panel) => {
          if (!acc.panels) {
            acc.panels = {};
          }

          if (!panel.name) {
            return acc;
          }

          acc.panels[panel.name] = {
            name: panel.name,
            yAxis: { zoom: panel.yAxis?.zoom || 0 },
          };

          return acc;
        },
        { panels: {}, ...customPreferences }
      );

      this.saveChartSettings(this._nodeId, {
        customSettings,
      });
    }
  }

  /**
   * Экспортирует и сохраняет текущий лайаут чарта в инстанс класса и в стор.
   * */
  public chartSaveLayout() {
    this.saveChartSettings(this._nodeId, {
      layout: this._stx.exportLayout(true),
    });
  }

  /**
   * Экспортирует и сохраняет текущие preferences чарта в инстанс класса и в стор.
   * */
  public chartSavePreferences() {
    this.saveChartSettings(this._nodeId, {
      preferences: this._stx.exportPreferences(),
    });
  }

  /**
   * Экспортирует и сохраняет текущие drawings чарта в инстанс класса и в стор.
   * */
  public chartSaveDrawings(symbol: string) {
    const prevDrawings = this.settings.drawings || {};
    const drawings = {
      ...prevDrawings,
      [symbol]: this._stx.exportDrawings(),
    };

    this.saveChartSettings(this._nodeId, {
      drawings,
      layout: this._stx.exportLayout(true),
    });
  }

  /**
   * Экспортирует и сохраняет текущую тему чарта в инстанс класса и в стор.
   * */
  public chartSaveTheme(theme: string) {
    this.saveChartSettings(this._nodeId, {
      theme,
    });
  }

  /**
   * Применяет настройки чарта (layout и preferences) из текущего инстанса ChartSettingsController к чарту
   * */
  public chartRestoreSettings(
    idFi?: number,
    symbol?: string,
    trueSymbol?: string
  ) {
    // Если у нас нет необходимых данных для восстановления -> early return
    if (!idFi || !symbol) {
      return;
    }

    const savedLayout = (Boolean(this._nodeId) && this.settings.layout) as
      | Layout
      | undefined;
    const pref = Boolean(this._nodeId) && this.settings.preferences;
    const chartConfig = savedLayout?.symbols[0]?.symbolObject;
    const isDifferentFis =
      chartConfig &&
      !(idFi === chartConfig?.id && symbol === chartConfig?.symbol);

    let newLayout = savedLayout;

    if (isDifferentFis && savedLayout) {
      // Обновляем инструмент, сохранив настройки графика для окна
      const newSymbols = {
        ...savedLayout.symbols[0],
        symbol,
        symbolObject: {
          periodicity: { ...savedLayout.symbols[0].periodicity },
          id: idFi,
          symbol: symbol,
          trueSymbol: trueSymbol ?? symbol,
        },
      };

      newLayout = {
        ...savedLayout,
        symbols: [newSymbols, ...drop(savedLayout.symbols, 1)],
      };

      this.saveChartSettings(this._nodeId, {
        layout: newLayout,
      });
    }

    if (newLayout) {
      this._stx.importLayout(newLayout, importOptions);
      this._stx.zoomSet?.(newLayout.candleWidth, this._stx.chart);
    }

    if (pref) {
      this._stx.importPreferences(pref);
    }
  }

  /**
   * Применяет настройки чарта (customSettings, drawings и тему) из текущего инстанса ChartSettingsController к чарту
   * */
  public chartRestore(symbol: string) {
    const customSettings = this.settings.customSettings;

    if (customSettings?.panels) {
      Object.entries(customSettings.panels).forEach(([panelName, object]) => {
        if (this._stx.panels && this._stx.panels[panelName]) {
          // stxx.modifyPanel - почему-то не работает
          // @ts-expect-error
          this._stx.panels[panelName].yAxis.zoom = object.yAxis.zoom;
        }
      });
    }

    const drawings = Boolean(this._nodeId) && this.settings.drawings?.[symbol];

    if (drawings && isArray(drawings) && !this._stx.drawingObjects?.length) {
      this._stx.importDrawings(drawings);
      this._stx.draw();
    }

    const symbols: LayoutSymbol[] =
      (this.settings.layout as Layout)?.symbols?.slice(1) || [];

    symbols.forEach((s, i) => {
      const seriesList = Object.keys(
        this._stx.panels?.chart?.chart?.series || []
      ).filter((symbol) => !symbols.find(({ id }) => id === symbol));

      this._stx.addSeries(s.id, {
        isComparison: true,
        shareYAxis: true,
        color:
          CIQ.UI.defaultSwatchColors[
            (seriesList.length % CIQ.UI.defaultSwatchColors.length) + i
          ],
        width: 0.5,
        gapDisplayStyle: 'true',
        symbolObject: s.symbolObject,
      });
    });

    const theme = this.settings.theme || '';

    if (CHART_DEFAULT_THEMES.includes(theme)) {
      this._stx.themes?.setDefaultTheme?.(theme);
    } else if (
      Object.keys(this._stx.themes?.params.customThemes || {}).includes(theme)
    ) {
      this._stx.themes?.setCustomTheme?.(theme);
    }
  }
}
