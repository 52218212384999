import cn from 'classnames';
import { Dispatch, memo, useCallback, useRef, useState } from 'react';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { ChevronForwardMIcon } from '@alfalab/icons-glyph/ChevronForwardMIcon';
import { DotsHorizontalMIcon } from '@alfalab/icons-glyph/DotsHorizontalMIcon';

import { Dropdown } from '@terminal/common/components/Dropdown';
import { useOnClickOutside } from '@terminal/core/hooks/useOnClickOutside';

import { useWidgetContext } from '../../../../shared';
import { VolumeAdditionalType } from '../../model';

import styles from './AdditionalVolumeSettings.module.css';

interface IProps {
  volumeAdditionalType: VolumeAdditionalType;
  setVolumeAdditionalType: Dispatch<VolumeAdditionalType>;
  limitDisabled: boolean;
  posDisabled: boolean;
  cashDisabled: boolean;
  volumeAdditionalPercent: number | null;
  setVolumeAdditionalPercent: Dispatch<number | null>;
  className?: string;
}

export const AdditionalVolumeSettings = memo((props: IProps) => {
  const {
    volumeAdditionalType,
    setVolumeAdditionalType,
    limitDisabled,
    posDisabled,
    cashDisabled,
    volumeAdditionalPercent,
    setVolumeAdditionalPercent,
    className,
  } = props;

  const [additionalVolumeVisible, setAdditionalVolumeVisible] =
    useState<boolean>(false);
  const [additionalLimitMenuVisible, setAdditionalLimitMenuVisible] =
    useState<boolean>(false);
  const [additionalPositionMenuVisible, setAdditionalPositionMenuVisible] =
    useState<boolean>(false);
  const [additionalCashMenuVisible, setAdditionalCashMenuVisible] =
    useState<boolean>(false);

  const additionalVolumeRef = useRef<HTMLDivElement>(null);
  const additionalVolumeDropdownRef = useRef<HTMLDivElement>(null);
  const additionalLimitMenuRef = useRef<HTMLDivElement>(null);
  const additionalPositionMenuRef = useRef<HTMLDivElement>(null);
  const additionalCashMenuRef = useRef<HTMLDivElement>(null);

  const { DropdownComponent } = useWidgetContext();

  useOnClickOutside(additionalVolumeRef, (e) => {
    const anchor = additionalVolumeRef.current;

    if (anchor && !anchor?.contains(e.target as Node)) {
      setAdditionalVolumeVisible(false);
    }
  });

  const handleClearAllAdditionals = useCallback(() => {
    setVolumeAdditionalType(null);
    setVolumeAdditionalPercent(null);
  }, [setVolumeAdditionalType, setVolumeAdditionalPercent]);

  return (
    <div
      ref={additionalVolumeRef}
      className={cn(styles.otherButton, className)}
    >
      <DotsHorizontalMIcon
        width={12}
        height={12}
        className={styles.icon}
        onClick={() => setAdditionalVolumeVisible((prev) => !prev)}
      />
      <DropdownComponent
        ref={additionalVolumeDropdownRef}
        anchorElement={additionalVolumeRef.current}
        open={additionalVolumeVisible}
        offset={[0, 12]}
        position="bottom-end"
        popperClassName={styles.popover}
      >
        <Dropdown.Item
          content="% от доступного плеча"
          rightIcon={
            <ChevronForwardMIcon
              height={18}
              width={18}
              color="var(--color-dark-graphic-secondary)"
            />
          }
          ref={additionalLimitMenuRef}
          onMouseEnter={() =>
            !limitDisabled && setAdditionalLimitMenuVisible(true)
          }
          onMouseLeave={() =>
            !limitDisabled && setAdditionalLimitMenuVisible(false)
          }
          onClick={() => !limitDisabled && setAdditionalLimitMenuVisible(false)}
          className={cn(limitDisabled && styles.dropDownItemDisabled)}
        >
          <DropdownComponent
            anchorElement={additionalLimitMenuRef.current}
            open={additionalLimitMenuVisible}
            position="right-start"
            offset={[0, 4]}
            popperClassName={styles.popover}
          >
            {[100, 90, 80, 70, 60, 50, 40, 30, 20, 10].map((percent) => (
              <Dropdown.Item
                key={percent}
                rightIcon={
                  volumeAdditionalType === 'lim' &&
                  volumeAdditionalPercent === percent ? (
                    <CheckmarkMIcon height={18} width={18} />
                  ) : undefined
                }
                content={
                  <div className={styles.dropDownItemContent}>{percent} %</div>
                }
                onClick={() => {
                  if (
                    volumeAdditionalType === 'lim' &&
                    volumeAdditionalPercent === percent
                  ) {
                    handleClearAllAdditionals();
                  } else {
                    setVolumeAdditionalType('lim');
                    setVolumeAdditionalPercent(percent);
                    setAdditionalVolumeVisible(false);
                  }
                }}
              />
            ))}
          </DropdownComponent>
        </Dropdown.Item>
        <Dropdown.Item
          content="% от открытой позиции"
          rightIcon={
            <ChevronForwardMIcon
              height={18}
              width={18}
              color="var(--color-dark-graphic-secondary)"
            />
          }
          ref={additionalPositionMenuRef}
          onMouseEnter={() =>
            !posDisabled && setAdditionalPositionMenuVisible(true)
          }
          onMouseLeave={() =>
            !posDisabled && setAdditionalPositionMenuVisible(false)
          }
          onClick={() =>
            !posDisabled && setAdditionalPositionMenuVisible(false)
          }
          className={cn(posDisabled && styles.dropDownItemDisabled)}
        >
          <DropdownComponent
            anchorElement={additionalPositionMenuRef.current}
            open={additionalPositionMenuVisible}
            position="right-start"
            offset={[0, 4]}
            popperClassName={styles.popover}
          >
            {[100, 90, 80, 70, 60, 50, 40, 30, 20, 10].map((percent) => (
              <Dropdown.Item
                key={percent}
                rightIcon={
                  volumeAdditionalType === 'pos' &&
                  volumeAdditionalPercent === percent ? (
                    <CheckmarkMIcon height={18} width={18} />
                  ) : undefined
                }
                content={
                  <div className={styles.dropDownItemContent}>{percent} %</div>
                }
                onClick={() => {
                  if (
                    volumeAdditionalType === 'pos' &&
                    volumeAdditionalPercent === percent
                  ) {
                    handleClearAllAdditionals();
                  } else {
                    setVolumeAdditionalType('pos');
                    setVolumeAdditionalPercent(percent);
                    setAdditionalVolumeVisible(false);
                  }
                }}
              />
            ))}
          </DropdownComponent>
        </Dropdown.Item>
        <Dropdown.Item
          content="% от собственных средств"
          rightIcon={
            <ChevronForwardMIcon
              height={18}
              width={18}
              color="var(--color-dark-graphic-secondary)"
            />
          }
          ref={additionalCashMenuRef}
          onMouseEnter={() =>
            !cashDisabled && setAdditionalCashMenuVisible(true)
          }
          onMouseLeave={() =>
            !cashDisabled && setAdditionalCashMenuVisible(false)
          }
          onClick={() => !cashDisabled && setAdditionalCashMenuVisible(false)}
          className={cn(cashDisabled && styles.dropDownItemDisabled)}
        >
          <DropdownComponent
            anchorElement={additionalCashMenuRef.current}
            open={additionalCashMenuVisible}
            position="right-start"
            offset={[0, 4]}
            popperClassName={styles.popover}
          >
            {[100, 90, 80, 70, 60, 50, 40, 30, 20, 10].map((percent) => (
              <Dropdown.Item
                key={percent}
                rightIcon={
                  volumeAdditionalType === 'cash' &&
                  volumeAdditionalPercent === percent ? (
                    <CheckmarkMIcon height={18} width={18} />
                  ) : undefined
                }
                content={
                  <div className={styles.dropDownItemContent}>{percent} %</div>
                }
                onClick={() => {
                  if (
                    volumeAdditionalType === 'cash' &&
                    volumeAdditionalPercent === percent
                  ) {
                    handleClearAllAdditionals();
                  } else {
                    setVolumeAdditionalType('cash');
                    setVolumeAdditionalPercent(percent);
                    setAdditionalVolumeVisible(false);
                  }
                }}
              />
            ))}
          </DropdownComponent>
        </Dropdown.Item>
      </DropdownComponent>
    </div>
  );
});

AdditionalVolumeSettings.displayName = 'AdditionalVolumeSettings';
