// Источник: https://www.moex.com/ru/index/RUCBTRNS/constituents

export const RUCBTRNSList = [
  'RU000A0JSGVO',
  'RU000A0JWTN2',
  'RU000A0JWVL2',
  'RU000A0JXN21',
  'RU000A0JXPG2',
  'RU000A0JXPN8',
  'RU000A0JXQ44',
  'RU000A0JXQK2',
  'RU000A0JXS59',
  'RU000AOZYBM4',
  'RU000AOZYEB1',
  'RU000AOZYLG5',
  'RU000AOZYNY4',
  'RU000AOZYR91',
  'RU000AOZYU05',
  'RU000AOZYU21',
  'RU000AOZYVU5',
  'RU000A0ZYWB3',
  'RU000A0ZZ1NO',
  'RU000A0ZZ5J9',
  'RU000AOZZV11',
  'RU000AOZZZ17',
  'RU000A1003A4',
  'RU000A1005L6',
  'RU000A1007Z2',
  'RU000A1008D7',
  'RU000A1008W7',
  'RU000A100FE5',
  'RU000A100HY9',
  'RU000A100N12',
  'RU000A100W60',
  'RU000A1010B7',
  'RU000A1013P1',
  'RU000A101541',
  'RU000A1017J5',
  'RU000A101EF3',
  'RU000A101FG8',
  'RU000A101FH6',
  'RU000A101GD3',
  'RU000A101M04',
  'RU000A101QL5',
  'RU000A101QN1',
  'RU000A101SC0',
  'RU000A101TBO',
  'RU000A101XN7',
  'RU000A101Z74',
  'RU000A102G50',
  'RU000A102GJ8',
  'RU000A102H91',
  'RU000A102LD1',
  'RU000A102QP4',
  'RU000A102YG7',
  'RU000A1031U3',
  'RU000A103372',
  'RU000A103661',
  'RU000A1038D4',
  'RU000A103943',
  'RU000A103AT8',
  'RU000A103C53',
  'RU000A103C95',
  'RU000A103EZ7',
  'RU000A103G00',
  'RU000A103NU9',
  'RU000A103PX8',
  'RU000A103QK3',
  'RU000A104362',
  'RU000A104693',
  'RU000A104A39',
  'RU000A104SU6',
  'RU000A104SX0',
  'RU000A104V75',
  'RU000A104W17',
  'RU000A104W33',
  'RU000A104WJ1',
  'RU000A104XR2',
  'RU000A104XW2',
  'RU000A104Y15',
  'RU000A104Z48',
  'RU000A1051E5',
  'RU000A105559',
  'RU000A1055Y4',
  'RU000A1057P8',
  'RU000A105CM4',
  'RU000A105FM7',
  'RU000A105K85',
  'RU000A105KB0',
  'RU000A105KPO',
  'RU000A105KQ8',
  'RU000A105KR6',
  'RU000A105L27',
  'RU000A105M91',
  'RU000A105Q63',
  'RU000A105SL2',
  'RU000A105TP1',
  'RU000A105TY3',
  'RU000A105U00',
  'RU000A105UU9',
  'RU000A105VC5',
  'RU000A105VU7',
  'RU000A105WP5',
  'RU000A105X64',
  'RU000A105X80',
  'RU000A105ZX2',
  'RU000A1061K1',
  'RU000A1064G3',
  'RU000A106516',
  'RU000A106672',
  'RU000A1066A1',
  'RU000A106888',
  'RU000A106938',
  'RU000A1069P3',
  'RU000A106AH6',
  'RU000A106AJ2',
  'RU000A106AT1',
  'RU000A106CU5',
  'RU000A106DP3',
  'RU000A106FR4',
  'RU000A106FV6',
  'RU000A106GD2',
  'RU000A106JZ9',
  'RU000A106KV6',
  'RU000A106P06',
  'RU000A106R53',
  'RU000A106SP1',
  'RU000A107910',
  'RU000A107A28',
  'RU000A107AJO',
  'RU000A107AM4',
  'RU000A107DM8',
  'RU000A107MM9',
  'RU000A107RZ0',
  'RU000A107TH4',
  'RU000A107UA7',
  'RU000A107W06',
  'RU000A1082Y8',
  'RU000A1084B2',
  'RU000A1085D5',
  'RU000A1087A7',
  'RU000A108C74',
  'RU000A109874',
  'RU000A109LG9',
];
