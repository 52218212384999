import { useMemo } from 'react';

import { OrderDimensionEnum } from '@terminal/core/types/ui';

import { formatWithSuffix } from '../lib/formatWithSuffix';
import { VolumeType } from '../model';

type Options = {
  // Значение введённое пользователем
  quantity: number;
  // Количество позиций высчитанное на основе введённой суммы
  computedQuantity: number;
  lot: number;
  orderDimension: OrderDimensionEnum;
  volumeType: VolumeType;
};

/**
 * - Если вводим по лотам, то выводим в штуках
 * - Если вводим по штукам, то выводим в лотах
 * - Если вводим по деньгам, то выводим в зависимости от isLotCount
 * @param options параметры для определения значения:
 * - lot - лотность
 * - orderDimension - настройки в лотах или в штуках
 * - quantity - количество лотов/штук или сумма, взятое из инпута
 * - volumeType - тип объема (сумма или лоты/штуки)
 * - computedQuantity - количество лотов/штук
 * @returns строку с количеством лотов или штук
 * */
export function useConvertedValue(options: Options) {
  const { lot, orderDimension, quantity, volumeType, computedQuantity } =
    options;

  return useMemo(() => {
    if (volumeType === VolumeType.Volume) {
      if (orderDimension === OrderDimensionEnum.LOT) {
        return formatWithSuffix({
          value: lot * quantity,
          orderDimension: OrderDimensionEnum.PIECE,
        });
      }

      if (orderDimension === OrderDimensionEnum.PIECE && quantity % lot === 0) {
        const value = quantity / lot;

        return formatWithSuffix({
          value,
          orderDimension: OrderDimensionEnum.LOT,
        });
      }

      return '';
    }

    return formatWithSuffix({
      value:
        orderDimension === OrderDimensionEnum.LOT
          ? computedQuantity / lot
          : computedQuantity,
      orderDimension,
    });
  }, [volumeType, orderDimension, quantity, lot, computedQuantity]);
}
