import {
  DragCancelEvent,
  DragEndEvent,
  DragStartEvent,
  UniqueIdentifier,
} from '@dnd-kit/core';
import { HTMLAttributes } from 'react';

import {
  BaseWidgetConfig,
  TableColumnSetting,
  Widget,
} from '@terminal/core/types/layout';
import { TableColumnKey } from '@terminal/core/types/tableColumn';
import { CommonSettings } from '@terminal/core/types/ui';

import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';

export interface DoubleColumnSettingModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  columnsSetting: TableColumnSetting[];
  defaultColumnSetting: TableColumnSetting[];
  defaultTickerKey?: TableColumnKey;
  defaultNameKey?: TableColumnKey;
  updateNode: (
    nodeId: string,
    config: BaseWidgetConfig,
    newName?: string | undefined
  ) => void;
  nodeId?: string;
  widgetType: Widget;
  selectedDisplayInstrumentType: CommonSettings['defaultValues']['selectedDisplayInstrumentType'];
}

export enum Position {
  Before = -1,
  After = 1,
}

export type Column = TableColumnSetting & { id: UniqueIdentifier };

export type ItemProps = HTMLAttributes<HTMLDivElement> & {
  id: string;
  items: Column[];
  flatItems: TableColumnSetting[];
  columnsSettingOptions: TableColumnSetting[];
  index?: number;
  activeIndex?: number;
  withOpacity?: boolean;
  isDragging?: boolean;
  column?: Column;
  onRemove?: () => void;
  handleSelection: (
    columnId: UniqueIdentifier,
    setting?: TableColumnSetting,
    secondRow?: boolean
  ) => void;
  listeners?: SyntheticListenerMap;
  insertPosition?: Position;
  widgetType: Widget;
};

export interface ColumnSettingSelectProps {
  items: Column[];
  flatItems: TableColumnSetting[];
  columnsSettingOptions: TableColumnSetting[];
  column?: TableColumnSetting;
  handleSelection: (setting?: TableColumnSetting) => void;
  widgetType: Widget;
}

export interface SortableGridProps {
  items: Column[];
  flatItems: TableColumnSetting[];
  columnsSettingOptions: TableColumnSetting[];
  handleDragStart: (event: DragStartEvent) => void;
  handleDragEnd: (event: DragEndEvent) => void;
  handleDragCancel: (event: DragCancelEvent) => void;
  handleRemove: (columnId: UniqueIdentifier) => void;
  handleSelection: (
    columnId: UniqueIdentifier,
    setting?: TableColumnSetting,
    secondRow?: boolean
  ) => void;
  activeId: UniqueIdentifier | null;
  widgetType: Widget;
}
