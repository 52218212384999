import { TSide } from '../../types/common';
import { MarginParams } from '../../types/MarginParams';

/**
 * Вычисляет ГО (гарантийное обеспечение) для заданных параметров маржи, цены и side на основе параметров полученных от ручки (формула ниже по ссылке, пункт №7)
 * @see https://confluence.moscow.alfaintra.net/pages/viewpage.action?pageId=1458984395
 * @param {MarginParams} marginParams - Параметры маржи.
 * @param {number} price - Цена.
 * @param {TSide} side - Сторона.
 * @returns {number} ГО.
 * */
export const calculateMarginParamsWarranty = (
  marginParams: MarginParams,
  price: number,
  side: TSide
): number => {
  const { lot, priceStep, priceStepCost, short, long } = marginParams;
  const mk =
    (side === 'buy' && long.mkCurrent) ||
    (side === 'sell' && short.mkCurrent) ||
    0;

  return price * lot * (priceStepCost / priceStep) * mk;
};
