import { QueryFunctionContext, useInfiniteQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getClientAccounts } from '@terminal/core/lib/rest/lkMoney';
import { NotificationType } from '@terminal/core/types/ui';

import { Treaty, useTreaties } from '../../account';

type TreatiesFetcher = () => Promise<Treaty[]>;

type Options = {
  fetchTreaties?: TreatiesFetcher;
  /**
   * @default false
   * */
  withdraw?: boolean;
};

const FETCH_TREATIES_DEFAULT: TreatiesFetcher = async () => [];

export const useClientAccounts = (options: Options = {}) => {
  const { fetchTreaties = FETCH_TREATIES_DEFAULT, withdraw = false } = options;

  const addNotification = useNotification();
  const { data: treaties } = useTreaties(fetchTreaties);

  const firstTreaty = treaties?.length ? treaties[0].treaty : 0;

  const fetchAccounts = ({ pageParam = firstTreaty }: QueryFunctionContext) => {
    return getClientAccounts({ treaty: pageParam, withdraw });
  };

  return useInfiniteQuery(['lk', 'clientAccounts', withdraw], fetchAccounts, {
    getNextPageParam: (_, pages) => {
      if (treaties && treaties.length > pages.length) {
        return treaties[pages.length || 0].treaty || 0;
      }
    },
    enabled: Boolean(treaties) && treaties!.length > 0,
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка',
        text: error?.message || 'При запросе счетов произошла ошибка',
      });
    },
  });
};
