import { useCallback, useMemo, useState } from 'react';
import UAParser from 'ua-parser-js';

import { Dropdown } from '@terminal/common/components/Dropdown';
import { getNews, useNews } from '@terminal/common/entities/News';
import { useRemoteConfig } from '@terminal/common/entities/RemoteConfig';
import { useCoupons } from '@terminal/common/hooks/useCoupons';
import { useDividends } from '@terminal/common/hooks/useDividends';
import { useIssuer } from '@terminal/common/hooks/useIssuer';
import { APP_VERSION } from '@terminal/core/env';
import { useAppContext } from '@terminal/core/hooks/useAppContext';
import { useIssuerFinancials } from '@terminal/core/hooks/useIssuerFinancials';
import useNotification from '@terminal/core/hooks/useNotification';
import {
  track,
  trackDetailedOrderInfo,
  trackOrder,
  trackOrderEditSource,
  trackOrderEditType,
  trackOrderVolumeChanging,
  trackTradingFormOpened,
} from '@terminal/core/lib/analytics';
import {
  logWidget,
  logWidgetLayoutChange,
  useTriggerOnConditionOrFirstRender,
} from '@terminal/core/lib/analytics/performance';
import { FrontEndType } from '@terminal/core/lib/client/entities';
import { makeFeatureFlagConsumer } from '@terminal/core/lib/featureFlags';
import { getInstrumentIconURL } from '@terminal/core/lib/icons';
import { createMarginParamsFetcher } from '@terminal/core/lib/rest/fetchMarginParams';
import { shallow, useStore } from '@terminal/core/store';
import { selectIsConnectionError } from '@terminal/core/store/selectors';
import { objectTypesSelector } from '@terminal/core/store/selectors/core/objectTypesSelector';
import { Widget } from '@terminal/core/types/layout';
import { useTopUpModal } from '@terminal/desktop/shared/hooks/useTopUpModal';
import { cloudConfigHooks } from '@terminal/lk/features/CloudConfiguration';
import { useTreaties } from '@terminal/lk/legacy/hooks';
import { useMoneyHistory } from '@terminal/lk/legacy/transfer/hooks';
import { useTradeLimits, WidgetApi } from '@terminal/widgets';
import { AccountDropdown } from '@terminal/widgets/features/AccountDropdown';
import { PortfolioAnalytics } from '@terminal/widgets/widgets/PortfolioAnalytics';

import { featureFlags, setFeatureFlags } from '../lib/featureFlags';
import { useEnrollCertificate } from './useEnrollCertificate';
import { useLastAutoSavedConfig } from './useLastAutoSavedConfig';
import { useLayout } from './useLayout';
import { useOperationsHistory } from './useOperationsHistory';
import { useRejectCertificateEffect } from './useRejectCertificateEffect';

const useFeatureFlag = makeFeatureFlagConsumer(featureFlags);

const useSettings = () => useStore((state) => state.settings);
const useWatchLists = () => useStore((state) => state.watchLists);

const useConnectionError = () =>
  useStore(selectIsConnectionError(FrontEndType.OperServer));

const useInstrumentIcons = () =>
  useStore((state) => state.instrumentIcons, shallow);

const useLogWidgetPerformance = (widgetName: Widget, activeTab?: number) =>
  useMemo(() => logWidget(widgetName, activeTab), [activeTab, widgetName]);

const useLogWidgetUpdatePerformance = (
  widgetName: Widget,
  activeTab?: number
) =>
  useMemo(
    () => logWidgetLayoutChange(widgetName, activeTab),
    [activeTab, widgetName]
  );

const uaParser = new UAParser();
const OS = uaParser.getBrowser().name?.toLowerCase();

export const useWidgetProps = () => {
  useRemoteConfig(
    {
      packageName: 'web-trade',
      os: OS,
      appVersion: APP_VERSION,
    },
    setFeatureFlags
  );

  const [loadedWidgets, setLoadedWidgets] = useState<string[]>([]);

  const [updateNode, getActiveLayoutKey, setHeaderMenuOpen, setLkSubMenuId] =
    useStore(
      (state) => [
        state.updateNode,
        state.getActiveLayoutKey,
        state.setHeaderMenuOpen,
        state.setLkSubMenuId,
      ],
      shallow
    );

  const config = useLastAutoSavedConfig();

  const unlinkFromGroup = useStore((store) => store.unlinkFromGroup);
  const setTradeFeedSettings = useStore((store) => store.setTradeFeedSettings);
  const setSetting = useStore((store) => store.setSetting);

  const addNotification = useNotification();

  const [
    createWatchList,
    editWatchList,
    deleteWatchList,
    addToWatchList,
    deleteFromWatchList,
    addToWatchListMultiple,
    selectedDisplayInstrumentType,
    objectTypes,
  ] = useStore(
    (state) => [
      state.createWatchList,
      state.editWatchList,
      state.deleteWatchList,
      state.addToWatchList,
      state.deleteFromWatchList,
      state.addToWatchListMultiple,
      state.settings.defaultValues.selectedDisplayInstrumentType,
      objectTypesSelector(state),
    ],
    shallow
  );

  const { endpoints } = useAppContext();

  const getNewsCallback = useCallback(
    (params) => getNews(endpoints.news, params),
    [endpoints.news]
  );

  const marginParamsFetcher = useMemo(
    () => createMarginParamsFetcher(endpoints.marginParams),
    [endpoints.marginParams]
  );

  const onWidgetLoad = useCallback((key: string) => {
    setLoadedWidgets((loadedWidgets) => [...loadedWidgets, key]);
  }, []);

  const resetLoadedWidgets = useCallback(() => setLoadedWidgets([]), []);

  const openTopUpModal = useTopUpModal();

  const activeLayoutKey = getActiveLayoutKey();

  const { getNode, addWidget, cloneWidget, deleteWidget } = useLayout({
    activeLayoutKey,
  });

  const getTradesFeedSettings = useCallback<WidgetApi['getTradesFeedSettings']>(
    () => config.tradesFeedsSettings,
    [config]
  );

  const widgetProps: WidgetApi = useMemo(
    () => ({
      // watchlists
      useWatchLists,
      createWatchList,
      editWatchList,
      deleteWatchList,
      addToWatchList,
      deleteFromWatchList,
      addToWatchListMultiple,
      selectedDisplayInstrumentType,
      objectTypes,

      // system
      addNotification,
      // layout
      getActiveLayoutKey,
      unlinkFromGroup,
      addWidget,
      deleteWidget,
      cloneWidget,
      getNode,
      // settings
      updateNode,
      useEnrollCertificate,
      useRejectCertificateEffect,
      setSetting,
      useSettings,
      getTradesFeedSettings,
      // ui
      openTopUpModal,

      // other
      setTradeFeedSettings,
      useConnectionError,
      useInstrumentIcons,
      // fetch handler
      useMoneyHistory,
      useTreaties,
      useNews: (params) => useNews(getNewsCallback, params),
      useIssuer,
      useIssuerFinancials,
      useCoupons,
      useDividends,

      getInstrumentIconURL,

      loadedWidgets,
      onWidgetLoad,
      resetLoadedWidgets,

      analytics: {
        track,
        trackOrder,
        trackOrderEditSource,
        trackOrderEditType,
        trackTradingFormOpened,
        trackDetailedOrderInfo,
        trackOrderVolumeChanging,
      },
      useLogWidgetPerformance,
      useLogWidgetUpdatePerformance,
      useTriggerOnConditionOrFirstRender,
      marginParamsFetcher,
      DropdownComponent: Dropdown,
      useRemoteConfig,
      useFeatureFlag,
      useTradeLimits,
      cloudConfigHooks,
      setHeaderMenuOpen,
      setLkSubMenuId,
      PortfolioAnalyticsComponent: PortfolioAnalytics,
      AccountDropdownComponent: AccountDropdown,
      useOperationsHistory,
    }),
    [
      createWatchList,
      editWatchList,
      deleteWatchList,
      addToWatchList,
      deleteFromWatchList,
      addToWatchListMultiple,
      selectedDisplayInstrumentType,
      objectTypes,
      addNotification,
      getActiveLayoutKey,
      unlinkFromGroup,
      addWidget,
      deleteWidget,
      cloneWidget,
      getNode,
      updateNode,
      setSetting,
      getTradesFeedSettings,
      openTopUpModal,
      setTradeFeedSettings,
      loadedWidgets,
      onWidgetLoad,
      resetLoadedWidgets,
      marginParamsFetcher,
      setHeaderMenuOpen,
      setLkSubMenuId,
      getNewsCallback,
    ]
  );

  return widgetProps;
};
