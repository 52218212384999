import { Link } from '@alfalab/core-components/link';

import styles from './FormatedText.module.css';

interface FormatedTextProps {
  text: string;
}

/**
 * @deprecated Используйте версию компонента из '@terminal/lk-desktop-common'
 * */
export const FormatedText = ({ text }: FormatedTextProps) => (
  <div className={styles.text}>
    {text.split('\n').map((x, i) => {
      const match = x.match(/(?:https?|ftp):\/\/[^\s/$.?#].[^\s]*[^\s,.]/);

      if (match !== null) {
        const index = match.index;
        const textBefore = x.slice(0, match.index);
        const linkText = match[0];
        const textAfter = x.slice(
          (typeof index === 'undefined' ? 0 : index) + match[0].length
        );

        return [
          <span key={`before-link-${i}`}>{textBefore}</span>,
          <Link
            key={`link-${i}`}
            target="_blank"
            href={linkText}
            children={linkText}
          />,
          <span key={`after-link-${i}`}>{textAfter}</span>,
          <br key={`br-${i}`} />,
        ];
      } else {
        return [<span key={`row-${i}`}>{x}</span>, <br key={`br-${i}`} />];
      }
    })}
  </div>
);
