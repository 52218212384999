/**
 * Функция возвращает объект со значением, если переданное значение существует
 * @param key ключ для значения
 * @param value проверяемое значение
 * @param overrideValue перезаписываемое значение
 * */
export const addKeyIfExists = <K extends string, V, O = V>(
  key: K,
  value?: V,
  overrideValue?: O
): Record<K, V | O> | undefined =>
  value ? ({ [key]: overrideValue ?? value } as Record<K, V | O>) : undefined;
