import React from 'react';
import { Space } from '@alfalab/core-components/space';
import { Switch } from '@alfalab/core-components/switch';

import styles from './ToggleFilter.module.css';

type RadioSelectFilterProps = {
  toggleItem?: string;
  filterValue?: boolean;
  onChange: (value: boolean) => void;
};

export const ToggleFilter = (props: RadioSelectFilterProps) => {
  const { toggleItem, filterValue, onChange } = props;

  return (
    <Space size={0} className={styles.wrapper} fullWidth>
      <Switch
        checked={filterValue}
        label={toggleItem}
        onChange={() => onChange(!filterValue)}
        block
        reversed
      />
    </Space>
  );
};
