import debounce from 'lodash/debounce';
import { z } from 'zod';

import { IS_AI, IS_GI } from '../../../env';
import { getSettings, saveSettings } from '../../rest/lkSettings';
import { IDB } from '..';
import { SettingsZod_V3 } from './version3';

import {
  getSettingsFromLS,
  saveSettingsToLS,
} from '../../../store/slices/helpers/settingsHelper';
import { initialSettings } from '../../../store/slices/uiSlice';

import { AppState } from '../../../types/app';
import { CommonSettings } from '../../../types/ui';

export type SettingsTypeLast = z.infer<typeof SettingsZod_V3.object>;

export class Settings {
  static ACTUAL_VERSION = 3;
  static save = debounce((settings: CommonSettings) => {
    // @ts-ignore
    // TODO вынести логику про сохранение из useStore
    const { appState, credentials } = window.__zsstore.getState();

    if (appState !== AppState.READY || !credentials) {
      return;
    }

    const config = {
      ...settings,
      saveTimestamp: Date.now(),
    };

    if (IS_AI || Settings.ACTUAL_VERSION <= 2) {
      saveSettingsToLS(config);
    }

    if (IS_AI) {
      return;
    }

    if (appState !== AppState.READY) {
      return;
    }

    saveSettings(config, Settings.ACTUAL_VERSION);

    IDB.save<SettingsTypeLast>('settings', SettingsZod_V3.object, config);
  }, 100);

  /**
   * Определяем какой конфиг применить с сервера или из БД
   * */
  static async init() {
    let actualConfig: SettingsTypeLast = {
      ...getSettingsFromLS(),
      saveTimestamp: Date.now(),
    };

    try {
      if (IS_GI) {
        const [localResult, serverResult] = await Promise.allSettled([
          this.get(),
          this.getFromServer(),
        ]);

        const localData =
          localResult.status === 'fulfilled' && localResult.value;
        const serverData =
          serverResult.status === 'fulfilled' && serverResult.value;

        const localTimestamp = localData ? localData.saveTimestamp : 0;
        const serverTimestamp = serverData ? serverData.saveTimestamp : 0;

        if (serverTimestamp > localTimestamp) {
          if (serverData) {
            actualConfig = serverData;
          }
        } else {
          if (localData) {
            actualConfig = localData;
          }
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Settings init error: ', e);
    }

    return Settings.migration(actualConfig);
  }

  static migration(settingsPrev: SettingsTypeLast): CommonSettings {
    const settings = {
      saveTimestamp: settingsPrev?.saveTimestamp || 0,
      design: {
        ...initialSettings.design,
        ...settingsPrev?.design,
      },
      notifications: {
        ...initialSettings.notifications,
        ...settingsPrev?.notifications,
      },
      defaultValues: {
        ...initialSettings.defaultValues,
        ...settingsPrev?.defaultValues,
      },
      tickers: {
        ...settingsPrev?.tickers,
      },
    };

    return settings;
  }

  static async get() {
    return IDB.get<SettingsTypeLast>('settings');
  }

  static async getFromServer() {
    const data = await getSettings();

    if (data && data.hasOwnProperty('version')) {
      delete data.version;
    }

    return data;
  }
}
