import { ReactNode } from 'react';

export interface GetContentParams {
  isExpired: boolean;
  isAllowIssueCertificate: boolean;
  isSizeXs?: boolean;
}

export interface ContentResult {
  title: ReactNode;
  description: ReactNode;
  buttonText: string;
}

export const getContent = (params: GetContentParams): ContentResult => {
  const { isExpired, isAllowIssueCertificate, isSizeXs } = params;

  let title: ReactNode = (
    <>
      Необходим сертификат
      <br />
      электронной подписи
    </>
  );

  if (isExpired) {
    title = (
      <>
        Закончился срок действия
        <br />
        сертификата электронной подписи
      </>
    );
  }

  let description: ReactNode;

  if (isExpired) {
    description = (
      <>
        Срок действия вашего сертификата истек.
        <br />
        Необходимо выпустить новый.
        <br />
        Выпускается за минуту, действует год.
      </>
    );
  } else if (!isAllowIssueCertificate) {
    description = (
      <>
        Чтобы выпустить новый сертификат, отзовите ненужный,
        <br />
        выпущенный для другого браузера или устройства.
      </>
    );
  } else {
    description = (
      <>
        Без него не получится торговать на бирже
        <br />
        и работать с личным кабинетом.
        <br />
        Выпускается за минуту, действует год.
      </>
    );
  }

  let buttonText: string;

  if (isSizeXs) {
    buttonText = 'Выпустить';
  } else if (!isAllowIssueCertificate || isExpired) {
    buttonText = 'Отозвать и выпустить новый';
  } else {
    buttonText = 'Выпустить сертификат';
  }

  return {
    title,
    description,
    buttonText,
  };
};
