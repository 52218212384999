import { FeatureSetting } from '../models/types/FeatureSetting';

/**
 * Проверяет спрятана ли фича из меню. При отсутствии id в списке - считается видимой
 * @param name - id фича-тогла
 * @param settings - список фич с бэкенда
 * */
export function isFeatureHidden(
  name: string,
  settings: FeatureSetting[] | undefined
): boolean {
  if (!settings) {
    return true;
  }

  return Boolean(
    settings && settings.find((s) => s.featureName === name && s.hideFromMenu)
  );
}
