import groupBy from 'lodash/groupBy';
import { useMemo } from 'react';

import { useAlfaDirectContext } from '@terminal/alfadirect/provider/react';

import { useFilteredAccounts } from './useFilteredAccounts';

import { SubAccountRazdelItem } from '../../types/subAccountRazdel';

// Фильтруем субсчета-инвесткопилки
export const useFilteredSubAccounts = () => {
  const { subAccountRazdels } = useAlfaDirectContext();
  const filteredAccountsMap = useFilteredAccounts();

  return useMemo(() => {
    const filteredAccountIds = Object.keys(filteredAccountsMap);
    // Счета приходят не сразу, так что фильтруем undefined
    const filteredRazdels = subAccountRazdels.filter((item) => item);
    const subAccounts = groupBy(filteredRazdels, 'codeSubAccount');

    const result: Record<string, SubAccountRazdelItem[]> = {};

    Object.keys(subAccounts).forEach((subAccountKey) => {
      const isInvestPiggyBank = !filteredAccountIds.includes(
        String(subAccounts[subAccountKey][0]?.idAccount)
      );

      if (!isInvestPiggyBank) {
        result[subAccountKey] = subAccounts[subAccountKey];
      }
    });

    return result;
  }, [filteredAccountsMap, subAccountRazdels]);
};
