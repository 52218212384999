import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';

import { Dropdown } from '@terminal/common/components/Dropdown';
import { PUShowMode } from '@terminal/core/types/subAccountPosition';

import { FILTERS_BY_PU } from '../../const/filters';

interface Props {
  selectedPUMode: PUShowMode;
  savePUMode: (selectedPUMode: PUShowMode) => void;
}

export const PUOptions = (props: Props) => {
  const { selectedPUMode, savePUMode } = props;

  return (
    <>
      {FILTERS_BY_PU.map((props) => {
        const { name, mode } = props;

        const checked = mode === selectedPUMode;

        return (
          <Dropdown.Item
            key={name}
            rightIcon={
              checked && (
                <CheckmarkMIcon
                  color="var(--color-light-graphic-secondary)"
                  height={16}
                  width={16}
                />
              )
            }
            content={
              <Typography.Text view="secondary-large">{name}</Typography.Text>
            }
            onClick={() => {
              if (!checked && mode !== PUShowMode.ANY) {
                savePUMode(mode);
              } else {
                savePUMode(PUShowMode.ANY);
              }
            }}
          />
        );
      })}
    </>
  );
};
