import { isBefore } from 'date-fns';
import { ComponentType, memo, ReactNode, useState } from 'react';

import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';
import { MAX_CERTIFICATES_COUNT } from '@terminal/core/constants/certificates';
import { CertificateStateType } from '@terminal/core/lib/client/entities';
import { CertificateEnrollStep } from '@terminal/core/types/certificate';

// eslint-disable-next-line custom-eslint-plugins/fsd-layer-imports
import { CertificateEnrollModal } from '../../../CertificateEnroll';
import {
  ContentResult,
  getContent as defaultGetContent,
  GetContentParams,
} from '../../lib/getContent';
import { CustomStubProps, DefaultStubComponent } from '../DefaultStubComponent';

interface Props {
  children?: ReactNode;
  hideMode?: boolean; // Когда нужно просто скрыть элемент и ничего не показывать
  size?: 's' | 'xs';
  fullHeight?: boolean;
  CustomComponent?: ComponentType<CustomStubProps>;
  getContent?: (params: GetContentParams) => ContentResult;
}

/**
 * HOC который не рендерит чилдренов и вместо них выводит
 * кнопку выпустить сертификать, если у пользователя нет валидного сертификата
 * <TradingCertificateLock>
 * <MyAwesomeTradeForm /> <--- не отрендерится если нет серта
 * </TradingCertificateLock>
 * На компонент есть интеграционный тест /src/mobile/components/__tests__/CertificateProvider.tsx
 * */
export const TradingCertificateLock = memo((props: Props) => {
  const {
    children,
    hideMode,
    size = 's',
    fullHeight = false,
    CustomComponent = DefaultStubComponent,
    getContent = defaultGetContent,
  } = props;
  const isSizeXs = size === 'xs';

  const [certificateEnrollModalVisible, setCertificateEnrollModalVisible] =
    useState<boolean>(false);

  const { useCertificates, useCurrentCertificate } = useAlfaDirectContext();
  const certificates = useCertificates();
  const { workingCertificate, certificateEnrollStep } = useCurrentCertificate();
  const isExpired = isBefore(
    workingCertificate?.parsedPayload?.endDate || new Date(),
    new Date()
  );
  const isAllowIssueCertificate = certificates.length < MAX_CERTIFICATES_COUNT;

  const tradingEnabled =
    workingCertificate &&
    workingCertificate.state === CertificateStateType.Ready &&
    !isExpired;

  if (tradingEnabled) {
    return <>{children}</>;
  }

  if (hideMode) {
    return null;
  }

  const { title, description, buttonText } = getContent({
    isAllowIssueCertificate,
    isExpired,
    isSizeXs,
  });

  const isDisabled =
    certificateEnrollStep === CertificateEnrollStep.InProgress ||
    certificateEnrollStep === CertificateEnrollStep.Success;

  return (
    <>
      <CustomComponent
        title={title}
        isFullHeight={fullHeight}
        description={description}
        isAllowIssueCertificate={isAllowIssueCertificate}
        isCertificateExpired={isExpired}
        buttonText={buttonText}
        onActionClick={() => setCertificateEnrollModalVisible(true)}
        isDisabled={isDisabled}
        size={size}
      />
      <CertificateEnrollModal
        isOpen={certificateEnrollModalVisible}
        onClose={() => setCertificateEnrollModalVisible(false)}
      />
    </>
  );
});

TradingCertificateLock.displayName = 'TradingCertificateLock';
