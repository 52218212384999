import cn from 'classnames';
import {
  BottomSheet as BottomSheetOriginal,
  BottomSheetProps,
} from '@alfalab/core-components/bottom-sheet';

import styles from './BottomSheet.module.css';

/**
 * @deprecated используйте `BottomSheet` из `@alfalab/core-components/bottom-sheet`
 * */
export const BottomSheet = (props: BottomSheetProps) => {
  const { className, ...restProps } = props;

  return (
    <BottomSheetOriginal
      className={cn(styles.customBottomSheet, className)}
      headerClassName={styles.customHeader}
      containerClassName={styles.container}
      {...restProps}
    />
  );
};
