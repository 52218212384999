import { useCallback, useMemo } from 'react';

import { LK_CERTIFICATES } from '@terminal/core/env';
import { useCertificateEnrollFlow } from '@terminal/core/hooks/useCertificate';
import { shallow, useStore } from '@terminal/core/store';
import { SingletonModalType } from '@terminal/core/store/slices/certificatesSlice';
import { CertificateSMSConfirmModal } from '@terminal/lk/legacy/components/CertificateSMSConfirmModal';

import { CodeConfirmationModalOld } from './ui/CodeConfirmationModalOld';
import { NeedCertificateModal } from './ui/NeedCertificateModal';

export const SingletonModalProvider = () => {
  useCertificateEnrollFlow();

  const {
    certificateId,
    modalVisible,
    hideCertificateSMSWindow,
    singletonModalType,
  } = useStore(
    (state) => ({
      certificateId: state.certificateEnrollId,
      modalVisible: state.certificateSMSWindowProps.isVisible,
      hideCertificateSMSWindow: state.hideCertificateSMSWindow,
      singletonModalType: state.singletonModalType,
    }),
    shallow
  );

  const modal = useMemo(() => {
    switch (singletonModalType) {
      case SingletonModalType.NeedCertificate: {
        return <NeedCertificateModal />;
      }

      default: {
        return null;
      }
    }
  }, [singletonModalType]);

  const onModalCloseRequest = useCallback(() => {
    hideCertificateSMSWindow();
  }, [hideCertificateSMSWindow]);

  const confirmModal = useMemo(() => {
    if (LK_CERTIFICATES) {
      return <CertificateSMSConfirmModal />;
    } else {
      return (
        <CodeConfirmationModalOld
          isOpened={modalVisible}
          onCloseRequest={onModalCloseRequest}
          certificateId={certificateId}
        />
      );
    }
  }, [modalVisible, onModalCloseRequest, certificateId]);

  return (
    <>
      {modal}
      {confirmModal}
    </>
  );
};
