import { createContext, useContext } from 'react';

import { LkContextStore } from './types/LkContextStore';

export const defaultContext: LkContextStore = {
  init: undefined,
  addNotification: () => ({}),
  workingCertificate: null,
};

export function createLkContext() {
  const CreatedLkContext = createContext<LkContextStore>(defaultContext);

  const useCreatedLkContext = () => useContext(CreatedLkContext);

  return {
    ...CreatedLkContext,
    LkContext: CreatedLkContext,
    useLkContext: useCreatedLkContext,
  };
}

export const { Provider, Consumer, LkContext, useLkContext } =
  createLkContext();
