import { useState } from 'react';
import { Amount, CurrencyCodes } from '@alfalab/core-components/amount';
import { Link } from '@alfalab/core-components/link';
import { Spinner } from '@alfalab/core-components/spinner';
import { Typography } from '@alfalab/core-components/typography';

import { FunctionalModalSidebar } from '@terminal/common/components/FunctionalModal';
import { Portal } from '@terminal/common/components/Portal';
import { MINORITY } from '@terminal/core/constants/ui';
import { MoneyLimitResult } from '@terminal/core/lib/rest/lkMoney';

import { getLimit } from '../../lib';

import styles from './MoneyLimits.module.css';

interface MoneyLimitsProps {
  limits: MoneyLimitResult | undefined;
  currency: string;
  pending: boolean;
  marginAvailable: boolean;
  backLabel?: string;
}

export const MoneyLimits = (props: MoneyLimitsProps) => {
  const { limits, currency, pending, marginAvailable, backLabel } = props;

  const [open, setOpen] = useState(false);

  if (pending) {
    return <Spinner visible />;
  }

  if (!limits) {
    return null;
  }

  const withMargin = marginAvailable && limits.marginalOn;

  // Если пришли лимиты и пользователь удовлетворяет устовиям маржинального перевода,
  // но маржинальный лимит равен обычному - показываем сообщение о временной недоступности
  if (withMargin && limits.limit && limits.limit === limits.marginalLimit) {
    return (
      <>
        <Link onClick={() => setOpen(true)} view="secondary">
          Вывод с плечом временно недоступен
        </Link>
        <Portal id="money-transfer-modals">
          <FunctionalModalSidebar
            open={open}
            backLabel={backLabel}
            onClose={() => setOpen(false)}
          >
            <Typography.Text className={styles.title} weight="bold" tag="p">
              Вывод с плечом временно недоступен
            </Typography.Text>
            <Typography.Text
              className={styles.text}
              tag="div"
              view="secondary-small"
            >
              <ol className={styles.list}>
                <li>
                  На вашем брокерском счёте нет или недостаточно ликвидных
                  активов, чтобы обеспечить вывод с «плечом». Такими активами
                  выступают денежные средства в рублях или долларах и акции
                  «голубых фишек» — полный список можно найти в разделе
                  «Торговать с плечом» на сайте Альфа-Инвестиций.
                </li>
                <li>
                  На вашем брокерском счёте нет или недостаточно ликвидных
                  активов, чтобы обеспечить вывод с «плечом». Такими активами
                  выступают денежные средства в рублях или долларах и акции
                  «голубых фишек» — полный список можно найти в разделе
                  «Торговать с плечом» на сайте Альфа-Инвестиций.
                </li>
                <li>
                  Вы пытались вывести средства в нерабочее время. Вывести
                  средства с «плечом» можно по рабочим дням с 9:00 до 19:00 мск
                  при наличии расчётов на бирже.
                </li>
                <li>
                  Вы пытались вывести средства во время технологического
                  перерыва. Попробуйте повторить попытку через 15 минут.
                </li>
              </ol>
            </Typography.Text>
          </FunctionalModalSidebar>
        </Portal>
      </>
    );
  }

  const label = withMargin ? 'Доступно с плечом: ' : 'Доступно: ';
  const limitValue = getLimit(limits, marginAvailable);

  return (
    <Typography.Text view="primary-small" className={styles.available}>
      {label}
      <Amount
        className={styles.amount}
        value={limitValue * MINORITY}
        minority={MINORITY}
        // TODO: поправить в ответе с бэка
        currency={currency as CurrencyCodes}
        bold="none"
      />
    </Typography.Text>
  );
};
