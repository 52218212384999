import { Typography } from '@alfalab/core-components/typography';

import { BaseWidgetConfig, Widget } from '@terminal/core/types/layout';

import { useWidgetTab } from '../../hooks';

import styles from './HeaderContent.module.css';

interface Props {
  component: Widget;
  config: BaseWidgetConfig | null;
  name: string;
}

export const HeaderContent = (props: Props) => {
  const { component, config, name: widgetName } = props;
  const { name, subName } = useWidgetTab(component, config, widgetName);

  return (
    <div className={styles.widgetNameWrapper}>
      <Typography.Text view="secondary-large" weight="bold">
        {name}
      </Typography.Text>
      <Typography.Text view="secondary-large" weight="medium" color="secondary">
        {subName}
      </Typography.Text>
    </div>
  );
};
