import { Button } from '@alfalab/core-components/button/modern';
import { useConfirmation } from '@alfalab/core-components/confirmation/utils';
import { Spinner } from '@alfalab/core-components/spinner';

import { Confirmation } from '@terminal/common/components/Confirmation';
import { useLogin2faForm } from '@terminal/lk-core';

import styles from './Login2faForm.module.css';

export const Login2faForm = () => {
  const confirmation = useConfirmation();

  const {
    authPending,
    confirmationScreen,
    confirmationState,
    formatedPhone,
    overlimitCooldown,
    blockDuration,
    error,
    lkAuthError,
    tempBlockDescription,
    validate2faCode,
    resend2faSms,
    unblock,
    logout,
    setConfirmationScreen,
    setConfirmationState,
  } = useLogin2faForm(confirmation);

  return authPending ? (
    <div className={styles.spinnerContainer}>
      <Spinner visible size="m" />
    </div>
  ) : (
    <div className={styles.loginForm}>
      <Confirmation
        screen={confirmationScreen}
        state={confirmationState}
        onChangeState={setConfirmationState}
        onChangeScreen={setConfirmationScreen}
        phone={formatedPhone}
        alignContent="center"
        countdownDuration={overlimitCooldown}
        tempBlockDuration={blockDuration}
        texts={{
          codeError: error || lkAuthError,
          tempBlockDescription: tempBlockDescription,
        }}
        onInputFinished={validate2faCode}
        onSmsRetryClick={resend2faSms}
        onTempBlockFinished={unblock}
      />

      <div className={styles.cancel}>
        <Button
          block
          view="secondary"
          type="button"
          size="m"
          onClick={() => logout()}
        >
          Отмена
        </Button>
      </div>
    </div>
  );
};
