import { arrayBufferToString, toBase64 } from 'pvutils';
import { encode } from 'single-byte';

import { createNotification } from '../../lib/notifications';
import { createCMS } from '../crypto/cms';
import { getRSAKey } from '../db/rsaKeys';
import { fetchLk, fetchLkResult, HttpMethod, LKResult } from './lkApi';

import { useStore } from '../../store';
import { LkSignOptions } from '../../store/slices/lkSignSlice';

import { Certificate } from '../../types/certificate';
import { NotificationType } from '../../types/ui';

const MAX_SIGN_STATUS_CHECK_COUNT = 120;

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export interface SignCreated extends LKResult {
  url: string;
  id: string;
  data?: any;
}

/**
 * @deprecated Нужно использовать механизм подписи `useSignApi` из `@terminal/lk-core` на основе независимого стора
 * */
export async function signOperation(
  url: string,
  method: HttpMethod = 'GET',
  body?: object,
  options?: LkSignOptions,
  customHeaders?: Headers
) {
  try {
    const created = await fetchLkResult<SignCreated>(
      url,
      method,
      body,
      false,
      customHeaders
    );
    const confirmCodes = options?.confirmation?.codes || [];
    const needConfirmation = confirmCodes.indexOf(created.code) !== -1;

    if (created.code !== 0 && !needConfirmation) {
      if (options?.errorCallback) {
        options.errorCallback({
          code: created.code || 0,
          message: created.message || '',
        });
      }

      throw new Error(created.message || '');
    }

    const store = useStore.getState();

    if (needConfirmation) {
      store.openLkSignConfirmation(created.message || '');
    } else {
      store.openLkSignDialog();
    }

    store.setLkSignOperation(created);

    if (options) {
      store.setLkSignOptions(options);
    }

    return created;
  } catch (err: any) {
    const store = useStore.getState();

    if (!options?.muteError) {
      store.addNotification(
        createNotification({
          badge: 'negative',
          title: options?.errorTitle || 'Ошибка',
          text: err?.message || '',
        })
      );
    }

    store.closeLkSignOperation();
  }
}

export interface ServerCertsShort {
  thumbPrint: string;
  name: string;
  cryptoProvider: 'RSA';
}

export interface SigningTextResponse {
  message: string;
  messages?: { [s: string]: string };
  certificates: ServerCertsShort[];
  operationType: string;
}

export async function requestSignText(operationUrl: string) {
  try {
    const signText = await fetchLkResult<SigningTextResponse>(operationUrl);

    // TODO: реализовать сверку рабочего сертификата со списком из ЛК
    useStore.getState().setLkSignOperationText(signText);
  } catch (err: any) {
    const store = useStore.getState();
    const options = store.lkSignOptions;

    if (!options?.muteError) {
      store.addNotification(
        createNotification({
          badge: 'negative',
          title: options?.errorTitle || 'Ошибка',
          text: err?.message || 'Ошибка запроса поручения',
        })
      );
    }

    useStore.getState().closeLkSignOperation();
  }
}

export interface AssignmentBody {
  signedMessage: string;
  signedMessageWindows1251?: string;
}

export async function signMessage(
  assignment: string,
  certificate: Certificate
): Promise<AssignmentBody> {
  const rsaKeys = await getRSAKey(certificate?.idCertificate);

  if (!rsaKeys) {
    throw new Error('Не найден приватный ключ для выбранного сертификата');
  }

  const encoder = new TextEncoder();
  const assignmentUtf8 = encoder.encode(assignment);
  const assignmentWin1251 = encode('windows-1251', assignment);

  const signedAssignment = await createCMS(
    assignmentUtf8,
    certificate.certificate,
    rsaKeys.keys.privateKey,
    'SHA-512'
  );
  const signedAssignmentWin1251 = await createCMS(
    assignmentWin1251,
    certificate.certificate,
    rsaKeys.keys.privateKey,
    'SHA-512'
  );

  return {
    signedMessage: toBase64(arrayBufferToString(signedAssignment)),
    signedMessageWindows1251: toBase64(
      arrayBufferToString(signedAssignmentWin1251)
    ),
  };
}

export enum SignOperationStatus {
  New = 0,
  Read = 1,
  Accepted = 2,
  Failed = 3,
  Cancelled = 4,
  Executed = 5,
  CheckSignFailed = 6,
  SignChecked = 7,
  FailedOnExecution = 8,
  SqlCodeRunning = 9,
}

export type SignOrderStatus = 'success' | 'failed' | 'waiting' | 'separated';

export interface SignOperationStatusResponse<
  D = { orderStatus?: SignOrderStatus }
> {
  operationStatus: SignOperationStatus;
  additionalMessage: string | null;
  operationType?: string;
  data?: D;
}

export interface SignOperationResponse {
  responseMessage: 'Success' | string;
  operationType: string;
}

export interface SendAssignmentRequest {
  assignment: string;
  messages?: { [s: string]: string };
  operationUrl: string;
}

export async function sendAssignment(params: SendAssignmentRequest) {
  const { assignment, messages, operationUrl } = params;
  const store = useStore.getState();
  // Проверяем статус операции
  let status: SignOperationStatusResponse = {
    operationStatus: SignOperationStatus.Read,
    additionalMessage: null,
  };

  try {
    const workingCertificate = store.workingCertificate;

    if (!workingCertificate) {
      throw new Error('Не найден валидный сертификат');
    }

    // Подпись составного поручения
    const errors: SignOperationResponse[] = [];
    const messagesCount = messages ? Object.keys(messages).length : 0;

    if (messages) {
      let currentNumber: number = 0;

      store.setLkSignProgress({
        count: messagesCount,
        signed: currentNumber,
      });

      for (let messageId in messages) {
        const signed = await signMessage(
          messages[messageId],
          workingCertificate
        );
        const serializedCommand = {
          key: messageId,
          signedMessage: signed.signedMessage,
        };

        const res = await fetchLkResult<SignOperationResponse>(
          `${operationUrl}/multiple`,
          'PUT',
          { serializedCommand: JSON.stringify(serializedCommand) }
        );

        if (res.responseMessage !== 'Success') {
          errors.push(res);
        }

        store.setLkSignProgress({
          count: messagesCount,
          signed: ++currentNumber,
        });
      }
    }

    const serializedCommand = await signMessage(assignment, workingCertificate);

    await fetchLkResult<SignOperationResponse>(String(operationUrl), 'PUT', {
      serializedCommand: JSON.stringify(serializedCommand),
    });

    for (
      let i = 0, done = false;
      i < MAX_SIGN_STATUS_CHECK_COUNT && !done;
      i++
    ) {
      status = await fetchLkResult<SignOperationStatusResponse>(
        `${operationUrl}/status`
      );

      switch (status.operationStatus) {
        // Успешное завершение
        case SignOperationStatus.Executed:
          done = true;
          break;
        // Ошибка подписи
        case SignOperationStatus.Failed:
        case SignOperationStatus.CheckSignFailed:
        case SignOperationStatus.FailedOnExecution:
          throw new Error(status.additionalMessage || '');
        // Отмена
        case SignOperationStatus.Cancelled:
          done = true;
          break;
        // Ждем дальше
        default:
          await sleep(1000);
      }
    }

    if (errors.length > 0) {
      store.setLkSignMultipleErrors(errors.map((err) => err.responseMessage));
    }

    if (errors.length === 0) {
      // ошибок не было, можно закрывать окно
      store.closeLkSignOperation();
    }

    const signOptions = store.lkSignOptions;

    if (messagesCount > 0 && errors.length === messagesCount) {
      store.addNotification(
        createNotification({
          badge: 'negative',
          title: signOptions?.errorTitle || 'Все поручения завершились ошибкой',
          text: signOptions?.errorText,
        })
      );
    } else {
      store.addNotification(
        createNotification({
          type: NotificationType.SYSTEM,
          badge: 'positive',
          title: signOptions?.successTitle || 'Поручение подписано',
          text: signOptions?.successText,
        })
      );

      if (signOptions?.successCallback) {
        signOptions.successCallback(status);
      }
    }
  } catch (err: any) {
    const signOptions = store.lkSignOptions;

    signOptions?.failureCallback?.(status);

    if (signOptions?.errorCallback) {
      signOptions.errorCallback({
        code: err.code || 0,
        message: err.message || '',
      });
    } else {
      if (!signOptions?.muteError) {
        store.addNotification(
          createNotification({
            badge: 'negative',
            title: signOptions?.errorTitle || 'Ошибка',
            text:
              signOptions?.errorText ||
              err?.message ||
              'Ошибка подписи поручения',
          })
        );
      }
    }

    store.closeLkSignOperation();
  }
}

export async function cancelSignOperation(operationUrl: string) {
  return fetchLk(operationUrl, 'DELETE');
}
