import {
  AccountOption,
  SubAccountOption,
} from '@terminal/common/model/types/account';
import { formatFullNameWithInitials } from '@terminal/common/shared/lib/formatFullNameWithInitials';
import { Treaty } from '@terminal/core/lib/rest/lkCommon';
import { MarginCallInfo } from '@terminal/core/types/marginCall';
import { SubAccountRazdelItem } from '@terminal/core/types/subAccountRazdel';

import { getRequirementBySubAccount } from '../lib/getRequirementBySubAccount';
import { isIisAccount } from '../lib/isIisAccount';

import { AccountsTree } from '../model/types';

export function createOptionsForSelect(
  accountsTree: AccountsTree,
  selectedSubAccounts: string[],
  searchText: string,
  marginCall: MarginCallInfo,
  withClientName: boolean,
  treaties?: Treaty[]
) {
  const accountsOptions: AccountOption[] = [];

  Object.keys(accountsTree).forEach((accountKey: string) => {
    const currentSubAccounts: SubAccountRazdelItem[] = accountsTree[accountKey];
    const subAccountsOfAccount = accountsTree[accountKey].map(
      (subAccount) => subAccount.codeSubAccount
    );
    const subAccountsInSelected = selectedSubAccounts.filter((subAccount) =>
      subAccountsOfAccount.includes(subAccount)
    );
    const isEverySubAccountSelected =
      subAccountsOfAccount.length === subAccountsInSelected.length;
    const isAnySubAccountSearchedFor = subAccountsOfAccount.find((subAccount) =>
      subAccount.includes(searchText)
    );
    const isAccountSearchedFor = !searchText || accountKey.includes(searchText);

    if (isAccountSearchedFor || isAnySubAccountSearchedFor) {
      const subAccountsOptions: SubAccountOption[] = [];

      currentSubAccounts.forEach((subAccount: SubAccountRazdelItem) => {
        const { codeSubAccount } = subAccount;
        const isSelected = selectedSubAccounts.includes(codeSubAccount);
        const isSubAccountSearchedFor =
          !searchText || codeSubAccount.includes(searchText);

        const requirement = getRequirementBySubAccount(
          marginCall,
          codeSubAccount
        );

        if (isSubAccountSearchedFor) {
          subAccountsOptions.push({
            isAccount: false,
            key: codeSubAccount,
            value: codeSubAccount,
            requirement,
            isSelected,
          });
        }
      });

      const accountTreaty = treaties?.find(
        ({ treaty }) => treaty.toString() === accountKey.split('-')[0]
      );

      accountsOptions.push({
        isAccount: true,
        key: accountKey,
        value: accountKey,
        isEverySubAccountSelected,
        subAccounts: subAccountsOptions,
        ownerName: withClientName
          ? formatFullNameWithInitials(currentSubAccounts[0].nameClient)
          : '',
        isIis: isIisAccount(accountTreaty),
      });
    }
  });

  return accountsOptions;
}
