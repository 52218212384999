interface props {
  nominalCurrencyPrice: number;
  marketCurrencyPrice: number;
  price: number;
  nominal: number;
  quantity: number;
  isLotCount: boolean;
  lot: number;
  nkd: number;
}

/**
 * Формула для получения суммы из количества штук/лотов в облигациях
 * @param options - параметры для формулы:
 * - marketCurrencyPrice - курс валюты номинала по отношению к рублю
 * - nominalCurrencyPrice - курс валюты рынка по отношению к рублю
 * - price - цена облигации. Для заявки STL price  = цена + проскок при покупке / цена - проскок при продаже. Для заявок BSL, BRS, TBRS  price = цене тейк профита, для MKT price = bid или ask в зависимости от направления сделки.
 * - nominal - номинал облигации
 * - quantity - количество лотов
 * - isLotCount - измеряется ли количество в лотах
 * - nkd - accruedInt из FinInfoExtEntity
 * - lot - количество штук в лоте
 * @returns Сумму в заявке
 * */
export const getCashForBonds = (options: props) => {
  const {
    nominalCurrencyPrice,
    marketCurrencyPrice,
    price,
    nominal,
    quantity,
    isLotCount,
    lot,
    nkd,
  } = options;

  const result = Math.ceil(
    (nominalCurrencyPrice / marketCurrencyPrice) *
      ((price * nominal) / 100 + nkd) *
      quantity *
      (isLotCount ? lot : 1)
  );

  // проверка на NaN и Infinity
  return Number.isFinite(result) ? result : 0;
};
