/**
 *	8.6.0
 *	Generation date: 2022-03-09T16:49:00.118Z
 *	Client name: alfabank
 *	Package Type: Technical Analysis e98f22c
 *	License type: annual
 *	Expiration date: "2024/01/02"
 *	Domain lock: ["127.0.0.1","localhost","alfadirect.ru","alfabank.ru","adirterminal.netlify.app"]
 *	iFrame lock: true
 */

/***********************************************************
 * Copyright by ChartIQ, Inc.
 * Licensed under the ChartIQ, Inc. Developer License Agreement https://www.chartiq.com/developer-license-agreement
 *************************************************************/
/*************************************** DO NOT MAKE CHANGES TO THIS LIBRARY FILE!! **************************************/
/* If you wish to overwrite default functionality, create a separate file with a copy of the methods you are overwriting */
/* and load that file right after the library has been loaded, but before the chart engine is instantiated.              */
/* Directly modifying library files will prevent upgrades and the ability for ChartIQ to support your solution.          */
/*************************************************************************************************************************/

/***************************************************************************/
/* Please note that manually changing the domain list or expiration dates  */
/*                                                                         */
/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>> WILL NOT <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< */
/*                                                                         */
/*   modify the library locking mechanism. Any changes must be requested   */
/*                          directly from chartIQ.                         */
/***************************************************************************/

(function () {
  /* eslint-disable no-undef-init */
  var trialExpiration = undefined;
  var licenseExpiration = '2024/01/02';
  var domains = [
    '127.0.0.1',
    'localhost',
    'alfadirect.ru',
    'alfabank.ru',
    'adirterminal.netlify.app',
  ];
  var filesystem = false;
  var expiration;

  if (trialExpiration) expiration = new Date(trialExpiration).getTime();
  else if (licenseExpiration)
    expiration = new Date(licenseExpiration).getTime();

  if (expiration) {
    var now = new Date().getTime();

    if (expiration <= now) {
      // if (trialExpiration) alert("This license has expired!");
      // console.error("This license has expired!");
    } else if (trialExpiration) {
      var diffDays = Math.round((expiration - now) / (1000 * 60 * 60 * 24));

      if (diffDays < 3) {
        // alert("This trial license expires in " + diffDays + " days!");
        // console.log("WARNING: This trial license expires in " + diffDays + " days!");
      }
    }
  }
  if (typeof document !== 'undefined') {
    if (filesystem === true && document.location.protocol == 'file:') {
      return; // valid environment, skip domain check
    }
    if (domains && domains.length > 0) {
      var href = document.location.href;
      var found = false;

      for (var i = 0; i < domains.length; i++) {
        if (href.indexOf(domains[i]) > -1) {
          found = true;
          break;
        }
      }

      if (!found) {
        // alert("ERROR: Not licensed for domain " + href);
        // console.error("ERROR: Not licensed for domain " + href);
      }
    }
  }
})();

/* eslint-disable no-extra-parens */

/* eslint-disable */ /* jshint ignore:start */ /* ignore jslint start */
v7ZLk[68028] = (function () {
  var S = 2;
  for (; S !== 9; ) {
    switch (S) {
      case 5:
        var J;
        try {
          var X = 2;
          for (; X !== 6; ) {
            switch (X) {
              case 2:
                Object[
                  '\x64\u0065\u0066\x69\x6e\u0065\u0050\x72\x6f\x70\u0065\u0072\u0074\u0079'
                ](
                  Object['\x70\x72\u006f\u0074\x6f\x74\u0079\x70\u0065'],
                  '\x6e\u0073\u004f\x44\u0075',
                  {
                    '\x67\x65\x74': function () {
                      var O = 2;
                      for (; O !== 1; ) {
                        switch (O) {
                          case 2:
                            return this;
                            break;
                        }
                      }
                    },
                    '\x63\x6f\x6e\x66\x69\x67\x75\x72\x61\x62\x6c\x65': true,
                  }
                );
                J = nsODu;
                X = 5;
                break;
              case 5:
                J['\x41\x76\x6b\u004b\x5f'] = J;
                X = 4;
                break;
              case 9:
                delete J['\u0041\x76\u006b\x4b\u005f'];
                var E =
                  Object['\x70\x72\u006f\u0074\u006f\u0074\u0079\x70\x65'];
                delete E['\x6e\x73\x4f\u0044\u0075'];
                X = 6;
                break;
              case 3:
                throw '';
                X = 9;
                break;
              case 4:
                X =
                  typeof AvkK_ ===
                  '\x75\u006e\x64\x65\u0066\u0069\x6e\x65\u0064'
                    ? 3
                    : 9;
                break;
            }
          }
        } catch (V) {
          J = window;
        }
        return J;
        break;
      case 1:
        return globalThis;
        break;
      case 2:
        S = typeof globalThis === '\x6f\x62\x6a\x65\x63\u0074' ? 1 : 5;
        break;
    }
  }
})();
H8f(v7ZLk[68028]);
v7ZLk.H9P = function () {
  return typeof v7ZLk[313396].W0mMTaO === 'function'
    ? v7ZLk[313396].W0mMTaO.apply(v7ZLk[313396], arguments)
    : v7ZLk[313396].W0mMTaO;
};
v7ZLk.v7o = function () {
  return typeof v7ZLk[580324].g$F$otX === 'function'
    ? v7ZLk[580324].g$F$otX.apply(v7ZLk[580324], arguments)
    : v7ZLk[580324].g$F$otX;
};
v7ZLk.D_N = function () {
  return typeof v7ZLk[316775].W0voEbF === 'function'
    ? v7ZLk[316775].W0voEbF.apply(v7ZLk[316775], arguments)
    : v7ZLk[316775].W0voEbF;
};
v7ZLk.t47 = function () {
  return typeof v7ZLk[316775].M5qrVrU === 'function'
    ? v7ZLk[316775].M5qrVrU.apply(v7ZLk[316775], arguments)
    : v7ZLk[316775].M5qrVrU;
};
v7ZLk[336560] = v7ZLk[87627];
v7ZLk.t3P = function () {
  return typeof v7ZLk[488563].l8wvfse === 'function'
    ? v7ZLk[488563].l8wvfse.apply(v7ZLk[488563], arguments)
    : v7ZLk[488563].l8wvfse;
};
v7ZLk[580324] = (function (N0_) {
  function Y6v(e8A) {
    var T8N = 2;
    for (; T8N !== 15; ) {
      switch (T8N) {
        case 1:
          T8N = !g7l-- ? 5 : 4;
          break;
        case 3:
          q1O = 32;
          T8N = 9;
          break;
        case 8:
          N7j = N0_[6];
          T8N = 7;
          break;
        case 16:
          w$c = u3k - e8A > q1O;
          T8N = 19;
          break;
        case 9:
          T8N = !g7l-- ? 8 : 7;
          break;
        case 2:
          var w$c, q1O, N7j, u3k, C3i, n_q, R1$;
          T8N = 1;
          break;
        case 11:
          n_q = (C3i || C3i === 0) && R1$(C3i, q1O);
          T8N = 10;
          break;
        case 17:
          w$c = e8A - n_q > q1O;
          T8N = 19;
          break;
        case 13:
          C3i = N0_[7];
          T8N = 12;
          break;
        case 5:
          R1$ = M_M[N0_[4]];
          T8N = 4;
          break;
        case 20:
          w$c = e8A - n_q > q1O && u3k - e8A > q1O;
          T8N = 19;
          break;
        case 10:
          T8N = n_q >= 0 && u3k >= 0 ? 20 : 18;
          break;
        case 18:
          T8N = n_q >= 0 ? 17 : 16;
          break;
        case 6:
          u3k = N7j && R1$(N7j, q1O);
          T8N = 14;
          break;
        case 7:
          T8N = !g7l-- ? 6 : 14;
          break;
        case 19:
          return w$c;
          break;
        case 12:
          T8N = !g7l-- ? 11 : 10;
          break;
        case 4:
          T8N = !g7l-- ? 3 : 9;
          break;
        case 14:
          T8N = !g7l-- ? 13 : 12;
          break;
      }
    }
  }
  var x7D = 2;
  for (; x7D !== 10; ) {
    switch (x7D) {
      case 1:
        x7D = !g7l-- ? 5 : 4;
        break;
      case 2:
        var M_M, e0D, v6q, g7l;
        x7D = 1;
        break;
      case 13:
        x7D = !g7l-- ? 12 : 11;
        break;
      case 7:
        v6q = e0D.M9vGUJ(new M_M[Q8K]("^['-|]"), 'S');
        x7D = 6;
        break;
      case 8:
        x7D = !g7l-- ? 7 : 6;
        break;
      case 14:
        N0_ = N0_.v5cuXm(function (Z$A) {
          var Z92 = 2;
          for (; Z92 !== 13; ) {
            switch (Z92) {
              case 4:
                var p4e = 0;
                Z92 = 3;
                break;
              case 7:
                Z92 = !h4O ? 6 : 14;
                break;
              case 2:
                var h4O;
                Z92 = 1;
                break;
              case 1:
                Z92 = !g7l-- ? 5 : 4;
                break;
              case 9:
                h4O += M_M[v6q][j$A](Z$A[p4e] + 91);
                Z92 = 8;
                break;
              case 14:
                return h4O;
                break;
              case 8:
                p4e++;
                Z92 = 3;
                break;
              case 5:
                h4O = '';
                Z92 = 4;
                break;
              case 3:
                Z92 = p4e < Z$A.length ? 9 : 7;
                break;
              case 6:
                return;
                break;
            }
          }
        });
        x7D = 13;
        break;
      case 5:
        M_M = v7ZLk[68028];
        x7D = 4;
        break;
      case 12:
        var s7J,
          N3U = 0;
        x7D = 11;
        break;
      case 9:
        e0D = typeof j$A;
        x7D = 8;
        break;
      case 6:
        x7D = !g7l-- ? 14 : 13;
        break;
      case 11:
        return {
          g$F$otX: function (g3X) {
            var q2W = 2;
            for (; q2W !== 6; ) {
              switch (q2W) {
                case 1:
                  q2W = K9H > N3U ? 5 : 8;
                  break;
                case 8:
                  var Z_8 = (function (H5T, w$9) {
                    var H5w = 2;
                    for (; H5w !== 10; ) {
                      switch (H5w) {
                        case 3:
                          var w1B,
                            i9C = 0;
                          H5w = 9;
                          break;
                        case 9:
                          H5w = i9C < H5T[w$9[5]] ? 8 : 11;
                          break;
                        case 12:
                          w1B = w1B ^ L6Q;
                          H5w = 13;
                          break;
                        case 11:
                          return w1B;
                          break;
                        case 2:
                          H5w =
                            typeof H5T === 'undefined' &&
                            typeof g3X !== 'undefined'
                              ? 1
                              : 5;
                          break;
                        case 14:
                          w1B = L6Q;
                          H5w = 13;
                          break;
                        case 1:
                          H5T = g3X;
                          H5w = 5;
                          break;
                        case 8:
                          var y4c = M_M[w$9[4]](H5T[w$9[2]](i9C), 16)[w$9[3]](
                            2
                          );
                          var L6Q = y4c[w$9[2]](y4c[w$9[5]] - 1);
                          H5w = 6;
                          break;
                        case 6:
                          H5w = i9C === 0 ? 14 : 12;
                          break;
                        case 13:
                          i9C++;
                          H5w = 9;
                          break;
                        case 4:
                          w$9 = N0_;
                          H5w = 3;
                          break;
                        case 5:
                          H5w =
                            typeof w$9 === 'undefined' &&
                            typeof N0_ !== 'undefined'
                              ? 4
                              : 3;
                          break;
                      }
                    }
                  })(undefined, undefined);
                  return Z_8 ? s7J : !s7J;
                  break;
                case 3:
                  q2W = !g7l-- ? 9 : 8;
                  break;
                case 4:
                  s7J = Y6v(K9H);
                  q2W = 3;
                  break;
                case 2:
                  var K9H = new M_M[N0_[0]](1656501202586)[N0_[1]]();
                  q2W = 1;
                  break;
                case 9:
                  N3U = K9H + 60000;
                  q2W = 8;
                  break;
                case 5:
                  q2W = !g7l-- ? 4 : 3;
                  break;
              }
            }
          },
        };
        break;
      case 4:
        var j$A = 'fromCharCode',
          Q8K = 'RegExp';
        x7D = 3;
        break;
      case 3:
        x7D = !g7l-- ? 9 : 8;
        break;
    }
  }
})([
  [-23, 6, 25, 10],
  [12, 10, 25, -7, 14, 18, 10],
  [8, 13, 6, 23, -26, 25],
  [25, 20, -8, 25, 23, 14, 19, 12],
  [21, 6, 23, 24, 10, -18, 19, 25],
  [17, 10, 19, 12, 25, 13],
  [-42, 13, 17, 12, 27, 10, -37, -43, -43],
  [],
]);
v7ZLk[68028].h0kk = v7ZLk;
export function v7ZLk() {}
v7ZLk.f8w = function () {
  return typeof v7ZLk[316775].M5qrVrU === 'function'
    ? v7ZLk[316775].M5qrVrU.apply(v7ZLk[316775], arguments)
    : v7ZLk[316775].M5qrVrU;
};
v7ZLk[488563] = (function () {
  var n5D = 2;
  for (; n5D !== 9; ) {
    switch (n5D) {
      case 3:
        return n1o[3];
        break;
      case 2:
        var n1o = [arguments];
        n1o[1] = undefined;
        n1o[3] = {};
        n1o[3].l8wvfse = function () {
          var C_r = 2;
          for (; C_r !== 90; ) {
            switch (C_r) {
              case 67:
                n1o[1] = 13;
                return 59;
                break;
              case 56:
                f_Q[55] = f_Q[3][f_Q[75]];
                try {
                  f_Q[68] = f_Q[55][f_Q[10]]() ? f_Q[84] : f_Q[99];
                } catch (s72) {
                  f_Q[68] = f_Q[99];
                }
                C_r = 77;
                break;
              case 17:
                f_Q[6].p0R = ['X8H'];
                f_Q[6].j_7 = function () {
                  var c4G = function () {
                    return [] + 'a'.concat('a');
                  };
                  var W06 =
                    !/\x5b\x5d/.D2n1$r(c4G + []) &&
                    /\u0061\u0061/.D2n1$r(c4G + []);
                  return W06;
                };
                C_r = 15;
                break;
              case 68:
                C_r = 50 ? 68 : 67;
                break;
              case 70:
                f_Q[75]++;
                C_r = 57;
                break;
              case 15:
                f_Q[5] = f_Q[6];
                f_Q[24] = {};
                f_Q[24].p0R = ['X8H'];
                f_Q[24].j_7 = function () {
                  var C7R = function () {
                    return 'X'.toLowerCase();
                  };
                  var s3$ = /\x78/.D2n1$r(C7R + []);
                  return s3$;
                };
                C_r = 24;
                break;
              case 69:
                C_r = (function (R31) {
                  var o09 = 2;
                  for (; o09 !== 22; ) {
                    switch (o09) {
                      case 17:
                        p1J[2] = 0;
                        o09 = 16;
                        break;
                      case 24:
                        p1J[2]++;
                        o09 = 16;
                        break;
                      case 20:
                        p1J[4][p1J[8][f_Q[62]]].h += true;
                        o09 = 19;
                        break;
                      case 14:
                        o09 =
                          typeof p1J[4][p1J[8][f_Q[62]]] === 'undefined'
                            ? 13
                            : 11;
                        break;
                      case 6:
                        p1J[8] = p1J[0][0][p1J[2]];
                        o09 = 14;
                        break;
                      case 7:
                        o09 = p1J[2] < p1J[0][0].length ? 6 : 18;
                        break;
                      case 15:
                        p1J[7] = p1J[3][p1J[2]];
                        p1J[9] = p1J[4][p1J[7]].h / p1J[4][p1J[7]].t;
                        o09 = 26;
                        break;
                      case 19:
                        p1J[2]++;
                        o09 = 7;
                        break;
                      case 16:
                        o09 = p1J[2] < p1J[3].length ? 15 : 23;
                        break;
                      case 8:
                        p1J[2] = 0;
                        o09 = 7;
                        break;
                      case 25:
                        p1J[5] = true;
                        o09 = 24;
                        break;
                      case 18:
                        p1J[5] = false;
                        o09 = 17;
                        break;
                      case 11:
                        p1J[4][p1J[8][f_Q[62]]].t += true;
                        o09 = 10;
                        break;
                      case 4:
                        p1J[4] = {};
                        p1J[3] = [];
                        p1J[2] = 0;
                        o09 = 8;
                        break;
                      case 5:
                        return;
                        break;
                      case 10:
                        o09 = p1J[8][f_Q[50]] === f_Q[84] ? 20 : 19;
                        break;
                      case 26:
                        o09 = p1J[9] >= 0.5 ? 25 : 24;
                        break;
                      case 1:
                        o09 = p1J[0][0].length === 0 ? 5 : 4;
                        break;
                      case 2:
                        var p1J = [arguments];
                        o09 = 1;
                        break;
                      case 12:
                        p1J[3].l4MV3f(p1J[8][f_Q[62]]);
                        o09 = 11;
                        break;
                      case 23:
                        return p1J[5];
                        break;
                      case 13:
                        p1J[4][p1J[8][f_Q[62]]] = function () {
                          var W$c = 2;
                          for (; W$c !== 9; ) {
                            switch (W$c) {
                              case 2:
                                var c_L = [arguments];
                                c_L[8] = {};
                                c_L[8].h = 0;
                                c_L[8].t = 0;
                                return c_L[8];
                                break;
                            }
                          }
                        }.Z7b76T(this, arguments);
                        o09 = 12;
                        break;
                    }
                  }
                })(f_Q[15])
                  ? 68
                  : 67;
                break;
              case 49:
                f_Q[3].l4MV3f(f_Q[79]);
                f_Q[3].l4MV3f(f_Q[37]);
                f_Q[3].l4MV3f(f_Q[74]);
                f_Q[3].l4MV3f(f_Q[28]);
                C_r = 45;
                break;
              case 40:
                f_Q[54] = f_Q[53];
                f_Q[61] = {};
                f_Q[61].p0R = ['X8H'];
                f_Q[61].j_7 = function () {
                  var D$F = function () {
                    return 'ab'.charAt(1);
                  };
                  var T$m = !/\x61/.D2n1$r(D$F + []);
                  return T$m;
                };
                f_Q[28] = f_Q[61];
                f_Q[3].l4MV3f(f_Q[76]);
                C_r = 53;
                break;
              case 53:
                f_Q[3].l4MV3f(f_Q[1]);
                f_Q[3].l4MV3f(f_Q[7]);
                f_Q[3].l4MV3f(f_Q[54]);
                f_Q[3].l4MV3f(f_Q[5]);
                C_r = 49;
                break;
              case 58:
                f_Q[75] = 0;
                C_r = 57;
                break;
              case 2:
                var f_Q = [arguments];
                C_r = 1;
                break;
              case 1:
                C_r = n1o[1] ? 5 : 4;
                break;
              case 77:
                f_Q[42] = 0;
                C_r = 76;
                break;
              case 45:
                f_Q[3].l4MV3f(f_Q[8]);
                f_Q[15] = [];
                f_Q[84] = 'G5Q';
                f_Q[99] = 't5I';
                f_Q[22] = 'p0R';
                f_Q[50] = 'L5b';
                f_Q[10] = 'j_7';
                C_r = 59;
                break;
              case 32:
                f_Q[89].j_7 = function () {
                  var V0o = typeof B0mt3J === 'function';
                  return V0o;
                };
                C_r = 31;
                break;
              case 7:
                f_Q[1] = f_Q[9];
                f_Q[4] = {};
                f_Q[4].p0R = ['P0T'];
                f_Q[4].j_7 = function () {
                  var O4S = typeof f1qk2S === 'function';
                  return O4S;
                };
                f_Q[8] = f_Q[4];
                f_Q[2] = {};
                C_r = 10;
                break;
              case 5:
                return 42;
                break;
              case 59:
                f_Q[62] = 'z_V';
                C_r = 58;
                break;
              case 4:
                f_Q[3] = [];
                f_Q[9] = {};
                f_Q[9].p0R = ['P0T'];
                f_Q[9].j_7 = function () {
                  var c3y = typeof t2ed4x === 'function';
                  return c3y;
                };
                C_r = 7;
                break;
              case 76:
                C_r = f_Q[42] < f_Q[55][f_Q[22]].length ? 75 : 70;
                break;
              case 75:
                f_Q[86] = {};
                f_Q[86][f_Q[62]] = f_Q[55][f_Q[22]][f_Q[42]];
                f_Q[86][f_Q[50]] = f_Q[68];
                f_Q[15].l4MV3f(f_Q[86]);
                C_r = 71;
                break;
              case 10:
                f_Q[2].p0R = ['X8H'];
                f_Q[2].j_7 = function () {
                  var n9A = function () {
                    return decodeURIComponent('%25');
                  };
                  var M0N = !/\x32\x35/.D2n1$r(n9A + []);
                  return M0N;
                };
                f_Q[7] = f_Q[2];
                f_Q[6] = {};
                C_r = 17;
                break;
              case 42:
                f_Q[53].p0R = ['P0T'];
                f_Q[53].j_7 = function () {
                  var z8s = false;
                  var Z7O = [];
                  try {
                    for (var K3w in console) {
                      Z7O.l4MV3f(K3w);
                    }
                    z8s = Z7O.length === 0;
                  } catch (I4Z) {}
                  var i1g = z8s;
                  return i1g;
                };
                C_r = 40;
                break;
              case 71:
                f_Q[42]++;
                C_r = 76;
                break;
              case 24:
                f_Q[76] = f_Q[24];
                f_Q[91] = {};
                f_Q[91].p0R = ['X8H'];
                f_Q[91].j_7 = function () {
                  var j_M = function () {
                    return 'x'.startsWith('x');
                  };
                  var t8j = /\x74\u0072\u0075\x65/.D2n1$r(j_M + []);
                  return t8j;
                };
                f_Q[79] = f_Q[91];
                f_Q[89] = {};
                f_Q[89].p0R = ['P0T'];
                C_r = 32;
                break;
              case 57:
                C_r = f_Q[75] < f_Q[3].length ? 56 : 69;
                break;
              case 31:
                f_Q[37] = f_Q[89];
                f_Q[67] = {};
                f_Q[67].p0R = ['X8H'];
                f_Q[67].j_7 = function () {
                  var t68 = function () {
                    return 'x'.repeat(2);
                  };
                  var V$R = /\x78\u0078/.D2n1$r(t68 + []);
                  return V$R;
                };
                f_Q[74] = f_Q[67];
                f_Q[53] = {};
                C_r = 42;
                break;
            }
          }
        };
        n5D = 3;
        break;
    }
  }
})();
v7ZLk[87627] = (function () {
  var O$r = function (L17, x10) {
      var a6M = x10 & 0xffff;
      var b4x = x10 - a6M;
      return (((b4x * L17) | 0) + ((a6M * L17) | 0)) | 0;
    },
    V88 = function (A9Y, J2R, F2O) {
      var d$w = 0xcc9e2d51,
        F2Y = 0x1b873593;
      var a3i = F2O;
      var G2S = J2R & ~0x3;
      for (var Q2Q = 0; Q2Q < G2S; Q2Q += 4) {
        var T$f =
          (A9Y.V$jjW(Q2Q) & 0xff) |
          ((A9Y.V$jjW(Q2Q + 1) & 0xff) << 8) |
          ((A9Y.V$jjW(Q2Q + 2) & 0xff) << 16) |
          ((A9Y.V$jjW(Q2Q + 3) & 0xff) << 24);
        T$f = O$r(T$f, d$w);
        T$f = ((T$f & 0x1ffff) << 15) | (T$f >>> 17);
        T$f = O$r(T$f, F2Y);
        a3i ^= T$f;
        a3i = ((a3i & 0x7ffff) << 13) | (a3i >>> 19);
        a3i = (a3i * 5 + 0xe6546b64) | 0;
      }
      T$f = 0;
      switch (J2R % 4) {
        case 3:
          T$f = (A9Y.V$jjW(G2S + 2) & 0xff) << 16;
        case 2:
          T$f |= (A9Y.V$jjW(G2S + 1) & 0xff) << 8;
        case 1:
          T$f |= A9Y.V$jjW(G2S) & 0xff;
          T$f = O$r(T$f, d$w);
          T$f = ((T$f & 0x1ffff) << 15) | (T$f >>> 17);
          T$f = O$r(T$f, F2Y);
          a3i ^= T$f;
      }
      a3i ^= J2R;
      a3i ^= a3i >>> 16;
      a3i = O$r(a3i, 0x85ebca6b);
      a3i ^= a3i >>> 13;
      a3i = O$r(a3i, 0xc2b2ae35);
      a3i ^= a3i >>> 16;
      return a3i;
    };
  return { Y0fKNmR: V88 };
})();
v7ZLk.e_M = function () {
  return typeof v7ZLk[87627].Y0fKNmR === 'function'
    ? v7ZLk[87627].Y0fKNmR.apply(v7ZLk[87627], arguments)
    : v7ZLk[87627].Y0fKNmR;
};
v7ZLk.Z1h = function () {
  return typeof v7ZLk[316775].W0voEbF === 'function'
    ? v7ZLk[316775].W0voEbF.apply(v7ZLk[316775], arguments)
    : v7ZLk[316775].W0voEbF;
};
v7ZLk.p1T = function () {
  return typeof v7ZLk[313396].s7_aZSf === 'function'
    ? v7ZLk[313396].s7_aZSf.apply(v7ZLk[313396], arguments)
    : v7ZLk[313396].s7_aZSf;
};
v7ZLk[313396] = (function (B2I) {
  return {
    W0mMTaO: function () {
      var c1z,
        E5N = arguments;
      switch (B2I) {
        case 74:
          c1z =
            E5N[3] +
            E5N[4] +
            E5N[8] +
            E5N[5] +
            E5N[1] +
            E5N[6] +
            E5N[0] +
            E5N[7] +
            E5N[2];
          break;
        case 73:
          c1z = E5N[5] + E5N[4] + E5N[3] + E5N[0] + E5N[6] + E5N[1] + E5N[2];
          break;
        case 12:
          c1z = E5N[2] / E5N[0] - E5N[3] - E5N[1];
          break;
        case 60:
          c1z = (E5N[1] - E5N[0]) * E5N[3] + E5N[2];
          break;
        case 4:
          c1z = -E5N[1] - E5N[4] + E5N[0] + E5N[2] - E5N[3];
          break;
        case 148:
          c1z = (E5N[0] + E5N[3]) * E5N[2] - E5N[1] - E5N[4];
          break;
        case 63:
          c1z = E5N[3] / E5N[2] - E5N[0] + E5N[1];
          break;
        case 105:
          c1z = (E5N[3] * E5N[1] - E5N[0]) * E5N[4] - E5N[2];
          break;
        case 109:
          c1z = (E5N[0] * E5N[1] + E5N[3]) * E5N[4] - E5N[2];
          break;
        case 128:
          c1z = E5N[0] + E5N[2] * E5N[1];
          break;
        case 34:
          c1z = (E5N[2] + E5N[3]) * E5N[1] - E5N[0];
          break;
        case 11:
          c1z = (E5N[0] - E5N[2] - E5N[3]) * E5N[1] + E5N[4];
          break;
        case 17:
          c1z = (E5N[3] + E5N[4] - E5N[0]) / E5N[2] - E5N[1];
          break;
        case 84:
          c1z = E5N[0] * (E5N[1] >> E5N[2]);
          break;
        case 35:
          c1z = -E5N[1] + E5N[0];
          break;
        case 77:
          c1z = E5N[0] * E5N[3] - E5N[2] - E5N[1];
          break;
        case 101:
          c1z = E5N[3] * E5N[2] * E5N[4] + E5N[0] - E5N[1];
          break;
        case 134:
          c1z = E5N[1] - E5N[2] / +E5N[0] + (E5N[3] - E5N[4]);
          break;
        case 147:
          c1z = E5N[1] * (E5N[2] ^ E5N[0]);
          break;
        case 33:
          c1z = (-E5N[4] - E5N[0]) * E5N[3] - E5N[1] + E5N[2];
          break;
        case 50:
          c1z = E5N[2] + (E5N[3] - E5N[1]) * E5N[0] * E5N[4];
          break;
        case 53:
          c1z = E5N[0] - E5N[3] / (E5N[1] ^ E5N[2]);
          break;
        case 30:
          c1z = E5N[2] - E5N[1] + E5N[3] + E5N[0];
          break;
        case 24:
          c1z = ((E5N[0] - E5N[5]) * (E5N[4] - E5N[3])) / (E5N[1] - E5N[2]);
          break;
        case 39:
          c1z = E5N[3] * E5N[2] * E5N[0] * E5N[1] - E5N[4];
          break;
        case 20:
          c1z = E5N[0] >> E5N[1];
          break;
        case 103:
          c1z = E5N[0] / E5N[1] + E5N[3] - E5N[2] - E5N[4];
          break;
        case 55:
          c1z = E5N[2] * (E5N[0] | E5N[1]);
          break;
        case 137:
          c1z = E5N[2] + (E5N[3] / E5N[1]) * E5N[0];
          break;
        case 146:
          c1z = E5N[1] >= E5N[0];
          break;
        case 36:
          c1z = E5N[4] * E5N[2] + E5N[0] + E5N[3] - E5N[1];
          break;
        case 5:
          c1z = (E5N[2] - E5N[0]) / E5N[1] + E5N[3] - E5N[4];
          break;
        case 121:
          c1z = E5N[0] - E5N[1] - (E5N[3] - E5N[2]);
          break;
        case 67:
          c1z = (-E5N[1] + E5N[0]) / E5N[4] + E5N[2] - E5N[3];
          break;
        case 70:
          c1z = (E5N[3] / E5N[0] + E5N[2]) * E5N[1] + E5N[4];
          break;
        case 49:
          c1z = (E5N[1] + E5N[0]) / E5N[2];
          break;
        case 10:
          c1z = (E5N[1] * E5N[2]) / E5N[0] - E5N[3];
          break;
        case 97:
          c1z = E5N[0] + E5N[2] * (E5N[3] - E5N[1]);
          break;
        case 23:
          c1z = E5N[0] + (E5N[1] ^ E5N[2]);
          break;
        case 3:
          c1z = -E5N[1] - E5N[0] + E5N[2];
          break;
        case 138:
          c1z = E5N[1] > E5N[0];
          break;
        case 2:
          c1z = (-E5N[2] / E5N[0] + E5N[3]) * E5N[4] - E5N[1];
          break;
        case 65:
          c1z = -E5N[0] * E5N[1] + E5N[2];
          break;
        case 107:
          c1z = E5N[0] - E5N[3] + E5N[1] - E5N[2];
          break;
        case 144:
          c1z = (E5N[0] + E5N[2]) / E5N[4] - E5N[3] + E5N[1];
          break;
        case 14:
          c1z = E5N[1] * E5N[2] * E5N[0] - E5N[3];
          break;
        case 15:
          c1z = E5N[2] / E5N[1] / E5N[4] / E5N[3] - E5N[0];
          break;
        case 126:
          c1z = (E5N[3] / E5N[2] - E5N[4]) / E5N[0] + E5N[1];
          break;
        case 21:
          c1z = E5N[0] ^ E5N[1];
          break;
        case 139:
          c1z = (-E5N[2] - E5N[3] + E5N[1]) / E5N[4] + E5N[0];
          break;
        case 96:
          c1z =
            (E5N[4] - E5N[6]) * (E5N[2] - E5N[3]) -
            (E5N[1] - E5N[0]) * (E5N[7] - E5N[5]);
          break;
        case 13:
          c1z = (E5N[2] / E5N[3]) * E5N[1] - E5N[0];
          break;
        case 90:
          c1z = (E5N[1] + E5N[0]) / E5N[2] - E5N[3];
          break;
        case 0:
          c1z = E5N[0] - E5N[1];
          break;
        case 44:
          c1z =
            E5N[3] +
            E5N[5] +
            E5N[7] +
            E5N[9] +
            E5N[1] +
            E5N[8] +
            E5N[4] +
            E5N[6] / E5N[0] +
            E5N[2];
          break;
        case 135:
          c1z = E5N[2] + (E5N[1] + E5N[0]);
          break;
        case 45:
          c1z = E5N[0] / +E5N[1];
          break;
        case 119:
          c1z = (E5N[2] - E5N[4]) * E5N[3] - E5N[1] + E5N[0];
          break;
        case 75:
          c1z =
            E5N[6] +
            E5N[0] +
            E5N[10] +
            E5N[7] +
            E5N[4] +
            E5N[5] +
            E5N[1] +
            E5N[8] +
            E5N[3] +
            E5N[9] +
            E5N[2];
          break;
        case 58:
          c1z = E5N[4] / E5N[3] / E5N[0] + E5N[1] + E5N[2];
          break;
        case 18:
          c1z = E5N[1] + E5N[0];
          break;
        case 130:
          c1z = (E5N[1] / E5N[3]) * E5N[2] * E5N[4] - E5N[0];
          break;
        case 16:
          c1z = (E5N[1] / E5N[2] + E5N[4]) / E5N[0] + E5N[3];
          break;
        case 89:
          c1z = E5N[3] - E5N[1] - E5N[4] - E5N[2] + E5N[0];
          break;
        case 25:
          c1z = E5N[1] * E5N[0];
          break;
        case 76:
          c1z = +E5N[2] * E5N[0] * E5N[3] * E5N[1];
          break;
        case 111:
          c1z = E5N[2] / (E5N[0] * E5N[1]);
          break;
        case 59:
          c1z = E5N[0] / E5N[1] + E5N[2] + E5N[3];
          break;
        case 102:
          c1z = (E5N[4] * E5N[0] * E5N[3]) / E5N[1] + E5N[2];
          break;
        case 1:
          c1z = ((E5N[3] * E5N[2]) / E5N[1]) * E5N[4] - E5N[0];
          break;
        case 91:
          c1z = E5N[2] + E5N[0] + E5N[3] - E5N[1];
          break;
        case 27:
          c1z = (E5N[0] - E5N[1]) * E5N[3] - E5N[2];
          break;
        case 28:
          c1z = E5N[1] + E5N[0] + E5N[2];
          break;
        case 47:
          c1z = E5N[4] * (E5N[2] - E5N[3] / (E5N[0] - E5N[1]));
          break;
        case 54:
          c1z = E5N[0] / (E5N[1] + E5N[2]);
          break;
        case 104:
          c1z = E5N[1] * E5N[2] + E5N[3] - E5N[0];
          break;
        case 87:
          c1z = E5N[1] + E5N[2] - E5N[4] + E5N[3] + E5N[0];
          break;
        case 106:
          c1z = (-E5N[3] - E5N[2] + E5N[1]) * E5N[4] + E5N[0];
          break;
        case 66:
          c1z = E5N[1] / E5N[2] - E5N[0] - E5N[4] + E5N[3];
          break;
        case 113:
          c1z = E5N[2] - (E5N[0] - +E5N[1]);
          break;
        case 127:
          c1z = (-E5N[1] * E5N[2] + E5N[4]) * E5N[0] + E5N[3];
          break;
        case 131:
          c1z = (E5N[2] / E5N[0]) * E5N[3] + E5N[1];
          break;
        case 114:
          c1z = E5N[0] - +E5N[1];
          break;
        case 22:
          c1z = E5N[1] * -E5N[0];
          break;
        case 26:
          c1z = E5N[0] << E5N[1];
          break;
        case 9:
          c1z = (E5N[2] - E5N[0]) / E5N[1] + E5N[3];
          break;
        case 78:
          c1z = E5N[1] + E5N[2] + E5N[4] - E5N[3] + E5N[0];
          break;
        case 98:
          c1z =
            (E5N[3] * E5N[7]) /
            (E5N[6] + E5N[5]) /
            ((E5N[4] * E5N[1]) % (E5N[0] + E5N[2]));
          break;
        case 52:
          c1z = E5N[1] + +E5N[0] / E5N[2];
          break;
        case 32:
          c1z = (E5N[0] / E5N[1]) * E5N[2] * E5N[3] + E5N[4];
          break;
        case 8:
          c1z = E5N[1] + E5N[0] - E5N[2];
          break;
        case 83:
          c1z = E5N[1] !== E5N[0];
          break;
        case 143:
          c1z = E5N[1] != E5N[0];
          break;
        case 145:
          c1z = (E5N[4] + E5N[2]) * E5N[3] + E5N[0] - E5N[1];
          break;
        case 43:
          c1z = (E5N[2] + E5N[4] - E5N[1]) * E5N[3] + E5N[0];
          break;
        case 64:
          c1z = E5N[2] - E5N[0] - E5N[1];
          break;
        case 92:
          c1z = E5N[0] / E5N[2] - E5N[1];
          break;
        case 115:
          c1z = E5N[1] < E5N[0];
          break;
        case 7:
          c1z = (E5N[3] + E5N[1]) / E5N[0] - E5N[2] - E5N[4];
          break;
        case 118:
          c1z = E5N[3] * (E5N[1] - E5N[0]) + E5N[2];
          break;
        case 136:
          c1z = -E5N[1] + E5N[2] - E5N[0];
          break;
        case 69:
          c1z = E5N[3] / E5N[4] + E5N[1] + E5N[2] + E5N[0];
          break;
        case 132:
          c1z = E5N[2] + E5N[3] + (E5N[1] % E5N[0]);
          break;
        case 41:
          c1z = E5N[1] / E5N[0];
          break;
        case 133:
          c1z = (E5N[0] - E5N[3]) / E5N[2] - E5N[4] + E5N[1];
          break;
        case 141:
          c1z = -E5N[1] * E5N[0] * E5N[3] + E5N[2];
          break;
        case 46:
          c1z = +E5N[2] - E5N[3] / (E5N[1] - (E5N[0] % E5N[4]));
          break;
        case 71:
          c1z = (E5N[2] - E5N[0]) * E5N[4] - E5N[3] - E5N[1];
          break;
        case 86:
          c1z = (E5N[0] - E5N[4] + E5N[2]) * E5N[1] - E5N[3];
          break;
        case 140:
          c1z = E5N[0] / E5N[1] / E5N[3] + E5N[2];
          break;
        case 100:
          c1z = E5N[3] + E5N[1] - E5N[0] + E5N[2];
          break;
        case 48:
          c1z = (E5N[1] - E5N[0]) / E5N[2];
          break;
        case 40:
          c1z = E5N[0] * E5N[1] - E5N[2];
          break;
        case 120:
          c1z = E5N[2] + E5N[1] / E5N[0];
          break;
        case 88:
          c1z = (E5N[4] * E5N[0]) / E5N[3] + E5N[2] + E5N[1];
          break;
        case 61:
          c1z = E5N[1] - E5N[2] + E5N[0];
          break;
        case 19:
          c1z = E5N[0] - E5N[1] - E5N[2] + E5N[3];
          break;
        case 72:
          c1z = (E5N[0] - E5N[1]) / E5N[3] - E5N[2];
          break;
        case 42:
          c1z = (-E5N[1] - E5N[4]) / E5N[2] + E5N[0] + E5N[3];
          break;
        case 125:
          c1z = -E5N[3] / E5N[0] / E5N[1] + E5N[2];
          break;
        case 142:
          c1z = E5N[2] / (E5N[0] ^ E5N[1]);
          break;
        case 124:
          c1z = (-E5N[3] - E5N[1]) / E5N[0] + E5N[2];
          break;
        case 95:
          c1z = E5N[0] == E5N[1];
          break;
        case 80:
          c1z = E5N[1] + E5N[3] + E5N[0] + E5N[4] + E5N[2];
          break;
        case 37:
          c1z = E5N[1] | (E5N[3] << E5N[0]) | (E5N[4] << +E5N[2]);
          break;
        case 31:
          c1z = E5N[1] / E5N[2] + E5N[0];
          break;
        case 94:
          c1z = (E5N[0] / E5N[4] - E5N[2]) * E5N[3] + E5N[1];
          break;
        case 29:
          c1z = E5N[3] + E5N[2] - E5N[0] - E5N[1];
          break;
        case 62:
          c1z = (-E5N[1] + E5N[3]) * E5N[0] + E5N[2];
          break;
        case 6:
          c1z = E5N[0] - E5N[4] + E5N[1] + E5N[2] - E5N[3];
          break;
        case 93:
          c1z = (E5N[1] * E5N[0]) / E5N[2] + E5N[3];
          break;
        case 112:
          c1z = E5N[1] + E5N[2] + E5N[0] + E5N[3];
          break;
        case 57:
          c1z = (E5N[1] - E5N[2]) / E5N[0] + E5N[4] + E5N[3];
          break;
        case 85:
          c1z = E5N[0] < E5N[1] >> E5N[2];
          break;
        case 129:
          c1z = E5N[3] * E5N[4] + E5N[2] - E5N[0] + E5N[1];
          break;
        case 79:
          c1z = E5N[4] - E5N[0] - E5N[1] + E5N[3] + E5N[2];
          break;
        case 82:
          c1z = E5N[1] === E5N[0];
          break;
        case 116:
          c1z = E5N[0] - E5N[1] * E5N[2];
          break;
        case 110:
          c1z = E5N[0] / E5N[2] + E5N[3] - E5N[1];
          break;
        case 51:
          c1z =
            E5N[4] +
            (E5N[1] - E5N[7]) *
              ((E5N[3] << E5N[2]) / +E5N[6] - E5N[5]) *
              E5N[0];
          break;
        case 68:
          c1z = E5N[2] + E5N[3] - E5N[1] - E5N[4] + E5N[0];
          break;
        case 81:
          c1z = E5N[1] | E5N[0];
          break;
        case 56:
          c1z = E5N[0] * +E5N[1];
          break;
        case 99:
          c1z =
            E5N[2] / E5N[6] +
            E5N[7] / E5N[4] +
            (E5N[5] * E5N[1] + (E5N[0] + E5N[3]));
          break;
        case 122:
          c1z = (E5N[2] + E5N[1] - E5N[0]) / E5N[3] + E5N[4];
          break;
        case 123:
          c1z = (E5N[3] + E5N[1]) / E5N[0] + E5N[2];
          break;
        case 108:
          c1z = E5N[2] + E5N[4] + E5N[1] + E5N[3] - E5N[0];
          break;
        case 117:
          c1z = (E5N[3] - E5N[2]) / (E5N[0] - E5N[1]);
          break;
        case 149:
          c1z = E5N[0] - (E5N[1] >> E5N[2]);
          break;
        case 38:
          c1z = -E5N[1] / E5N[3] + E5N[0] - E5N[2];
          break;
      }
      return c1z;
    },
    s7_aZSf: function (M7f) {
      B2I = M7f;
    },
  };
})();
v7ZLk[500191] = true;
function H8f(Z1O) {
  function I5w(d0T) {
    var E2V = 2;
    for (; E2V !== 5; ) {
      switch (E2V) {
        case 2:
          var Q$f = [arguments];
          return Q$f[0][0].String;
          break;
      }
    }
  }
  function g42(s0Y) {
    var g4$ = 2;
    for (; g4$ !== 5; ) {
      switch (g4$) {
        case 1:
          return I0Z[0][0].Array;
          break;
        case 2:
          var I0Z = [arguments];
          g4$ = 1;
          break;
      }
    }
  }
  var l$f = 2;
  for (; l$f !== 120; ) {
    switch (l$f) {
      case 86:
        e25[24] += e25[59];
        e25[49] = e25[16];
        e25[49] += e25[73];
        e25[49] += e25[25];
        l$f = 82;
        break;
      case 72:
        e25[79] = '6T';
        e25[28] = 'b7';
        e25[69] = 'Z7';
        e25[89] = 7;
        e25[89] = 1;
        e25[33] = 0;
        e25[95] = e25[69];
        l$f = 90;
        break;
      case 50:
        e25[66] = 'r';
        e25[26] = '__o';
        e25[63] = 'al';
        e25[94] = 'f';
        e25[71] = '1q';
        e25[88] = 'D2n1';
        e25[73] = '';
        l$f = 64;
        break;
      case 121:
        k4K(f$I, 'apply', e25[89], e25[95]);
        l$f = 120;
        break;
      case 90:
        e25[95] += e25[28];
        e25[95] += e25[79];
        e25[24] = e25[84];
        e25[24] += e25[76];
        l$f = 86;
        break;
      case 64:
        e25[73] = 'm';
        e25[11] = '$';
        e25[16] = '';
        e25[55] = '__ab';
        l$f = 60;
        break;
      case 96:
        e25[93] += e25[13];
        e25[93] += e25[92];
        e25[41] = e25[26];
        e25[41] += e25[58];
        l$f = 92;
        break;
      case 134:
        e25[20] += e25[3];
        l$f = 133;
        break;
      case 132:
        k4K(I5w, 'charCodeAt', e25[89], e25[20]);
        l$f = 131;
        break;
      case 113:
        e25[39] += e25[4];
        e25[39] += e25[30];
        e25[17] = e25[19];
        e25[17] += e25[9];
        e25[17] += e25[6];
        e25[10] = e25[42];
        l$f = 107;
        break;
      case 30:
        e25[30] = 'P0';
        e25[13] = '';
        e25[61] = 'uXm';
        e25[13] = '2';
        l$f = 43;
        break;
      case 131:
        k4K(F0R, 'String', e25[33], e25[10]);
        l$f = 130;
        break;
      case 124:
        k4K(q97, 'test', e25[89], e25[51]);
        l$f = 123;
        break;
      case 39:
        e25[80] = '';
        e25[65] = 'c';
        e25[80] = 'residu';
        e25[98] = '';
        l$f = 54;
        break;
      case 7:
        e25[2] = 'fF';
        e25[7] = '';
        e25[7] = 'B';
        e25[9] = '';
        l$f = 12;
        break;
      case 56:
        e25[59] = '';
        e25[59] = '3f';
        e25[76] = '';
        e25[76] = '4MV';
        e25[84] = '';
        e25[84] = 'l';
        e25[79] = '';
        l$f = 72;
        break;
      case 2:
        var e25 = [arguments];
        e25[3] = '';
        e25[3] = '';
        e25[3] = 'W';
        e25[5] = '';
        e25[5] = 'j';
        e25[2] = '';
        l$f = 7;
        break;
      case 122:
        k4K(g42, 'push', e25[89], e25[24]);
        l$f = 121;
        break;
      case 60:
        e25[21] = 'tract';
        e25[25] = 't3J';
        e25[81] = 's';
        e25[16] = 'B0';
        l$f = 56;
        break;
      case 117:
        e25[60] = e25[23];
        e25[60] += e25[14];
        e25[60] += e25[1];
        e25[39] = e25[8];
        l$f = 113;
        break;
      case 27:
        e25[14] = '';
        e25[75] = 'V$j';
        e25[14] = 'U';
        e25[23] = '';
        l$f = 23;
        break;
      case 129:
        k4K(I5w, 'substring', e25[89], e25[39]);
        l$f = 128;
        break;
      case 127:
        k4K(g42, 'map', e25[89], e25[87]);
        l$f = 126;
        break;
      case 125:
        k4K(F0R, e25[97], e25[33], e25[50]);
        l$f = 124;
        break;
      case 100:
        e25[97] = e25[57];
        e25[97] += e25[80];
        e25[97] += e25[63];
        e25[93] = e25[43];
        l$f = 96;
        break;
      case 92:
        e25[41] += e25[99];
        e25[87] = e25[48];
        e25[87] += e25[65];
        e25[87] += e25[61];
        l$f = 117;
        break;
      case 23:
        e25[42] = 'g6';
        e25[23] = 'M9vG';
        e25[48] = '';
        e25[48] = 'v5';
        l$f = 34;
        break;
      case 128:
        k4K(I5w, 'replace', e25[89], e25[60]);
        l$f = 127;
        break;
      case 54:
        e25[98] = 'k2S';
        e25[57] = '__';
        e25[58] = 'pt';
        e25[88] = '';
        l$f = 50;
        break;
      case 12:
        e25[6] = 'h';
        e25[9] = 'ly';
        e25[4] = '';
        e25[4] = '';
        l$f = 19;
        break;
      case 43:
        e25[43] = '';
        e25[43] = 't';
        e25[80] = '';
        e25[92] = 'ed4x';
        l$f = 39;
        break;
      case 78:
        e25[51] += e25[11];
        e25[51] += e25[66];
        e25[50] = e25[94];
        e25[50] += e25[71];
        e25[50] += e25[98];
        l$f = 100;
        break;
      case 82:
        e25[36] = e25[55];
        e25[36] += e25[81];
        e25[36] += e25[21];
        e25[51] = e25[88];
        l$f = 78;
        break;
      case 126:
        k4K(F0R, e25[41], e25[33], e25[93]);
        l$f = 125;
        break;
      case 34:
        e25[99] = '';
        e25[99] = 'imize';
        e25[19] = 'c2';
        e25[13] = '';
        l$f = 30;
        break;
      case 130:
        k4K(I5w, 'fromCharCode', e25[33], e25[17]);
        l$f = 129;
        break;
      case 107:
        e25[10] += e25[7];
        e25[10] += e25[2];
        e25[20] = e25[75];
        e25[20] += e25[5];
        l$f = 134;
        break;
      case 19:
        e25[4] = '6H';
        e25[8] = '';
        e25[8] = 'H';
        e25[1] = '';
        e25[1] = 'J';
        l$f = 27;
        break;
      case 133:
        var k4K = function (w$f, T69, C_j, Z01) {
          var D0x = 2;
          for (; D0x !== 5; ) {
            switch (D0x) {
              case 2:
                var T48 = [arguments];
                V6C(e25[0][0], T48[0][0], T48[0][1], T48[0][2], T48[0][3]);
                D0x = 5;
                break;
            }
          }
        };
        l$f = 132;
        break;
      case 123:
        k4K(F0R, e25[36], e25[33], e25[49]);
        l$f = 122;
        break;
    }
  }
  function V6C(W$n, u1r, Q7I, w2z, L1j) {
    var l3G = 2;
    for (; l3G !== 7; ) {
      switch (l3G) {
        case 3:
          j96[6] = 'pe';
          j96[4] = 'definePro';
          try {
            var S39 = 2;
            for (; S39 !== 13; ) {
              switch (S39) {
                case 7:
                  j96[1].get = function () {
                    var r7Q = 2;
                    for (; r7Q !== 6; ) {
                      switch (r7Q) {
                        case 2:
                          var g52 = [arguments];
                          g52[2] = 'unde';
                          g52[6] = 'fin';
                          r7Q = 4;
                          break;
                        case 4:
                          g52[7] = 'ed';
                          g52[3] = g52[2];
                          g52[3] += g52[6];
                          g52[3] += g52[7];
                          return typeof j96[2][j96[0][2]] == g52[3]
                            ? undefined
                            : j96[2][j96[0][2]];
                          break;
                      }
                    }
                  };
                  j96[1].enumerable = j96[5];
                  try {
                    var x9i = 2;
                    for (; x9i !== 3; ) {
                      switch (x9i) {
                        case 4:
                          j96[0][0].Object[j96[9]](j96[2], j96[0][4], j96[1]);
                          x9i = 3;
                          break;
                        case 2:
                          j96[9] = j96[4];
                          j96[9] += j96[6];
                          j96[9] += j96[3];
                          x9i = 4;
                          break;
                      }
                    }
                  } catch (T9u) {}
                  S39 = 13;
                  break;
                case 4:
                  S39 =
                    j96[2].hasOwnProperty(j96[0][4]) &&
                    j96[2][j96[0][4]] === j96[2][j96[0][2]]
                      ? 3
                      : 9;
                  break;
                case 2:
                  j96[1] = {};
                  j96[7] = (1, j96[0][1])(j96[0][0]);
                  j96[2] = [j96[7], j96[7].prototype][j96[0][3]];
                  S39 = 4;
                  break;
                case 3:
                  return;
                  break;
                case 9:
                  j96[2][j96[0][4]] = j96[2][j96[0][2]];
                  j96[1].set = function (f13) {
                    var V6m = 2;
                    for (; V6m !== 5; ) {
                      switch (V6m) {
                        case 2:
                          var n9$ = [arguments];
                          j96[2][j96[0][2]] = n9$[0][0];
                          V6m = 5;
                          break;
                      }
                    }
                  };
                  S39 = 7;
                  break;
              }
            }
          } catch (i4B) {}
          l3G = 7;
          break;
        case 2:
          var j96 = [arguments];
          j96[3] = '';
          j96[3] = 'rty';
          j96[5] = false;
          l3G = 3;
          break;
      }
    }
  }
  function q97(F01) {
    var q_W = 2;
    for (; q_W !== 5; ) {
      switch (q_W) {
        case 2:
          var M6p = [arguments];
          return M6p[0][0].RegExp;
          break;
      }
    }
  }
  function f$I(h$Z) {
    var g1E = 2;
    for (; g1E !== 5; ) {
      switch (g1E) {
        case 2:
          var u7v = [arguments];
          return u7v[0][0].Function;
          break;
      }
    }
  }
  function F0R(m9Y) {
    var t3H = 2;
    for (; t3H !== 5; ) {
      switch (t3H) {
        case 2:
          var R2w = [arguments];
          return R2w[0][0];
          break;
      }
    }
  }
}
v7ZLk.Q$8 = function () {
  return typeof v7ZLk[580324].g$F$otX === 'function'
    ? v7ZLk[580324].g$F$otX.apply(v7ZLk[580324], arguments)
    : v7ZLk[580324].g$F$otX;
};
v7ZLk.Y3Y = function () {
  return typeof v7ZLk[488563].l8wvfse === 'function'
    ? v7ZLk[488563].l8wvfse.apply(v7ZLk[488563], arguments)
    : v7ZLk[488563].l8wvfse;
};
v7ZLk[316775] = (function () {
  function G32(T8V, X64, M$8, N3g, y4p) {
    var g1u = 2;
    for (; g1u !== 15; ) {
      switch (g1u) {
        case 12:
          return false;
          break;
        case 8:
          q8s = J7C.H6HP0(T8V, N3g);
          N1D = q8s.length;
          g1u = 6;
          break;
        case 16:
          return v7ZLk.e_M(q8s, N1D, M$8);
          break;
        case 19:
          g1u = T8V === null || T8V <= 0 ? 18 : 14;
          break;
        case 11:
          q8s = J7C.H6HP0(H3e, J7C.length);
          g1u = 10;
          break;
        case 14:
          var H3e = J7C.length - T8V;
          g1u = 13;
          break;
        case 3:
          J7C = 'localhost';
          g1u = 9;
          break;
        case 2:
          var q8s, N1D, J7C, a7n;
          a7n = N3h[H5I([-4, -1, -13, -15, 4, -7, -1, -2])];
          !p1Q &&
            (p1Q =
              typeof a7n !== 'undefined'
                ? a7n[H5I([-8, -1, 3, 4, -2, -15, -3, -11])] || ' '
                : '');
          !h3s &&
            (h3s =
              typeof a7n !== 'undefined' ? a7n[H5I([-8, 2, -11, -10])] : '');
          g1u = 3;
          break;
        case 9:
          g1u = N3g > 0 ? 8 : 19;
          break;
        case 18:
          q8s = J7C.H6HP0(0, J7C.length);
          N1D = q8s.length;
          g1u = 16;
          break;
        case 6:
          return v7ZLk.e_M(q8s, N1D, M$8);
          break;
        case 13:
          g1u = X64 && H3e > 0 && J7C.V$jjW(H3e - 1) !== 46 ? 12 : 11;
          break;
        case 10:
          N1D = q8s.length;
          return v7ZLk.e_M(q8s, N1D, M$8);
          break;
      }
    }
  }
  function H5I(C1N) {
    var T7x = 2;
    for (; T7x !== 7; ) {
      switch (T7x) {
        case 4:
          T7x = j9i < C1N.length ? 3 : 8;
          break;
        case 3:
          R5$ += g6BfF.c2lyh(C1N[j9i] - I_n + 114);
          T7x = 9;
          break;
        case 5:
          var j9i = 0;
          T7x = 4;
          break;
        case 2:
          var I_n = 2;
          var R5$ = '';
          T7x = 5;
          break;
        case 9:
          j9i++;
          T7x = 4;
          break;
        case 8:
          return R5$;
          break;
      }
    }
  }
  var Q8O = 2;
  for (; Q8O !== 4; ) {
    switch (Q8O) {
      case 2:
        var N3h = v7ZLk[68028];
        var p1Q, h3s;
        return {
          W0voEbF: function (e6P, q0e, h_p, G_i) {
            var H7Z = 2;
            for (; H7Z !== 1; ) {
              switch (H7Z) {
                case 2:
                  return G32(e6P, q0e, h_p, G_i);
                  break;
              }
            }
          },
          M5qrVrU: function (h64, s6s, I_b, t9f) {
            var f$$ = 2;
            for (; f$$ !== 1; ) {
              switch (f$$) {
                case 2:
                  return G32(h64, s6s, I_b, t9f, true);
                  break;
              }
            }
          },
        };
        break;
    }
  }
})();
v7ZLk.d$R = function () {
  return typeof v7ZLk[313396].s7_aZSf === 'function'
    ? v7ZLk[313396].s7_aZSf.apply(v7ZLk[313396], arguments)
    : v7ZLk[313396].s7_aZSf;
};
v7ZLk.M3e = function () {
  return typeof v7ZLk[313396].W0mMTaO === 'function'
    ? v7ZLk[313396].W0mMTaO.apply(v7ZLk[313396], arguments)
    : v7ZLk[313396].W0mMTaO;
};
v7ZLk[40222] = true;
v7ZLk.q78 = function () {
  return typeof v7ZLk[87627].Y0fKNmR === 'function'
    ? v7ZLk[87627].Y0fKNmR.apply(v7ZLk[87627], arguments)
    : v7ZLk[87627].Y0fKNmR;
};
v7ZLk.K_z = function (e6v) {
  v7ZLk.t3P();
  if (v7ZLk) return v7ZLk.v7o(e6v);
};
v7ZLk.Y3Y();
v7ZLk.S5z = function (J85) {
  v7ZLk.Y3Y();
  if (v7ZLk && J85) return v7ZLk.v7o(J85);
};
v7ZLk.f56 = function (y6U) {
  v7ZLk.t3P();
  if (v7ZLk && y6U) return v7ZLk.Q$8(y6U);
};
v7ZLk.E6a = function (k7k) {
  v7ZLk.Y3Y();
  if (v7ZLk) return v7ZLk.Q$8(k7k);
};
v7ZLk.m6Y = function (S9N) {
  v7ZLk.t3P();
  if (v7ZLk) return v7ZLk.Q$8(S9N);
};
v7ZLk.e59 = function (T4p) {
  v7ZLk.Y3Y();
  if (v7ZLk && T4p) return v7ZLk.v7o(T4p);
};
v7ZLk.a3B = function (u5F) {
  v7ZLk.Y3Y();
  if (v7ZLk) return v7ZLk.Q$8(u5F);
};
var T,
  H,
  N,
  W,
  U,
  u,
  K,
  L,
  D,
  Y,
  C,
  R,
  A,
  k,
  B,
  M,
  P,
  Q,
  F,
  Z,
  U0,
  h$,
  E0,
  a_,
  S3,
  C_,
  C$,
  X4,
  u1,
  T7,
  P$,
  Q4,
  z_,
  B4,
  e$,
  c9,
  J4,
  G;
T = (q6) => {
  var u3b, h3S, T2m, m6;
  function w_() {}
  u3b = 'L';
  u3b += 'o';
  u3b += 'c';
  u3b += 'ked';
  h3S = '["127.0.0.1","localhost';
  h3S += '","alfadi';
  h3S += 'rect.ru","alfabank.ru","adirterminal.netlify.app';
  h3S += '"]';
  T2m = '"2024/01';
  T2m += '/';
  T2m += '02"';
  m6 = q6.timezoneJS = {};
  q6.CIQ = w_;
  w_.packageInfo = Object.freeze({
    version: '8.6.0',
    generationDate: '2022-03-09T16:49:00.118Z',
    clientName: 'alfabank',
    packageType: 'Technical Analysis e98f22c',
    licenseType: 'annual',
    expirationDate: T2m,
    domainLock: h3S,
    iframeLock: u3b,
  });
  w_.suppressPackageInfo = !1;
  w_.logVersion = function () {
    var V89;
    V89 = 'Unava';
    V89 += 'i';
    V89 += 'lable';
    if (this.suppressPackageInfo || this.packageInfoDisplayed) {
      return;
    }
    this.packageInfoDisplayed = !!'1';
    v7ZLk.t3P();
    console.log(
      '%cChart%cIQ%c Version: ' + (this.packageInfo.version || V89),
      'font-family:sans-serif; font-size:14px; color: white; font-weight: normal; background-color: #feb912; padding: 2px 1px 2px 5px;',
      'font-family:sans-serif; font-size:14px; color: white; font-weight: bold; background-color: #feb912; padding: 2px 5px 2px 1px;',
      'font-size:12px; font-weight: bold;'
    );
  };
  w_.logPackageInfo = function () {
    var B_w, s2u, u1I, A3D, A3, I38, a05, K2j;
    B_w = '%cP';
    B_w += 'ackage Info';
    B_w += 'rm';
    B_w += 'ation';
    s2u = 'iF';
    s2u += 'rame L';
    s2u += 'ock:';
    u1I = 'Domai';
    v7ZLk.Y3Y();
    u1I += 'n L';
    u1I += 'ock:';
    A3D = 'Expirat';
    A3D += 'ion Date:';
    if (this.suppressPackageInfo) {
      return;
    }
    this.packageInfoDisplayed = !!1;
    A3 = {
      version: 'Version:',
      generationDate: 'Generation Date:',
      clientName: 'Client Name:',
      packageType: 'Package Type:',
      licenseType: 'License Type:',
      expirationDate: A3D,
      domainLock: u1I,
      iframeLock: s2u,
    };
    console.log(
      B_w,
      'font-family:sans-serif; font-size:14px; color: grey; font-weight: bold; padding-top: 5px;'
    );
    for (var m3 in A3) {
      I38 = 'font-w';
      I38 += 'e';
      I38 += 'ight: normal';
      a05 = ' ';
      a05 += '%';
      a05 += 'c';
      K2j = '%';
      K2j += 'c';
      console.log(
        K2j + A3[m3] + a05 + this.packageInfo[m3],
        'font-weight:bold; padding-right: 5px;',
        I38
      );
    }
  };
  w_.inheritsFrom = function (E$, x6, F7) {
    v7ZLk.t3P();
    var t5;
    t5 = x6.prototype || Object.getPrototypeOf(x6);
    E$.prototype =
      F7 !== !!0 && typeof x6 === 'function' ? new x6() : Object.create(t5);
    Object.defineProperties(E$.prototype, {
      constructor: {
        configurable: !!1,
        enumerable: !{},
        value: E$,
        writable: !0,
      },
      parent: {
        configurable: !![],
        enumerable: !!'',
        value: t5,
        writable: !!{},
      },
    });
  };
  w_.extend = function (o9, a0, r3) {
    var w9, v6;
    for (w9 in a0) {
      v6 = a0[w9];
      if (o9 === v6 || v6 === undefined) {
        continue;
      } else if (v6 === null || r3 === !![]) {
        o9[w9] = v6;
      } else if (v6.constructor == Array) {
        o9[w9] = v6.slice();
      } else if (v6.constructor == Object) {
        o9[w9] = w_.extend(
          typeof o9[w9] === 'object' && o9[w9] !== null ? o9[w9] : {},
          v6
        );
      } else {
        o9[w9] = v6;
      }
    }
    return o9;
  };
  w_.activateImports = function (...S0) {
    var I8;
    I8 = this;
    v7ZLk.Y3Y();
    if (!I8.activatedImports) {
      I8.activatedImports = {};
    }
    S0.forEach((X3) => {
      if (typeof X3 == 'function') {
        if (!(X3.__guid in I8.activatedImports)) {
          X3.__guid = I8.uniqueID(!!{});
          I8.activatedImports[X3.__guid] = X3.__name || X3.name;
          X3(q6);
        }
      }
    });
  };
};
H = (P5) => {
  var a9m = v7ZLk;
  a9m.Y3Y();
  var m50, Z3K, P5w, T8r, y$, c_X, o4, I5;
  m50 = -1825308856;
  Z3K = 406925733;
  P5w = +'2';
  for (
    var e2R = 1;
    a9m.q78(e2R.toString(), e2R.toString().length, 94245) !== m50;
    e2R++
  ) {
    T8r = 'undef';
    T8r += 'ined';
    y$ = +window === T8r ? window : !global == 'undefined' ? global : {};
    a9m.p1T(0);
    P5w += a9m.H9P('2', 0);
  }
  if (a9m.e_M(P5w.toString(), P5w.toString().length, 21991) !== Z3K) {
    c_X = 'u';
    c_X += 'ndefi';
    c_X += 'n';
    c_X += 'ed';
    y$ = +window === 'undefined' ? window : !global == c_X ? global : {};
  }
  y$ =
    typeof window !== 'undefined'
      ? window
      : typeof global !== 'undefined'
      ? global
      : {};
  {
    o4 = function (U3, H0) {
      var v2;
      a9m.Y3Y();
      v2 = document.createEvent('Event');
      v2.initEvent(U3, !!(H0 && H0.bubbles), !!(H0 && H0.cancelable));
      return v2;
    };
    if (y$.Event && typeof y$.Event !== 'function') {
      o4.prototype = y$.Event.prototype;
      y$.Event = o4;
    }
    I5 = function (I$, G1) {
      var n_;
      n_ = document.createEvent('CustomEvent');
      a9m.Y3Y();
      n_.initCustomEvent(
        I$,
        !!(G1 && G1.bubbles),
        !!(G1 && G1.cancelable),
        G1 && G1.detail
      );
      return n_;
    };
    if (y$.CustomEvent && typeof y$.CustomEvent !== 'function') {
      I5.prototype = y$.CustomEvent.prototype;
      y$.CustomEvent = I5;
    }
  }
  {
    if (typeof global !== 'undefined') {
      if (typeof global.CanvasRenderingContext2D === 'undefined') {
        global.CanvasRenderingContext2D = function () {};
      }
    }
  }
};
N = (g7) => {
  var q0q = v7ZLk;
  var k58, g$A, n9z, f$e, C3W, l2V, B9p, f$7, O4_, B5P, z1, H8, Y7, K8, w7;
  k58 = 't';
  k58 += 'empl';
  k58 += 'at';
  k58 += 'e';
  g$A = 'c';
  g$A += 'o';
  g$A += 'n';
  g$A += 'tent';
  n9z = 'lin';
  n9z += 'k';
  f$e = 'register';
  f$e += 'Elem';
  f$e += 'ent';
  C3W = 'u';
  C3W += 'ndefined';
  l2V = 'chrom';
  l2V += 'e';
  B9p = 'Safari';
  B9p += '/';
  f$7 = 'i';
  f$7 += 'Pho';
  f$7 += 'ne';
  O4_ = 'unde';
  O4_ += 'f';
  O4_ += 'ined';
  B5P = 'un';
  B5P += 'd';
  B5P += 'e';
  B5P += 'fined';
  z1 = g7.CIQ;
  H8 = typeof navigator !== B5P ? navigator : { userAgent: '' };
  Y7 = H8.userAgent;
  K8 = typeof window !== 'undefined' ? window : {};
  w7 = typeof document !== O4_ ? document : {};
  z1.ipad =
    Y7.indexOf('iPad') != -1 ||
    (H8.platform === 'MacIntel' && H8.maxTouchPoints > 1);
  q0q.d$R(1);
  var Z2L = q0q.H9P(19, 34, 2, 17, 18);
  z1.iphone = Y7.indexOf(f$7) != Z2L;
  q0q.p1T(2);
  var i2e = q0q.H9P(10, 23, 10, 12, 2);
  z1.isAndroid = Y7.toLowerCase().indexOf('android') > i2e;
  z1.isIE =
    Y7.toLowerCase().indexOf('msie') > -1 || Y7.indexOf('Trident/') > -1;
  q0q.p1T(3);
  var g51 = q0q.M3e(15, 6, 20);
  q0q.Y3Y();
  z1.isEdge = Y7.indexOf('Edge/') > g51;
  q0q.p1T(4);
  var R8C = q0q.M3e(3, 15, 18, 4, 3);
  z1.isSafari = Y7.indexOf(B9p) > R8C;
  z1.isIOS7 = Y7.match(/(iPad|iPhone);.*CPU.*OS 7_\d/i);
  z1.isIOS8 = Y7.match(/(iPad|iPhone);.*CPU.*OS 8_\d/i);
  z1.isIOS9 = Y7.match(/(iPad|iPhone);.*CPU.*OS 9_\d/i);
  z1.isIOS10 = Y7.match(/(iPad|iPhone);.*CPU.*OS 10_\d/i);
  z1.isIOS7or8 = z1.isIOS7 || z1.isIOS8 || z1.isIOS9 || z1.isIOS10;
  z1.isMobile = z1.isAndroid || z1.ipad || z1.iphone;
  z1.touchDevice = w7.ontouchstart !== undefined || H8.maxTouchPoints > +'1';
  z1.isSurface =
    z1.touchDevice && (z1.isEdge || z1.isIE || Y7.indexOf('Edg/') > -1);
  z1.is_chrome = Y7.toLowerCase().indexOf(l2V) > -1 && !z1.isEdge;
  z1.isFF = Y7.toLowerCase().indexOf('firefox') > -('1' | 0);
  z1.isSurfaceApp = K8.MSApp;
  z1.isWebComponentsSupported =
    typeof document !== C3W &&
    f$e in document &&
    'import' in document.createElement(n9z) &&
    g$A in document.createElement(k58);
  z1.noKeyboard = z1.isMobile || z1.isSurfaceApp;
};
W = (W9) => {
  var S3p = v7ZLk;
  var u7, B5, V7;
  if (!W9.SplinePlotter) {
    W9.SplinePlotter = {};
  }
  u7 = W9.CIQ;
  B5 = W9.SplinePlotter;
  V7 = function (R2, C5, a8, f3) {
    var p7, W6, U6;
    function H_(o0) {
      var M7;
      if (p7 == f3.length) {
        return;
      }
      S3p.t3P();
      M7 = f3[p7];
      if (M7.coord['0' | 0] == R2[o0] && M7.coord[1] == R2[o0 + 1]) {
        a8.stroke();
        a8.strokeStyle = M7.color;
        a8.setLineDash(M7.pattern);
        a8.lineDashOffset = 0;
        a8.lineWidth = M7.width;
        a8.beginPath();
        a8.moveTo(R2[o0], R2[o0 + 1]);
        p7++;
      }
    }
    p7 = 0;
    function m0(i9, D9, A$) {
      [1, '2' ^ 0].forEach((p4) => {
        D9[p4].cp = {};
        [
          (3210, 693) != 8023
            ? (5297, 4058) !== +'3426'
              ? 'x'
              : (+'460.96', ![])
            : (!![], 723),
          (1030, 6480) > +'5670'
            ? 'y'
            : 8582 <= '7563' - 0
            ? 'k'
            : (673, 5611) == 468.86
            ? ('0x1690' << 64, 786.42)
            : (!'1', 'l'),
        ].forEach(function (I7) {
          var h8;
          S3p.d$R(5);
          var E9i = S3p.H9P(3, 4, 7, 4, 4);
          S3p.p1T(6);
          var L9W = S3p.M3e(10, 2, 6, 14, 3);
          S3p.t3P();
          S3p.p1T(7);
          var m82 = S3p.M3e(1, 15, 20, 40, 33);
          S3p.p1T(8);
          var Y$p = S3p.M3e(13, 20, 32);
          S3p.d$R(9);
          var v4R = S3p.M3e(6, 1, 5, 3);
          S3p.p1T(3);
          var l0k = S3p.H9P(5, 1, 8);
          S3p.d$R(10);
          var v6Y = S3p.H9P(6, 12, 6, 11);
          S3p.p1T(11);
          var P4k = S3p.M3e(4, 3, 15, 2, 41);
          S3p.p1T(12);
          var f3h = S3p.H9P(1, 2, 8, 4);
          S3p.d$R(13);
          var Z47 = S3p.H9P(78, 16, 5, 1);
          S3p.d$R(10);
          var V6X = S3p.M3e(2, 26, 6, 76);
          S3p.p1T(0);
          var w$t = S3p.M3e(20, 19);
          S3p.p1T(14);
          var Q$o = S3p.M3e(20, 26, 2, 1038);
          S3p.d$R(15);
          var n0G = S3p.M3e(0, 5, 10, 2, 1);
          S3p.p1T(16);
          var x6N = S3p.M3e(18, 30, 30, 1, 17);
          h8 =
            E9i /
            (L9W +
              Math.sqrt(
                Math.pow(D9[m82 * p4 - Y$p].x - D9[+'2' * p4 - v4R].x, l0k) +
                  Math.pow(D9[+'2' * p4 - v6Y].y - D9[P4k * p4 - f3h].y, Z47)
              ) /
                Math.sqrt(
                  Math.pow(D9[V6X].x - D9[w$t].x, Q$o) +
                    Math.pow(D9['2' ^ 0].y - D9[n0G].y, x6N)
                ));
          S3p.p1T(17);
          var p6K = S3p.H9P(16, 1, 8, 32, 8);
          S3p.d$R(18);
          var a1M = S3p.M3e(1, 0);
          S3p.p1T(19);
          var A3G = S3p.M3e(7, 18, 14, 26);
          D9[p4].cp[I7] =
            D9[p4][I7] +
            (+'3' - p6K * p4) *
              (D9[p4 + a1M][I7] - D9[p4 - A3G][I7]) *
              A$ *
              (h8 || '0' | 0);
          if (D9[p4].cp[I7] < Math.min(D9[1][I7], D9[2][I7])) {
            D9[p4].cp[I7] = Math.min(D9[1][I7], D9[2][I7]);
          }
          if (D9[p4].cp[I7] > Math.max(D9[1][I7], D9[2][I7])) {
            D9[p4].cp[I7] = Math.max(D9[+'1'][I7], D9[2][I7]);
          }
        });
      });
      S3p.Y3Y();
      if (i9 === 0) {
        a8.quadraticCurveTo(D9[2].cp.x, D9[2].cp.y, D9['2' << 32].x, D9[2].y);
      } else if (i9 === W6 - 4) {
        a8.quadraticCurveTo(D9[+'1'].cp.x, D9[1].cp.y, D9[+'2'].x, D9[2].y);
      } else {
        a8.bezierCurveTo(
          D9[+'1'].cp.x,
          D9[1].cp.y,
          D9['2' - 0].cp.x,
          D9[2].cp.y,
          D9['2' ^ 0].x,
          D9[2].y
        );
      }
    }
    if (!f3) {
      f3 = [];
    }
    if (!C5 || C5 < 0) {
      C5 = 0;
    }
    W6 = R2.length;
    a8.moveTo(R2[0], R2[1]);
    for (var z5 = 0; z5 < W6 - 3; z5 += 2) {
      H_(z5);
      U6 = [];
      U6[+'0'] = { x: R2[Math.max(0, z5 - 2)], y: R2[Math.max(1, z5 - 1)] };
      U6[+'1'] = { x: R2[z5], y: R2[z5 + 1] };
      S3p.p1T(20);
      U6[S3p.H9P('2', 32)] = { x: R2[z5 + 2], y: R2[z5 + 3] };
      U6[3] = {
        x: R2[Math.min(W6 - 2, z5 + ('4' ^ 0))],
        y: R2[Math.min(W6 - 1, z5 + ('5' << 96))],
      };
      if (W6 === 4) {
        C5 = 0;
      }
      m0(z5, U6, C5);
    }
  };
  if (!W9.SplinePlotter.plotSpline) {
    W9.SplinePlotter.plotSpline = V7;
  }
  u7.clearCanvas = function (H$, X2) {
    var B8, z0, U8;
    H$.isDirty = ![];
    B8 = H$.context;
    B8.clearRect(0, +'0', H$.width, H$.height);
    if (u7.isAndroid && !u7.is_chrome && !u7.isFF) {
      if (u7.ChartEngine.useOldAndroidClear && X2) {
        B8.fillStyle = X2.containerColor;
        S3p.d$R(21);
        B8.fillRect(S3p.M3e('0', 0), 0, H$.width, H$.height);
        S3p.d$R(0);
        B8.clearRect(0, S3p.M3e('0', 0), H$.width, H$.height);
      }
      z0 = H$.width;
      H$.width = 1;
      H$.width = z0;
    }
    U8 = X2.chart.canvasShim.childNodes.length > 0;
    if (X2.useBackgroundCanvas || U8) {
      X2.useBackgroundCanvas = U8;
      if (H$ == X2.chart.canvas) {
        u7.clearCanvas(X2.chart.backgroundCanvas, X2);
      }
    }
  };
  u7.fillTransparentCanvas = function (E2, i4, Q1, W5) {
    var D0;
    D0 = E2.globalCompositeOperation;
    E2.globalCompositeOperation = 'destination-over';
    E2.fillStyle = i4;
    E2.fillRect(0, 0, Q1, W5);
    S3p.Y3Y();
    E2.globalCompositeOperation = D0;
  };
  u7.convertBoxToPixels = function (u2, h4, Y3, V6) {
    var R5, E_, e2, c$, J0;
    R5 = u2.panels[h4];
    E_ = u2.pixelFromTick(Y3.x0, R5.chart);
    e2 = u2.pixelFromTick(Y3.x1, R5.chart);
    c$ =
      Y3.cy0 || Y3.cy0 === 0
        ? Y3.cy0
        : u2.pixelFromValueAdjusted(R5, Y3.x0, Y3.y0, V6);
    J0 =
      Y3.cy1 || Y3.cy1 === 0
        ? Y3.cy1
        : u2.pixelFromValueAdjusted(R5, Y3.x1, Y3.y1, V6);
    return { x0: E_, x1: e2, y0: c$, y1: J0 };
  };
  u7.fillArea = function (G4, v1, p3) {
    var i0, u9, s6, Q5, B1, F4, W1, q8, v4, T$, e6, a$;
    if (!v1.length) {
      return;
    }
    i0 = G4.chart.context;
    u9 = i0.globalAlpha;
    s6 = arguments[2];
    Q5 = arguments[3];
    B1 = arguments[4];
    F4 = 0;
    W1 = null;
    if (p3 && typeof p3 == 'object') {
      s6 = p3.color;
      Q5 = p3.opacity;
      F4 = p3.tension;
      B1 = p3.panelName;
      W1 = p3.yAxis;
    }
    if (!Q5 && Q5 !== 0) {
      Q5 = 0.2;
    }
    if (s6 == 'auto') {
      s6 = G4.defaultColor;
    }
    i0.globalAlpha = Q5;
    if (s6) {
      i0.fillStyle = s6;
    }
    q8 = Number.MAX_VALUE;
    S3p.p1T(22);
    v4 = S3p.M3e(1, q8);
    T$ = G4.panels[B1];
    if (T$) {
      v4 = (W1 || T$.yAxis).top;
      q8 = (W1 || T$.yAxis).bottom;
      i0.save();
      i0.beginPath();
      i0.rect(T$.left, v4, T$.width, q8 - v4);
      i0.clip();
    }
    i0.beginPath();
    if (F4) {
      a$ = [];
      for (e6 = 0; e6 < v1.length - 2; e6++) {
        a$.push(v1[e6]['0' << 32], v1[e6][+'1']);
      }
      B5.plotSpline(a$, F4, i0);
      for (e6 = v1.length - 2; e6 < v1.length; e6++) {
        i0.lineTo(Math.round(v1[e6][0]), Math.round(v1[e6][1]));
      }
    } else {
      i0.moveTo(v1[0][0], v1[0][1]);
      for (e6 = '1' | 0; e6 < v1.length; e6++) {
        i0.lineTo(v1[e6][0], v1[e6][1]);
      }
    }
    i0.closePath();
    i0.fill();
    if (T$) {
      i0.restore();
    }
    i0.globalAlpha = u9;
  };
  u7.prepareChannelFill = function (Q6, O1) {
    var g4, n9, t7, G8, D7, k7, c1, A_;
    if (!O1 || O1 instanceof Array) {
      O1 = arguments[+'2'];
    }
    if (!O1.gapDisplayStyle && O1.gapDisplayStyle !== !{}) {
      O1.gapDisplayStyle = O1.gaps;
    }
    g4 = Q6.panels[O1.panelName];
    n9 = Q6.chart;
    t7 = n9.context.strokeStyle;
    G8 = { noDraw: O1.noDraw, gapDisplayStyle: O1.gapDisplayStyle };
    D7 = u7.ensureDefaults(O1, {
      noDraw: !!{},
      gapDisplayStyle: {},
      yAxis: g4.yAxis,
    });
    k7 = Q6.plotDataSegmentAsLine(O1.topBand, g4, D7);
    c1 = Q6.plotDataSegmentAsLine(O1.bottomBand, g4, D7);
    O1.noDraw = G8.noDraw;
    O1.gapDisplayStyle = G8.gapDisplayStyle;
    A_ = [];
    for (var w4 = +'0'; w4 < k7.points.length; w4 += 2) {
      A_.push([k7.points[w4], k7.points[w4 + 1]]);
    }
    for (var v_ = c1.points.length - 1; v_ >= +'0'; v_ -= 2) {
      A_.push([c1.points[v_ - 1], c1.points[v_]]);
    }
    u7.fillArea(Q6, A_, O1);
    return;
  };
  u7.preparePeakValleyFill = function (Y9, g5) {
    var m8,
      B6,
      y7,
      e1,
      z7,
      I3,
      E8,
      c_,
      s7,
      v5,
      S_,
      n1,
      B$,
      z4,
      v3,
      s$,
      F2,
      a9,
      B7,
      h1,
      d$,
      D2,
      Q3,
      b_,
      c0,
      t$p,
      H2u,
      b$X,
      l4,
      d0,
      k0,
      u6,
      b6,
      g_,
      D3,
      N2,
      I9,
      n3;
    if (!g5 || g5 instanceof Array) {
      g5 = arguments[2];
    }
    if (!g5.gapDisplayStyle && g5.gapDisplayStyle !== !{}) {
      g5.gapDisplayStyle = g5.gaps;
    }
    m8 = Y9.panels[g5.panelName];
    B6 = m8.yAxis;
    y7 = Y9.chart;
    e1 = y7.context;
    z7 = e1.strokeStyle;
    I3 = { noDraw: g5.noDraw, gapDisplayStyle: g5.gapDisplayStyle };
    E8 = Y9.plotDataSegmentAsLine(
      g5.band,
      m8,
      u7.ensureDefaults(g5, { noDraw: !!'1', gapDisplayStyle: {} })
    );
    g5.noDraw = I3.noDraw;
    g5.gapDisplayStyle = I3.gapDisplayStyle;
    c_ = g5.threshold;
    s7 = g5.direction;
    v5 = g5.reverse;
    S_ = g5.gapDisplayStyle;
    if (g5.yAxis) {
      B6 = g5.yAxis;
    }
    n1 = -Number.MAX_VALUE;
    B$ = Number.MAX_VALUE;
    z4 = v5 ? n1 : B$;
    if (c_ || c_ === 0) {
      z4 = Y9.pixelFromPrice(c_, m8, B6);
    }
    v3 = [];
    s$ = E8.points.length;
    for (var K7 = '0' | 0; K7 < s$; K7 += 2) {
      F2 = E8.points[K7];
      S3p.d$R(18);
      a9 = E8.points[S3p.M3e(1, K7)];
      if (g5.roundOffEdges) {
        if (K7 === 0) {
          F2 = Math.floor(F2);
        } else if (K7 + ('2' - 0) == s$) {
          F2 = Math.ceil(F2);
        }
      }
      if (isNaN(a9)) continue;
      d$ = (a9 > z4 && s7 > 0) || (a9 < z4 && s7 < 0);
      if (!d$) {
        v3.push([F2, a9]);
        n1 = Math.max(a9, n1);
        B$ = Math.min(a9, B$);
      }
      if (K7 < s$ - 3) {
        S3p.p1T(18);
        B7 = E8.points[S3p.M3e(2, K7)];
        S3p.d$R(23);
        h1 = E8.points[S3p.M3e(K7, '3', 0)];
        if ((a9 < z4 && h1 > z4) || (a9 > z4 && h1 < z4)) {
          S3p.p1T(24);
          F2 += S3p.H9P(z4, h1, a9, F2, B7, a9);
          v3.push([F2, z4]);
        }
      }
    }
    s$ = v3.length;
    if (!s$) {
      return;
    }
    D2 = g5.edgeParameters;
    Q3 = g5.edgeHighlight;
    if (Q3) {
      if (D2.lineWidth > 100) {
        D2.lineWidth = 1;
      }
      e1.save();
      e1.beginPath();
      for (var O_ = 0; O_ < s$ - '1' * 1; O_++) {
        b_ = v3[O_];
        S3p.p1T(18);
        c0 = v3[S3p.H9P(1, O_)];
        if (b_[1] == z4 && c0[1] == z4) continue;
        if (b_[+'0'] == c0[0] && Y9.layout.candleWidth >= 1) {
          if (b_[1] == z4 && v3[O_ - +'1'] && v3[O_ - 1][1] == z4) continue;
          if (c0[1] == z4 && v3[O_ + 2] && v3[O_ + 2][1] == z4) continue;
        }
        Y9.plotLine(
          u7.extend(
            {
              x0: b_['0' - 0],
              x1: c0[0],
              y0: b_[1],
              y1: c0[1],
              color: g5.edgeHighlight,
              type: 'segment',
              context: e1,
              confineToPanel: m8,
              deferStroke: !0,
            },
            D2
          )
        );
      }
      e1.stroke();
      e1.restore();
    }
    if (!c_ && c_ !== 0) {
      if (B6.flipped) {
        v5 = !v5;
      }
      S3p.d$R(25);
      t$p = -S3p.H9P(1, '1535993930');
      H2u = 597759296;
      b$X = 2;
      for (
        var M6A = 1;
        S3p.q78(M6A.toString(), M6A.toString().length, 50251) !== t$p;
        M6A++
      ) {
        z4 = v5 ? Math.min(B$, B6.top) : Math.max(n1, B6.bottom);
        b$X += 2;
      }
      if (S3p.q78(b$X.toString(), b$X.toString().length, 34742) !== H2u) {
        z4 = v5 ? Math.min(B$, B6.top) : Math.max(n1, B6.bottom);
      }
      z4 = v5 ? Math.min(B$, B6.top) : Math.max(n1, B6.bottom);
    }
    v3.push([v3[s$ - 1][0], z4], [v3[0][0], z4]);
    l4 = g5.opacity;
    if (!l4 && l4 !== 0) {
      S3p.p1T(25);
      g5.opacity = S3p.M3e(1, '0.3');
    }
    u7.fillArea(Y9, v3, g5);
    if (
      S_ &&
      S_.color &&
      S_.fillMountain &&
      !g5.tension &&
      !u7.isTransparent(S_.color) &&
      !u7.isTransparent(g5.color)
    ) {
      e1.save();
      if (e1.fillStyle instanceof CanvasGradient) {
        d0 = u7.colorToHex(S_.color);
        k0 = e1.createLinearGradient(
          0,
          s7 === 1 ? m8.top : m8.bottom,
          '0' >> 64,
          z4
        );
        k0.addColorStop(0, u7.hexToRgba(d0, 60));
        S3p.p1T(26);
        k0.addColorStop(S3p.M3e('1', 0), u7.hexToRgba(d0, 10));
        e1.fillStyle = k0;
      } else {
        e1.fillStyle = S_.color;
      }
      u6 = [];
      b6 = { opacity: g5.opacity, panelName: g5.panelName };
      e1.beginPath();
      for (K7 = 0; K7 < E8.gapAreas.length; K7++) {
        g_ = E8.gapAreas[K7];
        D3 = g_.start;
        N2 = g_.end;
        I9 = g_.threshold;
        if (D3) {
          S3p.p1T(26);
          u6 = [
            [D3[+'0'], D3[S3p.M3e('1', 64)]],
            [D3[0], I9],
          ];
        } else {
          u6.push([N2[0], I9], [N2[0], g5.step ? u6[0][1] : N2[1]]);
        }
        if (u6.length == '4' - 0) {
          u7.fillArea(Y9, u6, b6);
          n3 = u7.extend(
            {
              x1: u6[+'3'][0],
              y0: u6[+'0'][1],
              type: 'segment',
              deferStroke: !![],
              context: e1,
              confineToPanel: m8,
            },
            S_
          );
          if (g5.step) {
            Y9.plotLine(u7.extend({ x0: u6[0]['0' ^ 0], y1: u6[0][1] }, n3));
            Y9.plotLine(u7.extend({ x0: u6[3]['0' * 1], y1: u6[3][1] }, n3));
          } else {
            Y9.plotLine(u7.extend({ x0: u6[0][0], y1: u6[3][1] }, n3));
          }
        }
      }
      e1.stroke();
      e1.restore();
    }
    g5.opacity = l4;
  };
  u7.fillIntersecting = function (s9, S5, O8) {
    var D4, H3, R8, H6, Q2, q$, t$, f6, I2, Z4, w9e, W2, M2, o3;
    if (!O8 || O8 instanceof Array) {
      O8 = arguments[3];
    }
    D4 = O8.topBand;
    H3 = O8.bottomBand;
    R8 = O8.topSubBand;
    H6 = O8.bottomSubBand;
    Q2 = O8.topColor;
    q$ = O8.bottomColor;
    t$ = S5;
    if (t$.panel) {
      if (t$.outputs && t$.outputMap) {
        if (!Q2) {
          Q2 = t$.outputs[t$.outputMap[D4]];
        }
        if (!q$) {
          q$ = t$.outputs[t$.outputMap[H3]];
        }
      }
      t$ = t$.panel;
    }
    t$ = s9.panels[t$];
    f6 = s9.chart.context;
    I2 = f6.canvas;
    Z4 = s9.scratchContext;
    if (!Z4) {
      w9e = '2';
      w9e += 'd';
      Z4 = s9.scratchContext = I2.cloneNode(!!{}).getContext(w9e);
    }
    W2 = Z4.canvas;
    W2.height = I2.height;
    W2.width = I2.width;
    W2.context = Z4;
    u7.clearCanvas(W2, s9);
    M2 = 0.3;
    if (O8.opacity) {
      M2 = O8.opacity;
    }
    Z4.globalCompositeOperation = 'xor';
    s9.chart.context = Z4;
    o3 = {
      band: D4,
      subField: R8,
      color: Q2,
      opacity: 1,
      panelName: t$.name,
      yAxis: O8.topAxis,
      skipTransform: O8.skipTransform,
      tension: O8.tension,
      roundOffEdges: !![],
      step: O8.step,
    };
    S3p.t3P();
    u7.preparePeakValleyFill(s9, o3);
    u7.extend(o3, { band: H3, subField: H6, color: q$, yAxis: O8.bottomAxis });
    u7.preparePeakValleyFill(s9, o3);
    f6.save();
    f6.globalAlpha = M2;
    f6.drawImage(W2, 0, 0);
    f6.restore();
    s9.chart.context = f6;
  };
  u7.drawLegendItem = function (b9, q0, r0, Y$, n4) {
    var H1, T2, g2, w8, c2;
    if (!n4) {
      n4 = 1;
    }
    H1 = q0[0];
    T2 = q0[1];
    g2 = 10;
    w8 = 10;
    c2 = b9.chart.context;
    c2.globalAlpha = n4;
    c2.fillStyle = Y$;
    c2.fillRect(H1, T2, g2, w8);
    c2.globalAlpha = 1;
    S3p.Y3Y();
    S3p.d$R(18);
    H1 += S3p.H9P(2, g2);
    c2.fillStyle = b9.defaultColor;
    c2.fillText(r0, H1, T2);
    S3p.p1T(18);
    var J_b = S3p.H9P(5, 1);
    H1 += c2.measureText(r0).width + J_b;
    return [H1, T2];
  };
  S3p.t3P();
  u7.drawLegend = function (J7, h3) {
    var U3k, H9, Y0, E4, J9, p9, m7, j9, T0, f9, P9, K_;
    U3k = 's';
    U3k += 't';
    U3k += 'x';
    U3k += '-legend';
    H9 = h3.coordinates;
    Y0 = J7.chart.context;
    Y0.textBaseline = 'top';
    E4 = Y0.font;
    J7.canvasFont(U3k, Y0);
    J9 = h3.chart || J7.chart;
    if (!H9) {
      H9 = J9.legend;
    }
    p9 = [H9.x, H9.y];
    m7 = J7.defaultColor;
    for (var c5 = 0; c5 < 2; c5++) {
      for (var y9 in h3.legendColorMap) {
        j9 = h3.legendColorMap[y9];
        if (j9.isBase && (c5 || h3.noBase)) continue;
        if (!j9.isBase && !c5) continue;
        if (j9.color instanceof Array) {
          f9 = j9.color;
          for (T0 = f9.length - 1; T0 >= 0; T0--) {
            if (u7.isTransparent(f9[T0])) {
              f9.splice(T0, 1);
            }
          }
          if (f9.length > 1) {
            P9 = Y0.createLinearGradient(
              p9[0],
              p9[1],
              p9[0] + ('10' ^ 0),
              p9[1]
            );
            for (T0 = '0' >> 0; T0 < f9.length; T0++) {
              P9.addColorStop(T0 / (f9.length - 1), f9[T0]);
            }
            m7 = P9;
          } else if (f9.length > 0) {
            m7 = f9[0];
          } else {
            m7 = J7.getCanvasColor('stx_line_chart');
          }
        } else if (j9.color) {
          m7 = j9.color;
        } else {
          m7 = null;
        }
        if (m7) {
          K_ = y9;
          if (j9.display) {
            K_ = j9.display;
          }
          if (!K_) {
            if (J9.symbolDisplay) {
              K_ = J9.symbolDisplay;
            } else {
              K_ = J9.symbol;
            }
          }
          if (p9[0] + Y0.measureText(K_).width > J9.panel.right) {
            S3p.d$R(27);
            var U4i = S3p.H9P(44184, 20, 480281, 11);
            S3p.d$R(28);
            var C4d = S3p.H9P(20, 318, 3792);
            S3p.d$R(29);
            var q_b = S3p.M3e(3, 23, 9, 2686);
            S3p.p1T(30);
            var h9S = S3p.M3e(4, 10, 1084, 17);
            S3p.p1T(12);
            var l9Q = S3p.M3e(2, 5211, 12350, 14);
            S3p.p1T(31);
            var I$O = S3p.H9P(9568, 9586, 4793);
            S3p.d$R(32);
            var f9E = S3p.M3e(5004, 1668, 14, 18, 78);
            S3p.p1T(33);
            var t1_ = S3p.M3e(5, 20, 169, 13, 6);
            p9 = [
              H9.x,
              H9.y +
                Y0.measureText(
                  U4i > C4d
                    ? q_b == 935.41
                      ? 837.37
                      : (h9S, l9Q) > (I$O, 3691)
                      ? !'1'
                      : 'M'
                    : f9E
                ).width +
                t1_,
            ];
          }
          p9 = u7.drawLegendItem(J7, p9, K_, m7, j9.opacity);
        }
      }
    }
    Y0.font = E4;
  };
};
U = (Z0) => {
  var o2;
  o2 = Z0.CIQ;
  o2.colorsEqual = function (I6, s4) {
    var W_T, V9, B2, v7, z2, v8;
    W_T = 'rg';
    W_T += 'b';
    W_T += 'a(0,0';
    W_T += ',0,0)';
    if (I6 == s4) {
      return !'';
    }
    if (!I6 && !s4) {
      return !'';
    }
    if (!I6 || !s4) {
      return !!0;
    }
    if (I6 == 'transparent') {
      I6 = 'rgba(0,0,0,0)';
    }
    if (s4 == 'transparent') {
      s4 = W_T;
    }
    V9 = /^rgba\(.*,(.+)\)/;
    B2 = I6.match(V9);
    v7ZLk.t3P();
    v7 = s4.match(V9);
    B2 = B2 ? parseFloat(B2[1]) : 1;
    v7 = v7 ? parseFloat(v7[1]) : 1;
    if (B2 != v7) {
      return !!0;
    }
    z2 = o2.colorToHex(I6);
    v8 = o2.colorToHex(s4);
    return z2.toLowerCase() == v8.toLowerCase();
  };
  o2.colorToHex = function (Z$) {
    var W$U = v7ZLk;
    var j0, R_, G2, S2, a4, B3, k3, y_;
    if (!o2.colorToHexMapping) {
      o2.colorToHexMapping = {};
    }
    if (!Z$ || Z$ == 'transparent') {
      Z$ = '#000000';
    }
    if (o2.colorToHexMapping[Z$]) {
      return o2.colorToHexMapping[Z$];
    }
    if (
      Z$.substr(0, 1) ===
      (8360 !== (2894, 558) ? (1511 == 2189 ? 0x7c3 : '#') : (0x2429, 6.02e3))
    ) {
      if (Z$.length == ('4' | 4)) {
        W$U.d$R(27);
        var k7v = W$U.H9P(18, 6, 179, 15);
        W$U.d$R(34);
        var w94 = W$U.H9P(135, 4, 21, 13);
        W$U.d$R(35);
        var X8v = W$U.H9P(19, 17);
        W$U.d$R(0);
        var Q7d = W$U.H9P(15, 14);
        W$U.p1T(36);
        var z4h = W$U.M3e(18, 39, 16, 8, 1);
        W$U.p1T(35);
        var R7A = W$U.H9P(2, 1);
        Z$ = o2.colorToHexMapping[Z$] =
          '#' +
          Array(3).join(Z$.substr(k7v, w94)) +
          Array(3).join(Z$.substr(X8v, Q7d)) +
          Array(3).join(Z$.substr(z4h, R7A));
      }
      return Z$;
    }
    j0 = /(.*?)rgb\((\d+), ?(\d+), ?(\d+)\)/.exec(Z$);
    if (!j0) {
      j0 = /(.*?)rgba\((\d+), ?(\d+), ?(\d+),.*\)/.exec(Z$);
    }
    if (!j0) {
      R_ = P8(Z$);
      o2.colorToHexMapping[Z$] = R_;
      return R_;
    }
    G2 = parseFloat(j0[2]);
    S2 = parseFloat(j0['3' ^ 0]);
    function P8(G5) {
      var v$E, W3Z, T1, l5I, I5$, o5;
      v$E = 'c';
      v$E += 'o';
      v$E += 'l';
      v$E += 'or';
      W3Z = 'undefi';
      W3Z += 'n';
      W3Z += 'e';
      W3Z += 'd';
      if (typeof document === W3Z) {
        return '#000000';
      }
      T1 = document.querySelector('.ciq_color_converter');
      if (!T1) {
        l5I = 'n';
        l5I += 'o';
        l5I += 'n';
        l5I += 'e';
        I5$ = 't';
        I5$ += 'ext';
        I5$ += 'a';
        I5$ += 'rea';
        T1 = document.createElement(I5$);
        T1.className = 'ciq_color_converter';
        T1.style.display = l5I;
        document.body.appendChild(T1);
      }
      T1.style.color = '#000000';
      T1.style.color = G5;
      o5 = getComputedStyle(T1).getPropertyValue(v$E);
      j0 = /(.*?)rgb\((\d+), ?(\d+), ?(\d+)\)/.exec(o5);
      if (j0) {
        return o2.colorToHex(o5);
      } else if (
        o5.substr(0, 1) ===
        ((854.2, 734.26) != (4262, 5090)
          ? (6730, 462.39) > 400
            ? '#'
            : 0x1a9a
          : (0x1d38, 0x1343))
      ) {
        return o5;
      }
      W$U.t3P();
      return G5;
    }
    a4 = parseFloat(j0['4' ^ 0]);
    W$U.d$R(37);
    B3 = W$U.H9P(8, a4, '16', S2, G2);
    k3 = B3.toString(16);
    for (var k_ = k3.length; k_ < 6; k_++) {
      W$U.d$R(18);
      k3 = W$U.M3e(
        k3,
        +'761.72' === (539, 1810) ? (815 === 549 ? 794 : !'1') : '0'
      );
    }
    W$U.p1T(0);
    var D4Y = W$U.H9P(18, 17);
    W$U.p1T(38);
    var k09 = W$U.M3e(20, 3, 7, 1);
    W$U.d$R(39);
    var B_v = W$U.H9P(9, 8, 7, 850, 427557);
    W$U.p1T(18);
    var l$r = W$U.M3e(11, 569);
    W$U.d$R(34);
    var H5t = W$U.M3e(5561, 3, 1892, 19);
    W$U.d$R(40);
    var l0n = W$U.M3e(13286, 7, 92053);
    y_ =
      j0[D4Y] +
      ((k09, B_v) >= 38.56
        ? '#'
        : l$r < (H5t, l0n)
        ? (837.68, +'0xc5b')
        : 'v') +
      k3;
    o2.colorToHexMapping[Z$] = y_;
    return y_;
  };
  o2.hexToRgba = function (z6, X7) {
    var e9W = v7ZLk;
    var N0s, q_Y, K6, O3, f8, d4, e_;
    N0s = 'r';
    N0s += 'g';
    N0s += 'b';
    N0s += 'a';
    if (!z6 || z6 == 'transparent') {
      z6 = 'rgba(0,0,0,0)';
    }
    if (z6.substr(0, 4) === N0s) {
      q_Y = 'rgb';
      q_Y += 'a(';
      K6 = /(.*?)rgba\((\d+), ?(\d+), ?(\d+), ?(\d*\.?\d*)\)/.exec(z6);
      O3 = K6[5];
      if (X7 || X7 === 0) {
        O3 = X7;
      }
      if (O3 > '1' - 0) {
        e9W.d$R(41);
        O3 = e9W.H9P(100, O3);
      }
      e9W.p1T(40);
      var x_J = e9W.M3e(30, 6, 178);
      e9W.d$R(8);
      var e7K = e9W.M3e(3, 42, 42);
      e9W.p1T(42);
      var o8s = e9W.H9P(10, 12, 1, 9, 3);
      e9W.p1T(0);
      var z96 = e9W.M3e(154000, 146300);
      e9W.p1T(43);
      var f9T = e9W.M3e(5508, 7, 612, 4, 8);
      return (
        q_Y +
        K6[x_J] +
        ',' +
        K6[e7K] +
        ',' +
        K6[o8s] +
        ',' +
        O3 +
        (z96 !== +'4820' ? ')' : f9T)
      );
    } else if (z6.substr(0, 3) === 'rgb') {
      z6 = o2.colorToHex(z6);
    }
    if (!X7 && X7 !== ('0' ^ 0)) {
      X7 = 100;
    }
    if (X7 <= +'1') {
      e9W.d$R(25);
      X7 = e9W.H9P(100, X7);
    }
    z6 = z6.replace('#', '');
    e9W.t3P();
    f8 = parseInt(z6.slice(0, '2' << 96), 16);
    d4 = parseInt(z6.slice(2, 4), 16);
    e_ = parseInt(z6.slice(4, 6), '16' - 0);
    if (isNaN(f8) || isNaN(d4) || isNaN(e_)) {
      console.log('CIQ.hexToRgba: invalid hex :', z6);
      return null;
    }
    e9W.p1T(44);
    return e9W.H9P(
      100,
      ',',
      (+'2060', 2530) < (4550, 7270)
        ? '8900' << 0 > (7299, 967.97)
          ? 732 < (676.14, 581.07)
            ? 0x877
            : ')'
          : (0x6c7, 1.84e3)
        : 0x223a,
      'rgba(',
      (9970, 9313) === (2250, 6740)
        ? ('N', ![])
        : (917.3, 341) !== (469.52, +'4900')
        ? ','
        : !!'1',
      f8,
      X7,
      ',',
      e_,
      d4
    );
  };
  o2.convertToNativeColor = function (d9) {
    var L9G, g8K, c2g, d6, G9;
    L9G = -610968547;
    g8K = -744292451;
    c2g = 2;
    for (
      var D3s = '1' ^ 0;
      v7ZLk.q78(D3s.toString(), D3s.toString().length, +'2369') !== L9G;
      D3s++
    ) {
      d6 = document.createElement('');
      c2g += 2;
    }
    if (v7ZLk.q78(c2g.toString(), c2g.toString().length, 89904) !== g8K) {
      d6 = document.createElement('DIV');
    }
    d6.style.color = d9;
    d6.style.display = 'none';
    document.body.appendChild(d6);
    G9 = getComputedStyle(d6).color;
    document.body.removeChild(d6);
    return G9;
  };
  o2.isTransparent = function (T5) {
    var i8r, N_;
    i8r = 'tran';
    i8r += 'sparent';
    if (!T5) {
      return !!0;
    }
    if (T5 == i8r) {
      return !![];
    }
    N_ = /(.*?)rgba\((\d+), ?(\d+), ?(\d+), ?(\d*\.?\d*)\)/.exec(T5);
    if (N_ === null) {
      return !!'';
    }
    if (parseFloat(N_[5]) === 0) {
      return !!{};
    }
    return !{};
  };
  o2.hsv = function (o7) {
    var Y1k = v7ZLk;
    var K5, h7, r1, r2, u5, m1, C2, I_, x9, P_, E3, j9j, q5D, x6S;
    K5 = o2.colorToHex(o7);
    if (
      K5.substr(0, 1) ===
      ((5040, +'8050') === (3410, 4870)
        ? 164.83
        : (7750, 9860) > 6908
        ? '#'
        : !!'')
    ) {
      K5 = K5.slice(1);
    }
    for (var g1 = K5.length; g1 < 6; g1++) {
      Y1k.p1T(18);
      K5 = Y1k.M3e(
        K5,
        3100 < 4100
          ? '0'
          : (5290, 693.84) !== (8310, 470)
          ? 5188 == 453.94
            ? '526.32' * 1
            : 0x1ab7
          : 4.02e3
      );
    }
    h7 = parseInt(K5.slice(0, +'2'), 16);
    r1 = parseInt(K5.slice(2, +'4'), 16);
    r2 = parseInt(K5.slice(4, 6), +'16');
    u5 = +'0';
    m1 = 0;
    C2 = 0;
    h7 = parseInt(('' + h7).replace(/\s/g, ''), 10);
    r1 = parseInt(('' + r1).replace(/\s/g, ''), 10);
    r2 = parseInt(('' + r2).replace(/\s/g, ''), '10' >> 32);
    if (
      h7 === null ||
      r1 === null ||
      r2 === null ||
      isNaN(h7) ||
      isNaN(r1) ||
      isNaN(r2)
    ) {
      console.log('CIQ.hsv: invalid color :', o7);
      return null;
    }
    if (
      h7 < 0 ||
      r1 < +'0' ||
      r2 < '0' * 1 ||
      h7 > ('255' ^ 0) ||
      r1 > '255' >> 0 ||
      r2 > 255
    ) {
      return null;
    }
    Y1k.d$R(41);
    h7 = Y1k.M3e(255, h7);
    Y1k.d$R(45);
    r1 = Y1k.H9P(r1, '255');
    Y1k.p1T(41);
    r2 = Y1k.M3e(255, r2);
    I_ = Math.min(h7, Math.min(r1, r2));
    x9 = Math.max(h7, Math.max(r1, r2));
    if (I_ == x9) {
      C2 = I_;
      Y1k.d$R(25);
      return [Y1k.H9P(1, '0'), 0, C2];
    }
    P_ = h7 == I_ ? r1 - r2 : r2 == I_ ? h7 - r1 : r2 - h7;
    E3 = h7 == I_ ? 3 : r2 == I_ ? '1' << 32 : 5;
    Y1k.d$R(20);
    j9j = Y1k.M3e('730284066', 64);
    q5D = 216150524;
    x6S = +'2';
    for (
      var c9c = 1;
      Y1k.e_M(c9c.toString(), c9c.toString().length, 4271) !== j9j;
      c9c++
    ) {
      Y1k.p1T(46);
      u5 = Y1k.M3e(x9, P_, '77', E3, I_);
      x6S += 2;
    }
    if (Y1k.q78(x6S.toString(), x6S.toString().length, 25316) !== q5D) {
      Y1k.p1T(47);
      u5 = Y1k.M3e(x9, I_, E3, P_, 60);
    }
    Y1k.p1T(48);
    m1 = Y1k.M3e(I_, x9, x9);
    C2 = x9;
    return [u5, m1, C2];
  };
  o2.hsl = function (U9) {
    var A44 = v7ZLk;
    var F8, e4, j3, n$, W8, V5, W$, P4, X1, w0;
    F8 = o2.colorToHex(U9);
    if (
      F8.substr(0, 1) ===
      ((8441, 5250) < (+'519.81', 5340)
        ? 1414 == +'686'
          ? ('3.33e+3' >> 64, 'g')
          : (6780, 303.93) > (3249, 823.91)
          ? (!![], 't')
          : '#'
        : 344.62)
    ) {
      F8 = F8.slice(1);
    }
    for (var T9 = F8.length; T9 < 6; T9++) {
      A44.d$R(18);
      F8 = A44.M3e(
        F8,
        (806.34, 116.86) == +'5070'
          ? 474 > 5621
            ? (534.48, 363.27)
            : (0x10e4, !![])
          : '0'
      );
    }
    e4 = parseInt(F8.slice('0' ^ 0, 2), '16' >> 0);
    j3 = parseInt(F8.slice('2' | 2, 4), 16);
    n$ = parseInt(F8.slice(4, 6), '16' - 0);
    e4 /= 255;
    j3 /= 255;
    n$ /= 255;
    W8 = Math.max(e4, j3, n$);
    A44.Y3Y();
    V5 = Math.min(e4, j3, n$);
    A44.p1T(49);
    X1 = A44.M3e(V5, W8, 2);
    if (W8 == V5) {
      W$ = P4 = 0;
    } else {
      A44.p1T(0);
      w0 = A44.H9P(W8, V5);
      P4 = X1 > '0.5' * 1 ? w0 / (2 - W8 - V5) : w0 / (W8 + V5);
      switch (W8) {
        case e4:
          A44.p1T(9);
          W$ = A44.M3e(n$, w0, j3, j3 < n$ ? 6 : 0);
          break;
        case j3:
          A44.d$R(9);
          W$ = A44.M3e(e4, w0, n$, 2);
          break;
        case n$:
          A44.d$R(9);
          W$ = A44.H9P(j3, w0, e4, 4);
          break;
      }
      W$ /= 6;
    }
    return [W$, P4, X1];
  };
  o2.hslToRgb = function (q5, f0, o6) {
    var i8H = v7ZLk;
    var h_, q9, n6, L9, J1, m5, X4H, F89, a47;
    i8H.Y3Y();
    if (f0 === 0) {
      h_ = q9 = n6 = o6;
    } else {
      L9 = function T_(Y2, z9, D_) {
        if (D_ < 0) {
          D_ += 1;
        }
        if (D_ > 1) {
          D_ -= 1;
        }
        if (D_ < 1 / 6) {
          i8H.d$R(50);
          return i8H.H9P(6, Y2, Y2, z9, D_);
        }
        if (D_ < +'1' / 2) {
          return z9;
        }
        if (D_ < +'2' / 3) {
          i8H.p1T(51);
          return i8H.M3e(6, z9, 64, '2', Y2, D_, '3', Y2);
        }
        return Y2;
      };
      J1 = o6 < 0.5 ? o6 * (1 + f0) : o6 + f0 - o6 * f0;
      i8H.d$R(40);
      m5 = i8H.H9P(2, o6, J1);
      i8H.p1T(52);
      h_ = L9(m5, J1, i8H.M3e('1', q5, 3));
      X4H = -384436264;
      F89 = -411689702;
      a47 = 2;
      for (
        var H4x = 1;
        i8H.e_M(H4x.toString(), H4x.toString().length, 73021) !== X4H;
        H4x++
      ) {
        q9 = L9(m5, J1, q5);
        i8H.d$R(53);
        n6 = L9(m5, J1, i8H.H9P(q5, '3', 0, 1));
        a47 += 2;
      }
      if (i8H.e_M(a47.toString(), a47.toString().length, 20019) !== F89) {
        q9 = L9(m5, J1, q5);
        i8H.d$R(54);
        n6 = L9(m5, J1, i8H.M3e(q5, 7, 2));
      }
    }
    i8H.p1T(55);
    return [
      Math.round(i8H.M3e('255', 48, h_)),
      Math.round(i8H.M3e(255, q9, i8H.p1T(25))),
      Math.round(i8H.M3e(255, n6, i8H.p1T(25))),
    ];
  };
  o2.chooseForegroundColor = function (x$) {
    v7ZLk.Y3Y();
    var u4, U7, o$, H4;
    u4 = o2.colorToHex(x$);
    U7 = parseInt(u4.slice(1, '3' | 2), 16);
    o$ = parseInt(u4.slice('3' ^ 0, 5), 16);
    H4 = parseInt(u4.slice(5, 7), +'16');
    return 0.2126 * U7 + 0.7152 * o$ + +'0.0722' * H4 < 100
      ? '#FFFFFF'
      : '#000000';
  };
  o2.borderPatternToArray = function (Z2, k8) {
    var Q0_ = v7ZLk;
    var R7v;
    R7v = '"; defaulting to "';
    R7v += 'solid"';
    if (!k8) {
      return [];
    }
    if (k8 instanceof Array) {
      return k8;
    }
    if (k8 == 'dotted') {
      return [Z2, Z2];
    }
    if (k8 == 'dashed') {
      Q0_.d$R(25);
      return [Q0_.M3e(5, Z2), Q0_.H9P(Z2, '5', Q0_.p1T(56))];
    }
    if (k8 != 'solid' && k8 != 'none') {
      Q0_.p1T(28);
      console.log(Q0_.H9P(k8, 'Unsupported pattern "', R7v));
    }
    return [];
  };
  o2.getBackgroundColor = function (Y1) {
    var b2, l6;
    b2 = null;
    while (!b2 || o2.isTransparent(b2)) {
      l6 = getComputedStyle(Y1);
      if (!l6) {
        return;
      }
      b2 = l6.backgroundColor;
      if (o2.isTransparent(b2)) {
        b2 = 'transparent';
      }
      Y1 = Y1.parentNode;
      if (!Y1 || !Y1.tagName) break;
    }
    if (!b2 || b2 == 'transparent') {
      b2 = '#FFFFFF';
    }
    return b2;
  };
};
u = (q3) => {
  var a5h = v7ZLk;
  var S4D, M1N, H_u, X89, G_, P7;
  S4D = 'D';
  S4D += 'e';
  S4D += 'c';
  M1N = 'J';
  M1N += 'ul';
  H_u = 'J';
  H_u += 'u';
  H_u += 'n';
  X89 = 'M';
  X89 += 'a';
  X89 += 'r';
  G_ = q3.CIQ;
  P7 = q3.timezoneJS;
  G_.monthLetters = [
    (701.9, 579.42) == (2540, 9092) ? (0x1065, 'S') : 'J',
    'F',
    '3790' * 1 == 867.96 ? (!!1, !{}) : 'M',
    5086 > 628.15 ? 'A' : 9800 === 3580 ? 501.26 : '197.41' * 1,
    (755.94, 4815) < (8118, '406' ^ 0)
      ? 7795 === 894.83
        ? 0xc9a
        : (618.51, +'3351') !== (3260, 3620)
        ? !1
        : (!![], 'k')
      : 'M',
    6959 != (941.15, +'744.95')
      ? 7502 >= ('7810' >> 32, 9300)
        ? ('3900' | 20) >= ('4010' >> 64, 256.09)
          ? '8.13e+2' | 13
          : (7.96e3, !!'')
        : 'J'
      : !!{},
    'J',
    'A',
    2800 == ('9250' | 0, 7379)
      ? (2380, '5430' | 22) !== +'913.37'
        ? ('3121' - 0, 3980) <= (9892, +'3067')
          ? (![], '9.99e+3' ^ 0)
          : (!![], 0x46e)
        : 'x'
      : 'S',
    'O',
    738.62 == 3820 ? !![] : 'N',
    'D',
  ];
  G_.monthAbv = [
    'Jan',
    'Feb',
    X89,
    'Apr',
    'May',
    H_u,
    M1N,
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    S4D,
  ];
  G_.MILLISECOND = 1;
  a5h.p1T(20);
  G_.SECOND = a5h.H9P('1000', 96);
  a5h.p1T(57);
  var j5O = a5h.H9P(15, 4, 19, 41, 20);
  G_.MINUTE = j5O * G_.SECOND;
  a5h.d$R(58);
  var S2r = a5h.M3e(1, 14, 44, 150, 300);
  G_.HOUR = S2r * G_.MINUTE;
  a5h.p1T(10);
  var G6L = a5h.H9P(8, 48, 6, 12);
  G_.DAY = G6L * G_.HOUR;
  G_.WEEK = ('7' - 0) * G_.DAY;
  G_.MONTH = +'31' * G_.DAY;
  a5h.p1T(59);
  var Z$J = a5h.H9P(349, 1, 7, 10);
  G_.YEAR = Z$J * G_.DAY;
  a5h.d$R(60);
  var U$N = a5h.M3e(2, 1, 19, 9);
  a5h.d$R(0);
  var F7T = a5h.H9P(105, 98);
  G_.DECADE = U$N * G_.YEAR - F7T * G_.DAY;
  G_.yyyymmddhhmmssmmmrx = new RegExp('\\d{17}');
  G_.strToDateTime = function (n5) {
    var O$, M8, Y4, s_, N0, m9, B_, J3, u8, b3, P0, D6, F5, j4z, v0O, k$, u$;
    if (!n5 || n5.getFullYear) {
      return n5;
    }
    O$ = [];
    if (n5.length == 12 || n5.length == 14) {
      M8 = parseFloat(n5.substring(0, 4));
      a5h.p1T(61);
      var n9K = a5h.H9P(22, 1, 19);
      a5h.p1T(0);
      var V$C = a5h.H9P(20, 14);
      a5h.d$R(8);
      var a2x = a5h.M3e(13, 0, 12);
      Y4 = parseFloat(n5.substring(n9K, V$C)) - a2x;
      s_ = parseFloat(n5.substring(6, '8' << 64));
      N0 = parseFloat(n5.substring('8' | 8, 10));
      m9 = parseFloat(n5.substring(10, 12));
      B_ = parseFloat(n5.substring(+'12', 14)) || +'0';
      return new Date(M8, Y4, s_, N0, m9, B_, 0);
    } else if (G_.yyyymmddhhmmssmmmrx.test(n5)) {
      M8 = parseFloat(n5.substring(0, 4));
      a5h.p1T(35);
      var a6i = a5h.H9P(19, 13);
      a5h.p1T(62);
      var t5M = a5h.H9P(18, 7, 19, 6);
      Y4 = parseFloat(n5.substring('4' >> 0, a6i)) - t5M;
      s_ = parseFloat(n5.substring(6, 8));
      N0 = parseFloat(n5.substring(8, 10));
      m9 = parseFloat(n5.substring(10, '12' * 1));
      B_ = parseFloat(n5.substring(12, 14));
      J3 = parseFloat(n5.substring(14, 17));
      return new Date(M8, Y4, s_, N0, m9, B_, J3);
    }
    u8 = [n5];
    b3 = n5.indexOf('T');
    if (b3 != -1) {
      P0 = n5.substring(b3);
      if (
        P0.indexOf(
          (723.31, 2740) < 9603
            ? (812, 4418) >= 256.13
              ? 'Z'
              : +'4.32e+3'
            : (961.66, 5.03e2)
        ) != -1 ||
        P0.indexOf(
          476.16 > 2.59
            ? +'619' === (2976, 7710)
              ? (+'3608', 2970) >= (5510, 6520)
                ? (+'0x6f6', 'Z')
                : (348.31, !!{})
              : '-'
            : 0x1845
        ) != -1 ||
        P0.indexOf(
          (4930, 2536) != 8353 ? (742 < '624.87' - 0 ? 'r' : '+') : ('G', 'g')
        ) != -1
      ) {
        return new Date(n5);
      }
      u8 = n5.split((328.55, 867.77) < 189.12 ? 569.85 : 'T');
    } else if (n5.indexOf(6970 !== +'777' ? ' ' : (!!0, !!1)) != -1) {
      u8 = n5.split(
        '5390' * 1 != (707.72, 790.21)
          ? +'7588' <= 864.31
            ? 'h'
            : (+'24.68', 986) == (3192, 5580)
            ? (7.76e3, 0x1bea)
            : ' '
          : !'1'
      );
    }
    a5h.t3P();
    if (
      u8[0].indexOf(
        ('8340' | 0, 7285) < 1070
          ? ('e', 180.86)
          : (7121, 1615) < (3729, 155.95)
          ? ![]
          : '/'
      ) != -1
    ) {
      O$ = u8[0].split('/');
    } else if (u8['0' << 64].indexOf('-') != -1) {
      O$ = u8[0].split(
        ('2589' * 1, '1800' >> 32) == 3240
          ? ('0x1ab5' << 32, 'O')
          : ('7479' >> 0, 259.82) <= (+'2160', 4670)
          ? 3545 < (941.28, +'935.86')
            ? (0x9b3, 'c')
            : '-'
          : 0xf86
      );
    } else {
      return G_.strToDate(n5);
    }
    D6 = parseFloat(O$[2], +'10');
    if (O$[0] && O$[0].length == 4) {
      D6 = parseFloat(O$[+'0'], 10);
      a5h.d$R(25);
      O$[0] = O$[a5h.M3e(1, '1')];
      O$[+'1'] = O$[2];
    }
    if (u8.length > 1) {
      F5 = u8[2];
      u8 = u8[1].split(
        (+'93', 971) <= (6257, 8340)
          ? ('362.79' - 0, 7346) === 998
            ? 6.14e3
            : 785.16 >= ('134' | 0, 979.26)
            ? 0x232f
            : ':'
          : (367.02, +'0x21b8')
      );
      if (F5) {
        j4z = '1';
        j4z += '2';
        v0O = 'A';
        v0O += 'M';
        if (u8[0] == '12' && F5.toUpperCase() == v0O) {
          u8[0] = 0;
        } else if (u8[0] != j4z && F5.toUpperCase() == 'PM') {
          a5h.d$R(0);
          var C6Z = a5h.M3e(110, 100);
          u8[0] = parseInt(u8[0], C6Z) + ('12' ^ 0);
        }
      }
      k$ = 0;
      u$ = 0;
      if (u8.length == 3) {
        if (
          u8[2].indexOf(
            (658.02, 1360) > (209.51, 392.38)
              ? 1545 > ('5390' - 0, 7560)
                ? 833.96 !== (+'2078', 834)
                  ? (![], 637.64)
                  : (1.47e3, !'1')
                : '.'
              : 0xbf0
          ) == -('1' ^ 0)
        ) {
          k$ = parseInt(u8[2], +'10');
        } else {
          k$ = u8[2].split(
            (267.53, 2863) == 154.9
              ? (!'', 8.09e3)
              : (7669, '407.26' * 1) > 5790
              ? 2.32e3
              : ('753.04' - 0, 569.58) <= (6225, 3390)
              ? '.'
              : (0x1a87, +'498.50')
          );
          if (k$[1].length == 3) {
            u$ = k$[1];
            k$ = k$[0];
          }
        }
      }
      a5h.p1T(35);
      var h23 = a5h.M3e(11, 10);
      a5h.d$R(20);
      return new Date(
        D6,
        O$[+'0'] - h23,
        O$[1],
        u8[0],
        u8[a5h.M3e('1', 32)],
        k$,
        u$
      );
    }
    a5h.d$R(40);
    var O6p = a5h.H9P(11, 17, 186);
    a5h.d$R(0);
    return new Date(D6, O$[0] - O6p, O$[1], 0, 0, a5h.M3e('0', 0), +'0');
  };
  G_.strToDate = function (i_) {
    var X8, l$;
    if (
      i_.indexOf(
        (932.35, 4175) !== (9090, 3231)
          ? 8060 > (3270, 5850)
            ? (75.75, 5952) === 6193
              ? (+'634', 704.31)
              : '/'
            : 4.69e3
          : 0x92f
      ) != -1
    ) {
      X8 = i_.split(('315.65' - 0, 603) > (4000, 2990) ? (!0, 'G') : '/');
    } else if (i_.indexOf('-') != -1) {
      X8 = i_.split(
        (5809, 348) === 793
          ? (!!1, 0xc3f)
          : 5063 !== 8670
          ? 6640 == ('44.33' * 1, 104)
            ? (!0, 1.11e3)
            : '-'
          : 'Z'
      );
    } else if (i_.length >= 8) {
      a5h.d$R(63);
      var I1Z = a5h.M3e(2, 2, 5, 20);
      a5h.p1T(64);
      var Q6V = a5h.M3e(11, 7, 24);
      a5h.p1T(65);
      var u8f = a5h.H9P(4, 19, 77);
      return new Date(
        parseFloat(i_.substring(0, +'4')),
        parseFloat(i_.substring(I1Z, Q6V)) - u8f,
        parseFloat(i_.substring(6, 8))
      );
    } else {
      return new Date();
    }
    if (X8.length < 3) {
      return new Date();
    }
    if (X8[2].indexOf(' ') != -1) {
      X8[2] = X8[2].substring(0, X8[+'2'].indexOf(' '));
    } else if (X8[2].indexOf('T') != -('1' - 0)) {
      X8[2] = X8[2].substring(0, X8['2' | 2].indexOf('T'));
    }
    l$ = parseFloat(X8[2], 10);
    a5h.t3P();
    if (l$ < 20) {
      l$ += 2000;
    }
    if (X8[0].length == +'4') {
      l$ = parseFloat(X8[0], 10);
      X8[0] = X8[1];
      a5h.d$R(0);
      X8[1] = X8[a5h.M3e('2', 0)];
    }
    return new Date(l$, X8['0' - 0] - ('1' - 0), X8[+'1']);
  };
  G_.dateToStr = function (X$, X6) {
    var C2x, Z3, u0, w6, O6;
    a5h.t3P();
    C2x = 'YY';
    C2x += 'Y';
    C2x += 'Y';
    Z3 = (L7) => {
      a5h.t3P();
      return X6.includes(L7);
    };
    u0 = (h2, e3 = 2) => {
      a5h.t3P();
      return ('0'.repeat(e3) + h2).slice(-e3);
    };
    w6 = (k9, o_) => {
      a5h.Y3Y();
      return (X6 = X6.replace(k9, o_));
    };
    O6 = (c4, w3) => {
      a5h.t3P();
      return c4 > w3 ? c4 - w3 : c4;
    };
    if (Z3(C2x)) {
      w6(/YYYY/g, X$.getFullYear());
    }
    if (Z3('MM')) {
      w6(/MM/g, u0(X$.getMonth() + ('1' ^ 0)));
    }
    if (Z3('dd')) {
      w6(/dd/g, u0(X$.getDate()));
    }
    if (Z3('HH')) {
      w6(/HH/g, u0(X$.getHours()));
    }
    if (Z3('hh')) {
      w6(/hh/g, u0(O6(X$.getHours() || 12, 12)));
    }
    if (Z3('mm')) {
      w6(/mm/g, u0(X$.getMinutes()));
    }
    if (Z3('ss')) {
      w6(/ss/g, u0(X$.getSeconds()));
    }
    if (Z3('SSS')) {
      w6(/SSS/g, u0(X$.getMilliseconds(), 3));
    }
    return X6;
  };
  G_.mmddyyyy = function (I1) {
    var D2b, V4, N6;
    D2b = 'strin';
    D2b += 'g';
    if (typeof I1 === D2b) {
      I1 = G_.strToDate(I1);
    }
    a5h.d$R(0);
    var G42 = a5h.H9P(9, 8);
    V4 = I1.getMonth() + G42;
    if (V4 < 10) {
      a5h.d$R(18);
      V4 = a5h.M3e(
        V4,
        (3463, 9360) !== ('5100' | 76)
          ? 75.23 >= (3747, 413.34)
            ? 0x3cf
            : (726, +'1120') != 1270
            ? '0'
            : (192, +'0x1b54')
          : 'H'
      );
    }
    N6 = I1.getDate();
    if (N6 < 10) {
      a5h.p1T(18);
      N6 = a5h.H9P(
        N6,
        (868.7, 854.74) > (6848, 4960)
          ? (3356, 5820) === (+'8450', 616)
            ? (8.54e3, 0x21ea)
            : (!!1, 0x1874)
          : '0'
      );
    }
    return V4 + '/' + N6 + '/' + I1.getFullYear();
  };
  G_.yyyymmdd = function (T8) {
    var C6, E6;
    a5h.t3P();
    C6 = T8.getMonth() + +'1';
    if (C6 < 10) {
      a5h.d$R(18);
      C6 = a5h.H9P(C6, '0');
    }
    E6 = T8.getDate();
    if (E6 < 10) {
      a5h.d$R(18);
      E6 = a5h.H9P(
        E6,
        4736 >= 8373
          ? 3980 != 7565
            ? 210.42 > 4103
              ? !{}
              : (9.45e3, 0x1868)
            : (938.9, !1)
          : '0'
      );
    }
    a5h.p1T(40);
    var B40 = a5h.M3e(2732, 4, 8186);
    a5h.p1T(66);
    var C55 = a5h.M3e(13, 16, 4, 21, 11);
    a5h.d$R(67);
    var Y4d = a5h.M3e(13, 10, 19, 21, 1);
    a5h.p1T(0);
    var X0O = a5h.M3e(9495, 5);
    a5h.d$R(0);
    var W5X = a5h.M3e(1715, 15);
    a5h.d$R(68);
    var S_m = a5h.M3e(3874, 9, 353, 15, 2);
    a5h.d$R(18);
    var D2V = a5h.M3e(18, 6789);
    return (
      T8.getFullYear() +
      (502.34 > 202.2
        ? (+'2900', B40) === '628.37' * C55
          ? +'5038' < ('771.81' * Y4d, 641.57)
            ? (X0O, 597.27)
            : ('a', 485.22)
          : '-'
        : (235.9, !'1')) +
      C6 +
      ((W5X, '7605' - 0) > (S_m, 410.4)
        ? (D2V, 586.69) > 678.76
          ? !!0
          : '-'
        : !{}) +
      E6
    );
  };
  G_.hhmm = function (K2) {
    var W_, C0;
    W_ = K2.getHours();
    a5h.t3P();
    if (W_ < '10' - 0) {
      a5h.d$R(18);
      W_ = a5h.H9P(W_, (4061, 9920) == (571, '5150' ^ 0) ? 0xb95 : '0');
    }
    C0 = K2.getMinutes();
    if (C0 < 10) {
      a5h.p1T(18);
      C0 = a5h.M3e(C0, '0');
    }
    a5h.d$R(28);
    return a5h.H9P(
      (801.33, 2021) !== (2806, 9130)
        ? (+'8980', 3391) <= 169.51
          ? 'N'
          : 3278 == ('9314' ^ 0)
          ? 3.87e2
          : ':'
        : ('d', 9.43e3),
      W_,
      C0
    );
  };
  G_.hhmmss = function (g8) {
    var C4;
    C4 = g8.getSeconds();
    if (C4 < 10) {
      a5h.d$R(18);
      C4 = a5h.H9P(C4, '0');
    }
    a5h.d$R(69);
    var V57 = a5h.M3e(990, 2, 19, 169, 1);
    a5h.d$R(70);
    var K9m = a5h.M3e(5682, 15, 7, 5682, 5555);
    a5h.d$R(71);
    var x3g = a5h.H9P(7, 1571, 182, 17, 10);
    a5h.d$R(0);
    var x1m = a5h.H9P(114323, 108306);
    return (
      G_.hhmm(g8) + ((V57, K9m) === (x3g, x1m) ? (17.16, 76.56) : ':') + C4
    );
  };
  G_.yyyymmddhhmm = function (L_) {
    return G_.yyyymmddhhmmssmmm(L_).substr(0, 12);
  };
  G_.yyyymmddhhmmssmmm = function (f4) {
    var g3, l2, a6, w1, S7, r8;
    g3 = f4.getMonth() + ('1' ^ 0);
    if (g3 < '10' * 1) {
      a5h.p1T(18);
      g3 = a5h.H9P(
        g3,
        (3748, '492.05' * 1) !== (945, 4570)
          ? 6303 === (577.21, 5343)
            ? 7.74e3
            : '0'
          : 0xec2
      );
    }
    l2 = f4.getDate();
    if (l2 < 10) {
      a5h.p1T(18);
      l2 = a5h.H9P(
        l2,
        (643.35, 426.08) == (+'2210', 449)
          ? '4640' * 1 != (+'805', '204' >> 64)
            ? 7789 !== (5794, 6720)
              ? (!!{}, !'')
              : ('S', 'v')
            : 'j'
          : '0'
      );
    }
    a6 = f4.getHours();
    if (a6 < 10) {
      a5h.d$R(18);
      a6 = a5h.M3e(a6, '0');
    }
    w1 = f4.getMinutes();
    if (w1 < 10) {
      a5h.p1T(18);
      w1 = a5h.H9P(w1, (436.34, +'582.77') > 4691 ? 0x8cc : '0');
    }
    S7 = f4.getSeconds();
    if (S7 < ('10' | 10)) {
      a5h.d$R(18);
      S7 = a5h.M3e(S7, '0');
    }
    r8 = f4.getMilliseconds();
    if (r8 < 10) {
      a5h.p1T(18);
      r8 = a5h.H9P(r8, '00');
    } else if (r8 < ('100' ^ 0)) {
      a5h.p1T(18);
      r8 = a5h.M3e(
        r8,
        '5940' << 0 <= (3568, 880) ? (844 <= 4810 ? !![] : 'V') : '0'
      );
    }
    return '' + f4.getFullYear() + g3 + l2 + a6 + w1 + S7 + r8;
  };
  G_.friendlyDate = function (W0) {
    a5h.t3P();
    return G_.dateToStr(W0, 'YYYY/MM/dd HH:mm');
  };
  G_.mmddhhmm = function (U_) {
    var s0_, H0m, D1z, G3, I4, P1, G7, R7, Z6, A6;
    s0_ = '00';
    s0_ += '0';
    H0m = '0';
    H0m += '00';
    D1z = '0';
    D1z += '0';
    G3 = G_.strToDateTime(U_);
    a5h.p1T(72);
    var C_v = a5h.H9P(13, 9, 1, 2);
    I4 = G3.getMonth() + C_v;
    if (I4 < 10) {
      a5h.p1T(18);
      I4 = a5h.H9P(I4, '0');
    }
    P1 = G3.getDate();
    if (P1 < 10) {
      a5h.d$R(18);
      P1 = a5h.H9P(
        P1,
        5540 === (2990, '915' >> 0)
          ? (0x1693, +'648.81')
          : 4686 < (503.41, 426.35)
          ? 'u'
          : 156 != (8670, 939.9)
          ? '0'
          : 0x259a
      );
    }
    G7 = G3.getHours();
    if (G7 < +'10') {
      a5h.p1T(18);
      G7 = a5h.M3e(
        G7,
        ('5281' - 0, 6314) !== (516.84, 5120)
          ? '0'
          : 9130 != 2000
          ? ('A', 'M')
          : (+'6.27e+2', !{})
      );
    }
    R7 = G3.getMinutes();
    if (R7 < 10) {
      a5h.p1T(18);
      R7 = a5h.M3e(
        R7,
        (145, 1420) >= (+'3862', 6210)
          ? (8606, 6225) !== (605.53, +'334')
            ? 'C'
            : (85.2, !![])
          : '0'
      );
    }
    Z6 = G3.getSeconds();
    if (Z6 < '10' << 64) {
      a5h.p1T(18);
      Z6 = a5h.H9P(Z6, '0');
    }
    A6 = G3.getMilliseconds();
    if (A6 < 10) {
      a5h.p1T(18);
      A6 = a5h.M3e(A6, D1z);
    } else if (A6 < +'100') {
      a5h.p1T(18);
      A6 = a5h.H9P(
        A6,
        (5910, 7340) == 2830
          ? (+'9.42e+3', +'7.16e+3')
          : +'168.82' > 677
          ? 757.46
          : '0'
      );
    }
    a5h.Y3Y();
    if (G7 == '00' && R7 == '00' && Z6 == '00' && A6 == H0m) {
      return I4 + '-' + P1 + '-' + G3.getFullYear();
    }
    if (Z6 == '00' && A6 == '000') {
      a5h.p1T(73);
      return a5h.M3e(
        ' ',
        (541, 3360) >= 5101 ? (0x1bb8, ![]) : ':',
        R7,
        P1,
        (7320, '523.74' - 0) === 5482
          ? ('0x2403' - 0, 9.28e3)
          : (991.89, 217) === (907.88, 513.48)
          ? (9.53e3, 931)
          : '-',
        I4,
        G7
      );
    }
    if (A6 == s0_) {
      a5h.d$R(74);
      return a5h.M3e(
        R7,
        G7,
        Z6,
        I4,
        '-',
        1100 <= 140.24
          ? ![]
          : (+'8586', 2020) === (240, 9825)
          ? (+'38.86', 0x1215)
          : ' ',
        (640, 19.81) == 9374
          ? +'1.06e+3'
          : '1725' * 1 >= +'5470'
          ? (0xc5d, +'7.28e+3')
          : +'2617' < '3680' << 96
          ? ':'
          : (7.32e3, 'g'),
        (885.14, 6072) == (413, 7473) ? !{} : ':',
        P1
      );
    }
    a5h.d$R(75);
    return a5h.H9P(
      (6749, 9.65) === 629.59 ? (5.15e3, 's') : '-',
      R7,
      A6,
      Z6,
      G7,
      ':',
      I4,
      (2508, '4990' | 34) > 2069
        ? (5947, 640.18) >= 8990
          ? 123.41
          : ' '
        : 615.67,
      1510 == (1824, 1240)
        ? 1112 <= (490.39, '3130' << 32)
          ? !!{}
          : 0x672
        : ':',
      223 != (778.17, 6980) ? ':' : !{},
      P1
    );
  };
  G_.getYearDay = function (U1) {
    var K4, y6, M6, L8, E7;
    K4 = U1;
    if (!K4) {
      K4 = new Date();
    }
    K4.setHours(0, 0, 0, 0);
    y6 = new Date(K4.getFullYear(), 0, 0);
    a5h.p1T(0);
    M6 = a5h.H9P(K4, y6);
    a5h.p1T(76);
    L8 = a5h.M3e(60, 24, '1000', 60);
    a5h.d$R(41);
    E7 = Math.round(a5h.H9P(L8, M6));
    return E7;
  };
  G_.getETDateTime = function () {
    var p9b, f2;
    p9b = 'Amer';
    p9b += 'ica/New_York';
    f2 = new Date();
    return G_.convertTimeZone(
      new Date(f2.getTime() + f2.getTimezoneOffset() * ('60000' >> 0)),
      'UTC',
      p9b
    );
  };
  G_.fromET = function (a5) {
    var j$, K1, l1, N$;
    j$ = new Date();
    K1 = +'4';
    if (
      j$.getMonth() < 2 ||
      (j$.getMonth() == 2 && j$.getDate() < 11) ||
      j$.getMonth() > 10 ||
      (j$.getMonth() == '10' - 0 && j$.getDate() >= +'4')
    ) {
      K1 = +'5';
    }
    a5h.d$R(34);
    a5h.t3P();
    var w1i = a5h.H9P(428400054, 6, 72000000, 9);
    l1 = a5.getTime() + w1i * K1;
    N$ = new Date(l1);
    return N$;
  };
  G_.monthAsDisplay = function (i7, f$, C3) {
    if (f$) {
      if (C3 && C3.monthLetters) {
        return C3.monthLetters[i7];
      }
      return G_.monthLetters[i7];
    }
    if (C3 && C3.monthAbv) {
      return C3.monthAbv[i7];
    }
    return G_.monthAbv[i7];
  };
  G_.timeAsDisplay = function (D8, X9, U4) {
    var i2, y8, w$, x5, w5;
    i2 = X9 ? X9.internationalizer : null;
    if (i2) {
      if (U4 == G_.SECOND) {
        return i2.hourMinuteSecond.format(D8);
      } else if (U4 == G_.MILLISECOND) {
        a5h.d$R(61);
        var Z03 = a5h.M3e(29, 6198, 17);
        a5h.d$R(77);
        var V1D = a5h.M3e(116261, 1621524, 11, 14);
        a5h.d$R(62);
        var A93 = a5h.H9P(20, 11, 61, 8);
        a5h.d$R(78);
        var X59 = a5h.H9P(1544, 119, 13, 18, 12);
        a5h.p1T(79);
        var v4n = a5h.M3e(4, 3, 2, 8, 4537);
        a5h.d$R(18);
        var c70 = a5h.H9P(7820, 782);
        return (
          i2.hourMinuteSecond.format(D8) +
          ((Z03, +'758.64') == V1D
            ? ('398.56' * A93, X59)
            : ('279.99' - 0, v4n) == +'8736'
            ? ('F', c70)
            : '.') +
          D8.getMilliseconds()
        );
      }
      return i2.hourMinute.format(D8);
    }
    y8 = D8.getMinutes();
    if (y8 < 10) {
      a5h.d$R(18);
      y8 = a5h.M3e(y8, '0');
    }
    w$ = D8.getHours() + ':' + y8;
    x5 = '';
    if (U4 <= G_.SECOND) {
      x5 = D8.getSeconds();
      if (x5 < 10) {
        a5h.d$R(18);
        x5 = a5h.H9P(x5, 2800 !== (326.55, 805) ? '0' : (8.17e3, !0));
      }
      a5h.p1T(18);
      w$ += a5h.H9P(
        x5,
        (+'457.59', '314.08' - 0) > (+'3386', 7640)
          ? 281.58
          : 752.57 != (3320, 2300)
          ? ':'
          : ('J', 108.71)
      );
    }
    a5h.t3P();
    if (U4 == G_.MILLISECOND) {
      w5 = D8.getMilliseconds();
      if (w5 < 10) {
        a5h.p1T(18);
        w5 = a5h.M3e(w5, '00');
      } else if (w5 < 100) {
        a5h.p1T(18);
        w5 = a5h.H9P(
          w5,
          ('4420' * 1, 8001) >= 236.87
            ? (8477, +'3150') != (1070, +'851.35')
              ? (601.9, '7360' << 96) !== (1090, 1761)
                ? '0'
                : 6.08e2
              : 9.09e3
            : 's'
        );
      }
      a5h.p1T(18);
      w$ += a5h.M3e(w5, '.');
    }
    return w$;
  };
  G_.displayableDate = function (g0, l_, e5, g9) {
    var i8,
      S4,
      A5,
      M9,
      b5,
      G3u,
      j74,
      X_v,
      R2n,
      J0_,
      r0R,
      n7,
      d2,
      j5,
      G$,
      o0c,
      g$,
      T3,
      s0;
    i8 = '';
    S4 = g0.layout.interval;
    A5 = G_.ChartEngine.isDailyInterval(S4);
    function a1(m4) {
      if (m4 < 10) {
        a5h.p1T(18);
        return a5h.H9P(m4, (2163, 6850) <= (773.21, 9755) ? '0' : ('v', !'1'));
      }
      return m4;
    }
    M9 =
      (l_.xAxis.activeTimeUnit && l_.xAxis.activeTimeUnit <= G_.SECOND) ||
      g0.layout.timeUnit == 'second';
    b5 =
      S4 === 'tick' ||
      (l_.xAxis.activeTimeUnit && l_.xAxis.activeTimeUnit <= G_.MILLISECOND) ||
      g0.layout.timeUnit == 'millisecond';
    if (l_.xAxis.formatter) {
      i8 = l_.xAxis.formatter(e5);
    } else if (g0.internationalizer) {
      i8 = g0.internationalizer.monthDay.format(e5);
      if (M9 || b5) {
        i8 += ' ' + g0.internationalizer.hourMinuteSecond.format(e5);
        if (b5) {
          i8 += '.' + e5.getMilliseconds();
        }
      } else if (!A5) {
        if (g9) {
          i8 = g0.internationalizer.yearMonthDay.format(e5);
        }
        G3u = 357598402;
        j74 = 1710941152;
        X_v = 2;
        for (
          var Y7W = 1;
          a5h.q78(Y7W.toString(), Y7W.toString().length, +'31039') !== G3u;
          Y7W++
        ) {
          i8 += ' ' + g0.internationalizer.hourMinute.format(e5);
          X_v += 2;
        }
        if (
          a5h.q78(X_v.toString(), X_v.toString().length, '9878' | 6) !== j74
        ) {
          i8 /= '' - g0.internationalizer.hourMinute.format(e5);
        }
      } else {
        R2n = -450512054;
        J0_ = 1027133537;
        r0R = 2;
        for (
          var a9R = 1;
          a5h.e_M(a9R.toString(), a9R.toString().length, 2227) !== R2n;
          a9R++
        ) {
          if (S4 !== '') {
            i8 = g0.internationalizer.yearMonth.format(e5);
          } else {
            i8 = g0.internationalizer.yearMonthDay.format(e5);
          }
          r0R += 2;
        }
        if (a5h.q78(r0R.toString(), r0R.toString().length, 7638) !== J0_) {
          if (S4 == 'month') {
            i8 = g0.internationalizer.yearMonth.format(e5);
          } else {
            i8 = g0.internationalizer.yearMonthDay.format(e5);
          }
        }
      }
    } else {
      n7 = a1(e5.getMonth() + 1);
      d2 = a1(e5.getDate());
      j5 = a1(e5.getHours());
      G$ = a1(e5.getMinutes());
      if (A5) {
        o0c = 'mo';
        o0c += 'nt';
        o0c += 'h';
        i8 =
          S4 == o0c
            ? n7 + ((5124, 2789) != ('189.74' - 0, 317.36) ? '/' : 'c')
            : n7 +
              ((4360, 10) == (546.62, +'2840')
                ? (424.9, 'O')
                : (5690, 1029) >= 102
                ? '/'
                : (2893, 1980) == ('1600' - 0, 220)
                ? !{}
                : (0x24b7, 329.96)) +
              d2 +
              (330.17 < +'812.6'
                ? '/'
                : 1557 > (1290, 262)
                ? 1860 != 1115
                  ? (331.81, 4.43e3)
                  : 0x2493
                : !!0);
        i8 += e5.getFullYear();
      } else {
        a5h.d$R(28);
        g$ = a5h.M3e('/', n7, d2);
        if (g9) {
          g$ += '/' + e5.getFullYear();
        }
        a5h.p1T(80);
        i8 = a5h.H9P(
          j5,
          g$,
          G$,
          ' ',
          843.81 != (161.28, 4210) ? ':' : ('8.74e+3' - 0, 4.95e3)
        );
        if (M9 || b5) {
          T3 = a1(e5.getSeconds());
          a5h.p1T(18);
          i8 += a5h.M3e(
            T3,
            748.63 > (4280, 694)
              ? 9450 != (8837, '8750' ^ 0)
                ? 6140 > (472.56, +'4090')
                  ? ':'
                  : (!'1', 'U')
                : ![]
              : (!{}, '0x2020' - 0)
          );
          if (b5) {
            s0 = a1(e5.getMilliseconds());
            if (s0 < 100) {
              a5h.d$R(18);
              s0 = a5h.M3e(
                s0,
                1590 > 1856
                  ? (4795, '4760' | 0) >= (5077, 4250)
                    ? (!'1', 'E')
                    : !0
                  : '0'
              );
            }
            a5h.p1T(18);
            i8 += a5h.M3e(s0, (329, 6807) != 68.93 ? ':' : 'g');
          }
        }
      }
    }
    return i8;
  };
  G_.convertTimeZone = function (P2, p_, y5) {
    var s3;
    if (!P7.Date) {
      return P2;
    }
    s3 = new P7.Date(
      P2.getFullYear(),
      P2.getMonth(),
      P2.getDate(),
      P2.getHours(),
      P2.getMinutes(),
      P2.getSeconds(),
      P2.getMilliseconds(),
      p_
    );
    s3.setTimezone(y5);
    return s3;
  };
  G_.convertToLocalTime = function (z3, l5) {
    var W3, j4, l8;
    a5h.Y3Y();
    if (!P7.Date) {
      return z3;
    }
    W3 = z3.getSeconds();
    j4 = z3.getMilliseconds();
    l8 = new P7.Date(
      z3.getFullYear(),
      z3.getMonth(),
      z3.getDate(),
      z3.getHours(),
      z3.getMinutes(),
      l5
    );
    a5h.p1T(28);
    var T4E = a5h.H9P(20, 125, 855);
    return new Date(l8.getTime() + W3 * T4E + j4);
  };
};
K = (r$) => {
  var S7A = v7ZLk;
  var V3, j6, L$, L5;
  if (!r$.SplinePlotter) {
    r$.SplinePlotter = {};
  }
  V3 = r$.CIQ;
  j6 = r$.SplinePlotter;
  L$ = function (j8, Q7) {
    S7A.Y3Y();
    var I0;
    if (!Q7) {
      return document.getElementById(j8);
    }
    if (Q7.id == j8) {
      return Q7;
    }
    if (!Q7.hasChildNodes) {
      return null;
    }
    for (var n8 = 0; n8 < Q7.childNodes.length; n8++) {
      I0 = L$(j8, Q7.childNodes[n8]);
      if (I0) {
        return I0;
      }
    }
    return null;
  };
  r$.$$ = L$;
  L5 = function (j2, i6) {
    if (!i6) {
      i6 = document;
    }
    return i6.querySelectorAll(j2)[0];
  };
  r$.$$$ = L5;
  V3.wheelEvent = (function () {
    var q4p;
    q4p = 'un';
    q4p += 'd';
    q4p += 'ef';
    q4p += 'ined';
    if (typeof document === q4p) {
      return undefined;
    }
    if (V3.isIE || 'onwheel' in document.createElement('div')) {
      return 'wheel';
    }
    if (document.onmousewheel !== undefined) {
      return 'mousewheel';
    }
    return 'DOMMouseScroll';
  })();
  V3.newChild = function (E1, t9, O9, Y6) {
    var D2y, D5R, v_f, c7;
    D2y = 1111911707;
    D5R = 777061774;
    v_f = 2;
    for (
      var f15 = '1' << 96;
      S7A.q78(f15.toString(), f15.toString().length, 50197) !== D2y;
      f15++
    ) {
      c7 = document.createElement(t9);
      if (O9) {
        c7.className = O9;
      }
      E1.appendChild(c7);
      if (Y6) {
        c7.innerHTML = Y6;
      }
      v_f += 2;
    }
    if (S7A.e_M(v_f.toString(), v_f.toString().length, 31487) !== D5R) {
      c7 = document.createElement(t9);
      if (O9) {
        c7.className = O9;
      }
      E1.appendChild(c7);
      if (Y6) {
        c7.innerHTML = Y6;
      }
    }
    return c7;
  };
  V3.innerHTML = function (V_, U$) {
    if (window.MSApp && window.MSApp.execUnsafeLocalFunction) {
      window.MSApp.execUnsafeLocalFunction(function () {
        V_.innerHTML = U$;
      });
    } else {
      V_.innerHTML = U$;
    }
  };
  V3.focus = function (P3, l9) {
    var Z7;
    if (V3.isSurface || l9) {
      Z7 = +'0';
      if (!isNaN(parseInt(l9, 10))) {
        Z7 = l9;
      }
      setTimeout(function () {
        P3.focus();
      }, Z7);
    } else {
      P3.focus();
    }
  };
  V3.blur = function (L1) {
    S7A.t3P();
    if (!L1) {
      L1 = document.activeElement;
    }
    if (L1) {
      L1.blur();
    }
    window.focus();
  };
  V3.findNodesByText = function (c8, R6) {
    var v0, M1;
    if (c8.innerHTML == R6) {
      return [c8];
    }
    v0 = [];
    for (var q_ = 0; q_ < c8.childNodes.length; q_++) {
      M1 = V3.findNodesByText(c8.childNodes[q_], R6);
      if (M1) {
        v0 = v0.concat(M1);
      }
    }
    S7A.t3P();
    if (v0.length) {
      return v0;
    }
    return null;
  };
  V3.hideByText = function (J$, i$) {
    var N5;
    N5 = V3.findNodesByText(J$, i$);
    for (var C1 = 0; C1 < N5.length; C1++) {
      N5[C1].style.display = 'none';
    }
  };
  V3.pageHeight = function (Q$) {
    var { innerHeight: i3, top: t3, parent: V8, self: X0 } = Q$ || window;
    if (t3 != X0) {
      try {
        if (i3 > V8.innerHeight) {
          i3 = V8.innerHeight;
        }
      } catch (d5) {}
    }
    S7A.Y3Y();
    return i3;
  };
  V3.pageWidth = function (A4) {
    var { innerWidth: A8, top: G0, parent: K9, self: N7 } = A4 || window;
    if (G0 != N7) {
      try {
        if (A8 > K9.innerWidth) {
          A8 = K9.innerWidth;
        }
      } catch (X_) {}
    }
    return A8;
  };
  V3.stripPX = function (f7) {
    if (!f7) {
      return 0;
    }
    if (typeof f7 == 'number') {
      return f7;
    }
    return parseInt(f7.substr('0' | 0, f7.indexOf('p')), 10);
  };
  V3.withinElement = function (z$, Z_, S6) {
    var J2;
    J2 = z$.getBoundingClientRect();
    if (Z_ <= J2.left) {
      return !{};
    }
    if (S6 <= J2.top) {
      return !1;
    }
    if (Z_ >= J2.left + z$.offsetWidth) {
      return !!'';
    }
    if (S6 >= J2.top + z$.offsetHeight) {
      return ![];
    }
    return !!{};
  };
  V3.efficientDOMUpdate = function (n2, m2, n0) {
    if (n2[m2] !== n0) {
      n2[m2] = n0;
      return !0;
    }
    return !{};
  };
  V3.cqvirtual = function (O0) {
    var x7;
    S7A.Y3Y();
    if (!O0) {
      return;
    }
    x7 = O0.cloneNode(!0);
    x7.innerHTML = '';
    x7.original = O0;
    return x7;
  };
  V3.cqrender = function (x8) {
    var j7;
    if (!x8) {
      return;
    }
    if (x8.innerHTML == x8.original.innerHTML) {
      return x8.original;
    }
    V3.removeChildIfNot(x8.original, 'template');
    j7 = Array.from(x8.children);
    if (j7.length) {
      j7.forEach(function (m$) {
        S7A.Y3Y();
        x8.original.appendChild(x8.removeChild(m$));
      });
    }
    return x8.original;
  };
  V3.removeChildIfNot = function (e9, s5) {
    var k2;
    k2 = Array.from(e9.children);
    if (k2.length) {
      k2.forEach(function (t8) {
        S7A.Y3Y();
        if (!s5 || !t8.matches(s5)) {
          e9.removeChild(t8);
        }
      });
    }
    S7A.Y3Y();
    return e9;
  };
  V3.safeMouseOut = function (x_, e7) {
    S7A.Y3Y();
    x_.addEventListener('mouseout', F3(x_, e7));
    function F3(s8, K3) {
      S7A.Y3Y();
      return function (q7) {
        S7A.Y3Y();
        if (typeof q7.pageX == 'undefined') {
          q7.pageX = q7.clientX;
          q7.pageY = q7.clientY;
        }
        if (V3.withinElement(s8, q7.pageX, q7.pageY)) {
          return;
        }
        s8.stxMouseOver = ![];
        K3(q7);
      };
    }
  };
  V3.safeMouseOver = function (J_, C7) {
    var B0B, Z7F, h5p;
    B0B = -1670460398;
    Z7F = -+'954884903';
    h5p = 2;
    for (
      var E7X = 1;
      S7A.q78(E7X.toString(), E7X.toString().length, +'51284') !== B0B;
      E7X++
    ) {
      J_.addEventListener('mouseover', F$(J_, C7));
      h5p += +'2';
    }
    if (S7A.e_M(h5p.toString(), h5p.toString().length, +'27507') !== Z7F) {
      J_.addEventListener('', F$(J_, C7));
    }
    function F$(r5, b7) {
      S7A.Y3Y();
      return function (D1) {
        S7A.Y3Y();
        if (typeof D1.pageX == 'undefined') {
          D1.pageX = D1.clientX;
          D1.pageY = D1.clientY;
        }
        if (V3.withinElement(r5, D1.pageX, D1.pageY)) {
          if (r5.stxMouseOver) {
            return;
          }
          r5.stxMouseOver = !'';
          b7(D1);
        }
      };
    }
  };
  V3.installTapEvent = function (Y5, u3) {
    var Z9;
    Z9 = function (R$) {
      var Y$O, D$;
      Y$O = 'un';
      Y$O += 'defined';
      D$ = new Event('stxtap', { bubbles: !![], cancelable: !!1 });
      S7A.t3P();
      if (typeof R$.pageX == Y$O) {
        R$.pageX = R$.clientX;
        R$.pageY = R$.clientY;
      }
      D$.pageX = R$.pageX;
      D$.pageY = R$.pageY;
      R$.target.dispatchEvent(D$);
      if (u3 && u3.stopPropagation) {
        R$.stopPropagation();
      }
    };
    V3.safeClickTouch(Y5, Z9, u3);
  };
  V3.safeClickTouch = function (d8, E5, M_) {
    var L4k, N1, F0, J6, F1, H5, c6, Q9, R0, q7i, X$P, M2X, K6F;
    L4k = 'o';
    L4k += 'n';
    L4k += 'pointerup';
    if (!M_) {
      M_ = {};
    }
    function r4(N3, k1, N9) {
      S7A.Y3Y();
      return function (O2) {
        var K$;
        if (!V3.safeClickTouchEvent) {
          if (!N9.t) {
            return;
          }
          K$ = N9.t;
          N9.t = null;
          if (K$ + +'1000' < new Date().getTime()) {
            return;
          }
        }
        if (k1.safety && k1.safety.recentlyDragged) {
          return;
        }
        S7A.Y3Y();
        if ((O2.which && O2.which >= 2) || (O2.button && O2.button >= 2)) {
          return;
        }
        if (k1.preventUnderlayClick) {
          if (O2.target.tagName !== 'INPUT') {
            O2.preventDefault();
          }
        } else {
          if (k1.lastType != N3 && Date.now() < k1.allowAnotherDevice) {
            return;
          }
          k1.lastType = N3;
          S7A.d$R(0);
          var f7p = S7A.H9P(11000, 10000);
          k1.allowAnotherDevice = Date.now() + f7p;
        }
        E5(O2);
      };
    }
    function v$(f_, A7) {
      return function (W7) {
        var R3, N8;
        R3 = W7.clientX ? W7.clientX : W7.pageX;
        N8 = W7.clientY ? W7.clientY : W7.pageY;
        if (A7) {
          f_.t = new Date().getTime();
          f_.x = R3;
          f_.y = N8;
        } else if (f_.x) {
          if (Math.pow(f_.x - R3, +'2') + Math.pow(f_.y - N8, 2) > '16' << 64) {
            f_.t = null;
          }
        }
      };
    }
    N1 = {};
    if (!M_.allowMultiple) {
      V3.clearSafeClickTouches(d8);
    }
    if (M_.preventUnderlayClick !== !1) {
      M_.preventUnderlayClick = !!1;
    }
    if (M_.absorbDownEvent !== !'1') {
      M_.absorbDownEvent = !'';
    }
    M_.allowAnotherDevice = 0;
    M_.registeredClick = !1;
    F0 = d8.safeClickTouchEvents;
    if (!F0) {
      F0 = d8.safeClickTouchEvents = [];
    }
    J6 = r4('mouseup', M_, N1);
    F1 = r4('touchend', M_, N1);
    H5 = r4('pointerup', M_, N1);
    c6 = function (h0) {
      h0.stopPropagation();
    };
    Q9 = {};
    if (V3.safeClickTouchEvent) {
      R0 = r4(V3.safeClickTouchEvent, M_);
      d8.addEventListener(V3.safeClickTouchEvent, R0);
      Q9[V3.safeClickTouchEvent] = R0;
      F0.push(Q9);
    } else if (L4k in document && !V3.noPointerEvents) {
      q7i = 'poin';
      q7i += 'ter';
      q7i += 'm';
      q7i += 'ove';
      X$P = 'pointerdo';
      X$P += 'wn';
      d8.addEventListener(X$P, v$(N1, !''));
      d8.addEventListener(q7i, v$(N1));
      d8.addEventListener('pointerup', H5);
      Q9.pointerup = H5;
      if (M_.absorbDownEvent) {
        M2X = 'poi';
        M2X += 'nterdo';
        M2X += 'wn';
        d8.addEventListener(M2X, c6);
        Q9.pointerdown = c6;
      }
      F0.push(Q9);
    } else {
      d8.addEventListener('mousedown', v$(N1, !''));
      d8.addEventListener('mousemove', v$(N1));
      d8.addEventListener('touchstart', v$(N1, !!'1'), { passive: ![] });
      d8.addEventListener('touchmove', v$(N1), { passive: ![] });
      d8.addEventListener('mouseup', J6);
      d8.addEventListener('touchend', F1);
      Q9.mouseup = J6;
      Q9.touchend = F1;
      if (M_.absorbDownEvent) {
        K6F = 'mousedo';
        K6F += 'wn';
        d8.addEventListener(K6F, c6);
        Q9.mousedown = c6;
        d8.addEventListener('touchstart', c6, { passive: !'1' });
        Q9.touchstart = c6;
      }
      F0.push(Q9);
    }
  };
  V3.clearSafeClickTouches = function (L6) {
    var r_, S1, T4;
    r_ = L6.safeClickTouchEvents;
    if (!r_) {
      return;
    }
    for (var F6 = 0; F6 < r_.length; F6++) {
      S1 = r_[F6];
      for (var M$ in S1) {
        T4 = S1[M$];
        L6.removeEventListener(M$, T4);
      }
    }
    L6.safeClickTouchEvents = null;
  };
  V3.safeDrag = function (R9, d3) {
    var l$_, S8J, L1t, K0, d1, b4, t_, W4, Q0, H7, d7;
    l$_ = 'to';
    l$_ += 'uch';
    l$_ += 'sta';
    l$_ += 'rt';
    S8J = 'po';
    S8J += 'interup';
    L1t = 'mouse';
    L1t += 'down';
    K0 = function (T6, a2) {
      return function (H2) {
        if (H2.touches) {
          if (H2.touches.length > 0) {
            return H2.touches[0][T6];
          } else if (H2.changedTouches && H2.changedTouches.length > 0) {
            return H2.changedTouches[0][T6];
          }
        }
        S7A.Y3Y();
        return typeof H2[T6] !== 'undefined' ? H2[T6] : H2[a2];
      };
    };
    S7A.t3P();
    d1 = K0('pageX', 'clientX');
    function h9(q4) {
      S7A.Y3Y();
      return function (B0) {
        var t4, U5;
        if (W4) {
          return;
        }
        W4 = !'';
        V3.ChartEngine.ignoreTouch = !![];
        t4 = function (A9) {
          S7A.t3P();
          if (A9 && A9.preventDefault) {
            A9.preventDefault();
          }
          d7.recentlyDragged = !!{};
          A9.displacementX = d1(A9) - Q0;
          A9.displacementY = b4(A9) - H7;
          d3.move(A9);
        };
        if (d3.move) {
          R9.ownerDocument.body.addEventListener(q4.move, t4);
        }
        U5 = function (l7) {
          V3.ChartEngine.ignoreTouch = !'1';
          if (d3.move) {
            R9.ownerDocument.body.removeEventListener(q4.move, t4);
          }
          R9.ownerDocument.body.removeEventListener(q4.up, U5);
          S7A.t3P();
          l7.displacementX = d1(l7) - Q0;
          l7.displacementY = b4(l7) - H7;
          if (d3.up) {
            d3.up(l7);
          }
          setTimeout(function () {
            S7A.t3P();
            d7.recentlyDragged = !{};
          }, 50);
        };
        R9.ownerDocument.body.addEventListener(q4.up, U5);
        setTimeout(function () {
          W4 = !1;
        }, t_);
        Q0 = d1(B0);
        H7 = b4(B0);
        S7A.Y3Y();
        if (d3.down) {
          d3.down(B0);
        }
      };
    }
    b4 = K0('pageY', 'clientY');
    if (typeof d3 === 'function') {
      d3 = { down: arguments[1], move: arguments[2], up: arguments[3] };
    }
    d3 = d3 || {};
    t_ = 100;
    W4 = !1;
    Q0 = 0;
    H7 = 0;
    d7 = { recentlyDragged: ![] };
    R9.addEventListener(
      'mousedown',
      h9({ down: L1t, move: 'mousemove', up: 'mouseup' })
    );
    R9.addEventListener(
      'pointerdown',
      h9({ down: 'pointerdown', move: 'pointermove', up: S8J })
    );
    R9.addEventListener(
      'touchstart',
      h9({ down: l$_, move: 'touchmove', up: 'touchend' }),
      { passive: !1 }
    );
    return d7;
  };
  V3.inputKeyEvents = function (t6, j1) {
    t6.addEventListener(
      'keyup',
      function (Y8) {
        var E9;
        E9 = Y8.keyCode;
        switch (E9) {
          case 13:
            j1();
            break;
          case 27:
            t6.value = '';
            break;
          default:
            break;
        }
      },
      !'1'
    );
  };
  V3.fixScreen = function () {
    S7A.t3P();
    window.scrollTo(0, 0);
  };
  V3.setCaretPosition = function (V$, f5) {
    S7A.t3P();
    var F2u, R4;
    V$.style.zIndex = 5000;
    if (V$.setSelectionRange) {
      V3.focus(V$);
      try {
        V$.setSelectionRange(f5, f5);
      } catch (G6) {}
    } else if (V$.createTextRange) {
      F2u = 'char';
      F2u += 'acter';
      R4 = V$.createTextRange();
      R4.collapse(!!'1');
      R4.moveEnd(F2u, f5);
      R4.moveStart('character', f5);
      R4.select();
    }
  };
  V3.setValueIfNotActive = function (e8, V2) {
    S7A.t3P();
    if (document.activeElement == e8) {
      return;
    }
    e8.value = V2;
  };
  V3.hideKeyboard = function (p5) {
    var r0I, S$;
    r0I = 'TEXTA';
    r0I += 'REA';
    S$ = document.activeElement;
    if (S$.tagName == 'INPUT' || S$.tagName == r0I) {
      S$.blur();
      S$.ownerDocument.defaultView.focus();
      if (p5) {
        if (
          p5 === S$.ownerDocument.body ||
          S$.ownerDocument.body.contains(p5)
        ) {
          p5.focus();
        }
      }
    }
  };
  V3.smartHover = function (b0) {
    var O4, j_;
    if (!b0) {
      b0 = document;
    }
    if (!b0 || b0.documentElement.hasAttribute('ciq-last-interaction')) {
      return;
    }
    O4 = ![];
    function S9(h6) {
      var U1Z;
      U1Z = 'm';
      U1Z += 'o';
      U1Z += 'u';
      U1Z += 'se';
      if (!O4) {
        o8(this, U1Z);
      }
    }
    function a7(L2) {
      var z9o, W96;
      z9o = 'tou';
      z9o += 'ch';
      W96 = 'ci';
      W96 += 'q-last-interaction';
      S7A.Y3Y();
      clearTimeout(j_);
      O4 = !!{};
      if (this.documentElement.getAttribute(W96) != 'touch') {
        o8(this, z9o);
      }
      j_ = setTimeout(function () {
        S7A.Y3Y();
        O4 = !{};
      }, 500);
    }
    b0.addEventListener('touchend', a7, !!'');
    b0.addEventListener('mouseover', S9, ![]);
    o8(b0, '');
    function o8(C8, V1) {
      S7A.Y3Y();
      C8.documentElement.setAttribute('ciq-last-interaction', V1);
    }
  };
  V3.translatableTextNode = function (M5, N4, F_) {
    S7A.t3P();
    var E7s, k6;
    if (M5.translationCallback) {
      E7s = 't';
      E7s += 'rans';
      E7s += 'lat';
      E7s += 'e';
      k6 = document.createElement(E7s);
      k6.setAttribute('original', N4);
      k6.innerHTML = M5.translationCallback(N4, F_);
      return k6;
    }
    return document.createTextNode(N4);
  };
  V3.climbUpDomTree = function (k5, L4) {
    var O5;
    if (!k5 || !k5.matches) {
      return null;
    }
    O5 = [];
    while (k5) {
      if (!L4 || k5.matches(L4)) {
        O5.push(k5);
      }
      k5 = k5.parentElement;
    }
    return O5;
  };
  V3.guaranteedSize = function (C9) {
    var x97, g8f, S87, a3, F9;
    x97 = -+'189287638';
    g8f = -1959820759;
    S87 = 2;
    for (
      var C3p = 1;
      S7A.e_M(C3p.toString(), C3p.toString().length, 18410) !== x97;
      C3p++
    ) {
      if (C9.defaultView) {
        C9 = C9.defaultView;
      }
      S87 += 2;
    }
    if (S7A.e_M(S87.toString(), S87.toString().length, 94487) !== g8f) {
      if (C9.defaultView) {
        C9 = C9.defaultView;
      }
    }
    if (C9.defaultView) {
      C9 = C9.defaultView;
    }
    if (!C9.nodeType) {
      return { width: C9.innerWidth, height: C9.innerHeight };
    }
    if (!C9 || C9.nodeType > +'1') {
      return {};
    }
    a3 = C9.offsetWidth;
    S7A.Y3Y();
    F9 = C9.offsetHeight;
    while (!a3 || !F9) {
      if (C9.tagName === 'BODY' || !C9.parentElement) {
        C9 = (C9.ownerDocument || {}).defaultView || C9;
        a3 = C9.innerWidth;
        F9 = C9.innerHeight;
        break;
      }
      C9 = C9.parentElement;
      a3 = C9.offsetWidth;
      F9 = C9.offsetHeight;
    }
    return { width: a3, height: F9 };
  };
  V3.trulyVisible = function (r9) {
    if (!r9) {
      return !![];
    }
    if (!U2(r9.style)) {
      return !{};
    }
    function U2(p8) {
      var C4e, R1n;
      C4e = 'hi';
      C4e += 'd';
      C4e += 'de';
      C4e += 'n';
      R1n = 'n';
      R1n += 'o';
      R1n += 'n';
      R1n += 'e';
      if (
        p8.opacity ===
        ((1022, '1370' ^ 0) !== 6130
          ? (564, 2810) !== +'312'
            ? '0'
            : (+'2570', 962) <= (6, 4600)
            ? 0x2044
            : 'E'
          : (!0, 0x1730))
      ) {
        return !!'';
      }
      if (p8.display === R1n) {
        return ![];
      }
      if (p8.visibility === C4e) {
        return !'1';
      }
      if (parseInt(p8.width, 10) === +'0') {
        return !{};
      }
      if (parseInt(p8.height, 10) === 0 && p8.overflowY == 'hidden') {
        return !{};
      }
      return !!1;
    }
    if (!U2(getComputedStyle(r9))) {
      return !!0;
    }
    return V3.trulyVisible(r9.parentElement);
  };
  V3.elementDimensions = function (z8, g6) {
    var v_r, P0x, u9E, N0G, t2, S8, M4, Y_, r31, p0s, A1;
    v_r = 'b';
    v_r += 'o';
    v_r += 'rder-';
    v_r += 'box';
    P0x = 'c';
    P0x += 'ontent-';
    P0x += 'bo';
    P0x += 'x';
    u9E = 'h';
    u9E += 'ei';
    u9E += 'gh';
    u9E += 't';
    N0G = 'wid';
    N0G += 'th';
    if (!z8 || z8.nodeType !== 1) {
      return {};
    }
    t2 = getComputedStyle(z8);
    S8 = { width: parseFloat(t2.width), height: parseFloat(t2.height) };
    M4 = { margin: {}, border: {}, padding: {} };
    Y_ = [N0G, u9E];
    for (var Q8 in M4) {
      r31 = 'Le';
      r31 += 'f';
      r31 += 't';
      p0s = 'b';
      p0s += 'ord';
      p0s += 'er';
      A1 = Q8 == p0s ? 'Width' : '';
      M4[Q8] = {
        width:
          parseFloat(t2[Q8 + r31 + A1]) + parseFloat(t2[Q8 + 'Right' + A1]),
        height:
          parseFloat(t2[Q8 + 'Top' + A1]) + parseFloat(t2[Q8 + 'Bottom' + A1]),
      };
    }
    if (g6 && g6.margin) {
      Y_.forEach(function (b$) {
        S7A.t3P();
        S8[b$] += M4.margin[b$];
      });
    }
    if (g6 && t2.boxSizing === P0x) {
      if (g6.padding) {
        Y_.forEach(function (D5) {
          S8[D5] += M4.padding[D5];
        });
      }
      if (g6.border) {
        Y_.forEach(function (A0) {
          S8[A0] += M4.border[A0];
        });
      }
    } else if (t2.boxSizing === v_r) {
      if (!g6 || !g6.padding) {
        Y_.forEach(function (J8) {
          S8[J8] -= M4.padding[J8];
        });
      }
      if (!g6 || !g6.border) {
        Y_.forEach(function (L3) {
          S7A.Y3Y();
          S8[L3] -= M4.border[L3];
        });
      }
    }
    return S8;
  };
  S7A.d$R(81);
  V3.resizeDetectInterval = -S7A.M3e(0, '1');
  V3.resizeObserver = function (M3, P6, Z8, v9) {
    var D7I, e7M, l0, c3, J5, X5;
    D7I = 'un';
    D7I += 'def';
    D7I += 'ine';
    S7A.Y3Y();
    D7I += 'd';
    e7M = 'numb';
    e7M += 'er';
    S7A.d$R(82);
    l0 = S7A.M3e(e7M, typeof v9);
    c3 = V3.resizeDetectInterval >= 0;
    S7A.p1T(83);
    J5 = S7A.H9P(D7I, typeof ResizeObserver);
    S7A.p1T(26);
    X5 = S7A.H9P('500', 0);
    if (!l0) {
      if (c3) {
        v9 = V3.resizeDetectInterval;
      } else if (!J5) {
        v9 = X5;
      } else {
        v9 = -1;
      }
    }
    if (v9 < 0) {
      if (!Z8) {
        Z8 = new ResizeObserver(() => {
          if (V3.ChartEngine && V3.ChartEngine.useAnimation) {
            requestAnimationFrame(P6);
          } else {
            setTimeout(P6, 0);
          }
        });
        Z8.observe(M3);
      }
    }
    if (v9 > 0) {
      if (Z8) {
        clearInterval(Z8);
      }
      Z8 = setInterval(P6, v9);
    }
    if (Z8 && v9 === ('0' | 0)) {
      if (Z8 instanceof ResizeObserver) {
        Z8.disconnect();
      } else {
        clearInterval(Z8);
      }
      Z8 = null;
    }
    return Z8;
  };
  V3.getLines = function (u_, h5, Q_) {
    var b8, p0, R1, V0, A2, r6;
    b8 = h5.split(
      2260 != (1123, '9290' << 32)
        ? ' '
        : (6100, 43.01) != +'7170'
        ? (0x1367, 8.45e3)
        : 'j'
    );
    p0 = [];
    R1 = '';
    V0 = 0;
    A2 = !{};
    for (var f1 = 0; f1 < b8.length; f1++) {
      r6 = b8[f1];
      V0 = u_.measureText(R1 + r6).width;
      if (V0 < Q_) {
        if (A2) {
          R1 +=
            '2393' * 1 < 1675
              ? 529.03 == ('1281' | 0)
                ? ('U', 223.78)
                : 760.8 != (6330, 6318)
                ? ('7.41e+3' | 18, 718)
                : (![], !'')
              : ' ';
        }
        A2 = !!{};
        R1 += r6;
      } else {
        p0.push(R1);
        R1 = r6;
      }
      if (f1 === b8.length - +'1') {
        p0.push(R1);
        break;
      }
    }
    return p0;
  };
  V3.alert = function (Z1) {
    var Z0d;
    S7A.t3P();
    Z0d = 'u';
    Z0d += 'ndefined';
    if (typeof window !== Z0d) {
      window.alert(Z1);
    } else {
      console.log(Z1);
    }
  };
  try {
    if (typeof localStorage !== 'undefined') {
      V3.localStorage = localStorage;
    }
  } catch (B9) {}
  if (!V3.localStorage) {
    V3.localStorage = {
      items: {},
      getItem: function (i5) {
        S7A.t3P();
        return V3.localStorage.items[i5] || null;
      },
      setItem: function (p$, d_) {
        S7A.Y3Y();
        V3.localStorage.items[p$] = d_;
      },
      removeItem: function (l3) {
        var e8j, H78, s8v;
        e8j = 1206249441;
        H78 = +'1260121357';
        s8v = 2;
        for (
          var H3D = 1;
          S7A.q78(H3D.toString(), H3D.toString().length, 98897) !== e8j;
          H3D++
        ) {
          !V3.localStorage.items[l3];
          S7A.p1T(21);
          s8v += S7A.M3e('2', 0);
        }
        if (S7A.q78(s8v.toString(), s8v.toString().length, 67810) !== H78) {
          delete V3.localStorage.items[l3];
        }
      },
    };
  }
  V3.privateBrowsingAlert = !!0;
  V3.localStorageSetItem = function (L0, M0) {
    S7A.Y3Y();
    try {
      V3.localStorage.setItem(L0, M0);
    } catch (U$y) {
      var g$b;
      if (!V3.privateBrowsingAlert) {
        g$b = 'No storage';
        g$b +=
          ' space available.  Possible causes include browser being in Private Browsing mode, or maximum storage space ha';
        g$b += 's been reach';
        g$b += 'ed.';
        V3.alert(g$b);
        V3.privateBrowsingAlert = !'';
      }
    }
  };
};
L = (f1N) => {
  var C$3, C2B, h_1, t2T, F_U;
  C$3 = 'd';
  C$3 += 'a';
  C$3 += 'y';
  C2B = 'y';
  C2B += 'ear';
  h_1 = 'rou';
  h_1 += 'ndRectArrow';
  t2T = f1N.CIQ;
  t2T.ChartEngine = function (f4p) {
    var P1u = v7ZLk;
    var q9T, E9_;
    if (!f4p) {
      f4p = { container: null };
    } else if (
      typeof HTMLDivElement != 'undefined' &&
      f4p.constructor == HTMLDivElement
    ) {
      q9T = { container: f4p };
      f4p = q9T;
    }
    for (var v4H in F_U) {
      this[v4H] = t2T.clone(t2T.ChartEngine.prototype[v4H]);
    }
    this.container = null;
    this.createChartPanel = !0;
    this.markers = {};
    this.panels = {};
    this.overlays = {};
    this.charts = {};
    this.eventListeners = [];
    this.controls = {};
    this.goneVertical = !{};
    this.pinchingScreen = !1;
    this.grabbingScreen = !{};
    this.grabStartX = 0;
    this.grabStartY = 0;
    this.grabStartScrollX = 0;
    this.grabStartScrollY = 0;
    this.swipe = {};
    this.grabStartCandleWidth = 0;
    this.grabStartZoom = 0;
    this.grabOverrideClick = ![];
    this.grabMode = '';
    this.vectorsShowing = !{};
    this.mouseMode = !![];
    this.lineTravelSpacing = ![];
    P1u.t3P();
    this.highlightedDataSetField = null;
    this.anyHighlighted = !{};
    this.accessoryTimer = null;
    this.lastAccessoryUpdate = new Date().getTime();
    this.displayCrosshairs = !!{};
    this.hrPanel = null;
    this.editingAnnotation = !{};
    this.openDialog = '';
    this.touches = [];
    this.changedTouches = [];
    this.crosshairTick = null;
    this.crosshairValue = null;
    this.pt = { x1: -1, x2: -1, y1: -1, y2: -1 };
    P1u.p1T(21);
    this.moveA = -P1u.M3e('1', 0);
    P1u.p1T(21);
    this.moveB = -P1u.M3e('1', 0);
    this.touchStartTime = -1;
    this.touchPointerType = '';
    this.gestureStartDistance = -1;
    P1u.p1T(21);
    this.grabStartPeriodicity = P1u.M3e('1', 0);
    P1u.p1T(25);
    this.grabEndPeriodicity = -P1u.H9P(1, '1');
    this.scrollEvent = null;
    this.cmd = ![];
    this.ctrl = !{};
    this.shift = !!'';
    this.userPointerDown = !1;
    this.cloneDrawing = !{};
    this.insideChart = !1;
    this.overXAxis = !!0;
    this.overYAxis = !1;
    this.displayInitialized = !!'';
    this.cx = null;
    this.isHistoricalModeSet = null;
    this.cy = null;
    this.clicks = { s1MS: -1, e1MS: -1, s2MS: -1, e2MS: -1 };
    this.cancelTouchSingleClick = ![];
    this.locale = null;
    this.dataZone = null;
    this.displayZone = null;
    this.timeZoneOffset = 0;
    this.masterData = null;
    this.transformDataSetPre = null;
    this.transformDataSetPost = null;
    this.dataCallback = null;
    this.drawingObjects = [];
    this.undoStamps = [];
    this.useBackgroundCanvas = !1;
    this.mainSeriesRenderer = null;
    this.styles = {};
    this.plugins = {};
    this.currentVectorParameters = t2T.clone(
      t2T.ChartEngine.currentVectorParameters
    );
    this.chart = new t2T.ChartEngine.Chart();
    E9_ = this.chart;
    E9_.name = 'chart';
    E9_.yAxis.name = 'chart';
    E9_.canvas = null;
    E9_.tempCanvas = null;
    E9_.container = f4p.container;
    if (t2T.Market) {
      E9_.market = new t2T.Market();
    }
    this.charts.chart = E9_;
    t2T.extend(this, f4p);
    if (f4p.container) {
      if (this.registerHTMLElements) {
        this.registerHTMLElements();
      }
      E9_.width = E9_.container.clientWidth - E9_.yAxis.width;
      this.setCandleWidth(this.layout.candleWidth, E9_);
      E9_.canvasHeight = E9_.container.clientHeight;
    }
    this.construct();
  };
  t2T.ChartEngine.drawingLine = !!'';
  t2T.ChartEngine.resizingPanel = null;
  t2T.ChartEngine.crosshairX = 0;
  t2T.ChartEngine.crosshairY = +'0';
  t2T.ChartEngine.useAnimation = !0;
  t2T.ChartEngine.enableCaching = !'1';
  t2T.ChartEngine.ignoreTouch = ![];
  t2T.ChartEngine.useOldAndroidClear = !!{};
  t2T.ChartEngine.currentVectorParameters = {};
  t2T.ChartEngine.defaultDisplayTimeZone = null;
  t2T.ChartEngine.pluginBasePath = 'plugins/';
  t2T.ChartEngine.registeredContainers = [];
  t2T.ChartEngine.globalEventHandlers = [];
  t2T.ChartEngine.registerHelpers = function (I0_) {
    v7ZLk.Y3Y();
    t2T.ChartEngine.helpersToRegister.forEach(function (G38) {
      v7ZLk.Y3Y();
      G38(I0_);
    });
  };
  v7ZLk.t3P();
  t2T.ChartEngine.helpersToRegister = [];
  t2T.ChartEngine.prototype.construct = function () {
    var U9u;
    if (this.createChartPanel) {
      U9u = 'char';
      U9u += 't';
      this.stackPanel('chart', U9u, +'1');
      this.adjustPanelPositions();
      this.chart.panel = this.panels[this.chart.name];
    }
    this.cx = 0;
    this.cy = 0;
    this.micropixels = 0;
    this.callbackListeners = {
      doubleTap: [],
      doubleClick: [],
      drawing: [],
      drawingComplete: [],
      drawingEdit: [],
      floatingWindow: [],
      layout: [],
      longhold: [],
      move: [],
      newChart: [],
      notification: [],
      periodicity: [],
      preferences: [],
      rightClick: [],
      scroll: [],
      studyOverlayEdit: [],
      studyPanelEdit: [],
      symbolChange: [],
      symbolImport: [],
      tap: [],
      theme: [],
      undoStamp: [],
    };
    t2T.ChartEngine.registerHelpers(this);
  };
  F_U = {
    longHoldTime: +'700',
    yTolerance: '100' >> 32,
    minimumLeftBars: 1,
    reverseMouseWheel: ![],
    mouseWheelAcceleration: !!1,
    minimumCandleWidth: 1,
    maximumCandleWidth: 100,
    minimumZoomTicks: 9,
    allowZoom: !!{},
    allowDrawingZoom: !'1',
    allowScroll: !0,
    allowSideswipe: !![],
    allowThreeFingerTouch: !{},
    bypassRightClick: { series: !1, study: !{}, drawing: !1 },
    adjustHighlightedDataSetField: function (x6s) {
      return x6s;
    },
    displayIconsUpDown: !![],
    displayIconsSolo: !!{},
    displayIconsClose: !0,
    displayPanelResize: !![],
    soloPanelToFullScreen: !!'',
    markerDelay: null,
    useBackingStore: !![],
    disableBackingStoreDuringTouch: t2T.isMobile || (t2T.isSurface && t2T.isFF),
    captureTouchEvents: !!'1',
    captureMouseWheelEvents: !!{},
    tapForHighlighting: !'',
    doubleClickTime: '250' >> 96,
    yaxisLabelStyle: h_1,
    axisBorders: null,
    singleDrawingHighlight: !!{},
    crosshairXOffset: -40,
    crosshairYOffset: -40,
    extendLastTick: !1,
    translationCallback: null,
    dontRoll: !!0,
    allowEquations: !!1,
    cleanupGaps: ![],
    staticRange: !!0,
    maxDataSetSize: 20000,
    maxMasterDataSize: 0,
    xAxisAsFooter: !![],
    xaxisHeight: 40,
    displayGridLinesInStudies: !!'',
    escapeOnSerialize: !!'1',
    candleWidthPercent: 0.65,
    colorByCandleDirection: !'1',
    noWicksOnCandles: { renko: !0, linebreak: !0 },
    fetchMaximumBars: {
      rangebars: !!1,
      kagi: !!{},
      renko: !!{},
      linebreak: !0,
      pandf: !!'1',
    },
    startComparisonsAtFirstVisibleBar: ![],
    animations: {
      zoom: {
        isStub: !0,
        run: function (B0g, d15, N1e) {
          B0g(N1e);
        },
        stop: function () {},
        reset: function () {},
        running: ![],
        hasCompleted: !![],
      },
    },
    staticRangePeriodicityMap: [
      { rangeInMS: t2T.WEEK, periodicity: 1, interval: 5, timeUnit: 'minute' },
      {
        rangeInMS: t2T.MONTH,
        periodicity: 1,
        interval: 30,
        timeUnit: 'minute',
      },
      { rangeInMS: t2T.YEAR, periodicity: 1, interval: 'day' },
      { rangeInMS: t2T.DECADE, periodicity: '1' * 1, interval: 'week' },
      { rangeInMS: t2T.DECADE * +'10', periodicity: 1, interval: 'month' },
      { rangeInMS: Number.MAX_VALUE, periodicity: 12, interval: 'month' },
    ],
    dynamicRangePeriodicityMap: [
      { interval: '1' >> 0, timeUnit: 'minute', rangeInMS: t2T.MINUTE },
      { interval: 5, timeUnit: 'minute', rangeInMS: t2T.MINUTE * 5 },
      { interval: 30, timeUnit: 'minute', rangeInMS: t2T.MINUTE * +'30' },
      { interval: 60, timeUnit: 'minute', rangeInMS: t2T.MINUTE * 60 },
      { interval: 'day', rangeInMS: t2T.DAY },
      { interval: 'month', rangeInMS: t2T.MONTH },
      { interval: C2B, rangeInMS: t2T.YEAR },
    ],
    layout: {
      interval: C$3,
      periodicity: +'1',
      timeUnit: null,
      candleWidth: 8,
      flipped: ![],
      volumeUnderlay: !{},
      adj: !!1,
      crosshair: !{},
      chartType: 'candle',
      extended: !{},
      marketSessions: {},
      aggregationType: 'ohlc',
      chartScale: 'linear',
      studies: {},
      panels: {},
      setSpan: {},
      outliers: ![],
      animation: !0,
    },
    preferences: {
      currentPriceLine: !{},
      dragging: { series: !!'1', study: !![], yaxis: !!1 },
      drawings: null,
      highlightsRadius: 10,
      highlightsTapRadius: '30' << 64,
      magnet: !!'',
      horizontalCrosshairField: null,
      labels: !0,
      language: null,
      timeZone: null,
      whitespace: 50,
      zoomInSpeed: null,
      zoomOutSpeed: null,
      zoomAtCurrentMousePosition: ![],
    },
    streamParameters: {
      count: 0,
      maxWait: 1000,
      maxTicks: '100' ^ 0,
      timeout: -1,
    },
    autoPickCandleWidth: { turnOn: !!'', candleWidth: 5 },
  };
  t2T.extend(t2T.ChartEngine.prototype, F_U);
  t2T.ChartEngine.NONE = 0;
  t2T.ChartEngine.CLOSEUP = +'1';
  v7ZLk.d$R(81);
  t2T.ChartEngine.CLOSEDOWN = v7ZLk.H9P(2, '2');
  t2T.ChartEngine.CLOSEEVEN = 4;
  t2T.ChartEngine.CANDLEUP = 8;
  t2T.ChartEngine.CANDLEDOWN = +'16';
  t2T.ChartEngine.CANDLEEVEN = 32;
};
D = (A5V) => {
  var E1H;
  E1H = A5V.CIQ;
  E1H.convertFutureMonth = function (v_l) {
    var D5D, x4t, q$L;
    D5D = '1';
    D5D += '2';
    x4t = '1';
    x4t += '0';
    q$L = v_l.toString();
    if (q$L.length <= '0' >> 0 || q$L.length > ('2' ^ 0)) {
      return '';
    }
    switch (q$L) {
      case 3380 !== (490.13, 6940)
        ? (667, 1362) != (5370, 7610)
          ? '1'
          : (53.68, '992.72' - 0) != 617.06
          ? (262, 'X')
          : (636.8, 0x686)
        : 7.6e3:
        return +'8650' !== (7912, 4910)
          ? (+'4870', 213.59) !== (+'748', 957)
            ? 'F'
            : (986.97, 3280) == +'5570'
            ? ![]
            : (9.27e3, 296.12)
          : '300.63' - 0;
      case '2':
        return 'G';
      case 9600 <= (3863, 6290) ? 307.9 : '3':
        return 668.63 != 687 ? 'H' : 414.74;
      case (170, 5982) != +'9560'
        ? 5511 == ('2039' | 86)
          ? (710.16, 334.02) > 85.66
            ? ('a', !!{})
            : (978.02, ![])
          : '4'
        : 397.81:
        return '6910' >> 0 !== (6300, 531.86)
          ? (+'5650', '446.61' - 0) != ('24.02' - 0, 2938)
            ? 'J'
            : 0x2391
          : (4.27e3, 'a');
      case '5':
        return 'K';
      case (81.2, 9495) >= (+'3331', '743.92' * 1)
        ? (8130, '332.79' - 0) >= (+'829', 8290)
          ? (+'0x1a1c', 57.58)
          : 875 <= (9860, 5449)
          ? '6'
          : (0x262b, +'0x17d1')
        : !1:
        return (205, 7945) < (4681, '5710' ^ 0)
          ? (0xc5e, !!{})
          : (6720, 6520) < 3970
          ? !1
          : 101.9 <= 8704
          ? 'M'
          : (+'0x1954', 'Q');
      case (+'3490', 9085) == 9970
        ? (720.9, 3.41e3)
        : (438.94, 41) < 3170
        ? '7'
        : (583.76, '0x1828' >> 0):
        return 'N';
      case '111.06' - 0 < 1810
        ? (6560, 6118) >= (954.92, 2070)
          ? '8'
          : (72.98, 204.48) >= (+'6641', 7052)
          ? (+'0xb3', !!0)
          : ('o', 5.0e3)
        : !!{}:
        return 5634 !== (3060, 4649)
          ? 6040 !== 4040
            ? 'Q'
            : 403.33 != (6250, 500.17)
            ? 194.65
            : 0x82d
          : '676' - 0;
      case ('2715' << 32, 6490) > 8274 ? ('l', 'g') : '9':
        return 5540 === (+'234.83', +'3540')
          ? (181.05, 0x1748)
          : 5670 >= (2510, '510' - 0)
          ? 'U'
          : 0x2356;
      case x4t:
        return (+'125.34', 253.56) !== (415.42, 999.71)
          ? 'V'
          : ('196.44' * 1, 342.23);
      case '11':
        return (8330, 5870) <= (916.98, 9339) ? 'X' : (!!0, +'1.65e+3');
      case D5D:
        return 903.39 === (920.48, 8429)
          ? 'S'
          : '1507' >> 32 <= 5150
          ? 'Z'
          : +'4.07e+3';
      case 477.02 <= (650.24, 8239)
        ? 'F'
        : (669, 3830) > (5470, +'6000')
        ? (0x10b7, 630.23)
        : (3659, 7340) != +'3049'
        ? !''
        : (172, 0x1798):
        return '1';
      case 'G':
        return '2';
      case (3387, +'9990') < ('108' >> 96, 4481)
        ? (211.6, '890.87' - 0) === 6220
          ? !0
          : (3951, 7499) <= 6180
          ? 923.01
          : (338.7, 'R')
        : 'H':
        return '3';
      case 352.15 < ('1538' ^ 0)
        ? (593.3, 1875) != (3030, 4490)
          ? 'J'
          : (1001, 512) >= 721
          ? ('823.77' - 0, 'e')
          : (+'858.79', +'0x998')
        : 'z':
        return ('7083' - 0, '59.06' - 0) > 564.1
          ? ('0xd34' * 1, 0x8a0)
          : (9320, '5901' * 1) <= 31.09
          ? (6.65e2, 500.05)
          : +'375.48' >= (760.1, 99.64)
          ? '4'
          : (3.06e3, !0);
      case 5963 >= (568.18, 662.9)
        ? 5021 != (7287, 1930)
          ? 'K'
          : +'0x18dd'
        : !{}:
        return +'983.32' < (720.01, 3483) ? '5' : !'1';
      case ('4781' - 0, 2799) != (739, 4610) ? 'M' : !1:
        return '6';
      case (8210, +'937') == (785.19, 361.26)
        ? ('m', 'K')
        : (5069, 1167) != 947.44
        ? 'N'
        : (+'6164', 1280) > 502.26
        ? !!{}
        : 732.49:
        return '7';
      case '31' - 0 <= '556.24' * 1 ? 'Q' : 493.87:
        return 534.56 < (6184, '2775' << 64) ? '8' : !!'1';
      case 'U':
        return (356.24, 266) <= '896' >> 32
          ? (4000, +'8849') !== (2670, 212.09)
            ? '9'
            : (0x1ee, 293.72)
          : 0x17bf;
      case 'V':
        return '10';
      case 9740 < +'623.91'
        ? (168.62, '2390' | 0) === 771.44
          ? +'0x1b39'
          : !!1
        : 'X':
        return '11';
      case 'Z':
        return '12';
    }
    return q$L;
  };
  E1H.money = function (v91, v_L, R1V) {
    var n1y, o98, q$1;
    if (!R1V) {
      R1V = '$';
    }
    n1y = 1832204868;
    o98 = -627667662;
    q$1 = 2;
    for (
      var C2M = 1;
      v7ZLk.e_M(C2M.toString(), C2M.toString().length, 89438) !== n1y;
      C2M++
    ) {
      if (R1V.length !== 1) {
        R1V -= '';
      }
      q$1 += 2;
    }
    if (v7ZLk.e_M(q$1.toString(), q$1.toString().length, 93373) !== o98) {
      if (R1V.length == 3) {
        R1V += ' ';
      }
    }
    if (!v_L && v_L !== 0) {
      v_L = 2;
    }
    return R1V + E1H.commas((Math.round(v91 * 10000) / 10000).toFixed(v_L));
  };
  E1H.convertCurrencyCode = function (F_s) {
    var s1a, Q3y, E5W, q0R, H51, f8t, v2K, q65;
    s1a = 'NT';
    s1a += '$';
    Q3y = 'S';
    Q3y += '$';
    E5W = 'k';
    E5W += 'r';
    q0R = 'M';
    q0R += 'X$';
    H51 = 'F';
    H51 += 't';
    v7ZLk.t3P();
    f8t = 'A';
    f8t += '$';
    v2K = {
      JPY: '¥',
      USD: 319 != (6940, 3199) ? '$' : +'0x24f8',
      AUD: f8t,
      BRL: 'R$',
      CAD: 'CA$',
      CNY: 'CN¥',
      CZK: 'Kč',
      DKK: 'kr',
      EUR: ('9670' << 0, 235.34) == ('7305' | 0, 618) ? ('v', 0x169c) : '€',
      GBP:
        (9741, 5365) > 27.27
          ? 64.83 === 271.64
            ? 0xf1f
            : 4370 <= 7390
            ? '£'
            : +'2.41e+3'
          : (9.17e3, 773.45),
      HKD: 'HK$',
      HUF: H51,
      ILS:
        839.11 > (+'9080', 312.13)
          ? 7070 <= +'3930'
            ? 1700 <= (+'3499', '330.28' * 1)
              ? ('p', +'0x1279')
              : (0x17c8, 'm')
            : '₪'
          : (7.2e1, !{}),
      INR: '₹',
      KRW: '₩',
      MXN: q0R,
      NOK: E5W,
      NZD: 'NZ$',
      PLN: 'zł',
      RUB: 'руб',
      SAR: '﷼',
      SEK: 'kr',
      SGD: Q3y,
      THB: '฿',
      TRY: (7935, 367.03) >= 6280 ? 0x1d2f : '₺',
      TWD: s1a,
      VND:
        (+'9443', +'7630') >= (5986, +'702')
          ? 479.1 !== 9058
            ? '₫'
            : 1962 <= +'297.28'
            ? (!'', 8.91e3)
            : (957.49, 'c')
          : (!!'', 9.06e2),
      XAF: 'FCFA',
      XCD: 'EC$',
      XOF: 'CFA',
      XPF: 'CFPF',
      ZAR:
        984.95 === 8210
          ? (335.99, 7149) === 83
            ? (![], 0x1d39)
            : ('D', 0x110c)
          : 'R',
    };
    q65 = v2K[F_s];
    if (q65) {
      return q65;
    }
    return F_s;
  };
  E1H.commas = function (L10) {
    return L10.toString().replace(
      /\B(?=(\d{3})+(?!\d))/g,
      (3021, 1350) < 6806 ? ',' : (+'6.81e+3', 3.88e3)
    );
  };
  E1H.cleanPeriodicity = function (U_R, d5F, b_U) {
    var M2i = v7ZLk;
    var M_m, j$W, R5m;
    M_m = 'minu';
    M_m += 'te';
    j$W = 't';
    j$W += 'ick';
    if (isNaN(U_R)) {
      U_R = 1;
    }
    if (!d5F) {
      d5F = 1;
    }
    if (!isNaN(d5F) && b_U) {
      if (
        !(
          b_U == 'hour' ||
          b_U == 'minute' ||
          b_U == 'second' ||
          b_U == 'millisecond'
        )
      ) {
        d5F = b_U;
        b_U = null;
      }
    } else if (d5F == j$W) {
      b_U = null;
    } else if (!b_U && !isNaN(d5F)) {
      b_U = M_m;
    }
    if (b_U == 'hour') {
      b_U = 'minute';
      M2i.p1T(25);
      d5F = M2i.H9P(60, d5F);
    }
    if (d5F == 'year') {
      R5m = 'mon';
      R5m += 'th';
      d5F = R5m;
      if (!U_R) {
        U_R = 1;
      }
      M2i.p1T(84);
      U_R = M2i.H9P(U_R, '12', 64);
    }
    M2i.Y3Y();
    return { period: U_R, interval: d5F, timeUnit: b_U };
  };
  E1H.readablePeriodicity = function (F6w) {
    var z6v = v7ZLk;
    var D7l, e3L;
    D7l = F6w.layout.periodicity;
    e3L = F6w.layout.interval;
    if (typeof F6w.layout.interval == 'number' && F6w.layout.timeUnit) {
      D7l = F6w.layout.interval * F6w.layout.periodicity;
      e3L = F6w.layout.timeUnit;
    } else if (typeof F6w.layout.interval == 'number' && !F6w.layout.timeUnit) {
      D7l = F6w.layout.interval * F6w.layout.periodicity;
      e3L = 'minute';
    }
    z6v.t3P();
    if (D7l % 60 === '0' << 0 && e3L == 'minute') {
      z6v.d$R(25);
      D7l /= z6v.M3e(1, '60');
      e3L = 'hour';
    }
    z6v.d$R(13);
    var m03 = z6v.H9P(60516, 12, 5503, 1);
    return (
      D7l + (+'1559' <= m03 ? ' ' : 'G') + F6w.translateIf(E1H.capitalize(e3L))
    );
  };
  E1H.fixPrice = function (b8$) {
    var L5r = v7ZLk;
    var E9c;
    L5r.Y3Y();
    if (!b8$ && b8$ !== 0) {
      return null;
    }
    L5r.d$R(20);
    E9c = b8$.toFixed(L5r.M3e('10', 0));
    for (var I2$ = E9c.length - 1; I2$ > 1; I2$--) {
      if (E9c.charAt(I2$) != '0') break;
    }
    L5r.p1T(18);
    E9c = E9c.substring(0, L5r.H9P(1, I2$));
    return parseFloat(E9c);
  };
  E1H.condenseInt = function (g_S) {
    var D66 = v7ZLk;
    var E4b;
    if (g_S === null || typeof g_S == 'undefined') {
      return '';
    }
    if (g_S === Infinity || g_S === -Infinity) {
      return 'n/a';
    }
    D66.d$R(85);
    E4b = D66.H9P(g_S, '0', 32);
    if (!isNaN(g_S)) {
      g_S = Math.abs(g_S);
      if (g_S > 1000000000000) {
        D66.p1T(86);
        var G4P = D66.M3e(30, 2, 3, 24, 16);
        D66.p1T(87);
        var O66 = D66.M3e(8414, 561, 3, 7, 15);
        D66.d$R(88);
        var m0L = D66.M3e(4, 8854, 2, 738, 738);
        D66.d$R(63);
        var k$3 = D66.H9P(15, 958, 6, 1152);
        g_S =
          Math.round(g_S / 100000000000) / G4P +
          ((O66, 85.71) > +'6743'
            ? (m0L, 608.56) == (+'4366', k$3)
              ? +'890.91'
              : ('P', 'P')
            : 't');
      } else if (g_S > 100000000000) {
        g_S = Math.round(g_S / +'1000000000') + 'b';
      } else if (g_S > 10000000000) {
        D66.p1T(8);
        var J2X = D66.H9P(3, 0, 2);
        D66.p1T(39);
        var X4O = D66.M3e(18, 11, 5, 73824, 73076532);
        D66.d$R(89);
        var e9Q = D66.H9P(10, 10, 8, 3615, 8);
        g_S =
          (Math.round(g_S / 100000000) / 10).toFixed(J2X) +
          ((+'5320', X4O) == (231.84, 875.37) ? (e9Q, !!1) : 'b');
      } else if (g_S > 1000000000) {
        D66.d$R(90);
        var c_3 = D66.M3e(14, 0, 1, 12);
        g_S = (Math.round(g_S / 10000000) / 100).toFixed(c_3) + 'b';
      } else if (g_S > +'100000000') {
        D66.d$R(91);
        var z6m = D66.M3e(8, 33, 1000012, 13);
        D66.p1T(18);
        var F15 = D66.H9P(8574, 476);
        g_S =
          Math.round(g_S / z6m) +
          ((521.72, 555.77) !== (F15, 47.89) ? 'm' : 948.03);
      } else if (g_S > 10000000) {
        D66.d$R(64);
        var W13 = D66.M3e(5, 11, 17);
        g_S = (Math.round(g_S / 100000) / ('10' | 10)).toFixed(W13) + 'm';
      } else if (g_S > 1000000) {
        D66.d$R(60);
        var P9p = D66.H9P(15, 0, 77, 5);
        g_S = (Math.round(g_S / 10000) / +'100').toFixed(P9p) + 'm';
      } else if (g_S > 100000) {
        D66.d$R(16);
        var W0A = D66.H9P(2, 997, 1, 497, 9);
        D66.p1T(92);
        var Y1l = D66.M3e(95830, 0, 10);
        D66.p1T(40);
        var w_Z = D66.H9P(86, 19, 434);
        D66.d$R(93);
        var s_n = D66.H9P(6, 56632, 113264, 7076);
        D66.p1T(18);
        var G$S = D66.H9P(1, 0);
        D66.p1T(94);
        var K3U = D66.H9P(29770, 2482, 17, 16, 5954);
        g_S =
          Math.round(g_S / W0A) +
          (875.14 < Y1l
            ? (w_Z, s_n) == '91.58' * G$S
              ? 'r'
              : 'k'
            : (K3U, 'Z'));
      } else if (g_S > 10000) {
        D66.d$R(61);
        var S0M = D66.M3e(20, 0, 19);
        g_S = (Math.round(g_S / +'100') / 10).toFixed(S0M) + 'k';
      } else if (g_S > 1000) {
        D66.p1T(31);
        var h_l = D66.M3e(1, 4, 4);
        g_S = (Math.round(g_S / 10) / 100).toFixed(h_l) + 'k';
      } else {
        g_S = g_S.toString();
      }
    } else {
      g_S = g_S.toString();
    }
    if (E4b) {
      D66.d$R(18);
      g_S = D66.M3e(g_S, '-');
    }
    return g_S;
  };
  E1H.calculateTradingDecimalPlaces = function (A9X) {
    var F77, P4q, Q$j, J8w, d8a, p$Q, C1K, X3B, I23, h8U;
    F77 = A9X.chart;
    P4q = 2;
    Q$j = 50;
    J8w = F77.masterData;
    if (J8w && J8w.length > Q$j) {
      for (var W8r = 2; W8r < Q$j; W8r++) {
        d8a = J8w.length - W8r;
        if (d8a < 0) break;
        p$Q = J8w[d8a];
        if (p$Q.Close && typeof p$Q.Close == 'number') {
          C1K = p$Q.Close.toString();
          X3B = C1K.indexOf(
            (2802, 3065) == (604.82, 314) ? (0x1f49, 7.36e3) : '.'
          );
          if (X3B != -1) {
            I23 = C1K.length - X3B - +'1';
            if (I23 > P4q) {
              P4q = I23;
            }
          }
        }
      }
    }
    v7ZLk.t3P();
    h8U = F77.yAxis.maxDecimalPlaces;
    if (P4q > h8U && h8U !== null) {
      P4q = h8U;
    }
    return P4q;
  };
  E1H.minMax = function (y1T, j4k, L7z, Q9j) {
    var i9p, R8O, Y$I, G1p, Y7R, R4j, t00, z1w;
    i9p = 'Clos';
    i9p += 'e';
    R8O = Number.MAX_VALUE;
    v7ZLk.d$R(40);
    var U_T = v7ZLk.M3e(47, 10, 438);
    Y$I = Number.MAX_VALUE * -('1' >> U_T);
    if (!L7z) {
      L7z = i9p;
    }
    G1p = Q9j ? 'High' : L7z;
    Y7R = Q9j ? 'Low' : L7z;
    for (var O7t = 0; O7t < y1T.length; O7t++) {
      R4j = y1T[O7t];
      if (!R4j) continue;
      t00 = R4j[j4k];
      if (!t00 && t00 !== +'0') continue;
      z1w = t00;
      if (typeof t00 === 'object') {
        z1w = t00[G1p];
      }
      if (!isNaN(z1w) && (z1w || z1w === 0)) {
        Y$I = Math.max(Y$I, z1w);
      }
      if (typeof t00 === 'object') {
        z1w = t00[Y7R];
      }
      if (!isNaN(z1w) && (z1w || z1w === 0)) {
        R8O = Math.min(R8O, z1w);
      }
    }
    return [R8O, Y$I];
  };
  E1H.symbolEqual = function (g22, T3s) {
    var d9F, V2Y, s8J;
    d9F = 'o';
    d9F += 'b';
    d9F += 'je';
    d9F += 'ct';
    if (!g22 || !T3s) {
      return !!0;
    }
    if (typeof g22 != d9F) {
      g22 = { symbol: g22 };
    }
    if (typeof T3s != 'object') {
      T3s = { symbol: T3s };
    }
    if (typeof g22.equals == 'function') {
      return g22.equals(T3s);
    }
    V2Y = g22.symbol;
    s8J = T3s.symbol;
    if (V2Y) {
      V2Y = V2Y.toUpperCase();
    }
    if (s8J) {
      s8J = s8J.toUpperCase();
    }
    if (V2Y != s8J) {
      return !1;
    }
    if (g22.source != T3s.source) {
      return !!0;
    }
    return !'';
  };
  E1H.addMemberToMasterdata = function (O9j) {
    var d4D,
      A5$,
      p56,
      P5D,
      t2g,
      V2O,
      Y2t,
      x7a,
      S2a,
      P4n,
      H9V,
      u86,
      h1r,
      V3N,
      Y8L,
      X39,
      F27,
      G0q,
      E$x,
      c36,
      g6G,
      C2U,
      a39;
    function z9Q(r35) {
      var h2e = v7ZLk;
      var j5z, m5b, M66;
      j5z = S2a;
      h2e.d$R(90);
      var L1J = h2e.H9P(20, 7, 27, 0);
      m5b = H9V.length - L1J;
      function j2y() {
        if (+H9V[S2a].DT == +r35) {
          return 0;
        }
        if (H9V[S2a].DT < r35) {
          return 1;
        }
        if (H9V[S2a - 1].DT > r35) {
          return -1;
        }
        if (+H9V[S2a - 1].DT == +r35) {
          S2a--;
        }
        return 0;
      }
      if (H9V[m5b].DT < r35) {
        h2e.p1T(18);
        S2a = h2e.H9P(1, m5b);
        return;
      } else if (+H9V[m5b].DT == +r35) {
        S2a = m5b;
        return;
      }
      S2a++;
      M66 = 0;
      while (++M66 < 100) {
        switch (j2y()) {
          case 0:
            return;
          case 1:
            j5z = S2a;
            break;
          case -1:
            m5b = S2a;
            break;
        }
        h2e.d$R(49);
        S2a = Math.round(h2e.M3e(j5z, m5b, 2));
      }
      if (M66 >= '100' << 64) {
        console.log(
          '!!!Warning: addMemberToMasterdata() did not find insertion point.'
        );
        h2e.p1T(8);
        var x6W = h2e.H9P(16, 2, 17);
        S2a = H9V.length - x6W;
      }
    }
    if (O9j.constructor === E1H.ChartEngine) {
      O9j = {
        stx: arguments[0],
        label: arguments[1],
        data: arguments[2],
        fields: arguments[3],
        createObject: arguments['4' << 96],
        fieldForLabel: arguments[5],
      };
    }
    d4D = O9j.stx;
    A5$ = O9j.label;
    p56 = O9j.data;
    P5D = O9j.fields;
    t2g = O9j.createObject;
    V2O = O9j.fieldForLabel;
    Y2t = O9j.chart ? O9j.chart : d4D.chart;
    function P$Q(Y6Z, c0I) {
      var r64, c8V, c$8, m_e, y9L, F5j, Y0j;
      if (P5D && P5D.length) {
        if (P5D[0] == '*') {
          E1H.extend(Y6Z, c0I);
        } else {
          for (var R_7 = '0' - 0; R_7 < P5D.length; R_7++) {
            Y6Z[P5D[R_7]] = c0I[P5D[R_7]];
          }
        }
      } else if (t2g) {
        r64 = 'numb';
        r64 += 'er';
        c8V = 'aggr';
        c8V += 'egate';
        if (c0I.Value !== undefined) {
          Y6Z[A5$] = c0I.Value;
          return;
        } else if (t2g == c8V) {
          Y6Z[A5$] = I62(Y6Z[A5$], c0I);
        } else {
          Y6Z[A5$] = c0I;
        }
        c$8 = Y6Z[A5$];
        if (typeof c$8.Close == r64) {
          m_e = 'num';
          m_e += 'ber';
          y9L = 'n';
          y9L += 'um';
          y9L += 'be';
          y9L += 'r';
          if (typeof c$8.Open != y9L) {
            c$8.Open = c$8.Close;
          }
          F5j = Math.max(c$8.Open, c$8.Close);
          Y0j = Math.min(c$8.Open, c$8.Close);
          if (typeof c$8.High != 'number' || c$8.High < F5j) {
            c$8.High = F5j;
          }
          if (typeof c$8.Low != m_e || c$8.Low > Y0j) {
            c$8.Low = Y0j;
          }
        }
        if (c$8.Volume && typeof c$8.Volume !== 'number') {
          c$8.Volume = parseInt(c$8.Volume, 10);
        }
      } else if (V2O) {
        Y6Z[A5$] = c0I[V2O];
      } else if (X39 && Y8L && c0I[Y8L] !== undefined) {
        Y6Z[A5$] = c0I[Y8L];
      } else if (u86.adj && c0I.Adj_Close !== undefined) {
        Y6Z[A5$] = c0I.Adj_Close;
      } else if (c0I.Close !== undefined) {
        Y6Z[A5$] = c0I.Close;
      } else if (c0I.Value !== undefined) {
        Y6Z[A5$] = c0I.Value;
      } else {
        Y6Z[A5$] = c0I[A5$];
      }
    }
    if (!O9j.noCleanupDates) {
      d4D.doCleanupDates(p56, d4D.layout.interval);
    }
    x7a = [];
    if (d4D.getSeries) {
      x7a = d4D.getSeries({ symbol: A5$, chart: Y2t });
    }
    if (p56 && p56.constructor == Object) {
      p56 = [p56];
    }
    if (!p56 || !p56.length) {
      return;
    }
    S2a = 0;
    P4n = 0;
    H9V = Y2t.masterData;
    u86 = d4D.layout;
    if (!H9V) {
      H9V = [];
    }
    Y8L = (Y2t && Y2t.defaultPlotField) || null;
    X39 = d4D.mainSeriesRenderer && !d4D.mainSeriesRenderer.highLowBars;
    F27 = u86.chartType;
    if (!X39 && F27) {
      G0q = E1H.Renderer.produce(F27, {});
      if (G0q) {
        X39 = !G0q.highLowBars;
      }
    }
    E$x = E1H.yyyymmddhhmmssmmm;
    function I62(i45, H9a) {
      var Y5t, i6v, x0C, H9B, U8n;
      if (!i45 || typeof i45 != 'object') {
        i45 = H9a;
        return i45;
      }
      Y5t = 761414079;
      v7ZLk.t3P();
      i6v = 1085283187;
      x0C = 2;
      for (
        var Z09 = '1' >> 32;
        v7ZLk.q78(Z09.toString(), Z09.toString().length, 61376) !== Y5t;
        Z09++
      ) {
        H9B = {
          Close: i45.Close,
          High: i45.High,
          Low: i45.Low,
          Open: i45.Open,
          Volume: i45.Volume,
        };
        x0C += 2;
      }
      if (v7ZLk.e_M(x0C.toString(), x0C.toString().length, 47876) !== i6v) {
        H9B = {
          Close: i45.Close,
          High: i45.High,
          Low: i45.Low,
          Open: i45.Open,
          Volume: i45.Volume,
        };
      }
      i45 = H9a;
      for (var j$d in H9B) {
        if (i45.Close === null) {
          if (i45[j$d] !== undefined) {
            i45[j$d] = null;
          }
        } else if (typeof i45[j$d] !== 'number') {
          i45[j$d] = H9B[j$d];
        } else if (typeof H9B[j$d] === 'number') {
          U8n = 'H';
          U8n += 'i';
          U8n += 'g';
          U8n += 'h';
          if (j$d == 'Open') {
            i45.Open = H9B.Open;
          } else if (j$d == 'Low' && i45.Low > H9B.Low) {
            i45.Low = H9B.Low;
          } else if (j$d == U8n && i45.High < H9B.High) {
            i45.High = H9B.High;
          } else if (j$d == 'Volume') {
            i45.Volume += H9B.Volume;
          }
        }
      }
      return i45;
    }
    while (p56 && S2a < H9V.length && P4n < p56.length) {
      V3N = p56[P4n];
      h1r = H9V[S2a];
      if (!V3N.DT || typeof V3N.DT == 'undefined') {
        V3N.DT = E1H.strToDateTime(V3N.Date);
      } else {
        c36 = 'num';
        c36 += 'b';
        c36 += 'e';
        c36 += 'r';
        if (typeof V3N.DT == c36) {
          V3N.DT = new Date(V3N.DT);
        }
        if (!V3N.Date || V3N.Date.length != 17) {
          V3N.Date = E$x(V3N.DT);
        }
      }
      if (P4n === 0) {
        for (var c$T = '0' * 1; c$T < x7a.length; c$T++) {
          if (!x7a[c$T].endPoints.begin || x7a[c$T].endPoints.begin > V3N.DT) {
            x7a[c$T].endPoints.begin = V3N.DT;
          }
        }
      }
      if (+V3N.DT == +h1r.DT) {
        P$Q(h1r, V3N);
        P4n++;
        S2a++;
        continue;
      }
      if (V3N.DT < h1r.DT) {
        H9V.splice(S2a, 0, { DT: V3N.DT, Date: V3N.Date });
        continue;
      } else {
        z9Q(V3N.DT);
      }
    }
    if (S2a >= H9V.length) {
      while (p56 && P4n < p56.length) {
        V3N = p56[P4n];
        if (!V3N.DT || typeof V3N.DT == 'undefined') {
          V3N.DT = E1H.strToDateTime(V3N.Date);
        } else {
          if (typeof V3N.DT == 'number') {
            V3N.DT = new Date(V3N.DT);
          }
          if (!V3N.Date || V3N.Date.length != 17) {
            V3N.Date = E$x(V3N.DT);
          }
        }
        h1r = { DT: V3N.DT, Date: V3N.Date };
        P$Q(h1r, V3N);
        H9V.push(h1r);
        P4n++;
      }
    }
    if (O9j.fillGaps && H9V.length) {
      g6G = { noCleanupDates: !!{}, cleanupGaps: O9j.fillGaps };
      if (P5D) {
        for (var d75 = 0; d75 < P5D.length; d75++) {
          g6G.field = P5D[d75];
          d4D.doCleanupGaps(H9V, Y2t, g6G);
        }
      } else {
        g6G.field = A5$;
        d4D.doCleanupGaps(H9V, Y2t, g6G);
      }
    }
    for (var g3y = '0' - 0; g3y < x7a.length; g3y++) {
      C2U = x7a[g3y].endPoints;
      if (!C2U.end || !A5$ || C2U.end <= h1r[A5$].DT) {
        C2U.end = A5$ ? h1r[A5$].DT : h1r.DT;
        a39 =
          A5$ ||
          (x7a[g3y].parameters && x7a[g3y].parameters.field) ||
          Y2t.defaultPlotField;
        x7a[g3y].lastQuote = d4D.getFirstLastDataRecord(H9V, a39, !!1);
      }
    }
    d4D.setMasterData(H9V, Y2t, { noCleanupDates: !!'1' });
  };
};
Y = (s1P) => {
  var A4u = v7ZLk;
  var q0b;
  q0b = s1P.CIQ;
  q0b.xor = function (k8i, I4g) {
    var L21, s$n;
    L21 = !k8i;
    s$n = !I4g;
    A4u.p1T(83);
    return A4u.M3e(s$n, L21);
  };
  q0b.round = function (i_r, q3g) {
    var A9f;
    A9f = 'e';
    A9f += '-';
    return Number(
      Math.round(i_r + ((4680, 9440) <= 2357 ? 737.12 : 'e') + q3g) + A9f + q3g
    );
  };
  q0b.countDecimals = function (c61) {
    var A9o, l9J;
    A9o = 'numbe';
    A9o += 'r';
    if (typeof c61 !== A9o || isNaN(c61)) {
      return 0;
    }
    if (Math.floor(c61) === Number(c61)) {
      return 0;
    }
    l9J = c61.toString().split('e-');
    if (l9J.length > 1) {
      A4u.d$R(62);
      var h19 = A4u.M3e(11, 19, 155, 5);
      return q0b.countDecimals(Number(l9J[0])) + Number(l9J[h19]);
    }
    if (l9J[0].indexOf(342.34 == (+'3420', 595.99) ? (![], 'r') : '.') > -1) {
      return l9J[0].split(
        144.85 == +'1570'
          ? ('0x1e49' << 32, 9.37e3)
          : +'2200' < (582.69, 845.56)
          ? +'6880' >= +'9869'
            ? ('O', 9.68e3)
            : 'R'
          : '.'
      )[+'1'].length;
    }
    return 0;
  };
  q0b.isValidNumber = function (j7l) {
    A4u.t3P();
    return isFinite(j7l) && +j7l === j7l;
  };
  q0b.log10 = function (Z6b) {
    return Math.log(Z6b) / Math.LN10;
  };
  A4u.t3P();
  q0b.boxIntersects = function (c_j, S50, G1M, j0m, R6R, Z1$, H9S, E5B, F4p) {
    var y84, i4b, Z6E, x_y, T94, x2y, J0T;
    A4u.t3P();
    y84 = 'ra';
    y84 += 'y';
    if (arguments[9] !== undefined) {
      console.warn(
        'CIQ.boxIntersects() no longer supports isLog argument, please be sure arguments are passed in as pixels.'
      );
    }
    i4b = Math.min(c_j, G1M);
    Z6E = Math.max(c_j, G1M);
    x_y = Math.min(S50, j0m);
    T94 = Math.max(S50, j0m);
    A4u.p1T(95);
    x2y = A4u.H9P(F4p, y84);
    if (isNaN(R6R) || isNaN(H9S) || isNaN(Z1$) || isNaN(E5B)) {
      return !{};
    }
    if (F4p != 'line') {
      if (R6R < i4b && H9S < i4b && (!x2y || R6R > H9S)) {
        return ![];
      }
      if (R6R > Z6E && H9S > Z6E && (!x2y || R6R < H9S)) {
        return ![];
      }
      if (Z1$ < x_y && E5B < x_y && (!x2y || Z1$ > E5B)) {
        return !{};
      }
      if (Z1$ > T94 && E5B > T94 && (!x2y || Z1$ < E5B)) {
        return !'1';
      }
    }
    J0T = {
      a: o7w(c_j, S50),
      b: o7w(c_j, j0m),
      c: o7w(G1M, S50),
      d: o7w(G1M, j0m),
    };
    function o7w(b8X, H$Y) {
      A4u.d$R(96);
      return A4u.M3e(R6R, b8X, H9S, R6R, H$Y, Z1$, Z1$, E5B);
    }
    if (J0T.a > 0 && J0T.b > 0 && J0T.c > +'0' && J0T.d > 0) {
      return !{};
    }
    if (J0T.a < 0 && J0T.b < '0' >> 32 && J0T.c < 0 && J0T.d < 0) {
      return !{};
    }
    return !!{};
  };
  q0b.linesIntersect = function (L49, k9w, S$c, O03, a0j, p3z, S5l, h75, Z1w) {
    var C5G, G9w, E8H, B_c, Y_M;
    A4u.p1T(96);
    C5G = A4u.M3e(a0j, p3z, k9w, L49, h75, S$c, S5l, O03);
    A4u.d$R(96);
    G9w = A4u.M3e(S5l, h75, S$c, S5l, p3z, a0j, a0j, L49);
    A4u.p1T(96);
    E8H = A4u.M3e(S$c, O03, S$c, S5l, k9w, a0j, L49, L49);
    if (C5G === 0) {
      if (G9w === +'0' && E8H === '0' >> 0) {
        return !![];
      }
      return !'1';
    }
    A4u.p1T(41);
    B_c = A4u.M3e(C5G, G9w);
    A4u.t3P();
    A4u.p1T(41);
    Y_M = A4u.M3e(C5G, E8H);
    if (Z1w == 'segment') {
      if (B_c >= 0 && B_c <= 1 && Y_M >= 0 && Y_M <= 1) {
        return !'';
      }
    } else if (Z1w == 'line' || Z1w == 'horizontal' || Z1w == 'vertical') {
      if (B_c >= ('0' | 0) && B_c <= +'1') {
        return !![];
      }
    } else if (Z1w == 'ray') {
      if (B_c >= 0 && B_c <= 1 && Y_M >= +'0') {
        return !![];
      }
    }
    return ![];
  };
  q0b.yIntersection = function (N4z, Y8h) {
    var l7p, O3U, q7Q, K3H, z0g, B46, V6r, C6r, B4n, W2A, j9X, p3S;
    l7p = N4z.x0;
    O3U = N4z.x1;
    q7Q = Y8h;
    K3H = Y8h;
    z0g = N4z.y0;
    B46 = N4z.y1;
    A4u.d$R(81);
    V6r = A4u.M3e(0, '0');
    C6r = 10000;
    A4u.p1T(96);
    B4n = A4u.H9P(q7Q, K3H, O3U, l7p, C6r, z0g, V6r, B46);
    A4u.Y3Y();
    A4u.p1T(96);
    W2A = A4u.H9P(V6r, C6r, z0g, V6r, K3H, q7Q, q7Q, l7p);
    A4u.p1T(41);
    j9X = A4u.M3e(B4n, W2A);
    if (B4n === 0) {
      if (W2A === 0) {
        j9X = +'1';
      } else {
        return null;
      }
    }
    A4u.p1T(97);
    p3S = A4u.H9P(z0g, z0g, j9X, B46);
    return p3S;
  };
  q0b.xIntersection = function (X4I, a1a) {
    var U2G,
      A6R,
      x_G,
      U$Z,
      V9_,
      V_H,
      q6T,
      P5Y,
      z4H,
      i96,
      c04,
      r8F,
      K3$,
      n_a,
      S$t;
    U2G = X4I.x0;
    A6R = X4I.x1;
    A4u.d$R(81);
    x_G = A4u.M3e(0, '0');
    U$Z = 10000;
    V9_ = X4I.y0;
    V_H = X4I.y1;
    q6T = a1a;
    P5Y = a1a;
    z4H = -408838577;
    i96 = -1275810576;
    c04 = 2;
    for (
      var l6e = +'1';
      A4u.q78(l6e.toString(), l6e.toString().length, 50583) !== z4H;
      l6e++
    ) {
      A4u.d$R(96);
      r8F = A4u.H9P(x_G, U$Z, A6R, U2G, P5Y, V9_, q6T, V_H);
      A4u.d$R(96);
      K3$ = A4u.M3e(q6T, P5Y, V9_, q6T, U$Z, x_G, x_G, U2G);
      c04 += 2;
    }
    A4u.t3P();
    if (A4u.q78(c04.toString(), c04.toString().length, 91508) !== i96) {
      A4u.d$R(98);
      r8F = A4u.M3e(V_H, x_G, V9_, P5Y, U$Z, U2G, A6R, q6T);
      A4u.d$R(99);
      K3$ = A4u.M3e(U2G, q6T, U$Z, x_G, q6T, P5Y, x_G, V9_);
    }
    A4u.p1T(41);
    n_a = A4u.M3e(r8F, K3$);
    if (r8F === 0) {
      if (K3$ === 0) {
        n_a = 1;
      } else {
        return null;
      }
    }
    A4u.p1T(97);
    S$t = A4u.M3e(U2G, U2G, n_a, A6R);
    return S$t;
  };
  q0b.intersectLineLineX = function (I89, V$i, J$Q, w6K, A0S, T8O, X$Q, u0C) {
    var v27, g_B, I11;
    A4u.Y3Y();
    A4u.d$R(96);
    v27 = A4u.M3e(X$Q, u0C, J$Q, X$Q, T8O, A0S, A0S, I89);
    A4u.d$R(96);
    g_B = A4u.M3e(A0S, T8O, V$i, I89, u0C, J$Q, X$Q, w6K);
    A4u.d$R(41);
    I11 = A4u.M3e(g_B, v27);
    A4u.p1T(97);
    return A4u.M3e(I89, I89, I11, V$i);
  };
  q0b.intersectLineLineY = function (k05, O3h, y3L, U_c, L5K, w09, I1h, V9y) {
    var d7K, A9P, Z37;
    A4u.d$R(96);
    d7K = A4u.M3e(I1h, V9y, y3L, I1h, w09, L5K, L5K, k05);
    A4u.p1T(96);
    A9P = A4u.M3e(L5K, w09, O3h, k05, V9y, y3L, I1h, U_c);
    A4u.d$R(41);
    Z37 = A4u.H9P(A9P, d7K);
    A4u.p1T(97);
    A4u.Y3Y();
    return A4u.H9P(y3L, y3L, Z37, U_c);
  };
};
C = (F8v) => {
  var L3E;
  L3E = F8v.CIQ;
  L3E.deleteRHS = function (R7V, m4J) {
    var h7c;
    h7c = !1;
    for (var U_X in R7V) {
      if (R7V[U_X] == m4J) {
        delete R7V[U_X];
        h7c = !0;
      }
    }
    return h7c;
  };
  L3E.scrub = function (X7E, k8A) {
    var F1V;
    v7ZLk.Y3Y();
    for (var B7u in X7E) {
      F1V = 'undefin';
      F1V += 'ed';
      if (typeof X7E[B7u] == F1V) {
        delete X7E[B7u];
      }
      if (k8A && X7E[B7u] === null) {
        delete X7E[B7u];
      }
    }
  };
  L3E.dataBindSafeAssignment = function (I_P, Q0x) {
    L3E.extend(I_P, Q0x);
    for (var p_e in I_P) {
      if (typeof Q0x[p_e] == 'undefined') {
        I_P[p_e] = undefined;
      }
    }
  };
  L3E.clone = function (Z6f, c3E) {
    var Z$7;
    if (Z6f === null || typeof Z6f != 'object') {
      return Z6f;
    }
    Z$7 = Z6f.constructor;
    v7ZLk.t3P();
    if (
      Z$7 == Date ||
      Z$7 == RegExp ||
      Z$7 == String ||
      Z$7 == Number ||
      Z$7 == Boolean
    ) {
      return new Z$7(Z6f.valueOf());
    }
    if (Z$7 == Function) {
      return function () {
        return Z6f.apply(this, arguments);
      };
    }
    if (!c3E) {
      try {
        c3E = new Z$7();
      } catch (G4g) {
        c3E = Object.create(Object.getPrototypeOf(Z6f));
      }
    }
    for (var v_P in Z6f) {
      c3E[v_P] = c3E[v_P] !== Z6f[v_P] ? L3E.clone(Z6f[v_P], null) : c3E[v_P];
    }
    return c3E;
  };
  L3E.shallowClone = function (v9K) {
    var b7j;
    if (!v9K) {
      return v9K;
    }
    if (v9K.constructor == Array) {
      b7j = new Array(v9K.length);
      for (var o8n = +'0'; o8n < v9K.length; o8n++) {
        b7j[o8n] = v9K[o8n];
      }
      return b7j;
    }
    b7j = {};
    v7ZLk.t3P();
    for (var Z3R in v9K) {
      b7j[Z3R] = v9K[Z3R];
    }
    return b7j;
  };
  L3E.ensureDefaults = function (s_J, m69) {
    var w6W;
    for (var B1c in m69) {
      w6W = 'unde';
      w6W += 'fined';
      if (typeof s_J[B1c] == w6W) {
        s_J[B1c] = m69[B1c];
      }
    }
    return s_J;
  };
  L3E.transferObject = function (O1W, K80) {
    var B1M;
    for (B1M in O1W) {
      if (O1W.hasOwnProperty(B1M)) {
        delete O1W[B1M];
      }
    }
    for (B1M in K80) {
      if (K80.hasOwnProperty(B1M)) {
        O1W[B1M] = K80[B1M];
      }
    }
    v7ZLk.Y3Y();
    return O1W;
  };
  L3E.equals = function (q2i, s2x, p3t) {
    var a1q;
    if (q2i === s2x) {
      return !![];
    }
    if (!q2i && s2x) {
      return !{};
    }
    if (q2i && !s2x) {
      return !!'';
    }
    if (typeof q2i !== typeof s2x) {
      return !{};
    }
    a1q = [...new Set(Object.keys(q2i).concat(Object.keys(s2x)))];
    v7ZLk.t3P();
    for (var L_X of a1q) {
      if (p3t && p3t[L_X]) continue;
      if (typeof q2i[L_X] === 'object') {
        if (L3E.equals(q2i[L_X], s2x[L_X])) continue;
        return !{};
      }
      if (s2x[L_X] != q2i[L_X]) {
        return !1;
      }
    }
    return !'';
  };
  L3E.isEmpty = function (O8Y) {
    for (var L85 in O8Y) {
      if (O8Y.hasOwnProperty(L85)) {
        return !{};
      }
    }
    return !!{};
  };
  L3E.first = function (a0N) {
    for (var H8u in a0N) {
      return H8u;
    }
    v7ZLk.Y3Y();
    return null;
  };
  L3E.last = function (b8B) {
    var n_f;
    v7ZLk.t3P();
    n_f = null;
    for (var Z3w in b8B) {
      n_f = Z3w;
    }
    return n_f;
  };
  L3E.objLength = function (d2w) {
    v7ZLk.t3P();
    var r3O;
    if (!d2w) {
      v7ZLk.p1T(0);
      return v7ZLk.H9P('0', 0);
    }
    r3O = 0;
    for (var I_h in d2w) {
      r3O++;
    }
    return r3O;
  };
  L3E.deriveFromObjectChain = function (v1c, h8D) {
    var k53, l0j;
    if (h8D.indexOf('.') == -1) {
      return { obj: v1c, member: h8D };
    }
    k53 = h8D.split(
      +'8650' != ('7670' ^ 0)
        ? 712.78 !== '6268' * 1
          ? ('248.2' - 0, 515) === '213.5' * 1
            ? 'r'
            : '.'
          : 'e'
        : !!{}
    );
    for (var Z5r = 0; Z5r < k53.length - 1; Z5r++) {
      l0j = k53[Z5r];
      if (!v1c[l0j] && v1c[l0j] !== 0) {
        v1c[l0j] = {};
      }
      v1c = v1c[l0j];
    }
    return { obj: v1c, member: k53[Z5r] };
  };
  L3E.createObjectChainNames = function (M5U, k69) {
    var X1K, u9U;
    X1K = [];
    for (var P2L = 0; P2L < k69.length; P2L++) {
      u9U = '-';
      u9U += '-';
      u9U += '>';
      X1K.push(M5U + u9U + k69[P2L]);
    }
    v7ZLk.Y3Y();
    return X1K;
  };
  L3E.getObjectChainRoot = function (Z4Z) {
    if (!Z4Z) {
      return null;
    }
    v7ZLk.Y3Y();
    return Z4Z.split('-->')[+'0'];
  };
  L3E.existsInObjectChain = function (h2L, w9d) {
    var X3T, m6l, p2U;
    X3T = '-';
    X3T += '-';
    X3T += '>';
    if (w9d.indexOf('-->') == -1) {
      m6l = h2L[w9d];
      if (!m6l && m6l !== 0) {
        return null;
      }
      return { obj: h2L, member: w9d };
    }
    v7ZLk.t3P();
    p2U = w9d.split(X3T);
    for (var b7c = 0; b7c < p2U.length; b7c++) {
      m6l = h2L[p2U[b7c]];
      if (!m6l && m6l !== 0) {
        return null;
      }
      if (b7c < p2U.length - 1) {
        h2L = m6l;
      } else {
        return { obj: h2L, member: p2U[b7c] };
      }
    }
  };
  L3E.derivedFrom = function (Y8F, q_8) {
    if (q_8.isPrototypeOf(Y8F)) {
      return !![];
    }
    v7ZLk.t3P();
    if (Y8F instanceof q_8) {
      return !![];
    }
    return !!0;
  };
  L3E.replaceFields = function (a6w, B_1) {
    var a2s, J1H, x05, O7I, g_j;
    if (!a6w) {
      return a6w;
    }
    a2s = {};
    v7ZLk.t3P();
    for (var x4A in a6w) {
      J1H = a6w[x4A];
      x05 = B_1[x4A];
      if (!x05) {
        x05 = x4A;
      }
      if (J1H && typeof J1H == 'object') {
        if (J1H.constructor == Array) {
          O7I = a2s[x05] = new Array(J1H.length);
          for (var x01 = '0' * 1; x01 < O7I.length; x01++) {
            g_j = J1H[x01];
            if (typeof g_j == 'object') {
              O7I[x01] = L3E.replaceFields(g_j, B_1);
            } else {
              O7I[x01] = g_j;
            }
          }
        } else {
          a2s[x05] = L3E.replaceFields(J1H, B_1);
        }
      } else {
        a2s[x05] = J1H;
      }
    }
    return a2s;
  };
  L3E.removeNullValues = function (v3D) {
    var q1J;
    q1J = L3E.clone(v3D);
    for (var d2B in q1J) {
      if (!q1J[d2B]) {
        delete q1J[d2B];
      }
    }
    v7ZLk.Y3Y();
    return q1J;
  };
  L3E.reverseObject = function (y7s) {
    var r1j;
    r1j = {};
    for (var O9A in y7s) {
      r1j[y7s[O9A]] = O9A;
    }
    v7ZLk.t3P();
    return r1j;
  };
  L3E.getFromNS = (y53, v$U, B9D) => {
    var D_U, x8s, z6t;
    if (y53) {
      D_U = y53;
      x8s = v$U.split(
        (453.31, 715.68) > (4919, +'1869')
          ? 5770 > 447.48
            ? ('7360' << 32, 8300) != 389
              ? 'e'
              : !![]
            : ('504.87' - 0, 't')
          : '.'
      );
      for (var Z4O = 0; Z4O < x8s.length; Z4O++) {
        z6t = x8s[Z4O];
        if (typeof D_U[z6t] === 'undefined') break;
        D_U = D_U[z6t];
      }
      if (Z4O === x8s.length) {
        return D_U;
      }
    }
    v7ZLk.t3P();
    return undefined || B9D;
  };
  L3E.getFnFromNS = (i_g, T5R, u4U) => {
    return L3E.getFromNS(i_g, T5R, function () {
      return u4U;
    });
  };
  L3E.get = (p9H, X4y) => {
    v7ZLk.t3P();
    return L3E.getFromNS(L3E, p9H, X4y);
  };
  L3E.getFn = (l1R, F7f) => {
    v7ZLk.t3P();
    return L3E.getFromNS(L3E, l1R, function () {
      return F7f;
    });
  };
};
R = (j7K) => {
  var Z1K;
  Z1K = j7K.CIQ;
  Z1K.Plotter = function () {
    v7ZLk.t3P();
    this.seriesArray = [];
    this.seriesMap = {};
  };
  Z1K.extend(
    Z1K.Plotter.prototype,
    {
      Series: function (e3T, M$e, B3l, p6E, n4u, l0$) {
        this.name = e3T;
        this.strokeOrFill = M$e;
        this.color = B3l;
        this.moves = [];
        this.text = [];
        if (!p6E || p6E > 1 || p6E < 0) {
          p6E = 1;
        }
        this.opacity = p6E;
        if (!n4u || n4u > 25 || n4u < 1) {
          n4u = 1;
        }
        this.width = n4u;
        v7ZLk.Y3Y();
        this.pattern = Z1K.borderPatternToArray(n4u, l0$);
      },
      newSeries: function (e$3, f2K, h1S, y4k, p2a) {
        var g8S;
        if (h1S.constructor == String) {
          g8S = new this.Series(e$3, f2K, h1S, y4k, p2a);
        } else {
          g8S = new this.Series(
            e$3,
            f2K,
            h1S.color,
            h1S.opacity,
            p2a >= ('0' | 0) ? p2a : Z1K.stripPX(h1S.width),
            h1S.borderTopStyle
          );
        }
        this.seriesArray.push(g8S);
        this.seriesMap[e$3] = g8S;
      },
      reset: function (x5i) {
        var u6U;
        for (var z6I in this.seriesMap) {
          if (x5i && x5i != z6I) continue;
          u6U = this.seriesMap[z6I];
          if (u6U) {
            u6U.moves = [];
            u6U.text = [];
          }
        }
      },
      moveTo: function (u_0, m3I, I9m) {
        var e7V, H37, E3d, i9Y;
        e7V = -222655932;
        H37 = 983520789;
        E3d = 2;
        for (
          var s90 = 1;
          v7ZLk.e_M(s90.toString(), s90.toString().length, +'44069') !== e7V;
          s90++
        ) {
          i9Y = this.seriesMap[u_0];
          E3d += 2;
        }
        if (v7ZLk.e_M(E3d.toString(), E3d.toString().length, 66424) !== H37) {
          i9Y = this.seriesMap[u_0];
        }
        i9Y.moves.push({ action: 'moveTo', x: m3I, y: I9m });
      },
      lineTo: function (D9a, t7R, U2B) {
        v7ZLk.t3P();
        var q2g, i_Y;
        q2g = this.seriesMap[D9a];
        i_Y = q2g.pattern;
        q2g.moves.push({ action: 'lineTo', x: t7R, y: U2B, pattern: i_Y });
      },
      dashedLineTo: function (w0C, Y$u, L7l, O6E) {
        v7ZLk.Y3Y();
        var v9q;
        v9q = this.seriesMap[w0C];
        v9q.moves.push({ action: 'lineTo', x: Y$u, y: L7l, pattern: O6E });
      },
      quadraticCurveTo: function (O$X, V6y, T2Z, y8z, g9J) {
        var Q3A, g8D, m$L;
        Q3A = 'q';
        Q3A += 'uad';
        Q3A += 'raticCurveTo';
        g8D = this.seriesMap[O$X];
        m$L = g8D.pattern;
        g8D.moves.push({
          action: Q3A,
          x0: V6y,
          y0: T2Z,
          x: y8z,
          y: g9J,
          pattern: m$L,
        });
      },
      bezierCurveTo: function (h8x, A4G, S3$, h5P, W8N, n4v, Z8u) {
        var b2G, z1R, K9i;
        v7ZLk.Y3Y();
        b2G = 'bezierCurveT';
        b2G += 'o';
        z1R = this.seriesMap[h8x];
        K9i = z1R.pattern;
        z1R.moves.push({
          action: b2G,
          x0: A4G,
          y0: S3$,
          x1: h5P,
          y1: W8N,
          x: n4v,
          y: Z8u,
          pattern: K9i,
        });
      },
      addText: function (d5o, t23, Z7J, M3s, j$Q, T_L, e_D) {
        var D7c;
        D7c = this.seriesMap[d5o];
        D7c.text.push({ text: t23, x: Z7J, y: M3s, bg: j$Q });
      },
      drawText: function (Y7T, X0X) {
        var p7z, D10, h2V, Y9$;
        for (var W9z = 0; W9z < X0X.text.length; W9z++) {
          p7z = X0X.text[W9z];
          if (p7z.bg) {
            D10 = p7z.width ? p7z.width : Y7T.measureText(p7z.text).width;
            h2V = p7z.height ? p7z.height : 12;
            Y9$ = Y7T.fillStyle;
            Y7T.fillStyle = p7z.bg;
            if (Y7T.textAlign == 'right') {
              Y7T.fillRect(p7z.x, p7z.y - h2V / 2, -D10, -h2V);
            } else {
              Y7T.fillRect(p7z.x, p7z.y - h2V / 2, D10, h2V);
            }
            Y7T.fillStyle = Y9$;
          }
          Y7T.fillText(p7z.text, p7z.x, p7z.y);
        }
      },
      draw: function (O8K, u4n) {
        var g$z, l_K, A8w, U4q, i5x, u53, V_F, m4o, l$I, l7D;
        g$z = O8K.lineWidth;
        l_K = O8K.fillStyle;
        A8w = O8K.strokeStyle;
        U4q = O8K.globalAlpha;
        for (var U_E = 0; U_E < this.seriesArray.length; U_E++) {
          i5x = 'stro';
          i5x += 'ke';
          u53 = 'f';
          u53 += 'i';
          u53 += 'l';
          u53 += 'l';
          V_F = this.seriesArray[U_E];
          if (u4n && V_F.name != u4n) continue;
          O8K.beginPath();
          O8K.lineWidth = V_F.width;
          O8K.globalAlpha = V_F.opacity;
          O8K.fillStyle = V_F.color;
          O8K.strokeStyle = V_F.color;
          O8K.save();
          m4o = [0, 0];
          for (var e6F = 0; e6F < V_F.moves.length; e6F++) {
            l$I = V_F.moves[e6F];
            if (!l$I.pattern) {
              l$I.pattern = [];
            }
            if (
              JSON.stringify(O8K.getLineDash()) !== JSON.stringify(l$I.pattern)
            ) {
              l7D = 'f';
              l7D += 'ill';
              O8K[V_F.strokeOrFill == l7D ? 'fill' : 'stroke']();
              O8K.beginPath();
              O8K.moveTo(m4o[0], m4o[1]);
            }
            O8K.setLineDash(l$I.pattern);
            O8K.lineDashOffset = 0;
            if (l$I.action == 'quadraticCurveTo') {
              O8K[l$I.action](l$I.x0, l$I.y0, l$I.x, l$I.y);
            } else if (l$I.action == 'bezierCurveTo') {
              O8K[l$I.action](l$I.x0, l$I.y0, l$I.x1, l$I.y1, l$I.x, l$I.y);
            } else {
              O8K[l$I.action](l$I.x, l$I.y);
            }
            m4o = [l$I.x, l$I.y];
          }
          O8K[V_F.strokeOrFill == u53 ? 'fill' : i5x]();
          O8K.closePath();
          O8K.restore();
          this.drawText(O8K, V_F);
          O8K.lineWidth = 1;
        }
        O8K.lineWidth = g$z;
        O8K.fillStyle = l_K;
        v7ZLk.Y3Y();
        O8K.strokeStyle = A8w;
        O8K.globalAlpha = U4q;
      },
    },
    !!'1'
  );
};
A = (B8V) => {
  var k$l = v7ZLk;
  var P4i;
  P4i = B8V.CIQ;
  P4i.Renderer = function () {};
  P4i.Renderer.produce = function (I84, e_H) {
    var o7p, Q1c;
    o7p = null;
    k$l.t3P();
    if (I84) {
      for (var c64 in P4i.Renderer) {
        Q1c = P4i.Renderer[c64];
        if (Q1c.requestNew) {
          o7p = Q1c.requestNew(
            I84.split((6276, +'9900') !== 3122 ? '_' : 0x1d2c),
            e_H
          );
        }
        if (o7p) {
          return o7p;
        }
      }
    }
    e_H.type = 'line';
    return new P4i.Renderer.Lines({ params: e_H });
  };
  P4i.Renderer.colorFunctions = {};
  P4i.Renderer.registerColorFunction = function (D7i, q3s) {
    k$l.Y3Y();
    P4i.Renderer.colorFunctions[D7i] = q3s;
  };
  P4i.Renderer.unregisterColorFunction = function (w9l) {
    delete P4i.Renderer.colorFunctions[w9l];
  };
  P4i.Renderer.prototype.init = {};
  P4i.Renderer.prototype.adjustYAxis = function () {};
  P4i.Renderer.prototype.draw = function () {};
  P4i.Renderer.prototype.drawIndividualSeries = function (l1o, j8q) {};
  P4i.Renderer.prototype.construct = function (n2d) {
    var H$9;
    if (!n2d) {
      n2d = {};
    }
    H$9 = n2d.params ? n2d.params : {};
    if (this.init) {
      P4i.ensureDefaults(H$9, this.init);
    }
    if (!H$9.name) {
      H$9.name = P4i.uniqueID();
    }
    if (!H$9.heightPercentage) {
      H$9.heightPercentage = 0.7;
    }
    if (!H$9.opacity) {
      H$9.opacity = 1;
    }
    if (H$9.highlightable !== !!'') {
      H$9.highlightable = !!{};
    }
    if (!H$9.panel) {
      H$9.panel = 'chart';
    }
    if (H$9.yAxis) {
      H$9.yAxis = new P4i.ChartEngine.YAxis(H$9.yAxis);
      if (!H$9.yAxis.name) {
        H$9.yAxis.name = H$9.name;
      }
    }
    this.cb = n2d.callback;
    this.params = H$9;
    this.seriesParams = [];
    this.caches = {};
    this.colors = {};
  };
  P4i.Renderer.prototype.attachSeries = function (S0J, y2d) {
    var q3l, u5U, N5S, P$c, w91, d4g, q3m, A1f, Z0E, Z2m;
    q3l = this.stx;
    if (!q3l) {
      return this;
    }
    u5U = q3l.chart.series[S0J];
    if (!u5U) {
      u5U = { parameters: {} };
    }
    N5S = this.params;
    P$c = u5U.parameters;
    w91 = {
      id: S0J,
      chartType: N5S.type,
      display: P$c.display,
      border_color_up: N5S.defaultBorders ? 'auto' : null,
      fill_color_up: P$c.color,
      opacity_up: N5S.opacity,
      border_color_down: N5S.defaultBorders ? 'auto' : null,
      fill_color_down: P$c.color,
      opacity_down: N5S.opacity,
      color: P$c.color,
      symbol: P$c.symbol,
      symbolObject: P4i.clone(P$c.symbolObject),
    };
    k$l.Y3Y();
    if (typeof y2d == 'string') {
      w91.color = w91.fill_color_down = w91.fill_color_up = y2d;
    } else if (typeof y2d == 'object') {
      for (var Z$q in y2d) {
        w91[Z$q] = y2d[Z$q];
      }
      d4g = w91.color;
      q3m = w91.border_color;
      if (d4g) {
        if (!w91.fill_color_up) {
          w91.fill_color_up = d4g;
        }
        if (!w91.fill_color_down) {
          w91.fill_color_down = d4g;
        }
        if (!w91.fill_color_even) {
          w91.fill_color_even = d4g;
        }
      }
      if (q3m) {
        if (!w91.border_color_up) {
          w91.border_color_up = q3m;
        }
        if (!w91.border_color_down) {
          w91.border_color_down = q3m;
        }
        if (!w91.border_color_even) {
          w91.border_color_even = q3m;
        }
      }
    }
    if (w91.symbol && w91.field != w91.symbol) {
      w91.subField = w91.field;
      w91.field = w91.symbol;
    }
    if (!w91.id) {
      w91.id = P4i.uniqueID();
    }
    A1f = 0;
    for (; A1f < this.seriesParams.length; ++A1f) {
      if (this.seriesParams[A1f].id === w91.id) {
        this.removeSeries(w91.id, !0);
        break;
      }
    }
    k$l.d$R(26);
    this.seriesParams.splice(A1f, k$l.H9P('0', 64), w91);
    if (w91.fill_color_up != w91.fill_color_down) {
      k$l.p1T(18);
      this.colors[k$l.M3e(' up', S0J)] = {
        color: w91.fill_color_up,
        opacity: w91.opacity_up,
        display: w91.display ? w91.display + ' up' : S0J + ' up',
      };
      k$l.p1T(18);
      this.colors[k$l.H9P(' dn', S0J)] = {
        color: w91.fill_color_down,
        opacity: w91.opacity_down,
        display: w91.display ? w91.display + ' down' : S0J + ' down',
      };
    } else {
      this.colors[S0J] = {
        color: w91.fill_color_up,
        opacity: w91.opacity_up,
        display: w91.display ? w91.display : S0J,
      };
    }
    Z0E = N5S.panel;
    if (!q3l.panels[Z0E]) {
      Z2m = N5S.yAxis;
      if (!Z2m) {
        Z2m = new P4i.ChartEngine.YAxis();
        Z2m.needsInitialPadding = !!'1';
      }
      Z2m.name = Z0E;
      q3l.createPanel(Z0E, Z0E, null, null, Z2m);
    } else {
      if (N5S.yAxis) {
        N5S.yAxis = q3l.addYAxis(q3l.panels[Z0E], N5S.yAxis);
        N5S.yAxis.needsInitialPadding = !!'1';
        P$c.yAxis = N5S.yAxis;
        q3l.resizeChart();
      } else if (w91.yAxis) {
        N5S.yAxis = w91.yAxis;
      }
    }
    return this;
  };
  P4i.Renderer.prototype.removeSeries = function (t62, J2e) {
    var L0b, r5V, S4F, e0A, y4i, F6S, v5D, s_i;
    L0b = null;
    r5V = ![];
    S4F = this.stx;
    e0A = S4F.chart;
    for (var L_v in e0A.seriesRenderers) {
      y4i = e0A.seriesRenderers[L_v];
      for (var E_9 = '0' << 0; E_9 < y4i.seriesParams.length; E_9++) {
        F6S = y4i.seriesParams[E_9];
        if (F6S.id == t62 && this === y4i) {
          L0b = E_9;
        } else if (
          F6S.isComparison &&
          F6S.panel == e0A.panel.name &&
          (!F6S.yAxis || F6S.yAxis == e0A.yAxis)
        ) {
          r5V = !!{};
        }
      }
    }
    if (L0b !== null) {
      k$l.d$R(26);
      this.seriesParams.splice(L0b, k$l.H9P('1', 0));
    }
    k$l.d$R(18);
    delete this.colors[k$l.H9P(' up', t62)];
    k$l.p1T(18);
    delete this.colors[k$l.M3e(' dn', t62)];
    delete this.colors[t62];
    if (!J2e) {
      for (var J8W in e0A.seriesRenderers) {
        s_i = e0A.seriesRenderers[J8W];
        for (var A_b = '0' | 0; A_b < s_i.seriesParams.length; A_b++) {
          if (s_i.seriesParams[A_b].id == t62) {
            v5D = !!1;
            break;
          }
          v5D = ![];
        }
        if (v5D) break;
      }
      if (v5D === !!0 || L0b !== null) {
        if (S4F.deleteSeries) {
          S4F.deleteSeries(t62, e0A);
        }
      }
    }
    S4F.deleteYAxisIfUnused(S4F.panels[this.params.panel], this.params.yAxis);
    S4F.resizeChart();
    return this;
  };
  P4i.Renderer.prototype.modifyRenderer = function (G7a) {
    var w2C, O_i, U4m;
    w2C = this.params;
    var { stx: z$F } = this;
    for (var C46 in G7a) {
      O_i = 'baselin';
      O_i += 'e';
      U4m = G7a[C46];
      switch (C46) {
        case O_i:
          if (U4m) {
            if (typeof U4m === 'object') {
              this.params.baseline = P4i.ensureDefaults(
                U4m,
                P4i.ChartEngine.Chart.prototype.baseline
              );
            }
            z$F.registerBaselineToHelper(this);
          } else {
            z$F.removeBaselineFromHelper(this);
          }
          break;
        case 'type':
          this.params.type = U4m;
          break;
        case 'style':
          this.params.style = U4m;
          break;
        default:
          break;
      }
    }
  };
  P4i.Renderer.prototype.getDependents = function (C9E) {
    var l0y, m5H;
    l0y = [];
    for (var M8P in C9E.chart.seriesRenderers) {
      m5H = C9E.chart.seriesRenderers[M8P];
      if (m5H.params.dependentOf == this.params.name) {
        l0y.push(m5H);
      }
    }
    return l0y;
  };
  P4i.Renderer.prototype.undraggable = function (o7d) {
    if (this == o7d.mainSeriesRenderer) {
      return !!'1';
    }
    return this.params.dependentOf == o7d.mainSeriesRenderer.params.name;
  };
  P4i.Renderer.prototype.removeAllSeries = function (W5W) {
    var s1Y;
    if (W5W || this === this.stx.mainSeriesRenderer) {
      s1Y = [];
      for (var r5E = 0; r5E < this.seriesParams.length; r5E++) {
        s1Y.push(this.seriesParams[r5E].id);
      }
      for (var q80 = 0; q80 < s1Y.length; q80++) {
        this.removeSeries(s1Y[q80]);
      }
    }
    this.seriesParams = [];
    this.colors = {};
    this.stx.deleteYAxisIfUnused(
      this.stx.panels[this.params.panel],
      this.params.yAxis
    );
    this.stx.resizeChart();
    return this;
  };
  P4i.Renderer.prototype.getYAxis = function (M4M) {
    var Z$m, m$b;
    if (this.params) {
      if (this.params.yAxis) {
        Z$m = this.params.yAxis;
      } else {
        m$b = M4M.panels[this.params.panel];
        if (!m$b) {
          return !1;
        }
        Z$m = m$b.yAxis;
      }
    } else {
      Z$m = M4M.chart.panel.yAxis;
    }
    return Z$m;
  };
  k$l.t3P();
  P4i.Renderer.prototype.ready = function () {
    this.stx.createDataSet();
    this.stx.draw();
    return this;
  };
  P4i.Renderer.Lines = function (l_k) {
    var n7L;
    this.construct(l_k);
    var { params: K7L } = this;
    if (!K7L.type) {
      K7L.type = 'line';
    }
    if (!K7L.style) {
      n7L = 'mou';
      n7L += 'nt';
      n7L += 'ain';
      switch (K7L.type) {
        case n7L:
          if (K7L.baseline) {
            K7L.style = 'stx_baseline_delta_mountain';
          } else if (K7L.colored) {
            K7L.style = 'stx_colored_mountain_chart';
          } else {
            K7L.style = 'stx_mountain_chart';
          }
          break;
        default:
          K7L.style = 'stx_line_chart';
      }
    }
    this.supportsAnimation = !!1;
    if (K7L.type == 'wave' || K7L.type == 'channel') {
      K7L.step = K7L.vertex = K7L.baseline = K7L.colored = !{};
      this.highLowBars = this.barsHaveWidth = !![];
      this.supportsAnimation = !{};
    } else if (K7L.type == 'step') {
      K7L.step = !![];
    }
    var { baseline: Y6H } = K7L;
    if (Y6H && typeof Y6H === 'object') {
      P4i.ensureDefaults(
        K7L.baseline,
        P4i.ChartEngine.Chart.prototype.baseline
      );
    }
  };
  P4i.inheritsFrom(P4i.Renderer.Lines, P4i.Renderer, !1);
  P4i.Renderer.Lines.requestNew = function (E2o, A8R) {
    var o7M, B1z, d5e, m_8, Y$X, p15, Y0v, w9n;
    o7M = null;
    B1z = A8R.step;
    d5e = A8R.colored;
    m_8 = A8R.baseline;
    Y$X = A8R.vertex;
    for (var K7r = 0; K7r < E2o.length; K7r++) {
      p15 = 'mounta';
      p15 += 'in';
      Y0v = 'lin';
      Y0v += 'e';
      w9n = E2o[K7r];
      switch (w9n) {
        case Y0v:
        case p15:
        case 'wave':
        case 'channel':
          o7M = w9n;
          break;
        case 'baseline':
          m_8 = !0;
          break;
        case 'colored':
          d5e = !'';
          break;
        case 'step':
          B1z = !!1;
          break;
        case 'vertex':
          Y$X = !![];
          break;
        case 'delta':
          break;
        default:
          return null;
      }
    }
    if (o7M === null && !m_8 && !B1z) {
      return null;
    }
    return new P4i.Renderer.Lines({
      params: P4i.extend(A8R, {
        type: o7M,
        step: B1z,
        colored: d5e,
        baseline: m_8,
        vertex: Y$X,
      }),
    });
  };
  P4i.Renderer.Lines.prototype.draw = function () {
    var o4I,
      j_n,
      m9C,
      g2p,
      l8t,
      m9s,
      y8p,
      H6j,
      h5T,
      s1q,
      U4d,
      t38,
      r5M,
      c0v,
      F1H;
    o4I = this.stx;
    j_n = this.stx.panels[this.params.panel];
    m9C = j_n.chart;
    g2p = {};
    function p3p(r3f) {
      k$l.t3P();
      var n$v, W67, N1A, B26;
      n$v = 'st';
      n$v += 'x_lin';
      n$v += 'e_cha';
      n$v += 'rt';
      W67 = r3f.fill_color_up || o4I.getCanvasColor('stx_line_up');
      N1A = r3f.fill_color_down || o4I.getCanvasColor('stx_line_down');
      B26 = r3f.color || o4I.getCanvasColor(n$v);
      return function (n96, f0L, l39) {
        if (!f0L.iqPrevClose && f0L.iqPrevClose !== 0) {
          return B26;
        }
        if (f0L.Close > f0L.iqPrevClose) {
          return W67;
        }
        if (f0L.Close < f0L.iqPrevClose) {
          return N1A;
        }
        return B26;
      };
    }
    m9s = this.seriesParams;
    y8p = this.params.colorFunction;
    for (l8t = 0; l8t < m9s.length; l8t++) {
      H6j = m9s[l8t];
      if (this.params.colored) {
        h5T = '_colo';
        h5T += 'r';
        s1q = '_co';
        s1q += 'lor_do';
        s1q += 'w';
        s1q += 'n';
        U4d = '_co';
        U4d += 'lo';
        U4d += 'r';
        U4d += '_up';
        t38 = [U4d, s1q, h5T];
        for (var t3Z = 0; t3Z < t38.length; t3Z++) {
          r5M = 'a';
          r5M += 'ut';
          r5M += 'o';
          c0v = H6j['border' + t38[t3Z]];
          if (c0v && c0v != r5M) {
            H6j['fill' + t38[t3Z]] = c0v;
          }
        }
        if (!y8p) {
          y8p = p3p(H6j);
        }
        this.params.colorFunction = y8p;
      }
      F1H = {};
      if (m9C.series[H6j.id]) {
        F1H = P4i.clone(m9C.series[H6j.id].parameters);
      }
      g2p[H6j.id] = {
        parameters: P4i.extend(P4i.extend(F1H, this.params), H6j),
        yValueCache: this.caches[H6j.id],
      };
      if (
        this == o4I.mainSeriesRenderer &&
        m9C.customChart &&
        m9C.customChart.colorFunction
      ) {
        g2p[H6j.id].parameters.colorFunction = m9C.customChart.colorFunction;
      }
    }
    o4I.drawSeries(m9C, g2p, this.params.yAxis, this);
    for (l8t in g2p) {
      this.caches[l8t] = g2p[l8t].yValueCache;
    }
  };
  P4i.Renderer.Lines.prototype.drawIndividualSeries = function (H2j, y2i) {
    var t01, D0m, K84, s7E, s1O, R3Q, e1i, P4z, I0Y, P4b;
    t01 = 'c';
    t01 += 'h';
    t01 += 'ann';
    t01 += 'el';
    D0m = 's';
    D0m += 'tr';
    D0m += 'ing';
    if (y2i.invalid) {
      return;
    }
    K84 = this.stx;
    s7E = H2j.context;
    s1O = null;
    k$l.Y3Y();
    R3Q = y2i.colorFunction;
    e1i = K84.panels[y2i.panel] || H2j.panel;
    if (typeof R3Q == D0m) {
      R3Q = P4i.Renderer.colorFunctions[R3Q];
      if (!R3Q) {
        return;
      }
    }
    if (y2i.vertex) {
      s7E.save();
      s7E.lineJoin = 'bevel';
    }
    if (y2i.type == 'wave') {
      s1O = K84.drawWaveChart(e1i, y2i);
    } else if (y2i.baseline) {
      s1O = K84.drawBaselineChart(e1i, y2i);
      K84.positionBaselineHandle(this);
    } else if (y2i.type == 'mountain') {
      P4z = 1722699989;
      I0Y = -2111007364;
      P4b = 2;
      for (
        var c9m = 1;
        k$l.q78(c9m.toString(), c9m.toString().length, '48083' ^ 0) !== P4z;
        c9m++
      ) {
        y2i.returnObject = !1;
        s1O = K84.drawMountainChart(e1i, y2i, R3Q);
        P4b += +'2';
      }
      if (k$l.e_M(P4b.toString(), P4b.toString().length, +'28643') !== I0Y) {
        y2i.returnObject = !!1;
        s1O = K84.drawMountainChart(e1i, y2i, R3Q);
      }
      y2i.returnObject = !!'1';
      s1O = K84.drawMountainChart(e1i, y2i, R3Q);
    } else if (y2i.type == t01) {
      y2i.returnObject = !!{};
      s1O = K84.drawChannelChart(e1i, R3Q, y2i);
    } else {
      y2i.returnObject = !'';
      s1O = K84.drawLineChart(e1i, y2i.style, R3Q, y2i);
    }
    if (y2i.vertex) {
      K84.scatter(e1i, {
        yAxis: y2i.yAxis,
        field: y2i.symbol || y2i.field,
        subField: y2i.subField,
        symbol: y2i.symbol,
        color: y2i.vertex_color,
        highlight: y2i.highlight,
      });
      s7E.restore();
    }
    return s1O;
  };
  P4i.Renderer.OHLC = function (Z_a) {
    var y7a, N8a;
    y7a = 'ca';
    y7a += 'n';
    y7a += 'd';
    y7a += 'le';
    this.construct(Z_a);
    N8a = this.params;
    if (!N8a.type) {
      N8a.type = y7a;
    }
    this.highLowBars = this.barsHaveWidth = this.standaloneBars = !![];
    if (N8a.histogram) {
      N8a.type = 'candle';
      this.highLowBars = !1;
      N8a.volume = N8a.hollow = !{};
    }
    if (N8a.type == 'bar') {
      N8a.volume = N8a.hollow = N8a.histogram = !!'';
    }
    if (N8a.type == 'candle') {
      N8a.hlc = N8a.colored = ![];
    }
    if (N8a.volume) {
      N8a.hollow = !![];
    }
  };
  P4i.inheritsFrom(P4i.Renderer.OHLC, P4i.Renderer, !!'');
  P4i.Renderer.OHLC.requestNew = function (c0D, s2o) {
    var u3v, d8m, a5n;
    u3v = null;
    d8m = s2o.histogram;
    for (var s_K = 0; s_K < c0D.length; s_K++) {
      a5n = c0D[s_K];
      switch (a5n) {
        case 'candle':
          u3v = a5n;
          break;
        case 'histogram':
          d8m = !!'1';
          u3v = 'candle';
          break;
        default:
          return null;
      }
    }
    if (u3v === null) {
      return null;
    }
    return new P4i.Renderer.OHLC({
      params: P4i.extend(s2o, { type: u3v, histogram: d8m }),
    });
  };
  P4i.Renderer.OHLC.getChartParts = function (e2e, P8M) {
    var a43,
      A0w,
      A83,
      L_T,
      A_c,
      X8T,
      f67,
      p5V,
      k1X,
      A6w,
      Q7q,
      b9Z,
      J0w,
      y4G,
      o84;
    a43 = 'ca';
    a43 += 'n';
    a43 += 'd';
    a43 += 'le';
    A0w = 'ca';
    A0w += 'ndl';
    A0w += 'e';
    A83 = 'fil';
    A83 += 'l_';
    A83 += 'color_u';
    A83 += 'p';
    L_T = 'stx_candle_';
    L_T += 'up';
    A_c = 's';
    A_c += 'h';
    A_c += 'ado';
    A_c += 'w';
    X8T = 'sha';
    X8T += 'dow';
    f67 = 'cand';
    f67 += 'le';
    p5V = 'sh';
    p5V += 'ado';
    p5V += 'w';
    k1X = 'ca';
    k1X += 'ndle';
    A6w = 'fill_c';
    A6w += 'ol';
    A6w += 'or_even';
    Q7q = 'h';
    Q7q += 'isto';
    Q7q += 'g';
    Q7q += 'ram';
    b9Z = 'fill';
    b9Z += '_';
    b9Z += 'color_up';
    J0w = 8;
    y4G = 16;
    o84 = 32;
    return [
      {
        type: 'histogram',
        drawType: 'histogram',
        style: 'stx_histogram_up',
        condition: J0w,
        fill: b9Z,
        border: 'border_color_up',
        useColorInMap: !!'1',
        useBorderStyleProp: !!'1',
      },
      {
        type: 'histogram',
        drawType: 'histogram',
        style: 'stx_histogram_down',
        condition: y4G,
        fill: 'fill_color_down',
        border: 'border_color_down',
        useColorInMap: !!'1',
        useBorderStyleProp: !![],
      },
      {
        type: Q7q,
        drawType: 'histogram',
        style: 'stx_histogram_even',
        condition: o84,
        fill: A6w,
        border: 'border_color_even',
        skipIfPass: !![],
        useColorInMap: !![],
        useBorderStyleProp: !0,
      },
      {
        type: 'candle',
        drawType: 'shadow',
        style: 'stx_candle_shadow',
        border: 'border_color_up',
      },
      {
        type: k1X,
        drawType: p5V,
        style: 'stx_candle_shadow_up',
        condition: J0w,
        border: 'border_color_up',
      },
      {
        type: f67,
        drawType: X8T,
        style: 'stx_candle_shadow_down',
        condition: y4G,
        border: 'border_color_down',
      },
      {
        type: 'candle',
        drawType: A_c,
        style: 'stx_candle_shadow_even',
        condition: o84,
        border: 'border_color_even',
        skipIfPass: !0,
      },
      {
        type: 'candle',
        drawType: 'candle',
        style: L_T,
        condition: J0w,
        fill: A83,
        border: 'border_color_up',
        useColorInMap: !!{},
        useBorderStyleProp: !!{},
      },
      {
        type: A0w,
        drawType: a43,
        style: 'stx_candle_down',
        condition: y4G,
        fill: 'fill_color_down',
        border: 'border_color_down',
        useColorInMap: !'',
        useBorderStyleProp: !!1,
      },
    ];
  };
  P4i.Renderer.OHLC.prototype.draw = function () {
    var g3I, M2o, p4y, F8f, w$h, M8_, X6K, f4o;
    g3I = this.stx;
    M2o = this.stx.panels[this.params.panel];
    p4y = M2o.chart;
    F8f = {};
    M8_ = this.seriesParams;
    for (w$h = 0; w$h < M8_.length; w$h++) {
      X6K = M8_[w$h];
      f4o = {};
      if (p4y.series[X6K.id]) {
        f4o = P4i.clone(p4y.series[X6K.id].parameters);
      }
      F8f[X6K.id] = {
        parameters: P4i.extend(P4i.extend(f4o, this.params), X6K),
      };
      if (
        this == g3I.mainSeriesRenderer &&
        p4y.customChart &&
        p4y.customChart.colorFunction
      ) {
        F8f[X6K.id].parameters.colorFunction = p4y.customChart.colorFunction;
      }
    }
    g3I.drawSeries(p4y, F8f, this.params.yAxis, this);
    k$l.Y3Y();
    for (w$h in F8f) {
      if (F8f[w$h].yValueCache) {
        this.caches[w$h] = F8f[w$h].yValueCache;
      }
    }
  };
  P4i.Renderer.OHLC.prototype.getColor = function (
    y78,
    F56,
    x5z,
    E7E,
    A8A,
    D7f
  ) {
    var j1D, L_f, Z$w, g1m, m54, S6q;
    j1D = D7f || x5z.color;
    L_f = this.params.yAxis || F56.yAxis;
    if (E7E) {
      j1D = D7f || x5z.borderLeftColor || x5z['border-left-color'];
      if (!j1D) {
        return null;
      }
    }
    if (!A8A) {
      return j1D;
    }
    Z$w = y78.pixelFromTransformedValue(L_f.highValue, F56);
    if (isNaN(Z$w)) {
      Z$w = 0;
    }
    g1m = x5z.backgroundColor;
    k$l.Y3Y();
    if (j1D && !P4i.isTransparent(j1D)) {
      m54 = 'bott';
      m54 += 'o';
      m54 += 'm';
      S6q = y78.chart.context.createLinearGradient(
        0,
        Z$w,
        0,
        2 * L_f[L_f.flipped ? 'top' : m54] - Z$w
      );
      S6q.addColorStop(0, j1D);
      S6q.addColorStop(1, g1m);
      return S6q;
    }
    return g1m;
  };
  P4i.Renderer.OHLC.prototype.drawIndividualSeries = function (g_i, V2n) {
    var p4P,
      F_r,
      b__,
      v3A,
      D6T,
      D4s,
      l4v,
      K7E,
      P$a,
      P4$,
      t_3,
      p3h,
      V3c,
      K99,
      v4C,
      s$W,
      s77,
      A9R,
      o78,
      F9M,
      a19,
      m9F,
      N3m,
      y3J,
      m_M,
      e2k,
      y1$,
      f6g,
      g4l,
      z17,
      X2G,
      G1P,
      E8S,
      h_x,
      M49,
      Q2D,
      a37,
      f7E,
      w8U,
      Q0a,
      s73,
      T9$,
      u_7;
    p4P = 'o';
    p4P += 'p';
    p4P += 'en';
    if (V2n.invalid) {
      return;
    }
    F_r = this.stx;
    b__ = g_i.context;
    k$l.Y3Y();
    v3A = V2n.colorFunction;
    D6T = F_r.panels[V2n.panel] || g_i.panel;
    if (typeof v3A == 'string') {
      v3A = P4i.Renderer.colorFunctions[v3A];
      if (!v3A) {
        return;
      }
    }
    D4s =
      F_r.layout.candleWidth - g_i.tmpWidth <= ('2' | 0) &&
      g_i.tmpWidth <= +'3';
    l4v = 1;
    K7E = 2;
    P$a = 4;
    k$l.p1T(20);
    P4$ = k$l.M3e('8', 64);
    t_3 = 16;
    p3h = 32;
    if (!g_i.state.chartType) {
      g_i.state.chartType = {};
    }
    V3c = g_i.state.chartType.pass = {};
    K99 = F_r.colorByCandleDirection;
    if (V2n.colorBasis) {
      K99 = V2n.colorBasis == p4P;
    }
    v4C = V2n.histogram;
    s$W = V2n.type;
    s77 = V2n.hollow;
    A9R = F_r.noWicksOnCandles[s$W];
    F_r.startClip(D6T.name);
    o78 = null;
    F9M = { colors: [], cache: [] };
    a19 = [];
    if (v3A) {
      m9F = {
        isHistogram: v4C,
        field: V2n.field,
        yAxis: V2n.yAxis,
        isVolume: V2n.volume,
        highlight: V2n.highlight,
      };
      if (!v4C && s$W == 'bar') {
        N3m = 'stx_bar';
        N3m += '_chart';
        y3J = 'ba';
        y3J += 'r';
        m9F.type = V2n.hlc ? 'hlc' : y3J;
        F9M = F_r.drawBarChart(D6T, N3m, v3A, m9F);
      } else {
        if (s$W == 'candle' && !A9R) {
          F_r.drawShadows(D6T, v3A, m9F);
        }
        F9M = F_r.drawCandles(D6T, v3A, m9F);
        m9F.isOutline = !'';
        if (s77 || !D4s) {
          F_r.drawCandles(D6T, v3A, m9F);
        }
      }
    } else {
      m_M = v4C && V2n.gradient !== ![];
      e2k = 513306568;
      y1$ = -1787734301;
      f6g = +'2';
      for (
        var Q31 = 1;
        k$l.e_M(Q31.toString(), Q31.toString().length, 46311) !== e2k;
        Q31++
      ) {
        g4l = P4i.Renderer.OHLC.getChartParts(V2n.style, K99);
        f6g += 2;
      }
      if (k$l.q78(f6g.toString(), f6g.toString().length, 33435) !== y1$) {
        g4l = P4i.Renderer.OHLC.getChartParts(V2n.style, K99);
      }
      for (var t3y = +'0'; t3y < g4l.length; t3y++) {
        z17 = 'b';
        z17 += 'a';
        z17 += 'r';
        X2G = g4l[t3y];
        if (X2G.skipIfPass && !V3c.even) continue;
        else if (v4C) {
          if (X2G.type != 'histogram') continue;
        } else if (s$W == z17) {
          if (X2G.type != 'bar') continue;
          else if (V2n.colored && !X2G.condition) continue;
          else if (!V2n.colored && X2G.condition) continue;
        } else if (s77) {
          if (X2G.type != 'hollow') continue;
          else if (X2G.drawType == 'shadow' && A9R) continue;
        } else if (s$W == 'candle') {
          if (X2G.type != 'candle') continue;
          else if (X2G.drawType == 'shadow') {
            G1P = 'stx_candle_shadow_';
            G1P += 'even';
            E8S = 's';
            E8S += 'tx';
            E8S += '_candle_shadow_u';
            E8S += 'p';
            if (A9R) continue;
            h_x = V2n.border_color_up || F_r.getCanvasColor(E8S);
            M49 =
              V2n.border_color_down ||
              F_r.getCanvasColor('stx_candle_shadow_down');
            Q2D = V2n.border_color_even || F_r.getCanvasColor(G1P);
            if (
              !P4i.colorsEqual(h_x, M49) ||
              !P4i.colorsEqual(h_x, Q2D) ||
              !P4i.colorsEqual(h_x, F_r.defaultColor)
            ) {
              if (!X2G.condition) continue;
            } else if (X2G.condition) continue;
          }
        } else continue;
        a37 = F_r.canvasStyle(X2G.style);
        f7E = this.getColor(F_r, D6T, a37, ![], !'1', V2n[X2G.fill]);
        w8U = this.getColor(F_r, D6T, a37, !{}, m_M, V2n[X2G.fill]);
        Q0a = this.getColor(
          F_r,
          D6T,
          a37,
          X2G.useBorderStyleProp && !D4s,
          m_M,
          V2n[X2G.border]
        );
        if (X2G.drawType == 'candle') {
          s73 = 'holl';
          s73 += 'o';
          s73 += 'w';
          if (X2G.type == s73) {
            T9$ = 'tra';
            T9$ += 'ns';
            T9$ += 'parent';
            if (!P4i.isTransparent(w8U) && P4i.colorsEqual(Q0a, w8U)) {
              Q0a = X2G.useColorInMap ? T9$ : w8U;
            }
            if (!X2G.useColorInMap) {
              w8U = F_r.containerColor;
            }
          } else if (X2G.type == 'candle') {
            if (D4s) {
              if (P4i.isTransparent(w8U)) {
                w8U = Q0a;
              } else {
                Q0a = w8U;
              }
            }
          }
        }
        b__.globalAlpha = V2n.opacity;
        a19.push(
          F_r.drawBarTypeChartInner({
            fillColor: w8U,
            borderColor: Q0a,
            condition: X2G.condition,
            style: X2G.style,
            type: s$W == 'bar' && V2n.hlc ? 'hlc' : X2G.drawType,
            panel: D6T,
            field: V2n.field,
            yAxis: V2n.yAxis,
            volume: V2n.volume && V2n.hollow,
            highlight: V2n.highlight,
          })
        );
        if (!o78) {
          o78 = {};
        }
        if (X2G.useColorInMap) {
          o78[f7E] = 1;
        }
      }
    }
    F_r.endClip();
    for (var z4n in o78) {
      if (!V2n.hollow || !P4i.equals(z4n, F_r.containerColor)) {
        F9M.colors.push(z4n);
      }
    }
    for (z4n = '0' - 0; z4n < a19.length; z4n++) {
      for (var S52 = 0; S52 < a19[z4n].cache.length; S52++) {
        u_7 = a19[z4n].cache[S52];
        if (u_7 || u_7 === 0) {
          F9M.cache[S52] = u_7;
        }
      }
    }
    return F9M;
  };
  P4i.Renderer.Candles = function (h9p) {
    var D1D, c2l;
    k$l.Y3Y();
    D1D = 'c';
    D1D += 'an';
    D1D += 'dl';
    D1D += 'e';
    this.construct(h9p);
    c2l = this.params;
    c2l.type = D1D;
    this.highLowBars = this.barsHaveWidth = this.standaloneBars = !![];
    c2l.hlc = c2l.colored = c2l.histogram = !{};
    if (c2l.volume) {
      c2l.hollow = !!{};
    }
  };
  P4i.inheritsFrom(P4i.Renderer.Candles, P4i.Renderer.OHLC, !!'');
  P4i.Renderer.SimpleHistogram = function (Z66) {
    var n46;
    this.construct(Z66);
    n46 = this.params;
    n46.type = 'candle';
    n46.histogram = !'';
    this.barsHaveWidth = this.standaloneBars = !!{};
    this.highLowBars = ![];
    k$l.t3P();
    n46.hlc = n46.colored = n46.hollow = n46.volume = ![];
  };
  P4i.inheritsFrom(P4i.Renderer.SimpleHistogram, P4i.Renderer.Candles, !!0);
};
k = (X6_) => {
  var y_J = v7ZLk;
  y_J.Y3Y();
  var m51;
  m51 = X6_.CIQ;
  m51.capitalize = function (k4H) {
    if (!k4H) {
      return '';
    }
    y_J.Y3Y();
    return k4H.charAt(0).toUpperCase() + k4H.slice(+'1');
  };
  m51.camelCaseRegExp = /-([a-z])/g;
  m51.makeCamelCase = function (y0m) {
    y_J.Y3Y();
    return y0m.replace(m51.camelCaseRegExp, function (V7r) {
      y_J.t3P();
      return V7r['1' | 1].toUpperCase();
    });
  };
  m51.uniqueID = function (W70) {
    y_J.t3P();
    var r5m, t$h, r6E, r6B;
    if (W70) {
      r5m = new Date().getTime();
      if (
        typeof window !== 'undefined' &&
        window.performance &&
        typeof window.performance.now === 'function'
      ) {
        r5m += window.performance.now();
      }
      t$h = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (x_9) {
          var u08;
          y_J.d$R(77);
          var S6u = y_J.H9P(144, 1994, 6, 14);
          y_J.p1T(0);
          var i1q = y_J.M3e(208, 192);
          u08 = (r5m + Math.random() * S6u) % i1q | 0;
          y_J.p1T(41);
          r5m = Math.floor(y_J.M3e(16, r5m));
          return (
            x_9 ==
            (4299 === (226.15, 635.22)
              ? 9.78e3
              : 1529 !== (9445, +'941.84')
              ? 'x'
              : 2986 == 413.64
              ? (644.75, 770.82)
              : 906.98)
              ? u08
              : (u08 & 0x3) | +'0x8'
          ).toString(16);
        }
      );
      return t$h;
    }
    r6E = new Date();
    r6B = r6E.getTime().toString(36);
    r6B += Math.floor(Math.random() * Math.pow(36, 2)).toString(36);
    return r6B.toUpperCase();
  };
};
B = (v6D) => {};
M = (Q1n) => {
  var j8R;
  j8R = Q1n.CIQ;
  j8R.getHostName = function (B$M) {
    try {
      return B$M.match(/:\/\/(.[^/]+)/)[1];
    } catch (A6h) {
      return '';
    }
  };
  j8R.qs = function (y4b) {
    var j6O, s8K, B2k, G6N;
    j6O = {};
    if (!y4b) {
      y4b = window.location.search.substring(1);
    }
    s8K = y4b.split('&');
    for (var Z00 = 0; Z00 < s8K.length; Z00++) {
      B2k = s8K[Z00].indexOf(
        (3250, 5590) < 476
          ? ('U', 1.29e3)
          : (7380, 6177) !== 3200
          ? '='
          : (771.11, 'i')
      );
      if (B2k > 0) {
        G6N = s8K[Z00].substring(0, B2k);
        v7ZLk.d$R(18);
        j6O[G6N] = s8K[Z00].substring(v7ZLk.M3e(1, B2k));
      } else {
        G6N = s8K[Z00];
        j6O[G6N] = null;
      }
    }
    v7ZLk.Y3Y();
    return j6O;
  };
  j8R.postAjax = function (c1j, w6G, n5P, K2f, j44) {
    var b_$ = v7ZLk;
    var k2I, g4e, p7X, R0q, Y2w, G7z, y5B;
    if (typeof c1j == 'string') {
      c1j = {
        url: c1j,
        payload: w6G,
        cb: n5P,
        contentType: K2f,
        noEpoch: j44,
        method: null,
        responseHeaders: ![],
      };
    }
    k2I = c1j.url;
    g4e = c1j.cb;
    p7X = c1j.payload;
    if (!g4e) {
      g4e = function () {};
    }
    if (!c1j.ungovernable) {
      if (
        j8R.Extras &&
        j8R.Extras.RequestLimiter &&
        j8R.Extras.RequestLimiter.hitRequestLimit(k2I)
      ) {
        b_$.d$R(20);
        g4e(b_$.M3e('429', 32), null, {});
        return;
      }
    }
    R0q = new XMLHttpRequest();
    if (!R0q) {
      return !!0;
    }
    Y2w = new Date();
    if (!c1j.noEpoch) {
      if (k2I.indexOf('?') == -1) {
        k2I += '?ciqrandom=' + Y2w.getTime();
      } else {
        k2I += '&ciqrandom=' + Y2w.getTime();
      }
    }
    G7z = c1j.method;
    y5B = c1j.headers;
    if (!G7z) {
      G7z = p7X ? 'POST' : 'GET';
    }
    R0q.open(G7z, k2I, !![]);
    if (!c1j.contentType) {
      c1j.contentType = 'application/x-www-form-urlencoded';
    }
    if (p7X) {
      R0q.setRequestHeader('Content-Type', c1j.contentType);
    }
    if (y5B) {
      for (var q2Q in y5B) {
        R0q.setRequestHeader(q2Q, y5B[q2Q]);
      }
    }
    if (c1j.timeout) {
      R0q.timeout = c1j.timeout;
    }
    R0q.ontimeout = function () {
      g4e(408, null, {});
    };
    R0q.onload = function () {
      if (this.status === 0) {
        this.status = '0';
      } else if (!this.status) {
        this.status = 200;
      }
      b_$.Y3Y();
      g4e(this.status, this.responseText, y9P(this));
    };
    R0q.onerror = function () {
      g4e((593.29, '5340' * 1) !== 5470 ? '0' : 7.69e3, null, {});
    };
    function y9P(z8_) {
      var J3d, s8A, E3$, R8l;
      J3d = {};
      if (!c1j.responseHeaders) {
        return;
      }
      s8A = z8_.getAllResponseHeaders();
      E3$ = s8A.split('\n');
      b_$.Y3Y();
      for (var R38 = 0; R38 < E3$.length; R38++) {
        R8l = E3$[R38].split(':');
        while (R8l[1] && R8l[+'1'].charAt(0) == ' ') {
          R8l[1] = R8l[1].substring(1);
        }
        if (R8l[0] !== '') {
          J3d[R8l.shift()] = R8l.join(':');
        }
      }
      return J3d;
    }
    try {
      R0q.send(p7X);
    } catch (r4e) {
      g4e(
        (820, '3604' * 1) != +'4323'
          ? +'1830' >= 133
            ? (4480, 6450) == ('31.37' - 0, 9890)
              ? (!!'1', 1.49e3)
              : '0'
            : 'p'
          : +'131.75',
        r4e,
        {}
      );
    }
    return !!1;
  };
  j8R.loadUI = function (r6g, F4G, D$G) {
    var Q$L = v7ZLk;
    var W4l, S$I, A8u, x$M, G5k;
    Q$L.Y3Y();
    W4l = '"';
    W4l += ']';
    if (!F4G || typeof F4G == 'function') {
      D$G = F4G;
      F4G = document.body;
    }
    S$I = F4G.ownerDocument;
    Q$L.p1T(28);
    A8u = S$I.querySelector(Q$L.H9P(r6g, 'iframe[original-url="', W4l));
    x$M = function () {
      var H6m, z0y, L5o, A0E, D22;
      Q$L.Y3Y();
      H6m = null;
      try {
        H6m = this.contentDocument;
      } catch (k9g) {
        return D$G(k9g);
      }
      if (H6m && !H6m.title) {
        z0y = 'd';
        z0y += 'i';
        z0y += 'v';
        L5o = H6m.body.innerHTML;
        A0E = document.createElement(z0y);
        j8R.innerHTML(A0E, L5o);
        for (var i3t = 0; i3t < A0E.children.length; i3t++) {
          D22 = A0E.children[i3t].cloneNode(!'');
          F4G.appendChild(D22);
        }
        D$G(null);
      } else {
        D$G(new Error('iFrame not found or document has a title'));
      }
    };
    if (A8u) {
      G5k = null;
      try {
        G5k = A8u.contentDocument;
      } catch (G2Z) {
        return D$G(G2Z);
      }
      if (
        G5k.readyState === 'complete' &&
        G5k.location &&
        G5k.location.href !== 'about:blank'
      ) {
        x$M.call(A8u);
      } else {
        A8u.addEventListener('load', x$M);
      }
    } else {
      A8u = document.createElement('iframe');
      A8u.setAttribute('original-url', r6g);
      Q$L.d$R(19);
      var h0P = Q$L.H9P(1, 7, 8, 13);
      Q$L.p1T(100);
      var s_f = Q$L.H9P(16, 11, 18, 6097);
      Q$L.d$R(101);
      var h_u = Q$L.M3e(13, 6482, 2, 647, 12);
      Q$L.p1T(79);
      var R$s = Q$L.H9P(14, 18, 15, 18, 0);
      Q$L.d$R(8);
      var e_d = Q$L.H9P(7, 106, 17);
      Q$L.p1T(63);
      var u0M = Q$L.H9P(3, 6, 1, 5227);
      Q$L.p1T(0);
      var x8W = Q$L.H9P(2725, 5);
      Q$L.d$R(0);
      var z0k = Q$L.M3e(878, 7);
      Q$L.d$R(0);
      var r$u = Q$L.H9P(66400, 63080);
      Q$L.d$R(18);
      var z0s = Q$L.M3e(6174, 1235);
      A8u.src =
        r6g +
        (r6g.indexOf('?') === h0P
          ? (549.04, s_f) <= h_u
            ? '?'
            : ('733.89' * R$s, '8860' >> e_d) >= 637.69
            ? !![]
            : !!''
          : u0M >= 776.1
          ? '&'
          : (x8W, z0k) === r$u
          ? +'0x13ef'
          : z0s) +
        j8R.uniqueID();
      A8u.hidden = !!'1';
      A8u.addEventListener('load', x$M);
      S$I.body.appendChild(A8u);
    }
  };
  j8R.loadScript = function (Q2a, B8c, q6F, p9Q) {
    var K60 = v7ZLk;
    var I1b, e$X, l3n, U0o;
    if (!j8R.loadedScripts) {
      j8R.loadedScripts = {};
    }
    if (j8R.loadedScripts[Q2a]) {
      if (B8c) {
        B8c();
      }
      return;
    }
    I1b = document.createElement('SCRIPT');
    if (q6F) {
      e$X = 'mo';
      e$X += 'du';
      e$X += 'le';
      I1b.type = e$X;
      I1b.crossOrigin = 'use-credentials';
    } else {
      I1b.async = !!1;
    }
    I1b.onload = function () {
      K60.t3P();
      j8R.loadedScripts[Q2a] = !!{};
      if (B8c) {
        B8c();
      }
    };
    l3n = Q2a;
    if (l3n.indexOf('?') == -1) {
      l3n = l3n + '?' + Date.now();
    } else {
      K60.p1T(0);
      var Y6p = K60.M3e(1250, 3);
      K60.p1T(58);
      var z78 = K60.M3e(1, 17, 80, 4, 148);
      K60.d$R(9);
      var v4u = K60.M3e(20, 122, 630, 37);
      K60.d$R(102);
      var n2W = K60.M3e(5, 3895, 9348, 3, 779);
      l3n =
        l3n +
        (847.92 != 459.17 ? (Y6p > (z78, v4u) ? '&' : (!!'1', n2W)) : 'Z') +
        Date.now();
    }
    I1b.src = l3n;
    U0o = (p9Q || document).getElementsByTagName('script')[+'0'];
    if (!U0o) {
      (p9Q || document).body.append(I1b);
    } else {
      U0o.parentNode.insertBefore(I1b, U0o.nextSibling);
    }
  };
  j8R.loadStylesheet = function (s$b, I6J, V64) {
    var T6H = v7ZLk;
    var W14, l1c, c8Z, o$f, U51, H5z;
    W14 = 'sc';
    W14 += 'reen';
    l1c = 'te';
    l1c += 'xt/cs';
    l1c += 's';
    c8Z = 'st';
    c8Z += 'yle';
    c8Z += 'sh';
    c8Z += 'eet';
    o$f = document.createElement('link');
    o$f.rel = c8Z;
    o$f.type = l1c;
    o$f.media = W14;
    T6H.p1T(103);
    var o0C = T6H.H9P(110640, 3, 17, 15, 27658);
    T6H.d$R(8);
    var C8o = T6H.M3e(10, 98583, 92794);
    T6H.p1T(104);
    var j3E = T6H.H9P(2112973, 111600, 19, 13);
    T6H.d$R(40);
    var b5e = T6H.H9P(2964, 5, 14573);
    T6H.p1T(105);
    var D8V = T6H.M3e(13, 20, 194085, 1961, 5);
    T6H.p1T(18);
    var q8Y = T6H.M3e(20, 3930);
    T6H.d$R(2);
    var B8e = T6H.M3e(11, 77, 11, 5, 19);
    T6H.d$R(31);
    var M1c = T6H.H9P(4406, 5138, 7);
    T6H.p1T(59);
    var V8w = T6H.H9P(1929, 3, 19, 1278);
    T6H.d$R(106);
    var H4c = T6H.H9P(13, 20, 18, 6, 3);
    T6H.d$R(59);
    var T_E = T6H.M3e(9015, 3005, 13, 9014);
    T6H.p1T(34);
    var p4V = T6H.H9P(478162, 6, 81180, 17);
    T6H.d$R(107);
    var h_T = T6H.M3e(6929, 5, 6, 8);
    o$f.href =
      s$b +
      (s$b.indexOf(
        '582.44' - 0 !== 503.3
          ? 867.82 <= o0C
            ? '?'
            : (C8o, j3E) < b5e
            ? (D8V, !!'1')
            : (869.38, q8Y)
          : 'a'
      ) === B8e
        ? '?'
        : (M1c, V8w) === '123.67' * H4c
        ? (T_E, 402.87) <= (p4V, h_T)
          ? !![]
          : '72.64' - 0
        : '&') +
      Date.now();
    o$f.onload = function () {
      T6H.t3P();
      if (this.loaded) {
        return;
      }
      this.loaded = !0;
      if (I6J) {
        I6J();
      }
    };
    U51 = (V64 || document).getElementsByTagName('link');
    T6H.d$R(35);
    var V3H = T6H.M3e(18, 17);
    H5z = U51[U51.length - V3H];
    if (!H5z) {
      (V64 || document).head.append(o$f);
    } else {
      H5z.parentNode.insertBefore(o$f, H5z.nextSibling);
    }
  };
  j8R.loadWidget = function (x6D, r6F, m6z, I2L) {
    var k9W = v7ZLk;
    var d6a;
    if (!r6F || typeof r6F == 'function') {
      m6z = r6F;
      r6F = document.body;
    }
    d6a = r6F.ownerDocument;
    k9W.d$R(18);
    k9W.Y3Y();
    j8R.loadStylesheet(
      k9W.M3e('.css', x6D),
      function () {
        k9W.p1T(18);
        j8R.loadUI(k9W.M3e('.html', x6D), r6F, function (X3E) {
          k9W.Y3Y();
          if (X3E) {
            m6z(X3E);
          } else {
            k9W.d$R(18);
            j8R.loadScript(k9W.H9P('.js', x6D), m6z, I2L, d6a);
          }
        });
      },
      d6a
    );
  };
  j8R.waitForPlugins = function (p6l, B8n, R70) {
    var T4e, A_l, s7G, N5y;
    T4e = 0;
    A_l = p6l.length;
    if (!A_l) {
      B8n();
      return;
    }
    for (var P3w = 0; P3w < A_l; P3w++) {
      s7G = 'cq-' + p6l[P3w];
      N5y = (R70 || document).getElementsByTagName(s7G)[0];
      if (N5y && N5y.hasAttribute('loaded')) {
        T4e++;
      }
    }
    if (A_l !== T4e) {
      return setTimeout(function () {
        v7ZLk.Y3Y();
        j8R.waitForPlugins(p6l, B8n, R70);
      }, 0);
    }
    v7ZLk.t3P();
    B8n();
  };
  j8R.addInternalStylesheet = function (q4b, n2l, v5E) {
    var D2h, k$H, C3E;
    D2h = 'st';
    D2h += 'yle[pa';
    D2h += 'th="';
    k$H = 'st';
    k$H += 'ri';
    k$H += 'n';
    k$H += 'g';
    if (!q4b) {
      return;
    }
    if (q4b.default) {
      q4b = q4b.default;
    }
    if (typeof q4b !== k$H) {
      return;
    }
    if (n2l && (v5E || document).querySelector(D2h + n2l + '"]')) {
      return;
    }
    C3E = document.createElement('style');
    C3E.setAttribute('type', 'text/css');
    C3E.setAttribute('path', n2l || '');
    C3E.innerText = q4b;
    (v5E || document).head.appendChild(C3E);
  };
};
P = (x_F) => {
  var d1D;
  d1D = x_F.CIQ;
  d1D.ChartEngine.prototype.registerHTMLElements = function () {
    var d4S = v7ZLk;
    var A8n, d4U, M2x, q6c, E0G, s57, k1y, s3K, i9_;
    A8n = this.chart.container;
    for (var O6_ in d1D.ChartEngine.htmlControls) {
      d4U = 'undefi';
      d4U += 'n';
      d4U += 'e';
      d4U += 'd';
      M2x = 'un';
      M2x += 'defin';
      M2x += 'ed';
      if (typeof this.chart[O6_] == M2x && typeof this.controls[O6_] == d4U) {
        if (!this.allowZoom && O6_ == 'chartControls') continue;
        d4S.p1T(18);
        q6c = A8n.querySelector(d4S.H9P(O6_, '.'));
        if (q6c) {
          this.chart[O6_] = q6c;
          this.controls[O6_] = q6c;
        } else {
          E0G = 'D';
          E0G += 'I';
          E0G += 'V';
          s57 = d1D.ChartEngine.htmlControls[O6_];
          if (!s57) continue;
          k1y = document.createElement(E0G);
          k1y.innerHTML = s57.replace(/[\t\n\r]/gm, '');
          q6c = k1y.firstChild;
          A8n.appendChild(q6c);
          this.chart[O6_] = q6c;
          this.controls[O6_] = q6c;
          q6c.classList.add(O6_);
        }
      }
    }
    var { chartControls: G_j, home: V5y } = this.controls;
    if (G_j) {
      s3K = G_j.querySelector('.stx-zoom-in');
      i9_ = G_j.querySelector('.stx-zoom-out');
      d1D.safeClickTouch(
        s3K,
        (function (J_l) {
          return function (Y5_) {
            if (
              !J_l.allowZoom ||
              (J_l.activeDrawing && !J_l.allowDrawingZoom)
            ) {
              Y5_.stopPropagation();
              return;
            }
            J_l.zoomIn(Y5_);
            Y5_.stopPropagation();
          };
        })(this)
      );
      d1D.safeClickTouch(
        i9_,
        (function (i4X) {
          return function (q31) {
            d4S.t3P();
            if (
              !i4X.allowZoom ||
              (i4X.activeDrawing && !i4X.allowDrawingZoom)
            ) {
              q31.stopPropagation();
              return;
            }
            i4X.zoomOut(q31);
            q31.stopPropagation();
          };
        })(this)
      );
      if (!d1D.touchDevice) {
        this.makeModal(s3K);
        this.makeModal(i9_);
      }
    }
    if (V5y) {
      d1D.safeClickTouch(
        V5y,
        (function (d7w) {
          d4S.t3P();
          return function (C2H) {
            C2H.stopPropagation();
            if (!d7w.isHistoricalMode()) {
              d7w.home({ animate: !0 });
              return;
            }
            d4S.Y3Y();
            delete d7w.layout.range;
            d7w.loadChart(d7w.chart.symbol, function () {
              d7w.home({ animate: !!0 });
            });
          };
        })(this)
      );
      if (!d1D.touchDevice) {
        this.makeModal(V5y);
      }
    }
    if (this.allowDragControls) {
      this.allowDragControls();
    }
  };
  d1D.ChartEngine.prototype.home = function (V3L) {
    var a3Y, g2I, k_E, N8Y, t2p, M89, n0X;
    this.swipe.amplitude = 0;
    a3Y = this.layout;
    if (typeof V3L != 'object') {
      V3L = { maintainWhitespace: V3L };
    }
    if (typeof V3L.maintainWhitespace == 'undefined') {
      V3L.maintainWhitespace = !!{};
    }
    function y7Q(L1e) {
      var c7C;
      v7ZLk.t3P();
      for (var l8X in L1e.panels) {
        c7C = L1e.panels[l8X].yaxisLHS.concat(L1e.panels[l8X].yaxisRHS);
        for (var J$5 = 0; J$5 < c7C.length; J$5++) {
          if (!c7C[J$5].lockScale) {
            L1e.calculateYAxisMargins(c7C[J$5]);
          }
        }
      }
    }
    this.chart.draggingControls = null;
    function t9r(r3H, B_U, v8q) {
      v7ZLk.Y3Y();
      return function () {
        y7Q(r3H);
        B_U.scroll = v8q;
        r3H.draw();
      };
    }
    this.cancelTouchSingleClick = !!1;
    if (!this.chart.dataSet || !this.chart.dataSet.length) {
      this.draw();
      return;
    }
    this.micropixels = 0;
    g2I = Math.floor(this.chart.width / a3Y.candleWidth);
    for (var Q_9 in this.charts) {
      k_E = this.charts[Q_9];
      if (V3L.chart && V3L.chart != k_E) continue;
      N8Y = 0;
      if (V3L.maintainWhitespace && this.preferences.whitespace >= 0) {
        N8Y = this.preferences.whitespace;
      }
      if (V3L.whitespace || V3L.whitespace === +'0') {
        N8Y = V3L.whitespace;
      }
      t2p = this.getLabelOffsetInPixels(k_E, a3Y.chartType);
      if (t2p > N8Y) {
        N8Y = t2p;
      }
      M89 = Math.min(g2I, k_E.dataSet.length);
      if (this.chart.allowScrollPast) {
        M89 = g2I;
      }
      this.micropixels = this.chart.width - M89 * a3Y.candleWidth - N8Y;
      this.preferences.whitespace = N8Y;
      while (this.micropixels > a3Y.candleWidth) {
        M89++;
        this.micropixels -= a3Y.candleWidth;
      }
      while (this.micropixels < 0) {
        M89--;
        this.micropixels += a3Y.candleWidth;
      }
      this.micropixels -= a3Y.candleWidth;
      M89++;
      if (!this.mainSeriesRenderer || !this.mainSeriesRenderer.standaloneBars) {
        v7ZLk.d$R(108);
        var M8D = v7ZLk.M3e(38, 11, 14, 6, 9);
        this.micropixels += a3Y.candleWidth / M8D;
      }
      if (V3L.animate) {
        n0X = this;
        this.scrollTo(k_E, M89, t9r(n0X, k_E, M89));
      } else {
        k_E.scroll = M89;
        y7Q(this);
      }
    }
    this.draw();
  };
  d1D.ChartEngine.prototype.headsUpHR = function () {
    var e9k, E1u, p4o, w_T, o6w, V5t;
    e9k = 'heads';
    e9k += 'UpHR';
    if (this.runPrepend(e9k, arguments)) {
      return;
    }
    E1u = this.currentPanel;
    if (!E1u) {
      return;
    }
    p4o = E1u.chart;
    function n0F(t2I) {
      var Z7s, E4S, Z68;
      Z7s = 34964668;
      E4S = -870707379;
      Z68 = 2;
      for (
        var Q2p = 1;
        v7ZLk.e_M(Q2p.toString(), Q2p.toString().length, 53788) !== Z7s;
        Q2p++
      ) {
        d1D.efficientDOMUpdate(w_T, '', t2I);
        Z68 += 2;
      }
      if (v7ZLk.e_M(Z68.toString(), Z68.toString().length, 907) !== E4S) {
        d1D.efficientDOMUpdate(w_T, '', t2I);
      }
      v7ZLk.Y3Y();
      d1D.efficientDOMUpdate(w_T, 'innerHTML', t2I);
    }
    this.updateFloatHRLabel(E1u);
    v7ZLk.Y3Y();
    w_T = this.controls.floatDate;
    if (w_T && !p4o.xAxis.noDraw) {
      o6w = this.barFromPixel(this.cx);
      V5t = p4o.xaxis[o6w];
      if (V5t && V5t.DT) {
        n0F(d1D.displayableDate(this, p4o, V5t.DT));
      } else if (V5t && V5t.index) {
        n0F(V5t.index);
      } else {
        n0F('');
      }
    }
    this.runAppend('headsUpHR', arguments);
  };
  d1D.ChartEngine.prototype.modalBegin = function () {
    this.openDialog = 'modal';
    this.undisplayCrosshairs();
  };
  d1D.ChartEngine.prototype.modalEnd = function () {
    this.cancelTouchSingleClick = !!{};
    v7ZLk.t3P();
    this.openDialog = '';
    this.doDisplayCrosshairs();
  };
  d1D.ChartEngine.prototype.makeModal = function (n8T) {
    var d40;
    d40 = this;
    v7ZLk.Y3Y();
    n8T.onmouseover = function (b0L) {
      v7ZLk.t3P();
      d40.modalBegin();
    };
    n8T.onmouseout = function (T2O) {
      v7ZLk.Y3Y();
      d40.modalEnd();
    };
  };
  d1D.ChartEngine.prototype.updateChartAccessories = function () {
    var s9d = v7ZLk;
    var m2x, k8x, t$V, j2D, z0V, i4q, x5H, O5g, b33;
    m2x = 'updateChartAcce';
    m2x += 'ssories';
    if (this.accessoryTimer !== null) {
      clearTimeout(this.accessoryTimer);
    }
    if (!d1D.ChartEngine.drawingLine && d1D.touchDevice) {
      if (new Date().getTime() - this.lastAccessoryUpdate < 100) {
        this.accessoryTimer = setTimeout(
          (function (B8E) {
            s9d.Y3Y();
            return function () {
              s9d.t3P();
              B8E.updateChartAccessories();
            };
          })(this),
          10
        );
        return;
      }
    }
    if (!this.chart.dataSet) {
      return;
    }
    if (this.runPrepend(m2x, arguments)) {
      return;
    }
    this.accessoryTimer = null;
    this.lastAccessoryUpdate = new Date().getTime();
    k8x = this.controls.floatDate;
    if (k8x) {
      t$V = this.currentPanel;
      if (!t$V) {
        t$V = this.chart.panel;
      }
      if (t$V) {
        j2D = 'p';
        j2D += 'x';
        z0V = 'bott';
        z0V += 'o';
        z0V += 'm';
        i4q = t$V.chart;
        x5H =
          this.xAxisAsFooter === !![]
            ? 0
            : this.chart.canvasHeight - t$V.chart.bottom;
        s9d.p1T(18);
        var U7e = s9d.H9P(1, 1);
        O5g = k8x.offsetWidth / U7e - 0.5;
        b33 = this.pixelFromTick(this.crosshairTick, i4q) - O5g;
        if (b33 < 0) {
          b33 = 0;
        } else if (b33 > this.width - 2 * O5g - 1) {
          s9d.d$R(18);
          var p74 = s9d.M3e(2, 0);
          s9d.d$R(61);
          var I08 = s9d.M3e(15, 5, 19);
          b33 = this.width - p74 * O5g - I08;
        }
        d1D.efficientDOMUpdate(k8x.style, 'left', b33 + 'px');
        d1D.efficientDOMUpdate(k8x.style, z0V, x5H + j2D);
      }
    }
    this.positionCrosshairsAtPointer();
    this.headsUpHR();
    this.runAppend('updateChartAccessories', arguments);
  };
  d1D.ChartEngine.prototype.positionSticky = function (a0E) {
    var j1f = v7ZLk;
    var S6r, W4g, W7e, C0D, a0i;
    S6r = 'p';
    j1f.Y3Y();
    S6r += 'x';
    W4g = 't';
    W4g += 'o';
    W4g += 'p';
    W7e = Math.max(this.cy - a0E.offsetHeight - 60, 0);
    C0D = Math.min(
      this.chart.canvasWidth - (this.cx - 50),
      this.chart.canvasWidth - a0E.offsetWidth
    );
    a0i = a0E.style;
    j1f.d$R(18);
    d1D.efficientDOMUpdate(a0i, W4g, j1f.H9P('px', W7e));
    d1D.efficientDOMUpdate(a0i, 'left', 'auto');
    j1f.d$R(18);
    d1D.efficientDOMUpdate(a0i, 'right', j1f.H9P(S6r, C0D));
  };
  d1D.ChartEngine.prototype.displaySticky = function (Z1H) {
    var W_h,
      k8T,
      y_z,
      s8V,
      b32,
      F7q,
      S5Y,
      h0r,
      v_y,
      h_R,
      f59,
      z2R,
      W$5,
      P3c,
      J10,
      s4I,
      a2M,
      h6P,
      T88,
      l$s,
      P5R,
      z1O,
      D9c,
      z3u,
      k8q,
      G1x;
    W_h = 'no_edi';
    W_h += 't';
    k8T = '.o';
    k8T += 've';
    k8T += 'rlayTe';
    k8T += 'xt';
    y_z = '.o';
    y_z += 'verlayTr';
    y_z += 'a';
    y_z += 'shCan';
    s8V = this.controls.mSticky;
    if (!s8V) {
      return;
    }
    b32 = s8V.querySelector('.mStickyInterior');
    if (!b32) {
      return;
    }
    F7q = s8V.querySelector(y_z);
    S5Y = s8V.querySelector('.overlayEdit');
    h0r = s8V.querySelector(k8T);
    v_y = s8V.querySelector('.mouseDeleteInstructions');
    h_R = s8V.querySelector('.stickyLongPressText');
    f59 = s8V.querySelector('.customInstructions');
    v7ZLk.Y3Y();
    v_y.classList.remove(W_h);
    if (!Z1H || typeof Z1H != 'object') {
      Z1H = {
        message: arguments[0],
        backgroundColor: arguments['1' * 1],
        forceShow: arguments[2],
        noDelete: arguments[3],
        type: arguments[4],
      };
    }
    var {
      forceShow: B1u,
      noDelete: Z5w,
      noEdit: j2Y,
      type: m6s,
      noText: c6N,
    } = Z1H;
    var { message: F$u, backgroundColor: Q42 } = Z1H;
    if (!B1u && !F$u) {
      z2R = 'n';
      z2R += 'o';
      z2R += 'ne';
      W$5 = 'no';
      W$5 += 'n';
      W$5 += 'e';
      b32.innerHTML = '';
      s8V.style.display = 'none';
      if (F7q) {
        F7q.style.display = 'none';
      }
      if (S5Y) {
        S5Y.style.display = 'none';
      }
      if (h0r) {
        h0r.style.display = 'none';
      }
      if (v_y) {
        v_y.style.display = W$5;
      }
      if (h_R) {
        h_R.style.display = z2R;
      }
    } else {
      P3c = 'cq-stick';
      P3c += 'y-type';
      J10 = 'a';
      J10 += 'u';
      J10 += 't';
      J10 += 'o';
      if (!F$u) {
        F$u = '';
      }
      var { defaultColor: G1y, highlightedDraggable: M1n } = this;
      if (Q42 == J10) {
        Q42 = G1y;
      }
      if (B1u && !F$u) {
        s4I = 'n';
        s4I += 'on';
        s4I += 'e';
        b32.style.backgroundColor = '';
        b32.style.color = '';
        b32.style.display = s4I;
      } else if (Q42) {
        b32.style.backgroundColor = Q42;
        b32.style.color = d1D.isTransparent(Q42)
          ? G1y
          : d1D.chooseForegroundColor(Q42);
        b32.style.display = 'inline-block';
      } else {
        b32.style.backgroundColor = '';
        b32.style.color = '';
        b32.style.display = 'inline-block';
      }
      b32.innerHTML = F$u;
      a2M = s8V.querySelector('.mStickyRightClick');
      a2M.className = 'mStickyRightClick';
      if (m6s) {
        v7ZLk.d$R(18);
        a2M.classList.add(v7ZLk.M3e(m6s, 'rightclick_'));
      }
      a2M.style.display = '';
      s8V.style.display = 'inline-block';
      if (!M1n || (M1n && M1n.undraggable && M1n.undraggable(this))) {
        h_R.style.display = 'none';
      }
      if (Z5w || this.bypassRightClick === !!{} || this.bypassRightClick[m6s]) {
        a2M.style.display = 'none';
      } else if (this.highlightViaTap || this.touches.length) {
        h6P = 'non';
        h6P += 'e';
        T88 = 'in';
        T88 += 'line';
        T88 += '-block';
        if (F7q) {
          F7q.style.display = 'inline-block';
        }
        if (S5Y && !j2Y) {
          S5Y.style.display = 'inline-block';
        }
        if (h0r && !c6N) {
          h0r.style.display = T88;
        }
        if (v_y) {
          v_y.style.display = h6P;
        }
        if (h_R) {
          h_R.style.display = 'none';
        }
        s8V.classList[F$u === '' ? 'add' : 'remove']('hide');
      } else {
        if (j2Y) {
          v_y.classList.add('no_edit');
        }
        if (v_y) {
          v_y.style.display = 'block';
        }
        if (h_R) {
          l$s = 'non';
          l$s += 'e';
          h_R.style.display = l$s;
          P5R = this.preferences.dragging;
          if (P5R && Z1H.panel && !Z1H.panel.noDrag) {
            z1O = 'se';
            z1O += 'r';
            z1O += 'ie';
            z1O += 's';
            if ((P5R === !!{} || P5R.study) && m6s == 'study') {
              h_R.style.display = 'block';
            } else if ((P5R === !![] || P5R.series) && m6s == z1O) {
              h_R.style.display = 'block';
            }
          }
        }
      }
      if (f59 && m6s) {
        D9c = 'bl';
        D9c += 'o';
        D9c += 'c';
        D9c += 'k';
        z3u = f59.querySelector(`[type=${m6s}]`);
        f59.style.display = z3u ? D9c : 'none';
      }
      k8q = m6s || 'default';
      s8V.setAttribute(P3c, k8q);
      G1x = Z1H.positioner || this.positionSticky;
      G1x.call(this, s8V);
    }
  };
  d1D.ChartEngine.prototype.displayNotification = function (
    q3j,
    K2Y,
    i9r = {}
  ) {
    var l1g, h$m, w8d, p4$, r$Y, G1C, H4w, f69, u39, q2f;
    l1g = 't';
    l1g += 'emp';
    l1g += 'l';
    l1g += 'ate';
    if (!this.controls.notificationTray) {
      return;
    }
    var { callback: Z3X, dismissalListeners: j7M } = i9r;
    h$m = this.controls.notificationTray;
    w8d = h$m.querySelector(l1g).content.cloneNode(!!'1');
    p4$ = w8d.firstElementChild;
    p4$.className = q3j;
    p4$.querySelector('.message').textContent = K2Y;
    if (Z3X) {
      r$Y = -1061935882;
      G1C = 1478504991;
      H4w = 2;
      for (
        var D$v = 1;
        v7ZLk.e_M(D$v.toString(), D$v.toString().length, 69490) !== r$Y;
        D$v++
      ) {
        f69 = 'to';
        f69 += 'uch';
        f69 += 'end';
        u39 = window.PointerEvent ? 'pointerup' : f69;
        H4w += +'2';
      }
      if (v7ZLk.q78(H4w.toString(), H4w.toString().length, 74122) !== G1C) {
        q2f = 't';
        q2f += 'ouche';
        q2f += 'nd';
        u39 = window.PointerEvent ? 'pointerup' : q2f;
      }
      p4$.handler = p4$.addEventListener(u39, Z3X);
    }
    if (j7M) {
      p4$.listeners = {};
      j7M.forEach((F9q) => {
        v7ZLk.t3P();
        p4$.listeners[q3j] = this.addEventListener(F9q.type, F9q.callback);
      });
    }
    this.makeModal(p4$);
    h$m.appendChild(p4$);
  };
  d1D.ChartEngine.prototype.removeNotification = function (U5I) {
    var e6i, o0a;
    if (!this.controls.notificationTray) {
      return;
    }
    e6i = this.controls.notificationTray;
    o0a = e6i.querySelector(`.${U5I}`);
    v7ZLk.Y3Y();
    if (o0a) {
      if (o0a.handler) {
        o0a.removeEventListener(o0a.handler);
      }
      if (o0a.listeners) {
        for (var I64 in o0a.listeners) {
          this.removeEventListener(o0a.listeners[I64]);
        }
      }
      this.modalEnd();
      e6i.removeChild(o0a);
    }
  };
  d1D.ChartEngine.prototype.setMeasure = function (
    X3m,
    T9Q,
    m_0,
    B3_,
    b6b,
    K4S
  ) {
    var R90 = v7ZLk;
    var R$8,
      n7N,
      n7S,
      c98,
      f37,
      a7h,
      I_f,
      a6u,
      q9t,
      w1R,
      Z5S,
      u_3,
      f6Q,
      p_$,
      y1J;
    R$8 = '.m';
    R$8 += 'Measure';
    n7N = 's';
    n7N += 'etMea';
    n7N += 'su';
    n7N += 're';
    if (this.runPrepend(n7N, arguments)) {
      return;
    }
    n7S = (this.container || document).querySelector(R$8);
    c98 = '';
    if (!X3m && X3m !== 0) {
      if (
        !this.anyHighlighted &&
        this.currentVectorParameters.vectorType === ''
      ) {
        this.clearMeasure();
      }
    } else {
      if (T9Q !== ![]) {
        f37 =
          Math.round(Math.abs(X3m - T9Q) * this.chart.roundit) /
          this.chart.roundit;
        a7h = 636526245;
        I_f = -1838931833;
        a6u = 2;
        for (
          var K$L = 1;
          R90.q78(K$L.toString(), K$L.toString().length, '65593' ^ 0) !== a7h;
          K$L++
        ) {
          f37 = f37.toFixed(this.chart.yAxis.printDecimalPlaces);
          a6u += 2;
        }
        if (R90.e_M(a6u.toString(), a6u.toString().length, 41506) !== I_f) {
          f37 = f37.toFixed(this.chart.yAxis.printDecimalPlaces);
        }
        if (this.internationalizer) {
          c98 += this.internationalizer.numbers.format(f37);
        } else {
          c98 += f37;
        }
        if (X3m > 0 && T9Q > 0) {
          R90.p1T(48);
          q9t = R90.M3e(X3m, T9Q, X3m);
          if (Math.abs(q9t) > '0.1' - 0) {
            R90.d$R(25);
            q9t = Math.round(R90.M3e(100, q9t));
          } else if (Math.abs(q9t) > '0.01' - 0) {
            R90.p1T(109);
            var u23 = R90.M3e(983, 20, 392380, 9, 20);
            R90.d$R(19);
            var q82 = R90.H9P(7, 15, 15, 33);
            q9t = Math.round(q9t * u23) / q82;
          } else {
            R90.p1T(110);
            var S9U = R90.M3e(120, 35, 1, 15);
            q9t = Math.round(q9t * +'10000') / S9U;
          }
          if (this.internationalizer) {
            R90.p1T(111);
            q9t = this.internationalizer.percent.format(R90.M3e('100', 1, q9t));
          } else {
            R90.p1T(18);
            q9t = R90.M3e('%', q9t);
          }
          R90.d$R(28);
          c98 += R90.H9P(
            q9t,
            ' (',
            (+'8837', 1790) != (+'6120', 8340)
              ? 879 === 1900
                ? 'C'
                : ('3250' | 2, 8392) >= 5089
                ? ')'
                : (478.92, 7.17e3)
              : 0x10b3
          );
        }
      }
      if (B3_ !== !{}) {
        R90.p1T(0);
        w1R = Math.abs(R90.M3e(B3_, m_0));
        R90.d$R(0);
        var e53 = R90.M3e(18, 17);
        w1R = Math.round(w1R) + e53;
        Z5S = this.translateIf('Bars');
        R90.p1T(112);
        c98 += R90.H9P(
          ' ',
          1251 != (856, 8730) ? ' ' : (0x22b4, !'1'),
          w1R,
          Z5S
        );
      }
      if (n7S) {
        n7S.innerHTML = c98;
      }
    }
    R90.t3P();
    if (this.activeDrawing) {
      return;
    }
    n7S = this.controls.mSticky;
    if (n7S) {
      u_3 = n7S.querySelector('.mStickyInterior');
      if (b6b) {
        f6Q = 'in';
        f6Q += 'l';
        f6Q += 'i';
        f6Q += 'ne-block';
        n7S.style.display = f6Q;
        if (u_3) {
          p_$ = 'in';
          p_$ += 'line';
          p_$ += '-';
          p_$ += 'block';
          u_3.style.display = p_$;
          if (X3m || X3m === +'0') {
            u_3.innerHTML = c98;
          }
        }
        n7S.classList[c98 === '' ? 'add' : 'remove']('hide');
        this.positionSticky(n7S);
      } else {
        y1J = 'no';
        y1J += 'ne';
        n7S.style.display = y1J;
        if (u_3) {
          u_3.innerHTML = '';
        }
      }
    }
    this.runAppend('setMeasure', arguments);
  };
  d1D.ChartEngine.prototype.clearMeasure = function () {
    v7ZLk.t3P();
    var P$K;
    P$K = (this.container || document).querySelector('.mMeasure');
    if (P$K) {
      P$K.innerHTML = '';
    }
  };
  d1D.ChartEngine.prototype.zoomSet = function (V3A, K0p) {
    var a$N = v7ZLk;
    var P91, f3P, J5X, l_e, x4R;
    if (!K0p) {
      K0p = this.chart;
    }
    if (!K0p.dataSet) {
      return;
    }
    a$N.Y3Y();
    V3A = this.constrainCandleWidth(V3A);
    if (K0p.tempCanvas && K0p.tempCanvas.style.display != 'none') {
      d1D.clearCanvas(K0p.tempCanvas, this);
    }
    P91 = this.mainSeriesRenderer || {};
    if (!P91.params || !P91.params.volume) {
      f3P = 'z';
      f3P += 'oom-x';
      if (
        this.preferences.zoomAtCurrentMousePosition &&
        this.zoomInitiatedByMouseWheel &&
        this.crosshairTick < K0p.dataSet.length
      ) {
        J5X = this.crosshairTick || this.tickFromPixel(this.cx, K0p);
      } else if (this.isHome()) {
        J5X = K0p.dataSet.length - +'1';
      } else if (K0p.scroll > K0p.dataSet.length) {
        J5X = +'0';
      } else if (this.grabMode == f3P) {
        J5X = this.tickFromPixel(this.chart.width, K0p);
      } else {
        J5X = this.tickFromPixel(this.chart.width / +'2', K0p);
      }
      if (this.animations.zoom.hasCompleted) {
        this.zoomInitiatedByMouseWheel = !!'';
      }
      a$N.p1T(86);
      var Q8V = a$N.M3e(13, 11, 2, 43, 11);
      l_e = K0p.dataSet.length - Q8V - J5X;
      x4R = K0p.scroll;
      a$N.d$R(0);
      var R06 = a$N.H9P(8, 7);
      K0p.scroll =
        Math.round((this.pixelFromTick(J5X, K0p) - K0p.left) / V3A) + R06 + l_e;
      this.micropixels +=
        (x4R - l_e) * this.layout.candleWidth - (K0p.scroll - l_e) * V3A;
    }
    this.setCandleWidth(V3A);
    K0p.spanLock = !{};
    this.draw();
    this.doDisplayCrosshairs();
    this.updateChartAccessories();
  };
};
Q = (F$U) => {
  var v$8;
  v$8 = F$U.CIQ;
  v$8.ChartEngine.prototype.currentBaseline = null;
  v$8.ChartEngine.prototype.baselineHelper = null;
  v$8.ChartEngine.helpersToRegister.push(function (Y3y) {
    Y3y.baselineHelper = new Map();
  });
  v$8.ChartEngine.prototype.registerBaselineToHelper = function (p4O) {
    var B_y, J0W;
    function g6l(m1u) {
      var F$m, a2O;
      F$m = 'cq-baseli';
      F$m += 'ne';
      F$m += '-handle';
      m1u = m1u.replace(
        ' ',
        (3947, 363.99) == ('576.37' - 0, 5.55)
          ? ('8.50e+3' << 64, 'O')
          : 554 <= (324, 257.29)
          ? +'0x112c'
          : 8387 != 5590
          ? '_'
          : ('P', ![])
      );
      a2O = document.createElement(F$m);
      a2O.classList.add('stx-baseline-handle', m1u);
      B_y.container.append(a2O);
      v7ZLk.p1T(18);
      B_y.controls[`${m1u}${v7ZLk.M3e('aseline-handle', ' cq-b')}`] = a2O;
      return a2O;
    }
    if (!p4O.params.baseline) {
      return;
    }
    var { baselineHelper: J2S } = this;
    B_y = this;
    if (!J2S.has(p4O)) {
      var { name: r2A } = p4O.params;
      J0W = this.controls.baselineHandle;
      J2S.set(p4O, {
        styleCache: null,
        display: ![],
        handle: r2A === '_main_series' && J0W ? J0W : g6l(r2A),
      });
    }
  };
  v$8.ChartEngine.prototype.removeBaselineFromHelper = function (i0V) {
    var p$R, s4U, w7N, T22, v65;
    var { baselineHelper: i4R } = this;
    v7ZLk.Y3Y();
    if (i4R.has(i0V)) {
      p$R = i0V.params.name.replace(' ', '_');
      if (p$R !== '_main_series') {
        s4U = i4R.get(i0V).handle;
        delete this.controls[`${p$R} cq-baseline-handle`];
        this.container.removeChild(s4U);
      }
      w7N = -1013338216;
      T22 = -+'2068831613';
      v65 = 2;
      for (
        var B0L = 1;
        v7ZLk.e_M(B0L.toString(), B0L.toString().length, '61056' * 1) !== w7N;
        B0L++
      ) {
        i4R.delete(i0V);
        v65 += 2;
      }
      if (v7ZLk.e_M(v65.toString(), v65.toString().length, 4072) !== T22) {
        i4R.delete(i0V);
      }
      i4R.delete(i0V);
    }
  };
  v$8.ChartEngine.prototype.findBaselineHandle = function (L4v, D4D) {
    v7ZLk.t3P();
    for (var W4K of this.baselineHelper) {
      var [T$F, O1N] = W4K;
      var { handle: p9y } = O1N;
      if (
        L4v.target === p9y ||
        (L4v.composedPath && L4v.composedPath().includes(p9y))
      ) {
        if (D4D) {
          this.repositioningBaseline = {
            lastDraw: Date.now(),
            handle: p9y,
            renderer: T$F,
          };
          p9y.classList.add('stx-grab');
        }
        this.currentBaseline = T$F;
        return !![];
      }
    }
    return !!0;
  };
  v$8.ChartEngine.prototype.setBaselines = function (s6C) {
    var d1x;
    if (!s6C) {
      s6C = this.chart;
    }
    d1x = this;
    var { baselineHelper: s_z } = this;
    s_z.forEach(function (N1i, o9e) {
      var n8K, U__, R3C, T0H, O_U;
      var { baseline: e4u } = o9e.params;
      v7ZLk.d$R(82);
      n8K = v7ZLk.M3e(!![], e4u);
      if (n8K) {
        e4u = s6C.baseline;
      }
      var { defaultLevel: j1A, userLevel: y9z } = e4u;
      U__ = o9e.getYAxis(d1x);
      if (!U__) {
        return;
      }
      R3C = d1x.getYAxisBaselineRenderer(U__);
      T0H =
        R3C &&
        R3C != d1x.mainSeriesRenderer &&
        R3C.seriesParams.length &&
        R3C.seriesParams[0].id;
      e4u.actualLevel = y9z || y9z === 0 ? y9z : j1A;
      if (!e4u.actualLevel && e4u.actualLevel !== 0) {
        O_U = k5E(T0H, n8K);
        if (O_U !== null) {
          e4u.actualLevel = O_U;
        }
      }
      N1i.display = R3C === o9e ? !![] : !'1';
      s_z.set(o9e, N1i);
    });
    function k5E(e75, b0j) {
      var d8_, U9l, F5_, n25, N6V, o90;
      var { dataSegment: D8e, dataSet: E6R, defaultPlotField: m1U } = s6C;
      d8_ = m1U;
      if (!b0j) {
        d8_ = e75;
      }
      v7ZLk.Y3Y();
      U9l = d1x.getFirstLastDataRecord(D8e, 'tick');
      if (!U9l) {
        return null;
      }
      F5_ = U9l.tick;
      while (!!{}) {
        n25 = E6R[F5_];
        if (n25) {
          if (!b0j || d8_ != 'Close') {
            v7ZLk.d$R(0);
            N6V = E6R[v7ZLk.H9P(F5_, 1)];
            if (N6V && (N6V[d8_] || N6V[d8_] === +'0')) {
              o90 = N6V[d8_];
              return typeof o90 === 'object' ? o90[m1U] : o90;
            }
          } else if (n25.iqPrevClose || n25.iqPrevClose === 0) {
            return n25.iqPrevClose;
          }
        }
        F5_--;
        if (F5_ < 0) break;
      }
    }
  };
  v$8.ChartEngine.prototype.setBaselineUserLevel = function () {
    var W4x, p$A, b8y, Y2y;
    W4x = 'obje';
    W4x += 'c';
    W4x += 't';
    var { chart: a4n, currentPanel: I$G } = this;
    var { lastDraw: Z36, renderer: q2e } = this.repositioningBaseline;
    if (q2e.params.panel != I$G.name) {
      return;
    }
    var { baseline: k1G } = a4n;
    p$A = typeof q2e.params.baseline === W4x ? q2e.params.baseline : k1G;
    b8y = q2e.getYAxis(this);
    Y2y = this.valueFromPixel(
      this.backOutY(v$8.ChartEngine.crosshairY),
      I$G,
      b8y
    );
    p$A.userLevel = this.adjustIfNecessary(I$G, this.crosshairTick, Y2y);
    if (Date.now() - Z36 > 100) {
      this.draw();
      this.repositioningBaseline.lastDraw = Date.now();
    }
  };
  v$8.ChartEngine.prototype.setBaselineMinMax = function (h2Q, P3C) {
    var u1O, A73, G5G;
    var { baselineHelper: m78, chart: Q3c, repositioningBaseline: X5j } = this;
    var { baseline: Q2t, seriesRenderers: a2m } = Q3c;
    u1O = Q3c.transformFunc && P3C === Q3c.panel.yAxis;
    A73 = P3C.renderers.find((u4i) => {
      return m78.get(a2m[u4i]);
    });
    if (!A73) {
      return h2Q;
    }
    var { baseline: M2d, type: c4t } = a2m[A73].params;
    if (c4t === 'mountain') {
      return h2Q;
    }
    M2d = typeof M2d === 'object' ? M2d : Q2t;
    var { actualLevel: H5L } = M2d;
    if (H5L || H5L === 0) {
      if (u1O) {
        H5L = Q3c.transformFunc(this, Q3c, H5L);
      }
      G5G = Math.max(H5L - h2Q[0], h2Q[1] - H5L);
      h2Q[0] = X5j ? P3C.lowValue : H5L - G5G;
      v7ZLk.p1T(0);
      h2Q[v7ZLk.H9P('1', 0)] = X5j ? P3C.highValue : H5L + G5G;
    }
    return h2Q;
  };
  v$8.ChartEngine.prototype.positionBaselineHandle = function (d9c) {
    var D0M = v7ZLk;
    var X$I,
      M_F,
      W1C,
      h5X,
      l9M,
      D58,
      u4k,
      h7m,
      u2W,
      T8w,
      U3V,
      T_Q,
      q64,
      z1J,
      G08,
      G72;
    if (!this.manageTouchAndMouse) {
      return;
    }
    var { baselineHelper: S2J, chart: o97, panels: a1m } = this;
    var { baseline: c$m, panel: i9O } = d9c.params;
    X$I = d9c.params.yAxis || d9c.getYAxis(this);
    var { display: P1Z, handle: U02, styleCache: c9T } = S2J.get(d9c);
    if (c$m === !![]) {
      c$m = o97.baseline;
    }
    if (c$m.userLevel === !!0 || !P1Z) {
      U02.style.display = 'none';
      return;
    }
    M_F = a1m[i9O];
    W1C = U02.classList.contains('stx-grab');
    h5X = 'block';
    l9M = c$m.actualLevel;
    if (X$I === o97.yAxis && o97.transformFunc) {
      l9M = o97.transformFunc(this, o97, l9M);
    }
    if (l9M > X$I.high) {
      D58 = 'no';
      D58 += 'ne';
      l9M = X$I.high;
      if (!W1C) {
        h5X = D58;
      }
    } else if (l9M < X$I.low) {
      u4k = 'n';
      u4k += 'o';
      u4k += 'n';
      u4k += 'e';
      l9M = X$I.low;
      if (!W1C) {
        h5X = u4k;
      }
    }
    if (X$I === o97.yAxis && o97.untransformFunc) {
      l9M = o97.untransformFunc(this, o97, l9M);
    }
    h7m = this.pixelFromPrice(l9M, M_F, X$I);
    if (!c9T) {
      c9T = getComputedStyle(U02);
    }
    u2W = v$8.stripPX(c9T.width);
    D0M.p1T(107);
    var Z7b = D0M.M3e(6, 11, 4, 11);
    T8w = `${h7m - v$8.stripPX(c9T.height) / Z7b}px`;
    T_Q = this.baselineHandleBuffer || 2;
    D0M.d$R(8);
    var m_v = D0M.H9P(17, 0, 16);
    q64 = M_F.yaxisRHS.indexOf(X$I) + m_v;
    if (q64) {
      z1J = q64 === 1 ? T_Q : T_Q * q64;
      D0M.d$R(18);
      U3V = `${o97.right - u2W * q64 - z1J}${D0M.M3e('x', 'p')}`;
    } else {
      G08 = M_F.yaxisLHS.slice(0).reverse().indexOf(X$I) + ('1' | 0);
      G72 = G08 === 1 ? T_Q : T_Q * G08;
      U3V = `${o97.left + u2W * G08 + G72 - u2W}px`;
    }
    Object.assign(U02.style, { display: h5X, top: T8w, left: U3V });
  };
  v$8.ChartEngine.prototype.getYAxisBaselineRenderer = function (y3S) {
    var g4Z;
    if (!y3S.renderers.length) {
      return null;
    }
    var { baselineHelper: w3L, chart: l5L } = this;
    g4Z = y3S.renderers.find((M$a) => {
      v7ZLk.Y3Y();
      return w3L.get(l5L.seriesRenderers[M$a]);
    });
    if (!g4Z) {
      return null;
    }
    return l5L.seriesRenderers[g4Z];
  };
  v7ZLk.t3P();
  v$8.ChartEngine.prototype.getYAxisBaseline = function (Y$P) {
    var A4g, Y2Z;
    A4g = 'o';
    A4g += 'b';
    A4g += 'ject';
    var { baseline: I4I } = this.chart;
    Y2Z = this.getYAxisBaselineRenderer(Y$P);
    if (!Y2Z) {
      return I4I;
    }
    var { baseline: a_t } = Y2Z.params;
    return typeof a_t === A4g ? a_t : I4I;
  };
};
F = (G9W) => {
  var P1x;
  P1x = G9W.CIQ;
  P1x.ChartEngine.Chart = function () {
    this.xAxis = new P1x.ChartEngine.XAxis();
    this.yAxis = new P1x.ChartEngine.YAxis();
    this.symbolObject = { symbol: null };
    this.series = {};
    this.seriesRenderers = {};
    this.xaxis = [];
    this.state = {};
    this.endPoints = {};
    this.defaultChartStyleConfig = {};
    this.baseline = P1x.clone(this.baseline);
    this.panel = undefined;
  };
  P1x.extend(
    P1x.ChartEngine.Chart.prototype,
    {
      symbol: null,
      symbolObject: { symbol: null },
      symbolDisplay: null,
      series: {},
      seriesRenderers: {},
      scroll: 0,
      isComparison: !1,
      forcePercentComparison: !0,
      maxTicks: 0,
      tension: null,
      currentMarketData: {},
      masterData: null,
      dataSet: null,
      scrubbed: null,
      dataSegment: null,
      segmentImage: null,
      baseline: {
        includeInDataSegment: !1,
        defaultLevel: null,
        userLevel: null,
        actualLevel: null,
      },
      xAxis: null,
      xaxis: [],
      xaxisFactor: 30,
      decimalPlaces: 2,
      dynamicYAxis: !!'1',
      roundit: 100,
      breakpoint: null,
      legendRenderer: P1x.drawLegend,
      customChart: null,
      yaxisPaddingRight: null,
      yaxisPaddingLeft: null,
      tickCache: {},
      yaxisMarginMultiplier: 2.5,
      allowScrollPast: !'',
      allowScrollFuture: !!1,
      whiteSpaceFutureTicks: 0,
      hideDrawings: !'1',
      defaultPlotField: 'Close',
      defaultChartStyleConfig: {},
      lockScroll: !{},
      includeOverlaysInMinMax: !'',
      gaplines: null,
      lineStyle: null,
      lineApproximation: !'',
      highLowBars: ![],
      standaloneBars: !1,
      barsHaveWidth: !{},
      calculateTradingDecimalPlaces: P1x.calculateTradingDecimalPlaces,
      width: null,
      height: null,
      top: null,
      bottom: null,
      left: null,
      right: null,
      context: null,
      endPoints: null,
    },
    !!'1'
  );
};
Z = (V7C) => {
  var J$n, j9D;
  J$n = 'd';
  J$n += 'at';
  J$n += 'aS';
  J$n += 'et';
  j9D = V7C.CIQ;
  j9D.ChartEngine.prototype.resolveY = function (M1p) {
    var K01, l9e, j7H;
    K01 = 1039627754;
    l9e = 1380246291;
    j7H = 2;
    for (
      var m7$ = 1;
      v7ZLk.e_M(m7$.toString(), m7$.toString().length, 92542) !== K01;
      m7$++
    ) {
      return this.top / M1p;
    }
    if (v7ZLk.e_M(j7H.toString(), j7H.toString().length, 87774) !== l9e) {
      return this.top + M1p;
    }
  };
  j9D.ChartEngine.prototype.resolveX = function (d2l) {
    v7ZLk.Y3Y();
    return this.left + d2l;
  };
  j9D.ChartEngine.prototype.backOutY = function (E5g) {
    return Math.max(0, Math.min(this.height, E5g - this.top));
  };
  j9D.ChartEngine.prototype.backOutX = function (I0A) {
    v7ZLk.Y3Y();
    return Math.max(0, Math.min(this.width, I0A - this.left));
  };
  j9D.ChartEngine.prototype.dateFromTick = function (
    j8X,
    Y0O,
    o4O,
    O65 = 'dataSet'
  ) {
    var n8Z, w9S, v0i, v3z, X8q, z7F;
    if (!Y0O) {
      Y0O = this.chart;
    }
    n8Z = Y0O[O65];
    w9S = n8Z.length;
    z7F = ![];
    if (w9S === 0) {
      n8Z = []
      n8Z[0] = {};
      n8Z[0].DT = new Date();
      w9S = n8Z.length;
      z7F = !'';
    }
    if (j8X < 0) {
      v3z = this.standardMarketIterator(n8Z[0].DT);
      if (v3z) {
        v0i = v3z.previous(Math.abs(j8X));
      } else {
        v0i = n8Z[0].DT;
      }
    } else if (j8X >= w9S) {
      v3z = this.standardMarketIterator(n8Z[w9S - 1].DT);
      if (v3z) {
        v7ZLk.p1T(113);
        v0i = v3z.next(v7ZLk.H9P(w9S, '1', j8X));
      } else {
        v0i = n8Z[w9S - 1].DT;
      }
    } else {
      v0i = n8Z[j8X].DT;
    }
    if (o4O) {
      X8q = new Date(v0i.getTime());
    } else {
      X8q = j9D.yyyymmddhhmmssmmm(v0i).substr(0, 12);
    }
    if (z7F) {
      delete n8Z[0].DT;
    }
    return X8q;
  };
  j9D.ChartEngine.prototype.tickFromDate = function (
    L8O,
    y8a,
    J4i,
    n6c,
    k9h = J$n
  ) {
    var G6S = v7ZLk;
    var N4t,
      Y4v,
      A0_,
      b$e,
      N9$,
      D61,
      t49,
      S8j,
      x45,
      E5l,
      c0k,
      i4Y,
      j$o,
      E9P,
      V6j;
    if (!y8a) {
      y8a = this.chart;
    }
    N4t = y8a[k9h];
    if (!(N4t && N4t.length)) {
      G6S.d$R(25);
      return G6S.M3e(1, '0');
    }
    if (!J4i) {
      G6S.p1T(26);
      J4i = G6S.H9P('0', 0);
    }
    Y4v = L8O.constructor == Date ? L8O : j9D.strToDateTime(L8O);
    if (!j9D.ChartEngine.isDailyInterval(this.layout.interval)) {
      Y4v.setMinutes(Y4v.getMinutes() + J4i);
    }
    A0_ = Y4v.getTime();
    G6S.Y3Y();
    if (!y8a.tickCache[k9h]) {
      y8a.tickCache[k9h] = {};
    }
    b$e = y8a.tickCache[k9h][A0_];
    if (b$e || b$e === 0) {
      return n6c ? Math.ceil(b$e) : Math.floor(b$e);
    }
    N9$ = N4t[0].DT;
    D61 = N4t[N4t.length - 1].DT;
    if (Y4v >= N9$ && Y4v <= D61) {
      t49 = 0;
      S8j = N4t.length;
      x45 = 0;
      while (++x45 < 100) {
        G6S.d$R(49);
        E5l = Math.floor(G6S.H9P(t49, S8j, 2));
        c0k = N4t[E5l].DT;
        if (+c0k == +Y4v) {
          y8a.tickCache[k9h][A0_] = E5l;
          return E5l;
        }
        if (c0k < Y4v) {
          t49 = E5l;
        }
        if (c0k > Y4v) {
          if (N4t[E5l - 1].DT < Y4v) {
            G6S.p1T(0);
            y8a.tickCache[k9h][A0_] = G6S.H9P(E5l, 0.5);
            return n6c ? E5l : E5l - 1;
          }
          if (+N4t[E5l - 1].DT == +Y4v) {
            G6S.p1T(0);
            y8a.tickCache[k9h][A0_] = G6S.M3e(E5l, 1);
            G6S.p1T(114);
            return G6S.M3e(E5l, '1');
          }
          S8j = E5l;
        }
      }
      if (x45 >= 100) {
        console.log('!!!Warning: tickFromDate() did not find match.');
        return N4t.length;
      }
    }
    G6S.p1T(115);
    i4Y = G6S.M3e(N9$, Y4v);
    j$o = i4Y ? N9$ : D61;
    E9P = this.standardMarketIterator(j$o);
    V6j = E9P ? E9P.futureTick({ end: Y4v }) : '0' * 1;
    b$e = i4Y ? V6j * -1 : N4t.length - 1 + V6j;
    y8a.tickCache[k9h][A0_] = b$e;
    return b$e;
  };
  j9D.ChartEngine.prototype.pixelFromBar = function (y$8, j2T) {
    var Q$P, k7M;
    if (!j2T) {
      j2T = this.chart;
    }
    Q$P = 0;
    k7M = this.chart.segmentImage;
    if (k7M && k7M[y$8] && typeof k7M[y$8].leftOffset === 'number') {
      Q$P = k7M[y$8].leftOffset;
    } else {
      Q$P = (y$8 + ('0.5' - 0)) * this.layout.candleWidth;
    }
    Q$P = j2T.panel.left + Math.floor(Q$P + this.micropixels) - +'1';
    return Q$P;
  };
  j9D.ChartEngine.prototype.barFromPixel = function (b9Q, G$E) {
    var f8i = v7ZLk;
    var x2A, X_U, X0S, E9t, G8z, J3M, S8n, D_Y, o2I, f2h, n_1, c5a, R1i;
    if (!G$E) {
      G$E = this.chart;
    }
    x2A = this.chart.segmentImage;
    X_U = this.micropixels;
    X0S = this.layout.candleWidth;
    if (x2A) {
      E9t = b9Q - G$E.panel.left - X_U;
      G8z = 2;
      S8n = x2A.length;
      f8i.d$R(41);
      D_Y = Math.round(f8i.M3e(G8z, S8n));
      f8i.p1T(0);
      var s$I = f8i.H9P(4, 3);
      f8i.d$R(61);
      var s_T = f8i.H9P(14, 0, 13);
      f8i.d$R(34);
      var O4r = f8i.M3e(448, 9, 32, 18);
      n_1 = x2A[S8n - s$I].leftOffset + x2A[S8n - s_T].candleWidth / O4r;
      if (E9t > n_1) {
        return S8n + Math.floor((b9Q - n_1 - G$E.panel.left - X_U) / X0S);
      }
      for (var B2$ = 1; B2$ < S8n; B2$++) {
        G8z *= 2;
        J3M = x2A[D_Y];
        if (!J3M) break;
        o2I = J3M.leftOffset;
        f8i.p1T(40);
        var L1l = f8i.H9P(16, 14, 222);
        f2h = J3M.candleWidth / L1l;
        f8i.p1T(0);
        c5a = f8i.M3e(o2I, f2h);
        f8i.p1T(18);
        R1i = f8i.H9P(f2h, o2I);
        if (D_Y === 0 || (E9t >= c5a && E9t < R1i)) break;
        else if (E9t < c5a) {
          D_Y -= Math.max(1, Math.round(S8n / G8z));
        } else {
          D_Y += Math.max(+'1', Math.round(S8n / G8z));
        }
        D_Y = Math.max(0, Math.min(S8n - 1, D_Y));
      }
      if (!x2A[D_Y]) {
        for (B2$ = +'0'; B2$ < S8n; B2$++) {
          J3M = x2A[B2$];
          if (!J3M) continue;
          o2I = J3M.leftOffset;
          f2h = J3M.candleWidth / +'2';
          if (E9t < o2I - f2h) {
            f8i.d$R(0);
            return Math.max(0, f8i.M3e(B2$, 1));
          } else if (E9t < o2I + f2h) {
            return B2$;
          } else if (E9t >= o2I + f2h) {
            f8i.p1T(18);
            return f8i.H9P(1, B2$);
          }
        }
      }
      return D_Y;
    }
    return Math.floor((b9Q - G$E.panel.left - X_U) / X0S);
  };
  j9D.ChartEngine.prototype.tickFromPixel = function (W2W, j5F) {
    var t7k;
    if (!j5F) {
      j5F = this.chart;
    }
    t7k = j5F.dataSet.length - j5F.scroll;
    if (j5F.segmentImage) {
      t7k += this.barFromPixel(W2W, j5F);
    } else {
      t7k += Math.floor(
        (W2W - j5F.panel.left - this.micropixels) / this.layout.candleWidth
      );
    }
    v7ZLk.t3P();
    return t7k;
  };
  j9D.ChartEngine.prototype.pixelFromTick = function (F2Q, I6D) {
    var S$P = v7ZLk;
    var T1k, u1W, x3G, X9A, E1K, C9l, q2k, U7o, Z3W, o3a, B5y, w2M;
    T1k = 'num';
    T1k += 'ber';
    if (!I6D) {
      I6D = this.chart;
    }
    u1W = I6D.dataSegment;
    x3G = I6D.dataSet;
    X9A = I6D.segmentImage;
    E1K = this.micropixels;
    C9l = u1W ? u1W.length : 0;
    q2k = I6D.panel;
    U7o = I6D.scroll;
    Z3W = F2Q - x3G.length + U7o;
    o3a = C9l ? u1W[Z3W] : null;
    if (X9A) {
      o3a = X9A[Z3W];
    }
    if (o3a && typeof o3a.leftOffset === 'number') {
      return q2k.left + Math.floor(o3a.leftOffset + E1K);
    }
    B5y = 0;
    w2M = 0;
    o3a = C9l ? u1W[C9l - 1] : null;
    if (X9A) {
      S$P.d$R(116);
      o3a = X9A[S$P.M3e(C9l, '1', 1)];
    }
    if (o3a && typeof o3a.leftOffset === T1k) {
      if (C9l <= F2Q - x3G.length + U7o) {
        S$P.p1T(61);
        var G8a = S$P.H9P(10, 12, 20);
        B5y = o3a.leftOffset + o3a.candleWidth / G8a;
        w2M = C9l;
      }
    }
    return (
      B5y +
      q2k.left +
      Math.floor(
        (F2Q - w2M - x3G.length + U7o + 0.5) * this.layout.candleWidth + E1K
      )
    );
  };
  j9D.ChartEngine.prototype.pixelFromDate = function (K0e, B1x, l9G, j6b) {
    return this.pixelFromTick(this.tickFromDate(K0e, B1x, l9G, j6b), B1x);
  };
  j9D.ChartEngine.prototype.transformedPriceFromPixel = function (
    m5a,
    t5f,
    G$G
  ) {
    var N7_, o56, t1s;
    if (!t5f) {
      t5f = this.chart.panel;
    }
    N7_ = G$G ? G$G : t5f.yAxis;
    m5a = N7_.bottom - m5a;
    if (N7_.semiLog) {
      t1s = (m5a * N7_.logShadow) / N7_.height;
      if (N7_.flipped) {
        t1s = N7_.logHigh - t1s;
      } else {
        t1s += N7_.logLow;
      }
      o56 = Math.pow(10, t1s);
    } else {
      if (!N7_.multiplier) {
        return null;
      }
      o56 = m5a / N7_.multiplier;
      if (N7_.flipped) {
        o56 = N7_.high - o56;
      } else {
        o56 += N7_.low;
      }
    }
    return o56;
  };
  j9D.ChartEngine.prototype.priceFromPixel = function (N$G, K7H, f20) {
    var h7G;
    if (!K7H) {
      K7H = this.chart.panel;
    }
    h7G = this.transformedPriceFromPixel(N$G, K7H, f20);
    if (this.charts[K7H.name] && K7H.chart.untransformFunc) {
      if (!f20 || f20 == K7H.yAxis) {
        h7G = K7H.chart.untransformFunc(this, K7H.chart, h7G, f20);
      }
    }
    return h7G;
  };
  j9D.ChartEngine.prototype.valueFromPixel = function (n43, S_u, f8h) {
    v7ZLk.t3P();
    var r5T;
    if (!S_u) {
      S_u = this.whichPanel(n43);
    }
    if (!S_u) {
      r5T = Object.values(this.panels);
      if (r5T && r5T.length) {
        if (n43 <= 0) {
          S_u = r5T.shift();
        } else {
          S_u = r5T.pop();
        }
      }
    }
    return this.priceFromPixel(n43, S_u, f8h);
  };
  j9D.ChartEngine.prototype.valueFromInterpolation = function (
    D44,
    G0z,
    S6d,
    Q_e,
    y6K
  ) {
    var q5Z = v7ZLk;
    var a_5, b3g, F7m, x4x, J8x, u1H, S6n, P8F, j2w, r3E, B1R, Y1a, N2w, l4n;
    a_5 = 'o';
    a_5 += 'bject';
    if (D44 === null || D44 < '0' << 32 || !G0z) {
      return null;
    }
    if (!Q_e) {
      Q_e = this.chart.panel;
    }
    if (!y6K) {
      y6K = Q_e.yAxis;
    }
    if (!S6d) {
      S6d = this.chart.defaultPlotField;
    }
    b3g = this.getPreviousBar(this.chart, G0z, D44);
    if (!b3g) {
      return null;
    }
    x4x = j9D.existsInObjectChain(b3g, G0z);
    if (x4x) {
      F7m = x4x.obj[x4x.member];
    }
    if (typeof F7m == 'object') {
      F7m = F7m[S6d];
    }
    J8x = this.getRendererFromSeries(G0z);
    if ((J8x && J8x.params.step) || this.layout.chartType === 'step') {
      return F7m;
    }
    u1H = this.getNextBar(this.chart, G0z, D44);
    q5Z.Y3Y();
    x4x = j9D.existsInObjectChain(u1H, G0z);
    if (x4x) {
      S6n = x4x.obj[x4x.member];
    }
    if (typeof S6n == a_5) {
      S6n = S6n[S6d];
    }
    if (!u1H) {
      return null;
    }
    if (
      F7m === null ||
      typeof F7m == 'undefined' ||
      S6n === null ||
      typeof S6n == 'undefined'
    ) {
      return null;
    }
    P8F = this.pixelFromPrice(F7m, Q_e, y6K);
    j2w = this.pixelFromPrice(S6n, Q_e, y6K);
    r3E = b3g.tick;
    B1R = u1H.tick;
    q5Z.p1T(117);
    Y1a = q5Z.H9P(B1R, r3E, P8F, j2w);
    N2w = this.chart.dataSegment[D44].tick;
    q5Z.d$R(118);
    l4n = q5Z.H9P(r3E, N2w, P8F, Y1a);
    return this.priceFromPixel(l4n, Q_e, y6K);
  };
  j9D.ChartEngine.prototype.pixelFromTransformedValue = function (
    k0X,
    Z8y,
    e9V
  ) {
    var v_H, G1n, S1e, H_F, A6M;
    if (!Z8y) {
      Z8y = this.chart.panel;
    }
    v_H = e9V ? e9V : Z8y.yAxis;
    G1n = (v_H.high - k0X) * v_H.multiplier;
    v7ZLk.Y3Y();
    if (v_H.semiLog) {
      S1e = Math.max(k0X, 0);
      H_F = Math.log(S1e) / Math.LN10;
      A6M = v_H.height;
      G1n = A6M - (A6M * (H_F - v_H.logLow)) / v_H.logShadow;
    }
    G1n = v_H.flipped ? v_H.bottom - G1n : v_H.top + G1n;
    return G1n;
  };
  j9D.ChartEngine.prototype.pixelFromPrice = function (H1U, X1b, f$m) {
    if (!X1b) {
      X1b = this.chart.panel;
    }
    v7ZLk.t3P();
    if (this.charts[X1b.name] && X1b.chart.transformFunc) {
      if (!f$m || f$m == X1b.yAxis) {
        H1U = X1b.chart.transformFunc(this, X1b.chart, H1U, f$m);
      }
    }
    return this.pixelFromTransformedValue(H1U, X1b, f$m);
  };
  j9D.ChartEngine.prototype.pixelFromValueAdjusted = function (
    w22,
    O_o,
    A4r,
    J7j
  ) {
    var l6R = v7ZLk;
    var l9K, P2U, I4C, W07, M20;
    if (this.layout.adj || !this.charts[w22.name]) {
      return this.pixelFromPrice(A4r, w22, J7j);
    }
    l6R.p1T(25);
    l9K = l6R.M3e(1, '1333029117');
    P2U = -583312189;
    I4C = 2;
    for (
      var F0f = 1;
      l6R.q78(F0f.toString(), F0f.toString().length, +'62443') !== l9K;
      F0f++
    ) {
      W07 = Math.round(O_o);
      I4C += 2;
    }
    if (l6R.e_M(I4C.toString(), I4C.toString().length, 9742) !== P2U) {
      W07 = Math.round(O_o);
    }
    if (
      W07 > 0 &&
      W07 < w22.chart.dataSet.length &&
      (M20 = w22.chart.dataSet[W07].ratio)
    ) {
      l6R.p1T(25);
      return this.pixelFromPrice(l6R.M3e(M20, A4r), w22, J7j);
    }
    return this.pixelFromPrice(A4r, w22, J7j);
  };
  j9D.ChartEngine.prototype.adjustIfNecessary = function (T5M, s48, r41) {
    var m3m, b15;
    if (this.layout.adj) {
      return r41;
    }
    if (!T5M || !this.charts[T5M.name]) {
      return r41;
    }
    m3m = Math.round(s48);
    if (
      m3m > 0 &&
      m3m < T5M.chart.dataSet.length &&
      (b15 = T5M.chart.dataSet[m3m].ratio)
    ) {
      v7ZLk.d$R(41);
      return v7ZLk.H9P(b15, r41);
    }
    v7ZLk.t3P();
    return r41;
  };
};
U0 = (J$1) => {
  var f6i;
  f6i = J$1.CIQ;
  f6i.ChartEngine.prototype.positionCrosshairsAtPointer = function () {
    var r$2 = v7ZLk;
    var P3I, O_M, G6_, I8L, m$T, e33, j4n, z9E;
    P3I = this.currentPanel;
    if (!P3I) {
      return;
    }
    if (
      !this.manageTouchAndMouse ||
      (this.mainSeriesRenderer && this.mainSeriesRenderer.nonInteractive)
    ) {
      return;
    }
    if (this.runPrepend('positionCrosshairsAtPointer', arguments)) {
      return;
    }
    O_M = P3I.chart;
    G6_ = this.container.getBoundingClientRect();
    this.top = G6_.top;
    this.left = G6_.left;
    this.right = this.left + this.width;
    this.bottom = this.top + this.height;
    I8L = this.crosshairTick = this.tickFromPixel(
      this.backOutX(this.crosshairX),
      O_M
    );
    r$2.d$R(63);
    var G4v = r$2.M3e(4, 1, 1, 4);
    m$T = this.pixelFromTick(I8L, O_M) - G4v;
    if (m$T >= P3I.right || m$T <= P3I.left) {
      this.undisplayCrosshairs();
      return;
    }
    if (this.controls.crossX) {
      r$2.d$R(18);
      this.controls.crossX.style.left = r$2.M3e('px', m$T);
    }
    e33 =
      P3I.name == 'chart'
        ? this.preferences.horizontalCrosshairField
        : P3I.horizontalCrosshairField;
    j4n = O_M.dataSet;
    if (e33 && j4n && I8L < j4n.length && I8L > -1) {
      this.crossYActualPos = this.pixelFromPrice(j4n[I8L][e33], P3I);
    }
    z9E =
      this.crossYActualPos >= P3I.yAxis.bottom &&
      this.crossYActualPos <= P3I.bottom;
    if (z9E) {
      this.undisplayCrosshairs();
      return;
    }
    if (this.controls.crossY) {
      this.controls.crossY.style.top = this.crossYActualPos + 'px';
    }
    this.runAppend('positionCrosshairsAtPointer', arguments);
  };
  f6i.ChartEngine.prototype.doDisplayCrosshairs = function () {
    var r74 = v7ZLk;
    r74.Y3Y();
    var C0I, c6b, G0p;
    if (this.runPrepend('doDisplayCrosshairs', arguments)) {
      return;
    }
    if (this.displayInitialized) {
      var { floatCanvas: F2q, controls: Y$r, layout: d$T } = this;
      var { crossX: d4x, crossY: D$V, floatDate: Y6N } = Y$r;
      var { vectorType: u_C } = this.currentVectorParameters;
      if (
        u_C === '' ||
        (!d$T.crosshair && !u_C) ||
        (f6i.Drawing &&
          f6i.Drawing[u_C] &&
          new f6i.Drawing[u_C]().dragToDraw) ||
        (!this.insideChart && !this.grabbingScreen) ||
        this.openDialog !== ''
      ) {
        this.undisplayCrosshairs();
      } else {
        if (d4x && d4x.style.display !== '') {
          d4x.style.display = '';
          if (D$V) {
            D$V.style.display = '';
          }
          if (this.magnetizedPrice && u_C) {
            this.container.classList.remove('stx-crosshair-on');
            this.chart.tempCanvas.style.display = 'block';
          } else {
            r74.d$R(0);
            C0I = -r74.H9P('654024281', 0);
            c6b = 974170676;
            G0p = +'2';
            for (
              var Q8Y = 1;
              r74.q78(Q8Y.toString(), Q8Y.toString().length, 35566) !== C0I;
              Q8Y++
            ) {
              this.container.classList.add('');
              G0p += 2;
            }
            if (r74.e_M(G0p.toString(), G0p.toString().length, 30848) !== c6b) {
              this.container.classList.add('stx-crosshair-on');
            }
          }
        }
        if (Y6N && !this.chart.xAxis.noDraw) {
          Y6N.style.visibility = '';
          if (this.currentPanel) {
            this.updateFloatHRLabel(this.currentPanel);
          }
        }
        if (F2q) {
          if (F2q.style.display == 'none') {
            f6i.clearCanvas(F2q, this);
          }
          F2q.style.display = 'block';
        }
      }
    }
    this.runAppend('doDisplayCrosshairs', arguments);
  };
  f6i.ChartEngine.prototype.undisplayCrosshairs = function () {
    var M9G, h8$, w4P, w7R, Y8l, T45, s4N, t3n;
    M9G = 'undisplayCr';
    M9G += 'osshairs';
    if (this.runPrepend(M9G, arguments)) {
      return;
    }
    h8$ = this.controls;
    w4P = h8$.crossX;
    w7R = h8$.crossY;
    if (w4P) {
      if (w4P.style.display != 'none') {
        w4P.style.display = 'none';
        if (w7R) {
          w7R.style.display = 'none';
        }
      }
    }
    if (this.displayInitialized && h8$.floatDate) {
      Y8l = 'hi';
      Y8l += 'dden';
      h8$.floatDate.style.visibility = Y8l;
    }
    this.container.classList.remove('stx-crosshair-on');
    T45 = this.floatCanvas;
    if (T45 && T45.isDirty && T45.style.display != 'none') {
      f6i.clearCanvas(T45, this);
      if (T45.style.display != 'none') {
        T45.style.display = 'none';
      }
    }
    if (
      !this.activeDrawing &&
      !this.repositioningDrawing &&
      !this.editingAnnotation
    ) {
      s4N = 'n';
      s4N += 'o';
      s4N += 'n';
      s4N += 'e';
      t3n = this.chart.tempCanvas;
      if (t3n && t3n.style.display != s4N) {
        t3n.style.display = 'none';
      }
    }
    this.runAppend('undisplayCrosshairs', arguments);
  };
  f6i.ChartEngine.prototype.hideCrosshairs = function () {
    this.displayCrosshairs = !!0;
  };
  f6i.ChartEngine.prototype.showCrosshairs = function () {
    v7ZLk.t3P();
    this.displayCrosshairs = !!{};
  };
};
h$ = (g4P) => {
  var A7Q = v7ZLk;
  var h1e, i72;
  h1e = g4P.CIQ;
  i72 = g4P.timezoneJS;
  h1e.ChartEngine.prototype.loadChart = function (x$N, t_a, R8d) {
    var A5r, U0X, A6L, K8P, j9O, K9x, P87, H82;
    A5r = 'obj';
    A5r += 'ect';
    if (!R8d && typeof t_a == 'function') {
      R8d = t_a;
      t_a = {};
    } else if (Array.isArray(t_a)) {
      t_a = { masterData: t_a };
    }
    if (!t_a) {
      t_a = {};
    }
    var { chart: r3Q, periodicity: L5S, range: U0W, span: y3u } = t_a;
    var { layout: Y4s, quoteDriver: H73 } = this;
    function j7U() {
      var Z6t, v_M;
      Z6t = 'symbolI';
      Z6t += 'm';
      Z6t += 'port';
      K9x.layout.symbols = K9x.getSymbols({
        'include-parameters': !![],
        'exclude-studies': !!{},
      });
      K9x.dispatch(K9x.currentlyImporting ? Z6t : 'symbolChange', {
        stx: K9x,
        symbol: r3Q.symbol,
        symbolObject: r3Q.symbolObject,
        prevSymbol: J5B,
        prevSymbolObject: K8P,
        action: 'master',
      });
      if (L5S) {
        v_M = 'perio';
        v_M += 'd';
        v_M += 'icit';
        v_M += 'y';
        K9x.dispatch(v_M, {
          stx: K9x,
          differentData: !!'1',
          prevPeriodicity: U0X,
        });
      }
      if (H73 && H73.paused) {
        H73.resume();
      }
    }
    U0X = {
      periodicity: Y4s.periodicity,
      interval: Y4s.interval,
      timeUnit: Y4s.timeUnit,
    };
    if (L5S) {
      A6L = h1e.cleanPeriodicity(
        L5S.period ? L5S.period : L5S.periodicity,
        L5S.interval,
        L5S.timeUnit
      );
      Y4s.interval = A6L.interval;
      Y4s.periodicity = A6L.period;
      Y4s.timeUnit = A6L.timeUnit;
    }
    if (!r3Q) {
      r3Q = this.chart;
    }
    var {
      dataSet: B0y,
      market: H8W,
      masterData: F_q,
      symbol: J5B,
      moreAvailable: T1f,
      upToDate: y9y,
    } = r3Q;
    K8P = h1e.clone(r3Q.symbolObject);
    r3Q.dataSet = [];
    r3Q.masterData = [];
    r3Q.moreAvailable = null;
    r3Q.upToDate = null;
    if (!x$N) {
      r3Q.symbol = null;
      r3Q.symbolObject = { symbol: null };
    } else if (typeof x$N == A5r) {
      if (x$N.symbol.toUpperCase && x$N.symbol.toUpperCase() === 'CIQINFO') {
        h1e.logPackageInfo();
      } else {
        r3Q.symbol = x$N.symbol;
        r3Q.symbolObject = x$N;
      }
    } else {
      if (x$N.toUpperCase && x$N.toUpperCase() === 'CIQINFO') {
        h1e.logPackageInfo();
      } else {
        r3Q.symbol = x$N;
        r3Q.symbolObject.symbol = x$N;
      }
    }
    r3Q.inflectionPoint = null;
    if (this.marketFactory) {
      j9O = this.marketFactory(r3Q.symbolObject);
      this.setMarket(j9O, r3Q);
    }
    this.setMainSeriesRenderer(!!'1');
    if (!U0W && !y3u && Y4s) {
      y3u = !Y4s.range ? Y4s.setSpan : {};
      U0W = Y4s.range || {};
    } else if (U0W && y3u) {
      y3u = {};
    }
    this.clearCurrentMarketData(r3Q);
    K9x = this;
    if (!t_a.masterData && H73) {
      P87 = function (R5Y) {
        var V5w;
        V5w = 'orp';
        V5w += 'ha';
        V5w += 'ned';
        if (R5Y && R5Y != V5w) {
          r3Q.symbol = J5B;
          r3Q.symbolObject = K8P;
          r3Q.market = H8W;
          K9x.masterData = r3Q.masterData = F_q;
          r3Q.dataSet = B0y;
          r3Q.moreAvailable = T1f;
          r3Q.upToDate = y9y;
        }
        j7U();
        A7Q.t3P();
        if (R8d) {
          R8d.call(K9x, R5Y);
        }
      };
      if (U0W && Object.keys(U0W).length && this.setRange) {
        delete t_a.span;
        delete Y4s.setSpan;
        this.chart.masterData = null;
        this.displayInitialized = !!'';
        if (L5S) {
          U0W.periodicity = L5S;
        }
        U0W.forceLoad = !0;
        this.setRange(U0W, P87);
      } else if (y3u && y3u.base && this.setSpan) {
        y3u.multiplier = y3u.multiplier || 1;
        this.chart.masterData = null;
        this.displayInitialized = !!0;
        if (L5S) {
          y3u.maintainPeriodicity = !![];
        }
        y3u.forceLoad = !!{};
        this.setSpan(y3u, P87);
      } else {
        H73.newChart(
          {
            symbol: r3Q.symbol,
            symbolObject: r3Q.symbolObject,
            chart: r3Q,
            initializeChart: !'',
          },
          function (u$f) {
            if (!u$f) {
              K9x.adjustPanelPositions();
              H73.updateSubscriptions();
              if (t_a.stretchToFillScreen) {
                K9x.fillScreen();
              }
            }
            A7Q.t3P();
            P87.apply(K9x, arguments);
          }
        );
      }
    } else {
      if (!t_a.masterData) {
        console.log(
          'Warning: No masterData specified and no QuoteFeed configured'
        );
      }
      if (!r3Q.symbol) {
        r3Q.symbol = '';
      }
      this.initializeChart();
      H82 = this.doCleanupGaps(t_a.masterData, r3Q);
      this.setMasterData(H82, r3Q, { noCleanupDates: !![] });
      r3Q.endPoints = {};
      if (H82 && H82.length) {
        r3Q.endPoints = { begin: H82[0].DT, end: H82[H82.length - 1].DT };
      }
      this.createDataSet();
      if (U0W && Object.keys(U0W).length && this.setRange) {
        this.setRange(U0W);
      } else if (y3u && y3u.multiplier && y3u.base && this.setSpan) {
        this.setSpan({
          maintainPeriodicity: !'',
          multiplier: y3u.multiplier,
          base: y3u.base,
        });
      } else if (t_a.stretchToFillScreen) {
        this.fillScreen();
      } else if (H82 && H82.length) {
        this.home();
      } else {
        this.clear();
      }
      this.adjustPanelPositions();
      j7U();
      if (R8d) {
        R8d.call(K9x);
      }
    }
    h1e.logVersion();
  };
  h1e.ChartEngine.prototype.loadBlankChart = function () {
    this.loadChart(null, []);
  };
  h1e.ChartEngine.prototype.getDataFields = function (P2S) {
    var Q9s, f3V, K6K, U74;
    Q9s = 'L';
    Q9s += 'ow';
    if (!P2S) {
      P2S = this.chart;
    }
    A7Q.t3P();
    f3V = P2S.defaultPlotField || 'Close';
    K6K = ['Open', 'High', Q9s];
    K6K.push(f3V);
    for (var l7w in P2S.series) {
      U74 = P2S.series[l7w].parameters;
      K6K.push(U74.symbol);
    }
    return K6K;
  };
  h1e.ChartEngine.prototype.cleanMasterData = function (g80, W6S) {
    var h7R, Y_f, C0V, G3X, D1o;
    h7R = g80.symbol;
    Y_f = W6S.masterData;
    if (!Y_f || !Y_f.length) {
      return;
    }
    C0V = this.getDataFields(W6S);
    G3X = 0;
    function t3R(R53, l7e) {
      var J77;
      for (var f$B = 0; f$B < l7e.length; f$B++) {
        J77 = R53[l7e[f$B]];
        if (typeof J77 != 'undefined') {
          return ![];
        }
      }
      return !!1;
    }
    do {
      D1o = Y_f[G3X];
      delete D1o[h7R];
      if (t3R.call(this, D1o, C0V)) {
        A7Q.d$R(0);
        Y_f.splice(G3X, A7Q.M3e('1', 0));
        continue;
      }
      G3X++;
    } while (G3X < Y_f.length);
    Y_f = this.doCleanupGaps(Y_f, W6S, { noCleanupDates: !!{} });
    this.setMasterData(Y_f, W6S, { noCleanupDates: !!{} });
    this.clearCurrentMarketData(W6S, h7R);
  };
  A7Q.t3P();
  h1e.ChartEngine.prototype.calculateATR = function (n6p, b5o, D2E) {
    var l9u, X5w, Q5K, r3r, x_5, M4r;
    if (!D2E) {
      D2E = n6p.dataSet;
    }
    l9u = n6p.state.calculations.atr;
    if (!l9u) {
      l9u = n6p.state.calculations.atr = {};
    }
    if (!b5o) {
      b5o = 20;
    }
    X5w = [];
    if (l9u.accum) {
      X5w = l9u.accum;
    }
    for (var e62 = 0; e62 < D2E.length; e62++) {
      r3r = D2E[e62];
      Q5K = e62 ? D2E[e62 - 1] : l9u.q1;
      if (!Q5K) continue;
      x_5 = Math.max(
        r3r.High - r3r.Low,
        Math.abs(r3r.High - Q5K.Close),
        Math.abs(r3r.Low - Q5K.Close)
      );
      if (X5w.length < b5o) {
        if (X5w.push(x_5) == b5o) {
          M4r = 0;
          for (var w15 = 0; w15 < X5w.length; w15++) {
            M4r += X5w[w15];
          }
          A7Q.p1T(41);
          r3r.atr = A7Q.M3e(b5o, M4r);
        }
      } else {
        A7Q.p1T(65);
        var R4k = A7Q.M3e(10, 8, 81);
        r3r.atr = (Q5K.atr * (b5o - R4k) + x_5) / b5o;
      }
      r3r.trueRange = x_5;
    }
    A7Q.Y3Y();
    n6p.state.calculations.atr = { accum: X5w, q1: Q5K };
  };
  h1e.ChartEngine.prototype.calculateMedianPrice = function (q1A, e5z) {
    var L9K, s2z, b0t, L_B;
    if (!e5z) {
      e5z = q1A.dataSet;
    }
    L9K = -1878986813;
    s2z = 707812372;
    b0t = 2;
    for (
      var U7s = 1;
      A7Q.q78(U7s.toString(), U7s.toString().length, +'42324') !== L9K;
      U7s++
    ) {
      b0t += 2;
    }
    if (A7Q.q78(b0t.toString(), b0t.toString().length, 55651) !== s2z) {
    }
    for (var w4e = 0; w4e < e5z.length; ++w4e) {
      L_B = e5z[w4e];
      L_B['hl/2'] = (L_B.High + L_B.Low) / +'2';
    }
  };
  h1e.ChartEngine.prototype.calculateTypicalPrice = function (k8w, s_0) {
    A7Q.Y3Y();
    var E0V;
    if (!s_0) {
      s_0 = k8w.dataSet;
    }
    for (var z2_ = 0; z2_ < s_0.length; ++z2_) {
      E0V = s_0[z2_];
      E0V['hlc/3'] = (E0V.High + E0V.Low + E0V.Close) / +'3';
    }
  };
  h1e.ChartEngine.prototype.calculateWeightedClose = function (W$Q, i$L) {
    A7Q.Y3Y();
    var i5p;
    if (!i$L) {
      i$L = W$Q.dataSet;
    }
    for (var m8n = 0; m8n < i$L.length; ++m8n) {
      i5p = i$L[m8n];
      A7Q.d$R(61);
      var N8R = A7Q.H9P(15, 0, 11);
      i5p['hlcc/4'] = (i5p.High + i5p.Low + +'2' * i5p.Close) / N8R;
    }
  };
  h1e.ChartEngine.prototype.calculateOHLC4 = function (w21, G5a) {
    var u8p;
    A7Q.Y3Y();
    if (!G5a) {
      G5a = w21.dataSet;
    }
    for (var I0N = 0; I0N < G5a.length; ++I0N) {
      u8p = G5a[I0N];
      A7Q.p1T(0);
      var Z4p = A7Q.H9P(9, 5);
      u8p['ohlc/4'] = (u8p.Open + u8p.High + u8p.Low + u8p.Close) / Z4p;
    }
  };
  h1e.ChartEngine.prototype.currentQuote = function (A_N) {
    var t9N, P8g, x9q, G5H;
    t9N = 281906241;
    P8g = -1483280935;
    x9q = 2;
    for (
      var S7v = 1;
      A7Q.e_M(S7v.toString(), S7v.toString().length, 17555) !== t9N;
      S7v++
    ) {
      if (!this.chart.dataSet) {
        return null;
      }
      x9q += 2;
    }
    if (A7Q.q78(x9q.toString(), x9q.toString().length, 2856) !== P8g) {
      if (-this.chart.dataSet) {
        return 1;
      }
    }
    for (var Y8X = this.chart.dataSet.length - 1; Y8X >= '0' << 0; Y8X--) {
      if (this.chart.dataSet[Y8X]) {
        if (!A_N) {
          return this.chart.dataSet[Y8X];
        }
        G5H = this.chart.dataSet[Y8X][A_N];
        if (G5H || G5H === 0) {
          return this.chart.dataSet[Y8X];
        }
      }
    }
    return null;
  };
  h1e.ChartEngine.prototype.mostRecentClose = function (K6q) {
    var A11, H6C, V8j, g$w;
    if (!this.chart.dataSet) {
      return null;
    }
    for (var D$h = this.chart.dataSet.length - 1; D$h >= 0; D$h--) {
      A11 = 'numb';
      A11 += 'er';
      H6C = 'objec';
      H6C += 't';
      V8j = this.chart.dataSet[D$h];
      if (!V8j) continue;
      if (K6q) {
        V8j = V8j[K6q];
        if (!V8j && V8j !== +'0') continue;
      }
      g$w = V8j.iqPrevClose;
      if (typeof V8j == H6C) {
        V8j = V8j.Close;
      }
      if (typeof V8j == 'number') {
        return V8j;
      }
      if (typeof g$w == A11) {
        return g$w;
      }
    }
    return null;
  };
  h1e.ChartEngine.prototype.createDataSegment = function (v81) {
    var M4U,
      L8H,
      z2N,
      n9d,
      o5t,
      C_y,
      J64,
      S80,
      o24,
      J15,
      W_x,
      N6N,
      V2e,
      P3L,
      a_M,
      K5m,
      W_1,
      j$F,
      S_v;
    M4U = 'crea';
    M4U += 'teData';
    M4U += 'Segme';
    M4U += 'nt';
    if (this.runPrepend(M4U, arguments)) {
      return;
    }
    for (var C1D in this.charts) {
      L8H = this.charts[C1D];
      if (v81) {
        L8H = v81;
      }
      if (h1e.Comparison && L8H.isComparison) {
        h1e.Comparison.createComparisonSegmentInner(this, L8H);
      }
      z2N = L8H.dataSet;
      n9d = L8H.baseline;
      o5t = L8H.scroll;
      C_y = L8H.maxTicks;
      J64 = this.layout;
      S80 = J64.candleWidth;
      n9d.actualLevel = n9d.userLevel ? n9d.userLevel : n9d.defaultLevel;
      o24 =
        n9d.includeInDataSegment &&
        (!this.mainSeriesRenderer || !this.mainSeriesRenderer.standaloneBars);
      W_x = 0;
      N6N = L8H.dataSegment = [];
      A7Q.d$R(18);
      var V7S = A7Q.M3e(1, 0);
      A7Q.d$R(35);
      var e4I = A7Q.H9P(12, 11);
      V2e = z2N.length - V7S - o5t - e4I;
      P3L = L8H.defaultPlotField;
      for (var v5U = -1; v5U < o5t && v5U < C_y; v5U++) {
        V2e++;
        if (v5U == -1 && !o24) continue;
        if (V2e < z2N.length && V2e >= 0) {
          J15 = z2N[V2e];
          J15.candleWidth = null;
          if (J15) {
            W_x += J15.Volume || +'1';
          }
          N6N.push(J15);
          if (n9d.actualLevel === null && v5U >= '0' - 0) {
            if (P3L && P3L != 'Close') {
              A7Q.d$R(0);
              a_M = z2N[A7Q.M3e(V2e, 1)];
              if (a_M && (a_M[P3L] || a_M[P3L] === 0)) {
                n9d.actualLevel = a_M[P3L];
              }
            } else {
              if (J15.iqPrevClose || J15.iqPrevClose === 0) {
                n9d.actualLevel = J15.iqPrevClose;
              }
            }
          }
        } else if (V2e < 0) {
          N6N.push(null);
        }
      }
      L8H.segmentImage = null;
      K5m = this.mainSeriesRenderer || {};
      if (K5m.params && K5m.params.volume) {
        A7Q.d$R(0);
        var A_s = A7Q.H9P(2, 1);
        W_1 = L8H.width - (C_y - N6N.length - A_s) * J64.candleWidth;
        j$F = 0;
        L8H.segmentImage = [];
        for (var Z$t = 0; Z$t < N6N.length; Z$t++) {
          J15 = N6N[Z$t];
          L8H.segmentImage[Z$t] = {};
          S_v = null;
          if (J15) {
            if (J15.Volume) {
              J15.candleWidth = (W_1 * J15.Volume) / W_x;
              A7Q.d$R(119);
              var L1Z = A7Q.H9P(67, 20, 0, 9, 5);
              S_v = j$F + J15.candleWidth / L1Z;
              j$F += J15.candleWidth;
            } else {
              J15.candleWidth = S80;
              A7Q.p1T(120);
              S_v = A7Q.H9P(2, S80, j$F);
              j$F += S80;
            }
            L8H.segmentImage[Z$t] = {
              tick: J15.tick,
              candleWidth: J15.candleWidth,
              leftOffset: S_v,
            };
          } else {
            L8H.segmentImage[Z$t] = {
              tick: null,
              candleWidth: S80,
              leftOffset: j$F + S80 / 2,
            };
            j$F += S80;
          }
        }
      }
      if (v81) break;
    }
    if (L8H && L8H.isComparison) {
      this.clearPixelCache();
    }
    this.positionCrosshairsAtPointer();
    this.runAppend('createDataSegment', arguments);
  };
  h1e.ChartEngine.prototype.getDataSegment = function (P2J) {
    var Q2d, Y6O, q7C;
    if (!P2J) {
      P2J = this.chart;
    }
    Q2d = P2J.dataSegment;
    A7Q.t3P();
    if (!Q2d || !Q2d.length) {
      return [];
    }
    Y6O = 0;
    q7C = Q2d.length;
    if (this.pixelFromBar(Y6O, P2J) < P2J.panel.left) {
      Y6O++;
    }
    if (this.pixelFromBar(q7C - 1, P2J) > P2J.panel.right) {
      q7C--;
    }
    return Q2d.slice(Y6O, q7C);
  };
  h1e.ChartEngine.prototype.setMasterData = function (o9X, Q84, d4W) {
    var s2b, D5y, A1l, A2Y, Q4x, P_p, B1Y, T4h, L_V;
    if (!Q84) {
      Q84 = this.chart;
    }
    if (this.marketFactory) {
      s2b = this.marketFactory(Q84.symbolObject);
      this.setMarket(s2b, Q84);
    }
    if (!d4W) {
      d4W = {};
    }
    if (!d4W.noCleanupDates) {
      this.doCleanupDates(o9X, this.layout.interval);
    }
    Q84.masterData = o9X;
    if (Q84.name == 'chart') {
      this.masterData = o9X;
    }
    A1l = null;
    for (D5y = 0; o9X && D5y < o9X.length; D5y++) {
      A2Y = 'numbe';
      A2Y += 'r';
      Q4x = 'n';
      Q4x += 'u';
      Q4x += 'mbe';
      Q4x += 'r';
      P_p = o9X[D5y];
      if (A1l === null) {
        B1Y = 'num';
        B1Y += 'be';
        B1Y += 'r';
        if (typeof P_p.Close === 'number') {
          A1l = 'Close';
        } else if (typeof P_p.Value === B1Y) {
          A1l = 'Value';
        }
      }
      if (A1l === 'Value' && typeof P_p.Value === Q4x) {
        P_p.Close = P_p.Value;
      }
      if (P_p.DT) {
        T4h = '[object D';
        T4h += 'at';
        T4h += 'e]';
        if (Object.prototype.toString.call(P_p.DT) != T4h) {
          P_p.DT = new Date(P_p.DT);
        }
        if (!P_p.Date || P_p.Date.length != 17) {
          P_p.Date = h1e.yyyymmddhhmmssmmm(P_p.DT);
        }
      } else if (P_p.Date) {
        P_p.DT = h1e.strToDateTime(P_p.Date);
      } else {
        console.log('setMasterData : Missing DT and Date on masterData object');
      }
      if (P_p.Volume && typeof P_p.Volume !== A2Y) {
        P_p.Volume = parseInt(P_p.Volume, 10);
      }
      if (o9X.length - D5y < 50) {
        this.updateCurrentMarketData(P_p, Q84, null, { fromTrade: !'' });
      }
    }
    if (Q84.calculateTradingDecimalPlaces) {
      Q84.decimalPlaces = Q84.calculateTradingDecimalPlaces({
        stx: this,
        chart: Q84,
        symbol: Q84.symbolObject.symbol,
        symbolObject: Q84.symbolObject,
      });
    }
    A7Q.t3P();
    this.setDisplayDates(o9X);
    Q84.roundit = Math.pow(10, Q84.decimalPlaces);
    for (D5y in this.plugins) {
      L_V = this.plugins[D5y];
      if (L_V.display) {
        if (L_V.setMasterData) {
          L_V.setMasterData(this, Q84);
        }
      }
    }
  };
  h1e.ChartEngine.prototype.setMasterDataRender = function (E69, U3f, h3J) {
    if (!h3J) {
      h3J = this.chart;
    }
    if (!h3J.symbol) {
      h3J.symbol = '';
    }
    this.setMasterData(U3f, h3J);
    if (U3f) {
      h3J.endPoints = {};
      if (U3f.length) {
        h3J.endPoints = { begin: U3f['0' - 0].DT, end: U3f[U3f.length - 1].DT };
        h3J.symbol = E69;
      }
    }
    this.createDataSet();
    this.initializeChart();
    this.draw();
    if (!U3f || !U3f.length) {
      h3J.symbol = null;
      this.clear();
    }
    this.adjustPanelPositions();
  };
  h1e.ChartEngine.prototype.getSymbols = function (o4k) {
    var U5u, Y1D, K6C, U0u, S4I, i0O, q4D, J5n, A_f, q85;
    A7Q.t3P();
    if (!o4k) {
      o4k = {};
    }
    U5u = [];
    K6C = this.layout;
    for (var u0j in this.charts) {
      U0u = this.charts[u0j];
      var { symbol: q$5, symbolObject: a8z } = U0u;
      if (a8z && a8z.symbol) {
        if (o4k.static) {
          if (a8z.static) {
            U5u.push(Q07(q$5, a8z, K6C));
          }
        } else {
          U5u.push(Q07(q$5, a8z, K6C));
        }
      }
      for (var y2o in U0u.series) {
        S4I = 'exclude-gener';
        S4I += 'at';
        S4I += 'ed';
        i0O = 'excl';
        i0O += 'ude-s';
        i0O += 'tudies';
        q4D = 'i';
        q4D += 'nc';
        q4D += 'lude-';
        q4D += 'parameters';
        var { parameters: q_f } = U0u.series[y2o];
        if (
          !(o4k['include-static'] || o4k.static) &&
          q_f.data &&
          !q_f.data.useDefaultQuoteFeed
        )
          continue;
        var { symbol: X9Q, symbolObject: h_i } = q_f;
        Y1D = Q07(X9Q, h_i, K6C);
        Y1D.id = y2o;
        if (o4k[q4D]) {
          Y1D.parameters = h1e.clone(q_f);
          if (Y1D.parameters.yAxis) {
            delete Y1D.parameters.yAxis.yAxisPlotter;
          }
        }
        if (o4k[i0O] && q_f.bucket == 'study') continue;
        if (o4k[S4I] && h_i.generator) continue;
        if (o4k.static) {
          if (h_i.static) {
            U5u.push(Y1D);
          }
        } else {
          U5u.push(Y1D);
        }
      }
    }
    function Q07(Z0i, U$H, m8Q) {
      A7Q.Y3Y();
      return {
        symbol: Z0i,
        symbolObject: U$H,
        periodicity: m8Q.periodicity,
        interval: m8Q.interval,
        timeUnit: m8Q.timeUnit,
        setSpan: m8Q.setSpan,
      };
    }
    if (o4k['breakout-equations']) {
      J5n = {};
      for (var K09 = 0; K09 < U5u.length; K09++) {
        var { symbol: d8o } = U5u[K09];
        if (this.isEquationChart(d8o)) {
          A_f = h1e.formatEquation(d8o);
          if (A_f) {
            q85 = A_f.symbols;
            for (var F9N = 0; F9N < q85.length; F9N++) {
              J5n[q85[F9N]] = Q07(q85[F9N], U5u[K09].symbolObject, U5u[K09]);
            }
          }
        } else {
          J5n[d8o] = Q07(d8o, U5u[K09].symbolObject, U5u[K09]);
        }
      }
      U5u = [];
      for (var g9Y in J5n) {
        U5u.push(J5n[g9Y]);
      }
    }
    return U5u;
  };
  h1e.ChartEngine.prototype.setDisplayDate = function (x5S) {
    var l_l, N$m, A3A;
    if (h1e.ChartEngine.isDailyInterval(this.layout.interval)) {
      return;
    }
    l_l = x5S.DT;
    N$m = l_l.getSeconds() * ('1000' ^ 0) + l_l.getMilliseconds();
    if (i72.Date && this.displayZone) {
      A3A = new i72.Date(l_l.getTime(), this.displayZone);
      l_l = new Date(
        A3A.getFullYear(),
        A3A.getMonth(),
        A3A.getDate(),
        A3A.getHours(),
        A3A.getMinutes()
      );
      l_l = new Date(l_l.getTime() + N$m);
    }
    x5S.displayDate = l_l;
  };
  h1e.ChartEngine.prototype.setDisplayDates = function (K0J) {
    var d8P;
    if (!K0J) {
      return;
    }
    if (h1e.ChartEngine.isDailyInterval(this.layout.interval)) {
      return;
    }
    A7Q.t3P();
    for (var u$S = 0; u$S < K0J.length; u$S++) {
      d8P = K0J[u$S];
      if (d8P.DT) {
        this.setDisplayDate(d8P);
      }
    }
  };
  h1e.ChartEngine.prototype.setTimeZone = function (M32, s0R) {
    var B5d, p6Z, W4r, D0J, S_U;
    if (!i72.Date) {
      this.timeZoneOffset = 0;
      return;
    }
    B5d = new Date();
    p6Z = B5d.getTimezoneOffset();
    W4r = p6Z;
    D0J = p6Z;
    if (M32) {
      this.dataZone = M32;
    }
    if (this.dataZone) {
      W4r = new i72.Date(B5d, this.dataZone).getTimezoneOffset();
    }
    if (s0R) {
      this.displayZone = s0R;
    }
    if (this.displayZone) {
      D0J = new i72.Date(B5d, this.displayZone).getTimezoneOffset();
    }
    A7Q.p1T(121);
    this.timeZoneOffset = A7Q.M3e(W4r, p6Z, p6Z, D0J);
    for (var y5e in this.charts) {
      S_U = this.charts[y5e];
      this.setDisplayDates(S_U.masterData);
    }
    this.preferences.timeZone = s0R;
    this.changeOccurred('preferences');
    this.createDataSet();
  };
  h1e.ChartEngine.prototype.updateChartData = function (v3h, g1j, e6J = {}) {
    var K2x,
      w4V,
      N3_,
      o8g,
      O4v,
      N4C,
      u74,
      u50,
      F_l,
      Q$k,
      W79,
      T0t,
      h45,
      w0W,
      p96,
      c2C,
      t$o,
      r2Q,
      y28,
      Z9E,
      b4l,
      B_l,
      D0S,
      H3Z,
      A_Y,
      B90,
      c4o;
    function Z8T() {
      var M_i, n6b, Y2P, w3F, F9n;
      M_i = 't';
      M_i += 'ick';
      N3_ = !'';
      A7Q.Y3Y();
      if (e6J.useAsLastSale && e6J.useAsLastSale.aggregatedVolume) {
        o8g = !!1;
      }
      if (v3h.constructor === Array) {
        A7Q.d$R(122);
        var j$i = A7Q.M3e(14, 12, 0, 2, 2);
        n6b = v3h[v3h.length - j$i];
        v3h = {};
        v3h.DT = n6b.DT;
        v3h.Close = n6b.Close;
        v3h.Volume = n6b.Volume;
      } else if (v3h.Last) {
        v3h.Close = v3h.Last;
        Y2P = -1129211351;
        A7Q.p1T(25);
        w3F = A7Q.M3e(1, '1614911208');
        F9n = 2;
        for (
          var m33 = 1;
          A7Q.q78(m33.toString(), m33.toString().length, 6098) !== Y2P;
          m33++
        ) {
          delete v3h.Last;
          F9n += 2;
        }
        if (A7Q.q78(F9n.toString(), F9n.toString().length, 34337) !== w3F) {
          ~v3h.Last;
        }
      }
      if (v3h.DT && Object.prototype.toString.call(v3h.DT) != '[object Date]') {
        v3h.DT = new Date(v3h.DT);
      }
      if (!v3h.DT || v3h.DT == 'Invalid Date') {
        v3h.DT = new Date();
      }
      if (o5G.interval != M_i) {
        l88();
      }
      v3h.Open = v3h.Close;
      v3h.High = v3h.Close;
      v3h.Low = v3h.Close;
    }
    K2x = 'appendMast';
    function X_6(J29, M2e) {
      var S2z, Z6h, y40;
      S2z = g$N[J29];
      if (F8q) {
        S2z = S2z[F8q] || {};
      }
      if (M2e.Close === null) {
        if (S2z.Open !== undefined) {
          M2e.Open = null;
        }
        if (S2z.High !== undefined) {
          M2e.High = null;
        }
        if (S2z.Low !== undefined) {
          M2e.Low = null;
        }
        if (S2z.Volume !== undefined) {
          M2e.Volume = null;
        }
      } else {
        Z6h = 'A';
        Z6h += 'sk';
        y40 = 'B';
        y40 += 'i';
        y40 += 'd';
        if (N3_) {
          if (M2e.Volume) {
            M2e.Volume = parseInt(M2e.Volume, 10);
          }
          if (!o8g) {
            M2e.Volume += S2z.Volume;
          }
        } else {
          if (!y0W(M2e.Volume) && S2z.Volume) {
            M2e.Volume = S2z.Volume;
          }
        }
        if (!e6J.allowReplaceOHL) {
          if (y0W(S2z.Open)) {
            M2e.Open = S2z.Open;
          }
          if (y0W(S2z.High) && y0W(M2e.High)) {
            if (S2z.High > M2e.High) {
              M2e.High = S2z.High;
            }
          }
          if (y0W(S2z.Low) && y0W(M2e.Low)) {
            if (S2z.Low < M2e.Low) {
              M2e.Low = S2z.Low;
            }
          }
        }
        ['Close', 'Open', 'High', 'Low', y40, Z6h].forEach(function (z$r) {
          A7Q.t3P();
          if (!y0W(M2e[z$r])) {
            M2e[z$r] = S2z[z$r];
          }
        });
        for (O4v in g1j.series) {
          N4C = g1j.series[O4v].parameters.symbolObject.symbol;
          if (
            typeof M2e[N4C] == 'undefined' &&
            typeof S2z[N4C] != 'undefined'
          ) {
            M2e[N4C] = S2z[N4C];
          }
        }
      }
    }
    K2x += 'erData';
    if (!g1j) {
      g1j = this.chart;
    }
    w4V = this;
    var { layout: o5G } = this;
    var { animationEntry: B5_, secondarySeries: F8q } = e6J;
    function J8b() {
      var d8A, T5C, b4w;
      d8A = null;
      T5C = +'0';
      A7Q.Y3Y();
      if (g$N.length) {
        d8A = w4V.getFirstLastDataRecord(
          g$N,
          F8q || g1j.defaultPlotField,
          !![]
        );
        if (d8A) {
          if (v3h[v3h.length - 1].DT <= d8A.DT) {
            return;
          }
          for (; T5C < v3h.length; T5C++) {
            if (+v3h[T5C].DT == +d8A.DT) {
              if (
                w4V.getFirstLastDataRecord(
                  [v3h[T5C]],
                  F8q || g1j.defaultPlotField
                )
              ) {
                d8A = null;
              }
              break;
            } else if (v3h[T5C].DT > d8A.DT) break;
          }
        }
      }
      b4w = v3h.slice(T5C);
      if (d8A) {
        b4w.unshift(F8q ? d8A[F8q] : d8A);
      }
      b4w = w4V.doCleanupGaps(b4w, g1j);
      if (d8A) {
        b4w.shift();
      }
      v3h = v3h.slice(0, T5C).concat(b4w);
    }
    var { isValidNumber: y0W } = h1e;
    var { masterData: g$N } = g1j;
    N3_ = !'1';
    function l88() {
      var g_d, Q9X, E5_, t8x, U36, f5M, y2z, K5a, g$x, j9F;
      g_d = 'market.m';
      g_d += 'arket_def.market_tz';
      if (!h1e.Market || !g1j.market) {
        return;
      }
      Q9X = { market_tz: h1e.getFromNS(g1j, g_d, null) };
      E5_ = o5G.interval;
      if (E5_ == 'month' || E5_ == 'week') {
        if (!w4V.dontRoll) {
          E5_ = 'day';
        }
        Q9X = w4V.chart.market.market_def;
      }
      t8x = new h1e.Market(Q9X);
      U36 = {
        begin: g$N && g$N.length ? g$N[g$N.length - ('1' - 0)].DT : v3h.DT,
        interval: E5_,
        periodicity: 1,
        timeUnit: o5G.timeUnit,
      };
      f5M = t8x.newIterator(U36);
      y2z = f5M.next();
      if (!g$N) {
        v3h.DT = new Date(+f5M.previous());
      } else if (v3h.DT < y2z) {
        K5a = 0;
        j9F = f5M.previous();
        g$x = v3h.DT;
        e6J.appending = !![];
        while (g$x < j9F && K5a < +'1000') {
          e6J.appending = !!0;
          j9F = f5M.previous();
          K5a++;
        }
        v3h.DT = j9F;
        e6J.updating = !e6J.appending;
      } else if (v3h.DT >= y2z) {
        K5a = 0;
        g$x = v3h.DT;
        while (g$x > y2z && K5a < 1000) {
          v3h.DT = y2z;
          y2z = f5M.next();
          K5a++;
        }
        e6J.appending = !'';
      }
    }
    o8g = !'1';
    if (!e6J.noCleanupDates) {
      this.doCleanupDates(v3h, o5G.interval);
    }
    if (
      e6J.useAsLastSale ||
      (v3h.constructor == Object && (v3h.Last || v3h.Last === ('0' | 0)))
    ) {
      Z8T();
    }
    if (v3h && v3h.constructor == Object) {
      v3h = [v3h];
    }
    if (!v3h || !v3h.length) {
      return;
    }
    if (this.runPrepend(K2x, [v3h, g1j, e6J])) {
      return;
    }
    if (this.runPrepend('updateChartData', [v3h, g1j, e6J])) {
      return;
    }
    if (!g$N) {
      g$N = [];
    }
    A7Q.p1T(0);
    var X03 = A7Q.M3e(10, 9);
    A7Q.t3P();
    u74 = g$N.length - X03;
    u50 = !!'';
    function L1i(V0R, o9w) {
      var N5a, r_i, q4c, s$k, T_t, f_X, X56;
      A7Q.t3P();
      if (F8q) {
        r_i = -1546609016;
        A7Q.d$R(21);
        q4c = A7Q.M3e('648643718', 0);
        s$k = 2;
        for (
          var i00 = +'1';
          A7Q.e_M(i00.toString(), i00.toString().length, 12553) !== r_i;
          i00++
        ) {
          ~g$N[V0R][F8q];
          s$k += 2;
        }
        if (A7Q.e_M(s$k.toString(), s$k.toString().length, 42021) !== q4c) {
          ~g$N[V0R][F8q];
        }
        delete g$N[V0R][F8q];
        if (w4V.cleanupGaps) {
          N5a = { DT: o9w, Close: null };
          if (w4V.cleanupGaps != 'gap' && g$N[V0R - 1] && g$N[V0R - 1][F8q]) {
            N5a.Close = g$N[V0R - +'1'][F8q].Close;
            N5a.High = N5a.Low = N5a.Open = N5a.Close;
            A7Q.p1T(81);
            N5a.Volume = A7Q.H9P(0, '0');
          }
          g$N[V0R][F8q] = N5a;
        }
      } else {
        T_t = 'g';
        T_t += 'a';
        T_t += 'p';
        f_X = g$N.splice(V0R, 1)[0];
        N5a = { DT: f_X.DT, Close: null, needed: !{} };
        for (O4v in g1j.series) {
          N4C = g1j.series[O4v].parameters.symbolObject.symbol;
          if (typeof f_X[N4C] != 'undefined') {
            N5a[N4C] = f_X[N4C];
            delete N5a.needed;
          }
        }
        if (w4V.cleanupGaps && w4V.cleanupGaps != T_t) {
          X56 = 'ga';
          X56 += 'p';
          delete N5a.needed;
          if (w4V.cleanupGaps != X56 && g$N[V0R - 1]) {
            N5a.Close = g$N[V0R - 1].Close;
            N5a.High = N5a.Low = N5a.Open = N5a.Close;
            N5a.Volume = 0;
          }
        }
        if (N5a.needed !== !1) {
          g$N.splice(V0R, 0, N5a);
          w4V.setDisplayDate(N5a);
        }
      }
    }
    if (e6J.fillGaps) {
      J8b();
    }
    if (!v3h.length) {
      return;
    }
    for (var M_4 = 0; M_4 < v3h.length; M_4++) {
      F_l = v3h[M_4];
      Q$k = F_l.DT;
      W79 = F_l.Date;
      if (Q$k && Object.prototype.toString.call(Q$k) != '[object Date]') {
        F_l.DT = Q$k = new Date(Q$k);
      }
      if (Q$k) {
        if (!W79 || W79.length != 17) {
          F_l.Date = h1e.yyyymmddhhmmssmmm(F_l.DT);
        }
      }
      if (!Q$k) {
        Q$k = F_l.DT = h1e.strToDateTime(W79);
      }
      if (!y0W(F_l.Close) && y0W(F_l.Value)) {
        F_l.Close = F_l.Value;
      }
      while (u74 >= +'0' && u74 < g$N.length) {
        T0t = g$N[u74].DT;
        if (!T0t) {
          T0t = h1e.strToDateTime(g$N[u74].Date);
        }
        if (T0t.getTime() <= Q$k.getTime()) {
          u50 = !![];
          h45 = 0;
          if (T0t.getTime() < Q$k.getTime()) {
            if (u74 < g$N.length - 1) {
              w0W = g$N[u74 + 1].DT || h1e.strToDateTime(g$N[u74 + 1].Date);
              if (w0W.getTime() <= Q$k.getTime()) {
                u74++;
                continue;
              }
            }
            A7Q.p1T(21);
            h45 = A7Q.M3e('1', 0);
          }
          if (e6J.deleteItems) {
            if (!h45) {
              L1i(u74, Q$k);
            }
            break;
          } else {
            p96 = 't';
            p96 += 'i';
            p96 += 'c';
            p96 += 'k';
            if (o5G.interval == p96 && e6J.firstLoop !== !!0) {
              h45 = +'1';
            }
            if (!h45) {
              X_6(u74, F_l);
            }
            if (y0W(F_l.Close)) {
              if (!y0W(F_l.Open)) {
                F_l.Open = F_l.Close;
              }
              c2C = Math.max(F_l.Open, F_l.Close);
              t$o = Math.min(F_l.Open, F_l.Close);
              if (!y0W(F_l.High) || F_l.High < c2C) {
                F_l.High = c2C;
              }
              if (!y0W(F_l.Low) || F_l.Low > t$o) {
                F_l.Low = t$o;
              }
            }
            if (F_l.Volume && !y0W(F_l.Volume)) {
              F_l.Volume = parseInt(F_l.Volume, 10);
            }
            u74 += h45;
            if (F8q) {
              r2Q = 'tic';
              r2Q += 'k';
              if (v3h.length - M_4 < 50) {
                this.updateCurrentMarketData(F_l, g1j, F8q, {
                  fromTrade: !0,
                  animationEntry: B5_,
                });
              }
              if (o5G.interval != r2Q || F_l.Close !== undefined) {
                if (h45) {
                  A7Q.d$R(26);
                  g$N.splice(u74, A7Q.M3e('0', 0), { DT: F_l.DT });
                  this.setDisplayDate(g$N[u74]);
                }
                g$N[u74][F8q] = F_l;
              }
            } else {
              if (v3h.length - M_4 < ('50' | 18)) {
                this.updateCurrentMarketData(F_l, g1j, null, {
                  fromTrade: !'',
                  animationEntry: B5_,
                });
              }
              if (o5G.interval != 'tick' || F_l.Close !== undefined) {
                g$N.splice(u74, h45 ? +'0' : '1' << 64, F_l);
                this.setDisplayDate(F_l);
              }
            }
          }
          break;
        }
        u74 += u50 ? 1 : -1;
      }
      if (u74 < +'0') {
        if (F8q) {
          this.updateCurrentMarketData(F_l, g1j, F8q, {
            fromTrade: !!{},
            animationEntry: B5_,
          });
          if (o5G.interval != 'tick' || F_l.Close !== undefined) {
            g$N.splice(+'0', +'0', { DT: F_l.DT });
            this.setDisplayDate(g$N[+'0']);
            g$N[0][F8q] = F_l;
          }
        } else {
          this.updateCurrentMarketData(F_l, g1j, null, {
            fromTrade: !0,
            animationEntry: B5_,
          });
          if (o5G.interval != 'tick' || F_l.Close !== undefined) {
            A7Q.p1T(20);
            g$N.splice(0, A7Q.M3e('0', 96), F_l);
            this.setDisplayDate(F_l);
          }
        }
        u50 = !'';
        u74 = 0;
      }
    }
    if (g$N.length) {
      this.masterData = g1j.masterData = g$N;
    }
    if (this.maxMasterDataSize) {
      g$N =
        g1j.masterData =
        this.masterData =
          g$N.slice(-this.maxMasterDataSize);
    }
    y28 = F8q ? this.getSeries({ symbol: F8q, chart: g1j }) : [g1j];
    for (var p8D = 0; p8D < y28.length; p8D++) {
      Z9E = y28[p8D];
      if (!Z9E.endPoints.begin || Z9E.endPoints.begin > v3h[0].DT) {
        Z9E.endPoints.begin = v3h['0' >> 64].DT;
      }
      if (!Z9E.endPoints.end || Z9E.endPoints.end < v3h[v3h.length - 1].DT) {
        Z9E.endPoints.end = v3h[v3h.length - ('1' - 0)].DT;
      }
      b4l = (Z9E.parameters && Z9E.parameters.field) || g1j.defaultPlotField;
      B_l = this.getFirstLastDataRecord(v3h, b4l, !![]);
      if (B_l && (!Z9E.lastQuote || Z9E.lastQuote.DT <= B_l.DT)) {
        Z9E.lastQuote = B_l;
      }
      if (F8q && e6J.deleteItems) {
        Z9E.lastQuote = this.getFirstLastDataRecord(g$N, F8q, !'')[F8q];
      }
    }
    for (var g7Z in this.plugins) {
      D0S = this.plugins[g7Z];
      if (D0S.display) {
        if (D0S.appendMasterData) {
          D0S.appendMasterData(this, v3h, g1j);
        }
      }
    }
    if (!this.masterData || !this.masterData.length) {
      this.masterData = g$N;
    }
    if (!e6J.noCreateDataSet) {
      A7Q.p1T(81);
      H3Z = -A7Q.M3e(1, '1708868495');
      A_Y = 2142886238;
      B90 = 2;
      for (
        var E_B = 1;
        A7Q.e_M(E_B.toString(), E_B.toString().length, 67443) !== H3Z;
        E_B++
      ) {
        c4o = this.streamParameters;
        B90 += 2;
      }
      if (A7Q.e_M(B90.toString(), B90.toString().length, 99190) !== A_Y) {
        c4o = this.streamParameters;
      }
      if (++c4o.count > c4o.maxTicks || e6J.bypassGovernor) {
        clearTimeout(c4o.timeout);
        x_g();
      } else {
        if (c4o.timeout == -1) {
          c4o.timeout = setTimeout(x_g, c4o.maxWait);
        }
      }
    }
    this.runAppend('appendMasterData', arguments);
    this.runAppend('updateChartData', arguments);
    function x_g() {
      w4V.createDataSet(null, null, e6J);
      w4V.draw({ animationEntry: B5_ });
      w4V.updateChartAccessories();
      w4V.streamParameters.count = 0;
      w4V.streamParameters.timeout = -+'1';
    }
  };
  h1e.ChartEngine.prototype.updateCurrentMarketData = function (
    s3z,
    o_z,
    u9Y,
    T8H = {}
  ) {
    var H$L, B8Z, d8z, c7p, I0F, y1k, e2L, X0W, c3O, M_r, W7l, A96, J9G, a2S;
    H$L = 'A';
    A7Q.t3P();
    H$L += 'sk';
    H$L += 'L2';
    B8Z = 'BidL';
    B8Z += '2';
    d8z = 'A';
    d8z += 's';
    d8z += 'k';
    c7p = 'up';
    c7p += 'dateCurrentMa';
    c7p += 'rketData';
    I0F = 't';
    I0F += 'ic';
    I0F += 'k';
    if (!s3z || !s3z.DT) {
      return;
    }
    if (!o_z) {
      o_z = this.chart;
    }
    var { fromTrade: H4A } = T8H;
    y1k = s3z.DT;
    if (!H4A && this.layout.interval != I0F && o_z.market) {
      if (o_z.market.market_def) {
        if (!o_z.market.isMarketDate(s3z.DT)) {
          return;
        }
        if (
          !h1e.ChartEngine.isDailyInterval(this.layout.interval) &&
          o_z.market.getSession(s3z.DT) === null
        ) {
          return;
        }
      }
      e2L = {
        begin: s3z.DT,
        interval: this.layout.interval,
        periodicity: this.layout.periodicity,
        timeUnit: this.layout.timeUnit,
      };
      X0W = new h1e.Market(o_z.market.market_def);
      if (this.extendedHours && this.extendedHours.filter) {
        X0W.enableAllAvailableSessions();
      }
      c3O = X0W.newIterator(e2L);
      c3O.next();
      s3z.DT = c3O.previous();
    }
    if (this.runPrepend(c7p, arguments)) {
      return;
    }
    M_r = H4A ? 'DT' : 'Timestamp';
    var { currentMarketData: K0n } = o_z;
    W7l = !{};
    if (u9Y) {
      if (!K0n[u9Y]) {
        K0n[u9Y] = {};
      }
      K0n = K0n[u9Y];
    }
    ['Last', 'Bid', d8z].forEach(function (H86) {
      var K5e;
      if (s3z[H86] && typeof s3z[H86] == 'number') {
        K5e = K0n[H86];
        if (!K5e || !K5e[M_r] || K5e[M_r] <= (H4A ? s3z.DT : y1k)) {
          K0n[H86] = {
            DT: s3z.DT,
            Price: s3z[H86],
            Size: s3z[H86 + 'Size'],
            Timestamp: y1k,
          };
          W7l = !![];
        }
      }
    });
    [B8Z, H$L].forEach(function (O6U) {
      var W$A;
      A7Q.Y3Y();
      if (s3z[O6U] && s3z[O6U] instanceof Array) {
        W$A = K0n[O6U];
        if (!W$A || !W$A[M_r] || W$A[M_r] <= (H4A ? s3z.DT : y1k)) {
          K0n[O6U] = { DT: s3z.DT, Price_Size: s3z[O6U], Timestamp: y1k };
          W7l = !!{};
        }
      }
    });
    if (
      s3z.Close &&
      (!K0n.Last || K0n.Last.Timestamp <= (s3z.LastTime || y1k))
    ) {
      A96 = s3z.Close;
      J9G = T8H && T8H.finalClose;
      if (J9G || J9G === 0) {
        A96 = J9G;
      }
      K0n.Last = {
        DT: s3z.DT,
        Price: A96,
        Size:
          s3z.LastSize === undefined && this.layout.interval == 'tick'
            ? s3z.Volume
            : s3z.LastSize,
        Timestamp: s3z.LastTime || y1k,
      };
      W7l = !!'1';
    }
    if (W7l && !T8H.animationEntry) {
      a2S = new Date();
      clearTimeout(K0n.assignLastTouchAsync);
      K0n.assignLastTouchAsync = setTimeout(() => {
        K0n.touched = a2S;
      });
    }
    if (!H4A) {
      delete s3z.Last;
    }
    this.runAppend('updateCurrentMarketData', arguments);
  };
  h1e.ChartEngine.prototype.clearCurrentMarketData = function (E_F, a_z) {
    var I0n, m$M, E_M;
    if (this.runPrepend('clearCurrentMarketData', arguments)) {
      return;
    }
    m$M = [];
    A7Q.Y3Y();
    if (!E_F) {
      for (I0n in this.charts) {
        m$M.push(this.charts[I0n]);
      }
    } else {
      m$M.push(E_F);
    }
    for (I0n = 0; I0n < m$M.length; I0n++) {
      E_M = m$M[I0n].currentMarketData;
      if (a_z) {
        delete E_M[a_z];
      } else {
        for (var h9R in E_M) {
          E_M[h9R] = undefined;
        }
      }
    }
    this.runAppend('clearCurrentMarketData', arguments);
  };
};
E0 = (Z_1) => {
  var A5u = v7ZLk;
  var v1B;
  v1B = Z_1.CIQ;
  v1B.ChartEngine.prototype.changeOccurred = function (h6_) {
    var C3h, v0I, S27, I90, N$Q, X96, j84, n7q;
    C3h = 'prefe';
    C3h += 'rences';
    v0I = 'v';
    v0I += 'ector';
    S27 = 'layou';
    S27 += 't';
    I90 = {
      stx: this,
      symbol: this.chart.symbol,
      symbolObject: this.chart.symbolObject,
      layout: this.layout,
      drawings: this.drawingObjects,
    };
    if (h6_ == 'theme') {
      this.dispatch('theme', I90);
    }
    // анонимная доработка. Последить не ломает ли что-нибудь
    // if (this.currentlyImporting) {
    //   return;
    // }
    if (h6_ == S27) {
      N$Q = -205006972;
      X96 = 1166191914;
      j84 = 2;
      for (
        var W6y = 1;
        A5u.q78(W6y.toString(), W6y.toString().length, 2315) !== N$Q;
        W6y++
      ) {
        this.dispatch('', I90);
        j84 += +'2';
      }
      if (A5u.q78(j84.toString(), j84.toString().length, 99251) !== X96) {
        n7q = 'la';
        n7q += 'yout';
        this.dispatch(n7q, I90);
      }
    } else if (h6_ == v0I) {
      this.dispatch('drawing', I90);
    } else if (h6_ == C3h) {
      this.dispatch('preferences', I90);
    }
  };
  v1B.ChartEngine.prototype.startAsyncAction = function () {
    if (!this.pendingAsyncs) {
      this.pendingAsyncs = [];
    }
    this.pendingAsyncs.push(!!{});
  };
  v1B.ChartEngine.prototype.registerChartDrawnCallback = function (Z3_) {
    if (!this.asyncCallbacks) {
      this.asyncCallbacks = [];
    }
    this.asyncCallbacks.push(Z3_);
    return { fc: Z3_ };
  };
  v1B.ChartEngine.prototype.unregisterChartDrawnCallback = function (o5b) {
    for (var e8J = 0; e8J < this.asyncCallbacks.length; e8J++) {
      if (this.asyncCallbacks[e8J] == o5b.fc) {
        this.asyncCallbacks.splice(e8J, 1);
        return;
      }
    }
  };
  v1B.ChartEngine.prototype.makeAsyncCallbacks = function () {
    A5u.Y3Y();
    if (!this.asyncCallbacks) {
      return;
    }
    if (!this.pendingAsyncs || !this.pendingAsyncs.length) {
      for (var l0o = 0; l0o < this.asyncCallbacks.length; l0o++) {
        this.asyncCallbacks[l0o]();
      }
    }
  };
  v1B.ChartEngine.prototype.completeAsyncAction = function () {
    this.pendingAsyncs.pop();
    A5u.t3P();
    this.makeAsyncCallbacks();
  };
  v1B.ChartEngine.prototype.addDomEventListener = function (
    B9s,
    i1e,
    a2H,
    K2_
  ) {
    B9s.addEventListener(i1e, a2H, K2_);
    this.eventListeners.push({
      element: B9s,
      event: i1e,
      function: a2H,
      options: K2_,
    });
  };
  v1B.ChartEngine.prototype.addEventListener = function (h1y, B0k) {
    A5u.t3P();
    var w1D;
    if (
      h1y ===
      ((892, 6150) > (3762, 2690)
        ? (7090, 6780) < 7.14
          ? (268.82, !{})
          : '7650' * 1 < 2652
          ? (960.03, +'327.90')
          : '*'
        : (6.27e3, !{}))
    ) {
      for (var i_p in this.callbackListeners) {
        this.callbackListeners[i_p].push(B0k);
      }
    } else if (h1y instanceof Array) {
      for (var H5M = 0; H5M < h1y.length; H5M++) {
        this.callbackListeners[h1y[H5M]].push(B0k);
      }
    } else {
      w1D = this.callbackListeners[h1y];
      if (!w1D) {
        throw new Error('Attempted to add an invalid listener.');
      }
      w1D.push(B0k);
    }
    return { type: h1y, cb: B0k };
  };
  v1B.ChartEngine.prototype.removeEventListener = function (Y7r, E2T) {
    var Y_e, i3L, d18;
    if (!Y7r || typeof Y7r != 'object') {
      Y7r = { type: Y7r, cb: E2T };
    }
    Y_e = function (F3Y, a0D) {
      for (var D9X = 0; D9X < F3Y.length; D9X++) {
        if (F3Y[D9X] === a0D) {
          F3Y.splice(D9X, 1);
          return;
        }
      }
    };
    A5u.t3P();
    i3L = this.callbackListeners;
    if (
      Y7r.type ===
      ((4310, 100.2) != (+'5000', 918.95)
        ? '*'
        : 4430 === 403.64
        ? (!{}, 380.45)
        : (5.12e3, 'F'))
    ) {
      for (var v1t in i3L) {
        Y_e(i3L[v1t], Y7r.cb);
      }
      return;
    }
    if (!i3L[Y7r.type]) {
      d18 = 'Attempted to remove an invalid l';
      d18 += 'istener.';
      throw new Error(d18);
    }
    Y_e(i3L[Y7r.type], Y7r.cb);
  };
  A5u.t3P();
  v1B.ChartEngine.prototype.dispatch = function (A89, X4S) {
    var I4i;
    A5u.t3P();
    I4i = this.callbackListeners[A89];
    if (I4i) {
      for (var U0l = 0; U0l < I4i.length; U0l++) {
        if (I4i[U0l].call(this, X4S) === !!1) {
          return !![];
        }
      }
    }
    I4i =
      this.callbackListeners[
        ('3710' | 10) === (6827, 7531)
          ? ('155' ^ 0, 552.93) > 433.29
            ? 'S'
            : (0xe75, +'2.68e+3')
          : '*'
      ];
    if (I4i) {
      for (var r_C = 0; r_C < I4i.length; r_C++) {
        if (I4i[r_C].call(this, X4S) === !!1) {
          return !!{};
        }
      }
    }
    return !!0;
  };
  v1B.ChartEngine.prototype.updateListeners = function (k1s) {
    var W1G;
    for (var k2g in this.plugins) {
      W1G = this.plugins[k2g];
      if (W1G.display && W1G.listener) {
        W1G.listener(this, k1s);
      }
    }
  };
  v1B.ChartEngine.registerGlobalEvent = function (z$T, K_T, g0R, H6d) {
    if (!Array.isArray(z$T)) {
      z$T = [z$T];
    }
    z$T.forEach((Y7D) => {
      v1B.ChartEngine.globalEventHandlers.push({
        event: Y7D,
        listener: K_T,
        options: g0R || !!'',
        isWindow: H6d,
        applied: [],
      });
    });
    v1B.ChartEngine.attachGlobalEventHandlers();
  };
  v1B.ChartEngine.attachGlobalEventHandlers = function () {
    var S_e;
    A5u.t3P();
    S_e = v1B.ChartEngine.registeredContainers;
    S_e.forEach((a8V) => {
      var { ownerDocument: k27 } = a8V;
      v1B.ChartEngine.globalEventHandlers.forEach(function (T1w) {
        var U1h;
        var {
          event: m6i,
          listener: s3E,
          options: b1W,
          isWindow: n$6,
          applied: a$d,
        } = T1w;
        A5u.Y3Y();
        if (a$d.indexOf(k27) === -1) {
          U1h = n$6 ? k27.defaultView : k27;
          U1h.addEventListener(m6i, s3E, b1W);
          a$d.push(k27);
        }
      });
    });
  };
};
a_ = (F8d) => {
  var C72;
  C72 = F8d.CIQ;
  C72.ChartEngine.prototype.prepend = function (F50, S5J) {
    var f8V, A1A;
    v7ZLk.p1T(18);
    f8V = v7ZLk.M3e(F50, 'prepend');
    if (this instanceof C72.ChartEngine) {
      A1A = this.hasOwnProperty(f8V) ? this[f8V] : [];
      this[f8V] = [S5J].concat(A1A);
    } else {
      A1A = C72.ChartEngine.prototype[f8V] || [];
      C72.ChartEngine.prototype[f8V] = [S5J].concat(A1A);
    }
    return { method: f8V, func: S5J };
  };
  C72.ChartEngine.prototype.append = function (i7S, E_u) {
    var x6k, Y_4;
    v7ZLk.d$R(18);
    x6k = v7ZLk.M3e(i7S, 'append');
    if (this instanceof C72.ChartEngine) {
      Y_4 = this.hasOwnProperty(x6k) ? this[x6k] : [];
      this[x6k] = Y_4.concat(E_u);
    } else {
      Y_4 = C72.ChartEngine.prototype[x6k] || [];
      C72.ChartEngine.prototype[x6k] = Y_4.concat(E_u);
    }
    v7ZLk.t3P();
    return { method: x6k, func: E_u };
  };
  C72.ChartEngine.prototype.runPrepend = function (B1W, P29, n7a) {
    var J73, P7r, M3j;
    v7ZLk.Y3Y();
    v7ZLk.d$R(18);
    J73 = v7ZLk.H9P(B1W, 'prepend');
    P7r = this.hasOwnProperty(J73) ? this[J73] : [];
    P7r = P7r.concat(C72.ChartEngine.prototype[J73] || []);
    if (!P7r.length) {
      return !!0;
    }
    if (!n7a) {
      n7a = this;
    }
    for (var a9s = 0; a9s < P7r.length; a9s++) {
      M3j = P7r[a9s].apply(n7a, P29);
      if (M3j) {
        return M3j;
      }
    }
    return !{};
  };
  C72.ChartEngine.prototype.runAppend = function (h3M, o32, u$F) {
    var y3P, t$d, T8i, z54;
    y3P = 'a';
    y3P += 'ppe';
    y3P += 'n';
    y3P += 'd';
    v7ZLk.d$R(18);
    t$d = v7ZLk.M3e(h3M, y3P);
    T8i = this.hasOwnProperty(t$d) ? this[t$d] : [];
    T8i = T8i.concat(C72.ChartEngine.prototype[t$d] || []);
    if (!T8i.length) {
      return !1;
    }
    if (!u$F) {
      u$F = this;
    }
    for (var D_7 = '0' >> 64; D_7 < T8i.length; D_7++) {
      z54 = T8i[D_7].apply(u$F, o32);
      if (z54) {
        return z54;
      }
    }
    return !!'';
  };
  C72.ChartEngine.prototype.removeInjection = function (c0N) {
    var h9D, c8g;
    h9D = c0N.method;
    if (this instanceof C72.ChartEngine) {
      if (!this[h9D]) {
        return;
      }
      for (c8g = '0' ^ 0; c8g < this[h9D].length; c8g++) {
        if (this[h9D][c8g] == c0N.func) {
          this[h9D].splice(c8g, 1);
          return;
        }
      }
    } else {
      if (!C72.ChartEngine.prototype[h9D]) {
        return;
      }
      for (c8g = 0; c8g < C72.ChartEngine.prototype[h9D].length; c8g++) {
        if (C72.ChartEngine.prototype[h9D][c8g] == c0N.func) {
          C72.ChartEngine.prototype[h9D].splice(c8g, +'1');
          return;
        }
      }
    }
  };
  C72.ChartEngine.prototype.remove = function (e8R) {
    var g06 = v7ZLk;
    var y7U, T9S;
    if (this instanceof C72.ChartEngine) {
      y7U = 'pr';
      y7U += 'epend';
      g06.p1T(18);
      delete this[g06.M3e(e8R, 'append')];
      g06.d$R(18);
      delete this[g06.H9P(e8R, y7U)];
    } else {
      T9S = 'prep';
      T9S += 'en';
      T9S += 'd';
      g06.p1T(18);
      delete C72.ChartEngine.prototype[g06.M3e(e8R, 'append')];
      g06.p1T(18);
      delete C72.ChartEngine.prototype[g06.H9P(e8R, T9S)];
    }
  };
};
S3 = (c6w) => {
  var v8e = v7ZLk;
  var K23, J2$, O__;
  K23 = 'u';
  K23 += 'nlo';
  K23 += 'a';
  K23 += 'd';
  J2$ = c6w.CIQ;
  O__ = c6w.timezoneJS;
  J2$.ChartEngine.prototype.convertToDataZone = function (x4i) {
    v8e.t3P();
    var N3N;
    if ((x4i || x4i === 0) && this.dataZone) {
      N3N = J2$.convertTimeZone(x4i, null, this.dataZone);
      x4i = new Date(
        N3N.getFullYear(),
        N3N.getMonth(),
        N3N.getDate(),
        N3N.getHours(),
        N3N.getMinutes(),
        N3N.getSeconds(),
        N3N.getMilliseconds()
      );
    }
    return x4i;
  };
  J2$.ChartEngine.prototype.debug = function () {};
  J2$.ChartEngine.prototype.fps = function (Q4u, S0U) {
    var w3x, n9V, O1z;
    Q4u = Q4u || 5;
    w3x = new Date().getTime();
    function y6h() {
      var K2X, i2O;
      v8e.p1T(0);
      var e$K = v8e.H9P(7000, 6000);
      K2X = (new Date().getTime() - w3x) / e$K;
      if (K2X > Q4u) {
        v8e.p1T(41);
        i2O = v8e.H9P(K2X, n9V);
        v8e.d$R(18);
        console.log(v8e.H9P(i2O, 'FPS='));
        if (S0U) {
          S0U(i2O);
        }
        return;
      }
      O1z.draw();
      v8e.t3P();
      n9V++;
      if (J2$.ChartEngine.useAnimation) {
        requestAnimationFrame(y6h);
      } else {
        setTimeout(y6h, 0);
      }
    }
    n9V = 0;
    O1z = this;
    v8e.d$R(28);
    console.log(v8e.M3e(Q4u, 'Running fps() for ', ' seconds'));
    y6h();
  };
  J2$.ChartEngine.htmlControls = {
    mSticky:
      '<div class="stx_sticky"> <span class="mStickyInterior"></span></div>',
    iconsTemplate:
      '<div class="stx-panel-control"><div class="stx-panel-title"></div><div class="stx-panel-legend"></div></div>',
  };
  J2$.ChartEngine.prototype.setChartType = function (Y5I) {
    var O7d, B1r, h2I;
    O7d = this.layout;
    B1r = this.chart;
    if (O7d.aggregationType != 'ohlc') {
      h2I = 'oh';
      h2I += 'l';
      h2I += 'c';
      O7d.aggregationType = h2I;
      if (B1r.canvas) {
        this.createDataSet();
      }
    }
    O7d.chartType = Y5I;
    this.setMainSeriesRenderer();
    if (this.mainSeriesRenderer.isAggregation && this.setAggregationType) {
      return this.setAggregationType(Y5I);
    }
    v8e.t3P();
    B1r.defaultChartStyleConfig = { type: Y5I };
    if (this.displayInitialized) {
      this.draw();
    }
    this.changeOccurred('layout');
  };
  J2$.ChartEngine.prototype.setChartScale = function (Z9L) {
    var e9h;
    if (!Z9L) {
      Z9L = 'linear';
    }
    e9h = { percent: !!{}, relative: !!{} };
    this.layout.chartScale = Z9L;
    if (this.chart.canvas) {
      this.draw();
    }
    this.changeOccurred('layout');
  };
  J2$.ChartEngine.prototype.checkLogScale = function () {
    v8e.Y3Y();
    var z2T, r54, A2U, X5D, g2d, v9P, k$w, k9f;
    z2T = 'check';
    z2T += 'LogScale';
    if (this.runPrepend('checkLogScale', arguments)) {
      return;
    }
    if (this.layout.chartScale !== 'log') {
      return ![];
    }
    r54 = !!0;
    if (this.chart.yAxis.lowValue <= 0) {
      this.setChartScale('linear');
      A2U = -2005892888;
      X5D = 256320141;
      g2d = +'2';
      for (
        var B0l = 1;
        v8e.q78(B0l.toString(), B0l.toString().length, 52317) !== A2U;
        B0l++
      ) {
        v9P = 'logdeact';
        v9P += 'i';
        v9P += 'va';
        v9P += 'ted';
        this.dispatch(v9P, 'logdeactivated');
        r54 = ![];
        g2d += 2;
      }
      if (v8e.e_M(g2d.toString(), g2d.toString().length, 45755) !== X5D) {
        k$w = 'log';
        k$w += 'deactivated';
        k9f = 'lo';
        k9f += 'gdeactiv';
        k9f += 'a';
        k9f += 'ted';
        this.dispatch(k9f, k$w);
        r54 = !'';
      }
      this.dispatch('notification', 'logdeactivated');
      r54 = !!1;
    }
    this.runAppend(z2T, arguments);
    return r54;
  };
  J2$.ChartEngine.prototype.setAdjusted = function (L4s) {
    v8e.Y3Y();
    this.layout.adj = L4s;
    if (this.chart.canvas) {
      this.createDataSet();
      this.draw();
    }
    this.changeOccurred('layout');
  };
  J2$.ChartEngine.prototype.padOutPrice = function (o5R, q__) {
    v8e.t3P();
    var m1O, m$c, z8k, q_o, P1K, K2R, F7W, u1z;
    m1O = 'n';
    m1O += 'umber';
    if (o5R !== 0 && (!o5R || typeof o5R != m1O)) {
      return '';
    }
    if (!q__ && q__ !== 0) {
      q__ = o5R;
    }
    v8e.d$R(18);
    m$c = v8e.H9P(q__, '');
    z8k = 0;
    if (m$c.indexOf('.') > -1) {
      v8e.p1T(122);
      var r4d = v8e.H9P(6, 15, 1294, 1, 5167);
      v8e.d$R(0);
      var p5C = v8e.H9P(9920, 20);
      v8e.d$R(27);
      var T$U = v8e.M3e(84150, 11, 158928, 2);
      v8e.d$R(14);
      var q8Z = v8e.H9P(2, 340, 14, 6460);
      v8e.p1T(11);
      var r8H = v8e.H9P(263, 4, 9, 11, 3758);
      v8e.d$R(18);
      var X91 = v8e.H9P(17, 9336);
      v8e.d$R(27);
      var N4U = v8e.M3e(2215, 16, 19788, 10);
      v8e.p1T(104);
      var l$T = v8e.M3e(89921, 9990, 10, 14);
      v8e.p1T(9);
      var M7b = v8e.M3e(15, 4, 8407, 6324);
      v8e.p1T(5);
      var c5v = v8e.H9P(9, 1, 7, 13, 10);
      z8k =
        m$c.substring(
          m$c.indexOf(
            (r4d, +'4900') > (p5C, T$U)
              ? q8Z
              : (54.19, r8H) === (X91, N4U)
              ? ('g', l$T)
              : (894.8, +'809.11') == M7b
              ? !!''
              : '.'
          )
        ).length - c5v;
    }
    if (q__ >= 1000) {
      z8k = Math.max(z8k, 0);
    } else if (q__ < 2) {
      v8e.p1T(26);
      z8k = Math.max(z8k, v8e.M3e('4', 32));
    } else {
      v8e.d$R(0);
      z8k = Math.max(z8k, v8e.H9P('2', 0));
    }
    q_o = this.internationalizer;
    if (q_o) {
      P1K = q_o.priceFormatters.length;
      K2R = +'643016605';
      F7W = 968974160;
      u1z = 2;
      for (
        var g6$ = 1;
        v8e.q78(g6$.toString(), g6$.toString().length, 5482) !== K2R;
        g6$++
      ) {
        if (z8k < P1K) {
          v8e.p1T(25);
          z8k = v8e.M3e(6, P1K);
        }
        u1z += 2;
      }
      if (v8e.e_M(u1z.toString(), u1z.toString().length, 37425) !== F7W) {
        if (z8k >= P1K) {
          v8e.p1T(0);
          z8k = v8e.M3e(P1K, 1);
        }
      }
      o5R = q_o.priceFormatters[z8k].format(o5R);
    } else {
      o5R = o5R.toFixed(z8k);
    }
    return o5R;
  };
  J2$.ChartEngine.prototype.formatPrice = function (c2U, W2t) {
    v8e.Y3Y();
    var s1Z, Q3w, q$F;
    if (c2U !== '0' * 1 && (!c2U || typeof c2U == 'undefined')) {
      return '';
    }
    if (!W2t) {
      W2t = this.currentPanel;
    }
    if (!W2t) {
      W2t = this.chart.panel;
    }
    if (!W2t) {
      return c2U.toString();
    }
    s1Z = W2t.decimalPlaces;
    if (!s1Z && s1Z !== 0) {
      s1Z = W2t.chart.decimalPlaces;
    }
    if (!s1Z && s1Z !== 0) {
      return c2U.toString();
    }
    Q3w = this.internationalizer;
    if (Q3w) {
      q$F = Q3w.priceFormatters.length;
      if (s1Z >= q$F) {
        v8e.p1T(0);
        s1Z = v8e.H9P(q$F, 1);
      }
      c2U = Q3w.priceFormatters[s1Z].format(c2U);
    } else {
      c2U = c2U.toFixed(s1Z);
    }
    return c2U;
  };
  J2$.ChartEngine.prototype.determineMinMax = function (
    c0Z,
    x2f,
    y5C,
    E4q,
    l6t,
    B27,
    a54,
    R01,
    s_a
  ) {
    var M9z, l6j, b$h, s$R, K1v, a$Z, L4p, C4T, T8X, V$M, e$a;
    v8e.p1T(65);
    var M97 = v8e.M3e(20, 5, 99);
    M9z = Number.MAX_VALUE * M97;
    l6j = Number.MAX_VALUE;
    b$h = !!0;
    s$R = c0Z.length;
    if (!s_a) {
      s_a = [];
    }
    K1v = [];
    if (l6t) {
      s$R = l6t;
    }
    for (var L0i = '0' | 0; L0i <= s$R + 1; L0i++) {
      if (x2f.length) {
        if (L0i == s$R) {
          a$Z = this.getPreviousBar(this.chart, x2f[0], 0);
        } else if (L0i == s$R + 1) {
          a$Z = this.getNextBar(this.chart, x2f[+'0'], s$R - 1);
        } else {
          a$Z = c0Z[L0i];
        }
      }
      if (!a$Z) continue;
      if (!E4q) {
        if (a$Z.transform) {
          b$h = !![];
          a$Z = a$Z.transform;
        } else if (b$h) continue;
      }
      v8e.d$R(20);
      L4p = v8e.M3e('0', 64);
      for (var s96 = 0; s96 < x2f.length; s96++) {
        C4T = J2$.existsInObjectChain(a$Z, x2f[s96]);
        if (!C4T) continue;
        T8X = C4T.obj[C4T.member];
        if (typeof T8X == 'number') {
          T8X = [T8X];
        }
        for (var s0B = 0; s0B < T8X.length; s0B++) {
          V$M = T8X[s0B];
          if (B27 && V$M instanceof Array) {
            v8e.p1T(25);
            V$M = V$M[v8e.M3e(1, '0')];
          }
          if (V$M || V$M === 0) {
            if (
              y5C === !![] ||
              (y5C instanceof Array && y5C.indexOf(x2f[s96]) > -1)
            ) {
              L4p += V$M;
              if (L4p > M9z) {
                M9z = L4p;
              }
              if (L4p < l6j) {
                l6j = L4p;
              }
            } else {
              if (V$M > M9z) {
                M9z = V$M;
              }
              if (V$M < l6j) {
                l6j = V$M;
              }
              K1v.push({ value: V$M, quote: a$Z });
            }
          }
        }
      }
      if (
        y5C === !!{} ||
        (y5C instanceof Array && y5C.indexOf(x2f[s96]) > -1)
      ) {
        K1v.push({ value: L4p, quote: a$Z });
      }
    }
    e$a = [l6j, M9z];
    v8e.Y3Y();
    s_a.forEach(function (s2O) {
      v8e.t3P();
      e$a = s2O(K1v, a54, R01) || e$a;
    });
    if (e$a[1] == Number.MAX_VALUE * -1) {
      v8e.d$R(26);
      e$a[v8e.M3e('1', 0)] = +'0';
    }
    if (e$a[+'0'] == Number.MAX_VALUE) {
      e$a[0] = 0;
    }
    return e$a;
  };
  J2$.ChartEngine.prototype.initializeDisplay = function (S5i) {
    var R$J, s9C, z2V, G6X, S1I, b8N, q3u, V9X, Q45, T6z, c3x, R$t, P7j, z_d;
    if (this.runPrepend('initializeDisplay', arguments)) {
      return;
    }
    R$J = [];
    s9C = [];
    z2V = !!'';
    G6X = this;
    S1I = ['Close', 'Open', 'High', 'Low'];
    b8N = [S5i.defaultPlotField || 'Close'];
    var { mainSeriesRenderer: K3h } = this;
    v8e.Y3Y();
    var { dataSegment: B96, seriesRenderers: d0k } = S5i;
    V9X = null;
    Q45 = Math.floor((S5i.width - this.micropixels) / this.layout.candleWidth);
    if (S5i.scroll > S5i.maxTicks && S5i.maxTicks > Q45 + 1) {
      v8e.d$R(0);
      var H9_ = v8e.M3e(12, 11);
      V9X = B96.length - H9_;
    }
    T6z = [];
    for (var y06 in this.panels) {
      c3x = this.panels[y06];
      T6z = c3x.yaxisLHS.concat(c3x.yaxisRHS);
      for (var S14 = 0; S14 < T6z.length; S14++) {
        R$t = T6z[S14];
        R$J = [];
        s9C = [];
        P7j = S5i.transformFunc && R$t == S5i.panel.yAxis;
        z4j(R$t, c3x);
        if (
          !this.currentlyImporting &&
          !R$t.renderers.length &&
          !R$t.studies.length
        ) {
          this.deleteYAxisIfUnused(c3x, R$t);
          continue;
        }
        if (K3h && K3h.determineMax) {
          q3u = K3h.determineMax(B96, R$J, s9C, !P7j, V9X, z2V, c3x, R$t);
        } else {
          q3u = this.determineMinMax(B96, R$J, s9C, !P7j, V9X, z2V, c3x, R$t);
        }
        if (this.baselineHelper) {
          q3u = this.setBaselineMinMax(q3u, R$t);
        }
        R$t.lowValue = q3u[0];
        R$t.highValue = q3u[1];
        if (R$t == S5i.panel.yAxis) {
          S5i.lowValue = R$t.lowValue;
          S5i.highValue = R$t.highValue;
        }
      }
    }
    z_d = S5i.state.aggregation;
    function z4j(i5R, Z7N) {
      var p4q, h0h, G8U, H9z, z4U, q9d, B1U, z_T, T0X, J1z;
      p4q = ![];
      h0h = G6X.layout && G6X.layout.studies && G6X.layout.studies[i5R.name];
      if (h0h && (!Z7N || Z7N.name == h0h.panel)) {
        for (var c_r in h0h.outputMap) {
          R$J.push(c_r);
          if (h0h.study) {
            if (h0h.study.renderer) {
              R$J = R$J.concat(J2$.createObjectChainNames(c_r, S1I));
            } else if (!h0h.study.seriesFN) {
              R$J = R$J.concat(J2$.createObjectChainNames(c_r, b8N));
            }
          }
        }
        for (var V80 = 0; V80 <= 2; V80++) {
          R$J.push(h0h.name + '_hist' + (V80 ? V80 : ''));
        }
        p4q = !0;
      }
      if (!Z7N) {
        return;
      }
      i5R.studies = [];
      i5R.renderers = [];
      if (p4q) {
        i5R.studies.push(i5R.name);
      }
      v8e.Y3Y();
      for (var K0I in d0k) {
        G8U = d0k[K0I];
        H9z = G8U.params;
        z4U = H9z.panel;
        if ((H9z.yAxis || !G6X.panels[z4U] || G6X.panels[z4U].yAxis) != i5R)
          continue;
        if (z4U != Z7N.name) continue;
        q9d = G8U.highLowBars ? S1I : b8N;
        z2V = G8U.bounded;
        for (var o38 = 0; o38 < G8U.seriesParams.length; o38++) {
          B1U = G8U.seriesParams[o38];
          if (B1U.hidden) continue;
          z_T = void 0;
          if (B1U.subField) {
            z_T = J2$.createObjectChainNames(B1U.symbol, [B1U.subField]).concat(
              B1U.symbol
            );
          } else if (B1U.symbol) {
            z_T = J2$.createObjectChainNames(B1U.symbol, q9d).concat(
              B1U.symbol
            );
          } else if (B1U.field) {
            z_T = B1U.field;
          } else if (i5R == S5i.panel.yAxis) {
            z_T = q9d;
          }
          if (z_T) {
            R$J = R$J.concat(z_T);
          }
          if (G8U.useSum) {
            s9C = s9C.concat(z_T);
          }
        }
        i5R.renderers.push(K0I);
      }
      for (var b6F in G6X.overlays) {
        T0X = G6X.overlays[b6F];
        if (T0X.panel != Z7N.name) continue;
        if (T0X.name == i5R.name) continue;
        J1z = T0X.getYAxis(G6X);
        if (J1z != i5R) continue;
        i5R.studies.push(T0X.name);
        if (S5i.includeOverlaysInMinMax) {
          z4j({ name: T0X.name });
        }
      }
    }
    if (z_d && z_d.box) {
      v8e.p1T(123);
      var N$P = v8e.M3e(13, 4, 1, 9);
      S5i.lowValue -= z_d.box / N$P;
      v8e.d$R(61);
      var H3p = v8e.H9P(13, 0, 11);
      S5i.highValue += z_d.box / H3p;
    }
    this.runAppend('initializeDisplay', arguments);
  };
  J2$.ChartEngine.prototype.setMarket = function (J6f, j_b) {
    if (!J2$.Market) {
      return;
    }
    if (!j_b) {
      j_b = this.chart;
    }
    v8e.t3P();
    j_b.market = new J2$.Market(J6f);
    for (var r_9 in this.layout.marketSessions) {
      j_b.market.disableSession(r_9, this.layout.marketSessions[r_9]);
    }
  };
  J2$.ChartEngine.prototype.setMarketFactory = function (R30) {
    v8e.Y3Y();
    this.marketFactory = R30;
  };
  J2$.ChartEngine.prototype.setResizeTimer = function (M4a) {
    var b52;
    v8e.t3P();
    b52 = () => {
      var { canvas: W5$, container: o9Y } = this.chart;
      if (!W5$ || J2$.isAndroid) {
        return;
      }
      v8e.Y3Y();
      if (
        W5$.height !== Math.floor(this.devicePixelRatio * o9Y.clientHeight) ||
        W5$.width !== Math.floor(this.devicePixelRatio * o9Y.clientWidth)
      ) {
        this.resizeChart();
      }
    };
    this.resizeHandle = J2$.resizeObserver(
      this.chart.container,
      b52,
      this.resizeHandle,
      M4a
    );
  };
  J2$.ChartEngine.prototype.getRenderedItems = function () {
    var d0P, d91, H9s, G2_, S7d, x1S, A3P, E$I, U4V;
    d0P = 'C';
    d0P += 'lo';
    d0P += 'se';
    d91 = this.chart;
    H9s = this.currentPanel;
    v8e.Y3Y();
    if (!H9s) {
      return;
    }
    G2_ = ['Open', 'High', 'Low', d0P];
    S7d = ['Close'];
    x1S = [];
    for (var R8i in this.overlays) {
      if (this.overlays[R8i].panel !== H9s.name) continue;
      x1S = x1S.concat(Object.keys(this.overlays[R8i].outputMap));
    }
    for (var Z5R in d91.seriesRenderers) {
      A3P = d91.seriesRenderers[Z5R];
      if (A3P.params.panel != H9s.name) continue;
      for (var C62 in A3P.seriesParams) {
        E$I = A3P.seriesParams[C62];
        U4V = A3P.highLowBars ? G2_ : S7d;
        if (E$I.subField) {
          x1S = x1S
            .concat(J2$.createObjectChainNames(E$I.symbol, [E$I.subField]))
            .concat(E$I.symbol);
        } else if (E$I.symbol) {
          x1S = x1S
            .concat(J2$.createObjectChainNames(E$I.symbol, U4V))
            .concat(E$I.symbol);
        } else if (E$I.field) {
          x1S.push(E$I.field);
        } else if (H9s == d91.panel) {
          x1S = x1S.concat(U4V);
        }
      }
    }
    return x1S;
  };
  J2$.ChartEngine.prototype.setTransform = function (j_p, s_q, v9O) {
    j_p.transformFunc = s_q;
    j_p.untransformFunc = v9O;
  };
  J2$.ChartEngine.prototype.unsetTransform = function (n6X) {
    v8e.Y3Y();
    delete n6X.transformFunc;
    delete n6X.untransformFunc;
    for (var Z$f = 0; n6X.dataSet && Z$f < n6X.dataSet.length; Z$f++) {
      n6X.dataSet[Z$f].transform = null;
    }
  };
  J2$.ChartEngine.prototype.isEquationChart = function (O1a) {
    var w_7, D08, n8I;
    if (
      O1a &&
      O1a[0] ==
        ((+'5284', 1137) <= 7960
          ? '='
          : (768.62, 442) <= (546.62, '6940' ^ 0)
          ? 6070 > (549.6, +'6000')
            ? 'i'
            : (!!{}, !!'1')
          : (699.51, 0xef2))
    ) {
      if (!this.allowEquations || !J2$.fetchEquationChart) {
        console.warn(
          'Error, equation chart option requires equationsAdvanced.js'
        );
        return ![];
      }
      w_7 = +'2013508560';
      D08 = -+'1229574714';
      n8I = 2;
      for (
        var g50 = 1;
        v8e.e_M(g50.toString(), g50.toString().length, 11886) !== w_7;
        g50++
      ) {
        return !'';
      }
      if (v8e.e_M(n8I.toString(), n8I.toString().length, 76813) !== D08) {
        return !'1';
      }
    }
    return !!0;
  };
  J2$.ChartEngine.prototype.correctIfOffEdge = function (C4K) {
    var M5m, B78, A5m, f$F, h0a, A6X, V0H, Q0j, n6v, P2_;
    v8e.Y3Y();
    if (this.runPrepend('correctIfOffEdge', arguments)) {
      return;
    }
    for (var A4L in this.charts) {
      M5m = this.charts[A4L];
      B78 = M5m.dataSet;
      A5m = M5m.maxTicks;
      f$F = this.layout;
      h0a = this.minimumLeftBars;
      A6X = Math.min(h0a, A5m);
      if (M5m.allowScrollPast) {
        v8e.d$R(0);
        V0H = v8e.H9P(A5m, A6X);
        if (A6X > B78.length) {
          V0H = A5m - B78.length;
        }
        if (M5m.scroll - V0H >= B78.length) {
          v8e.p1T(61);
          var c_E = v8e.M3e(3, 16, 18);
          M5m.scroll = B78.length + V0H - c_E;
          this.micropixels = 0;
        }
        if (M5m.scroll <= A6X) {
          M5m.scroll = A6X;
          this.micropixels = 0;
        }
      } else {
        if (M5m.scroll < A6X) {
          M5m.scroll = A6X;
        }
        if (M5m.scroll > B78.length) {
          M5m.scroll = B78.length;
        }
      }
      if (M5m.allowScrollFuture === !{}) {
        Q0j =
          this.getLabelOffsetInPixels(M5m, f$F.chartType) +
          f$F.candleWidth * M5m.whiteSpaceFutureTicks;
        v8e.p1T(9);
        var i7p = v8e.M3e(11, 9, 2, 2);
        n6v = A5m - Math.round(Q0j / f$F.candleWidth) - i7p;
        P2_ = this.micropixels < 0 ? M5m.scroll - 1 : M5m.scroll;
        if (P2_ < n6v) {
          M5m.scroll = n6v;
          this.micropixels = 0;
        }
      }
    }
    this.runAppend('correctIfOffEdge', arguments);
  };
  J2$.ChartEngine.prototype.getStartDateOffset = function () {
    for (var e7m = 0; e7m < this.chart.dataSegment.length; e7m++) {
      if (this.chart.dataSegment[e7m]) {
        return e7m;
      }
    }
    return 0;
  };
  J2$.ChartEngine.prototype.setStartDate = function (k$V) {
    var V8N;
    for (var o11 = 0; o11 < this.chart.dataSet.length; o11++) {
      V8N = this.chart.dataSet[o11];
      if (V8N.DT.getTime() == k$V.getTime()) {
        this.chart.scroll = this.chart.dataSet.length - o11;
        this.draw();
        return;
      }
    }
  };
  J2$.ChartEngine.prototype.clearPixelCache = function () {
    var U0D;
    for (var T1r in this.panels) {
      U0D = this.panels[T1r];
      U0D.cacheHigh = null;
      U0D.cacheLow = null;
      U0D.cacheLeft = 1000000;
      v8e.p1T(25);
      U0D.cacheRight = -v8e.M3e(1, '1');
    }
    this.chart.pixelCacheExpired = !'';
  };
  J2$.ChartEngine.prototype.adjustBackingStore = function (J1F, e71) {
    var u0W, V4N, f6O, s9U, Q4k;
    this.devicePixelRatio = window.devicePixelRatio || 1;
    if (this.devicePixelRatio < 1.0) {
      v8e.d$R(21);
      this.devicePixelRatio = v8e.M3e('1.0', 0);
    }
    u0W =
      e71.webkitBackingStorePixelRatio ||
      e71.mozBackingStorePixelRatio ||
      e71.msBackingStorePixelRatio ||
      e71.oBackingStorePixelRatio ||
      e71.backingStorePixelRatio ||
      1;
    V4N = this.devicePixelRatio / u0W;
    if (!this.useBackingStore) {
      this.devicePixelRatio = this.adjustedDisplayPixelRatio = 1;
      J1F.style.width = J1F.style.height = '';
      return;
    }
    if (!J2$.isAndroid || J2$.is_chrome || J2$.isFF) {
      f6O = 'p';
      f6O += 'x';
      s9U = J1F.width;
      Q4k = J1F.height;
      v8e.d$R(25);
      J1F.width = v8e.M3e(V4N, s9U);
      v8e.p1T(25);
      J1F.height = v8e.H9P(V4N, Q4k);
      v8e.p1T(18);
      J1F.style.width = v8e.H9P('px', s9U);
      v8e.d$R(18);
      J1F.style.height = v8e.M3e(f6O, Q4k);
      e71.scale(V4N, V4N);
      this.adjustedDisplayPixelRatio = V4N;
      this.backing = {
        ratio: V4N,
        width: J1F.width,
        height: J1F.height,
        styleWidth: s9U,
        styleHeight: Q4k,
      };
    }
  };
  J2$.ChartEngine.prototype.reconstituteBackingStore = function () {
    var o3m, u0E;
    if (!this.useBackingStore || !this.backing) {
      return;
    }
    v8e.Y3Y();
    o3m = [this.chart.canvas];
    if (this.useBackgroundCanvas) {
      o3m.push(this.chart.backgroundCanvas);
    }
    u0E = this.backing;
    o3m.forEach(function (f7d) {
      if (f7d.width == u0E.width) {
        return;
      }
      f7d.width = u0E.width;
      f7d.height = u0E.height;
      f7d.context.scale(u0E.ratio, u0E.ratio);
    });
    this.adjustedDisplayPixelRatio = u0E.ratio;
    this.draw();
  };
  J2$.ChartEngine.prototype.disableBackingStore = function () {
    var c29, z7z;
    if (!this.useBackingStore || !this.backing) {
      return;
    }
    c29 = [this.chart.canvas];
    v8e.t3P();
    if (this.useBackgroundCanvas) {
      c29.push(this.chart.backgroundCanvas);
    }
    z7z = this.backing;
    c29.forEach(function (h0L) {
      var J5j, H0D, y_n;
      if (h0L.width == z7z.styleWidth) {
        return;
      }
      h0L.width = z7z.styleWidth;
      h0L.height = z7z.styleHeight;
      J5j = -+'1510846027';
      H0D = +'494784463';
      y_n = 2;
      for (
        var N5Q = 1;
        v8e.q78(N5Q.toString(), N5Q.toString().length, 26063) !== J5j;
        N5Q++
      ) {
        v8e.d$R(81);
        h0L.context.scale(v8e.H9P(1, '9'), 8);
        y_n += 2;
      }
      if (v8e.e_M(y_n.toString(), y_n.toString().length, +'95368') !== H0D) {
        h0L.context.scale(1, 1);
      }
    });
    this.adjustedDisplayPixelRatio = 1;
    this.draw();
  };
  J2$.ChartEngine.prototype.getBackgroundCanvas = function (h4A) {
    v8e.t3P();
    if (!h4A) {
      h4A = this.chart;
    }
    return this.useBackgroundCanvas ? h4A.backgroundCanvas : h4A.canvas;
  };
  J2$.ChartEngine.prototype.resizeCanvas = function () {
    var c6B, K3_, M_k, c_z, U6S, s84, j5h, o2c, i5V;
    c6B = 'un';
    c6B += 'defined';
    K3_ = this.chart.canvas;
    M_k = this.chart.context;
    if (K3_ && M_k) {
      this.floatCanvas.height =
        this.chart.tempCanvas.height =
        this.chart.backgroundCanvas.height =
        K3_.height =
          this.chart.container.clientHeight;
      this.floatCanvas.width =
        this.chart.tempCanvas.width =
        this.chart.backgroundCanvas.width =
        K3_.width =
          this.chart.container.clientWidth;
      this.adjustBackingStore(K3_, M_k);
      this.adjustBackingStore(
        this.chart.tempCanvas,
        this.chart.tempCanvas.context
      );
      this.adjustBackingStore(this.floatCanvas, this.floatCanvas.context);
      this.adjustBackingStore(
        this.chart.backgroundCanvas,
        this.chart.backgroundCanvas.context
      );
    }
    c_z = this.container.getBoundingClientRect();
    this.top = c_z.top;
    this.left = c_z.left;
    this.canvasWidth = this.chart.canvasWidth =
      this.chart.container.clientWidth;
    this.right = this.left + this.canvasWidth;
    this.height = this.chart.container.clientHeight;
    this.width = this.right - this.left;
    
    if (
      this.width === 0 &&
      !this.container.dimensionlessCanvas &&
      this.container.closest('html') &&
      !this.container.closest('cq-context-wrapper')
    ) {
      const candleWidth = this.exportLayout().candleWidth
      const intervalID = setInterval(() => {
        if (this.exportLayout().candleWidth !== candleWidth) {
          this.zoomSet?.(candleWidth, this.chart)
          setTimeout(() => clearInterval(intervalID), 100)
        }
      }, 30)

      console.log('warning: zero width chart. Check CSS for chart container.');
    }

    this.bottom = this.top + this.height;
    this.calculateYAxisPositions();
    this.chart.canvasRight = this.right;
    this.chart.canvasHeight = this.height;
    U6S = this.layout.candleWidth;
    if (typeof U6S == c6B) {
      U6S = 8;
    }
    for (var O8G in this.charts) {
      s84 = this.charts[O8G];
      this.setCandleWidth(U6S, s84);
      if (s84.scroll < s84.width / U6S) {
        s84.scroll = Math.floor(s84.width / U6S);
        j5h = Math.round(this.preferences.whitespace / this.layout.candleWidth);
        s84.scroll -= j5h;
      }
      v8e.p1T(81);
      o2c = v8e.H9P(10, '10');
      try {
        v8e.d$R(124);
        var a52 = v8e.H9P(5, 7, 5, 8);
        i5V = M_k.measureText('10:00').width * a52;
      } catch (s$9) {
        i5V = 100;
      }
      while (o2c > 1) {
        if (this.chart.width / i5V > o2c) break;
        v8e.p1T(0);
        o2c /= v8e.H9P('1.5', 0);
      }
      s84.xAxis.autoComputedTickSizePixels = Math.round(this.chart.width / o2c);
      if (s84.xAxis.autoComputedTickSizePixels < +'1') {
        v8e.d$R(20);
        s84.xAxis.autoComputedTickSizePixels = v8e.M3e('1', 0);
      }
    }
  };
  J2$.ChartEngine.prototype.setCandleWidth = function (e5i, l$3) {
    if (!l$3) {
      l$3 = this.chart;
    }
    e5i = this.constrainCandleWidth(e5i);
    this.layout.candleWidth = e5i;
    v8e.p1T(91);
    var M0l = v8e.H9P(7, 18, 0, 12);
    l$3.maxTicks = Math.round(l$3.width / e5i) + M0l;
  };
  J2$.ChartEngine.prototype.constrainCandleWidth = function (n45) {
    v8e.t3P();
    var Q44, b7E, g6b;
    Q44 = this.minimumCandleWidth;
    b7E = this.maximumCandleWidth;
    g6b = this.animations.zoom;
    if (Q44 && n45 < Q44) {
      n45 = Q44;
      if (g6b && g6b.running) {
        g6b.stop();
      }
    }
    if (b7E && n45 > b7E) {
      n45 = b7E;
      if (g6b && g6b.running) {
        g6b.stop();
      }
    }
    return n45;
  };
  J2$.ChartEngine.prototype.resizeChart = function (h3V) {
    var F1R;
    if (this.runPrepend('resizeChart', arguments)) {
      return;
    }
    if (h3V !== !{}) {
      h3V = !!'1';
    }
    v8e.Y3Y();
    if (h3V) {
      this.preAdjustScroll();
    }
    F1R = this.chart.canvasHeight;
    this.resizeCanvas();
    if (h3V) {
      this.postAdjustScroll();
    }
    if (this.displayInitialized) {
      this.adjustPanelPositions();
      this.draw();
    } else if (this.chart.canvasHeight !== +'0' && F1R === 0) {
      this.adjustPanelPositions();
      this.draw();
    }
    this.doDisplayCrosshairs();
    this.updateChartAccessories();
    this.runAppend('resizeChart', arguments);
  };
  J2$.ChartEngine.prototype.clear = function () {
    var f4r;
    this.displayInitialized = !!'';
    for (var k_4 in this.layout.studies) {
      f4r = this.layout.studies[k_4];
      J2$.getFn('Studies.removeStudy')(this, f4r);
    }
    if (this.controls.chartControls) {
      this.controls.chartControls.style.display = 'none';
    }
    this.chart.panel.title.innerHTML = '';
    this.chart.panel.title.appendChild(
      document.createTextNode(this.chart.panel.display)
    );
  };
  J2$.ChartEngine.prototype.fillScreen = function () {
    var K79, q7c, V8R, X9i, c99;
    K79 = this.chart;
    q7c = this.layout.candleWidth;
    V8R = K79.width - this.preferences.whitespace;
    X9i = K79.dataSet.length;
    if (X9i * q7c >= V8R) {
      this.draw();
      return;
    }
    if (!this.mainSeriesRenderer || !this.mainSeriesRenderer.standaloneBars) {
      X9i--;
    }
    v8e.p1T(41);
    c99 = v8e.H9P(X9i, V8R);
    this.setCandleWidth(c99, K79);
    this.home({ maintainWhitespace: !!'1' });
  };
  J2$.ChartEngine.prototype.setMaxTicks = function (X9p, S6m) {
    var E5T, N$s, z9Z;
    if (!S6m) {
      S6m = {};
    }
    X9p = Math.round(X9p);
    if (X9p < 2) {
      X9p = 2;
    }
    E5T = S6m.padding ? S6m.padding : 0;
    N$s = this.chart;
    z9Z = (N$s.width - E5T) / (X9p - 1) || 8;
    this.setCandleWidth(z9Z, N$s);
    if (S6m.padding || S6m.padding === 0) {
      N$s.scroll = X9p;
    }
  };
  J2$.ChartEngine.prototype.initializeChart = function (V8S) {
    var Q4q,
      P6C,
      W_P,
      z7X,
      y_Q,
      j2a,
      V$w,
      X6O,
      J0x,
      s1W,
      I1d,
      w6J,
      G_A,
      D57,
      d7j,
      p5A,
      W4j,
      o0v,
      n3T,
      p47,
      j5X,
      X5u,
      H_I,
      i5W,
      D42;
    if (this.runPrepend('initializeChart', arguments)) {
      return;
    }
    Q4q = this.chart;
    if (!Q4q.symbolObject.symbol) {
      Q4q.symbolObject.symbol = Q4q.symbol;
    }
    if (this.locale) {
      this.setLocale(this.locale);
    }
    if (!this.displayZone && J2$.ChartEngine.defaultDisplayTimeZone) {
      this.setTimeZone(null, J2$.ChartEngine.defaultDisplayTimeZone);
    }
    this.resetDynamicYAxis({ noRecalculate: !![] });
    function J5S(V1Z, D1A) {
      var i_J, C_7;
      i_J = '0';
      i_J += 'p';
      v8e.Y3Y();
      i_J += 'x';
      V1Z.context = V1Z.getContext('2d');
      V1Z.context.lineWidth = 1;
      C_7 = V1Z.style;
      C_7.position = 'absolute';
      C_7.left = i_J;
      if (D1A) {
        C_7.display = 'none';
      }
    }
    this.calculateYAxisPositions();
    this.micropixels = 0;
    if (V8S) {
      Q4q.container = V8S;
    } else {
      V8S = Q4q.container;
    }
    V8S.stx = this;
    if (!V8S.CIQRegistered) {
      V8S.CIQRegistered = !!'1';
      J2$.ChartEngine.registeredContainers.push(V8S);
      J2$.ChartEngine.attachGlobalEventHandlers();
    }
    if (this.registerHTMLElements) {
      this.registerHTMLElements();
    }
    P6C = Q4q.canvas;
    W_P = Q4q.backgroundCanvas;
    z7X = Q4q.tempCanvas;
    y_Q = this.floatCanvas;
    j2a = Q4q.canvasShim;
    this.setCandleWidth(this.layout.candleWidth);
    if (!Q4q.backgroundCanvas) {
      V$w = 'ca';
      V$w += 'nvas';
      X6O = 'stx';
      X6O += '-';
      X6O += 'ca';
      X6O += 'nvas-shim';
      J0x = 'di';
      J0x += 'v';
      s1W = 'c';
      s1W += 'anvas';
      W_P = Q4q.backgroundCanvas = document.createElement(s1W);
      V8S.appendChild(W_P);
      J5S(W_P);
      j2a = Q4q.canvasShim = document.createElement(J0x);
      j2a.className = X6O;
      V8S.appendChild(j2a);
      P6C = Q4q.canvas = document.createElement('canvas');
      V8S.appendChild(P6C);
      J5S(P6C);
      Q4q.context = P6C.context;
      z7X = Q4q.tempCanvas = document.createElement('canvas');
      V8S.appendChild(z7X);
      J5S(z7X, !0);
      y_Q = this.floatCanvas = document.createElement(V$w);
      V8S.appendChild(y_Q);
      J5S(y_Q, !!'1');
    }
    this.resizeCanvas();
    if (J2$.isAndroid) {
      I1d = function (t1F) {
        v8e.t3P();
        if (t1F.preventDefault) {
          t1F.preventDefault();
        }
      };
      z7X.addEventListener('touchstart', I1d, { passive: !!'' });
      y_Q.addEventListener('touchstart', I1d, { passive: !{} });
    }
    w6J = this.panels;
    Q4q.panel.display = Q4q.symbol;
    if (Q4q.symbolDisplay) {
      Q4q.panel.display = Q4q.symbolDisplay;
    }
    this.adjustPanelPositions();
    Q4q.panel = w6J[Q4q.name];
    for (var c6D in w6J) {
      G_A = w6J[c6D].yaxisLHS.concat(w6J[c6D].yaxisRHS);
      for (var H7E = 0; H7E < G_A.length; H7E++) {
        G_A[H7E].height = w6J[c6D].yAxis.height;
        this.calculateYAxisMargins(G_A[H7E]);
      }
    }
    this.initialWhitespace = this.preferences.whitespace;
    if (Q4q.dataSet && Q4q.dataSet.length > 0) {
      Q4q.scroll = Math.floor(Q4q.width / this.layout.candleWidth);
      D57 = Math.round(this.preferences.whitespace / this.layout.candleWidth);
      Q4q.scroll -= D57;
    }
    if (J2$.touchDevice) {
      d7j = '.';
      d7j += 'ove';
      d7j += 'r';
      d7j += 'layText';
      p5A = '.overlayEd';
      p5A += 'i';
      p5A += 't';
      W4j = V8S.querySelector(p5A);
      o0v = V8S.querySelector(d7j);
      n3T = V8S.querySelector('.overlayTrashCan');
      p47 = V8S.querySelector('.vectorTrashCan');
      j5X = function (f1h, U5J, g3$, O6Z) {
        v8e.t3P();
        return function (R3U) {
          v8e.t3P();
          f1h.deleteHighlighted(U5J, g3$, O6Z);
        };
      };
      if (o0v) {
        J2$.safeClickTouch(o0v, j5X(this, !0, !{}, !''));
      }
      if (W4j) {
        J2$.safeClickTouch(W4j, j5X(this, !!1, !'', !'1'));
        if (n3T) {
          J2$.safeClickTouch(n3T, j5X(this, !'1'));
        }
      } else if (n3T) {
        J2$.safeClickTouch(n3T, j5X(this, !!1));
      }
      if (p47) {
        J2$.safeClickTouch(p47, j5X(this, !!1));
      }
    }
    if (this.manageTouchAndMouse) {
      this.registerTouchAndMouseEvents();
    }
    if (this.handleMouseOut) {
      V8S.onmouseout = (function (v96) {
        return function (o8p) {
          v96.handleMouseOut(o8p);
        };
      })(this);
      J2$.smartHover(V8S.ownerDocument);
    }
    if (this.abortDrawings) {
      this.abortDrawings();
    }
    this.undoStamps = [];
    for (var i8a in w6J) {
      X5u = w6J[i8a];
      if (X5u.markerHolder) {
        V8S.removeChild(X5u.markerHolder);
        X5u.markerHolder = null;
      }
    }
    for (var h1n in this.plugins) {
      H_I = this.plugins[h1n];
      if (H_I.display) {
        if (H_I.initializeChart) {
          H_I.initializeChart(this);
        }
      }
    }
    if (!this.resizeListenerInitialized) {
      i5W = this;
      this.resizeListenerInitialized = !!{};
      D42 = function () {
        return function (R5H) {
          i5W.resizeChart();
        };
      };
      this.addDomEventListener(
        V8S.ownerDocument.defaultView,
        'resize',
        D42(),
        !![]
      );
    }
    if (Q4q.baseline.userLevel) {
      Q4q.baseline.userLevel = null;
    }
    this.setResizeTimer();
    this.runAppend('initializeChart', arguments);
  };
  J2$.ChartEngine.prototype.destroy = function () {
    var g2L, q4Z, z79;
    this.setResizeTimer(0);
    if (this.detachQuoteFeed) {
      this.detachQuoteFeed();
    }
    this.styles = {};
    for (var B1e = 0; B1e < this.eventListeners.length; B1e++) {
      g2L = this.eventListeners[B1e];
      g2L.element.removeEventListener(g2L.event, g2L['function'], g2L.options);
    }
    this.touchAndMouseEventsRegistered = !'1';
    this.eventListeners = [];
    if (this.streamParameters.timeout) {
      clearTimeout(this.streamParameters.timeout);
    }
    v8e.Y3Y();
    q4Z = J2$.ChartEngine.registeredContainers;
    z79 = q4Z.indexOf(this.chart.container);
    if (z79 > -1) {
      q4Z.splice(z79, 1);
    }
    this.destroyed = !!{};
    if (this.slider) {
      this.slider.slider.destroy();
    }
    if (this.marketDepth) {
      this.marketDepth.marketDepth.destroy();
    }
  };
  J2$.ChartEngine.registerGlobalEvent(
    K23,
    function () {
      var L7b;
      L7b = J2$.ChartEngine.registeredContainers.slice(+'0');
      L7b.forEach((n6g) => {
        v8e.Y3Y();
        if (n6g.ownerDocument === this.document) {
          try {
            n6g.stx.destroy();
            n6g.stx = null;
          } catch (Q4Z) {}
        }
      });
      J2$.ChartEngine.globalEventHandlers.forEach((l7Y) => {
        var F7i;
        F7i = l7Y.applied.indexOf(this.document);
        if (F7i > -+'1') {
          try {
            l7Y.applied.splice(F7i);
          } catch (B6Q) {}
        }
      });
      this.document.body.innerHTML = '';
    },
    !!'',
    !!{}
  );
  J2$.ChartEngine.prototype.preAdjustScroll = function (Q9g) {
    if (!Q9g) {
      Q9g = this.chart;
    }
    this.previousAdjust = {
      chart: Q9g,
      scroll: Q9g.scroll,
      maxTicks: Q9g.maxTicks,
    };
  };
  J2$.ChartEngine.prototype.postAdjustScroll = function () {
    var x13;
    if (!this.previousAdjust) {
      return;
    }
    x13 = this.previousAdjust.chart;
    x13.scroll =
      this.previousAdjust.scroll +
      (x13.maxTicks - this.previousAdjust.maxTicks);
    if (this.displayInitialized) {
      this.draw();
    }
  };
  J2$.ChartEngine.prototype.translateIf = function (M72) {
    if (this.translationCallback) {
      return this.translationCallback(M72, this.preferences.language);
    }
    return M72;
  };
  J2$.ChartEngine.prototype.doCleanupDates = function (y6Z, I48) {
    var J7W, y2P, M41, H8r, c$v, t_Q;
    if (!y6Z || !y6Z.length) {
      return;
    }
    for (var m3f = 0; m3f < y6Z.length; m3f++) {
      J7W = '[ob';
      J7W += 'ject Strin';
      J7W += 'g]';
      y2P = y6Z[m3f];
      M41 = y2P.DT;
      if (!M41 && !y2P.Date) continue;
      if (
        y2P.DT &&
        Object.prototype.toString.call(M41) == J7W &&
        M41.length <= +'10'
      ) {
        M41 = new Date(M41);
        M41.setMinutes(M41.getMinutes() + M41.getTimezoneOffset());
      } else {
        H8r = !!{};
        if (!y2P.DT) {
          M41 = J2$.strToDateTime(y2P.Date);
          if (y2P.Date.length <= 10) {
            H8r = !!'';
          }
        }
        if (Object.prototype.toString.call(M41) != '[object Date]') {
          M41 = new Date(M41);
        }
        if (O__.Date && this.dataZone && H8r) {
          c$v = new O__.Date(
            M41.getFullYear(),
            M41.getMonth(),
            M41.getDate(),
            M41.getHours(),
            M41.getMinutes(),
            this.dataZone
          );
          v8e.p1T(77);
          var l97 = v8e.M3e(984, 958, 10, 2);
          t_Q = M41.getSeconds() * l97 + M41.getMilliseconds();
          M41 = new Date(c$v.getTime() + t_Q);
        }
        if (J2$.ChartEngine.isDailyInterval(I48)) {
          v8e.d$R(26);
          M41.setHours(0, v8e.H9P('0', 64), v8e.H9P(1, '0', v8e.p1T(25)), 0);
        }
      }
      if (!y2P.DT) {
        y2P.Date = J2$.yyyymmddhhmmssmmm(M41);
      }
      y2P.DT = M41;
    }
  };
  v8e.t3P();
  J2$.ChartEngine.prototype.doCleanupGaps = function (S4n, r1i, v_7) {
    var w0A, w5O, J7e, v5k, a6l, R71, R1o, Z5u, d_M, Y1N, l2c, S2S, g1_, n_g;
    if (!S4n || !S4n.length) {
      return S4n;
    }
    w0A = this.layout.interval;
    v_7 = v_7 ? v_7 : {};
    if (!r1i) {
      r1i = this.chart;
    }
    if (!J2$.Market || !r1i.market) {
      return S4n;
    }
    if (!v_7.noCleanupDates) {
      this.doCleanupDates(S4n, w0A);
    }
    w5O = v_7.cleanupGaps;
    if (w5O === !!0) {
      return S4n;
    }
    if (!w5O || w5O === !!'1') {
      w5O = this.cleanupGaps || w5O;
    }
    v8e.p1T(95);
    J7e = v8e.M3e(w5O, 'gap');
    if (!w5O) {
      return S4n;
    }
    if (w0A == 'tick') {
      return S4n;
    }
    if (w0A == 'month' || w0A == 'week') {
      if (this.dontRoll) {
        return S4n;
      }
      w0A = 'day';
    }
    v5k = function (q2B) {
      var d$F, n51, v85, j3L;
      if (q2B.DT) {
        d$F = '[ob';
        d$F += 'ject Da';
        d$F += 't';
        d$F += 'e]';
        if (Object.prototype.toString.call(q2B.DT) != d$F) {
          return new Date(q2B.DT);
        }
        return new Date(+q2B.DT);
      }
      n51 = 1153773557;
      v8e.d$R(26);
      v85 = -v8e.M3e('1912818177', 0);
      j3L = 2;
      for (
        var N5_ = +'1';
        v8e.q78(N5_.toString(), N5_.toString().length, '19241' << 64) !== n51;
        N5_++
      ) {
        return J2$.strToDateTime(q2B.Date);
      }
      if (v8e.q78(j3L.toString(), j3L.toString().length, 43094) !== v85) {
        return J2$.strToDateTime(q2B.Date);
      }
    };
    a6l = [];
    function y8H(r4u, h_L) {
      var p1d, s3h, K$N, S2Y, X7n;
      p1d = v_7.field;
      s3h = p1d ? R71[p1d] : R71;
      if (s3h === undefined) {
        s3h = {};
      }
      K$N = J7e ? null : s3h[Y1N];
      S2Y = J7e ? null : s3h.Adj_Close;
      while (+r4u < +h_L) {
        X7n = { DT: r4u };
        if (p1d) {
        } else {
          a6l.push(X7n);
          J2$.extend(X7n, {
            Open: K$N,
            High: K$N,
            Low: K$N,
            Close: K$N,
            Volume: 0,
            Adj_Close: S2Y,
          });
        }
        r4u = d_M.next();
      }
    }
    R71 = S4n[0];
    a6l.push(R71);
    R1o = {
      begin: v5k(R71),
      interval: w0A,
      periodicity: 1,
      timeUnit: this.layout.timeUnit,
    };
    Z5u = new J2$.Market(r1i.market.market_def);
    function d$L(E73, E98) {
      var X8R, j0V, V8k, s9I;
      X8R = v_7.field;
      if (X8R) {
        j0V = 'u';
        j0V += 'nde';
        j0V += 'f';
        j0V += 'ined';
        if (typeof E73[X8R] != j0V && typeof E98[X8R] == 'undefined') {
          E98[X8R] = J7e ? null : E73[X8R];
        }
        return;
      }
      if (J7e) {
        return;
      }
      v8e.Y3Y();
      V8k = E73[Y1N];
      s9I = E98[Y1N];
      if (typeof V8k != 'undefined' && typeof s9I == 'undefined') {
        J2$.ensureDefaults(E98, {
          Close: V8k,
          Open: V8k,
          High: V8k,
          Low: V8k,
          Volume: 0,
          Adj_Close: E73.Adj_Close,
        });
      }
    }
    d_M = Z5u.newIterator(R1o);
    if (this.extendedHours && this.extendedHours.filter) {
      d_M.market.enableAllAvailableSessions();
    }
    Y1N = r1i.defaultPlotField;
    for (var g4_ = 0; g4_ < S4n.length; g4_++) {
      if (S4n[g4_][Y1N] !== undefined) break;
      if (S4n[g4_].Value !== undefined) {
        l2c = 'V';
        l2c += 'alu';
        l2c += 'e';
        Y1N = l2c;
        break;
      }
    }
    for (var U_N = 1; U_N < S4n.length; U_N++) {
      g1_ = S4n[U_N];
      S2S = d_M.next();
      n_g = v5k(g1_);
      if (S2S < n_g) {
        y8H(S2S, n_g);
        S2S = d_M.market._convertFromMarketTZ(d_M.begin, d_M.outZone);
      }
      while (n_g < S2S) {
        if (++U_N == S4n.length) {
          return a6l;
        }
        d$L(R71, g1_);
        a6l.push(g1_);
        R71 = g1_;
        g1_ = S4n[U_N];
        n_g = v5k(g1_);
      }
      if (S2S < n_g) {
        U_N--;
        S2S = d_M.previous();
      } else {
        d$L(R71, g1_);
        a6l.push(g1_);
        R71 = g1_;
      }
    }
    return a6l;
  };
  J2$.ChartEngine.prototype.getCreatingLibrary = function () {
    v8e.Y3Y();
    return c6w;
  };
};
C_ = (a94) => {
  var s6x = v7ZLk;
  var Q56;
  Q56 = a94.CIQ;
  Q56.ChartEngine.Panel = function (Z9j, q2R) {
    if (q2R) {
      this.yAxis = q2R;
    } else {
      this.yAxis = new Q56.ChartEngine.YAxis();
    }
    this.name = Z9j;
    this.state = {};
  };
  Q56.extend(
    Q56.ChartEngine.Panel.prototype,
    {
      name: null,
      display: null,
      chart: null,
      yAxis: null,
      shareChartXAxis: null,
      top: null,
      bottom: null,
      height: null,
      percent: null,
      displayEdgeIfPadded: !!{},
      noDrag: ![],
      exportable: !!1,
    },
    !![]
  );
  Q56.ChartEngine.prototype.whichPanel = function (q$x) {
    var h5q;
    for (var N4M in this.panels) {
      h5q = this.panels[N4M];
      if (h5q.hidden) continue;
      if (q$x >= h5q.top && q$x < h5q.bottom) {
        return h5q;
      }
    }
    return null;
  };
  Q56.ChartEngine.prototype.panelExists = function (W1m) {
    var U0c;
    s6x.Y3Y();
    for (var i_1 in this.panels) {
      U0c = this.panels[i_1];
      if (U0c.name == W1m) {
        return !0;
      }
    }
    return !!0;
  };
  Q56.ChartEngine.prototype.storePanels = function () {
    var D7n, e4x;
    if (!this.layout) {
      this.layout = {};
    }
    D7n = this.layout;
    s6x.Y3Y();
    D7n.panels = {};
    for (var h3F in this.panels) {
      e4x = this.panels[h3F];
      D7n.panels[e4x.name] = {
        percent: e4x.percent,
        display: e4x.display,
        yAxis: e4x.yAxis,
      };
    }
  };
  Q56.ChartEngine.prototype.savePanels = function (F5U) {
    var A$k, I5c, F6K;
    this.storePanels();
    A$k = -1554815454;
    I5c = +'1930133832';
    F6K = 2;
    for (
      var c05 = 1;
      s6x.q78(c05.toString(), c05.toString().length, 8472) !== A$k;
      c05++
    ) {
      if (F5U == !'') {
        this.changeOccurred('');
      }
      F6K += +'2';
    }
    s6x.t3P();
    if (s6x.q78(F6K.toString(), F6K.toString().length, 12852) !== I5c) {
      if (F5U === !1) {
        this.changeOccurred('');
      }
    }
    if (F5U !== !1) {
      this.changeOccurred('layout');
    }
  };
  s6x.Y3Y();
  Q56.ChartEngine.prototype.privateDeletePanel = function (M$7) {
    var F$V, w5S;
    for (var F8W in this.layout.studies) {
      F$V = this.layout.studies[F8W];
      if (F$V.panel == M$7.name) {
        this.cleanupRemovedStudy(F$V);
      }
    }
    delete this.panels[M$7.name];
    for (var p5i in this.overlays) {
      if (this.overlays[p5i].panel == M$7.name) {
        if (this.layout.studies) {
          this.cleanupRemovedStudy(this.layout.studies[p5i]);
        }
        delete this.overlays[p5i];
      }
    }
    for (var h6n in this.chart.series) {
      if (this.chart.series[h6n].parameters.panel == M$7.name) {
        this.removeSeries(this.chart.series[h6n], this.chart);
      }
    }
    if (M$7.holder) {
      this.chart.container.removeChild(M$7.holder);
      if (this.getMarkerArray) {
        w5S = this.getMarkerArray('panelName', M$7.name);
        for (var g0w = 0; g0w < w5S.length; g0w++) {
          this.removeFromHolder(w5S[g0w]);
        }
      }
    }
    if (M$7.handle) {
      M$7.handle.remove();
    }
    this.currentPanel = null;
  };
  Q56.ChartEngine.prototype.plotsInPanel = function (M7k) {
    var u8q, P$I, b7e, u8A, S7X, N3P, q0S;
    u8q = [];
    P$I = M7k;
    if (typeof M7k == 'object') {
      P$I = M7k.name;
    }
    for (var S2m in this.layout.studies) {
      b7e = this.layout.studies[S2m];
      if (P$I === b7e.panel) {
        u8q.push(b7e);
      }
    }
    for (var T3j in this.chart.seriesRenderers) {
      u8A = this.chart.seriesRenderers[T3j];
      if (P$I === u8A.params.panel) {
        u8q.push(u8A);
      }
    }
    S7X = -+'220997093';
    N3P = 1300890591;
    q0S = 2;
    for (
      var W2L = '1' ^ 0;
      s6x.e_M(W2L.toString(), W2L.toString().length, '56901' >> 64) !== S7X;
      W2L++
    ) {
      return u8q;
    }
    if (s6x.e_M(q0S.toString(), q0S.toString().length, 86138) !== N3P) {
      return u8q;
    }
  };
  Q56.ChartEngine.prototype.checkForEmptyPanel = function (T1e, p5R, n4F) {
    var W$N, I26;
    if (!T1e) {
      return ![];
    }
    W$N = T1e;
    if (typeof T1e == 'object') {
      W$N = T1e.name;
    }
    if (W$N === this.chart.name) {
      return !!0;
    }
    if (!n4F) {
      n4F = [];
    } else if (!(n4F instanceof Array)) {
      n4F = [n4F];
    }
    I26 = this.plotsInPanel(T1e);
    for (var l26 = 0; l26 < I26.length; l26++) {
      if (n4F.indexOf(I26[l26]) == -1) {
        return !'1';
      }
    }
    if (!p5R) {
      this.panelClose(this.panels[W$N]);
    }
    return !!1;
  };
  Q56.ChartEngine.prototype.panelClose = function (i8h) {
    var K4U;
    if (!i8h) {
      return;
    }
    if (this.runPrepend('panelClose', arguments)) {
      return;
    }
    this.cancelTouchSingleClick = !!{};
    Q56.ChartEngine.drawingLine = ![];
    if (i8h.soloing) {
      this.panelSolo(i8h);
    }
    if (this.charts[i8h.name]) {
      for (var T4q in this.panels) {
        K4U = this.panels[T4q];
        if (K4U.chart.name == i8h.name) {
          this.privateDeletePanel(K4U);
        }
      }
      delete this.charts[i8h.name];
    } else {
      this.privateDeletePanel(i8h);
    }
    if (!this.currentlyImporting) {
      this.showCrosshairs();
      this.resetDynamicYAxis({ noRecalculate: !!1 });
      this.calculateYAxisPositions();
      this.draw();
      this.savePanels();
    }
    this.userPointerDown = this.grabbingScreen = !{};
    if (this.openDialog) {
      this.openDialog = '';
    }
    this.runAppend('panelClose', arguments);
  };
  Q56.ChartEngine.prototype.deleteAllPanels = function () {
    var Q6_;
    for (var G2K in this.panels) {
      Q6_ = this.panels[G2K];
      this.privateDeletePanel(Q6_);
    }
    this.layout.panels = {};
    s6x.t3P();
    this.panels = {};
  };
  Q56.ChartEngine.prototype.panelUp = function (q2M) {
    var B0D, K1R, G5q, C4g;
    this.cancelTouchSingleClick = !!'1';
    Q56.ChartEngine.drawingLine = ![];
    this.showCrosshairs();
    B0D = {};
    K1R = 0;
    for (G5q in this.panels) {
      if (G5q == q2M.name) break;
      K1R++;
    }
    if (!K1R) {
      return;
    }
    C4g = 0;
    for (G5q in this.panels) {
      if (C4g == K1R - 1) {
        B0D[q2M.name] = q2M;
      }
      if (G5q == q2M.name) continue;
      B0D[G5q] = this.panels[G5q];
      C4g++;
    }
    this.panels = B0D;
    this.adjustPanelPositions();
    this.draw();
    s6x.Y3Y();
    this.savePanels();
  };
  Q56.ChartEngine.prototype.panelDown = function (m3_) {
    var k6x, y_9, l4O, Q1b, Q5B;
    this.cancelTouchSingleClick = !![];
    Q56.ChartEngine.drawingLine = !!0;
    this.showCrosshairs();
    k6x = {};
    y_9 = 0;
    for (l4O in this.panels) {
      if (l4O == m3_.name) break;
      y_9++;
    }
    Q1b = +'0';
    for (l4O in this.panels) {
      Q1b++;
    }
    if (y_9 == Q1b - 1) {
      return;
    }
    Q5B = 0;
    for (l4O in this.panels) {
      if (l4O == m3_.name) {
        Q5B++;
        continue;
      }
      k6x[l4O] = this.panels[l4O];
      if (Q5B == y_9 + 1) {
        k6x[m3_.name] = m3_;
      }
      Q5B++;
    }
    this.panels = k6x;
    s6x.t3P();
    this.adjustPanelPositions();
    this.draw();
    this.savePanels();
  };
  Q56.ChartEngine.prototype.panelSolo = function (D$y) {
    var y71, Y4D, K_C, Y4Q;
    this.cancelTouchSingleClick = !![];
    Q56.ChartEngine.drawingLine = ![];
    this.showCrosshairs();
    s6x.Y3Y();
    y71 = !!{};
    if (D$y.soloing) {
      y71 = ![];
      D$y.soloing = !!'';
      D$y.solo.classList.remove('stx_solo_lit');
      D$y.percent = D$y.oldPercent;
      if (D$y.name != 'chart') {
        if (this.soloPanelToFullScreen) {
          if (D$y.percent == 1) {
            for (Y4D in this.panels) {
              K_C = this.panels[Y4D];
              if (K_C != D$y) {
                D$y.percent -= K_C.percent;
              }
            }
          }
        } else {
          this.chart.panel.percent = this.chart.panel.oldPercent;
        }
      }
      if (this.soloPanelToFullScreen) {
        this.xAxisAsFooter = this.chart.panel.oldXAxisAsFooter;
      }
    } else {
      Y4Q = 'cha';
      Y4Q += 'rt';
      D$y.soloing = !'';
      D$y.solo.classList.add('stx_solo_lit');
      D$y.oldPercent = D$y.percent;
      this.chart.panel.oldXAxisAsFooter = this.xAxisAsFooter;
      if (D$y.name != Y4Q) {
        if (this.soloPanelToFullScreen) {
          this.xAxisAsFooter = !![];
        } else {
          this.chart.panel.oldPercent = this.chart.panel.percent;
          s6x.p1T(125);
          var c79 = s6x.M3e(8, 1, 2, 8);
          D$y.percent = c79 - this.chart.panel.percent;
        }
      }
    }
    for (Y4D in this.panels) {
      if (D$y === this.panels[Y4D]) continue;
      if (Y4D === 'chart' && !this.soloPanelToFullScreen) continue;
      this.panels[Y4D].hidden = y71;
    }
    this.resetDynamicYAxis({ noRecalculate: !0 });
    this.calculateYAxisPositions();
    this.draw();
    this.savePanels();
  };
  Q56.ChartEngine.prototype.calculatePanelPercent = function (b93) {
    s6x.Y3Y();
    var T80;
    T80 = b93.bottom - b93.top;
    b93.percent = T80 / this.chart.canvasHeight;
  };
  Q56.ChartEngine.prototype.resizePanels = function () {
    var x9n, M3b, l$b, n1Y, z38, B7O;
    if (!Q56.ChartEngine.resizingPanel) {
      return;
    }
    z38 =
      Q56.ChartEngine.crosshairY >
      this.resolveY(Q56.ChartEngine.resizingPanel.top);
    for (var v7H in this.panels) {
      if (this.panels[v7H] == Q56.ChartEngine.resizingPanel) break;
      if (this.panels[v7H].hidden) continue;
      x9n = this.panels[v7H];
    }
    B7O = this.backOutY(Q56.ChartEngine.crosshairY);
    if (z38) {
      l$b = Q56.ChartEngine.resizingPanel.yaxisLHS.concat(
        Q56.ChartEngine.resizingPanel.yaxisRHS
      );
      for (n1Y = +'0'; n1Y < l$b.length; n1Y++) {
        s6x.d$R(110);
        var N$w = s6x.M3e(150, 11, 10, 6);
        M3b = l$b[n1Y].initialMarginTop + l$b[n1Y].initialMarginBottom + N$w;
        if (B7O > l$b[n1Y].bottom - M3b) {
          B7O = l$b[n1Y].bottom - M3b;
        }
      }
    } else {
      l$b = x9n.yaxisLHS.concat(x9n.yaxisRHS);
      for (n1Y = 0; n1Y < l$b.length; n1Y++) {
        s6x.d$R(0);
        var k$9 = s6x.H9P(60, 50);
        M3b = l$b[n1Y].initialMarginTop + l$b[n1Y].initialMarginBottom + k$9;
        if (B7O < l$b[n1Y].top + M3b) {
          B7O = l$b[n1Y].top + M3b;
        }
      }
    }
    Q56.ChartEngine.crosshairY = this.resolveY(B7O);
    x9n.bottom = B7O;
    Q56.ChartEngine.resizingPanel.top = B7O;
    this.calculatePanelPercent(x9n);
    this.calculatePanelPercent(Q56.ChartEngine.resizingPanel);
    this.adjustPanelPositions();
    this.draw();
    this.savePanels();
  };
  Q56.ChartEngine.prototype.isPanelAboveChart = function (g62) {
    for (var m_L in this.panels) {
      if (m_L == 'chart') {
        return ![];
      }
      if (m_L == g62.name) {
        return !0;
      }
    }
    return ![];
  };
  Q56.ChartEngine.prototype.adjustPanelPositions = function () {
    var Q78,
      F7K,
      d_h,
      i6Q,
      p$M,
      h4d,
      N6F,
      N9y,
      H_8,
      D1Z,
      n9O,
      b5M,
      D_n,
      I8f,
      j7r,
      z3A,
      B95,
      T6h,
      Z3A,
      i7W,
      A1C,
      W$f,
      F8M,
      k9S,
      i3c,
      e0J,
      B7m;
    Q78 = 'stx-s';
    Q78 += 'how';
    F7K = 'adju';
    F7K += 'stPanelPos';
    F7K += 'itions';
    var { chart: y5w, panels: O7m } = this;
    if (y5w.tempCanvas) {
      Q56.clearCanvas(y5w.tempCanvas, this);
    }
    if (this.runPrepend(F7K, arguments)) {
      return;
    }
    d_h = 0;
    i6Q = y5w.canvasHeight;
    p$M = !!'';
    h4d = 0;
    s6x.p1T(25);
    N6F = s6x.H9P(1, '0');
    N9y = ![];
    for (H_8 in O7m) {
      D1Z = O7m[H_8];
      if (isNaN(D1Z.percent) || D1Z.percent <= 0) {
        D1Z.percent = 0.05;
      }
      if (D1Z.hidden) continue;
      h4d += D1Z.percent;
      N6F++;
      if (D1Z.soloing) {
        N9y = !'';
      }
    }
    for (H_8 in O7m) {
      n9O = 0;
      D1Z = O7m[H_8];
      if (D1Z.hidden) {
        if (D1Z.markerHolder) {
          D1Z.markerHolder.style.display = 'none';
        }
        continue;
      }
      if (this.manageTouchAndMouse) {
        if (D1Z.up) {
          if (!p$M) {
            b5M = 'st';
            b5M += 'x';
            b5M += '-sh';
            b5M += 'ow';
            p$M = !!{};
            D1Z.up.classList.remove(b5M);
          } else {
            D_n = 'st';
            D_n += 'x-show';
            if (this.displayIconsUpDown) {
              D1Z.up.classList.add(D_n);
            }
          }
        }
        if (D1Z.solo) {
          if (N9y) {
            I8f = 'st';
            I8f += 'x-s';
            I8f += 'ho';
            I8f += 'w';
            if (D1Z.soloing && this.displayIconsSolo) {
              D1Z.solo.classList.add(I8f);
            } else {
              D1Z.solo.classList.remove('stx-show');
            }
          } else if (N6F == 1) {
            D1Z.solo.classList.remove('stx-show');
          } else if (N6F == 2 && !this.soloPanelToFullScreen) {
            j7r = 'st';
            j7r += 'x';
            j7r += '-';
            j7r += 'show';
            D1Z.solo.classList.remove(j7r);
          } else {
            if (this.displayIconsSolo) {
              D1Z.solo.classList.add('stx-show');
            }
          }
        }
        if (D1Z.down) {
          if (N6F == 1) {
            z3A = 's';
            z3A += 'tx-show';
            D1Z.down.classList.remove(z3A);
          } else {
            if (this.displayIconsUpDown) {
              D1Z.down.classList.add('stx-show');
            }
          }
        }
        if (D1Z.edit) {
          B95 = 's';
          B95 += 'tx';
          B95 += '-show';
          if (D1Z.editFunction) {
            D1Z.edit.classList.add(B95);
          } else {
            D1Z.edit.classList.remove('stx-show');
          }
        }
        if (D1Z.close) {
          if (this.displayIconsClose) {
            D1Z.close.classList.add('stx-show');
          } else {
            D1Z.close.classList.remove('stx-show');
          }
        }
      }
      D1Z.percent = D1Z.percent / h4d;
      D1Z.top = d_h;
      D1Z.bottom = D1Z.top + i6Q * D1Z.percent;
      D1Z.height = D1Z.bottom - D1Z.top;
      if (D1Z.chart.name == D1Z.name) {
        D1Z.chart.top = D1Z.top;
        D1Z.chart.bottom = D1Z.bottom;
        D1Z.chart.height = D1Z.height;
      }
      d_h = D1Z.bottom;
      if (D1Z.yaxisLHS) {
        T6h = D1Z.yaxisLHS.concat(D1Z.yaxisRHS);
        for (var v_j = 0; v_j < T6h.length; v_j++) {
          Z3A = 'n';
          Z3A += 'on';
          Z3A += 'e';
          i7W = T6h[v_j];
          if (i7W.zoom && i7W.height > 0) {
            n9O = i7W.zoom / i7W.height;
          }
          this.adjustYAxisHeightOffset(D1Z, i7W);
          i7W.height = i7W.bottom - i7W.top;
          if (n9O) {
            i7W.scroll *= (n9O * i7W.height) / i7W.zoom;
            i7W.zoom = n9O * i7W.height;
            if (i7W.zoom > i7W.height) {
              i7W.zoom = 0;
              i7W.scroll = 0;
            }
          }
          if (!i7W.high && i7W.high !== 0) {
            i7W.high = 100;
            i7W.low = 0;
            i7W.shadow = 100;
          }
          i7W.multiplier = i7W.height / i7W.shadow;
          if (i7W.position === Z3A) {
            this.calculateYAxisMargins(i7W);
          }
        }
      }
      if (D1Z.holder) {
        A1C = 'p';
        A1C += 'x';
        W$f = 'p';
        W$f += 'x';
        D1Z.holder.style.right = '0px';
        D1Z.holder.style.top = D1Z.top + 'px';
        D1Z.holder.style.left = '0px';
        D1Z.holder.style.height = D1Z.height + 'px';
        D1Z.subholder.style.left = D1Z.left + W$f;
        D1Z.subholder.style.width = D1Z.width + A1C;
        D1Z.subholder.style.top = '0px';
        if (D1Z.yAxis.height >= 0) {
          D1Z.subholder.style.height = D1Z.yAxis.height + 'px';
        }
      }
    }
    if (H_8 && O7m[H_8].down) {
      O7m[H_8].down.classList.remove(Q78);
    }
    if (this.manageTouchAndMouse && N6F == 2 && !N9y && y5w.panel.solo) {
      F8M = 'stx-';
      F8M += 's';
      F8M += 'how';
      y5w.panel.solo.classList.add(F8M);
    }
    if (y5w.panel) {
      if (N9y && this.soloPanelToFullScreen) {
        s6x.d$R(0);
        var J2Y = s6x.H9P(28, 16);
        k9S = y5w.canvasHeight - D1Z.yAxis.bottom + J2Y;
      } else {
        s6x.d$R(104);
        var Z25 = s6x.M3e(108, 15, 7, 15);
        k9S = y5w.canvasHeight - y5w.panel.yAxis.bottom + Z25;
      }
      i3c = this.controls;
      var { chartControls: C6b, home: O4g, notificationTray: A34 } = i3c;
      e0J = this.width - y5w.panel.right;
      if (C6b) {
        s6x.d$R(18);
        C6b.style.bottom = s6x.M3e('px', k9S);
      }
      if (O4g) {
        s6x.d$R(18);
        O4g.style.bottom = s6x.H9P('px', k9S);
        s6x.p1T(18);
        O4g.style.marginRight = s6x.M3e('px', e0J);
      }
      if (A34) {
        B7m = 'p';
        B7m += 'x';
        s6x.p1T(18);
        A34.style.bottom = s6x.H9P('px', k9S);
        s6x.d$R(18);
        A34.style.marginRight = s6x.M3e(B7m, e0J);
      }
    }
    this.clearPixelCache();
    if (this.drawingObjects.length) {
      this.adjustDrawings();
    }
    this.runAppend('adjustPanelPositions', arguments);
  };
  Q56.ChartEngine.prototype.createPanel = function (
    j5c,
    e9F,
    m7o,
    v_m,
    I88,
    S5B
  ) {
    s6x.t3P();
    var M5K, a5G, k9X, I$a, x68;
    if (this.runPrepend('createPanel', arguments)) {
      return;
    }
    if (!v_m) {
      v_m = 'chart';
    }
    M5K = this.chart.canvasHeight;
    if (!m7o) {
      s6x.d$R(25);
      m7o = s6x.M3e(0.2, M5K);
    }
    if (m7o > M5K) {
      s6x.p1T(56);
      m7o = s6x.H9P(M5K, '0.5');
    }
    s6x.p1T(41);
    a5G = s6x.M3e(M5K, m7o);
    s6x.d$R(0);
    k9X = s6x.H9P(1, a5G);
    I$a = !{};
    for (var v8o in this.panels) {
      x68 = this.panels[v8o];
      x68.percent *= k9X;
      if (x68.soloing) {
        I$a = !!{};
      }
    }
    this.stackPanel(j5c, e9F, a5G, v_m, I88);
    this.panels[e9F].hidden = I$a;
    this.panels[e9F].exportable = !S5B;
    this.calculateYAxisMargins(this.panels[e9F].yAxis);
    this.adjustPanelPositions();
    this.savePanels(!!'');
    this.runAppend('createPanel', arguments);
    return this.panels[e9F];
  };
  Q56.ChartEngine.prototype.modifyPanel = function (b5A, g59) {
    var G2H, Z3Z, i5g, n8$, y3F, p5N, p0o, N1E;
    G2H = b5A.name;
    var { studies: H3j } = this.layout;
    var { series: E6i } = this.chart;
    var { name: t7j, display: B2n, yAxis: C0Y } = g59 || {};
    if (!t7j) {
      t7j = Q56.uniqueID();
    }
    if (!B2n) {
      B2n = t7j;
    }
    s6x.Y3Y();
    if (!C0Y) {
      C0Y = b5A.yAxis;
      C0Y.name = t7j;
    }
    Z3Z = {};
    for (var E3X in this.panels) {
      if (E3X === b5A.name) {
        i5g = this.panels[E3X];
        i5g.name = t7j;
        i5g.display = B2n;
        i5g.yAxis = C0Y;
        b5A = Z3Z[t7j] = i5g;
        if (this.moveMarkers && G2H !== t7j) {
          this.moveMarkers(G2H, t7j);
        }
      } else {
        Z3Z[E3X] = this.panels[E3X];
      }
    }
    this.panels = Z3Z;
    n8$ = !!0;
    for (var n_E in H3j) {
      y3F = H3j[n_E];
      if (y3F.panel === G2H) {
        y3F.panel = t7j;
        if (y3F.parameters && y3F.parameters.panelName) {
          n8$ = !!{};
          y3F.parameters.panelName = t7j;
        }
      }
    }
    for (var G9b in E6i) {
      if (E6i[G9b].parameters.panel === G2H) {
        n8$ = !![];
        p5N = { panel: t7j };
        if (
          E6i[G9b].parameters.yAxis &&
          E6i[G9b].parameters.yAxis.name === G2H
        ) {
          p5N.yAxis = C0Y;
        }
        this.modifySeries(G9b, p5N, !![]);
      }
    }
    if (n8$) {
      this.changeOccurred('layout');
    }
    p0o = !!'';
    for (var S3M = 0; S3M < this.drawingObjects.length; S3M++) {
      N1E = this.drawingObjects[S3M];
      if (G2H === N1E.panelName) {
        N1E.panelName = t7j;
        p0o = !!'1';
      }
    }
    if (p0o) {
      this.changeOccurred('vector');
    }
    this.calculateYAxisPositions();
  };
  Q56.ChartEngine.prototype.setPanelHeight = function (A49, y77) {
    s6x.Y3Y();
    var x2K, C9W, V90, D7q;
    if (!y77) {
      return;
    }
    if (Object.values(this.panels).slice(-1)['0' - 0] === A49) {
      y77 += this.xaxisHeight;
    }
    var { canvasHeight: M9J } = this.chart;
    var { percent: b5j } = A49;
    s6x.d$R(41);
    x2K = s6x.M3e(M9J, y77);
    s6x.d$R(0);
    C9W = s6x.H9P(1, b5j);
    s6x.p1T(0);
    V90 = s6x.H9P(1, x2K);
    s6x.d$R(41);
    D7q = s6x.M3e(V90, C9W);
    Object.values(this.panels).forEach((x_1) => {
      s6x.t3P();
      x_1.percent /= D7q;
    });
    A49.percent = x2K;
    this.adjustPanelPositions();
    this.savePanels();
  };
  Q56.ChartEngine.prototype.electNewPanelOwner = function (x1e, F6W) {
    var j4u, c4y, j$s, Z$1, Q1f, i8e, a3H, R1t, t24;
    j4u = 'str';
    j4u += 'in';
    s6x.t3P();
    j4u += 'g';
    function R5g(w42) {
      return w42.name != j$s.name;
    }
    if (typeof x1e == j4u) {
      x1e = this.panels[x1e];
    }
    j$s = x1e.yAxis;
    if (x1e && x1e != this.chart.panel) {
      Z$1 = x1e.yAxis;
      Q1f = Z$1.studies[+'0'];
      if (!Q1f || Q1f == j$s.name) {
        Q1f = Z$1.renderers[0];
      }
      if (!Q1f || Q1f == j$s.name) {
        Q1f = Z$1.studies[1];
      }
      if (!Q1f) {
        Q1f = Z$1.renderers[1];
      }
      if (F6W) {
        Z$1 = x1e.yAxis = F6W;
        c4y = F6W.name;
      } else if (!Q1f) {
        if (x1e.yaxisLHS) {
          i8e = x1e.yaxisRHS.concat(x1e.yaxisLHS).filter(R5g);
          a3H = i8e[0];
          for (var u31 = 0; u31 < i8e.length; u31++) {
            if (!i8e[u31].position) {
              a3H = i8e[u31];
              break;
            }
          }
          if (a3H) {
            Z$1 = x1e.yAxis = a3H;
            c4y = a3H.studies[+'0'] || a3H.renderers[0];
          }
        }
      } else {
        Z$1 = this.addYAxis(
          x1e,
          new Q56.ChartEngine.YAxis({ name: Q1f, position: Z$1.position })
        );
        Z$1.renderers = x1e.yAxis.renderers;
        Z$1.studies = x1e.yAxis.studies;
        c4y = Q1f;
      }
      if (c4y) {
        Q1f = c4y;
        if (j$s.name != x1e.name) {
          Q1f = x1e.name;
        }
        t24 = this.layout.studies;
        if (t24 && t24[c4y]) {
          R1t = t24[c4y].inputs.display;
        }
        this.modifyPanel(x1e, { name: Q1f, display: R1t || c4y, yAxis: Z$1 });
        this.deleteYAxisIfUnused(x1e, j$s);
        if (!this.panels[Q1f].yAxis.lockScale) {
          this.calculateYAxisMargins(this.panels[Q1f].yAxis);
        }
      } else {
        this.checkForEmptyPanel(x1e);
      }
    }
    return c4y;
  };
  Q56.ChartEngine.prototype.configurePanelControls = function (i61) {
    var j_3, z2y, u2x, l4U, R1g, U55;
    j_3 = '.stx-';
    j_3 += 'i';
    j_3 += 'co-d';
    j_3 += 'own';
    z2y = '.s';
    z2y += 't';
    z2y += 'x-panel-tit';
    z2y += 'le';
    u2x = i61.icons;
    if (!u2x) {
      return;
    }
    l4U = i61.name == i61.chart.name;
    u2x.classList.add('stx-show');
    u2x.classList.remove('iconsTemplate');
    i61.title = u2x.querySelector(z2y);
    i61.up = u2x.querySelector('.stx-ico-up');
    if (i61.up) {
      i61.up = i61.up.parentNode;
    }
    i61.solo = u2x.querySelector('.stx-ico-focus');
    if (i61.solo) {
      i61.solo = i61.solo.parentNode;
    }
    i61.down = u2x.querySelector(j_3);
    if (i61.down) {
      i61.down = i61.down.parentNode;
    }
    i61.edit = u2x.querySelector('.stx-ico-edit');
    if (i61.edit) {
      i61.edit = i61.edit.parentNode;
    }
    i61.close = u2x.querySelector('.stx-ico-close');
    if (i61.close) {
      i61.close = i61.close.parentNode;
    }
    if (i61.title) {
      i61.title.innerHTML = '';
      if (i61.display) {
        i61.title.appendChild(document.createTextNode(i61.display));
      }
      if (l4U) {
        i61.title.classList.add('chart-title');
        u2x.classList.add('stx-chart-panel');
      }
    }
    if (!Q56.touchDevice || Q56.isSurface) {
      this.makeModal(u2x);
    }
    if (i61.handle) {
      if (!Q56.touchDevice || Q56.isSurface) {
        i61.handle.onmouseover = (function (c2L) {
          s6x.t3P();
          return function () {
            s6x.t3P();
            c2L.hideCrosshairs();
          };
        })(this);
      }
      if (!Q56.touchDevice || Q56.isSurface) {
        i61.handle.onmouseout = (function (X2C) {
          return function () {
            s6x.Y3Y();
            X2C.showCrosshairs();
          };
        })(this);
      }
      R1g = function (O0x, o7I) {
        s6x.Y3Y();
        return function (Y5o) {
          if (Q56.ChartEngine.resizingPanel || O0x.openDialog !== '') {
            return;
          }
          s6x.Y3Y();
          O0x.grabHandle(o7I);
        };
      };
      if (Q56.isSurface) {
        i61.handle.onpointerdown = R1g(this, i61);
      } else {
        i61.handle.onmousedown = R1g(this, i61);
      }
      if (Q56.touchDevice) {
        i61.handle.addEventListener('touchstart', R1g(this, i61), {
          passive: !!0,
        });
      }
    }
    if (i61.up) {
      Q56.safeClickTouch(
        i61.up,
        (function (Z$Z, X6J) {
          return function () {
            s6x.t3P();
            Z$Z.panelUp(X6J);
          };
        })(this, i61)
      );
    }
    if (i61.down) {
      Q56.safeClickTouch(
        i61.down,
        (function (V5I, R6Q) {
          s6x.t3P();
          return function () {
            s6x.Y3Y();
            V5I.panelDown(R6Q);
          };
        })(this, i61)
      );
    }
    if (i61.solo) {
      Q56.safeClickTouch(
        i61.solo,
        (function (Y52, v94) {
          return function () {
            s6x.Y3Y();
            Y52.panelSolo(v94);
          };
        })(this, i61)
      );
    }
    if (i61.close) {
      U55 = 'cha';
      U55 += 'rt';
      if (i61.name == U55) {
        i61.close.style.display = 'none';
      } else {
        Q56.safeClickTouch(
          i61.close,
          (function (U71, p7K) {
            return function () {
              s6x.t3P();
              U71.panelClose(p7K);
            };
          })(this, i61)
        );
      }
    }
  };
  Q56.ChartEngine.prototype.stackPanel = function (y86, P82, Z3B, k4d, n47) {
    var i6Z, l7I, i$7, S6X, J5M, q4P, k4x, V_J, j0w, G_G;
    i6Z = 's';
    i6Z += 't';
    i6Z += 'ackPan';
    i6Z += 'el';
    l7I = 'stx';
    l7I += '-panel';
    l7I += '-char';
    l7I += 't';
    i$7 = 'd';
    i$7 += 'iv';
    S6X = 'c';
    S6X += 'h';
    S6X += 'ar';
    S6X += 't';
    J5M = 'sta';
    J5M += 'c';
    J5M += 'kPane';
    J5M += 'l';
    if (this.runPrepend(J5M, arguments)) {
      return;
    }
    if (!k4d) {
      k4d = S6X;
    }
    q4P = this.charts[k4d];
    s6x.p1T(95);
    k4x = s6x.H9P(P82, k4d);
    if (k4x) {
      y86 = q4P.symbol;
      if (q4P.symbolDisplay) {
        y86 = q4P.symbolDisplay;
      }
      if (!n47) {
        n47 = q4P.yAxis;
      }
    }
    V_J = this.panels[P82] = new Q56.ChartEngine.Panel(P82, n47);
    if (!k4x && q4P.yAxis && V_J.yAxis.position == q4P.yAxis.position) {
      V_J.yAxis.width = q4P.yAxis.width;
    }
    if (k4x && !q4P.panel) {
      q4P.panel = V_J;
    }
    V_J.percent = Z3B;
    V_J.chart = q4P;
    V_J.display = y86;
    V_J.holder = Q56.newChild(this.container, i$7, 'stx-holder');
    V_J.subholder = Q56.newChild(V_J.holder, 'div', 'stx-subholder');
    V_J.subholder.style.zIndex = 1;
    V_J.holder.panel = V_J;
    j0w = k4x ? l7I : 'stx-panel-study';
    V_J.holder.classList.add(j0w);
    if (this.controls.handleTemplate && this.manageTouchAndMouse) {
      G_G = 'handleTem';
      G_G += 'plate';
      V_J.handle = this.controls.handleTemplate.cloneNode(!!1);
      V_J.handle.classList.remove(G_G);
      this.container.appendChild(V_J.handle);
      V_J.handle.panel = V_J;
    }
    if (this.controls.iconsTemplate) {
      V_J.icons = this.controls.iconsTemplate.cloneNode(!0);
      V_J.subholder.appendChild(V_J.icons);
      this.configurePanelControls(V_J);
    }
    if (!this.currentlyImporting) {
      this.resizeCanvas();
    }
    this.runAppend(i6Z, arguments);
  };
  Q56.ChartEngine.prototype.setPanelEdit = function (r6Q, I1w) {
    r6Q.editFunction = I1w;
    if (r6Q.edit) {
      Q56.safeClickTouch(r6Q.edit, I1w);
    }
    this.adjustPanelPositions();
  };
  Q56.ChartEngine.prototype.drawPanels = function () {
    var k2_,
      F4K,
      B1B,
      u1q,
      J8Z,
      R6B,
      g1s,
      r68,
      Z5A,
      h1h,
      V4V,
      m4q,
      V1Q,
      r4v,
      u26,
      B31;
    if (this.runPrepend('drawPanels', arguments)) {
      return;
    }
    k2_ = !!0;
    for (var E3m in this.panels) {
      F4K = 'segm';
      F4K += 'ent';
      B1B = 'stx_pan';
      B1B += 'el_border';
      u1q = 'st';
      u1q += 'x-s';
      u1q += 'how';
      J8Z = this.panels[E3m];
      J8Z.state = {};
      R6B = this.translateIf(J8Z.display);
      if (J8Z.title && J8Z.title.textContent != R6B) {
        J8Z.title.innerHTML = '';
        J8Z.title.appendChild(document.createTextNode(R6B));
      }
      if (J8Z.icons) {
        J8Z.icons.classList.add(u1q);
      }
      if (J8Z.hidden) {
        if (J8Z.icons) {
          J8Z.icons.classList.remove('stx-show');
        }
        if (J8Z.handle) {
          J8Z.handle.style.display = 'none';
        }
        J8Z.holder.style.display = 'none';
        continue;
      } else {
        if (J8Z.name != 'chart') {
          g1s = 'n';
          g1s += 'o';
          g1s += 'n';
          g1s += 'e';
          r68 = 'non';
          r68 += 'e';
          Z5A = 'n';
          Z5A += 'on';
          Z5A += 'e';
          h1h = this.manageTouchAndMouse;
          if (J8Z.up) {
            J8Z.up.style.display = this.displayIconsUpDown && h1h ? '' : Z5A;
          }
          if (J8Z.down) {
            J8Z.down.style.display = this.displayIconsUpDown && h1h ? '' : r68;
          }
          if (J8Z.solo) {
            J8Z.solo.style.display = this.displayIconsSolo && h1h ? '' : g1s;
          }
          if (J8Z.close) {
            J8Z.close.style.display =
              this.displayIconsClose && h1h ? '' : 'none';
          }
          if (J8Z.edit) {
            J8Z.edit.style.display = J8Z.editFunction && h1h ? '' : 'none';
          }
        }
        J8Z.holder.style.display = 'block';
      }
      if (J8Z.displayEdgeIfPadded) {
        V4V = 's';
        V4V += 'eg';
        V4V += 'ment';
        m4q = Math.round(J8Z.left) + +'0.5';
        V1Q = J8Z.yAxis.top - 0.5;
        r4v = J8Z.yAxis.bottom + 0.5;
        if (J8Z.yaxisCalculatedPaddingLeft && !J8Z.yaxisTotalWidthLeft) {
          this.plotLine(
            m4q,
            m4q,
            V1Q,
            r4v,
            this.canvasStyle('stx_grid_border'),
            V4V,
            this.chart.context,
            !'1',
            { lineWidth: 1 }
          );
        }
        m4q = Math.round(J8Z.right) + +'0.5';
        if (J8Z.yaxisCalculatedPaddingRight && !J8Z.yaxisTotalWidthRight) {
          this.plotLine(
            m4q,
            m4q,
            V1Q,
            r4v,
            this.canvasStyle('stx_grid_border'),
            'segment',
            this.chart.context,
            ![],
            { lineWidth: 1 }
          );
        }
      }
      if (!k2_) {
        if (J8Z.handle) {
          J8Z.handle.style.display = 'none';
        }
        k2_ = !!{};
        continue;
      }
      u26 = J8Z.top;
      u26 = Math.round(u26) + 0.5;
      this.plotLine(
        J8Z.left - 0.5,
        J8Z.right + 0.5,
        u26,
        u26,
        this.canvasStyle(B1B),
        F4K,
        this.chart.context,
        !1,
        {}
      );
      if (J8Z.handle) {
        B31 = 'p';
        B31 += 'x';
        if (!this.displayPanelResize) {
          J8Z.handle.style.display = 'none';
        } else {
          J8Z.handle.style.display = '';
        }
        s6x.p1T(35);
        var v7I = s6x.H9P(15, 13);
        J8Z.handle.style.top = u26 - J8Z.handle.offsetHeight / v7I + B31;
      }
    }
    this.runAppend('drawPanels', arguments);
  };
};
C$ = (D4l) => {
  var r5u = v7ZLk;
  var R_d;
  r5u.Y3Y();
  R_d = D4l.CIQ;
  R_d.ChartEngine.isDailyInterval = function (E1a) {
    var w6q;
    w6q = 'd';
    w6q += 'ay';
    if (E1a == w6q) {
      return !!1;
    }
    if (E1a == 'week') {
      return !0;
    }
    if (E1a == 'month') {
      return !!'1';
    }
    return !!0;
  };
  R_d.ChartEngine.prototype.setPeriodicity = function (I0H, G01) {
    var P89,
      p_z,
      D56,
      l19,
      x_7,
      m$V,
      L6F,
      w$0,
      X08,
      W3Y,
      t3J,
      n8A,
      u87,
      k63,
      q2C,
      a96;
    P89 = 'set';
    P89 += 'Pe';
    P89 += 'riodicity';
    if (this.runPrepend('setPeriodicity', arguments)) {
      return;
    }
    if (typeof arguments[0] !== 'object') {
      I0H = {
        period: arguments['0' | 0],
        interval: arguments[1],
        timeUnit: arguments[2],
      };
      r5u.d$R(126);
      var m5W = r5u.M3e(3, 4, 20, 20, 10);
      G01 = arguments[arguments.length - m5W];
      if (arguments.length === 3) {
        I0H.timeUnit = undefined;
      }
    }
    var { period: g$R, interval: Z1f, timeUnit: y$w } = I0H;
    if (typeof G01 !== 'function') {
      G01 = null;
    }
    ({
      period: g$R,
      interval: Z1f,
      timeUnit: y$w,
    } = R_d.cleanPeriodicity(g$R, Z1f, y$w));
    var { layout: A4B } = this;
    A4B.setSpan = {};
    A4B.range = {};
    this.chart.inflectionPoint = null;
    p_z = ![];
    if (this.chart.symbol) {
      p_z = this.needDifferentData({
        period: g$R,
        interval: Z1f,
        timeUnit: y$w,
      });
    }
    function X0n() {
      r5u.t3P();
      l19.dispatch('periodicity', x_7);
      if (G01) {
        G01(null);
      }
    }
    var {
      candleWidth: S09,
      periodicity: B1q,
      interval: I2z,
      timeUnit: V4o,
    } = A4B;
    D56 = { prvPeriodicity: B1q, prvInterval: I2z, prvTimeUnit: V4o };
    A4B.periodicity = g$R;
    A4B.interval = Z1f;
    A4B.timeUnit = y$w;
    r5u.t3P();
    l19 = this;
    x_7 = { stx: l19, differentData: p_z, prevPeriodicity: D56 };
    if (p_z) {
      this.changeOccurred('layout');
      this.clearCurrentMarketData();
      if (this.quoteDriver) {
        for (var f2L in this.charts) {
          m$V = this.charts[f2L];
          if (m$V.symbol) {
            if (this.displayInitialized) {
              this.quoteDriver.newChart(
                {
                  symbol: m$V.symbol,
                  symbolObject: m$V.symbolObject,
                  chart: m$V,
                },
                X0n
              );
            } else {
              this.loadChart(m$V.symbol, { chart: m$V }, X0n);
            }
          }
        }
      } else if (this.dataCallback) {
        this.dataCallback();
        X0n();
      } else {
        console.log(
          'cannot change periodicity because neither dataCallback or quoteDriver are set'
        );
      }
      this.home();
      return;
    }
    for (var V6N in this.charts) {
      L6F = this.charts[V6N];
      var { dataSegment: Z3y, dataSet: i70, maxTicks: s6l, scroll: V_e } = L6F;
      w$0 = Z3y ? Z3y.length : 0;
      X08 = i70 ? i70.length : +'0';
      W3Y = void 0;
      t3J = Math.round(L6F.maxTicks / 2);
      this.setCandleWidth(S09, L6F);
      n8A = !0;
      u87 = !'1';
      if (V_e <= s6l) {
        n8A = !!0;
      } else if (Z3y && !Z3y[t3J]) {
        n8A = ![];
        r5u.p1T(0);
        u87 = r5u.M3e(V_e, X08);
      }
      if (n8A && w$0 > 0) {
        if (s6l < (Math.round(this.chart.width / S09 - 0.499) - 1) / 2) {
          r5u.p1T(116);
          t3J = r5u.M3e(w$0, '1', 1);
        }
        if (t3J >= w$0) {
          W3Y = Z3y[w$0 - 1].DT;
          r5u.p1T(0);
          t3J = r5u.M3e(w$0, 1);
        } else {
          W3Y = Z3y[t3J].DT;
        }
      }
      this.createDataSet();
      if (n8A) {
        if (w$0 > '0' * 1) {
          for (var Q49 = X08 - 1; Q49 >= '0' - 0; Q49--) {
            k63 = i70[Q49].DT;
            if (k63.getTime() < W3Y.getTime()) {
              r5u.p1T(19);
              L6F.scroll = r5u.H9P(X08, 1, Q49, t3J);
              break;
            }
          }
        }
      } else if (!u87) {
        q2C = Math.round(this.preferences.whitespace / S09);
        r5u.d$R(64);
        L6F.scroll = r5u.M3e(q2C, 1, s6l);
      } else {
        L6F.scroll = i70.length + u87;
      }
    }
    if (this.displayInitialized) {
      this.draw();
    }
    this.changeOccurred('layout');
    if (this.quoteDriver) {
      for (var W6W in this.charts) {
        a96 = this.charts[W6W];
        if (a96.symbol && (a96.moreAvailable || !a96.upToDate)) {
          this.quoteDriver.checkLoadMore(a96);
        }
      }
    }
    X0n();
    this.runAppend(P89, arguments);
  };
  R_d.ChartEngine.prototype.needDifferentData = function (D0e) {
    var j7j, R$q, j79, r7w;
    j7j = this.layout;
    R$q = R_d.ChartEngine.isDailyInterval(D0e.interval);
    j79 = R_d.ChartEngine.isDailyInterval(j7j.interval);
    r7w = !!0;
    if (this.dontRoll || !j79) {
      if (j7j.interval != D0e.interval) {
        r7w = !![];
      }
    } else {
      if (R$q != j79) {
        r7w = !!{};
      }
    }
    r5u.t3P();
    if (!R$q && !D0e.timeUnit) {
      D0e.timeUnit = 'minute';
    }
    if (!j79 && !j7j.timeUnit) {
      j7j.timeUnit = 'minute';
    }
    if (D0e.timeUnit != j7j.timeUnit) {
      r7w = !![];
    }
    if (!this.masterData || !this.masterData.length) {
      r7w = !![];
    }
    return r7w;
  };
  R_d.ChartEngine.prototype.getPeriodicity = function () {
    var n6d, v$l, l0E;
    n6d = this.layout;
    v$l = n6d.interval;
    l0E = n6d.timeUnit;
    if (!l0E) {
      l0E = v$l;
      v$l = 1;
    }
    r5u.t3P();
    return { period: n6d.periodicity, interval: v$l, timeUnit: l0E };
  };
};
X4 = (M9A) => {
  var u9z = v7ZLk;
  var T9P;
  T9P = M9A.CIQ;
  T9P.ChartEngine.prototype.isHistoricalMode = function () {
    var x4P, q7k, T3Q, J2k, t6M, x4C, w50;
    x4P = new Date();
    q7k = !!{};
    T3Q = this.masterData;
    if (!this.isHistoricalModeSet) {
      return ![];
    }
    if (T3Q.length) {
      J2k = 'D';
      J2k += 'T';
      t6M = this.getFirstLastDataRecord(T3Q, J2k, !!{});
      x4C = this.standardMarketIterator(t6M.DT);
      q7k = (x4C ? x4C.next() : t6M.DT) <= x4P;
      if (q7k && T9P.ChartEngine.isDailyInterval(x4C.interval)) {
        w50 = this.chart.market.getOpen();
        if (w50 && x4P < w50) {
          u9z.d$R(26);
          x4P.setHours(0, 0, 0, u9z.H9P('0', 64));
          if (+x4P == +x4C.begin) {
            q7k = !{};
          }
        }
      }
    }
    return q7k;
  };
  T9P.ChartEngine.prototype.isHome = function () {
    var D3H, l6z, n5J;
    D3H = this.chart;
    l6z = D3H.dataSet;
    n5J = D3H.animatingHorizontalScroll;
    u9z.p1T(64);
    var P1E = u9z.H9P(4, 0, 6);
    u9z.p1T(0);
    var k3c = u9z.M3e(10, 9);
    return (
      this.pixelFromTick(l6z.length - (n5J ? P1E : k3c), D3H) <
      D3H.width + D3H.panel.left
    );
  };
  T9P.ChartEngine.prototype.getPreviousBar = function (U2$, o1L, x1c) {
    u9z.Y3Y();
    return this.getNextBarInternal(U2$, o1L, x1c, -1);
  };
  T9P.ChartEngine.prototype.getNextBar = function (P2$, Y51, Y4y) {
    u9z.Y3Y();
    return this.getNextBarInternal(P2$, Y51, Y4y, 1);
  };
  u9z.t3P();
  T9P.ChartEngine.prototype.getNextBarInternal = function (V5r, N45, Z5M, M1K) {
    var S8C, K0f, G0D, U0x;
    S8C = V5r.dataSegment && V5r.dataSegment[Z5M];
    if (S8C) {
      K0f = S8C.tick;
      while (K0f > ('0' | 0) && K0f < V5r.dataSet.length) {
        u9z.d$R(18);
        K0f = u9z.H9P(M1K, K0f);
        G0D = V5r.dataSet[K0f];
        if (G0D) {
          U0x = T9P.existsInObjectChain(G0D, N45);
          if (U0x && U0x.obj[U0x.member]) {
            return G0D;
          }
        }
      }
    }
    return null;
  };
  T9P.ChartEngine.prototype.getFirstLastDataRecord = function (Z$5, W1M, C$l) {
    var t0Q;
    if (Z$5 && Z$5.length) {
      t0Q = C$l ? Z$5.length - 1 : 0;
      while (t0Q >= 0 && t0Q < Z$5.length) {
        if (Z$5[t0Q] && typeof Z$5[t0Q][W1M] != 'undefined') {
          return Z$5[t0Q];
        }
        if (C$l) {
          t0Q--;
        } else {
          t0Q++;
        }
      }
    }
    return null;
  };
  T9P.ChartEngine.prototype.leftTick = function () {
    u9z.Y3Y();
    return this.chart.dataSet.length - this.chart.scroll;
  };
  T9P.ChartEngine.prototype.getNextInterval = function (j3m, M_v, P0d) {
    var q_Z;
    if (!M_v) {
      M_v = 1;
    }
    if (P0d !== !1) {
      P0d = !0;
    }
    q_Z = this.standardMarketIterator(
      j3m,
      P0d ? this.dataZone : this.displayZone
    );
    if (!q_Z) {
      return j3m;
    }
    if (M_v < +'1') {
      u9z.p1T(22);
      return q_Z.previous(u9z.H9P(1, M_v));
    }
    return q_Z.next(M_v);
  };
  T9P.ChartEngine.prototype.standardMarketIterator = function (G51, V6k, H5e) {
    u9z.t3P();
    var T5l, f4d;
    T5l = H5e || this.chart;
    if (!T5l.market) {
      return null;
    }
    f4d = {
      begin: G51,
      interval: this.layout.interval,
      periodicity:
        this.layout.interval == 'tick'
          ? this.chart.xAxis.futureTicksInterval
          : this.layout.periodicity,
      timeUnit: this.layout.timeUnit,
      outZone: V6k,
    };
    return T5l.market.newIterator(f4d);
  };
};
u1 = (O90) => {
  var D2$, N_1;
  if (!O90.SplinePlotter) {
    O90.SplinePlotter = {};
  }
  D2$ = O90.CIQ;
  N_1 = O90.SplinePlotter;
  D2$.ChartEngine.prototype.draw = function (N7M) {
    var i1$ = v7ZLk;
    var K$k,
      W0j,
      U_l,
      S8i,
      g_0,
      c8d,
      E8s,
      c73,
      f5B,
      N2e,
      y0S,
      C0n,
      m4D,
      S6_,
      w24,
      O7_,
      Z2j,
      l$B;
    K$k = 'Studies.displaySt';
    K$k += 'udies';
    W0j = 'non';
    W0j += 'e';
    this.debug();
    if (this.destroyed) {
      return;
    }
    U_l = this.chart;
    S8i = this.layout;
    i1$.Y3Y();
    if (!U_l.canvas) {
      return;
    }
    if (!U_l.dataSet) {
      return;
    }
    if (!U_l.canvasHeight) {
      return;
    }
    i1$.p1T(0);
    var d4A = i1$.H9P(28, 26);
    this.offset = (S8i.candleWidth * this.candleWidthPercent) / d4A;
    D2$.clearCanvas(U_l.canvas, this);
    if (!this.masterData) {
      return;
    }
    if (this.runPrepend('draw', arguments)) {
      return;
    }
    if (!this.defaultColor) {
      this.getDefaultColor();
    }
    this.vectorsShowing = !1;
    this.drawPanels();
    this.yAxisLabels = [];
    this.correctIfOffEdge();
    this.createDataSegment();
    this.setBaselines(U_l);
    E8s = this.createXAxis(U_l);
    this.initializeDisplay(U_l);
    this.drawXAxis(U_l, E8s);
    try {
      this.renderYAxis(U_l);
    } catch (j2_) {
      var i9$;
      i9$ = 'reboo';
      i9$ += 't d';
      i9$ += 'ra';
      i9$ += 'w';
      if (j2_ && j2_.message === i9$) {
        return this.draw();
      }
      throw j2_;
    }
    this.currentHRParams = this.getCurrentHRParams();
    this.drawCurrentPriceLine();
    U_l.tmpWidth = Math.floor(S8i.candleWidth * this.candleWidthPercent);
    if (U_l.tmpWidth % 2 === 0) {
      U_l.tmpWidth += 1;
      if (U_l.tmpWidth > S8i.candleWidth) {
        U_l.tmpWidth -= +'2';
      }
    }
    if (U_l.tmpWidth < 0.5) {
      U_l.tmpWidth = 0.5;
    }
    for (g_0 in this.plugins) {
      c8d = this.plugins[g_0];
      if (c8d.display) {
        if (c8d.drawUnder) {
          c8d.drawUnder(this, U_l);
        }
      }
    }
    if (U_l.legend) {
      U_l.legend.colorMap = null;
    }
    if (this.controls.baselineHandle) {
      this.controls.baselineHandle.style.display = W0j;
    }
    this.rendererAction(U_l, 'underlay');
    D2$.getFn('Studies.displayStudies')(this, U_l, !0);
    this.displayChart(U_l);
    D2$.getFn(K$k)(this, U_l, !'1');
    this.rendererAction(U_l, 'overlay');
    if (U_l.legend && U_l.legend.colorMap && U_l.legendRenderer) {
      c73 = -250585236;
      f5B = 1208241361;
      N2e = 2;
      for (
        var Z8e = 1;
        i1$.e_M(Z8e.toString(), Z8e.toString().length, 49148) !== c73;
        Z8e++
      ) {
        U_l.legendRenderer(this, {
          chart: U_l,
          legendColorMap: U_l.legend.colorMap,
          coordinates: {
            x: U_l.legend.x,
            y: U_l.legend.y / U_l.panel.yAxis.top,
          },
        });
        N2e += 2;
      }
      if (i1$.e_M(N2e.toString(), N2e.toString().length, 54299) !== f5B) {
        U_l.legendRenderer(this, {
          chart: U_l,
          legendColorMap: U_l.legend.colorMap,
          coordinates: {
            x: U_l.legend.x,
            y: U_l.legend.y + U_l.panel.yAxis.top,
          },
        });
      }
    }
    for (g_0 in this.plugins) {
      c8d = this.plugins[g_0];
      if (c8d.display) {
        if (c8d.drawOver) {
          c8d.drawOver(this, U_l);
        }
      }
    }
    for (var Z7e in this.panels) {
      if (!this.panels[Z7e].hidden) {
        this.plotYAxisText(this.panels[Z7e]);
      }
    }
    for (var C3o = '0' - 0; C3o < this.yAxisLabels.length; C3o++) {
      y0S = this.yAxisLabels[C3o];
      if (
        y0S.src == 'series' &&
        y0S.args[6] &&
        y0S.args[6].drawSeriesPriceLabels === !1
      )
        continue;
      this.createYAxisLabel.apply(this, y0S.args);
    }
    if (this.createCrosshairs) {
      this.createCrosshairs();
    }
    if (this.drawVectors) {
      this.drawVectors();
    }
    this.drawCurrentHR();
    this.currentHRParams = null;
    this.displayInitialized = !!'1';
    C0n = this.controls;
    if (C0n) {
      m4D = 'b';
      m4D += 'lo';
      m4D += 'ck';
      S6_ =
        this.manageTouchAndMouse &&
        (!this.mainSeriesRenderer || !this.mainSeriesRenderer.nonInteractive);
      if (C0n.home) {
        C0n.home.style.display = S6_ && !this.isHome() ? m4D : 'none';
      }
      if (C0n.chartControls) {
        w24 = 'lef';
        w24 += 't';
        O7_ = 'n';
        O7_ += 'o';
        O7_ += 'ne';
        Z2j = 'b';
        Z2j += 'lo';
        Z2j += 'c';
        Z2j += 'k';
        l$B = 'hidd';
        l$B += 'en';
        C0n.chartControls.style.display =
          this.manageTouchAndMouse && C0n.chartControls.style.visibility !== l$B
            ? Z2j
            : O7_;
        if (C0n.chartControls.style.display === 'block') {
          D2$.efficientDOMUpdate(
            C0n.chartControls.style,
            w24,
            U_l.left + U_l.width / 2 + 'px'
          );
        }
      }
    }
    if (D2$.Marker) {
      this.positionMarkers();
    }
    if (this.quoteDriver && this.animations.zoom.hasCompleted) {
      this.quoteDriver.checkLoadMore(U_l);
    }
    this.runAppend('draw', arguments);
    this.makeAsyncCallbacks();
  };
  D2$.ChartEngine.prototype.setSeriesRenderer = function (g2_) {
    var { baseline: p6e, name: T5N, panel: r8q, yAxis: E8A } = g2_.params;
    if (this.chart.seriesRenderers[T5N]) {
      return this.chart.seriesRenderers[T5N];
    }
    if (E8A) {
      g2_.params.yAxis = this.addYAxis(this.panels[r8q], E8A);
      this.resizeChart();
    }
    g2_.stx = this;
    this.chart.seriesRenderers[T5N] = g2_;
    if (p6e) {
      this.registerBaselineToHelper(g2_);
    }
    return g2_;
  };
  D2$.ChartEngine.prototype.setMainSeriesRenderer = function (c7_) {
    var Q3m, s0Z, X7a, c4D, q38;
    Q3m = 'o';
    Q3m += 'h';
    Q3m += 'lc';
    s0Z = 'no';
    s0Z += 'n';
    s0Z += 'e';
    var { chartType: n7e, aggregationType: f6Y } = this.layout;
    var { chart: L4h } = this;
    var { custom: r3V } = L4h;
    X7a = this.mainSeriesRenderer;
    c4D = this.displayInitialized;
    if (X7a) {
      if (c7_) {
        this.setMasterData();
      }
      this.displayInitialized = !'1';
      X7a.removeAllSeries();
      this.removeSeriesRenderer(X7a);
      X7a = this.mainSeriesRenderer = null;
    }
    if (r3V && r3V.chartType) {
      n7e = r3V.chartType;
    }
    if (n7e == s0Z) {
      return;
    }
    if (f6Y && f6Y != Q3m) {
      n7e = f6Y;
    }
    q38 = D2$.Renderer.produce(n7e, {
      panel: L4h.panel.name,
      name: '_main_series',
      highlightable: !'1',
      useChartLegend: !![],
    });
    if (q38) {
      this.setSeriesRenderer(q38).attachSeries(null, { display: L4h.symbol });
      X7a = this.mainSeriesRenderer = q38;
    }
    this.displayInitialized = c4D;
    ['highLowBars', 'standaloneBars', 'barsHaveWidth'].forEach(
      function (w49) {
        L4h[w49] = this.mainSeriesRenderer && this.mainSeriesRenderer[w49];
      }.bind(this)
    );
  };
  D2$.ChartEngine.prototype.removeSeriesRenderer = function (a_N) {
    var q3w;
    v7ZLk.Y3Y();
    var { baseline: B2v, name: t1W } = a_N.params;
    q3w = this.controls[`${t1W} baseline-handle`];
    if (B2v) {
      this.removeBaselineFromHelper(a_N);
      if (q3w) {
        this.container.removeChild(q3w);
        delete this.controls[q3w];
      }
    }
    delete this.chart.seriesRenderers[t1W];
  };
  D2$.ChartEngine.prototype.getSeriesRenderer = function (e1l) {
    v7ZLk.Y3Y();
    return this.chart.seriesRenderers[e1l];
  };
  D2$.ChartEngine.prototype.getRendererFromSeries = function (K_r) {
    var G3g;
    v7ZLk.t3P();
    G3g = this.chart.seriesRenderers;
    for (var C8A in G3g) {
      for (var C2s in G3g[C8A].seriesParams) {
        if (G3g[C8A].seriesParams[C2s].id == K_r) {
          return G3g[C8A];
        }
      }
    }
    return null;
  };
  D2$.ChartEngine.prototype.startClip = function (F6T, q_H) {
    var e9l, m3g, V3t, S9_, e9L;
    if (!F6T) {
      F6T = this.chart.panel.name;
    }
    e9l = this.panels[F6T];
    m3g = e9l.yAxis;
    V3t = this.chart;
    V3t.context.save();
    V3t.context.beginPath();
    S9_ = e9l.left;
    e9L = e9l.width;
    if (q_H) {
      S9_ = 0;
      e9L = this.width;
    } else if (e9l.yaxisLHS && e9l.yaxisLHS.length) {
      S9_++;
      e9L--;
    }
    V3t.context.rect(S9_, m3g.top, e9L, m3g.height);
    V3t.context.clip();
  };
  D2$.ChartEngine.prototype.endClip = function () {
    v7ZLk.Y3Y();
    this.chart.context.restore();
  };
  D2$.ChartEngine.prototype.setLineStyle = function (F9P, m1h) {
    var J3N, h7e;
    J3N = {};
    if (F9P && typeof F9P == 'object') {
      J3N = F9P;
    } else {
      J3N.color = F9P;
    }
    v7ZLk.Y3Y();
    if (!J3N.color && !J3N.pattern && !J3N.width && !J3N.baseColor) {
      J3N = null;
    }
    if (!m1h) {
      m1h = this.chart;
    }
    h7e = +'1';
    if (J3N && J3N.width) {
      h7e = J3N.width;
    }
    if (J3N && J3N.pattern) {
      J3N.pattern = D2$.borderPatternToArray(h7e, J3N.pattern);
    }
    m1h.lineStyle = J3N;
  };
  D2$.ChartEngine.prototype.setGapLines = function (A0s, p7p) {
    v7ZLk.Y3Y();
    var j3P;
    if (!p7p) {
      p7p = this.chart;
    }
    j3P = {};
    if (A0s && typeof A0s == 'object') {
      j3P = A0s;
    } else if (typeof A0s === 'boolean') {
      return (p7p.gaplines = A0s);
    } else {
      j3P.color = A0s;
    }
    if (!j3P.color && !j3P.pattern && !j3P.fillMountain) {
      j3P = null;
    }
    if (j3P && j3P.pattern) {
      j3P.pattern = D2$.borderPatternToArray(j3P.width, j3P.pattern);
    }
    if (j3P && j3P.width <= +'0') {
      j3P.width = null;
    }
    p7p.gaplines = j3P;
  };
  D2$.ChartEngine.prototype.getGapColorFunction = function (
    F9B,
    r0y,
    S_Q,
    t6J,
    t_4
  ) {
    v7ZLk.t3P();
    if (typeof S_Q != 'object') {
      S_Q = { color: S_Q };
    }
    return function (Z1t, g7n, p0y) {
      var V2S, l9a, N4d, P9$, d9a;
      V2S = t_4 ? t_4(Z1t, g7n, p0y) : S_Q;
      if (V2S.color) {
        V2S = V2S.color;
      }
      l9a = g7n[F9B];
      if (!l9a && l9a !== '0' >> 0) {
        l9a = g7n[r0y];
      }
      if (!p0y && (l9a || l9a === 0)) {
        return { color: V2S, pattern: S_Q.pattern, width: S_Q.width };
      }
      if (!t6J) {
        return null;
      }
      if (typeof t6J != 'object') {
        if (typeof t6J == 'string') {
          t6J = { color: t6J };
        } else {
          t6J = {};
        }
      }
      N4d = -781482731;
      P9$ = -1101796346;
      d9a = 2;
      for (
        var F8r = 1;
        v7ZLk.q78(F8r.toString(), F8r.toString().length, 14062) !== N4d;
        F8r++
      ) {
        return {
          color: t6J.color && V2S,
          pattern: t6J.pattern && S_Q.pattern,
          width: t6J.width && S_Q.width,
        };
      }
      if (v7ZLk.e_M(d9a.toString(), d9a.toString().length, 14758) !== P9$) {
        return {
          color: t6J.color && V2S,
          pattern: t6J.pattern && S_Q.pattern,
          width: t6J.width && S_Q.width,
        };
      }
      return {
        color: t6J.color || V2S,
        pattern: t6J.pattern || S_Q.pattern,
        width: t6J.width || S_Q.width,
      };
    };
  };
  D2$.ChartEngine.prototype.drawLineChart = function (r2c, r9R, y8c, W93) {
    var i_B, x4n, x0$, X82, P7o, N4H, d3u, R6b, s3y, v1a, M1s;
    i_B = this.chart;
    x4n = i_B.context;
    x0$ = i_B.lineStyle || {};
    X82 = this.canvasStyle(r9R);
    if (!W93) {
      W93 = {};
    }
    this.startClip(r2c.name);
    P7o = W93.width || x0$.width || X82.width;
    if (P7o && parseInt(P7o, 10) <= 25) {
      x4n.lineWidth = Math.max(1, D2$.stripPX(P7o));
    } else {
      x4n.lineWidth = 1;
    }
    W93.pattern = W93.pattern || x0$.pattern || X82.borderTopStyle;
    W93.pattern = D2$.borderPatternToArray(x4n.lineWidth, W93.pattern);
    this.canvasColor(r9R);
    N4H = W93.color || x0$.color;
    if (N4H) {
      if (N4H == 'auto') {
        N4H = this.defaultColor;
      }
      if (W93.opacity && W93.opacity !== 1) {
        N4H = D2$.hexToRgba(D2$.colorToHex(N4H), parseFloat(W93.opacity));
      }
      x4n.strokeStyle = N4H;
    }
    W93.skipProjections = !0;
    d3u = W93.field || i_B.defaultPlotField;
    R6b = W93.subField || i_B.defaultPlotField || 'Close';
    v7ZLk.Y3Y();
    s3y = W93.gapDisplayStyle;
    if (!s3y && s3y !== !1) {
      s3y = W93.gaps;
    }
    if (!s3y && s3y !== ![]) {
      s3y = i_B.gaplines;
    }
    if (!s3y) {
      s3y = 'transparent';
    }
    W93.gapDisplayStyle = s3y;
    v1a = this.getGapColorFunction(
      d3u,
      R6b,
      { color: x4n.strokeStyle, pattern: W93.pattern, width: x4n.lineWidth },
      s3y,
      y8c
    );
    if (r2c.chart.tension) {
      W93.tension = r2c.chart.tension;
    }
    M1s = this.plotDataSegmentAsLine(d3u, r2c, W93, v1a);
    if (!M1s.colors.length) {
      M1s.colors.push(x4n.strokeStyle);
    }
    x4n.lineWidth = +'1';
    this.endClip();
    return W93.returnObject ? M1s : M1s.colors;
  };
  D2$.ChartEngine.prototype.drawChannelChart = function (Y9b, K1F, L4I) {
    var L1u, u5a, S_W, O7F, U2y, Z_v, s0y, S$b, Z4s, n4$, w$F, i_7;
    L1u = 'L';
    L1u += 'o';
    L1u += 'w';
    u5a = 'fi';
    u5a += 'el';
    u5a += 'd';
    S_W = Object.assign({}, L4I);
    S_W.color = L4I.color;
    O7F = this.drawLineChart(Y9b, S_W.style, K1F, S_W);
    U2y = S_W.border_color_up || this.getCanvasColor('stx_channel_up');
    Z_v = S_W.border_color_down || this.getCanvasColor('stx_channel_down');
    S_W[L4I.field ? 'subField' : 'field'] = S_W.field_high || 'High';
    S_W.color = U2y;
    s0y = this.drawLineChart(Y9b, S_W.style, K1F, S_W);
    S_W[L4I.field ? 'subField' : u5a] = S_W.field_low || L1u;
    S_W.color = Z_v;
    S$b = this.drawLineChart(Y9b, S_W.style, K1F, S_W);
    S_W[L4I.field ? 'subField' : 'field'] =
      L4I.subField || this.chart.defaultPlotField || 'Close';
    n4$ = [];
    w$F = [];
    for (Z4s = 0; Z4s < s0y.points.length; Z4s += '2' >> 0) {
      n4$.push([s0y.points[Z4s], s0y.points[Z4s + '1' * 1]]);
    }
    for (Z4s = 0; Z4s < S$b.points.length; Z4s += 2) {
      w$F.push([S$b.points[Z4s], S$b.points[Z4s + ('1' ^ 0)]]);
    }
    v7ZLk.d$R(127);
    var f3y = v7ZLk.M3e(6, 7, 3, 116, 2);
    i_7 = this.chart.context.lineWidth / f3y;
    for (Z4s = O7F.points.length - 2; Z4s >= 0; Z4s -= 2) {
      n4$.push([O7F.points[Z4s], O7F.points[Z4s + ('1' >> 32)] - i_7]);
      w$F.push([O7F.points[Z4s], O7F.points[Z4s + +'1'] + i_7]);
    }
    this.startClip(Y9b.name);
    S_W.color = U2y;
    D2$.fillArea(this, n4$, S_W);
    S_W.color = Z_v;
    D2$.fillArea(this, w$F, S_W);
    this.endClip();
    O7F.colors = O7F.colors.concat(s0y.colors).concat(S$b.colors);
    return L4I.returnObject ? O7F : O7F.colors;
  };
  D2$.ChartEngine.prototype.connectTheDots = function (
    Y_d,
    v8s,
    i5$,
    R_P,
    V7W,
    n0w
  ) {
    var q$S = v7ZLk;
    var V16,
      O_k,
      K16,
      s94,
      V5o,
      I$6,
      h2H,
      y5M,
      A6A,
      w97,
      M$z,
      G8M,
      B3B,
      H$C,
      n$Q,
      k3n,
      C8i,
      s5R,
      j5T,
      a3D,
      k3C,
      y0f,
      Z3k,
      t69,
      s_$,
      W_n,
      N1Q,
      W1k;
    V16 = 'unde';
    V16 += 'fined';
    if (!n0w) {
      n0w = {};
    }
    if (n0w.pattern == 'none') {
      return;
    }
    if (V7W === !!{}) {
      V7W = this.chart.panel;
    }
    if (R_P === null || typeof R_P == V16) {
      R_P = this.chart.context;
    }
    if (Y_d.length < 4) {
      return;
    }
    O_k = 0;
    K16 = this.chart.canvasHeight;
    s94 = +'0';
    V5o = this.chart.width;
    if (V7W) {
      I$6 = 1757504116;
      h2H = 1376971436;
      y5M = 2;
      for (
        var w1e = 1;
        q$S.e_M(w1e.toString(), w1e.toString().length, 70120) !== I$6;
        w1e++
      ) {
        K16 = V7W.yAxis.bottom;
        y5M += 2;
      }
      if (q$S.e_M(y5M.toString(), y5M.toString().length, 32441) !== h2H) {
        K16 = V7W.yAxis.bottom;
      }
      O_k = V7W.yAxis.top;
    }
    R_P.lineWidth = 1.1;
    q$S.t3P();
    if (typeof v8s == 'object') {
      R_P.strokeStyle = v8s.color;
      if (v8s.opacity) {
        R_P.globalAlpha = v8s.opacity;
      } else {
        R_P.globalAlpha = 1;
      }
      R_P.lineWidth = D2$.stripPX(v8s.width);
    } else {
      A6A = 'aut';
      A6A += 'o';
      if (!v8s || v8s == A6A || D2$.isTransparent(v8s)) {
        w97 = 1384806559;
        M$z = -271267534;
        G8M = +'2';
        for (
          var q4$ = 1;
          q$S.e_M(q4$.toString(), q4$.toString().length, 56537) !== w97;
          q4$++
        ) {
          R_P.strokeStyle = this.defaultColor;
          G8M += 2;
        }
        if (q$S.e_M(G8M.toString(), G8M.toString().length, 77199) !== M$z) {
          R_P.strokeStyle = this.defaultColor;
        }
      } else {
        R_P.strokeStyle = v8s;
      }
    }
    if (n0w.opacity) {
      R_P.globalAlpha = n0w.opacity;
    }
    if (n0w.lineWidth) {
      R_P.lineWidth = n0w.lineWidth;
    }
    B3B = D2$.borderPatternToArray(R_P.lineWidth, n0w.pattern);
    R_P.beginPath();
    for (var E9M = 0; E9M < Y_d.length - 2; E9M += 2) {
      H$C = Y_d[E9M];
      q$S.d$R(18);
      n$Q = Y_d[q$S.H9P(1, E9M)];
      q$S.d$R(18);
      k3n = Y_d[q$S.M3e(2, E9M)];
      q$S.d$R(18);
      C8i = Y_d[q$S.H9P(3, E9M)];
      if (isNaN(H$C) || isNaN(k3n) || isNaN(n$Q) || isNaN(C8i)) {
        return;
      }
      s5R = 0.0;
      j5T = +'1.0';
      q$S.d$R(0);
      a3D = q$S.H9P(k3n, H$C);
      q$S.p1T(0);
      k3C = q$S.H9P(C8i, n$Q);
      for (var M50 = +'0'; M50 < ('4' | 4); M50++) {
        if (M50 === 0) {
          y0f = -a3D;
          q$S.d$R(0);
          Z3k = -q$S.M3e(s94, H$C);
        }
        if (M50 == 1) {
          y0f = a3D;
          q$S.d$R(0);
          Z3k = q$S.M3e(V5o, H$C);
        }
        if (M50 == 2) {
          y0f = -k3C;
          q$S.p1T(0);
          Z3k = -q$S.M3e(O_k, n$Q);
        }
        if (M50 == '3' - 0) {
          y0f = k3C;
          q$S.d$R(0);
          Z3k = q$S.M3e(K16, n$Q);
        }
        q$S.p1T(41);
        t69 = q$S.H9P(y0f, Z3k);
        if ((C8i || C8i === 0) && y0f === +'0' && Z3k < 0) {
          return !{};
        }
        if (y0f < ('0' ^ 0)) {
          if (t69 > j5T) {
            return !!0;
          } else if (t69 > s5R) {
            s5R = t69;
          }
        } else if (y0f > 0) {
          if (t69 < s5R) {
            return ![];
          } else if (t69 < j5T) {
            j5T = t69;
          }
        }
      }
      q$S.p1T(128);
      s_$ = q$S.M3e(H$C, a3D, s5R);
      q$S.p1T(128);
      W_n = q$S.H9P(n$Q, k3C, s5R);
      q$S.p1T(128);
      N1Q = q$S.M3e(H$C, a3D, j5T);
      q$S.d$R(128);
      W1k = q$S.H9P(n$Q, k3C, j5T);
      try {
        R_P.setLineDash(B3B && B3B.length ? B3B : []);
        R_P.moveTo(s_$, W_n);
        R_P.lineTo(N1Q, W1k);
      } catch (d4a) {}
    }
    R_P.stroke();
    R_P.closePath();
    R_P.globalAlpha = 1;
    R_P.lineWidth = 1;
  };
  D2$.ChartEngine.prototype.plotSpline = function (
    S1N,
    X_M,
    C2A,
    e7d,
    h$t,
    c4U,
    n4k
  ) {
    var C36, U2Q;
    C36 = 'unde';
    C36 += 'fined';
    if (!n4k) {
      n4k = {};
    }
    if (n4k.pattern == 'none') {
      return;
    }
    if (c4U === !!{}) {
      c4U = this.chart.panel;
    }
    if (h$t === null || typeof h$t == C36) {
      h$t = this.chart.context;
    }
    h$t.save();
    h$t.lineWidth = 1.1;
    if (typeof C2A == 'object') {
      h$t.strokeStyle = C2A.color;
      if (C2A.opacity) {
        h$t.globalAlpha = C2A.opacity;
      } else {
        h$t.globalAlpha = 1;
      }
      h$t.lineWidth = D2$.stripPX(C2A.width);
    } else {
      if (!C2A || C2A == 'auto' || D2$.isTransparent(C2A)) {
        h$t.strokeStyle = this.defaultColor;
      } else {
        h$t.strokeStyle = C2A;
      }
    }
    if (n4k.opacity) {
      h$t.globalAlpha = n4k.opacity;
    }
    if (n4k.lineWidth) {
      h$t.lineWidth = n4k.lineWidth;
    }
    U2Q = D2$.borderPatternToArray(h$t.lineWidth, n4k.pattern);
    if (n4k.pattern && h$t.setLineDash) {
      h$t.setLineDash(U2Q);
      h$t.lineDashOffset = 0;
    }
    h$t.beginPath();
    N_1.plotSpline(S1N, X_M, h$t);
    h$t.stroke();
    h$t.closePath();
    h$t.restore();
  };
  D2$.ChartEngine.prototype.rawWatermark = function (V_c, X4T, Z86, n7k) {
    var j28;
    j28 = 'alp';
    j28 += 'habetic';
    this.canvasFont('stx_watermark', V_c);
    V_c.fillStyle = this.defaultColor;
    V_c.globalAlpha = 0.5;
    v7ZLk.Y3Y();
    this.chart.context.textBaseline = j28;
    V_c.fillText(n7k, X4T, Z86);
    V_c.globalAlpha = +'1';
  };
  D2$.ChartEngine.prototype.watermark = function (D4f, C9F) {
    var y1u = v7ZLk;
    var b9E, V_G, C1M, q_L, A8U, j3n, c0J;
    b9E = 'stx_w';
    b9E += 'atermark';
    V_G = 'm';
    V_G += 'iddle';
    C1M = 'botto';
    C1M += 'm';
    if (C9F && typeof C9F != 'object') {
      C9F = { h: arguments[1], v: arguments[2], text: arguments[3] };
    }
    C9F = {
      h: C9F.h || 'left',
      v: C9F.v || C1M,
      text: C9F.text || '',
      hOffset: C9F.hOffset === +'0' ? 0 : C9F.hOffset || 10,
      vOffset: C9F.vOffset === +'0' ? 0 : C9F.vOffset || 20,
      context: C9F.context || this.chart.context,
    };
    q_L = C9F.context;
    if (!q_L) {
      return;
    }
    A8U = this.panels[D4f];
    if (!A8U || A8U.hidden) {
      return;
    }
    j3n = A8U.yAxis.bottom - C9F.vOffset;
    if (C9F.v == 'top') {
      j3n = A8U.top + C9F.vOffset;
    } else if (C9F.v == V_G) {
      y1u.d$R(13);
      var F5I = y1u.H9P(151, 9, 17, 1);
      j3n = (A8U.top + A8U.yAxis.bottom) / F5I;
    }
    q_L.save();
    this.canvasFont('stx_watermark', q_L);
    this.canvasColor(b9E, q_L);
    q_L.textBaseline = 'alphabetic';
    c0J = A8U.left + C9F.hOffset;
    if (C9F.h == 'right') {
      c0J = A8U.right - C9F.hOffset;
    } else if (C9F.h == 'center') {
      y1u.p1T(0);
      var G_d = y1u.H9P(10, 8);
      c0J = (A8U.right + A8U.left - q_L.measureText(C9F.text).width) / G_d;
    }
    q_L.globalAlpha = 0.5;
    if (this.highlightedDraggable) {
      q_L.globalAlpha *= +'0.3';
    }
    q_L.fillText(C9F.text, c0J, j3n);
    q_L.restore();
  };
  D2$.ChartEngine.prototype.displayErrorAsWatermark = function (F_E, o2n) {
    var B6m = v7ZLk;
    var r1S, a3t, L3J, w0p, F5t, F_T, A$A, G0v, Q3S, k26, Z_c, M_5, S6O;
    r1S = 'b';
    r1S += 'ot';
    r1S += 'tom';
    a3t = 'stx_wate';
    a3t += 'rmark';
    if (!o2n) {
      return;
    }
    if (!F_E) {
      F_E = 'chart';
    }
    L3J = this.panels[F_E];
    if (!L3J || L3J.hidden) {
      return;
    }
    w0p = L3J.state;
    if (!w0p) {
      L3J.state = w0p = {};
    }
    F5t = w0p.studyErrors;
    if (!F5t) {
      w0p.studyErrors = F5t = new Set();
    }
    if (F5t.has(o2n)) {
      return;
    }
    F_T = w0p.watermarkOffset || +'10';
    A$A = 10;
    G0v = this.getCanvasFontSize(a3t);
    if (this.chart && this.chart.chartControls) {
      Q3S = L3J.yAxis.bottom;
      k26 = this.chart.chartControls;
      B6m.d$R(0);
      Z_c = B6m.H9P(Q3S, F_T);
      B6m.p1T(0);
      M_5 = B6m.H9P(Z_c, G0v);
      if (
        (Z_c > k26.offsetTop && Z_c < k26.offsetTop + k26.offsetHeight) ||
        (M_5 > k26.offsetTop && M_5 < k26.offsetTop + k26.offsetHeight)
      ) {
        F_T = Q3S - k26.offsetTop + A$A;
      }
    }
    F5t.add(o2n);
    S6O = { h: 'center', v: r1S, text: o2n, vOffset: F_T };
    B6m.d$R(18);
    F_T += B6m.M3e(A$A, G0v);
    w0p.watermarkOffset = F_T;
    this.watermark(F_E, S6O);
  };
  D2$.ChartEngine.prototype.displayChart = function (O9h) {
    if (this.runPrepend('displayChart', arguments)) {
      return;
    }
    this.rendererAction(O9h, 'main');
    this.runAppend('displayChart', arguments);
  };
};
T7 = (F3d) => {
  var s2l;
  s2l = F3d.CIQ;
  s2l.ChartEngine.prototype.cloneStyle = function (V_d) {
    var o6T = v7ZLk;
    o6T.Y3Y();
    var h6E, v9D, a6g, s_S, W$2, k2x, J0d, Q1_;
    h6E = {};
    function B3e(J0$) {
      o6T.Y3Y();
      return J0$[1].toUpperCase();
    }
    v9D = !{};
    for (var m9q in V_d) {
      a6g = V_d[m9q];
      if (m9q == 'backgroundAttachment') {
        v9D = !!1;
      }
      if (v9D) {
        if (a6g && a6g.constructor == String && isNaN(m9q)) {
          h6E[m9q] = a6g;
        }
      } else if (!isNaN(m9q)) {
        s_S = V_d.getPropertyValue(a6g);
        if (s_S) {
          a6g = a6g.split('-');
          o6T.p1T(0);
          W$2 = o6T.H9P('0', 0);
          k2x = a6g.length;
          J0d = a6g[0];
          while (++W$2 < k2x) {
            J0d += a6g[W$2].charAt(0).toUpperCase() + a6g[W$2].slice('1' ^ 0);
          }
          h6E[J0d] = s_S;
        }
      } else {
        Q1_ = m9q.replace(s2l.camelCaseRegExp, B3e);
        h6E[Q1_] = a6g;
      }
    }
    return h6E;
  };
  s2l.ChartEngine.prototype.canvasStyle = function (X2H) {
    var z7V, p_w, T9t;
    z7V = this.styles[X2H];
    if (!z7V) {
      p_w = document.createElement('div');
      p_w.className = X2H;
      this.container.appendChild(p_w);
      T9t = getComputedStyle(p_w);
      z7V = this.styles[X2H] = this.cloneStyle(T9t);
      this.container.removeChild(p_w);
      if (!T9t) {
        this.styles[X2H] = null;
      }
    }
    return z7V;
  };
  s2l.ChartEngine.prototype.colorOrStyle = function (Y_W) {
    if (
      Y_W.indexOf(
        2844 === '700' * 1
          ? 8.35e3
          : ('560.6' * 1, 6905) === 8740
          ? 3140 === (88.51, 620.7)
            ? ('T', !!0)
            : 4.18e3
          : '#'
      ) != -('1' << 0)
    ) {
      return Y_W;
    }
    if (
      Y_W.indexOf(
        (404.31, 336.86) >= (4928, 9410)
          ? 0x23fc
          : (679.1, 6138) != (2806, 5830)
          ? '('
          : +'6810' < 2409
          ? (769.89, 8.92e3)
          : (636.27, 3.71e3)
      ) != -1
    ) {
      return Y_W;
    }
    if (Y_W == 'transparent') {
      return Y_W;
    }
    v7ZLk.t3P();
    return this.canvasStyle(Y_W);
  };
  s2l.ChartEngine.prototype.clearStyles = function () {
    this.styles = {};
    this.defaultColor = '';
  };
  s2l.ChartEngine.prototype.setStyle = function (s8U, B2B, u_H) {
    if (!this.styles[s8U]) {
      this.canvasStyle(s8U);
    }
    if (!this.styles[s8U]) {
      this.styles[s8U] = {};
    }
    this.styles[s8U][s2l.makeCamelCase(B2B)] = u_H;
  };
  s2l.ChartEngine.prototype.canvasFont = function (F3t, D6c) {
    var N8O = v7ZLk;
    var s4V, H_p;
    if (!D6c) {
      D6c = this.chart.context;
    }
    s4V = this.canvasStyle(F3t);
    if (!s4V) {
      return;
    }
    N8O.p1T(40);
    var m8I = N8O.M3e(6286, 15, 87994);
    N8O.d$R(129);
    N8O.t3P();
    var i7f = N8O.M3e(10, 92, 12, 50, 12);
    N8O.p1T(130);
    var j4v = N8O.H9P(208932, 6056, 10, 2, 7);
    N8O.d$R(78);
    var U04 = N8O.H9P(5, 89, 2, 18, 18);
    N8O.d$R(0);
    var Q_1 = N8O.H9P(108680, 98800);
    N8O.p1T(89);
    var O9Y = N8O.H9P(26, 9, 16, 2620, 7);
    N8O.d$R(131);
    var C7g = N8O.H9P(2300, 3632, 18400, 6);
    N8O.d$R(64);
    var D30 = N8O.M3e(11, 4, 183);
    H_p =
      s4V.fontStyle +
      ' ' +
      s4V.fontWeight +
      ((m8I, i7f) == (273.54, j4v)
        ? ('9091' << U04, 174.14) < Q_1
          ? 229.92
          : 'J'
        : ' ') +
      s4V.fontSize +
      ((O9Y, 137.49) == 734.16 ? (C7g, D30) : ' ') +
      s4V.fontFamily;
    if (H_p.indexOf('undefined') == -1) {
      D6c.font = H_p;
    } else {
      this.styles[F3t] = null;
      N8O.d$R(18);
      console.log(N8O.M3e(F3t, 'bad css style for class '));
    }
  };
  s2l.ChartEngine.prototype.canvasColor = function (P_P, W37) {
    var i2k, A4p, S30, D8$;
    i2k = 'u';
    i2k += 'ndefine';
    i2k += 'd';
    if (!W37) {
      W37 = this.chart.context;
    }
    A4p = this.canvasStyle(P_P);
    if (!A4p) {
      return;
    }
    S30 = A4p.color;
    if (!S30) {
      S30 = this.defaultColor;
    }
    W37.globalAlpha = 1;
    W37.fillStyle = S30;
    W37.strokeStyle = S30;
    D8$ = A4p.opacity;
    if (typeof D8$ != i2k) {
      W37.globalAlpha = D8$;
    }
  };
  s2l.ChartEngine.prototype.getCanvasFontSize = function (O$T) {
    var d3A, E1W, x84;
    d3A = '1';
    d3A += '2';
    E1W = this.canvasStyle(O$T);
    v7ZLk.Y3Y();
    x84 = E1W.fontSize;
    if (!x84) {
      x84 = d3A;
    }
    return parseInt(s2l.stripPX(x84), 10);
  };
  s2l.ChartEngine.prototype.getCanvasColor = function (T3_) {
    var q_u;
    q_u = this.canvasStyle(T3_);
    return q_u.color;
  };
  s2l.ChartEngine.prototype.getDefaultColor = function () {
    var K1T, L13, i49, m1I, J2c, Y4F;
    v7ZLk.Y3Y();
    this.defaultColor = '#000000';
    K1T = null;
    L13 = this.chart.container;
    while (!K1T || s2l.isTransparent(K1T)) {
      i49 = 't';
      i49 += 'rans';
      i49 += 'pare';
      i49 += 'nt';
      m1I = getComputedStyle(L13);
      if (!m1I) {
        return;
      }
      K1T = m1I.backgroundColor;
      if (s2l.isTransparent(K1T)) {
        K1T = i49;
      }
      L13 = L13.parentNode;
      if (!L13 || !L13.tagName) break;
    }
    if (K1T) {
      if (K1T == 'transparent') {
        K1T = '#FFFFFF';
      }
      this.containerColor = K1T;
      if (!s2l.isTransparent(K1T)) {
        J2c = s2l.hsv(K1T);
        Y4F = J2c[2];
        if (Y4F > '0.65' - 0) {
          this.defaultColor = '#000000';
        } else {
          this.defaultColor = '#FFFFFF';
        }
      } else {
        this.defaultColor = '#000000';
      }
    } else {
      this.containerColor = '#FFFFFF';
    }
  };
};
P$ = (o9O) => {
  var K$b = v7ZLk;
  var z$u;
  z$u = o9O.CIQ;
  z$u.ChartEngine.XAxis = function (t8s) {
    K$b.t3P();
    for (var V1m in t8s) {
      this[V1m] = t8s[V1m];
    }
  };
  K$b.Y3Y();
  z$u.ChartEngine.XAxis.setPropertiesForInterval = {
    year: (I9R, O2C, p$j, P8i) => {
      I9R.level = Number(I9R.raw) % 5 === 0 ? 0 : '1' >> 64;
    },
    month: (r1M, h2J, r2P, x0H) => {
      var c4O, D0W, G4e, m$S;
      c4O = 'bounda';
      K$b.t3P();
      c4O += 'ry';
      D0W = Number(r1M.raw);
      G4e = h2J.DT;
      m$S = G4e.getFullYear();
      if (D0W === 0) {
        r1M.level = m$S % 5 === 0 ? 0 : 1;
      } else {
        r1M.level = D0W % 3 === 0 ? 2 : 3;
      }
      r1M.text = z$u.monthAsDisplay(D0W, !!'', x0H);
      if (r1M.grid === c4O) {
        r1M.boundaryOnlyText = D0W ? r1M.text : m$S.toString();
        K$b.d$R(13);
        var G_r = K$b.M3e(112, 19, 6, 1);
        r1M.text += " '" + m$S.toString().substring(G_r);
        if (!D0W) {
          r1M.alwaysAsBoundary = !![];
        }
      }
    },
    week: null,
    day: (L19, y5r, m01, U4L) => {
      var G7E, z8G, X5l, E8C, v3X, H28, L2X;
      G7E = y5r.DT;
      z8G = G7E.getDay();
      X5l = G7E.getDate();
      E8C = G7E.getMonth();
      v3X = G7E.getFullYear();
      if (L19.grid === 'boundary') {
        if (m01 && m01.isFirstMarketDayOfMonth(G7E)) {
          H28 = ' ';
          H28 += "'";
          L2X = z$u.monthAsDisplay(E8C, !'1', U4L);
          K$b.d$R(132);
          L19.text = K$b.M3e(100, v3X, L2X, H28);
          L19.boundaryOnlyText = E8C ? L2X : v3X.toString();
          L19.alwaysAsBoundary = !![];
          L19.level = E8C ? (E8C % 3 === 0 ? 1 : 2) : 0;
          return;
        }
        if (U4L.internationalizer) {
          L19.text = U4L.internationalizer.monthDay.format(G7E);
        } else {
          K$b.p1T(112);
          L19.text = K$b.H9P(
            3511 > 14.8 ? '/' : ('142.38' - 0, 470.91),
            E8C,
            1,
            X5l
          );
        }
      }
      if ((m01 && m01.isFirstMarketDayOfWeek(G7E)) || (!m01 && z8G === 0)) {
        L19.level = 4;
      } else if (z8G > 0) {
        L19.level = 6;
      } else {
        L19.level = +'7';
      }
    },
    minute: (e7S, h17, T$l, E0w) => {
      var m79, S5n, z1m;
      m79 = h17.DT;
      S5n = Number(e7S.raw) + (e7S.marketOpenOffset || '0' << 0);
      z1m = [720, 360, 120, 60, 30, +'15', 5];
      e7S.level = z1m.length;
      for (var f$8 = 0; f$8 < z1m.length; f$8++) {
        if (S5n % z1m[f$8] === 0) {
          e7S.level = f$8;
          break;
        }
      }
      e7S.text = z$u.timeAsDisplay(h17.DT, E0w, z$u.MINUTE);
      if (e7S.grid === 'boundary') {
        if (S5n === 0) {
          e7S.alwaysAsBoundary = !![];
          if (E0w.internationalizer) {
            e7S.text = E0w.internationalizer.monthDay.format(m79);
          } else {
            K$b.p1T(61);
            var U2A = K$b.H9P(5, 0, 4);
            e7S.text = m79.getMonth() + U2A + '/' + m79.getDate();
          }
        }
      }
    },
    second: (m$2, I8z, b1m, O8p) => {
      var u1g, X87, e7x;
      u1g = 'bounda';
      u1g += 'r';
      u1g += 'y';
      K$b.p1T(25);
      X87 = [K$b.H9P(1, '30'), 15, 5];
      m$2.level = X87.length;
      for (var m0Y = 0; m0Y < X87.length; m0Y++) {
        if (Number(m$2.raw) % X87[m0Y] === 0) {
          m$2.level = m0Y;
          break;
        }
      }
      K$b.d$R(64);
      var I04 = K$b.M3e(3, 1, 6864);
      K$b.p1T(77);
      var E0x = K$b.H9P(6857, 116565, 15, 18);
      m$2.text = m$2.raw + (I04 > E0x ? 's' : (!!'', 'E'));
      if (m$2.grid === u1g && m$2.raw == 0) {
        e7x = I8z.DT;
        m$2.alwaysAsBoundary = !![];
        if (!e7x.getHours()) {
          if (O8p.internationalizer) {
            m$2.text = O8p.internationalizer.monthDay.format(e7x);
          } else {
            K$b.p1T(8);
            var H$T = K$b.H9P(14, 146880, 138734);
            m$2.text =
              e7x.getMonth() +
              +'1' +
              ((H$T, +'4660') !== (+'730.82', 400.32)
                ? '/'
                : ('r', +'653.55')) +
              e7x.getDate();
          }
        } else {
          m$2.text = z$u.timeAsDisplay(e7x, O8p, z$u.MINUTE);
        }
      }
    },
    millisecond: (C0$, F2v, z$$, p$N) => {
      var v0A, a4S, S7h, Y18;
      K$b.p1T(21);
      v0A = [250, K$b.M3e('50', 0), +'10'];
      a4S = 1795912687;
      S7h = -+'1443374240';
      Y18 = 2;
      for (
        var Z2K = 1;
        K$b.e_M(Z2K.toString(), Z2K.toString().length, 54426) !== a4S;
        Z2K++
      ) {
        C0$.level = v0A.length;
        Y18 += 2;
      }
      if (K$b.e_M(Y18.toString(), Y18.toString().length, 33253) !== S7h) {
        C0$.level = v0A.length;
      }
      for (var Q0e = 0; Q0e < v0A.length; Q0e++) {
        if (Number(C0$.raw) % v0A[Q0e] === 0) {
          C0$.level = Q0e;
          break;
        }
      }
      C0$.text = C0$.raw + 'ms';
    },
    tick: (H_x, L$o, p$2, C2g) => {
      H_x.text = z$u.timeAsDisplay(L$o.DT, C2g, z$u.MILLISECOND);
    },
  };
  z$u.ChartEngine.XAxis.lineBaseLevel = 500;
  z$u.extend(
    z$u.ChartEngine.XAxis.prototype,
    {
      formatter: null,
      adjustTimeZone: !!1,
      displayBorder: !'',
      displayGridLines: !!1,
      fitLeftToRight: null,
      fitTight: null,
      maxLevelToDisplay: +'0',
      noDraw: null,
      minimumLabelWidth: 50,
      futureTicks: !!'1',
      futureTicksInterval: 1,
    },
    !!{}
  );
  z$u.ChartEngine.XAxisLabel = function (T7w) {
    if (typeof T7w === 'number') {
      this.hz = T7w;
      this.grid = arguments[1];
      this.text = arguments[2];
    } else {
      Object.assign(this, T7w);
    }
    K$b.t3P();
    if (!this.level && this.level !== 0) {
      this.level =
        this.grid === 'boundary' ? +'0' : z$u.ChartEngine.XAxis.lineBaseLevel;
    }
    if (typeof this.text === 'undefined') {
      this.text = this.raw;
    }
    this.text = this.text.toString();
  };
  z$u.ChartEngine.prototype.createXAxis = function (i2j) {
    var N50, Q_a, v_a;
    N50 = 'crea';
    N50 += 'teXA';
    N50 += 'xis';
    if (i2j.dataSegment.length <= 0) {
      return null;
    }
    if (i2j.xAxis.noDraw) {
      return null;
    }
    Q_a = [i2j];
    v_a = this.runPrepend('createXAxis', Q_a);
    if (v_a) {
      return v_a;
    }
    if (this.mainSeriesRenderer && this.mainSeriesRenderer.createXAxis) {
      v_a = this.mainSeriesRenderer.createXAxis(i2j);
    } else if (this.createTickXAxisWithDates && i2j.xAxis.fitLeftToRight) {
      v_a = this.createTickXAxisWithDates(i2j);
    } else {
      v_a = this.createSpacedDateXAxis(i2j);
    }
    this.headsUpHR();
    this.runAppend(N50, Q_a);
    return v_a;
  };
  z$u.ChartEngine.prototype.createXAxisLabel = function (o0b) {
    var P6D, H7V, H3X, t2e, K9C, i5O, a_f, d5c, K2o, N0L, G2D, o5a, l$P, S2h;
    if (arguments[0] instanceof z$u.ChartEngine.Panel) {
      o0b = {
        panel: arguments[0],
        txt: arguments[1],
        x: arguments[2],
        backgroundColor: arguments[3],
        color: arguments[+'4'],
        pointed: arguments[5],
        padding: arguments[6],
      };
    }
    P6D = o0b.panel;
    H7V = o0b.txt;
    H3X = o0b.x;
    t2e = o0b.backgroundColor;
    K9C = o0b.color;
    i5O = o0b.pointed;
    a_f = o0b.padding === 0 ? 0 : o0b.padding || 2;
    d5c = this.chart.context;
    K2o = 'st';
    K2o += 'x-';
    K2o += 'float-date';
    K$b.d$R(133);
    var p8R = K$b.H9P(1, 6, 3, 7, 3);
    N0L = this.getCanvasFontSize(K2o) + a_f * ('2' * p8R);
    this.canvasFont(K2o, d5c);
    try {
      K$b.d$R(67);
      var a4v = K$b.M3e(15, 13, 4, 4, 1);
      G2D = d5c.measureText(H7V).width + a_f * a4v;
    } catch (L7U) {
      K$b.p1T(21);
      G2D = K$b.M3e('0', 0);
    }
    o5a = P6D.top + P6D.height - N0L - a_f;
    if (H3X + G2D / ('2' * 1) < P6D.left || H3X - G2D / 2 > P6D.right) {
      return;
    }
    if (!i5O) {
      if (H3X + G2D / 2 > P6D.right) {
        K$b.d$R(0);
        var x3n = K$b.H9P(12, 10);
        H3X = P6D.right - G2D / x3n;
      }
      if (H3X - G2D / 2 < P6D.left) {
        H3X = P6D.left + G2D / +'2';
      }
    }
    d5c.fillStyle = t2e;
    z$u.roundRect({
      ctx: d5c,
      x: H3X - G2D / ('2' << 0),
      top: o5a,
      width: G2D,
      height: N0L,
      radius: +'3',
      fill: !0,
    });
    l$P = P6D.bottom - P6D.yAxis.bottom - N0L;
    d5c.beginPath();
    if (i5O) {
      K$b.d$R(0);
      d5c.moveTo(K$b.H9P(H3X, l$P), o5a);
      K$b.p1T(64);
      d5c.lineTo(H3X, K$b.H9P(l$P, 1, o5a));
      K$b.p1T(18);
      d5c.lineTo(K$b.H9P(l$P, H3X), o5a);
      d5c.closePath();
      d5c.fill();
    } else {
      d5c.moveTo(H3X, o5a);
      K$b.p1T(0);
      d5c.lineTo(H3X, K$b.H9P(o5a, l$P));
      d5c.strokeStyle = t2e;
      d5c.stroke();
    }
    d5c.textBaseline = 'top';
    d5c.fillStyle = K9C ? K9C : z$u.chooseForegroundColor(t2e);
    K$b.Y3Y();
    if (d5c.fillStyle == t2e) {
      S2h = '#00';
      S2h += '0';
      S2h += '00';
      S2h += '0';
      if (t2e.toUpperCase() == '#FFFFFF') {
        d5c.fillStyle = S2h;
      } else {
        d5c.fillStyle = '#FFFFFF';
      }
    }
    K$b.p1T(134);
    d5c.fillText(
      H7V,
      K$b.M3e('2', H3X, G2D, a_f, 1),
      K$b.H9P(2, a_f, o5a, K$b.d$R(135))
    );
  };
};
Q4 = (r43) => {
  var F10 = v7ZLk;
  var o2O;
  o2O = r43.CIQ;
  o2O.createLabel = function (Q5$) {
    var E59;
    Q5$.ctx.textBaseline = 'middle';
    Q5$.ctx.fillStyle = Q5$.color
      ? Q5$.color
      : o2O.chooseForegroundColor(Q5$.backgroundColor);
    if (Q5$.ctx.fillStyle === Q5$.backgroundColor) {
      E59 = '#';
      E59 += 'FF';
      E59 += 'FFF';
      E59 += 'F';
      if (Q5$.backgroundColor.toUpperCase() == '#FFFFFF') {
        Q5$.ctx.fillStyle = '#000000';
      } else {
        Q5$.ctx.fillStyle = E59;
      }
    }
    Q5$.ctx.fillText(Q5$.txt, Q5$.x + Q5$.margin.left, Q5$.y + Q5$.margin.top);
    Q5$.ctx.textAlign = 'left';
  };
  o2O.roundRectArrow = function (T9B) {
    o2O.roundRect(T9B, 'arrow');
  };
  o2O.semiRoundRect = function (B0E) {
    var M2r;
    M2r = 'fl';
    M2r += 'u';
    M2r += 's';
    M2r += 'h';
    o2O.roundRect(B0E, M2r);
  };
  o2O.rect = function (S1x) {
    S1x.radius = 0;
    o2O.roundRect(S1x);
  };
  o2O.noop = function (g6B) {
    g6B.color = g6B.backgroundColor;
    o2O.createLabel(g6B);
  };
  o2O.tickedRect = function (X76) {
    var f1D;
    o2O.rect(X76);
    F10.p1T(136);
    var r25 = F10.M3e(13, 5, 20);
    f1D = Math.round(X76.top + X76.height / r25) + 0.5;
    X76.ctx.beginPath();
    X76.ctx.moveTo(X76.x - 2, f1D);
    X76.ctx.lineTo(X76.x, f1D);
    X76.ctx.stroke();
    X76.ctx.closePath();
  };
  o2O.roundRect = function (E41, U23) {
    var n7v,
      q01,
      x5J,
      r29,
      Z85,
      A00,
      H4V,
      J2b,
      S_J,
      S3U,
      E5E,
      o18,
      n2a,
      H0F,
      N6l,
      A6i,
      b9r,
      F3a,
      L75;
    n7v = 'f';
    n7v += 'l';
    n7v += 'us';
    n7v += 'h';
    q01 = 'u';
    q01 += 'n';
    q01 += 'd';
    q01 += 'efined';
    x5J = 'undef';
    x5J += 'ined';
    if (arguments.length === 9) {
      E41 = {
        ctx: arguments[0],
        x: arguments[1],
        top: arguments[2],
        width: arguments[+'3'],
        height: arguments[4],
        radius: arguments[5],
        fill: arguments[6],
        stroke: arguments[7],
        edge: arguments[8],
      };
    }
    r29 = E41.stroke;
    Z85 = E41.x;
    A00 = E41.top;
    H4V = E41.width;
    J2b = E41.height;
    S_J = E41.radius;
    S3U = E41.fill;
    E5E = E41.ctx;
    if (typeof r29 == x5J) {
      r29 = !![];
    }
    if (typeof S_J === q01) {
      S_J = 5;
      if (H4V < 0) {
        S_J = -5;
      }
    }
    o18 = H4V < 0 ? S_J * -1 : S_J;
    if (S_J && !U23) {
      F10.p1T(0);
      Z85 = F10.M3e(Z85, 1);
    }
    F10.d$R(18);
    n2a = F10.H9P(S_J, Z85);
    F10.d$R(18);
    H0F = F10.H9P(H4V, Z85);
    F10.d$R(18);
    N6l = F10.H9P(o18, A00);
    F10.d$R(18);
    A6i = F10.H9P(J2b, A00);
    F10.d$R(0);
    b9r = F10.M3e(H0F, S_J);
    F10.p1T(0);
    F3a = F10.H9P(A6i, o18);
    E5E.beginPath();
    E5E.moveTo(n2a, A00);
    E5E.lineTo(b9r, A00);
    E5E.quadraticCurveTo(H0F, A00, H0F, N6l);
    E5E.lineTo(H0F, F3a);
    E5E.quadraticCurveTo(H0F, A6i, b9r, A6i);
    E5E.lineTo(n2a, A6i);
    if (U23 == n7v) {
      E5E.lineTo(Z85, A6i);
      E5E.lineTo(Z85, A00);
    } else if (U23 == 'arrow') {
      F10.d$R(0);
      E5E.quadraticCurveTo(Z85, A6i, F10.M3e(Z85, S_J), F3a);
      L75 = H4V < '0' >> 64 ? 1 : -1;
      F10.p1T(137);
      E5E.lineTo(F10.M3e(L75, 2, Z85, J2b), F10.M3e(2, J2b, A00, F10.p1T(120)));
      F10.d$R(0);
      E5E.lineTo(F10.H9P(Z85, S_J), N6l);
      E5E.quadraticCurveTo(Z85, A00, n2a, A00);
    } else {
      E5E.quadraticCurveTo(Z85, A6i, Z85, F3a);
      E5E.lineTo(Z85, N6l);
      E5E.quadraticCurveTo(Z85, A00, n2a, A00);
    }
    E5E.closePath();
    if (E41.backgroundColor) {
      E5E.fillStyle = E41.backgroundColor;
    }
    if (r29) {
      E5E.stroke();
    }
    if (S3U) {
      E5E.fill();
    }
    if (E41.txt) {
      o2O.createLabel(E41);
    }
  };
  o2O.ChartEngine.YAxis = function (D4$) {
    F10.t3P();
    for (var n6E in D4$) {
      this[n6E] = D4$[n6E];
    }
    if (!this.name) {
      this.name = o2O.uniqueID();
    }
    if (this.position == 'none') {
      this.width = 0;
    }
  };
  o2O.extend(
    o2O.ChartEngine.YAxis.prototype,
    {
      name: null,
      high: null,
      low: null,
      shadow: null,
      logHigh: null,
      logLow: null,
      logShadow: null,
      allowSharing: !!'1',
      multiplier: null,
      bottom: null,
      top: null,
      height: null,
      left: null,
      renderers: [],
      studies: [],
      lowValue: null,
      highValue: null,
      semiLog: null,
      priceTick: null,
      printDecimalPlaces: null,
      yAxisPlotter: null,
      highlight: null,
    },
    !![]
  );
  o2O.ChartEngine.YAxis.defaultShadowBreaks = [
    [1000, 2],
    [5, 4],
    [0.001, 8],
  ];
  o2O.ChartEngine.YAxis.smallChartShadowBreaks = [
    [10, 2],
    [1, 4],
  ];
  o2O.ChartEngine.YAxis.prototype.maxDecimalPlaces = null;
  o2O.ChartEngine.YAxis.prototype.max = null;
  o2O.ChartEngine.YAxis.prototype.min = null;
  o2O.ChartEngine.YAxis.prototype.decimalPlaces = null;
  o2O.ChartEngine.YAxis.prototype.idealTickSizePixels = null;
  o2O.ChartEngine.YAxis.prototype.minimumPriceTick = null;
  o2O.ChartEngine.YAxis.prototype.fractional = null;
  o2O.ChartEngine.YAxis.prototype.displayBorder = !!1;
  o2O.ChartEngine.YAxis.prototype.displayGridLines = !!{};
  o2O.ChartEngine.YAxis.prototype.noDraw = null;
  o2O.ChartEngine.YAxis.prototype.drawCurrentPriceLabel = !!'1';
  o2O.ChartEngine.YAxis.prototype.drawSeriesPriceLabels = !!'1';
  o2O.ChartEngine.YAxis.prototype.drawPriceLabels = !![];
  o2O.ChartEngine.YAxis.prototype.goldenRatioYAxis = !![];
  o2O.ChartEngine.YAxis.prototype.yaxisLabelStyle = null;
  o2O.ChartEngine.YAxis.prototype.justifyRight = null;
  o2O.ChartEngine.YAxis.prototype.flipped = !1;
  o2O.ChartEngine.YAxis.prototype.textBackground = !!0;
  o2O.ChartEngine.YAxis.prototype.priceFormatter = null;
  o2O.ChartEngine.YAxis.prototype.bottomOffset = 0;
  o2O.ChartEngine.YAxis.prototype.topOffset = 0;
  o2O.ChartEngine.YAxis.prototype.initialMarginTop = 10;
  o2O.ChartEngine.YAxis.prototype.initialMarginBottom = 10;
  o2O.ChartEngine.YAxis.prototype.zoom = 0;
  o2O.ChartEngine.YAxis.prototype.scroll = 0;
  o2O.ChartEngine.YAxis.prototype.heightFactor = 1;
  Object.defineProperty(o2O.ChartEngine.YAxis.prototype, 'width', {
    configurable: !!1,
    enumerable: !!1,
    get: function () {
      if (this.position === 'none') {
        return 0;
      }
      F10.t3P();
      return this._dynamicWidth || this._width;
    },
    set: function (i1x) {
      this._width = i1x;
      if (this._dynamicWidth < i1x) {
        this._dynamicWidth = NaN;
      }
    },
  });
  o2O.ChartEngine.YAxis.prototype.width = +'60';
  o2O.ChartEngine.YAxis.prototype.smallScreenWidth = 50;
  o2O.ChartEngine.YAxis.prototype.textStyle = null;
  o2O.ChartEngine.YAxis.prototype.position = null;
  o2O.ChartEngine.YAxis.prototype.pretty = !![];
  F10.p1T(20);
  o2O.ChartEngine.YAxis.prototype.increments = [F10.H9P('1', 32), 2.5, 5];
  o2O.ChartEngine.YAxis.prototype.prettySemiLog = !![];
  o2O.ChartEngine.YAxis.prototype.shadowBreaks =
    o2O.ChartEngine.YAxis.defaultShadowBreaks;
  o2O.ChartEngine.YAxis.prototype.getYAxis = function (I5L) {
    return this;
  };
  o2O.ChartEngine.YAxis.prototype.isShared = function (u8H, M7T) {
    var z7Y, M22;
    z7Y = this.studies.length;
    M22 = this.renderers.length;
    if (z7Y > 1) {
      return !!1;
    }
    F10.Y3Y();
    if (M22 && z7Y) {
      return !!{};
    }
    if (!M22) {
      return ![];
    }
    if (M22 > 1 && M7T) {
      return !!'1';
    }
    for (var f3L = M22 - 1; f3L >= 0; f3L--) {
      if (u8H.chart.seriesRenderers[this.renderers[f3L]].params.dependentOf) {
        M22--;
      }
    }
    F10.d$R(138);
    return F10.H9P(1, M22);
  };
  o2O.ChartEngine.YAxis.prototype.setBackground = function (L6f, y9C) {
    var X7P;
    if (!y9C) {
      y9C = {};
    }
    if (!y9C.color) {
      y9C.color = 'auto';
    }
    X7P = [
      [this.left, this.top],
      [this.left, this.bottom],
      [this.left + this.width, this.bottom],
      [this.left + this.width, this.top],
    ];
    o2O.fillArea(L6f, X7P, { color: y9C.color, opacity: y9C.opacity });
  };
  o2O.ChartEngine.YAxis.prototype.setBreakpointWidth = function (T_y) {
    var n8P;
    if (!T_y) {
      return;
    }
    var { width: D$Z, smallScreenWidth: T_w } = o2O.ChartEngine.YAxis.prototype;
    F10.p1T(82);
    n8P = F10.M3e('break-sm', T_y);
    this.width = n8P ? T_w : D$Z;
  };
  o2O.ChartEngine.prototype.getLabelOffsetInPixels = function (D_A, x5o) {
    var Q5I, H5l, l6m;
    Q5I = !this.mainSeriesRenderer || !this.mainSeriesRenderer.standaloneBars;
    if (
      this.yaxisLabelStyle == 'roundRectArrow' &&
      !(Q5I && this.extendLastTick && D_A.yaxisPaddingRight !== 0)
    ) {
      H5l = 3;
      F10.d$R(0);
      var h3u = F10.H9P(28, 26);
      l6m = this.getCanvasFontSize('stx_yaxis') + H5l * h3u;
      F10.d$R(25);
      return F10.H9P(0.66, l6m);
    }
    return 0;
  };
  o2O.ChartEngine.prototype.flipChart = function (w53) {
    if (this.layout.flipped == w53) {
      return;
    }
    this.layout.flipped = w53;
    this.chart.yAxis.flipped = w53;
    this.changeOccurred('layout');
    this.draw();
  };
  o2O.ChartEngine.prototype.calculateYAxisMargins = function (t3E) {
    F10.t3P();
    var e1k, w_j;
    e1k = this.chart;
    w_j = e1k.panel.yaxisLHS.concat(e1k.panel.yaxisRHS).includes(t3E)
      ? e1k.yaxisMarginMultiplier
      : 1;
    if (t3E.heightFactor) {
      F10.p1T(92);
      var n$s = F10.H9P(2, 1, 1);
      t3E.zoom = t3E.height * (n$s - t3E.heightFactor);
    }
    t3E.zoom += (t3E.initialMarginTop + t3E.initialMarginBottom) * w_j;
    F10.d$R(139);
    var b8D = F10.M3e(9, 9, 13, 10, 2);
    t3E.scroll = ((t3E.initialMarginTop - t3E.initialMarginBottom) * w_j) / b8D;
    if (t3E.zoom > t3E.height) {
      t3E.zoom = 0;
      t3E.scroll = 0;
    }
  };
  o2O.ChartEngine.prototype.resetDynamicYAxis = function (V5g) {
    var g$m, x8K, V50, o_V;
    g$m = 'resetDyna';
    g$m += 'micYAxi';
    F10.Y3Y();
    g$m += 's';
    if (this.runPrepend('resetDynamicYAxis', arguments)) {
      return;
    }
    x8K = !!'';
    for (var O$p in this.panels) {
      V50 = this.panels[O$p];
      if (V5g && V5g.chartName && V50.chart.name !== V5g.chartName) continue;
      if (!V50.yaxisLHS || !V50.yaxisRHS) continue;
      o_V = V50.yaxisLHS.concat(V50.yaxisRHS);
      for (var n9I = 0; n9I < o_V.length; n9I++) {
        if (o_V[n9I]._dynamicWidth) {
          o_V[n9I]._dynamicWidth = NaN;
          x8K = !![];
        }
      }
    }
    if (x8K && (!V5g || !V5g.noRecalculate)) {
      this.calculateYAxisPositions();
    }
    this.runAppend(g$m, arguments);
  };
  o2O.ChartEngine.prototype.notifyBreakpoint = function (O29) {
    F10.t3P();
    var A3a, Z_B, Y8J, E_t, K53, b8_, g$6, g2N;
    A3a = 'break';
    A3a += '-md';
    if (this.chart.breakpoint === O29) {
      return;
    }
    if (!['break-lg', A3a, 'break-sm'].includes(O29)) {
      return;
    }
    Z_B = 1597545899;
    Y8J = 1267095739;
    E_t = 2;
    for (
      var h2m = 1;
      F10.e_M(h2m.toString(), h2m.toString().length, 94564) !== Z_B;
      h2m++
    ) {
      var { chart: z3h } = this;
      var { dynamicYAxis: A$N } = z3h;
      K53 = o2O.ThemeHelper || new o2O.ThemeHelper({ stx: this });
      this.clearStyles();
      if (K53) {
        K53.update(this);
      }
      z3h.breakpoint = O29;
      E_t += 2;
    }
    if (F10.q78(E_t.toString(), E_t.toString().length, 40612) !== Y8J) {
      var { chart: z3h } = this;
      var { dynamicYAxis: A$N } = z3h;
      K53 = o2O.ThemeHelper && new o2O.ThemeHelper({ stx: this });
      this.clearStyles();
      if (K53) {
        K53.update(this);
      }
      z3h.breakpoint = O29;
    }
    for (var S5b in this.panels) {
      b8_ = this.panels[S5b];
      g$6 = b8_.yaxisRHS.concat(b8_.yaxisLHS);
      for (var S8h = 0; S8h < g$6.length; S8h++) {
        g2N = g$6[S8h];
        g2N.setBreakpointWidth(O29);
      }
    }
    if (A$N) {
      this.resetDynamicYAxis({ chartName: z3h.name });
    }
  };
  o2O.ChartEngine.prototype.adjustYAxisHeightOffset = function (q0j, o3H) {
    var D1i, I6a, C2I, i46;
    D1i = o3H.topOffset;
    I6a = o3H.bottomOffset;
    F10.t3P();
    if (D1i + I6a > q0j.height) {
      C2I = 'The sum of yAx';
      C2I +=
        'is.topOffset and yAxis.bottomOffset cannot be greater than the panel height. Both values will ';
      C2I += 'be reset to 0.';
      console.log(C2I);
      o3H.bottomOffset = +'0';
      o3H.topOffset = +'0';
    }
    if (!this.xaxisHeight && this.xaxisHeight !== 0) {
      this.xaxisHeight = this.getCanvasFontSize('stx_xaxis') + +'4';
      if (this.chart.xAxis.displayBorder || this.axisBorders) {
        this.xaxisHeight += 3;
      }
    }
    i46 =
      (this.xAxisAsFooter &&
        q0j.bottom > this.chart.canvasHeight - this.xaxisHeight) ||
      (!this.xAxisAsFooter && q0j == this.chart.panel);
    if (i46) {
      I6a += this.xaxisHeight;
    }
    o3H.top = q0j.top + D1i;
    o3H.bottom = q0j.bottom - I6a;
  };
  o2O.ChartEngine.prototype.plotYAxisGrid = function (s1F) {
    var z5m, q$w, D24, Q6X;
    F10.Y3Y();
    z5m = 'p';
    z5m += 'lotYA';
    z5m += 'xisGrid';
    q$w = 'plotYAx';
    q$w += 'isG';
    q$w += 'rid';
    if (this.runPrepend(q$w, arguments)) {
      return;
    }
    D24 = this.getBackgroundCanvas().context;
    Q6X = s1F.yAxis;
    if (Q6X.yAxisPlotter) {
      Q6X.yAxisPlotter.draw(D24, 'grid');
    }
    this.runAppend(z5m, arguments);
  };
  o2O.ChartEngine.prototype.plotYAxisText = function (O6I) {
    var m6I, P63, A$f, h5j;
    if (this.runPrepend('plotYAxisText', arguments)) {
      return;
    }
    m6I = this.getBackgroundCanvas().context;
    this.canvasFont('stx_yaxis', m6I);
    function J1O(b5f) {
      if (!b5f.yAxisPlotter) {
        return;
      }
      if (b5f.noDraw || !b5f.width) {
        return;
      }
      if (b5f.justifyRight) {
        m6I.textAlign = 'right';
      } else if (b5f.justifyRight === !!'') {
        m6I.textAlign = 'left';
      }
      b5f.yAxisPlotter.draw(m6I, 'text');
    }
    this.canvasColor('stx_yaxis', m6I);
    m6I.textBaseline = 'middle';
    P63 = O6I.yaxisLHS;
    for (A$f = +'0'; A$f < P63.length; A$f++) {
      h5j = 'ri';
      h5j += 'g';
      h5j += 'ht';
      m6I.textAlign = h5j;
      J1O(P63[A$f]);
    }
    P63 = O6I.yaxisRHS;
    for (A$f = 0; A$f < P63.length; A$f++) {
      m6I.textAlign = 'left';
      J1O(P63[A$f]);
    }
    m6I.textAlign = 'left';
    m6I.textBaseline = 'alphabetic';
    this.runAppend('plotYAxisText', arguments);
  };
  o2O.ChartEngine.prototype.decimalPlacesFromPriceTick = function (f7Q, s0t) {
    var L4X, d_m, u$B;
    if (s0t) {
      f7Q /= s0t;
    }
    F10.Y3Y();
    if (f7Q < 0.0001) {
      return 8;
    }
    L4X = 1670594174;
    d_m = +'2065767176';
    u$B = 2;
    for (
      var B9Y = 1;
      F10.q78(B9Y.toString(), B9Y.toString().length, 3557) !== L4X;
      B9Y++
    ) {
      if (f7Q > 3218) {
        return 6;
      }
      if (f7Q > 100) {
        F10.p1T(25);
        return F10.M3e(1, '4');
      }
      u$B += 2;
    }
    if (F10.q78(u$B.toString(), u$B.toString().length, 52464) !== d_m) {
      if (f7Q < 0.01) {
        return 4;
      }
      if (f7Q < 0.1) {
        return 2;
      }
    }
    if (f7Q < '1' - 0) {
      return 1;
    }
    return +'0';
  };
  o2O.ChartEngine.prototype.formatYAxisPrice = function (
    I$Q,
    u1V,
    b44,
    l$t,
    H4r
  ) {
    var C66, t5x, z53, m0T, Y_n;
    if (I$Q === null || typeof I$Q == 'undefined' || isNaN(I$Q)) {
      return '';
    }
    if (!u1V) {
      u1V = this.chart.panel;
    }
    C66 = l$t ? l$t : u1V.yAxis;
    t5x = b44;
    if (!t5x && t5x !== 0) {
      t5x = C66.printDecimalPlaces;
    }
    if (!t5x && t5x !== +'0') {
      t5x = this.decimalPlacesFromPriceTick(
        C66.priceTick,
        C66.idealTickSizePixels
      );
    }
    z53 = C66 == u1V.chart.yAxis ? +'20000' : +'1000';
    if (C66.priceTick >= z53) {
      I$Q = I$Q.toFixed(t5x);
      return o2O.condenseInt(I$Q);
    }
    m0T = this.internationalizer;
    if (m0T && H4r !== !{}) {
      Y_n = m0T.priceFormatters.length;
      if (t5x >= Y_n) {
        F10.p1T(116);
        t5x = F10.M3e(Y_n, '1', 1);
      }
      I$Q = m0T.priceFormatters[t5x].format(I$Q);
    } else {
      I$Q = I$Q.toFixed(t5x);
    }
    return I$Q;
  };
  o2O.ChartEngine.prototype.calculateYAxisRange = function (
    P0G,
    q2Z,
    u6c,
    r16
  ) {
    var i8p, u6Z, W$9, k6a, N23, L0v, e_K, K9r, x$f, I4P;
    i8p = 'l';
    i8p += 'o';
    i8p += 'g';
    if (u6c == Number.MAX_VALUE) {
      u6c = 0;
      F10.d$R(21);
      r16 = F10.M3e('0', 0);
    }
    u6Z = P0G.height;
    W$9 = null;
    k6a = null;
    this.adjustYAxisHeightOffset(P0G, q2Z);
    q2Z.height = q2Z.bottom - q2Z.top;
    N23 = Math.round(Math.abs(u6Z / +'5'));
    if (q2Z.zoom >= +'0' && u6Z - Math.abs(q2Z.scroll) < N23) {
      F10.d$R(65);
      var x79 = F10.M3e(15, 10, 149);
      F10.p1T(90);
      var W2y = F10.M3e(18, 0, 1, 17);
      q2Z.scroll = (u6Z - N23) * (q2Z.scroll < 0 ? x79 : W2y);
    }
    L0v = P0G.chart.name === P0G.name && P0G.yAxis.name === q2Z.name;
    e_K =
      u6c > 0 &&
      (this.layout.semiLog || this.layout.chartScale == i8p) &&
      !P0G.chart.isComparison &&
      this.layout.aggregationType != 'pandf';
    if (u6c || u6c === 0) {
      if (r16 - u6c === 0) {
        K9r = Math.pow(
          10,
          -(
            u6c.toString() +
            (566.81 != (481.08, 7594)
              ? 4526 != 3700
                ? 109.14 > '5539' << 0
                  ? ('m', '0x1d76' >> 64)
                  : '.'
                : 0x97a
              : ![])
          ).split(
            9898 > (329.82, 372.64)
              ? (1506, 5369) === 36
                ? ('1834' << 0, 876.99) === (7810, 6601)
                  ? +'0xb53'
                  : ('562.46' - 0, 443.17)
                : '.'
              : (660.44, 'e')
          )['1' << 32].length
        );
        if (K9r == ('1' | 1)) {
          K9r = +'100';
        }
        F10.d$R(18);
        W$9 = F10.H9P(K9r, u6c);
        F10.d$R(0);
        k6a = F10.M3e(u6c, K9r);
      } else {
        if (L0v && e_K && (r16 || r16 === 0)) {
          x$f = Math.log(u6c) / Math.LN10;
          I4P = Math.log(r16) / Math.LN10;
          W$9 = Math.pow(10, I4P);
          F10.p1T(25);
          k6a = Math.pow(F10.H9P(1, '10'), x$f);
        } else {
          W$9 = r16;
          k6a = u6c;
        }
      }
      q2Z.high = W$9;
      q2Z.low = k6a;
    }
    if (q2Z.max || q2Z.max === 0) {
      q2Z.high = q2Z.max;
    }
    if (q2Z.min || q2Z.min === 0) {
      q2Z.low = q2Z.min;
    }
    q2Z.shadow = q2Z.high - q2Z.low;
    if (L0v) {
      if (q2Z.semiLog != e_K) {
        this.clearPixelCache();
        q2Z.semiLog = e_K;
      }
      q2Z.flipped = this.layout.flipped;
    }
  };
  o2O.ChartEngine.prototype.renderYAxis = function (r9u) {
    var W5O, g6h, i2G, k00, q4e, G3n, n48, i19, Z$n, u2Y, D0d;
    W5O = 'yAx';
    W5O += 'is';
    g6h = 'rebo';
    g6h += 'o';
    g6h += 't d';
    g6h += 'raw';
    if (this.runPrepend('renderYAxis', arguments)) {
      return;
    }
    if (this.checkLogScale()) {
      throw new Error(g6h);
    }
    F10.Y3Y();
    this.rendererAction(r9u, W5O);
    var { context: c_J } = this.getBackgroundCanvas(r9u);
    for (var Y6Q in this.panels) {
      i2G = this.panels[Y6Q];
      if (i2G.chart != r9u) continue;
      k00 = i2G.yaxisRHS.concat(i2G.yaxisLHS);
      for (q4e = 0; q4e < k00.length; q4e++) {
        n48 = 'Studies.doP';
        n48 += 'ostDrawYAx';
        n48 += 'is';
        G3n = k00[q4e];
        this.calculateYAxisRange(i2G, G3n, G3n.lowValue, G3n.highValue);
        i19 = o2O.getFn('Studies.getYAxisParameters', {})(this, G3n);
        i19.yAxis = G3n;
        this.createYAxis(i2G, i19);
        this.drawYAxis(i2G, i19);
        o2O.getFn(n48)(this, G3n);
      }
      for (q4e = 0; q4e < k00.length; q4e++) {
        G3n = k00[q4e];
        if (G3n.dropzone) {
          Z$n = this.canvasStyle('stx-subholder dropzone left');
          if (Z$n) {
            u2Y = 'a';
            u2Y += 'l';
            u2Y += 'l';
            c_J.save();
            c_J.strokeStyle = Z$n.borderLeftColor;
            c_J.lineWidth = parseFloat(Z$n.borderLeftWidth);
            c_J.beginPath();
            if (G3n.dropzone == u2Y) {
              c_J.strokeRect(G3n.left, G3n.top, G3n.width, G3n.height);
            } else {
              D0d = G3n.left + (G3n.dropzone == 'left' ? 0 : G3n.width);
              c_J.moveTo(D0d, G3n.top);
              c_J.lineTo(D0d, G3n.top + G3n.height);
              c_J.stroke();
            }
            c_J.restore();
          }
        }
      }
      if (this.displayDragOK) {
        this.displayDragOK(!!'1');
      }
    }
    this.runAppend('renderYAxis', arguments);
  };
  o2O.ChartEngine.prototype.updateFloatHRLabel = function (N7U) {
    F10.Y3Y();
    var w0I, X5e, f9c, q7Y, c$l, w03, l4R, M29, B8r, E7p, O9w;
    if (!this.floatCanvas) {
      return;
    }
    w0I = N7U.yaxisLHS.concat(N7U.yaxisRHS);
    X5e = this.crossYActualPos ? this.crossYActualPos : this.cy;
    if (this.floatCanvas.isDirty) {
      o2O.clearCanvas(this.floatCanvas, this);
    }
    if (this.controls.crossX && this.controls.crossX.style.display == 'none') {
      return;
    }
    if (this.controls.crossY) {
      f9c = 'p';
      f9c += 'x';
      q7Y = N7U.left;
      c$l = N7U.width;
      w03 = 7;
      if (this.yaxisLabelStyle == 'roundRectArrow') {
        c$l -= w03;
        if (q7Y > 0) {
          q7Y += w03;
        }
        if (N7U.yaxisLHS.length && N7U.yaxisRHS.length) {
          c$l -= w03;
        }
      }
      F10.d$R(18);
      this.controls.crossY.style.left = F10.M3e('px', q7Y);
      F10.d$R(18);
      this.controls.crossY.style.width = F10.M3e(f9c, c$l);
    }
    for (var t4h = 0; t4h < w0I.length; t4h++) {
      l4R = 'stx-flo';
      l4R += 'a';
      l4R += 't-p';
      l4R += 'rice';
      M29 = w0I[t4h];
      B8r = this.transformedPriceFromPixel(X5e, N7U, M29);
      if (isNaN(B8r)) continue;
      if ((M29.min || M29.min === 0) && B8r < M29.min) continue;
      if ((M29.max || M29.max === 0) && B8r > M29.max) continue;
      E7p = null;
      if (M29 !== N7U.chart.yAxis) {
        E7p = M29.decimalPlaces;
      }
      if (M29.priceFormatter) {
        if (!E7p && E7p !== 0) {
          E7p = this.decimalPlacesFromPriceTick(
            M29.priceTick,
            M29.idealTickSizePixels
          );
        }
        B8r = M29.priceFormatter(this, N7U, B8r, E7p);
      } else {
        B8r = this.formatYAxisPrice(B8r, N7U, E7p, M29);
      }
      O9w = this.canvasStyle(l4R);
      this.createYAxisLabel(
        N7U,
        B8r,
        X5e,
        O9w.backgroundColor,
        O9w.color,
        this.floatCanvas.context,
        M29
      );
      this.floatCanvas.isDirty = !![];
    }
  };
  o2O.ChartEngine.prototype.whichYAxis = function (r02, g9t, y5n) {
    var Q4t, D4F, g8e, i0n;
    Q4t = 'undef';
    Q4t += 'ined';
    if (typeof g9t === 'undefined') {
      g9t = this.cx;
    }
    if (typeof y5n === Q4t) {
      y5n = this.cy;
    }
    D4F = this.whichPanel(y5n);
    if (r02 && r02 == D4F) {
      g8e = r02.yaxisLHS.concat(r02.yaxisRHS);
      for (var U7F = +'0'; U7F < g8e.length; U7F++) {
        i0n = g8e[U7F];
        if (i0n.left <= g9t && i0n.left + i0n.width >= g9t) {
          return i0n;
        }
      }
    }
    F10.Y3Y();
    return null;
  };
  o2O.ChartEngine.prototype.yaxisMatches = function (S9l, U8o) {
    if (
      !S9l ||
      !S9l.getYAxis ||
      !U8o ||
      !(U8o instanceof o2O.ChartEngine.YAxis)
    ) {
      return ![];
    }
    F10.Y3Y();
    return S9l.getYAxis(this).name == U8o.name;
  };
  o2O.ChartEngine.prototype.createYAxisLabel = function (
    o_k,
    Y4n,
    D$B,
    l3X,
    w4c,
    C_m,
    b$o
  ) {
    var T0I,
      h1T,
      T7Z,
      y7u,
      j4x,
      e7i,
      z7p,
      p7t,
      Y83,
      d11,
      b9J,
      L5v,
      Y1m,
      E1Z,
      e$7,
      Y3X,
      F6h;
    T0I = 'l';
    T0I += 'ef';
    T0I += 't';
    h1T = 'stx_';
    h1T += 'y';
    h1T += 'axi';
    h1T += 's';
    T7Z = b$o ? b$o : o_k.yAxis;
    if (T7Z.drawPriceLabels === !'1' || T7Z.noDraw || !T7Z.width) {
      return;
    }
    y7u = C_m ? C_m : this.chart.context;
    F10.d$R(21);
    j4x = F10.H9P('3', 0);
    e7i = this.getCanvasFontSize('stx_yaxis') + j4x * +'2';
    this.canvasFont(h1T, y7u);
    z7p =
      T7Z.displayBorder === null ? o_k.yAxis.displayBorder : T7Z.displayBorder;
    if (this.axisBorders === !1) {
      z7p = ![];
    }
    if (this.axisBorders === !!{}) {
      z7p = !![];
    }
    p7t = z7p ? '3' | 0 : 0;
    Y83 = 3;
    try {
      F10.p1T(92);
      var b8v = F10.H9P(10, 0, 5);
      d11 = y7u.measureText(Y4n).width + p7t + j4x * b8v;
    } catch (R2t) {
      d11 = T7Z.width;
    }
    F10.t3P();
    Y1m = T7Z.position === null ? o_k.chart.yAxis.position : T7Z.position;
    if (Y1m === T0I) {
      L5v = T7Z.left + T7Z.width;
      if (T7Z.width < 0) {
        L5v -= T7Z.width + d11;
      }
      Y83 = -3;
      if (T7Z.justifyRight === !{}) {
        y7u.textAlign = 'left';
        F10.d$R(61);
        var o5Y = F10.H9P(6, 0, 7);
        d11 = T7Z.width * o5Y;
        b9J = L5v - T7Z.width + j4x;
      } else {
        E1Z = 'r';
        E1Z += 'ight';
        y7u.textAlign = E1Z;
        F10.d$R(22);
        d11 = F10.M3e(1, d11);
        F10.d$R(64);
        b9J = F10.H9P(j4x, p7t, L5v);
      }
    } else {
      L5v = T7Z.left;
      if (T7Z.width < 0) {
        L5v += T7Z.width - d11;
      }
      if (T7Z.justifyRight) {
        e$7 = 'ri';
        e$7 += 'ght';
        y7u.textAlign = e$7;
        d11 = T7Z.width;
        b9J = T7Z.left + d11 - j4x;
      } else {
        y7u.textAlign = 'left';
        F10.p1T(28);
        b9J = F10.M3e(j4x, L5v, p7t);
      }
    }
    if (D$B + e7i / 2 > T7Z.bottom) {
      F10.d$R(91);
      var x6f = F10.M3e(5, 23, 0, 20);
      D$B = T7Z.bottom - e7i / x6f;
    }
    if (D$B - e7i / 2 < T7Z.top) {
      F10.p1T(8);
      var z2$ = F10.M3e(8, 10, 16);
      D$B = T7Z.top + e7i / z2$;
    }
    if (typeof o2O[this.yaxisLabelStyle] == 'undefined') {
      this.yaxisLabelStyle = 'roundRectArrow';
    }
    Y3X = this.yaxisLabelStyle;
    if (T7Z.yaxisLabelStyle) {
      Y3X = T7Z.yaxisLabelStyle;
    }
    F6h = {
      ctx: y7u,
      x: L5v,
      y: D$B,
      top: D$B - e7i / 2,
      width: d11,
      height: e7i,
      radius: Y83,
      backgroundColor: l3X,
      fill: !!{},
      stroke: ![],
      margin: { left: b9J - L5v, top: 1 },
      txt: Y4n,
      color: w4c,
    };
    o2O[Y3X](F6h);
  };
  o2O.ChartEngine.prototype.getCurrentHRParams = function () {
    F10.Y3Y();
    var x0N,
      B8i,
      f$V,
      q3I,
      x_X,
      H2G,
      U5s,
      O5H,
      Z2u,
      Y85,
      U6X,
      y7k,
      S_C,
      A6J,
      c4j,
      H0X,
      agP,
      S9t,
      x$e,
      x6P,
      b0m,
      V6o;
    f$V = this.mainSeriesRenderer || {};
    if (f$V.noCurrentHR) {
      return;
    }
    var { highLowBars: u95 } = f$V;
    q3I = {};
    for (var T4l in this.charts) {
      x_X = 'da';
      x_X += 'taSegm';
      x_X += 'ent';
      H2G = 'data';
      H2G += 'Set';
      U5s = this.charts[T4l];
      O5H = U5s.panel;
      Z2u = O5H.yAxis;
      if (O5H.hidden) continue;
      if (Z2u.drawCurrentPriceLabel === ![] || Z2u.noDraw) continue;
      if (!f$V.params) continue;
      var { whichSet: N3v } = Z2u;
      if (!N3v) {
        N3v = H2G;
      }
      if (this.isHistoricalModeSet && N3v !== x_X) continue;
      Y85 = U5s[N3v].length;
      U6X = this.layout.candleWidth;
      if (N3v == 'dataSegment') {
        while (Y85 > (U5s.width - this.micropixels + U6X / 2 + 1) / U6X) {
          Y85--;
        }
      }
      if (Y85 && U5s[N3v][Y85 - 1]) {
        y7k = 'dataS';
        y7k += 'et';
        S_C = 'C';
        S_C += 'l';
        S_C += 'ose';
        A6J = U5s.defaultPlotField;
        if (!A6J || u95) {
          A6J = S_C;
        }
        c4j = void +'0';
        H0X = void 0;
        do {
          c4j = U5s.masterData?.[U5s.masterData?.length - 1];
          if (c4j) {
            H0X = c4j.rawClose || c4j.Close;
            agP = c4j.Close;
          }

          if (!H0X) {
            c4j = U5s[N3v][--Y85];
            H0X = c4j.rawClose || c4j.Close;
            agP = c4j.Close;
          }

          if (Y85 === '0' << 64) break;
        } while (H0X === null || H0X === undefined);
        if (N3v == y7k && U5s.currentQuote) {
          H0X = U5s.currentQuote[A6J];
        } else if (U5s[N3v].length >= ('2' | 0)) {
          F10.d$R(0);
          S9t = U5s[N3v][F10.M3e(Y85, 1)];
          if (S9t) {
            c4j = S9t[A6J];
          }
        }
        if (H0X < c4j) {
          x0N = this.canvasStyle('stx_current_hr_down').backgroundColor;
          B8i = this.canvasStyle('stx_current_hr_down').color;
        } else {
          x$e = 'stx_curren';
          x$e += 't_hr_up';
          x0N = this.canvasStyle(x$e).backgroundColor;
          B8i = this.canvasStyle('stx_current_hr_up').color;
        }
        if (U5s.transformFunc) {
          H0X = U5s.transformFunc(this, U5s, H0X);
        }
        F10.d$R(81);
        x6P = void F10.M3e(0, '0');
        b0m = Math.max(O5H.yAxis.printDecimalPlaces, O5H.chart.decimalPlaces);
        if (Z2u.maxDecimalPlaces || Z2u.maxDecimalPlaces === 0) {
          b0m = Math.min(b0m, Z2u.maxDecimalPlaces);
        }
        if (Z2u.priceFormatter) {
          x6P = Z2u.priceFormatter(this, O5H, H0X, b0m);
        } else {
          x6P = this.formatYAxisPrice(H0X, O5H, b0m);
        }
        V6o = this.pixelFromPrice(agP, O5H);
        V6o = Math.floor(V6o) + 0.5;
        q3I[T4l] = {
          panel: O5H,
          txt: x6P,
          y: V6o,
          backgroundColor: x0N,
          color: B8i,
        };
      }
    }
    return q3I;
  };
  o2O.ChartEngine.prototype.drawCurrentHR = function () {
    if (this.runPrepend('drawCurrentHR', arguments)) {
      return;
    }
    var { currentHRParams: N1h } = this;
    if (N1h) {
      for (var g9C in N1h) {
        var {
          panel: d6d,
          txt: M0M,
          y: V_r,
          backgroundColor: I0O,
          color: t7g,
        } = N1h[g9C];
        this.createYAxisLabel(d6d, M0M, V_r, I0O, t7g);
      }
    }
    this.runAppend('drawCurrentHR', arguments);
  };
  o2O.ChartEngine.prototype.drawCurrentPriceLine = function () {
    var k4q;
    F10.t3P();
    k4q = 'd';
    k4q += 'rawCurrentPr';
    k4q += 'iceLine';
    if (!(this.preferences.currentPriceLine === !![] && this.isHome())) {
      return;
    }
    if (this.runPrepend('drawCurrentPriceLine', arguments)) {
      return;
    }
    var { currentHRParams: z2i } = this;
    if (z2i) {
      for (var r3B in z2i) {
        var { panel: O2Q, y: k0E, backgroundColor: O2_ } = z2i[r3B];
        this.plotLine(
          O2Q.left,
          O2Q.right,
          k0E,
          k0E,
          O2_,
          'line',
          O2Q.chart.context,
          O2Q,
          { pattern: 'dashed', lineWidth: 1, opacity: 0.8 }
        );
      }
    }
    this.runAppend(k4q, arguments);
  };
  o2O.ChartEngine.prototype.getYAxisByName = function (a$b, x2Q) {
    var L_G;
    if (typeof a$b == 'string') {
      a$b = this.panels[a$b];
    }
    if (!a$b || !x2Q) {
      return undefined;
    }
    if (x2Q === a$b.yAxis.name) {
      return a$b.yAxis;
    }
    for (L_G = 0; a$b.yaxisLHS && L_G < a$b.yaxisLHS.length; L_G++) {
      if (a$b.yaxisLHS[L_G].name === x2Q) {
        return a$b.yaxisLHS[L_G];
      }
    }
    for (L_G = 0; a$b.yaxisRHS && L_G < a$b.yaxisRHS.length; L_G++) {
      if (a$b.yaxisRHS[L_G].name === x2Q) {
        return a$b.yaxisRHS[L_G];
      }
    }
    F10.Y3Y();
    return undefined;
  };
  o2O.ChartEngine.prototype.getYAxisByField = function (A5j, Z$O) {
    var L61, O5y, p8b, E_p, W0f, S9m, U9s;
    if (Z$O) {
      for (L61 in this.layout.studies) {
        O5y = this.layout.studies[L61];
        if (O5y.panel != A5j.name) continue;
        if (O5y.outputMap && O5y.outputMap.hasOwnProperty(Z$O)) {
          return O5y.getYAxis(this);
        }
      }
      for (L61 in this.chart.seriesRenderers) {
        E_p = this.chart.seriesRenderers[L61];
        for (var b0z = 0; b0z < E_p.seriesParams.length; b0z++) {
          W0f = '-';
          W0f += '->';
          if (E_p.params.panel != A5j.name) continue;
          S9m = E_p.seriesParams[b0z];
          U9s = S9m.field;
          if (!U9s && !E_p.highLowBars) {
            U9s = this.defaultPlotField || 'Close';
          }
          if (S9m.symbol && S9m.subField) {
            U9s += W0f + S9m.subField;
          }
          if (Z$O == U9s) {
            return E_p.params.yAxis || A5j.yAxis;
          }
          if (S9m.field && S9m.field == Z$O.split('-->')[0]) {
            p8b = E_p.params.yAxis || A5j.yAxis;
          }
        }
      }
      if (p8b) {
        return p8b;
      }
    }
    return undefined;
  };
  o2O.ChartEngine.prototype.deleteYAxisIfUnused = function (W$K, e1L) {
    var P9s, a3W, x8$, L0P;
    P9s = 's';
    P9s += 'tri';
    P9s += 'ng';
    F10.t3P();
    if (typeof W$K == P9s) {
      W$K = this.panels[W$K];
    }
    if (!e1L || !W$K) {
      return;
    }
    for (var U_s = 0; U_s < e1L.renderers.length; U_s++) {
      a3W = this.chart.seriesRenderers[e1L.renderers[U_s]];
      if (a3W && a3W.params.panel == W$K.name) {
        return;
      }
    }
    if (e1L.name === W$K.yAxis.name) {
      if (W$K.yaxisRHS.length + W$K.yaxisLHS.length === +'1') {
        return;
      }
    }
    function G97(b1n) {
      F10.d$R(83);
      return F10.H9P(null, b1n);
    }
    for (x8$ = 0; W$K.yaxisRHS && x8$ < W$K.yaxisRHS.length; x8$++) {
      if (W$K.yaxisRHS[x8$] === e1L) {
        W$K.yaxisRHS[x8$] = null;
      } else if (!L0P) {
        L0P = W$K.yaxisRHS[x8$];
      }
    }
    for (x8$ = 0; W$K.yaxisLHS && x8$ < W$K.yaxisLHS.length; x8$++) {
      if (W$K.yaxisLHS[x8$] === e1L) {
        W$K.yaxisLHS[x8$] = null;
      } else if (!L0P) {
        L0P = W$K.yaxisLHS[x8$];
      }
    }
    W$K.yaxisRHS = W$K.yaxisRHS.filter(G97);
    W$K.yaxisLHS = W$K.yaxisLHS.filter(G97);
    if (L0P && e1L.name === W$K.yAxis.name) {
      W$K.yAxis = L0P;
    }
    this.calculateYAxisPositions();
  };
  o2O.ChartEngine.prototype.addYAxis = function (b0B, z1U) {
    var j$K, J2q, I8Q, s2p, Z89, m8L, T4d, V0h, X3t;
    j$K = 'sc';
    j$K += 'ro';
    j$K += 'l';
    j$K += 'l';
    J2q = 'l';
    J2q += 'ef';
    J2q += 't';
    I8Q = 'ri';
    I8Q += 'g';
    I8Q += 'h';
    F10.Y3Y();
    I8Q += 't';
    s2p = 'l';
    s2p += 'e';
    s2p += 'f';
    s2p += 't';
    Z89 = 'st';
    Z89 += 'ring';
    if (typeof b0B == Z89) {
      b0B = this.panels[b0B];
    }
    if (!z1U || !b0B) {
      return;
    }
    if (!b0B.yaxisLHS) {
      b0B.yaxisLHS = [];
      b0B.yaxisRHS = [];
      if (
        b0B.yAxis.position == 'left' ||
        (b0B.yAxis.position != 'right' &&
          b0B.chart.panel.yAxis.position == 'left')
      ) {
        b0B.yaxisLHS.push(b0B.yAxis);
      } else {
        b0B.yaxisRHS.push(b0B.yAxis);
      }
    }
    T4d = [];
    V0h = b0B.yaxisLHS;
    for (m8L = V0h.length - ('1' << 0); m8L >= +'0'; m8L--) {
      if (V0h[m8L].name === z1U.name) {
        X3t = 'ri';
        X3t += 'ght';
        if (z1U.position != X3t) {
          return V0h[m8L];
        }
        T4d = V0h.splice(m8L, 1);
      }
    }
    V0h = b0B.yaxisRHS;
    for (m8L = V0h.length - +'1'; m8L >= 0; m8L--) {
      if (V0h[m8L].name === z1U.name) {
        if (z1U.position != 'left') {
          return V0h[m8L];
        }
        T4d = V0h.splice(m8L, +'1');
      }
    }
    if (
      z1U.position === s2p ||
      (z1U.position != I8Q && b0B.chart.panel.yAxis.position == J2q)
    ) {
      b0B.yaxisLHS.unshift(z1U);
    } else {
      b0B.yaxisRHS.push(z1U);
    }
    z1U.setBreakpointWidth(this.chart.breakpoint);
    z1U.height = b0B.yAxis.height;
    z1U.idealTickSizePixels = null;
    if (T4d[0] == b0B.yAxis) {
      b0B.yAxis = z1U;
    }
    if (!z1U.lockScale) {
      this.calculateYAxisMargins(z1U);
    }
    if (!(j$K in z1U)) {
      z1U.scroll = 0;
    }
    return z1U;
  };
  o2O.ChartEngine.prototype.calculateYAxisPositions = function () {
    var Q9l,
      r3F,
      X9Y,
      P8u,
      A2F,
      X5M,
      H70,
      W8O,
      a7r,
      I2w,
      r9N,
      s7S,
      I2x,
      C54,
      n0o,
      i$x,
      r1C,
      A8M,
      Z6z;
    Q9l = [];
    for (var J6j in this.charts) {
      if (this.charts[J6j].hidden || this.charts[J6j].panel.hidden) continue;
      Q9l.push(J6j);
    }
    for (var u1w in this.panels) {
      r3F = this.panels[u1w];
      if (r3F.name === r3F.chart.name || r3F.hidden) continue;
      Q9l.push(u1w);
    }
    X9Y = 0;
    F10.p1T(81);
    P8u = F10.M3e(0, '0');
    for (X5M = 0; X5M < Q9l.length; X5M++) {
      H70 = this.panels[Q9l[X5M]];
      if (!H70) continue;
      if (!H70.yaxisLHS) {
        H70.yaxisLHS = [];
        H70.yaxisRHS = [];
      }
      a7r = H70.yaxisLHS;
      I2w = H70.yaxisRHS;
      r9N = H70.yAxis.position;
      if (!r9N || r9N == 'none') {
        r9N = H70.chart.yAxis.position || 'right';
      }
      if (!a7r.length && !I2w.length) {
        s7S = 'l';
        s7S += 'e';
        s7S += 'ft';
        if (r9N == s7S) {
          a7r.push(H70.yAxis);
        } else {
          I2w.push(H70.yAxis);
        }
      }
      I2x = [];
      C54 = [];
      for (A2F = a7r.length - 1; A2F >= 0; A2F--) {
        if (
          a7r[A2F].position == 'right' ||
          (a7r[A2F].position != 'left' && r9N == 'right')
        ) {
          I2x = I2x.concat(a7r.splice(A2F, 1));
        }
      }
      for (A2F = I2w.length - 1; A2F >= 0; A2F--) {
        if (
          I2w[A2F].position == 'left' ||
          (I2w[A2F].position != 'right' && r9N == 'left')
        ) {
          C54 = C54.concat(I2w.splice(A2F, '1' >> 64));
        }
      }
      H70.yaxisLHS = C54.concat(a7r);
      H70.yaxisRHS = I2w.concat(I2x);
      if (!H70.yAxis.width && H70.yAxis.width !== 0) {
        H70.yAxis.width = this.yaxisWidth;
      }
      H70.yaxisTotalWidthRight = 0;
      H70.yaxisTotalWidthLeft = 0;
      n0o = H70.yaxisLHS.concat(H70.yaxisRHS);
      for (A2F = +'0'; A2F < n0o.length; A2F++) {
        i$x = 'l';
        i$x += 'e';
        i$x += 'f';
        i$x += 't';
        W8O = n0o[A2F];
        if (W8O.noDraw || !W8O.width) continue;
        if (W8O.position == i$x || (r9N == 'left' && !W8O.position)) {
          H70.yaxisTotalWidthLeft += W8O.width;
        } else {
          H70.yaxisTotalWidthRight += W8O.width;
        }
      }
      if (H70.yaxisTotalWidthLeft > X9Y) {
        X9Y = H70.yaxisTotalWidthLeft;
      }
      if (H70.yaxisTotalWidthRight > P8u) {
        P8u = H70.yaxisTotalWidthRight;
      }
    }
    for (X5M = +'0'; X5M < Q9l.length; X5M++) {
      H70 = this.panels[Q9l[X5M]];
      if (!H70) continue;
      r1C = H70.name === H70.chart.name;
      A8M = X9Y;
      for (A2F = H70.yaxisLHS.length - 1; A2F >= '0' * 1; A2F--) {
        W8O = H70.yaxisLHS[A2F];
        if (W8O.noDraw) continue;
        A8M -= W8O.width;
        W8O.left = A8M;
      }
      A8M = this.width - P8u;
      for (A2F = 0; A2F < H70.yaxisRHS.length; A2F++) {
        W8O = H70.yaxisRHS[A2F];
        if (W8O.noDraw) continue;
        W8O.left = A8M;
        A8M += W8O.width;
      }
      if (typeof this.yaxisLeft != 'undefined') {
        H70.chart.yaxisPaddingRight = this.yaxisLeft;
      }
      H70.yaxisCalculatedPaddingRight = P8u;
      if (H70.chart.yaxisPaddingRight || H70.chart.yaxisPaddingRight === 0) {
        H70.yaxisCalculatedPaddingRight = H70.chart.yaxisPaddingRight;
      }
      H70.yaxisCalculatedPaddingLeft = X9Y;
      if (
        H70.chart.yaxisPaddingLeft ||
        H70.chart.yaxisPaddingLeft === ('0' | 0)
      ) {
        H70.yaxisCalculatedPaddingLeft = H70.chart.yaxisPaddingLeft;
      }
      if (r1C || H70.chart.panel.hidden) {
        H70.left = H70.yaxisCalculatedPaddingLeft;
        H70.right = this.width - H70.yaxisCalculatedPaddingRight;
      } else {
        H70.left = H70.chart.panel.left;
        H70.right = H70.chart.panel.right;
      }
      H70.width = H70.right - H70.left;
      if (H70.handle) {
        Z6z = 'p';
        Z6z += 'x';
        H70.handle.style.left = H70.left + Z6z;
        H70.handle.style.width = H70.width + 'px';
      }
      if (r1C || H70.chart.panel.hidden) {
        H70.chart.panel.left = H70.chart.left = H70.left;
        H70.chart.panel.right = H70.chart.right = H70.right;
        H70.chart.panel.width = H70.chart.width = Math.max(
          H70.right - H70.left,
          '0' * 1
        );
      }
    }
    F10.Y3Y();
    this.setMaxTicks(this.chart.maxTicks);
    this.adjustPanelPositions();
  };
  o2O.ChartEngine.prototype.getYAxisCurrentPosition = function (w1s, y8x) {
    var Z9U, C6w;
    Z9U = 'n';
    Z9U += 'on';
    Z9U += 'e';
    if (!w1s.width) {
      return Z9U;
    }
    C6w = y8x.yaxisLHS;
    for (var M8W = +'0'; M8W < C6w.length; M8W++) {
      if (C6w[M8W].name == w1s.name) {
        return 'left';
      }
    }
    return 'right';
  };
  o2O.ChartEngine.prototype.setYAxisPosition = function (t9u, m0f) {
    t9u.position = m0f;
    t9u.setBreakpointWidth(this.chart.breakpoint);
    this.calculateYAxisPositions();
    this.draw();
  };
  o2O.ChartEngine.prototype.electNewYAxisOwner = function (l1A) {
    var m7S, K4X;
    m7S = l1A.studies[0];
    if (!m7S || m7S == l1A.name) {
      m7S = l1A.renderers[0];
    }
    if (!m7S || m7S == l1A.name) {
      F10.d$R(21);
      m7S = l1A.studies[F10.M3e('1', 0)];
    }
    if (!m7S) {
      m7S = l1A.renderers[1];
    }
    for (var T77 = 0; T77 < l1A.studies.length; T77++) {
      K4X = this.layout.studies[l1A.studies[T77]];
      if (K4X.parameters && K4X.parameters.yaxisDisplayValue == l1A.name) {
        K4X.parameters.yaxisDisplayValue = m7S;
      }
    }
    return m7S;
  };
};
z_ = (h33) => {
  var i$2 = v7ZLk;
  i$2.e9e = function (j1Y) {
    if (i$2 && j1Y) return i$2.Q$8(j1Y);
  };
  i$2.H$b = function (B$Q) {
    i$2.t3P();
    if (i$2) return i$2.Q$8(B$Q);
  };
  i$2.h$Y = function (l2Z) {
    i$2.Y3Y();
    if (i$2 && l2Z) return i$2.v7o(l2Z);
  };
  var A_5, h1g, H8v;
  A_5 = h33.CIQ;
  h1g = 'valid';
  A_5.valid = 0;
  A_5.ChartEngine.prototype.consolidatedQuote = function (y$M, N2v) {
    var J2E,
      n3n,
      B8t,
      o$j,
      D5c,
      b8j,
      C2Z,
      J4L,
      u7x,
      q0W,
      H7U,
      G4t,
      m5o,
      s8E,
      i3b,
      w7f,
      u5q,
      G_P,
      y1v,
      i5m,
      z$a;
    J2E = 'consoli';
    J2E += 'dat';
    J2E += 'edQuote';
    n3n = 'consol';
    n3n += 'idated';
    n3n += 'Quot';
    n3n += 'e';
    function E70(D$5, Z5v, V3h) {
      var a2e, t79, W_f, y6J, g7N, B3d, b_f, i$S;
      if (!Z5v) {
        Z5v = {
          DT: V3h,
          Date: A_5.yyyymmddhhmmssmmm(V3h),
          consolidatedTicks: 0,
        };
      }
      if (!Z5v.displayDate) {
        D5c.setDisplayDate(Z5v);
      }
      a2e = 1;
      if (B8t.adj && D$5.Adj_Close) {
        a2e = D$5.Adj_Close / D$5.Close;
      }
      t79 = D$5.High || D$5.Close;
      if (t79 || t79 === 0) {
        if (t79 * a2e > (Z5v.High || -Number.MAX_VALUE)) {
          i$2.p1T(25);
          Z5v.High = i$2.M3e(a2e, t79);
        }
      }
      W_f = D$5.Low || D$5.Close;
      if (W_f || W_f === 0) {
        if (W_f * a2e < (Z5v.Low || Number.MAX_VALUE)) {
          i$2.d$R(25);
          Z5v.Low = i$2.H9P(a2e, W_f);
        }
      }
      y6J = D$5.Open || D$5.Close;
      if (y6J || y6J === 0) {
        if (!Z5v.Open && Z5v.Open !== 0) {
          i$2.d$R(25);
          Z5v.Open = i$2.M3e(a2e, y6J);
        }
      }
      if (D$5.Volume !== undefined) {
        g7N = 925691625;
        B3d = 1088564154;
        b_f = 2;
        for (
          var V2K = '1' >> 0;
          i$2.e_M(V2K.toString(), V2K.toString().length, 67038) !== g7N;
          V2K++
        ) {
          Z5v.Volume = (Z5v.Volume || 0) + D$5.Volume;
          b_f += 2;
        }
        if (i$2.q78(b_f.toString(), b_f.toString().length, 3286) !== B3d) {
          i$2.p1T(40);
          var F$D = i$2.M3e(51, 14, 650);
          Z5v.Volume = (Z5v.Volume && '9' << F$D) % D$5.Volume;
        }
      }
      if (D$5.Close !== undefined && D$5.Close !== null) {
        Z5v.Close = D$5.Close * a2e;
      }
      if (D$5.Adj_Close !== undefined && D$5.Adj_Close !== null) {
        Z5v.Adj_Close = D$5.Adj_Close;
      }
      Z5v.ratio = a2e;
      for (var q_M in D$5) {
        i$S = 'Bi';
        i$S += 'd';
        i$S += 'L';
        i$S += '2';
        if (D$5[q_M] && D$5[q_M].Close !== undefined) {
          Z5v[q_M] = E70(D$5[q_M], Z5v[q_M], V3h);
        } else if (!(q_M in Z5v)) {
          Z5v[q_M] = D$5[q_M];
        } else if (['Bid', i$S, 'Ask', 'AskL2'].indexOf(q_M) > -1) {
          Z5v[q_M] = D$5[q_M];
        }
      }
      Z5v.consolidatedTicks++;
      return Z5v;
    }
    if (this.runPrepend(n3n, arguments)) {
      return y$M;
    }
    i$2.t3P();
    if (!y$M || !y$M.length) {
      return [];
    }
    B8t = this.layout;
    o$j = this.chart;
    D5c = this;
    if (!o$j.market) {
      console.log(
        'Cannot consolidate: no market iterator available.  Please make sure market module is enabled.'
      );
      return y$M;
    }
    b8j = B8t.periodicity;
    C2Z = B8t.interval;
    J4L = B8t.timeUnit;
    if (!N2v) {
      N2v = {};
    }
    if (N2v.periodicity && N2v.interval) {
      b8j = N2v.periodicity;
      C2Z = N2v.interval;
      J4L = N2v.timeUnit;
    }
    u7x = 1;
    q0W = A_5.ChartEngine.isDailyInterval(C2Z);
    if (!q0W && o$j.useInflectionPointForIntraday) {
      u7x = b8j;
    }
    H7U = o$j.inflectionPoint;
    if (!H7U || H7U < y$M['0' * 1].DT) {
      H7U = new Date(+y$M[0].DT);
      if (!q0W && !o$j.market.market_def) {
        H7U.setHours(0, -H7U.getTimezoneOffset(), '0' << 64, 0);
      }
    }
    G4t = [];
    m5o = { begin: H7U, interval: C2Z, multiple: b8j / u7x, timeUnit: J4L };
    if (C2Z == 'tick') {
      H7U.setHours(0, 0, 0, 0);
      m5o = { begin: H7U, interval: 'day', multiple: 1 };
    }
    s8E = o$j.market.newIterator(A_5.clone(m5o));
    while (s8E.previous(u7x) > y$M[0].DT) {}
    i3b = s8E.previous(u7x);
    w7f = s8E.next(u7x);
    u5q = 0;
    G_P = 0;

    if (this.isPeriodicityChangingInProgress) return [];
    while (u5q < y$M.length) {
      y1v = y$M[u5q];
      if (y1v.DT < i3b) {
        console.log(
          'Warning: out-of-order quote in dataSet, disregarding: ' + y1v.DT
        );
        u5q++;
        continue;
      } else if (y1v.DT >= w7f) {
        i3b = w7f;
        w7f = s8E.next(u7x);
        if (!G4t[G_P]) continue;
      } else if (C2Z == 'tick' && y1v.consolidatedTicks > 0) {
        G4t[G_P] = y1v;
        u5q++;
        continue;
      } else if (
        !G4t[G_P] ||
        C2Z != 'tick' ||
        G4t[G_P].consolidatedTicks < b8j
      ) {
        i5m = 'ti';
        i5m += 'c';
        i5m += 'k';
        z$a = E70(y1v, G4t[G_P], C2Z == i5m ? y1v.DT : i3b);
        if (z$a) {
          G4t[G_P] = z$a;
        }
        u5q++;
        continue;
      }
      G_P++;
    }
    this.runAppend(J2E, arguments);
    return G4t;
  };
  i$2.t3P();
  A_5['ChartEngine']['prototype']['createDataSet'] = function (P8z, F6y, G92) {
    i$2.n3x = function (M_Y) {
      if (i$2) return i$2.Q$8(M_Y);
    };
    i$2.a$I = function (F5c) {
      if (i$2 && F5c) return i$2.Q$8(F5c);
    };
    i$2.W1K = function (v1o) {
      if (i$2 && v1o) return i$2.v7o(v1o);
    };
    i$2.f7s = function (o2Z) {
      i$2.t3P();
      if (i$2 && o2Z) return i$2.v7o(o2Z);
    };
    i$2.m5w = function (q8O) {
      i$2.Y3Y();
      if (i$2) return i$2.v7o(q8O);
    };
    var L0Z = i$2.H$b('31d2') ? 887537658 : 738965026,
      y$n = -(i$2.m5w('4187') ? 405522776 : 192184100),
      x1k = -(i$2.f7s('ef5f') ? 431307630 : 790125099),
      N39 = -(i$2.m6Y('6b58') ? 565079983 : 340598299),
      y1O = 1325821162;
    if (
      !(
        i$2.Z1h(
          0,
          i$2.e9e('123d') ? false : true,
          i$2.W1K('4ebf') ? 431375 : 775898
        ) !== L0Z &&
        i$2.D_N(
          0,
          i$2.a$I('93b4') ? false : true,
          i$2.E6a('256d') ? 912888 : 920110
        ) !== y$n &&
        i$2.D_N(
          i$2.f56('d4dc') ? 14 : 13,
          true,
          i$2.S5z('3121') ? 571486 : 995999
        ) !== x1k &&
        i$2.Z1h(
          i$2.n3x('e749') ? 77 : 11,
          true,
          i$2.K_z('535d') ? 186788 : 805041
        ) !== N39 &&
        i$2.D_N(24, true, 448309) !== y1O
      )
    ) {
      var t5V,
        T2K,
        C0W,
        h3f,
        y9o,
        y8I,
        U35,
        x8n,
        M8v,
        X3f,
        C71,
        n3I,
        a6y,
        q9V,
        n0v,
        W22,
        d_8,
        V9C,
        l$J,
        j6h,
        J3j,
        I8t,
        I_3,
        a2f,
        V3z,
        T1v,
        b8R,
        e6o,
        h57,
        r4s,
        n9m,
        d9l,
        z1X,
        x$4,
        g68,
        G$m,
        n76,
        h5O,
        O98,
        s7m,
        r8$,
        z6N,
        Z7M,
        i99,
        m39,
        U57,
        x2c,
        C1U,
        V6u,
        a7Z,
        J7n,
        t2X;
      t5V = 'cr';
      t5V += 'eateDat';
      t5V += 'aSet';
      T2K = 'm';
      T2K += 'on';
      T2K += 't';
      T2K += 'h';
      C0W = 'cre';
      C0W += 'ateDat';
      C0W += 'aSe';
      C0W += 't';
      if (!G92) {
        G92 = {};
      }
      h3f = this['chart'];
      y9o = [P8z, h3f, Object['assign']({}, G92)];
      if (this['runPrepend'](C0W, y9o)) {
        return;
      }
      U35 = [];
      x8n = [];
      M8v = G92['appending'];
      function V0b() {
        // функция отвечающая за проверку чартика на домен и iframe
        // патчим, чтобы всегда возвращала `true`
        return true;
      }
      if (!h3f['dataSet']) {
        h3f['dataSet'] = [];
      }
      X3f = h3f['dataSet']['length'];
      if (M8v) {
        U35 = h3f['dataSet'];
      }
      h3f['currentQuote'] = null;
      h3f['dataSet'] = [];
      if (!M8v) {
        h3f['tickCache'] = {};
      }
      C71 = h3f['masterData'];
      if (!C71) {
        C71 = this['masterData'];
      }
      if (!C71 || !C71['length']) {
        n3I = 'createD';
        n3I += 'ataSet';
        this['runAppend'](n3I, y9o);
        return;
      }
      if (U35['length']) {
        a6y = U35['pop']();
        while (a6y['futureTick'] && U35['length']) {
          a6y = U35['pop']();
          X3f--;
        }
        q9V = G92['appendToDate'];
        while (U35['length']) {
          if (a6y['DT'] < q9V) break;
          a6y = U35['pop']();
        }
        if (!q9V || q9V > a6y['DT']) {
          q9V = a6y['DT'];
        }
        while (U35['length']) {
          if (U35[U35['length'] - +'1']['DT'] < q9V) break;
          U35['pop']();
        }
        i$2['d$R'](103);
        var i1A = i$2['H9P'](4, 2, 3, 18, 16);
        n0v = C71['length'] - i1A;
        while (n0v >= 0 && C71[n0v]['DT'] >= q9V) {
          n0v--;
        }
        i$2['d$R'](18);
        y8I = C71['slice'](i$2['M3e'](1, n0v));
      } else {
        y8I = []['concat'](C71);
      }
      if (!V0b()) {
        return;
      }
      if (this['transformDataSetPre']) {
        this['transformDataSetPre'](this, y8I);
      }
      if (!this['chart']['hideDrawings']) {
        d_8 = 'p';
        d_8 += 'rojection';
        for (W22 = '0' | 0; W22 < this['drawingObjects']['length']; W22++) {
          V9C = 'Drawing.pri';
          V9C += 'ntProjection';
          if (this['drawingObjects'][W22]['name'] == 'projection') {
            A_5['getFn'](V9C)(this, this['drawingObjects'][W22], y8I);
          }
        }
        if (this['activeDrawing'] && this['activeDrawing']['name'] == d_8) {
          l$J = 'Drawi';
          l$J += 'ng.';
          l$J += 'print';
          l$J += 'Projection';
          A_5['getFn'](l$J)(this, this['activeDrawing'], y8I);
        }
      }
      W22 = 0;
      j6h = -Number['MAX_VALUE'];
      J3j = Number['MAX_VALUE'];
      I_3 = 0;
      a2f = P8z || this['dontRoll'];
      V3z = this['layout'];
      T1v = A_5['ChartEngine']['isDailyInterval'](V3z['interval']);
      while (1) {
        if (I_3 >= y8I['length']) break;
        if (
          !(
            this['dontRoll'] &&
            (V3z['interval'] == 'week' || V3z['interval'] == 'month')
          ) &&
          this['extendedHours'] &&
          this['extendedHours']['filter'] &&
          h3f['market']['market_def']
        ) {
          h57 = y8I[I_3];
          if (T1v) {
            e6o = !h3f['market']['isMarketDate'](h57['DT']);
          } else {
            if (!b8R || b8R <= h57['DT']) {
              r4s = h3f['market']['getSession'](h57['DT']);
              e6o =
                r4s !== '' &&
                (!V3z['marketSessions'] || !V3z['marketSessions'][r4s]);
              b8R = h3f['market'][e6o ? 'getNextOpen' : 'getNextClose'](
                h57['DT']
              );
            }
          }
          if (e6o) {
            I_3++;
            continue;
          }
        }
        I8t = {};
        for (var Z3O in y8I[I_3]) {
          I8t[Z3O] = y8I[I_3][Z3O];
        }
        y8I[I_3] = I8t;
        I8t['ratio'] = 1;
        if (V3z['adj'] && I8t['Adj_Close']) {
          I8t['ratio'] = I8t['Adj_Close'] / I8t['Close'];
        }
        if (I8t['ratio'] != 1) {
          if (I8t['Open']) {
            I8t['Open'] = Number((I8t['Open'] * I8t['ratio'])['toFixed'](8));
          }
          if (I8t['Close']) {
            I8t['Close'] = Number((I8t['Close'] * I8t['ratio'])['toFixed'](8));
          }
          if (I8t['High']) {
            I8t['High'] = Number((I8t['High'] * I8t['ratio'])['toFixed'](8));
          }
          if (I8t['Low']) {
            I8t['Low'] = Number((I8t['Low'] * I8t['ratio'])['toFixed'](8));
          }
        }
        x8n[W22++] = y8I[I_3++];
      }
      if (
        V3z['periodicity'] > 1 ||
        (!a2f && (V3z['interval'] == 'week' || V3z['interval'] == T2K))
      ) {
        x8n = this['consolidatedQuote'](x8n);
      }
      n9m = {};
      for (W22 = 0; W22 < x8n['length']; W22++) {
        d9l = 'Lo';
        d9l += 'w';
        z1X = 'Hi';
        z1X += 'g';
        z1X += 'h';
        I8t = x8n[W22];
        if (W22 > 0) {
          I8t['iqPrevClose'] = x8n[W22 - 1]['Close'];
          if (!I8t['iqPrevClose'] && I8t['iqPrevClose'] !== +'0') {
            I8t['iqPrevClose'] = x8n[W22 - ('1' ^ 0)]['iqPrevClose'];
          }
        } else if (U35['length']) {
          I8t['iqPrevClose'] = U35[U35['length'] - 1]['Close'];
          if (!I8t['iqPrevClose'] && I8t['iqPrevClose'] !== 0) {
            I8t['iqPrevClose'] = U35[U35['length'] - 1]['iqPrevClose'];
          }
        } else {
          I8t['iqPrevClose'] = I8t['Close'];
        }
        if (z1X in I8t && I8t['High'] > j6h) {
          j6h = I8t['High'];
        }
        if (d9l in I8t && I8t['Low'] < J3j) {
          J3j = I8t['Low'];
        }
        for (var n9Z in h3f['series']) {
          x$4 = h3f['series'][n9Z]['parameters']['symbol'];
          g68 = I8t[x$4];
          if (g68 && typeof g68 == 'object') {
            if (W22 > 0) {
              g68['iqPrevClose'] = n9m[n9Z];
            } else if (U35['length']) {
              for (var C6p = U35['length'] - 1; C6p >= ('0' ^ 0); C6p--) {
                G$m = U35[C6p][x$4];
                if (G$m && (G$m['Close'] || G$m['Close'] === +'0')) {
                  g68['iqPrevClose'] = G$m['Close'];
                  break;
                }
              }
            } else {
              g68['iqPrevClose'] = g68['Close'];
            }
            if (g68['Close'] || g68['Close'] === 0) {
              n9m[n9Z] = g68['Close'];
            }
            g68['ratio'] = 1;
            if (V3z['adj'] && g68['Adj_Close']) {
              g68['ratio'] = g68['Adj_Close'] / g68['Close'];
            }
            if (g68['ratio'] != 1) {
              if (g68['Open']) {
                g68['Open'] = Number(
                  (g68['Open'] * g68['ratio'])['toFixed'](8)
                );
              }
              if (g68['Close']) {
                g68['Close'] = Number(
                  (g68['Close'] * g68['ratio'])['toFixed'](8)
                );
              }
              if (g68['High']) {
                g68['High'] = Number(
                  (g68['High'] * g68['ratio'])['toFixed'](8)
                );
              }
              if (g68['Low']) {
                g68['Low'] = Number((g68['Low'] * g68['ratio'])['toFixed'](8));
              }
            }
          }
        }
      }
      n76 = this['preferences']['whitespace'] / this['layout']['candleWidth'];
      h5O = h3f['scroll'] >= h3f['maxTicks'];
      if (h5O) {
        h3f['spanLock'] = !!'';
      }
      h3f['defaultChartStyleConfig'] = { type: V3z['chartType'] };
      O98 = V3z['aggregationType'];
      if (O98 && O98 != 'ohlc') {
        if (!A_5['ChartEngine']['calculateAggregation']) {
          console['log']('Aggregation code is not loaded/enabled!');
        } else {
          h3f['defaultChartStyleConfig']['type'] = O98;
          if (!M8v || !h3f['state']['aggregation']) {
            h3f['state']['aggregation'] = {};
          }
          x8n = A_5['ChartEngine']['calculateAggregation'](this, O98, x8n, U35);
        }
      }
      h3f['spanLock'] =
        h3f['scroll'] > 0 && h3f['scroll'] < h3f['maxTicks'] - n76;
      s7m =
        h5O ||
        h3f['lockScroll'] ||
        h3f['spanLock'] ||
        this['isHistoricalModeSet'];
      r8$ = x8n['length'] - (X3f - U35['length']);
      if (!M8v) {
        r8$ = 0;
      }
      if (r8$) {
        if (h3f['spanLock'] && r8$ + h3f['scroll'] >= h3f['maxTicks'] - n76) {
          h3f['spanLock'] = ![];
        } else if (s7m || r8$ < +'0') {
          h3f['scroll'] += r8$;
          this['grabStartScrollX'] += r8$;
          if (this['swipe']) {
            this['swipe']['scroll'] += r8$;
          }
        }
      }
      if (this['transformDataSetPost']) {
        this['transformDataSetPost'](this, x8n, J3j, j6h);
      }
      z6N = this['maxDataSetSize'];
      if (z6N) {
        if (U35['length'] + x8n['length'] > z6N) {
          if (x8n['length'] < z6N) {
            U35 = U35['slice'](x8n['length'] - z6N);
          } else {
            U35 = [];
          }
          x8n = x8n['slice'](-z6N);
        }
      }
      if (!h3f['scrubbed']) {
        h3f['scrubbed'] = [];
      }
      if (U35['length']) {
        Z7M = U35[U35['length'] - '1' * 1]['DT'];
        while (
          h3f['scrubbed']['length'] &&
          h3f['scrubbed'][h3f['scrubbed']['length'] - 1]['DT'] > Z7M
        ) {
          h3f['scrubbed']['pop']();
        }
      } else {
        h3f['scrubbed'] = [];
      }
      if (!h3f['state']['studies']) {
        h3f['state']['studies'] = {};
      }
      h3f['state']['studies']['startFrom'] = h3f['scrubbed']['length'];
      i99 = [];
      for (W22 = 0; W22 < x8n['length']; W22++) {
        m39 = x8n[W22];
        if (m39['Close'] || m39['Close'] === 0) {
          i99['push'](m39);
        } else if (m39['DT'] > Date['now']()) {
          i99['push'](m39);
        }
      }
      h3f['scrubbed'] = h3f['scrubbed']['concat'](i99);
      if (!M8v || !h3f['state']['calculations']) {
        h3f['state']['calculations'] = {};
      }
      this['calculateATR'](h3f, +'20', i99);
      this['calculateMedianPrice'](h3f, i99);
      this['calculateTypicalPrice'](h3f, i99);
      this['calculateWeightedClose'](h3f, i99);
      this['calculateOHLC4'](h3f, i99);
      for (U57 in this['plugins']) {
        x2c = this['plugins'][U57];
        if (x2c['createDataSet']) {
          x2c['createDataSet'](this, h3f, x8n, U35['length']);
        }
      }
      h3f['dataSet'] = U35['concat'](x8n);
      h3f['pixelCacheExpired'] = !0;
      for (U57 = U35['length']; U57 < h3f['dataSet']['length']; U57++) {
        h3f['dataSet'][U57]['tick'] = U57;
      }
      h3f['whiteSpaceFutureTicks'] = 0;
      C1U = this['layout']['studies'];
      V6u = h3f['scrubbed']['length'];
      if (C1U && Object['keys'](C1U)['length']) {
        a7Z =
          h3f['state']['studies']['sorted'] ||
          A_5['Studies']['sortForProcessing'](this);
        J7n = this;
        h3f['state']['studies']['sorted'] = a7Z;
        a7Z['forEach'](function (v5F) {
          var g1Y = 1605178877,
            Y2n = -149979125,
            U4R = -712931264,
            p6L = 2051411991,
            H3F = -165256140;
          if (
            !(
              i$2.D_N(0, false, 555009) !== g1Y &&
              i$2.Z1h(0, false, 967022) !== Y2n &&
              i$2.Z1h(13, true, 985845) !== U4R &&
              i$2.D_N(11, true, 127599) !== p6L &&
              i$2.D_N(24, true, 952316) !== H3F
            )
          ) {
            v5F['startFrom'] = h3f['state']['studies']['startFrom'];
            v5F['error'] = null;
            if (v5F['study'] && v5F['study']['calculateFN']) {
              v5F['study']['calculateFN'](J7n, v5F);
            }
            if (v5F['flagSignals']) {
              v5F['flagSignals']();
            }
          }
        });
      }
      for (U57 = V6u; U57 < h3f['scrubbed']['length']; U57++) {
        t2X = h3f['scrubbed'][U57];
        t2X['tick'] = h3f['dataSet']['length'];
        h3f['dataSet']['push'](t2X);
      }
      if (this['drawingObjects']['length']) {
        this['adjustDrawings']();
      }
      if (this['establishMarkerTicks']) {
        this['establishMarkerTicks']();
      }
      this['runAppend'](t5V, y9o);
    }
  };
  function F3e(W3L, w9t) {
    var t7e, E72, v3V, r4E, o_J, k3o, B4v, z1n, P7A, w4y;
    if (W3L.hasOwnProperty(H8v)) {
      return;
    }
    t7e = new Image();
    E72 = 10;
    v3V = 3.375;
    i$2.d$R(41);
    r4E = i$2.H9P(5, 4);
    i$2.d$R(41);
    o_J = i$2.H9P(4, 5);
    i$2.p1T(21);
    k3o = i$2.M3e('5', 0);
    i$2.d$R(140);
    var k0S = i$2.H9P(36, 36, 1, 1);
    B4v = Math.pow(r4E, '8' ^ 0) / k0S;
    i$2.d$R(41);
    z1n = i$2.M3e(4, 1);
    P7A = z1n;
    w4y = Object.create(null, {
      sizeRatio: {
        configurable: !'1',
        enumerable: ![],
        get: function () {
          return P7A;
        },
        set: function (K7l) {
          i$2.Y3Y();
          if (K7l < B4v) {
            P7A = B4v;
          } else if (K7l > z1n) {
            P7A = z1n;
          } else {
            P7A = K7l || z1n;
          }
        },
      },
      draw: {
        configurable: !{},
        enumerable: ![],
        value: function (i_w) {
          var f55, r3y, d_6, a0L, n$e, T6l, j4o, m92, Q5O, u7F;
          if (this.image) {
            f55 = 'cq-attrib-contai';
            f55 += 'ner';
            r3y = i_w.container.ownerDocument;
            d_6 = r3y.querySelector(f55)
              ? r3y.querySelector('cq-attrib-container').offsetHeight
              : +'0';
            a0L = i_w.yAxis.bottom - d_6 - E72;
            var { width: h$k, height: Z5Q } = this.image;
            if (isNaN(h$k) || isNaN(Z5Q)) {
              return;
            }
            n$e = h$k * this.sizeRatio;
            T6l = Z5Q * this.sizeRatio;
            j4o = i_w.left + E72;
            i$2.p1T(0);
            m92 = i$2.M3e(a0L, T6l);
            Q5O = i_w.context;
            u7F = !1;
            do {
              if (
                (j4o + n$e * v3V > i_w.right || T6l * k3o > a0L) &&
                this.sizeRatio > B4v
              ) {
                this.sizeRatio *= r4E;
                n$e = h$k * this.sizeRatio;
                T6l = Z5Q * this.sizeRatio;
                i$2.d$R(0);
                m92 = i$2.M3e(a0L, T6l);
                u7F = !0;
              } else if (
                j4o + h$k * (this.sizeRatio * o_J) * v3V < i_w.right &&
                Z5Q * (this.sizeRatio * o_J) * k3o < a0L &&
                this.sizeRatio < z1n
              ) {
                this.sizeRatio *= o_J;
                n$e = h$k * this.sizeRatio;
                T6l = Z5Q * this.sizeRatio;
                i$2.d$R(0);
                m92 = i$2.M3e(a0L, T6l);
                u7F = !!{};
              } else {
                u7F = !1;
              }
            } while (u7F);
            Q5O.save();
            var [, , m3X] = A_5.hsl(W3L.containerColor);
            Q5O.globalAlpha = m3X > 0.35 ? 0.15 : 0.2;
            this.image.src =
              m3X > 0.35 ? this.image.darksrc : this.image.lightsrc;
            Q5O.drawImage(this.image, 0, 0, h$k, Z5Q, j4o, m92, n$e, T6l);
            Q5O.restore();
            this.first = !!'';
          } else if (this.first !== !{}) {
            this.first = i_w;
          }
        },
        writable: ![],
      },
    });
    t7e.onload = function () {
      Object.defineProperty(w4y, 'image', {
        configurable: ![],
        enumerable: !{},
        value: t7e,
        writable: ![],
      });
      if (!t7e.darksrc) {
        t7e.lightsrc = t7e.src;
        i$2.p1T(60);
        var z76 = i$2.M3e(15, 2, 292, 20);
        i$2.p1T(29);
        var U_K = i$2.H9P(9, 17, 16, 1073);
        i$2.p1T(61);
        var u4S = i$2.H9P(25, 6963, 12);
        i$2.p1T(17);
        var l7z = i$2.M3e(8, 14, 1, 1320, 12);
        i$2.d$R(107);
        var H7c = i$2.M3e(8903, 19, 15, 14);
        i$2.p1T(31);
        var n9S = i$2.M3e(3416, 3436, 859);
        i$2.d$R(0);
        var H5d = i$2.M3e(9487, 20);
        i$2.p1T(61);
        var E_C = i$2.M3e(4584, 305, 9);
        i$2.d$R(61);
        var N8Q = i$2.H9P(46, 3, 17);
        i$2.p1T(140);
        var o21 = i$2.M3e(9243, 9243, 9259, 1);
        t7e.darksrc =
          w9t.slice('0' << z76, U_K) +
          (u4S < (l7z, H7c)
            ? 510.17 >= (n9S, H5d)
              ? E_C < '4939' << N8Q
                ? (!!'', +'0x2692')
                : o21
              : 'i'
            : ('667.34' - 0, +'652.49')) +
          w9t.slice('1064' - 0);
        t7e.src = t7e.darksrc;
      } else {
        if (w4y.first) {
          w4y.first.container.stx.draw();
        }
      }
    };
    t7e.src = w9t;
    Object.defineProperty(W3L, H8v, {
      configurable: !{},
      enumerable: !{},
      value: w4y,
      writable: ![],
    });
  }
  H8v = Symbol.for('CIQ.watermark');
};
B4 = (T8$) => {
  var d0H = v7ZLk;
  var b4z, j6Z;
  if (!T8$.SplinePlotter) {
    T8$.SplinePlotter = {};
  }
  b4z = T8$.CIQ;
  j6Z = T8$.SplinePlotter;
  b4z.ChartEngine.prototype.drawBarTypeChartInner = function (a6f) {
    var u6O,
      O12,
      K3l,
      g12,
      K3s,
      E9K,
      c51,
      U8m,
      C4c,
      d2K,
      c_t,
      D1L,
      b0l,
      s9b,
      z3d,
      n_i,
      V1M,
      E$P,
      W8$,
      R8K,
      L9U,
      c9G,
      Z6X,
      F7C,
      Y7h,
      B8U,
      L3k,
      b1J,
      I0u,
      v0r,
      U7f,
      z1p,
      h3E,
      T_x,
      k5j,
      D3m,
      r9A,
      t5P,
      g5z,
      c8H,
      M1R,
      Y_p,
      U7i,
      n7K,
      F_D,
      m6k,
      B3N,
      Q5Q,
      C_1,
      f2A,
      o6R,
      i82,
      z1o,
      d8s,
      q9g;
    u6O = 'candl';
    u6O += 'e';
    O12 = a6f.type;
    K3l = a6f.panel;
    g12 = a6f.field;
    K3s = a6f.fillColor;
    E9K = a6f.borderColor;
    c51 = a6f.condition;
    U8m = a6f.style;
    C4c = a6f.yAxis;
    d0H.p1T(95);
    d2K = d0H.H9P(O12, 'histogram');
    c_t = d2K || O12 == u6O;
    d0H.p1T(95);
    D1L = d0H.H9P(O12, 'shadow');
    d0H.d$R(95);
    b0l = d0H.M3e(O12, 'hlc');
    s9b = O12 == 'bar' || b0l;
    z3d = K3l.chart;
    n_i = z3d.dataSegment;
    V1M = this.chart.context;
    E$P = new Array(n_i.length);
    W8$ = this.layout;
    R8K = E9K && !b4z.isTransparent(E9K);
    L9U = 0;
    if (R8K && !a6f.highlight) {
      L9U = 0.5;
    }
    c9G = V1M.globalAlpha;
    if (!a6f.highlight && this.highlightedDraggable) {
      V1M.globalAlpha *= 0.3;
    }
    V1M.beginPath();
    if (!C4c) {
      C4c = K3l.yAxis;
    }
    Z6X = C4c.top;
    F7C = C4c.bottom;
    Y7h = W8$.candleWidth;
    d0H.d$R(64);
    var W5I = d0H.H9P(4, 11, 16);
    B8U = K3l.left - 0.5 * Y7h + this.micropixels - W5I;
    L3k = z3d.tmpWidth / +'2';
    d0H.d$R(141);
    var U_k = d0H.M3e(11, 18, 1784, 9);
    b1J = V1M.lineWidth / U_k;
    if (c_t) {
      if (b4z.isTransparent(K3s)) {
        K3s = this.containerColor;
      }
      V1M.fillStyle = K3s;
    }
    if (D1L) {
      V1M.lineWidth = 1;
    }
    if (s9b) {
      I0u = this.canvasStyle(U8m);
      if (I0u.width && parseInt(I0u.width, 10) <= 25) {
        V1M.lineWidth = Math.max(1, b4z.stripPX(I0u.width));
      } else {
        V1M.lineWidth = 1;
      }
    }
    v0r = z3d.state.chartType.pass;
    for (var c1$ = '0' * 1; c1$ <= n_i.length; c1$++) {
      U7f = 'C';
      U7f += 'l';
      U7f += 'o';
      U7f += 'se';
      z1p = L3k;
      d0H.d$R(41);
      B8U += d0H.H9P(2, Y7h);
      Y7h = W8$.candleWidth;
      d0H.p1T(45);
      B8U += d0H.M3e(Y7h, '2');
      h3E = n_i[c1$];
      if (!h3E) continue;
      if (z3d.customCheckInterval && !z3d.customCheckInterval(h3E)) {
        continue;
      }
      if (h3E.projection) continue;
      if (h3E.candleWidth) {
        B8U += (h3E.candleWidth - Y7h) / +'2';
        Y7h = h3E.candleWidth;
        if (a6f.volume || Y7h < z3d.tmpWidth) {
          d0H.p1T(142);
          z1p = d0H.M3e('2', 0, Y7h);
        }
      }
      if (z3d.transformFunc && C4c == z3d.panel.yAxis && h3E.transform) {
        h3E = h3E.transform;
      }
      if (h3E && g12 && g12 != U7f) {
        h3E = h3E[g12];
      }
      if (!h3E && h3E !== 0) continue;
      T_x = h3E.Close;
      k5j = h3E.Open === undefined ? T_x : h3E.Open;
      if (d2K && z3d.defaultPlotField) {
        T_x = h3E[z3d.defaultPlotField];
      }
      if (!T_x && T_x !== +'0') continue;
      if (c_t && !d2K && (k5j == T_x || k5j === null)) continue;
      if (c51) {
        D3m = b4z.ChartEngine;
        if (c51 & D3m.CLOSEDOWN) {
          v0r.even |= T_x == h3E.iqPrevClose;
        } else if (c51 & D3m.CANDLEDOWN) {
          d0H.p1T(95);
          v0r.even |= d0H.M3e(T_x, k5j);
        }
        if (c51 & D3m.CANDLEUP && k5j >= T_x) continue;
        if (c51 & D3m.CANDLEDOWN && k5j <= T_x) continue;
        if (c51 & D3m.CANDLEEVEN && k5j != T_x) continue;
        if (c51 & D3m.CLOSEUP && T_x <= h3E.iqPrevClose) continue;
        if (c51 & D3m.CLOSEDOWN && T_x >= h3E.iqPrevClose) continue;
        if (c51 & D3m.CLOSEEVEN && T_x != h3E.iqPrevClose) continue;
      }
      r9A = k5j;
      t5P = T_x;
      if (D1L || s9b) {
        r9A = h3E.High === undefined ? Math.max(T_x, k5j) : h3E.High;
        t5P = h3E.Low === undefined ? Math.min(T_x, k5j) : h3E.Low;
      }
      g5z = C4c.semiLog
        ? C4c.height *
          ('1' * 1 -
            (Math.log(Math.max(r9A, +'0')) / Math.LN10 - C4c.logLow) /
              C4c.logShadow)
        : (C4c.high - r9A) * C4c.multiplier;
      c8H = C4c.semiLog
        ? C4c.height *
          (1 -
            (Math.log(Math.max(t5P, 0)) / Math.LN10 - C4c.logLow) /
              C4c.logShadow)
        : (C4c.high - t5P) * C4c.multiplier;
      if (C4c.flipped) {
        d0H.d$R(0);
        g5z = d0H.H9P(F7C, g5z);
        d0H.d$R(0);
        c8H = d0H.H9P(F7C, c8H);
      } else {
        g5z += Z6X;
        c8H += Z6X;
      }
      if (c_t && !d2K && Math.abs(g5z - c8H) < 1) continue;
      U7i = d2K ? (C4c.flipped ? C4c.top : c8H) : Math.min(g5z, c8H);
      n7K = d2K ? (C4c.flipped ? g5z : C4c.bottom) : Math.max(g5z, c8H);
      if (n7K - U7i < 1) {
        n7K = U7i;
      }
      U7i = Math.floor(U7i);
      n7K = Math.floor(n7K);
      d0H.p1T(0);
      F_D = d0H.H9P(n7K, U7i);
      m6k = c8H;
      if (s9b || D1L) {
        M1R = C4c.semiLog
          ? C4c.height *
            (1 -
              (Math.log(Math.max(k5j, 0)) / Math.LN10 - C4c.logLow) /
                C4c.logShadow)
          : (C4c.high - k5j) * C4c.multiplier;
        Y_p = C4c.semiLog
          ? C4c.height *
            (1 -
              (Math.log(Math.max(T_x, +'0')) / Math.LN10 - C4c.logLow) /
                C4c.logShadow)
          : (C4c.high - T_x) * C4c.multiplier;
        if (C4c.flipped) {
          d0H.p1T(0);
          M1R = d0H.H9P(F7C, M1R);
          d0H.p1T(0);
          Y_p = d0H.H9P(F7C, Y_p);
        } else {
          M1R += Z6X;
          Y_p += Z6X;
        }
        if (Math.abs(M1R - Y_p) < 1) {
          M1R = Y_p;
        }
        m6k = Y_p;
      }
      E$P[c1$] = m6k;
      if (U7i < Z6X) {
        d0H.d$R(64);
        F_D -= d0H.M3e(U7i, 1, Z6X);
        d0H.d$R(114);
        U7i = d0H.H9P(Z6X, '1');
      }
      if (U7i + F_D > F7C) {
        d0H.d$R(61);
        F_D = d0H.H9P(1, F7C, U7i);
      }
      d0H.d$R(18);
      n7K = d0H.M3e(F_D, U7i);
      if (U7i >= F7C) continue;
      if (n7K <= Z6X) continue;
      B3N = Math.floor(B8U) + (!a6f.highlight && 0.5);
      d0H.d$R(0);
      Q5Q = Math.floor(d0H.M3e(B3N, z1p));
      d0H.p1T(18);
      C_1 = Math.round(d0H.H9P(z1p, B3N));
      f2A = Q5Q == C_1 ? z1p : +'0';
      o6R = U7i === n7K || (D1L && M1R === Y_p);
      if (h3E.Hide) {
        continue;
      }
      if (c_t) {
        if (d2K || !o6R) {
          Q5Q += L9U;
          C_1 -= L9U;
          U7i += L9U;
          V1M.rect(Q5Q, U7i, Math.max(0, C_1 - Q5Q), F_D);
        }
      } else if (D1L) {
        if (o6R) {
          if (Y_p <= F7C && Y_p >= Z6X) {
            i82 = Math.floor(Y_p) + (!a6f.highlight && 0.5);
            d0H.d$R(0);
            V1M.moveTo(d0H.H9P(Q5Q, f2A), i82);
            d0H.p1T(18);
            V1M.lineTo(d0H.H9P(f2A, C_1), i82);
          }
        }
        if (n7K > U7i) {
          V1M.moveTo(B3N, U7i);
          V1M.lineTo(B3N, n7K);
        }
      } else if (s9b) {
        if (U7i < F7C && n7K > Z6X && h3E.High != h3E.Low) {
          d0H.p1T(0);
          V1M.moveTo(B3N, d0H.H9P(U7i, b1J));
          d0H.d$R(18);
          V1M.lineTo(B3N, d0H.M3e(b1J, n7K));
        }
        if (M1R > Z6X && M1R < F7C && !b0l) {
          z1o = Math.floor(M1R) + (!a6f.highlight && 0.5);
          V1M.moveTo(B3N, z1o);
          d0H.p1T(64);
          V1M.lineTo(d0H.M3e(z1p, f2A, B3N), z1o);
        }
        if (Y_p > Z6X && Y_p < F7C) {
          d8s = Math.floor(Y_p) + (!a6f.highlight && 0.5);
          V1M.moveTo(B3N, d8s);
          d0H.p1T(28);
          V1M.lineTo(d0H.M3e(z1p, B3N, f2A), d8s);
        }
      }
    }
    q9g = V1M.globalAlpha;
    if (c_t) {
      if (q9g < 1) {
        V1M.save();
        V1M.globalAlpha = 1;
        V1M.fillStyle = this.containerColor;
        V1M.fill();
        V1M.restore();
      }
      V1M.fill();
      if (R8K) {
        V1M.lineWidth = a6f.highlight ? 2 : 1;
        V1M.strokeStyle = E9K;
        V1M.stroke();
      }
    } else if (D1L || s9b) {
      this.canvasColor(U8m);
      V1M.globalAlpha = q9g;
      if (E9K) {
        V1M.strokeStyle = E9K;
      }
      if (a6f.highlight) {
        V1M.lineWidth *= +'2';
      }
      V1M.stroke();
      V1M.closePath();
      V1M.lineWidth = 1;
    }
    V1M.globalAlpha = c9G;
    return { cache: E$P };
  };
  b4z.ChartEngine.prototype.plotDataSegmentAsLine = function (
    F2j,
    Z7C,
    i8Z,
    J0F
  ) {
    var t8Z,
      w5Y,
      u7K,
      M_R,
      J$L,
      c1f,
      E4K,
      c14,
      T9Y,
      R6P,
      u$7,
      m0J,
      U7d,
      d6v,
      b2B,
      t9q,
      Z3F,
      T4C,
      G3V,
      E6g,
      a0Y,
      f3r,
      a8Y,
      I6N,
      r3W,
      K3K,
      i1V,
      W0s,
      B1E,
      p7W,
      P20,
      v99,
      u2n,
      E$U,
      C98,
      X$k,
      m$F,
      a53,
      w3M,
      F2l,
      u_X,
      b7Y,
      D4T,
      Z0A,
      n82,
      h$6,
      c2f,
      c7s,
      R94,
      N6d,
      a4E,
      o3Q,
      G5W,
      j5_,
      w1q,
      T0u,
      A4O,
      m_6,
      e3u,
      p9s,
      T21,
      v$q,
      k5N,
      T_X,
      b05,
      Y1b,
      I4R,
      U_a,
      q8G,
      a9d,
      v72,
      O_z,
      r0O,
      H_W,
      U3y,
      D7o,
      K10,
      f23,
      L1m,
      P4j,
      P7T,
      E20,
      A3H,
      x7Q,
      O5Y,
      K9V,
      I77,
      C2u,
      O6e,
      m96,
      U82,
      v1_,
      l37,
      V2t,
      N9a,
      q6p;
    t8Z = ![];
    w5Y = !{};
    u7K = !!'';
    M_R = !!'';
    J$L = !![];
    c1f = null;
    E4K = null;
    c14 = null;
    d0H.p1T(0);
    T9Y = d0H.H9P('0', 0);
    R6P = ![];
    u$7 = ![];
    m0J = !{};
    U7d = ![];
    d6v = null;
    b2B = null;
    t9q = null;
    Z3F = null;
    function l2k(i8u) {
      var c7z, Y0T, p0K, E7W, V6G, i3T, Z9b, X_W, o1K;
      c7z = i1V.getLineDash();
      Y0T = m0J ? 2 : 1;
      p0K = i8u;
      if (typeof p0K == 'object') {
        Y0T *= p0K.width;
        E4K = b4z.borderPatternToArray(Y0T, p0K.pattern);
        p0K = p0K.color;
      }
      T4C[p0K] = 1;
      if (R6P) {
        return;
      }
      E7W = G3V.slice(-2);
      V6G = E4K instanceof Array && E4K.join();
      i3T = c7z instanceof Array && c7z.join();
      d0H.p1T(143);
      Z9b = d0H.H9P(i3T, V6G);
      X_W = !b4z.colorsEqual(B1E, p0K);
      o1K = i1V.lineWidth != Y0T;
      if (X_W || Z9b || o1K) {
        if (T9Y) {
          E6g.push({
            coord: E7W,
            color: p0K,
            pattern: E4K ? E4K : [],
            width: Y0T,
          });
        } else {
          i1V.stroke();
          i1V.lineWidth = Y0T;
          if (Z9b) {
            i1V.setLineDash(V6G ? E4K : []);
          }
          i1V.beginPath();
          i1V.moveTo(E7W[0], E7W[1]);
        }
      }
      B1E = p0K;
      if (!T9Y) {
        if (!p0K || p0K == 'auto') {
          i1V.strokeStyle = a8Y.defaultColor;
        } else {
          i1V.strokeStyle = p0K;
        }
      }
    }
    T4C = {};
    G3V = [];
    E6g = [];
    a0Y = [];
    f3r = [];
    a8Y = this;
    I6N = this.layout;
    r3W = Z7C.chart;
    K3K = r3W.dataSegment;
    i1V = r3W.context;
    W0s = new Array(K3K.length);
    B1E = i1V.strokeStyle;
    p7W = i1V.globalAlpha;
    if (r3W.dataSet.length) {
      P20 = 'C';
      P20 += 'l';
      P20 += 'os';
      P20 += 'e';
      this.startClip(Z7C.name);
      if (i8Z) {
        t8Z = i8Z.skipProjections;
        w5Y = i8Z.skipTransform;
        u7K = i8Z.noSlopes;
        T9Y = i8Z.tension;
        M_R = i8Z.step;
        E4K = i8Z.pattern;
        J$L = i8Z.extendOffChart;
        c14 = i8Z.yAxis;
        c1f = i8Z.gapDisplayStyle;
        R6P = i8Z.noDraw;
        u$7 = i8Z.reverse;
        m0J = i8Z.highlight;
        if (i8Z.width) {
          i1V.lineWidth = i8Z.width;
        }
        U7d = i8Z.shiftRight;
        d6v = i8Z.subField;
        b2B = i8Z.threshold;
        t9q = i8Z.lineTravelSpacing;
        Z3F = i8Z.extendToEndOfDataSet;
      }
      if (!c1f && c1f !== !!0 && i8Z) {
        c1f = i8Z.gaps;
      }
      if (!c1f) {
        c1f = { color: 'transparent', fillMountain: !!'1' };
      }
      if (E4K instanceof Array) {
        i1V.setLineDash(E4K);
      }
      if (m0J) {
        i1V.lineWidth *= +'2';
      }
      if (!m0J && this.highlightedDraggable) {
        d0H.d$R(25);
        i1V.globalAlpha *= d0H.M3e(1, '0.3');
      }
      if (J$L !== ![]) {
        J$L = !![];
      }
      v99 = d6v || r3W.defaultPlotField || P20;
      if (!c14) {
        c14 = Z7C.yAxis;
      }
      u2n = r3W.transformFunc && c14 == r3W.panel.yAxis;
      E$U = i1V.lineWidth * +'2';
      C98 = u$7 ? r3W.top - E$U : r3W.bottom + E$U;
      if (b2B || b2B === 0) {
        C98 = this.pixelFromPrice(b2B, Z7C, c14);
      }
      X$k = !T9Y && R6P && c1f && c1f.fillMountain;
      m$F = F2j;
      a53 = F2j;
      for (var W2m = 0; W2m < K3K.length; W2m++) {
        w3M = 'o';
        w3M += 'bj';
        w3M += 'ec';
        w3M += 't';
        F2l = K3K[W2m];
        if (F2l && typeof F2l == w3M) {
          u_X = F2l[F2j];
          if (u_X || u_X === 0) {
            if (typeof u_X == 'object') {
              a53 = b4z.createObjectChainNames(F2j, [v99])[0];
            }
            break;
          }
        }
      }
      b7Y = { left: null, right: null };
      d0H.p1T(61);
      var G$c = d0H.H9P(4, 13, 16);
      D4T = r3W.dataSet.length - r3W.scroll - G$c;
      if (J$L) {
        b7Y.left = this.getPreviousBar(r3W, a53, 0);
        b7Y.right = this.getNextBar(r3W, a53, K3K.length - 1);
      }
      Z0A = !!1;
      n82 = !{};
      i1V.beginPath();
      c7s = b7Y.left;
      R94 = null;
      if (c7s) {
        R94 = c7s.transform;
      }
      if (c7s) {
        c2f = u2n ? (R94 ? R94[F2j] : null) : c7s[F2j];
        if (c2f || c2f === 0) {
          if (c2f[v99] || c2f[v99] === 0) {
            c2f = c2f[v99];
          }
          N6d = this.pixelFromTick(c7s.tick, r3W);
          a4E = this.pixelFromTransformedValue(c2f, Z7C, c14);
          i1V.moveTo(N6d, a4E);
          G3V.push(N6d, a4E);
          if (K3K[0].tick - c7s.tick > 1) {
            a0Y.push({ start: G3V.slice(-2), threshold: C98, tick: c7s });
            n82 = !![];
          }
          Z0A = !1;
        }
      }
      d0H.p1T(40);
      var j0X = d0H.M3e(12, 18, 215);
      o3Q = Z7C.left + this.micropixels - j0X;
      if (U7d) {
        o3Q += U7d;
      }
      if (M_R && i8Z && i8Z.alignStepToSide) {
        d0H.d$R(0);
        var W5x = d0H.M3e(14, 12);
        o3Q -= this.layout.candleWidth / W5x;
      }
      w1q = null;
      T0u = 0;
      A4O = 0;
      m_6 = !1;
      e3u = { reset: !!'1' };
      for (var i9k = 0; i9k < K3K.length; i9k++) {
        p9s = 'ob';
        p9s += 'j';
        p9s += 'ec';
        p9s += 't';
        h$6 = I6N.candleWidth;
        T21 = K3K[i9k];
        v$q = K3K[i9k];
        if (!T21) {
          T21 = {};
        }
        if (!T21.cache || r3W.pixelCacheExpired) {
          T21.cache = {};
        }
        k5N = T21.lineTravel;
        if (t8Z && T21.projection) {
          b7Y.right = null;
          break;
        }
        if (T21.candleWidth) {
          h$6 = T21.candleWidth;
        }
        if (t9q) {
          h$6 = 0;
        }
        if (u2n && T21.transform) {
          T21 = T21.transform;
        }
        T_X = T21[F2j];
        if (T_X && typeof T_X == p9s) {
          T_X = T_X[v99];
          d0H.d$R(28);
          m$F = d0H.H9P('.', F2j, v99);
        }
        if (r3W.lineApproximation && I6N.candleWidth < 1 && !t9q) {
          if (e3u.reset) {
            e3u = {
              CollatedHigh: -Number.MAX_VALUE,
              CollatedLow: Number.MAX_VALUE,
              CollatedOpen: null,
              CollatedClose: null,
            };
            m_6 = !1;
          }
          b05 = T_X;
          if (b05 || b05 === 0) {
            e3u.CollatedHigh = Math.max(e3u.CollatedHigh, b05);
            e3u.CollatedLow = Math.min(e3u.CollatedLow, b05);
            e3u.CollatedClose = b05;
            if (e3u.CollatedOpen === null) {
              e3u.CollatedOpen = b05;
            } else {
              m_6 = !!{};
            }
          }
          T0u += h$6;
          if (T0u - A4O >= 1 || i9k == K3K.length - 1) {
            A4O = Math.floor(T0u);
            e3u.reset = !0;
            e3u[F2j] = e3u.CollatedClose;
            T21 = e3u;
            T21.cache = {};
          } else {
            o3Q += h$6;
            continue;
          }
        }
        if (!u7K) {
          d0H.p1T(41);
          o3Q += d0H.H9P(2, h$6);
        }
        if (!T_X && T_X !== 0) {
          Y1b = G3V.slice(-2);
          if (X$k && !n82 && G3V.length) {
            G3V.push(Y1b[0], C98);
          }
          if (!n82) {
            a0Y.push({ start: Y1b, threshold: C98, tick: j5_ });
          }
          n82 = !0;
          o3Q += u7K ? h$6 : h$6 / 2;
          if ((M_R || u7K) && G3V.length) {
            W0s[i9k] = G3V.slice(-d0H.M3e('1', 0, d0H.p1T(20)))[+'0'];
          }
          if (k5N) {
            o3Q += k5N;
          }
          continue;
        }
        G5W = T21;
        I4R = T21.cache;
        d0H.p1T(18);
        U_a = d0H.H9P(i9k, D4T);
        if (U_a < Z7C.cacheLeft || U_a > Z7C.cacheRight || !I4R[F2j]) {
          q8G = c14.semiLog
            ? c14.height *
              (+'1' -
                (Math.log(Math.max(T_X, '0' << 64)) / Math.LN10 - c14.logLow) /
                  c14.logShadow)
            : (c14.high - T_X) * c14.multiplier;
          if (c14.flipped) {
            I4R[m$F] = c14.bottom - q8G;
          } else {
            I4R[m$F] = c14.top + q8G;
          }
        }
        a9d = W0s[i9k] = I4R[m$F];
        if (r3W.lastTickOffset) {
          if (w1q === null) {
            w1q = this.currentQuote(F2j) || {};
          }
          if (v$q.tick == w1q.tick) {
            o3Q += r3W.lastTickOffset;
          }
        }
        if (!Z0A && J0F) {
          if (v$q[F2j] && v$q[F2j][v99]) {
            v$q = v$q[F2j];
          }
          v72 = J0F(this, v$q, n82);
          if (!v72) {
            o3Q += u7K ? h$6 : h$6 / 2;
            continue;
          }
          l2k(v72);
        }
        if (Z0A) {
          i1V.moveTo(o3Q, a9d);
          if (T9Y) {
            E6g.push({
              coord: [o3Q, a9d],
              color: i1V.strokeStyle,
              pattern: E4K ? E4K : [],
              width: i1V.lineWidth,
            });
          }
        } else {
          if (M_R || u7K) {
            O_z = G3V.slice(-1)[0];
            if (m_6) {
              E6T(o3Q, O_z, T21);
            } else {
              i1V.lineTo(o3Q, O_z);
            }
            G3V.push(o3Q, O_z);
          }
          if (m_6 && !u7K) {
            E6T(o3Q, a9d, T21);
          } else {
            i1V[u7K ? 'moveTo' : 'lineTo'](o3Q, a9d);
          }
        }
        if (n82) {
          a0Y.push({ end: [o3Q, a9d], threshold: C98 });
          j5_ = v$q;
          if (X$k && !M_R && !u7K) {
            G3V.push(o3Q, C98);
          }
        }
        G3V.push(o3Q, a9d);
        Z0A = !!0;
        n82 = !'1';
        o3Q += u7K ? h$6 : h$6 / 2;
        if (k5N) {
          o3Q += k5N;
        }
      }
      r3W.pixelCacheExpired = !!'';
      r0O = b7Y.right;
      H_W = null;
      if (r0O) {
        H_W = r0O.transform;
      }
      if (!Z0A && r0O) {
        c2f = u2n ? (H_W ? H_W[F2j] : null) : r0O[F2j];
        if (c2f && (c2f[v99] || c2f[v99] === 0)) {
          c2f = c2f[v99];
        }
        U3y = this.pixelFromTick(r0O.tick, r3W);
        D7o = this.pixelFromTransformedValue(c2f, Z7C, c14);
        if (r0O.tick - K3K[K3K.length - 1].tick > 1) {
          if (!n82) {
            d0H.d$R(81);
            K10 = G3V.slice(-d0H.M3e(2, '2'));
            if (X$k && G3V.length) {
              G3V.push(K10['0' * 1], C98);
            }
            a0Y.push({ start: K10, threshold: C98, tick: K3K[K3K.length - 1] });
          }
          n82 = !!1;
        }
        if (!Z0A && J0F) {
          f23 = J0F(this, r0O, n82);
          if (f23) {
            l2k(f23);
          }
        }
        L1m = G3V.slice(-2);
        if (!E4K || !E4K.length) {
          if (M_R || u7K) {
            i1V.lineTo(U3y, L1m[1]);
            G3V.push(U3y, L1m[1]);
          }
          i1V[u7K ? 'moveTo' : 'lineTo'](U3y, D7o);
        }
        if (n82) {
          a0Y.push({ end: [U3y, D7o], threshold: C98 });
          if (X$k && !M_R && !u7K) {
            G3V.push(U3y, C98);
          }
        }
        G3V.push(U3y, D7o);
      }
      for (var T1Y in T4C) {
        f3r.push(T1Y);
      }
      if (i8Z && i8Z.extendToEndOfLastBar) {
        P4j = G3V.slice(-+'2');
        i1V.lineTo(P4j[0] + h$6, P4j[1]);
      } else if (M_R || u7K || this.extendLastTick || Z3F) {
        P7T = G3V.slice(-2);
        if (G3V.length) {
          E20 = P7T[+'0'];
          d0H.p1T(21);
          A3H = P7T[d0H.H9P('1', 0)];
          if (Z3F || (M_R && Z3F !== !1)) {
            E20 = this.pixelFromTick(r3W.dataSet.length - 1, r3W);
            if (u7K || this.extendLastTick) {
              d0H.d$R(41);
              E20 += d0H.H9P(2, h$6);
            }
          } else if (u7K) {
            E20 += h$6;
          } else if (this.extendLastTick) {
            d0H.d$R(41);
            E20 += d0H.M3e(2, h$6);
          }
          if (E20 > P7T['0' >> 64]) {
            x7Q = null;
            if (J0F) {
              x7Q = J0F(this, {}, !!{});
            }
            if (x7Q) {
              l2k(x7Q);
            }
            i1V.lineTo(E20, A3H);
            if (!n82 || !X$k) {
              G3V.push(E20, A3H);
            }
          }
        }
      }
      if (!R6P) {
        if (T9Y && G3V.length) {
          i1V.beginPath();
          if (i8Z && i8Z.pattern) {
            i1V.setLineDash(i8Z.pattern);
          }
          j6Z.plotSpline(G3V, T9Y, i1V, E6g);
        }
        i1V.stroke();
      }
      if (!R6P && i8Z && i8Z.label && G5W) {
        O5Y = 'noo';
        O5Y += 'p';
        I77 = G5W[F2j];
        if (I77 && typeof I77 == 'object') {
          I77 = I77[v99];
        }
        if (c14.priceFormatter) {
          K9V = c14.priceFormatter(this, Z7C, I77, i8Z.labelDecimalPlaces);
        } else {
          K9V = this.formatYAxisPrice(I77, Z7C, i8Z.labelDecimalPlaces);
        }
        C2u = this.yaxisLabelStyle;
        if (c14.yaxisLabelStyle) {
          C2u = c14.yaxisLabelStyle;
        }
        O6e = C2u == 'noop' ? i1V.strokeStyle : null;
        m96 = C2u == O5Y ? '#FFFFFF' : i1V.strokeStyle;
        this.yAxisLabels.push({
          src: 'plot',
          args: [Z7C, K9V, G5W.cache[m$F], m96, O6e, i1V, c14],
        });
      }
      U82 = typeof c1f == 'object' ? c1f.color : c1f;
      if (b4z.isTransparent(U82)) {
        for (var I$8 = 0; I$8 < a0Y.length; I$8 += 2) {
          v1_ = a0Y[I$8].start;
          if (I$8) {
            l37 = a0Y[I$8 - 1].end;
          }
          if (l37 && v1_[0] == l37[0] && v1_[1] == l37[1]) {
            i1V.beginPath();
            V2t = i1V.lineWidth;
            if (J0F) {
              N9a = 'obj';
              N9a += 'e';
              N9a += 'c';
              N9a += 't';
              q6p = J0F(this, a0Y[I$8].tick || {}, !!'');
              if (typeof q6p == N9a) {
                d0H.d$R(144);
                var p0N = d0H.M3e(0, 21, 2, 20, 2);
                V2t = q6p.width * (m0J ? p0N : +'1');
                q6p = q6p.color;
              }
              i1V.strokeStyle = i1V.fillStyle = q6p;
            }
            i1V.lineWidth = V2t;
            i1V.arc(v1_[0], v1_[1], 1, '0' >> 32, 2 * Math.PI);
            i1V.stroke();
            i1V.fill();
          }
        }
      }
      this.endClip();
    }
    function E6T(F4f, A1R, h39) {
      var f2c, I25, D7T, P$A, I1r, f5J, o4L, c5t, i8R;
      i1V.setLineDash([]);
      f2c = s1d('CollatedOpen');
      I25 = 550045474;
      D7T = -769300938;
      P$A = 2;
      for (
        var G5Z = 1;
        d0H.q78(G5Z.toString(), G5Z.toString().length, +'4106') !== I25;
        G5Z++
      ) {
        I1r = 'Collat';
        I1r += 'edHigh';
        f5J = 'Collat';
        f5J += 'edHigh';
        o4L = s1d(f5J);
        c5t = s1d(I1r);
        i1V.lineTo(F4f, f2c);
        P$A += 2;
      }
      function s1d(f90) {
        var r_h;
        d0H.t3P();
        r_h = c14.semiLog
          ? c14.height *
            (1 -
              (Math.log(Math.max(h39[f90], 0)) / Math.LN10 - c14.logLow) /
                c14.logShadow)
          : (c14.high - h39[f90]) * c14.multiplier;
        if (c14.flipped) {
          r_h = c14.bottom - r_h;
        } else {
          r_h += c14.top;
        }
        return r_h;
      }
      if (d0H.e_M(P$A.toString(), P$A.toString().length, 65917) !== D7T) {
        i8R = 'Co';
        i8R += 'llat';
        i8R += 'edL';
        i8R += 'ow';
        o4L = s1d('CollatedHigh');
        c5t = s1d(i8R);
        i1V.lineTo(F4f, f2c);
      }
      i1V.moveTo(F4f, o4L);
      i1V.lineTo(F4f, c5t);
      i1V.moveTo(F4f, A1R);
      G3V.push(F4f, f2c);
    }
    i1V.globalAlpha = p7W;
    return { colors: f3r, points: G3V, cache: W0s, gapAreas: a0Y };
  };
  d0H.Y3Y();
  b4z.ChartEngine.prototype.drawMountainChart = function (v7J, m8G, F59) {
    var u8x,
      N4w,
      a8n,
      v05,
      H8P,
      L7j,
      v1y,
      m2i,
      C_N,
      B8q,
      o$J,
      Y$L,
      t1w,
      Y$W,
      M8J,
      f8d,
      p3C,
      O1n,
      D1_,
      g08,
      k35,
      M23,
      o$Q,
      O2d,
      v3w,
      M6r,
      x5R,
      f0D,
      b6J,
      n11,
      Q3l,
      y1S,
      C7_,
      M6v,
      t7_,
      P8y;
    u8x = this.chart.context;
    N4w = m8G;
    a8n = !{};
    v05 = !{};
    H8P = null;
    L7j = null;
    v1y = null;
    m2i = null;
    C_N = 0;
    B8q = null;
    o$J = ![];
    Y$L = null;
    t1w = null;
    Y$W = !'1';
    M8J = null;
    f8d = null;
    p3C = 1;
    O1n = !!0;
    D1_ = !!'';
    g08 = ![];
    k35 = v7J.chart;
    M23 = k35.dataSegment;
    o$Q = k35.lineStyle || {};
    if (!m8G || typeof m8G != 'object') {
      m8G = { style: m8G };
    }
    N4w = m8G.style || 'stx_mountain_chart';
    H8P = m8G.field || k35.defaultPlotField || 'Close';
    L7j = m8G.subField || k35.defaultPlotField || 'Close';
    B8q = m8G.gapDisplayStyle;
    if (!B8q && B8q !== !1) {
      B8q = m8G.gaps;
    }
    if (!B8q && B8q !== !{}) {
      B8q = k35.gaplines;
    }
    if (!B8q) {
      B8q = 'transparent';
    }
    v1y = m8G.yAxis || v7J.yAxis;
    a8n = m8G.reverse || !{};
    m2i = m8G.tension;
    Y$L = m8G.fillStyle;
    C_N = m8G.width || o$Q.width;
    o$J = m8G.step;
    t1w = m8G.pattern || o$Q.pattern;
    Y$W = m8G.highlight;
    f8d = m8G.color || o$Q.color;
    M8J = m8G.baseColor || o$Q.baseColor;
    v05 = m8G.colored;
    p3C = m8G.opacity;
    O1n = m8G.extendToEndOfDataSet;
    D1_ = m8G.isComparison;
    g08 = m8G.returnObject;
    O2d = this.canvasStyle(N4w);
    v3w = v1y.top;
    if (isNaN(v3w) || isNaN(v3w / v3w)) {
      v3w = +'0';
    }
    M6r =
      f8d ||
      (N4w && O2d.backgroundColor ? O2d.backgroundColor : this.defaultColor);
    x5R = M8J || (N4w && O2d.color ? O2d.color : this.containerColor);
    if (Y$L) {
      u8x.fillStyle = Y$L;
    } else if (M8J || O2d.color) {
      f0D = u8x.createLinearGradient(0, v3w, 0, v1y.bottom);
      f0D.addColorStop(v1y.flipped ? 1 : 0, M6r);
      f0D.addColorStop(v1y.flipped ? 0 : 1, x5R);
      u8x.fillStyle = f0D;
    } else {
      u8x.fillStyle = M6r;
    }
    this.startClip(v7J.name);
    b6J = u8x.lineWidth;
    if (!m8G.symbol) {
      L7j = null;
    }
    m8G = {
      skipProjections: !0,
      reverse: a8n,
      yAxis: v1y,
      gapDisplayStyle: B8q,
      step: o$J,
      highlight: Y$W,
      extendToEndOfDataSet: O1n,
      isComparison: D1_,
    };
    if (k35.tension) {
      m8G.tension = k35.tension;
    }
    if (m2i || m2i === +'0') {
      m8G.tension = m2i;
    }
    n11 = parseInt(O2d.paddingTop, 10);
    Q3l = f8d || O2d.borderTopColor;
    y1S = null;
    if (v05 || (Q3l && !b4z.isTransparent(Q3l))) {
      if (n11) {
        C7_ = this.scratchContext;
        if (!C7_) {
          M6v = u8x.canvas.cloneNode(!!{});
          C7_ = this.scratchContext = M6v.getContext('2d');
        }
        C7_.canvas.height = u8x.canvas.height;
        C7_.canvas.width = u8x.canvas.width;
        C7_.drawImage(u8x.canvas, 0, +'0');
        b4z.clearCanvas(u8x.canvas, this);
      }
    }
    b4z.extend(m8G, {
      panelName: v7J.name,
      direction: m8G.reverse ? -1 : 1,
      band: H8P,
      subField: L7j,
      opacity: p3C,
    });
    if (!m8G.highlight && this.highlightedDraggable) {
      m8G.opacity *= 0.3;
    }
    b4z.preparePeakValleyFill(this, m8G);
    if (v05 || (Q3l && !b4z.isTransparent(Q3l))) {
      if (n11) {
        t7_ = 'dest';
        t7_ += 'inat';
        t7_ += 'i';
        t7_ += 'on-out';
        u8x.save();
        d0H.d$R(25);
        u8x.lineWidth += d0H.H9P(n11, 2);
        u8x.globalCompositeOperation = t7_;
        d0H.d$R(81);
        u8x.globalAlpha = d0H.M3e(1, '1');
        this.plotDataSegmentAsLine(H8P, v7J, m8G);
        u8x.globalCompositeOperation = 'destination-over';
        u8x.scale(
          1 / this.adjustedDisplayPixelRatio,
          1 / this.adjustedDisplayPixelRatio
        );
        u8x.drawImage(this.scratchContext.canvas, 0, 0);
        u8x.restore();
      }
    }
    u8x.strokeStyle = Q3l;
    if (C_N) {
      u8x.lineWidth = C_N;
    } else if (O2d.width && parseInt(O2d.width, 10) <= 25) {
      u8x.lineWidth = Math.max(1, b4z.stripPX(O2d.width));
    } else {
      u8x.lineWidth = 1;
    }
    if (!t1w) {
      t1w = O2d.borderTopStyle;
    }
    m8G.pattern = b4z.borderPatternToArray(u8x.lineWidth, t1w);
    P8y = F59;
    if (B8q) {
      P8y = this.getGapColorFunction(
        H8P,
        L7j,
        { color: Q3l, pattern: m8G.pattern, width: u8x.lineWidth },
        B8q,
        F59
      );
    }
    y1S = this.plotDataSegmentAsLine(H8P, v7J, m8G, P8y);
    u8x.lineWidth = b6J;
    this.endClip();
    if (!y1S.colors.length) {
      y1S.colors.push(Q3l);
    }
    return g08 ? y1S : y1S.colors;
  };
  b4z.ChartEngine.prototype.drawBaselineChart = function (H2d, X4j) {
    var T1X,
      v5w,
      J40,
      C57,
      W21,
      Y66,
      w0H,
      t45,
      i$z,
      I2H,
      S4e,
      O7v,
      i3Q,
      H5Q,
      S1w,
      k9b,
      H3I,
      z8u,
      k56,
      s2B,
      U8Q,
      F_k,
      m_f,
      O5j,
      x6p,
      z$7,
      P9C,
      h6m,
      S8X,
      C5C,
      y79,
      v8Q,
      Z8r,
      Y_H,
      x3C;
    var { chart: r5g } = H2d;
    var { field: C5w, id: N4R, yAxis: u$u } = X4j;
    var { gaplines: K4O, defaultPlotField: h1_, lineStyle: U4l } = r5g;
    var { display: L8g } = this.baselineHelper.get(
      this.getRendererFromSeries(N4R)
    );
    T1X = this.getYAxisBaseline(u$u).actualLevel;
    v5w = [];
    if (!C5w) {
      C5w = h1_;
    }
    if (!U4l) {
      U4l = {};
    }
    J40 = X4j.gapDisplayStyle;
    if (!J40 && J40 !== ![]) {
      J40 = X4j.gaps;
    }
    if (T1X !== null && !isNaN(T1X)) {
      C57 = 'stx_';
      C57 += 'baselin';
      C57 += 'e';
      W21 = 'stx_';
      W21 += 'baseline';
      Y66 = 'stx_';
      Y66 += 'baseline_up';
      w0H = 'stx_ba';
      w0H += 'seline_down';
      t45 = 'st';
      t45 += 'x_b';
      t45 += 'aseline_up';
      i$z = 'mo';
      i$z += 'unta';
      i$z += 'i';
      i$z += 'n';
      I2H = X4j.type == i$z;
      if (I2H) {
        v5w = this.drawMountainChart(H2d, {
          style: X4j.style,
          field: X4j.field,
          yAxis: u$u,
          gapDisplayStyle: J40,
          colored: !![],
          tension: 0,
        });
      }
      S4e = this.pixelFromPrice(T1X, H2d, u$u);
      if (isNaN(S4e)) {
        return;
      }
      this.startClip(H2d.name);
      O7v = X4j.pattern || U4l.pattern;
      i3Q = X4j.fill_color_up || this.getCanvasColor(t45);
      H5Q = X4j.fill_color_down || this.getCanvasColor('stx_baseline_down');
      S1w = X4j.border_color_up || this.getCanvasColor('stx_baseline_up');
      k9b = X4j.border_color_down || this.getCanvasColor(w0H);
      H3I = X4j.width || U4l.width || this.canvasStyle(Y66).width;
      z8u =
        X4j.width || U4l.width || this.canvasStyle('stx_baseline_down').width;
      k56 =
        X4j.widthBaseline ||
        U4l.width ||
        b4z.stripPX(this.canvasStyle(W21).width);
      s2B = X4j.baselineOpacity || this.canvasStyle(C57).opacity;
      U8Q = { fill: i3Q, edge: S1w, width: H3I };
      F_k = { fill: H5Q, edge: k9b, width: z8u };
      m_f = X4j.yAxis.flipped;
      O5j = { over: m_f ? F_k : U8Q, under: m_f ? U8Q : F_k };
      x6p = ![];
      if (!J40 && J40 !== !1) {
        J40 = K4O;
      }
      z$7 = 1;
      if (!X4j.highlight && this.highlightedDraggable) {
        z$7 *= 0.3;
      }
      for (var z$w in O5j) {
        P9C = 'tran';
        P9C += 'sp';
        P9C += 'aren';
        P9C += 't';
        h6m = 'transparen';
        h6m += 't';
        S8X = parseInt(Math.max(1, b4z.stripPX(O5j[z$w].width)), 10);
        if (X4j.highlight) {
          S8X *= 2;
        }
        O7v = b4z.borderPatternToArray(S8X, O7v);
        C5C = {
          panelName: H2d.name,
          band: C5w,
          threshold: T1X,
          color: I2H ? h6m : O5j[z$w].fill,
          direction: z$w == 'over' ? +'1' : -1,
          edgeHighlight: O5j[z$w].edge,
          edgeParameters: { pattern: O7v, lineWidth: S8X + 0.1, opacity: z$7 },
          gapDisplayStyle: J40,
          yAxis: X4j.yAxis,
        };
        if (u$u) {
          C5C.threshold = this.priceFromPixel(
            this.pixelFromPrice(C5C.threshold, H2d, u$u),
            H2d,
            u$u
          );
        }
        v5w.push(O5j[z$w].edge);
        y79 = C5C.color;
        if (!I2H && y79 && y79 != 'transparent') {
          v8Q = H2d.top;
          Z8r = H2d.bottom;
          Y_H = r5g.context.createLinearGradient(
            0,
            z$w == 'over' ? v8Q : Z8r,
            0,
            S4e
          );
          Y_H.addColorStop(0, b4z.hexToRgba(b4z.colorToHex(y79), 60));
          d0H.d$R(20);
          Y_H.addColorStop(
            d0H.H9P('1', 32),
            b4z.hexToRgba(b4z.colorToHex(y79), 10)
          );
          C5C.color = Y_H;
          C5C.opacity = z$7;
        }
        b4z.preparePeakValleyFill(this, r5g.dataSegment, C5C);
        if (K4O) {
          if (K4O === true) {
            K4O = {};
          }
          if (!K4O.fillMountain) {
            this.drawLineChart(H2d, null, null, {
              color: 'transparent',
              gapDisplayStyle: {
                color: this.containerColor,
                pattern: 'solid',
                width: C5C.edgeParameters.lineWidth,
              },
            });
          }
          if (!K4O.color) {
            x6p = !!{};
            K4O.color = this.defaultColor;
          }
        }
        this.drawLineChart(H2d, null, null, {
          color: P9C,
          width: C5C.edgeParameters.lineWidth,
        });
        if (x6p) {
          K4O.color = null;
        }
      }
      if (L8g) {
        x3C = 'lin';
        x3C += 'e';
        d0H.d$R(25);
        this.plotLine(
          d0H.M3e(1, '0'),
          1,
          S4e,
          S4e,
          this.containerColor,
          'line',
          r5g.context,
          H2d,
          { lineWidth: '1.1' }
        );
        this.plotLine(
          0,
          1,
          S4e,
          S4e,
          this.getCanvasColor('stx_baseline'),
          x3C,
          r5g.context,
          H2d,
          {
            pattern: 'dotted',
            lineWidth: k56 || '2.1',
            opacity: s2B || 0.5 * z$7,
          }
        );
      }
      this.endClip();
    }
    return { colors: v5w };
  };
  b4z.ChartEngine.prototype.plotLine = function (B$_) {
    var Z_s,
      N54,
      R8Z,
      t7M,
      z7j,
      D99,
      J2T,
      c6_,
      y4l,
      U03,
      s1r,
      J2o,
      m0P,
      s4$,
      w_6,
      F4n,
      Y0f,
      V07,
      m1l,
      x4U,
      K9Q,
      Y08,
      W$q,
      h6a,
      n_w,
      k4t,
      o7f,
      M8n,
      Z1Q,
      Q7W,
      v6F,
      l6S,
      V3T,
      X$T;
    Z_s = 'vert';
    Z_s += 'i';
    Z_s += 'cal';
    N54 = 'ho';
    N54 += 'rizonta';
    N54 += 'l';
    R8Z = 'no';
    R8Z += 'n';
    R8Z += 'e';
    if (typeof arguments[0] == 'number') {
      B$_ = {
        x0: arguments[0],
        x1: arguments[1],
        y0: arguments[+'2'],
        y1: arguments[3],
        color: arguments[+'4'],
        type: arguments[5],
        context: arguments[6],
        confineToPanel: arguments[+'7'],
      };
      for (var x44 in arguments[8]) {
        B$_[x44] = arguments[8][x44];
      }
    }
    if (!B$_) {
      B$_ = {};
    }
    if (B$_.pattern == R8Z) {
      return;
    }
    t7M = B$_.x0;
    z7j = B$_.x1;
    D99 = B$_.y0;
    J2T = B$_.y1;
    c6_ = B$_.color;
    y4l = B$_.type;
    U03 = B$_.context;
    s1r = B$_.confineToPanel;
    J2o = B$_.deferStroke;
    if (s1r === !!{}) {
      s1r = this.chart.panel;
    }
    if (U03 === null || typeof U03 == 'undefined') {
      U03 = this.chart.context;
    }
    if (isNaN(t7M) || isNaN(z7j) || isNaN(D99) || isNaN(J2T)) {
      return;
    }
    m0P = 0;
    s4$ = this.chart.canvasHeight;
    w_6 = 0;
    F4n = this.right;
    if (s1r) {
      s4$ = s1r.yAxis.bottom;
      m0P = s1r.yAxis.top;
      w_6 = s1r.left;
      F4n = s1r.right;
    }
    if (y4l == 'ray') {
      Y0f = 10000000;
      if (z7j < t7M) {
        Y0f = -10000000;
      }
      m1l = { x0: t7M, x1: z7j, y0: D99, y1: J2T };
      V07 = b4z.yIntersection(m1l, Y0f);
      z7j = Y0f;
      J2T = V07;
    }
    if (y4l == 'line' || y4l == N54 || y4l == Z_s) {
      d0H.p1T(26);
      Y0f = d0H.H9P('10000000', 0);
      x4U = -+'10000000';
      m1l = { x0: t7M, x1: z7j, y0: D99, y1: J2T };
      V07 = b4z.yIntersection(m1l, Y0f);
      K9Q = b4z.yIntersection(m1l, x4U);
      t7M = x4U;
      z7j = Y0f;
      D99 = K9Q;
      J2T = V07;
    }
    Y08 = 0.0;
    W$q = 1.0;
    d0H.p1T(0);
    h6a = d0H.M3e(z7j, t7M);
    d0H.d$R(0);
    n_w = d0H.M3e(J2T, D99);
    for (var V7t = 0; V7t < 4; V7t++) {
      if (V7t === 0) {
        k4t = -h6a;
        d0H.d$R(0);
        o7f = -d0H.H9P(w_6, t7M);
      }
      if (V7t == 1) {
        k4t = h6a;
        d0H.p1T(0);
        o7f = d0H.M3e(F4n, t7M);
      }
      if (V7t == 2) {
        k4t = -n_w;
        d0H.p1T(0);
        o7f = -d0H.M3e(m0P, D99);
      }
      if (V7t == 3) {
        k4t = n_w;
        d0H.d$R(0);
        o7f = d0H.H9P(s4$, D99);
      }
      d0H.p1T(41);
      M8n = d0H.M3e(k4t, o7f);
      if ((J2T || J2T === +'0') && k4t === 0 && o7f < 0) {
        return !!'';
      }
      if (k4t < 0) {
        if (M8n > W$q) {
          return ![];
        } else if (M8n > Y08) {
          Y08 = M8n;
        }
      } else if (k4t > +'0') {
        if (M8n < Y08) {
          return !{};
        } else if (M8n < W$q) {
          W$q = M8n;
        }
      }
    }
    d0H.d$R(128);
    Z1Q = d0H.H9P(t7M, h6a, Y08);
    d0H.p1T(128);
    Q7W = d0H.H9P(D99, n_w, Y08);
    d0H.d$R(128);
    v6F = d0H.M3e(t7M, h6a, W$q);
    d0H.p1T(128);
    l6S = d0H.M3e(D99, n_w, W$q);
    if (!J2T && J2T !== 0 && !D99 && D99 !== 0) {
      Q7W = m0P;
      l6S = s4$;
      Z1Q = m1l.x0;
      v6F = m1l.x0;
      if (m1l.x0 > F4n) {
        return !'1';
      }
      if (m1l.x0 < w_6) {
        return !'1';
      }
    } else if (!J2T && J2T !== 0) {
      if (m1l.y0 < m1l.y1) {
        l6S = s4$;
      } else {
        l6S = m0P;
      }
      Z1Q = m1l.x0;
      v6F = m1l.x0;
      if (m1l.x0 > F4n) {
        return !!'';
      }
      if (m1l.x0 < w_6) {
        return !!'';
      }
    }
    if (!J2o) {
      U03.save();
      U03.beginPath();
    }
    U03.lineWidth = 1.1;
    if (c6_ && typeof c6_ == 'object') {
      U03.strokeStyle = c6_.color;
      if (c6_.opacity) {
        U03.globalAlpha = c6_.opacity;
      } else {
        U03.globalAlpha = 1;
      }
      U03.lineWidth = b4z.stripPX(c6_.width);
    } else {
      V3T = 'a';
      V3T += 'u';
      V3T += 'to';
      if (!c6_ || c6_ == V3T || b4z.isTransparent(c6_)) {
        U03.strokeStyle = this.defaultColor;
      } else {
        U03.strokeStyle = c6_;
      }
    }
    if (B$_.opacity) {
      U03.globalAlpha = B$_.opacity;
    }
    if (B$_.lineWidth) {
      U03.lineWidth = B$_.lineWidth;
    }
    if (B$_.globalCompositeOperation) {
      U03.globalCompositeOperation = B$_.globalCompositeOperation;
    }
    X$T = b4z.borderPatternToArray(U03.lineWidth, B$_.pattern);
    U03.setLineDash(B$_.pattern ? X$T : []);
    U03.moveTo(Z1Q, Q7W);
    U03.lineTo(v6F, l6S);
    if (!J2o) {
      U03.stroke();
      U03.restore();
    }
  };
  b4z.ChartEngine.prototype.rendererAction = function (X4q, n2V) {
    var n9u, H8h, e0g, z3B, c$I, s9P, H4u, S6i, g71, b6t, S03;
    n9u = -215944836;
    H8h = 474002969;
    d0H.p1T(26);
    e0g = d0H.H9P('2', 64);
    function D2S() {
      var r5e, L_n, Q1d, j8f, p$g;
      r5e = 404606591;
      L_n = -420074488;
      Q1d = 2;
      for (
        var D15 = 1;
        d0H.q78(D15.toString(), D15.toString().length, 94311) !== r5e;
        D15++
      ) {
        j8f = this;
        Q1d += 2;
      }
      if (d0H.e_M(Q1d.toString(), Q1d.toString().length, 46000) !== L_n) {
        j8f = this;
      }
      if (!z3B && n2V === 'underlay') {
        p$g = Symbol.for('CIQ.watermark');
        if (j8f[p$g]) {
          j8f[p$g].draw(X4q);
          z3B = !!'1';
        }
      }
    }
    for (
      var x7y = 1;
      d0H.e_M(x7y.toString(), x7y.toString().length, 80153) !== n9u;
      x7y++
    ) {
      z3B = !!'';
      e0g += +'2';
    }
    if (d0H.e_M(e0g.toString(), e0g.toString().length, 58243) !== H8h) {
      z3B = !!{};
    }
    if (!this.runPrepend('rendererAction', arguments)) {
      for (var p54 in X4q.seriesRenderers) {
        c$I = '_';
        c$I += 'm';
        c$I += 'ain_series';
        s9P = 'u';
        s9P += 'nderl';
        s9P += 'ay';
        H4u = 'u';
        H4u += 'nd';
        H4u += 'er';
        H4u += 'lay';
        S6i = X4q.seriesRenderers[p54];
        g71 = S6i.params;
        b6t = g71.panel;
        S03 = this.panels[b6t];
        if (g71.overChart && n2V == H4u) continue;
        if (g71.name == '_main_series' && n2V == s9P) continue;
        if (g71.name != c$I && n2V == 'main') continue;
        if (!g71.overChart && n2V == 'overlay') continue;
        if (!S03) continue;
        if (S03.chart !== X4q) continue;
        if (S03.hidden) continue;
        if (n2V == 'yAxis') {
          S6i.adjustYAxis();
        } else {
          D2S.apply(this);
          S6i.draw();
          if (S6i.cb) {
            S6i.cb(S6i.colors);
          }
        }
      }
      this.runAppend('rendererAction', arguments);
    }
    D2S.apply(this);
  };
  b4z.ChartEngine.prototype.drawSeries = function (d7R, a7X, w8V, V7d) {
    var b8w,
      F2L,
      g0j,
      b4u,
      m0I,
      v4t,
      C9e,
      c4c,
      n9f,
      o08,
      v3v,
      c5s,
      m8M,
      F$e,
      P35,
      D8N,
      A9A;
    if (this.runPrepend('drawSeries', arguments)) {
      return;
    }
    b8w = d7R.dataSegment;
    d0H.t3P();
    F2L = null;
    if (!a7X) {
      a7X = d7R.series;
    }
    for (var R5L in a7X) {
      g0j = 'Clo';
      g0j += 'se';
      F2L = a7X[R5L];
      b4u = F2L.parameters;
      m0I = b4u.panel ? this.panels[b4u.panel] : d7R.panel;
      v4t = b4u.color;
      C9e = b4u.width;
      c4c = b4u.field;
      if (!m0I) continue;
      n9f = b4u.yAxis = w8V ? w8V : m0I.yAxis;
      if (!v4t) {
        v4t = n9f.textStyle || this.defaultColor;
      }
      if (v4t == 'auto') {
        v4t = this.defaultColor;
      }
      if (!c4c) {
        c4c = d7R.defaultPlotField;
      }
      o08 = b4u.subField || d7R.defaultPlotField || g0j;
      if (
        !b4u._rawExtendToEndOfDataSet &&
        b4u._rawExtendToEndOfDataSet !== !'1'
      ) {
        b4u._rawExtendToEndOfDataSet = b4u.extendToEndOfDataSet;
      }
      if (d7R.animatingHorizontalScroll) {
        b4u.extendToEndOfDataSet = !1;
      } else {
        b4u.extendToEndOfDataSet = b4u._rawExtendToEndOfDataSet;
      }
      v3v = b4u.colorFunction;
      if (F2L.highlight || F2L.parameters.highlight) {
        b4u.highlight = !!1;
      }
      c5s = { colors: [] };
      if (V7d) {
        if (V7d.params.highlight) {
          b4u.highlight = !![];
        }
        if (b4u.hidden) continue;
        c5s = V7d.drawIndividualSeries(d7R, b4u) || c5s;
      } else if (b4u.type == 'mountain') {
        c5s = this.drawMountainChart(
          m0I,
          b4z.extend({ returnObject: !!1 }, b4u),
          v3v
        );
      } else {
        c5s = this.drawLineChart(
          m0I,
          b4u.style,
          v3v,
          b4z.extend({ returnObject: !!{} }, b4u)
        );
      }
      F2L.yValueCache = c5s.cache;
      d0H.d$R(0);
      var Y7I = d0H.M3e(6, 5);
      m8M = d7R.dataSegment[d7R.dataSegment.length - Y7I];
      if (m8M) {
        F$e = !b4u.skipTransform && d7R.transformFunc && n9f == d7R.panel.yAxis;
        if (!m8M[c4c] && m8M[c4c] !== 0) {
          m8M = this.getPreviousBar(d7R, c4c, d7R.dataSegment.length - 1);
        }
        if (F$e && m8M && m8M.transform) {
          m8M = m8M.transform;
        }
      }
      if (
        b4u.displayFloatingLabel !== ![] &&
        this.mainSeriesRenderer != V7d &&
        m8M &&
        !n9f.noDraw
      ) {
        P35 = m8M[c4c];
        if (P35) {
          if (P35[o08] || P35[o08] === 0) {
            P35 = P35[o08];
          } else {
            P35 = P35.iqPrevClose;
          }
        }
        if (n9f.priceFormatter) {
          D8N = n9f.priceFormatter(this, m0I, P35);
        } else {
          D8N = this.formatYAxisPrice(P35, m0I, null, n9f);
        }
        this.yAxisLabels.push({
          src: 'series',
          args: [
            m0I,
            D8N,
            this.pixelFromTransformedValue(P35, m0I, n9f),
            b4z.hexToRgba(b4z.colorToHex(v4t), parseFloat(b4u.opacity)),
            null,
            null,
            n9f,
          ],
        });
      }
      if (d7R.legend && b4u.useChartLegend) {
        if (!d7R.legend.colorMap) {
          d7R.legend.colorMap = {};
        }
        A9A = b4u.display;
        if (!A9A) {
          A9A = b4u.symbol;
        }
        d7R.legend.colorMap[R5L] = {
          color: c5s.colors,
          display: A9A,
          isBase: V7d == this.mainSeriesRenderer,
        };
      }
    }
    this.runAppend('drawSeries', arguments);
  };
};
e$ = (r3I) => {
  var C$V;
  C$V = r3I.CIQ;
  C$V.ChartEngine.prototype.scrollTo = function (s_D, b5i, e2y) {
    var Y9V = v7ZLk;
    var a6k, k3s, J8n, i3h, N52;
    a6k = this.swipe;
    k3s = -859107828;
    J8n = -1394222182;
    i3h = 2;
    Y9V.t3P();
    for (
      var M5z = '1' | 1;
      Y9V.e_M(M5z.toString(), M5z.toString().length, 67198) !== k3s;
      M5z++
    ) {
      a6k.end = !{};
      a6k.amplitude = a6k.target = b5i / s_D.scroll / this.layout.candleWidth;
      a6k.timeConstant = 847;
      a6k.timestamp = Date.now();
      a6k.scroll = s_D.scroll;
      a6k.chart = s_D;
      i3h += +'2';
    }
    if (Y9V.q78(i3h.toString(), i3h.toString().length, 30276) !== J8n) {
      a6k.end = !0;
      a6k.amplitude = a6k.target = (b5i - s_D.scroll) * this.layout.candleWidth;
      a6k.timeConstant = 100;
      a6k.timestamp = Date.now();
      a6k.scroll = s_D.scroll;
      a6k.chart = s_D;
    }
    a6k.cb = e2y;
    N52 = this;
    requestAnimationFrame(function () {
      Y9V.Y3Y();
      N52.autoscroll();
    });
  };
  C$V.ChartEngine.prototype.autoscroll = function () {
    var O4Z, h5Y, x8q, x_o;
    O4Z = this;
    h5Y = this.swipe;
    if (h5Y.amplitude) {
      h5Y.elapsed = Date.now() - h5Y.timestamp;
      x8q = -h5Y.amplitude * Math.exp(-h5Y.elapsed / h5Y.timeConstant);
      x_o = (h5Y.target + x8q) / this.layout.candleWidth;
      h5Y.chart.scroll = h5Y.scroll + Math.round(x_o);
      this.draw();
      this.updateChartAccessories();
      if (x8q > 0.5 || x8q < -0.5) {
        requestAnimationFrame(function () {
          O4Z.autoscroll();
        });
      } else {
        if (this.disableBackingStoreDuringTouch) {
          this.reconstituteBackingStore();
        }
        if (h5Y.cb) {
          h5Y.cb();
        }
      }
    }
  };
};
c9 = (p8l) => {
  var W8j = v7ZLk;
  var H65, N$r, Z$U, o33, C1p;
  H65 = 'borde';
  H65 += 'r';
  W8j.t3P();
  N$r = p8l.CIQ;
  Z$U = 'boundary';
  o33 = 'line';
  C1p = H65;
  N$r.ChartEngine.prototype.drawXAxis = function (t1T, u_D) {
    var o_G,
      j9f,
      V2J,
      z7D,
      r2g,
      i2P,
      d8c,
      t2i,
      w3v,
      i2J,
      o3C,
      T4y,
      Y_g,
      N9n,
      w3k,
      x_8,
      E3Y,
      K_g,
      V8z,
      M4E,
      T6m,
      S3x,
      I5f,
      l8D,
      k$5,
      D_W,
      N0p,
      Y8u;
    o_G = 't';
    o_G += 'i';
    o_G += 'c';
    o_G += 'k';
    j9f = [t1T, u_D];
    if (this.runPrepend('drawXAxis', j9f)) {
      return;
    }
    if (!u_D) {
      return;
    }
    var { lineBaseLevel: R3n } = N$r.ChartEngine.XAxis;
    var { interval: o4M, timeUnit: H1j } = this.layout;
    var { xAxis: Z7V } = t1T;
    var {
      displayGridLines: v9J,
      displayBorder: H1o,
      fitLeftToRight: k9p,
      minimumLabelWidth: l1N,
      noDraw: w6l,
      fitTight: c1s,
    } = Z7V;
    V2J = k9p || o4M === 'tick' || H1j === o_G;
    if (w6l) {
      return;
    }
    z7D = this.getBackgroundCanvas().context;
    this.canvasFont('stx_xaxis', z7D);
    W8j.t3P();
    r2g = this.getCanvasFontSize('stx_xaxis');
    z7D.textAlign = 'center';
    z7D.textBaseline = 'middle';
    function e_C(C4I) {
      W8j.Y3Y();
      var X6I, g1A, I0S, H_$, Z8j, J6l, R6w, p8M, W0J, r9s, D8T, q8H;
      C4I.sort((E1I, F5L) => {
        if (E1I.level < F5L.level) {
          return -1;
        }
        if (E1I.level > F5L.level) {
          return 1;
        }
        if (E1I.hz < F5L.hz) {
          return -1;
        }
        if (E1I.hz > F5L.hz) {
          return 1;
        }
        W8j.Y3Y();
        return 0;
      });
      g1A = Number.MAX_SAFE_INTEGER;
      I0S = {};
      H_$ = {};
      for (X6I = 0; X6I < C4I.length; X6I++) {
        Z8j = 'r';
        Z8j += 'ight';
        J6l = C4I[X6I];
        if (J6l.level > g1A) break;
        if (
          this.container.ownerDocument.body.classList.contains('sharing') &&
          (J6l.left <= N9n.left || J6l.unpaddedRight >= N9n.right)
        ) {
          J6l.suppress = !!{};
        }
        R6w = Math.floor(J6l.left);
        p8M = Math.ceil(J6l[c1s ? 'unpaddedRight' : Z8j]);
        for (var g4A = R6w; g4A <= p8M; g4A++) {
          W0J = I0S[g4A] || {};
          if (W0J.level <= J6l.level) {
            r9s = W0J.alwaysAsBoundary && W0J.level < g1A;
            if (
              (r9s && !J6l.alwaysAsBoundary) ||
              (W0J.grid === Z$U && J6l.grid === o33) ||
              V2J
            ) {
              D8T = 'h';
              D8T += 'z';
              if (
                (W0J.left < R6w && W0J[i2P ? D8T : 'right'] >= R6w) ||
                (W0J.left > R6w && W0J[i2P ? 'hz' : 'left'] <= p8M)
              ) {
                J6l.suppress = !'';
                break;
              }
            } else if (J6l.level) {
              W8j.d$R(145);
              var L4C = W8j.M3e(19, 218, 15, 10, 5);
              g1A = J6l.level - L4C;
              delete H_$[J6l.level];
              break;
            }
          }
          I0S[g4A] = J6l;
          if (g4A === p8M) {
            if (!H_$[J6l.level]) {
              W8j.d$R(26);
              H_$[J6l.level] = W8j.M3e('0', 32);
            }
            H_$[J6l.level]++;
          }
        }
      }
      q8H = Object.entries(H_$).reverse();
      if (
        q8H.length > 1 &&
        !V2J &&
        q8H[0][0] >= R3n &&
        q8H[1][0] < R3n &&
        q8H[0][1] === 1 &&
        (q8H['1' ^ 0][1] > 1 || q8H.length > 2)
      ) {
        q8H.shift();
      }
      if (q8H.length) {
        g1A = Number(q8H[0][0]);
      }
      return g1A;
    }
    i2P = !V2J && this.xaxisHeight > 3 * r2g;
    d8c = !!0;
    t2i = new Map();
    i2J = z7D.measureText('   ').width;
    for (var Q6b = 0; Q6b < u_D.length; Q6b++) {
      w3v = u_D[Q6b];
      o3C = t2i.get(w3v.text);
      if (o3C === undefined) {
        o3C = z7D.measureText(w3v.text).width;
        t2i.set(w3v.text, o3C);
      }
      W8j.d$R(18);
      T4y = Math.max(W8j.M3e(i2J, o3C), l1N);
      w3v.hz = Math.floor(w3v.hz + this.micropixels) - 0.5;
      w3v.left = w3v.hz - T4y / +'2';
      W8j.d$R(123);
      var Z6r = W8j.M3e(428, 4, 61, 1280);
      w3v.right = w3v.hz + T4y / ('2' >> Z6r);
      W8j.p1T(18);
      var d0$ = W8j.M3e(2, 0);
      w3v.unpaddedRight = w3v.hz + o3C / d0$;
    }
    Y_g =
      this.xAxisAsFooter === !!{} ? this.chart.canvasHeight : t1T.panel.bottom;
    W8j.d$R(0);
    N9n = this.whichPanel(W8j.M3e(Y_g, 1));
    if (!N9n) {
      return;
    }
    this.adjustYAxisHeightOffset(N9n, N9n.yAxis);
    w3k = H1o || H1o === null;
    if (this.axisBorders === !![]) {
      w3k = !0;
    }
    if (this.axisBorders === ![]) {
      w3k = !1;
    }
    x_8 = Y_g - this.xaxisHeight + r2g;
    if (w3k) {
      x_8 += 3;
    }
    E3Y = !!{};
    K_g = e_C.call(this, u_D);
    t1T.xAxis.maxLevelToDisplay = K_g;
    W8j.p1T(146);
    V8z = W8j.M3e(R3n, K_g);
    for (var J_0 in this.panels) {
      M4E = this.panels[J_0];
      if (M4E.hidden || M4E.shareChartXAxis === !{}) continue;
      W8j.p1T(95);
      T6m = W8j.M3e(M4E, N9n);
      S3x = M4E.yAxis;
      if (!S3x) continue;
      z7D.save();
      z7D.beginPath();
      z7D.rect(
        M4E.left,
        M4E.top + (E3Y ? +'0' : 1),
        M4E.width,
        M4E.height - ('1' | 1)
      );
      z7D.clip();
      E3Y = !{};
      I5f = new N$r.Plotter();
      I5f.newSeries(o33, 'stroke', this.canvasStyle('stx_grid'));
      I5f.newSeries(Z$U, 'stroke', this.canvasStyle('stx_grid_dark'));
      I5f.newSeries(C1p, 'stroke', this.canvasStyle('stx_grid_border'));
      for (var V31 = 0; V31 < u_D.length; V31++) {
        w3v = u_D[V31];
        if (w3v.level > K_g) break;
        if (w3v.suppress) continue;
        if (Math.floor(w3v.left) <= M4E.right) {
          l8D =
            i2P &&
            w3v.grid === Z$U &&
            ((w3v.alwaysAsBoundary && w3v.level < K_g) || V8z);
          if (Math.floor(w3v.hz) > M4E.left) {
            if (v9J) {
              I5f.moveTo(w3v.grid, w3v.hz, S3x.top);
              I5f.lineTo(w3v.grid, w3v.hz, S3x.bottom);
            }
            if (T6m && w3k) {
              I5f.moveTo(C1p, w3v.hz, S3x.bottom + +'0.5');
              I5f.lineTo(C1p, w3v.hz, S3x.bottom + 6);
              if (l8D) {
                I5f.dashedLineTo(C1p, w3v.hz, S3x.bottom + 6 + r2g + 3, [1, 3]);
              }
            }
          }
          if (T6m && w3v.right > M4E.left) {
            k$5 = 'st';
            k$5 += 'x_';
            k$5 += 'xaxis';
            this.canvasColor(
              (w3v.grid == Z$U && V8z) ||
                (w3v.alwaysAsBoundary && w3v.level < K_g)
                ? 'stx_xaxis_dark'
                : k$5,
              z7D
            );
            if (!V8z && w3v.boundaryOnlyText) {
              w3v.text = w3v.boundaryOnlyText;
            }
            z7D.fillText(w3v.text, w3v.hz, x_8 + (l8D ? r2g + 3 : 0));
            if (d8c) {
              D_W = 'g';
              D_W += 'ree';
              D_W += 'n';
              z7D.strokeStyle = 'red';
              z7D.strokeRect(
                w3v.left,
                x_8 - r2g,
                w3v.unpaddedRight - w3v.left,
                r2g * 2
              );
              z7D.strokeStyle = D_W;
              z7D.strokeRect(
                w3v.unpaddedRight,
                x_8 - r2g,
                w3v.right - w3v.unpaddedRight,
                r2g * +'2'
              );
            }
          }
        }
      }
      if (w3k) {
        N0p = Math.round(S3x.bottom) + +'0.5';
        Y8u = Math.round(M4E.right) + 0.5;
        I5f.moveTo(C1p, M4E.left, N0p);
        I5f.lineTo(C1p, Y8u, N0p);
      }
      I5f.draw(z7D);
      z7D.restore();
    }
    z7D.textAlign = 'left';
    this.runAppend('drawXAxis', j9f);
  };
  N$r.ChartEngine.prototype.createSpacedDateXAxis = function (B7C) {
    var R$V,
      e8q,
      E$L,
      A4f,
      X1m,
      R0u,
      v_U,
      a7N,
      A7S,
      V6_,
      M6X,
      K7D,
      Q$S,
      o7O,
      D_9,
      g87,
      z$2,
      z9s,
      M_2;
    R$V = 's';
    R$V += 'ec';
    R$V += 'ond';
    e8q = 'm';
    e8q += 'in';
    e8q += 'ute';
    E$L = 'm';
    E$L += 'ont';
    E$L += 'h';
    A4f = 'y';
    A4f += 'ear';
    if (!B7C) {
      B7C = this.chart;
    }
    var { lineBaseLevel: W9A } = N$r.ChartEngine.XAxis;
    var {
      dataSegment: d5l,
      lineApproximation: l4g,
      market: z9P,
      maxTicks: o9W,
      xaxis: i2d,
      xAxis: v$M,
    } = B7C;
    var { candleWidth: Q_p, interval: N6h, timeUnit: B0Z } = this.layout;
    i2d.length = 0;
    for (var l2n = 0; l2n < o9W; l2n++) {
      R0u = d5l[l2n];
      if (R0u) {
        if (l2n && !i2d.length) {
          if (z9P) {
            X1m = this.standardMarketIterator(
              R0u.DT,
              v$M.adjustTimeZone ? this.displayZone : null
            );
          }
          for (var H4o = l2n; H4o > ('0' ^ 0); H4o--) {
            v_U = {};
            if (X1m && !(l4g && Q_p < +'1')) {
              v_U.DT = X1m.previous();
            }
            i2d.unshift(v_U);
          }
          X1m = null;
        }
      }
      if (R0u || (l2n && i2d.length)) {
        a7N = R0u && R0u.DT;
        if (R0u && R0u.displayDate && v$M.adjustTimeZone) {
          a7N = R0u.displayDate;
        }
        if (!a7N) {
          if (z9P) {
            if (N6h == 'tick' && !v$M.futureTicksInterval) break;
            if (l4g && Q_p < 1) break;
            if (!v$M.futureTicks) break;
            if (!X1m) {
              X1m = this.standardMarketIterator(
                d5l[l2n - 1].DT,
                v$M.adjustTimeZone ? this.displayZone : null
              );
            }
            a7N = X1m.next();
          } else continue;
        }
        A7S = { DT: a7N, data: null };
        if (l2n < d5l.length) {
          A7S.data = R0u;
        }
        i2d.push(A7S);
      }
    }
    V6_ = [];
    if (!B7C.tzOffsetCache) {
      B7C.tzOffsetCache = new Map();
    }
    if (B7C.tzOffsetCache.size > '20000' * 1) {
      B7C.tzOffsetCache.clear();
    }
    M6X = B7C.tzOffsetCache;
    K7D = (z9P && z9P.market_def) || {};
    Q$S = (K7D.normal_daily_open || '00:00').split(':');
    W8j.p1T(18);
    var F0A = W8j.H9P(57, 3);
    o7O = Number(Q$S[+'0']) * F0A;
    if (!K7D.hour_aligned) {
      o7O += Number(Q$S[1]);
    }
    D_9 = (E8z) => {
      var w$S, Q0u, b57, x_Z, y4g, M$n;
      w$S = E8z === 'year' || (N6h !== E8z && B0Z !== E8z) ? Z$U : o33;
      Q0u = N$r.ChartEngine.XAxis.setPropertiesForInterval[E8z];
      W8j.Y3Y();
      b57 = B7C.left;
      i2d.forEach((w7u, D2X) => {
        var a40, P5c, X9L, Z3f, f9I, s4S, L0B, I44;
        a40 = 'un';
        a40 += 'define';
        a40 += 'd';
        P5c = (w7u.data && w7u.data.candleWidth) || Q_p;
        X9L = w7u.DT;
        if (X9L) {
          f9I = 'd';
          f9I += 'a';
          f9I += 'y';
          switch (E8z) {
            case 'year':
              Z3f = X9L.getFullYear();
              break;
            case 'month':
              Z3f = X9L.getMonth();
              break;
            case 'week':
            case f9I:
              Z3f = X9L.getDate();
              break;
            case 'minute':
              W8j.p1T(28);
              var C_8 = W8j.M3e(15, 7, 38);
              Z3f = X9L.getHours() * C_8 + X9L.getMinutes();
              break;
            case 'second':
              Z3f = X9L.getSeconds();
              break;
            case 'millisecond':
            case 'tick':
              Z3f = X9L.getMilliseconds();
              break;
            default:
              break;
          }
        }
        if (w$S === o33) {
          W8j.p1T(111);
          b57 += W8j.M3e('2', 1, P5c);
        }
        if (typeof Z3f !== a40 && (w$S === o33 || x_Z !== Z3f)) {
          s4S = new N$r.ChartEngine.XAxisLabel({
            hz: b57,
            grid: w$S,
            raw: Z3f,
          });
          if (E8z === 'minute') {
            if (!y4g || y4g.getDate() !== X9L.getDate()) {
              y4g = X9L;
              L0B = z9P && z9P.market_tz;
              I44 = `${+X9L}|${L0B}|${this.displayZone}`;
              M$n = M6X.get(I44);
              if (M$n === undefined) {
                W8j.p1T(0);
                var q9D = W8j.M3e(60009, 9);
                M$n =
                  (N$r.convertTimeZone(X9L, this.displayZone, L0B).getTime() -
                    X9L) /
                  q9D;
                M6X.set(I44, M$n);
              }
            }
            W8j.d$R(0);
            s4S.marketOpenOffset = W8j.M3e(M$n, o7O);
          }
          if (Q0u) {
            Q0u(s4S, w7u, z9P, this);
          }
          if (s4S.grid == o33 && s4S.level < W9A) {
            s4S.level += W9A;
          }
          if (D2X || w$S === o33) {
            V6_.push(s4S);
          }
          x_Z = Z3f;
        }
        if (w$S === o33) {
          W8j.p1T(41);
          b57 += W8j.M3e(2, P5c);
        } else {
          b57 += P5c;
        }
      });
    };
    g87 = B0Z || N6h;
    z$2 = [A4f, 'month', 'week', E$L, 'day', e8q, R$V, 'millisecond', 'tick'];
    W8j.Y3Y();
    z9s = z$2.indexOf(g87);
    if (z9s > 0) {
      M_2 = 'ti';
      M_2 += 'ck';
      if (g87 !== M_2) {
        D_9(z$2[z9s - 1]);
      }
      D_9(z$2[z9s]);
    }
    return V6_;
  };
};
J4 = (R44) => {
  var f8j = v7ZLk;
  var q3f;
  q3f = R44.CIQ;
  f8j.t3P();
  q3f.ChartEngine.prototype.createYAxis = function (t82, Z_j) {
    var R1C,
      q_2,
      U0Y,
      W8R,
      m5g,
      Q4o,
      L2m,
      y9_,
      f2Y,
      g90,
      Y_I,
      Q8J,
      k$b,
      v10,
      F_a,
      E4r,
      i3f,
      R$P,
      g$f,
      b82,
      N$i,
      o$u,
      E8u,
      B7Y,
      x21;
    R1C = 'cr';
    R1C += 'eate';
    R1C += 'YAxis';
    if (this.runPrepend('createYAxis', arguments)) {
      return;
    }
    q_2 = t82.chart;
    f8j.t3P();
    U0Y = t82.name == q_2.name;
    if (!Z_j) {
      Z_j = {};
    }
    Z_j.noChange = !'1';
    W8R = Z_j.yAxis ? Z_j.yAxis : t82.yAxis;
    if (
      q3f.ChartEngine.enableCaching &&
      W8R.high == t82.cacheHigh &&
      W8R.low == t82.cacheLow
    ) {
      f8j.d$R(34);
      var M5F = f8j.H9P(35, 12, 0, 3);
      m5g = q_2.dataSet.length - q_2.scroll - M5F;
      f8j.d$R(35);
      var y4L = f8j.M3e(8, 7);
      Q4o = m5g + q_2.maxTicks + y4L;
      t82.cacheLeft = m5g;
      t82.cacheRight = Q4o;
      Z_j.noChange = !'';
    } else {
      t82.cacheLeft = 1000000;
      t82.cacheRight = -1;
      t82.cacheHigh = W8R.high;
      t82.cacheLow = W8R.low;
    }
    L2m = q_2.xAxis.idealTickSizePixels || q_2.xAxis.autoComputedTickSizePixels;
    if (W8R.goldenRatioYAxis) {
      if (W8R.idealTickSizePixels != L2m / 1.618) {
        Z_j.noChange = !'1';
      }
    }
    if (!Z_j.noChange) {
      f2Y = 'proj';
      f2Y += 'ecti';
      f2Y += 'on';
      this.adjustYAxisHeightOffset(t82, W8R);
      g90 = W8R.height = W8R.bottom - W8R.top;
      Y_I = (W8R.high - W8R.low) / (g90 - W8R.zoom);
      if (!W8R.semiLog) {
        if (Z_j.ground) {
          W8R.high = W8R.high + W8R.zoom * Y_I;
        } else {
          f8j.p1T(31);
          var c$a = f8j.M3e(1, 1, 1);
          W8R.high = W8R.high + (W8R.zoom / c$a + W8R.scroll) * Y_I;
          W8R.low = W8R.low - (W8R.zoom / ('2' ^ 0) - W8R.scroll) * Y_I;
        }
      }
      if (W8R.min || W8R.min === 0) {
        W8R.low = W8R.min;
      }
      if (W8R.max || W8R.max === 0) {
        W8R.high = W8R.max;
      }
      W8R.shadow = W8R.high - W8R.low;
      if (
        W8R.semiLog &&
        (!this.activeDrawing || this.activeDrawing.name != f2Y)
      ) {
        Q8J = function () {
          var e$5;
          W8R.logHigh = Math.log(W8R.high) / Math.LN10;
          e$5 = Math.max(W8R.low, +'0.000000001');
          W8R.logLow = Math.log(e$5) / Math.LN10;
          if (W8R.low <= +'0') {
            W8R.logLow = 0;
          }
          W8R.logShadow = W8R.logHigh - W8R.logLow;
        };
        if (W8R.semiLog) {
          Q8J();
        }
        k$b = W8R.height / (W8R.height - W8R.zoom);
        if (W8R.flipped) {
          W8R.high = this.transformedPriceFromPixel(
            W8R.bottom + k$b * (W8R.zoom / 2 + W8R.scroll),
            t82,
            W8R
          );
          W8R.low = this.transformedPriceFromPixel(
            W8R.top - k$b * (W8R.zoom / 2 - W8R.scroll),
            t82,
            W8R
          );
        } else {
          W8R.high = this.transformedPriceFromPixel(
            W8R.top - k$b * (W8R.zoom / 2 + W8R.scroll),
            t82,
            W8R
          );
          W8R.low = this.transformedPriceFromPixel(
            W8R.bottom + k$b * (W8R.zoom / 2 - W8R.scroll),
            t82,
            W8R
          );
        }
        W8R.shadow = W8R.high - W8R.low;
        if (W8R.semiLog) {
          Q8J();
        }
      }
      if (W8R.goldenRatioYAxis && U0Y && W8R == t82.yAxis) {
        f8j.d$R(41);
        W8R.idealTickSizePixels = f8j.H9P(1.618, L2m);
        if (W8R.idealTickSizePixels === 0) {
          v10 = this.getCanvasFontSize('stx_yaxis');
          f8j.d$R(147);
          W8R.idealTickSizePixels = f8j.M3e(0, v10, '5');
        }
      } else {
        if (!W8R.idealTickSizePixels) {
          F_a = 'st';
          F_a += 'x_';
          F_a += 'yax';
          F_a += 'is';
          v10 = this.getCanvasFontSize(F_a);
          if (U0Y) {
            f8j.p1T(84);
            W8R.idealTickSizePixels = f8j.H9P(v10, '5', 96);
          } else {
            f8j.d$R(25);
            W8R.idealTickSizePixels = f8j.H9P(2, v10);
          }
        }
      }
      E4r = Math.round(g90 / W8R.idealTickSizePixels);
      y9_ = Z_j.range ? Z_j.range[1] - Z_j.range[+'0'] : W8R.shadow;
      f8j.d$R(41);
      W8R.priceTick = Math.floor(f8j.M3e(E4r, y9_));
      i3f = 1;
      for (var k$Q = 0; k$Q < 10; k$Q++) {
        if (W8R.priceTick > 0) break;
        i3f *= 10;
        W8R.priceTick = Math.floor((y9_ / E4r) * i3f) / i3f;
      }
      if (k$Q == 10) {
        W8R.priceTick = 0.00000001;
      }
      W8R.priceTick = Math.round((y9_ / E4r) * i3f) / i3f;
      R$P = Math.round(y9_ / W8R.priceTick);
      if (Z_j.range && R$P < y9_ && !W8R.noEvenDivisorTicks) {
        while (R$P >= 1) {
          if (y9_ % R$P === +'0') break;
          R$P--;
        }
        f8j.p1T(41);
        W8R.priceTick = f8j.H9P(R$P, y9_);
      }
      if (W8R.minimumPriceTick) {
        g$f = W8R.minimumPriceTick;
        v10 = this.getCanvasFontSize('stx_yaxis');
        for (var I5D = 0; I5D < 100; I5D++) {
          f8j.d$R(41);
          b82 = f8j.H9P(g$f, y9_);
          if (g90 / b82 < v10 * ('2' ^ 0)) {
            g$f += W8R.minimumPriceTick;
          } else break;
        }
        if (I5D < 100) {
          W8R.priceTick = g$f;
        }
      }
    }
    if (W8R.priceTick <= 0 || W8R.priceTick === Infinity) {
      W8R.priceTick = 1;
    }
    W8R.multiplier = W8R.height / W8R.shadow;
    if (W8R.multiplier == Infinity) {
      W8R.multiplier = +'0';
    }
    if (!W8R.decimalPlaces && W8R.decimalPlaces !== 0) {
      if (U0Y) {
        N$i = 0;
        for (var I6n = 0; I6n < t82.yAxis.shadowBreaks.length; I6n++) {
          o$u = t82.yAxis.shadowBreaks[I6n];
          if (t82.yAxis.shadow < o$u[0]) {
            N$i = o$u[1];
          }
        }
        W8R.printDecimalPlaces = N$i;
      } else {
        W8R.printDecimalPlaces = null;
      }
    } else {
      E8u = 877027260;
      B7Y = -66768871;
      x21 = 2;
      for (
        var t0y = +'1';
        f8j.q78(t0y.toString(), t0y.toString().length, 83750) !== E8u;
        t0y++
      ) {
        W8R.printDecimalPlaces = W8R.decimalPlaces;
        x21 += 2;
      }
      if (f8j.e_M(x21.toString(), x21.toString().length, +'84178') !== B7Y) {
        W8R.printDecimalPlaces = W8R.decimalPlaces;
      }
    }
    this.runAppend(R1C, arguments);
  };
  q3f.ChartEngine.prototype.drawYAxis = function (f7J, o1b) {
    var y5a,
      y6q,
      k7F,
      o9N,
      V8D,
      v7r,
      E7d,
      L3H,
      S$l,
      z$L,
      B47,
      O88,
      r1u,
      b0J,
      X3h,
      s7w,
      R6G,
      b4f,
      J$3,
      M1C,
      D4i,
      M7j,
      J3m,
      R45,
      u55,
      d23,
      j$4,
      j5E,
      X2b,
      T3h,
      r_X,
      I67,
      f93,
      s51,
      Q1C,
      O5r,
      C8D,
      l9t,
      Q4h,
      w38;
    if (!o1b) {
      o1b = {};
    }
    y5a = o1b.yAxis ? o1b.yAxis : f7J.yAxis;
    if (f7J.hidden || y5a.noDraw || !y5a.width) {
      return;
    }
    if (!q3f.Comparison || y5a.priceFormatter != q3f.Comparison.priceFormat) {
      y6q = y5a.fractional;
      if (y6q) {
        if (!y5a.originalPriceFormatter) {
          y5a.originalPriceFormatter = { func: y5a.priceFormatter };
        }
        if (!y6q.resolution) {
          y6q.resolution = y5a.minimumPrice;
        }
        if (!y6q.formatter) {
          y6q.formatter = "'";
        }
        if (!y5a.priceFormatter) {
          y5a.priceFormatter = function (j4e, X60, m0u) {
            var M1w, L9m, s6a, j0Y;
            if (!y6q) {
              return;
            }
            f8j.t3P();
            M1w = '';
            if (m0u < 0) {
              M1w = '-';
              m0u = Math.abs(m0u);
            }
            L9m = Math.floor(Math.round(m0u / y6q.resolution) * y6q.resolution);
            s6a = Math.round((m0u - L9m) / y6q.resolution);
            j0Y = Math.floor(s6a);
            f8j.p1T(40);
            var g1o = f8j.H9P(2, 20, 30);
            f8j.p1T(144);
            var k3M = f8j.M3e(401, 6410, 12, 9, 59);
            f8j.p1T(18);
            var C7A = f8j.H9P(5463, 390);
            return (
              M1w +
              L9m +
              y6q.formatter +
              (j0Y < g1o ? '0' : '') +
              j0Y +
              (s6a - j0Y >= 0.5 ? (82.38 > (k3M, C7A) ? 816.43 : '+') : '')
            );
          };
        }
      } else {
        if (y5a.originalPriceFormatter) {
          y5a.priceFormatter = y5a.originalPriceFormatter.func;
          y5a.originalPriceFormatter = null;
        }
      }
    }
    f8j.t3P();
    k7F = this.colorOrStyle(y5a.textStyle || 'stx_yaxis');
    o9N = this.highlightedDraggable;
    V8D = 0;
    if (o9N && this.yaxisMatches(o9N, y5a)) {
      V8D = 0.15;
    } else if (y5a.highlight) {
      V8D = +'0.1';
    }
    if (V8D) {
      v7r = k7F.constructor == String ? k7F : k7F.color;
      y5a.setBackground(this, { color: v7r, opacity: V8D });
    }
    if (y5a.pretty) {
      return this.drawYAxisPretty(f7J, o1b);
    }
    if (this.runPrepend('drawYAxis', arguments)) {
      return;
    }
    if (!o1b.noDraw && !y5a.noDraw) {
      E7d = y5a.yAxisPlotter;
      if (!E7d || !o1b.noChange) {
        L3H = 'l';
        L3H += 'e';
        L3H += 'f';
        L3H += 't';
        S$l = 'st';
        S$l += 'ro';
        S$l += 'ke';
        z$L = 'bord';
        z$L += 'er';
        E7d = y5a.yAxisPlotter = new q3f.Plotter();
        B47 = f7J.chart;
        O88 = f7J.name == B47.name && y5a.name === f7J.yAxis.name;
        if (!y5a.priceTick) {
          return;
        }
        r1u = y5a.shadow;
        b0J = o1b.range;
        if (b0J) {
          r1u = b0J['1' ^ 0] - b0J[0];
        }
        X3h = r1u / y5a.priceTick;
        X3h = Math.round(X3h);
        if (y5a.semiLog) {
          s7w =
            Math.log(
              this.valueFromPixel(y5a.flipped ? y5a.top : y5a.bottom, f7J)
            ) / Math.LN10;
          R6G = (y5a.logHigh - y5a.logLow) / X3h;
        }
        E7d.newSeries('grid', 'stroke', this.canvasStyle('stx_grid'));
        E7d.newSeries('text', 'fill', k7F);
        E7d.newSeries(z$L, S$l, this.canvasStyle('stx_grid_border'));
        b4f = 0;
        J$3 = b0J ? b0J[1] : y5a.high;
        M1C = b0J ? b0J[0] : y5a.low;
        D4i =
          y5a.displayBorder === null
            ? B47.panel.yAxis.displayBorder
            : y5a.displayBorder;
        if (this.axisBorders === !{}) {
          D4i = !!0;
        }
        if (this.axisBorders === !![]) {
          D4i = !!'1';
        }
        J3m = B47.dynamicYAxis;
        R45 = J3m ? y5a.width : NaN;
        u55 = this.getYAxisCurrentPosition(y5a, f7J);
        if (u55 == L3H) {
          M7j = y5a.left + y5a.width;
        } else {
          M7j = y5a.left;
        }
        d23 = Math.round(M7j) + 0.5;
        j$4 = D4i ? 3 : 0;
        if (u55 == 'left') {
          j$4 = D4i ? -3 : 0;
        }
        if (O88) {
          if (y5a.shadow < 1) {
            f8j.d$R(18);
            var u8W = f8j.H9P(1, 0);
            b4f =
              (parseInt(M1C / y5a.priceTick, +'10') + u8W) * y5a.priceTick -
              M1C;
          } else {
            b4f =
              y5a.priceTick -
              Math.round((M1C % y5a.priceTick) * f7J.chart.roundit) /
                f7J.chart.roundit;
          }
        } else {
          b4f = J$3 % y5a.priceTick;
        }
        j5E = this.getCanvasFontSize('stx_yaxis');
        for (var p93 = 0; p93 < X3h; p93++) {
          if (y5a.semiLog) {
            f8j.d$R(128);
            T3h = f8j.H9P(s7w, R6G, p93);
            X2b = Math.pow(10, T3h);
          } else {
            if (O88) {
              X2b = M1C + p93 * y5a.priceTick + b4f;
            } else {
              X2b = J$3 - p93 * y5a.priceTick - b4f;
            }
          }
          r_X = this.pixelFromTransformedValue(X2b, f7J, y5a);
          f8j.d$R(148);
          var O7n = f8j.H9P(0, 18, 20, 16, 301);
          I67 = Math.floor(r_X) + '0.5' * O7n;
          if (I67 + j5E / 2 > f7J.bottom) continue;
          if (I67 - j5E / 2 < f7J.top) continue;
          if (Math.abs(I67 - y5a.bottom) < 1) continue;
          if (y5a.flipped) {
            I67 = y5a.top + y5a.bottom - I67;
          }
          if (y5a.displayGridLines) {
            f93 = 'g';
            f93 += 'r';
            f93 += 'i';
            f93 += 'd';
            E7d.moveTo('grid', f7J.left + 1, I67);
            E7d.lineTo(f93, f7J.right - 1, I67);
          }
          if (D4i) {
            f8j.d$R(0);
            E7d.moveTo('border', f8j.H9P(d23, 0.5), I67);
            f8j.p1T(18);
            E7d.lineTo('border', f8j.M3e(j$4, d23), I67);
          }
          if (y5a.priceFormatter) {
            X2b = y5a.priceFormatter(this, f7J, X2b);
          } else {
            X2b = this.formatYAxisPrice(X2b, f7J, null, y5a);
          }
          s51 = y5a.textBackground ? this.containerColor : null;
          Q1C = 3;
          f8j.d$R(28);
          O5r = f8j.H9P(j$4, M7j, Q1C);
          if (u55 == 'left') {
            O5r = y5a.left + Q1C;
            if (y5a.justifyRight !== !1) {
              O5r = y5a.left + y5a.width + j$4 - Q1C;
            }
          } else {
            if (y5a.justifyRight) {
              O5r = M7j + y5a.width;
            }
          }
          E7d.addText('text', X2b, O5r, I67, s51, null, j5E);
          if (J3m) {
            R45 = Math.max(
              R45,
              B47.context.measureText(X2b).width + Math.abs(j$4) + Q1C
            );
          }
        }
        if (D4i) {
          C8D = 'bo';
          C8D += 'rder';
          l9t = 'bo';
          l9t += 'r';
          l9t += 'de';
          l9t += 'r';
          Q4h = Math.round(y5a.bottom) + 0.5;
          E7d.moveTo(l9t, d23, y5a.top);
          E7d.lineTo(C8D, d23, Q4h);
          E7d.draw(this.getBackgroundCanvas(B47).context, 'border');
        }
        if (J3m && R45 > y5a.width) {
          y5a._dynamicWidth = R45;
          this.calculateYAxisPositions();
          throw new Error('reboot draw');
        } else if (!J3m && y5a._dynamicWidth) {
          w38 = 're';
          w38 += 'bo';
          w38 += 'ot draw';
          this.resetDynamicYAxis({ chartName: B47.name });
          throw new Error(w38);
        }
      }
      if (y5a == f7J.yAxis) {
        this.plotYAxisGrid(f7J);
      }
    }
    this.runAppend('drawYAxis', arguments);
  };
  q3f.ChartEngine.prototype.drawYAxisPretty = function (M91, f6w) {
    var U8G,
      S6s,
      U0h,
      V_m,
      d4y,
      W3d,
      R0w,
      i0U,
      b73,
      W_Z,
      q0J,
      F0j,
      r8p,
      W7s,
      c6C,
      a7s,
      j9v,
      h6N,
      m63,
      C93,
      u0N,
      f1s,
      a1g,
      W8M,
      A4W,
      E4E,
      V2U,
      B12,
      i$Q,
      m5X,
      K02,
      K0G,
      t5Q,
      X71,
      A85,
      y9K,
      S8O,
      l1X,
      d96,
      j46,
      x5e,
      L73,
      N41,
      h2b,
      M1S,
      d2T,
      p$$,
      n75,
      T5P,
      m1R;
    U8G = 'drawYAx';
    U8G += 'is';
    S6s = 'dr';
    S6s += 'awYAxis';
    if (this.runPrepend(S6s, arguments)) {
      return;
    }
    if (!f6w) {
      f6w = {};
    }
    U0h = f6w.yAxis || M91.yAxis;
    f8j.t3P();
    if (M91.hidden || U0h.noDraw || !U0h.width) {
      return;
    }
    if (!f6w.noDraw) {
      V_m = U0h.yAxisPlotter;
      if (!V_m || !f6w.noChange) {
        d4y = 'stx_grid_bor';
        d4y += 'der';
        W3d = 'bor';
        W3d += 'der';
        R0w = 'stx_gri';
        R0w += 'd';
        V_m = U0h.yAxisPlotter = new q3f.Plotter();
        i0U = M91.chart;
        if (!U0h.priceTick) {
          return;
        }
        if (isNaN(U0h.high) || isNaN(U0h.low)) {
          return;
        }
        b73 = U0h.shadow;
        if (f6w.range) {
          f8j.p1T(65);
          var n6y = f8j.H9P(8, 8, 65);
          b73 = f6w.range[n6y] - f6w.range[0];
        }
        W_Z =
          (U0h.height - (f6w.range ? U0h.zoom : 0)) / U0h.idealTickSizePixels;
        W_Z = Math.round(W_Z);
        q0J = U0h.textStyle || 'stx_yaxis';
        V_m.newSeries('grid', 'stroke', this.canvasStyle(R0w));
        V_m.newSeries('text', 'fill', this.colorOrStyle(q0J));
        V_m.newSeries(W3d, 'stroke', this.canvasStyle(d4y));
        F0j = f6w.range;
        r8p = F0j ? F0j[1] : U0h.high;
        W7s = F0j ? F0j[0] : U0h.low;
        c6C =
          U0h.displayBorder === null
            ? i0U.panel.yAxis.displayBorder
            : U0h.displayBorder;
        if (this.axisBorders === ![]) {
          c6C = ![];
        }
        if (this.axisBorders === !![]) {
          c6C = !'';
        }
        j9v = i0U.dynamicYAxis;
        h6N = j9v ? U0h.width : NaN;
        m63 = this.getYAxisCurrentPosition(U0h, M91);
        if (m63 == 'left') {
          a7s = U0h.left + U0h.width;
        } else {
          a7s = U0h.left;
        }
        C93 = Math.round(a7s) + 0.5;
        u0N = c6C ? 3 : '0' ^ 0;
        if (m63 == 'left') {
          u0N = c6C ? -3 : 0;
        }
        f1s = this.getCanvasFontSize('stx_yaxis');
        a1g = U0h.increments;
        W8M = a1g.length;
        A4W = 0;
        E4E = 1;
        V2U = 0;
        B12 = 0;
        i$Q = 0;
        m5X = Number.MAX_VALUE;
        for (var Z_e = 0; Z_e < 100; Z_e++) {
          f8j.p1T(92);
          var j$N = f8j.M3e(17, 7, 1);
          V2U = a1g[A4W] * Math.pow(j$N, i$Q);
          f8j.p1T(41);
          E4E = Math.floor(f8j.M3e(V2U, b73));
          f8j.p1T(0);
          K02 = Math.abs(f8j.H9P(W_Z, E4E));
          if (K02 > m5X) {
            break;
          } else {
            m5X = K02;
          }
          if (E4E == W_Z) {
            B12 = V2U;
            break;
          } else if (E4E > W_Z) {
            A4W++;
            if (A4W >= W8M) {
              A4W = 0;
              i$Q++;
            }
          } else {
            A4W--;
            if (A4W < 0) {
              f8j.d$R(149);
              A4W = f8j.M3e(W8M, '1', 64);
              i$Q--;
            }
          }
          B12 = V2U;
        }
        K0G = Math.ceil(W7s / B12) * B12;
        t5Q = U0h.bottom - this.pixelFromTransformedValue(K0G, M91, U0h);
        X71 = 0;
        if (t5Q > U0h.idealTickSizePixels && U0h.semiLog && U0h.prettySemiLog) {
          if (r8p - W7s > 10) {
            A85 = Math.ceil(W7s);
            y9K = 0;
            while (K0G - A85 >= 10000 && y9K <= +'15') {
              K0G /= 10;
              A85 /= 10;
              y9K++;
            }
            K0G = Math.ceil(K0G);
            A85 = Math.ceil(A85);
            for (A85; A85 < K0G && K0G % A85 !== +'0'; ++A85) {}
            K0G *= Math.pow(+'10', y9K);
            A85 *= Math.pow(10, y9K);
            if (A85 < K0G) {
              if (K0G === B12) {
                B12 = A85;
                X71 = A85;
              }
              K0G = A85;
            }
          }
        }
        if (U0h.height > U0h.zoom) {
          S8O = 0;
          l1X = Number.MAX_VALUE;
          i0U.context.save();
          this.canvasFont('stx_yaxis', i0U.context);
          d96 = U0h.shadow / B12 + +'2';
          for (var K8$ = +'0'; K8$ < d96; K8$++) {
            j46 = 'l';
            j46 += 'e';
            j46 += 'f';
            j46 += 't';
            f8j.p1T(128);
            x5e = f8j.M3e(K0G, B12, S8O);
            if (x5e > r8p) break;
            B12 += X71;
            S8O++;
            L73 = this.pixelFromTransformedValue(x5e, M91, U0h);
            if (l1X - L73 < f1s + 1 && X71 > 0) {
              l1X = Number.MAX_VALUE;
              B12 = X71;
              X71 *= 2;
              K8$ = S8O = +'0';
              f8j.d$R(40);
              var m0c = f8j.M3e(7, 5, 34);
              d96 = U0h.shadow / B12 + m0c;
              V_m.reset();
              continue;
            }
            l1X = L73;
            N41 = Math.floor(L73) + 0.5;
            if (N41 + f1s / 2 > U0h.bottom) continue;
            if (N41 - f1s / 2 < U0h.top) continue;
            if (Math.abs(N41 - U0h.bottom) < 1) continue;
            if (U0h.displayGridLines) {
              V_m.moveTo('grid', M91.left + +'1', N41);
              V_m.lineTo('grid', M91.right - 1, N41);
            }
            if (c6C) {
              f8j.d$R(0);
              V_m.moveTo('border', f8j.M3e(C93, 0.5), N41);
              f8j.p1T(18);
              V_m.lineTo('border', f8j.H9P(u0N, C93), N41);
            }
            if (U0h.priceFormatter) {
              x5e = U0h.priceFormatter(this, M91, x5e);
            } else {
              x5e = this.formatYAxisPrice(x5e, M91, null, U0h);
            }
            h2b = U0h.textBackground ? this.containerColor : null;
            M1S = d2T = 3;
            f8j.p1T(28);
            p$$ = f8j.H9P(u0N, a7s, M1S);
            if (m63 == j46) {
              f8j.p1T(0);
              var I59 = f8j.H9P(6, 3);
              p$$ = U0h.left + I59;
              if (U0h.justifyRight !== !{}) {
                p$$ = U0h.left + U0h.width + u0N - M1S;
              }
            } else {
              if (U0h.justifyRight) {
                p$$ = a7s + U0h.width - d2T;
              }
            }
            V_m.addText('text', x5e, p$$, N41, h2b, null, f1s);
            if (j9v) {
              f8j.d$R(18);
              n75 = f8j.H9P(
                899.35 === (4886, 393.38)
                  ? (+'867.08', +'2853') !== 968.11
                    ? ('9162' | 10) < 4960
                      ? 3.69e3
                      : 2.35e3
                    : (745.36, 9.49e3)
                  : '\xA0',
                x5e
              );
              h6N = Math.max(
                h6N,
                i0U.context.measureText(n75).width + Math.abs(u0N) + M1S
              );
            }
          }
          i0U.context.restore();
          if (K8$ >= d96) {
            f8j.d$R(18);
            console.log(
              f8j.M3e(d96, 'drawYAxisPretty: assertion error. zz reached ')
            );
          }
        }
        if (c6C) {
          T5P = 'bo';
          T5P += 'r';
          T5P += 'd';
          T5P += 'er';
          m1R = Math.round(U0h.bottom) + 0.5;
          V_m.moveTo(T5P, C93, U0h.top);
          V_m.lineTo('border', C93, m1R);
          V_m.draw(this.getBackgroundCanvas(i0U).context, 'border');
        }
        if (j9v && h6N > U0h.width) {
          U0h._dynamicWidth = h6N;
          this.calculateYAxisPositions();
          throw new Error('reboot draw');
        } else if (!j9v && U0h._dynamicWidth) {
          this.resetDynamicYAxis({ chartName: i0U.name });
          throw new Error('reboot draw');
        }
      }
      if (U0h == M91.yAxis) {
        this.plotYAxisGrid(M91);
      }
    }
    this.runAppend(U8G, arguments);
  };
};
G = {};
T(G);
H(G);
N(G);
W(G);
U(G);
u(G);
K(G);
L(G);
D(G);
Y(G);
C(G);
R(G);
A(G);
k(G);
B(G);
M(G);
P(G);
Q(G);
F(G);
Z(G);
U0(G);
h$(G);
E0(G);
a_(G);
S3(G);
C_(G);
C$(G);
X4(G);
u1(G);
T7(G);
P$(G);
Q4(G);
z_(G);
B4(G);
e$(G);
c9(G);
J4(G);
var { CIQ: Z5, SplinePlotter: O7, timezoneJS: r7, $$: p6, $$$: k4 } = G;
export {
  Z5 as CIQ,
  O7 as SplinePlotter,
  r7 as timezoneJS,
  p6 as $$,
  k4 as $$$,
}; /* eslint-enable  */ /* jshint ignore:end   */ /* ignore jslint end   */
