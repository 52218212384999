import { differenceInDays, format } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import React, { FC, useState } from 'react';
import { Gap } from '@alfalab/core-components/gap';
import { TagDesktop as Tag } from '@alfalab/core-components/tag/desktop/Component.desktop';
import { ClockMIcon } from '@alfalab/icons-glyph/ClockMIcon';

import { PortfolioBalanceChart } from '@terminal/widgets/features/PortfolioBalanceChart';
import { PortfolioProfitChart } from '@terminal/widgets/features/PortfolioProfitChart';

import {
  PortfolioChart,
  PortfolioChartNameMap,
  usePortfolioReportsMetrics,
} from '../../../../entities/PortfolioReport';
import { PortfolioAnalyticsWarningContainer } from '../../../../shared';
import { thinPortfolioGraph } from '../../lib/thinPortfolioGraph';

import { usePortfolioGraph } from '../../hooks/usePortfolioGraph';

import styles from './PortfolioCharts.module.css';

interface Props {
  accountId: number;
  dateFrom: number;
  dateTo: number;
  isPortfolioSuccess: boolean;
  isEveryThingLoaded: boolean;
}

export const PortfolioCharts: FC<Props> = (props) => {
  const {
    accountId,
    dateFrom,
    dateTo,
    isPortfolioSuccess,
    isEveryThingLoaded,
  } = props;

  const [selected, setSelected] = useState<PortfolioChart>(
    PortfolioChart.BALANCE
  );

  const { trackClickPortfolioReportGraphChip } = usePortfolioReportsMetrics();

  const handleChangeChart = (chartType: PortfolioChart) => {
    setSelected(chartType);
    trackClickPortfolioReportGraphChip(chartType);
  };

  const isMoreThanYear = Math.abs(differenceInDays(dateFrom, dateTo)) > 365;

  const { data: portfolioGraph } = usePortfolioGraph(
    {
      accountId,
      dateFrom: format(dateFrom, 'yyyy-MM-dd'),
      dateTo: format(dateTo, 'yyyy-MM-dd'),
    },
    !isMoreThanYear
  );

  if (!isPortfolioSuccess) {
    return null;
  }

  if (isMoreThanYear) {
    return (
      <>
        <PortfolioAnalyticsWarningContainer
          Icon={ClockMIcon}
          title="Измените даты"
          description={
            <>
              Чтобы увидеть график, выберите
              <br />
              период менее года
            </>
          }
        />
        <Gap size="4xl" />
      </>
    );
  }

  const isGraphEmpty =
    isEmpty(portfolioGraph) ||
    portfolioGraph?.netValueGraph?.every(
      ({ netAssets, returnOnAssets }) =>
        returnOnAssets?.value === 0 && netAssets?.value === 0
    );

  if (!isEveryThingLoaded || isGraphEmpty) {
    return null;
  }

  const graph = thinPortfolioGraph(portfolioGraph);

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        {[PortfolioChart.BALANCE, PortfolioChart.PROFIT].map((chartType) => (
          <Tag
            key={chartType}
            checked={selected === chartType}
            size="xs"
            view="filled"
            onClick={() => {
              handleChangeChart(chartType);
            }}
            className={styles.tag}
          >
            {PortfolioChartNameMap[chartType]}
          </Tag>
        ))}
      </div>

      <Gap size="2xs" />

      {selected === PortfolioChart.BALANCE ? (
        <PortfolioBalanceChart portfolioGraph={graph} />
      ) : (
        <PortfolioProfitChart
          portfolioGraph={graph}
          dateFrom={dateFrom}
          dateTo={dateTo}
        />
      )}

      <Gap size="m" />
    </div>
  );
};
