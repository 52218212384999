import {
  MutableRefObject,
  RefCallback,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useEventListener } from '@terminal/core/hooks';

import { messagesListEventEmitter } from '../lib/messagesListEventEmitter';
import { useDivideMessagesIntoGroups } from './useDivideMessagesIntoGroups';

import { ChatMessage, ChatMessagesGroup } from '../types';

type Options = {
  allLoaded?: boolean;
  loading?: boolean;
  unreadMessages?: ChatMessage[];
  loadMore?: () => Promise<void>;
};

type ReturnType = {
  listRef: RefObject<HTMLDivElement>;
  pageTopRef: RefCallback<HTMLDivElement>;
  listBottomRef: RefObject<HTMLDivElement>;
  groupedReadMessages: Record<string, ChatMessagesGroup[]>;
  groupedUnreadMessages: Record<string, ChatMessagesGroup[]>;
  showDates: boolean;
  scrollButtonVisible: boolean;
};

export function useMessagesList(
  messages: ChatMessage[],
  options?: Options
): ReturnType {
  const {
    unreadMessages = [],
    loading = false,
    allLoaded = true,
    loadMore,
  } = options || {};

  const [showDates, setShowDates] = useState<boolean>(false);

  const [scrollButtonVisible, setScrollButtonVisible] =
    useState<boolean>(false);

  const groupedReadMessages = useDivideMessagesIntoGroups(messages);
  const groupedUnreadMessages = useDivideMessagesIntoGroups(unreadMessages);

  // Элемент для отображения дат
  const listRef = useRef<HTMLDivElement>(null);

  const handleHideDates = useDebouncedCallback(() => setShowDates(false), 1000);

  useEventListener(
    'scroll',
    () => {
      if (!showDates) {
        setShowDates(true);
      }

      handleHideDates();
    },
    { element: listRef }
  );

  // Элемент для дозагрузки истории
  const topObserver: MutableRefObject<IntersectionObserver | undefined> =
    useRef();

  const pageTopRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (topObserver.current) {
        topObserver.current.disconnect();
      }

      if (loadMore) {
        topObserver.current = new IntersectionObserver((entries) => {
          if (
            entries[0].isIntersecting &&
            !loading &&
            !allLoaded &&
            messages.length + unreadMessages?.length > 0
          ) {
            loadMore(); // TODO .then(подскролл обратно к бывшему первому из сообщений)
          }
        });

        if (node) {
          topObserver.current.observe(node);
        }
      }
    },
    [loadMore, loading, allLoaded, messages.length, unreadMessages?.length]
  );

  // Элемент для перемотки вниз
  const bottomObserver: MutableRefObject<IntersectionObserver | undefined> =
    useRef();

  const listBottomRef: RefObject<HTMLDivElement> = useRef(null);

  useEffect(() => {
    bottomObserver.current = new IntersectionObserver(
      ([e]) => {
        setScrollButtonVisible(e.intersectionRatio < 1);
      },
      { threshold: [1] }
    );

    if (listBottomRef.current) {
      bottomObserver.current.observe(listBottomRef.current);
    }

    return () => {
      if (bottomObserver.current) {
        bottomObserver.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    (loadMore ? loadMore() : Promise.resolve()).then(() =>
      messagesListEventEmitter.emit('scroll-bottom')
    );
  }, [loadMore]);

  const handleScrollBottom = useCallback(() => {
    listBottomRef?.current?.scrollIntoView();
  }, []);

  useEffect(() => {
    messagesListEventEmitter.on('scroll-bottom', handleScrollBottom);

    return () => {
      messagesListEventEmitter.off('scroll-bottom', handleScrollBottom);
    };
  }, [handleScrollBottom]);

  return {
    listRef,
    pageTopRef,
    listBottomRef,
    groupedReadMessages,
    groupedUnreadMessages,
    showDates,
    scrollButtonVisible,
  };
}
