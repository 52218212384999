import { useCallback, useEffect, useRef, useState } from 'react';
import { ButtonDesktop as Button } from '@alfalab/core-components/button/desktop';
import { ModalDesktop } from '@alfalab/core-components/modal/desktop';

import {
  Confirmation,
  ConfirmationScreen,
  ConfirmationState,
  useConfirmation,
} from '@terminal/common/components/Confirmation';
import { CertificateActionType } from '@terminal/core/lib/client/entities';
import { SmsActionsService } from '@terminal/core/lib/services/smsActions';
import { useStore } from '@terminal/core/store';

import styles from './CodeConfirmationModalOld.module.css';

export interface Props {
  isOpened: boolean;
  onCloseRequest: () => void;
  certificateId?: number;
}

export const CodeConfirmationModalOld = ({
  onCloseRequest,
  isOpened,
  certificateId,
}: Props) => {
  const serviceRef = useRef<SmsActionsService>();

  const {
    confirmationState,
    confirmationScreen,
    setConfirmationState,
    setConfirmationScreen,
  } = useConfirmation();

  const [error, setError] = useState<string | undefined>('');
  const completeCertificateFlow = useStore(
    (store) => store.completeCertificateFlow
  );

  const onSuccesCb = useCallback(() => {
    setConfirmationState('INITIAL' as ConfirmationState);
    setConfirmationScreen('INITIAL' as ConfirmationScreen);

    completeCertificateFlow();
    onCloseRequest();
  }, [
    completeCertificateFlow,
    onCloseRequest,
    setConfirmationScreen,
    setConfirmationState,
  ]);

  const onErrorCb = useCallback(
    (errorMsg: string) => {
      setError(errorMsg);
      setConfirmationState('CODE_ERROR' as ConfirmationState);
    },
    [setConfirmationState]
  );

  useEffect(() => {
    setConfirmationState('INITIAL' as ConfirmationState);
    setConfirmationScreen('INITIAL' as ConfirmationScreen);

    if (certificateId && isOpened) {
      setError(undefined);
      serviceRef.current = new SmsActionsService(certificateId);
      serviceRef.current.on('certificate-code-success', onSuccesCb);
      serviceRef.current.on('certificate-code-error', onErrorCb);
      serviceRef.current.askCode(CertificateActionType.Certify);
    }

    return () => {
      serviceRef.current?.clear();
    };
  }, [
    onSuccesCb,
    onErrorCb,
    setConfirmationState,
    setConfirmationScreen,
    certificateId,
    isOpened,
  ]);

  const handleInputFinished = (code: string) => {
    serviceRef.current?.sendCode(code);
    setConfirmationState('CODE_CHECKING' as ConfirmationState);
  };

  const handleSmsRetryClick = () => {
    serviceRef.current?.askCode(CertificateActionType.Certify);
    setConfirmationState('CODE_SENDING' as ConfirmationState);
    setTimeout(
      () => setConfirmationState('INITIAL' as ConfirmationState),
      2000
    );
  };

  return (
    <>
      <ModalDesktop
        open={isOpened}
        onClose={onCloseRequest}
        size="m"
        hasCloser
        className={styles.confirmModal}
      >
        <ModalDesktop.Content>
          <Confirmation
            screen={confirmationScreen}
            state={confirmationState}
            onChangeState={setConfirmationState}
            onChangeScreen={setConfirmationScreen}
            onInputFinished={handleInputFinished}
            onSmsRetryClick={handleSmsRetryClick}
            countdownDuration={10000}
            alignContent="center"
            requiredCharAmount={5}
            texts={{ codeError: error }}
          />
        </ModalDesktop.Content>
        <ModalDesktop.Footer>
          <Button view="primary" size="s" onClick={onCloseRequest}>
            Отмена
          </Button>
        </ModalDesktop.Footer>
      </ModalDesktop>
    </>
  );
};
