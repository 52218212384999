import { useCallback, useEffect } from 'react';
import { BaseModal } from '@alfalab/core-components/base-modal';
import { ButtonDesktop as Button } from '@alfalab/core-components/button/desktop';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Typography } from '@alfalab/core-components/typography';
import { ShieldMIcon } from '@alfalab/icons-glyph/ShieldMIcon';

import {
  IS_NEED_CERTIFICATE_MODAL_WAS_SHOWN_STORAGE_KEY,
  MAX_CERTIFICATES_COUNT,
} from '@terminal/core/constants/certificates';
import { shallow, useStore } from '@terminal/core/store';
import { SingletonModalType } from '@terminal/core/store/slices/certificatesSlice';
import { CertificateEnrollStep } from '@terminal/core/types/certificate';

import styles from './NeedCertificateModal.module.css';

export const NeedCertificateModal = () => {
  const {
    enrollCertificate,
    step,
    certificates,
    certificateEnrollId,
    setSingletonModalType,
  } = useStore(
    (state) => ({
      step: state.certificateEnrollStep,
      enrollCertificate: state.enrollCertificate,
      certificates: state.certificates,
      certificateEnrollId: state.certificateEnrollId,
      setSingletonModalType: state.setSingletonModalType,
    }),
    shallow
  );

  const isAllowIssueCertificate =
    Boolean(certificateEnrollId) ||
    certificates.length < MAX_CERTIFICATES_COUNT;

  const onClose = useCallback(() => {
    setSingletonModalType(SingletonModalType.NONE);
  }, [setSingletonModalType]);

  const onClickIssue = useCallback(() => {
    enrollCertificate(onClose);
  }, [enrollCertificate, onClose]);

  useEffect(() => {
    // Сохраняем в session storage чтобы не раздражать пользователя
    // когда он телефон после не активности включает
    const isWasShown =
      sessionStorage.getItem(
        IS_NEED_CERTIFICATE_MODAL_WAS_SHOWN_STORAGE_KEY
      ) === 'true';

    if (!isWasShown) {
      sessionStorage.setItem(
        IS_NEED_CERTIFICATE_MODAL_WAS_SHOWN_STORAGE_KEY,
        'true'
      );
    }
  }, []);

  const isDisabled =
    step === CertificateEnrollStep.InProgress ||
    step === CertificateEnrollStep.Success;

  return (
    <BaseModal
      open={true}
      onClose={onClose}
      wrapperClassName={styles.modalWrapperClassName}
      contentClassName={styles.contentClassName}
      className={styles.baseModalClassName}
    >
      <div className={styles.iconWrapper}>
        <SuperEllipse size={80}>
          <ShieldMIcon />
        </SuperEllipse>
      </div>

      <Typography.Text
        view="primary-large"
        tag="div"
        className={styles.title}
        weight="bold"
      >
        У вас нет действующего сертификата для данного браузера
      </Typography.Text>

      <Typography.Text
        view="secondary-large"
        tag="div"
        className={styles.subTitle}
      >
        Сертификат электронной подписи необходим для торговли на бирже, открытия
        счетов и других неторговых функций
      </Typography.Text>

      <Typography.Text
        tag="div"
        view="secondary-large"
        weight="medium"
        className={styles.buttons}
      >
        <Button
          loading={isDisabled}
          disabled={!isAllowIssueCertificate || isDisabled}
          view="secondary"
          size="xs"
          onClick={onClickIssue}
          block
        >
          Создать новый
        </Button>

        {!isAllowIssueCertificate && (
          <Typography.Text
            view="secondary-medium"
            tag="div"
            className={styles.notIsAllowIssueCertificate}
          >
            Чтобы выпустить новый сертификат, удалите ненужный, выпущенный для
            другого браузера или устройства
          </Typography.Text>
        )}

        <Button view="ghost" size="xs" onClick={onClose} block>
          Продолжить в режиме просмотра
        </Button>
      </Typography.Text>
    </BaseModal>
  );
};
