import { MarginParamsDTO } from './MarginParamsDTO';

import { MarginParams } from '../../../types/MarginParams';

type MarginParamsDTOItem = MarginParamsDTO[number];

/**
 * Мапит MarginParamsDTOItem в MarginParams.
 * @param item элемент DTO
 * @returns MarginParams
 * */
export function mapMarginParams(item: MarginParamsDTOItem): MarginParams {
  return {
    short: {
      margin: item.marginShort,
      mkStart: item.mkShortStart ?? 0,
      mkMin: item.mkShortMin ?? 0,
      mkCurrent: item.mkShortCurrent,
    },
    long: {
      margin: item.marginLong,
      mkStart: item.mkLongStart ?? 0,
      mkMin: item.mkLongMin ?? 0,
      mkCurrent: item.mkLongCurrent,
    },
    lot: item.lot,
    priceStep: item.priceStep,
    priceStepCost: item.priceStepCost,
  };
}
