import uniqWith from 'lodash/uniqWith';

import { sortObjKeys } from '@terminal/core/lib/sortObjKeys';
import { SubAccountRazdelItem } from '@terminal/core/types/subAccountRazdel';

import { AccountsTree } from '../model/types';

export function createAccountsTree(accountsMap: AccountsTree) {
  let accountsTree: AccountsTree = {};

  // У каждого счета оставляем только уникальные субсчета и сортируем их
  Object.keys(accountsMap).forEach((accountKey: string) => {
    const currentSubAccounts: SubAccountRazdelItem[] = accountsMap[accountKey];

    accountsTree[accountKey] = uniqWith(
      currentSubAccounts,
      (a: SubAccountRazdelItem, b: SubAccountRazdelItem) =>
        a.idSubAccount === b.idSubAccount
    ).sort((a: SubAccountRazdelItem, b: SubAccountRazdelItem) =>
      a.codeSubAccount.localeCompare(b.codeSubAccount)
    );
  });

  // Сортируем счета в дереве
  accountsTree = sortObjKeys(accountsTree);

  return accountsTree;
}
