import { ClusterEndpoint, Handler } from './types';

// @deprected Не использовать
export let _handler: Handler<any, any, any, any> = function (
  params: ClusterEndpoint
) {
  throw Error('Please set handler by call registerHandler(<fn>)');
};

function registerHandler(handler: Handler<unknown, unknown, unknown, string>) {
  _handler = handler;
}

export let _errorMapHandler: (e: Error) => void = (e: Error) => {
  throw Error('Please set handler for data mapping');
};

function registerErrorMapHandler(handler: (e: Error) => void) {
  _errorMapHandler = handler;
}

export const handlers = {
  endpoint: registerHandler,
  mapErrorHandler: registerErrorMapHandler,
};
