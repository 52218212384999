import cn from 'classnames';
import { MouseEventHandler, useCallback, useState, VFC } from 'react';
import { IconButton } from '@alfalab/core-components/icon-button';
import { Typography } from '@alfalab/core-components/typography';
import { CopyMIcon } from '@alfalab/icons-glyph/CopyMIcon';
import { MailMIcon } from '@alfalab/icons-glyph/MailMIcon';
import { NavigationChatMIcon } from '@alfalab/icons-glyph/NavigationChatMIcon';
import { PhoneMIcon } from '@alfalab/icons-glyph/PhoneMIcon';

import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';
import { useSupportMessage } from '@terminal/chat/hooks/useSupportMessage';
import { useChatContext } from '@terminal/chat/shared/lib/ChatContext';
import { useAppContext } from '@terminal/core/hooks/useAppContext';
import useNotification from '@terminal/core/hooks/useNotification';
import { trackClickOnManuelLK } from '@terminal/core/lib/analytics';
import { copyToClipboard } from '@terminal/core/lib/copyToClipboard';
import { cascanaChatService } from '@terminal/core/lib/services/cascanaChat';
import { useStore } from '@terminal/core/store';
import { HeaderMenuItem, NotificationType } from '@terminal/core/types/ui';

import { ListItem } from '../../../../entities/Chat';

import styles from './SupportPanel.module.css';

enum TooltipType {
  EMAIL,
  TG,
  PHONE,
}

export type SupportPanelProps = {
  onClose?: () => void;
};

const TOOLTIP_TEXT_BY_TYPE: Record<TooltipType, string> = {
  [TooltipType.EMAIL]: 'Адрес скопирован',
  [TooltipType.TG]: 'Ссылка на бота скопирована',
  [TooltipType.PHONE]: 'Номер телефона скопирован',
};

export const SupportPanel: VFC<SupportPanelProps> = (props) => {
  const { onClose = () => {} } = props;

  const { user } = useAlfaDirectContext();
  const setHeaderMenuOpen = useStore((state) => state.setHeaderMenuOpen);
  const addNotification = useNotification();
  const getSupportMessage = useSupportMessage();
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const [tooltipText, setTooltipText] = useState<string>('');

  const { supportPhone, supportEmail, supportChat } = useChatContext();

  const { manuals } = useAppContext();

  const showTooltip = (type: TooltipType) => {
    setTooltipText(TOOLTIP_TEXT_BY_TYPE[type]);
    setTooltipVisible(true);
    setTimeout(() => setTooltipVisible(false), 2000);
  };

  const handleCopyEmail: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    copyToClipboard(supportEmail);
    showTooltip(TooltipType.EMAIL);
  };

  const handleCopyPhone: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    copyToClipboard(supportPhone || '');
    showTooltip(TooltipType.PHONE);
  };

  // Токены для чата и скрипт чата загружаются только если пользователю нужна техподдержка
  const openChat = useCallback(async () => {
    onClose();

    try {
      cascanaChatService.initialize();
      const res = await cascanaChatService.loadExternalToken();

      await cascanaChatService.loadChatTokens(res.accessToken);
      await cascanaChatService.loadClient(user?.fullName || '');
      cascanaChatService.showChatWindow();
    } catch (error) {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка загрузки чата поддержки',
      });
    }
  }, [user, addNotification, onClose]);

  return (
    <div className={styles.supportPanel}>
      {manuals && manuals.SupportBanner && (
        <manuals.SupportBanner
          onClick={() => {
            trackClickOnManuelLK();
            onClose();
            setHeaderMenuOpen(HeaderMenuItem.MANUAL);
          }}
        />
      )}

      <ListItem
        Icon={MailMIcon}
        label="Написать на почту"
        description={supportEmail}
        onClick={() => {
          window.open(
            `mailto:${supportEmail}?subject=${encodeURIComponent(
              'Отзыв Альфа-Инвестиции'
            )}&body=${encodeURIComponent(getSupportMessage())}`
          );
        }}
      >
        <IconButton
          view="secondary"
          size="s"
          icon={CopyMIcon}
          onClick={handleCopyEmail}
        />
      </ListItem>

      {/* Cascana CHAT */}
      {supportChat && (
        <ListItem
          Icon={NavigationChatMIcon}
          label="Чат поддержки Go Invest"
          description="Открыть окно чата"
          onClick={openChat}
        />
      )}

      {supportPhone && (
        <ListItem
          Icon={PhoneMIcon}
          label="Позвонить"
          description={
            <>
              {supportPhone}
              <br />
              Бесплатно по России
            </>
          }
          onClick={() => window.open(`tel:${supportPhone}`)}
        >
          <IconButton
            view="secondary"
            size="s"
            icon={CopyMIcon}
            onClick={handleCopyPhone}
          />
        </ListItem>
      )}

      <Typography.Text
        view="secondary-small"
        className={cn(styles.copyTooltip, {
          [styles.copyTooltipVisible]: tooltipVisible,
        })}
      >
        {tooltipText}
      </Typography.Text>
    </div>
  );
};
