import {
  Filter,
  FilterSettings,
  FilterType,
} from '@terminal/core/types/layout';

import { ColumnFilter } from './ColumnFilter';

export const defaultColumnFilterRender = (
  columnKey: string,
  filterType: FilterType,
  filter?: Filter,
  filterSettings?: FilterSettings,
  onFilter?: ((filter?: Filter) => void) | undefined
) => (
  <ColumnFilter
    columnKey={columnKey}
    filterType={filterType}
    filter={filter}
    filterSettings={filterSettings}
    onFilter={onFilter}
  />
);
