import debounce from 'lodash/debounce';
import log from 'loglevel';
import { z } from 'zod';

import { IS_AI, IS_GI } from '../../../env';
import { getWorkspace, saveWorkspace } from '../../rest/lkSettings';
import { storage } from '../../services/storage';
import { IDB } from '../';
import { filterFlexLayouts } from './utils';
import { WorkspaceConfigurationsZod_V2 } from './versions/version2';

import {
  getWorkspacesFromLS,
  saveWorkspacesToLS,
} from '../../../store/slices/helpers/workspaceConfigurationsHelpers';

import { AppState } from '../../../types/app';

export type WorkspaceConfigurationsTypeLast = z.infer<
  typeof WorkspaceConfigurationsZod_V2.configurations
>;

export class WorkspaceConfigurations {
  static ACTUAL_VERSION = 2;
  static isOnboarded = false;

  /**
   * Определяем какой конфиг применить с сервера или из БД
   * */
  static async init(flexLayoutEnabled: boolean) {
    let actualConfig = {
      ...getWorkspacesFromLS(),
      saveTimestamp: Date.now(),
    };

    try {
      if (IS_GI) {
        const [localResult, serverResult] = await Promise.allSettled([
          this.get(),
          this.getFromServer(),
        ]);

        const localData =
          localResult.status === 'fulfilled' && localResult.value;
        const serverData =
          serverResult.status === 'fulfilled' && serverResult.value;

        const localTimestamp = localData ? localData.saveTimestamp : 0;
        const serverTimestamp = serverData ? serverData.saveTimestamp : 0;

        if (
          serverTimestamp ||
          storage.modal.onboardingDone ||
          localStorage.getItem('onboarding')
        ) {
          this.isOnboarded = true;
        }

        if (serverTimestamp > localTimestamp) {
          if (serverData) {
            actualConfig = serverData;
          }
        } else {
          if (localData) {
            actualConfig = localData;
          }
        }
      }
    } catch (e) {
      log.error('WorkspaceConfigurations init error: ', e);
    }

    return WorkspaceConfigurations.migration(actualConfig, flexLayoutEnabled);
  }

  static migration(
    prevWorkspaceConfigurations: WorkspaceConfigurationsTypeLast,
    flexLayoutEnabled: boolean
  ): WorkspaceConfigurationsTypeLast {
    const { workspaceConfigurations } = prevWorkspaceConfigurations;

    if (flexLayoutEnabled) {
      return prevWorkspaceConfigurations;
    } else {
      const filteredWorkspace = {
        ...prevWorkspaceConfigurations,
        workspaceConfigurations: workspaceConfigurations.map(
          (configuration) => {
            const { lastManualSavedConfig, lastAutoSavedConfig } =
              configuration;

            return {
              ...configuration,
              lastManualSavedConfig: filterFlexLayouts(lastManualSavedConfig),
              lastAutoSavedConfig: filterFlexLayouts(lastAutoSavedConfig),
            };
          }
        ),
      };

      return filteredWorkspace;
    }
  }

  static save = debounce(
    (
      workspaceConfigurations: WorkspaceConfigurationsTypeLast['workspaceConfigurations'],
      workspaceConfigurationActiveId: WorkspaceConfigurationsTypeLast['workspaceConfigurationActiveId']
    ) => {
      // @ts-ignore
      // TODO вынести логику про сохранение из useStore
      const { appState, credentials } = window.__zsstore.getState();

      if (
        appState !== AppState.READY ||
        !credentials ||
        !workspaceConfigurations[workspaceConfigurationActiveId]
      ) {
        return;
      }

      workspaceConfigurations[
        workspaceConfigurationActiveId
      ].lastAutoSavedConfig.timeStamp = Date.now();

      const config = {
        workspaceConfigurations,
        workspaceConfigurationActiveId,
        saveTimestamp: Date.now(),
      };

      if (
        IS_AI ||
        WorkspaceConfigurations.ACTUAL_VERSION === 1 ||
        WorkspaceConfigurations.ACTUAL_VERSION === 2
      ) {
        saveWorkspacesToLS(config);
      }

      if (IS_AI) {
        return;
      }

      saveWorkspace(config, WorkspaceConfigurations.ACTUAL_VERSION);

      return IDB.save<WorkspaceConfigurationsTypeLast>(
        'workspaceConfigurations',
        WorkspaceConfigurationsZod_V2.configurations,
        config
      );
    },
    100
  );

  static async get() {
    return IDB.get<WorkspaceConfigurationsTypeLast>('workspaceConfigurations');
  }

  static async getFromServer() {
    const data = await getWorkspace();

    if (data && data.hasOwnProperty('version')) {
      delete data.version;
    }

    return data;
  }
}
