import {
  Colision,
  Direction,
  Position,
  Size,
} from '@terminal/core/types/layout';

export const adjustResize = (
  position: Position,
  size: Size,
  dir: Direction,
  colisions?: Colision
): {
  position: Position;
  size: Size;
} => {
  if (colisions) {
    let modifiedPosition = position;
    let modifiedSize = size;

    const { top, right, bottom, left } = colisions;

    // Корнер кейс когда нужно пересчитать всё
    if (
      left !== undefined &&
      right !== undefined &&
      top !== undefined &&
      bottom !== undefined
    ) {
      modifiedPosition.x = left;
      modifiedPosition.y = top;
      modifiedSize.width = right - left;
      modifiedSize.height = bottom - top;

      return { size: modifiedSize, position: modifiedPosition };
    }

    switch (dir) {
      case 'top':
        if (top !== undefined) {
          modifiedSize.height += modifiedPosition.y - top;
          modifiedPosition.y = top;
        }

        break;

      case 'bottom':
        if (bottom !== undefined) {
          modifiedSize.height = bottom - modifiedPosition.y;
        }

        break;

      case 'left':
        if (left !== undefined) {
          modifiedSize.width += modifiedPosition.x - left;
          modifiedPosition.x = left;
        }

        break;

      case 'right':
        if (right !== undefined) {
          modifiedSize.width = right - modifiedPosition.x;
        }

        break;

      case 'bottomRight':
        if (bottom !== undefined) {
          modifiedSize.height = bottom - modifiedPosition.y;
        }

        if (right !== undefined) {
          modifiedSize.width = right - modifiedPosition.x;
        }

        break;

      case 'bottomLeft':
        if (bottom !== undefined) {
          modifiedSize.height = bottom - modifiedPosition.y;
        }

        if (left !== undefined) {
          modifiedSize.width += modifiedPosition.x - left;
          modifiedPosition.x = left;
        }

        break;

      case 'topRight':
        if (top !== undefined) {
          modifiedSize.height += modifiedPosition.y - top;
          modifiedPosition.y = top;
        }

        if (right !== undefined) {
          modifiedSize.width = right - modifiedPosition.x;
        }

        break;

      case 'topLeft':
        if (top !== undefined) {
          modifiedSize.height += modifiedPosition.y - top;
          modifiedPosition.y = top;
        }

        if (left !== undefined) {
          modifiedSize.width += modifiedPosition.x - left;
          modifiedPosition.x = left;
        }

        break;

      default:
        break;
    }

    return { size: modifiedSize, position: modifiedPosition };
  } else {
    return { size, position };
  }
};
