import { _handler } from './public';

import { ClusterEndpoint, EndpointParams } from './types';

export function replaceUrlParams(
  path: string,
  params: Record<string, string> = {}
) {
  let res = path;

  for (let key in params) {
    const type = typeof params[key];

    if (type === 'string' || type === 'number') {
      res = res.replace(`{${key}}`, params[key]);
    }
  }

  return res;
}

export function createClusterEndpoint<P extends string>(
  endpoint: ClusterEndpoint & { path: P }
) {
  const fetch = <
    Result = [
      Error,
      'Please specify the Result type parameter for createClusterEndpoint()<ResultType>'
    ],
    QueryParams = never,
    Payload = never
  >(
    params?: EndpointParams<Result, QueryParams, Payload, P>
  ): Promise<Result> => {
    const preparedEndpoint: ClusterEndpoint = {
      ...endpoint,
      // @ts-expect-error TODO если знаете как починить, то welcome
      path: replaceUrlParams(endpoint.path, params.params),
    };

    const result = params?.customHandler
      ? params.customHandler(preparedEndpoint, params)
      : // @ts-expect-error
        _handler(preparedEndpoint, params);

    return result;
  };

  fetch._endpoint = endpoint;

  return fetch;
}
