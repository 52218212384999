import EventEmitter from 'eventemitter3';
import log from 'loglevel';

import { clientMessageSuccess } from '../../constants/auth';
import { logWebsoketReconnect } from '../../lib/analytics/performance';
import { checkMessageId, isConnectionError, makeAuthMessage } from './auth';
import { decode } from './decode';
import { encode } from './encode';
import {
  ClientMessageEntity,
  DataFlowSubscribeEntity,
  Entity,
  FrontEndType,
  Message,
  SubscribeType,
} from './entities';
import { EntityType } from './entityTypes';
import { Heartbeat } from './Heartbeat';
import { isObjectMessage, makeDataFlowEntity } from './helpers';
import { getEntityNameById } from './serialization';

import { UserCredentials } from '../../types/user';

// автоинкрементный id для отладки реконектов
let id = 0;

/**
 * Справочники, которые нужно загрузить один раз
 * @see initCore()
 * */
const notResubscribeDataFlowTypes = [
  EntityType.MarketBoardEntity,
  EntityType.FinInstrumentEntity,
  EntityType.ObjectEntity,
  EntityType.ObjectTypeEntity,
  EntityType.AllowedOrderParamEntity,
];

export enum SocketEvent {
  Open,
  Close,
  Error,
  Message,
  Ready,
}

export enum SocketStatus {
  Closed,
  Ready,
  Connecting,
  Auth,
  Closing,
}

type EmitterTypes = {
  [SocketEvent.Message]: Message;
  [SocketEvent.Error]: Event;
  [SocketEvent.Open]: Event;
  [SocketEvent.Close]: CloseEvent;
  [SocketEvent.Ready]: { frontEndType: FrontEndType };
};

const WEBSOCKET_NORMAL_CLOSE_CODE = 1000;

class ConnectionError extends Error {
  override message: string = 'Ошибка соединения';
}

class SocketClosedError extends Error {
  override message: string = 'Сокет закрыт';
}

export class AuthTimeoutError extends Error {
  override message: string = 'Превышено время ожидания авторизации';
}

/**
 * Класс инкапсулирующий общую логику работы с веб-сокетом:
 * инициализация, heartbeat, авторизация, обработка, буферизация данных и передача их энкодером
 * событие offline работает не навсех ОС и не во всех браузерах, поэтому не стоит на это слишком полагаться
 * */
export class ADSocket {
  /**
   * uri и объект сокета отдельно хранятся
   * потому что сокет fe1 открывается сразу,
   * а остальные только после успешной авторизации fe1
   * */
  private uri: string;

  /**
   * Этот флаг означает, что мы знаем длину текущего сообщения
   * и ждем пока кол-во байт в очереди будет равно или превысит
   * */
  private messagePending: boolean;

  /**
   * кол-во байт, которое у нас есть локально в очереди для парсинга
   * */
  private bytesRead: number;
  /**
   * список из буферов
   * сюда копятся байты пока кол-во прочитаных байт не превысит или сравняется с размером текущего сообщения
   * */
  private packetBuffer: ArrayBuffer[];
  /**
   * пока количество прочитаных байт не сравняется или превысит это число копим байты
   * */
  private currentMessageLength: number;
  /**
   * может произойти что 1ый байт размера был в одном пакете, а еще 2 в следующем
   * Этот флаг нужен в ситуации когда байты есть, а размера текущего сообщения нет.
   * это может произойти если сервер прислал байты с размером в разных пакетах, тк размер состоит из 3 байт
   * */
  private panicMode: boolean;

  /**
   * по какой-то причине websocket.close(code) - не сохраняет код
   * */
  closeEventCode: number;

  status: SocketStatus = SocketStatus.Closed;

  private isOffline = false;

  fe: FrontEndType;
  id = id;
  private emitter: EventEmitter<EmitterTypes> = new EventEmitter();

  addListener = this.emitter.addListener.bind(this.emitter);
  removeListener = this.emitter.removeListener.bind(this.emitter);

  private heartbeat?: Heartbeat;
  private websocket: WebSocket | null = null;

  private entitiesEmitter: EventEmitter<{
    [key in EntityType]: Entity;
  }> = new EventEmitter();

  private reconnectAttempt: number = 0;
  private maxReconnects: number = Infinity;
  private userCredentials: UserCredentials | null = null;
  private idDevice?: string;
  private subscriptions: Map<
    EntityType,
    {
      flowKeys: Set<number>;
      version: BigInt;
    }
  > = new Map();

  private lostConnectionTimeout: number;

  constructor(
    uri: string,
    fe: FrontEndType,
    lostConnectionTimeout: number,
    heartBeatInterval: number,
    idDevice: string
  ) {
    this.uri = uri;
    this.fe = fe;

    this.bytesRead = 0;
    this.messagePending = false;
    this.packetBuffer = [];
    this.currentMessageLength = 0;
    this.panicMode = false;
    this.closeEventCode = 0;
    this.idDevice = idDevice;
    this.lostConnectionTimeout = lostConnectionTimeout;

    this.heartbeat = new Heartbeat(
      lostConnectionTimeout,
      heartBeatInterval,
      fe,
      this.reopen
    );
    window.addEventListener('online', () => {
      this.isOffline = false;
      this.reopen();
    });
    window.addEventListener('offline', () => {
      this.isOffline = true;
      this.heartbeat?.cancel();
    });

    if (fe === FrontEndType.AuthAndOperInitServer) {
      this.emitter.addListener(
        SocketEvent.Message,
        this.heartbeat.handleHeartbeat
      );
    } else {
      this.entitiesEmitter.addListener(
        EntityType.HeartbeatEntity,
        this.heartbeat.handleHeartbeat
      );
    }

    id++;
  }

  setMaxReconnects(n: number) {
    this.maxReconnects = n;
  }

  isWebSocketReady() {
    return (
      this.websocket instanceof WebSocket &&
      this.websocket.readyState === WebSocket.OPEN
    );
  }

  subscribe(entity: EntityType, flowKeys: number[] = [], version: BigInt = 0n) {
    const message = makeDataFlowEntity(
      SubscribeType.Subscribe,
      this.fe,
      entity,
      flowKeys,
      version
    );

    if (!notResubscribeDataFlowTypes.includes(entity)) {
      const subscribtion = this.subscriptions.get(entity);

      if (!subscribtion) {
        this.subscriptions.set(entity, {
          flowKeys: new Set(flowKeys),
          version,
        });
      } else {
        flowKeys.forEach((key) => {
          subscribtion.flowKeys.add(key);
        });
      }
    }

    if (this.status === SocketStatus.Ready) {
      this.send(message);
    }
  }

  resubscribe() {
    this.subscriptions.forEach(({ flowKeys, version }, entity) => {
      const message = makeDataFlowEntity(
        SubscribeType.Subscribe,
        this.fe,
        entity,
        [...flowKeys],
        version
      );

      this.send(message);
    });
  }

  unsubscribe(entity: EntityType, flowKeys: number[] = []) {
    const subscribtion = this.subscriptions.get(entity);

    if (!subscribtion) {
      log.debug(`Подписка отсутсвует, FE=${this.fe}, entity: ${entity}`);

      return;
    }

    for (const key of flowKeys) {
      subscribtion.flowKeys.delete(key);
    }

    // Если подписки кончились - очищаем, чтобы избежать повторных подписок
    if (subscribtion.flowKeys.size === 0) {
      this.subscriptions.delete(entity);
    }
  }

  resetCredentials() {
    this.userCredentials = null;
  }

  async auth(credentials?: UserCredentials) {
    this.status = SocketStatus.Auth;

    if (credentials) {
      this.userCredentials = credentials;
    }

    if (!this.userCredentials) {
      throw new Error('No userCredentials');
    }

    if (!this.idDevice) {
      throw new Error('No idDevice');
    }

    try {
      this.send(makeAuthMessage(this.userCredentials, this.fe, this.idDevice));
      await this.getAuthResponse();
    } catch (e) {
      if (e instanceof SocketClosedError) {
        log.error(e);

        return false;
      }

      if (e instanceof ConnectionError) {
        this.reopen();
        log.error(e);

        return false;
      }

      throw e;
    }

    this.status = SocketStatus.Ready;

    this.heartbeat?.run();

    // Этот trycatch нужен, чтобы игнорировать ошибки в колбеках эмиттера
    try {
      if (!this.isOffline) {
        this.emitter.emit(SocketEvent.Ready, {
          frontEndType: this.fe,
        });
      }
    } catch (err) {
      log.debug(err);
    }

    return true;
  }

  private getAuthResponse() {
    return new Promise<void>((resolve, reject) => {
      let removeListeners: () => void;

      const authTimeout = setTimeout(() => {
        reject(new AuthTimeoutError());
      }, this.lostConnectionTimeout);

      const closeListener = () => {
        removeListeners();
        reject(new SocketClosedError());
      };

      const listener = (entity: ClientMessageEntity) => {
        try {
          checkMessageId(entity.MessageId);

          if (isConnectionError(entity)) {
            log.debug('Ошибка соединения', entity);

            throw new ConnectionError();
          }

          if (clientMessageSuccess === entity.MessageId) {
            log.debug(`FE ${this.fe} number ${entity.Objects[0]}`);
            removeListeners();
            resolve();
          }
        } catch (err) {
          removeListeners();
          reject(err);
        }
      };

      removeListeners = () => {
        clearTimeout(authTimeout);
        this.removeListener(SocketEvent.Close, closeListener);

        this.entitiesEmitter.removeListener(
          EntityType.ClientMessageEntity,
          listener
        );
      };

      this.addListener(SocketEvent.Close, closeListener);

      this.entitiesEmitter.addListener(
        EntityType.ClientMessageEntity,
        listener
      );
    });
  }

  reopen = async () => {
    this.reconnectAttempt++;

    if ([SocketStatus.Auth, SocketStatus.Closing].includes(this.status)) {
      return;
    }

    const metrics = logWebsoketReconnect(this.fe);

    await this.close();

    if (this.reconnectAttempt > this.maxReconnects) {
      return;
    }

    // не можем реконнектится если нет userCredentials
    if (!this.userCredentials) {
      return;
    }

    await this.open();

    try {
      if (await this.auth()) {
        this.resubscribe();
      }
    } catch (e) {
      log.error('Ошибка авторизации, socket - ', this.fe, e);

      if (
        !(e instanceof ConnectionError) &&
        !(e instanceof SocketClosedError)
      ) {
        this.emitter.emit(SocketEvent.Error, e, this);
      }
    }

    metrics.ready();
  };

  open() {
    if (this.status !== SocketStatus.Closed) {
      log.debug('Сокет не закрыт fe', this.fe, 'status', this.status);

      return Promise.resolve();
    }

    this.status = SocketStatus.Connecting;

    this.closeEventCode = 0;

    this.websocket = new WebSocket(this.uri, 'adirsvc.native');
    this.websocket.binaryType = 'arraybuffer';

    this.websocket.onopen = (ev) => {
      log.info('socket open', this.fe);
      this.emitter.emit(SocketEvent.Open, ev);
      this.reconnectAttempt = 0;

      this.heartbeat?.setSend((data) => {
        if (!this.isWebSocketReady()) {
          throw new SocketClosedError();
        }

        this.websocket?.send(data);
      });
    };

    this.websocket.onmessage = this.messageHandler.bind(this);

    this.websocket.onerror = (event: Event) => {
      log.info(event);
    };

    this.websocket.onclose = (ev: CloseEvent) => {
      this.status = SocketStatus.Closed;

      /**
       * @see closeEventCode
       * */
      if (this.closeEventCode !== 0) {
        ev = new CloseEvent('close', {
          ...ev,
          code: this.closeEventCode,
        });
      }

      this.removeSocketListeners();

      if (!this.isOffline && this.reconnectAttempt <= this.maxReconnects) {
        this.reopen();
      }

      this.emitter.emit(SocketEvent.Close, ev, this);
    };

    return new Promise((resolve, reject) => {
      const openListener = (e: Event) => {
        this.emitter.removeListener(SocketEvent.Open, openListener);
        resolve(e);
      };
      const errorListener = (e: Event | CloseEvent) => {
        this.emitter.removeListener(SocketEvent.Open, errorListener);
        reject(e);
      };

      this.emitter.addListener(SocketEvent.Open, openListener);
      this.emitter.addListener(SocketEvent.Close, errorListener);
      this.emitter.addListener(SocketEvent.Error, errorListener);
    });
  }

  terminate() {
    this.status = SocketStatus.Closed;
    this.heartbeat?.cancel();

    if (this.websocket) {
      this.websocket.onerror = null;
      this.websocket.onmessage = null;
      this.websocket.onclose = null;

      this.websocket.close();
    }

    this.websocket = null;
  }
  close(): Promise<void> {
    this.status = SocketStatus.Closing;
    this.heartbeat?.cancel();

    this.closeEventCode = WEBSOCKET_NORMAL_CLOSE_CODE;

    if (!this.websocket) {
      this.status = SocketStatus.Closed;

      return Promise.resolve();
    }

    this.websocket.onerror = null;
    this.websocket.onmessage = null;

    if (
      this.websocket.readyState === WebSocket.CLOSED ||
      this.websocket.readyState === WebSocket.CONNECTING
    ) {
      this.websocket.onclose = null;
      this.websocket = null;
      this.status = SocketStatus.Closed;

      return Promise.resolve();
    }

    return new Promise<void>((resolve) => {
      const listener = () => {
        if (this.websocket) {
          this.websocket.onclose = null;
        }

        this.websocket = null;

        this.status = SocketStatus.Closed;

        resolve();
      };

      this.emitter.once(SocketEvent.Close, listener);

      if (this.websocket?.readyState !== WebSocket.CLOSING) {
        this.websocket?.close();
      }
    });
  }

  removeSocketListeners() {
    if (this.websocket) {
      this.websocket.onmessage = null;
      this.websocket.onclose = null;
      this.websocket.onerror = null;
    }

    this.websocket = null;
  }

  handleMessage(message: Message) {
    this.emitter.emit(SocketEvent.Message, message);

    // trycatch нужен чтобы игнорировать срабатывания ошибок в обработчиках эмиттера
    try {
      if (isObjectMessage(message.payload)) {
        this.entitiesEmitter.emit(
          message.payload.type as EntityType,
          message.payload.data
        );
      } else {
        message.payload.forEach((item) => {
          item.data.forEach((entity) => {
            this.entitiesEmitter.emit(item.type as EntityType, entity);
          });
        });
      }
    } catch (e) {
      log.debug(e);
    }
  }

  messageHandler(e: MessageEvent) {
    const buffer = e.data;
    const bytesArray = new Uint8Array(buffer);

    if (this.panicMode) {
      // надо найти длинну текущего сообщения
      // в этот if мы попадаем когда оказались в ситуации, где разрыв между чанками
      // по несчастливой случайности попал аккуратно на последовательность байт с размером следующего сообщения в очереди
      // и мы не знаем когда и где находиться граница сообщений и все сломается, если мы ее не найдем
      // грубо говоря - тут мы ждем хотя бы 3 байта и когда у нас есть, то считываем размер текущего сообщения из них

      // теоретическая ситуация если сервер прислал два огрызка по 2 байта. Я такого не видел пока, но на всякий случай дальше закладка
      if (this.bytesRead + bytesArray.length < 3) {
        throw new Error('Panic: expected error, check messagehandler code');
      }

      const tempBuffer = new Uint8Array(
        this.packetBuffer[0].byteLength + bytesArray.length
      );
      const lastChunk = new Uint8Array(this.packetBuffer[0]);

      tempBuffer.set(lastChunk, 0);
      tempBuffer.set(bytesArray, lastChunk.length);
      const dv = new DataView(tempBuffer.buffer);

      this.packetBuffer = [tempBuffer.buffer];
      this.bytesRead = tempBuffer.byteLength;
      this.currentMessageLength = dv.getUint24(0);
      this.panicMode = false;
    } else {
      // дефолтный флоу - просто копим байты в буффере
      this.bytesRead += bytesArray.byteLength;
      this.packetBuffer.push(buffer);
    }

    if (this.messagePending === false) {
      // новое сообщение
      this.messagePending = true;
      this.packetBuffer = [bytesArray.buffer];
      this.bytesRead = bytesArray.byteLength;

      // есть такой прикол что иногда прилетают просто рандомные один или два байта
      // и мы снова оказываемся в ситуации когда не знаем размер след сообщения

      if (bytesArray.byteLength >= 3) {
        const dataView = new DataView(buffer);

        this.currentMessageLength = dataView.getUint24(0);
      } else {
        this.panicMode = true;
        this.bytesRead += bytesArray.byteLength;
        this.packetBuffer.push(buffer);

        return;
      }
    }

    //с накоплением данных тут все, дальше парсим что есть в очереди

    if (this.bytesRead === this.currentMessageLength) {
      this.messagePending = false;

      this.parseMessage(this.flattenBufferQueue(this.packetBuffer));
    } else if (this.bytesRead >= this.currentMessageLength) {
      // Дальше сложно: для больших пачек данных возможна ситуация, когда данные
      // приходят в нескольких пакетах. Те каждый пакет это свой размер пакета, возможно архив ну и тд
      // но при этом события onMessage продолжают приходить одно за другим
      // и возникает ситуация что внутри пачки данных часть относится к одному пакет
      // а часть к другому. Например такая ситуация с ObjectEntity
      const deltaBytes = this.bytesRead - this.currentMessageLength;

      let workingSet = this.flattenBufferQueue(this.packetBuffer);

      const prevMessageSlice = workingSet.slice(0, this.currentMessageLength);
      const nextMessageSlice = workingSet.slice(
        this.currentMessageLength,
        workingSet.byteLength
      );

      this.parseMessage(prevMessageSlice);

      // ситуация когда осталось меньше 3 байт и мы не знаем размер след сообщения
      if (nextMessageSlice.byteLength < 3) {
        this.panicMode = true;
        this.bytesRead = nextMessageSlice.byteLength;
        this.packetBuffer = [nextMessageSlice];

        // тет делать нечего, го дальше ждать данных
        return;
      }

      const dataView = new DataView(nextMessageSlice);

      const nextMessageLength = dataView.getUint24(0);

      this.packetBuffer = [nextMessageSlice];
      this.bytesRead = deltaBytes;
      this.currentMessageLength = nextMessageLength;

      if (this.currentMessageLength <= this.bytesRead) {
        // ситуация когда в текущем буфере есть более чем одно целое сообщение
        // вообще говоря можно было бы сделать что все что в одном цикле
        // но мне кажется так читаемее и проще отлаживать. Сверху обрабатывается кейс
        // когда одно больше сообщение разбивается на несколько пакетов, а тут дальше
        // случай когда в одном пакете пришло нексколько маленьких.
        this.reduceMessageBufferTail(nextMessageSlice);
      }
    }
  }

  private reduceMessageBufferTail(bytes: ArrayBuffer) {
    // Очищаем буффер, т.к. возможно мы распарсим хвост сообщения прямо сейчас
    this.packetBuffer.pop();
    let workingBytes = bytes;

    while (workingBytes.byteLength >= this.currentMessageLength) {
      const currentSlice = workingBytes.slice(0, this.currentMessageLength);

      // Обрабатываем текущее сообщение
      this.parseMessage(currentSlice);
      this.bytesRead -= currentSlice.byteLength;

      workingBytes = workingBytes.slice(this.currentMessageLength);

      if (workingBytes.byteLength === 0) {
        // Это было последнее сообщение в полученном чанке
        break;
      }

      if (workingBytes.byteLength < 3) {
        // Доступна лишь неполная часть от следующего сообщения
        this.panicMode = true;
        break;
      }

      // Определяем размер следующего сообщения
      this.currentMessageLength = new DataView(workingBytes).getUint24(0);
    }

    if (this.bytesRead === 0) {
      this.messagePending = false;
    } else {
      this.messagePending = true;
      this.packetBuffer.push(workingBytes);
    }
  }

  private parseMessage(buffer: ArrayBuffer) {
    try {
      const messageData = decode(buffer);

      const messageObj = {
        isArray: Array.isArray(messageData),
        frontend: this.fe,
        // если это один элемент он будет в payload, если там массив то массив
        payload: messageData,
      };

      this.handleMessage(messageObj);
    } catch (error) {
      throw new Error(
        `${error}\n
        fe: ${this.fe}\n
        id: ${this.id}\n
        idDevice: ${this.idDevice}\n
        bytesRead: ${this.bytesRead}\n,
        buffer: ${new Uint8Array(buffer).join(', ')}`
      );
    }
  }

  send(message: Message) {
    if (this.isOffline) {
      log.debug('Cannot send message when offline');

      return;
    }

    if (!Array.isArray(message.payload)) {
      log.debug(`SENDING ${message.payload.type} to fe=${message.frontend}`);

      if (message.payload.type === 'DataFlowSubscribeEntity') {
        const subscribeEntity = message.payload.data as DataFlowSubscribeEntity;

        log.debug(
          `DataFlowSubscribeEntity entity= "${getEntityNameById(
            subscribeEntity.DataFlowType
          )}" for type=${subscribeEntity.DataFlowType} `
        );
      }

      if (this.websocket?.readyState === WebSocket.OPEN) {
        this.websocket.send(encode(message.payload.data, message.payload.type));
      } else {
        throw new SocketClosedError();
      }
    } else {
      throw new Error('Sending of multi-entities packets not implemented');
    }
  }

  // вернет первый массив в списке или же пересоберет все массивы в один
  private flattenBufferQueue(queue: ArrayBuffer[]): ArrayBuffer {
    if (queue.length === 1) {
      return queue[0];
    } else {
      // возьмем из буффера все байты и сделаем из них один массив
      const mergedBytesLength = queue.reduce((prev, curr) => {
        return prev + curr.byteLength;
      }, 0);
      const mergedBytes = new Uint8Array(mergedBytesLength);

      let offset = 0;

      for (let slice of this.packetBuffer) {
        mergedBytes.set(new Uint8Array(slice), offset);
        offset += slice.byteLength;
      }

      return mergedBytes.buffer;
    }
  }
}
