import { format } from 'date-fns';
import qs from 'query-string';
import type { CurrencyCodes } from '@alfalab/utils';

import {
  IS_AI,
  MIDDLE_API_INVEST_PUBLIC_URI,
  MIDDLE_API_INVEST_URI,
  MOBILE_CLUSTER_URI,
  USE_PASSPORT_CERBERUS_AUTH,
} from '../../../env';
import { fetchThroughProxy, makeGetParams } from '../../url';

import { PortfolioAnalyticsResult } from '../../../types/account';
import { Product } from '../../../types/advisor';
import { Dividends } from '../../../types/dividents';
import { Issuer } from '../../../types/issuer';
import { IssuerFinancials } from '../../../types/issuerFinancials/issuerFinancials';
import { OperationHistoryResult } from '../../../types/operation';
import {
  DocumentsResult,
  DocumentToSign,
  ProfileRiskResult,
  StrategyResult,
} from '../../../types/strategy';

let access = '';
let refresh = '';

export const INVEST_URI = MIDDLE_API_INVEST_URI;
export const ALFA_MOBILE_URI = MOBILE_CLUSTER_URI;

export function setAlfaTokens(accessToken: string, refreshToken: string) {
  access = accessToken;
  refresh = refreshToken;
}

export function getAccessToken() {
  return access;
}

export function getRefreshToken() {
  return refresh;
}

export function getAuthorizationHeader() {
  if (USE_PASSPORT_CERBERUS_AUTH) {
    return;
  }

  if (!access) {
    throw new Error('Отсутствует токен доступа');
  }

  return {
    Authorization: `Bearer ${access}`,
  };
}

const fetchMiddleService = async (url: string, data) => {
  const params = makeGetParams(data);
  const response = await fetchThroughProxy(
    INVEST_URI + `/${IS_AI ? 'invest' : 'mobile'}/api/${url}?${params}`,
    {
      method: 'GET',
      headers: {
        ...getAuthorizationHeader(),
      },
    }
  );

  if (response.ok) {
    return await response.json();
  } else {
    if (response.status === 419) {
      // TODO заимпленть меня
      throw new Error('Токен просрочен');
    } else {
      throw new Error('Произошла ошибка при запросе к серверу');
    }
  }
};

//Дописать по необходимости
//http://confluence.moscow.alfaintra.net/pages/viewpage.action?pageId=679648143
export interface OperationsHistoryRequest {
  subAccount: string;
  maxElements: number;
  assetIdType: 'SYMBOL_OBJECT' | 'ID_OBJECT';
  operationTypes?: string;
  cursor?: string;
  assetIds?: string[];
}

export interface OperationsHistoryResult {
  cursor?: string;
  operations: OperationHistoryResult[];
}

export async function getOperationsHistory(
  data: OperationsHistoryRequest
): Promise<OperationsHistoryResult> {
  return fetchMiddleService('v1/history/operations', data);
}

//Дописать по необходимости
//http://confluence.moscow.alfaintra.net/pages/viewpage.action?pageId=679648143
export interface PortfolioAnalyticsRequest {
  accountId: number;
  dateFrom: string; //Формат - YYYY-MM-DD
  dateTo: string; //Формат - YYYY-MM-DD
}

export const getPortfolioAnalytics = async (
  data: PortfolioAnalyticsRequest
): Promise<PortfolioAnalyticsResult> => {
  return fetchMiddleService('v1/portfolio-analytics/report', data);
};

interface NetPoint {
  currency: CurrencyCodes;
  minorUnits: number;
  value: number;
}

interface ReturnPoint {
  currency: string;
  value: number;
}

export interface NetValuePoint {
  date: string;
  netAssets: NetPoint;
  returnOnAssets: ReturnPoint;
}

export interface PortfolioValueResult {
  netValueGraph: NetValuePoint[];
}

export const getPortfolioGraph = async (
  params
): Promise<PortfolioValueResult> => {
  return fetchMiddleService('v1/portfolio-analytics/portfolio-value', params);
};

export const getFinResultByActGroup = async (data) => {
  return fetchMiddleService('v1/portfolio-analytics/fin-results/assets', data);
};

export const getFinResultByActId = async ({ actId, ...other }) => {
  return fetchMiddleService(
    `v1/portfolio-analytics/fin-results/assets/${actId}`,
    other
  );
};

export const getFinResultByAccount = async ({ accountId, ...other }) => {
  return fetchMiddleService(
    `v1/portfolio-analytics/fin-results/accounts/${accountId}`,
    other
  );
};

export async function getDividends(isin: string): Promise<Dividends> {
  const response = await fetchThroughProxy(
    INVEST_URI +
      `/${
        IS_AI ? 'invest' : 'mobile'
      }/api/v1/instruments/dividends/${isin}/info`,
    {
      method: 'GET',
      headers: {
        ...getAuthorizationHeader(),
      },
    }
  );

  if (response.ok) {
    return await response.json();
  } else {
    if (response.status === 419) {
      // TODO заимпленть меня
      // @zinovev
      throw new Error('Токен просрочен');
    } else {
      throw new Error('Произошла ошибка при запросе к серверу');
    }
  }
}

export interface CouponsItem {
  date: string;
  rate: number;
  amount: {
    value: number;
    currency: string;
    minorUnits?: number;
  };
}

export interface CouponsResponse {
  isin: string;
  imageId: number;
  name: string;
  comingCoupons: CouponsItem[];
  paidCoupons: CouponsItem[];
}

export async function getCoupons(isin: string): Promise<CouponsResponse> {
  const response = await fetchThroughProxy(
    INVEST_URI +
      `/${IS_AI ? 'invest' : 'mobile'}/api/v1/instruments/coupons/${isin}/info`,
    {
      method: 'GET',
      headers: {
        ...getAuthorizationHeader(),
      },
    }
  );

  if (response.ok) {
    return await response.json();
  } else {
    if (response.status === 419) {
      // TODO заимпленть меня
      // @zinovev
      throw new Error('Токен просрочен');
    } else {
      throw new Error('Произошла ошибка при запросе к серверу');
    }
  }
}

export interface MultimarketExchange {
  id: string;
  liquidMarketId: string;
  liquidMarketName: string;
  markets: Array<{ id: string; name: string }>;
}

export interface MultimarketsItem {
  isin: string;
  symbolObject: string;
  defaultExchangeId: string;
  exchanges: MultimarketExchange[];
}

export type MultimarketsItems = MultimarketsItem[];

export type StrategyDocumentsRequest = {
  riskTypeID: number;
  advisorType: string;
};

export type GetDocumentsRequest = {
  agreement: string;
  newTariffGroup: string;
  product?: Product;
  portfolioId?: number;
};

export type SignChangeTariffRequest = {
  agreement: string;
  newTariffGroup: string;
  documents: DocumentToSign[];
  certificateId: number;
  processTime: Date;
  portfolioId?: number;
  product?: Product;
};

export const getMutimarketAssets = async (
  isins: string[] = []
): Promise<MultimarketsItems> => {
  const response = await fetchThroughProxy(
    INVEST_URI + `/${IS_AI ? 'invest' : 'mobile'}/api/v1/multi-markets/markets`,
    {
      method: 'POST',
      body: JSON.stringify({
        symbolObjects: [],
        isins,
      }),
      headers: {
        ...getAuthorizationHeader(),
        'Content-Type': 'application/json',
      },
    }
  );

  if (response.ok) {
    return await response.json();
  } else {
    if (response.status === 419) {
      // TODO заимпленть меня
      // @zinovev
      throw new Error('Токен просрочен');
    } else {
      throw new Error('Произошла ошибка при запросе к серверу');
    }
  }
};

export enum InvestQueryKeys {
  OperationsHistory = 'invest-operations-history',
  PortfolioAnalytics = 'portfolio-analytics',
  PortfolioGraph = 'portfolio-graph',
  FinResultByAccount = 'fin-result-by-account',
  FinResultByEmitent = 'fin-result-by-emitent',
  FinResultByInstrument = 'fin-result-by-instrument',
  InvestDividends = 'invest-dividends',
}

// http://confluence.moscow.alfaintra.net/pages/viewpage.action?pageId=952980154
export enum AccountsTypes {
  Current = 'EE',
  Salary = 'EH',
  Express = 'EL',
  Broker = 'GK',
  IIS = 'ZN',
  IIS3 = 'Z0',
  /**
   * Детский счёт
   * */
  Underage = '2N',
  /**
   * Накопительные счета
   * */
  SavingsAccount1 = 'FY',
  SavingsAccount2 = 'VW',
  SavingsAccount3 = 'A5',
  Other = 'GZ',
}

export interface TransferableAccount {
  description: string;
  number: string;
  type: AccountsTypes;
  groups: string[];
  amount: {
    currency: CurrencyCodes;
    minorUnits: number;
    value: number;
  };
}

export interface TransferableAccountResponse {
  accounts: TransferableAccount[];
}

export async function getTransferableAccounts(): Promise<TransferableAccountResponse> {
  const response = await fetchThroughProxy(
    ALFA_MOBILE_URI +
      `/mobile-invest/api/v1/self-transfer/transferable-accounts`,
    {
      method: 'GET',
      headers: {
        ...getAuthorizationHeader(),
      },
    }
  );

  if (response.ok) {
    return await response.json();
  } else {
    if (response.status === 419) {
      // TODO заимпленть меня
      throw new Error('Токен просрочен');
    } else {
      throw new Error('Произошла ошибка при запросе к серверу');
    }
  }
}

export interface SuccessCheck {
  status: 'OK';
}

export interface ErrorCheck {
  status: 'ERROR';
  message: string;
  account: string;
}

export interface CheckAccountsResult {
  result: (SuccessCheck | ErrorCheck)[];
}

export async function checkTransferableAccounts(
  source: string,
  destination: string
): Promise<CheckAccountsResult> {
  const response = await fetchThroughProxy(
    ALFA_MOBILE_URI +
      `/mobile-invest/api/v1/self-transfer/checks?sourceAccount=${source}&destinationAccount=${destination}`,
    {
      method: 'GET',
      headers: {
        ...getAuthorizationHeader(),
      },
    }
  );

  if (response.ok) {
    return await response.json();
  } else {
    if (response.status === 419) {
      // TODO заимпленть меня
      throw new Error('Токен просрочен');
    } else {
      throw new Error('Произошла ошибка при запросе к серверу');
    }
  }
}

export interface SumLimit {
  value: number;
  currency: CurrencyCodes;
  minorUnits: number;
}

export interface AmountLimit {
  title: string;
  max: SumLimit;
  min: SumLimit;
}

export interface CheckLimitsResult {
  max: SumLimit;
  min: SumLimit;
  sourceAvailability: {
    withdrawalAvailability: { status: 'OPEN'; message: '' };
    limits: AmountLimit[];
  };
  destinationAvailability: {
    refillAvailability: { status: 'OPEN'; message: '' };
    limits: AmountLimit[];
  };
}

export async function checkTransferLimits(
  source: string,
  destination: string
): Promise<CheckLimitsResult> {
  const response = await fetchThroughProxy(
    ALFA_MOBILE_URI +
      `/mobile-invest/api/v1/self-transfer/limits?sourceAccount=${source}&destinationAccount=${destination}`,
    {
      method: 'GET',
      headers: {
        ...getAuthorizationHeader(),
      },
    }
  );

  if (response.ok) {
    return await response.json();
  } else {
    if (response.status === 419) {
      // TODO заимпленть меня
      throw new Error('Токен просрочен');
    } else {
      throw new Error('Произошла ошибка при запросе к серверу');
    }
  }
}

export interface CreateRefillOperationResult {
  reference: string;
  confirmationMessage: string;
}

export async function createRefillOperation(
  sourceAccount: string | undefined,
  destinationAccount: string | undefined,
  value: number | null,
  currency: CurrencyCodes | undefined,
  minorUnits: number | undefined,
  CUS: string | undefined
): Promise<CreateRefillOperationResult> {
  const body = {
    sourceAccount,
    destinationAccount,
    amount: {
      value,
      currency,
      minorUnits,
    },
  };

  try {
    const res = await fetchThroughProxy(
      ALFA_MOBILE_URI + `/mobile-invest/api/v1/self-transfer/operations`,
      {
        method: 'POST',
        headers: {
          ...getAuthorizationHeader(),
          'CHANNEL-ID': 'I1',
          'Content-Type': 'application/json',
          'AUTH-USER-ID': CUS,
        },
        body: JSON.stringify(body),
      }
    );

    if (!res.ok) {
      throw new Error('Ошибка создания операции пополнения');
    }

    const parsed = await res.json();

    return parsed as unknown as CreateRefillOperationResult;
  } catch (_) {
    throw new Error('Ошибка создания операции пополнения');
  }
}

export async function confirmRefillIOperation(
  reference: string | undefined,
  CUS: string | undefined
): Promise<CreateRefillOperationResult> {
  try {
    const res = await fetchThroughProxy(
      ALFA_MOBILE_URI +
        `/mobile-invest/api/v1/self-transfer/operations/${reference}`,
      {
        method: 'PUT',
        headers: {
          ...getAuthorizationHeader(),
          'CHANNEL-ID': 'I1',
          'Content-Type': 'application/json',
          'AUTH-USER-ID': CUS,
        },
      }
    );

    if (!res.ok) {
      throw new Error('Ошибка создания операции пополнения');
    }

    const parsed = await res.json();

    return parsed as unknown as CreateRefillOperationResult;
  } catch (_) {
    throw new Error('Ошибка создания операции пополнения');
  }
}

export type ExternalChatToken = {
  tokenType: string;
  accessToken: string;
  expiresIn: number;
};

export const getExternalChatToken = async (): Promise<ExternalChatToken> => {
  const response = await fetchThroughProxy(
    INVEST_URI + `/${IS_AI ? 'invest' : 'mobile'}/api/v1/chat-auth/token`,
    {
      method: 'GET',
      headers: {
        ...getAuthorizationHeader(),
      },
    }
  );

  if (response.ok) {
    return await response.json();
  } else {
    if (response.status === 419) {
      // TODO заимпленть меня
      // @zinovev
      throw new Error('Токен просрочен');
    } else {
      throw new Error('Произошла ошибка при запросе к серверу');
    }
  }
};

export const getIssuer = async (isin: string): Promise<Issuer> => {
  const response = await fetchThroughProxy(
    INVEST_URI + `/${IS_AI ? 'invest' : 'mobile'}/api/v1/issuers?isin=${isin}`,
    {
      method: 'GET',
      headers: getAuthorizationHeader(),
    }
  );

  if (response.ok) {
    return response.json();
  } else {
    throw new Error('Произошла ошибка при запросе к серверу');
  }
};

/**
 * На данный момент этот endpoint не корректно обрабатывает query-параметры
 * */
export const getIssuerFinancials = async <
  Params extends {
    allHistoricalInfoRequired?: boolean;
    financialIndustryAvg?: boolean;
    scores?: boolean;
  }
>(
  isin: string,
  queryParams?: Params
): Promise<
  IssuerFinancials<
    Params['allHistoricalInfoRequired'],
    Params['financialIndustryAvg'],
    Params['scores']
  >
> => {
  const response = await fetchThroughProxy(
    qs.stringifyUrl({
      url:
        INVEST_URI +
        `/${IS_AI ? 'invest' : 'mobile'}/api/v1/issuers/financials`,
      query: { isin, ...queryParams },
    }),
    {
      method: 'GET',
      headers: getAuthorizationHeader(),
    }
  );

  if (response.ok) {
    return response.json();
  } else {
    throw new Error('Произошла ошибка при запросе к серверу');
  }
};

export const getScheduleOpeningTime = async (
  marketCode: string,
  isin: string
) => {
  const response = await fetchThroughProxy(
    INVEST_URI +
      `/invest/api/v1/trade-schedule/schedule/opening/next?marketCode=${marketCode}&assetId=${isin}`,
    {
      method: 'GET',
      headers: getAuthorizationHeader(),
    }
  );

  if (response.ok) {
    return response.json();
  } else {
    throw new Error('Произошла ошибка при запросе к серверу');
  }
};

export const getScreenerBondsData = async (
  CUS: string | undefined,
  filters
) => {
  const response = await fetchThroughProxy(
    INVEST_URI +
      `/${IS_AI ? 'invest' : 'mobile'}/api/v1/instruments-search/filters/bonds`,
    {
      method: 'POST',
      body: JSON.stringify(filters),
      headers: {
        ...getAuthorizationHeader(),
        'Content-Type': 'application/json',
        'AUTH-USER-ID': CUS,
        'CHANNEL-ID': 'I1',
      },
    }
  );

  if (response.ok) {
    return response.json();
  } else {
    throw new Error('Произошла ошибка при запросе к серверу');
  }
};

export type StrategyAsset = {
  isin: string;
  imageID: number;
  shortName: string;
};

export type StrategyModel = {
  modelID: number;
  modelName: string;
  productID: 'LITE';
  riskTypeID: number;
  entryAmount: number;
  currency: CurrencyCodes;
  expectedReturn: number;
  backgroundURL: string;
  assets: StrategyAsset[];
  yield: string | null;
};

export type Strategies = {
  models: StrategyModel[];
};

export const getStrategies = async (): Promise<Strategies> => {
  const response = await fetchThroughProxy(
    INVEST_URI + `/invest/api/v2/portfolio-model/portfolios`,
    {
      method: 'GET',
      headers: getAuthorizationHeader(),
    }
  );

  if (response.ok) {
    return response.json();
  } else {
    throw new Error('Произошла ошибка при запросе к серверу');
  }
};

export const getAuthorStrategies = async (
  authorId: string
): Promise<Strategies> => {
  const response = await fetchThroughProxy(
    INVEST_URI +
      `/invest/api/v2/portfolio-model/portfolios?authorId=${authorId}`,
    {
      method: 'GET',
      headers: getAuthorizationHeader(),
    }
  );

  if (response.ok) {
    return response.json();
  } else {
    throw new Error('Произошла ошибка при запросе к серверу');
  }
};

export async function getStrategyDetails(id: number): Promise<StrategyResult> {
  const query =
    INVEST_URI +
    `/${IS_AI ? 'invest' : 'mobile'}/api/v2/portfolio-model/portfolios/${id}`;
  const response = await fetchThroughProxy(query, {
    method: 'GET',
    headers: {
      ...getAuthorizationHeader(),
    },
  });

  if (response.ok) {
    return await response.json();
  } else {
    throw new Error('Произошла ошибка при запросе к серверу');
  }
}

export async function getProfileRisk(): Promise<ProfileRiskResult> {
  const query = `${MOBILE_CLUSTER_URI}/mobile-invest/api/v1/investments-recommendations/profile/description`;
  const response = await fetchThroughProxy(query, {
    method: 'GET',
    headers: {
      ...getAuthorizationHeader(),
    },
  });

  if (response.ok) {
    return await response.json();
  } else {
    throw new Error('Произошла ошибка при запросе к серверу');
  }
}

export async function getDocuments(
  data: GetDocumentsRequest
): Promise<DocumentsResult> {
  const query =
    INVEST_URI +
    `/${IS_AI ? 'invest' : 'mobile'}/api/v2/tariffs/agreements/${
      data.agreement
    }`;

  const response = await fetchThroughProxy(query, {
    method: 'POST',
    body: JSON.stringify({
      channel: 'MTAD',
      newTariffGroup: data.newTariffGroup,
      processTime: new Date(),
      ...(data.product && { product: data.product }),
      ...(data.portfolioId && {
        additionalParams: {
          portfolioId: String(data.portfolioId),
        },
      }),
    }),

    headers: {
      ...getAuthorizationHeader(),
      'Content-Type': 'application/json',
    },
  });

  if (response.ok) {
    return await response.json();
  } else {
    throw new Error('Произошла ошибка при запросе к серверу');
  }
}

export async function signChangeTariff(data: SignChangeTariffRequest) {
  const query =
    INVEST_URI + `/invest/api/v2/tariffs/agreements/${data.agreement}`;
  const body = {
    channel: 'STAD',
    newTariffGroup: data.newTariffGroup,
    processTime: format(data.processTime, "yyyy-MM-dd'T'HH:mm:ss.SSSSSSSXXX"),
    ...(data.product && { product: data.product }),
    ...(data.portfolioId && {
      additionalParams: {
        portfolioId: String(data.portfolioId),
      },
    }),
    documents: data.documents,
    eSign: {
      certificateId: data.certificateId.toString(),
      docCreatedAt: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSSSSSXXX"),
      signProvider: 'RSA',
    },
  };

  const response = await fetchThroughProxy(query, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: {
      ...getAuthorizationHeader(),
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Произошла ошибка при запросе к серверу');
  }
}

// Статус ребалансировки портфеля соглавно стратегии
export type StrategyStatus =
  | `OK`
  | 'REBALANCE'
  | 'REPLENISH'
  | 'REMOVE_ORDERS'
  | 'RP_NEEDED'
  | 'NOT_MATCH';

export type ProtfolioRebalanceStatus = {
  status: StrategyStatus;
  entryAmount?: string;
  currency?: string;
  currencyIcon?: string;
};

export const getPortfolioRebalanceStatus = async (
  agreementId: string
): Promise<ProtfolioRebalanceStatus> => {
  const response = await fetchThroughProxy(
    INVEST_URI +
      `/invest/api/v1/portfolio-rebalance/rebalance/status?agreementId=${agreementId}`,
    {
      method: 'GET',
      headers: getAuthorizationHeader(),
    }
  );

  if (response.ok) {
    return response.json();
  } else {
    throw new Error('Произошла ошибка при запросе к серверу');
  }
};

// Инструкции по ребалансировке портфеля
export type PortfolioRebalanceItem = {
  isin: string;
  name: string;
  ticker: string;
  assetType: null;
  quantity: number;
  price: { value: number; currency?: CurrencyCodes; unitCost: number };
  amount?: { value: number; currency?: CurrencyCodes };
  imageId: number;
};

export type PortfolioRebalanceInstructions = {
  sales: PortfolioRebalanceItem[];
  purchases: PortfolioRebalanceItem[];
  total: {
    purchases: number;
    sales: number;
    currency: CurrencyCodes;
  };
};

export const getPortfolioRebalanceInstructions = async (
  agreementId: string
): Promise<PortfolioRebalanceInstructions> => {
  const response = await fetchThroughProxy(
    INVEST_URI +
      `/invest/api/v1/portfolio-rebalance/rebalance/instructions?agreementId=${agreementId}`,
    {
      method: 'GET',
      headers: getAuthorizationHeader(),
    }
  );

  if (response.ok) {
    return response.json();
  } else {
    throw new Error('Произошла ошибка при запросе к серверу');
  }
};

export type AuthorizationStatus =
  | 'OPEN_BROKERAGE_ACC'
  | 'OPEN_BROKERAGE_ACC_NATIVE'
  | 'OPEN_BROKERAGE_ACC_WAITING'
  | 'AUTHORIZATION_NATIVE'
  | 'PASSWORD_RECOVERY'
  | 'AUTHORIZATION_PASSPORT'
  | 'NAME_NEEDED'
  | 'FINAL_STATUS'
  | 'INVESTBOX_ONLY'
  | 'FATAL_ERROR';

export type AuthorizationStatusRequest = {
  phone: string;
  firstName?: string;
  lastName?: string;
  surName?: string;
};

export type AuthorizationStatusResponse = {
  login: string;
  clientMessage: string;
  status: AuthorizationStatus;
  guid: string;
};

export const getAuthorizationStatus = async (
  data: AuthorizationStatusRequest
): Promise<AuthorizationStatusResponse> => {
  let url = `${MIDDLE_API_INVEST_PUBLIC_URI}/v1/auth-orchestrator/authorization/${data.phone}`;

  const params = Object.fromEntries(
    ['firstName', 'lastName', 'surName']
      .filter((key) => data[key])
      .map((key) => [key, data[key]])
  );

  if (Object.keys(params).length) {
    url = `${url}?${makeGetParams(params)}`;
  }

  const response = await fetchThroughProxy(url, {
    method: 'GET',
  });

  if (response.ok) {
    return response.json();
  } else {
    throw new Error('Произошла ошибка при запросе к серверу');
  }
};

export const downloadDocumentIIR = async (idAccount: number) => {
  const response = await fetchThroughProxy(
    MIDDLE_API_INVEST_URI +
      `/invest/api/v1/portfolio-model/lite/models/model/iir?agreementId=` +
      idAccount,
    {
      method: 'GET',
      headers: getAuthorizationHeader(),
    }
  );

  if (response.ok) {
    try {
      const json = await response.json();

      return json.iir;
    } catch (error) {
      throw error;
    }
  } else {
    throw new Error('Произошла ошибка при запросе к серверу');
  }
};
