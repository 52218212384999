import isEqual from 'lodash/isEqual';
import { useEffect, useMemo, useReducer, useRef } from 'react';

import { useAlfaDirectContext } from '@terminal/alfadirect/provider/react';

import { FinInfo } from '../../types/quotes';

type UseQuotesOptions =
  | {
      throttleTimeout?: number;
      selector?: (quote: Partial<FinInfo>) => Partial<FinInfo>;
    }
  | undefined;

// !важно чтобы fi был мемоизириован
export function useQuotes(
  fi: number | number[],
  { throttleTimeout = 1000, selector }: UseQuotesOptions = {
    throttleTimeout: 1000,
  }
): Record<number, Partial<FinInfo>> {
  const [, forceRender] = useReducer(() => ({}), {});
  const quotes = useRef<Record<number, Partial<FinInfo>>>({});

  const fis = useMemo(() => (Array.isArray(fi) ? fi : [fi]), [fi]);

  const { quotesService } = useAlfaDirectContext();

  useEffect(() => {
    if (!fis.length) {
      return;
    }

    const unsubscribe = quotesService.subscribeToFI(
      fis,
      (data: Record<string, Partial<FinInfo>>) => {
        const result = {};

        for (let fi of fis) {
          if (data[fi]) {
            result[fi] = selector ? selector(data[fi]) : data[fi];
          } else if (quotes.current[fi]) {
            result[fi] = quotes.current[fi];
          }
        }

        if (!isEqual(result, quotes.current)) {
          quotes.current = result;
          forceRender();
        }
      },
      throttleTimeout
    );

    return () => unsubscribe();
  }, [fis, quotesService, selector, throttleTimeout]);

  return quotes.current;
}
