import { fetchLkResult } from '@terminal/core/lib/rest/lkApi';

import { AccountInfoAI } from '../model/types/AccountInfoAI';
import { AccountInfoGI } from '../model/types/AccountInfoGI';
import { AccountInfoParams } from './types/AccountInfoParams';

export async function getAccountInfoAI(
  params: AccountInfoParams
): Promise<AccountInfoAI> {
  return await fetchLkResult<AccountInfoAI>(
    `/api/client/accounts/info/${params.treaty}`
  );
}

export async function getAccountInfoGI(
  params: AccountInfoParams
): Promise<AccountInfoGI> {
  return await fetchLkResult<AccountInfoGI>(
    `/api/client/accounts/info/v2/${params.treaty}`
  );
}
