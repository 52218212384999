import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Gap } from '@alfalab/core-components/gap';
import { Grid } from '@alfalab/core-components/grid';
import { Typography } from '@alfalab/core-components/typography';
import { AlertCircleMIcon } from '@alfalab/icons-glyph/AlertCircleMIcon';
import { DocumentPdfMIcon } from '@alfalab/icons-glyph/DocumentPdfMIcon';

import { DownloadedFile } from '@terminal/core/lib/rest/lkApi';
import { useDownloadFile } from '@terminal/lk/legacy/hooks';
import { getURLDownloadPDF, useCodeTable } from '@terminal/lk-core';

import styles from './CodeTableList.module.css';

export const CodeTableList = () => {
  const [isSigned, setIsSigned] = useState(false);
  const isFileWasRequested = useRef(false);
  const [file, setFile] = useState<DownloadedFile>();

  const { mutateAsync: downloadFile } = useDownloadFile();

  const signOptions = {
    successCallback: () => {
      setIsSigned(true);
    },
  };
  const { data, isFetching, refetch } = useCodeTable({ signOptions });

  useEffect(() => {
    if (isSigned && data?.id && !file && !isFileWasRequested.current) {
      isFileWasRequested.current = true;
      downloadFile(getURLDownloadPDF(data.id))
        .then((file) => {
          setFile(file);
        })
        .catch((e) => {
          isFileWasRequested.current = false;
        });
    }
  }, [data?.id, file, isSigned, downloadFile]);

  const onClickGenCodes = useCallback(() => {
    refetch();
  }, [refetch]);

  if (isFetching) {
    return null;
  }

  if (!isSigned) {
    return (
      <div className={styles.wrapperEmpty}>
        <Typography.Text view="secondary-large" className={styles.title}>
          Необходимо подписать поручение
        </Typography.Text>
        <div>
          <ButtonDesktop
            view="secondary"
            size="xs"
            onClick={onClickGenCodes}
            block
          >
            Сформировать новую таблицу
          </ButtonDesktop>
        </div>
      </div>
    );
  }

  return (
    <>
      <Typography.Text
        view="primary-small"
        weight="bold"
        tag="p"
        defaultMargins={false}
        className={styles.title}
      >
        Таблица кодов голосового доступа
      </Typography.Text>
      <div className={styles.secondRow}>
        <div className={styles.tableIcon}>
          <AlertCircleMIcon
            width={18}
            height={18}
            color="var(--color-light-graphic-attention)"
          />
        </div>
        <Typography.Text
          view="secondary-small"
          color="secondary"
          className={styles.tableSubTitle}
        >
          Внимание! Таблица показывается однократно. Для дальнейшего
          <br />
          использования настоятельно рекомендуем вам сохранить её.
        </Typography.Text>
      </div>

      {data && (
        <>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Номер ячейки</th>
                <th>Пароль</th>
              </tr>
            </thead>
            <tbody>
              {data.data.map((props) => {
                const { passNo, password } = props;

                return (
                  <tr key={passNo}>
                    <td>{passNo}</td>
                    <td>{password}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Gap size="2xl" />
          <Grid.Row>
            <Grid.Col width="6">
              <ButtonDesktop
                view="tertiary"
                size="xs"
                block
                leftAddons={<DocumentPdfMIcon width={18} height={18} />}
                disabled={!file || isFetching}
                loading={!file || isFetching}
                href={file?.url}
                download={file?.filename}
                target="_blank"
              >
                Скачать PDF
              </ButtonDesktop>
            </Grid.Col>
          </Grid.Row>
        </>
      )}
    </>
  );
};
