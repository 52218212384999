import { fetchThroughProxy } from '../../url';
import { getAuthorizationHeader } from '../investApi';
import { createMapFromMarginParams } from './createMapFromMarginParams';
import { MarginParamsDTO } from './MarginParamsDTO';

import { MarginParams, MarginParamsMap } from '../../../types/MarginParams';

type ReturnMarginParams<T, K> = K extends string
  ? T extends number
    ? MarginParams
    : MarginParamsMap<number, string>
  : MarginParamsMap<number, string>;

export type MarginParamsFetcher<T, K> = (
  idAccount: T,
  isin: K,
  universalMarketCode: string
) => Promise<ReturnMarginParams<T, K>>;

/**
 * Создает функцию для запроса маржинальных параметров по массиву счетов
 * и массиву ISIN-кодов.
 * @param {string} url - URL, на который отправляется запрос.
 * @returns {MarginParamsFetcher} - Функция, делающая запрос и возвращающая
 * объект с маржинальными параметрами.
 * */
export function createMarginParamsFetcher(
  url: string
): MarginParamsFetcher<number | number[], string | string[]> {
  return async (idAccount, isin, universalMarketCode) => {
    const accountIds = Array.isArray(idAccount) ? idAccount : [idAccount];
    const assetCodes = Array.isArray(isin) ? isin : [isin];

    const response = await fetchThroughProxy(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...getAuthorizationHeader(),
      },
      body: JSON.stringify({
        accountIds,
        assetCodes,
        marketCodes: [universalMarketCode],
      }),
    });

    if (!response.ok) {
      throw new Error('Произошла ошибка при запросе к серверу');
    }

    const data: MarginParamsDTO = await response.json();

    if (!Array.isArray(data) || data.length === 0) {
      throw new Error('Неверный формат данных');
    }

    const marginParamsMap = createMapFromMarginParams(data);

    /**
     * если запрос был для одного счета и одного isin, то возвращаем инстанс параметров
     * если запрос по нескольким счетам/isin, то возвращаем мапу
     * */
    return Object.values(marginParamsMap).length === 1
      ? Object.values(marginParamsMap)[0]
      : marginParamsMap;
  };
}
