export const TRADE_TOP_CHART_SETTING = {
  customSettings: {
    panels: {
      chart: { yAxis: { zoom: 42.689075630252105 } },
      '‌volume‌': { yAxis: { zoom: 2 } },
    },
    showOHLC: true,
    showOrders: true,
    showPositions: true,
    showDividends: true,
  },
  layout: {
    interval: 'day',
    periodicity: 1,
    timeUnit: null,
    candleWidth: 1.5504177737289182,
    flipped: false,
    volumeUnderlay: false,
    adj: true,
    crosshair: true,
    chartType: 'candle',
    extended: false,
    marketSessions: {},
    aggregationType: 'ohlc',
    chartScale: 'linear',
    panels: {
      chart: {
        percent: 0.8,
        display: 'GAZP МБ ЦК',
        chartName: 'chart',
        index: 0,
        yAxis: { name: 'chart', position: null },
        yaxisLHS: [],
        yaxisRHS: ['chart'],
      },
      '‌volume‌': {
        percent: 0.2,
        display: '‌volume‌',
        chartName: 'chart',
        index: 1,
        yAxis: { name: '‌volume‌' },
        yaxisLHS: [],
        yaxisRHS: ['‌volume‌'],
      },
    },
    setSpan: null,
    outliers: false,
    animation: true,
    headsUp: { static: true },
    sidenav: 'sidenavOff',
    symbols: [
      {
        symbol: 'GAZP МБ ЦК',
        symbolObject: {
          symbol: 'GAZP МБ ЦК',
          id: 146986,
          periodicity: { period: 1, interval: 1, timeUnit: 'day' },
          trueSymbol: 'GAZP',
        },
        periodicity: 1,
        interval: 'day',
        timeUnit: null,
        setSpan: null,
      },
    ],
    range: null,
    studies: {
      '‌volume‌': {
        type: 'volume',
        inputs: { id: '‌volume‌', display: '‌volume‌' },
        outputs: {
          'Up Volume': { color: '#898989' },
          'Down Volume': { color: '#898989' },
        },
        panel: '‌volume‌',
        parameters: {
          chartName: 'chart',
          editMode: true,
          panelName: '‌volume‌',
        },
      },
      '‌ma‌ (50,ema,0,1)': {
        type: 'ma',
        inputs: {
          Period: 50,
          Field: 'field',
          Type: 'exponential',
          Offset: 0,
          'MA width': 1,
          id: '‌ma‌ (50,ema,0,1)',
          display: '‌ma‌ (50,ema,0,1)',
        },
        outputs: { MA: { color: '#5875b7', width: 1 } },
        panel: 'chart',
        parameters: {
          interactiveAdd: false,
          chartName: 'chart',
          editMode: true,
          panelName: 'chart',
        },
      },
      '‌ma‌ (100,ema,0,1)': {
        type: 'ma',
        inputs: {
          Period: '100',
          Field: 'field',
          Type: 'exponential',
          Offset: 0,
          'MA width': 1,
          id: '‌ma‌ (100,ema,0,1)',
          display: '‌ma‌ (100,ema,0,1)',
        },
        outputs: { MA: { color: '#FF0000', width: 1 } },
        panel: 'chart',
        parameters: {
          interactiveAdd: false,
          chartName: 'chart',
          editMode: true,
          panelName: 'chart',
        },
      },
    },
  },
};
export const TRADE_BOTTOM_CHART_SETTING = {
  customSettings: {
    panels: {
      chart: { yAxis: { zoom: 42.689075630252105 } },
    },
    showOHLC: true,
    showOrders: true,
    showPositions: true,
    showDividends: true,
  },
  layout: {
    interval: 1,
    periodicity: 5,
    timeUnit: 'minute',
    candleWidth: 7.304471380896617,
    flipped: false,
    volumeUnderlay: false,
    adj: true,
    crosshair: true,
    chartType: 'colored_bar',
    extended: false,
    marketSessions: {},
    aggregationType: 'ohlc',
    chartScale: 'linear',
    panels: {
      chart: {
        percent: 0.8,
        display: 'GAZP МБ ЦК',
        chartName: 'chart',
        index: 0,
        yAxis: { name: 'chart', position: null },
        yaxisLHS: [],
        yaxisRHS: ['chart'],
      },
    },
    setSpan: null,
    outliers: false,
    animation: true,
    headsUp: { static: true },
    sidenav: 'sidenavOff',
    symbols: [
      {
        symbol: 'GAZP МБ ЦК',
        symbolObject: {
          symbol: 'GAZP МБ ЦК',
          id: 146986,
          periodicity: { period: 1, interval: 5, timeUnit: 'minute' },
          trueSymbol: 'GAZP',
        },
        periodicity: 5,
        interval: 1,
        timeUnit: 'minute',
        setSpan: null,
      },
    ],
    range: null,
    studies: {
      '‌ma‌ (7,ema,0,1)': {
        type: 'ma',
        inputs: {
          Period: '7',
          Field: 'field',
          Type: 'exponential',
          Offset: 0,
          'MA width': 1,
          id: '‌ma‌ (7,ema,0,1)',
          display: '‌ma‌ (7,ema,0,1)',
        },
        outputs: { MA: { color: '#5875b7', width: 1 } },
        panel: 'chart',
        parameters: {
          interactiveAdd: false,
          chartName: 'chart',
          editMode: true,
          panelName: 'chart',
        },
      },
      '‌ma‌ (21,ema,0,1)': {
        type: 'ma',
        inputs: {
          Period: '21',
          Field: 'field',
          Type: 'exponential',
          Offset: 0,
          'MA width': 1,
          id: '‌ma‌ (21,ema,0,1)',
          display: '‌ma‌ (21,ema,0,1)',
        },
        outputs: { MA: { color: '#FF0000', width: 1 } },
        panel: 'chart',
        parameters: {
          interactiveAdd: false,
          chartName: 'chart',
          editMode: true,
          panelName: 'chart',
        },
      },
    },
  },
};

export const CHART_MIDDLE_CHRTIQ_SETTINGS = {
  customSettings: {
    panels: {
      chart: { yAxis: { zoom: 36.6634335596508 } },
      '‌macd‌ (7,14,9,1,1)': { yAxis: { zoom: 3.719862227324916 } },
      '‌volume‌': { yAxis: { zoom: 1.2973593570608501 } },
    },
    showOHLC: true,
    showOrders: true,
    showPositions: true,
    showDividends: true,
  },
  layout: {
    interval: 'day',
    periodicity: 1,
    timeUnit: null,
    candleWidth: 9.301075268817204,
    flipped: false,
    volumeUnderlay: false,
    adj: true,
    crosshair: true,
    chartType: 'bar',
    extended: false,
    marketSessions: {},
    aggregationType: 'ohlc',
    chartScale: 'linear',
    panels: {
      chart: {
        percent: 0.7058823529411765,
        display: 'YNDX МБ ЦК',
        chartName: 'chart',
        index: 0,
        yAxis: { name: 'chart', position: null },
        yaxisLHS: [],
        yaxisRHS: ['chart'],
      },
      '‌macd‌ (7,14,9,1,1)': {
        percent: 0.15126050420168066,
        display: '‌macd‌ (7,14,9,1,1)',
        chartName: 'chart',
        index: 1,
        yAxis: { name: '‌macd‌ (7,14,9,1,1)' },
        yaxisLHS: [],
        yaxisRHS: ['‌macd‌ (7,14,9,1,1)'],
      },
      '‌volume‌': {
        percent: 0.14285714285714285,
        display: '‌volume‌',
        chartName: 'chart',
        index: 2,
        yAxis: { name: '‌volume‌' },
        yaxisLHS: [],
        yaxisRHS: ['‌volume‌'],
      },
    },
    setSpan: {},
    outliers: false,
    animation: true,
    headsUp: { static: true },
    sidenav: 'sidenavOff',
    symbols: [
      {
        symbol: 'YNDX МБ ЦК',
        symbolObject: {
          symbol: 'YNDX МБ ЦК',
          id: 162016,
          periodicity: { period: 1, interval: 1, timeUnit: 'day' },
          trueSymbol: 'YNDX',
        },
        periodicity: 1,
        interval: 'day',
        timeUnit: null,
        setSpan: {},
      },
    ],
    studies: {
      '‌macd‌ (7,14,9,1,1)': {
        type: 'macd',
        inputs: {
          'Fast MA Period': '7',
          'Slow MA Period': '14',
          'Signal Period': 9,
          'MACD width': 1,
          'Signal width': 1,
          id: '‌macd‌ (7,14,9,1,1)',
          display: '‌macd‌ (7,14,9,1,1)',
        },
        outputs: {
          MACD: { color: 'auto', width: 1 },
          Signal: { color: '#FF0000', width: 1 },
          'Increasing Bar': '#2FC26E',
          'Decreasing Bar': '#EF3124',
        },
        panel: '‌macd‌ (7,14,9,1,1)',
        parameters: {
          chartName: 'chart',
          editMode: true,
          panelName: '‌macd‌ (7,14,9,1,1)',
        },
      },
      '‌ma‌ (7,ema,0,1)': {
        type: 'ma',
        inputs: {
          Period: '7',
          Field: 'field',
          Type: 'exponential',
          Offset: 0,
          'MA width': 1,
          id: '‌ma‌ (7,ema,0,1)',
          display: '‌ma‌ (7,ema,0,1)',
        },
        outputs: { MA: { color: '#FF0000', width: 1 } },
        panel: 'chart',
        parameters: {
          interactiveAdd: false,
          chartName: 'chart',
          editMode: true,
          panelName: 'chart',
        },
      },
      '‌ma‌ (14,ema,0,1)': {
        type: 'ma',
        inputs: {
          Period: '14',
          Field: 'field',
          Type: 'exponential',
          Offset: 0,
          'MA width': 1,
          id: '‌ma‌ (14,ema,0,1)',
          display: '‌ma‌ (14,ema,0,1)',
        },
        outputs: { MA: { color: '#4a8dc8', width: 1 } },
        panel: 'chart',
        parameters: {
          interactiveAdd: false,
          chartName: 'chart',
          editMode: true,
          panelName: 'chart',
        },
      },
      '‌volume‌': {
        type: 'volume',
        inputs: { id: '‌volume‌', display: '‌volume‌' },
        outputs: {
          'Up Volume': { color: '#707070' },
          'Down Volume': { color: '#555555' },
        },
        panel: '‌volume‌',
        parameters: {
          chartName: 'chart',
          editMode: true,
          panelName: '‌volume‌',
        },
      },
    },
  },
};
export const CHART_RIGHT_TOP_CHRTIQ_SETTINGS = {
  customSettings: {
    panels: {
      chart: { yAxis: { zoom: 18.855480116391853 } },
      '‌volume‌': { yAxis: { zoom: 2 } },
    },
    showOHLC: true,
    showOrders: true,
    showPositions: true,
    showDividends: true,
  },
  layout: {
    interval: 1,
    periodicity: 10,
    timeUnit: 'minute',
    candleWidth: 6.204379562043796,
    flipped: false,
    volumeUnderlay: false,
    adj: true,
    crosshair: true,
    chartType: 'bar',
    extended: false,
    marketSessions: {},
    aggregationType: 'ohlc',
    chartScale: 'linear',
    panels: {
      chart: {
        percent: 0.8,
        display: 'YNDX МБ ЦК',
        chartName: 'chart',
        index: 0,
        yAxis: { name: 'chart', position: null },
        yaxisLHS: [],
        yaxisRHS: ['chart'],
      },
      '‌volume‌': {
        percent: 0.2,
        display: '‌volume‌',
        chartName: 'chart',
        index: 1,
        yAxis: { name: '‌volume‌', position: 'right' },
        yaxisLHS: [],
        yaxisRHS: ['‌volume‌'],
      },
    },
    setSpan: null,
    outliers: false,
    animation: true,
    headsUp: { static: true },
    sidenav: 'sidenavOff',
    symbols: [
      {
        symbol: 'YNDX МБ ЦК',
        symbolObject: {
          symbol: 'YNDX МБ ЦК',
          id: 162016,
          periodicity: { period: 1, interval: 10, timeUnit: 'minute' },
          trueSymbol: 'YNDX',
        },
        periodicity: 10,
        interval: 1,
        timeUnit: 'minute',
        setSpan: null,
      },
    ],
    range: null,
    studies: {
      '‌volume‌': {
        type: 'volume',
        inputs: { id: '‌volume‌', display: '‌volume‌' },
        outputs: {
          'Up Volume': { color: '#707070' },
          'Down Volume': { color: '#555555' },
        },
        panel: '‌volume‌',
        parameters: {
          chartName: 'chart',
          editMode: true,
          yaxisDisplayValue: 'right',
          panelName: '‌volume‌',
        },
      },
      '‌ma‌ (7,ema,0,1)': {
        type: 'ma',
        inputs: {
          Period: '7',
          Field: 'field',
          Type: 'exponential',
          Offset: 0,
          'MA width': 1,
          id: '‌ma‌ (7,ema,0,1)',
          display: '‌ma‌ (7,ema,0,1)',
        },
        outputs: { MA: { color: '#FF0000', width: 1 } },
        panel: 'chart',
        parameters: {
          interactiveAdd: false,
          chartName: 'chart',
          editMode: true,
          panelName: 'chart',
        },
      },
      '‌ma‌ (14,ema,0,1)': {
        type: 'ma',
        inputs: {
          Period: '14',
          Field: 'field',
          Type: 'exponential',
          Offset: 0,
          'MA width': 1,
          id: '‌ma‌ (14,ema,0,1)',
          display: '‌ma‌ (14,ema,0,1)',
        },
        outputs: { MA: { color: '#4a8dc8', width: 1 } },
        panel: 'chart',
        parameters: {
          interactiveAdd: false,
          chartName: 'chart',
          editMode: true,
          panelName: 'chart',
        },
      },
    },
  },
};
export const CHART_RIGHT_BOTTOM_CHRTIQ_SETTINGS = {
  customSettings: {
    panels: {
      chart: { yAxis: { zoom: 16.63433559650824 } },
      '‌rsi‌ (14,1)': { yAxis: { zoom: 7.157894736842104 } },
    },
    showOHLC: true,
    showOrders: true,
    showPositions: true,
    showDividends: true,
  },
  layout: {
    interval: 1,
    periodicity: 60,
    timeUnit: 'minute',
    candleWidth: 4.690721649484536,
    flipped: false,
    volumeUnderlay: false,
    adj: true,
    crosshair: true,
    chartType: 'bar',
    extended: false,
    marketSessions: {},
    aggregationType: 'ohlc',
    chartScale: 'linear',
    panels: {
      chart: {
        percent: 0.7072164948453609,
        display: 'YNDX МБ ЦК',
        chartName: 'chart',
        index: 0,
        yAxis: { name: 'chart', position: null },
        yaxisLHS: [],
        yaxisRHS: ['chart'],
      },
      '‌rsi‌ (14,1)': {
        percent: 0.2927835051546392,
        display: '‌rsi‌ (14,1)',
        chartName: 'chart',
        index: 1,
        yAxis: { name: '‌rsi‌ (14,1)' },
        yaxisLHS: [],
        yaxisRHS: ['‌rsi‌ (14,1)'],
      },
    },
    setSpan: {},
    outliers: false,
    animation: true,
    headsUp: { static: true },
    sidenav: 'sidenavOff',
    symbols: [
      {
        symbol: 'YNDX МБ ЦК',
        symbolObject: {
          symbol: 'YNDX МБ ЦК',
          id: 162016,
          periodicity: { period: 1, interval: 60, timeUnit: 'minute' },
          trueSymbol: 'YNDX',
        },
        periodicity: 60,
        interval: 1,
        timeUnit: 'minute',
        setSpan: {},
      },
    ],
    range: {},
    studies: {
      '‌ma‌ (7,ema,0,1)': {
        type: 'ma',
        inputs: {
          Period: '7',
          Field: 'field',
          Type: 'exponential',
          Offset: 0,
          'MA width': 1,
          id: '‌ma‌ (7,ema,0,1)',
          display: '‌ma‌ (7,ema,0,1)',
        },
        outputs: { MA: { color: '#FF0000', width: 1 } },
        panel: 'chart',
        parameters: {
          interactiveAdd: false,
          chartName: 'chart',
          editMode: true,
          panelName: 'chart',
        },
      },
      '‌ma‌ (14,ema,0,1)': {
        type: 'ma',
        inputs: {
          Period: '14',
          Field: 'field',
          Type: 'exponential',
          Offset: 0,
          'MA width': 1,
          id: '‌ma‌ (14,ema,0,1)',
          display: '‌ma‌ (14,ema,0,1)',
        },
        outputs: { MA: { color: '#00afed', width: 1 } },
        panel: 'chart',
        parameters: {
          interactiveAdd: false,
          chartName: 'chart',
          editMode: true,
          panelName: 'chart',
        },
      },
      '‌rsi‌ (14,1)': {
        type: 'rsi',
        inputs: {
          Period: 14,
          Field: 'field',
          'RSI width': 1,
          id: '‌rsi‌ (14,1)',
          display: '‌rsi‌ (14,1)',
        },
        outputs: { RSI: 'auto' },
        panel: '‌rsi‌ (14,1)',
        parameters: {
          studyOverZonesEnabled: true,
          studyOverBoughtValue: 70,
          studyOverBoughtColor: 'auto',
          studyOverSoldValue: 30,
          studyOverSoldColor: 'auto',
          chartName: 'chart',
          editMode: true,
          panelName: '‌rsi‌ (14,1)',
        },
      },
    },
  },
};
export const CHARTS__10MIN_CHARTIQ_SETTINGS = {
  customSettings: {
    panels: {
      chart: {
        yAxis: {
          zoom: 42.868945330941365,
        },
      },
    },
    showOHLC: true,
    showOrders: true,
    showPositions: true,
    showDividends: true,
  },
  layout: {
    interval: 1,
    periodicity: 10,
    timeUnit: 'minute',
    candleWidth: 8.349043813739673,
    flipped: false,
    volumeUnderlay: false,
    adj: true,
    crosshair: true,
    chartType: 'candle',
    extended: false,
    marketSessions: {},
    aggregationType: 'ohlc',
    chartScale: 'linear',
    panels: {},
    setSpan: null,
    outliers: false,
    animation: true,
    headsUp: {
      static: true,
    },
    sidenav: 'sidenavOff',
    symbols: [
      {
        symbol: 'SBER МБ ЦК',
        symbolObject: {
          symbol: 'SBER МБ ЦК',
          id: 144950,
          periodicity: {
            period: 10,
            interval: 1,
            timeUnit: 'minute',
          },
          trueSymbol: 'SBER',
        },
        periodicity: 10,
        interval: 1,
        timeUnit: 'minute',
        setSpan: null,
      },
    ],
    range: null,
    studies: {
      '‌ma‌ (7,ema,0,1)': {
        type: 'ma',
        inputs: {
          Period: '7',
          Field: 'field',
          Type: 'exponential',
          Offset: 0,
          'MA width': 1,
          id: '‌ma‌ (7,ema,0,1)',
          display: '‌ma‌ (7,ema,0,1)',
        },
        outputs: {
          MA: {
            color: '#5875b7',
            width: 1,
          },
        },
        panel: 'chart',
        parameters: {
          interactiveAdd: false,
          chartName: 'chart',
          editMode: true,
          panelName: 'chart',
        },
      },
      '‌ma‌ (14,ema,0,1)': {
        type: 'ma',
        inputs: {
          Period: '14',
          Field: 'field',
          Type: 'exponential',
          Offset: 0,
          'MA width': 1,
          id: '‌ma‌ (14,ema,0,1)',
          display: '‌ma‌ (14,ema,0,1)',
        },
        outputs: {
          MA: {
            color: '#FF0000',
            width: 1,
          },
        },
        panel: 'chart',
        parameters: {
          interactiveAdd: false,
          chartName: 'chart',
          editMode: true,
          panelName: 'chart',
        },
      },
    },
  },
};
export const CHARTS__HOUR_CHARTIQ_SETTINGS = {
  customSettings: {
    panels: {
      chart: {
        yAxis: {
          zoom: 42.689075630252105,
        },
      },
      '‌volume‌': {
        yAxis: {
          zoom: 2,
        },
      },
    },
    showOHLC: true,
    showOrders: true,
    showPositions: true,
    showDividends: true,
  },
  layout: {
    interval: 1,
    periodicity: 60,
    timeUnit: 'minute',
    candleWidth: 7.961904761904762,
    flipped: false,
    volumeUnderlay: false,
    adj: true,
    crosshair: true,
    chartType: 'candle',
    extended: false,
    marketSessions: {},
    aggregationType: 'ohlc',
    chartScale: 'linear',
    panels: {
      chart: {
        percent: 0.8,
        display: 'SBER МБ ЦК',
        chartName: 'chart',
        index: 0,
        yAxis: {
          name: 'chart',
          position: null,
        },
        yaxisLHS: [],
        yaxisRHS: ['chart'],
      },
      '‌volume‌': {
        percent: 0.2,
        display: '‌volume‌',
        chartName: 'chart',
        index: 1,
        yAxis: {
          name: '‌volume‌',
        },
        yaxisLHS: [],
        yaxisRHS: ['‌volume‌'],
      },
    },
    setSpan: {},
    outliers: false,
    animation: true,
    headsUp: {
      static: true,
    },
    sidenav: 'sidenavOff',
    symbols: [
      {
        symbol: 'SBER МБ ЦК',
        symbolObject: {
          symbol: 'SBER МБ ЦК',
          id: 144950,
          periodicity: {
            period: 1,
            interval: 60,
            timeUnit: 'minute',
          },
          trueSymbol: 'SBER',
        },
        periodicity: 60,
        interval: 1,
        timeUnit: 'minute',
        setSpan: {},
      },
    ],
    range: {},
    studies: {
      '‌volume‌': {
        type: 'volume',
        inputs: {
          id: '‌volume‌',
          display: '‌volume‌',
        },
        outputs: {
          'Up Volume': {
            color: '#898989',
          },
          'Down Volume': {
            color: '#555555',
          },
        },
        panel: '‌volume‌',
        parameters: {
          chartName: 'chart',
          editMode: true,
          panelName: '‌volume‌',
        },
      },
      '‌ma‌ (7,ema,0,1)': {
        type: 'ma',
        inputs: {
          Period: '7',
          Field: 'field',
          Type: 'exponential',
          Offset: 0,
          'MA width': 1,
          id: '‌ma‌ (7,ema,0,1)',
          display: '‌ma‌ (7,ema,0,1)',
        },
        outputs: {
          MA: {
            color: '#5875b7',
            width: 1,
          },
        },
        panel: 'chart',
        parameters: {
          interactiveAdd: false,
          chartName: 'chart',
          editMode: true,
          panelName: 'chart',
        },
      },
      '‌ma‌ (14,ema,0,1)': {
        type: 'ma',
        inputs: {
          Period: '14',
          Field: 'field',
          Type: 'exponential',
          Offset: 0,
          'MA width': 1,
          id: '‌ma‌ (14,ema,0,1)',
          display: '‌ma‌ (14,ema,0,1)',
        },
        outputs: {
          MA: {
            color: '#FF0000',
            width: 1,
          },
        },
        panel: 'chart',
        parameters: {
          interactiveAdd: false,
          chartName: 'chart',
          editMode: true,
          panelName: 'chart',
        },
      },
    },
  },
};
export const CHARTS_USD_CHARTIQ_SETTINGS = {
  customSettings: {
    panels: {
      chart: {
        yAxis: {
          zoom: 45.30680196606945,
        },
      },
    },
    showOHLC: true,
    showOrders: true,
    showPositions: true,
    showDividends: true,
  },
  layout: {
    interval: 'day',
    periodicity: 1,
    timeUnit: null,
    candleWidth: 8.041666666666666,
    flipped: false,
    volumeUnderlay: false,
    adj: true,
    crosshair: true,
    chartType: 'candle',
    extended: false,
    marketSessions: {},
    aggregationType: 'ohlc',
    chartScale: 'linear',
    panels: {
      chart: {
        percent: 1,
        display: 'USD ВР TOM',
        chartName: 'chart',
        index: 0,
        yAxis: {
          name: 'chart',
          position: null,
        },
        yaxisLHS: [],
        yaxisRHS: ['chart'],
      },
    },
    setSpan: {},
    outliers: false,
    animation: true,
    headsUp: {
      static: true,
    },
    sidenav: 'sidenavOff',
    symbols: [
      {
        symbol: 'USD ВР TOM',
        symbolObject: {
          periodicity: {},
          id: 149262,
          symbol: 'USD ВР TOM',
          trueSymbol: 'USD',
        },
        periodicity: 1,
        interval: 'day',
        timeUnit: null,
        setSpan: {},
      },
    ],
    studies: {},
  },
};
export const CHARTS_USD_MOEX_SETTINGS = {
  customSettings: {
    panels: {
      chart: {
        yAxis: {
          zoom: 45.30680196606945,
        },
      },
    },
    showOHLC: true,
    showOrders: true,
    showPositions: true,
    showDividends: true,
  },
  layout: {
    interval: 'day',
    periodicity: 1,
    timeUnit: null,
    candleWidth: 8.041666666666666,
    flipped: false,
    volumeUnderlay: false,
    adj: true,
    crosshair: true,
    chartType: 'candle',
    extended: false,
    marketSessions: {},
    aggregationType: 'ohlc',
    chartScale: 'linear',
    panels: {
      chart: {
        percent: 1,
        display: 'IMOEX ММВБ Индексы',
        chartName: 'chart',
        index: 0,
        yAxis: {
          name: 'chart',
          position: null,
        },
        yaxisLHS: [],
        yaxisRHS: ['chart'],
      },
    },
    setSpan: {},
    outliers: false,
    animation: true,
    headsUp: {
      static: true,
    },
    sidenav: 'sidenavOff',
    symbols: [
      {
        symbol: 'IMOEX ММВБ Индексы',
        symbolObject: {
          periodicity: {},
          id: 108897,
          symbol: 'IMOEX ММВБ Индексы',
          trueSymbol: 'IMOEX',
        },
        periodicity: 1,
        interval: 'day',
        timeUnit: null,
        setSpan: {},
      },
    ],
    studies: {},
  },
};
export const CHARTS_DAY_SETTINGS = {
  customSettings: {
    panels: {
      chart: {
        yAxis: {
          zoom: 29.784138374450905,
        },
      },
      '‌macd‌ (20,50,9,1,1)': {
        yAxis: {
          zoom: 9.856386999244148,
        },
      },
      '‌volume‌': {
        yAxis: {
          zoom: 2,
        },
      },
    },
    showOHLC: true,
    showOrders: true,
    showPositions: true,
    showDividends: true,
  },
  layout: {
    interval: 'day',
    periodicity: 1,
    timeUnit: null,
    candleWidth: 7.119402985074627,
    flipped: false,
    volumeUnderlay: false,
    adj: true,
    crosshair: true,
    chartType: 'candle',
    extended: false,
    marketSessions: {},
    aggregationType: 'ohlc',
    chartScale: 'linear',
    panels: {
      chart: {
        percent: 0.6095238095238096,
        display: 'SBER МБ ЦК',
        chartName: 'chart',
        index: 0,
        yAxis: {
          name: 'chart',
          position: null,
        },
        yaxisLHS: [],
        yaxisRHS: ['chart'],
      },
      '‌macd‌ (20,50,9,1,1)': {
        percent: 0.19047619047619047,
        display: '‌macd‌ (20,50,9,1,1)',
        chartName: 'chart',
        index: 1,
        yAxis: {
          name: '‌macd‌ (20,50,9,1,1)',
        },
        yaxisLHS: [],
        yaxisRHS: ['‌macd‌ (20,50,9,1,1)'],
      },
      '‌volume‌': {
        percent: 0.2,
        display: '‌volume‌',
        chartName: 'chart',
        index: 2,
        yAxis: {
          name: '‌volume‌',
        },
        yaxisLHS: [],
        yaxisRHS: ['‌volume‌'],
      },
    },
    setSpan: {},
    outliers: false,
    animation: true,
    headsUp: {
      static: true,
    },
    sidenav: 'sidenavOff',
    symbols: [
      {
        symbol: 'SBER МБ ЦК',
        symbolObject: {
          symbol: 'SBER МБ ЦК',
          id: 144950,
          periodicity: {
            period: 1,
            interval: 1,
            timeUnit: 'day',
          },
          trueSymbol: 'SBER',
        },
        periodicity: 1,
        interval: 'day',
        timeUnit: null,
        setSpan: {},
      },
    ],
    studies: {
      '‌ma‌ (20,ema,0,1)': {
        type: 'ma',
        inputs: {
          Period: '20',
          Field: 'field',
          Type: 'exponential',
          Offset: 0,
          'MA width': 1,
          id: '‌ma‌ (20,ema,0,1)',
          display: '‌ma‌ (20,ema,0,1)',
        },
        outputs: {
          MA: {
            color: '#5875b7',
            width: 1,
          },
        },
        panel: 'chart',
        parameters: {
          interactiveAdd: false,
          chartName: 'chart',
          editMode: true,
          panelName: 'chart',
        },
      },
      '‌ma‌ (50,ema,0,1)': {
        type: 'ma',
        inputs: {
          Period: '50',
          Field: 'field',
          Type: 'exponential',
          Offset: 0,
          'MA width': 1,
          id: '‌ma‌ (50,ema,0,1)',
          display: '‌ma‌ (50,ema,0,1)',
        },
        outputs: {
          MA: {
            color: '#FF0000',
            width: 1,
          },
        },
        panel: 'chart',
        parameters: {
          interactiveAdd: false,
          chartName: 'chart',
          editMode: true,
          panelName: 'chart',
        },
      },
      '‌macd‌ (20,50,9,1,1)': {
        type: 'macd',
        inputs: {
          'Fast MA Period': '20',
          'Slow MA Period': '50',
          'Signal Period': 9,
          'MACD width': 1,
          'Signal width': 1,
          id: '‌macd‌ (20,50,9,1,1)',
          display: '‌macd‌ (20,50,9,1,1)',
        },
        outputs: {
          MACD: {
            color: 'auto',
            width: 1,
          },
          Signal: {
            color: '#FF0000',
            width: 1,
          },
          'Increasing Bar': '#2FC26E',
          'Decreasing Bar': '#EF3124',
        },
        panel: '‌macd‌ (20,50,9,1,1)',
        parameters: {
          chartName: 'chart',
          editMode: true,
        },
      },
      '‌volume‌': {
        type: 'volume',
        inputs: {
          id: '‌volume‌',
          display: '‌volume‌',
        },
        outputs: {
          'Up Volume': {
            color: '#707070',
          },
          'Down Volume': {
            color: '#555555',
          },
        },
        panel: '‌volume‌',
        parameters: {
          chartName: 'chart',
          editMode: true,
        },
      },
    },
  },
};
