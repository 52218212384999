import { useEffect, useState } from 'react';

import {
  trackPwaShowSms,
  trackPwaSmsEntered,
} from '@terminal/core/lib/analytics';
import { phoneFormat } from '@terminal/core/lib/format';
import { sms2faResend } from '@terminal/core/lib/rest/lkAuth';
import { shallow, useStore } from '@terminal/core/store';

type ConfirmationState =
  | 'INITIAL'
  | 'CODE_CHECKING'
  | 'CODE_SENDING'
  | 'CODE_ERROR'
  | string;

type ConfirmationScreen = 'INITIAL' | 'TEMP_BLOCK' | string;

type Confirmation = {
  confirmationState: ConfirmationState;
  setConfirmationState: (confirmationState: ConfirmationState) => void;
  confirmationScreen: ConfirmationScreen;
  setConfirmationScreen: (confirmationScreen: ConfirmationScreen) => void;
};

export const useLogin2faForm = (confirmation: Confirmation) => {
  const [logout, authRes, lkAuthError, loginLK2fa, clearLkAuthError] = useStore(
    (state) => [
      state.logout,
      state.lkAuthRes,
      state.lkAuthError,
      state.loginLK2fa,
      state.clearLkAuthError,
    ],
    shallow
  );

  const {
    confirmationState,
    confirmationScreen,
    setConfirmationState,
    setConfirmationScreen,
  } = confirmation;

  const [authPending, setAuthPending] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setAuthPending(true);
  }, []);

  useEffect(() => {
    trackPwaShowSms();
  }, []);

  useEffect(() => {
    if (confirmationState === 'CODE_CHECKING') {
      trackPwaSmsEntered();
    }
  }, [confirmationState]);

  useEffect(() => {
    if (authRes) {
      setAuthPending(false);
      setConfirmationState('INITIAL');
      setConfirmationScreen('INITIAL');

      if (authRes.type === '2fa_overlimit') {
        setConfirmationState('CODE_ERROR');
        setError(authRes.message || '');
      } else if (authRes.type === '2fa_blocked') {
        setConfirmationScreen('TEMP_BLOCK');
        setBlocked(true);
      } else if (authRes.type === 'error') {
        setConfirmationState('CODE_ERROR');
      }
    }
  }, [authRes, setConfirmationState, setConfirmationScreen]);

  const validate2faCode = (code: string) => {
    setConfirmationState('CODE_CHECKING');
    loginLK2fa(code);
  };

  const resend2faSms = async () => {
    if (!authRes?.data?.id) {
      return setError('Отсутствует id операции. Попробуйте ещё раз');
    }

    setError('');
    clearLkAuthError();
    setConfirmationState('CODE_SENDING');

    try {
      const res = await sms2faResend(authRes?.data?.id);

      if (res.type !== 'success') {
        throw new Error(res.message || '');
      }
    } catch (err: any) {
      setError(err.message || 'При отправке смс произошла ошибка');
    }

    setConfirmationState('INITIAL');
  };

  const unblock = () => {
    setAuthPending(false);
    setConfirmationState('INITIAL');
    setConfirmationScreen('INITIAL');
  };

  const blockedCooldown = blocked ? 10 * 60 * 1000 : 30000;
  const overlimitCooldown = authRes?.data?.secondsToUnblock
    ? authRes?.data?.secondsToUnblock * 1000
    : blockedCooldown;

  const blockDuration = (authRes?.data?.secondsToUnblock || 10 * 60) * 1000;
  const tempBlockDescription = `Повторное подтверждение кодом будет возможно через:`;
  const formatedPhone =
    authRes?.data?.phone && phoneFormat(authRes?.data?.phone, true);

  return {
    authPending,
    confirmationScreen,
    confirmationState,
    setConfirmationState,
    setConfirmationScreen,
    formatedPhone,
    overlimitCooldown,
    blockDuration,
    error,
    lkAuthError,
    tempBlockDescription,
    validate2faCode,
    resend2faSms,
    unblock,
    logout,
  };
};
