import { CIQ } from 'chartiq';

import { EMarkerType } from '@terminal/chartiq/src/plugins/tfc/types';

import { IChartEventEmitter } from './ChartEventEmitter';
import { IChartManager } from './ChartManager';
import { ITFC } from './TFC';
import { TVolumeFilterSetting } from './workspace';

export interface ArchiveCandle {
  Open: number;
  High: number;
  Close: number;
  Low: number;
  DT: Date;
  Date?: string;
  Volume: number;
  Last?: number;
  Hide?: boolean;
}

export interface OHLCCandle extends ArchiveCandle {
  rawHigh: number;
  rawLow: number;
  rawOpen: number;
  rawClose: number;
}

export interface Periodicity {
  period: number;
  interval: number;
  timeUnit: string;
}

export interface SymbolObject {
  symbol: string;
  id: number;
  periodicity: Periodicity;
  trueSymbol: string;
}

export interface LayoutSymbol {
  id: string;
  symbol: string;
  symbolObject: SymbolObject;
  periodicity: Periodicity;
  interval: number;
  timeUnit: string;
  setSpan: object | null;
}

export interface Layout {
  interval: string;
  periodicity: number;
  timeUnit: string;
  candleWidth: number;
  flipped: boolean;
  volumeUnderlay: boolean;
  adj: boolean;
  crosshair: boolean;
  chartType: string;
  extended: boolean;
  marketSessions: {};
  aggregationType: string;
  chartScale: string;
  panels: object[];
  setSpan: object | null;
  outliers: boolean;
  animation: boolean;
  sidenav: string;
  headsUp: boolean;
  symbols: LayoutSymbol[];
}

export type TChartTradesMode = 'none' | 'recent' | 'history';
export type TChartScalesMode = 'linear' | 'log' | 'percent';

export interface IDrawingBase {
  name: string;
  serialize: () => string;
  highlighted: boolean;
  repositioner: boolean;
}

export type TDrawingLinePattern = 'solid' | 'dashed' | 'dotted';

export interface FontParams {
  size: string;
  weight?: 'bold' | null;
  style?: 'italic' | null;
}

export interface IDrawingCommon extends IDrawingBase {
  color?: string;
  fillColor?: string;
  pattern?: TDrawingLinePattern;
  lineWidth?: number;
  continueToLeft?: boolean;
  continueToRight?: boolean;
  centralDot?: boolean;
  axisLabel?: boolean;
  font?: FontParams;
  parameters?: any;
}

export interface IDrawingDeviationParams {
  active1: boolean;
  active2: boolean;
  active3: boolean;
  color1: string;
  color2: string;
  color3: string;
  lineWidth1: number;
  lineWidth2: number;
  lineWidth3: number;
  pattern1: TDrawingLinePattern;
  pattern2: TDrawingLinePattern;
  pattern3: TDrawingLinePattern;
}

export interface IDrawingWithDeviations
  extends IDrawingCommon,
    IDrawingDeviationParams {}

export interface IElliottWaveParams {
  annotationPoints: [
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string
  ];
  impulse: [string, string, string, string, string];
  corrective: [string, string, string];
  decoration: null | 'parentheses' | 'enclosed';
  showLines: boolean;
}

export interface IElliottWaveDrawing
  extends IDrawingCommon,
    IElliottWaveParams {}

export interface ITextDrawingParams {
  borderColor: string;
  callout: {
    color: string;
    borderColor: string;
  };
}
export interface ITextDrawing extends IDrawingCommon, ITextDrawingParams {}

export type TDrawing =
  | IDrawingCommon
  | IDrawingWithDeviations
  | IElliottWaveDrawing
  | ITextDrawing;

export interface IChartCustomSettings {
  isOrderbookActive?: boolean;
  isTradingActive?: boolean;
  isIntervalActive?: boolean;
  dateInterval?: string;
  aggregationStep?: number;
  panels?: Record<string, { yAxis?: { zoom?: number }; name?: string }>;
  rangeSlide?: boolean;
  chartScale?: TChartScalesMode;
  trades?: TChartTradesMode;
  showOrders?: boolean;
  showPositions?: boolean;
  showDividends?: boolean;
  showOHLC?: boolean;
  drawingSettingsPos?: {
    x: number;
    y: number;
  };
  drawingsDefaultSettings?: Record<string, string>;
}

export const chartCustomSettingsFields: (keyof IChartCustomSettings)[] = [
  'isIntervalActive',
  'dateInterval',
  'aggregationStep',
  'rangeSlide',
  'chartScale',
  'trades',
  'showOrders',
  'showPositions',
  'showDividends',
  'showOHLC',
  'isOrderbookActive',
  'drawingSettingsPos',
  'drawingsDefaultSettings',
  'isTradingActive',
];

export interface ChartSetting {
  layout?: object;
  drawings?: { [key: string]: any[] };
  preferences?: object;
  theme?: string;
  customSettings?: IChartCustomSettings;
  alerts?: {
    isDisplayingOnChart?: boolean;
  };
}

export interface ChartThemeSettings {
  name: string;
  auto?: boolean;
  axisText: string;
  background: string;
  gridDividers: string;
  gridLines: string;
  candleUpFill: string;
  candleDownFill: string;
  candleUpWick: string;
  candleDownWick: string;
  candleUpBorder: string;
  candleDownBorder: string;
  line: string;
}

export enum ChartDefaultTheme {
  NIGHT = 'ciq-night',
  DAY = 'ciq-day',
}

export interface ITFCTrade {
  id: string;
  time: number;
  quantity: number;
  basis: number;
  price: number;
}

interface IChartThemes {
  params: {
    builtInThemes: Record<string, string>;
    customThemes: Record<string, string>;
    defaultTheme?: ChartDefaultTheme;
  };
  setDefaultTheme?: (className: string) => void;
  setCustomTheme?: (name: string) => void;
  removeTheme?: (name: string) => void;
  newTheme?: () => void;
}

export interface IChartEngine<T extends unknown = unknown>
  extends CIQ.ChartEngine {
  nodeId?: string;
  masterData: OHLCCandle[];
  priceStep?: number;
  showOHLC?: boolean;
  maxVelocity?: number;
  isVolumesLoading?: boolean;
  volumeFilters?: TVolumeFilterSetting[];
  themes?: IChartThemes;
  tfc: T;
  panels: Record<string, CIQ.ChartEngine.Panel>;
  container: HTMLDivElement;
  chart: CIQ.ChartEngine.Chart & {
    container: HTMLDivElement;
    panel: CIQ.ChartEngine.Panel & { subholder: HTMLDivElement };
    symbolObject: SymbolObject;
    /**
     * Метод для проверки свечи на присутсвие в заданном интервале. Если результат False, свеча не отрисовывается на графике
     * */
    customCheckInterval?: null | ((candle: OHLCCandle) => boolean);
  };
  currentVectorParameters: typeof CIQ.ChartEngine.currentVectorParameters & {
    continueToLeft?: boolean;
    continueToRight?: boolean;
    centralDot?: boolean;
  };
  activeDrawingObject: null | TDrawing;
  callbackListeners: Record<string, Function[]>;
  markersGroupingFunctions: Partial<
    Record<
      EMarkerType,
      (markers: CIQ.Marker.GroupableMarker[]) => CIQ.Marker.GroupableMarker[][]
    >
  >;
  isPeriodicityChangingInProgress: boolean;
}

export type TChartStxx = IChartEngine<ITFC> & {
  isMobile: false;
  eventemitter: IChartEventEmitter;
  chartManager: IChartManager;
};
