import { useQuery } from 'react-query';

import {
  getPortfolioGraph,
  InvestQueryKeys,
} from '@terminal/core/lib/rest/investApi';

import { useWidgetContext } from '../../../shared';

export const usePortfolioGraph = (params, enabled) => {
  const { addNotification } = useWidgetContext();

  return useQuery(
    [InvestQueryKeys.PortfolioGraph, params],
    () => getPortfolioGraph(params),
    {
      enabled,
      onError: (error: any) =>
        addNotification({
          badge: 'negative',
          title: 'Ошибка',
          text: error?.message || 'Произошла ошибка при запросе',
        }),
    }
  );
};
