import { memo, SyntheticEvent, useEffect, useRef, useState } from 'react';

import { trackClickOnDesktop } from '@terminal/core/lib/analytics';
import { shallow, useStore } from '@terminal/core/store';
import { activeConfigurationSelector } from '@terminal/core/store/selectors/workspaceConfigurations/activeConfigurationSelector';

import { trackChangeLayout, trackCloseLayout } from '../../lib/analytics';
import { TabButton } from '../TabButton';

export type TabProps = {
  tabKey: number;
  isChildWindow?: boolean;
};

export const Tab = memo((props: TabProps) => {
  const { tabKey, isChildWindow } = props;
  const [getActiveLayoutKey, setActiveLayoutKey, removeLayout, setLayoutName] =
    useStore(
      (state) => [
        state.getActiveLayoutKey,
        state.setActiveLayoutKey,
        state.removeLayout,
        state.setLayoutName,
      ],
      shallow
    );

  const { lastAutoSavedConfig } = useStore(activeConfigurationSelector);
  const { layoutNames, layouts } = lastAutoSavedConfig;

  const [tabName, setTabName] = useState(layoutNames[tabKey]);
  const storeTabName = layoutNames[tabKey];
  const nodeRef = useRef(null);

  useEffect(() => {
    setTabName(storeTabName);
  }, [storeTabName]);

  const onTabClick = () => {
    setActiveLayoutKey(tabKey);
    trackClickOnDesktop(String(tabKey));
    trackChangeLayout({
      layoutsCount: layouts.length,
      layoutName: layoutNames[tabKey],
    });
  };

  const onTabCloseClick = (event: SyntheticEvent) => {
    event.stopPropagation();
    removeLayout(tabKey);
    trackCloseLayout({
      layoutsCount: layouts.length,
      layoutName: layoutNames[tabKey],
    });
  };

  return (
    <TabButton
      ref={nodeRef}
      label={tabName}
      editable
      closable={!isChildWindow && layouts.length > 1}
      selected={getActiveLayoutKey() === tabKey}
      onClick={onTabClick}
      onEdit={(name) => {
        setTabName(name);
      }}
      onEditFinish={() => {
        setLayoutName(tabKey, tabName || `Рабочий стол ${tabKey + 1}`);
      }}
      onClose={onTabCloseClick}
      dataTestId={getActiveLayoutKey() === tabKey ? 'Tab--selected' : 'Tab'}
    />
  );
});

Tab.displayName = 'Tab';
