import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getClientProfile } from '@terminal/core/lib/rest/lkRiskProfile';
import { NotificationType } from '@terminal/core/types/ui';

export const useClientProfile = () => {
  const addNotification = useNotification();

  return useQuery(
    ['lk', 'riskProfile', 'clientProfile'],
    async () => getClientProfile(),
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка запроса профиля риска',
          text: error?.message || '',
        });
      },
    }
  );
};
