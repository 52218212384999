import qs from 'query-string';

import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { IiaConvertStatus } from '../model/types/IiaConvertStatus';
import { IiaConvertStatusParams } from './types/IiaConvertStatusParams';

export async function getIiaConvertStatus(params: IiaConvertStatusParams) {
  const res = await fetchLkResult<IiaConvertStatus | LKResult>(
    qs.stringifyUrl({
      url: '/api/iia-convert/status',
      query: { ...params },
    })
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}
