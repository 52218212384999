import { useEffect, useState } from 'react';

import { useIiaConvertSend } from './useIiaConvertSend';

import { IiaConvertText } from '../model/types/IiaConvertText';

type ResultType = 'success' | 'failed';

export function useIiaConvertForm(text: IiaConvertText | undefined) {
  const [isAgreed, setIsAgreed] = useState(false);
  const [result, setResult] = useState<ResultType | null>(null);

  const { mutateAsync: requestIiaConvertSend } = useIiaConvertSend({
    params: {
      operationId: text?.id || '',
    },
    signOptions: {
      successCallback: () => {
        setResult('success');
      },
      errorCallback: () => {
        setResult('failed');
      },
    },
  });

  useEffect(() => {
    setIsAgreed(false);
    setResult(null);
  }, [text]);

  const handleSend = () => {
    if (text) {
      requestIiaConvertSend();
    }
  };

  const isReady = isAgreed;

  return {
    isAgreed,
    setIsAgreed,
    result,
    isReady,
    requestIiaConvertSend: handleSend,
  };
}
