/* eslint-disable custom-eslint-plugins/files-export */
import {
  ActionSources,
  ANALYTICS,
  DIMENSIONS_SCHEMAS,
} from '../../constants/analytics';
import { ActivationTime } from '../../constants/orderBook';
import { IS_DESKTOP } from '../../env';
import {
  LifeTime,
  ObjectGroup,
  OrderType,
  QuantityType,
} from '../client/entities';
import { track } from './core';
import {
  AnaliticsVolumeType,
  DetailedOrderAction,
  DetailedOrderParams,
} from './model/order';

/**
 * Выставление заявки
 * */
export const trackOrder = (buy: boolean, orderType: number, source: string) => {
  const codeType = OrderType[orderType].toLowerCase();
  const actionSell = IS_DESKTOP
    ? ANALYTICS.ACTION.CREATE_ORDER_SELL_WT
    : ANALYTICS.ACTION.CREATE_ORDER_SELL;
  const actionBuy = IS_DESKTOP
    ? ANALYTICS.ACTION.CREATE_ORDER_BUY_WT
    : ANALYTICS.ACTION.CREATE_ORDER_BUY;

  track(
    ANALYTICS.CATEGORY.ORDER_TYPE,
    buy ? actionBuy : actionSell,
    ANALYTICS.LABEL.USER,
    null,
    null,
    [
      {
        schema: DIMENSIONS_SCHEMAS.CUSTOM,
        data: {
          '1': codeType.toUpperCase(),
          '2': source,
        },
      },
    ]
  );
};

/**
 * Топ по торгуемым заявкам и тикерам
 * */
export const trackTickerTypeOrder = (
  tickerCategory: number,
  ticker: string,
  buy: boolean,
  rCode: string
) => {
  const tickerCat = ObjectGroup[tickerCategory];
  const trading = buy ? 'buy' : 'sell';

  track(
    ANALYTICS.CATEGORY.TICKER_CATEGORY,
    ANALYTICS.ACTION.TICKER_BUY_SELL_WT,
    ANALYTICS.LABEL.USER,
    null,
    null,
    [
      {
        schema: DIMENSIONS_SCHEMAS.CUSTOM,
        data: {
          '1': tickerCat,
          '2': ticker,
          '3': trading,
          '4': rCode,
        },
      },
    ]
  );
};

/**
 * Связанные заявки
 * */
export const trackLinkedOrder = (linkedOrderId: string | undefined) => {
  track(
    ANALYTICS.CATEGORY.ADD_INSTRUMENT,
    IS_DESKTOP ? ANALYTICS.ACTION.ADD_INSTR_WT : ANALYTICS.ACTION.ADD_INSTR,
    ANALYTICS.LABEL.USER,
    null,
    null,
    [
      {
        schema: DIMENSIONS_SCHEMAS.CUSTOM,
        data: {
          1: `conditions=${linkedOrderId ? 'aditional' : 'no'}`,
        },
      },
    ]
  );
};

/**
 * Источник открытия окна редактирования заявки
 * */
export const trackOrderEditSource = (source: ActionSources) => {
  track(
    ANALYTICS.CATEGORY.ORDER_TYPE,
    IS_DESKTOP
      ? ANALYTICS.ACTION.EDIT_ORDER_SOURCE_WT
      : ANALYTICS.ACTION.EDIT_ORDER_SOURCE,
    ANALYTICS.LABEL.USER,
    null,
    null,
    [
      {
        schema: DIMENSIONS_SCHEMAS.CUSTOM,
        data: { 1: `Source=${ActionSources[source]?.toLowerCase()}` },
      },
    ]
  );
};

/**
 * Тип редактируемых заявок
 * */
export const trackOrderEditType = (idOrderType: OrderType) => {
  track(
    ANALYTICS.CATEGORY.ORDER_TYPE,
    IS_DESKTOP
      ? ANALYTICS.ACTION.EDIT_ORDER_TYPE_WT
      : ANALYTICS.ACTION.EDIT_ORDER_TYPE,
    ANALYTICS.LABEL.USER,
    null,
    null,
    [
      {
        schema: DIMENSIONS_SCHEMAS.CUSTOM,
        data: { 1: `CodeType=${OrderType[idOrderType]?.toUpperCase()}` },
      },
    ]
  );
};

/**
 * Подробная информация об отправляемой заявке
 * */
export const trackDetailedOrderInfo = (
  action: DetailedOrderAction,
  params: DetailedOrderParams
) => {
  const {
    orderDirection,
    orderSource,
    ticker,
    productTitle,
    productGroup,
    orderType,
    orderSum,
    quantityType,
    activationDate,
    additionalInstructions,
    maturityDate,
    isin,
    errorText,
  } = params;

  track(
    ANALYTICS.CATEGORY.TRADING_ORDER,
    action,
    ANALYTICS.LABEL.ORDER,
    null,
    null,
    [
      {
        schema: DIMENSIONS_SCHEMAS.CUSTOM,
        data: {
          1: orderDirection, // Направление заявки
          2: orderSource, // Место выставления заявки
          3: ticker, // Тикер продукта
          4: productTitle, // Название продукта
          5: productGroup, // Тип продукта
          6: orderType, // Выбранный тип заявки
          7: `Сумма: ${orderSum}`, // Сумма заявки
          10: QuantityType[quantityType], // Способ изменения количества
          11: activationDate && ActivationTime[activationDate], // Дата активации заявки
          12: additionalInstructions, // Дополнительные инструкции к заявке
          13: LifeTime[maturityDate], // Дата экспириации заявки
          14: isin, // ISIN
          21: errorText, // Текст ошибки
        },
      },
    ]
  );
};

/**
 * Смена типа выставления объёма
 * */
export const trackOrderVolumeChanging = (volumeType: AnaliticsVolumeType) => {
  track(
    ANALYTICS.CATEGORY.TRADING_ORDER,
    ANALYTICS.ACTION.CLICK,
    ANALYTICS.LABEL.VOLUME,
    null,
    null,
    [
      {
        schema: DIMENSIONS_SCHEMAS.CUSTOM,
        data: {
          1: volumeType,
        },
      },
    ]
  );
};
