import cn from 'classnames';
import { memo, useCallback } from 'react';
import { Typography } from '@alfalab/core-components/typography';
import { LightningSIcon } from '@alfalab/icons-glyph/LightningSIcon';

import { NewsItem as NewsType } from '@terminal/common/entities/News';

import { formatNewsDate } from '../../lib/formatNewsDate';

import styles from './NewsItem.module.css';

export type NewsItemProps = NewsType & {
  onClick: (NewsItemType: NewsType) => void;
};

export const NewsItem = memo((props: NewsItemProps) => {
  const dateFormatted = formatNewsDate(props.date);

  const clickHandler = useCallback(() => {
    props.onClick(props);
  }, [props]);

  const isImportant = ['EXPRESS', 'FLASH'].includes(props.importance);

  return (
    <div
      role="button"
      tabIndex={0}
      className={styles.newsItemWrapper}
      onClick={clickHandler}
    >
      <div
        className={cn(styles.newsItemHeader, {
          [styles.newsItemHeaderSimple]: !isImportant,
          [styles.newsItemHeaderImportant]: isImportant,
        })}
      >
        {isImportant ? (
          <Typography.Text
            view="secondary-small"
            className={styles.attentionIcon}
            color="attention"
          >
            <LightningSIcon />
          </Typography.Text>
        ) : null}

        <div
          className={cn(
            styles.newsItemHeaderItem,
            styles.newsItemHeaderItemSource
          )}
        >
          {props.source}
        </div>

        <div className={styles.newsItemHeaderImportantDateContainer}>
          {isImportant ? (
            <div className={styles.importantText}>Срочная</div>
          ) : null}

          <div
            className={cn(
              styles.newsItemHeaderItem,
              styles.newsItemHeaderItemDate,
              { [styles.newsImportantColor]: isImportant }
            )}
          >
            {dateFormatted}
          </div>
        </div>
      </div>
      <div className={styles.newsItemBody}>{props.title}</div>
    </div>
  );
});
