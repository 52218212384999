import { isValid } from 'date-fns';
import { useCallback, useMemo } from 'react';

import { getDateFromAssignment } from '../lib/getDateFromAssignment';
import { useSignStore } from '../model/useSignStore';
import { useCancelSignOperation } from './useCancelSignOperation';
import { useSendAssignmentOperation } from './useSendAssignmentOperation';
import { useSignTextOperation } from './useSignTextOperation';

export function useSignDialog() {
  const [
    openDialog,
    operation,
    signText,
    signMultipleText,
    signProgress,
    signErrors,
    openSignDialog,
    closeSignOperation,
    confirmationDialog,
    closeConfirmation,
  ] = useSignStore((state) => [
    state.lkSignDialogOpen,
    state.lkSignOperation,
    state.lkSignText,
    state.lkSignMultipleText,
    state.lkSignProgress,
    state.lkSignMultipleErrors,
    state.openLkSignDialog,
    state.closeLkSignOperation,

    state.lkSignConfirmationDialog,
    state.closeLkSignConfirmation,
  ]);

  const { isFetching: textPending } = useSignTextOperation(
    operation?.url,
    openDialog
  );

  const { mutateAsync: sendAssignment, isLoading: signing } =
    useSendAssignmentOperation();

  const { mutateAsync: cancelSignOperation } = useCancelSignOperation();

  const signDate = useMemo(() => {
    if (signText) {
      return getDateFromAssignment(signText);
    }
  }, [signText]);
  const assignmentDate = signDate && isValid(signDate) ? signDate : new Date();

  const onConfirm = useCallback(() => {
    closeConfirmation();
    openSignDialog();
  }, [closeConfirmation, openSignDialog]);

  const onDecline = useCallback(() => {
    closeConfirmation();
    closeSignOperation();
  }, [closeConfirmation, closeSignOperation]);

  const onClose = useCallback(() => {
    if (operation?.url) {
      cancelSignOperation(operation.url);
    }

    closeSignOperation();
  }, [operation, cancelSignOperation, closeSignOperation]);

  const sign = async () => {
    if (signText && operation) {
      await sendAssignment({
        assignment: signText,
        messages: signMultipleText || undefined,
        operationUrl: operation.url,
      });
    }
  };

  return {
    openDialog,
    operation,
    signText,
    textPending,
    signProgress,
    signing,
    signErrors,
    confirmationDialog,

    assignmentDate,
    onConfirm,
    onDecline,
    close: onClose,
    sign,
  };
}
