import { CIQ } from 'chartiq';
import { Model } from 'flexlayout-react';
import drop from 'lodash/drop';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import isUndefined from 'lodash/isUndefined';
import merge from 'lodash/merge';
import type { PartialDeep } from 'type-fest';
import { v4 } from 'uuid';
import { GetState } from 'zustand';

import { CHART_DEFAULT_THEMES } from '../../constants/chart';
import {
  createInitialFlexLayout,
  importOptions,
  initialJson,
  initialLayoutLink,
  WidgetsNameMap,
  WidgetWithName,
} from '../../constants/Layout';
import { layoutTabWidth } from '../../constants/workspaceConfigurations';
import { move } from '../../lib/arrayMove';
import {
  linkToGroup,
  unlinkFromGroup,
  updateLinkProps,
} from '../../lib/linking';
import log from '../../lib/loglevel-mobile-debug';
import { RootStore, StoreSet } from '../useStore';
import { getNodeById, updateNodeAttributes } from './helpers/layout';
import {
  createLayoutModels,
  parseWorkspaceConfig,
  parseWorkspaceConfigs,
} from './helpers/workspaceConfigurationsHelpers';

import {
  chartCustomSettingsFields,
  ChartSetting,
  ChartThemeSettings,
  IChartCustomSettings,
  IChartEngine,
} from '../../types/chart';
import {
  LayoutModel,
  LayoutType,
  LinkName,
  Size,
  TableProps,
  WidgetLinkProps,
} from '../../types/layout';
import {
  TrackPrice,
  TrackPriceCommonSettings,
  TrackPriceSettings,
} from '../../types/trackPrice';
import { TradesFeedSettings } from '../../types/tradeFeed';
import {
  FavoritesConfiguration,
  TOrderBookFilterSetting,
  TVolumeFilterSetting,
  WorkspaceConfig,
  WorkspaceConfiguration,
  WorkspaceConfigurationLS,
  WorkspaceConfigurationsLS,
} from '../../types/workspace';

/**
 * Стор
 * */

export interface WorkspaceConfigurationsSlice {
  workspacesIsInitialized: boolean;
  workspaceConfigurations: WorkspaceConfiguration[];
  workspaceConfigurationActiveId: number;
  setActiveConfigurationById: (id: string) => void;

  workspaceConfigurationsInit: (data: WorkspaceConfigurationsLS) => void;

  manualSaveConfiguration: (id: number) => void;
  editConfiguration: (id: string, config: WorkspaceConfiguration) => void;
  renameConfiguration: (id: string, newName: string) => void;
  removeConfiguration: (key: string) => void;
  resetConfigurationById: (key: string) => void;
  addNewConfiguration: (key: string) => void;
  importConfiguration: (
    configuration: WorkspaceConfigurationLS,
    replace?: boolean
  ) => void;
  setActiveLayoutKey: (newKey: number) => void;
  setLayoutJson: <T>(key: number, newJson: LayoutModel<{ type: T }>) => void;
  updateNode: <T>(nodeId: string, config: T, symbol?: string) => void;
  createNewLayout: (layoutSize: Size, type?: LayoutType) => void;
  removeLayout: (keyToRemove: number) => void;
  setLayoutName: (key: number, value: string) => void;
  setLayoutPosition: (key: number, newPosition: number) => void;
  getActiveLayoutKey: () => number | undefined;
  getActiveLayoutType: () => LayoutType;
  linkToGroup: (
    nodeId: string,
    linkName: LinkName,
    params: WidgetLinkProps
  ) => void;
  unlinkFromGroup: (nodeId: string, linkName: LinkName) => void;

  setTradeFeedSettings: (
    idFI: number,
    nodeId?: string,
    idx?: number,
    volume?: number
  ) => void;
  setVolumeFilterSettings: (
    idFI: number,
    volumeFilters: TVolumeFilterSetting[],
    nodeId?: string
  ) => void;
  setOrderBookFilterSettings: (
    idFI: number,
    filter: TOrderBookFilterSetting,
    nodeId?: string
  ) => void;

  chartSetChartSetting: (nodeId: string, setting: ChartSetting) => void;
  chartRestoreSettings: (
    stxx?: IChartEngine,
    nodeId?: string,
    idFi?: number,
    symbol?: string,
    trueSymbol?: string
  ) => void;
  chartRestore: (stxx: IChartEngine, symbol: string, nodeId?: string) => void;
  chartSaveCustomSettings: (
    stxx?: IChartEngine,
    nodeId?: string,
    customPreferences?: Record<string, any>
  ) => void;
  chartSaveLayout: (stxx?: IChartEngine, nodeId?: string) => void;
  chartSavePreferences: (stxx?: IChartEngine, nodeId?: string) => void;
  chartSaveDrawings: (
    stxx: IChartEngine,
    symbol: string,
    nodeId?: string
  ) => void;
  chartSaveTheme: (nodeId: string, theme: string) => void;
  chartGetChartSetting: <K extends keyof ChartSetting>(
    nodeId: string,
    setting: K
  ) => ChartSetting[K] | undefined;
  chartDeleteChartSetting: (nodeId: string) => void;

  createNewConfiguration: (
    config: WorkspaceConfiguration,
    cb?: () => void
  ) => void;

  setTrackPriceDefaultSettings: (settings: TrackPriceCommonSettings) => void;
  setTrackPriceSettings: (
    trackPrice: TrackPrice,
    settings: TrackPriceSettings
  ) => void;

  setFavorites: (favorites: FavoritesConfiguration) => void;
  setChartThemes: (themes: ChartThemeSettings[]) => void;
}

const copyHardConfig = (config: WorkspaceConfig) => {
  const withOutModels = {
    ...config,
    models: undefined,
  };

  const copiedObject: WorkspaceConfig = JSON.parse(
    JSON.stringify(withOutModels)
  );

  const newObjectWithModels: WorkspaceConfig = {
    ...copiedObject,
    models: createLayoutModels(copiedObject.layouts),
  };

  return newObjectWithModels;
};

function resolveSameNameCounter(
  configurations: WorkspaceConfiguration[],
  newName
) {
  let number = 0;
  let uniqueName = newName;

  while (true) {
    // eslint-disable-next-line no-loop-func
    const has = configurations.some((config) => config.name === uniqueName);

    if (has) {
      uniqueName = `${newName} (${++number})`;
    } else {
      return uniqueName;
    }
  }
}

export const createWorkspaceConfigurationsSlice = (
  set: StoreSet,
  get: GetState<RootStore>
): WorkspaceConfigurationsSlice => ({
  workspacesIsInitialized: false,
  workspaceConfigurations: [],
  workspaceConfigurationActiveId: 0,

  workspaceConfigurationsInit: ({
    workspaceConfigurations,
    workspaceConfigurationActiveId,
  }: WorkspaceConfigurationsLS) => {
    const wcWithModels = parseWorkspaceConfigs(workspaceConfigurations);

    set((state) => {
      state.workspacesIsInitialized = true;
      state.workspaceConfigurations = wcWithModels;
      state.workspaceConfigurationActiveId = workspaceConfigurationActiveId;
    });
  },
  editConfiguration: (id: string, config: WorkspaceConfiguration) => {
    set((state) => {
      const index = get().workspaceConfigurations.findIndex(
        (config) => config.id === id
      );

      if (index === -1) {
        log.error(`editConfiguration error id=${id}`);

        return;
      }

      state.workspaceConfigurations[index] = {
        ...config,
        id: state.workspaceConfigurations[index].id,
      };
      state.workspaceConfigurationActiveId = index;
    });
  },
  renameConfiguration: (id: string, newName: string) => {
    set((state) => {
      const countedName = resolveSameNameCounter(
        get().workspaceConfigurations.filter((c) => c.id !== id),
        newName
      );

      const idx = get().workspaceConfigurations.findIndex(
        (config) => config.id === id
      );

      if (idx === -1) {
        log.error(`resetConfiguration error id=${id}`);

        return;
      }

      state.workspaceConfigurations[idx].name = countedName;
    });
  },
  removeConfiguration: (id: string) => {
    set((state) => {
      const idx = get().workspaceConfigurations.findIndex(
        (config) => config.id === id
      );

      if (idx === -1) {
        log.error(`resetConfiguration error idx=${idx}`);

        return;
      }

      state.workspaceConfigurations.splice(idx, 1);

      if (idx <= get().workspaceConfigurationActiveId) {
        state.workspaceConfigurationActiveId =
          get().workspaceConfigurationActiveId - 1;
      }
    });
  },
  resetConfigurationById: (configId: string) => {
    set((state) => {
      const id = get().workspaceConfigurations.findIndex(
        (config) => config.id === configId
      );

      if (id === -1) {
        log.error(`resetConfiguration error id=${id} name=${configId}`);

        return;
      }

      state.workspaceConfigurations[id].lastAutoSavedConfig = copyHardConfig(
        get().workspaceConfigurations[id].lastManualSavedConfig
      );
    });
  },
  setActiveConfigurationById: (id: string) => {
    set((state) => {
      const idx = get().workspaceConfigurations.findIndex(
        (config) => config.id === id
      );

      if (idx === -1) {
        log.error(`setActiveConfigurationById error id=${id}`);

        return;
      }

      state.workspaceConfigurationActiveId = idx;
    });
  },
  manualSaveConfiguration: (id: number) => {
    set((state) => {
      const { lastAutoSavedConfig } = get().workspaceConfigurations[id];

      state.workspaceConfigurations[id].lastManualSavedConfig = {
        ...lastAutoSavedConfig,
        timeStamp: Date.now(),
      };
    });
  },
  addNewConfiguration: (key: string) => {
    set((state) => {
      const currentConfiguration =
        get().workspaceConfigurations[get().workspaceConfigurationActiveId]
          .lastAutoSavedConfig;

      const keyId = get().workspaceConfigurations.findIndex(
        ({ name }) => name === key
      );
      const isKeyExist = keyId !== -1;

      const newActiveId = isKeyExist
        ? keyId
        : get().workspaceConfigurations.length;

      const newConfig = copyHardConfig({
        ...currentConfiguration,
        timeStamp: Date.now(),
      });

      state.workspaceConfigurations[newActiveId] = {
        name: key,
        type: 'custom',
        id: v4(),
        lastAutoSavedConfig: newConfig,
        lastManualSavedConfig: newConfig,
      };
      state.workspaceConfigurationActiveId = newActiveId;
    });
  },
  importConfiguration: (
    configuration: WorkspaceConfigurationLS,
    replace: boolean = false
  ) => {
    const configWithModels = parseWorkspaceConfig(configuration);
    const otherConfigs = replace
      ? get().workspaceConfigurations.filter(
          (config) => config.id !== configuration.id
        )
      : get().workspaceConfigurations;

    const countedConfig = {
      ...configWithModels,
      name: resolveSameNameCounter(otherConfigs, configWithModels.name),
    };

    set((state) => {
      state.workspaceConfigurations = [countedConfig, ...otherConfigs];
      state.workspaceConfigurationActiveId = 0;
    });
  },
  setActiveLayoutKey: (newKey: number) => {
    set((state) => {
      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig.activeLayoutKey = newKey;
    });
  },
  setLayoutJson: (key, newJson) =>
    set((state) => {
      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig.layouts[key] = newJson;
    }),
  updateNode: (nodeId, config, symbol) =>
    set((state) => {
      const activeLayoutKey = get().getActiveLayoutKey();

      if (activeLayoutKey === undefined) {
        return;
      }

      const currentWorkspaceConfig =
        get().workspaceConfigurations[get().workspaceConfigurationActiveId]
          .lastAutoSavedConfig;

      const layout = currentWorkspaceConfig.layouts[activeLayoutKey];
      const model = currentWorkspaceConfig.models[activeLayoutKey];

      let {
        config: prevConfig,
        name,
        widget,
      } = getNodeById(layout, model, nodeId);

      const tableProps: TableProps = {
        ...prevConfig?.tableProps,
        //@ts-expect-error
        ...config.tableProps,
      };

      const newConfig = {
        ...prevConfig,
        ...config,
        tableProps,
      };

      //Если виджет привязан к группе линковки, то обновляем все виджеты в группе
      if (
        !isUndefined(prevConfig?.link) &&
        !isUndefined(newConfig?.link) &&
        isEqual(prevConfig?.link, newConfig.link) &&
        //Обновляем только управляемые пропсы
        (!isUndefined(
          (config as unknown as WidgetLinkProps).tableProps?.accounts
        ) ||
          !isUndefined((config as unknown as WidgetLinkProps).idFi) ||
          !isUndefined((config as unknown as WidgetLinkProps).symbol) ||
          !isUndefined((config as unknown as WidgetLinkProps).linkedPrice))
      ) {
        const layoutLinks = currentWorkspaceConfig.layoutLinks;

        const { newLayout, newLayoutLinks } = updateLinkProps(
          activeLayoutKey,
          newConfig.link,
          layoutLinks,
          config as unknown as WidgetLinkProps,
          layout,
          model,
          symbol
        );

        state.workspaceConfigurations[
          get().workspaceConfigurationActiveId
        ].lastAutoSavedConfig.layoutLinks = newLayoutLinks;

        // Обновляем JSON только если это новый рабочий стол, старый обновит себя сам через Actions и onModelChange
        if (newLayout) {
          state.workspaceConfigurations[
            get().workspaceConfigurationActiveId
          ].lastAutoSavedConfig.layouts[activeLayoutKey] = newLayout;
        }
      } else {
        if (symbol && widget && WidgetWithName.includes(widget)) {
          name = WidgetsNameMap.get(widget) || 'Вкладка';
        }

        const newLayout = updateNodeAttributes(
          {
            name,
            config: newConfig,
          },
          nodeId,
          model,
          layout
        );

        // Обновляем JSON только если это новый рабочий стол, старый обновит себя сам через Actions и onModelChange
        if (newLayout) {
          state.workspaceConfigurations[
            get().workspaceConfigurationActiveId
          ].lastAutoSavedConfig.layouts[activeLayoutKey] = newLayout;
        }
      }
    }),
  createNewLayout: (layoutSize, type) =>
    set((state) => {
      const currentWorkspaceConfig =
        get().workspaceConfigurations[get().workspaceConfigurationActiveId]
          .lastAutoSavedConfig;

      const { layouts } = currentWorkspaceConfig;
      const newLayoutKey = layouts.length;

      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig.activeLayoutKey = newLayoutKey;

      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig.layoutNames[newLayoutKey] = `Рабочий стол ${
        newLayoutKey + 1
      }`;

      if (type === LayoutType.Flex) {
        state.workspaceConfigurations[
          get().workspaceConfigurationActiveId
        ].lastAutoSavedConfig.layouts[newLayoutKey] =
          createInitialFlexLayout(layoutSize);
      } else {
        state.workspaceConfigurations[
          get().workspaceConfigurationActiveId
        ].lastAutoSavedConfig.layouts[newLayoutKey] = initialJson;

        state.workspaceConfigurations[
          get().workspaceConfigurationActiveId
        ].lastAutoSavedConfig.models[newLayoutKey] =
          Model.fromJson(initialJson);
      }

      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig.layoutLinks[newLayoutKey] = initialLayoutLink;
    }),
  removeLayout: (selectedKey: number) =>
    set((state) => {
      const currentWorkspaceConfig =
        get().workspaceConfigurations[get().workspaceConfigurationActiveId]
          .lastAutoSavedConfig;

      const { layouts, activeLayoutKey } = currentWorkspaceConfig;

      const isCurrentTabLast = activeLayoutKey === layouts.length - 1;

      // Смещаемся влево когда сидим на последней вкладке, чтобы не вылететь за пределы массива
      if (isCurrentTabLast) {
        state.workspaceConfigurations[
          get().workspaceConfigurationActiveId
        ].lastAutoSavedConfig.activeLayoutKey = activeLayoutKey - 1;
      }

      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig.layouts.splice(selectedKey, 1);

      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig.layoutNames.splice(selectedKey, 1);

      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig.layoutLinks.splice(selectedKey, 1);

      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig.models.splice(selectedKey, 1);
    }),
  setLayoutName: (key, value) => {
    set((state) => {
      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig.layoutNames[key] = value;
    });
  },
  setLayoutPosition: (key, positionChange) =>
    set((state) => {
      const currentWorkspaceConfig =
        get().workspaceConfigurations[get().workspaceConfigurationActiveId]
          .lastAutoSavedConfig;

      const totalTabsWidth =
        currentWorkspaceConfig.models.length * layoutTabWidth;
      const startPosition = key * layoutTabWidth;
      const newPosition = startPosition + positionChange;
      let newIndex;

      if (newPosition < 0) {
        newIndex = 0;
      } else if (newPosition > totalTabsWidth) {
        newIndex = currentWorkspaceConfig.models.length - 1;
      } else {
        newIndex =
          positionChange > 0
            ? Math.floor(newPosition / layoutTabWidth)
            : Math.ceil(newPosition / layoutTabWidth);
      }

      if (newIndex === key) {
        return;
      }

      move(currentWorkspaceConfig.layouts, key, newIndex);
      move(currentWorkspaceConfig.models, key, newIndex);
      move(currentWorkspaceConfig.layoutNames, key, newIndex);
      move(currentWorkspaceConfig.layoutLinks, key, newIndex);

      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig = {
        ...currentWorkspaceConfig,
        activeLayoutKey: newIndex,
      };
    }),
  getActiveLayoutKey: () => {
    if (!get().workspacesIsInitialized) {
      return;
    }

    const currentWorkspaceConfig =
      get().workspaceConfigurations[get().workspaceConfigurationActiveId]
        ?.lastAutoSavedConfig;

    return currentWorkspaceConfig?.activeLayoutKey;
  },
  getActiveLayoutType: () => {
    if (get().workspaceConfigurations.length) {
      const currentWorkspaceConfig =
        get().workspaceConfigurations[get().workspaceConfigurationActiveId]
          .lastAutoSavedConfig;
      const activeLayoutKey = currentWorkspaceConfig.activeLayoutKey;
      const activeLayout = currentWorkspaceConfig.layouts[activeLayoutKey];

      return activeLayout.type ?? LayoutType.Grid;
    }

    return LayoutType.Grid;
  },
  linkToGroup: (
    nodeId: string,
    linkName: LinkName,
    params: WidgetLinkProps | null
  ) => {
    const activeLayoutKey = get().getActiveLayoutKey();

    if (activeLayoutKey === undefined) {
      return;
    }

    const layoutLinks =
      get().workspaceConfigurations[get().workspaceConfigurationActiveId]
        .lastAutoSavedConfig.layoutLinks;
    const updateNode = get().updateNode;

    const newLayoutLinks = linkToGroup(
      nodeId,
      activeLayoutKey,
      linkName,
      layoutLinks,
      updateNode,
      params
    );

    set((state) => {
      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig.layoutLinks = newLayoutLinks;
    });
  },
  unlinkFromGroup: (nodeId: string, linkName: LinkName) => {
    set((state) => {
      const activeLayoutKey = get().getActiveLayoutKey();

      if (activeLayoutKey === undefined) {
        return;
      }

      const layoutLinks =
        get().workspaceConfigurations[get().workspaceConfigurationActiveId]
          .lastAutoSavedConfig.layoutLinks;
      const updateNode = get().updateNode;
      const newLayoutLinks = unlinkFromGroup(
        nodeId,
        activeLayoutKey,
        linkName,
        layoutLinks,
        updateNode
      );

      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig = {
        ...get().workspaceConfigurations[get().workspaceConfigurationActiveId]
          .lastAutoSavedConfig,
        layoutLinks: newLayoutLinks,
      };
    });
  },

  setTradeFeedSettings: (
    idFI: number,
    nodeId?: string,
    idx?: number,
    volume?: number
  ) => {
    set((state) => {
      const currentWorkspaceConfig =
        get().workspaceConfigurations[get().workspaceConfigurationActiveId]
          .lastAutoSavedConfig;

      let tradesFeedsSettings = currentWorkspaceConfig.tradesFeedsSettings;

      tradesFeedsSettings ??= {};

      if (!tradesFeedsSettings[idFI]) {
        tradesFeedsSettings = {
          ...tradesFeedsSettings,
          [idFI]: [{ volume: 0 }, { volume: 0 }, { volume: 0 }],
        };
      }

      const newSettings = tradesFeedsSettings[idFI].map<TradesFeedSettings>(
        (item) => {
          if (item.nodeId === nodeId) {
            return { volume: item.volume };
          }

          return item;
        }
      );

      if (idx !== undefined && volume !== undefined) {
        newSettings[idx] = {
          volume,
          nodeId,
        };
      }

      tradesFeedsSettings = {
        ...tradesFeedsSettings,
        [idFI]: newSettings,
      };

      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig.tradesFeedsSettings = tradesFeedsSettings;
    });
  },

  setVolumeFilterSettings: (
    idFI: number,
    volumeFilters: TVolumeFilterSetting[]
  ) => {
    set((state) => {
      const currentWorkspaceConfig =
        get().workspaceConfigurations[get().workspaceConfigurationActiveId]
          .lastAutoSavedConfig;

      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig.volumeFiltersSettings = {
        ...(currentWorkspaceConfig.volumeFiltersSettings || {}),
        [idFI]: volumeFilters,
      };
    });
  },
  setOrderBookFilterSettings: (
    idFI: number,
    filter: TOrderBookFilterSetting
  ) => {
    set((state) => {
      const currentWorkspaceConfig =
        get().workspaceConfigurations[get().workspaceConfigurationActiveId]
          .lastAutoSavedConfig;

      state.workspaceConfigurations[
        get().workspaceConfigurationActiveId
      ].lastAutoSavedConfig.orderBookFiltersSettings = {
        ...(currentWorkspaceConfig.orderBookFiltersSettings || {}),
        [idFI]: filter,
      };
    });
  },

  chartSetChartSetting: (nodeId: string, setting: ChartSetting) => {
    set((state) => {
      const workspaceConfigurationActiveId =
        state.workspaceConfigurationActiveId;
      const workspaceConfiguration =
        state.workspaceConfigurations[workspaceConfigurationActiveId];

      const chartStorage = workspaceConfiguration.lastAutoSavedConfig.chart;
      const prevSettings = chartStorage[nodeId];

      state.workspaceConfigurations[
        workspaceConfigurationActiveId
      ].lastAutoSavedConfig.chart[nodeId] = {
        ...prevSettings,
        ...setting,
        customSettings: {
          panels:
            setting.customSettings?.panels ||
            prevSettings?.customSettings?.panels,
          ...chartCustomSettingsFields.reduce<IChartCustomSettings>(
            (newSettings, field) => {
              return {
                ...newSettings,
                [field]: setting?.customSettings?.hasOwnProperty(field)
                  ? setting.customSettings?.[field]
                  : prevSettings?.customSettings?.[field],
              };
            },
            {}
          ),
          drawingsDefaultSettings: {
            ...prevSettings?.customSettings?.drawingsDefaultSettings,
            ...setting.customSettings?.drawingsDefaultSettings,
          },
        },
      };
    });
  },

  chartRestoreSettings: (
    stxx?: IChartEngine,
    nodeId?: string,
    idFi?: number,
    symbol?: string,
    trueSymbol?: string
  ) => {
    const currentWorkspaceConfig =
      get().workspaceConfigurations[get().workspaceConfigurationActiveId]
        .lastAutoSavedConfig;

    // Если у нас нет необходимых данных для восстановления -> early return
    if (!stxx || !nodeId || !idFi || !symbol) {
      return;
    }

    const savedLayout: any =
      Boolean(nodeId) && currentWorkspaceConfig.chart[nodeId]?.layout;
    const pref =
      Boolean(nodeId) && currentWorkspaceConfig.chart[nodeId]?.preferences;
    const chartConfig = savedLayout?.symbols[0]?.symbolObject;
    const isDifferentFis =
      chartConfig &&
      !(idFi === chartConfig?.id && symbol === chartConfig?.symbol);

    let newLayout = savedLayout;

    if (isDifferentFis && savedLayout) {
      // Обновляем инструмент, сохранив настройки графика для окна
      const newSymbols = {
        ...savedLayout.symbols[0],
        symbol,
        symbolObject: {
          periodicity: { ...savedLayout.symbols[0].periodicity },
          id: idFi,
          symbol: symbol,
          trueSymbol: trueSymbol ?? symbol,
        },
      };

      newLayout = {
        ...savedLayout,
        symbols: [newSymbols, ...drop(savedLayout.symbols, 1)],
      };

      get().chartSetChartSetting(nodeId, {
        layout: newLayout,
      });
    }

    if (newLayout) {
      stxx.importLayout(newLayout, importOptions);
      stxx.zoomSet?.(newLayout.candleWidth, stxx.chart);
    }

    if (pref) {
      stxx.importPreferences(pref);
    }
  },

  chartRestore: (stxx: IChartEngine, symbol: string, nodeId?: string) => {
    const currentChartsConfig =
      get().workspaceConfigurations[get().workspaceConfigurationActiveId]
        .lastAutoSavedConfig.chart;

    if (stxx && nodeId) {
      const customSettings = currentChartsConfig[nodeId]?.customSettings;

      if (customSettings?.panels) {
        Object.entries(customSettings.panels).forEach(([panelName, object]) => {
          if (stxx?.panels && stxx?.panels[panelName]) {
            // stxx.modifyPanel - почему-то не работает
            // @ts-expect-error
            stxx.panels[panelName].yAxis.zoom = object.yAxis.zoom;
          }
        });
      }

      const drawings =
        Boolean(nodeId) && currentChartsConfig[nodeId]?.drawings?.[symbol];

      if (drawings && isArray(drawings) && !stxx.drawingObjects?.length) {
        stxx.importDrawings(drawings);
        stxx.draw();
      }

      const symbols =
        // @ts-expect-error
        currentChartsConfig[nodeId]?.layout?.symbols?.slice(1) || [];

      symbols.forEach((s, i) => {
        const seriesList = Object.keys(
          stxx?.panels?.chart?.chart?.series || []
        ).filter((symbol) => !symbols.find(({ id }) => id === symbol));

        stxx?.addSeries(s.id, {
          isComparison: true,
          shareYAxis: true,
          color:
            CIQ.UI.defaultSwatchColors[
              (seriesList.length % CIQ.UI.defaultSwatchColors.length) + i
            ],
          width: 0.5,
          gapDisplayStyle: 'true',
          symbolObject: s.symbolObject,
        });
      });

      const theme = currentChartsConfig[nodeId]?.theme || '';

      if (CHART_DEFAULT_THEMES.includes(theme)) {
        stxx?.themes?.setDefaultTheme?.(theme);
      } else if (
        Object.keys(stxx?.themes?.params.customThemes || {}).includes(theme)
      ) {
        stxx?.themes?.setCustomTheme?.(theme);
      }
    }
  },

  chartSaveCustomSettings: (
    stxx?: IChartEngine,
    nodeId?: string,
    customPreferences?: Record<string, any>
  ) => {
    if (stxx && nodeId && stxx.panels) {
      const customSettings = Object.values<CIQ.ChartEngine.Panel>(
        stxx.panels
      ).reduce(
        (acc: IChartCustomSettings, panel) => {
          if (!acc.panels) {
            acc.panels = {};
          }

          if (!panel.name) {
            return acc;
          }

          acc.panels[panel.name] = {
            name: panel.name,
            yAxis: { zoom: panel.yAxis?.zoom || 0 },
          };

          return acc;
        },
        { panels: {}, ...customPreferences }
      );

      get().chartSetChartSetting(nodeId, {
        customSettings,
      });
    }
  },

  chartSaveLayout: (stxx?: IChartEngine, nodeId?: string) => {
    if (stxx && nodeId) {
      get().chartSetChartSetting(nodeId, {
        layout: stxx.exportLayout(true),
      });
    }
  },

  chartSavePreferences: (stxx?: IChartEngine, nodeId?: string) => {
    if (stxx && nodeId) {
      get().chartSetChartSetting(nodeId, {
        preferences: stxx.exportPreferences(),
      });
    }
  },

  chartSaveDrawings: (stxx: IChartEngine, symbol: string, nodeId?: string) => {
    const currentWorkspaceConfig =
      get().workspaceConfigurations[get().workspaceConfigurationActiveId]
        .lastAutoSavedConfig;

    if (stxx && nodeId) {
      const prevDrawings = currentWorkspaceConfig.chart[nodeId]?.drawings;
      const drawings =
        prevDrawings && !isEmpty(prevDrawings) && isObject(prevDrawings)
          ? { ...(prevDrawings as object), [symbol]: stxx.exportDrawings() }
          : { [symbol]: stxx.exportDrawings() };

      get().chartSetChartSetting(nodeId, {
        drawings,
        layout: stxx.exportLayout(true),
      });
    }
  },

  chartSaveTheme: (nodeId: string, theme: string) => {
    if (nodeId) {
      get().chartSetChartSetting(nodeId, {
        theme,
      });
    }
  },

  chartGetChartSetting: <K extends keyof ChartSetting>(
    nodeId: string,
    setting: K
  ): ChartSetting[K] | undefined => {
    const currentWorkspaceConfig =
      get().workspaceConfigurations[get().workspaceConfigurationActiveId]
        .lastAutoSavedConfig;
    const chartStorage = currentWorkspaceConfig.chart;

    return chartStorage[nodeId]?.[setting];
  },

  chartDeleteChartSetting: (nodeId: string) => {
    set((state) => {
      delete state.workspaceConfigurations[get().workspaceConfigurationActiveId]
        .lastAutoSavedConfig.chart[nodeId];
    });
  },
  createNewConfiguration(
    config: WorkspaceConfiguration,
    callback?: () => void
  ) {
    set((state) => {
      const keyId = get().workspaceConfigurations.findIndex(
        ({ id }) => id === config.id
      );
      const isKeyExist = keyId !== -1;

      const newActiveId = isKeyExist
        ? keyId
        : get().workspaceConfigurationActiveId + 1;

      state.workspaceConfigurations[newActiveId] = {
        name: config.name,
        id: config.id,
        type: config.type,
        lastAutoSavedConfig: {
          ...config.lastAutoSavedConfig,
          models: createLayoutModels(config.lastAutoSavedConfig.layouts),
        },
        lastManualSavedConfig: {
          ...config.lastAutoSavedConfig,
          models: createLayoutModels(config.lastAutoSavedConfig.layouts),
        },
      };

      state.workspaceConfigurationActiveId = newActiveId;

      if (callback) {
        callback();
      }
    });
  },

  setTrackPriceDefaultSettings(settings: TrackPriceCommonSettings) {
    set((state) => {
      const activeId = get().workspaceConfigurationActiveId;

      merge<WorkspaceConfiguration, PartialDeep<WorkspaceConfiguration>>(
        state.workspaceConfigurations[activeId],
        {
          lastAutoSavedConfig: {
            trackPriceSettings: {
              defaultSettings: settings,
            },
          },
        }
      );
    });
  },

  setTrackPriceSettings(trackPrice: TrackPrice, settings: TrackPriceSettings) {
    set((state) => {
      const id = trackPrice.IdTrackPrice.toString();
      const activeId = get().workspaceConfigurationActiveId;

      merge<WorkspaceConfiguration, PartialDeep<WorkspaceConfiguration>>(
        state.workspaceConfigurations[activeId],
        {
          lastAutoSavedConfig: {
            trackPriceSettings: {
              settingsByTrackPriceId: {
                [id]: settings,
              },
            },
          },
        }
      );
    });
  },

  setFavorites(favorites: FavoritesConfiguration) {
    set((state) => {
      const activeId = get().workspaceConfigurationActiveId;

      state.workspaceConfigurations[activeId].lastAutoSavedConfig.favorites = {
        ...(state.workspaceConfigurations[activeId].lastAutoSavedConfig
          .favorites || {}),
        ...favorites,
      };
    });
  },

  setChartThemes(themes) {
    set((state) => {
      const activeId = get().workspaceConfigurationActiveId;

      state.workspaceConfigurations[activeId].lastAutoSavedConfig.chartThemes =
        themes;
    });
  },
});
