import cn from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useAlfaDirectContext, useFullFI } from '@terminal/alfadirect/hooks';
import { OrderType } from '@terminal/core/lib/client/entities';
import { useTradingService } from '@terminal/core/lib/services/trading/useTradingService';
import {
  HybridOrderProps,
  TradingLimitsShowMode,
} from '@terminal/core/types/layout';
import { OrderSide, SendOrderParams } from '@terminal/core/types/trading';
import { NotificationType } from '@terminal/core/types/ui';

import { OrderSource, useOrderAnalyticsMetrics } from '../../../entities/Order';
import { TradingCertificateLock } from '../../../features/TradingCertificateLock';
import { useWidgetContext } from '../../../shared';
import { TradingContextProvider } from '../context';
import { FormType } from '../model';
import { Header, OrdersInfo } from '../ui';
import { WidgetForm } from '../ui/WidgetForm';

import { useSelectedAccount } from '../../../shared/hooks/useSelectedAccount';
import { useHybridOrderAnalyticsMetrics, useTradingFormOrder } from '../hooks';

import styles from './HybridOrder.module.css';

export const HybridOrder = (props: HybridOrderProps) => {
  const {
    nodeId,
    idFi,
    tableProps,
    hideActiveOrders,
    linkedPrice,
    limitsProps,
  } = props;
  const { addNotification, onWidgetLoad, getNode } = useWidgetContext();
  const { useFinInfoExt } = useAlfaDirectContext();

  const fullFi = useFullFI(idFi);

  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
  const [isLoadingCancelModal, setIsLoadingCancelModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isLoadingEditModal, setIsLoadingEditModal] = useState(false);
  const [isOpenLimitsModal, setIsOpenLimitsModal] = useState(false);

  const [secondPrice, setSecondPrice] = useState(0);
  const [priceTouched, setPriceTouched] = useState(false);

  const finInfoExtRecord = useFinInfoExt(idFi);
  const finInfoExt = finInfoExtRecord[idFi];

  const nodeWidth = getNode(nodeId || '')?.getRect().width || 0;

  const { selectedAccount, selectedSubAccounts, selectedSubAccountId } =
    useSelectedAccount(tableProps);
  const selectedSubAccount = useMemo(
    () => [selectedSubAccounts[0]],
    [selectedSubAccounts]
  );

  const { trackErrorOrder, trackSuccessOrder } = useOrderAnalyticsMetrics(idFi);

  //Колбэки на успех и фейл
  const onOrderResponse = useCallback(
    (_, params?: SendOrderParams) => {
      if (params) {
        trackSuccessOrder(OrderSource.HYBRID_ORDER, params);
      }

      setIsOpenCancelModal(false);
      setIsLoadingCancelModal(false);
      setIsOpenEditModal(false);
      setIsLoadingEditModal(false);
    },
    [trackSuccessOrder]
  );
  const onOrderError = useCallback(
    (message: string, params?: SendOrderParams) => {
      if (params) {
        trackErrorOrder(OrderSource.HYBRID_ORDER, params, undefined, message);
      }

      addNotification({
        type: NotificationType.TRADE,
        badge: 'negative',
        title: 'Ошибка',
        text: message,
      });

      setIsOpenCancelModal(false);
      setIsLoadingCancelModal(false);
      setIsOpenEditModal(false);
      setIsLoadingEditModal(false);
    },
    [addNotification, trackErrorOrder]
  );

  const { sendOrder, replaceOrder, cancelOrder } = useTradingService(
    onOrderError,
    onOrderResponse
  );

  const {
    side,
    orderType,
    price,
    slippage,
    quantity,
    last,
    currentAdditionalInstructions,
    lifeTime,
    activation,
    sendOrderParams,
    setQuantity,
    setPrice,
    setSlippage,
    setSide,
    setOrderType,
    setCurrentAdditionalInstructions,
    setLifeTime,
    setActivation,
    sendOrder: handleSendOrder,
    volumeType,
    setVolumeType,
    computedQuantity,
  } = useTradingFormOrder({
    idFi,
    selectedAccountId: selectedAccount?.idAccount,
    fullFI: fullFi,
    finInfoExt,
    defaultOrderType: OrderType.LMT,
    sendOrder,
    defaultSide: OrderSide.BUY,
    selectedSubAccountId,
    secondPrice,
    setSecondPrice,
    formType: FormType.WIDGET,
  });

  useEffect(() => {
    // Дефолтная цена при маунте компонента: либо 0 либо читаем из параметров
    const initPrice = linkedPrice;

    if (initPrice) {
      setPrice(initPrice);
      setPriceTouched(true);
    }

    if (orderType !== OrderType.BRS && orderType !== OrderType.TBRS) {
      setSecondPrice(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idFi, linkedPrice, setPrice, setSecondPrice]);

  useEffect(() => {
    if (nodeId) {
      onWidgetLoad(nodeId);
    }
  }, [nodeId, onWidgetLoad]);

  const { trackHybridOrderSettings } = useHybridOrderAnalyticsMetrics();

  useEffect(() => {
    trackHybridOrderSettings({
      hideActiveOrders,
      showPosition: limitsProps?.showPosition ?? true,
      showLimits: limitsProps?.showLimits ?? TradingLimitsShowMode.MONEY,
      showNPU: limitsProps?.showNPU ?? TradingLimitsShowMode.MONEY,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackHybridOrderSettings]);

  return (
    <TradingContextProvider
      idFi={idFi}
      selectedAccount={selectedAccount}
      selectedSubAccountId={selectedSubAccountId}
      side={side}
      setSide={setSide}
      orderType={orderType}
      setOrderType={setOrderType}
      price={price}
      setPrice={setPrice}
      priceTouched={priceTouched}
      setPriceTouched={setPriceTouched}
      secondPrice={secondPrice}
      setSecondPrice={setSecondPrice}
      slippage={slippage}
      setSlippage={setSlippage}
      last={last}
      quantity={quantity}
      setQuantity={setQuantity}
      lifeTime={lifeTime}
      setLifeTime={setLifeTime}
      activation={activation}
      setActivation={setActivation}
      currentAdditionalInstructions={currentAdditionalInstructions}
      setCurrentAdditionalInstructions={setCurrentAdditionalInstructions}
      sendOrderParams={sendOrderParams}
      sendOrder={handleSendOrder}
      currencyCode={fullFi?.currencyCode}
      volumeType={volumeType}
      setVolumeType={setVolumeType}
      computedQuantity={computedQuantity}
    >
      <div className={styles.container}>
        <Header
          nodeId={nodeId}
          idFi={idFi}
          selectedSubAccounts={selectedSubAccount}
          hideActiveOrders={hideActiveOrders}
          setIsOpenLimitsModal={setIsOpenLimitsModal}
        />
        <TradingCertificateLock size={nodeWidth < 240 ? 'xs' : 's'}>
          <div className={cn(styles.content)}>
            <div
              id={`trading-limits-${nodeId?.slice(1)}`}
              className={styles.limitsPortal}
            />
            <OrdersInfo
              finInfoExt={finInfoExt}
              hideActiveOrders={hideActiveOrders}
              replaceOrder={replaceOrder}
              cancelOrder={cancelOrder}
              isLoadingCancelModal={isLoadingCancelModal}
              setIsLoadingCancelModal={setIsLoadingCancelModal}
              isLoadingEditModal={isLoadingEditModal}
              setIsLoadingEditModal={setIsLoadingEditModal}
              isOpenCancelModal={isOpenCancelModal}
              setIsOpenCancelModal={setIsOpenCancelModal}
              isOpenEditModal={isOpenEditModal}
              setIsOpenEditModal={setIsOpenEditModal}
              idObjectGroup={fullFi?.idObjectGroup}
            />
            <WidgetForm
              nodeId={nodeId}
              fullFi={fullFi}
              finInfoExt={finInfoExt}
              selectedSubAccounts={selectedSubAccounts}
              isOpenLimitsModal={isOpenLimitsModal}
              setIsOpenLimitsModal={setIsOpenLimitsModal}
              limitsProps={limitsProps}
            />
          </div>
        </TradingCertificateLock>
      </div>
    </TradingContextProvider>
  );
};
