import { TableColumnSetting, Widget } from '../../types/layout';
import { TableColumnKey } from '../../types/tableColumn';

export function getIsColumnStatic(
  column?: TableColumnSetting,
  widgetType?: Widget
): boolean {
  return (
    column?.key === TableColumnKey.SymbolObject ||
    column?.key === TableColumnKey.NameObject ||
    column?.key === TableColumnKey.BsName ||
    (column?.key === TableColumnKey.IdAccount && widgetType === Widget.BALANCE)
  );
}
