// Апи предназначенное для предоставления отладочных данных в консоль браузера
// нужно для траблшутинга
import log from 'loglevel';

import { APP_VERSION } from '../../env';
import { decode } from '../client/decode';
import {
  ClientMessage,
  ClientMessageEntity,
  MessageUnitedType,
  OrderBookEntity,
  OrderBookWithYieldEntity,
} from '../client/entities';
import { EntityType } from '../client/entityTypes';
import AdirClientService from '../client/service';
import { getMockData } from './getMockData';
import { OrderBookService, orderBookService } from './orderbook';
import { QuotesService } from './quotes';
import { SubscribeReturnData } from './streaming';
import { TradesService } from './trades';

import { useStore } from '../../store';

import { OrderBookLine } from '../../types/orderBook';
import { FinInfo } from '../../types/quotes';
import { SubscriberHandle, TradeInfo } from '../../types/TradesService';

enum EntityToSubscribe {
  OrderBook = 'orderbook',
  FinInfo = 'fininfo',
  Trades = 'trades',
}

// хранит номер фронтэндов
const feNumbers = new Map<string, string>();

function onClientMessageEntity(message: MessageUnitedType) {
  const messages = message.data as ClientMessageEntity[];
  const frontend = message.frontend;

  messages.forEach((m) => {
    if (m.MessageId === ClientMessage.FrontEndAuthenticationSuccess) {
      feNumbers.set(String(frontend), String(m.Objects[0]));
    }
  });
}

function getFENumbers() {
  for (const [key, val] of feNumbers) {
    log.info(`fe=${key} number=${val}`);
  }
}

function getSpravochnikiSize() {
  const store = useStore.getState();
  const objects = store.objects;
  const fi = store.finInstruments;

  log.info(`Total objects: ${objects.length} Total FI: ${fi.length}`);
}

function getStore() {
  return useStore.getState();
}

const quotesService = new QuotesService();

// храним подписки
const orderbooks = new Map<
  number,
  [
    OrderBookService,
    SubscribeReturnData<OrderBookEntity | OrderBookWithYieldEntity>
  ]
>();
const finInfos = new Map<number, [Partial<FinInfo>, () => void]>();
const trades = new Map<number, [TradesService, SubscriberHandle]>();

function subscribeToEntity(entityType: EntityToSubscribe, idFI: number) {
  switch (entityType) {
    case EntityToSubscribe.OrderBook: {
      if (!orderbooks.has(idFI)) {
        const service = orderBookService;
        const subscription = service.subscribe(idFI);

        service.on('update', (lines: OrderBookLine[]) => {
          // eslint-disable-next-line
          console.dir(lines);
        });
        orderbooks.set(idFI, [service, subscription]);
      }

      break;
    }
    case EntityToSubscribe.FinInfo: {
      if (!finInfos.has(idFI)) {
        const finInfoObj: Partial<FinInfo> = {};
        const subscription = quotesService.subscribeToFI(
          idFI,
          (message: Record<string, Partial<FinInfo>>) => {
            if (message[idFI]) {
              Object.assign(finInfoObj, message[idFI]);
              // eslint-disable-next-line
              console.dir(finInfoObj);
            }
          }
        );

        finInfos.set(idFI, [finInfoObj, subscription]);
      }

      break;
    }

    case EntityToSubscribe.Trades: {
      if (!trades.has(idFI)) {
        const service = new TradesService();
        const unsubscribe = service.subscribeToFI(idFI, (data: TradeInfo[]) => {
          // eslint-disable-next-line
          console.dir(data);
        });

        trades.set(idFI, [service, unsubscribe]);
      }

      break;
    }
  }
}

function unsubscribeToEntity(entityType: EntityToSubscribe, idFI: number) {
  switch (entityType) {
    case EntityToSubscribe.OrderBook: {
      if (orderbooks.has(idFI)) {
        const [service, subscription] = orderbooks.get(idFI)!;

        service?.unsubscribe(idFI, subscription);
        orderbooks.delete(idFI);
      }

      break;
    }
    case EntityToSubscribe.FinInfo: {
      if (finInfos.has(idFI)) {
        const [, unsubscribe] = finInfos.get(idFI)!;

        unsubscribe();
      }

      break;
    }
    case EntityToSubscribe.Trades: {
      if (trades.has(idFI)) {
        const [service, subscription] = trades.get(idFI)!;

        service.unsubscribe(idFI, subscription);
      }
    }
  }
}

export const parseHEX = (HEX: string) => {
  const preparedData = HEX.match(/[\da-f]{2}/gi)?.map(function (h) {
    return parseInt(h, 16);
  });

  if (!preparedData) {
    return null;
  }

  const typedArray = new Uint8Array(preparedData);

  return decode(typedArray.buffer);
};

class ConsoleApi extends AdirClientService {
  override init() {
    this.addClientListener(
      EntityType.ClientMessageEntity,
      onClientMessageEntity
    );

    // eslint-disable-next-line
    console.info(`%cVERSION: ${APP_VERSION}`, 'color: blue; font-size:16px;');

    // Подробнее в TROUBLESHOOTING.md
    const apiObject = {
      getAlfaDirectClient: () => this.getAlfaDirectClient(),
      getFENumbers,
      getSpravochnikiSize,
      getStore,
      subscribeToEntity,
      unsubscribeToEntity,
      getMockData,
      parseHEX,
      setPR: (prNumber: string, reload = true) => {
        // @ts-expect-error
        // cкрипт добавляется только для внутренних сборок
        window.__setPR(prNumber);
        reload && window.location.reload();
      },
    };

    window['aDebug'] = window['aDebug'] || {};
    window['aDebug'] = apiObject;
  }
  getAlfaDirectClient() {
    return this.client;
  }
}

export const ConsoleApiService = new ConsoleApi();
