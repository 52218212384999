import { useMutation } from 'react-query';

import { NotificationType } from '@terminal/core/types/ui';

import { useLkContext } from '../../shared';
import { useSignStore as useStore } from '../model/useSignStore';

import { LkSignOptions } from '../model/types/LkSignOptions';
import { SignCreated } from '../model/types/SignCreated';

interface Props<TData = void> {
  apiCall: () => Promise<SignCreated<TData>>;
  options?: LkSignOptions;
}

export function useSignApi<TData = void>({ apiCall, options }: Props<TData>) {
  const [
    openLkSignConfirmation,
    openLkSignDialog,
    setLkSignOperation,
    setLkSignOptions,
  ] = useStore((state) => [
    state.openLkSignConfirmation,
    state.openLkSignDialog,
    state.setLkSignOperation,
    state.setLkSignOptions,
  ]);

  const { addNotification } = useLkContext();

  return useMutation<SignCreated<TData>>(apiCall, {
    onError: (error: any) => {
      if (!options?.muteError) {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: options?.errorTitle || 'Ошибка',
          text: error?.message || '',
        });
      }
    },
    onSuccess: (created: SignCreated<TData>) => {
      if (created) {
        const confirmCodes = options?.confirmation?.codes || [];
        const needConfirmation = confirmCodes.indexOf(created.code) !== -1;

        if (created.code !== 0 && !needConfirmation) {
          if (options?.errorCallback) {
            options.errorCallback({
              code: created.code || 0,
              message: created.message || '',
            });
          }

          if (!options?.muteError) {
            addNotification({
              type: NotificationType.SYSTEM,
              badge: 'negative',
              title: options?.errorTitle || 'Ошибка',
              text: created.message || '',
            });
          }

          return;
        }

        if (needConfirmation) {
          openLkSignConfirmation(created.message || '');
        } else {
          openLkSignDialog();
        }

        setLkSignOperation(created);

        if (options) {
          setLkSignOptions(options);
        }
      }
    },
  });
}
