import { format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Loader } from '@alfalab/core-components/loader';
import { Typography } from '@alfalab/core-components/typography';

import { FormatedText, StaticField } from '../../../../shared';

import { useCorporateAction } from '../../hooks';

import styles from './MessageDetail.module.css';

interface MessageDetailProps {
  id: string;
}

export const MessageDetail = ({ id }: MessageDetailProps) => {
  const { data: corporateAction, isFetching: corporateActionPending } =
    useCorporateAction(id);

  if (corporateActionPending) {
    return <Loader />;
  }

  if (!corporateAction) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Typography.Text
          view="primary-small"
          className={styles.title}
          weight="bold"
          tag="div"
        >
          {corporateAction.title}
        </Typography.Text>
        <Typography.Text
          view="secondary-small"
          className={styles.date}
          color="secondary"
          tag="div"
        >
          {format(parseISO(corporateAction.date), 'd MMMM yyyy HH:mm', {
            locale: ru,
          })}
        </Typography.Text>
        <Typography.Text view="secondary-small" tag="div">
          <FormatedText text={corporateAction.content} />
        </Typography.Text>
      </div>
      {corporateAction.parameters && (
        <div className={styles.details}>
          <Typography.Text
            view="primary-small"
            className={styles.title}
            weight="bold"
            tag="div"
          >
            Информация о ценной бумаге
          </Typography.Text>
          <div className={styles.parameters}>
            {corporateAction.parameters.map((param) => (
              <StaticField
                className={styles.field}
                key={`detail-param-${param.text}`}
                label={param.text}
              >
                {param.value}
              </StaticField>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
