import React, {
  ComponentType,
  HTMLAttributes,
  ReactNode,
  SVGAttributes,
  VFC,
} from 'react';
import { Typography } from '@alfalab/core-components/typography';

import styles from './ListItem.module.css';

export type ListItemProps = HTMLAttributes<HTMLDivElement> & {
  Icon: ComponentType<SVGAttributes<SVGSVGElement>>;
  label: string;
  description: ReactNode;
};

export const ListItem: VFC<ListItemProps> = (props) => {
  const { Icon, label, description, children, ...restProps } = props;

  return (
    <div className={styles.root} {...restProps}>
      <div className={styles.iconContainer}>
        <Icon width={24} height={24} className={styles.icon} />
      </div>

      <div className={styles.info}>
        <Typography.Text
          tag="div"
          view="secondary-large"
          weight="medium"
          className={styles.title}
        >
          {label}
        </Typography.Text>
        <Typography.Text
          tag="div"
          view="secondary-small"
          color="secondary"
          className={styles.description}
        >
          {description}
        </Typography.Text>
      </div>
      {children}
    </div>
  );
};
