export const TRADE_CHART_MOEX = {
  symbol: 'IMOEX',
  idFi: 108897,
} as const;

export const TRADE_LEFT_CHART_SETTINGS = {
  customSettings: {
    panels: {
      chart: { yAxis: { zoom: 41.57196969696971 } },
      '‌rsi‌ (14,1)': { yAxis: { zoom: 3.070422535211268 } },
    },
    showOHLC: true,
    showOrders: true,
    showPositions: true,
    showDividends: true,
  },
  layout: {
    interval: 1,
    periodicity: 5,
    timeUnit: 'minute',
    candleWidth: 8.03225806451613,
    flipped: false,
    volumeUnderlay: false,
    adj: true,
    crosshair: true,
    chartType: 'colored_bar',
    extended: false,
    marketSessions: {},
    aggregationType: 'ohlc',
    chartScale: 'linear',
    panels: {
      chart: {
        percent: 0.690251572327044,
        display: 'IMOEX ММВБ Индексы',
        chartName: 'chart',
        index: 0,
        yAxis: { name: 'chart', position: null },
        yaxisLHS: [],
        yaxisRHS: ['chart'],
      },
      '‌volume‌': {
        percent: 0.10974842767295599,
        display: '‌volume‌',
        chartName: 'chart',
        index: 1,
        yAxis: { name: '‌volume‌' },
        yaxisLHS: [],
        yaxisRHS: ['‌volume‌'],
      },
      '‌rsi‌ (14,1)': {
        percent: 0.2,
        display: '‌rsi‌ (14,1)',
        chartName: 'chart',
        index: 2,
        yAxis: { name: '‌rsi‌ (14,1)' },
        yaxisLHS: [],
        yaxisRHS: ['‌rsi‌ (14,1)'],
      },
    },
    setSpan: {},
    outliers: false,
    animation: true,
    headsUp: { static: true },
    sidenav: 'sidenavOff',
    symbols: [
      {
        symbol: 'IMOEX ММВБ Индексы',
        symbolObject: { id: 108897, symbol: 'IMOEX ММВБ Индексы' },
        periodicity: 5,
        interval: 1,
        timeUnit: 'minute',
        setSpan: {},
      },
    ],
    range: {},
    studies: {
      '‌Bollinger Bands‌ (20,3,ema,n)': {
        type: 'Bollinger Bands',
        inputs: {
          Period: 20,
          Field: 'field',
          'Standard Deviations': '3',
          'Moving Average Type': 'exponential',
          'Channel Fill': false,
          id: '‌Bollinger Bands‌ (20,3,ema,n)',
          display: '‌Bollinger Bands‌ (20,3,ema,n)',
        },
        outputs: {
          'Bollinger Bands Top': 'auto',
          'Bollinger Bands Median': { color: '#707070' },
          'Bollinger Bands Bottom': { color: '#0073ba' },
        },
        panel: 'chart',
        parameters: { chartName: 'chart', editMode: true },
      },
      '‌rsi‌ (14,1)': {
        type: 'rsi',
        inputs: {
          Period: 14,
          Field: 'field',
          'RSI width': 1,
          id: '‌rsi‌ (14,1)',
          display: '‌rsi‌ (14,1)',
        },
        outputs: { RSI: 'auto' },
        panel: '‌rsi‌ (14,1)',
        parameters: {
          studyOverZonesEnabled: true,
          studyOverBoughtValue: 70,
          studyOverBoughtColor: 'auto',
          studyOverSoldValue: 30,
          studyOverSoldColor: 'auto',
          chartName: 'chart',
          editMode: true,
        },
      },
    },
  },
};
export const TRADE_RIGHT_CHART_SETTINGS = {
  customSettings: {
    panels: {
      chart: {
        yAxis: {
          zoom: 25.05392715556368,
        },
      },
      '‌volume‌': {
        yAxis: {
          zoom: 1.8909090909090907,
        },
      },
      '‌rsi‌ (14,1)': {
        yAxis: {
          zoom: 3.657142857142857,
        },
      },
    },
    showOHLC: true,
    showOrders: true,
    showPositions: true,
    showDividends: true,
  },
  layout: {
    interval: 1,
    periodicity: 5,
    timeUnit: 'minute',
    candleWidth: 7.975609756097561,
    flipped: false,
    volumeUnderlay: false,
    adj: true,
    crosshair: true,
    chartType: 'colored_bar',
    extended: false,
    marketSessions: {},
    aggregationType: 'ohlc',
    chartScale: 'linear',
    panels: {
      chart: {
        percent: 0.64,
        display: 'SBER МБ ЦК',
        chartName: 'chart',
        index: 0,
        yAxis: {
          name: 'chart',
          position: null,
        },
        yaxisLHS: [],
        yaxisRHS: ['chart'],
      },
      '‌volume‌': {
        percent: 0.16,
        display: '‌volume‌',
        chartName: 'chart',
        index: 1,
        yAxis: {
          name: '‌volume‌',
        },
        yaxisLHS: [],
        yaxisRHS: ['‌volume‌'],
      },
      '‌rsi‌ (14,1)': {
        percent: 0.19999999999999996,
        display: '‌rsi‌ (14,1)',
        chartName: 'chart',
        index: 2,
        yAxis: {
          name: '‌rsi‌ (14,1)',
        },
        yaxisLHS: [],
        yaxisRHS: ['‌rsi‌ (14,1)'],
      },
    },
    setSpan: {},
    outliers: false,
    animation: true,
    headsUp: {
      static: true,
    },
    sidenav: 'sidenavOff',
    symbols: [
      {
        symbol: 'SBER МБ ЦК',
        symbolObject: {
          symbol: 'SBER МБ ЦК',
          id: 144950,
          periodicity: {
            period: 1,
            interval: 1,
            timeUnit: 'day',
          },
          trueSymbol: 'SBER',
        },
        periodicity: 5,
        interval: 1,
        timeUnit: 'minute',
        setSpan: {},
      },
    ],
    range: {},
    studies: {
      '‌Bollinger Bands‌ (20,3,ema,n)': {
        type: 'Bollinger Bands',
        inputs: {
          Period: 20,
          Field: 'field',
          'Standard Deviations': '3',
          'Moving Average Type': 'exponential',
          'Channel Fill': false,
          id: '‌Bollinger Bands‌ (20,3,ema,n)',
          display: '‌Bollinger Bands‌ (20,3,ema,n)',
        },
        outputs: {
          'Bollinger Bands Top': 'auto',
          'Bollinger Bands Median': {
            color: '#707070',
          },
          'Bollinger Bands Bottom': {
            color: '#0073ba',
          },
        },
        panel: 'chart',
        parameters: {
          chartName: 'chart',
          editMode: true,
          panelName: 'chart',
        },
      },
      '‌volume‌': {
        type: 'volume',
        inputs: {
          id: '‌volume‌',
          display: '‌volume‌',
        },
        outputs: {
          'Up Volume': {
            color: '#707070',
          },
          'Down Volume': {
            color: '#626262',
          },
        },
        panel: '‌volume‌',
        parameters: {
          chartName: 'chart',
          editMode: true,
          panelName: '‌volume‌',
        },
      },
      '‌rsi‌ (14,1)': {
        type: 'rsi',
        inputs: {
          Period: 14,
          Field: 'field',
          'RSI width': 1,
          id: '‌rsi‌ (14,1)',
          display: '‌rsi‌ (14,1)',
        },
        outputs: {
          RSI: 'auto',
        },
        panel: '‌rsi‌ (14,1)',
        parameters: {
          studyOverZonesEnabled: true,
          studyOverBoughtValue: 70,
          studyOverBoughtColor: 'auto',
          studyOverSoldValue: 30,
          studyOverSoldColor: 'auto',
          chartName: 'chart',
          editMode: true,
          panelName: '‌rsi‌ (14,1)',
        },
      },
    },
  },
};

export const CHART_LEFT_CHARTIQ_SETTINGS = {
  customSettings: {
    panels: {
      chart: { yAxis: { zoom: 45.58034977726449 } },
      '‌volume‌': { yAxis: { zoom: 2.948394223940663 } },
    },
    showOHLC: true,
    showOrders: true,
    showPositions: true,
    showDividends: true,
  },
  layout: {
    interval: 'day',
    periodicity: 1,
    timeUnit: null,
    candleWidth: 7.5793103448275865,
    flipped: false,
    volumeUnderlay: false,
    adj: true,
    crosshair: true,
    chartType: 'candle',
    extended: false,
    marketSessions: {},
    aggregationType: 'ohlc',
    chartScale: 'linear',
    panels: {
      chart: {
        percent: 0.7568058076225045,
        display: 'SBER МБ ЦК',
        chartName: 'chart',
        index: 0,
        yAxis: { name: 'chart', position: null },
        yaxisLHS: [],
        yaxisRHS: ['chart'],
      },
      '‌volume‌': {
        percent: 0.24319419237749546,
        display: '‌volume‌',
        chartName: 'chart',
        index: 1,
        yAxis: { name: '‌volume‌' },
        yaxisLHS: [],
        yaxisRHS: ['‌volume‌'],
      },
    },
    setSpan: {},
    outliers: false,
    animation: true,
    headsUp: { static: true },
    sidenav: 'sidenavOff',
    symbols: [
      {
        symbol: 'SBER МБ ЦК',
        symbolObject: {
          symbol: 'SBER МБ ЦК',
          id: 144950,
          periodicity: { period: 1, interval: 1, timeUnit: 'day' },
          trueSymbol: 'SBER',
        },
        periodicity: 1,
        interval: 'day',
        timeUnit: null,
        setSpan: {},
      },
    ],
    range: {},
    studies: {
      '‌volume‌': {
        type: 'volume',
        inputs: { id: '‌volume‌', display: '‌volume‌' },
        outputs: {
          'Up Volume': { color: '#707070' },
          'Down Volume': { color: '#626262' },
        },
        panel: '‌volume‌',
        parameters: {
          chartName: 'chart',
          editMode: true,
          panelName: '‌volume‌',
        },
      },
      '‌ma‌ (50,ema,0,1)': {
        type: 'ma',
        inputs: {
          Period: 50,
          Field: 'field',
          Type: 'exponential',
          Offset: 0,
          'MA width': 1,
          id: '‌ma‌ (50,ema,0,1)',
          display: '‌ma‌ (50,ema,0,1)',
        },
        outputs: { MA: { color: '#FF0000', width: 1 } },
        panel: 'chart',
        parameters: {
          interactiveAdd: false,
          chartName: 'chart',
          editMode: true,
          panelName: 'chart',
        },
      },
      '‌ma‌ (200,ema,0,1)': {
        type: 'ma',
        inputs: {
          Period: '200',
          Field: 'field',
          Type: 'exponential',
          Offset: 0,
          'MA width': 1,
          id: '‌ma‌ (200,ema,0,1)',
          display: '‌ma‌ (200,ema,0,1)',
        },
        outputs: { MA: { color: '#0056a4', width: 1 } },
        panel: 'chart',
        parameters: {
          interactiveAdd: false,
          chartName: 'chart',
          editMode: true,
          panelName: 'chart',
        },
      },
    },
  },
};
export const CHART_RIGHT_CHARTIQ_SETTINGS = {
  customSettings: {
    panels: {
      chart: { yAxis: { zoom: 37.35750297036063 } },
      '‌macd‌ (12,26,9,1,1)': { yAxis: { zoom: 4.418913795170765 } },
      '‌volume‌': { yAxis: { zoom: 1.9999999999999998 } },
    },
    showOHLC: true,
    showOrders: true,
    showPositions: true,
    showDividends: true,
  },
  layout: {
    interval: 1,
    periodicity: 60,
    timeUnit: 'minute',
    candleWidth: 7.9862068965517246,
    flipped: false,
    volumeUnderlay: false,
    adj: true,
    crosshair: true,
    chartType: 'candle',
    extended: false,
    marketSessions: {},
    aggregationType: 'ohlc',
    chartScale: 'linear',
    panels: {
      chart: {
        percent: 0.6202755210173084,
        display: 'SBER МБ ЦК',
        chartName: 'chart',
        index: 0,
        yAxis: { name: 'chart', position: null },
        yaxisLHS: [],
        yaxisRHS: ['chart'],
      },
      '‌macd‌ (12,26,9,1,1)': {
        percent: 0.17972447898269164,
        display: '‌macd‌ (12,26,9,1,1)',
        chartName: 'chart',
        index: 1,
        yAxis: { name: '‌macd‌ (12,26,9,1,1)' },
        yaxisLHS: [],
        yaxisRHS: ['‌macd‌ (12,26,9,1,1)'],
      },
      '‌volume‌': {
        percent: 0.2,
        display: '‌volume‌',
        chartName: 'chart',
        index: 2,
        yAxis: { name: '‌volume‌' },
        yaxisLHS: [],
        yaxisRHS: ['‌volume‌'],
      },
    },
    setSpan: {},
    outliers: false,
    animation: true,
    headsUp: { static: true },
    sidenav: 'sidenavOff',
    symbols: [
      {
        symbol: 'SBER МБ ЦК',
        symbolObject: {
          symbol: 'SBER МБ ЦК',
          id: 144950,
          periodicity: { period: 1, interval: 1, timeUnit: 'day' },
          trueSymbol: 'SBER',
        },
        periodicity: 60,
        interval: 1,
        timeUnit: 'minute',
        setSpan: {},
      },
    ],
    range: {},
    studies: {
      '‌macd‌ (12,26,9,1,1)': {
        type: 'macd',
        inputs: {
          'Fast MA Period': 12,
          'Slow MA Period': 26,
          'Signal Period': 9,
          'MACD width': 1,
          'Signal width': 1,
          id: '‌macd‌ (12,26,9,1,1)',
          display: '‌macd‌ (12,26,9,1,1)',
        },
        outputs: {
          MACD: 'auto',
          Signal: '#FF0000',
          'Increasing Bar': '#2FC26E',
          'Decreasing Bar': '#EF3124',
        },
        panel: '‌macd‌ (12,26,9,1,1)',
        parameters: {
          chartName: 'chart',
          editMode: true,
          panelName: '‌macd‌ (12,26,9,1,1)',
        },
      },
      '‌volume‌': {
        type: 'volume',
        inputs: { id: '‌volume‌', display: '‌volume‌' },
        outputs: {
          'Up Volume': { color: '#626262' },
          'Down Volume': { color: '#555555' },
        },
        panel: '‌volume‌',
        parameters: {
          chartName: 'chart',
          editMode: true,
          panelName: '‌volume‌',
        },
      },
      '‌PSAR‌ (0.02,0.2)': {
        type: 'PSAR',
        inputs: {
          'Minimum AF': 0.02,
          'Maximum AF': 0.2,
          id: '‌PSAR‌ (0.02,0.2)',
          display: '‌PSAR‌ (0.02,0.2)',
        },
        outputs: { Result: 'auto' },
        panel: 'chart',
        parameters: {
          chartName: 'chart',
          editMode: true,
          panelName: 'chart',
        },
      },
    },
  },
};
