import { alfaDirectClient } from '@terminal/core/lib/client/client';
import {
  BrokerRecommendationEntity,
  ChatGroupEntity,
  ChatMessageEntity,
  FrontEndType,
} from '@terminal/core/lib/client/entities';
import { EntityType } from '@terminal/core/lib/client/entityTypes';
import { sortByDate } from '@terminal/core/lib/sortByDate';
import { useStore as useRootStore } from '@terminal/core/store/useStore';

import { brokerChatMessageMap } from '../lib/brokerChatMessageMapping';
import { brokerRecommendationMap } from '../lib/brokerRecommendationMapping';
import { chatGroupMapping } from '../lib/chatGroupMapping';

import { useStore } from '../store/useStore';

import { ChatSlice } from '../types';

class BrokerRecommendationService {
  private entityTypes = [
    EntityType.ChatGroupEntity,
    EntityType.BrokerRecommendationEntity,
    EntityType.ChatMessageEntity,
  ];

  constructor() {
    this.init();
  }

  private init() {
    alfaDirectClient.addListener(EntityType.ChatGroupEntity, (message) => {
      useStore
        .getState()
        .setChatGroups(
          (message.data as ChatGroupEntity[]).map(chatGroupMapping)
        );
    });
    alfaDirectClient.addListener(
      EntityType.BrokerRecommendationEntity,
      (message) => {
        useStore
          .getState()
          .setBrokerRecommendations(
            (message.data as BrokerRecommendationEntity[]).map(
              brokerRecommendationMap
            )
          );
      }
    );
    alfaDirectClient.addListener(EntityType.ChatMessageEntity, (message) => {
      useStore
        .getState()
        .setBrokerMessages(
          (message.data as ChatMessageEntity[])
            .sort(sortByDate('DtChatMessage'))
            .map(brokerChatMessageMap)
        );
    });
  }

  public subscribe() {
    this.entityTypes.forEach((entityType) => {
      alfaDirectClient.subscribe(
        FrontEndType.BirzArchAndMediaServer,
        entityType
      );
    });

    return this.unsubscribe();
  }

  private unsubscribe() {
    this.entityTypes.forEach((entityType) => {
      alfaDirectClient.unsubscribe(
        FrontEndType.BirzArchAndMediaServer,
        entityType
      );
    });
  }

  public async sendMessage(
    text: string,
    chatId: string,
    setMessagesCb: ChatSlice['setNewMessages']
  ) {
    const idAccount = useRootStore.getState().credentials?.idAccount;

    const message = new ChatMessageEntity();

    message.IdSender = idAccount || 0;
    message.IdChatGroup = Number(chatId);
    message.DtChatMessage = new Date();
    message.Text = text;
    message.BrokerRecommendation = [];
    message.Version = 1n;

    try {
      alfaDirectClient.send({
        frontend: FrontEndType.BirzArchAndMediaServer,
        isArray: false,
        payload: {
          type: EntityType.ChatMessageEntity,
          data: message,
        },
      });

      setMessagesCb(chatId, [brokerChatMessageMap(message)]);
    } catch (err) {
      throw err;
    }
  }
}

const brokerRecommendationService = new BrokerRecommendationService();

export { brokerRecommendationService as BrokerRecommendationService };
