import JSEncrypt from 'jsencrypt';
import log from 'loglevel';

import {
  LK_URI,
  USE_PASSPORT_AUTH,
  USE_PASSPORT_CERBERUS_AUTH,
} from '../../env';
import {
  BACKEND_DATE_FORMAT as BACKEND_DATE_FORMAT_BASE,
  dateToRequestFormat as dateToRequestFormatBase,
  DEFAULT_VIEW_DATE_FORMAT as DEFAULT_VIEW_DATE_FORMAT_BASE,
} from '../../lib/dateToRequestFormat';
import { fetchThroughProxy } from '../url';
import { LKCrossauthTokens } from './lkCrossauthTokens';
import { HttpStatusError } from './lkErrors';

/**
 * @deprecated Используй @terminal/core/lib/dateToRequestFormat
 * */
export const DEFAULT_VIEW_DATE_FORMAT = DEFAULT_VIEW_DATE_FORMAT_BASE;
/**
 * @deprecated Используй @terminal/core/lib/dateToRequestFormat
 * */
export const BACKEND_DATE_FORMAT = BACKEND_DATE_FORMAT_BASE;
/**
 * @deprecated Используй @terminal/core/lib/dateToRequestFormat
 * */
export const dateToRequestFormat = dateToRequestFormatBase;

export interface LKResult {
  code: number;
  message: string | null;
}

export interface LKPageResult {
  pageCount: number;
  pageNumber: number;
}

export type HttpMethod = 'GET' | 'DELETE' | 'POST' | 'HEAD' | 'PUT';

export const USE_PASSPORT = USE_PASSPORT_AUTH;
const USE_CERBERUS = USE_PASSPORT_CERBERUS_AUTH;
const tokenService = new LKCrossauthTokens();

// Требование безопастников: все операции с банковкими картами должны дополнительно шифроваться.
// Приватная пара ключа хранится в бэке ЛК
const rsaPubKey: string = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA4GJZhLo2EGtozUwU55UW
pfjPIrPRTGplPGO7m8dACrYskN3aWWvxuym7tOBm/KJZ5X1gEC0MujNFQ/HpT83Z
DjLMSrIkhBOysgGoaII0e1oEnMwLXXzLW4Qv6Dljuvo6J8132gDb6Df4VVtmr+PU
5OTR+tHoyFtM47K8mFmDitBFE3NWlWNCWN284VBeU0iRVfe2npnIlmq0i02IU123
qXKigk6+0bbs6nBWH/phbPKSpxOB5Q4ZBwKcVX4ZgQD23AN2ERSzjxflE87Z9Gvf
1+XSAyP/vmpQE+EaygqiHvy8u7hCDRf93Wzmgz2pQgLJ31lfwKt1Vr8P/hbGE6eV
mQIDAQAB
-----END PUBLIC KEY-----`;

function getCommonHeaders() {
  const headers = new Headers();

  headers.append('Content-Type', 'application/json');
  headers.append('Accept', 'application/json, text/javascript, */*; q=0.01');
  headers.append('Platform', 'WT');

  return headers;
}

async function fetchLkApi(
  url: string,
  method: HttpMethod = 'GET',
  body?: object,
  encrypt?: boolean,
  customHeaders?: Headers,
  needAuth: boolean = true
): Promise<Response> {
  const prepareBody = async (body: object): Promise<string> => {
    let data: string = JSON.stringify(body);

    if (encrypt) {
      const jsenc = new JSEncrypt();

      jsenc.setPublicKey(rsaPubKey);
      const encrupted = jsenc.encrypt(data);

      if (encrupted) {
        data = encrupted;
      } else {
        log.error('Encryption failed');
      }
    }

    return data;
  };

  const headers = customHeaders || getCommonHeaders();
  let params: RequestInit = {
    credentials: 'include',
    cache: 'no-store',
    method,
  };

  if (typeof body !== 'undefined') {
    const obj = body instanceof FormData ? body : await prepareBody(body);

    params = {
      ...params,
      body: obj as BodyInit,
    };
  }

  if (USE_PASSPORT && !USE_CERBERUS && needAuth) {
    let tokens = await tokenService.getTokens();
    const time = new Date().valueOf();

    if (tokens.expires < time || !tokens.expires) {
      tokens = await tokenService.refreshTokens();
    }

    if (!tokens.access) {
      throw new Error('Не удалось авторизоваться в личном кабинете');
    }

    headers.append('access_token', tokens.access);
  }

  params.headers = headers;

  const fetchUrl = url.indexOf(LK_URI) === -1 ? LK_URI + url : url;
  const response = await fetchThroughProxy(fetchUrl, params);

  if (response.ok) {
    return response;
  }

  if (response.status === 401) {
    if (USE_PASSPORT && !USE_CERBERUS) {
      // Закончилось время жизни токена
      // пробуем обновить токены и запросить ещё раз
      const tokens = await tokenService.refreshTokens();

      if (!tokens.access) {
        throw new Error('Не удалось авторизоваться в личном кабинете');
      }

      headers.set('access_token', tokens.access);

      return await fetchThroughProxy(fetchUrl, params);
    }

    if (USE_CERBERUS) {
      throw new Error('Не удалось авторизоваться в личном кабинете');
    } else {
      // Закончилась авторизация, выходим
      // @ts-expect-error
      // см useStore.ts чтоб понять что это за дичь
      window?._logout();
    }
  }

  return response;
}

/**
 * @param url Url запроса
 * @param method Метод (GET | POST | PUT | HEAD | DELETE), GET по-умолчанию
 * @param body Тело запроса
 * @param encrypt Шифровать запрос
 * @param customHeaders Заголовки запроса
 * @param needAuth Для запроса нужна авторизация, по-умолчанию true
 * @returns
 * */
export async function fetchLkResult<TResult>(
  url: string,
  method: HttpMethod = 'GET',
  body?: object,
  encrypt?: boolean,
  customHeaders?: Headers,
  needAuth: boolean = true
): Promise<TResult> {
  const response = await fetchLkApi(
    url,
    method,
    body,
    encrypt,
    customHeaders,
    needAuth
  );

  if (response.ok) {
    try {
      return await response.json();
    } catch (error) {
      throw error;
    }
  } else {
    throw await parseApiHttpStatusError(response);
  }
}

export async function fetchLk(
  url: string,
  method: HttpMethod = 'GET',
  body?: object,
  encrypt?: boolean,
  customHeaders?: Headers,
  needAuth: boolean = true
): Promise<void> {
  const response = await fetchLkApi(
    url,
    method,
    body,
    encrypt,
    customHeaders,
    needAuth
  );

  if (response.ok) {
    return;
  } else {
    throw await parseApiHttpStatusError(response);
  }
}

export async function callLkApi(
  url: string,
  method: HttpMethod = 'GET',
  body?: object,
  encrypt?: boolean,
  customHeaders?: Headers
): Promise<void> {
  const response = await fetchLkApi(url, method, body, encrypt, customHeaders);

  if (response.ok) {
    return;
  } else {
    throw new Error(await getResponseErrorMessage(response));
  }
}

export interface DownloadedFile {
  url: string;
  filename: string;
  destroy: () => void;
}

/**
 * Метод загрузки файлов из ЛК. Нужен, чтобы обеспечить передачу хедеров, например токена авторизации.
 * Возвращает локальную ссылку на файл.
 * @param {string} url
 * @param {Headers} customHeaders
 * @returns {Promise<{url: string, filename: string}>}
 * */
export async function fetchLkFile(
  url: string,
  customHeaders?: Headers
): Promise<DownloadedFile> {
  const response = await fetchLkApi(
    url,
    'GET',
    undefined,
    false,
    customHeaders
  );

  if (response.ok) {
    try {
      let filename = '';
      const disposition = response.headers.get('content-disposition');

      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex =
          disposition.indexOf('UTF-8') !== -1
            ? /filename[^;=\n]*=UTF-8'((['"]).*?\2|[^;\n]*)/
            : /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);

        if (matches != null && matches[1]) {
          filename = decodeURI(matches[1].replace(/['"]/g, ''));
        }
      }

      const file = await response.blob();
      const fileUrl = URL.createObjectURL(file);

      return {
        url: fileUrl,
        filename,
        destroy: () => URL.revokeObjectURL(fileUrl),
      };
    } catch (error) {
      throw error;
    }
  } else {
    throw new Error('Ошибка загрузки файла');
  }
}

export async function fetchLkImage(
  url: string,
  customHeaders?: Headers
): Promise<string> {
  const headers = customHeaders || getCommonHeaders();

  const response = await fetchLkApi(url, 'GET', undefined, false, headers);

  if (response.ok) {
    try {
      const image = await response.blob();
      const imageUrl = URL.createObjectURL(image);

      return imageUrl;
    } catch (error) {
      throw error;
    }
  } else {
    throw new Error('Ошибка загрузки файла');
  }
}

async function parseApiHttpStatusError(
  response: Response
): Promise<HttpStatusError> {
  const contentType = response.headers.get('Content-Type');

  if (contentType && contentType.indexOf('application/json') >= 0) {
    const result = await response.json();

    if (typeof result === 'string') {
      return new HttpStatusError(result, response.status);
    }

    if (result.message && result.code && result.code !== 0) {
      return new HttpStatusError(
        result.message,
        response.status,
        result.code,
        result.data
      );
    }
  } else if (contentType && contentType.indexOf('text/plain') >= 0) {
    const result = await response.text();

    return new HttpStatusError(result, response.status);
  }

  return new HttpStatusError(
    'При выполнении запроса произошла ошибка',
    response.status
  );
}

async function getResponseErrorMessage(response: Response): Promise<string> {
  const defaultError = 'Ошибка запроса api ЛК';
  const responseText = await response.text();

  if (!responseText) {
    return defaultError;
  }

  try {
    const obj = JSON.parse(responseText);

    return obj.message || obj.Message || defaultError;
  } catch {
    return responseText;
  }
}

export const getAccessToken = async () => {
  let tokens = await tokenService.getTokens();

  return tokens.access;
};

export async function makeCrossAuthUrl(redirect_url: string) {
  if (USE_PASSPORT && !USE_CERBERUS) {
    let tokens = await tokenService.getTokens();
    const time = new Date().valueOf();

    if (tokens.expires < time) {
      tokens = await tokenService.refreshTokens();
    }

    if (!tokens.access) {
      return redirect_url;
    }

    return `${LK_URI}/api/account/authorize/token?access_token=${
      tokens.access
    }&redirect_url=${encodeURIComponent(redirect_url)}`;
  } else if (USE_CERBERUS) {
    return `${LK_URI}/account/otp?redirect_url=${encodeURIComponent(
      redirect_url
    )}`;
  } else {
    return redirect_url;
  }
}
