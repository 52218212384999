import { memo } from 'react';
import { Amount } from '@alfalab/core-components/amount';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowDownCompactXsIcon } from '@alfalab/icons-glyph/ArrowDownCompactXsIcon'; // TODO Поменять на ArrowDownTriangleMIcon при обновлении либы
import { ArrowUpCompactXsIcon } from '@alfalab/icons-glyph/ArrowUpCompactXsIcon'; // TODO Поменять на ArrowUpTriangleMIcon при обновлении либы

import { useRecommendationOrder } from '@terminal/chat/hooks/useRecommendationOrder';
import { BrokerRecommendation } from '@terminal/chat/types';
import { SymbolCell } from '@terminal/common/features-DEPRECATED/Table';
import { MINORITY } from '@terminal/core/constants/ui';
import { OrderDirection, OrderType } from '@terminal/core/lib/client/entities';

import { Message } from '../../../../entities/Chat';

import styles from './BrokerRecommendationBubble.module.css';

const getOperationText = (direction: OrderDirection, orderType: OrderType) => {
  const orderTypeAbbreviation = Object.entries(OrderType).find(
    ([id]) => String(orderType) === id
  )?.[1];

  if (direction === OrderDirection.Buy) {
    return (
      <div className={styles.directionRow}>
        <ArrowUpCompactXsIcon
          width={10}
          height={10}
          color="#13A463"
          className={styles.directionIcon}
        />
        <Typography.Text view="secondary-small">Покупка</Typography.Text>
        {orderTypeAbbreviation && (
          <Typography.Text
            view="secondary-small"
            className={styles.operationType}
          >
            {orderTypeAbbreviation}
          </Typography.Text>
        )}
      </div>
    );
  }

  if (direction === OrderDirection.Sell) {
    return (
      <div className={styles.directionRow}>
        <ArrowDownCompactXsIcon
          width={10}
          height={10}
          color="#D91D0B"
          className={styles.directionIcon}
        />
        <Typography.Text view="secondary-small">Продажа</Typography.Text>
        {orderTypeAbbreviation && (
          <Typography.Text
            view="secondary-small"
            className={styles.operationType}
          >
            {orderTypeAbbreviation}
          </Typography.Text>
        )}
      </div>
    );
  }

  return null;
};

export type BrokerRecommendationBubbleProps = {
  isMe: boolean;
  recommendation: BrokerRecommendation;
};

export const BrokerRecommendationBubble = memo<BrokerRecommendationBubbleProps>(
  function BrokerRecommendationBubble(props) {
    const { isMe, recommendation } = props;

    const recommendedOrder = useRecommendationOrder(recommendation);

    return (
      <Message isMe={isMe}>
        <div className={styles.recommendationContainer}>
          <SymbolCell
            idObject={recommendedOrder?.idObject}
            symbolObject={recommendedOrder?.symbolObject}
            onlyIcon
            isMobile
          />
          <div />
          <div>
            <div>
              <Typography.Text view="secondary-large">
                {recommendedOrder?.symbolObject}
              </Typography.Text>
              <Typography.Text
                view="secondary-large"
                className={styles.marketBoard}
              >
                {recommendedOrder?.nameMarketBoard}
              </Typography.Text>
            </div>
            {getOperationText(
              recommendedOrder.buySell,
              recommendedOrder.idOrderType
            )}
            <div className={styles.directionRow}>
              <Amount
                className={styles.amount}
                value={
                  recommendedOrder.price
                    ? recommendedOrder.price * MINORITY
                    : recommendedOrder.price
                }
                view="withZeroMinorPart"
                transparentMinor={false}
                currency={recommendedOrder.currencyCode}
                minority={MINORITY}
              />
            </div>
          </div>
          <div />
        </div>
      </Message>
    );
  }
);
