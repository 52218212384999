import { useCallback, useEffect, useMemo, useState } from 'react';

import { AlfaDirectProvider } from '@terminal/alfadirect/provider/react';
// пока полностью не перешли на новую архитектуру нужно использовать такой импорт, не из индекса
import { useAlfaDirectProps } from '@terminal/alfadirect/provider/react/useAlfaDirectProps';
import { SupportService } from '@terminal/chat/api/chatService';
import { BrokerRecommendationService } from '@terminal/chat/services/broker';
import { DesktopNotificationManager } from '@terminal/common/features-DEPRECATED/NotificationManager';
import { ENABLE_LK_FEATURES, USE_PASSPORT_AUTH } from '@terminal/core/env';
import { featureFlags } from '@terminal/core/featureFlags';
import { useEventListener, usePrevious } from '@terminal/core/hooks';
import { useAppContext } from '@terminal/core/hooks/useAppContext';
import {
  logAuthorizationPerformance,
  logProblemFrames,
} from '@terminal/core/lib/analytics/performance';
import { WorkspaceConfigurations } from '@terminal/core/lib/db/workspaceConfiguration';
import { shallow, useStore } from '@terminal/core/store';
import { activeConfigurationSelector } from '@terminal/core/store/selectors/workspaceConfigurations/activeConfigurationSelector';
import { LayoutType } from '@terminal/core/types/layout';
import { UserCredentials } from '@terminal/core/types/user';
import { SigningDialog as SigningDialogDepricated } from '@terminal/lk/legacy/components/SigningDialog';
import { LoginProlongation } from '@terminal/lk/legacy/login/LoginProlongation';
import { getInit, LkContextProvider } from '@terminal/lk-core';
import { SigningDialog } from '@terminal/lk-desktop-common';
import {
  Provider as WidgetsApiProvider,
  TECH_MESSAGE_CODE,
  WidgetServiceNotification,
} from '@terminal/widgets';
import { useFeatureOnboarding } from '@terminal/widgets/features/FeatureOnboarding';

import { getOnboardingStateFromLocaleStorage } from '../../entities/OnboardingConfig';
import { FlexLayout } from '../../features/FlexLayout';
import { Layout } from '../../features/Layout';
import { MarginCall } from '../../features/MarginCall';
import { SingletonModalProvider } from '../../features/SingletonModalProvider';
import { SrEfrBanner } from '../../features/SrEfrBanner';
import { Alerts } from '../../legacy/components/Alerts';
import { Chat } from '../../widgets/Chat';
import { Header } from '../../widgets/Header';
import { OnboardingConfig } from '../../widgets/OnboardingConfig';
import { trackInitLayout } from './lib/analytics';
import { PerformanceBanner } from './ui/PerformanceBanner';

import { useLayout } from '../../shared/hooks/useLayout';
import { useLkContextProps } from '../../shared/hooks/useLkContextProps';
import { useWidgetProps } from '../../shared/hooks/useWidgetProps';

import styles from './Workspace.module.css';

const authPerformanceLogger = logAuthorizationPerformance();

export interface Props {
  user: UserCredentials;
}

enum ConfigurationState {
  UNKNOWN,
  CONFIGURED,
  EMPTY,
}

export function Workspace({ user }: Props): JSX.Element {
  const problemFramesPerformance = useMemo(() => logProblemFrames(), []);

  const { overrideAlfaDirectProps } = useAppContext();

  useEffect(() => {
    problemFramesPerformance.start();

    return () => {
      problemFramesPerformance.stop();
    };
  }, [problemFramesPerformance]);

  const [workspacesIsInitialized, getActiveLayoutKey] = useStore(
    (state) => [state.workspacesIsInitialized, state.getActiveLayoutKey],
    shallow
  );

  const { lastAutoSavedConfig } = useStore(activeConfigurationSelector);
  const { layouts, layoutNames } = lastAutoSavedConfig;

  const activeLayoutKey = getActiveLayoutKey();
  const prevActiveLayoutKey = usePrevious(activeLayoutKey);

  const {
    gridLayoutRef,
    flexLayoutRef,
    layoutType,
    onAddFromMenu,
    onAddFromTabSetButton,
    createLayout,
    layoutPerfomanceSize,
    closePerfomanceBanner,
  } = useLayout({ activeLayoutKey });

  useEffect(() => {
    if (
      activeLayoutKey !== undefined &&
      prevActiveLayoutKey !== activeLayoutKey
    ) {
      const model = layouts[activeLayoutKey];

      trackInitLayout({
        layoutsCount: layouts.length,
        layoutName: layoutNames[activeLayoutKey],
        tabsetsCount: model.type === LayoutType.Flex ? model.layout.length : 0,
      });
    }
  }, [activeLayoutKey, layoutNames, layouts, prevActiveLayoutKey]);

  const [onboardingCongif, setOnboardingConfig] = useState<ConfigurationState>(
    ConfigurationState.UNKNOWN
  );

  useEffect(() => {
    authPerformanceLogger.workspaceLoaded();
  }, []);

  useEffect(() => BrokerRecommendationService.subscribe(), []);

  useEventListener('visibilitychange', () => {
    SupportService.togglePolling(document.visibilityState === 'visible');
  });

  const disableNewFeaturesOnboarding = useFeatureOnboarding(
    (store) => store.disableOnboarding
  );

  const closeOnboardingConfigMenu = useCallback(() => {
    setOnboardingConfig(ConfigurationState.CONFIGURED);
  }, []);

  useEffect(() => {
    if (workspacesIsInitialized) {
      // клиент проходил онбординг ранее
      const isPassed = getOnboardingStateFromLocaleStorage('passed');
      const isOnboarded = WorkspaceConfigurations.isOnboarded;

      if (!isPassed && !isOnboarded) {
        disableNewFeaturesOnboarding();
        setOnboardingConfig(ConfigurationState.EMPTY);
      } else {
        setOnboardingConfig(ConfigurationState.CONFIGURED);
      }
    }
  }, [
    setOnboardingConfig,
    workspacesIsInitialized,
    disableNewFeaturesOnboarding,
  ]);

  const widgetProps = useWidgetProps();
  const alfaDirectProps = useAlfaDirectProps(overrideAlfaDirectProps);
  const lkContextProps = useLkContextProps({ fetchInit: getInit });

  if (onboardingCongif === ConfigurationState.UNKNOWN) {
    return <div />;
  }

  if (onboardingCongif === ConfigurationState.EMPTY) {
    return (
      <AlfaDirectProvider value={alfaDirectProps}>
        <WidgetsApiProvider value={widgetProps}>
          <OnboardingConfig
            closeOnboardingConfigMenu={closeOnboardingConfigMenu}
          />
        </WidgetsApiProvider>
        <DesktopNotificationManager />
      </AlfaDirectProvider>
    );
  }

  return (
    <AlfaDirectProvider value={alfaDirectProps}>
      <WidgetsApiProvider value={widgetProps}>
        <LkContextProvider {...lkContextProps}>
          <div className={styles.container}>
            <Header
              user={user}
              onAddFromMenu={onAddFromMenu}
              createLayout={createLayout}
            />
            <WidgetServiceNotification
              variant="line"
              code={TECH_MESSAGE_CODE.MAIN}
              closable
            />
            {featureFlags.SR_EFR_ACCOUNTS_MIGRATION_BANNER && <SrEfrBanner />}
            <MarginCall />
            <Alerts />
            {layoutPerfomanceSize && (
              <PerformanceBanner
                count={layoutPerfomanceSize}
                onClose={closePerfomanceBanner}
              />
            )}
            {workspacesIsInitialized &&
              activeLayoutKey !== undefined &&
              layoutType === LayoutType.Flex && (
                <FlexLayout
                  ref={flexLayoutRef}
                  activeLayoutKey={activeLayoutKey}
                />
              )}
            {workspacesIsInitialized &&
              activeLayoutKey !== undefined &&
              layoutType === LayoutType.Grid && (
                <Layout
                  ref={gridLayoutRef}
                  onAddFromTabSetButton={onAddFromTabSetButton}
                  activeLayoutKey={activeLayoutKey}
                />
              )}
            <Chat />
            <SingletonModalProvider />
            <DesktopNotificationManager />
            {ENABLE_LK_FEATURES && (
              <>
                {!USE_PASSPORT_AUTH && <LoginProlongation />}
                <SigningDialogDepricated />
                <SigningDialog />
              </>
            )}
          </div>
        </LkContextProvider>
      </WidgetsApiProvider>
    </AlfaDirectProvider>
  );
}
