import cn from 'classnames';
import isNull from 'lodash/isNull';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Amount, CurrencyCodes } from '@alfalab/core-components/amount';
import { AmountInput } from '@alfalab/core-components/amount-input';
import { CustomButton } from '@alfalab/core-components/custom-button';
import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';
import { CalendarLineMIcon } from '@alfalab/icons-glyph/CalendarLineMIcon';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { ChevronDownCompactMIcon } from '@alfalab/icons-glyph/ChevronDownCompactMIcon';
import { ClockLineMIcon } from '@alfalab/icons-glyph/ClockLineMIcon';
import { formatAmount } from '@alfalab/utils';

import {
  useAlfaDirectContext,
  useMissingAmount,
  usePriceToMarketCurrency,
} from '@terminal/alfadirect/hooks';
import { Dropdown } from '@terminal/common/components/Dropdown';
import { Stepper } from '@terminal/common/components/Stepper';
import {
  activationOptions,
  ActivationOptionShape,
  lifeTimeOptions,
  LifeTimeOptionShape,
  SetOrderParams,
} from '@terminal/core/constants/orderBook';
import { MINORITY } from '@terminal/core/constants/ui';
import { useOnClickOutside } from '@terminal/core/hooks/useOnClickOutside';
import { usePrevious } from '@terminal/core/hooks/usePrevious';
import { OrderType } from '@terminal/core/lib/client/entities';
import { getSymbolToShow } from '@terminal/core/lib/currencies';
import { getStepDecimals, roundPrice } from '@terminal/core/lib/format';
import { getPriceError } from '@terminal/core/lib/helpers/getPriceError';
import { evaluatePricePosition } from '@terminal/core/lib/orderBook/evaluatePricePosition';
import { TradingStatus } from '@terminal/core/lib/services/finInfoExt/getTradingStatus';
import { AccountItem } from '@terminal/core/types/account';
import { AdditionalInstruction } from '@terminal/core/types/additionalInstruction';
import { ExtendedFI } from '@terminal/core/types/extendedFI';
import { FinInfoExt } from '@terminal/core/types/quotes';
import { TradeLimitsResult } from '@terminal/core/types/tradeLimits';
import { OrderSide } from '@terminal/core/types/trading';

import { MissingAmountTopUp } from '../../../../../../components/MissingAmountTopUp';
import {
  instructionsOptionsParams,
  SLTPInstruction,
} from '../../../../../../features';
import { TradingCertificateLock } from '../../../../../../features/TradingCertificateLock';
import { useWidgetContext } from '../../../../../../shared';
import { useOrderBookContext } from '../../../../context';
import { OrderFormButtons } from './OrderFormButtons';

import { useWarranty } from '../../../../../../shared/hooks/useWarranty';

import styles from './OrderForm.module.css';

interface OrderFormProps {
  idFi: number;
  side: OrderSide;
  price: number;
  setPrice: (value: number) => void;
  quantity: number;
  setQuantity: (value: number) => void;
  upSpreadPrice: number;
  downSpreadPrice: number;
  currencyCode?: CurrencyCodes;
  finInfoExt?: FinInfoExt;
  selectedAccount?: AccountItem;
  selectedSubAccounts: string[];
  isLotCount?: boolean;
  isBond: boolean;
  isFuture: boolean;
  hide: boolean;
  setIsOpenInstruction: (state: boolean) => void;
  nodeId?: string;
  setOrderParams: (params: SetOrderParams, orderSum?: number) => void;
  lifeTime: LifeTimeOptionShape;
  activation: ActivationOptionShape;
  setLifeTime: (opt: LifeTimeOptionShape) => void;
  setActivation: (opt: ActivationOptionShape) => void;
  sltp: SLTPInstruction | null;
  setSltp: (sltp: SLTPInstruction | null) => void;
  tradingStatus: TradingStatus;
  fullFi?: ExtendedFI;
  tradeLimitsLong: TradeLimitsResult | null;
  tradeLimitsShort: TradeLimitsResult | null;
}

export const OrderForm = ({
  idFi,
  side,
  price,
  setPrice,
  quantity,
  setQuantity,
  upSpreadPrice,
  downSpreadPrice,
  finInfoExt,
  currencyCode,
  selectedAccount,
  selectedSubAccounts,
  isLotCount,
  isBond,
  isFuture,
  hide,
  nodeId,
  setOrderParams,
  lifeTime,
  activation,
  setLifeTime,
  setActivation,
  setIsOpenInstruction,
  sltp,
  setSltp,
  tradingStatus,
  fullFi,
  tradeLimitsLong,
  tradeLimitsShort,
}: OrderFormProps) => {
  const lifeTimeDropdown = useRef<HTMLDivElement>(null);
  const activationDropdown = useRef<HTMLDivElement>(null);
  const [lifeTimeOpen, setLifeTimeOpen] = useState(false);
  const [activationOpen, setActivationOpen] = useState(false);

  useOnClickOutside(lifeTimeDropdown, () => setLifeTimeOpen(false));
  useOnClickOutside(activationDropdown, () => setActivationOpen(false));
  const isQuantityNotMultipleOfALot =
    !isLotCount && finInfoExt && quantity % finInfoExt.lot !== 0;

  const { useConnectionError, DropdownComponent, openTopUpModal, getNode } =
    useWidgetContext();
  const { useBrokerageFee } = useAlfaDirectContext();

  const { sendTrallingOrders, stopOrderType } = useOrderBookContext();

  const nodeWidth = useMemo(
    () => getNode(nodeId)?.getRect().width || 0,
    [getNode, nodeId]
  );
  const isConnectionError = useConnectionError();
  const isDisabledButtons = Boolean(
    isConnectionError ||
      isQuantityNotMultipleOfALot ||
      tradingStatus === TradingStatus.CLOSED
  );

  const [priceTouched, setPriceTouched] = useState(false);

  const isMount = useRef(false);
  const getQuantity = () =>
    isLotCount ? (finInfoExt?.lot || 0) * quantity : quantity;

  const { displayedTotalPrice, reducedPrice } = usePriceToMarketCurrency(
    fullFi,
    price,
    MINORITY,
    fullFi?.nominal,
    getQuantity(),
    finInfoExt?.priceStep,
    finInfoExt?.priceStepCost
  );

  const symbol = getSymbolToShow(fullFi?.currencyCode, fullFi?.idObjectGroup);

  const prevPrice = usePrevious(price);

  useEffect(() => {
    if (!prevPrice || prevPrice === price || !sltp) {
      return;
    }

    setSltp(sltp);
  }, [
    sltp,
    prevPrice,
    price,
    setSltp,
    side,
    finInfoExt?.priceStep,
    currencyCode,
  ]);

  const fee = useBrokerageFee(
    idFi,
    reducedPrice,
    getQuantity(),
    selectedAccount
  );

  const { decimalsNumber, priceDecimals } = getStepDecimals(
    finInfoExt?.priceStep
  );

  const buyWarranty = useWarranty(idFi, price, {
    side: 'buy',
    idAccount: selectedAccount?.idAccount,
    finInfoExt,
  });
  const sellWarranty = useWarranty(idFi, price, {
    side: 'sell',
    idAccount: selectedAccount?.idAccount,
    finInfoExt,
  });
  const { formatted: warrantyFormatted } = formatAmount({
    view: 'withZeroMinorPart',
    value: Math.max(buyWarranty, sellWarranty) * getQuantity() * MINORITY,
    minority: MINORITY,
  });

  useEffect(() => {
    isMount.current = false;
    setPriceTouched(false);
  }, [idFi]);

  useEffect(() => {
    if (finInfoExt && !isMount.current) {
      if (!isLotCount) {
        setQuantity(finInfoExt.lot);
      }

      isMount.current = true;
    }
  }, [finInfoExt, setQuantity, setPrice, isLotCount]);

  const renderFee = () => (
    <div>
      ≈&nbsp;
      {fee ? (
        <Amount.Pure
          //Пока захардкодил как 1% комиссии
          //Комиссия всегда с точностью 2 знака после запятой
          value={fee * MINORITY}
          minority={MINORITY}
          view="withZeroMinorPart"
          //Комиссия отображается только в рублевом виде, независимо от валюты fi
          currency="RUB"
        />
      ) : (
        '0,00'
      )}
    </div>
  );

  const { isAboveSpread, isWithinSpread } = evaluatePricePosition(price, {
    upperSpreadPrice: upSpreadPrice,
    maxBuyPrice: downSpreadPrice,
  });

  let { missingAmount, isEnoughMoney } = useMissingAmount(
    displayedTotalPrice / MINORITY,
    fee,
    fullFi?.idObjectGroup,
    isAboveSpread ? tradeLimitsLong : tradeLimitsShort,
    (isAboveSpread ? sellWarranty : buyWarranty) * getQuantity()
  );

  if (isWithinSpread) {
    isEnoughMoney = true;
  }

  return !hide ? (
    <TradingCertificateLock size={nodeWidth < 240 ? 'xs' : 's'}>
      <div
        className={cn(
          styles.form,
          nodeWidth < 380 && styles.xsmall,
          //TODO: удалили из css small. Тут тоже не нужен?
          nodeWidth >= 380 && nodeWidth < 470 && styles.small,
          nodeWidth >= 470 && nodeWidth < 600 && styles.medium,
          nodeWidth >= 600 && nodeWidth < 900 && styles.large,
          nodeWidth >= 900 && styles.xlarge
        )}
      >
        <div className={styles.orderParamsBlock}>
          <div className={cn(styles.formRow, styles.priceQuantityBlock)}>
            <AmountInput
              className={styles.customInput}
              fieldClassName={styles.customInputField}
              error={getPriceError(price, priceTouched, finInfoExt?.priceStep)}
              block
              suffix={isBond ? '%' : currencyCode || ''}
              currency={isBond ? undefined : currencyCode}
              value={price * priceDecimals}
              minority={priceDecimals}
              onBlur={() => !priceTouched && setPriceTouched(true)}
              onChange={(e, { value }) =>
                setPrice(
                  isNull(value)
                    ? 0
                    : Number((value / priceDecimals).toFixed(decimalsNumber))
                )
              }
              rightAddons={
                <Stepper
                  onAdd={() => {
                    if (finInfoExt) {
                      setPrice(
                        roundPrice(
                          price + finInfoExt.priceStep,
                          finInfoExt.priceStep
                        )
                      );

                      if (!priceTouched) {
                        setPriceTouched(true);
                      }
                    }
                  }}
                  onSub={() => {
                    if (finInfoExt) {
                      setPrice(
                        Math.max(
                          0,
                          roundPrice(
                            price - finInfoExt.priceStep,
                            finInfoExt.priceStep
                          )
                        )
                      );

                      if (!priceTouched) {
                        setPriceTouched(true);
                      }
                    }
                  }}
                />
              }
            />
            <AmountInput
              //Проверяем кратность лоту
              className={styles.customInput}
              fieldClassName={styles.customInputField}
              error={
                isQuantityNotMultipleOfALot ? (
                  <>
                    1 лот = {finInfoExt.lot} шт. Объем должен быть кратным лоту
                  </>
                ) : undefined
              }
              block
              suffix={isLotCount ? 'лот' : 'шт'}
              value={quantity}
              minority={1}
              integersOnly
              onChange={(e, { value }) => {
                setQuantity(value || 0);
              }}
              onBlur={() => {
                if (finInfoExt && !isLotCount && quantity < finInfoExt?.lot) {
                  setQuantity(finInfoExt.lot);
                }
              }}
              rightAddons={
                <Stepper
                  onAdd={() => {
                    if (isLotCount) {
                      setQuantity(++quantity);
                    } else if (finInfoExt) {
                      setQuantity(quantity + finInfoExt.lot);
                    }
                  }}
                  onSub={() => {
                    if (isLotCount) {
                      setQuantity(quantity > 1 ? --quantity : quantity);
                    } else if (finInfoExt) {
                      setQuantity(
                        quantity > finInfoExt.lot
                          ? quantity - finInfoExt.lot
                          : finInfoExt.lot
                      );
                    }
                  }}
                />
              }
              dataTestId="priceInput"
            />
          </div>
          <div className={styles.formRow}>
            {/* Выбираем дату активации заявки */}
            <div
              ref={activationDropdown}
              className={cn([
                styles.dropdownRef,
                styles.dropdownRefMaxWidthHalf,
              ])}
            >
              <div
                className={styles.dropDownContent}
                onClick={() => setActivationOpen((prev) => !prev)}
              >
                <ClockLineMIcon
                  height={16}
                  width={16}
                  color="var(--color-dark-graphic-secondary)"
                />
                <Typography.Text
                  view="secondary-large"
                  className={styles.orderName}
                >
                  {activation.content}
                </Typography.Text>
                <ChevronDownCompactMIcon
                  height={16}
                  width={16}
                  className={cn(
                    styles.chevron,
                    activationOpen && styles.chevronUp
                  )}
                />
              </div>
              <DropdownComponent
                anchorElement={activationDropdown.current}
                open={activationOpen}
                offset={[0, 4]}
                position="top-start"
                header={
                  <div className={styles.menuTitleWrapper}>
                    <Typography.Text view="secondary-small" weight="bold">
                      Активация
                    </Typography.Text>
                  </div>
                }
                headerDivider={false}
                preventFlip={false}
                preventOverflow={false}
              >
                {activationOptions.map((opt) => {
                  const isSelected = opt.key === activation.key;

                  return (
                    <Dropdown.Item
                      key={opt.key}
                      content={
                        <div className={styles.dropDownItemContent}>
                          {opt.content}
                        </div>
                      }
                      onClick={() => setActivation(opt)}
                      rightIcon={
                        isSelected ? (
                          <CheckmarkMIcon height={18} width={18} />
                        ) : undefined
                      }
                    />
                  );
                })}
              </DropdownComponent>
            </div>
            {/* Выбираем срок исполнения заявки */}
            <div
              ref={lifeTimeDropdown}
              className={cn([
                styles.dropdownRef,
                styles.dropdownRefMaxWidthHalf,
              ])}
            >
              <div
                className={styles.dropDownContent}
                onClick={() => setLifeTimeOpen((prev) => !prev)}
              >
                <CalendarLineMIcon
                  height={16}
                  width={16}
                  color="var(--color-dark-graphic-secondary)"
                />
                <Typography.Text
                  view="secondary-large"
                  className={styles.orderName}
                >
                  {lifeTime.content}
                </Typography.Text>
                <ChevronDownCompactMIcon
                  height={16}
                  width={16}
                  className={cn(
                    styles.chevron,
                    lifeTimeOpen && styles.chevronUp
                  )}
                />
              </div>
              <DropdownComponent
                anchorElement={lifeTimeDropdown.current}
                open={lifeTimeOpen}
                offset={[0, 4]}
                position="top-start"
                header={
                  <div className={styles.menuTitleWrapper}>
                    <Typography.Text view="secondary-small" weight="bold">
                      Срок исполнения
                    </Typography.Text>
                  </div>
                }
                headerDivider={false}
                preventFlip={false}
                preventOverflow={false}
              >
                {lifeTimeOptions.map((opt) => {
                  const isSelected = opt.key === lifeTime.key;

                  if (opt?.isHidden) {
                    return null;
                  }

                  return (
                    <Dropdown.Item
                      key={opt.key}
                      content={
                        <div className={styles.dropDownItemContent}>
                          {opt.content}
                        </div>
                      }
                      onClick={() => setLifeTime(opt)}
                      rightIcon={
                        isSelected ? (
                          <CheckmarkMIcon height={18} width={18} />
                        ) : undefined
                      }
                    />
                  );
                })}
              </DropdownComponent>
            </div>
          </div>
          {!sendTrallingOrders && (
            <div className={styles.formRow}>
              <div className={styles.dropdownRef}>
                <div
                  className={styles.sltp}
                  onClick={() => setIsOpenInstruction(true)}
                >
                  <Typography.Text
                    view="secondary-large"
                    weight="medium"
                    tag="div"
                    className={styles.sltpText}
                  >
                    {instructionsOptionsParams[
                      AdditionalInstruction.SLTP
                    ].getButtonText(sltp, {
                      idFi,
                      isLotCount: isLotCount ?? true,
                      price,
                      side,
                      priceStep: finInfoExt?.priceStep,
                      symbol,
                    })}
                  </Typography.Text>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={cn(styles.formRow, styles.totalBlock)}>
          <div className={styles.formCol}>
            <div className={styles.plate}>
              <Typography.Text
                view="secondary-large"
                color="secondary"
                weight="medium"
                className={styles.amountValueText}
              >
                Объём
              </Typography.Text>
              <Amount.Pure
                value={displayedTotalPrice}
                minority={MINORITY}
                view="withZeroMinorPart"
                currency={currencyCode}
                dataTestId="sizeInput"
              />
            </div>
            {isFuture && finInfoExt && (
              <div className={styles.plate}>
                <Typography.Text
                  view="secondary-large"
                  color="secondary"
                  weight="medium"
                  className={styles.amountValueText}
                >
                  ГО
                </Typography.Text>
                <span>≈&nbsp;{warrantyFormatted}</span>
              </div>
            )}
            {isBond && finInfoExt && (
              <div className={styles.plate}>
                <Typography.Text
                  view="secondary-large"
                  color="secondary"
                  weight="medium"
                  className={styles.amountValueText}
                >
                  НКД
                </Typography.Text>
                <Amount.Pure
                  value={finInfoExt.accruedInt * getQuantity() * MINORITY}
                  minority={MINORITY}
                  view="withZeroMinorPart"
                  currency={currencyCode}
                />
              </div>
            )}
          </div>
          <div className={styles.formCol}>
            <div className={styles.plate}>
              <Typography.Text
                view="secondary-large"
                color="secondary"
                weight="medium"
                className={styles.amountValueText}
              >
                Комиссия
              </Typography.Text>
              {renderFee()}
            </div>
          </div>
        </div>
        {isEnoughMoney ? (
          <div className={styles.buttonsBlock}>
            <div className={styles.formRow}>
              <OrderFormButtons
                price={price}
                quantity={quantity}
                upSpreadPrice={upSpreadPrice}
                downSpreadPrice={downSpreadPrice}
                isDisabledButtons={isDisabledButtons}
                compactView={nodeWidth < 380}
                onLMT={(
                  buy: boolean,
                  bestOffer: boolean,
                  tralling: boolean
                ) => {
                  setOrderParams(
                    {
                      idOrderType: tralling ? OrderType.TRL : OrderType.LMT,
                      buy,
                      quantity: getQuantity(),
                      bestOffer,
                      inputPrice: price,
                    },
                    displayedTotalPrice / MINORITY
                  );
                }}
                onStop={(buy: boolean, tralling: boolean) => {
                  setOrderParams(
                    {
                      idOrderType: tralling
                        ? OrderType.TRS
                        : stopOrderType || OrderType.STP,
                      buy,
                      quantity: getQuantity(),
                      inputPrice: price,
                    },
                    displayedTotalPrice / MINORITY
                  );
                }}
              />
            </div>
            <div className={cn(styles.formRow, styles.tradeMarketButtonsBlock)}>
              <CustomButton
                size="xxs"
                block
                backgroundColor="var(--form-control-bg-color)"
                className={styles.buySingleButton}
                disabled={isDisabledButtons}
                onClick={() => {
                  setOrderParams(
                    {
                      idOrderType: OrderType.MKT,
                      buy: true,
                      quantity: getQuantity(),
                      inputPrice: 0,
                    },
                    displayedTotalPrice / MINORITY
                  );
                }}
              >
                Купить {quantity}&nbsp;по&nbsp;рынку
              </CustomButton>
              <CustomButton
                size="xxs"
                block
                backgroundColor="var(--form-control-bg-color)"
                className={styles.sellSingleButton}
                disabled={isDisabledButtons}
                onClick={() => {
                  setOrderParams(
                    {
                      idOrderType: OrderType.MKT,
                      buy: false,
                      quantity: getQuantity(),
                      inputPrice: 0,
                    },
                    displayedTotalPrice / MINORITY
                  );
                }}
              >
                Продать {quantity}&nbsp;по&nbsp;рынку
              </CustomButton>
            </div>
          </div>
        ) : (
          <>
            <Gap size="xs" />
            <MissingAmountTopUp
              sum={missingAmount}
              onTopUpClick={() =>
                openTopUpModal({
                  selectedSubAccounts,
                  sum: missingAmount,
                })
              }
            />
          </>
        )}
      </div>
    </TradingCertificateLock>
  ) : null;
};
