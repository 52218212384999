/* eslint-disable no-restricted-imports, no-restricted-syntax */
import { useMemo } from 'react';

import { useQuotes } from '@terminal/core/hooks/domain/useQuotes';
import { useConfirmCertificateSignOperation } from '@terminal/core/hooks/useConfirmCertificateSignOperation';
import { useIndustries } from '@terminal/core/hooks/useIndustries';
import { useRejectCertificateSignOperation } from '@terminal/core/hooks/useRejectCertificateSignOperation';
import { useServicesContext } from '@terminal/core/hooks/useServicesContext';
import {
  smsSignCodeCheck,
  smsSignSendSms,
} from '@terminal/core/lib/rest/lkSmsSignOperation';
import { useFinInfoExt } from '@terminal/core/lib/services/finInfoExt/useFinInfoExt';
import { loadMultimarkets } from '@terminal/core/lib/services/multimarkets';
import { orderBookService } from '@terminal/core/lib/services/orderbook';
import { useBrokerageFee } from '@terminal/core/lib/services/orderbookForm';
import { getServerTime } from '@terminal/core/lib/services/time';
import { checkTradeLimits } from '@terminal/core/lib/services/tradeLimits';
import { TradesService } from '@terminal/core/lib/services/trades';
import { RootStore, shallow, useStore } from '@terminal/core/store';
import { selectActiveAndWaitingOrders } from '@terminal/core/store/selectors';
import { recentOperationsSelector } from '@terminal/core/store/selectors/account/recentOperationsSelector';
import { relevantOrdersSelector } from '@terminal/core/store/selectors/account/relevantOrdersSelector';
import { MarketBoardItemsTable } from '@terminal/core/types/core';

import { AlfaDirectAPI } from '../../types/AlfaDirectAPI';

const useFinInfoExtsList = () => useStore((store) => store.finInfoExt);

const useCertificates = () => useStore((store) => store.certificates, shallow);

export const useOrders = () => useStore((state: RootStore) => state.orders);
const useRelevantOrders = (idFi?: number, idSubAccount?: number) =>
  useStore(relevantOrdersSelector)(idFi, idSubAccount);
const useActiveAndWaitingOrders = (
  idFI: number,
  selectedSubAccountId: number
) =>
  useStore((store) =>
    selectActiveAndWaitingOrders(idFI, selectedSubAccountId)(store)
  );

const useMarketBoards = () => useStore((state) => state.marketBoards);

const useCurrentCertificate = () => {
  const workingCertificate = useStore((store) => store.workingCertificate);
  const [
    certificateEnrollStep,
    certificateEnrollId,
    generateCertificate,
    setCertificateEnrollId,
  ] = useStore(
    (store) => [
      store.certificateEnrollStep,
      store.certificateEnrollId,
      store.generateCertificate,
      store.setCertificateEnrollId,
    ],
    shallow
  );

  return {
    certificateEnrollStep,
    certificateEnrollId,
    workingCertificate,
    generateCertificate,
    setCertificateEnrollId,
  };
};

const useOperations = () => useStore(recentOperationsSelector, shallow);
const useSubAccountPosition = () =>
  useStore((state) => state.subAccountPositions, shallow);
const useExtendSubAccountPosition = () =>
  useStore((state) => state.extendSubAccountPositions, shallow);
const useOrderParams = () => useStore((state) => state.orderParams);

const usePwaGlobalSubAccount = () =>
  useStore((state) => state.pwaGlobalSubAccount);

export type OverrideAlfaDirectProps =
  | Partial<AlfaDirectAPI>
  | ((alfaDirectProps: AlfaDirectAPI) => Partial<AlfaDirectAPI>);

export const useAlfaDirectProps = (override?: OverrideAlfaDirectProps) => {
  const [
    user,
    accounts,
    subAccountsList,
    subGTAccounts,
    subAccountRazdels,
    instrumentIcons,
  ] = useStore(
    (state) => [
      state.user,
      state.accounts,
      state.subAccounts,
      state.subGTAccounts,
      state.subAccountRazdel,
      state.instrumentIcons,
    ],
    shallow
  );

  const finInstrumentsTable = useStore((state) => state.finInstrumentsTable);

  const [objectTypesTable, marketBoardsTable] = useStore(
    (store) => [store.objectTypesTable, store.marketBoardsTable],
    shallow
  );

  const [enrollCertificate, confirmCertificate] = useStore(
    (store) => [store.enrollCertificate, store.confirmCertificate],
    shallow
  );

  const [objectsTable, objectExtsTable] = useStore(
    (state) => [state.objectsTable, state.objectExtsTable],
    shallow
  );

  const { tradingService, quotesService } = useServicesContext();

  const widgetProps: AlfaDirectAPI = useMemo(
    () => ({
      // accounts
      user,
      accounts,
      subAccountRazdels,
      subAccountsList,
      subGTAccounts,
      instrumentIcons,
      useSubAccountPosition,
      useExtendSubAccountPosition,
      // временное поле, нужно будет вынести отсюда
      usePwaGlobalSubAccount,

      // certs
      useCertificates,
      useCurrentCertificate,
      enrollCertificate,
      confirmCertificate,
      smsSignSendSms,
      smsSignCodeCheck,
      useConfirmCertificateSignOperation,
      useRejectCertificateSignOperation,

      // справочники
      marketBoardsTable: marketBoardsTable as MarketBoardItemsTable,
      objectTypesTable,
      objectsTable,
      objectExtsTable,
      finInstrumentsTable,
      // тоже справочник
      useFinInfoExtsList,

      // orders
      useOrders,
      useRelevantOrders,
      useActiveAndWaitingOrders,

      quotesService,
      useQuotes,
      // TODO BrokerageFeeService
      useBrokerageFee,
      // TODO StreamService
      useFinInfoExt,

      // services
      tradingService,
      TradesService,
      orderBookService,
      getServerTime,

      // other
      useOperations,
      loadMultimarkets,
      // TODO как засунуть внутрь фичи
      // сервис AllowedOrderParams
      useOrderParams,

      checkTradeLimits,
      useMarketBoards,
      useIndustries,
    }),
    [
      user,
      accounts,
      subAccountRazdels,
      subAccountsList,
      subGTAccounts,
      instrumentIcons,
      enrollCertificate,
      confirmCertificate,
      marketBoardsTable,
      objectTypesTable,
      objectsTable,
      objectExtsTable,
      finInstrumentsTable,
      quotesService,
      tradingService,
    ]
  );

  return useMemo(() => {
    const overrideProps =
      typeof override === 'function' ? override(widgetProps) : override;

    return { ...widgetProps, ...overrideProps };
  }, [widgetProps, override]);
};
