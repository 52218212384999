import cn from 'classnames';
import { FC, ReactNode } from 'react';
import {
  AmountInput,
  AmountInputProps,
} from '@alfalab/core-components/amount-input';
import { Typography } from '@alfalab/core-components/typography';
import { PauseCompactMIcon } from '@alfalab/icons-glyph/PauseCompactMIcon';
import { PlayCompactMIcon } from '@alfalab/icons-glyph/PlayCompactMIcon';

import { Stepper } from '@terminal/common/components/Stepper';
import { OrderType } from '@terminal/core/lib/client/entities';

import { AdditionalPriceSettings } from '../AdditionalPriceSettings';

import { PriceQuickControls } from '../../model/types';

import styles from './PriceInputComponent.module.css';

export interface PriceInputProps
  extends Pick<
    AmountInputProps,
    | 'disabled'
    | 'value'
    | 'minority'
    | 'suffix'
    | 'onChange'
    | 'onClick'
    | 'onFocus'
    | 'onBlur'
    | 'error'
    | 'currency'
  > {
  isChart: boolean;
  label?: ReactNode;
  orderType: OrderType;
  side: 'buy' | 'sell';
  withStepper?: boolean;
  onAdd: () => void;
  onSub: () => void;
  allowStreamPrice: boolean;
  isPriceStreamed: boolean;
  setPriceStreamed: (streamed: boolean) => void;
  limitPriceAdditional?: PriceQuickControls;
  withShortcuts?: boolean;
  containerClassName?: string;
}

export const PriceInputComponent: FC<PriceInputProps> = (props) => {
  const {
    isChart,
    label,
    orderType,
    side,
    withStepper = true,
    onAdd,
    onSub,
    allowStreamPrice,
    isPriceStreamed,
    setPriceStreamed,
    limitPriceAdditional,
    withShortcuts = false,
    containerClassName,
    ...restProps
  } = props;

  return (
    <div className={cn(containerClassName)}>
      {label ? (
        <Typography.Text
          view="secondary-small"
          color="secondary"
          className={cn(styles.label, styles.graphic)}
        >
          {label}
        </Typography.Text>
      ) : null}

      <AmountInput
        className={styles.customInput}
        fieldClassName={styles.customInputField}
        block
        rightAddons={
          orderType !== OrderType.MKT && (
            <div className={styles.priceAddons}>
              {allowStreamPrice && (
                <div
                  role="button"
                  className={cn(styles.playButton, {
                    [styles.playButtonActive]: isPriceStreamed,
                  })}
                  onClick={() => setPriceStreamed(!isPriceStreamed)}
                >
                  {isPriceStreamed ? (
                    <PauseCompactMIcon width={12} height={12} />
                  ) : (
                    <PlayCompactMIcon width={11} height={11} />
                  )}
                </div>
              )}
              {withStepper && (
                <Stepper
                  size="s"
                  onAdd={onAdd}
                  onSub={onSub}
                  className={styles.stepper}
                />
              )}
              {withShortcuts && limitPriceAdditional && (
                <AdditionalPriceSettings
                  isChart={isChart}
                  orderType={orderType}
                  side={side}
                  className={styles.additionalPrice}
                  {...limitPriceAdditional}
                />
              )}
            </div>
          )
        }
        {...restProps}
      />
    </div>
  );
};
