import debounce from 'lodash/debounce';
import log from 'loglevel';
import { z } from 'zod';

import { IS_GI, IS_MOBILE_PWA } from '../../../env';
import { getWatchLists, saveWatchLists } from '../../rest/lkSettings';
import { IDB } from '../';
import { WatchListsZod_V1 } from './version1';
import { createWatchLists, mergeDuplicates } from './watchListUtils';

import {
  getWatchListsFromLS,
  saveWatchListsToLS,
} from '../../../store/slices/helpers/watchListsHelpers';

import { AppState } from '../../../types/app';
import { WatchList } from '../../../types/watchList';

export type WatchListsTypeLast = z.infer<typeof WatchListsZod_V1.object>;

export class WatchLists {
  static ACTUAL_VERSION = 1;

  /**
   * Определяем какой конфиг применить с сервера или из БД
   * */
  static async init() {
    let actualConfig = {
      lists: getWatchListsFromLS(),
      saveTimestamp: Date.now(),
    };

    try {
      if (IS_GI) {
        const [localResult, serverResult] = await Promise.allSettled([
          this.get(),
          this.getFromServer(),
        ]);

        const localData =
          localResult.status === 'fulfilled' && localResult.value;
        const serverData =
          serverResult.status === 'fulfilled' && serverResult.value;

        const localTimestamp = localData ? localData.saveTimestamp : 0;
        const serverTimestamp = serverData ? serverData.saveTimestamp : 0;

        if (serverTimestamp > localTimestamp) {
          if (serverData) {
            actualConfig = serverData;
          }
        } else {
          if (localData) {
            actualConfig = localData;
          }
        }
      }
    } catch (e) {
      log.error('WatchLists init error: ', e);
    }

    return WatchLists.migration(actualConfig.lists);
  }

  static migration(prevWatchLists: WatchList[]): WatchList[] {
    return mergeDuplicates(createWatchLists(prevWatchLists));
  }

  static save = debounce((watchLists: WatchList[]) => {
    // @ts-ignore
    // TODO вынести логику про сохранение из useStore
    const { appState, credentials } = window.__zsstore.getState();

    if (appState !== AppState.READY || !credentials) {
      return;
    }

    const config = {
      lists: watchLists,
      saveTimestamp: Date.now(),
    };

    if (IS_MOBILE_PWA || WatchLists.ACTUAL_VERSION === 1) {
      saveWatchListsToLS(config);
    }

    if (IS_MOBILE_PWA) {
      return;
    }

    saveWatchLists(config, WatchLists.ACTUAL_VERSION);

    IDB.save<WatchListsTypeLast>('watchLists', WatchListsZod_V1.object, config);
  }, 100);

  static async get() {
    return IDB.get<WatchListsTypeLast>('watchLists');
  }

  static async getFromServer() {
    const data = await getWatchLists();

    if (data && data.hasOwnProperty('version')) {
      delete data.version;
    }

    return data;
  }
}
