import React, { useState } from 'react';
import { Tab, TabsDesktop } from '@alfalab/core-components/tabs/desktop';
import { SelectedId } from '@alfalab/core-components/tabs/typings';

import { WorkspaceConfiguration } from '@terminal/core/types/workspace';

import { WorkspaceConfigurationCustomList } from '../WorkspaceConfigurationCustomList';
import { WorkspaceConfigurationDefaultList } from '../WorkspaceConfigurationDefaultList';

import styles from './WorkspaceConfigurationsTabs.module.css';

enum TabsIds {
  CUSTOM,
  DEFAULT,
}

interface Props {
  otherConfigurations: WorkspaceConfiguration[];
}

const tabs = [
  {
    id: TabsIds.CUSTOM,
    name: 'Пользовательские',
  },
  {
    id: TabsIds.DEFAULT,
    name: 'Стандартные',
  },
];

export const WorkspaceConfigurationsTabs = ({ otherConfigurations }: Props) => {
  const [selectedTab, setSelectedTab] = useState(TabsIds.CUSTOM);

  const handleChangeTab = (
    _: React.MouseEvent<Element, MouseEvent>,
    payload: { selectedId: SelectedId }
  ) => {
    setSelectedTab(payload.selectedId as TabsIds);
  };

  return (
    <TabsDesktop
      selectedId={selectedTab}
      onChange={handleChangeTab}
      scrollable={false}
      size="s"
    >
      {tabs.map((tab) => {
        return (
          <Tab key={tab.id} id={tab.id} title={tab.name} className={styles.tab}>
            {tab.id === TabsIds.CUSTOM ? (
              <WorkspaceConfigurationCustomList
                otherConfigurations={otherConfigurations}
              />
            ) : (
              <WorkspaceConfigurationDefaultList />
            )}
          </Tab>
        );
      })}
    </TabsDesktop>
  );
};
