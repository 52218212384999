import { useEffect } from 'react';

import { useStore } from '@terminal/core/store';

import { useLoginPinCodeStore } from '../lib/storage';

export const usePinCode = (
  userWithoutBrokerageAccount: boolean,
  isWebView?: boolean
) => {
  const checkPinCodeAvailability = useStore(
    (state) => state.checkPinCodeAvailability
  );

  const hasPinCode = useLoginPinCodeStore((store) => store.hasPinCode);

  useEffect(() => {
    (async () => {
      const isPinCodeEnabled = isWebView ? false : undefined;

      checkPinCodeAvailability(
        await hasPinCode(),
        userWithoutBrokerageAccount ? false : isPinCodeEnabled
      );
    })();
  }, [
    checkPinCodeAvailability,
    hasPinCode,
    isWebView,
    userWithoutBrokerageAccount,
  ]);
};
