import { Suspense, VFC } from 'react';
import { Spinner } from '@alfalab/core-components/spinner';

import { BaseWidgetConfig, Widget } from '@terminal/core/types/layout';

import { LazyWidgetFactory } from '../../../../shared/ui/LazyWidgetFactory';

interface WidgetFactoryProps {
  config: BaseWidgetConfig;
  component: Widget;
  visible?: boolean;
  nodeId?: string;
}

export const WidgetFactory: VFC<WidgetFactoryProps> = (props) => {
  const { config, component, visible, nodeId } = props;

  return (
    <Suspense
      fallback={
        <div
          style={{ display: 'grid', placeContent: 'center', height: '100%' }}
        >
          <Spinner size="m" visible />
        </div>
      }
    >
      <LazyWidgetFactory
        config={config}
        component={component}
        visible={visible}
        nodeId={nodeId}
      />
    </Suspense>
  );
};
