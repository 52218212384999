import { Typography } from '@alfalab/core-components/typography';

import { ButtonItem } from '@terminal/common/components/Menu/types/ButtonItem';
import { MenuGroup } from '@terminal/common/src/components/Menu';
import { getColumnDescription } from '@terminal/core/lib/helpers/getColumnDescription';
import { TableColumnSetting } from '@terminal/core/types/layout';
import { TableColumnKey } from '@terminal/core/types/tableColumn';

import { getFullNameByKey } from '../../helpers';

import styles from './useMenuGroups.module.css';

export function useMenuGroups(
  filtersIdsVisible: string[],
  onClickItemFromSelect: (key: string) => void
): MenuGroup[] {
  const getItemFromKey = (key: string): ButtonItem => {
    return {
      key: key,
      type: 'button',
      label: getFullNameByKey(key),
      hint: getColumnDescription({
        key,
      } as TableColumnSetting) ? (
        <div className={styles.hintContainer}>
          <Typography.Text weight="bold" view="secondary-small">
            {getFullNameByKey(key)}
          </Typography.Text>
          <Typography.Text view="secondary-small">
            {getColumnDescription({
              key,
            } as TableColumnSetting)}
          </Typography.Text>
        </div>
      ) : undefined,
      checked: filtersIdsVisible.includes(key),
      onClick: () => onClickItemFromSelect(key),
      hideMenu: true,
    };
  };

  return [
    {
      key: '1',
      label: 'Популярное',
      items: [
        TableColumnKey.BsBondTypeName,
        TableColumnKey.BsCurrency,
        TableColumnKey.BsMatDate,
        TableColumnKey.BsYield,
        TableColumnKey.BsAgencyRating,
      ].map((key) => getItemFromKey(key)),
    },
    {
      key: '2',
      label: 'Эмитент',
      items: [TableColumnKey.BsSectorName, TableColumnKey.BsCountryName].map(
        (key) => getItemFromKey(key)
      ),
    },
    {
      key: '3',
      label: 'Цена и доходность',
      items: [
        TableColumnKey.BsPrice,
        TableColumnKey.BsNominal,
        TableColumnKey.BsYieldToOffert,
        TableColumnKey.BsCupYield,
      ].map((key) => getItemFromKey(key)),
    },
    {
      key: '4',
      label: 'Купон',
      items: [
        TableColumnKey.BsCupSize,
        TableColumnKey.BsIsFloatingRate,
        TableColumnKey.BsCouponPaymentPerYear,
      ].map((key) => getItemFromKey(key)),
    },
    {
      key: '5',
      label: 'Выпуск',
      items: [
        TableColumnKey.BsAmortization,
        TableColumnKey.BsIsOffert,
        TableColumnKey.BsOffertType,
        TableColumnKey.BsOffertDate,
        TableColumnKey.BsIsStructuredNote,
        TableColumnKey.BsIsReplacement,
      ].map((key) => getItemFromKey(key)),
    },
    {
      key: '6',
      label: 'Ограничения',
      items: [TableColumnKey.BsAllowNonQualInvest].map((key) =>
        getItemFromKey(key)
      ),
    },
  ];
}
