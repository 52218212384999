import inRange from 'lodash/inRange';

import { workspaceConfigurationDefault } from '../../../constants/workspaceConfigurations';
import { RootStore } from '../../useStore';

/**
 * Выбирает активную конфигурацию
 * */
export function activeConfigurationSelector(store: RootStore) {
  const {
    workspaceConfigurationActiveId: index,
    workspaceConfigurations,
    workspacesIsInitialized,
  } = store;

  if (!workspacesIsInitialized || !workspaceConfigurations.length) {
    return workspaceConfigurationDefault;
  }

  if (inRange(index, 0, workspaceConfigurations.length)) {
    return workspaceConfigurations[index];
  }

  return workspaceConfigurations[0];
}
