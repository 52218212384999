import {
  DockLocation,
  DropInfo,
  Node,
  Orientation,
  Rect,
} from 'flexlayout-react';

import { ElementType } from '@terminal/core/types/layout';

export const isDropAllow = (
  dragNode: Node,
  dropInfo: DropInfo,
  { row, col }: { row: number; col: number },
  mainId: string
) => {
  const dropNode = dropInfo.node;
  const dropOrientation = dropInfo.location.getOrientation();
  const dropLocation = dropInfo.location;
  const parent = dropNode.getParent();

  // Добавление вкладки (блокировать не нужно)
  if (dropLocation === DockLocation.CENTER) {
    //Если НЕ перемещаем внутри уже существующей группы
    if (dragNode.getParent()?.getId() !== dropInfo.node.getId()) {
      const dropRect = dropNode.getRect();

      dropInfo.rect = new Rect(dropRect.x, dropRect.y, dropRect.width, 24);
    }

    return true;
  }

  // Блокировка добавления более 4х столбцов
  if (
    col > 3 &&
    dropOrientation === Orientation.HORZ &&
    dropNode.getId() === mainId
  ) {
    return false;
  }

  // Блокировка добавления более 4х строк
  if (
    row > 3 &&
    dropOrientation === Orientation.VERT &&
    dropNode.getId() === mainId
  ) {
    return false;
  }

  //Если создаем новую колонку (глобальную)
  if (
    dropInfo.className === 'flexlayout__outline_rect_edge' &&
    dropOrientation === Orientation.HORZ &&
    col <= 3
  ) {
    return true;
  }

  //Если создаем новую строку (глобальную)
  if (
    dropInfo.className === 'flexlayout__outline_rect_edge' &&
    dropOrientation === Orientation.VERT &&
    row <= 3
  ) {
    return true;
  }

  // Блокировка добавления более 4х табов в столбец
  if (
    parent?.getType() === ElementType.ROW &&
    Number(parent?.getChildren()?.length) > 3 &&
    mainId !== parent?.getId()
  ) {
    return false;
  }

  // Блокировка добавления более 4х столбцов
  return !(
    col >= 4 &&
    (dropNode.getType() === ElementType.ROW ||
      dropOrientation === Orientation.HORZ)
  );
};
