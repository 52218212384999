import { Fragment, useMemo } from 'react';
import { BaseModal } from '@alfalab/core-components/base-modal';
import { ButtonDesktop as Button } from '@alfalab/core-components/button/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';
import { ExclamationCircleMIcon } from '@alfalab/icons-glyph/ExclamationCircleMIcon';

import { useAlfaDirectContext, useLast } from '@terminal/alfadirect/hooks';
import { OrderItem } from '@terminal/core/types/order';
import {
  InputSendOrderParams,
  SendOrderParams,
} from '@terminal/core/types/trading';

import styles from './TextOrder.module.css';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  params?: SendOrderParams & InputSendOrderParams;
  prevOrder?: OrderItem;
}

interface ModalWrapperProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ModalWrapper = ({
  children,
  isOpen,
  onClose,
  onConfirm,
}: ModalWrapperProps) => (
  <BaseModal
    open={isOpen}
    onClose={onClose}
    contentClassName={styles.desktopModalContent}
    className={styles.desktopModal}
  >
    <div className={styles.header}>
      <Typography.Text view="primary-medium" weight="bold">
        Текстовое поручение
      </Typography.Text>
      <CrossMIcon
        className={styles.closeIcon}
        width={18}
        height={18}
        onClick={() => {
          onClose();
        }}
      />
    </div>
    {children}
    <div className={styles.closeActionButtons}>
      <Button
        view="tertiary"
        size="xs"
        block
        onClick={onClose}
        leftAddons={
          <CrossMIcon
            width={18}
            height={18}
            color="var(--color-dark-graphic-secondary)"
          />
        }
      >
        Отмена
      </Button>
      <Button
        view="secondary"
        size="xs"
        block
        onClick={onConfirm}
        leftAddons={
          <CheckmarkMIcon
            width={18}
            height={18}
            color="var(--color-dark-graphic-secondary)"
          />
        }
      >
        Отправить
      </Button>
    </div>
  </BaseModal>
);

export const TextOrder = ({
  isOpen,
  onClose,
  onConfirm,
  params,
  prevOrder,
}: Props) => {
  const { tradingService } = useAlfaDirectContext();

  // Берем ласт цену
  const last = useLast(params?.idFi ?? 0);

  const { hasError, text } = useMemo(() => {
    if (params) {
      try {
        const text = tradingService.getNewOrderText({
          ...params,
          last,
        });

        if (text) {
          return { hasError: false, text };
        } else {
          return { hasError: true };
        }
      } catch (error) {
        return { hasError: true };
      }
    } else {
      return { hasError: false };
    }
  }, [tradingService, last, params]);

  const { hasSecondaryError, secondaryText } = useMemo(() => {
    if (prevOrder) {
      try {
        const secondaryText = tradingService.getKillOrderText(prevOrder);

        if (secondaryText) {
          return { hasSecondaryError: false, secondaryText };
        } else {
          return { hasSecondaryError: true };
        }
      } catch (error) {
        return { hasSecondaryError: true };
      }
    } else {
      return { hasSecondaryError: false };
    }
  }, [tradingService, prevOrder]);

  return (
    <>
      <ModalWrapper isOpen={isOpen} onClose={onClose} onConfirm={onConfirm}>
        {!hasError && !hasSecondaryError && (params || prevOrder) ? (
          <>
            {secondaryText &&
              secondaryText.map((deleteText, index) => (
                <Fragment key={index}>
                  {params && (
                    <span className={styles.orderTextSectionTitle}>
                      Поручение на отмену заявки
                    </span>
                  )}
                  <Typography.Text
                    view="primary-medium"
                    className={styles.orderText}
                  >
                    {deleteText}
                  </Typography.Text>
                </Fragment>
              ))}

            {text && (
              <>
                {prevOrder && (
                  <span className={styles.orderTextSectionTitle}>
                    Поручение на выставление новой заявки
                  </span>
                )}
                <Typography.Text
                  view="primary-medium"
                  className={styles.orderText}
                >
                  {text}
                </Typography.Text>
              </>
            )}
          </>
        ) : (
          <div className={styles.orderTextErrorWrapper}>
            <ExclamationCircleMIcon className={styles.orderTextErrorIcon} />
            <span className={styles.orderTextErrorTitle}>
              Не удалось сформировать поручение
            </span>
            <Typography.Text view="primary-medium">
              Что-то пошло не так. Проверьте данные и попробуйте ещё раз
            </Typography.Text>
          </div>
        )}
      </ModalWrapper>
    </>
  );
};
