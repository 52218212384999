import { CrossHeavyMIcon } from '@alfalab/icons-glyph/CrossHeavyMIcon';

import { LKResult } from '@terminal/core/lib/rest/lkApi';

import { ManagementResultType } from '../../../entities/AccountsOperations';
import { CREATE_ATTORNEY_FULL_TEXT_ERROR_MAP } from './const/createAttorneyErrors';

export const getCreateAttorneyErrorResult = (
  error?: LKResult
): ManagementResultType => {
  const result = error?.code
    ? CREATE_ATTORNEY_FULL_TEXT_ERROR_MAP?.[String(error.code)]
    : undefined;

  if (!result) {
    const defaultMsg = 'Повторите попытку позже';
    const description =
      error?.message !== 'Failed to fetch'
        ? error?.message || defaultMsg
        : defaultMsg;

    return {
      icon: <CrossHeavyMIcon />,
      message: 'Мы не смогли оформить доверенность',
      description,
    };
  }

  return result;
};
