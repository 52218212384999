import {
  BalanceProps,
  BondScreenerProps,
  ChartProps,
  HybridOrderProps,
  InstrumentInfoProps,
  OperationsProps,
  OrderBookProps,
  OrderTableProps,
  PositionProps,
  TradesProps,
  WatchListProps,
  Widget,
} from '@terminal/core/types/layout';

export const isOrderBookProps = (
  props: any,
  type: Widget
): props is OrderBookProps => type === Widget.ORDERBOOK;

export const isTradesProps = (props: any, type: Widget): props is TradesProps =>
  type === Widget.TRADES;

export const isChartProps = (props: any, type: Widget): props is ChartProps =>
  type === Widget.CHART;

export const isWatchListProps = (
  props: any,
  type: Widget
): props is WatchListProps => type === Widget.WATCHLIST;

export const isBalanceProps = (
  props: any,
  type: Widget
): props is BalanceProps => type === Widget.BALANCE;

export const isOrderTableProps = (
  props: any,
  type: Widget
): props is OrderTableProps => type === Widget.ORDERS;

export const isPositionProps = (
  props: any,
  type: Widget
): props is PositionProps => type === Widget.POSITIONS;

export const isOperationProps = (
  props: any,
  type: Widget
): props is OperationsProps => type === Widget.OPERATIONS;

export const isHybridOrderProps = (
  props: any,
  type: Widget
): props is HybridOrderProps => type === Widget.HYBRIDORDER;

export const isInstrumentInfoProps = (
  props: any,
  type: Widget
): props is InstrumentInfoProps => type === Widget.INSTRUMENT_INFO;

export const isNewsProps = (props: any, type: Widget) => type === Widget.NEWS;

export const isBondScreenerProps = (
  props: any,
  type: Widget
): props is BondScreenerProps => type === Widget.BOND_SCREENER;
