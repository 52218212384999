import { dateToRequestFormat } from '@terminal/core/lib/dateToRequestFormat';
import {
  ActiveTransfer,
  SecuritiesDocumentType,
  SecuritiesManager,
  SecuritiesPlaceItem,
  SecuritiesPosition,
  SecuritiesReasonDocument,
  SecuritiesTransferOutActive,
  SecuritiesTransferOutBase,
  SecuritiesUserType,
} from '@terminal/core/lib/rest/lkSecurities';

import {
  AMOUNT_MINORITY,
  COUNTERAGENT_TYPES,
  getMarket,
  isAccountTypeRequired,
  isConteragentAccDepoRequired,
  isContrTreatyRequired,
  isCounterpartyRequired,
  isDepoAccountCounterpartyRequired,
  isDepoCodeCounterpartyRequired,
  isDepoSectionCounterpartyRequired,
  isDocumentsRequired,
  isLegalFieldsRequired,
  isOrgNameRequired,
  isOtherPlaceNameRequired,
  isPersonalAccount,
  isRequisitesRequired,
  isSwiftRequired,
  SecurityDeponentPlaceCode,
} from '../../shared';
import {
  isAccountTypeValid,
  isDateValid,
  isDepoAccountValid,
  isDepoCodeValid,
  isDepoSectionValid,
  isFieldFilled,
  isReasonDocumentsValid,
  isSwiftValid,
  validateConteragentAccDepo,
  validateCounterpartyPlace,
  validateCounterpartyPlaceOther,
} from '../../validation';

export interface SecuritiesOutFormData {
  selectedActives: ActiveTransfer[];
  positionFrom: SecuritiesPosition | null;
  ownershipChange: boolean;
  marketTo: string;
  accountType: string;
  counterpartyTo: string;
  counterpartyToOther: string;
  depoCodeTo: string;
  depoAccountTo: string;
  depoSectionTo: string;
  contragentAccDepoTo: string;
  treatyTo: number | null;
  contrAlfaBank: boolean;
  swift: string;
  persDocNumber: string;
  persDocDate: string;
  persDocOrg: string;
  orgName: string;
  orgOGRN: string;
  orgWhen: string;
  orgWhom: string;
  reasonDocuments: SecuritiesReasonDocument[];
  reasonOther: string;
  dealSumm: number | null;
  dealCurrency: string;
}

export interface SourceStepValidateProps {
  positionFrom: SecuritiesPosition | null;
  ownershipChange: boolean;
  reasonDocuments: SecuritiesReasonDocument[];
  dealSumm: number | null;
  dealCurrency: string;
  availableDocumentTypes: SecuritiesDocumentType[] | undefined;
  dealSummRequired: boolean;
}

export function validateSourceStep(data: SourceStepValidateProps) {
  return (
    Boolean(data.positionFrom) &&
    (!isDocumentsRequired(data.ownershipChange) ||
      isReasonDocumentsValid(
        data.reasonDocuments,
        data.availableDocumentTypes
      )) &&
    (!data.dealSummRequired ||
      Boolean(data.dealSumm && data.dealSumm > 0 && data.dealCurrency))
  );
}

export interface SecurityStepValidateProps {
  selectedActives: ActiveTransfer[];
  marketTo: string;
}

export function validateSecurityStep(data: SecurityStepValidateProps) {
  return data.selectedActives.length > 0 && Boolean(data.marketTo);
}

interface CounterpartyStepValidateProps {
  positionFrom: SecuritiesPosition | null;
  placeTo: SecuritiesPlaceItem | null;
  marketTo: string;
  accountType: string;
  counterpartyTo: string;
  counterpartyToOther: string;
  depoCodeTo: string;
  depoAccountTo: string;
  depoSectionTo: string;
  contragentAccDepoTo: string;
  treatyTo: number | null;
  contrAlfaBank: boolean;
  swift: string;
  persDocNumber: string;
  persDocDate: string;
  persDocOrg: string;
  orgName: string;
  orgOGRN: string;
  orgWhen: string;
  orgWhom: string;

  ownershipChange: boolean;
  requisitesCorrect: boolean;
  manager: SecuritiesManager | undefined;
  counteragentType: COUNTERAGENT_TYPES | undefined;
}

export function validationCounterpartyStep(
  data: CounterpartyStepValidateProps
) {
  const MARKET = getMarket(
    data.positionFrom ? data.positionFrom.placeCode : '',
    data.marketTo
  );
  const MARKET_ALFA = data.positionFrom ? data.positionFrom.placeCode : '';
  const DEPONENT_PLACE_CODE_ALFA = data.placeTo
    ? (data.placeTo.deponetPlaceCode as SecurityDeponentPlaceCode)
    : SecurityDeponentPlaceCode.EMPTY;

  const otherPlaceNameRequired = isOtherPlaceNameRequired(
    data.marketTo,
    data.contrAlfaBank
  );
  const counterpartyRequired = isCounterpartyRequired(
    data.marketTo,
    data.accountType,
    data.contrAlfaBank
  );
  const depoAccountCounterpartyRequired = isDepoAccountCounterpartyRequired(
    data.contrAlfaBank
  );
  const depoCodeCounterpartyRequired = isDepoCodeCounterpartyRequired(
    MARKET,
    MARKET_ALFA,
    data.contrAlfaBank
  );
  const treatyCounterpartyReqired = isContrTreatyRequired(
    data.ownershipChange,
    'out',
    data.contrAlfaBank
  );
  const depoSectionCounterpartyRequired = isDepoSectionCounterpartyRequired(
    MARKET,
    MARKET_ALFA,
    data.contrAlfaBank
  );
  const accountTypeRequired = isAccountTypeRequired(
    data.marketTo,
    data.contrAlfaBank
  );
  const requisitesRequired = isRequisitesRequired(data.marketTo);
  const legalFieldsRequired = isLegalFieldsRequired({
    manager: data.manager,
    counteragentType: data.counteragentType,
    ownershipChange: data.ownershipChange,
  });

  return (
    Boolean(data.positionFrom) &&
    Boolean(data.marketTo) &&
    (!otherPlaceNameRequired ||
      validateCounterpartyPlaceOther(data.counterpartyToOther) === '') &&
    (!counterpartyRequired ||
      validateCounterpartyPlace(data.counterpartyTo) === '') &&
    (!depoCodeCounterpartyRequired ||
      isDepoCodeValid(data.depoCodeTo, data.marketTo)) &&
    (!depoAccountCounterpartyRequired ||
      isDepoAccountValid(data.depoAccountTo, MARKET)) &&
    (!depoSectionCounterpartyRequired ||
      isDepoSectionValid(
        data.depoSectionTo,
        data.marketTo,
        MARKET_ALFA,
        DEPONENT_PLACE_CODE_ALFA
      )) &&
    (!isConteragentAccDepoRequired(data.contrAlfaBank) ||
      validateConteragentAccDepo(data.contragentAccDepoTo) === '') &&
    (!treatyCounterpartyReqired || Boolean(data.treatyTo)) &&
    (!isSwiftRequired(MARKET, MARKET_ALFA, data.contrAlfaBank) ||
      isSwiftValid(data.swift)) &&
    (!accountTypeRequired || isAccountTypeValid(data.accountType)) &&
    (!requisitesRequired ||
      !isPersonalAccount({
        manager: data.manager,
        counteragentType: data.counteragentType,
      }) ||
      (data.manager && Boolean(data.manager.managerFullName))) &&
    (!requisitesRequired ||
      !isPersonalAccount({
        manager: data.manager,
        counteragentType: data.counteragentType,
      }) ||
      (isFieldFilled(data.persDocNumber) &&
        isFieldFilled(data.persDocOrg) &&
        isDateValid(data.persDocDate))) &&
    (!requisitesRequired ||
      !isOrgNameRequired({
        accountType: data.accountType,
        manager: data.manager,
        counteragentType: data.counteragentType,
      }) ||
      isFieldFilled(data.orgName)) &&
    (!requisitesRequired ||
      !legalFieldsRequired ||
      isFieldFilled(data.orgOGRN)) &&
    (!requisitesRequired ||
      !legalFieldsRequired ||
      isDateValid(data.orgWhen)) &&
    (!requisitesRequired ||
      !legalFieldsRequired ||
      isFieldFilled(data.orgWhom)) &&
    (!requisitesRequired || data.requisitesCorrect)
  );
}

export function validateForm(
  data: SourceStepValidateProps &
    SecurityStepValidateProps &
    CounterpartyStepValidateProps
) {
  return (
    validateSourceStep(data) &&
    validateSecurityStep(data) &&
    validationCounterpartyStep(data)
  );
}

interface BuildTransferData extends SecuritiesOutFormData {
  treaty: number;
  positionFrom: SecuritiesPosition;
  target: SecuritiesPlaceItem;
  userType: SecuritiesUserType | undefined;
  manager: SecuritiesManager | undefined;
  counteragentType: COUNTERAGENT_TYPES | undefined;
  dealSummRequired: boolean;
}

export function buildTransferData({
  selectedActives,
  positionFrom,
  ownershipChange,
  marketTo,
  accountType,
  counterpartyTo,
  counterpartyToOther,
  depoCodeTo,
  depoAccountTo,
  depoSectionTo,
  contragentAccDepoTo,
  treatyTo,
  contrAlfaBank,
  swift,
  persDocNumber,
  persDocDate,
  persDocOrg,
  orgName,
  orgOGRN,
  orgWhen,
  orgWhom,
  reasonDocuments,
  reasonOther,
  dealSumm,
  dealCurrency,

  treaty,
  target,
  userType,
  manager,
  counteragentType,
  dealSummRequired,
}: BuildTransferData): SecuritiesTransferOutActive[] {
  const MARKET = getMarket(
    positionFrom ? positionFrom.placeCode : '',
    marketTo
  );
  const MARKET_ALFA = positionFrom ? positionFrom.placeCode : '';
  const source = positionFrom;
  const documentsRequired = isDocumentsRequired(ownershipChange);
  const documents = reasonDocuments.map((document) => {
    return {
      ...document,
      date: dateToRequestFormat(document.date),
    };
  });
  const personalDocRequired =
    isRequisitesRequired(marketTo) &&
    isPersonalAccount({
      manager,
      counteragentType,
    });
  const treatyCounterpartyReqired = isContrTreatyRequired(
    ownershipChange,
    'out',
    contrAlfaBank
  );
  const legalFieldsRequired =
    isRequisitesRequired(marketTo) &&
    isLegalFieldsRequired({
      manager,
      counteragentType,
      ownershipChange,
    });

  const accountTypeRequired = isAccountTypeRequired(marketTo, contrAlfaBank);

  const actives = [...selectedActives];

  const baseData: SecuritiesTransferOutBase = {
    treaty: treaty,
    srcAccCode: source.accCode,
    srcPlaceCode: source.placeCode,
    srcDeponetPlaceCode: source.deponetPlaceCode,
    srcPlaceName: source.placeName,
    srcDepoAccount: source.depoAccount,
    srcDepositary: source.depositary,
    srcDpName: source.dpName,

    changeOfQwner: ownershipChange,
    targetPlaceCode: target.placeCode,
    targetDeponetPlaceCode: target.deponetPlaceCode,
    targetPlaceName: target.name,
    targetOtherPlace: isOtherPlaceNameRequired(marketTo, contrAlfaBank)
      ? counterpartyToOther
      : undefined,
    targetContragent: isCounterpartyRequired(
      marketTo,
      accountType,
      contrAlfaBank
    )
      ? counterpartyTo
      : undefined,
    targetDeponentCode: isDepoCodeCounterpartyRequired(
      MARKET,
      MARKET_ALFA,
      contrAlfaBank
    )
      ? depoCodeTo
      : undefined,
    targetContragentAccount: isDepoAccountCounterpartyRequired(contrAlfaBank)
      ? depoAccountTo
      : '',
    targetDepoAccountPart: isDepoSectionCounterpartyRequired(
      MARKET,
      MARKET_ALFA,
      contrAlfaBank
    )
      ? depoSectionTo
      : undefined,
    targetContragentAccDepo: isConteragentAccDepoRequired(contrAlfaBank)
      ? contragentAccDepoTo
      : undefined,
    targetTreaty: treatyCounterpartyReqired ? treatyTo || 0 : undefined,
    swiftCode: isSwiftRequired(MARKET, MARKET_ALFA, contrAlfaBank)
      ? swift
      : undefined,
    isInternal: contrAlfaBank,

    // Реестр
    targetAccountType: accountTypeRequired ? accountType : undefined,
    targetAccountTypeDescription: accountTypeRequired
      ? userType?.description || ''
      : undefined,
    targetPersonalName: personalDocRequired
      ? manager?.managerFullName || ''
      : undefined,
    targetPersonalDocNumber: personalDocRequired ? persDocNumber : undefined,
    targetPersonalDocDate: personalDocRequired ? persDocDate : undefined,
    targetPersonalDocOrg: personalDocRequired ? persDocOrg : undefined,
    targetOrgName:
      isRequisitesRequired(marketTo) &&
      isOrgNameRequired({ accountType, manager, counteragentType })
        ? orgName
        : undefined,
    targetOrgOgrn: legalFieldsRequired ? orgOGRN : undefined,
    targetOrgRegDate: legalFieldsRequired
      ? dateToRequestFormat(orgWhen)
      : undefined,
    targetOrgRegOrg: legalFieldsRequired ? orgWhom : undefined,

    comment: reasonOther,
    documents: documentsRequired ? documents : undefined,

    tradeVolume: dealSummRequired
      ? (dealSumm || 0) / AMOUNT_MINORITY
      : undefined,
    tradeCurrCode: dealSummRequired ? dealCurrency : undefined,
  };

  return actives.map((active) => ({
    ...baseData,
    pCode: active.paper.pCode,
    isin: active.paper.isin,
    qty: (active.count || 0) / AMOUNT_MINORITY,
    tradeDate: dateToRequestFormat(active.dealDate),
    tradeDateDelivery: dateToRequestFormat(active.shipDate),
    reference: active.reference,
    gbox: active.gbox,
  }));
}
