import {
  Actions,
  BorderNode,
  DockLocation,
  ITabAttributes,
  Layout as GridLayout,
  Model,
  Node,
  TabSetNode,
} from 'flexlayout-react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { v4 } from 'uuid';

import {
  FEATURE_FLAG_CODE,
  featureFlags,
} from '@terminal/common/entities/ServiceNotifications';
import {
  DEFAULT_WIDGET_HEIGHT,
  DEFAULT_WIDGET_WIDTH,
  WidgetsDefaultConfigMap,
  WidgetsDefaultSizeMap,
  WidgetsNameMap,
} from '@terminal/core/constants/Layout';
import { trackOnAddDesktop } from '@terminal/core/lib/analytics';
import { trackBondScreenerWidget } from '@terminal/core/lib/analytics/bondScreener/bondScreener';
import { sessionStorage } from '@terminal/core/lib/storages';
import { shallow, useStore } from '@terminal/core/store';
import { activeConfigurationSelector } from '@terminal/core/store/selectors/workspaceConfigurations/activeConfigurationSelector';
import {
  getFlexNodeById,
  getNodeById,
} from '@terminal/core/store/slices/helpers/layout';
import {
  ElementType,
  FlexLayoutModel,
  FlexNode,
  GridLayoutModel,
  LayoutType,
  Widget,
} from '@terminal/core/types/layout';
import { WidgetApi, WidgetConfig } from '@terminal/widgets/shared/model';

import { trackCreateLayout } from '../lib/analytics';

export const PERFOMANCE_BANNER_SHOWN_KEY = 'perfomanceBannerShown';

interface LayoutProps {
  activeLayoutKey?: number;
}

export const useLayout = ({ activeLayoutKey }: LayoutProps) => {
  const maxFlexLayoutSize = featureFlags.get(
    FEATURE_FLAG_CODE.DRAGGABLE_LAYOUT_MAX_AVAILABLE_WIDGETS
  );
  const [perfomanceBannerShown, setPerfomanceBannerShown] = useState(
    sessionStorage.getItem<boolean>(PERFOMANCE_BANNER_SHOWN_KEY)
  );

  const [getActiveLayoutType, setLayoutJson, createNewLayout, unlinkFromGroup] =
    useStore(
      (state) => [
        state.getActiveLayoutType,
        state.setLayoutJson,
        state.createNewLayout,
        state.unlinkFromGroup,
      ],
      shallow
    );
  const { lastAutoSavedConfig } = useStore(activeConfigurationSelector);
  const layoutType = getActiveLayoutType();
  const { layouts, models, chart } = lastAutoSavedConfig;

  const gridLayoutRef = useRef<GridLayout>(null);
  const flexLayoutRef = useRef<HTMLDivElement>(null);

  const layoutPerfomanceSize = useMemo(() => {
    if (
      activeLayoutKey !== undefined &&
      layoutType === LayoutType.Flex &&
      !perfomanceBannerShown
    ) {
      const model = layouts[activeLayoutKey] as FlexLayoutModel;

      if (model.layout.length > maxFlexLayoutSize) {
        return maxFlexLayoutSize;
      }
    }
  }, [
    activeLayoutKey,
    layoutType,
    layouts,
    maxFlexLayoutSize,
    perfomanceBannerShown,
  ]);

  const closePerfomanceBanner = useCallback(() => {
    setPerfomanceBannerShown(true);
    sessionStorage.setItem(PERFOMANCE_BANNER_SHOWN_KEY, true);
  }, []);

  const getSize = useCallback(() => {
    let height: number = 0,
      width: number = 0;

    if (
      layoutType === LayoutType.Flex &&
      flexLayoutRef &&
      flexLayoutRef.current
    ) {
      const flexRect = flexLayoutRef.current.getBoundingClientRect();

      height = flexRect.height;
      width = flexRect.width;
    }

    if (gridLayoutRef && gridLayoutRef.current) {
      const gridRect = gridLayoutRef.current?.state.rect;

      if (gridRect) {
        height = gridRect.height;
        width = gridRect.width - 4;
      }
    }

    if (!height || !width) {
      height = document.body.clientHeight;
      width = document.body.clientWidth;
    }

    return { height, width };
  }, [layoutType]);

  // Добавление виджета из шапки терминала
  const onAddFromMenu = useCallback(
    (component: Widget, config?: WidgetConfig, name?: string) => {
      if (layoutType === LayoutType.Flex) {
        if (activeLayoutKey === undefined) {
          return;
        }

        const model = layouts[activeLayoutKey] as FlexLayoutModel;
        const { layout } = model;

        const { width: layoutWidth, height: layoutHeight } = getSize();

        const size = WidgetsDefaultSizeMap.get(component);

        const { width, height } = size ?? {
          width: Math.round(layoutWidth * DEFAULT_WIDGET_WIDTH),
          height: Math.round(layoutHeight * DEFAULT_WIDGET_HEIGHT),
        };

        const x = layoutWidth / 2 - width / 2;
        const y = layoutHeight / 2 - height / 2;

        setLayoutJson(activeLayoutKey, {
          ...model,
          layout: [
            ...layout,
            {
              id: v4(),
              weight: layout.length,
              position: {
                x,
                y,
              },
              size: {
                width,
                height,
              },
              children: [
                {
                  nodeId: v4(),
                  component,
                  name: name ?? WidgetsNameMap.get(component),
                  ...WidgetsDefaultConfigMap.get(component),
                  ...config,
                  visible: true,
                },
              ],
            },
          ],
        });
      } else {
        gridLayoutRef.current?.addTabToActiveTabSet({
          type: ElementType.TAB,
          name: WidgetsNameMap.get(component),
          component,
          config: WidgetsDefaultConfigMap.get(component),
        });
      }

      if (component === Widget.BOND_SCREENER) {
        trackBondScreenerWidget.add();
      }
    },
    [activeLayoutKey, getSize, layoutType, layouts, setLayoutJson]
  );

  // Добавление виджета из группы вкладок
  const onAddFromTabSetButton = (
    node: TabSetNode | BorderNode,
    key: Widget
  ) => {
    gridLayoutRef.current?.addTabToTabSet(node.getId(), {
      type: ElementType.TAB,
      name: WidgetsNameMap.get(key),
      component: key,
      config: WidgetsDefaultConfigMap.get(key),
    });

    if (key === Widget.BOND_SCREENER) {
      trackBondScreenerWidget.add();
    }
  };

  const createLayout = useCallback(
    (type: LayoutType) => {
      createNewLayout(getSize(), type);
      trackCreateLayout({ layoutsCount: layouts.length + 1, type });
      trackOnAddDesktop();
    },
    [createNewLayout, getSize, layouts]
  );

  const addWidget = useCallback<WidgetApi['addWidget']>(
    (name, widgetType, config) => {
      if (activeLayoutKey === undefined) {
        return;
      }

      const layout = layouts[activeLayoutKey];

      if (layout.type === LayoutType.Flex) {
        onAddFromMenu(widgetType, config, name);
      } else {
        const model = models[activeLayoutKey] ?? Model.fromJson(layout);

        const activeTabSet = model.getActiveTabset();
        const activeTabSetId = activeTabSet?.getId();
        const children = activeTabSet?.getChildren().length ?? 0;

        if (activeTabSetId) {
          const component = {
            type: ElementType.TAB,
            name,
            component: widgetType,
            config,
          };

          model.doAction(
            Actions.addNode(
              component,
              activeTabSetId,
              DockLocation.CENTER,
              children,
              true
            )
          );
        }
      }
    },
    [activeLayoutKey, layouts, models, onAddFromMenu]
  );

  const cloneWidget = useCallback<WidgetApi['cloneWidget']>(
    (nodeId?: string) => {
      if (activeLayoutKey === undefined) {
        return;
      }

      const layout = layouts[activeLayoutKey];

      if (layout.type === LayoutType.Flex) {
        const flexNode = getNodeById(layout, models[activeLayoutKey], nodeId);

        onAddFromMenu(flexNode.widget!);
      } else {
        const model = models[activeLayoutKey] ?? Model.fromJson(layout);

        if (model) {
          const node = model.getNodeById(nodeId || '');
          const activeTabSet = model.getActiveTabset();
          const activeTabSetId = activeTabSet?.getId();
          const tabsAmount = activeTabSet?.getChildren().length ?? 0;

          const component = node?.toJson() as ITabAttributes;

          if (activeTabSetId) {
            delete component.id;

            model.doAction(
              Actions.addNode(
                component,
                activeTabSetId,
                DockLocation.CENTER,
                tabsAmount,
                true
              )
            );
          }
        }
      }
    },
    [activeLayoutKey, layouts, models, onAddFromMenu]
  );

  const deleteWidget = useCallback<WidgetApi['deleteWidget']>(
    (nodeId, link, attributes) => {
      if (activeLayoutKey === undefined) {
        return;
      }

      const layout = layouts[activeLayoutKey];

      if (layoutType === LayoutType.Flex) {
        return;
      } else {
        const model =
          models[activeLayoutKey] ?? Model.fromJson(layout as GridLayoutModel);
        const node = model.getNodeById(nodeId || '');
        const tabSet = node?.getParent();

        if (nodeId) {
          if (link !== undefined) {
            unlinkFromGroup(nodeId, link);
          }

          if (tabSet && attributes) {
            model.doAction(
              Actions.updateNodeAttributes(tabSet.getId(), attributes)
            );
          }

          model.doAction(Actions.deleteTab(nodeId));
        }
      }
    },
    [activeLayoutKey, layouts, layoutType, models, unlinkFromGroup]
  );

  const getNode = useCallback<WidgetApi['getNode']>(
    (nodeId) => {
      if (activeLayoutKey === undefined) {
        return;
      }

      const layout = layouts[activeLayoutKey];
      let node: FlexNode | Node | undefined;

      if (layout.type === LayoutType.Flex) {
        node = getFlexNodeById(layout, nodeId);
      } else {
        const model = models[activeLayoutKey] ?? Model.fromJson(layout);

        node = model.getNodeById?.(nodeId || '');
      }

      if (!node) {
        return;
      }

      const getRect = () =>
        typeof node?.['getRect'] === 'function'
          ? (node as Node).getRect()
          : {
              ...(node as FlexNode).size,
              ...(node as FlexNode).position,
            };

      return {
        getRect,
        chartSettings: chart[nodeId!]?.customSettings,
      };
    },
    [activeLayoutKey, chart, layouts, models]
  );

  return {
    gridLayoutRef,
    flexLayoutRef,
    layoutType,
    onAddFromMenu,
    onAddFromTabSetButton,
    createLayout,
    layoutPerfomanceSize,
    closePerfomanceBanner,
    addWidget,
    cloneWidget,
    deleteWidget,
    getNode,
  };
};
