/**
 * @param forms 3 формы склонения в формате [слово, слова, слов]
 * @param num Число для склоняемого слова
 * @returns правильную форму склонения слова для числа
 * */
export function plural(forms: [string, string, string], num: number): string {
  const n = Math.abs(num);
  const [f1, f2, f3] = forms;

  if (n % 10 === 1 && n % 100 !== 11) {
    return f1;
  }

  if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) {
    return f2;
  }

  return f3;
}
