import React, { useMemo } from 'react';

import {
  ConfigStyle,
  isConfigAvailable,
  useOnboardingConfigStore,
} from '@terminal/desktop/entities/OnboardingConfig';

import { ReactComponent as ConfigDayTrade } from '../../assets/config_daytrade.svg';
import { ReactComponent as ConfigScalping } from '../../assets/config_scalping.svg';
import { ReactComponent as ConfigSwing } from '../../assets/config_swing.svg';
import { ConfigChoiceButton } from '../ConfigChoiceButton';
import { ConfigurationTooltip } from '../ConfigurationTooltip';
import { InfoHeading } from '../InfoHeading';

import styles from './OnboardingWorkspace.module.css';

const choiceIcons = {
  configSwing: ConfigSwing,
  configDaytrade: ConfigDayTrade,
  configScalping: ConfigScalping,
};

interface ChoiceButton {
  title: string;
  subtitle: React.ReactNode;
  icon: keyof typeof choiceIcons;
  id: ConfigStyle;
}

const choiceButtons: ChoiceButton[] = [
  {
    title: 'Среднесрочная торговля',
    subtitle: (
      <>
        В диапазоне от дня
        <br />
        до недели
      </>
    ),
    icon: 'configSwing',
    id: ConfigStyle.SWING,
  },
  {
    title: 'Дневная торговля',
    subtitle: 'Совершение сделок в рамках торгового дня',
    icon: 'configDaytrade',
    id: ConfigStyle.DAYTRADE,
  },
  {
    title: 'Скальпинг',
    subtitle: 'Совершение небольших сделок в большом количестве',
    icon: 'configScalping',
    id: ConfigStyle.SCALPING,
  },
];

export const OnboardingWorkspace = () => {
  const [configSize, configStyle, setConfigStyle] = useOnboardingConfigStore(
    (store) => [store.configSize, store.configStyle, store.setConfigStyle]
  );
  const availableStyles = useMemo(
    () =>
      choiceButtons.filter((button) =>
        isConfigAvailable(configSize, button.id)
      ),
    [configSize]
  );

  return (
    <>
      <InfoHeading
        title="Конфигурация рабочего стола"
        description={
          <>
            Выберите конфигурацию рабочего стола в соответствии с вашим стилем
            <br />
            торговли. Вы сможете изменить или настроить ее в любой момент.
          </>
        }
      />
      <div className={styles.firstStepChoice}>
        {availableStyles.map((button) => (
          <ConfigChoiceButton
            key={button.id}
            title={button.title}
            subtitle={
              <>
                {button.subtitle}
                <ConfigurationTooltip style={button.id} size={configSize} />
              </>
            }
            Content={choiceIcons[button.icon]}
            contentClassname={styles.iconColors}
            containerClassname={styles.container}
            containerActiveClassname={styles.containerActive}
            isActive={configStyle === button.id}
            onClick={() => setConfigStyle(button.id)}
          />
        ))}
      </div>
    </>
  );
};
