import { useQuery } from 'react-query';

import { fetchBrokerRequisitesPDF } from '../api/fetchBrokerRequisitesPDF';

type Params = Parameters<typeof fetchBrokerRequisitesPDF> extends [infer P]
  ? P
  : never;

export const useBrokerRequisitesPDF = (params: Params, enabled?: boolean) => {
  return useQuery(
    ['lk', 'requisites', 'file', 'pdf', params],
    () => fetchBrokerRequisitesPDF(params),
    {
      enabled,
    }
  );
};
