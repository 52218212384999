import { LkSignOptions, useSignApi } from '../../sign';
import { sendIiaConvert } from '../api/sendIiaConvert';

import { IiaConvertSendParams as IiaConvertSendRequest } from '../api/types/IiaConvertSendParams';

interface IiaConvertSendParams {
  params: IiaConvertSendRequest;
  signOptions?: LkSignOptions;
}

export const useIiaConvertSend = (data: IiaConvertSendParams) => {
  return useSignApi({
    apiCall: () => sendIiaConvert(data.params),
    options: data?.signOptions,
  });
};
