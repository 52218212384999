import cn from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Popover } from '@alfalab/core-components/popover';
import { FilterMIcon } from '@alfalab/icons-glyph/FilterMIcon';

import { useOnClickOutside } from '@terminal/core/hooks';
import {
  Filter,
  FilterSettings,
  FilterType,
  FilterTypes,
} from '@terminal/core/types/layout';
import { TableColumnKey } from '@terminal/core/types/tableColumn';

import {
  DateRange,
  FilterButtons,
  MultiSelectFilter,
  NumberRange,
  prepareDateRange,
  prepareNumberRange,
  TextFilter,
} from '../../../Filter';

import styles from './ColumnFilter.module.css';

interface Props {
  columnKey: TableColumnKey;
  filterType: FilterType;
  filter?: Filter;
  filterSettings?: FilterSettings;
  onFilter?: (filter?: Filter) => void;
}

export const ColumnFilter = (props: Props) => {
  const { filter, columnKey, onFilter, filterType, filterSettings } = props;
  const [isOpen, setIsOpen] = useState(false);
  const calendarFromRef = useRef<HTMLInputElement>(null);
  const calendarToRef = useRef<HTMLInputElement>(null);
  const anchorRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState(filter?.value);

  const isActive = columnKey === filter?.key;

  const onChange = useCallback((value: any) => {
    setValue(value);
  }, []);

  const onCancel = useCallback(() => {
    onFilter?.();
    setIsOpen(false);
  }, [onFilter]);

  const handleSave = useCallback(() => {
    let innerValue = value;

    switch (filterType) {
      case 'numberRange': {
        innerValue = prepareNumberRange(value);
        break;
      }
      case 'dateRange': {
        innerValue = prepareDateRange(value);
        break;
      }
    }

    onFilter?.({
      key: columnKey,
      value: innerValue,
      type: filterType,
    });

    setIsOpen(false);
  }, [filterType, columnKey, value, onFilter]);

  const filterView = useMemo(() => {
    if (filterType === FilterTypes.Text) {
      return <TextFilter filterValue={value} onChange={onChange} />;
    }

    if (filterType === FilterTypes.Array) {
      return (
        <MultiSelectFilter
          filterItems={filterSettings?.filterItems}
          filterValue={value}
          onChange={onChange}
        />
      );
    }

    if (filterType === FilterTypes.DateRange) {
      return (
        <DateRange
          calendarFromRef={calendarFromRef}
          calendarToRef={calendarToRef}
          filterValue={value}
          maxDate={filterSettings?.maxDate}
          onChange={onChange}
        />
      );
    }

    if (filterType === FilterTypes.NumberRange) {
      return (
        <NumberRange
          filterValue={value}
          min={filterSettings?.min}
          max={filterSettings?.max}
          step={filterSettings?.step}
          onChange={onChange}
        />
      );
    }

    return null;
  }, [filterSettings, filterType, onChange, value]);

  useEffect(() => {
    setValue(filter?.value);
  }, [filter]);

  useOnClickOutside([dropdownRef, calendarFromRef, calendarToRef], (e) => {
    const anchor = anchorRef.current;

    //Закрываем только если это НЕ клик в кнопку управляющую дропдауном
    if (anchor && !anchor?.contains(e.target as Node)) {
      setIsOpen(false);
    }
  });

  return (
    //Предотвращаем всплытие, чтобы не сработала  функция сортировки
    <div onClick={(e) => e.stopPropagation()}>
      <div
        ref={anchorRef}
        onClick={(e) => setIsOpen(!isOpen)}
        style={{ display: 'flex' }}
      >
        <FilterMIcon
          className={cn(
            'filterIcon',
            isOpen && styles.filterIconOpen,
            isActive && styles.activeFilter
          )}
          height={12}
          width={12}
        />
      </div>
      <Popover
        popperClassName={cn(styles.popper, styles.filterPopover)}
        ref={dropdownRef}
        anchorElement={anchorRef.current}
        open={isOpen}
        offset={[0, 2]}
        position="bottom-start"
      >
        <div className={styles.popoverChildrenWrapper}>
          <FilterButtons value={value} onCancel={onCancel} onSave={handleSave}>
            {filterView}
          </FilterButtons>
        </div>
      </Popover>
    </div>
  );
};

export const defaultColumnFilterRender = (
  columnKey: TableColumnKey,
  filterType: FilterType,
  filter?: Filter,
  filterSettings?: FilterSettings,
  onFilter?: ((filter?: Filter) => void) | undefined
) => (
  <ColumnFilter
    columnKey={columnKey}
    filterType={filterType}
    filter={filter}
    filterSettings={filterSettings}
    onFilter={onFilter}
  />
);
