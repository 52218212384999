import cn from 'classnames';
import { ComponentClass, FC, SVGProps, VFC } from 'react';

import { ListItem } from '../../../../entities/Chat';

import styles from './ChatRoomListItem.module.css';

export type ChatRoomListItemProps = {
  id: string;
  description: string;
  icon: ComponentClass<SVGProps<SVGSVGElement>> | FC<SVGProps<SVGSVGElement>>;
  missedCount: number;
  missedColor?: string;
  title: string;
  onEnterRoom: (id: string) => void;
};

export const ChatRoomListItem: VFC<ChatRoomListItemProps> = (props) => {
  const {
    id,
    description,
    icon,
    missedCount,
    missedColor = '#D91D0B',
    title,
    onEnterRoom,
  } = props;

  return (
    <ListItem
      label={title}
      description={description}
      Icon={icon}
      onClick={() => onEnterRoom(id)}
    >
      <div
        data-count={Math.min(missedCount, 99) || ''}
        className={cn(styles.missedBadge, {
          [styles.missedBadgeActive]: missedCount > 0,
        })}
        style={{
          backgroundColor: missedCount > 0 ? missedColor : 'transparent',
        }}
      />
    </ListItem>
  );
};
