import { createClusterEndpoint } from '../../../../lib/createClusterEndpoint';
import { createQueryFromRequest } from '../../../../lib/createQueryFromRequest';

import { TestingListDTO } from './types/TestingListDTO';
import { TestingListQueryParams } from './types/TestingListQueryParams';

/**
 * Получение данных пользователя для экранов профиля
 * @author: NTF
 * */
export const getTestingList = createClusterEndpoint({
  cluster: 'lk',
  service: 'client-testing',
  path: 'status',
  method: 'GET',
})<TestingListDTO, TestingListQueryParams>;

export const useGetTestingListQuery = createQueryFromRequest(getTestingList);
