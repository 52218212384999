import isUndefined from 'lodash/isUndefined';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { TooltipDesktop as Tooltip } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { AddLargeMIcon } from '@alfalab/icons-glyph/AddLargeMIcon';
import { ArrowsUpDownBoldMIcon } from '@alfalab/icons-glyph/ArrowsUpDownBoldMIcon';
import { BanknotesMIcon } from '@alfalab/icons-glyph/BanknotesMIcon';
import { BarChartMIcon } from '@alfalab/icons-glyph/BarChartMIcon';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { ChevronForwardMIcon } from '@alfalab/icons-glyph/ChevronForwardMIcon';
import { CopyMIcon } from '@alfalab/icons-glyph/CopyMIcon';
import { CrossCircleMIcon } from '@alfalab/icons-glyph/CrossCircleMIcon';
import { DocumentPlusLineMIcon } from '@alfalab/icons-glyph/DocumentPlusLineMIcon';
import { GearMIcon } from '@alfalab/icons-glyph/GearMIcon';
import { JapaneseCandlesMIcon } from '@alfalab/icons-glyph/JapaneseCandlesMIcon';
import { PencilMIcon } from '@alfalab/icons-glyph/PencilMIcon';
import { SettingsCompactSIcon } from '@alfalab/icons-glyph/SettingsCompactSIcon';
import { TrashCanLineMIcon } from '@alfalab/icons-glyph/TrashCanLineMIcon';
import { WalletMIcon } from '@alfalab/icons-glyph/WalletMIcon';

import { useFullFI } from '@terminal/alfadirect/hooks';
import { Dropdown } from '@terminal/common/components/Dropdown';
import { RUSSIAN_ROUBLE_FI_ID } from '@terminal/core/constants/common';
import {
  ChartPieLineMIcon,
  OrderBookIcon,
} from '@terminal/core/constants/icons';
import {
  WidgetsDefaultConfigMap,
  WidgetsNameMap,
} from '@terminal/core/constants/Layout';
import { useOnClickOutside } from '@terminal/core/hooks/useOnClickOutside';
import { addKeyIfExists } from '@terminal/core/lib/addKeyIfExists';
import { TradingFormSource } from '@terminal/core/lib/analytics/model/main';
import { ExtendedFI } from '@terminal/core/types/extendedFI';
import { Widget, WidgetLinkProps } from '@terminal/core/types/layout';
import { StopOrderType } from '@terminal/core/types/ui';
import {
  EDITABLE_WATCH_LISTS,
  WatchList,
  WatchListItem,
  WatchListType,
} from '@terminal/core/types/watchList';

import { FeatureFlagName, useWidgetContext } from '../../shared';
// eslint-disable-next-line no-restricted-imports
import { OrderBookSettings } from '../../widgets/orderBook/features/OrderBook/ui/OrderBookSettings';
import { TradingCertificateLock } from '../TradingCertificateLock';

import styles from './ContextMenu.module.css';

interface Props extends WidgetLinkProps {
  widget:
    | Widget.WATCHLIST
    | Widget.ORDERBOOK
    | Widget.POSITIONS
    | Widget.ORDERS
    | Widget.BOND_SCREENER;
  idFi: number;
  setIsOpenLimitsModal: (isOpen: boolean) => void;
  setIsOpenContextMenuModal: (isOpen: boolean) => void;
  setIsOpenSlippageModal: (isOpen: boolean) => void;
  setIsOpenQuantityModal: (isOpen: boolean) => void;
  nodeId?: string;
  idSubAccount?: number;
  nameMarketBoard?: string;
  selectedSubAccounts?: string[];
  setIsOpenColumnSettings?: (isOpen: boolean) => void;
  setIsWatchlistModalOpen?: (isOpen: boolean) => void;
  setIsFIModalOpen?: (isOpen: boolean) => void;
  setWatchlistModalType?: (type: 'create' | 'edit' | 'delete') => void;
  setInstrumentToAdd?: (instrument?: WatchListItem) => void;
  selectedList?: WatchList;
  onEditOrder?: () => void;
  onDeleteOrder?: () => void;
  setIsPortfolioModalOpen?: (isOpen: boolean) => void;
  isOpenContext?: boolean;
  setIsOpenContext?: (isOpen: boolean) => void;
  anchorPoint?: {
    x: number;
    y: number;
  };
  autoCenter?: boolean;
  hideForm?: boolean;
  showSpread?: boolean;
  showYield?: boolean;
  showEmptyPrice?: boolean;
  sendTrallingOrders?: boolean;
  enableDoublePos?: boolean;
  enableClosePos?: boolean;
  scrollOrderbookToCenter?: () => void;
  additionalButtons?: ReactNode;
  setContextMenuRow?: (rowIndex: number | null) => void;
  isAllowEditOrder?: boolean;
  onClickOption?: (name: string) => void;
  onClickClone?: () => void;
  onClickClose?: () => void;
  onClickColumnsSettings?: () => void;
}

interface WidgetOptionsProps extends Props {
  onTopUp?: () => void;
  onClose: () => void;
  fullFi?: ExtendedFI;
  dropdownRef?: React.RefObject<HTMLDivElement>;
  wrapperRef: React.RefObject<HTMLDivElement>;
}

//Рендерим контекстное меню, в зависимости от типа виджета
const WidgetOptions = ({
  widget,
  selectedList,
  idFi,
  onClose,
  fullFi,
  setWatchlistModalType,
  setIsWatchlistModalOpen,
  setIsFIModalOpen,
  onEditOrder,
  onDeleteOrder,
  onTopUp,
  setIsPortfolioModalOpen,
  autoCenter,
  hideForm,
  showEmptyPrice,
  showSpread,
  showYield,
  sendTrallingOrders,
  nodeId,
  enableDoublePos,
  enableClosePos,
  setInstrumentToAdd,
  isAllowEditOrder,
  setIsOpenLimitsModal,
  dropdownRef,
  wrapperRef,
  setIsOpenContextMenuModal,
  setIsOpenQuantityModal,
  setIsOpenSlippageModal,
  onClickOption = () => {},
}: WidgetOptionsProps) => {
  const subMenuRef = useRef<HTMLDivElement>(null);
  const subMenuAnchorRef = useRef<HTMLDivElement>(null);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const isEditable =
    selectedList && EDITABLE_WATCH_LISTS.includes(selectedList.type);
  const isRuble = idFi === RUSSIAN_ROUBLE_FI_ID;

  //Клик вне дропдаун с кастомным управлением, проверяем дополнительно клики в сабмею
  useOnClickOutside(
    [
      wrapperRef,
      ...(dropdownRef ? [dropdownRef] : []),
      subMenuRef,
      subMenuAnchorRef,
    ],
    () => {
      // Хак с таймаутом нужен для того,
      // чтобы успел сработать обработчик на подменю 3-го уровня внутри ContextMenuSettings
      // так как нет доступа к ref
      setTimeout(() => {
        onClose();
      }, 200);
    }
  );

  const {
    useWatchLists,
    addToWatchList,
    deleteFromWatchList,
    updateNode,
    DropdownComponent,
    useFeatureFlag,
  } = useWidgetContext();

  const isPortfolioEnabled = useFeatureFlag(
    FeatureFlagName.PORTFOLIO_ANALYTICS_ENABLED
  );
  const isTopupEnabled = useFeatureFlag(
    FeatureFlagName.TOPUP_FROM_WIDGET_ENABLED
  );

  const watchLists = useWatchLists();

  const filteredWatchLists = watchLists
    .filter(
      (list) =>
        EDITABLE_WATCH_LISTS.includes(list.type) &&
        list.type !== WatchListType.AFLA &&
        list.id !== selectedList?.id
    )
    .map((list) => ({
      key: list.id,
      content: list.name,
      value: list,
    }));

  switch (widget) {
    case Widget.WATCHLIST:
      return (
        <>
          {isEditable && selectedList && (
            <Dropdown.Item
              content="Удалить из списка"
              leftIcon={
                <TrashCanLineMIcon
                  height={18}
                  width={18}
                  color="var(--color-dark-graphic-secondary)"
                />
              }
              onClick={() => {
                deleteFromWatchList(selectedList.id, idFi);
                onClose();
              }}
            />
          )}
          <Dropdown.Item
            content="Добавить в список"
            leftIcon={
              <AddLargeMIcon
                height={18}
                width={18}
                color="var(--color-dark-graphic-secondary)"
              />
            }
            rightIcon={
              <ChevronForwardMIcon
                height={18}
                width={18}
                color="var(--color-dark-graphic-secondary)"
              />
            }
            ref={subMenuRef}
            onMouseEnter={() => setOpenSubMenu(true)}
            onMouseLeave={() => setOpenSubMenu(false)}
            onClick={() => setOpenSubMenu(false)}
            withDivider
          >
            <DropdownComponent
              ref={subMenuAnchorRef}
              anchorElement={subMenuRef.current}
              open={openSubMenu}
              position="right-start"
              offset={[-7, 4]}
              popperClassName={styles.subMenu}
            >
              <Dropdown.Item
                content="Новый список"
                withDivider
                leftIcon={
                  <DocumentPlusLineMIcon
                    height={18}
                    width={18}
                    color="var(--color-light-graphic-secondary)"
                  />
                }
                onClick={() => {
                  const instrument = selectedList?.instruments?.find(
                    ({ idFI }) => idFI === idFi
                  );

                  setInstrumentToAdd?.(instrument);
                  setWatchlistModalType?.('create');
                  setIsWatchlistModalOpen?.(true);
                  onClose();
                }}
              />
              {filteredWatchLists.map((props) => {
                const { content, key, value } = props;

                const isAlreadyAdded = value.instruments.find(
                  ({ idFI }) => idFI === idFi
                );

                return (
                  <Dropdown.Item
                    content={content}
                    rightIcon={
                      isAlreadyAdded ? (
                        <Tooltip
                          targetClassName={styles.tooltipTarget}
                          contentClassName={styles.tooltipContent}
                          content="Уже добавлено в список"
                          trigger="hover"
                          position="top"
                        >
                          <CheckmarkMIcon
                            height={18}
                            width={18}
                            color="var(--color-light-graphic-secondary)"
                          />
                        </Tooltip>
                      ) : undefined
                    }
                    key={key}
                    onClick={() => {
                      fullFi &&
                        addToWatchList(key, {
                          idFI: fullFi.idFI,
                          idObject: fullFi.idObject,
                          codeMarketBoard: fullFi.codeMarketBoard,
                          symbolObject: fullFi.symbolObject,
                        });
                      onClose();
                    }}
                  />
                );
              })}
            </DropdownComponent>
          </Dropdown.Item>
          {isEditable && (
            <>
              <Typography.Text
                view="secondary-large"
                weight="bold"
                tag="div"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className={styles.blockHeading}
              >
                {selectedList?.name}
              </Typography.Text>
              <Dropdown.Item
                content="Добавить инструмент"
                leftIcon={
                  <AddLargeMIcon
                    height={18}
                    width={18}
                    color="var(--color-dark-graphic-secondary)"
                  />
                }
                onClick={() => {
                  setIsFIModalOpen?.(true);
                  onClose();
                }}
              />
              <Dropdown.Item
                content="Переименовать список"
                leftIcon={
                  <PencilMIcon
                    height={18}
                    width={18}
                    color="var(--color-dark-graphic-secondary)"
                  />
                }
                onClick={() => {
                  setWatchlistModalType?.('edit');
                  setIsWatchlistModalOpen?.(true);
                  onClose();
                }}
              />
              <Dropdown.Item
                content="Удалить список"
                leftIcon={
                  <TrashCanLineMIcon
                    height={18}
                    width={18}
                    color="var(--color-dark-graphic-secondary)"
                  />
                }
                onClick={() => {
                  setWatchlistModalType?.('delete');
                  setIsWatchlistModalOpen?.(true);
                  onClose();
                }}
                withDivider
              />
            </>
          )}
        </>
      );
    case Widget.ORDERS:
      return (
        <TradingCertificateLock hideMode>
          <Typography.Text
            view="secondary-large"
            weight="bold"
            tag="div"
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={styles.blockHeading}
          >
            Заявка
          </Typography.Text>
          {isAllowEditOrder && (
            <Dropdown.Item
              content="Редактировать заявку"
              leftIcon={
                <PencilMIcon
                  height={18}
                  width={18}
                  color="var(--color-dark-graphic-secondary)"
                />
              }
              onClick={() => {
                onEditOrder?.();
                onClose();
              }}
            />
          )}
          <Dropdown.Item
            content="Снять заявку"
            leftIcon={
              <CrossCircleMIcon
                height={18}
                width={18}
                color="var(--color-dark-graphic-secondary)"
              />
            }
            onClick={() => {
              onDeleteOrder?.();
              onClose();
            }}
            withDivider
          />
        </TradingCertificateLock>
      );
    case Widget.POSITIONS:
      return (
        <>
          <TradingCertificateLock hideMode>
            {enableDoublePos && enableClosePos && (
              <Typography.Text
                view="secondary-large"
                weight="bold"
                tag="div"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className={styles.blockHeading}
              >
                Позиция
              </Typography.Text>
            )}
            {enableDoublePos && (
              <Dropdown.Item
                content="Удвоить позицию"
                leftIcon={
                  <BanknotesMIcon
                    height={18}
                    width={18}
                    color="var(--color-dark-graphic-secondary)"
                  />
                }
                onClick={() => {
                  onEditOrder?.();
                  onClose();
                }}
              />
            )}
            {enableClosePos && (
              <Dropdown.Item
                content="Закрыть позицию"
                leftIcon={
                  <CrossCircleMIcon
                    height={18}
                    width={18}
                    color="var(--color-dark-graphic-secondary)"
                  />
                }
                onClick={() => {
                  onDeleteOrder?.();
                  onClose();
                }}
                withDivider
              />
            )}
          </TradingCertificateLock>
          <Typography.Text
            view="secondary-large"
            weight="bold"
            tag="div"
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={styles.blockHeading}
          >
            {isRuble ? (
              <>
                RUB&nbsp;
                <Typography.Text
                  view="secondary-large"
                  color="secondary"
                  weight="bold"
                  tag="span"
                >
                  МБ ЦК
                </Typography.Text>
              </>
            ) : (
              'Дополнительно'
            )}
          </Typography.Text>
          {isTopupEnabled && (
            <Dropdown.Item
              content="Пополнить счёт"
              leftIcon={
                <WalletMIcon
                  height={16}
                  width={16}
                  color="var(--color-dark-graphic-secondary)"
                />
              }
              onClick={onTopUp}
            />
          )}
          {isPortfolioEnabled && (
            <Dropdown.Item
              content="Аналитика портфеля"
              leftIcon={
                <ChartPieLineMIcon
                  height={18}
                  width={18}
                  color="var(--color-dark-graphic-secondary)"
                />
              }
              onClick={() => {
                setIsPortfolioModalOpen?.(true);
                onClose();
              }}
            />
          )}
        </>
      );
    case Widget.ORDERBOOK:
      const saveForm = (
        hideForm: boolean,
        showSpread: boolean,
        showEmptyPrice: boolean,
        sendTrallingOrders: boolean,
        autoCenter: boolean,
        stopOrderType: StopOrderType,
        showYield: boolean
      ) => {
        if (nodeId) {
          updateNode(nodeId, {
            hideForm,
            showSpread,
            showEmptyPrice,
            sendTrallingOrders,
            autoCenter,
            stopOrderType,
            showYield,
          });
        }
      };

      return (
        <>
          <Typography.Text
            view="secondary-large"
            weight="bold"
            tag="div"
            className={styles.blockHeading}
          >
            Стакан
          </Typography.Text>
          <Dropdown.Item
            content="Настройки"
            leftIcon={
              <GearMIcon
                height={18}
                width={18}
                color="var(--color-dark-graphic-secondary)"
              />
            }
            rightIcon={
              <ChevronForwardMIcon
                height={18}
                width={18}
                color="var(--color-dark-graphic-secondary)"
              />
            }
            ref={subMenuRef}
            onMouseEnter={() => setOpenSubMenu(true)}
            onMouseLeave={() => setOpenSubMenu(false)}
          >
            <DropdownComponent
              ref={subMenuAnchorRef}
              anchorElement={subMenuRef.current}
              open={openSubMenu}
              position="right-start"
              offset={[-7, 4]}
              popperClassName={styles.subMenu}
            >
              <OrderBookSettings
                idFi={idFi}
                showSpread={showSpread}
                showYield={showYield}
                showEmptyPrice={showEmptyPrice}
                saveForm={saveForm}
                hideForm={hideForm}
                setIsOpenContextMenuModal={setIsOpenContextMenuModal}
                setIsOpenLimitsModal={setIsOpenLimitsModal}
                setIsOpenQuantityModal={setIsOpenQuantityModal}
                setIsOpenSlippageModal={setIsOpenSlippageModal}
                open={openSubMenu}
                sendTrallingOrders={sendTrallingOrders}
                autoCenter={autoCenter}
              />
            </DropdownComponent>
          </Dropdown.Item>
        </>
      );
    case Widget.BOND_SCREENER:
      return (
        <>
          <Dropdown.Item
            content="Добавить в список"
            leftIcon={
              <AddLargeMIcon
                height={18}
                width={18}
                color="var(--color-dark-graphic-secondary)"
              />
            }
            rightIcon={
              <ChevronForwardMIcon
                height={18}
                width={18}
                color="var(--color-dark-graphic-secondary)"
              />
            }
            ref={subMenuRef}
            onMouseEnter={() => setOpenSubMenu(true)}
            onMouseLeave={() => setOpenSubMenu(false)}
            onClick={() => setOpenSubMenu(false)}
            withDivider
          >
            <DropdownComponent
              ref={subMenuAnchorRef}
              anchorElement={subMenuRef.current}
              open={openSubMenu}
              position="right-start"
              offset={[-7, 4]}
              popperClassName={styles.subMenu}
            >
              <Dropdown.Item
                content="Новый список"
                withDivider
                leftIcon={
                  <DocumentPlusLineMIcon
                    height={18}
                    width={18}
                    color="var(--color-light-graphic-secondary)"
                  />
                }
                onClick={() => {
                  setInstrumentToAdd?.(
                    fullFi && {
                      idFI: fullFi.idFI,
                      idObject: fullFi.idObject,
                      codeMarketBoard: fullFi.codeMarketBoard,
                      symbolObject: fullFi.symbolObject,
                    }
                  );
                  setIsWatchlistModalOpen?.(true);
                  onClose();
                  onClickOption('Добавить в список > Новый список');
                }}
              />
              {filteredWatchLists.map((props) => {
                const { content, key, value } = props;

                const isAlreadyAdded = value.instruments.find(
                  ({ idFI }) => idFI === idFi
                );

                return (
                  <Dropdown.Item
                    content={content}
                    rightIcon={
                      isAlreadyAdded ? (
                        <Tooltip
                          targetClassName={styles.tooltipTarget}
                          contentClassName={styles.tooltipContent}
                          content="Уже добавлено в список"
                          trigger="hover"
                          position="top"
                        >
                          <CheckmarkMIcon
                            height={18}
                            width={18}
                            color="var(--color-light-graphic-secondary)"
                          />
                        </Tooltip>
                      ) : undefined
                    }
                    key={key}
                    onClick={() => {
                      fullFi &&
                        addToWatchList(key, {
                          idFI: fullFi.idFI,
                          idObject: fullFi.idObject,
                          codeMarketBoard: fullFi.codeMarketBoard,
                          symbolObject: fullFi.symbolObject,
                        });
                      onClose();
                      onClickOption(`Добавить в список > ${content}`);
                    }}
                  />
                );
              })}
            </DropdownComponent>
          </Dropdown.Item>
          <Typography.Text
            view="secondary-large"
            weight="bold"
            tag="div"
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={styles.blockHeading}
          >
            Скринер
          </Typography.Text>
        </>
      );
    default:
      return null;
  }
};

export const ContextMenu = (props: Props) => {
  const {
    widget,
    idFi,
    idSubAccount,
    nameMarketBoard,
    selectedSubAccounts,
    nodeId,
    link,
    setIsOpenColumnSettings,
    isOpenContext,
    setIsOpenContext,
    anchorPoint,
    setContextMenuRow,
    additionalButtons,
    onClickOption = () => {},
    onClickClone = () => {},
    onClickClose = () => {},
    onClickColumnsSettings = () => {},
    ...restProps
  } = props;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(isOpenContext ?? true);

  //Контекстное меню может управлять состоянием открытия из вне
  useEffect(() => {
    if (!isUndefined(isOpenContext)) {
      setIsOpen(isOpenContext);
    }
  }, [isOpenContext]);

  const onCloseMenu = useCallback(() => {
    if (setContextMenuRow) {
      setContextMenuRow(null);
    }
  }, [setContextMenuRow]);

  useEffect(() => {
    return onCloseMenu;
  }, [onCloseMenu]);

  //Определяем как управляется закрытие
  const onClose = setIsOpenContext ?? setIsOpen;
  const {
    addWidget,
    deleteWidget,
    cloneWidget,
    analytics,
    DropdownComponent,
    openTopUpModal,
  } = useWidgetContext();

  const fullFi = useFullFI(idFi);
  const isRuble = idFi === RUSSIAN_ROUBLE_FI_ID;

  const handleTopUp = () => {
    openTopUpModal({
      ...addKeyIfExists<string, number>('idSubAccount', idSubAccount),
      ...addKeyIfExists<string, boolean, string[]>(
        'selectedSubAccounts',
        !idSubAccount,
        selectedSubAccounts
      ),
    });
  };

  const addNewWidget = useCallback(
    (widget: Widget) => {
      addWidget(
        WidgetsNameMap.get(widget)!,
        widget,
        {
          ...WidgetsDefaultConfigMap.get(widget),
          idFi,
          symbol: fullFi?.symbolObject,
        },
        nodeId
      );

      onClose(false);
    },
    [fullFi?.symbolObject, idFi, nodeId, onClose, addWidget]
  );

  const openHybridOrderWithMetrics = useCallback(() => {
    let source;

    switch (widget) {
      case Widget.POSITIONS:
        source = TradingFormSource.contextPosition;
        break;
      case Widget.ORDERS:
        source = TradingFormSource.contextIssue;
        break;
      case Widget.ORDERBOOK:
        source = TradingFormSource.contextDom;
    }

    if (source) {
      analytics.trackTradingFormOpened(source);
    }

    addNewWidget(Widget.HYBRIDORDER);
  }, [addNewWidget, analytics, widget]);

  //Проверяем, нужно ли оборачивать в Popover
  const withDropdown = (children: ReactNode) => {
    if (anchorPoint) {
      return (
        <DropdownComponent
          ref={dropdownRef}
          anchorElement={wrapperRef.current}
          open={isOpen}
          position="right-start"
          offset={[-7, 4]}
          popperClassName={styles.subMenu}
        >
          {children}
        </DropdownComponent>
      );
    } else {
      return children;
    }
  };

  return (
    <div
      className={styles.wrapper}
      ref={wrapperRef}
      style={{
        top: anchorPoint?.y,
        left: anchorPoint?.x,
        display: isOpen ? 'flex' : 'none',
        position: anchorPoint ? 'fixed' : 'unset',
        paddingTop: anchorPoint ? 0 : '8px',
        paddingBottom: anchorPoint ? 0 : '8px',
        minWidth: anchorPoint ? 'unset' : '220px',
      }}
    >
      {isOpen
        ? withDropdown(
            <>
              {!isRuble && (
                <>
                  <Typography.Text
                    view="secondary-large"
                    weight="bold"
                    tag="div"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className={styles.blockHeading}
                  >
                    {fullFi?.symbolObject}&nbsp;
                    <Typography.Text
                      view="secondary-large"
                      color="secondary"
                      weight="bold"
                      tag="span"
                    >
                      {nameMarketBoard}
                    </Typography.Text>
                  </Typography.Text>
                  {widget !== Widget.ORDERBOOK && (
                    <Dropdown.Item
                      content="Торговля"
                      leftIcon={
                        <ArrowsUpDownBoldMIcon
                          height={18}
                          width={18}
                          color="var(--color-dark-graphic-secondary)"
                        />
                      }
                      onClick={() => {
                        openHybridOrderWithMetrics();
                        onClickOption('Торговля');
                      }}
                    />
                  )}
                  <Dropdown.Item
                    content="График"
                    leftIcon={
                      <JapaneseCandlesMIcon
                        height={18}
                        width={18}
                        color="var(--color-dark-graphic-secondary)"
                      />
                    }
                    onClick={() => {
                      addNewWidget(Widget.CHART);
                      onClickOption('График');
                    }}
                  />
                  {widget === Widget.ORDERBOOK && additionalButtons && (
                    <>{additionalButtons}</>
                  )}
                  {![Widget.ORDERBOOK, Widget.BOND_SCREENER].includes(
                    widget
                  ) && (
                    <Dropdown.Item
                      content="Стакан"
                      leftIcon={
                        <OrderBookIcon
                          height={18}
                          width={18}
                          color="var(--color-dark-graphic-secondary)"
                        />
                      }
                      onClick={() => addNewWidget(Widget.ORDERBOOK)}
                      withDivider={[Widget.ORDERS, Widget.POSITIONS].includes(
                        widget
                      )}
                    />
                  )}
                  {Widget.BOND_SCREENER === widget && (
                    <Dropdown.Item
                      content="Инфо"
                      leftIcon={
                        <BarChartMIcon
                          height={18}
                          width={18}
                          color="var(--color-dark-graphic-secondary)"
                        />
                      }
                      onClick={() => {
                        addNewWidget(Widget.INSTRUMENT_INFO);
                        onClickOption('Инфо');
                      }}
                    />
                  )}
                </>
              )}
              <WidgetOptions
                idFi={idFi}
                widget={widget}
                nodeId={nodeId}
                selectedSubAccounts={selectedSubAccounts}
                onTopUp={handleTopUp}
                onClose={() => onClose(false)}
                fullFi={fullFi}
                dropdownRef={dropdownRef}
                wrapperRef={wrapperRef}
                onClickOption={onClickOption}
                {...restProps}
              />
              {widget !== Widget.ORDERBOOK && setIsOpenColumnSettings && (
                <Dropdown.Item
                  content="Настроить колонки"
                  leftIcon={
                    <SettingsCompactSIcon
                      height={18}
                      width={18}
                      color="var(--color-dark-graphic-secondary)"
                    />
                  }
                  onClick={() => {
                    setIsOpenColumnSettings(true);
                    onClose(false);
                    onClickOption('Настроить колонки');
                    onClickColumnsSettings();
                  }}
                />
              )}
              <Dropdown.Item
                content="Дублировать виджет"
                leftIcon={
                  <CopyMIcon
                    height={18}
                    width={18}
                    color="var(--color-dark-graphic-secondary)"
                  />
                }
                onClick={() => {
                  cloneWidget(nodeId);
                  onClose(false);
                  onClickOption('Дублировать виджет');
                  onClickClone();
                }}
              />
              <Dropdown.Item
                content="Закрыть виджет"
                leftIcon={
                  <CrossCircleMIcon
                    height={18}
                    width={18}
                    color="var(--color-dark-graphic-secondary)"
                  />
                }
                onClick={() => {
                  deleteWidget(nodeId, link);
                  onClose(false);
                  onClickOption('Закрыть виджет');
                  onClickClose();
                }}
              />
            </>
          )
        : null}
    </div>
  );
};
