import { useEffect } from 'react';

import { useActiveConfigurationModel } from '@terminal/core/hooks/useActiveConfigurationModel';

import { WIDGET_TAB_HEIGHT } from '../const/layout';
import { setWidgetTabHeight } from '../utils';

export const useModelMigration = () => {
  const model = useActiveConfigurationModel();

  useEffect(() => {
    if (model?._getAttribute('tabSetTabStripHeight') !== WIDGET_TAB_HEIGHT) {
      setWidgetTabHeight(WIDGET_TAB_HEIGHT, model);
    }
  }, [model]);
};
