import { useCallback, useMemo, useState } from 'react';
import { Attach } from '@alfalab/core-components/attach';
import { Gap } from '@alfalab/core-components/gap';
import { Spinner } from '@alfalab/core-components/spinner';
import { TagDesktop } from '@alfalab/core-components/tag/desktop';
import { CloudMIcon } from '@alfalab/icons-glyph/CloudMIcon';
import { FolderMIcon } from '@alfalab/icons-glyph/FolderMIcon';
import { PencilMIcon } from '@alfalab/icons-glyph/PencilMIcon';
import { TrashCanLineMIcon } from '@alfalab/icons-glyph/TrashCanLineMIcon';

import { useErrorNotification } from '@terminal/core/hooks/useNotifications';
import { shallow, useStore } from '@terminal/core/store';
import { WorkspaceConfiguration } from '@terminal/core/types/workspace';
import { useWidgetProps } from '@terminal/desktop/shared/hooks/useWidgetProps';
import { CloudConfigListItem } from '@terminal/lk/features/CloudConfiguration';

import { formatDateTime } from '../../lib/formatDateTime';
import { parseJSONFile } from '../../lib/parseJSONFile';
import {
  WorkspaceConfigurationCustomListItem,
  WorkspaceConfigurationCustomListItemProps,
} from '../WorkspaceConfigurationCustomListItem';
import { WorkspaceConfigurationModal } from '../WorkspaceConfigurationModal';
import { WorkspaceRenameModal } from '../WorkspaceConfigurationModal/WorkspaceRenameModal';

import { useConfigurationExportGroup } from '../../hooks/useConfigurationExportGroup';

import { WorkspaceConfigurationExport } from '../../model/types';

import styles from './WorkspaceConfigurationCustomList.module.css';

interface Props {
  otherConfigurations: WorkspaceConfiguration[];
}

export const WorkspaceConfigurationCustomList = ({
  otherConfigurations,
}: Props) => {
  const [deleteModalConfig, setDeleteModalConfig] = useState({
    isOpen: false,
    isCloud: false,
    id: '',
    name: '',
  });

  const [renameModalConfig, setRenameModalConfig] = useState({
    isOpen: false,
    isCloud: false,
    id: '',
    value: '',
  });

  const [duplicateModalConfig, setDuplicateModalConfig] = useState<{
    isOpen: boolean;
    config: CloudConfigListItem | null;
  }>({
    isOpen: false,
    config: null,
  });

  const [visibleConfigs, setVisibleConfigs] = useState<
    'all' | 'local' | 'cloud'
  >('all');

  const {
    allConfigurations,
    setActiveConfigurationById,
    removeConfiguration,
    renameConfiguration,
    importConfiguration,
    importWatchLists,
    initSettings,
  } = useStore(
    (state) => ({
      allConfigurations: state.workspaceConfigurations,
      setActiveConfigurationById: state.setActiveConfigurationById,
      removeConfiguration: state.removeConfiguration,
      renameConfiguration: state.renameConfiguration,
      importConfiguration: state.importConfiguration,
      importWatchLists: state.importWatchLists,
      initSettings: state.initSettings,
    }),
    shallow
  );

  const {
    cloudConfigHooks: {
      useCloudConfigList,
      useGetCloudConfigMutation,
      useUpdateCloudConfigMutation,
      useDeleteCloudConfigMutation,
    },
  } = useWidgetProps();
  const { data: cloudConfigs, isLoading: isCloudConfigsLoading } =
    useCloudConfigList();
  const {
    mutateAsync: getCloudConfig,
    isLoading: isCloudConfigLoading,
    variables: acceptedCloudConfigId,
  } = useGetCloudConfigMutation();
  const { mutateAsync: deleteCloudConfig, isLoading: isCloudConfigDeleting } =
    useDeleteCloudConfigMutation();
  const { mutateAsync: updateCloudConfig, isLoading: isCloudConfigUpdating } =
    useUpdateCloudConfigMutation();

  const notifyError = useErrorNotification();

  const handleCloseDeleteConfigurationModal = () =>
    setDeleteModalConfig({
      isOpen: false,
      isCloud: false,
      id: '',
      name: '',
    });

  const handleCloseRenameConfigurationModal = () =>
    setRenameModalConfig({
      isOpen: false,
      isCloud: false,
      id: '',
      value: '',
    });

  const handleCloseDuplicateConfigurationModal = () =>
    setDuplicateModalConfig({
      isOpen: false,
      config: null,
    });

  const handleRenameConfiguration = async () => {
    const { isCloud, id, value } = renameModalConfig;

    if (isCloud) {
      await updateCloudConfig({
        id,
        name: value,
      });
    } else {
      renameConfiguration(renameModalConfig.id, renameModalConfig.value);
    }
  };

  const handleDeleteConfiguration = async () => {
    const { isCloud, id } = deleteModalConfig;

    if (isCloud) {
      await deleteCloudConfig(id);
    } else {
      removeConfiguration(deleteModalConfig.id);
    }

    handleCloseDeleteConfigurationModal();
  };

  const acceptCloudConfiguration = useCallback(
    async (listItem: CloudConfigListItem) => {
      try {
        const { configName, configUid } = listItem;
        const cloudConfig = await getCloudConfig(configUid);

        const { workspaceConfiguration, watchLists, settings } = cloudConfig;

        importWatchLists(watchLists);
        importConfiguration(
          {
            lastAutoSavedConfig: workspaceConfiguration,
            lastManualSavedConfig: workspaceConfiguration,
            id: configUid,
            name: configName,
            type: 'custom',
          },
          true
        );
        initSettings(settings);
      } catch (error) {
        notifyError('Не удалось применить облачную конфигурацию', error);
      }
    },
    [
      notifyError,
      getCloudConfig,
      importConfiguration,
      importWatchLists,
      initSettings,
    ]
  );

  const handleAcceptCloudConfigurationButtonClick = useCallback(
    async (acceptedConfig: CloudConfigListItem) => {
      const localConfigDuplicate = allConfigurations.find(
        (config) => config.id === acceptedConfig.configUid
      );

      if (localConfigDuplicate) {
        setDuplicateModalConfig({
          isOpen: true,
          config: {
            ...acceptedConfig,
            configName: localConfigDuplicate.name,
          },
        });
      } else {
        await acceptCloudConfiguration(acceptedConfig);
      }
    },
    [allConfigurations, acceptCloudConfiguration]
  );

  const handleReplaceConfiguration = useCallback(async () => {
    if (duplicateModalConfig.config) {
      await acceptCloudConfiguration(duplicateModalConfig.config);
      handleCloseDuplicateConfigurationModal();
    }
  }, [acceptCloudConfiguration, duplicateModalConfig.config]);

  const handleImportConfiguration = (file: File) => {
    parseJSONFile<WorkspaceConfigurationExport>(file)
      .then((result: WorkspaceConfigurationExport) => {
        if (!result.configuration) {
          throw new Error('Неверный формат файла. Требуется JSON');
        }

        if (
          !result.watchLists ||
          !Array.isArray(result.watchLists) ||
          !result.configuration
        ) {
          throw new Error('Неверный формат файла');
        }

        if (
          allConfigurations.some(
            (config) => config.id === result.configuration.id
          )
        ) {
          throw new Error(
            'Конфигурация с таким идентификатором уже существует'
          );
        }

        importWatchLists(result.watchLists);
        importConfiguration(result.configuration);
      })
      .catch((err) => notifyError('Ошибка', new Error(err.message)));
  };

  const exportMenuGroup = useConfigurationExportGroup();

  const localItems = useMemo<
    WorkspaceConfigurationCustomListItemProps[]
  >(() => {
    return otherConfigurations.map((config) => {
      return {
        id: config.id,
        key: `local-${config.id}`,
        name: config.name,
        savedDate: formatDateTime(
          new Date(config.lastManualSavedConfig.timeStamp)
        ),
        updatedDate: formatDateTime(
          new Date(config.lastAutoSavedConfig.timeStamp)
        ),
        onAcceptConfigClick: () => setActiveConfigurationById(config.id),
        dropdownGroups: [
          {
            key: 'actions',
            items: [
              {
                key: 'reset',
                type: 'button',
                label: 'Переименовать',
                Icon: PencilMIcon,
                hideMenu: true,
                onClick: () =>
                  setRenameModalConfig({
                    isOpen: true,
                    isCloud: false,
                    id: config.id,
                    value: config.name,
                  }),
              },
              {
                key: 'delete',
                type: 'button',
                label: 'Удалить',
                Icon: TrashCanLineMIcon,
                hideMenu: true,
                onClick: () =>
                  setDeleteModalConfig({
                    isOpen: true,
                    isCloud: false,
                    id: config.id,
                    name: config.name,
                  }),
              },
            ],
          },
          exportMenuGroup(config),
        ],
      } satisfies WorkspaceConfigurationCustomListItemProps;
    });
  }, [setActiveConfigurationById, otherConfigurations, exportMenuGroup]);

  const cloudItems = useMemo<
    WorkspaceConfigurationCustomListItemProps[]
  >(() => {
    return (
      cloudConfigs?.items.map((config) => {
        return {
          id: config.configUid,
          key: `cloud-${config.configUid}`,
          name: config.configName,
          savedDate: formatDateTime(new Date(config.dtInserted)),
          updatedDate: formatDateTime(new Date(config.dtUpdated)),
          onAcceptConfigClick: () =>
            handleAcceptCloudConfigurationButtonClick(config),
          isAccepting:
            isCloudConfigLoading && acceptedCloudConfigId === config.configUid,
          dropdownGroups: [
            {
              key: 'actions',
              items: [
                {
                  key: 'reset',
                  type: 'button',
                  label: 'Переименовать',
                  Icon: PencilMIcon,
                  hideMenu: true,
                  onClick: () =>
                    setRenameModalConfig({
                      isOpen: true,
                      isCloud: true,
                      id: config.configUid,
                      value: config.configName,
                    }),
                },
                {
                  key: 'delete',
                  type: 'button',
                  label: 'Удалить',
                  Icon: TrashCanLineMIcon,
                  hideMenu: true,
                  onClick: () =>
                    setDeleteModalConfig({
                      isOpen: true,
                      isCloud: true,
                      id: config.configUid,
                      name: config.configName,
                    }),
                },
              ],
            },
          ],
          isCloud: true,
        } satisfies WorkspaceConfigurationCustomListItemProps;
      }) ?? []
    );
  }, [
    acceptedCloudConfigId,
    cloudConfigs?.items,
    handleAcceptCloudConfigurationButtonClick,
    isCloudConfigLoading,
  ]);

  const items = useMemo(() => {
    let result: WorkspaceConfigurationCustomListItemProps[] = [];

    switch (visibleConfigs) {
      case 'all':
        result = [...localItems, ...cloudItems];
        break;
      case 'local':
        result = localItems;
        break;
      case 'cloud':
        result = cloudItems;
        break;
    }

    result.sort(
      (a, b) =>
        new Date(b.updatedDate).getTime() - new Date(a.updatedDate).getTime()
    );

    return result;
  }, [cloudItems, localItems, visibleConfigs]);

  return (
    <>
      <div className={styles.actions}>
        <TagDesktop
          size="xs"
          view="filled"
          onClick={() => setVisibleConfigs('all')}
          checked={visibleConfigs === 'all'}
        >
          Все
        </TagDesktop>
        <TagDesktop
          size="xs"
          view="filled"
          onClick={() => setVisibleConfigs('local')}
          checked={visibleConfigs === 'local'}
        >
          На устройстве
        </TagDesktop>
        <TagDesktop
          size="xs"
          view="filled"
          onClick={() => setVisibleConfigs('cloud')}
          checked={visibleConfigs === 'cloud'}
        >
          <span className={styles.flexCentered}>
            <CloudMIcon width={16} height={16} /> В облаке
          </span>
        </TagDesktop>
        <Attach
          size="xs"
          value={[]}
          buttonContent="Загрузить из файла"
          buttonProps={{
            block: true,
            view: 'secondary',
            leftAddons: (
              <FolderMIcon
                width={16}
                height={16}
                color="var(--color-light-graphic-secondary)"
              />
            ),
          }}
          onChange={(_, { files: [file] }) => handleImportConfiguration(file)}
          fileClassName={styles.fileList}
          className={styles.uploadButton}
          noFileText=""
        />
      </div>

      <Gap size="m" />

      <div>
        {isCloudConfigsLoading && (
          <div className={styles.spinnerWrapper}>
            <Spinner visible />
          </div>
        )}

        {!isCloudConfigsLoading &&
          items.map((item) => (
            <WorkspaceConfigurationCustomListItem {...item} key={item.key} />
          ))}
      </div>

      <WorkspaceRenameModal
        open={renameModalConfig.isOpen}
        value={renameModalConfig.value}
        onChange={(value) =>
          setRenameModalConfig({ ...renameModalConfig, value })
        }
        onClose={handleCloseRenameConfigurationModal}
        onSubmit={handleRenameConfiguration}
        isActionLoading={isCloudConfigUpdating}
      />

      <WorkspaceConfigurationModal
        open={deleteModalConfig.isOpen}
        onClose={handleCloseDeleteConfigurationModal}
        title="Удалить конфигурацию?"
        actionButtonsEnd={[
          {
            children: 'Отмена',
            view: 'outlined',
            onClick: handleCloseDeleteConfigurationModal,
          },
          {
            children: 'Удалить',
            leftAddons: <TrashCanLineMIcon width={18} height={18} />,
            view: 'secondary',
            onClick: handleDeleteConfiguration,
            loading: isCloudConfigDeleting,
          },
        ]}
      >
        Вы уверены, что хотите удалить конфигурацию «{deleteModalConfig.name}»?
        <br />
        Вы не сможете ее восстановить.
      </WorkspaceConfigurationModal>

      <WorkspaceConfigurationModal
        open={duplicateModalConfig.isOpen}
        onClose={handleCloseDuplicateConfigurationModal}
        title={
          <>
            Эта конфигурация уже существует
            <br />
            на устройстве. Заменить ее?
          </>
        }
        actionButtonsEnd={[
          {
            children: 'Отмена',
            view: 'outlined',
            onClick: handleCloseDuplicateConfigurationModal,
          },
          {
            children: 'Заменить',
            view: 'secondary',
            onClick: handleReplaceConfiguration,
            loading: isCloudConfigLoading,
          },
        ]}
      >
        Мы заменим конфигурацию «{duplicateModalConfig.config?.configName}» на
        вашем устройстве. Если вы ранее вносили изменения в конфигурацию на этом
        устройстве, они могут не сохранится.
      </WorkspaceConfigurationModal>
    </>
  );
};
