import { useEffect, useMemo, useState } from 'react';

import { useAlfaDirectContext, useLast } from '@terminal/alfadirect/hooks';
import { ORDER_SOURCE } from '@terminal/core/constants/analytics';
import { currenciesFI } from '@terminal/core/constants/FIModal';
import { MINORITY } from '@terminal/core/constants/ui';
import { LifeTime, OrderType } from '@terminal/core/lib/client/entities';
import { quoteLastSelector } from '@terminal/core/lib/domain/quoteSelector';
import { roundPrice } from '@terminal/core/lib/format';
import {
  checkOrderType,
  getCurrencyPrice,
  getExecutionType,
  getOrderSendPrice,
} from '@terminal/core/lib/formulas';
import { getQuantityFromCash } from '@terminal/core/lib/formulas/getQuantityFromCash';
import { getBracketOrderParams } from '@terminal/core/lib/services/trading/getBracketOrderParams';
import { SendOrderFn } from '@terminal/core/lib/services/trading/useTradingService';
import { AdditionalInstruction } from '@terminal/core/types/additionalInstruction';
import { ExtendedFI } from '@terminal/core/types/extendedFI';
import { FinInfoExt } from '@terminal/core/types/quotes';
import {
  InputSendOrderParams,
  OrderSide,
  SendOrderParams,
} from '@terminal/core/types/trading';
import { SlippageType } from '@terminal/core/types/ui';

import { useAdditionalInstruction } from '../../../features/AdditionalInstructions';
import { getBracketPrice } from '../../../features/BracketPrice';
import { useActivationTime } from '../../../features/Orders';
import { useWidgetContext } from '../../../shared';
import { FormType, VolumeType } from '../model';
import { useTradeFormControls } from './useTradeFormControls';

import { useIsLotCount } from '../../../shared/hooks/useIsLotCount';
import { useWarranty } from '../../../shared/hooks/useWarranty';

type UseTradingFormOrderProps = {
  formType: FormType;
  idFi: number;
  fullFI: ExtendedFI | undefined;
  finInfoExt: FinInfoExt | undefined;
  defaultOrderType: OrderType;
  defaultSide: OrderSide;
  selectedSubAccountId: number;
  selectedAccountId?: number;
  sendOrder: SendOrderFn;
  secondPrice?: number;
  setSecondPrice: (value: number) => void;
};

export const useTradingFormOrder = ({
  formType,
  idFi,
  fullFI,
  finInfoExt,
  defaultOrderType,
  defaultSide,
  selectedAccountId,
  selectedSubAccountId,
  sendOrder,
  secondPrice,
  setSecondPrice,
}: UseTradingFormOrderProps) => {
  const priceStep = finInfoExt?.priceStep || 0.01;
  const lot = finInfoExt?.lot || 1;

  const [quantity, setQuantity] = useState<number>(0);
  const [price, setPrice] = useState<number>(0);
  const [slippage, setSlippage] = useState<number>(priceStep);
  const [volumeType, setVolumeType] = useState(VolumeType.Volume);
  const isLotCount = useIsLotCount();
  const getActivationTime = useActivationTime();
  const { analytics } = useWidgetContext();

  const last = useLast(idFi);

  const {
    side,
    setSide,
    orderType,
    setOrderType,
    lifeTime,
    setLifeTime,
    activation,
    setActivation,
    orderTypeOptions,
    lifeTimeOptions,
  } = useTradeFormControls({
    idMarketBoard: fullFI?.idMarketBoard,
    setSecondPrice,
    defaultOrderType,
    defaultSide,
  });

  const { useQuotes, useFinInfoExt } = useAlfaDirectContext();

  const warranty = useWarranty(idFi, price, {
    side,
    finInfoExt,
    idAccount: selectedAccountId,
  });

  const currenciesQuotes = useQuotes(currenciesFI, {
    throttleTimeout: 1000,
    selector: quoteLastSelector,
  });

  const currenciesFinInfoExts = useFinInfoExt(currenciesFI);

  /**
   * Цена валюты номинала
   * */
  const nominalCurrencyPrice = getCurrencyPrice(
    currenciesQuotes,
    currenciesFinInfoExts,
    fullFI?.idObjectFaceUnit
  );

  /**
   * Цена валюты ликвидного рынка
   * */
  const marketCurrencyPrice = getCurrencyPrice(
    currenciesQuotes,
    currenciesFinInfoExts,
    fullFI?.idObjectCurrency
  );

  const calculateQuantity = () => {
    if (volumeType === VolumeType.Volume) {
      return isLotCount ? lot * quantity : quantity;
    }

    if (volumeType === VolumeType.Cash) {
      const currentQuantity = getQuantityFromCash({
        nominalCurrencyPrice,
        marketCurrencyPrice,
        price: price || 1,
        nominal: fullFI?.nominal || 0,
        quantity: quantity / MINORITY,
        isLotCount,
        warranty,
        lot,
        nkd: finInfoExt?.accruedInt || 0,
        idObjectGroup: fullFI?.idObjectGroup || 0,
      });

      // Добавляем минорити и умножаем на лотность, для того, чтобы получить штуки
      return Math.max(
        Math.floor(currentQuantity * (isLotCount ? lot : 1)),
        lot
      );
    }

    return 0;
  };

  // Количество акций для заявки в штуках
  const computedQuantity = calculateQuantity();

  const {
    currentAdditionalInstructions,
    setCurrentAdditionalInstructions,
    quantityType,
  } = useAdditionalInstruction({
    idFi,
    side,
    orderType,
    isChart: FormType.CHART === formType,
    isLotCount,
    lot,
  });

  const { visibleQuantity = 0 } =
    currentAdditionalInstructions?.[AdditionalInstruction.ICEBERG]?.data || {};

  const sendOrderParams: SendOrderParams & InputSendOrderParams =
    useMemo(() => {
      const inputPrice = Number(price.toFixed(10));

      const sltp =
        currentAdditionalInstructions?.[AdditionalInstruction.SLTP]?.data;

      const slPrice = sltp?.isSlEnabled
        ? getBracketPrice(
            inputPrice,
            sltp.slPriceType,
            sltp.slPrice,
            side === OrderSide.BUY ? -1 : 1,
            priceStep
          )
        : undefined;

      const tpPrice = sltp?.isTpEnabled
        ? getBracketPrice(
            inputPrice,
            sltp.tpPriceType,
            sltp.tpPrice,
            side === OrderSide.BUY ? 1 : -1,
            priceStep
          )
        : undefined;

      let slSlippagePrice;

      if (sltp && slPrice && sltp?.slOrderType === OrderType.STL) {
        const slippagePrice =
          (sltp?.slSlippageType === SlippageType.TICK
            ? priceStep
            : slPrice / 100) * sltp.slSlippageValue;

        slSlippagePrice = roundPrice(
          slPrice + (side === OrderSide.BUY ? -1 : 1) * slippagePrice,
          priceStep
        );
      }

      const bracket = getBracketOrderParams({
        slPrice,
        tpPrice,
        slOrderType: sltp?.slOrderType,
        slSlippagePrice,
      });

      let limitLevelAlternative: number | undefined;

      if (orderType === OrderType.STL || orderType === OrderType.BSL) {
        limitLevelAlternative = roundPrice(
          inputPrice + (side === OrderSide.BUY ? 1 : -1) * slippage,
          priceStep
        );
      } else if (orderType === OrderType.TBRS) {
        limitLevelAlternative = last;
      }

      return {
        idFi,
        idExecutionType: getExecutionType(
          checkOrderType(orderType, secondPrice ?? 0),
          visibleQuantity
        ),
        idSubaccount: selectedSubAccountId,
        idOrderType: checkOrderType(orderType, secondPrice ?? 0),
        idLifeTime: lifeTime.value,
        idActivationTime: activation.value,
        activationTime: getActivationTime(activation.value),
        quantityType,
        inputPrice,
        quantity: computedQuantity,
        openQuantity: isLotCount ? lot * visibleQuantity : visibleQuantity,
        buy: side === OrderSide.BUY,
        secondPrice: secondPrice ?? 0,
        limitLevelAlternative,
        linkedOrderId:
          currentAdditionalInstructions?.[AdditionalInstruction.LINKEDORDER]
            ?.data?.clientNumEDocument,
        numEDocument:
          currentAdditionalInstructions?.[AdditionalInstruction.LINKEDORDER]
            ?.data?.numEDocument,
        activationPriceDirection:
          currentAdditionalInstructions?.[AdditionalInstruction.CONDITION]?.data
            ?.activationPriceDirection,
        priceControlType:
          currentAdditionalInstructions?.[AdditionalInstruction.CONDITION]?.data
            ?.idPriceControlType,
        idFIActivate:
          currentAdditionalInstructions?.[AdditionalInstruction.CONDITION]?.data
            ?.idFIActivate,
        bracket,
        withDrawTime:
          //Пока тут обрабатываем GTC
          lifeTime.value === LifeTime.GTD ? new Date(2100, 0, 1) : undefined,
      };
    }, [
      price,
      currentAdditionalInstructions,
      side,
      priceStep,
      orderType,
      idFi,
      secondPrice,
      visibleQuantity,
      selectedSubAccountId,
      lifeTime.value,
      activation.value,
      getActivationTime,
      quantityType,
      computedQuantity,
      isLotCount,
      lot,
      slippage,
      last,
    ]);

  const handleSendOrder = ({
    inputPrice,
    secondPrice,
    idOrderType,
    idLifeTime,
    quantityType,
    quantity,
    openQuantity,
    buy,
    linkedOrderId,
    idActivationTime,
    numEDocument,
    activationPriceDirection,
    priceControlType,
    idFIActivate,
    bracket,
    limitLevelAlternative,
  }: SendOrderParams & InputSendOrderParams) => {
    let idExecutionType = getExecutionType(idOrderType, openQuantity);

    const { stopPrice, limitPrice } = getOrderSendPrice(
      idOrderType,
      inputPrice,
      last,
      secondPrice
    );

    analytics.trackOrder(buy, orderType, ORDER_SOURCE.TRADING_FORM);

    sendOrder({
      limitPrice,
      stopPrice,
      limitLevelAlternative,
      idFi,
      idSubaccount: selectedSubAccountId,
      idOrderType,
      buy,
      quantity,
      openQuantity,
      idLifeTime,
      idExecutionType,
      quantityType,
      activationTime: getActivationTime(activation.value),
      idActivationTime,
      withDrawTime:
        //Пока тут обрабатываем GTC
        idLifeTime === LifeTime.GTD ? new Date(2100, 0, 1) : undefined,
      linkedOrderId,
      numEDocument,
      activationPriceDirection,
      priceControlType,
      idFIActivate,
      bracket,
      last,
    });
  };

  useEffect(() => {
    if (priceStep) {
      setSlippage(priceStep);
    }
  }, [priceStep]);

  return {
    last,
    price,
    slippage,
    quantity,
    side,
    orderType,
    activation,
    lifeTime,
    sendOrderParams,
    currentAdditionalInstructions,
    setQuantity,
    setPrice,
    setSlippage,
    setSide,
    setOrderType,
    setActivation,
    setLifeTime,
    orderTypeOptions,
    lifeTimeOptions,
    sendOrder: handleSendOrder,
    setCurrentAdditionalInstructions,
    volumeType,
    setVolumeType,
    computedQuantity,
  };
};
