import { makeGetParams } from '../url';
import { fetchLkResult, LKPageResult, LKResult } from './lkApi';
import { signOperation } from './lkSignOperation';

import { LkSignOptions } from '../../store/slices/lkSignSlice';

import {
  MoneyHistoryFilter as MoneyHistoryFilterBase,
  MoneyHistoryRequest as MoneyHistoryRequestBase,
  MoneyTransferType as MoneyTransferTypeBase,
} from '../../types/money';
import {
  MoneyHistoryItem as MoneyHistoryItemBase,
  MoneyHistorySource as MoneyHistorySourceBase,
} from '../../types/operation';

/**
 * @deprecated импортировать напрямую из @terminal/core/types/money
 * */
export type MoneyHistoryRequest = MoneyHistoryRequestBase;
/**
 * @deprecated импортировать напрямую из @terminal/core/types/money
 * */
export type MoneyHistoryFilter = MoneyHistoryFilterBase;
/**
 * @deprecated импортировать напрямую из @terminal/core/types/operation
 * */
export type MoneyHistoryItem = MoneyHistoryItemBase;
/**
 * @deprecated импортировать напрямую из @terminal/core/types/operation
 * */
export type MoneyHistorySource = MoneyHistorySourceBase;
/**
 * @deprecated импортировать напрямую из @termina/core/types/money
 * */
export type MoneyTransferType = MoneyTransferTypeBase;

// NOTE: Для тестирования подписи сертификатом
export function checkSigntool() {
  signOperation(`/api/signingtool/test`);
}

export interface TradeAccountBase {
  subAccount: string;
  placeCode: string;
  placeName: string;
}

export interface TradeAccount extends TradeAccountBase {
  currency: string;
  assetValue: number;
  isDisallow: boolean;
}

export interface BrokerAccounts {
  from: TradeAccount[];
  to: TradeAccount[];
}

export interface MoneyAccount {
  treaty?: number;
  moneyAccount: string;
  currency?: string;
  accountName: string;
}

export interface ClientAccounts {
  brokerAccounts: BrokerAccounts;
  moneyAccounts: MoneyAccount[];
}

export interface ClientAccountsRequest {
  treaty: number;
  withdraw?: boolean;
}

export async function getClientAccounts(
  data: ClientAccountsRequest
): Promise<ClientAccounts & LKResult> {
  const params = makeGetParams(data);
  const res = await fetchLkResult<ClientAccounts & LKResult>(
    `/api/transfers/money/accounts?${params}`
  );

  if (res.code !== 0) {
    throw new Error(res.message || '');
  }

  return res;
}

export interface BankInfo {
  name: string;
  bic: string;
  city: string;
  corrAccount: string;
}

export async function getBankByBic(bic: string): Promise<BankInfo> {
  return await fetchLkResult<BankInfo>(`/api/common/bank/${bic}`);
}

export type TMoneyTransfeLimitType = 'EXTRA_ALFA' | 'EXTRA_OTHER' | 'INTRA';

export interface MoneyLimitRequest {
  treaty: number;
  currency: string;
  accCodeFrom: string;
  placeCodeFrom: string;
  accCodeTo?: string;
  placeCodeTo?: string;
  type: TMoneyTransfeLimitType;
  amount: number;
}
interface MoneyTaxesDetails {
  taxAccrued: number;
  taxWithheld: number;
  taxDue: number;
}
export interface MoneyTaxes {
  taxSum: number;
  taxRate: string;
  taxDetails: MoneyTaxesDetails | null;
  factWithdraw: number;
}
export interface MoneyLimitResult {
  limit: number;
  limitT0?: number;
  marginalLimit: number;
  marginalOn: boolean;
  taxes: MoneyTaxes;
}

export async function getMoneyLimit(
  data: MoneyLimitRequest
): Promise<MoneyLimitResult> {
  const params = makeGetParams(data);
  const res = await fetchLkResult<MoneyLimitResult | LKResult>(
    `/api/transfers/money/limit?${params}`
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}

export interface MoneyTransferToBankRequest {
  portfolio: string;
  placeCode: string;
  currency: string;
  amount: number;
  moneyAccount: string;
  bank: BankInfo;
  comments?: string;
}

export interface MoneyTransferToBankParams {
  params: MoneyTransferToBankRequest;
  signOptions?: LkSignOptions;
}

export async function moneyTransferToBank(data: MoneyTransferToBankParams) {
  return await signOperation(
    '/api/transfers/money/bank',
    'POST',
    data.params,
    data.signOptions
  );
}

export interface MoneyTransferFreeOrder {
  portfolio: string;
  currency: string;
  amount: number;
  placeCode: string;
  beneficiary: string;
  beneficiaryAccount: string;
  beneficiaryAddress: string;
  bank: string;
  bankSwift: string;
  corrBank: string;
  corrBankSwift: string;
  corrAccount: string;
  paymentPurpose: string;
}

export interface MoneyTransferFreeOrderParams {
  params: MoneyTransferFreeOrder;
  signOptions?: LkSignOptions;
}

export async function moneyTransferFreeOrder(
  data: MoneyTransferFreeOrderParams
) {
  return await signOperation(
    '/api/transfers/money/free-order',
    'POST',
    data.params,
    data.signOptions
  );
}

export interface MoneyTransferMoveRequest {
  from: {
    subAccount: string;
    placeCode: string;
  };
  currency: string;
  amount: number;
  to: {
    subAccount: string;
    placeCode: string;
  };
}

export interface MoneyTransferMoveParams {
  params: MoneyTransferMoveRequest;
  signOptions?: LkSignOptions;
}

export async function moneyTransferMove(data: MoneyTransferMoveParams) {
  return await signOperation(
    '/api/transfers/money/move',
    'POST',
    data.params,
    data.signOptions
  );
}

export interface MoneyHistoryBalance {
  in: number;
  out: number;
  balance: number;
  currency: string;
}

export interface MoneyHistoryResult extends LKPageResult {
  transfers: MoneyHistoryItem[];
  totals: MoneyHistoryBalance[];
}

export async function getMoneyHistory(
  data: MoneyHistoryRequest
): Promise<MoneyHistoryResult & LKResult> {
  const params = makeGetParams(data, true);
  const res = await fetchLkResult<MoneyHistoryResult & LKResult>(
    `/api/transfers/money/history?${params}`
  );

  if (res.code !== 0) {
    throw new Error(res.message || '');
  }

  return res;
}

export interface MoneyHistoryIncome {
  name: string;
  description: string;
}

export interface MoneyHistoryType {
  name: string;
  description: string;
}

export interface MoneyHistoryCurrency {
  code: string;
  name: string;
}

export interface MoneyHistorySettings {
  types: MoneyHistoryType[];
  incomes: MoneyHistoryIncome[];
  currencies: MoneyHistoryCurrency[];
}

export async function getMoneyHistorySettings(
  treaty: number
): Promise<MoneyHistorySettings & LKResult> {
  const request = makeGetParams({ treaty });
  const res = await fetchLkResult<MoneyHistorySettings & LKResult>(
    `/api/transfers/money/history/settings?${request}`
  );

  if (res.code !== 0) {
    throw new Error(res.message || '');
  }

  return res;
}

export interface MoneyBankTemplate {
  id: number;
  title: string;
  accountNumber: string;
  bic: string;
  bankName: string;
  corrAcc: string;
  city: string;
  other?: {
    comments: string;
  };
}

export interface MoneyBankTemplateResults {
  items: MoneyBankTemplate[];
}

export async function requestMoneyBankTemplates(
  treaty: number
): Promise<MoneyBankTemplate[]> {
  const res = await fetchLkResult<MoneyBankTemplateResults & LKResult>(
    `/api/transfers/money/templates/${treaty}`
  );

  if (res.code !== 0) {
    throw new Error(res.message || '');
  }

  return res.items;
}

export interface MoneyBankTemplateSaveRequest {
  id?: number;
  treaty: number;
  title: string;
  accountNumber: string;
  bic: string;
  comments: string;
}

export async function moneyBankTemplateSave(
  params: MoneyBankTemplateSaveRequest
): Promise<LKResult> {
  return await fetchLkResult<LKResult>(
    `/api/transfers/money/templates/save-or-update`,
    'POST',
    params
  );
}

export interface MoneyBankTemplateDeleteRequest {
  id: number;
  treaty: number;
}

export async function moneyBankTemplateDelete(
  params: MoneyBankTemplateDeleteRequest
): Promise<LKResult> {
  return await fetchLkResult<LKResult>(
    `/api/transfers/money/templates/delete`,
    'POST',
    params
  );
}

export interface BankAccount {
  name: string;
  nameLatin: string;
  treaty: number;
  openDate: Date;
  currency: string;
  placeCode: string;
  placeName: string;
  moneyAccount: string;
}

export interface BankAccountGoInvest {
  account: string;
  bank: string;
  beneficiary: string;
  bic: string;
  corrAccount: string;
  currency: string;
  destination: string;
  inn: string;
  kpp: string;
}

export async function getMoneyAccountRequisites(): Promise<BankAccount[]> {
  return await fetchLkResult<BankAccount[]>('/api/requisites/money');
}

export async function getMoneyAccountRequisitesGoInvest(
  treaty: number
): Promise<BankAccountGoInvest> {
  const request = makeGetParams({ treaty });

  return await fetchLkResult<BankAccountGoInvest>(
    `/api/requisites/money?${request}`
  );
}

export interface CurrenciesRequisites {
  id: number;
  currency: string;
  account: string;
  corrAccount: string;
  beneficiary: string;
  bic: string;
  bank: string;
  inn: string;
  kpp: string;
  destination: string;
  bankIntermediary: string;
  bankIntermediarySwift: string;
  bankCorrName: string;
  bankCorrSwift: string;
  bankCorrAccount: string;
  recAdress: string;
  bankRecSwift: string;
  paymentPurpose: string;
  priority: number;
  requisitesName: string;
  error: string;
}

export async function getMoneyRequisitesListGoInvest(
  treaty: number
): Promise<CurrenciesRequisites[]> {
  const request = makeGetParams({ treaty });
  const res = await fetchLkResult<CurrenciesRequisites[] | LKResult>(
    `/api/requisites/money/list?${request}`
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}

export interface CardTransactionParams {
  treaty: number;
  amount: number;
  returnUrl: string;
}

export interface CardTransaction {
  orderId: string;
  formUrl: string;
}

export const createCardTransaction = async (
  params: CardTransactionParams
): Promise<CardTransaction & LKResult> => {
  const res = await fetchLkResult<CardTransaction & LKResult>(
    `/api/top-up/create`,
    'POST',
    params
  );

  if (res.code && res.code !== 0) {
    throw new Error(res.message || '');
  }

  return res;
};
