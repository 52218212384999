import cn from 'classnames';
import groupBy from 'lodash/groupBy';
import React, { useCallback, useMemo, useState } from 'react';
import { Checkbox } from '@alfalab/core-components/checkbox';
import { CheckboxGroupDesktop as CheckboxGroup } from '@alfalab/core-components/checkbox-group/desktop';
import { Divider } from '@alfalab/core-components/divider';
import { Input } from '@alfalab/core-components/input/modern';
import { Space } from '@alfalab/core-components/space';
import { Switch } from '@alfalab/core-components/switch';
import { TooltipDesktop as Tooltip } from '@alfalab/core-components/tooltip/desktop/Component.desktop';
import { Typography } from '@alfalab/core-components/typography';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';
import { MagnifierMIcon } from '@alfalab/icons-glyph/MagnifierMIcon';

import { MultiSelectItemObject } from '@terminal/core/types/layout';

import styles from './MultiSelectFilter.module.css';

type MultiSelectFilterProps = {
  filterItems?: string[] | MultiSelectItemObject[];
  filterValue?: string[];
  onChange: (value: string[]) => void;
  isChips?: boolean;
  withSearch?: boolean;
  listClassName?: string;
};

export const MultiSelectFilter = (props: MultiSelectFilterProps) => {
  const {
    filterItems: rawFilterItems,
    filterValue,
    onChange,
    isChips,
    withSearch,
    listClassName,
  } = props;

  const [searchInputValue, setSearchInputValue] = useState('');

  const filterItems: string[] | MultiSelectItemObject[] = useMemo(() => {
    if (!rawFilterItems) {
      return [];
    }

    // Типовая защита для определения типа элементов массива
    if (typeof rawFilterItems[0] === 'string') {
      // Если элементы являются строками
      return (rawFilterItems as string[]).filter((item) =>
        item.toLowerCase().includes(searchInputValue.toLowerCase())
      );
    } else {
      // Если элементы являются объектами MultiSelectItemObject
      return (rawFilterItems as MultiSelectItemObject[]).filter((item) =>
        item.name.toLowerCase().includes(searchInputValue.toLowerCase())
      );
    }
  }, [rawFilterItems, searchInputValue]);

  const onArrayChange = useCallback(
    (_, payload) => {
      let items = Array.isArray(filterValue) ? [...filterValue] : [];

      if (!items.includes(payload.name)) {
        items.push(payload.name);
      } else {
        items.splice(items.indexOf(payload.name), 1);
      }

      onChange(items);
    },
    [filterValue, onChange]
  );

  const isGrouped = useMemo(() => {
    return filterItems?.some((item) => item?.group);
  }, [filterItems]);

  const groups: Record<string, MultiSelectItemObject[]> | null = useMemo(() => {
    if (!isGrouped) {
      return null;
    }

    return groupBy(filterItems, 'group') as Record<
      string,
      MultiSelectItemObject[]
    >;
  }, [filterItems, isGrouped]);

  const onChangeSwitch = (items: MultiSelectItemObject[]) => {
    let filterItems = Array.isArray(filterValue) ? [...filterValue] : [];

    if (items.every((item) => filterValue?.includes(item.value as string))) {
      items.forEach((item) => {
        filterItems.splice(filterItems.indexOf(item.value as string), 1);
      });
    } else {
      items
        .filter((item) => !filterValue?.includes(item.value as string))
        .forEach((item) => {
          filterItems.push(item.value as string);
        });
    }

    onChange(filterItems);
  };

  const renderCheckboxes = (items?: string[] | MultiSelectItemObject[]) => {
    return (
      <CheckboxGroup className={styles.filterItems} onChange={onArrayChange}>
        {items?.map((item, index) => {
          const [name, value, description] = item?.value
            ? [item.content ?? item.name, item.value, item.description]
            : [item, item];

          return (
            <Checkbox
              key={index}
              label={
                <Typography.Text view="secondary-medium">
                  {name}
                  {description ? (
                    <Tooltip
                      contentClassName={styles.tooltipContent}
                      content={description}
                      trigger="hover"
                      position="top-start"
                      offset={[-20, 16]}
                    >
                      <InformationCircleLineSIcon
                        height={12}
                        width={12}
                        color="var(--color-dark-graphic-secondary)"
                      />
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </Typography.Text>
              }
              boxClassName={styles.checkbox}
              contentClassName={styles.checkBoxContent}
              className={cn(
                styles.filterWrapper,
                styles.checkboxContent,
                isChips && styles.checkboxContentChips
              )}
              size="s"
              name={value}
              checked={
                Array.isArray(filterValue)
                  ? filterValue?.includes(value)
                  : false
              }
              block
            />
          );
        })}
      </CheckboxGroup>
    );
  };

  return (
    <Space size={0} className={styles.wrapper} fullWidth>
      {withSearch && (
        <>
          <Divider />
          <Input
            className={styles.searchInput}
            value={searchInputValue}
            onChange={(e) => setSearchInputValue(e.target.value)}
            onClear={() => setSearchInputValue('')}
            placeholder="Поиск"
            leftAddons={
              <MagnifierMIcon
                width={16}
                height={16}
                color="var(--color-light-graphic-secondary)"
              />
            }
            size="s"
            block
            clear
          />
          <Divider className={styles.divider} />
        </>
      )}
      <div className={cn(styles.list, listClassName)}>
        {isGrouped && groups
          ? Object.entries(groups).map(([key, items]) => (
              <div key={key}>
                <Switch
                  checked={items.every((item) =>
                    filterValue?.includes(item.value as string)
                  )}
                  label={
                    <Typography.Text view="secondary-medium">
                      {key}
                    </Typography.Text>
                  }
                  onChange={() => {
                    onChangeSwitch(items);
                  }}
                  block
                  reversed
                  className={styles.switch}
                />
                {renderCheckboxes(items)}
              </div>
            ))
          : renderCheckboxes(filterItems)}
      </div>
    </Space>
  );
};
