import cn from 'classnames';
import isNull from 'lodash/isNull';
import {
  cloneElement,
  useCallback,
  useMemo,
  useRef,
  useState,
  VFC,
} from 'react';
import { Amount, CurrencyCodes } from '@alfalab/core-components/amount';
import { ButtonDesktop as Button } from '@alfalab/core-components/button/desktop';
import { Gap } from '@alfalab/core-components/gap';
import { TagDesktop } from '@alfalab/core-components/tag/desktop';
import { TooltipDesktop as Tooltip } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { CalendarLineMIcon } from '@alfalab/icons-glyph/CalendarLineMIcon';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { ChevronDownCompactMIcon } from '@alfalab/icons-glyph/ChevronDownCompactMIcon';
import { ClockLineMIcon } from '@alfalab/icons-glyph/ClockLineMIcon';
import { DocumentLineMIcon } from '@alfalab/icons-glyph/DocumentLineMIcon';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';

import { useMissingAmount } from '@terminal/alfadirect/hooks';
import { Dropdown } from '@terminal/common/components/Dropdown';
import { activationOptions } from '@terminal/core/constants/orderBook';
import { MINORITY } from '@terminal/core/constants/ui';
import { useOnClickOutside } from '@terminal/core/hooks/useOnClickOutside';
import { useOrderBookService } from '@terminal/core/hooks/useOrderBookService';
import {
  ObjectGroup,
  OrderType,
  PriceControlType,
} from '@terminal/core/lib/client/entities';
import { getSymbolToShow } from '@terminal/core/lib/currencies';
import { formatNumber, getStepDecimals } from '@terminal/core/lib/format';
import { getPriceError } from '@terminal/core/lib/helpers/getPriceError';
import { AdditionalInstruction } from '@terminal/core/types/additionalInstruction';
import { ExtendedFI } from '@terminal/core/types/extendedFI';
import { LimitsProps } from '@terminal/core/types/layout';
import { FinInfoExt } from '@terminal/core/types/quotes';
import { TradeLimits } from '@terminal/core/types/tradeLimits';
import { OrderSide } from '@terminal/core/types/trading';

import { MissingAmountTopUp } from '../../../../components/MissingAmountTopUp';
import {
  OrderSource,
  useOrderAnalyticsMetrics,
} from '../../../../entities/Order';
import {
  ConditionData,
  instructionsOptionsParams,
  TradingLimits,
  TradingLimitsSettings,
} from '../../../../features';
import { OrderConfirm } from '../../../../features/Orders';
import { PriceInputComponent } from '../../../../features/PriceInput';
import { TradingParamsLock } from '../../../../features/TradingParamsLock';
import { useWidgetContext } from '../../../../shared';
import { useTradingContext } from '../../context';
import { formatWithSuffix } from '../../lib/formatWithSuffix';
import { FormType, VolumeType } from '../../model';
import { AdditionalInstructionsSelect } from '../AdditionalInstructionsSelect';
import { PriceInput } from '../PriceInput';
import { SlippageInput } from '../SlippageInput';
import { VolumeInput, type VolumeInputProps } from '../VolumeInput';
import { VolumeInputHint } from '../VolumeInputHint';
import { Summary } from './Summary';

import { useIsLotCount } from '../../../../shared/hooks/useIsLotCount';
import { useWarranty } from '../../../../shared/hooks/useWarranty';
import { useFormOptions, useTradingForm } from '../../hooks';
import { useCashQuantity } from '../../hooks/useCashQuantity';
import { useConvertedValue } from '../../hooks/useConvertedValue';

import styles from './WidgetForm.module.css';

interface WidgetFormProps {
  nodeId?: string;
  fullFi?: ExtendedFI;
  finInfoExt?: FinInfoExt;
  selectedSubAccounts: string[];
  isOpenLimitsModal: boolean;
  setIsOpenLimitsModal: (state: boolean) => void;
  limitsProps?: LimitsProps;
}

export const WidgetForm: VFC<WidgetFormProps> = (props) => {
  const {
    nodeId,
    fullFi,
    finInfoExt,
    selectedSubAccounts,
    isOpenLimitsModal,
    setIsOpenLimitsModal,
    limitsProps,
  } = props;
  const [secondPriceTouched, setSecondPriceTouched] = useState<boolean>(false);
  const [orderTypeOpen, setOrderTypeOpen] = useState(false);
  const [lifeTimeOpen, setLifeTimeOpen] = useState(false);
  const [activationOpen, setActivationOpen] = useState(false);

  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const orderTypeDropdown = useRef<HTMLDivElement>(null);
  const orderTypeDropdownBody = useRef<HTMLDivElement>(null);
  const lifeTimeDropdown = useRef<HTMLDivElement>(null);
  const activationDropdown = useRef<HTMLDivElement>(null);

  useOnClickOutside([orderTypeDropdown, orderTypeDropdownBody], () =>
    setOrderTypeOpen(false)
  );
  useOnClickOutside(lifeTimeDropdown, () => setLifeTimeOpen(false));
  useOnClickOutside(activationDropdown, () => setActivationOpen(false));

  const {
    idFi,
    selectedAccount,
    selectedSubAccountId,
    side,
    setSide,
    orderType,
    setOrderType,
    price,
    setPrice,
    priceTouched,
    setPriceTouched,
    secondPrice,
    setSecondPrice: setWidgetSecondPrice,
    slippage,
    setSlippage,
    last,
    quantity,
    setQuantity,
    lifeTime,
    setLifeTime,
    activation,
    setActivation,
    currentAdditionalInstructions,
    setCurrentAdditionalInstructions,
    sendOrderParams,
    sendOrder,
    currencyCode,
    volumeType,
    setVolumeType,
    computedQuantity,
  } = useTradingContext();

  const { lifeTimeOptions, orderTypeOptions } = useFormOptions(
    fullFi?.idMarketBoard
  );
  const isLotCount = useIsLotCount();

  const {
    useSettings,
    useConnectionError,
    DropdownComponent,
    useTradeLimits,
    openTopUpModal,
  } = useWidgetContext();

  const { trackStartOrder, trackConfirmOrder } = useOrderAnalyticsMetrics(idFi);

  const limitParams = useMemo(() => {
    const { visibleQuantity = 0 } =
      currentAdditionalInstructions?.[AdditionalInstruction.ICEBERG]?.data ||
      {};
    const { idPriceControlType = PriceControlType.None } =
      currentAdditionalInstructions?.[AdditionalInstruction.CONDITION]?.data ||
      {};

    return {
      idFi,
      orderType,
      lifeTime: lifeTime.value,
      price,
      secondPrice,
      openQuantity: visibleQuantity,
      idPriceControlType,
    };
  }, [
    currentAdditionalInstructions,
    idFi,
    lifeTime.value,
    orderType,
    price,
    secondPrice,
  ]);

  const { tradeLimitsLong, tradeLimitsShort } = useTradeLimits(
    selectedSubAccountId,
    selectedSubAccounts,
    limitParams,
    selectedAccount?.idAccount
  );

  const tradeLimits = useMemo(
    () => (side === 'buy' ? tradeLimitsLong : tradeLimitsShort),
    [side, tradeLimitsLong, tradeLimitsShort]
  );
  const allTradeLimits = useMemo<TradeLimits>(
    () => ({
      short: tradeLimitsShort,
      long: tradeLimitsLong,
    }),
    [tradeLimitsLong, tradeLimitsShort]
  );

  const isDisabledButtons = useConnectionError();
  const {
    defaultValues: { alwaysConfirmOrders, orderDimension },
  } = useSettings();

  const {
    setSecondPrice,
    secondPriceAdditional,
    secPAdSuffix,
    instructionsAvailability,
    total,
    fee,
    updatePrice,
    isPriceStreamed,
    setPriceStreamed,
    isHideQuickLimits,
  } = useTradingForm({
    idFi,
    formType: FormType.WIDGET,
    price,
    setPrice,
    priceTouched,
    setPriceTouched,
    setSecondPrice: setWidgetSecondPrice,
    secondPriceTouched,
    setSecondPriceTouched,
    quantity,
    setQuantity,
    finInfoExt,
    fullFi,
    selectedAccount,
    side,
    orderType,
    last,
    tradeLimits,
    computedQuantity,
    slippage,
    volumeType,
  });
  const isBracket = orderType === OrderType.BRS || orderType === OrderType.TBRS;
  const isSTL = orderType === OrderType.STL;
  const brsSide = side === 'buy' ? 'sell' : 'buy';
  const isBond = fullFi?.idObjectGroup === ObjectGroup.Bonds;
  const symbol = getSymbolToShow(fullFi?.currencyCode, fullFi?.idObjectGroup);

  const [currentInstruction] = Object.values(
    currentAdditionalInstructions || {}
  );

  const { decimalsNumber, priceDecimals } = getStepDecimals(
    finInfoExt?.priceStep
  );

  const oneLotCashQuantity = useCashQuantity({
    volumeType,
    fullFi,
    quantity: finInfoExt?.lot || 1,
    price,
    selectedAccount,
    finInfoExt,
    idFi,
    side,
  });

  const warranty = useWarranty(idFi, price, {
    side,
    finInfoExt,
    idAccount: selectedAccount?.idAccount,
  });

  const { bestBid, bestAsk } = useOrderBookService(idFi, 2);

  const handleChangeSecondPrice = useCallback(
    (_, { value }) => {
      setSecondPrice(
        isNull(value)
          ? 0
          : Number((value / priceDecimals).toFixed(decimalsNumber)),
        true
      );
    },
    [setSecondPrice, priceDecimals, decimalsNumber]
  );

  const handleAddSecondPrice = useCallback(() => {
    if (finInfoExt?.priceStep) {
      setSecondPrice(
        formatNumber(secondPrice + finInfoExt.priceStep, decimalsNumber)
      );
    }
  }, [decimalsNumber, finInfoExt?.priceStep, secondPrice, setSecondPrice]);

  const handleSubSecondPrice = useCallback(() => {
    if (finInfoExt?.priceStep) {
      setSecondPrice(
        formatNumber(
          Math.max(0, secondPrice - finInfoExt.priceStep),
          decimalsNumber
        )
      );
    }
  }, [finInfoExt?.priceStep, setSecondPrice, secondPrice, decimalsNumber]);

  const handleBlurSecondPrice = useCallback(
    () => setSecondPrice(),
    [setSecondPrice]
  );

  const secondPriceError = getPriceError(
    secondPrice,
    secondPriceTouched,
    finInfoExt?.priceStep
  );

  const convertedValue = useConvertedValue({
    quantity,
    computedQuantity,
    lot: finInfoExt?.lot || 1,
    orderDimension,
    volumeType,
  });

  // Количество штук не кратно лоту
  const isNotMultiplyToLotError = useMemo(() => {
    const lot = finInfoExt?.lot || 0;

    return (
      volumeType === VolumeType.Volume &&
      !isLotCount &&
      Boolean(lot) &&
      quantity % lot !== 0
    );
  }, [finInfoExt?.lot, isLotCount, quantity, volumeType]);

  const isFieldError = useMemo(() => {
    const hasPriceError = Boolean(
      getPriceError(price, priceTouched, finInfoExt?.priceStep)
    );
    const hasSecondPriceError = Boolean(secondPriceError);

    return hasPriceError || hasSecondPriceError || isNotMultiplyToLotError;
  }, [
    finInfoExt?.priceStep,
    price,
    priceTouched,
    secondPriceError,
    isNotMultiplyToLotError,
  ]);

  const volumeInputAdditionalInfo = useMemo<
    Pick<VolumeInputProps, 'bottomAddons' | 'error'>
  >(() => {
    const hint = (
      <VolumeInputHint
        idFi={idFi}
        selectedSubAccountId={selectedSubAccountId}
        selectedSubAccounts={selectedSubAccounts}
        volumeType={volumeType}
        side={side}
        tradeLimits={allTradeLimits}
        price={price}
        bestAsk={bestAsk?.Price ?? 0}
        bestBid={bestBid?.Price ?? 0}
        slippage={slippage}
        orderType={orderType}
      />
    );

    if (tradeLimits?.quantity && computedQuantity > tradeLimits.quantity) {
      return {
        error: <></>,
      };
    }

    if (
      volumeType === VolumeType.Cash &&
      quantity / MINORITY < oneLotCashQuantity
    ) {
      return {
        error: (
          <>
            <div className={styles.hintWrapper}>
              <Typography.Text view="secondary-small">
                минимум{' '}
                <Amount.Pure
                  value={oneLotCashQuantity * MINORITY}
                  minority={MINORITY}
                  currency="RUB"
                />
              </Typography.Text>
            </div>
            <div className={styles.hintWrapper}>{hint}</div>
          </>
        ),
      };
    }

    if (!isNotMultiplyToLotError) {
      return {
        bottomAddons: <div className={styles.hintWrapper}>{hint}</div>,
      };
    }

    return {
      error: (
        <div className={styles.hintWrapper}>
          <Typography.Text view="secondary-small">
            некратный лоту объём, 1 лот = {finInfoExt?.lot ?? 0} шт
          </Typography.Text>
          {hint}
        </div>
      ),
    };
  }, [
    tradeLimits,
    oneLotCashQuantity,
    allTradeLimits,
    bestAsk?.Price,
    bestBid?.Price,
    finInfoExt?.lot,
    computedQuantity,
    idFi,
    isNotMultiplyToLotError,
    orderType,
    price,
    selectedSubAccountId,
    selectedSubAccounts,
    quantity,
    side,
    slippage,
    volumeType,
  ]);

  const additionalInstructionContent = useMemo(() => {
    if (!currentInstruction) {
      return;
    }

    const CurrentAdditionalInstructionIcon =
      instructionsOptionsParams[currentInstruction.name]?.icon;

    return (
      <>
        {cloneElement(CurrentAdditionalInstructionIcon, {
          height: 15,
          width: 15,
          color: 'var(--color-light-graphic-secondary)',
        })}
        {instructionsOptionsParams[currentInstruction.name].getButtonText(
          currentInstruction.data,
          {
            idFi,
            isLotCount,
            price,
            side,
            symbol,
            priceStep: finInfoExt?.priceStep,
          },
          false
        )}
      </>
    );
  }, [
    currentInstruction,
    finInfoExt?.priceStep,
    idFi,
    isLotCount,
    price,
    side,
    symbol,
  ]);

  const renderFee = () => (
    <div>
      ≈&nbsp;
      {fee ? (
        <Amount.Pure
          //Пока захардкодил как 1% комиссии
          //Комиссия всегда с точностью 2 знака после запятой
          value={fee * MINORITY}
          minority={MINORITY}
          view="withZeroMinorPart"
          //Комиссия отображается только в рублевом виде, независимо от валюты fi
          currency="RUB"
        />
      ) : (
        '0,00'
      )}
    </div>
  );

  const handleConditionSubmit = useCallback(
    (data: ConditionData) => {
      setSecondPrice(data.stopPrice);
      setCurrentAdditionalInstructions({
        [AdditionalInstruction.CONDITION]: {
          name: AdditionalInstruction.CONDITION,
          data,
        },
      });
    },
    [setSecondPrice, setCurrentAdditionalInstructions]
  );

  const getPriceLabel = () => {
    if (isBracket) {
      return 'Цена Тейк-профит';
    }

    if (isSTL) {
      return (
        <>
          Цена Стоп-Лосс&nbsp;
          <Tooltip
            targetClassName={styles.tooltipTarget}
            contentClassName={styles.tooltipContent}
            content={
              <>
                <Typography.Text
                  weight="bold"
                  view="secondary-small"
                  className={styles.graphic}
                >
                  Цена Стоп-лосс
                </Typography.Text>
                <Typography.Text
                  view="secondary-small"
                  className={styles.graphic}
                >
                  Если на рынке пройдет сделка по указанной стоп-цене, будет
                  выставлена лимитная заявка, которая будет исполнена по
                  стоп-цене + проскок (для покупок) или стоп-цене - проскок (для
                  продаж)
                </Typography.Text>
              </>
            }
            trigger="hover"
            position="top-start"
            offset={[-20, 16]}
          >
            <InformationCircleLineSIcon
              height={10}
              width={10}
              color="var(--color-dark-graphic-secondary)"
            />
          </Tooltip>
        </>
      );
    }
  };

  const { missingAmount, isEnoughMoney } = useMissingAmount(
    total / MINORITY,
    fee,
    fullFi?.idObjectGroup,
    side === 'buy' ? tradeLimitsLong : tradeLimitsShort,
    warranty * quantity
  );

  return (
    <div className={styles.form} ref={containerRef}>
      <TradingLimits
        idFi={idFi}
        nodeId={nodeId}
        selectedSubAccountId={selectedSubAccountId}
        selectedSubAccounts={selectedSubAccounts}
        limitsProps={limitsProps}
        tradeLimitsLong={tradeLimitsLong}
        tradeLimitsShort={tradeLimitsShort}
      />
      <TradingLimitsSettings
        nodeId={nodeId}
        limitsProps={limitsProps}
        open={isOpenLimitsModal}
        onClose={() => setIsOpenLimitsModal(false)}
        container={
          containerRef.current && containerRef.current.clientWidth >= 290
            ? () => containerRef.current as HTMLDivElement
            : undefined
        }
      />

      <div className={styles.grid}>
        <TagDesktop
          view="filled"
          size="xs"
          checked={side === OrderSide.BUY}
          onClick={() => setSide(OrderSide.BUY)}
          className={cn(styles.halfWidth, styles.boldTag)}
        >
          Покупка
        </TagDesktop>
        <TagDesktop
          view="filled"
          size="xs"
          checked={side === OrderSide.SELL}
          onClick={() => setSide(OrderSide.SELL)}
          className={cn(styles.halfWidth, styles.boldTag)}
        >
          Продажа
        </TagDesktop>

        <div
          ref={orderTypeDropdown}
          className={cn(styles.dropdownRef, styles.fullWidth)}
          onClick={(e) => {
            if (orderTypeDropdown.current?.contains(e.target as Node)) {
              setOrderTypeOpen((prev) => !prev);
            }
          }}
        >
          <div className={styles.dropDownContent}>
            <DocumentLineMIcon
              height={18}
              width={18}
              color="var(--color-light-graphic-secondary)"
            />
            <Typography.Text
              view="secondary-large"
              weight="medium"
              className={cn(styles.orderName)}
            >
              {orderTypeOptions.find((op) => op.value === orderType)?.content}
            </Typography.Text>
            <ChevronDownCompactMIcon
              height={16}
              width={16}
              color="var(--color-light-graphic-secondary)"
              className={cn(styles.chevron, orderTypeOpen && styles.chevronUp)}
            />
          </div>
          <DropdownComponent
            ref={orderTypeDropdownBody}
            anchorElement={orderTypeDropdown.current}
            open={orderTypeOpen}
            offset={[0, 4]}
            position="bottom-start"
            header={
              <div className={styles.menuTitleWrapper}>
                <Typography.Text view="secondary-large" weight="bold">
                  Тип заявки
                </Typography.Text>
              </div>
            }
            headerDivider={false}
          >
            {orderTypeOptions.map((opt) => {
              const isSelected = opt.value === orderType;

              return (
                <Dropdown.Item
                  key={opt.value}
                  content={
                    <div className={styles.dropDownItemContent}>
                      {opt.content}
                      <Tooltip
                        contentClassName={styles.tooltipContent}
                        content={opt.description}
                        trigger="hover"
                        position="top-start"
                        offset={[-20, 16]}
                      >
                        <InformationCircleLineSIcon
                          height={12}
                          width={12}
                          color="var(--color-light-graphic-secondary)"
                        />
                      </Tooltip>
                    </div>
                  }
                  onClick={() => {
                    setOrderType(opt.value);
                    setOrderTypeOpen(false);
                  }}
                  rightIcon={
                    isSelected ? (
                      <CheckmarkMIcon height={18} width={18} />
                    ) : undefined
                  }
                />
              );
            })}
          </DropdownComponent>
        </div>

        {isBracket && (
          <PriceInputComponent
            withShortcuts
            isChart={false}
            label="Цена Стоп-маркет"
            value={secondPrice * priceDecimals}
            minority={priceDecimals}
            currency={symbol}
            orderType={orderType}
            side={isBracket ? brsSide : side}
            allowStreamPrice={!isBracket}
            suffix={symbol + secPAdSuffix}
            limitPriceAdditional={secondPriceAdditional}
            onChange={handleChangeSecondPrice}
            onAdd={handleAddSecondPrice}
            onSub={handleSubSecondPrice}
            isPriceStreamed={isPriceStreamed}
            setPriceStreamed={setPriceStreamed}
            error={secondPriceError}
            onBlur={handleBlurSecondPrice}
            containerClassName={styles.fullWidth}
          />
        )}

        <PriceInput
          isChart={false}
          label={getPriceLabel()}
          price={price}
          priceTouched={priceTouched}
          idFi={idFi}
          orderType={orderType}
          isPriceStreamed={isPriceStreamed}
          side={side}
          fullFi={fullFi}
          priceStep={finInfoExt?.priceStep}
          tradeLimits={tradeLimits}
          setPrice={setPrice}
          setSecondPrice={setWidgetSecondPrice}
          handleSetPrice={updatePrice}
          setPriceStreamed={setPriceStreamed}
          containerClassName={styles.fullWidth}
        />

        {isSTL && (
          <SlippageInput
            isChart={false}
            side={side}
            orderType={orderType}
            slippage={slippage}
            setSlippage={setSlippage}
            price={price}
            symbol={symbol}
            priceStep={finInfoExt?.priceStep || 0.01}
            containerClassName={styles.fullWidth}
          />
        )}

        <div className={styles.fullWidth}>
          <Gap size="2xs" />
          <div>
            <TagDesktop
              size="xs"
              view="filled"
              checked={volumeType === VolumeType.Volume}
              onClick={() => setVolumeType(VolumeType.Volume)}
              className={styles.boldTag}
            >
              В {isLotCount ? 'лотах' : 'штуках'}
            </TagDesktop>
            <Gap size="xs" direction="horizontal" />
            <TagDesktop
              size="xs"
              view="filled"
              checked={volumeType === VolumeType.Cash}
              onClick={() => setVolumeType(VolumeType.Cash)}
              className={styles.boldTag}
            >
              На сумму
            </TagDesktop>
          </div>
        </div>

        <div className={styles.fullWidth}>
          <VolumeInput
            formType={FormType.WIDGET}
            orderType={orderType}
            quantity={quantity}
            idFi={idFi}
            side={side}
            price={price}
            lot={finInfoExt?.lot}
            currencyCode={currencyCode}
            tradeLimits={tradeLimits}
            setQuantity={setQuantity}
            isHideQuickLimits={isHideQuickLimits}
            selectedSubAccountId={selectedSubAccountId}
            selectedSubAccounts={selectedSubAccounts}
            volumeType={volumeType}
            convertedValue={convertedValue}
            {...volumeInputAdditionalInfo}
          />
        </div>

        {/* Выбираем дату активации заявки */}
        <div
          ref={activationDropdown}
          className={cn(styles.dropdownRef, styles.halfWidth)}
          onClick={() => setActivationOpen((prev) => !prev)}
        >
          <div className={styles.dropDownContent}>
            <ClockLineMIcon
              height={18}
              width={18}
              color="var(--color-light-graphic-secondary)"
            />
            <Typography.Text
              view="secondary-large"
              weight="medium"
              className={styles.orderName}
            >
              {activation.content}
            </Typography.Text>
            <ChevronDownCompactMIcon
              height={16}
              width={16}
              color="var(--color-light-graphic-secondary)"
              className={cn(styles.chevron, activationOpen && styles.chevronUp)}
            />
          </div>
          <DropdownComponent
            anchorElement={activationDropdown.current}
            open={activationOpen}
            offset={[0, 4]}
            position="bottom-start"
            header={
              <div className={styles.menuTitleWrapper}>
                <Typography.Text view="secondary-large" weight="bold">
                  Активация
                </Typography.Text>
              </div>
            }
            headerDivider={false}
          >
            {activationOptions.map((opt) => {
              const isSelected = opt.key === activation.key;

              return (
                <Dropdown.Item
                  key={opt.key}
                  content={
                    <div className={styles.dropDownItemContent}>
                      {opt.content}
                    </div>
                  }
                  onClick={() => setActivation(opt)}
                  rightIcon={
                    isSelected ? (
                      <CheckmarkMIcon height={18} width={18} />
                    ) : undefined
                  }
                />
              );
            })}
          </DropdownComponent>
        </div>

        {/* Выбираем срок исполнения заявки */}
        <div
          ref={lifeTimeDropdown}
          className={cn(styles.dropdownRef, styles.halfWidth)}
          onClick={() => setLifeTimeOpen((prev) => !prev)}
        >
          <div className={styles.dropDownContent}>
            <CalendarLineMIcon
              height={18}
              width={18}
              color="var(--color-light-graphic-secondary)"
            />
            <Typography.Text
              view="secondary-large"
              weight="medium"
              className={styles.orderName}
            >
              {lifeTime.content}
            </Typography.Text>
            <ChevronDownCompactMIcon
              height={16}
              width={16}
              color="var(--color-light-graphic-secondary)"
              className={cn(styles.chevron, lifeTimeOpen && styles.chevronUp)}
            />
          </div>
          <DropdownComponent
            anchorElement={lifeTimeDropdown.current}
            open={lifeTimeOpen}
            offset={[0, 4]}
            position="bottom-start"
            header={
              <div className={styles.menuTitleWrapper}>
                <Typography.Text view="secondary-large" weight="bold">
                  Срок исполнения
                </Typography.Text>
              </div>
            }
            headerDivider={false}
          >
            {lifeTimeOptions.map((opt) => {
              const isSelected = opt.key === lifeTime.key;

              if (opt?.isHidden) {
                return null;
              }

              return (
                <Dropdown.Item
                  key={opt.key}
                  content={
                    <div className={styles.dropDownItemContent}>
                      {opt.content}
                    </div>
                  }
                  onClick={() => setLifeTime(opt)}
                  rightIcon={
                    isSelected ? (
                      <CheckmarkMIcon height={18} width={18} />
                    ) : undefined
                  }
                />
              );
            })}
          </DropdownComponent>
        </div>

        <AdditionalInstructionsSelect
          widgetContainer={containerRef.current}
          side={side}
          price={price}
          instructionsAvailability={instructionsAvailability}
          isLotCount={isLotCount}
          priceStep={finInfoExt?.priceStep}
          lot={finInfoExt?.lot}
          symbol={symbol}
          currencyCode={fullFi?.currencyCode as CurrencyCodes}
          isBond={isBond}
          currentInstruction={currentInstruction}
          onConditionSubmit={handleConditionSubmit}
          containerClassName={styles.fullWidth}
        />
      </div>

      <div className={styles.bottomWrapper}>
        <TradingParamsLock
          orderType={sendOrderParams.idOrderType}
          executionType={sendOrderParams.idExecutionType}
          lifeTime={sendOrderParams.idLifeTime}
          idMarketBoard={fullFi?.idMarketBoard}
          idObjectGroup={fullFi?.idObjectGroup}
          idObject={fullFi?.idObject}
          linkedOrderId={sendOrderParams.linkedOrderId}
        >
          <div className={styles.formStickyBottom}>
            <div className={cn(styles.formRow, styles.mobileFormRowFullWidth)}>
              <Summary
                computedQuantity={computedQuantity}
                fullFi={fullFi}
                warranty={warranty}
                finInfoExt={finInfoExt}
                total={total}
                isBond={isBond}
                renderFee={renderFee}
              />
            </div>
            {isEnoughMoney ? (
              <div
                className={cn(styles.formRow, styles.mobileFormRowFullWidth)}
              >
                <Button
                  size="xs"
                  block
                  className={cn(
                    styles.submitButton,
                    side === 'buy' ? styles.buy : styles.sell
                  )}
                  disabled={
                    isDisabledButtons ||
                    isFieldError ||
                    Boolean(volumeInputAdditionalInfo?.error)
                  }
                  onClick={() => {
                    if (alwaysConfirmOrders) {
                      trackConfirmOrder(
                        OrderSource.HYBRID_ORDER,
                        sendOrderParams,
                        total / MINORITY
                      );
                      setIsOpenConfirm(true);
                    } else {
                      trackStartOrder(
                        OrderSource.HYBRID_ORDER,
                        sendOrderParams,
                        total / MINORITY
                      );
                      sendOrder(sendOrderParams);
                    }
                  }}
                >
                  <Typography.Text view="secondary-small" weight="medium">
                    {side === 'buy' ? 'Купить' : 'Продать'}&nbsp;
                    {volumeType === VolumeType.Volume &&
                      formatWithSuffix({ value: quantity, orderDimension })}
                    {volumeType === VolumeType.Cash && convertedValue}
                  </Typography.Text>
                </Button>
              </div>
            ) : (
              <MissingAmountTopUp
                sum={missingAmount}
                onTopUpClick={() =>
                  openTopUpModal({
                    selectedSubAccounts,
                    sum: missingAmount,
                  })
                }
              />
            )}

            <Gap size="m" />
          </div>
          {alwaysConfirmOrders && (
            <OrderConfirm
              fullFi={fullFi}
              priceDecimals={priceDecimals}
              isOpen={isOpenConfirm}
              onClose={() => setIsOpenConfirm(false)}
              onConfirm={(params) => {
                sendOrder(params);
                trackStartOrder(
                  OrderSource.HYBRID_ORDER,
                  params,
                  total / MINORITY
                );
              }}
              total={
                <Amount.Pure
                  value={total}
                  minority={MINORITY}
                  view="withZeroMinorPart"
                  currency={symbol}
                />
              }
              additionalInstruction={additionalInstructionContent}
              params={sendOrderParams}
              container={
                containerRef.current
                  ? () => containerRef.current as HTMLDivElement
                  : undefined
              }
            />
          )}
        </TradingParamsLock>
      </div>
    </div>
  );
};
