import React, { FC, memo } from 'react';

import { MainInfo as MainInfoLegacy } from '@terminal/lk/legacy/accountsInfo/MainInfo';
import { AccountInfoGI, Treaty } from '@terminal/lk-core';

interface MainInfoProps {
  treaty: Treaty | null;
  accountInfo?: AccountInfoGI;
  accountInfoPending: boolean;
  onAccountSave: () => void;
}

export const MainInfo: FC<MainInfoProps> = memo((props) => {
  const { treaty, accountInfo, accountInfoPending, onAccountSave } = props;

  return (
    <MainInfoLegacy
      treaty={treaty}
      accountInfo={accountInfo}
      accountInfoPending={accountInfoPending}
      onAccountSave={onAccountSave}
    />
  );
});

MainInfo.displayName = 'MainInfo';
