import { TableColumnKey } from '../types/tableColumn';

export const mapColumnToDescription: Record<TableColumnKey, string | null> = {
  // Common
  [TableColumnKey.NameObject]: null,
  [TableColumnKey.SymbolObject]: 'Биржевой код инструмента',
  [TableColumnKey.ShortDescription]: 'Описание инструмента',
  [TableColumnKey.IsSelected]: null,
  [TableColumnKey.ReleaseNameObject]: 'Наименование инструмента',

  // Watchlist
  [TableColumnKey.CodeMarketBoard]:
    'Рынок на котором торгуется данный инструмент',
  [TableColumnKey.ISIN]: 'ISIN или рег. номер выпуска инструмента',
  [TableColumnKey.Open]: 'Цена открытия текущего дня',
  [TableColumnKey.Close]:
    'Цена закрытия предыдущего дня или текущего дня, по завершении торгов',
  [TableColumnKey.LastPrice]:
    'Цена последней сделки или цена по которой строится график',
  [TableColumnKey.Change]: '% изменения цены Last по отношению к цене Close',
  [TableColumnKey.VolToday]:
    'Объем сделок текущего дня в единицах актива (штуках, лотах)',
  [TableColumnKey.ValToday]: 'Оборот сделок текущего дня в валюте актива',
  [TableColumnKey.NameObjectType]: 'Тип инструмента',
  [TableColumnKey.PriceStep]: 'Минимальный шаг цены',
  [TableColumnKey.PriceStepCost]: 'Стоимость шага цены в валюте инструмента',
  [TableColumnKey.Lot]: 'Размер лота в единицах',
  [TableColumnKey.MatDate]: 'Дата погашения/экспирации инструмента',
  [TableColumnKey.GtBuy]: 'ГО на первом уровне по фьючерсам',
  [TableColumnKey.Yield]:
    'Показывает расчетную доходность к погашению облигации по последней сделке',
  [TableColumnKey.PSTNKD]:
    'Показывает накопленный на текущую дату купонный доход по облигации',
  [TableColumnKey.NextCoupon]: 'Дата ближайшей выплаты купона',
  [TableColumnKey.CouponRate]: 'Ставка купона по бумаге',
  [TableColumnKey.CouponValue]: 'Размер купона по бумаге',
  [TableColumnKey.BuyBackDate]: 'Дата ближайшей оферты',
  [TableColumnKey.DaysToBuyBack]: 'Срок до оферты в днях',
  [TableColumnKey.DaysToMat]: 'Срок до погашения в днях',
  [TableColumnKey.IdFi]: 'Внутренний технический код инструмента',

  // FiModal
  [TableColumnKey.DescObject]: null,
  [TableColumnKey.Currency]: null,
  [TableColumnKey.NameMarketBoard]: null,
  [TableColumnKey.AvailableMarketBoards]: null,

  // Balance
  [TableColumnKey.IdAccount]: 'Номер Ген соглашения или торгового счета',
  [TableColumnKey.NameBalanceGroup]:
    'Наименование раздела рынка в составе субсчета, на котором рассчитываются позиции',
  [TableColumnKey.Balance]:
    'Чистый баланс всех активов и обязательств. Рассчитывается как сумма чистых стоимостей всех позиций с учетом переоценки позиций текущего дня',
  [TableColumnKey.PrevBalance]: 'Чистый баланс на начало торгового дня',
  [TableColumnKey.DailyPLRur]:
    'Прибыль/убыток за день (с учетом закрытых позиций)',
  [TableColumnKey.DailyPLRurPrecent]:
    'Прибыль/убыток за день (с учетом закрытых позиций) в % от суммы на торговом счете',
  [TableColumnKey.NplRur]: 'Прибыль/убыток по открытым позициям',
  [TableColumnKey.NplRurPercent]:
    'Прибыль/убыток по открытым позициям в % от суммы на торговом счете',
  [TableColumnKey.LongBalance]: 'Суммарная стоимость всех длинных позиций',
  [TableColumnKey.ShortBalance]:
    'Суммарная стоимость непокрытых позиций ценных бумаг портфеля по данному инструменту',
  [TableColumnKey.InitialMargin]:
    'Начальная маржа - уровень риска портфеля, рассчитанный на основании стоимостей позиций и начальных дисконтов по инструментам. Если начальная маржа превышает стоимость портфеля, не разрешается дальнейшее наращивание позиций (увеличение рыночного риска)',
  [TableColumnKey.MinimumMargin]:
    'Минимальная маржа - уровень риска портфеля, рассчитанный на основании стоимостей позиций и минимальных дисконтов по инструментам. Если минимальная маржа превышает стоимость портфеля, брокер принудительно закрывает позиции (производит сокращение рыночного риска)',
  [TableColumnKey.Requirements]:
    'Денежные средства, которые должны быть внесены на субсчет для поддержания позиции, т.е. разница между начальной маржой и стоимостью счета',
  [TableColumnKey.ImmediateRequirements]:
    'Сумма денежных средств, которая немедленно должна быть внесена на субсчет для исключения ликвидации части позиции брокером',
  [TableColumnKey.LiquedBalance]:
    'Сумма стоимостей всех положительных позиций (лонгов) по ликвидным активам с учетом НКД и стоимостей всех отрицательных позиций (шортов) в рублевом эквиваленте',
  [TableColumnKey.PortfolioValueWithOrders]:
    'Скорректированная стоимость субсчёта с учетом торговых поручений',
  [TableColumnKey.InitialMarginWithOrders]:
    'Скорректированная маржа – начальная маржа с учетом активных заявок',
  [TableColumnKey.Money]:
    'Чистая денежная позиция портфеля – денежные средства, которые остаются в распоряжении клиента после урегулирования всех прав и обязательств по сделкам и прочим операциям и уплаты комиссий и сборов',
  [TableColumnKey.InitialMoney]:
    'Чистая денежная позиция портфеля на начало торгового дня',
  [TableColumnKey.NameClient]: null,

  // AlertsModal
  [TableColumnKey.TargetPrice]: null,
  [TableColumnKey.Caption]: null,
  [TableColumnKey.IdState]: null,

  // Orders
  [TableColumnKey.CodeSubAccount]:
    'Номер торгового счета по которому подается поручение',
  [TableColumnKey.Direction]: 'Направление сделки (покупка/продажа)',
  [TableColumnKey.AcceptTime]:
    'Время принятия поручения торговой системой брокера',
  [TableColumnKey.AcceptDate]:
    'Дата принятия поручения торговой системой брокера',
  [TableColumnKey.Quantity]: 'Количество торгуемых единиц актива в заявке',
  [TableColumnKey.Executed]:
    'Количество торгуемых единиц актива в исполненной части заявки',
  [TableColumnKey.Rest]:
    'Количество торгуемых единиц актива в неисполненной части заявки',
  [TableColumnKey.DisplayPrice]: 'Цена заявки для заявок с одной ценой',
  [TableColumnKey.Type]:
    'Тип торговой заявки ( стоп, стоп-лимит, лимит, маркет и т.д.)',
  [TableColumnKey.DisplayStatus]:
    'Статус заявки по которому можно отфильтровать поданную заявку',
  [TableColumnKey.WithdrawTime]: 'Дата до которой заявка будет действовать',
  [TableColumnKey.LifeTime]:
    'Тип срока хранения заявки, выбираемый в момент подачи поручения',
  [TableColumnKey.OrderNumEDocument]: 'Номер заявки в торговой системе брокера',
  [TableColumnKey.OrderLogin]:
    'Пользовательский логин, под которым была подана заявка',
  [TableColumnKey.MarketNameMarketBoard]: 'Рынок на который было подана заявка',
  [TableColumnKey.StopPrice]: 'Стоп цена для заявки типа стоп-лимит',
  [TableColumnKey.LimitPrice]: 'Лимит цена для заявки типа стоп-лимит',
  [TableColumnKey.OrderClientNumEDocument]: 'Технический код поручения',

  // Operations
  [TableColumnKey.OperationTime]: 'Время',
  [TableColumnKey.OperationDate]: 'Дата выполнения операции',
  [TableColumnKey.OperationBuySell]: 'Направление операции (покупка/продажа)',
  [TableColumnKey.OperationQuantity]:
    'Количество единиц инструмента в операции',
  [TableColumnKey.OperationValue]: 'Объем операции в валюте инструмента',
  [TableColumnKey.OperationCurrency]: 'Валюта инструмента',
  [TableColumnKey.OperationStatus]: 'Статус выполнения расчетов по операции',
  [TableColumnKey.OperationAccount]: 'Номер Генерального соглашения с брокером',
  [TableColumnKey.OperationNumEDocument]:
    'Номер заявки в торговой системе брокера',
  [TableColumnKey.OrderComment]: 'Комментарий к операции',
  [TableColumnKey.OperationPrice]: 'Цена совершения операции',
  [TableColumnKey.OperationYield]:
    'Доходность к погашению для сделок по облигациям',
  [TableColumnKey.OperationAccruedInt]:
    'Накопленный купонный доход, уплаченный в рамках операции',
  [TableColumnKey.OperationSettleDate]: 'Дата расчетов по операции',
  [TableColumnKey.OperationDeliveryDate]: 'Дата поставки по операции',
  [TableColumnKey.OperationTypeId]: 'Тип совершенной сделки',

  // OperationHistory
  [TableColumnKey.OperationIsSettled]: 'Статус выполнения расчетов по операции',

  // Positions
  [TableColumnKey.PositionNameType]: 'Раздел хранения позиции',
  [TableColumnKey.PositionTorgPos]: 'Объем позиции',
  [TableColumnKey.PositionConvertedPrice]:
    'Цена по которой позиция учитывается при расчете прибыли и убытка',
  [TableColumnKey.PositionLiquidCost]:
    'Объем денежных средств, которые можно получить под залог данной позиции',
  [TableColumnKey.PositionPrice]:
    'Цена последней сделки или цена по которой строится график',
  [TableColumnKey.PositionNplPrice]: 'Прибыль/убыток по открытой позиции',
  [TableColumnKey.PositionNplPercent]:
    'Прибыль/убыток по открытой позиции в % от суммы на торговом счете',
  [TableColumnKey.PositionDailyPrice]:
    'Прибыль/убыток за день (с учетом закрытых позиций)',
  [TableColumnKey.PositionDailyPercent]:
    'Прибыль/убыток за день (с учетом закрытых позиций) в % от суммы на торговом счете',
  [TableColumnKey.PositionAssetsPart]:
    'Доля данной позиции в объеме торгового счета',
  [TableColumnKey.PositionInPrice]: 'Цена позиции на начало торгового дня',
  [TableColumnKey.PositionCurSymbol]:
    'Валюта, в которой осуществляются расчеты по сделке',
  [TableColumnKey.PositionPriceSymbol]: 'Валюта, в которой котируется актив',
  [TableColumnKey.PositionVariationMargin]: 'Вариационная маржа',
  [TableColumnKey.PositionDeposits]: 'Вводы денежных средств на торговый счет',
  [TableColumnKey.PositionBackPos]:
    'Позиция по инструменту на начало торгового дня',
  [TableColumnKey.PositionBackPosCost]:
    'Стоимость позиции на начало торгового дня',
  [TableColumnKey.PositionWithdrawal]:
    'Выводы денежных средств с торгового счета',
  [TableColumnKey.PositionIsMoney]:
    'Указывает, что данная позиция является денежной',
  [TableColumnKey.PositionSessionBuyQty]:
    'Объем купленной позиции за день в единицах актива',
  [TableColumnKey.PositionSessionBuyVal]: 'Объем купленной позиции в рублях',
  [TableColumnKey.PositionPSTNKD]:
    'Накопленный купонный доход по облигации в %',
  [TableColumnKey.PositionNKD]: null,
  [TableColumnKey.PositionLongOrders]:
    'Объем, выставленный на покупку, в лотах',
  [TableColumnKey.PositionPlanLong]: 'Объем, выставленный на покупку, в штуках',
  [TableColumnKey.PositionShortOrders]:
    'Объем, выставленный на продажу, в лотах',
  [TableColumnKey.PositionPlanShort]:
    'Объем, выставленный на продажу, в штуках',
  [TableColumnKey.PositionSessionVal]: 'Оборот по позиции за день, в рублях',
  [TableColumnKey.PositionSessionQty]: 'Оборот по позиции за день, в штуках',
  [TableColumnKey.PositionSessionSellQty]:
    'Объем проданной позиции за день в единицах актива',
  [TableColumnKey.PositionSessionSellVal]:
    'Объем проданной позиции за день в рублях',
  [TableColumnKey.PositionTorgPosCost]:
    'Стоимость позиции, после всех расчетов',
  [TableColumnKey.PositionSubAccNalPos]:
    'Стоимость позиции до проведения расчетов',
  [TableColumnKey.PositionTorgPosUchCost]:
    'Стоимость по которой позиция учитывается при расчете прибыли и убытка',
  [TableColumnKey.PositionGO]: 'Гарантийное обеспечение',

  // TradesFeed
  [TableColumnKey.TradePrice]: 'Цена сделки',
  [TableColumnKey.TradeQty]: 'Объем сделки',
  [TableColumnKey.TradeBuySell]: 'Сторона сделки по агрессору - Купля/Продажа',
  [TableColumnKey.TradeTime]: 'Время сделки',

  // BondScreener
  [TableColumnKey.BsName]: null,
  [TableColumnKey.BsUntilExpiration]:
    'Сколько времени осталось до даты выплаты инвестору номинальной стоимости облигации компанией-эмитентом',
  [TableColumnKey.BsPrice]: 'Текущая стоимость облигации на рынке',
  [TableColumnKey.BsYield]:
    'Ожидаемая доходность, которую инвестор получит, если будет держать облигацию до конца ее срока и будет получать все купоны и номинальную стоимость в соответствии с условиями выпуска',
  [TableColumnKey.BsCupYield]:
    'Процентная ставка, которую облигация обещает выплачивать ежегодно или периодически в виде купонов относительно ее номинальной стоимости',
  [TableColumnKey.BsAgencyRating]:
    'Кредитный рейтинг, присвоенный АКРА, оценивает способность эмитента исполнять принятые на себя финансовые обязательства (кредитоспособность, финансовая надежность, финансовая устойчивость) и о кредитном риске его отдельных финансовых обязательств. \n' +
    '\n' +
    'ААА–A — высокий уровень кредитоспособности, BBB–B — умеренный уровень, имеется возможность исполнения долговых обязательств в срок. ССС–С — низкий и очень низкий уровень, существует вероятность невыполнения рейтингуемым лицом своих долговых обязательств.',
  [TableColumnKey.BsCupSize]:
    'Выплата, которую инвестор получает в назначенную дату при приобретении облигации',
  [TableColumnKey.BsCouponPaymentPerYear]:
    'Число раз за год, когда выплачиваются купонные платежи по облигации ',
  [TableColumnKey.BsSectorName]:
    'Отрасль или область экономики, в которой действует организация, выпустившая облигации',
  [TableColumnKey.BsISIN]: 'Регистрационный номер выпуска инструмента',
  [TableColumnKey.BsEmShortName]:
    'Тип эмитента облигации определяет категорию организации или учреждения, которое выпустило облигацию. Обычно оказывает влияние на уровень риска и доходности облигации.',
  [TableColumnKey.BsCountryName]: 'Страна происхождения эмитента ценных бумаг',
  [TableColumnKey.BsNominal]:
    'Фиксированная сумма, которую эмитент обязуется выплатить инвестору при погашении облигации',
  [TableColumnKey.BsCurrency]:
    'Валюта, в которой указана номинальная стоимость облигации и процентные платежи',
  [TableColumnKey.BsYieldToOffert]:
    'Ожидаемая доходность для инвестора, если облигация будет выкуплена эмитентом по цене оферты (выкупа) в определенный заранее срок, как это указано в условиях выпуска облигации',
  [TableColumnKey.BsIsFloatingRate]:
    'Вид выплат по облигациям — фиксированный или переменный',
  [TableColumnKey.BsCupDate]:
    'Конкретный день, когда инвестору будет произведён следующий купонный платеж по облигации. В этот день эмитент перечисляет процентный доход, который был начислен с момента последней выплаты купона.',
  [TableColumnKey.BsDuration]: 'Дюрация',
  [TableColumnKey.BsMatDate]:
    'Дата выплаты инвестору номинальной стоимости облигации компанией-эмитентом',
  [TableColumnKey.BsAmortization]:
    'Процесс, при котором номинальная стоимость облигации частично погашается на протяжении всего ее обращения',
  [TableColumnKey.BsIsOffert]:
    'Право покупателя облигации на ее досрочное погашение в заранее известную дату',
  [TableColumnKey.BsOffertType]:
    'Тип оферты для облигации указывает на условия, при которых эмитент облигации предлагает её выкупить до срока погашения.',
  [TableColumnKey.BsOffertDate]:
    'Конкретная дата, когда эмитент облигации имеет право выкупить облигацию до её срока погашения (для call-оферты) или инвестор может потребовать выкуп облигации (для put-оферты)',
  [TableColumnKey.BsNkd]:
    'Часть суммы купона, которая накопилась по облигации на текущий день после выплаты предыдущего купона. Вы платите его при покупке и получаете при продаже',
  [TableColumnKey.BsBondTypeName]:
    'Тип бумаги определяет категорию организации или учреждения, которое выпустило облигацию. Обычно оказывает влияние на уровень риска и доходности облигации',
  [TableColumnKey.BsIsReplacement]:
    'Облигации, выпущенные эмитентом для замены или погашения ранее выпущенных облигаций',
  [TableColumnKey.BsIsMortgageBonds]:
    'Вид облигации, обеспеченный ипотечными кредитами или залогами на недвижимость',
  [TableColumnKey.BsIsRestructing]:
    'Процесс изменения условий выпуска облигации, чтобы улучшить финансовое положение эмитента или адаптировать условия к изменившимся рыночным условиям. Это может включать изменения в таких аспектах, как процентная ставка, срок погашения, график платежей и другие условия',
  [TableColumnKey.BsRestructingDate]:
    'Дата, когда вступают в силу новые условия, установленные в результате реструктуризации облигации',
  [TableColumnKey.BsIsStructuredNote]:
    'Облигации, которые предусматривают право на получение выплат по ним в зависимости от наступления опеределенных событий, например: изменение цен на товары, ценные бумаги, курса валюты, величины процентных ставок, уровня инфляции, индексов и т.д.',
  [TableColumnKey.BsAllowNonQualInvest]: null,
  [TableColumnKey.BsOffertPrice]:
    'Сумма, которую эмитент облигации готов выплатить инвестору за выкуп облигации до её срока погашения',
  [TableColumnKey.BsIsSubordinatedDebt]:
    'Вид долговых ценных бумаг, которые обладают более низким приоритетом при выплате в случае ликвидации или банкротства эмитента по сравнению с другими видами облигаций или долговыми обязательствами, поэтому являются более рискованными',
};
