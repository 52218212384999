import { useEffect, useMemo, useState } from 'react';

import { usePrevious } from '@terminal/core/hooks/usePrevious';
import { TradingStatus } from '@terminal/core/lib/services/finInfoExt/getTradingStatus';
import { Widget } from '@terminal/core/types/layout';
import { OrderBookLine } from '@terminal/core/types/orderBook';
import { FinInfoExt } from '@terminal/core/types/quotes';

import { useWidgetContext } from '../../../shared';

export const INSTRUMENT_CHANGED_METRIC = 'instrumentChanged';

export const useOrderBookPerfomanceMetric = (
  idFi: number,
  tradingStatus: TradingStatus,
  lines: OrderBookLine[],
  finInfoExt?: FinInfoExt,
  nodeId?: string
) => {
  const [startSend, setStartSend] = useState(false);
  const {
    useLogWidgetUpdatePerformance,
    useLogWidgetPerformance,
    getActiveLayoutKey,
    onWidgetLoad,
  } = useWidgetContext();

  const perfomanceMetric = useLogWidgetPerformance(
    Widget.ORDERBOOK,
    getActiveLayoutKey()
  );

  const updatePerfomanceMetric = useLogWidgetUpdatePerformance(
    Widget.ORDERBOOK,
    getActiveLayoutKey()
  );

  const prevIdFi = usePrevious(idFi);

  const isDataLoaded = useMemo(
    () =>
      finInfoExt &&
      (tradingStatus === TradingStatus.CLOSED ||
        lines.some(({ BuyQty, SellQty }) => BuyQty > 0 || SellQty > 0)),
    [finInfoExt, lines, tradingStatus]
  );

  // Начинаем считать время загрузки с момента смены инструмента (при это пропускаем первую загрузку инструмента)
  useEffect(() => {
    if (prevIdFi && prevIdFi !== idFi) {
      updatePerfomanceMetric.start(INSTRUMENT_CHANGED_METRIC);
      setStartSend(true);
    }
  }, [idFi, prevIdFi, updatePerfomanceMetric]);

  // Заканчиваем считать время загрузки после заполнения строк стакана
  useEffect(() => {
    if (prevIdFi === idFi && isDataLoaded && startSend) {
      updatePerfomanceMetric.finish(INSTRUMENT_CHANGED_METRIC);
      setStartSend(false);
    }
  }, [idFi, isDataLoaded, prevIdFi, startSend, updatePerfomanceMetric]);

  useEffect(() => {
    if (isDataLoaded) {
      if (nodeId) {
        onWidgetLoad(nodeId);
      }

      perfomanceMetric.ready();
    }
  }, [
    nodeId,
    onWidgetLoad,
    isDataLoaded,
    perfomanceMetric,
    updatePerfomanceMetric,
  ]);
};
