import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
import isYesterday from 'date-fns/isYesterday';
import { ru } from 'date-fns/locale';

export const formatNewsDate = (dateStr: string) => {
  const date = new Date(dateStr);
  const time = format(date, 'HH:mm', { locale: ru });

  if (isToday(date)) {
    return `Сегодня, ${time}`;
  }

  if (isYesterday(date)) {
    return `Вчера, ${time}`;
  }

  return `${format(date, 'dd MMM yyyy', { locale: ru }).replace(
    '.',
    ''
  )}, ${time}`;
};
