import { format } from 'date-fns';
import React from 'react';
import { Grid } from '@alfalab/core-components/grid';
import { Space } from '@alfalab/core-components/space';
import { Textarea } from '@alfalab/core-components/textarea';
import { Typography } from '@alfalab/core-components/typography';

import { useManager } from '@terminal/lk-core';

import styles from './PassportData.module.css';

export const PassportData = () => {
  const { data: manager } = useManager();

  const birthday = manager?.birthday
    ? format(new Date(manager.birthday), 'dd.MM.yyyy')
    : undefined;

  return (
    <>
      <Typography.Text
        view="primary-small"
        weight="bold"
        tag="p"
        defaultMargins={false}
        className={styles.title}
      >
        Паспортные данные
      </Typography.Text>

      <Space fullWidth size="l">
        <Grid.Row>
          <Grid.Col width="6">
            <Textarea
              label="Дата рождения"
              labelView="outer"
              size="s"
              value={birthday}
              disabled
              block
            />
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col width="12">
            <Textarea
              label="Документ"
              labelView="outer"
              size="s"
              value={manager?.document}
              minRows={2}
              disabled
              block
            />
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col width="12">
            <Textarea
              label="Зарегистрирован"
              labelView="outer"
              size="s"
              value={manager?.mailAddress}
              minRows={2}
              disabled
              block
            />
          </Grid.Col>
        </Grid.Row>
      </Space>
    </>
  );
};
