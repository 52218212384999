import clamp from 'lodash/clamp';
import React, { useEffect, useState } from 'react';
import { AmountInput } from '@alfalab/core-components/amount-input';

import { NumberRange as NumberRangeValue } from '@terminal/core/types/ranges';

import styles from './NumberRange.module.css';

type NumberRangeDigitsProps = {
  filterValue?: NumberRangeValue;
  min?: number;
  max?: number;
  onChange: (value: NumberRangeValue) => void;
};

export const NumberRangeDigits = (props: NumberRangeDigitsProps) => {
  const { filterValue, min = -Infinity, max = Infinity, onChange } = props;

  const minority = 100;

  const adjustInput = (value?: number | '') =>
    typeof value === 'number' ? value * minority : value ?? '';

  const fromInput = adjustInput(filterValue?.from);
  const toInput = adjustInput(filterValue?.to);

  const [from, setFrom] = useState<string | number | null>(fromInput);
  const [to, setTo] = useState<string | number | null>(toInput);

  const adjustOutput = (value?: number | string | null) =>
    typeof value === 'number' ? value / minority : '';

  const fromOutput = adjustOutput(from);
  const toOutput = adjustOutput(to);

  useEffect(() => {
    const clampOutput = (value?: number | '') =>
      typeof value === 'number' ? clamp(value, min, max) : '';

    onChange({
      from: clampOutput(fromOutput),
      to: clampOutput(toOutput),
    });
  }, [onChange, fromOutput, min, toOutput, max, from, to]);

  const getRangeError = (value: number) =>
    value < min || value > max ? `От ${min} до ${max}` : null;

  return (
    <div className={styles.wrapper}>
      <div className={styles.inputs}>
        <AmountInput
          className={styles.customInput}
          fieldClassName={styles.customInputField}
          error={getRangeError(fromOutput || 0)}
          block
          suffix=""
          placeholder="От"
          value={from}
          minority={minority}
          onChange={(e, { value }) => setFrom(value)}
        />
        <span className={styles.dash}>–</span>
        <AmountInput
          className={styles.customInput}
          fieldClassName={styles.customInputField}
          error={getRangeError(toOutput || 0)}
          block
          suffix=""
          placeholder="До"
          value={to}
          minority={minority}
          onChange={(e, { value }) => setTo(value)}
        />
      </div>
    </div>
  );
};
