import React from 'react';
import { InputDesktop } from '@alfalab/core-components/input/desktop';
import { MagnifierMIcon } from '@alfalab/icons-glyph/MagnifierMIcon';

import styles from './BondScreenerSearch.module.css';

interface Props {
  setQuery: (query: string) => void;
  query: string;
}

export const BondScreenerSearch = (props: Props) => {
  const { setQuery, query } = props;

  return (
    <InputDesktop
      value={query}
      size="s"
      onChange={(e) => setQuery(e.target.value)}
      onClear={() => setQuery('')}
      placeholder="Поиск"
      className={styles.searchInput}
      leftAddons={
        <MagnifierMIcon
          width={16}
          height={16}
          color="var(--color-light-graphic-secondary)"
        />
      }
      block
      clear
    />
  );
};
