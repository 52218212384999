import { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { getIndustries } from '../lib/rest/industriesApi';
import { usePrevious } from '.';

export const useIndustries = (isins: string[], enabled?: boolean) => {
  const queryClient = useQueryClient();

  const prevEnabled = usePrevious(enabled);

  useEffect(() => {
    if (prevEnabled !== enabled && !enabled) {
      queryClient.cancelQueries({ queryKey: ['isins', isins] });
    }
  }, [queryClient, prevEnabled, enabled, isins]);

  return useQuery({
    enabled,
    queryKey: ['isins', isins],
    queryFn: ({ signal }) => getIndustries(isins, signal),
  });
};
