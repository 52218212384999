import isNull from 'lodash/isNull';
import { memo, useRef, useState } from 'react';

import {
  BaseWidgetConfig,
  LinkName,
  WidgetLinkProps,
} from '@terminal/core/types/layout';

import { LinkIcon } from '../LinkIcon';
import { LinkDropdown } from './LinkDropdown';

interface LinkProps {
  nodeId: string;
  config: BaseWidgetConfig | null;
  linkToGroup: (
    nodeId: string,
    linkName: LinkName,
    params: WidgetLinkProps
  ) => void;
}

export const Link = memo((props: LinkProps) => {
  const { nodeId, config, linkToGroup } = props;
  const [isOpen, setIsOpen] = useState(false);
  //Якорь для позиционирования дропдауна
  const anchorRef = useRef<HTMLDivElement>(null);

  const currentLink = config
    ? config.link || LinkName.DEFAULT
    : LinkName.DEFAULT;

  return (
    //onMouseDown это обязательный хак для того, чтобы можно было хэндлить собития клика в кнокпе таба (узнал через issue)
    <div ref={anchorRef} onMouseDown={(e) => e.stopPropagation()}>
      <LinkIcon
        linkName={currentLink}
        onClick={() => setIsOpen(true)}
        withTooltip
      />
      <LinkDropdown
        isOpen={isOpen}
        anchor={anchorRef}
        currentLink={currentLink}
        onChooseLink={(linkName) => {
          if (!isNull(config)) {
            linkToGroup(nodeId, linkName, config);
          }
        }}
        onClose={() => setIsOpen(false)}
      />
    </div>
  );
});

Link.displayName = 'Link';
