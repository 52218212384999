import cn from 'classnames';
import { FC } from 'react';
import { Amount } from '@alfalab/core-components/amount';
import { IconButton } from '@alfalab/core-components/icon-button';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { PencilMIcon } from '@alfalab/icons-glyph/PencilMIcon';

import { MINORITY } from '@terminal/core/constants/ui';
import { SubAccount } from '@terminal/core/lib/rest/lkAccountInfo';

import styles from './SubaccountTable.module.css';

interface SubaccountTableItemProps {
  item: SubAccount;
  showPortfolioNumber?: boolean;
  onRedact?: () => void;
}

export const SubaccountTableItem: FC<SubaccountTableItemProps> = (props) => {
  const { item, showPortfolioNumber = false, onRedact } = props;

  const [treaty] = item.accCode.split('-');
  const accountStr = showPortfolioNumber ? item.accCode : treaty;

  return (
    <tr className={styles.row}>
      <td className={styles.cell}>
        <Typography.Text view="secondary-small" tag="div">
          {accountStr}
        </Typography.Text>
        <Typography.Text view="secondary-small" tag="div" color="secondary">
          {item.accName}
        </Typography.Text>
      </td>
      <td className={styles.cell}>
        <Typography.Text view="secondary-small" tag="div">
          {item.fortsAccount || '—'}
        </Typography.Text>
        <Typography.Text view="secondary-small" tag="div" color="secondary">
          <Amount
            value={item.indLimit * MINORITY}
            minority={MINORITY}
            bold="none"
            currency="RUB"
          />
        </Typography.Text>
      </td>
      <td className={cn(styles.cell, styles.actionCell)}>
        <div className={styles.actionButtons}>
          {onRedact && (
            <TooltipDesktop
              position="bottom"
              trigger="hover"
              content="Редактировать"
            >
              <IconButton
                view="secondary"
                size="xxs"
                icon={PencilMIcon}
                onClick={onRedact}
              />
            </TooltipDesktop>
          )}
        </div>
      </td>
    </tr>
  );
};
