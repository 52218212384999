import { useMemo } from 'react';
import { useQueries } from 'react-query';

import { ChartArchiveService } from '@terminal/core/lib/services/chartArchive';
import { ArchiveCandle } from '@terminal/core/types/chart';

import { INDICES_CHIPSE } from '../lib/const';

import { ChartIndex } from '../model/types';

export const useIndicesData = (
  indices: ChartIndex[],
  dateFrom: number,
  dateTo: number
) => {
  const chartArchiveService = useMemo(() => new ChartArchiveService(), []);

  const queries = useQueries(
    indices
      .filter((index) => Boolean(INDICES_CHIPSE[index]))
      .map((index) => ({
        queryKey: ['index-candles', index, dateFrom, dateTo],
        queryFn: () =>
          chartArchiveService.fetch(
            INDICES_CHIPSE[index].idFi,
            new Date(dateFrom),
            new Date(dateTo),
            {
              period: 1,
              interval: 'day',
            }
          ).response,
        select: (candles) => ({
          index,
          candles,
        }),
      }))
  );

  return queries.reduce<Record<ChartIndex, ArchiveCandle[]>>((acc, query) => {
    if (
      query.status === 'success' &&
      query.data &&
      query.data.candles.length > 0
    ) {
      acc[query.data.index] = query.data.candles;
    }

    return acc;
  }, {} as Record<ChartIndex, ArchiveCandle[]>);
};
