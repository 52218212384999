import { useCallback, useState } from 'react';
import { useMountedState } from 'react-use';

import { useErrorNotification } from './useNotifications';

export const useDownload = () => {
  const [isLoading, setIsLoading] = useState(false);
  const notify = useErrorNotification();
  const isMounted = useMountedState();

  const download = useCallback(
    async (url: string) => {
      setIsLoading(true);

      try {
        const result = await fetch(url);

        if (!isMounted()) {
          return;
        }

        if (result.ok) {
          const data = await result.blob();

          if (!isMounted()) {
            return;
          }

          const dataUrl = URL.createObjectURL(data);
          const link = document.createElement('a');

          try {
            link.href = dataUrl;
            link.download = url.substring(url.lastIndexOf('/') + 1);
            link.click();
          } finally {
            URL.revokeObjectURL(dataUrl);
          }
        }
      } catch (e) {
        if (!isMounted()) {
          return;
        }

        notify('Ошибка загрузки файла', e);
      } finally {
        if (!isMounted()) {
          return;
        }

        setIsLoading(false);
      }
    },
    [setIsLoading, isMounted, notify]
  );

  return {
    isLoading,
    download,
  };
};
