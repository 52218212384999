import {
  ElementType,
  LayoutModel,
  LayoutType,
} from '@terminal/core/types/layout';

export const findFirstTabset = (
  layout: LayoutModel<{ type: LayoutType.Grid }>['layout']
) => {
  if (layout.children?.length === 0) {
    return;
  }

  for (let child of layout.children) {
    if (child.type === ElementType.TABSET) {
      return child;
    }

    if (child.type === ElementType.ROW) {
      const tabset = findFirstTabset(child);

      if (tabset) {
        return tabset;
      }
    }
  }
};
