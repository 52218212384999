import { useCallback } from 'react';
import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import {
  getOperationsHistory,
  InvestQueryKeys,
} from '@terminal/core/lib/rest/investApi';
import { USE_PASSPORT } from '@terminal/core/lib/rest/lkApi';
import { operationHistoryMap } from '@terminal/core/mapping/operationMapping';
import {
  OperationHistoryResult,
  OperationHistoryType,
} from '@terminal/core/types/operation';
import {
  OperationsHistoryParams,
  useAlfaDirectContext,
} from '@terminal/widgets';

export const useOperationsHistory = (params: OperationsHistoryParams) => {
  const addNotification = useNotification();
  const { marketBoardsTable, objectsTable } = useAlfaDirectContext();

  const transformOperations = useCallback(
    (data: OperationHistoryResult[]) =>
      data.map((operation) => {
        const parsedObject = operationHistoryMap(operation);
        const object = parsedObject.operation.objectId
          ? objectsTable.get('idObject', parsedObject.operation.objectId)
          : undefined;

        const market = parsedObject.operation.marketId
          ? marketBoardsTable
              .toArray()
              .find(
                (market) =>
                  market.universalMarketCode === parsedObject.operation.marketId
              )
          : undefined;

        return {
          ...parsedObject,
          object,
          market,
        };
      }),
    [marketBoardsTable, objectsTable]
  );

  return useQuery(
    [InvestQueryKeys.OperationsHistory, params],
    () => {
      return getOperationsHistory({
        ...params,
        assetIdType: 'ID_OBJECT',
        maxElements: 100,
        operationTypes: [
          OperationHistoryType.E,
          OperationHistoryType.D,
          OperationHistoryType.S,
        ].join(','),
      });
    },
    {
      enabled: USE_PASSPORT,
      select: ({ operations }) => transformOperations(operations),
      onError: (error: any) => {
        addNotification({
          badge: 'negative',
          title: 'Ошибка',
          text: error?.message || 'Произошла ошибка при запросе лимита',
        });
      },
    }
  );
};
