import cn from 'classnames';
import React, { useCallback } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Typography } from '@alfalab/core-components/typography';

import { workspaceConfigurationsLSDefault } from '@terminal/core/constants/workspaceConfigurations';
import { useAppContext } from '@terminal/core/hooks/useAppContext';
import { shallow, useStore } from '@terminal/core/store';
import {
  ConfigStep,
  configurationMatrix,
  createConfiguration,
  useOnboardingConfigStore,
} from '@terminal/desktop/entities/OnboardingConfig';
import {
  DotsPagination,
  OnboardingCertificate,
  OnboardingExperience,
  OnboardingScreenSize,
  OnboardingTheme,
  OnboardingWorkspace,
} from '@terminal/desktop/features/OnboardingConfig';
import { useLogoutAction } from '@terminal/lk-desktop-common';

import { BackButton } from '../BackButton';

import { useOnboardingSteps } from '../../hooks/useOnboardingSteps';

import styles from './OnboardingConfigs.module.css';

interface Props {
  closeOnboardingConfigMenu: () => void;
}

export const OnboardingConfig = ({ closeOnboardingConfigMenu }: Props) => {
  const { appLogo } = useAppContext();
  const [step, setStep] = useOnboardingConfigStore((store) => [
    store.step,
    store.setStep,
  ]);
  const onboardingSteps = useOnboardingSteps();
  const currentStepIndex = onboardingSteps.findIndex((el) => el === step);

  const [createNewConfiguration, workspaceConfigurations, removeConfiguration] =
    useStore(
      (state) => [
        state.createNewConfiguration,
        state.workspaceConfigurations,
        state.removeConfiguration,
      ],
      shallow
    );

  const logoutAction = useLogoutAction();

  const removeDefaultConfiguration = useCallback(() => {
    // Если при онбоардинге всего одна конфигурация и имя совпадает с дефолтной, то удаляем ее
    if (
      workspaceConfigurations.length === 1 &&
      workspaceConfigurations[0].name ===
        workspaceConfigurationsLSDefault.workspaceConfigurations[0].name
    ) {
      removeConfiguration(workspaceConfigurations[0].id);
    }
  }, [workspaceConfigurations, removeConfiguration]);

  const handleNext = () => {
    const nextStep = onboardingSteps[currentStepIndex + 1];
    const { configSize, configStyle } = useOnboardingConfigStore.getState();

    if (typeof nextStep === 'undefined') {
      const configurationTemplate =
        configurationMatrix[configSize][configStyle];

      if (configurationTemplate) {
        const newConfig = createConfiguration(configurationTemplate());

        removeDefaultConfiguration();

        createNewConfiguration(newConfig, closeOnboardingConfigMenu);
      }

      closeOnboardingConfigMenu();
    } else {
      setStep(nextStep);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.frame}>
        <div className={styles.onboardingHeader}>
          <div
            className={cn(currentStepIndex === 0 && styles.backButtonHidden)}
          >
            <BackButton />
          </div>
          <div className={styles.logo}>{appLogo}</div>
          <ButtonDesktop
            onClick={logoutAction}
            className={styles.exitButton}
            view="ghost"
          >
            <Typography.Text view="secondary-large" color="secondary">
              Выйти
            </Typography.Text>
          </ButtonDesktop>
        </div>
        <div className={styles.onboardingPage}>
          {step === ConfigStep.Experience && <OnboardingExperience />}
          {step === ConfigStep.Size && <OnboardingScreenSize />}
          {step === ConfigStep.Style && <OnboardingWorkspace />}
          {step === ConfigStep.Theme && <OnboardingTheme />}
          {step === ConfigStep.Certificate && (
            <OnboardingCertificate closeOnboardingModal={handleNext} />
          )}
        </div>
        <div
          className={cn(styles.onboardingFooter, {
            [styles.onboardingFooterWider]:
              step === ConfigStep.Experience || step === ConfigStep.Style,
          })}
        >
          {step !== ConfigStep.Certificate && (
            <ButtonDesktop
              view="accent"
              size="s"
              block
              onClick={handleNext}
              className={styles.nextButton}
            >
              Далее
            </ButtonDesktop>
          )}
          <div className={styles.pagination}>
            <DotsPagination
              step={currentStepIndex + 1}
              stepsNumber={onboardingSteps.length}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
