import { useCallback, useEffect, useState } from 'react';

import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';
import { BestBidBestAskAndSpread } from '@terminal/common/components/BestBidBestAskAndSpread';
import { ActionSources } from '@terminal/core/constants/analytics';
import { useOrderBookService } from '@terminal/core/hooks/useOrderBookService';
import { ObjectGroup } from '@terminal/core/lib/client/entities';
import { getSymbolToShow } from '@terminal/core/lib/currencies';
import { getStepDecimals } from '@terminal/core/lib/format';
import {
  CancelOrderFn,
  ReplaceOrderFn,
} from '@terminal/core/lib/services/trading/useTradingService';
import { selectActiveOrders } from '@terminal/core/store/selectors';
import { OrderItem } from '@terminal/core/types/order';
import { FinInfoExt } from '@terminal/core/types/quotes';

import {
  CancelOrderModal,
  EditOrderModal,
  OrderInfo,
} from '../../../../features/Orders';
import { useWidgetContext } from '../../../../shared';
import { useTradingContext } from '../../context';

import styles from './OrdersInfo.module.css';

interface Props {
  finInfoExt?: FinInfoExt;
  hideActiveOrders?: boolean;
  replaceOrder: ReplaceOrderFn;
  cancelOrder: CancelOrderFn;
  isLoadingCancelModal: boolean;
  setIsLoadingCancelModal: (state: boolean) => void;
  isLoadingEditModal: boolean;
  setIsLoadingEditModal: (state: boolean) => void;
  isOpenCancelModal: boolean;
  setIsOpenCancelModal: (state: boolean) => void;
  isOpenEditModal: boolean;
  setIsOpenEditModal: (state: boolean) => void;
  idObjectGroup: number | undefined;
}

export const OrdersInfo = ({
  finInfoExt,
  hideActiveOrders,
  cancelOrder,
  replaceOrder,
  isLoadingCancelModal,
  setIsLoadingCancelModal,
  isLoadingEditModal,
  setIsLoadingEditModal,
  isOpenCancelModal,
  setIsOpenCancelModal,
  isOpenEditModal,
  setIsOpenEditModal,
  idObjectGroup,
}: Props) => {
  const {
    idFi,
    selectedAccount,
    selectedSubAccountId,
    setPrice,
    currencyCode,
  } = useTradingContext();

  const { priceDecimals } = getStepDecimals(finInfoExt?.priceStep);

  const { bestBid, bestAsk, spread } = useOrderBookService(idFi, priceDecimals);

  const { useSettings, analytics } = useWidgetContext();
  const { useOrders, finInstrumentsTable } = useAlfaDirectContext();
  const settings = useSettings();
  const orders = useOrders();

  //Информация по активным и отложенным заявкам
  const activeOrders = selectActiveOrders(
    idFi,
    selectedSubAccountId,
    true
  )({
    orders,
    finInstrumentsTable,
  });

  const { alwaysConfirmOrders } = settings.defaultValues;

  //модалка отмены заявки
  const [orderToCancel, setOrderToCancel] = useState<OrderItem | null>(null);

  useEffect(() => {
    setIsLoadingCancelModal(false);
  }, [orderToCancel, setIsLoadingCancelModal]);
  //модалка редактирования заявки
  const [orderToEdit, setOrderToEdit] = useState<OrderItem | null>(null);

  useEffect(() => {
    setIsLoadingEditModal(false);
  }, [orderToEdit, setIsLoadingEditModal]);

  const isBond = idObjectGroup === ObjectGroup.Bonds;

  const handleCancelOrder = useCallback(
    (order: OrderItem) => {
      if (alwaysConfirmOrders) {
        setIsOpenCancelModal(true);
        setOrderToCancel(order);
      } else {
        cancelOrder(order);
      }
    },
    [alwaysConfirmOrders, cancelOrder, setIsOpenCancelModal]
  );

  const handleEditOrder = useCallback(
    (order: OrderItem) => {
      setIsOpenEditModal(true);
      setOrderToEdit(order);
      analytics.trackOrderEditSource(ActionSources.TRADINGFORM);
      analytics.trackOrderEditType(order.idOrderType);
    },
    [analytics, setIsOpenEditModal]
  );

  return (
    <>
      <BestBidBestAskAndSpread
        bestBid={bestBid?.Price}
        bidQty={bestBid?.BuyQty}
        bestAsk={bestAsk?.Price}
        askQty={bestAsk?.SellQty}
        spread={spread}
        priceDecimals={priceDecimals}
        currencyCode={getSymbolToShow(currencyCode, idObjectGroup)}
        isMobileVersion
        onBidClick={() => setPrice(bestBid?.Price || 0)}
        onAskClick={() => setPrice(bestAsk?.Price || 0)}
        isBond={isBond}
      />
      {!hideActiveOrders && activeOrders && activeOrders.length > 0 ? (
        <OrderInfo
          orders={activeOrders}
          position="top"
          finInfoExt={finInfoExt}
          isBond={isBond}
          currencyCode={currencyCode}
          onCancelOrder={handleCancelOrder}
          onEditOrder={handleEditOrder}
          hybrid
          wrapperClassName={styles.ordersButton}
        />
      ) : null}
      <EditOrderModal
        isOpen={isOpenEditModal}
        idFi={idFi}
        order={orderToEdit}
        onClose={() => {
          setOrderToEdit(null);
        }}
        selectedAccount={selectedAccount}
        onCancelOrder={() => {
          if (alwaysConfirmOrders) {
            setOrderToCancel(orderToEdit);
            setIsOpenCancelModal(true);
          } else {
            orderToEdit && cancelOrder(orderToEdit);
          }
        }}
        selectedSubAccountId={selectedSubAccountId}
        replaceOrder={replaceOrder}
        isLoading={isLoadingEditModal}
        setIsLoading={setIsLoadingEditModal}
      />
      <CancelOrderModal
        isOpen={isOpenCancelModal}
        order={orderToCancel}
        onCloseRequest={() => {
          setOrderToCancel(null);
          setOrderToEdit(null);
        }}
        cancelOrder={cancelOrder}
        isLoading={isLoadingCancelModal}
        setIsLoading={(state) => setIsLoadingCancelModal(state)}
      />
    </>
  );
};
