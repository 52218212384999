import { VFC } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { AlertCircleMIcon } from '@alfalab/icons-glyph/AlertCircleMIcon';

import styles from './HistoryError.module.css';

export type HistoryErrorProps = {
  onRetry: () => void;
};

export const HistoryError: VFC<HistoryErrorProps> = (props) => {
  const { onRetry } = props;

  return (
    <div className={styles.container}>
      <div className={styles.topRow}>
        <AlertCircleMIcon
          width={24}
          height={24}
          color="#F6BF65"
          className={styles.icon}
        />
        <Typography.Text view="primary-small">
          Не удалось загрузить историю переписки
        </Typography.Text>
      </div>
      <div className={styles.bottomRow}>
        <ButtonDesktop size="xs" onClick={onRetry}>
          Обновить
        </ButtonDesktop>
      </div>
    </div>
  );
};
