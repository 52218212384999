/**
 * Функция для сортировки ключей объекта.
 * @example { z: 15, g: 23, a: 3 } -> { a: 3, g: 23, z: 15 }
 * */
export function sortObjKeys<Obj extends Record<string, unknown>>(
  unordered: Obj extends any[] ? never : Obj,
  comparator?: (a: keyof Obj, b: keyof Obj) => number
): Obj {
  return Object.keys(unordered)
    .sort(comparator)
    .reduce<Obj>((obj: Obj, key: keyof Obj) => {
      obj[key] = unordered[key];

      return obj;
    }, {} as Obj);
}
