import useNotification from '@terminal/core/hooks/useNotification';
import { useStore } from '@terminal/core/store';
import { InitObject, LkContextProviderProps } from '@terminal/lk-core';

type Props = {
  fetchInit: () => Promise<InitObject>;
};

export function useLkContextProps({ fetchInit }: Props) {
  const addNotification = useNotification();

  const workingCertificate = useStore((state) => state.workingCertificate);

  const contextProps: Omit<LkContextProviderProps, 'children'> = {
    getInit: fetchInit,
    addNotification,
    certificate: workingCertificate,
  };

  return contextProps;
}
