import { createAttorney } from '@terminal/lk-core';

import { IDENTITY_DOCUMENT_TYPE_MAP } from './const/mappings';

import { CreateAttorneyFormValues } from '../model/types/CreateAttorneyFormValues';

export const createAttorneyRequestFromValues = (
  values: CreateAttorneyFormValues
): Parameters<typeof createAttorney>[0]['params'] => {
  return {
    attorneySysName: values.login,
    accCode: values.subAccount,
    accessLevel: Number(values.accessLevel),
    attorneyName: values.fullName,
    docType: IDENTITY_DOCUMENT_TYPE_MAP[Number(values.identityDocumentType)],
    docNumber: values.identityDocumentNumber,
    attorneyDateEnd: values.endDate,
  };
};
