import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

export const useInit = <R>(fetchInit: () => Promise<R>) => {
  const addNotification = useNotification();

  return useQuery(['lk', 'init'], () => fetchInit(), {
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка',
        text: error?.message || 'Ошибка запроса настроек ЛК',
      });
    },
  });
};
