import { MarginParams, MarginParamsMap } from '../../../types/MarginParams';

/**
 * Проверяет, является ли данный объект объектом MarginParams.
 * Объект MarginParams должен иметь свойство 'long' типа объект.
 * @param data - объект, который необходимо проверить
 * @returns true, если данный объект является MarginParams, в противном случае false
 * */
export const isMarginParams = (
  data: MarginParams | MarginParamsMap<number, string>
): data is MarginParams => {
  return 'long' in data && typeof data.long === 'object' && data.long !== null;
};
