import cn from 'classnames';
import React, { ComponentType, SVGProps } from 'react';
import {
  TooltipDesktop,
  TooltipDesktopProps,
} from '@alfalab/core-components/tooltip/desktop';
import { TextProps, Typography } from '@alfalab/core-components/typography';
import { InformationCircleMIcon } from '@alfalab/icons-glyph/InformationCircleMIcon';

import styles from './FieldTooltip.module.css';

export type FieldTooltipProps = Omit<
  TooltipDesktopProps,
  'children' | 'content'
> & {
  title?: string;
  Icon?: ComponentType<SVGProps<SVGSVGElement>>;
  iconSize?: number;
  iconColor?: 'promary' | 'secondary' | 'tertiary' | 'quartinary';
  textView?: TextProps['view'];
  maxWidth?: number;
  children: React.ReactNode;
};

export const FieldTooltip = (props: FieldTooltipProps) => {
  const {
    title,
    Icon = InformationCircleMIcon,
    iconSize = 20,
    iconColor = 'secondary',
    textView = 'secondary-large',
    maxWidth = 360,
    children,
    ...restProps
  } = props;

  const gapS = 12;
  const contentWidth = maxWidth - gapS * 2;

  return (
    <TooltipDesktop
      zIndex={200}
      {...restProps}
      targetClassName={cn(styles.target, restProps.targetClassName)}
      content={
        <div className={styles.content} style={{ maxWidth: contentWidth }}>
          {title && (
            <Typography.Title tag="h4" view="xsmall" className={styles.title}>
              {title}
            </Typography.Title>
          )}
          <Typography.Text view={textView} color="secondary" tag="div">
            {children}
          </Typography.Text>
        </div>
      }
    >
      <Icon
        width={iconSize}
        height={iconSize}
        color={`var(--color-light-graphic-${iconColor})`}
      />
    </TooltipDesktop>
  );
};
