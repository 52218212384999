import cn from 'classnames';
import { ChangeEvent, FC, memo } from 'react';
import { InputDesktop } from '@alfalab/core-components/input/desktop';
import { PopoverProps } from '@alfalab/core-components/popover';
import { Typography } from '@alfalab/core-components/typography';
import { BriefcaseMIcon } from '@alfalab/icons-glyph/BriefcaseMIcon';
import { CheckmarkOnCircleMIcon } from '@alfalab/icons-glyph/CheckmarkOnCircleMIcon';
import { CheckmarkSIcon } from '@alfalab/icons-glyph/CheckmarkSIcon';
import { ExclamationCircleMIcon } from '@alfalab/icons-glyph/ExclamationCircleMIcon';
import { MagnifierMIcon } from '@alfalab/icons-glyph/MagnifierMIcon';
import { SelectionMaskMIcon } from '@alfalab/icons-glyph/SelectionMaskMIcon';

import { Dropdown } from '@terminal/common/components/Dropdown';
import {
  DropdownMenu,
  DropdownMenuProps,
} from '@terminal/common/components/DropdownMenu';
import {
  AccountOption,
  AccountsOption,
} from '@terminal/common/model/types/account';
import { ToggleOff, ToggleOn } from '@terminal/core/constants/icons';

import styles from './AccountDropdown.module.css';

export interface AccountDropdownProps {
  position: PopoverProps['position'];
  hasRequirements: boolean;
  options: AccountOption[];
  onSelect: (option: AccountsOption) => void;
  searchText: string;
  setSearchText: (text: string) => void;
  children: DropdownMenuProps['children'];
  isOrderBookVersion?: boolean;
  isEverySubAccountSelected?: boolean;
  onSelectAllClick?: () => void;
  useAnchorWidth?: boolean;
  popoverClassName?: string;
}

export const AccountDropdown: FC<AccountDropdownProps> = memo((props) => {
  const {
    position,
    hasRequirements,
    options,
    onSelect,
    searchText,
    setSearchText,
    isOrderBookVersion = false,
    isEverySubAccountSelected = false,
    onSelectAllClick = () => {},
    useAnchorWidth = false,
    popoverClassName,
    children,
  } = props;

  return (
    <DropdownMenu
      popoverProps={{
        position,
        useAnchorWidth,
        availableHeight: true,
        popperClassName: cn(styles.popover, popoverClassName),
      }}
      groups={[
        {
          key: '1',
          items: [
            {
              key: '1.1',
              type: 'element',
              hideMenu: false,
              label: (
                <>
                  <InputDesktop
                    size="s"
                    block
                    autoFocus
                    clear
                    onClear={() => {
                      setSearchText('');
                    }}
                    leftAddons={
                      <MagnifierMIcon
                        width={16}
                        height={16}
                        color="var(--color-light-graphic-secondary)"
                      />
                    }
                    placeholder="Номер счета"
                    value={searchText}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const value = e.target.value;

                      setSearchText(value);
                    }}
                    fieldClassName={cn(
                      styles.inputDesktop,
                      styles.field,
                      styles.custom
                    )}
                    addonsClassName={cn(styles.inputDesktop, styles.addons)}
                    inputClassName={cn(
                      styles.inputDesktop,
                      styles.input,
                      styles.inputCustom
                    )}
                  />
                  <Dropdown.Divider size="s" />
                </>
              ),
            },
            {
              key: '1.2',
              // Скрываем элемент "Все счета", если поиск активен
              hidden: searchText.length !== 0,
              type: 'element',
              hideMenu: true,
              label: (
                <>
                  {!isOrderBookVersion && (
                    <>
                      <div
                        className={styles.allAccountsButton}
                        onClick={onSelectAllClick}
                      >
                        <div className={styles.allAccountsButtonContent}>
                          <BriefcaseMIcon
                            width={16}
                            height={16}
                            color="var(--color-light-graphic-secondary)"
                          />
                          <Typography.Text view="secondary-large">
                            Все счета
                          </Typography.Text>
                        </div>

                        {isEverySubAccountSelected && (
                          <CheckmarkSIcon height={16} width={16} />
                        )}
                      </div>
                      <Dropdown.Divider size="s" />
                    </>
                  )}
                </>
              ),
            },
          ],
        },
        {
          key: '2',
          items: options.map((account) => ({
            key: `2.${account.key}`,
            type: 'element',
            hideMenu: isOrderBookVersion,
            label: (
              <Dropdown.Block key={`account-${account.key}`}>
                <Dropdown.Item
                  content={
                    <Typography.Text
                      tag="div"
                      view="secondary-small"
                      weight="bold"
                      className={cn(
                        styles.accountOption,
                        isOrderBookVersion && styles.accountOptionOrderBook
                      )}
                    >
                      <Typography.Text
                        tag="div"
                        view="secondary-small"
                        color="secondary"
                        weight="bold"
                      >
                        {account.ownerName ? (
                          <>
                            {account.ownerName}
                            {account.isIis ? ', ИИС' : ''}
                          </>
                        ) : (
                          <>
                            {account.isIis ? 'ИИС' : 'Счёт'}&nbsp;
                            {account.value}
                          </>
                        )}
                      </Typography.Text>
                    </Typography.Text>
                  }
                  rightIcon={
                    !isOrderBookVersion ? (
                      <div>
                        {account.isEverySubAccountSelected ? (
                          <ToggleOn />
                        ) : (
                          <ToggleOff />
                        )}
                      </div>
                    ) : undefined
                  }
                  onClick={() => onSelect(account)}
                  className={cn(
                    isOrderBookVersion && styles.accountItemOrderBook
                  )}
                />
                {account.subAccounts.map((subAccount) => {
                  const checkIcon = subAccount.isSelected ? (
                    <CheckmarkOnCircleMIcon
                      color="var(--control-accent-bg-color)"
                      height={16}
                      width={16}
                    />
                  ) : (
                    <SelectionMaskMIcon
                      color="var(--color-light-text-secondary)"
                      height={16}
                      width={16}
                    />
                  );

                  return (
                    <Dropdown.Item
                      key={`subAccount-${subAccount.key}`}
                      content={
                        <Typography.Text
                          view="secondary-large"
                          className={cn(
                            styles.option,
                            hasRequirements && styles.optionWithRequirements
                          )}
                        >
                          {subAccount.requirement && (
                            <ExclamationCircleMIcon
                              className={styles.requirementIcon}
                              width={18}
                              height={18}
                              color={
                                subAccount.requirement ===
                                'immediateRequirements'
                                  ? 'var(--color-light-text-negative)'
                                  : 'var(--color-light-text-attention)'
                              }
                            />
                          )}
                          {isOrderBookVersion ? null : checkIcon}
                          <Typography.Text tag="div" view="secondary-small">
                            {subAccount.value}
                          </Typography.Text>
                        </Typography.Text>
                      }
                      rightIcon={
                        isOrderBookVersion && subAccount.isSelected ? (
                          <CheckmarkSIcon
                            height={16}
                            width={16}
                            className={styles.accountSelectedIcon}
                          />
                        ) : undefined
                      }
                      onClick={() => onSelect(subAccount)}
                    />
                  );
                })}
              </Dropdown.Block>
            ),
          })),
        },
        {
          key: '3',
          items: [
            {
              key: '3.1',
              // Показываем элемент если поиск активен и элементы не найдены
              hidden: !(searchText.length !== 0 && options.length === 0),
              type: 'element',
              hideMenu: false,
              label: (
                <>
                  <Dropdown.Item
                    key="1.3.1"
                    content={
                      <Typography.Text
                        tag="div"
                        view="secondary-small"
                        color="secondary"
                      >
                        Совпадений не найдено
                      </Typography.Text>
                    }
                  />
                </>
              ),
            },
          ],
        },
      ]}
      className={styles.menu}
    >
      {children}
    </DropdownMenu>
  );
});

AccountDropdown.displayName = 'AccountDropdown';
