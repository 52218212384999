import { Actions, Model, TabNode } from 'flexlayout-react';

import { trackApplicationError } from '../../../lib/analytics';

import {
  BaseWidgetConfig,
  FlexLayoutModel,
  FlexLayoutTabset,
  FlexNode,
  GridLayoutModel,
  LayoutModel,
  LayoutType,
  NodeProps,
  Widget,
} from '../../../types/layout';

export const getFlexNodeById = (
  model: FlexLayoutModel,
  nodeId?: string
): FlexNode | undefined => {
  let node: FlexNode | undefined = undefined;

  model.layout.forEach((tabset) => {
    const { children, size, position } = tabset;

    const nodeIndex = children.findIndex((node) => node.nodeId === nodeId);

    if (nodeIndex > -1) {
      node = {
        component: children[nodeIndex].component,
        name: children[nodeIndex].name,
        config: children[nodeIndex],
        size,
        position,
      };
    }
  });

  return node;
};

export const updateFlexNodeAttributes = (
  model: FlexLayoutModel,
  nodeId: string,
  attributes: NodeProps
): FlexLayoutModel => {
  const layout = model.layout.reduce((acc, tabset) => {
    const hasNode = tabset.children.find((node) => node.nodeId === nodeId);

    if (hasNode) {
      const children = tabset.children.map((widget) =>
        widget.nodeId === nodeId
          ? {
              ...widget,
              ...attributes.config,
              name: attributes.name ?? widget.name,
            }
          : widget
      );
      const newTabset = { ...tabset, children };

      acc.push(newTabset);
    } else {
      acc.push(tabset);
    }

    return acc;
  }, [] as FlexLayoutTabset[]);

  return { ...model, layout };
};

export const getNodeById = (
  layout: LayoutModel<FlexLayoutModel | GridLayoutModel>,
  model?: Model,
  nodeId?: string
): NodeProps => {
  let config: BaseWidgetConfig | null = null;
  let name: string | undefined;
  let widget: Widget | undefined;

  if (layout.type === LayoutType.Flex) {
    const node = getFlexNodeById(layout, nodeId);

    if (node) {
      config = node.config;
      name = node.name;
      widget = node.component;
    }
  } else {
    //@ts-expect-error
    const node: TabNode | undefined = model?.getNodeById(nodeId || '');

    if (node) {
      config = node.getConfig();
      name = node.getName();
      widget = node.getComponent() as Widget;
    }
  }

  return {
    config,
    name,
    widget,
  };
};

export const updateNodeAttributes = (
  attributes: NodeProps,
  nodeId: string,
  inputModel?: Model,
  layout?: LayoutModel<FlexLayoutModel | GridLayoutModel>
) => {
  if (layout && layout.type === LayoutType.Flex) {
    return updateFlexNodeAttributes(layout, nodeId, attributes);
  } else {
    if (!inputModel) {
      return;
    } else {
      try {
        inputModel.doAction(Actions.updateNodeAttributes(nodeId, attributes));
      } catch (error) {
        const message = `Layout Error updateNodeAttributes; attributes: ${JSON.stringify(
          attributes
        )} error: ${JSON.stringify(error)}`;

        trackApplicationError(message);
      }
    }
  }
};
