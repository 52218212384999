import cn from 'classnames';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';

import { useStore } from '@terminal/core/store';
import { FlexLayoutWidget } from '@terminal/core/types/layout';

import { HeaderContent, Link } from '../../../../shared/ui/WidgetTab';
import { DRAG_HANDLE_CLASSNAME } from '../../const';

type WidgetTabProps = FlexLayoutWidget & {
  onCloseNode: () => void;
};

export const WidgetTab = (props: WidgetTabProps) => {
  const { onCloseNode, ...widget } = props;
  const { component, name, nodeId, visible, ...config } = widget;

  return (
    <div
      className={cn(
        'flexlayout__tab_button',
        'flexlayout__tab_button--selected',
        DRAG_HANDLE_CLASSNAME
      )}
    >
      {visible && (
        <div className="flexlayout__tab_button_leading">
          <Link
            nodeId={nodeId}
            config={config}
            linkToGroup={useStore.getState().linkToGroup}
          />
        </div>
      )}
      <div className="flexlayout__tab_button_content">
        <HeaderContent component={component} config={config} name={name} />
      </div>
      <div
        className="flexlayout__tab_button_trailing"
        onMouseDown={(e) => e.stopPropagation()}
        onClick={onCloseNode}
      >
        <CrossMIcon height={18} width={18} />
      </div>
    </div>
  );
};
