import { localStorage } from '../../storages';

import { SubAccountsState } from '../../../types/subAccount';

const KEY_SELECTED_WATCHLIST_ID = 'selected-watchlist-id';
const KEY_SELECTED_LAST_SUBACCOUNT = 'selected-last-subaccount';
const KEY_SELECTED_SUBACCOUNTS_STATE = 'selected-subaccounts-state';
const KEY_SELECTED_PARTNER_SUBACCOUNT = 'selected-partner-subaccount';

class UiSettings {
  get selectedWatchlistId(): string | undefined {
    return localStorage.getItem<string>(KEY_SELECTED_WATCHLIST_ID);
  }
  set selectedWatchlistId(value: string | undefined) {
    localStorage.setItem(KEY_SELECTED_WATCHLIST_ID, value);
  }
  set lastSelectedSubAccount(
    accountInfo: {
      subAccountCode: string;
      subAccountId: number;
    } | null
  ) {
    localStorage.setItem(KEY_SELECTED_LAST_SUBACCOUNT, accountInfo);
  }
  get lastSelectedSubAccount(): {
    subAccountCode: string;
    subAccountId: number;
  } | null {
    const subAccount = localStorage.getItem<{
      subAccountCode: string;
      subAccountId: number;
    }>(KEY_SELECTED_LAST_SUBACCOUNT);

    if (subAccount?.subAccountCode && subAccount?.subAccountId) {
      return subAccount;
    }

    return null;
  }
  set subAccountsState(subAccountsState: SubAccountsState) {
    localStorage.setItem(KEY_SELECTED_SUBACCOUNTS_STATE, subAccountsState);
  }
  get subAccountsState(): SubAccountsState {
    const subAccountsState = localStorage.getItem<SubAccountsState>(
      KEY_SELECTED_SUBACCOUNTS_STATE
    );

    if (subAccountsState) {
      return subAccountsState;
    }

    return {};
  }
  set selectedPartnerSubAccount(idSubAccount: number | null) {
    if (idSubAccount === null) {
      localStorage.removeItem(KEY_SELECTED_PARTNER_SUBACCOUNT);

      return;
    }

    localStorage.setItem(KEY_SELECTED_PARTNER_SUBACCOUNT, idSubAccount);
  }
  get selectedPartnerSubAccount(): number | null {
    const idSubAccount = localStorage.getItem<number>(
      KEY_SELECTED_PARTNER_SUBACCOUNT
    );

    return idSubAccount ?? null;
  }
}

export const uiSettings = new UiSettings();
