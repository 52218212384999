import isNull from 'lodash/isNull';
import { useCallback, useEffect, useRef } from 'react';
import { FixedSizeList } from 'react-window';

import { OrderBookLine } from '@terminal/core/types/orderBook';

export const useOrderBookScroll = <T>(
  idFi: number,
  centerSpreadIndex: number,
  lines: OrderBookLine[],
  autoCenter: boolean,
  nodeId?: string
) => {
  const listRef = useRef<FixedSizeList<T>>(null);
  const isMount = useRef(false);

  useEffect(() => {
    isMount.current = false;
  }, [idFi]);

  const isScrollReady =
    !isNull(listRef.current) && lines.some(({ Price }) => Boolean(Price));

  const scrollToCenter = useCallback(() => {
    if (typeof listRef.current?.scrollToItem === 'function' && isScrollReady) {
      const centerRow = document.getElementById(
        `${nodeId}-${centerSpreadIndex}`
      );

      if (centerRow) {
        listRef.current?.scrollTo(
          centerRow.offsetTop -
            Number(listRef.current.props.height) / 2 +
            centerRow.getBoundingClientRect().height / 2
        );
      } else {
        listRef.current?.scrollToItem(centerSpreadIndex, 'center');
      }
    }
  }, [centerSpreadIndex, isScrollReady, nodeId]);

  //Скроллим при маунте к центру таблицы
  useEffect(() => {
    if (!isMount.current && !autoCenter && isScrollReady) {
      scrollToCenter();
      isMount.current = true;
    }
  }, [autoCenter, isScrollReady, scrollToCenter]);

  return {
    listRef,
    scrollToCenter,
  };
};
