import cn from 'classnames';
import React, { FC } from 'react';
import { Checkbox } from '@alfalab/core-components/checkbox';

import { MenuItem } from '../MenuItem';

import { CheckboxItem as CheckboxItemType } from '../types/CheckboxItem';

import styles from './CheckboxItem.module.css';

export type CheckboxItemProps = Omit<
  CheckboxItemType,
  'key' | 'Icon' | 'hideMenu' | 'hidden'
>;

export const CheckboxItem: FC<CheckboxItemProps> = (props) => {
  const { className, label, children, type, ...restProps } = props;
  const rootClassName = cn(className, styles.root);

  return (
    <MenuItem
      Component={Checkbox}
      label={label}
      className={rootClassName}
      {...restProps}
    />
  );
};
