import cn from 'classnames';
import React, { FC } from 'react';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { CheckmarkSIcon } from '@alfalab/icons-glyph/CheckmarkSIcon';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';

import { MenuItemComponent } from '../../../shared/ui/Menu';

import { ButtonItem as ButtonItemType } from '../types/ButtonItem';

// eslint-disable-next-line no-restricted-imports
import styles from '../Menu.module.css';

export type ButtonItemProps = Omit<
  ButtonItemType,
  'key' | 'Icon' | 'hideMenu' | 'hidden'
>;

export const ButtonItem: FC<ButtonItemProps> = (props) => {
  const {
    label,
    hint,
    children,
    type,
    checked,
    actions,
    className,
    ...restProps
  } = props;

  const text =
    hint && label ? (
      <span className={styles.inlineFlex}>
        {label}&nbsp;
        <TooltipDesktop
          position="top"
          content={hint}
          contentClassName={styles.tooltipContent}
          targetClassName={styles.inlineFlex}
        >
          <InformationCircleLineSIcon
            height={12}
            width={12}
            color="var(--color-light-text-secondary)"
          />
        </TooltipDesktop>
      </span>
    ) : (
      label
    );

  return (
    <MenuItemComponent className={cn(className, styles.item)} {...restProps}>
      {children}
      {text}
      {checked && (
        <CheckmarkSIcon
          color="var(--color-light-graphic-secondary)"
          style={{
            marginLeft: 'auto',
            width: 'var(--select-checkmark-size)',
            height: 'var(--select-checkmark-size)',
          }}
        />
      )}
      <div className={styles.itemActions}>{actions}</div>
    </MenuItemComponent>
  );
};
