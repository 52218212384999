// Размер брокерсой комиссии при подаче торговой заявки

import { ObjectGroup } from '../client/entities';

const SPB_MARKET_R_CODES = ['SPBEX', 'SPBR']; // СПБ
const MB_MARKET_R_CODES = ['MICEX', 'SELT']; // МБ
const OTC_MB_MARKET_R_CODES = ['OTC', 'ECL']; // ОТС МБ

// Формула описана тут - http://confluence.moscow.alfaintra.net/pages/viewpage.action?pageId=545636245
export const getOrderTax = (
  price: number,
  quantity: number,
  idObjectGroup: number,
  rCode: string,
  //Курс валюты расчетов к рублю
  currencyPayment: number,
  //Курс валюты номинала к рублю
  currencyNominal: number,
  nominal: number,
  tax: {
    sPBEX_FeeRate: number;
    mICEX_FeeRate: number;
    sELT_FeeRate: number;
    oTC_FeeRate: number;
  }
): number | undefined => {
  //СПБ
  if (SPB_MARKET_R_CODES.includes(rCode)) {
    switch (idObjectGroup) {
      case ObjectGroup.Stocks:
      case ObjectGroup.EquityParticipations:
      case ObjectGroup.Receipts:
        if (
          quantity * price * currencyPayment >
          quantity * 0.01 * currencyPayment
        ) {
          return quantity * price * currencyPayment * tax.sPBEX_FeeRate;
        } else {
          return quantity * 0.01 * currencyPayment;
        }
      case ObjectGroup.Bonds:
        if (
          quantity * price * (nominal / 100) * currencyNominal >
          quantity * 0.01 * currencyPayment
        ) {
          return (
            quantity *
            price *
            (nominal / 100) *
            currencyNominal *
            tax.sPBEX_FeeRate
          );
        } else {
          return quantity * 0.01 * currencyPayment;
        }

      default:
        return;
    }
  }

  //МБ
  if (MB_MARKET_R_CODES.includes(rCode)) {
    switch (idObjectGroup) {
      case ObjectGroup.Stocks:
      case ObjectGroup.EquityParticipations:
      case ObjectGroup.Receipts:
        return (
          quantity *
          price *
          currencyPayment *
          (rCode === 'MICEX' ? tax.mICEX_FeeRate : tax.sELT_FeeRate)
        );
      case ObjectGroup.Currency:
      case ObjectGroup.Metals:
        return quantity * price * currencyPayment * tax.sELT_FeeRate;
      case ObjectGroup.Bonds:
        return (
          quantity *
          price *
          (nominal / 100) *
          currencyNominal *
          (rCode === 'MICEX' ? tax.mICEX_FeeRate : tax.sELT_FeeRate)
        );

      default:
        return;
    }
  }

  //ОТС МБ
  if (OTC_MB_MARKET_R_CODES.includes(rCode)) {
    switch (idObjectGroup) {
      case ObjectGroup.Stocks:
      case ObjectGroup.EquityParticipations:
      case ObjectGroup.Receipts:
        return quantity * price * currencyPayment * tax.oTC_FeeRate;
      case ObjectGroup.Bonds:
        return (
          quantity * price * (nominal / 100) * currencyNominal * tax.oTC_FeeRate
        );

      default:
        return;
    }
  }
};
