import { memo, useEffect, useState } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Gap } from '@alfalab/core-components/gap';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import {
  Tab,
  TabsDesktop,
  TabsDesktopProps,
} from '@alfalab/core-components/tabs/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { ArrowsRightLeftCurvedMIcon } from '@alfalab/icons-glyph/ArrowsRightLeftCurvedMIcon';
import { ToolsMIcon } from '@alfalab/icons-glyph/ToolsMIcon';

import { LOCATIONS } from '@terminal/core/constants/analytics';
import { trackMainMenu } from '@terminal/core/lib/analytics';
import { SelectTreaty } from '@terminal/lk/legacy/components/SelectTreaty';
import { getAccountInfoGI, Treaty, useAccountInfo } from '@terminal/lk-core';

import { ACCESS_LEVELS } from '../../../../entities/AccountsOperations';
import { MainInfo, Management } from '../../../../widgets/AccountsOperations';

import styles from './AccountsOperations.module.css';

enum Subpage {
  INFO = 'info',
  MANAGEMENT = 'management',
  PLATFORM_REGISTRATION = 'platforms-registration',
}

export const AccountsOperations = memo(() => {
  const [treaty, setTreaty] = useState<Treaty | null>(null);
  const [subpage, setSubpage] = useState<Subpage>(Subpage.INFO);

  useEffect(() => {
    if (subpage === Subpage.INFO) {
      trackMainMenu(LOCATIONS.LK_MAIN_INFO);
    } else {
      trackMainMenu(LOCATIONS.LK_ACCOUNTS);
    }
  }, [subpage]);

  const {
    data: accountInfo,
    isLoading: accountInfoPending,
    refetch: refetchAccountInfo,
    isError: isAccountInfoError,
  } = useAccountInfo(treaty?.treaty, getAccountInfoGI);

  const handleChange: TabsDesktopProps['onChange'] = (_, { selectedId }) =>
    setSubpage(selectedId as Subpage);

  const onAccountSave = () => {
    refetchAccountInfo();
  };

  const onSubaccountAdd = () => {
    refetchAccountInfo();
  };

  const onSubaccountRedacted = () => {
    refetchAccountInfo();
  };

  if (isAccountInfoError) {
    return (
      <div className={styles.errorModal}>
        <SuperEllipse
          size={64}
          backgroundColor="var(--color-light-bg-tertiary)"
        >
          <ToolsMIcon />
        </SuperEllipse>
        <Gap size="xl" direction="vertical" />
        <Typography.Text view="primary-large" weight="bold">
          Временные неполадки
        </Typography.Text>
        <Gap size="m" direction="vertical" />
        <Typography.Text color="secondary" view="secondary-large">
          Извините, уже исправляем. Попробуйте обновить <br />
          страницу или вернуться позже.
        </Typography.Text>
        <Gap size="2xl" direction="vertical" />
        <ButtonDesktop
          onClick={() => {
            refetchAccountInfo();
          }}
          size="xs"
          view="tertiary"
          leftAddons={<ArrowsRightLeftCurvedMIcon width={18} height={18} />}
          loading={accountInfoPending}
          className={styles.refreshButton}
        >
          Обновить страницу
        </ButtonDesktop>
      </div>
    );
  }

  return (
    <>
      <div className={styles.treaty}>
        <SelectTreaty
          zeros={false}
          hint="Все настройки будут применены к выбранному счету"
          onSelect={(treaty) => setTreaty(treaty)}
        />
      </div>
      <div>
        <TabsDesktop
          selectedId={subpage}
          onChange={handleChange}
          scrollable={true}
          size="s"
        >
          <Tab
            title="Основная информация"
            id={Subpage.INFO}
            className={styles.tab}
          >
            <MainInfo
              treaty={treaty}
              accountInfo={accountInfo}
              accountInfoPending={accountInfoPending}
              onAccountSave={onAccountSave}
            />
          </Tab>
          <Tab
            title="Управление счетами"
            id={Subpage.MANAGEMENT}
            className={styles.tab}
          >
            <Management
              treaty={treaty}
              accountInfo={accountInfo}
              accountInfoPending={accountInfoPending}
              refetchAccountInfo={refetchAccountInfo}
              onSubaccountAdd={onSubaccountAdd}
              onSubaccountRedacted={onSubaccountRedacted}
              accessLevels={ACCESS_LEVELS}
            />
          </Tab>
        </TabsDesktop>
      </div>
    </>
  );
});

AccountsOperations.displayName = 'AccountsOperations';
