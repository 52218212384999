import { format } from 'date-fns';
import { useMemo } from 'react';

import { Filter } from '@terminal/core/types/layout';
import { TableColumnKey } from '@terminal/core/types/tableColumn';

export function useFiltersFormatted(
  filters: Record<string, Filter | undefined>
) {
  return useMemo(() => {
    return Object.entries(filters).reduce((reducer, [key, filter]) => {
      if (filter) {
        if (
          [
            TableColumnKey.BsYield,
            TableColumnKey.BsPrice,
            TableColumnKey.BsYieldToOffert,
            TableColumnKey.BsCupYield,
            TableColumnKey.BsCupSize,
            TableColumnKey.BsCouponPaymentPerYear,
            TableColumnKey.BsNominal,
          ].includes(key as TableColumnKey)
        ) {
          reducer[key] = filter.value
            ? {
                min: filter.value.from,
                max: filter.value.to,
              }
            : filter.value;
        } else if (
          key === TableColumnKey.BsMatDate ||
          key === TableColumnKey.BsOffertDate
        ) {
          reducer[key] = filter.value
            ? {
                min: filter.value.dateFrom
                  ? format(filter.value.dateFrom, 'yyyy-MM-dd')
                  : undefined,
                max: filter.value.dateTo
                  ? format(filter.value.dateTo, 'yyyy-MM-dd')
                  : undefined,
              }
            : filter.value;
        } else if (key === TableColumnKey.BsAllowNonQualInvest) {
          reducer[key] = filter.value;
        } else {
          reducer[key] = filter.value;
        }
      }

      return reducer;
    }, {});
  }, [filters]);
}
