import React, { useCallback, useMemo, useState } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Divider } from '@alfalab/core-components/divider';
import { Typography } from '@alfalab/core-components/typography';
import { PlusMIcon } from '@alfalab/icons-glyph/PlusMIcon';

import { FunctionalModalSidebar } from '@terminal/common/components/FunctionalModal';
import { Portal } from '@terminal/common/components/Portal';
import { ConfirmModal } from '@terminal/lk/legacy/components/ConfirmModal';
import {
  AccountConfidant,
  Attorney,
  AttorneyStatus,
  SubAccount,
  Treaty,
  useAttorneys,
  useCreateAttorney,
  useGetAttorneyPdf,
  useRejectAttorney,
} from '@terminal/lk-core';
import { TradingCertificateLock } from '@terminal/widgets';

import {
  AccessLevelInfo,
  ManagementResultType,
} from '../../../../entities/AccountsOperations';
import { createAttorneyRequestFromValues } from '../../lib/createAttorneyRequestFromValues';
import { getCreateAttorneyErrorResult } from '../../lib/getCreateAttorneyErrorResult';
import { mergeAttorneys } from '../../lib/mergeAttorneys';
import { AccessLevels } from '../AccessLevels';
import { AttorneyForm } from '../AttorneyForm';
import { AttorneyTable } from '../AttorneyTable';
import { AttorneyTableFilters } from '../AttorneyTableFilters';

import { CreateAttorneyFormValues } from '../../model/types/CreateAttorneyFormValues';

import styles from './AccessList.module.css';

interface AccessListProps {
  treaty: Treaty;
  managers: AccountConfidant[];
  subAccounts: SubAccount[];
  setResult: (result: ManagementResultType) => void;
  refetchAccountInfo: () => void;
  hideAttorneyCreation?: boolean;
  withSubAccounts?: boolean;
  accessLevels: AccessLevelInfo[];
}

export const AccessList = (props: AccessListProps) => {
  const {
    managers,
    treaty,
    subAccounts,
    setResult,
    refetchAccountInfo,
    hideAttorneyCreation,
    withSubAccounts,
    accessLevels,
  } = props;

  const [attorneysReloading, setAttorneysReloading] = useState(false);
  const [statusFilter, setStatusFilter] = useState<AttorneyStatus>(
    AttorneyStatus.ACTIVE
  );
  const [attorneyFormOpen, setAttorneyFormOpen] = useState<boolean>(false);
  const [accessLevelsOpen, setAccessLevelsOpen] = useState<boolean>(false);
  const [rejectingAttorney, setRejectingAttorney] = useState<null | Attorney>(
    null
  );

  const {
    data: rawAttorneys,
    isFetching: attorneysPending,
    refetch: refetchAttorneys,
  } = useAttorneys({
    treaty: treaty.treaty,
    status: statusFilter,
  });

  const { mutateAsync: getAttorneyPdf } = useGetAttorneyPdf();
  const { mutateAsync: createAttorney } = useCreateAttorney();
  const { mutateAsync: rejectAttorney, isLoading: rejectPending } =
    useRejectAttorney();

  const subAccount = subAccounts.find((account) =>
    account.accCode.includes('-000')
  );

  const attorneys = useMemo(
    () =>
      mergeAttorneys(
        rawAttorneys,
        statusFilter === AttorneyStatus.ACTIVE ? managers : undefined
      ),
    [managers, rawAttorneys, statusFilter]
  );

  const handleGetAttorneyPdf = useCallback(
    async (attorneyId?: number) => {
      if (attorneyId) {
        await getAttorneyPdf({ attorneyId: attorneyId });
      }
    },
    [getAttorneyPdf]
  );

  const handleAttorneyReject = useCallback(
    (attorneyId?: number) => {
      if (attorneyId) {
        const rejectingAttorney = rawAttorneys?.find(
          (attorney) => attorney.id === attorneyId
        );

        if (rejectingAttorney) {
          setRejectingAttorney(rejectingAttorney);
        }
      }
    },
    [rawAttorneys]
  );

  const handleConfirmAttorneyReject = useCallback(
    async (attorney: Attorney) => {
      try {
        await rejectAttorney({
          params: {
            attorneyId: attorney.id,
          },
          signOptions: {
            successTitle: 'Доверенность успешно отозвана',
            successCallback: async () => {
              setRejectingAttorney(null);
              setAttorneysReloading(true);
              await refetchAttorneys();
              await refetchAccountInfo();
              setAttorneysReloading(false);
            },
          },
        });
      } finally {
        setRejectingAttorney(null);
      }
    },
    [refetchAttorneys, rejectAttorney, refetchAccountInfo]
  );

  const handleCreateAttorneyFormSubmit = useCallback(
    async (values: CreateAttorneyFormValues) => {
      await createAttorney({
        params: createAttorneyRequestFromValues(values),
        signOptions: {
          successTitle: 'Доверенность успешно оформлена',
          successCallback: async () => {
            setStatusFilter(AttorneyStatus.ACTIVE);
            setAttorneyFormOpen(false);
            setAccessLevelsOpen(false);
            setAttorneysReloading(true);
            await refetchAttorneys();
            await refetchAccountInfo();
            setAttorneysReloading(false);
          },
          muteError: true,
          errorCallback: (result) => {
            setAttorneyFormOpen(false);
            setAccessLevelsOpen(false);
            setResult(getCreateAttorneyErrorResult(result));
          },
        },
      });
    },
    [createAttorney, refetchAttorneys, refetchAccountInfo, setResult]
  );

  return (
    <div className={styles.container}>
      <Typography.Text
        view="primary-small"
        weight="bold"
        className={styles.title}
      >
        Доверенности и доступы
      </Typography.Text>
      <AttorneyTableFilters
        value={statusFilter}
        onChange={setStatusFilter}
        className={styles.filters}
      />
      <AttorneyTable
        items={attorneys}
        showAccount={withSubAccounts || treaty.isFirm}
        loading={attorneysPending || attorneysReloading}
        onReject={handleAttorneyReject}
        onDownload={handleGetAttorneyPdf}
        highlightExpirationDate={statusFilter === AttorneyStatus.ACTIVE}
      />
      {Boolean(!hideAttorneyCreation && !treaty.isRepo) && (
        <TradingCertificateLock>
          <Divider className={styles.divider} />

          <div className={styles.submit}>
            <ButtonDesktop
              size="xs"
              block
              onClick={() => setAttorneyFormOpen(true)}
              leftAddons={<PlusMIcon width={16} height={16} />}
            >
              Оформить доверенность
            </ButtonDesktop>
          </div>
        </TradingCertificateLock>
      )}

      <Portal id="main-window-modals">
        <FunctionalModalSidebar
          open={attorneyFormOpen}
          backLabel="Управление счетами"
          onClose={() => setAttorneyFormOpen(false)}
        >
          <AttorneyForm
            key={`form-${attorneyFormOpen}`}
            onAccessLevelsClick={() => setAccessLevelsOpen(true)}
            treaty={treaty.treaty}
            subAccounts={subAccounts}
            onSubmit={handleCreateAttorneyFormSubmit}
            disabled={!subAccount?.accCode}
            withSubAccountSelect={withSubAccounts}
            accessLevels={accessLevels}
          />
        </FunctionalModalSidebar>
      </Portal>
      <Portal id="main-window-modals">
        <FunctionalModalSidebar
          open={accessLevelsOpen}
          backLabel="Оформление доверенности на управление счётом"
          onClose={() => setAccessLevelsOpen(false)}
        >
          <AccessLevels accessLevels={accessLevels} />
        </FunctionalModalSidebar>
      </Portal>
      <ConfirmModal
        open={Boolean(rejectingAttorney)}
        title="Отзыв доверенности"
        text={
          <>
            Вы уверены, что хотите отозвать доверенность на управление счётом{' '}
            {rejectingAttorney?.accCode} для {rejectingAttorney?.attorneyName}?
          </>
        }
        confirmLabel="Отозвать"
        confirmLoading={rejectPending}
        onClose={() => setRejectingAttorney(null)}
        onConfirm={() => handleConfirmAttorneyReject(rejectingAttorney!)}
      />
    </div>
  );
};
