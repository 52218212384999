import {
  ANALYTICS,
  DIMENSIONS_SCHEMAS,
} from '@terminal/core/constants/analytics';
import { track } from '@terminal/core/lib/analytics';
import { LayoutType } from '@terminal/core/types/layout';

interface Props {
  layoutsCount: number;
  type: LayoutType;
}

/**
 * Отправляет метрику при создании нового рабочего стола
 * @see https://docs.google.com/spreadsheets/d/1HmY0eZQe_fKKUsWQjnhGIy7vN0MEakswEEV1AZe5Qx0/edit?gid=1008763574#gid=1008763574
 * */
export function trackCreateLayout(props: Props): void {
  const { layoutsCount, type } = props;

  track(
    ANALYTICS.CATEGORY.TRADING_WINDOW,
    ANALYTICS.ACTION.OPEN,
    ANALYTICS.LABEL.WINDOW,
    null,
    null,
    [
      {
        schema: DIMENSIONS_SCHEMAS.CUSTOM,
        data: {
          '1': layoutsCount,
          '2':
            type === LayoutType.Flex ? 'Гибкая сетка' : 'Фиксированная сетка',
        },
      },
    ]
  );
}
