import React, { FC, PropsWithChildren, useMemo } from 'react';

import { ChatContext, Context } from '../../../shared/lib/ChatContext';

export const ChatProvider: FC<PropsWithChildren<ChatContext>> = (props) => {
  const { supportChat, supportPhone, supportEmail, children } = props;

  const value = useMemo<ChatContext>(
    () => ({
      supportChat,
      supportPhone,
      supportEmail,
    }),
    [supportChat, supportPhone, supportEmail]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
