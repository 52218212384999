import { BaseWidgetConfig, Widget } from '@terminal/core/types/layout';

import { useWidgetSubName } from '../hooks/useWidgetSubName';

export const useWidgetTab = (
  component: Widget,
  config: BaseWidgetConfig | null,
  name: string
) => {
  const subName = useWidgetSubName(component, config);

  return { name, subName };
};
