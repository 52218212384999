import { ObjectGroup } from '@terminal/core/lib/client/entities';
import { PUShowMode } from '@terminal/core/types/subAccountPosition';

export const FILTERS_BY_TYPE = [
  {
    name: 'Акции',
    idObjectGroup: [ObjectGroup.Stocks, ObjectGroup.Receipts],
  },
  {
    name: 'Облигации',
    idObjectGroup: [ObjectGroup.Bonds],
  },
  {
    name: 'Фьючерсы',
    idObjectGroup: [ObjectGroup.Futures],
  },
  {
    name: 'Фонды',
    idObjectGroup: [ObjectGroup.EquityParticipations],
  },
  {
    name: 'Валюты',
    idObjectGroup: [ObjectGroup.Currency],
  },
  {
    name: 'Металлы',
    idObjectGroup: [ObjectGroup.Metals],
  },
];

export const FILTERS_BY_PU = [
  {
    name: 'Любая доходность',
    mode: PUShowMode.ANY,
  },
  {
    name: 'Прибыльные: день',
    mode: PUShowMode.UP_DAY,
  },
  {
    name: 'Прибыльные: все время',
    mode: PUShowMode.UP_ALL,
  },
  {
    name: 'Убыточные: день',
    mode: PUShowMode.DOWN_DAY,
  },
  {
    name: 'Убыточные: все время',
    mode: PUShowMode.DOWN_ALL,
  },
];
