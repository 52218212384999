//Источник:  https://www.moex.com/ru/index/RGBI/constituents
// nickname вместо isin - потому что @todo: а почему, кстати?

export const RGBITRList = [
  'OFZ-PD 26207', // RU000A0JS3W6
  'OFZ-PD 26212', // RU000A0JTK38
  'OFZ-PD 26218', // RU000A0JVW48
  'OFZ-PD 26219', // RU000A0JWM07
  'OFZ-PD 26221', // RU000A0JXFM1
  'OFZ-PD 26224', // RU000A0ZYUA9
  'OFZ-PD 26225', // RU000A0ZYUB7
  'OFZ-PD 26226', // RU000A0ZZYW2
  'OFZ-PD 26228', // RU000A100A82
  'OFZ-PD 26230', // RU000A100EF5
  'OFZ-PD 26232', // RU000A1014N4
  'OFZ-PD 26233', // RU000A101F94
  'OFZ-PD 26235', // RU000A1028E3
  'OFZ-PD 26236', // RU000A102BT8
  'OFZ-PD 26237', // RU000A1038Z7
  'OFZ-PD 26238', // RU000A1038V6
  'OFZ-PD 26239', // RU000A103901
  'OFZ-PD 26240', // RU000A103BR0
  'OFZ-PD 26241', // RU000A105FZ9
  'OFZ-PD 26242', // RU000A105RV3
  'OFZ-PD 26243', // RU000A106E90
  'OFZ-PD 26244', // RU000A1074G2
  'OFZ-PD 26245', // RU000A108EG6
  'OFZ-PD 26246', // RU000A108EE1
  'OFZ-PD 26247', // RU000A108EF8
  'OFZ-PD 26248', // RU000A108EH4
];
