import { ReactElement, RefCallback } from 'react';
import { PopoverProps } from '@alfalab/core-components/popover';

import {
  FetchRemoteConfigParams,
  RemoteConfig,
} from '@terminal/common/entities/RemoteConfig';
import {
  AccountOption,
  AccountsOption,
} from '@terminal/common/model/types/account';
import { ActionSources } from '@terminal/core/constants/analytics';
import { TradingFormSource } from '@terminal/core/lib/analytics/model/main';
import {
  AnaliticsVolumeType,
  DetailedOrderAction,
  DetailedOrderParams,
} from '@terminal/core/lib/analytics/model/order';
import { PerformanceAnalyticsWidgetParams } from '@terminal/core/lib/analytics/performance';
import { OrderType } from '@terminal/core/lib/client/entities';
import { CreateNotificationParams } from '@terminal/core/lib/notifications/types';
import { MarginParamsFetcher } from '@terminal/core/lib/rest/fetchMarginParams';
import { getIssuerFinancials } from '@terminal/core/lib/rest/investApi';
import { Treaty } from '@terminal/core/lib/rest/lkCommon';
import { WatchListSlice } from '@terminal/core/store/slices/watchlistSlice';
import {
  EmitentIcons,
  ObjectItem,
  ObjectItemsTable,
  ObjectTypeItem,
} from '@terminal/core/types/core';
import { Dividends } from '@terminal/core/types/dividents';
import { IssuerFinancials } from '@terminal/core/types/issuerFinancials';
import { LinkName, Widget } from '@terminal/core/types/layout';
import { MoneyHistoryFilter } from '@terminal/core/types/money';
import {
  OperationHistoryItem,
  OperationHistoryResult,
} from '@terminal/core/types/operation';
import { TradesFeedsSettings } from '@terminal/core/types/tradeFeed';
import { TradeLimitsResult } from '@terminal/core/types/tradeLimits';
import { LimitRequestParams } from '@terminal/core/types/trading';
import {
  CommonSettings,
  DisplayInstrumentType,
  HeaderMenuItem,
  HeaderMenuParams,
  LkSubMenuValue,
  SettingType,
} from '@terminal/core/types/ui';
import type { WatchList } from '@terminal/core/types/watchList';
import type { CloudConfigHooks } from '@terminal/lk/features/CloudConfiguration';

import { ChartApi } from './ChartApi';
import { CompoundedDropdown } from './DropdownApi';
import { TabNode, WidgetConfig } from './TabNodeApi';

import { UseQueryResult } from 'react-query/types/react/types';

export enum FeatureFlagName {
  PORTFOLIO_ANALYTICS_ENABLED = 'PORTFOLIO_ANALYTICS_ENABLED',
  TOPUP_FROM_WIDGET_ENABLED = 'TOPUP_FROM_WIDGET_ENABLED',
  USE_PASSPORT_ENABLED = 'USE_PASSPORT_ENABLED',
}

export interface OperationsHistoryParams {
  subAccount: string; //456454-000
  dateFrom?: string; //UTC Date - 2022-01-26T05:34:20.680Z
  dateTo?: string; //UTC Date - 2022-02-26T05:34:20.680Z
}

export interface OperationsHistoryResult {
  cursor?: string;
  operations: OperationHistoryResult[];
}

interface WidgetSettingsApi {
  setSetting: (type: SettingType, key: string, value: unknown) => void;
  useSettings: () => CommonSettings;
  setTradeFeedSettings: (
    idFI: number,
    nodeId?: string,
    idx?: number,
    volume?: number
  ) => void;
  unlinkFromGroup: (nodeId: string, name: LinkName) => void;
  updateNode: <T>(
    nodeId: string,
    config: T,
    symbol?: string | undefined
  ) => void;
}

export interface LKResult {
  code: number;
  message: string | null;
}

interface WidgetWatchListsApi {
  useWatchLists: () => WatchList[];
  createWatchList: WatchListSlice['createWatchList'];
  editWatchList: WatchListSlice['editWatchList'];
  deleteWatchList: WatchListSlice['deleteWatchList'];
  addToWatchList: WatchListSlice['addToWatchList'];
  deleteFromWatchList: WatchListSlice['deleteFromWatchList'];
  addToWatchListMultiple: WatchListSlice['addToWatchListMultiple'];
  selectedDisplayInstrumentType: DisplayInstrumentType;
  objectTypes: ObjectTypeItem[];
}

interface TopUpProps {
  idSubAccount?: number;
  selectedSubAccounts?: string[];
  sum?: number;
}

type PortfolioAnalyticsProps = {
  nodeId?: string;
  isOpen: boolean;
  onClose: () => void;
  selectedSubAccounts: string[];
};

export type OpenTopUpModalFunc = (props?: TopUpProps) => void;

export type AnalyticsTrack = (
  category: string,
  action: string,
  label?: string | null,
  property?: string | null,
  value?: number | null,
  dimensions?: object[] // TODO Dimensions[] ( api-extractor не может собрать этот тип )
) => void;

export type DetailedOrderInfoAnalyticsTrack = (
  action: DetailedOrderAction,
  params: DetailedOrderParams
) => void;

export interface AccountDropdownProps {
  position: PopoverProps['position'];
  hasRequirements: boolean;
  options: AccountOption[];
  onSelect: (option: AccountsOption) => void;
  searchText: string;
  setSearchText: (text: string) => void;
  children:
    | ReactElement<HTMLElement>
    | ((
        ref: RefCallback<any>,
        isOpen: boolean,
        setIsOpen: (boolean) => void
      ) => ReactElement<HTMLElement>);
  isOrderBookVersion?: boolean;
  isEverySubAccountSelected?: boolean;
  onSelectAllClick?: () => void;
  useAnchorWidth?: boolean;
  popoverClassName?: string;
}

export interface WidgetApi extends WidgetSettingsApi, WidgetWatchListsApi {
  setHeaderMenuOpen: (
    menuId: HeaderMenuItem | null,
    params?: HeaderMenuParams
  ) => void;
  setLkSubMenuId: (lkSubMenuValue: LkSubMenuValue) => void;
  addNotification: (params: CreateNotificationParams) => void;

  openTopUpModal: OpenTopUpModalFunc;

  getActiveLayoutKey: () => number | undefined;
  getTradesFeedSettings: () => TradesFeedsSettings | undefined;
  //
  useTreaties: () => UseQueryResult<Treaty[]>;
  useMoneyHistory: (params: MoneyHistoryFilter) => any;
  useConnectionError: () => boolean;
  // ручки для фичей
  useInstrumentIcons: () => EmitentIcons;
  getInstrumentIconURL: (
    instrumentIcons: EmitentIcons,
    objects: ObjectItemsTable,
    object?: ObjectItem
  ) => string;
  useIssuer: (isin?: string) => any;
  useIssuerFinancials: (
    isin?: string,
    dataOptions?: Parameters<typeof getIssuerFinancials>[1]
  ) => UseQueryResult<IssuerFinancials>;
  useCoupons: (isin?: string, idObject?: number) => any;
  useDividends: (isin?: string, idObject?: number) => UseQueryResult<Dividends>;
  useNews: (params: {
    cursor?: string;
    sources?: string[];
    isins?: string[];
    count?: number;
    importanceTypes?: string[];
    sentiment?: string;
    refetchInterval?: number;
  }) => any;

  loadedWidgets: string[];
  onWidgetLoad: (key: string) => void;
  resetLoadedWidgets: () => void;
  addWidget: (
    widgetName: string,
    widgetType: Widget,
    widgetConfig: WidgetConfig,
    nodeId?: string
  ) => void;
  deleteWidget: (
    nodeId?: string,
    link?: LinkName,
    attributes?: {
      enableTabStrip?: boolean;
    }
  ) => void;
  cloneWidget: (nodeId?: string) => void;
  getNode: (nodeId?: string) => TabNode | undefined;
  analytics: {
    track: AnalyticsTrack;
    trackOrder: (buy: boolean, orderType: number, source: string) => void;
    trackOrderEditSource: (source: ActionSources) => void;
    trackOrderEditType: (idOrderType: OrderType) => void;
    trackTradingFormOpened: (from: TradingFormSource) => void;
    trackDetailedOrderInfo: DetailedOrderInfoAnalyticsTrack;
    trackOrderVolumeChanging: (volumeType: AnaliticsVolumeType) => void;
  };
  useLogWidgetPerformance: <T extends Widget>(
    widgetName: T,
    activeTab?: number
  ) => {
    isReady: () => boolean;
    startInit: () => void;
    ready: (
      params?: PerformanceAnalyticsWidgetParams[T],
      endDate?: Date
    ) => void;
  };
  useLogWidgetUpdatePerformance: <T extends Widget>(
    widgetName: T,
    activeTab?: number
  ) => {
    start: (eventName: string) => void;
    finish: (
      eventName: string,
      params?: PerformanceAnalyticsWidgetParams[T],
      endDate?: Date
    ) => void;
  };
  useTriggerOnConditionOrFirstRender: (
    ready: (params?: any) => void,
    condition: boolean,
    timeout?: number
  ) => void;
  marginParamsFetcher?: MarginParamsFetcher<
    number | number[],
    string | string[]
  >;
  DropdownComponent: CompoundedDropdown;
  useRemoteConfig: <T>(
    params: FetchRemoteConfigParams,
    onSuccess: (onSuccess: RemoteConfig<T>) => void
  ) => UseQueryResult<RemoteConfig<T>, unknown>;
  useTradeLimits: (
    selectedSubAccountId: number,
    selectedSubAccounts: string[],
    params: LimitRequestParams,
    idAccount?: number
  ) => {
    tradeLimitsLong: TradeLimitsResult | null;
    tradeLimitsShort: TradeLimitsResult | null;
  };
  useEnrollCertificate: () => { enrollCertificate: () => void };
  useRejectCertificateEffect: (onReject: () => void) => void;
  cloudConfigHooks: CloudConfigHooks;
  chart?: ChartApi;
  useFeatureFlag: (name: FeatureFlagName) => boolean;
  PortfolioAnalyticsComponent?: React.FC<PortfolioAnalyticsProps>;
  AccountDropdownComponent: React.FC<AccountDropdownProps>;
  useOperationsHistory: (data: OperationsHistoryParams) => {
    isFetching: boolean;
    data?: OperationHistoryItem[];
  };
}
