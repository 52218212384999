import { RequireAtLeastOne } from 'type-fest';

import { LK_URI } from '../env';
import { normalizeLkAPIURI } from './rest/normalizeLkAPIURI';

import { EmitentIcons, ObjectItem, ObjectItemsTable } from '../types/core';

/**
 * Можно передать весь ObjectItem целиком.
 * Главное, чтобы был либо isin, либо idObject. Лучше оба
 */
type ObjectProps = RequireAtLeastOne<
  Required<Pick<ObjectItem, 'isin' | 'idObject'>>,
  'isin' | 'idObject'
> &
  Partial<ObjectItem>;

export const getInstrumentIconURL = (
  instrumentIcons: EmitentIcons,
  objects: ObjectItemsTable,
  object?: ObjectProps
): string => {
  // СМ - http://confluence.moscow.alfaintra.net/pages/viewpage.action?pageId=846895601
  // Там косяк в описании, поиск по id в реальности загруженных файлов искать надо по дереву
  // например у акций эпла нет своей иконки, но есть а родительског объекта - компании Эпл

  if (!object || !instrumentIcons || instrumentIcons.length === 0) {
    return '';
  }

  const isin = object.isin;

  // первый приоритет поиск совпадения по ИСИНу
  if (isin) {
    const isinsList = instrumentIcons.find((list) => list.type === 'Isin');

    if (isinsList) {
      const gotIsinIcon = isinsList.items.find((item) => item === isin);

      if (gotIsinIcon) {
        return normalizeLkAPIURI(
          `${LK_URI}/api/logotypes/emitents/download/isin/${isin}.png`
        );
      }
    }
  }

  let currentObject: ObjectProps | undefined = object;
  let iconId = '';

  if (!currentObject) {
    return '';
  }

  const idList = instrumentIcons.find((list) => list.type === 'IdObject');

  if (!idList) {
    return '';
  }

  const checkIfIdIsAvailable = (id?: number) =>
    id && idList.items?.includes(id.toString());

  // Пока не нашли id иконки и текущий объект существует
  while (!iconId && currentObject && currentObject?.idObject) {
    // Ищем в текущем объекте
    if (checkIfIdIsAvailable(currentObject.idObject)) {
      iconId = (currentObject?.idObject || '').toString();
      // Ищем в родительском объекте
    } else {
      const { idObjectBase }: { idObjectBase?: number } = currentObject;

      // Родителя нет, завершаем поиск
      if (!idObjectBase) {
        break;
      }

      currentObject = objects.get('idObject', idObjectBase);
    }
  }

  return iconId
    ? normalizeLkAPIURI(
        `${LK_URI}/api/logotypes/emitents/download/idobject/${iconId}.png`
      )
    : '';
};
