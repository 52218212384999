import { NotificationType } from '@terminal/core/types/ui';
import { useGetTestingListQuery } from '@terminal/endpoints/clusters/lk/client-testing/status';

import { useLkContext } from '../../shared';
import { mapTestingList } from '../lib/mapTestingList';

export const useTestingList = (treaty?: number, enabled: boolean = false) => {
  const { addNotification } = useLkContext();

  return useGetTestingListQuery(
    { queryParams: { treaty: treaty || 0 } },
    {
      mapper: mapTestingList,
      enabled: Boolean(treaty) && enabled,
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка запроса списка тестов',
          text: error?.message || '',
        });
      },
    }
  );
};
