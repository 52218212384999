import { formatAmount } from '@alfalab/utils';

import { getAssetUnits } from '@terminal/core/lib/format';
import { OrderDimensionEnum } from '@terminal/core/types/ui';

type Options = {
  value: number;
  minority?: number;
  orderDimension: OrderDimensionEnum;
  withSuffix?: boolean;
};

export function formatWithSuffix(options: Options): string {
  const { value, orderDimension, minority = 1, withSuffix = true } = options;

  const amount = formatAmount({
    value,
    minority,
    view: 'withZeroMinorPart',
  });

  if (!withSuffix) {
    return amount.formatted;
  }

  let suffix = '';

  if (orderDimension === OrderDimensionEnum.LOT) {
    suffix = getAssetUnits(value, OrderDimensionEnum.LOT);
  }

  if (orderDimension === OrderDimensionEnum.PIECE) {
    suffix = 'шт';
  }

  return `${amount.formatted} ${suffix}`;
}
