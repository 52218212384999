import { makeGetParams } from '../url';
import { fetchLkResult, LKResult } from './lkApi';

/**
 * @deprecated
 * */
export interface TestingListParams {
  treaty: number;
}

/**
 * @deprecated
 * */
export interface Test {
  testId: number;
  testName: string;
  completed: boolean;
}

/**
 * @deprecated
 * */
export interface TestingListResponse {
  testStatusItems: Test[] | null;
  candidateQualInverstorStatus?: boolean;
  qualifiedInvestorDate?: string;
  isFirm?: boolean;
}

export interface TestStatusParams {
  testId: number;
}

interface TestStatusResponse {
  testStatus: Test;
  necessaryTests?: Test[]; // Тесты, которые нужно пройти вначале
  questionCount?: number;
}

interface StartSessionResponse {
  sessionId: string;
}

interface Answer {
  answerId: number;
  answerName: string;
  noteText?: string;
}
interface IQuestion {
  questionNumber: number;
  questionId: number;
  questionName: string;
  allowMultiple: boolean;
  sectionId: number;
}
interface TestQuestionResponse {
  testId: number;
  question: IQuestion;
  answers: Answer[];
}

interface CheckAnswerResponse {
  wrongQuestions?: string[];
  nextQuestionNumber: number;
}

/**
 * @deprecated
 * */
export async function getTestingList(
  params: TestingListParams
): Promise<TestingListResponse> {
  const request = makeGetParams(params);
  const res = await fetchLkResult<TestingListResponse | LKResult>(
    `/api/client-testing/status?${request}`
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
}

export const getTestStatus = async (
  testId: number,
  sessionId: string | undefined
): Promise<TestStatusResponse> => {
  const request = makeGetParams({ sessionId });
  const res = await fetchLkResult<TestStatusResponse | LKResult>(
    `/api/client-testing/status/${testId}?${request}`
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
};

export const getStartTestSession = async (
  testId: number
): Promise<StartSessionResponse> => {
  const res = await fetchLkResult<StartSessionResponse | LKResult>(
    `/api/client-testing/start-session/${testId}`
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
};

export const getQuestion = async (
  testId: number,
  questionNumber: number,
  sessionId: string | undefined
): Promise<TestQuestionResponse> => {
  const request = makeGetParams({ sessionId });
  const res = await fetchLkResult<TestQuestionResponse | LKResult>(
    `/api/client-testing/question/${testId}/${questionNumber}?${request}`
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
};

export const postCheckAnswer = async (
  testId: number,
  questionId: number | undefined,
  sessionId: string | undefined,
  questionNumber: number,
  answerId: (number | null)[],
  allAnswers: (number | null)[][]
): Promise<CheckAnswerResponse> => {
  const body = {
    testId,
    questionNumber,
    questionId,
    answerId,
    allAnswers,
    sessionId,
  };
  const res = await fetchLkResult<CheckAnswerResponse | LKResult>(
    '/api/client-testing/check-answer',
    'POST',
    body
  );

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
};
