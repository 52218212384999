import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { ObjectGroup } from '../../lib/client/entities';
import { calculateMarginParamsWarranty } from '../../lib/formulas/calculateMarginParamsWarranty';
import { calculateMarketWarranty } from '../../lib/formulas/calculateMarketWarranty';
import { MarginParamsFetcher } from '../../lib/rest/fetchMarginParams';
import { buildMarginParamsKey } from '../../lib/rest/fetchMarginParams/buildMarginParamsKey';
import { isMarginParams } from '../../lib/rest/fetchMarginParams/isMarginParams';

import { usePrevious } from '../../hooks/usePrevious';

import { AccountItem } from '../../types/account';
import { PositionItem } from '../../types/position';

// все фьючерсы должны торговаться на MOEX_FOM_FUT
const universalMarketCode = 'MOEX_FOM_FUT';

/**
 * Разделяет портфолио на два массива: на ЕФР фьючерсы и остальные позиции
 * */
const splitPositionsByEFR = (
  positions: PositionItem[],
  accounts: AccountItem[]
) => {
  const matching: PositionItem[] = [];
  const nonMatching: PositionItem[] = [];

  for (const position of positions) {
    const idAccount = position.position.idAccount;
    const isSingleFORTSMarket = accounts.some(
      (account) =>
        account.idAccount === idAccount && account.isSingleFORTSMarket
    );

    if (isSingleFORTSMarket && position.idObjectGroup === ObjectGroup.Futures) {
      matching.push(position);
    } else {
      nonMatching.push(position);
    }
  }

  return [matching, nonMatching];
};

/**
 * Собирает параметры получения маржи из списка позиций.
 * Проходит по каждой позиции и извлекает идентификаторы счетов и ISIN, возвращая
 * их в виде двух отдельных массивов. Если массив позиций пуст, возвращает два пустых массива.
 * @param positions - Массив позиций, из которых необходимо извлечь идентификаторы счетов и ISIN.
 * @returns Кортеж, в котором первый элемент — массив идентификаторов счетов, а второй элемент — массив строк ISIN.
 * */
const collectMarginFetchParams = (
  positions: PositionItem[]
): [number[], string[]] => {
  return positions.length === 0
    ? [[], []]
    : positions.reduce<[number[], string[]]>(
        ([idAccounts, isins], position) => {
          idAccounts.push(position.position.idAccount);

          if (position.object?.isin) {
            isins.push(position.object.isin);
          }

          return [idAccounts, isins];
        },
        [[], []]
      );
};

export function usePortfolioWarranty(
  positions: PositionItem[],
  accounts: AccountItem[],
  marginParamsFetcher?: MarginParamsFetcher<number[], string[]>
) {
  const [warrantyMap, setWarrantyMap] = useState({});

  const prevPositions = usePrevious(positions);
  const positionsChanged = !isEqual(prevPositions, positions);

  const [EFRPositions, nonEFRPositions] = useMemo(
    () => splitPositionsByEFR(positions, accounts),
    // осознанно используем positionsChanged, так как много лишних ререндеров positions
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accounts, positionsChanged]
  );

  const [idAccounts, isins] = useMemo(
    () => collectMarginFetchParams(EFRPositions),
    [EFRPositions]
  );

  const { data, isError, isIdle } = useQuery(
    ['Account', 'marginParams', idAccounts, isins, universalMarketCode],
    () => marginParamsFetcher?.(idAccounts, isins, universalMarketCode),
    {
      enabled: Boolean(marginParamsFetcher) && EFRPositions.length > 0,
    }
  );

  useEffect(() => {
    // считаем ГО для всех позийий по бирже
    if (!data || isIdle || isError) {
      setWarrantyMap(
        EFRPositions.concat(nonEFRPositions).reduce((map, position) => {
          const side = position.torgPos > 0 ? 'buy' : 'sell';
          const idAccount = position.position.idAccount;
          const isin = position.object?.isin;
          const key = buildMarginParamsKey(idAccount, isin);

          map[key] = calculateMarketWarranty(position.finInfoExt, side);

          return map;
        }, {})
      );
    } else {
      setWarrantyMap({
        ...nonEFRPositions.reduce((map, position) => {
          const side = position.torgPos > 0 ? 'buy' : 'sell';
          const idAccount = position.position.idAccount;
          const isin = position.object?.isin;
          const key = buildMarginParamsKey(idAccount, isin);
          const positionWarranty =
            calculateMarketWarranty(position.finInfoExt, side) *
            // количество штук (бывает отрицательным, а ГО должно быть всегда в +)
            Math.abs(position.torgPos);

          map[key] = positionWarranty;

          return map;
        }, {}),
        ...EFRPositions.reduce((map, position) => {
          const side = position.torgPos > 0 ? 'buy' : 'sell';
          const idAccount = position.position.idAccount;
          const isin = position.object?.isin;
          const key = buildMarginParamsKey(idAccount, isin);
          const marginParams = isMarginParams(data) ? data : data[key];
          const positionWarranty =
            calculateMarginParamsWarranty(
              marginParams,
              position.price ?? 0,
              side
              // количество штук (бывает отрицательным, а ГО должно быть всегда в +)
            ) * Math.abs(position.torgPos);

          map[key] = positionWarranty;

          return map;
        }, {}),
      });
    }
  }, [data, isError, isIdle, EFRPositions, nonEFRPositions]);

  /**
   * Возвращает расчитаное значение ГО из мапы
   * */
  const getPositionWarranty = useCallback(
    (idAccount: number, isin: string | undefined = ''): number => {
      return warrantyMap[buildMarginParamsKey(idAccount, isin)] ?? 0;
    },
    [warrantyMap]
  );

  return {
    getPositionWarranty,
    warrantyMap,
  };
}
