import { TSide } from '../../types/common';
import { FinInfoExt } from '../../types/quotes';

/**
 * Вычисляет ГО (гарантийное обеспечение) по биржевой цене.
 * Если сторона 'buy' и gtBuy определен в finInfoExt, возвращает gtBuy.
 * Если сторона 'sell' и gtSell определен в finInfoExt, возвращает gtSell.
 * В противном случае возвращает 0.
 * (формула ниже по ссылке, пункт №7)
 * @see https://confluence.moscow.alfaintra.net/pages/viewpage.action?pageId=1458984395
 * @param {FinInfoExt | undefined} finInfoExt
 * @param {TSide} side
 * @returns {number} ГО
 * */
export const calculateMarketWarranty = (
  finInfoExt: FinInfoExt | undefined,
  side: TSide
): number => {
  const { gtBuy, gtSell } = finInfoExt || {};

  if (side === 'buy' && gtBuy) {
    return gtBuy;
  }

  if (side === 'sell' && gtSell) {
    return gtSell;
  }

  return 0;
};
