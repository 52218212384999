import { ZodType } from 'zod';

import { _errorMapHandler } from './public';

export function createMapper<T, R>(
  scheme: ZodType<R, any, R>,
  fn: (input: T) => Promise<R> | R
) {
  return async function mapAndLogErrors(data: T): Promise<R> {
    let mappedData: R;

    try {
      mappedData = await fn(data);

      scheme.parse(mappedData);

      return mappedData;
    } catch (error) {
      _errorMapHandler(error as Error);
    }

    return mappedData!;
  };
}
