import { parse } from 'date-fns';

import { DATE_AND_TIME_FORMAT } from '@terminal/core/constants/date';

const TIME_PATTERN =
  /Дата и время подписания поручения: (\d{2}\.\d{2}\.\d{4} \d{2}:\d{2})/im;

export function getDateFromAssignment(text: string) {
  const found = text.match(TIME_PATTERN);

  if (found) {
    const dateStr = found[1];

    return parse(dateStr, DATE_AND_TIME_FORMAT, new Date());
  }
}
