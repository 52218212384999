import { useMemo } from 'react';

import { trackBondScreenerWidget } from '@terminal/core/lib/analytics/bondScreener/bondScreener';

interface Settings {
  setIsOpenColumnSettings: (state: boolean) => void;
}

export const useSettings = ({ setIsOpenColumnSettings }: Settings) => {
  return useMemo(
    () => ({
      open: false,
      groups: [],
      onShowRequest: () => {
        setIsOpenColumnSettings(true);
        trackBondScreenerWidget.settings();
        trackBondScreenerWidget.settingsColumns();
      },
      onHideRequest: () => {},
    }),
    [setIsOpenColumnSettings]
  );
};
