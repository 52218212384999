import { useQuery } from 'react-query';

import { requestSignText } from '@terminal/core/lib/rest/lkSignOperation';

/**
 * @deprecated используйте аналог из lk-core
 * */
export const useSignText = (
  operationUrl: string | undefined,
  open: boolean
) => {
  const query = useQuery(
    ['sign-text', { operationUrl }],
    () => requestSignText(operationUrl || ''),
    {
      enabled: Boolean(operationUrl) && open,
    }
  );

  return query;
};
