import { useQuery, UseQueryOptions } from 'react-query';

import { getKeyForEndpoint } from './getKeyForEndpoint';

type FetchFunction<TParams, TData = unknown> = (
  params: TParams
) => Promise<TData>;

type QueryOptions<TData, TError, TSelected = TData> = Omit<
  UseQueryOptions<TData, TError, TSelected>,
  'queryKey' | 'queryFn' | 'select'
> & {
  mapper: (data: TData) => Promise<TSelected> | TSelected;
};

export function createQueryFromRequest<TParams, TError, TData>(
  fetchFn: FetchFunction<TParams, TData>
) {
  return <TSelected = TData>(
    ...args:
      | [TParams, QueryOptions<TData, TError, TSelected>]
      | [QueryOptions<TData, TError, TSelected>]
  ) => {
    let [params, options] = args;

    if (args.length === 1) {
      options = args[0];
    }

    // @ts-expect-error игнорируем т.к. сложно типизировать внутреннее свойство _endpoint для этого кейса
    const endpoint = fetchFn._endpoint;

    return useQuery(
      getKeyForEndpoint(endpoint, params),
      () => fetchFn(params as TParams).then(options!.mapper) as TData,
      options
    );
  };
}
