import { parseISO } from 'date-fns';
import { z } from 'zod';

import { createMapper } from '@terminal/endpoints';
import type { TestingListDTO } from '@terminal/endpoints/clusters/lk/client-testing/status';

const testScheme = z.object({
  testId: z.number(),
  testName: z.string(),
  completed: z.boolean(),
});

const testingListScheme = z.object({
  testStatusItems: z.array(testScheme),
  candidateQualInverstorStatus: z.boolean(),
  qualifiedInvestorDate: z.instanceof(Date).nullable(),
  isFirm: z.boolean(),
});

export const mapTestingList = createMapper(
  testingListScheme,
  (data: TestingListDTO) => ({
    testStatusItems: data.testStatusItems
      ? data.testStatusItems.map((item) => ({
          testId: item.testId,
          testName: item.testName,
          completed: Boolean(item.completed),
        }))
      : [],
    candidateQualInverstorStatus: Boolean(data.candidateQualInverstorStatus),
    qualifiedInvestorDate: data.qualifiedInvestorDate
      ? parseISO(data.qualifiedInvestorDate)
      : null,
    isFirm: Boolean(data.isFirm),
  })
);
