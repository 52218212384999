import { useQuery } from 'react-query';

import {
  getFinResultByActId,
  InvestQueryKeys,
} from '@terminal/core/lib/rest/investApi';

import { useWidgetContext } from '../../../shared';
import { FinResultByEmitentResponse } from '../model/FinResultByEmitent';
import { FinResultByEmitentParams } from '../model/FinResultParams';

export const useFinResultByEmitent = (params: FinResultByEmitentParams) => {
  const { addNotification } = useWidgetContext();

  return useQuery<FinResultByEmitentResponse>(
    [InvestQueryKeys.FinResultByEmitent, params],
    () => getFinResultByActId(params),
    {
      onError: (error: any) =>
        addNotification({
          badge: 'negative',
          title: 'Ошибка',
          text: error?.message || 'Произошла ошибка при запросе',
        }),
    }
  );
};
