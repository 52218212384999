import cn from 'classnames';
import sortBy from 'lodash/sortBy';
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DraggableEventHandler } from 'react-draggable';
import { mergeRefs } from 'react-merge-refs';
import {
  RndDragCallback,
  RndResizeCallback,
  RndResizeStartCallback,
} from 'react-rnd';
import { useThrottledCallback } from 'use-debounce';

import { usePrevious } from '@terminal/core/hooks';
import { trackBondScreenerWidget } from '@terminal/core/lib/analytics/bondScreener/bondScreener';
import { shallow, useStore } from '@terminal/core/store';
import { activeConfigurationSelector } from '@terminal/core/store/selectors/workspaceConfigurations/activeConfigurationSelector';
import {
  Bound,
  Colision,
  FlexLayoutModel,
  FlexLayoutTabset,
  FlexLayoutWidget,
  LayoutType,
  Position,
  Size,
  Widget,
} from '@terminal/core/types/layout';

import { ChartSettingsController } from '../../legacy/widgets/chart/core/ChartSettingsController';
import { WidgetTabset } from './ui/WidgetTabset';
import {
  adjustDrag,
  adjustResize,
  detectDragOverlap,
  detectResizeOverlap,
} from './utils';

import styles from './FlexLayout.module.css';

interface LayoutProps {
  activeLayoutKey: number;
}

export const FlexLayout = forwardRef<HTMLDivElement, LayoutProps>(
  (props, ref) => {
    const layoutSizeRef = useRef<HTMLDivElement>(null);
    const { activeLayoutKey } = props;
    const [setJson, unlinkFromGroup] = useStore(
      (state) => [
        state.setLayoutJson,
        state.unlinkFromGroup,
        state.chartDeleteChartSetting,
      ],
      shallow
    );

    const [activeTabsetId, setActiveTabsetId] = useState('');
    const bounds = useRef<Bound[]>([]);

    const topColision = useRef<HTMLDivElement>(null);
    const rightColision = useRef<HTMLDivElement>(null);
    const bottomColision = useRef<HTMLDivElement>(null);
    const leftColision = useRef<HTMLDivElement>(null);

    const { lastAutoSavedConfig } = useStore(activeConfigurationSelector);
    const { layouts } = lastAutoSavedConfig;

    const model = useMemo(
      () => layouts[activeLayoutKey] as FlexLayoutModel,
      [layouts, activeLayoutKey]
    );
    const layout = useMemo(() => model.layout, [model.layout]);

    const prevTabsetLength = usePrevious(layout.length);

    useEffect(() => {
      if (prevTabsetLength && prevTabsetLength < layout.length) {
        setActiveTabsetId(layout[layout.length - 1].id);
      }
    }, [layout, prevTabsetLength]);

    const updateLayoutModel = useCallback(
      (layout: FlexLayoutTabset[]) => {
        setJson<LayoutType.Flex>(activeLayoutKey, {
          ...model,
          layout,
        });
      },
      [activeLayoutKey, model, setJson]
    );

    const onDeleteWidget = useCallback(
      (tabsetId: string, widget: FlexLayoutWidget) => {
        const { link, component, nodeId } = widget;

        if (link) {
          unlinkFromGroup(nodeId, link);
        }

        if (component === Widget.CHART) {
          ChartSettingsController.deleteChartStoredSettings(nodeId);
        }

        if (component === Widget.BOND_SCREENER) {
          trackBondScreenerWidget.close();
        }

        const newLayout = layout.reduce((layout, tabset) => {
          if (tabset.id === tabsetId) {
            return layout;
          } else {
            layout.push(tabset);

            return layout;
          }
        }, [] as FlexLayoutTabset[]);

        updateLayoutModel(newLayout);
      },
      [layout, unlinkFromGroup, updateLayoutModel]
    );

    const updateColisions = useCallback((colisions: Colision) => {
      const { top, right, bottom, left } = colisions;

      if (topColision.current) {
        topColision.current.style.opacity = top !== undefined ? '1' : '0';
        topColision.current.style.transform = `translate(0, ${
          top !== undefined ? top - 1 : top
        }px)`;
        topColision.current.style.zIndex = String(bounds.current.length + 1);
      }

      if (rightColision.current) {
        rightColision.current.style.opacity = right !== undefined ? '1' : '0';
        rightColision.current.style.transform = `translate(${
          right !== undefined ? right - 1 : right
        }px, 0)`;
        rightColision.current.style.zIndex = String(bounds.current.length + 1);
      }

      if (bottomColision.current) {
        bottomColision.current.style.opacity = bottom !== undefined ? '1' : '0';
        bottomColision.current.style.transform = `translate(0, ${
          bottom !== undefined ? bottom - 1 : bottom
        }px)`;
        bottomColision.current.style.zIndex = String(bounds.current.length + 1);
      }

      if (leftColision.current) {
        leftColision.current.style.opacity = left !== undefined ? '1' : '0';
        leftColision.current.style.transform = `translate(${
          left !== undefined ? left - 1 : left
        }px, 0)`;
        leftColision.current.style.zIndex = String(bounds.current.length + 1);
      }
    }, []);

    const expandLayout = useCallback(() => {
      if (layoutSizeRef && layoutSizeRef.current) {
        // const { height, width } = layoutSizeRef.current.getBoundingClientRect();
        // layoutSizeRef.current.style.width = `${width * 2}px`;
        // layoutSizeRef.current.style.height = `${height * 2}px`;
      }
    }, []);

    const collectBounds = useCallback(
      (tabsetId: string) => {
        const boundRects = layout.reduce((acc, tabset) => {
          const {
            id,
            position: { x, y },
            size: { width, height },
          } = tabset;

          if (id === tabsetId) {
            return acc;
          } else {
            acc.push({ xLine: x, yLine: y });
            acc.push({ xLine: x + width, yLine: y + height });
          }

          return acc;
        }, [] as { xLine: number; yLine: number }[]);

        bounds.current = boundRects;
      },
      [layout]
    );

    const clearTemporaryCalcs = useCallback(() => {
      bounds.current = [];
      updateColisions({});
    }, [updateColisions]);

    const adjustTabset = useCallback(
      (position: Position, size: Size, tabsetId: string) => {
        const newLayout = layout.map((tabset) =>
          tabset.id === tabsetId
            ? {
                ...tabset,
                position,
                size,
              }
            : tabset
        );

        updateLayoutModel(newLayout);

        // if (layoutSizeRef && layoutSizeRef.current) {
        //   layoutSizeRef.current.style.width = '';
        //   layoutSizeRef.current.style.height = '';
        // }
        clearTemporaryCalcs();
      },
      [clearTemporaryCalcs, layout, updateLayoutModel]
    );

    const makeSortedLayout = useCallback(
      (layout: FlexLayoutTabset[], id: string, pinned?: boolean) => {
        const newWeigthMap = layout.reduce((acc, { id }, index) => {
          acc[id] = index;

          return acc;
        }, {} as { string: number });

        const actualLayout = activeConfigurationSelector(useStore.getState())
          .lastAutoSavedConfig.layouts[activeLayoutKey]
          .layout as FlexLayoutTabset[];

        const newLayout = actualLayout.map((tabset) => ({
          ...tabset,
          weight: newWeigthMap[tabset.id] + 1,
          pinned: tabset.id === id ? pinned ?? tabset.pinned : tabset.pinned,
        }));

        return newLayout;
      },
      [activeLayoutKey]
    );

    const onFocusTabset = useCallback(
      (id: string) => {
        if (id === activeTabsetId) {
          return;
        }

        setActiveTabsetId(id);

        // Сортируем по индексам, но элемент с совпадающим айди будет самым "весовым" (добавится в конец)
        const sortedLayout = sortBy(layout, [
          ({ pinned }) => Boolean(pinned),
          (tabset) => tabset.id === id,
          'weight',
        ]);

        updateLayoutModel(makeSortedLayout(sortedLayout, id));
      },
      [activeTabsetId, layout, makeSortedLayout, updateLayoutModel]
    );

    const onResizeStart: RndResizeStartCallback = useCallback(
      (e, dir, ref) => {
        onFocusTabset(ref.id);
        expandLayout();
        collectBounds(ref.id);
      },
      [collectBounds, expandLayout, onFocusTabset]
    );

    const onResize: RndResizeCallback = useCallback(
      (e, dir, ref, delta, position) => {
        const size: Size = {
          width: Math.round(ref.offsetWidth),
          height: Math.round(ref.offsetHeight),
        };
        const colisions = detectResizeOverlap(bounds.current, position, size);

        updateColisions(colisions ?? {});
      },
      [updateColisions]
    );

    const onResizeThrottled = useThrottledCallback(onResize, 100);

    const onResizeStop: RndResizeCallback = useCallback(
      (e, dir, ref, delta, inputPosition) => {
        const inputSize: Size = {
          width: Math.round(ref.offsetWidth),
          height: Math.round(ref.offsetHeight),
        };

        const colisions = detectResizeOverlap(
          bounds.current,
          inputPosition,
          inputSize
        );

        const { size, position } = adjustResize(
          inputPosition,
          inputSize,
          dir,
          colisions
        );

        adjustTabset(position, size, ref.id);
      },
      [adjustTabset]
    );

    const onDragStart: DraggableEventHandler = useCallback(
      (e, data) => {
        expandLayout();
        collectBounds(data.node.id);
      },
      [collectBounds, expandLayout]
    );

    const onDrag: DraggableEventHandler = useCallback(
      (e, data) => {
        const position: Position = {
          x: data.x,
          y: data.y,
        };
        const size: Size = {
          width: data.node.offsetWidth,
          height: data.node.offsetHeight,
        };
        const colisions = detectDragOverlap(bounds.current, position, size);

        updateColisions(colisions ?? {});
      },
      [updateColisions]
    );

    const onDragThrottled = useThrottledCallback(onDrag, 100);

    const onDragStop: RndDragCallback = useCallback(
      (e, data) => {
        const inputPosition: Position = {
          x: Math.round(data.x),
          y: Math.round(data.y),
        };

        const inputSize: Size = {
          width: data.node.offsetWidth,
          height: data.node.offsetHeight,
        };

        const colisions = detectDragOverlap(
          bounds.current,
          inputPosition,
          inputSize
        );

        const { size, position } = adjustDrag(
          inputPosition,
          inputSize,
          colisions
        );

        adjustTabset(position, size, data.node.id);
      },
      [adjustTabset]
    );

    const onTogglePin = useCallback(
      (id: string, pinned: boolean) => {
        setActiveTabsetId(id);

        let sortedLayout: FlexLayoutTabset[];

        if (pinned) {
          sortedLayout = sortBy(layout, [
            (tabset) => tabset.id === id,
            ({ pinned }) => Boolean(pinned),
            'weight',
          ]);
        } else {
          sortedLayout = sortBy(layout, [
            (tabset) => Boolean(tabset.pinned) && tabset.id !== id,
            (tabset) => tabset.id === id,
            'weight',
          ]);
        }

        updateLayoutModel(makeSortedLayout(sortedLayout, id, pinned));
      },
      [layout, makeSortedLayout, updateLayoutModel]
    );

    return activeLayoutKey !== undefined ? (
      <div className={styles.layout} ref={mergeRefs([ref, layoutSizeRef])}>
        <div
          ref={topColision}
          className={cn(styles.colision, styles.topColision)}
        />
        <div
          ref={rightColision}
          className={cn(styles.colision, styles.rightColision)}
        />
        <div
          ref={bottomColision}
          className={cn(styles.colision, styles.bottomColision)}
        />
        <div
          ref={leftColision}
          className={cn(styles.colision, styles.leftColision)}
        />
        {layout.map((tabset) => (
          <WidgetTabset
            key={tabset.id}
            activeTabsetId={activeTabsetId}
            onResizeStart={onResizeStart}
            onResize={onResizeThrottled}
            onResizeStop={onResizeStop}
            onDragStart={onDragStart}
            onDrag={onDragThrottled}
            onDragStop={onDragStop}
            onClick={(e, id) => onFocusTabset(id)}
            onCloseNode={onDeleteWidget}
            onTogglePin={onTogglePin}
            {...tabset}
          />
        ))}
      </div>
    ) : null;
  }
);

FlexLayout.displayName = 'FlexLayout';
