import isNil from 'lodash/isNil';

export function minLength(length: number, errorText = 'Используйте кириллицу') {
  return function (str: string) {
    if (isNil(str) || str.length < length) {
      return errorText;
    }

    return '';
  };
}
