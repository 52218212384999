import orderBy from 'lodash/orderBy';
import { useCallback, useMemo, useState } from 'react';

import { Sort } from '@terminal/core/types/layout';
import { TableColumnKey } from '@terminal/core/types/tableColumn';

import { useWidgetContext } from '../index';

interface Props<T> {
  sort?: Sort;
  data: T[];
  setSort?: (sort?: Sort) => void;
  onOrder?: (data: T, key?: string) => unknown;
}

// Хук для сортировки данных в колонке таблицы
// Параметры сортировки могут сохраняться как в настройки таблицы, так и в локальный стейт (например в модалке выбора фин инструмента)
export const useSortTable = <T>({ data, onOrder, ...props }: Props<T>) => {
  const [localSort, setLocalSort] = useState<Sort | undefined>(props.sort);

  const sort = useMemo(
    () => (props.setSort ? props.sort : localSort),
    [localSort, props.setSort, props.sort]
  );
  const setSort = useMemo(
    () => (props.setSort ? props.setSort : setLocalSort),
    [props.setSort]
  );

  const { useSettings } = useWidgetContext();
  const {
    defaultValues: { selectedDisplayInstrumentType },
  } = useSettings();

  const sortKey = useMemo(() => {
    return sort?.key === TableColumnKey.SymbolObject &&
      selectedDisplayInstrumentType === 'name'
      ? TableColumnKey.NameObject
      : sort?.key;
  }, [selectedDisplayInstrumentType, sort?.key]);

  const onSort = useCallback(
    (key: string) => {
      if (sort?.key === key) {
        // Третий клик на сортировку должно сбросить ее
        // https://jira.moscow.alfaintra.net/browse/ADIRWEB-4707
        if (sort.asc === false) {
          setSort(undefined);
        } else {
          setSort({ key, asc: !sort.asc });
        }
      } else {
        setSort({ key, asc: true });
      }
    },
    [setSort, sort?.asc, sort?.key]
  );

  const sortedData = useMemo(() => {
    if (sort) {
      return orderBy(
        data,
        onOrder ? (item) => onOrder(item, sortKey) : sortKey,
        sort.asc ? 'asc' : 'desc'
      );
    } else {
      return data;
    }
  }, [sort, data, onOrder, sortKey]);

  return { sort, onSort, sortedData };
};
