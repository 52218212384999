import log from 'loglevel';

import { setHeartbeatTimeout } from '../services/heartbeatTimeout';
import { encode } from './encode';
import { FrontEndType, HeartbeatEntity } from './entities';
import { EntityType } from './entityTypes';

export class Heartbeat {
  private heartbeatLostTimer: number;
  private cancelHeartbeatTimer?: () => void;
  private heartBeatInterval: number;
  private lostConnectionTimeout: number;
  private frontEndType: FrontEndType;

  private onSend: (data: ArrayBuffer) => void;
  private onFail: () => void;
  private isRunning = false;

  constructor(
    lostConnectionTimeout: number,
    heartBeatInterval: number,
    frontEndType: FrontEndType,
    onFail: () => void
  ) {
    this.lostConnectionTimeout = lostConnectionTimeout;
    this.heartBeatInterval = heartBeatInterval;
    this.frontEndType = frontEndType;

    this.onFail = () => {
      this.isRunning = false;
      onFail();
    };
  }

  cancel() {
    this.isRunning = false;
    clearTimeout(this.heartbeatLostTimer);
    this.cancelHeartbeatTimer?.();
  }

  setSend(onSend: (data: ArrayBufferLike) => void) {
    this.onSend = onSend;
  }

  run() {
    if (this.isRunning) {
      log.debug('Heartbeat for socket ' + this.frontEndType, 'already running');

      return;
    }

    this.isRunning = true;
    this.send();
  }

  handleHeartbeat = () => {
    log.debug('receiveHeartBeat', this.frontEndType);

    this.cancel();

    this.cancelHeartbeatTimer = setHeartbeatTimeout(() => {
      this.send();
    }, this.heartBeatInterval);
  };

  private send() {
    this.heartbeatLostTimer = window.setTimeout(() => {
      try {
        this.onFail();
      } catch {}
    }, this.lostConnectionTimeout);

    const heartbeat = new HeartbeatEntity();

    heartbeat.SendTime = new Date();

    log.debug(`sending heartbeat to ${this.frontEndType}`);

    try {
      this.onSend(encode(heartbeat, EntityType.HeartbeatEntity));
    } catch (err) {
      log.error('Ошибка при отправке heartbeat fe', this.frontEndType, err);

      try {
        this.onFail();
      } catch (err) {
        log.error('Ошибка при отправке heartbeat fe', this.frontEndType, err);
      }
    }
  }
}
