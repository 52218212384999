import { QueryFunctionContext, useInfiniteQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import {
  getMoneyHistory,
  MoneyHistoryRequest,
} from '@terminal/core/lib/rest/lkMoney';
import { NotificationType } from '@terminal/core/types/ui';

export const useMoneyHistory = (params: MoneyHistoryRequest) => {
  const { page, ...otherParams } = params;

  const addNotification = useNotification();

  const fetchHistory = ({ pageParam = page }: QueryFunctionContext) => {
    return getMoneyHistory({
      ...otherParams,
      page: pageParam,
    });
  };

  return useInfiniteQuery(
    ['lk', 'transfer', 'money', 'history', otherParams],
    fetchHistory,
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.pageNumber < lastPage.pageCount) {
          return lastPage.pageNumber + 1;
        }
      },
      enabled: Boolean(otherParams.treaty),
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text: error?.message || 'Произошла ошибка при запросе истории',
        });
      },
    }
  );
};
