interface props {
  nominalCurrencyPrice: number;
  marketCurrencyPrice: number;
  price: number;
  nominal: number;
  quantity: number;
  isLotCount: boolean;
  lot: number;
  nkd: number;
}

/**
 * Формула для получения количества штук/лотов из суммы облигациях
 * @param options - параметры для формулы:
 * - nominalCurrencyPrice - курс валюты рынка по отношению к рублю
 * - marketCurrencyPrice - курс валюты номинала по отношению к рублю
 * - price - цена облигации. Для заявки STL price  = цена + проскок при покупке / цена - проскок при продаже. Для заявок BSL, BRS, TBRS  price = цене тейк профита, для MKT price = bid или ask в зависимости от направления сделки.
 * - nominal - номинал облигации
 * - quantity - количество лотов
 * - isLotCount - измеряется ли количество в лотах
 * - lot - количество штук в лоте
 * @returns Количество штук/лотов
 * */
export const getQuantityForBonds = (options: props) => {
  const {
    nominalCurrencyPrice,
    marketCurrencyPrice,
    price,
    nominal,
    quantity,
    isLotCount,
    lot,
    nkd,
  } = options;

  // сначала получаем в лотах
  let currentQuantity = Math.floor(
    quantity /
      (nominalCurrencyPrice / marketCurrencyPrice) /
      ((price * nominal) / 100 + nkd) /
      lot
  );

  // после этого переводим из лотов в штуки, если нужно
  currentQuantity = isLotCount ? currentQuantity : currentQuantity * lot;

  // проверка на NaN и Infinity
  return Number.isFinite(currentQuantity) && currentQuantity > 0
    ? currentQuantity
    : 0;
};
