export const enum TableColumnKey {
  // Common
  NameObject = 'object.nameObject', // Наименование
  SymbolObject = 'object.symbolObject', // Тикер
  ShortDescription = 'shortDescription',
  IsSelected = 'isSelected',
  ReleaseNameObject = 'nameObject', // Выпуск. Оставлено для совместимости, чтобы разделять object.nameObject и nameObject
  MarketNameMarketBoard = 'market.nameMarketBoard',
  CodeSubAccount = 'subAccountRazdel.codeSubAccount',
  OrderLogin = 'order.login',
  OrderClientNumEDocument = 'order.clientNumEDocument',
  NameBalanceGroup = 'nameBalanceGroup',
  Yield = 'yield',
  MatDate = 'matDate',

  // Watchlist
  CodeMarketBoard = 'codeMarketBoard',
  ISIN = 'ISIN',
  Open = 'open',
  Close = 'close',
  LastPrice = 'lastPrice',
  Change = 'change',
  VolToday = 'volToday',
  ValToday = 'valToday',
  NameObjectType = 'nameObjectType',
  PriceStep = 'priceStep',
  PriceStepCost = 'priceStepCost',
  Lot = 'lot',
  GtBuy = 'gtBuy',
  PSTNKD = 'PSTNKD',
  NextCoupon = 'nextCoupon',
  CouponRate = 'couponRate',
  CouponValue = 'couponValue',
  BuyBackDate = 'buyBackDate',
  DaysToBuyBack = 'daysToBuyBack',
  DaysToMat = 'daysToMat',
  IdFi = 'idFi',

  // FiModal
  DescObject = 'descObject',
  Currency = 'currency',
  NameMarketBoard = 'nameMarketBoard',
  AvailableMarketBoards = 'availableMarketBoardsColumn',

  // Balance
  IdAccount = 'idAccount', // Счёт
  Balance = 'balance',
  PrevBalance = 'prevBalance',
  DailyPLRur = 'dailyPLRur',
  DailyPLRurPrecent = 'dailyPLRurPrecent',
  NplRur = 'NPLRur',
  NplRurPercent = 'NPLRurPercent',
  LongBalance = 'longBalance',
  ShortBalance = 'shortBalance',
  InitialMargin = 'initialMargin',
  MinimumMargin = 'minimumMargin',
  Requirements = 'requirements',
  ImmediateRequirements = 'immediateRequirements',
  LiquedBalance = 'liquedBalance',
  PortfolioValueWithOrders = 'portfolioValueWithOrders',
  InitialMarginWithOrders = 'initialMarginWithOrders',
  Money = 'money',
  InitialMoney = 'initialMoney',
  NameClient = 'nameClient',

  // AlertsModal
  TargetPrice = 'TargetPrice',
  Caption = 'Caption',
  IdState = 'IdState',

  // Orders
  Direction = 'direction',
  AcceptTime = 'acceptTime',
  AcceptDate = 'acceptDate',
  Quantity = 'order.quantity',
  Executed = 'executed',
  Rest = 'rest',
  DisplayPrice = 'displayPrice',
  Type = 'type',
  DisplayStatus = 'displayStatus',
  WithdrawTime = 'withdrawTime',
  LifeTime = 'lifeTime',
  OrderNumEDocument = 'order.numEDocument',
  StopPrice = 'order.stopPrice',
  LimitPrice = 'order.limitPrice',

  // Operations
  OperationTime = 'operation.timeOperation',
  OperationDate = 'operation.dateOperation',
  OperationBuySell = 'operation.buySell',
  OperationQuantity = 'operation.quantity',
  OperationValue = 'operation.value',
  OperationCurrency = 'currency.symbolObject',
  OperationStatus = 'operation.idOperationStatus',
  OperationAccount = 'operation.idAccount',
  OperationNumEDocument = 'operation.numEDocument',
  OrderComment = 'order.comment',
  OperationPrice = 'operation.price',
  OperationYield = 'operation.yield',
  OperationAccruedInt = 'operation.accruedInt',
  OperationSettleDate = 'operation.settleDate',
  OperationDeliveryDate = 'operation.deliveryDate',
  OperationTypeId = 'operation.idOperationType',

  // OperationsHistory
  OperationIsSettled = 'isSettled',

  // Positions
  PositionNameType = 'subAccountRazdel.nameRazdelType',
  PositionTorgPos = 'torgPos',
  PositionConvertedPrice = 'uchPriceConvertedToLiquidMarketCur',
  PositionLiquidCost = 'liquedTorgPosCostRur',
  PositionPrice = 'price',
  PositionNplPrice = 'NPLtoMarketCurPrice',
  PositionNplPercent = 'NPLPercent',
  PositionDailyPrice = 'dailyPLtoMarketCurPrice',
  PositionDailyPercent = 'dailyPLPrecenttoMarketCurPrice',
  PositionAssetsPart = 'assetsPart',
  PositionInPrice = 'inPrice',
  PositionCurSymbol = 'marketCurSymbol',
  PositionPriceSymbol = 'marketPriceSymbol',
  PositionVariationMargin = 'variationMargin',
  PositionDeposits = 'position.trnIn',
  PositionBackPos = 'backPos',
  PositionBackPosCost = 'backPosCost',
  PositionWithdrawal = 'position.trnOut',
  PositionIsMoney = 'isMoney',
  PositionSessionBuyQty = 'position.sessionBuyQty',
  PositionSessionBuyVal = 'position.sessionBuyVal',
  PositionPSTNKD = 'finInfoExt.PSTNKD',
  PositionNKD = 'NKDtoNominalCurPrice',
  PositionLongOrders = 'longOrders',
  PositionPlanLong = 'planLong',
  PositionShortOrders = 'shortOrders',
  PositionPlanShort = 'planShort',
  PositionSessionVal = 'sessionVal',
  PositionSessionQty = 'sessionQty',
  PositionSessionSellQty = 'position.sessionSellQty',
  PositionSessionSellVal = 'position.sessionSellVal',
  PositionTorgPosCost = 'torgPosCost',
  PositionSubAccNalPos = 'position.subAccNalPos',
  PositionTorgPosUchCost = 'torgPosUchCost',
  PositionGO = 'go',

  // TradesFeed
  TradePrice = 'Price',
  TradeQty = 'Qty',
  TradeBuySell = 'BuySell',
  TradeTime = 'TradeTime',

  // BondScreener Bs - Сокращение для BondScreener
  BsName = 'name',
  BsUntilExpiration = 'untilExpiration',
  BsPrice = 'price',
  BsYield = 'yield',
  BsCupYield = 'cupYield',
  BsAgencyRating = 'agencyRating',
  BsCupSize = 'cupSize',
  BsCouponPaymentPerYear = 'couponPaymentPerYear',
  BsSectorName = 'sectorName',
  BsISIN = 'isin',
  BsEmShortName = 'emShortName',
  BsCountryName = 'countryName',
  BsNominal = 'nominal',
  BsCurrency = 'currency',
  BsYieldToOffert = 'yieldToOffert',
  BsIsFloatingRate = 'isFloatingRate',
  BsCupDate = 'cupDate',
  BsDuration = 'duration',
  BsMatDate = 'matDate',
  BsAmortization = 'amortization',
  BsIsOffert = 'isOffert',
  BsOffertType = 'offertType',
  BsOffertDate = 'offertDate',
  BsNkd = 'nkd',
  BsBondTypeName = 'bondTypeName',
  BsIsReplacement = 'isReplacement',
  BsIsMortgageBonds = 'isMortgageBonds',
  BsIsRestructing = 'isRestructing',
  BsRestructingDate = 'restructingDate',
  BsIsStructuredNote = 'isStructuredNote',
  BsAllowNonQualInvest = 'allowNonQualInvest',
  BsOffertPrice = 'offertPrice',
  BsIsSubordinatedDebt = 'isSubordinatedDebt',
}
