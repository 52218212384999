import { SelectDesktop as Select } from '@alfalab/core-components/select/desktop';
import { TooltipDesktop as Tooltip } from '@alfalab/core-components/tooltip/desktop';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';

import { getColumnDescription } from '@terminal/core/lib/helpers/getColumnDescription';
import { getColumnName } from '@terminal/core/lib/helpers/getColumnName';
import { Widget } from '@terminal/core/types/layout';
import { TableColumnKey } from '@terminal/core/types/tableColumn';

import { ColumnSettingSelectProps } from '../../types';

import styles from './ColumnSettingSelect.module.css';

export const ColumnSettingSelect = (props: ColumnSettingSelectProps) => {
  const {
    columnsSettingOptions,
    column,
    flatItems,
    handleSelection,
    widgetType,
  } = props;

  const isLockedColumn =
    column?.key === TableColumnKey.SymbolObject ||
    column?.key === TableColumnKey.NameObject ||
    column?.key === TableColumnKey.ShortDescription ||
    (widgetType === Widget.BALANCE &&
      column?.key === TableColumnKey.IdAccount) ||
    (widgetType === Widget.BALANCE &&
      column?.key === TableColumnKey.NameBalanceGroup);

  return (
    <Select
      allowUnselect
      block
      size="s"
      placeholder="Не выбрано"
      disabled={isLockedColumn}
      className={styles.select}
      selected={column?.key}
      valueRenderer={({ selected }) => {
        if (selected?.value) {
          return getColumnName(selected.value);
        }
      }}
      onChange={({ selected }) => {
        handleSelection(selected?.value);
      }}
      options={columnsSettingOptions.map((columnsSettingOption) => {
        const isSelected = flatItems.some(
          (item) => item.key === columnsSettingOption.key
        );
        const isCurrentColumn = columnsSettingOption.key === column?.key;
        const columnDescription = getColumnDescription(columnsSettingOption);

        return {
          key: columnsSettingOption.key,
          value: columnsSettingOption,
          disabled: isSelected && !isCurrentColumn,
          content: (
            <div className={styles.settingNameWrapper}>
              {getColumnName(columnsSettingOption)}
              {columnDescription && (
                <Tooltip
                  contentClassName={styles.tooltipContent}
                  content={columnDescription}
                  trigger="hover"
                  position="top-start"
                  offset={[-20, 16]}
                >
                  <InformationCircleLineSIcon
                    height={12}
                    width={12}
                    color="var(--color-dark-graphic-secondary)"
                  />
                </Tooltip>
              )}
            </div>
          ),
        };
      })}
    />
  );
};
