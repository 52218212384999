import { BaseModal } from '@alfalab/core-components/base-modal';
import { PortalProps } from '@alfalab/core-components/portal/Component';

import { WatchList, WatchListItem } from '@terminal/core/types/watchList';

import { useWidgetContext } from '../../../../shared';
import { AddWatchlist, WatchlistModalType } from '../AddWatchlist';

import styles from './AddWatchlistModal.module.css';

interface AddWatchlistModalProps {
  isOpen: boolean;
  setIsOpen: (boolean) => void;
  instrumentsToAdd: WatchListItem[] | undefined;
  setInstrumentToAdd: (item?: any) => void;
  container: PortalProps['getPortalContainer'];
  type: WatchlistModalType;
  selectedList?: WatchList;
  nodeId: string | undefined;
  onWatchlistCreated?: (name: string, id?: string) => void;
}

export const AddWatchlistModal = (props: AddWatchlistModalProps) => {
  const {
    isOpen,
    setIsOpen,
    container,
    instrumentsToAdd,
    setInstrumentToAdd,
    selectedList,
    nodeId,
    type,
    onWatchlistCreated = () => {},
  } = props;

  const { createWatchList, editWatchList, deleteWatchList } =
    useWidgetContext();

  const handleSubmitWatchList = (name: string) => {
    if (!selectedList && type !== 'create') {
      return;
    }

    switch (type) {
      case 'create': {
        const id =
          nodeId &&
          createWatchList(
            name,
            nodeId,
            instrumentsToAdd ?? [],
            !instrumentsToAdd
          );

        onWatchlistCreated(name, id);

        return id;
      }
      case 'edit':
        return nodeId && editWatchList({ ...selectedList!, name });
      case 'delete':
        return nodeId && deleteWatchList(selectedList!);

      default:
        break;
    }
  };

  return (
    <BaseModal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      wrapperClassName={styles.containerModal}
      container={container}
    >
      <AddWatchlist
        list={selectedList}
        type={type}
        onClose={() => {
          setIsOpen(false);
          setInstrumentToAdd(undefined);
        }}
        onSubmit={(name) => handleSubmitWatchList(name)}
      />
    </BaseModal>
  );
};
