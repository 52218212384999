import { Widget, WidgetLinkProps } from '@terminal/core/types/layout';

import { WIDGET_SUB_NAME_MAP } from '../const/widgetSubNameMap';

export function useWidgetSubName(
  widget: Widget,
  config: null | WidgetLinkProps
) {
  return WIDGET_SUB_NAME_MAP[widget]?.(config) || '';
}
