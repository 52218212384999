import { fetchLkResult, LKResult } from '@terminal/core/lib/rest/lkApi';

import { InitObject } from '../models/types/InitObject';

export const getInit = async () => {
  const res = await fetchLkResult<InitObject | LKResult>(`/api/common/init`);

  if ('code' in res) {
    throw new Error(res.message || '');
  }

  return res;
};
