import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { WorkspaceConfiguration } from '@terminal/core/types/workspace';

import { WorkspaceConfigurationScreen } from '../types';

type WorkspaceConfigurationStore = {
  cloudSelectedConfiguration: WorkspaceConfiguration | null;
  screen: WorkspaceConfigurationScreen;
};

type WorkspaceConfigurationStoreActions = {
  setCloudSelectedConfiguration: (
    config: WorkspaceConfiguration | null
  ) => void;
  setScreen: (screen: WorkspaceConfigurationScreen) => void;
  reset: () => void;
};

const initialState: WorkspaceConfigurationStore = {
  cloudSelectedConfiguration: null,
  screen: WorkspaceConfigurationScreen.List,
};

export const useWorkspaceConfigurationStore = createWithEqualityFn<
  WorkspaceConfigurationStore & WorkspaceConfigurationStoreActions
>()(
  (set) => ({
    ...initialState,
    setCloudSelectedConfiguration: (selectedConfiguration) =>
      set({ cloudSelectedConfiguration: selectedConfiguration }),
    setScreen: (screen) => set({ screen }),
    reset: () => set(initialState),
  }),
  shallow
);
