import { RefObject, useEffect, useRef } from 'react';

type UseObserverParams = {
  ref: RefObject<HTMLElement>;
  callback: () => void;
  canCb: boolean;
};

export const useObserver = ({ ref, callback, canCb }: UseObserverParams) => {
  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (observer.current) {
      observer.current.disconnect();
    }

    const cb: IntersectionObserverCallback = (entries) => {
      if (canCb && entries[0].isIntersecting) {
        callback();
      }
    };

    observer.current = new IntersectionObserver(cb);

    if (ref.current) {
      observer.current.observe(ref.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [callback, ref, canCb]);
};
