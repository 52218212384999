import * as z from 'zod';

import { WorkspaceConfigurationsZod_V1 } from './version1';

import { FlexLayoutModel, GridLayoutModel } from '../../../../types/layout';

/**
 * Пример создания новой версии схемы
 * */
//@ts-expect-error
export class WorkspaceConfigurationsZod_V2 extends WorkspaceConfigurationsZod_V1 {
  /**
   * Схема конфигурации рабочего стола
   * */
  static override config = super.config.merge(
    z.object({
      layouts: z.array(z.custom<GridLayoutModel | FlexLayoutModel>()),
      layoutWindows: z.array(z.boolean()).optional(),
    })
  );

  /**
   * Схема хранения конкретной конфигурации
   * */
  static override configuration = super.configuration.merge(
    z.object({
      lastAutoSavedConfig: this.config,
      lastManualSavedConfig: this.config,
    })
  );

  /**
   * Схема хранения списка конфигураций
   * */
  static override configurations = super.configurations.merge(
    z.object({
      workspaceConfigurations: z.array(this.configuration),
    })
  );
}
