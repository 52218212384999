import inRange from 'lodash/inRange';

import { RootStore } from '../../useStore';

/**
 * Выбирает текущую модель из активной конфигурации
 * */
export function activeConfigurationModelSelector(store: RootStore) {
  const {
    workspaceConfigurationActiveId: index,
    workspaceConfigurations,
    getActiveLayoutKey,
  } = store;

  let config = workspaceConfigurations[0];

  if (inRange(index, 0, workspaceConfigurations.length)) {
    config = workspaceConfigurations[index];
  }

  const model = config.lastAutoSavedConfig.models[getActiveLayoutKey() ?? 0];

  return model;
}
