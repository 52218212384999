import cn from 'classnames';
import { FC } from 'react';
import { Loader } from '@alfalab/core-components/loader';
import { Typography } from '@alfalab/core-components/typography';

import { AttorneyTableRow } from './AttorneyTableRow';

import { AttorneyTableItem } from '../../model/types/AttorneyTableItem';

import styles from './AttorneyTable.module.css';

export interface AttorneyTableProps {
  items: AttorneyTableItem[];
  onReject: (attorneyId?: number) => Promise<void> | void;
  onDownload?: (attorneyId?: number) => Promise<void> | void;
  loading?: boolean;
  showAccount?: boolean;
  highlightExpirationDate?: boolean;
}

export const AttorneyTable: FC<AttorneyTableProps> = ({
  items,
  loading,
  showAccount,
  onReject,
  onDownload,
  highlightExpirationDate,
}) => (
  <div className={styles.tableWrapper}>
    <table className={styles.table}>
      <thead className={styles.header}>
        <tr className={styles.row}>
          <th className={styles.cell}>
            <Typography.Text view="secondary-small">
              Уполномоченное лицо / Счёт
            </Typography.Text>
          </th>
          <th className={styles.cell}>
            <Typography.Text view="secondary-small">
              Доступ / Срок
            </Typography.Text>
          </th>
          <th className={cn(styles.cell, styles.actionCell)}>
            <Typography.Text view="secondary-small">Действия</Typography.Text>
          </th>
        </tr>
      </thead>
      <tbody className={styles.body}>
        {loading && (
          <tr className={styles.row}>
            <td className={styles.cell}>
              <Loader />
            </td>
          </tr>
        )}
        {!loading &&
          items.map((attorney) => (
            <AttorneyTableRow
              highlightExpirationDate={highlightExpirationDate}
              key={attorney.key}
              attorney={attorney}
              showAccount={showAccount}
              onReject={
                attorney.canReject ? () => onReject(attorney.id) : undefined
              }
              onDownload={
                attorney.canDownload && onDownload
                  ? () => onDownload(attorney.id)
                  : undefined
              }
            />
          ))}
      </tbody>
    </table>
  </div>
);
