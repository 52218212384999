import { FC } from 'react';
import { Typography } from '@alfalab/core-components/typography';

import { AccessLevelInfo } from '../../../../entities/AccountsOperations';

import styles from './AccessLevels.module.css';

interface AccessLevelsProps {
  accessLevels: AccessLevelInfo[];
}

export const AccessLevels: FC<AccessLevelsProps> = ({ accessLevels }) => (
  <>
    <Typography.Text
      view="primary-small"
      weight="bold"
      tag="p"
      defaultMargins={false}
      className={styles.title}
    >
      Уровни доступа
    </Typography.Text>

    {accessLevels.map((level) => (
      <div key={level.id} className={styles.section}>
        <Typography.Text
          view="secondary-small"
          weight="bold"
          className={styles.sectionTitle}
        >
          {level.name}
        </Typography.Text>
        <Typography.Text view="secondary-small" className={styles.sectionText}>
          {level.permissions.map((permission, index) => (
            <span key={index}>
              — {permission}
              {index < level.permissions.length - 1 ? ';' : '.'}
              {index < level.permissions.length - 1 && <br />}
            </span>
          ))}
        </Typography.Text>
      </div>
    ))}
  </>
);
