import { z } from 'zod';

import { setFeatureFlags as createSetFeatureFlags } from '@terminal/common/entities/RemoteConfig';
import { FeatureFlags } from '@terminal/core/lib/featureFlags';
import { USE_PASSPORT } from '@terminal/core/lib/rest/lkApi';
import { FeatureFlagName } from '@terminal/widgets';

const scheme = z.object({
  /**
   * Включает логирование количества рендеров в PRO
   * */
  PRO_RENDER_COUNT_PROFILER_ENABLED: z.coerce.boolean(),
  /**
   * Управляет наличием кнопки вызова аналитики порфеля из контекстного меню и отдельной кнопки в виджете
   * */
  [FeatureFlagName.PORTFOLIO_ANALYTICS_ENABLED]: z.coerce.boolean(),
  /**
   * Управляет наличием кнопки вызова пополнения счета из контекстного меню и отдельной кнопки в виджете
   * */
  [FeatureFlagName.TOPUP_FROM_WIDGET_ENABLED]: z.coerce.boolean(),
  /**
   * Используется ли паспортная авторизация
   * */
  [FeatureFlagName.USE_PASSPORT_ENABLED]: z.coerce.boolean(),
});

const defaultFeatureFlags: z.infer<typeof scheme> = {
  PRO_RENDER_COUNT_PROFILER_ENABLED: false,
  [FeatureFlagName.PORTFOLIO_ANALYTICS_ENABLED]: true,
  [FeatureFlagName.TOPUP_FROM_WIDGET_ENABLED]: true,
  [FeatureFlagName.USE_PASSPORT_ENABLED]: USE_PASSPORT,
};

export const featureFlags = new FeatureFlags(scheme, defaultFeatureFlags, {
  setterName: '__setProFeatureFlags',
});

export const setFeatureFlags = createSetFeatureFlags(featureFlags);
