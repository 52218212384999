import noop from 'lodash/noop';
import { GetState } from 'zustand';

import {
  ENABLE_LK_FEATURES,
  IS_AI,
  IS_DEVELOPMENT,
  PASSPORT_CLIENT_ID,
  PASSPORT_URI,
  USE_PASSPORT_AUTH,
} from '../../env';
import { trackApplicationError } from '../../lib/analytics';
import { alfaDirectClient } from '../../lib/client/client';
import { FrontEndType, Messages } from '../../lib/client/entities';
import { getRefreshToken } from '../../lib/rest/investApi';
import {
  authorize,
  authorize2fa,
  LKLoginResponse,
  passportProxyLogout,
} from '../../lib/rest/lkAuth';
import { AuthService } from '../../lib/services/auth';
import { localStorage } from '../../lib/storages';
import { doNoCORSFetch } from '../../utils/dom/doNoCORSFetch';
import { RootStore, StoreSet } from '../useStore';
import { SingletonModalType } from './certificatesSlice';

import { AdirApiEvent } from '../../lib/client/types';
import { AppState } from '../../types/app';
import { CertificateEnrollStep } from '../../types/certificate';
import { ConnectionStatus } from '../../types/connection';

// сохраянем в переменную чтобы она не торчала в сторе
// это нужно чтоб если мы прологинились в ЛК, потом отослать логопасс в торговые сервисы
// в конце процедуры переменные очищаются
let userLogin = '';
let userPassword = '';

// В этом слайсе стора живет глобальное состояние приложения
// И экшоны ответсвенные за логин и логаут

// Флоу логина устроены так
// Логопасс простой: loginWithPassword -> loginWithADPassword -> AuthService.connect -> loginComplete
// Логопасс + ЛК: loginWithPassword -> loginLK(отправка логопаспа в ЛК) -> loginLkResolve -> loginLK2fa(отправка кода СМСки) -> loginfLKResolve(обработка ответа ЛК) -> loginWithADPassword -> AuthService.connect -> loginComplete
// Пасспорт: loginWithPassport -> AuthService.connectPassport -> loginComplete
// При перемещении шагов логина изменяется appState значение и тем самым рисует те или иные компоненты интерфейса

// Изначально при логине в ЛК мы логинились сначала в торговых сервисах, потом уже в ЛК
// Выяснилось что подход не совсем рабочий для мобилки в том плане что пользователь может альттабнуться, потерять коннект к сокету
// И плюс тебе после логина надо сразу слать хартбиты и если чувак альттабнулся, то реконект произойдет до 5 секунд и пока реконекта нет он может прологиниться и вообще все сломается
// Короче надежнее было сначало логинить в ЛК потом в сервисы с постоянным коннектом

const SESSION_STORAGE_KEY = 'authPhone';

const PIN_CODE_ENABLED = !IS_DEVELOPMENT;

export interface AppSlice {
  appState: AppState;
  setAppState: (state: AppState) => void;
  loginWithPassword: (
    username: string,
    password: string,
    hasCode?: boolean
  ) => void;
  loginWithADPassword: (
    username: string,
    password: string,
    keepLoginForm?: boolean
  ) => Promise<void>;
  loginWithPassport: (
    code: string,
    withCerberus?: boolean,
    hasPinCode?: boolean,
    isPinCodeEnabled?: boolean
  ) => Promise<void>;
  loginWithPassportPWA: (code: string, withCerberus?: boolean) => Promise<void>;
  setConnectionError: (error: string | undefined) => void;
  loginLK: (username: string, password: string) => void;
  loginLK2fa: (code: string) => void;
  loginLK2faNew: () => void;
  loginLKResolve: (res: LKLoginResponse) => void;
  passportLogged: boolean;
  loginComplete: () => void;
  logout: (withPassport?: boolean, redirectTo?: string) => void;
  reset: () => void;
  connectionStatus: ConnectionStatus;
  setConnectionStatus: (status: ConnectionStatus) => void;
  connectionError?: string;
  lkAuthRes?: LKLoginResponse;
  lkAuthError?: any;
  clearAuth: () => void;
  setLkAuthError: (error: string) => void;
  clearLkAuthError: () => void;
  waitingOpeningBrokerageAccount: boolean;
  setWaitingOpeningBrokerageAccount: (param: boolean) => void;
  logoutWithCerberus: () => void;
  checkPinCodeAvailability: (
    hasPinCode?: boolean,
    isPinCodeEnabled?: boolean
  ) => void;
}

interface SocketErrorParams {
  /* Флаг указывающий на то что ошибка затрагивает все приложение */
  blockedReconnection?: boolean;
}

export const createAppSlice = (
  set: StoreSet,
  get: GetState<RootStore>
): AppSlice => {
  window.addEventListener('offline', () => {
    get().setConnectionStatus(ConnectionStatus.OFFLINE);
  });
  alfaDirectClient.addListener(AdirApiEvent.SOCKET_CLOSE, () => {
    if (get().connectionStatus !== ConnectionStatus.OFFLINE) {
      get().setConnectionStatus(ConnectionStatus.CONNECTING);
    }
  });
  alfaDirectClient.addListener(
    AdirApiEvent.SOCKET_READY,
    (data: { frontEndType: FrontEndType }) => {
      if (data.frontEndType === FrontEndType.AuthAndOperInitServer) {
        get().initCore();
      }
    }
  );
  alfaDirectClient.addListener(AdirApiEvent.SOCKETS_READY, () => {
    get().setConnectionError(undefined);
    get().setConnectionStatus(ConnectionStatus.CONNECTED);
  });
  alfaDirectClient.addListener(
    AdirApiEvent.SOCKET_ERROR,
    (error?: string, options?: SocketErrorParams) => {
      if (options?.blockedReconnection) {
        get().setConnectionError(error);
      }
    }
  );

  return {
    connectionStatus: ConnectionStatus.CONNECTING,
    appState: AppState.NOT_READY,
    setAppState: (clientState: AppState) =>
      set((state) => {
        state.appState = clientState;
      }),
    setConnectionStatus: (status: ConnectionStatus) =>
      set((state) => {
        state.connectionStatus = status;
      }),
    /**
     *  Объединенные метод логина по логину паролю. В зависимости от настроек в .env
     * или сразу пойдет в торговые сервисы или сначала залогиниться в ЛК и в случае успеха уже
     * прологинит в сервисы.
     */
    loginWithPassword: (
      username: string,
      password: string,
      hasCode: boolean = false
    ) => {
      get().reset();

      if (PIN_CODE_ENABLED && hasCode) {
        userLogin = username;
        userPassword = password;
        get().clearLkAuthError();
        get().setAppState(AppState.AUTHORIZING_ENTER_PIN_CODE);
      } else if (ENABLE_LK_FEATURES) {
        get().loginLK(username, password);
      } else {
        get().loginWithADPassword(username, password);
      }
    },
    loginWithADPassword: async (
      username: string,
      password: string,
      keepLoginForm = true
    ) => {
      set((state) => {
        state.connectionError = undefined;
        state.connectionStatus = ConnectionStatus.CONNECTING;
        state.lkAuthError = undefined;

        if (keepLoginForm) {
          state.appState = AppState.AUTHORIZING_FRONTENDS;
        } else {
          state.appState = AppState.LOADING_DICTIONARY;
        }
      });

      try {
        const userCredentials = await AuthService.connect(username, password);

        get().setCredentials(userCredentials);
        get().loginComplete();
      } catch (err) {
        set((state) => {
          state.connectionError =
            (err as string) || 'Произошла ошибка при попытке авторизации';
          state.connectionStatus = ConnectionStatus.ERROR;
          state.appState = AppState.NOT_READY;
        });
      } finally {
        userLogin = '';
        userPassword = '';
      }
    },
    loginWithPassport: async (
      code: string,
      withCerberus = false,
      hasPinCode = false,
      isPinCodeEnabled = PIN_CODE_ENABLED
    ) => {
      set((state) => {
        state.connectionError = undefined;
        state.connectionStatus = ConnectionStatus.CONNECTING;
        state.lkAuthError = undefined;
        state.appState = AppState.AUTHORIZING_PASSPORT;
      });

      try {
        const userCredentials = await AuthService.connectPassport({
          code,
          withCerberus,
        });

        get().setUser(userCredentials);

        if (isPinCodeEnabled && hasPinCode) {
          set((state) => {
            state.appState = AppState.AUTHORIZING_ENTER_PIN_CODE;
          });
        } else if (isPinCodeEnabled && !hasPinCode) {
          set((state) => {
            state.appState = AppState.AUTHORIZING_CREATE_PIN_CODE;
          });
        } else {
          get().loginComplete();
        }
      } catch (err) {
        const stringifiedError = String(err);

        if (
          stringifiedError.includes(
            String(Messages.AuthenticationFailedClientRegistrationInProgress)
          )
        ) {
          get().setWaitingOpeningBrokerageAccount(true);
          set((state) => {
            state.appState = AppState.NOT_READY;
          });
        } else if (
          stringifiedError.includes(
            String(Messages.AuthenticationFailedUnknownCUS)
          )
        ) {
          get().setUserWithoutBrokerageAccount(true);
          set((state) => {
            state.appState = AppState.NOT_READY;
          });
        } else {
          set((state) => {
            state.connectionError = stringifiedError;
            state.connectionStatus = ConnectionStatus.ERROR;
            state.appState = AppState.NOT_READY;
          });
        }
      }
    },
    loginWithPassportPWA: async (code: string, withCerberus = false) => {
      set((state) => {
        state.connectionError = undefined;
        state.connectionStatus = ConnectionStatus.CONNECTING;
        state.lkAuthError = undefined;
        state.appState = AppState.AUTHORIZING_PASSPORT;
      });

      try {
        const userCredentials = await AuthService.connectPassport({
          code,
          withCerberus,
        });

        get().setUser(userCredentials);

        get().loginComplete();
      } catch (err) {
        const stringifiedError = String(err);

        if (
          stringifiedError.includes(
            String(Messages.AuthenticationFailedClientRegistrationInProgress)
          )
        ) {
          get().setWaitingOpeningBrokerageAccount(true);
          set((state) => {
            state.appState = AppState.NOT_READY;
          });
        } else if (
          stringifiedError.includes(
            String(Messages.AuthenticationFailedUnknownCUS)
          )
        ) {
          get().setUserWithoutBrokerageAccount(true);
          set((state) => {
            state.appState = AppState.NOT_READY;
          });
        } else {
          set((state) => {
            state.connectionError = stringifiedError;
            state.connectionStatus = ConnectionStatus.ERROR;
            state.appState = AppState.NOT_READY;
          });
        }
      }
    },
    checkPinCodeAvailability: (
      hasPinCode = false,
      isPinCodeEnabled = PIN_CODE_ENABLED
    ) => {
      if (isPinCodeEnabled && hasPinCode) {
        set((state) => {
          state.appState = AppState.AUTHORIZING_ENTER_PIN_CODE;
        });
      } else if (isPinCodeEnabled && !hasPinCode) {
        set((state) => {
          state.appState = AppState.AUTHORIZING_CREATE_PIN_CODE;
        });
      } else {
        set((state) => {
          state.appState = AppState.AUTHORIZING_PASSPORT;
        });
      }
    },
    loginLK: async (username: string, password: string) => {
      get().setAppState(AppState.AUTHORIZING_LK);
      get().setConnectionStatus(ConnectionStatus.CONNECTING);

      try {
        const res = await authorize(username, password);

        // сервер вернул ошибку при авторизации, например неверный логин пароль
        // Вечная классика: 200 ответ с типом эррор...
        if (res.type === 'error') {
          get().setLkAuthError(res.message || '');
          get().setAppState(AppState.NOT_READY);

          return;
        }

        userLogin = username;
        userPassword = password;
        get().loginLKResolve(res);
        get().setConnectionStatus(ConnectionStatus.CONNECTED);
      } catch (err) {
        get().setConnectionStatus(ConnectionStatus.ERROR);
        get().setAppState(AppState.NOT_READY);
        get().setLkAuthError('Произошла ошибка при попытке авторизации');
      }
    },
    loginLK2fa: async (code: string) => {
      const id = get().lkAuthRes?.data?.id;

      if (id) {
        get().clearLkAuthError();

        try {
          const res = await authorize2fa({ id, code });

          get().loginLKResolve(res);
        } catch (err) {
          get().loginLKResolve({
            type: 'error',
            message: 'При проверке кода произошла ошибка',
          });
        }
      } else {
        get().setAppState(AppState.NOT_READY);
        get().setLkAuthError('Отсутствует id операции. Попробуйте ещё раз');
      }
    },
    loginLK2faNew: () => {
      get().clearLkAuthError();
      get().loginWithADPassword(userLogin, userPassword, false);
    },
    loginLKResolve: (res: LKLoginResponse) => {
      set((state) => {
        state.lkAuthRes = {
          ...res,
          data: res.data || state.lkAuthRes?.data,
        };
      });

      if (res.type === 'success' && PIN_CODE_ENABLED) {
        get().setAppState(AppState.AUTHORIZING_CREATE_PIN_CODE);
      } else if (res.type === 'success') {
        get().loginLK2faNew();
      } else if (res.type === 'error') {
        // Нотификация об ошибке
        get().setLkAuthError(res.message || '');
      } else if (res.type === 'access_denied') {
        // Нотификация об ошибке:
        get().setLkAuthError('Доступ ограничен. Обратитесь к администратору');
      } else if (
        res.type === '2fa' ||
        res.type === '2fa_overlimit' ||
        res.type === '2fa_blocked'
      ) {
        get().setAppState(AppState.AUTHORIZING_2FA);
      } else if (res.type === 'captcha') {
        // Капча
        get().setAppState(AppState.AUTHORIZING_CAPTCHA);
      }
    },
    passportLogged: false,
    loginComplete: () => {
      const userCredentials = get().credentials;

      if (userCredentials) {
        get().setUser(userCredentials);
      }

      get().setAppState(AppState.LOADING_DICTIONARY);
      get().setConnectionStatus(ConnectionStatus.CONNECTED);
      set((state) => {
        state.passportLogged = true;
      });

      if (get().isCoreReady()) {
        get().setAppState(AppState.READY);
      }

      // не отображать вход по номеру телефона после успещного входа
      localStorage.setItem(SESSION_STORAGE_KEY, true);
    },
    reset: () => {
      set((state) => {
        state.appState = AppState.NOT_READY;
        state.certificates = [];
        state.certificatesReady = false;
        state.certificateSMSWindowProps = {
          isVisible: false,
          certificateId: undefined,
        };
        state.certificateEnrollStep = CertificateEnrollStep.Idle;
        state.singletonModalType = SingletonModalType.NONE;
        // eslint-disable-next-line no-restricted-syntax
        state.accounts = [];
        state.selectedAccount = undefined;
        // eslint-disable-next-line no-restricted-syntax
        state.subAccounts = [];
        // eslint-disable-next-line no-restricted-syntax
        state.subGTAccounts = [];
        state.subAccountPositions = [];
        state.orders = [];
        state.operations = [];
        // eslint-disable-next-line no-restricted-syntax
        state.subAccountRazdel = [];
        // eslint-disable-next-line no-restricted-syntax
        state.user = null;
        state.objectsReady = false;
        // eslint-disable-next-line no-restricted-syntax
        state.objects = [];
        state.objectTypesReady = false;
        // eslint-disable-next-line no-restricted-syntax
        state.objectTypes = [];
        state.finInstrumentsReady = false;
        // eslint-disable-next-line no-restricted-syntax
        state.finInstruments = [];
        // eslint-disable-next-line no-restricted-syntax
        state.marketBoards = [];
        state.marketBoardsReady = false;
        state.instrumentIcons = [];
      });
    },
    setConnectionError: (error: string | undefined) => {
      set((state) => {
        state.connectionError = error;
      });
    },
    logout: (withPassport = false, redirectTo?: string) => {
      if (!USE_PASSPORT_AUTH || !withPassport) {
        get().clearAuth();

        return;
      }

      const refreshToken = getRefreshToken();

      if (!refreshToken) {
        return;
      }

      localStorage.removeItem(SESSION_STORAGE_KEY);

      return (
        IS_AI
          ? doNoCORSFetch({
              url: PASSPORT_URI.replace('/authorize', '/logout'),
              method: 'post',
              body: { refresh_token: refreshToken },
              onError: noop,
            })
          : passportProxyLogout(refreshToken)
      ).then(() => {
        if (redirectTo) {
          window.location.assign(redirectTo);
        } else {
          get().clearAuth();
        }
      });
    },

    logoutWithCerberus: () => {
      localStorage.removeItem(SESSION_STORAGE_KEY);
      get().clearAuth();
      window.location.href = `/openid/logout?client_id=${PASSPORT_CLIENT_ID}`;
    },
    clearAuth: () => {
      set((state) => {
        state.appState = AppState.NOT_READY;
      });
      alfaDirectClient.resetConnections();
    },
    setLkAuthError: (error: string) => {
      set((state) => {
        state.lkAuthError = error;
        trackApplicationError(
          error ? `Auth Error: ${error}` : 'Неизвестная ошибка авторизации'
        );
      });
    },
    clearLkAuthError: () => {
      set((state) => {
        state.lkAuthError = undefined;
      });
    },
    waitingOpeningBrokerageAccount: false,
    setWaitingOpeningBrokerageAccount: (param) => {
      set((state) => {
        state.waitingOpeningBrokerageAccount = param;
      });
    },
  };
};
