import { format, parse } from 'date-fns';

export const DEFAULT_VIEW_DATE_FORMAT = 'dd.MM.yyyy';
export const BACKEND_DATE_FORMAT = 'yyyyMMdd';

export function dateToRequestFormat(
  date: string | undefined,
  fromFormat: string = DEFAULT_VIEW_DATE_FORMAT
) {
  if (!date) {
    return '';
  }

  return format(parse(date, fromFormat, new Date()), BACKEND_DATE_FORMAT);
}
