import { useLast } from '@terminal/alfadirect/hooks';
import { useOrderBookService } from '@terminal/core/hooks/useOrderBookService';
import { OrderType } from '@terminal/core/lib/client/entities';
import { TradeLimitsResult } from '@terminal/core/types/tradeLimits';
import { OrderSide } from '@terminal/core/types/trading';

import { usePriceQuickControls } from '../../hooks';

const ASK_BID_DECIMALS = 2;

interface Props {
  idFi: number;
  side?: OrderSide;
  orderType: OrderType;
  priceStep?: number;
  setPrice: (value: number) => void;
  isPriceStreamed: boolean;
  setSecondPrice: (value: number) => void;
  price: number;
  tradeLimits: TradeLimitsResult | null;
  setPriceStreamed: (stream: boolean) => void;
}

export const usePriceInput = ({
  idFi,
  side,
  orderType,
  priceStep,
  price,
  setPrice,
  isPriceStreamed,
  setSecondPrice,
  tradeLimits,
  setPriceStreamed,
}: Props) => {
  const last = useLast(idFi);
  const { bestBid, bestAsk } = useOrderBookService(idFi, ASK_BID_DECIMALS);

  const { limitPriceAdditional, clearPricesAdditional, limPAdSuffix } =
    usePriceQuickControls({
      side,
      price,
      orderType,
      priceStep,
      setPrice,
      isPriceStreamed,
      setSecondPrice,
      last,
      bestAsk: bestAsk?.Price,
      bestBid: bestBid?.Price,
      tradeLimits,
      setPriceStreamed,
    });

  return {
    limitPriceAdditional,
    clearPricesAdditional,
    limPAdSuffix,
  };
};
