import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getMoneyAccountRequisites } from '@terminal/core/lib/rest/lkMoney';
import { NotificationType } from '@terminal/core/types/ui';

export const useMoneyAccountRequisites = () => {
  const addNotification = useNotification();

  return useQuery(['lk', 'requisites', 'get'], getMoneyAccountRequisites, {
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка',
        text: error?.message || 'При запросе счетов произошла ошибка',
      });
    },
  });
};
