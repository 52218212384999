import { fetchLkResult } from '@terminal/core/lib/rest/lkApi';

import { SignCreated } from '../../sign';

import { ChangeOperationModeParams } from './types/ChangeOperationModeParams';

export async function changeOperationMode(params: ChangeOperationModeParams) {
  return await fetchLkResult<SignCreated>(
    '/api/client/accounts/change-operation-mode',
    'POST',
    params
  );
}
