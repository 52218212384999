import { useFilteredSubAccounts } from '@terminal/core/hooks/domain';
import { plural } from '@terminal/core/lib/plural';
import { WidgetLinkProps } from '@terminal/core/types/layout';

type SelectFunction = (config: WidgetLinkProps | null) => string | undefined;

export const useSelectAccount: SelectFunction = (config) => {
  const selectedSubAccounts = config?.tableProps?.accounts;
  const filteredSubAccounts = useFilteredSubAccounts();
  const subAccountsKeys = Object.keys(filteredSubAccounts);

  const isEverySubAccountSelected =
    selectedSubAccounts?.length === subAccountsKeys.length;

  // Если сабсчетов на аккаунте не больше 1 или не выбран ни один счет - ничего не показываем
  if (
    subAccountsKeys.length <= 1 ||
    (selectedSubAccounts && !selectedSubAccounts.length) ||
    // Проверка на аккаунты с другой учетной записи
    (selectedSubAccounts?.length &&
      !selectedSubAccounts?.every((acc) => subAccountsKeys.includes(acc)))
  ) {
    return;
  }

  // Если выбраны все сабсчета - пишем "Все"
  if (!selectedSubAccounts || isEverySubAccountSelected) {
    return 'Все';
  }

  // Если выбрано больше 1, но не все, пишем "n счетов"
  if (selectedSubAccounts.length > 1) {
    return `${selectedSubAccounts.length} ${plural(
      ['счёт', 'счёта', 'счетов'],
      selectedSubAccounts.length
    )}`;
  }

  // Иначе отображаем номер выбранного сабсчета
  if (selectedSubAccounts.length) {
    const selectedSubAccountCode = selectedSubAccounts[0];

    // Нужно понять один ли сабсчет существует в рамках этого счета, если да - можно стереть -000 в конце
    const hasSiblingSubAccounts =
      Object.values(filteredSubAccounts)
        .flat()
        .filter(
          (razdel) =>
            razdel.codeSubAccount !== selectedSubAccountCode &&
            razdel.idAccount ===
              filteredSubAccounts[selectedSubAccountCode][0].idAccount
        ).length > 0;

    return hasSiblingSubAccounts
      ? selectedSubAccountCode
      : selectedSubAccountCode.split('-')[0];
  }
};
