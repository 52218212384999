import { VFC } from 'react';
import { Typography } from '@alfalab/core-components/typography';
import { ChevronDownMIcon } from '@alfalab/icons-glyph/ChevronDownMIcon';

import { useMessagesList } from '@terminal/chat/hooks/useMessagesList';
import { ChatMessage } from '@terminal/chat/types';
import { Spinner } from '@terminal/common/components/Spinner';

import { HistoryError } from '../../../../entities/Chat';
import { MessagesDay } from '../MessagesDay';

import styles from './MessagesList.module.css';

export type MessagesListProps = {
  allLoaded?: boolean;
  loading?: boolean;
  moreError?: boolean;
  readMessages: ChatMessage[];
  unreadMessages?: ChatMessage[];
  loadMore?: () => Promise<void>;
};

export const MessagesList: VFC<MessagesListProps> = (props) => {
  const {
    allLoaded = true,
    loading = false,
    moreError = false,
    readMessages,
    unreadMessages = [],
    loadMore,
  } = props;

  const {
    listRef,
    pageTopRef,
    listBottomRef,
    groupedReadMessages,
    groupedUnreadMessages,
    showDates,
    scrollButtonVisible,
  } = useMessagesList(readMessages, {
    unreadMessages,
    loading,
    allLoaded,
    loadMore,
  });

  return (
    <>
      <div className={styles.list} ref={listRef}>
        <div ref={pageTopRef} className={styles.topBlock} />
        {loading && (
          <div className={styles.spinnerContainer}>
            <Spinner size="36px" color="#8D8D93" />
          </div>
        )}
        {moreError && loadMore && <HistoryError onRetry={loadMore} />}
        {Object.entries(groupedReadMessages).map(([date, groups]) => (
          <MessagesDay
            key={date}
            showDate={showDates}
            groups={groups}
            date={date}
          />
        ))}
        {Object.keys(groupedUnreadMessages).length > 0 && (
          <>
            <div className={styles.divider}>
              <Typography.Text view="secondary-medium" color="secondary">
                Новые сообщения
              </Typography.Text>
            </div>
            {Object.entries(groupedUnreadMessages).map(([date, groups]) => (
              <MessagesDay
                showDate={showDates}
                key={date}
                groups={groups}
                date={date}
              />
            ))}
          </>
        )}
        <div ref={listBottomRef} />
      </div>
      {scrollButtonVisible && (
        <ChevronDownMIcon
          width={24}
          height={24}
          className={styles.scrollButton}
          color="#fff"
          onClick={() =>
            listBottomRef?.current?.scrollIntoView({ behavior: 'smooth' })
          }
        />
      )}
    </>
  );
};
