import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDebouncedCallback } from 'use-debounce';

import { useComputedPositions, usePositions } from '@terminal/alfadirect/hooks';
import { useAlfaDirectContext } from '@terminal/alfadirect/provider/react';
import { OrderType } from '@terminal/core/lib/client/entities';
import { isSingleFORTSMarketAvailable } from '@terminal/core/lib/domain/isSingleFORTSMarketAvailable';
import { LimitRequestParams } from '@terminal/core/types/trading';

import { getLimits } from '../lib';

const LIMIT_REQUEST_WAIT_TIME = 2000;
const LIMIT_REQUEST_REFETCH_TIME = 5000;

export const useLimits = (
  params: LimitRequestParams,
  selectedSubAccountId: number,
  selectedSubAccounts: string[],
  warrantyBuy: number,
  warrantySell: number
) => {
  const { accounts, subGTAccounts, tradingService, checkTradeLimits } =
    useAlfaDirectContext();

  const positions = usePositions();

  const computedPositions = useComputedPositions(
    selectedSubAccounts,
    positions
  );

  const isEFRAccount = isSingleFORTSMarketAvailable(accounts);

  const debouncedGetLimits = useDebouncedCallback(
    getLimits,
    LIMIT_REQUEST_WAIT_TIME,
    {
      leading: true,
      trailing: false,
    }
  );

  const {
    refetch,
    data: {
      tradeLimitsLong = null,
      tradeLimitsShort = null,
      idFi,
      selectedSubAccountId: lastSelectedSubAccountId,
    } = {},
  } = useQuery(
    [
      'useLimitsQuery',
      params,
      selectedSubAccountId,
      subGTAccounts,
      warrantyBuy,
      warrantySell,
      isEFRAccount,
    ],
    () =>
      debouncedGetLimits({
        requestParams: params,
        selectedSubAccountId,
        computedPositions,
        subGTAccounts,
        warrantyBuy,
        warrantySell,
        tradingService,
        isEFRAccount,
        checkTradeLimits,
      }),
    {
      keepPreviousData: true,
    }
  );

  const debouncedRefetch = useDebouncedCallback(
    refetch,
    LIMIT_REQUEST_REFETCH_TIME,
    {
      leading: true,
      trailing: true,
    }
  );

  useEffect(() => {
    debouncedRefetch();
    //Перезапрашиваем информацию если изменились позиции
  }, [
    debouncedRefetch,
    positions,
    warrantySell,
    warrantyBuy,
    selectedSubAccountId,
    selectedSubAccounts,
  ]);

  if (
    (idFi && params.idFi !== idFi) ||
    lastSelectedSubAccountId !== selectedSubAccountId ||
    (params.price === 0 && params.orderType !== OrderType.MKT)
  ) {
    // Если данные ответа не совпадают с запросом или не задана цена, то не возвращаем значение лимитов
    return { tradeLimitsLong: null, tradeLimitsShort: null };
  }

  return { tradeLimitsLong, tradeLimitsShort };
};
