import { isFuture, isToday } from 'date-fns';
import isObject from 'lodash/isObject';
import memoize from 'lodash/memoize';
import orderBy from 'lodash/orderBy';
import take from 'lodash/take';
import { matchSorter } from 'match-sorter';

import { defaultOrderStatusFilters } from '../constants/orders';
import { IS_GI } from '../env';
import { FrontEndType } from '../lib/client/entities';
import { getAvailableMarketBoards } from '../lib/domain/getAvailableMarketBoards';
import { getAvailableObjects } from '../lib/domain/getAvailableObjects';
import { getInstrumentId } from '../lib/domain/getInstrumentId';
import { RootStore } from './useStore';

import {
  InstrumentItem,
  InstrumentItemsTable,
  MarketBoardItem,
  MarketBoardItemsTable,
  ObjectItem,
  ObjectItemsTable,
  ObjectTypeItemsTable,
} from '../types/core';
import { OrderItem } from '../types/order';

export type FILookupResult = ObjectItem & InstrumentItem & MarketBoardItem;

/**
 * Список доступных MarketBoards
 * */
export const marketCodeFilter = [
  'TQxx',
  'TQcny',
  'TQeur',
  'TQusd',
  'EQxx',
  'TQXXT1',
  'CETS_TOD_USD',
  'CETS_TOD',
  'CETS_TOM_USD',
  'CETS_TOM',
  'RTFU',
  'SPFPA',
  'MICI',
  'RTIN',
  'CPCL_TOM',
  'TMS_CETS',
  'SPFPA_E',
  'OTCR', // OTC с расчетами в RUB
  'EUCLR', // Euroclear RUB
  'EUCLU', // Euroclear USD
  'OCBY', // МБ ОТС (ОТС режим Т+2 с расчетами в CNY)
  'CETS_TMS', // Малые лоты
  'SPFPAT1', // СПБ Т+1
  'PMTC', // Размещение внебиржевое НРД
  'PMMC', // Размещение внебиржевое НРД
  'MTQR', // МосБиржа ОТС с ЦК
  'SPFPA_R', // СПБ Биржа RUB
].reduce<Record<string, string>>((acc, next) => {
  acc[next] = next;

  return acc;
}, {});
const GI_MARKET_CODE = 'SPFPA_H';

if (IS_GI) {
  marketCodeFilter[GI_MARKET_CODE] = GI_MARKET_CODE;
}

export const selectMarketBoard = (marketBoards: MarketBoardItemsTable) =>
  getAvailableMarketBoards(marketBoards);

export const selectObjects = (objects: ObjectItemsTable) =>
  getAvailableObjects(objects);

export const isMarketBoardAvailable = (marketBoard: MarketBoardItem) =>
  Boolean(marketCodeFilter[marketBoard.codeMarketBoard]);

export const isObjectAvailable = (object: ObjectItem): boolean =>
  object.expired !== 1 &&
  (isFuture(object.matDateObject) || isToday(object.matDateObject));

// Фукнция для создания hash map фин. инструментов.
// Возвращаемая структура: { idObject: { iFI: ..., idMarketBoard: ... }[] }
// Берем только с разрешенными market board-ами
// TODO Поменять на таблицы, нужно сделать вложенные Map для связей one-to-many
export const buildFIHashMap = memoize(
  (
    finInstruments: InstrumentItemsTable,
    availableMarketBoards: ReturnType<MarketBoardItemsTable['getMutation']>
  ) => {
    const fiMap: Map<number, InstrumentItem[]> = new Map();

    finInstruments.toArray().forEach((instrument) => {
      if (
        availableMarketBoards.get('idMarketBoard', instrument.idMarketBoard)
      ) {
        if (fiMap.has(instrument.idObject)) {
          fiMap.get(instrument.idObject)?.push(instrument);
        } else {
          fiMap.set(instrument.idObject, [instrument]);
        }
      }
    });

    return fiMap;
  }
);

// Функция для вывода всех финансовых инструментов в модалке фин. инструментов.
// Оптимизирована для работы с десятками тысяч объектов
export const getAllFIs = (
  objects: ObjectItemsTable,
  finInstruments: InstrumentItemsTable,
  marketBoards: MarketBoardItemsTable,
  defaultFIsIds: number[]
): FILookupResult[][] => {
  const items: FILookupResult[] = [];
  const availableMarketBoards = marketBoards.getMutation(
    'isMarketBoardAvailable'
  );
  const availableObjects = objects.getMutation('isObjectAvailable');
  const fiHashMap = buildFIHashMap(finInstruments, availableMarketBoards);
  // Здесь сначала id-шники, а потом сами объекты дефолтных FI
  let defaults: Array<number | Record<string, any>> = defaultFIsIds.slice();

  // Для каждого object-а находим его фин. инструменты и добавляем каждый инструмент в итог
  availableObjects.toArray().forEach((object) => {
    const instruments = fiHashMap.get(object.idObject) ?? [];

    if (!instruments.length) {
      return;
    }

    instruments.forEach((fi: InstrumentItem) => {
      const marketBoard = availableMarketBoards.get(
        'idMarketBoard',
        fi.idMarketBoard
      );
      const defaultFIIndex = defaultFIsIds.findIndex(
        (defaultFI) => defaultFI === fi.idFI
      );

      // Если инструмент из дефолтных, записываем данные в массив дефолтных и скипаем запись в items
      if (defaultFIIndex !== -1) {
        defaults[defaultFIIndex] = {
          idFI: fi.idFI,
          ...object,
          ...marketBoard,
        };

        return;
      }

      items.push({
        idFI: fi.idFI,
        ...object,
        ...marketBoard,
      });
    });
  });

  // Оставляем только дефолтные FI, которые удалось найти (не тянем истекшие фьючерсы и т.д.)
  defaults = defaults.filter((fi) => isObject(fi));

  return [defaults as FILookupResult[], items];
};

// Функция для поиска фин. инструментов из автокомплита и лукапа
// Если дан value, ищет от одного символа, иначе возвращает всё
// Оптимизирована для работы с десятками тысяч объектов
export const lookupFininstrument = (
  objects: ObjectItemsTable,
  finInstruments: InstrumentItemsTable,
  marketBoards: MarketBoardItemsTable,
  value: string = '',
  maxResults = 100
): FILookupResult[] => {
  const byValue = Boolean(value);
  const items: FILookupResult[] = [];
  const availableObjects = objects.getMutation('isObjectAvailable');
  const availableMarketBoards = marketBoards.getMutation(
    'isMarketBoardAvailable'
  );
  const fiHashMap = buildFIHashMap(finInstruments, availableMarketBoards);
  // Удаляем пробелы по краям и оставляем только 1 если следуют несколько подряд
  const trimmedValue = value.trim().replace(/ +(?= )/g, '');
  const availableObjectsArray = availableObjects.toArray();
  const lookupResults = byValue
    ? take(
        //Задаем индивидуальные настройки ключам для ранга поиска
        matchSorter(availableObjectsArray, trimmedValue, {
          keys: [
            {
              threshold: matchSorter.rankings.WORD_STARTS_WITH,
              key: 'symbolObject',
            },
            {
              threshold: matchSorter.rankings.CONTAINS,
              key: 'nameObject',
            },
            {
              threshold: matchSorter.rankings.CONTAINS,
              key: 'descObject',
            },
            {
              threshold: matchSorter.rankings.EQUAL,
              key: 'isin',
            },
          ],
          sorter: (rankedItems) =>
            orderBy(
              rankedItems,
              ['rank', 'keyIndex', 'rankedValue'],
              ['desc', 'asc']
            ),
        }),
        maxResults
      )
    : take(availableObjectsArray, maxResults);

  // Для каждого object-а находим его фин. инструменты и добавляем каждый инструмент в итог
  lookupResults.forEach((object) => {
    const instruments = fiHashMap.get(object.idObject);

    if (!instruments?.length) {
      return;
    }

    instruments.forEach((fi: InstrumentItem) => {
      const marketBoard = marketBoards.get('idMarketBoard', fi.idMarketBoard);

      items.push({
        idFI: fi.idFI,
        ...object,
        ...marketBoard,
      });
    });
  });

  return items;
};

//Функция получения типа финансового инструмента
export const selectObjectType = (
  idObject: number,
  objects: ObjectItemsTable,
  objectTypes: ObjectTypeItemsTable
) => {
  const object = objects.get('idObject', idObject);

  if (object) {
    return objectTypes.get('idObjectType', object.idObjectType.value);
  }
};

//Функция получения рынка финансового инструмента
export const selectMarket = (
  idFi: number,
  finInstruments: InstrumentItemsTable,
  marketBoards: MarketBoardItemsTable
) => {
  const instrument = finInstruments.get('idFI', idFi);

  if (instrument) {
    return marketBoards.get('idMarketBoard', instrument.idMarketBoard);
  }
};

//Получаем фин инструмент валюты
export const selectCurrencyFi = (
  idObjectCurrency: number,
  idObjectFaceUnit: number,
  searchType: 'currency' | 'faceunit',
  finInstruments: InstrumentItem[]
): InstrumentItem | undefined => {
  let instrument: InstrumentItem | undefined;

  if (searchType === 'currency') {
    instrument = finInstruments.find(
      (fi) => fi.idObject === idObjectCurrency && fi.idMarketBoard === 114
    );
  } else {
    instrument = finInstruments.find(
      (fi) => fi.idObject === idObjectFaceUnit && fi.idMarketBoard === 114
    );
  }

  return instrument;
};

export const selectIsConnectionError =
  (frontEndType: FrontEndType) => (state: RootStore) => {
    return Boolean(state.connectionError?.[frontEndType]);
  };

export const getActiveOrders = ({
  idFI,
  selectedSubAccountId,
  withWaiting,
  finInstruments,
  orders,
}: {
  idFI: number;
  selectedSubAccountId: number;
  finInstruments: InstrumentItemsTable;
  orders: OrderItem[];
  withWaiting?: boolean;
}) => {
  return orders.filter((order) => {
    const instrumentId = getInstrumentId(
      order.idObject,
      order.idMarketBoard,
      finInstruments
    );

    if (instrumentId !== idFI) {
      return false;
    }

    if (order.idSubAccount !== selectedSubAccountId) {
      return false;
    }

    const isActiveOrderStatus = defaultOrderStatusFilters.active.includes(
      order.idOrderStatus
    );

    const isWaitingOrderStatus =
      withWaiting &&
      defaultOrderStatusFilters.waiting.includes(order.idOrderStatus);

    if (!isActiveOrderStatus && !isWaitingOrderStatus) {
      return false;
    }

    return true;
  });
};

export const selectActiveOrders =
  (idFI: number, selectedSubAccountId: number, withWaiting?: boolean) =>
  (state: {
    orders: OrderItem[];
    finInstrumentsTable: InstrumentItemsTable;
  }) => {
    return getActiveOrders({
      idFI,
      selectedSubAccountId,
      withWaiting,
      orders: state.orders,
      // eslint-disable-next-line no-restricted-syntax
      finInstruments: state.finInstrumentsTable,
    });
  };

export const selectActiveAndWaitingOrders =
  (idFI: number, selectedSubAccountId: number) =>
  (state: {
    finInstrumentsTable: InstrumentItemsTable;
    orders: OrderItem[];
  }) => {
    const clientOrders = state.orders;
    // eslint-disable-next-line no-restricted-syntax
    const finInstruments = state.finInstrumentsTable;

    return clientOrders.filter(
      (order) =>
        getInstrumentId(order.idObject, order.idMarketBoard, finInstruments) ===
          idFI &&
        (defaultOrderStatusFilters.active.includes(order.idOrderStatus) ||
          defaultOrderStatusFilters.waiting.includes(order.idOrderStatus)) &&
        order.idSubAccount === selectedSubAccountId
    );
  };

/**
 * КОСТЫЛЬ! Получение idFI по значениям ISIN инструментов, работает только пока недоступен мультимаркет.
 * @param ISINs - массив значений ISIN
 * @param objects
 * @param marketBoards
 * @param finInstruments
 * @returns объект {ISIN: idFI}
 * */
export const selectIdFiByISIN = (
  ISINs: string[] | undefined,
  objects: ObjectItemsTable,
  marketBoards: MarketBoardItemsTable,
  finInstruments: InstrumentItemsTable
): Record<string, number> => {
  if (!ISINs) {
    return {};
  }

  const filtredMarketBoards = getAvailableMarketBoards(marketBoards);

  return ISINs.reduce((acc, isin) => {
    const objectWithISIN = objects.get('isin', isin);

    if (objectWithISIN) {
      const instWithISIN = finInstruments.get(
        'idObject',
        objectWithISIN.idObject
      );
      const board = instWithISIN?.filter((instrument) =>
        Boolean(
          filtredMarketBoards.get('idMarketBoard', instrument.idMarketBoard)
        )
      );
      const finalBoard = ['USD', 'EUR'].includes(isin)
        ? board?.filter(
            (mb) =>
              // отфильтровавываем режимы TOD и TOM по USD и EUR
              mb.idMarketBoard !== 113 && mb.idMarketBoard !== 114
          )
        : board;

      if (!finalBoard?.length) {
        return acc;
      }

      const { idFI } = finalBoard[0];

      return { ...acc, [isin]: idFI };
    }

    return acc;
  }, {});
};

/**
 * Получаем idFi по isin и ликвидному рынку
 * */
export const selectFiByIsinAndCode = (
  objects: ObjectItemsTable,
  marketBoards: MarketBoardItemsTable,
  finInstruments: InstrumentItemsTable,
  isin: string,
  marketCode?: string
) => {
  const filtredMarketBoardsTable = getAvailableMarketBoards(marketBoards);
  const filtredObjectsTable = getAvailableObjects(objects);
  const objectWithISIN = filtredObjectsTable.get('isin', isin);

  if (objectWithISIN) {
    const liquidMarket = filtredMarketBoardsTable.get(
      'universalMarketCode',
      marketCode ?? ''
    );

    if (liquidMarket) {
      return getInstrumentId(
        objectWithISIN.idObject,
        liquidMarket.idMarketBoard,
        finInstruments
      );
    }
  }
};

export const selectChartCustomSettings = (state: RootStore, nodeId: string) => {
  const workspaceConfiguration =
    state.workspaceConfigurations[state.workspaceConfigurationActiveId];

  const currentWorkspaceConfig =
    workspaceConfiguration[
      workspaceConfiguration.lastAutoSavedConfig.timeStamp >
      workspaceConfiguration.lastManualSavedConfig.timeStamp
        ? 'lastAutoSavedConfig'
        : 'lastManualSavedConfig'
    ];

  return currentWorkspaceConfig.chart[nodeId!]?.customSettings;
};

/**
 * Возвращает флаг-признак наличия ЕФР для счета
 * */

export const selectIsEFRAccount = (state: RootStore) => {
  // eslint-disable-next-line no-restricted-syntax
  return state.accounts?.[0]?.isSingleFORTSMarket;
};
