import cn from 'classnames';
import { useCallback, useEffect, useState, VFC } from 'react';
import { IconButton } from '@alfalab/core-components/icon-button';
import { Typography } from '@alfalab/core-components/typography';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';

import { ChatGroupId } from '@terminal/chat/types';
import { useRemoteConfig } from '@terminal/common/entities/RemoteConfig';
import {
  ServiceNotifications,
  TECH_MESSAGE_CODE,
} from '@terminal/common/entities/ServiceNotifications';

import { chatWindowEventEmitter } from '../../../../entities/Chat';
import { BrokerChat } from '../BrokerChat';
import { ChatRoomList } from '../ChatRoomList';
import { Support } from '../Support';

import styles from './ChatWindow.module.css';

export const ChatWindow: VFC = () => {
  const [windowVisibility, setWindowVisibility] = useState<boolean>(false);
  const [activeChatId, setActiveChatId] = useState<string | null>(null);

  const handleEnterRoom = useCallback((id: string) => setActiveChatId(id), []);

  const handleCloseRoom = useCallback(() => setActiveChatId(null), []);

  const handleCloseWindow = () => setWindowVisibility(false);

  const handleOpenWindow = useCallback(() => setWindowVisibility(true), []);

  const onClose = useCallback(() => setWindowVisibility(false), []);

  useEffect(() => {
    chatWindowEventEmitter.on('open', handleOpenWindow);

    return () => {
      chatWindowEventEmitter.off('open', handleOpenWindow);
    };
  }, [handleOpenWindow]);

  const renderActiveChat = (chatId: string) => {
    if (chatId === ChatGroupId.TECH_SUPPORT) {
      return <Support onBack={handleCloseRoom} />;
    }

    return <BrokerChat id={chatId} onBack={handleCloseRoom} />;
  };

  return (
    <div
      className={cn(styles.window, {
        [styles.windowVisible]: windowVisibility,
      })}
    >
      {activeChatId ? (
        renderActiveChat(activeChatId)
      ) : (
        <>
          <div className={styles.header}>
            <Typography.Text tag="div" view="secondary-large" weight="bold">
              Поддержка
            </Typography.Text>
            <IconButton
              size="xs"
              view="secondary"
              icon={CrossMIcon}
              onClick={handleCloseWindow}
            />
          </div>
          <ServiceNotifications
            variant="banner"
            containerClassName={styles.nofiticationsContainer}
            code={TECH_MESSAGE_CODE.SUPPORT}
            useRemoteConfig={useRemoteConfig}
          />
          <ChatRoomList onEnterRoom={handleEnterRoom} onClose={onClose} />
        </>
      )}
    </div>
  );
};
