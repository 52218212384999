import { useQuery } from 'react-query';

import { useAlfaDirectContext } from '@terminal/alfadirect/provider/react';
import { getScreenerBondsData } from '@terminal/core/lib/rest/investApi';
import { ScreenerBondEntity } from '@terminal/core/types/bondScreener';

import { ScreenerBondFilters } from '../model/types';

export const useFetchBonds = (filters: ScreenerBondFilters) => {
  const { user } = useAlfaDirectContext();

  return useQuery<ScreenerBondEntity[]>(['bonds', filters], async () => {
    const data = await getScreenerBondsData(user?.cus, filters);

    return data?.assets || [];
  });
};
