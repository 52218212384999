import { isEqual, startOfDay } from 'date-fns';
import isUndefined from 'lodash/isUndefined';

import { YEAR_INTERVALS } from '../../constants/filters';

export const getValueForDateRange = (dateFrom?: Date, dateTo?: Date) => {
  for (const interval of YEAR_INTERVALS) {
    const { startDate, endDate } = interval;

    const startMatches =
      (isUndefined(startDate) && isUndefined(dateFrom)) ||
      (!isUndefined(startDate) &&
        !isUndefined(dateFrom) &&
        isEqual(startOfDay(dateFrom), startOfDay(startDate)));
    const endMatches =
      (isUndefined(endDate) && isUndefined(dateTo)) ||
      (!isUndefined(endDate) &&
        !isUndefined(dateTo) &&
        isEqual(startOfDay(dateTo), startOfDay(endDate)));

    if (startMatches && endMatches) {
      return interval;
    }
  }

  return undefined;
};
