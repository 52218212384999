import { FormikConfig as BaseFormikConfig } from 'formik';

import {
  useLast,
  usePriceInfo,
  useSymbolSpec,
} from '@terminal/alfadirect/hooks';
import { TRACK_PRICE_SETTINGS_DEFAULT } from '@terminal/core/constants/trackPriceSettings';
import { useTrackPriceAdd } from '@terminal/core/lib/services/trackPrice/hooks/useTrackPriceAdd';
import { useTrackPriceEdit } from '@terminal/core/lib/services/trackPrice/hooks/useTrackPriceEdit';
import { useStore } from '@terminal/core/store';
import { trackPriceForEditSelector } from '@terminal/core/store/selectors/trackPrice/trackPriceForEditSelector';
import { trackPriceCommonSettingsSelector } from '@terminal/core/store/selectors/workspaceConfigurations/trackPriceCommonSettingsSelector';
import { validateForm } from '@terminal/desktop/legacy/components/AlertEditModal/FinInstrumentForm/Form/validateForm';

import { FormValues } from './types';

type FormikConfig = BaseFormikConfig<FormValues>;

type ReturnType = {
  formikConfig: FormikConfig;
  isLoading: boolean;
};

export function useForm(idFi: number): ReturnType {
  const trackPriceAdd = useTrackPriceAdd();
  const trackPriceEdit = useTrackPriceEdit();
  const trackPrice = useStore(trackPriceForEditSelector);
  const commonSettings = useStore(trackPriceCommonSettingsSelector);
  const closeModal = useStore((store) => store.closeEditTrackPriceModal);
  const setSettings = useStore((store) => store.setTrackPriceSettings);
  const lastPrice = useLast(idFi, Infinity);
  const { minority, priceStep } = usePriceInfo(idFi);

  const symbolSpec = useSymbolSpec(idFi);
  const isLoading = trackPriceAdd.isLoading || trackPriceEdit.isLoading;
  const targetPrice = Number.isNaN(trackPrice?.TargetPrice)
    ? lastPrice ?? 0
    : trackPrice?.TargetPrice ?? 0;

  const validate = (values: FormValues) => {
    return validateForm(values, minority, priceStep);
  };

  const initialValues: FormValues = {
    ...TRACK_PRICE_SETTINGS_DEFAULT,
    ...commonSettings,
    ...trackPrice?.settings,
    targetPrice: targetPrice * minority,
  };

  const onSubmit: FormikConfig['onSubmit'] = async (values, { setErrors }) => {
    if (!trackPrice) {
      return;
    }

    const { targetPrice: originalTrackPrice, ...settings } = values;
    const targetPrice = originalTrackPrice / minority;

    if (trackPrice.IdTrackPrice === 0n) {
      const newTrackPrice = await trackPriceAdd.mutateAsync({
        IdFI: idFi,
        InitialPrice: lastPrice ?? 0,
        TargetPrice: targetPrice,
        SymbolSpec: symbolSpec,
      });

      setSettings(newTrackPrice, settings);
    } else {
      await trackPriceEdit.mutateAsync({
        ...trackPrice,
        TargetPrice: targetPrice,
      });
      setSettings(trackPrice, settings);
    }

    closeModal();
  };

  return {
    isLoading,
    formikConfig: {
      onSubmit,
      initialValues,
      enableReinitialize: true,
      validate,
    },
  };
}
