import addYears from 'date-fns/addYears';
import { BriefcaseMIcon } from '@alfalab/icons-glyph/BriefcaseMIcon';
import EagleMIcon from '@alfalab/icons-glyph/EagleMIcon';
import EurOffMIcon from '@alfalab/icons-glyph/EurOffMIcon';
import { PercentMIcon } from '@alfalab/icons-glyph/PercentMIcon';
import RocketMIcon from '@alfalab/icons-glyph/RocketMIcon';

import { Filter, FilterTypes } from '@terminal/core/types/layout';
import { TableColumnKey } from '@terminal/core/types/tableColumn';

export const defaultFiltersVisible = [
  TableColumnKey.BsBondTypeName,
  TableColumnKey.BsMatDate,
  TableColumnKey.BsYield,
];

export const alignLeftColumns = [
  TableColumnKey.BsUntilExpiration,
  TableColumnKey.BsAgencyRating,
  TableColumnKey.BsSectorName,
  TableColumnKey.BsISIN,
  TableColumnKey.BsEmShortName,
  TableColumnKey.BsCountryName,
  TableColumnKey.BsCurrency,
  TableColumnKey.BsIsFloatingRate,
  TableColumnKey.BsAmortization,
  TableColumnKey.BsIsOffert,
  TableColumnKey.BsOffertType,
  TableColumnKey.BsBondTypeName,
  TableColumnKey.BsIsReplacement,
  TableColumnKey.BsIsMortgageBonds,
  TableColumnKey.BsIsRestructing,
  TableColumnKey.BsIsStructuredNote,
  TableColumnKey.BsIsSubordinatedDebt,
  TableColumnKey.BsAllowNonQualInvest,
];

export const defaultFilters: Record<string, Filter | undefined> = {
  [TableColumnKey.BsBondTypeName]: undefined,
  [TableColumnKey.BsMatDate]: undefined,
  [TableColumnKey.BsYield]: undefined,
  [TableColumnKey.BsAgencyRating]: undefined,
  [TableColumnKey.BsSectorName]: undefined,
  [TableColumnKey.BsCountryName]: undefined,
  [TableColumnKey.BsPrice]: undefined,
  [TableColumnKey.BsNominal]: undefined,
  [TableColumnKey.BsCurrency]: undefined,
  [TableColumnKey.BsYieldToOffert]: undefined,
  [TableColumnKey.BsCupYield]: undefined,
  [TableColumnKey.BsCupSize]: undefined,
  [TableColumnKey.BsIsFloatingRate]: undefined,
  [TableColumnKey.BsCouponPaymentPerYear]: undefined,
  [TableColumnKey.BsAmortization]: undefined,
  [TableColumnKey.BsIsOffert]: undefined,
  [TableColumnKey.BsOffertType]: undefined,
  [TableColumnKey.BsOffertDate]: undefined,
  [TableColumnKey.BsIsStructuredNote]: undefined,
  [TableColumnKey.BsIsReplacement]: undefined,
  [TableColumnKey.BsAllowNonQualInvest]: undefined,
  [TableColumnKey.BsIsSubordinatedDebt]: undefined,
};

export const defaultFiltersTemplates = [
  {
    icon: EagleMIcon,
    title: 'Государственные облигации',
    description:
      'Ликвидные и наиболее надежные бумаги государственные бумаги со сроком погашения от 1 до 3 лет',
    filter: {
      [TableColumnKey.BsBondTypeName]: {
        key: TableColumnKey.BsBondTypeName,
        type: FilterTypes.Array,
        value: ['Государственный'],
      },
      [TableColumnKey.BsMatDate]: {
        key: TableColumnKey.BsMatDate,
        type: FilterTypes.DateRange,
        value: {
          dateFrom: addYears(new Date(), 1),
          dateTo: addYears(new Date(), 3),
        },
      },
      [TableColumnKey.BsYield]: undefined,
      [TableColumnKey.BsAgencyRating]: undefined,
      [TableColumnKey.BsSectorName]: undefined,
      [TableColumnKey.BsCountryName]: undefined,
      [TableColumnKey.BsPrice]: undefined,
      [TableColumnKey.BsNominal]: undefined,
      [TableColumnKey.BsCurrency]: undefined,
      [TableColumnKey.BsYieldToOffert]: undefined,
      [TableColumnKey.BsCupYield]: undefined,
      [TableColumnKey.BsCupSize]: undefined,
      [TableColumnKey.BsIsFloatingRate]: undefined,
      [TableColumnKey.BsCouponPaymentPerYear]: undefined,
      [TableColumnKey.BsAmortization]: undefined,
      [TableColumnKey.BsIsOffert]: undefined,
      [TableColumnKey.BsOffertType]: undefined,
      [TableColumnKey.BsOffertDate]: undefined,
      [TableColumnKey.BsIsStructuredNote]: undefined,
      [TableColumnKey.BsIsReplacement]: undefined,
      [TableColumnKey.BsAllowNonQualInvest]: undefined,
      [TableColumnKey.BsIsSubordinatedDebt]: undefined,
    } as Record<string, Filter | undefined>,
  },
  {
    icon: BriefcaseMIcon,
    title: 'Доходные и надежные',
    description:
      'Качественные корпоративные бумаги со сроком погашения от 1 до 5 лет и высоким кредитным рейтингом',
    filter: {
      [TableColumnKey.BsBondTypeName]: {
        key: TableColumnKey.BsBondTypeName,
        value: ['Корпоративный'],
        type: FilterTypes.Array,
      },
      [TableColumnKey.BsMatDate]: {
        key: TableColumnKey.BsMatDate,
        type: FilterTypes.DateRange,
        value: {
          dateFrom: addYears(new Date(), 1),
          dateTo: addYears(new Date(), 5),
        },
      },
      [TableColumnKey.BsYield]: undefined,
      [TableColumnKey.BsAgencyRating]: {
        key: TableColumnKey.BsAgencyRating,
        value: ['AAA', 'AA', 'A'],
        type: FilterTypes.Array,
      },
      [TableColumnKey.BsSectorName]: undefined,
      [TableColumnKey.BsCountryName]: undefined,
      [TableColumnKey.BsPrice]: undefined,
      [TableColumnKey.BsNominal]: undefined,
      [TableColumnKey.BsCurrency]: undefined,
      [TableColumnKey.BsYieldToOffert]: undefined,
      [TableColumnKey.BsCupYield]: undefined,
      [TableColumnKey.BsCupSize]: undefined,
      [TableColumnKey.BsIsFloatingRate]: undefined,
      [TableColumnKey.BsCouponPaymentPerYear]: undefined,
      [TableColumnKey.BsAmortization]: undefined,
      [TableColumnKey.BsIsOffert]: undefined,
      [TableColumnKey.BsOffertType]: undefined,
      [TableColumnKey.BsOffertDate]: undefined,
      [TableColumnKey.BsIsStructuredNote]: undefined,
      [TableColumnKey.BsIsReplacement]: undefined,
      [TableColumnKey.BsAllowNonQualInvest]: undefined,
      [TableColumnKey.BsIsSubordinatedDebt]: undefined,
    },
  },
  {
    icon: RocketMIcon,
    title: 'Высокодоходные облигации',
    description:
      'Бумаги с низкими кредитными рейтингами и повышенной доходностью',
    filter: {
      [TableColumnKey.BsBondTypeName]: {
        key: TableColumnKey.BsBondTypeName,
        value: ['Корпоративный'],
        type: FilterTypes.Array,
      },
      [TableColumnKey.BsMatDate]: undefined,
      [TableColumnKey.BsYield]: undefined,
      [TableColumnKey.BsAgencyRating]: {
        key: TableColumnKey.BsAgencyRating,
        value: ['BBB', 'BB', 'B', 'CCC', 'CC', 'C'],
        type: FilterTypes.Array,
      },
      [TableColumnKey.BsSectorName]: undefined,
      [TableColumnKey.BsCountryName]: undefined,
      [TableColumnKey.BsPrice]: undefined,
      [TableColumnKey.BsNominal]: undefined,
      [TableColumnKey.BsCurrency]: undefined,
      [TableColumnKey.BsYieldToOffert]: undefined,
      [TableColumnKey.BsCupYield]: undefined,
      [TableColumnKey.BsCupSize]: undefined,
      [TableColumnKey.BsIsFloatingRate]: undefined,
      [TableColumnKey.BsCouponPaymentPerYear]: undefined,
      [TableColumnKey.BsAmortization]: undefined,
      [TableColumnKey.BsIsOffert]: undefined,
      [TableColumnKey.BsOffertType]: undefined,
      [TableColumnKey.BsOffertDate]: undefined,
      [TableColumnKey.BsIsStructuredNote]: undefined,
      [TableColumnKey.BsIsReplacement]: undefined,
      [TableColumnKey.BsAllowNonQualInvest]: undefined,
      [TableColumnKey.BsIsSubordinatedDebt]: undefined,
    },
  },
  {
    icon: EurOffMIcon,
    title: 'Замещающие облигации',
    description:
      'Облигации, выпущенные эмитентом для замены или погашения ранее выпущенных облигаций',
    filter: {
      [TableColumnKey.BsBondTypeName]: {
        key: TableColumnKey.BsBondTypeName,
        value: ['Корпоративный'],
        type: FilterTypes.Array,
      },
      [TableColumnKey.BsMatDate]: undefined,
      [TableColumnKey.BsYield]: undefined,
      [TableColumnKey.BsAgencyRating]: undefined,
      [TableColumnKey.BsSectorName]: undefined,
      [TableColumnKey.BsCountryName]: undefined,
      [TableColumnKey.BsPrice]: undefined,
      [TableColumnKey.BsNominal]: undefined,
      [TableColumnKey.BsCurrency]: undefined,
      [TableColumnKey.BsYieldToOffert]: undefined,
      [TableColumnKey.BsCupYield]: undefined,
      [TableColumnKey.BsCupSize]: undefined,
      [TableColumnKey.BsIsFloatingRate]: undefined,
      [TableColumnKey.BsCouponPaymentPerYear]: undefined,
      [TableColumnKey.BsAmortization]: undefined,
      [TableColumnKey.BsIsOffert]: undefined,
      [TableColumnKey.BsOffertType]: undefined,
      [TableColumnKey.BsOffertDate]: undefined,
      [TableColumnKey.BsIsStructuredNote]: undefined,
      [TableColumnKey.BsIsReplacement]: {
        key: TableColumnKey.BsIsReplacement,
        value: true,
        type: FilterTypes.RadioSelect,
      },
      [TableColumnKey.BsAllowNonQualInvest]: undefined,
      [TableColumnKey.BsIsSubordinatedDebt]: undefined,
    },
  },
  {
    icon: PercentMIcon,
    title: 'Флоатеры',
    description:
      'Облигация с плавающей процентной ставкой. Размер выплат по таким бондам зависит от внешних индикаторов, например ключевой ставки',
    filter: {
      [TableColumnKey.BsBondTypeName]: undefined,
      [TableColumnKey.BsMatDate]: undefined,
      [TableColumnKey.BsYield]: undefined,
      [TableColumnKey.BsAgencyRating]: {
        key: TableColumnKey.BsAgencyRating,
        value: ['AAA', 'AA', 'A'],
        type: FilterTypes.Array,
      },
      [TableColumnKey.BsSectorName]: undefined,
      [TableColumnKey.BsCountryName]: undefined,
      [TableColumnKey.BsPrice]: undefined,
      [TableColumnKey.BsNominal]: undefined,
      [TableColumnKey.BsCurrency]: undefined,
      [TableColumnKey.BsYieldToOffert]: undefined,
      [TableColumnKey.BsCupYield]: undefined,
      [TableColumnKey.BsCupSize]: undefined,
      [TableColumnKey.BsIsFloatingRate]: {
        key: TableColumnKey.BsIsFloatingRate,
        value: true,
        type: FilterTypes.RadioSelect,
      },
      [TableColumnKey.BsCouponPaymentPerYear]: undefined,
      [TableColumnKey.BsAmortization]: undefined,
      [TableColumnKey.BsIsOffert]: undefined,
      [TableColumnKey.BsOffertType]: undefined,
      [TableColumnKey.BsOffertDate]: undefined,
      [TableColumnKey.BsIsStructuredNote]: undefined,
      [TableColumnKey.BsIsReplacement]: undefined,
      [TableColumnKey.BsAllowNonQualInvest]: undefined,
      [TableColumnKey.BsIsSubordinatedDebt]: undefined,
    },
  },
];
