import isEqual from 'lodash/isEqual';
import { useEffect, useRef, useState } from 'react';
import { IconButton } from '@alfalab/core-components/icon-button';
import { CheckmarkSIcon } from '@alfalab/icons-glyph/CheckmarkSIcon';
import { ChevronDownCompactMIcon } from '@alfalab/icons-glyph/ChevronDownCompactMIcon';
import { ChevronUpCompactMIcon } from '@alfalab/icons-glyph/ChevronUpCompactMIcon';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';

import { Dropdown } from '@terminal/common/components/Dropdown';
import { useOnClickOutside, usePrevious } from '@terminal/core/hooks';
import { shallow, useStore } from '@terminal/core/store';
import { activeConfigurationSelector } from '@terminal/core/store/selectors/workspaceConfigurations/activeConfigurationSelector';
import { LayoutType } from '@terminal/core/types/layout';

import { trackChangeLayout, trackCloseLayout } from '../../lib/analytics';
import { CreateLayoutButton } from '../CreateLayoutButton';
import { Tab } from '../Tab';
import { TabButton } from '../TabButton';

import { useTabsOverflow } from '../../hooks/useTabsOverflow';

import styles from './TabsRow.module.css';

interface Props {
  createLayout: (type: LayoutType) => void;
}

export const TabsRow = ({ createLayout }: Props) => {
  const [overflowingTabsMenuVisible, setOverflowingTabsMenuVisible] =
    useState(false);

  const tabsRowRef = useRef<HTMLDivElement>(null);
  const allTabsButtonRef = useRef<HTMLDivElement>(null);
  const tabsDropdownRef = useRef<HTMLDivElement>(null);

  const [getActiveLayoutKey, removeLayout, setActiveLayoutKey] = useStore(
    (state) => [
      state.getActiveLayoutKey,
      state.removeLayout,
      state.setActiveLayoutKey,
    ],
    shallow
  );

  const activeLayoutKey = getActiveLayoutKey();

  const { lastAutoSavedConfig } = useStore(activeConfigurationSelector);
  const { layoutNames } = lastAutoSavedConfig;

  const layoutTabs = layoutNames.map((_, key) => key);

  const overflowingIndices = useTabsOverflow(
    tabsRowRef,
    layoutTabs,
    activeLayoutKey
  );

  useOnClickOutside(tabsDropdownRef, () =>
    setOverflowingTabsMenuVisible(false)
  );

  const prevOverflowingIndices = usePrevious(overflowingIndices);

  useEffect(() => {
    if (!isEqual(prevOverflowingIndices, overflowingIndices)) {
      setOverflowingTabsMenuVisible(false);
    }
  }, [prevOverflowingIndices, overflowingIndices]);

  return (
    <div className={styles.headerTabsContainer}>
      <div ref={tabsRowRef} className={styles.layoutTabs}>
        {layoutTabs.map((key) => (
          <Tab key={key} tabKey={key} />
        ))}

        <div className={styles.auxButtons}>
          <TabButton
            ref={allTabsButtonRef}
            label={`+ ${overflowingIndices.length}`}
            rightAddons={
              overflowingTabsMenuVisible ? (
                <ChevronUpCompactMIcon width={18} height={18} />
              ) : (
                <ChevronDownCompactMIcon width={18} height={18} />
              )
            }
            onClick={() => setOverflowingTabsMenuVisible(true)}
          />
          <Dropdown
            ref={tabsDropdownRef}
            anchorElement={allTabsButtonRef.current}
            open={overflowingTabsMenuVisible}
            offset={[0, 12]}
            position="bottom-start"
            className={styles.tabsMenu}
          >
            {layoutTabs
              .filter((key) => overflowingIndices.includes(key))
              .map((key) => (
                <Dropdown.Item
                  key={key}
                  content={layoutNames[key]}
                  rightIcon={
                    <>
                      {key === activeLayoutKey && (
                        <CheckmarkSIcon
                          height={18}
                          width={18}
                          color="var(--color-dark-graphic-secondary)"
                        />
                      )}
                      <IconButton
                        size="xxs"
                        view="secondary"
                        icon={CrossMIcon}
                        onClick={(event) => {
                          event.stopPropagation();
                          removeLayout(key);
                          trackCloseLayout({
                            layoutsCount: layoutTabs.length,
                            layoutName: layoutNames[key],
                          });
                          setOverflowingTabsMenuVisible(false);
                        }}
                      />
                    </>
                  }
                  onClick={() => {
                    setActiveLayoutKey(key);
                    setOverflowingTabsMenuVisible(false);
                    trackChangeLayout({
                      layoutsCount: layoutTabs.length,
                      layoutName: layoutNames[key],
                    });
                  }}
                />
              ))}
          </Dropdown>
        </div>
      </div>

      <div className={styles.auxButtons}>
        <CreateLayoutButton onPick={createLayout} />
      </div>
    </div>
  );
};
