import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { NotificationType } from '@terminal/core/types/ui';

import { useLkContext } from '../../shared';
import { requestSignText } from '../api/requestSignText';
import { useSignStore } from '../model/useSignStore';

export function useSignTextOperation(
  operationUrl: string | undefined,
  open: boolean
) {
  const [lkSignOptions, setLkSignOperationText, closeLkSignOperation] =
    useSignStore((state) => [
      state.lkSignOptions,
      state.setLkSignOperationText,
      state.closeLkSignOperation,
    ]);

  const { addNotification } = useLkContext();

  const { data: text, ...restQueryData } = useQuery(
    ['lk', 'sign-text', { operationUrl }],
    () => requestSignText(operationUrl || ''),
    {
      enabled: Boolean(operationUrl) && open,
      onError: (error: any) => {
        const options = lkSignOptions;

        if (!options?.muteError) {
          addNotification({
            type: NotificationType.SYSTEM,
            badge: 'negative',
            title: options?.errorTitle || 'Ошибка',
            text: error?.message || 'Ошибка запроса поручения',
          });
        }

        closeLkSignOperation();
      },
    }
  );

  useEffect(() => {
    if (text) {
      setLkSignOperationText(text);
    }
  }, [text, setLkSignOperationText]);

  return {
    data: text,
    ...restQueryData,
  };
}
