import {
  ACCESS_LEVELS_MAP,
  AccessLevel,
  AccessLevelInfo,
} from '@terminal/lk-desktop-common';

export const ACCESS_LEVELS: AccessLevelInfo[] = [
  {
    id: AccessLevel.CONTROLLER,
    name: ACCESS_LEVELS_MAP[AccessLevel.CONTROLLER],
    permissions: [
      'Получает информацию о сделках, поручениях, активах доверителя. Полномочия не распространяются на получение документов, содержащих налоговую информацию',
    ],
  },
  {
    id: AccessLevel.TRADER,
    name: ACCESS_LEVELS_MAP[AccessLevel.TRADER],
    permissions: [
      'Получает информацию о сделках, поручениях, активах доверителя. Полномочия не распространяются на получение документов, содержащих налоговую информацию',
      'Подаёт поручения на совершение сделок',
    ],
  },
  {
    id: AccessLevel.SENIOR_TRADER,
    name: ACCESS_LEVELS_MAP[AccessLevel.SENIOR_TRADER],
    permissions: [
      'Получает информацию о сделках, поручениях, активах доверителя. Полномочия не распространяются на получение документов, содержащих налоговую информацию',
      'Подаёт поручения на совершение сделок',
      'Подаёт поручения на совершение операций по счету депо',
      'Подаёт поручения на отзыв (вывод) денежных средств',
    ],
  },
];
