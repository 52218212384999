import { ObjectGroup } from '../../lib/client/entities';
import { getQuantityForBonds } from './getQuantityForBonds';
import { getQuantityForFutures } from './getQuantityForFutures';
import { getQuantityForOtherAssets } from './getQuantityForOtherAssets';

interface props {
  nominalCurrencyPrice: number;
  marketCurrencyPrice: number;
  price: number;
  nominal: number;
  quantity: number;
  isLotCount: boolean;
  nkd: number;
  lot: number;
  warranty: number;
  idObjectGroup: ObjectGroup;
}

/**
 * Формула для получения количества штук/лотов из суммы
 * @param options - параметры для формулы:
 * - marketCurrencyPrice - курс валюты номинала по отношению к рублю
 * - nominalCurrencyPrice - курс валюты рынка по отношению к рублю
 * - price - цена облигации. Для заявки STL price  = цена + проскок при покупке / цена - проскок при продаже. Для заявок BSL, BRS, TBRS  price = цене тейк профита, для MKT price = bid или ask в зависимости от направления сделки.
 * - nominal - номинал облигации
 * - quantity - количество лотов
 * - warranty - гарантийное обеспечение (Price * Lot *  (PriceStepCost / PriceStep) * MK)
 * - isLotCount - измеряется ли количество в лотах
 * - lot - количество штук в лоте
 * - idObjectGroup - тип актива
 * - nkd - accruedInt из FinInfoExtEntity
 * @returns Количество штук/лотов
 * */
export const getQuantityFromCash = (options: props) => {
  const {
    nominalCurrencyPrice,
    marketCurrencyPrice,
    price,
    nominal,
    quantity,
    isLotCount,
    warranty,
    lot,
    idObjectGroup,
    nkd,
  } = options;

  const isBond = idObjectGroup === ObjectGroup.Bonds;
  const isFutures = idObjectGroup === ObjectGroup.Futures;

  if (isBond) {
    return getQuantityForBonds({
      nominalCurrencyPrice,
      marketCurrencyPrice,
      price: price || 1,
      nominal: nominal,
      quantity,
      isLotCount,
      lot,
      nkd,
    });
  } else if (isFutures) {
    return getQuantityForFutures({ warranty, quantity, isLotCount, lot });
  } else {
    return getQuantityForOtherAssets({
      nominalCurrencyPrice,
      marketCurrencyPrice,
      price: price || 1,
      quantity,
      isLotCount,
      lot,
    });
  }
};
