import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useThrottledCallback } from 'use-debounce';

import { useAlfaDirectContext } from '@terminal/alfadirect/provider/react';
import { trackBondScreenerWidget } from '@terminal/core/lib/analytics/bondScreener/bondScreener';
import { getAvailableObjects } from '@terminal/core/lib/domain/getAvailableObjects';
import { ScreenerBondEntity } from '@terminal/core/types/bondScreener';
import { Sort } from '@terminal/core/types/layout';
import { TableColumnKey } from '@terminal/core/types/tableColumn';

import { useFetchBonds } from './useFetchBonds';

import { ScreenerBondFilters } from '../model/types';

const PAGE_SIZE = 100;
const DEBOUNCE_QUERY = 300;

export const useGetBondsData = ({
  filters,
  sort,
  query,
}: {
  filters?: Omit<ScreenerBondFilters, 'sort' | 'size' | 'query'>;
  sort?: Sort;
  query: string;
}) => {
  const { objectsTable } = useAlfaDirectContext();

  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [slowQuery, setSlowQuery] = useState('');
  const [allData, setAllData] = useState<ScreenerBondEntity[]>([]);

  const debouncedSetSlowQuery = useMemo(
    () =>
      debounce(
        (value: string) =>
          setSlowQuery((prevSlowQuery) => {
            const QUERY_FILTER_NAME = 'Поиск';

            if (!prevSlowQuery && value) {
              trackBondScreenerWidget.addFilter(QUERY_FILTER_NAME);
            }

            if (value) {
              trackBondScreenerWidget.applyFilter(QUERY_FILTER_NAME, value);
            }

            if (prevSlowQuery && !value) {
              trackBondScreenerWidget.removeFilter(
                QUERY_FILTER_NAME,
                prevSlowQuery
              );
            }

            return value;
          }),
        DEBOUNCE_QUERY
      ),
    []
  );

  useEffect(() => {
    debouncedSetSlowQuery(query);

    return () => {
      debouncedSetSlowQuery.cancel();
    };
  }, [query, debouncedSetSlowQuery]);

  const formattedSort = useMemo<
    | {
        value: string;
        order: 'desc' | 'asc';
      }
    | undefined
  >(() => {
    if (sort) {
      const sortKey =
        sort.key === TableColumnKey.BsUntilExpiration
          ? TableColumnKey.MatDate
          : sort.key;

      const isKeywordParamSkipped = [
        TableColumnKey.BsPrice,
        TableColumnKey.BsMatDate,
        TableColumnKey.BsCupYield,
        TableColumnKey.BsCupSize,
        TableColumnKey.Yield,
        TableColumnKey.BsNominal,
        TableColumnKey.BsYieldToOffert,
        TableColumnKey.BsDuration,
        TableColumnKey.BsCouponPaymentPerYear,
        TableColumnKey.BsNkd,
        TableColumnKey.BsOffertPrice,
      ].includes(sortKey as TableColumnKey);

      return {
        value: isKeywordParamSkipped ? sortKey : `${sortKey}.keyword`,
        order: sort.asc ? 'asc' : 'desc',
      };
    }
  }, [sort]);

  const queryFilters = {
    ...filters,
    size: PAGE_SIZE,
    from: page * PAGE_SIZE,
  };

  const { data, isLoading, isFetching, isError, refetch } = useFetchBonds({
    ...queryFilters,
    sort: formattedSort,
    query: slowQuery || undefined,
  });

  const isLoadingOrFetching = useMemo(
    () => isLoading || isFetching,
    [isLoading, isFetching]
  );

  useEffect(() => {
    setPage(0);
    setHasMore(true);
    refetch();
  }, [filters, slowQuery, formattedSort, refetch]);

  const retry = useCallback(() => {
    setPage(0);
    setAllData([]);
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (data && data.length < PAGE_SIZE) {
      setHasMore(false);
    }

    const onlyActiveTickers =
      data?.filter(({ objectId }) => {
        return getAvailableObjects(objectsTable).get('idObject', objectId);
      }) || [];

    if (page === 0) {
      setAllData(onlyActiveTickers);
    } else {
      setAllData((prevData) => [...prevData, ...onlyActiveTickers]);
    }

    // Если мы получили data, то можно гарантировать, что page изменился
    // Но если page изменился, не факт, что мы получили новые данные
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getMoreData = useCallback(() => {
    if (hasMore && !isLoadingOrFetching) {
      setPage((page) => page + 1);
    }
  }, [hasMore, isLoadingOrFetching]);

  const throttledGetMoreData = useThrottledCallback(getMoreData, 100);

  return {
    data: allData,
    isLoading: isLoadingOrFetching,
    isError,
    getMoreData: throttledGetMoreData,
    hasMore,
    retry,
  };
};
