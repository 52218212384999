import { addKeyIfExists } from '@terminal/core/lib/addKeyIfExists';
import { FilterTypes, LinkName } from '@terminal/core/types/layout';

export const TRADE_CHART_SETTINGS = {
  customSettings: {
    panels: {
      chart: { yAxis: { zoom: 42.689075630252105 } },
      '‌volume‌': { yAxis: { zoom: 2 } },
    },
    showOHLC: true,
    showOrders: true,
    showPositions: true,
    showDividends: true,
  },
  layout: {
    interval: 'day',
    periodicity: 1,
    timeUnit: null,
    candleWidth: 7.984615384615385,
    flipped: false,
    volumeUnderlay: false,
    adj: true,
    crosshair: true,
    chartType: 'candle',
    extended: false,
    marketSessions: {},
    aggregationType: 'ohlc',
    chartScale: 'linear',
    panels: {
      chart: {
        percent: 0.8,
        display: 'SBER МБ ЦК',
        chartName: 'chart',
        index: 0,
        yAxis: { name: 'chart', position: null },
        yaxisLHS: [],
        yaxisRHS: ['chart'],
      },
      '‌volume‌': {
        percent: 0.2,
        display: '‌volume‌',
        chartName: 'chart',
        index: 1,
        yAxis: { name: '‌volume‌' },
        yaxisLHS: [],
        yaxisRHS: ['‌volume‌'],
      },
    },
    setSpan: {},
    outliers: false,
    animation: true,
    headsUp: { static: true },
    sidenav: 'sidenavOff',
    symbols: [
      {
        symbol: 'SBER МБ ЦК',
        symbolObject: {
          symbol: 'SBER МБ ЦК',
          id: 144950,
          periodicity: { period: 1, interval: 1, timeUnit: 'day' },
          trueSymbol: 'SBER',
        },
        periodicity: 1,
        interval: 'day',
        timeUnit: null,
        setSpan: {},
      },
    ],
    studies: {
      '‌volume‌': {
        type: 'volume',
        inputs: { id: '‌volume‌', display: '‌volume‌' },
        outputs: {
          'Up Volume': { color: '#898989' },
          'Down Volume': { color: '#898989' },
        },
        panel: '‌volume‌',
        parameters: {
          chartName: 'chart',
          editMode: true,
          panelName: '‌volume‌',
        },
      },
      '‌ma‌ (50,ema,0,1)': {
        type: 'ma',
        inputs: {
          Period: 50,
          Field: 'field',
          Type: 'exponential',
          Offset: 0,
          'MA width': 1,
          id: '‌ma‌ (50,ema,0,1)',
          display: '‌ma‌ (50,ema,0,1)',
        },
        outputs: { MA: { color: '#5875b7', width: 1 } },
        panel: 'chart',
        parameters: {
          interactiveAdd: false,
          chartName: 'chart',
          editMode: true,
          panelName: 'chart',
        },
      },
      '‌ma‌ (100,ema,0,1)': {
        type: 'ma',
        inputs: {
          Period: '100',
          Field: 'field',
          Type: 'exponential',
          Offset: 0,
          'MA width': 1,
          id: '‌ma‌ (100,ema,0,1)',
          display: '‌ma‌ (100,ema,0,1)',
        },
        outputs: { MA: { color: '#FF0000', width: 1 } },
        panel: 'chart',
        parameters: {
          interactiveAdd: false,
          chartName: 'chart',
          editMode: true,
          panelName: 'chart',
        },
      },
    },
  },
};

export const getChartWatchlistConfig = (link?: LinkName) => {
  return {
    selectedListId: 'default',
    columns: [
      {
        name: 'Тикер',
        key: 'symbolObject',
        selected: true,
        cellClassName: 'customSelection textAlignStart',
        filterType: FilterTypes.Text,
        width: 125,
        description: 'Биржевой код инструмента (тикер)',
      },
      {
        name: 'Откр',
        key: 'open',
        selected: true,
        cellClassName: 'customSelection',
        width: 100,
        description: 'Цена открытия текущего дня',
      },
      {
        name: 'Цена З',
        key: 'close',
        selected: true,
        cellClassName: 'customSelection',
        width: 100,
        description:
          'Цена закрытия предыдущего дня или текущего дня, по завершении торгов',
      },
      {
        name: 'Цена П',
        key: 'lastPrice',
        selected: true,
        cellClassName: 'customSelection',
        width: 100,
        description:
          'Цена последней сделки или цена по которой строится график',
      },
      {
        name: 'Изм З',
        key: 'change',
        selected: true,
        cellClassName: 'customSelection',
        width: 125,
        description: '% изменения цены Last по отношению к цене Close',
      },
      {
        name: 'Объем',
        key: 'volToday',
        selected: true,
        cellClassName: 'customSelection',
        width: 125,
        description:
          'Объем сделок текущего дня в единицах актива (штуках, лотах)',
      },
      {
        name: 'Оборот',
        key: 'valToday',
        selected: true,
        cellClassName: 'customSelection',
        width: 125,
        description: 'Оборот сделок текущего дня в валюте актива',
      },
      {
        name: 'Рынок',
        key: 'codeMarketBoard',
        selected: true,
        cellClassName: 'customSelection',
        width: 100,
        description: 'Рынок на котором торгуется данный инструмент',
      },
    ],
    tableProps: {
      columns: [
        {
          name: 'Наименование',
          key: 'nameObject',
          selected: true,
          cellClassName: 'customSelection textAlignStart',
          filterType: FilterTypes.Text,
          width: 125,
        },
        {
          name: 'Откр',
          key: 'open',
          selected: true,
          cellClassName: 'customSelection',
          width: 100,
          description: 'Цена открытия текущего дня',
        },
        {
          name: 'Цена П',
          key: 'lastPrice',
          selected: true,
          cellClassName: 'customSelection',
          width: 100,
          description:
            'Цена последней сделки или цена по которой строится график',
        },
        {
          name: 'Цена З',
          key: 'close',
          selected: true,
          cellClassName: 'customSelection',
          width: 100,
          description:
            'Цена закрытия предыдущего дня или текущего дня, по завершении торгов',
        },
        {
          name: 'Изм З',
          key: 'change',
          selected: true,
          cellClassName: 'customSelection',
          width: 125,
          description: '% изменения цены Last по отношению к цене Close',
        },
        {
          name: 'Объем',
          key: 'volToday',
          selected: true,
          cellClassName: 'customSelection',
          width: 125,
          description:
            'Объем сделок текущего дня в единицах актива (штуках, лотах)',
        },
        {
          name: 'Оборот',
          key: 'valToday',
          selected: true,
          cellClassName: 'customSelection',
          width: 125,
          description: 'Оборот сделок текущего дня в валюте актива',
        },
        {
          name: 'Рынок',
          key: 'codeMarketBoard',
          selected: false,
          cellClassName: 'customSelection',
          width: 100,
          description: 'Рынок на котором торгуется данный инструмент',
        },
      ],
    },
    symbol: 'SBER',
    idFi: 144950,
    ...addKeyIfExists('link', link),
  };
};

export const TRADE_CHARTIQ_SMALL = {
  customSettings: {
    panels: { chart: { yAxis: { zoom: 50.00000000000001 } } },
    showOHLC: true,
    showOrders: true,
    showPositions: true,
    showDividends: true,
  },
  layout: {
    interval: 'day',
    periodicity: 1,
    timeUnit: null,
    candleWidth: 5.7592592592592595,
    flipped: false,
    volumeUnderlay: false,
    adj: true,
    crosshair: true,
    chartType: 'candle',
    extended: false,
    marketSessions: {},
    aggregationType: 'ohlc',
    chartScale: 'linear',
    panels: {
      chart: {
        percent: 1,
        display: 'SBER МБ ЦК',
        chartName: 'chart',
        index: 0,
        yAxis: { name: 'chart', position: null },
        yaxisRHS: ['chart'],
      },
      '‌volume‌': {
        percent: 0.2,
        display: '‌volume‌',
        chartName: 'chart',
        index: 1,
        yAxis: { name: '‌volume‌' },
        yaxisLHS: [],
        yaxisRHS: ['‌volume‌'],
      },
    },
    setSpan: null,
    outliers: false,
    animation: true,
    headsUp: { static: true },
    sidenav: 'sidenavOff',
    symbols: [
      {
        symbol: 'SBER МБ ЦК',
        symbolObject: {
          symbol: 'SBER МБ ЦК',
          id: 144950,
          periodicity: { period: 1, interval: 1, timeUnit: 'day' },
          trueSymbol: 'SBER',
        },
        periodicity: 1,
        interval: 'day',
        timeUnit: null,
        setSpan: null,
      },
    ],
    range: null,
    studies: {
      '‌volume‌': {
        type: 'volume',
        inputs: { id: '‌volume‌', display: '‌volume‌' },
        outputs: {
          'Up Volume': { color: '#898989' },
          'Down Volume': { color: '#707070' },
        },
        panel: '‌volume‌',
        parameters: {
          chartName: 'chart',
          editMode: true,
          panelName: '‌volume‌',
        },
      },
      '‌ma‌ (50,ema,0,1)': {
        type: 'ma',
        inputs: {
          Period: 14,
          Field: 'field',
          Type: 'exponential',
          Offset: 0,
          'MA width': 1,
          id: '‌ma‌ (50,ema,0,1)',
          display: '‌ma‌ (50,ema,0,1)',
        },
        outputs: {
          MA: {
            color: '#5875b7',
            width: 1,
          },
        },
        panel: 'chart',
        parameters: {
          interactiveAdd: false,
          chartName: 'chart',
          editMode: true,
          panelName: 'chart',
        },
      },
      '‌ma‌ (100,ema,0,1)': {
        type: 'ma',
        inputs: {
          Period: '7',
          Field: 'field',
          Type: 'exponential',
          Offset: 0,
          'MA width': 1,
          id: '‌ma‌ (100,ema,0,1)',
          display: '‌ma‌ (100,ema,0,1)',
        },
        outputs: {
          MA: {
            color: '#FF0000',
            width: 1,
          },
        },
        panel: 'chart',
        parameters: {
          interactiveAdd: false,
          chartName: 'chart',
          editMode: true,
          panelName: 'chart',
        },
      },
    },
  },
};

export const CHART_LEFT_CHARTIQ_SETTINGS = {
  customSettings: {
    panels: { chart: { yAxis: { zoom: 50.00000000000001 } } },
    showOHLC: true,
    showOrders: true,
    showPositions: true,
    showDividends: true,
  },
  layout: {
    interval: 'day',
    periodicity: 1,
    timeUnit: null,
    candleWidth: 5.7592592592592595,
    flipped: false,
    volumeUnderlay: false,
    adj: true,
    crosshair: true,
    chartType: 'bar',
    extended: false,
    marketSessions: {},
    aggregationType: 'ohlc',
    chartScale: 'linear',
    panels: {
      chart: {
        percent: 1,
        display: 'SBER МБ ЦК',
        chartName: 'chart',
        index: 0,
        yAxis: { name: 'chart', position: null },
        yaxisLHS: ['‌Donchian Channel‌ (50,50,n)'],
        yaxisRHS: ['chart'],
      },
    },
    setSpan: null,
    outliers: false,
    animation: true,
    headsUp: { static: true },
    sidenav: 'sidenavOff',
    symbols: [
      {
        symbol: 'SBER МБ ЦК',
        symbolObject: {
          symbol: 'SBER МБ ЦК',
          id: 144950,
          periodicity: { period: 1, interval: 1, timeUnit: 'day' },
          trueSymbol: 'SBER',
        },
        periodicity: 1,
        interval: 'day',
        timeUnit: null,
        setSpan: null,
      },
    ],
    range: null,
    studies: {
      '‌Donchian Channel‌ (50,50,n)': {
        type: 'Donchian Channel',
        inputs: {
          'High Period': '50',
          'Low Period': '50',
          'Channel Fill': false,
          id: '‌Donchian Channel‌ (50,50,n)',
          display: '‌Donchian Channel‌ (50,50,n)',
        },
        outputs: {
          'Donchian High': 'auto',
          'Donchian Median': { color: '#fff371' },
          'Donchian Low': { color: '#0077a1' },
        },
        panel: 'chart',
        parameters: {
          chartName: 'chart',
          editMode: true,
          yaxisDisplayValue: 'left',
          panelName: 'chart',
        },
      },
    },
  },
};

export const CHART_RIGHT_CHARTIQ_SETTINGS = {
  customSettings: {
    panels: {
      chart: { yAxis: { zoom: 42.689075630252105 } },
      '‌volume‌': { yAxis: { zoom: 2 } },
    },
    showOHLC: true,
    showOrders: true,
    showPositions: true,
    showDividends: true,
  },
  layout: {
    interval: 'week',
    periodicity: 1,
    timeUnit: null,
    candleWidth: 8.018518518518519,
    flipped: false,
    volumeUnderlay: false,
    adj: true,
    crosshair: true,
    chartType: 'candle',
    extended: false,
    marketSessions: {},
    aggregationType: 'ohlc',
    chartScale: 'linear',
    panels: {
      chart: {
        percent: 0.8,
        display: 'SBER МБ ЦК',
        chartName: 'chart',
        index: 0,
        yAxis: { name: 'chart', position: null },
        yaxisLHS: [],
        yaxisRHS: ['chart'],
      },
      '‌volume‌': {
        percent: 0.2,
        display: '‌volume‌',
        chartName: 'chart',
        index: 1,
        yAxis: { name: '‌volume‌' },
        yaxisLHS: [],
        yaxisRHS: ['‌volume‌'],
      },
    },
    setSpan: {},
    outliers: false,
    animation: true,
    headsUp: { static: true },
    sidenav: 'sidenavOff',
    symbols: [
      {
        symbol: 'SBER МБ ЦК',
        symbolObject: {
          symbol: 'SBER МБ ЦК',
          id: 144950,
          periodicity: { period: 1, interval: 1, timeUnit: 'day' },
          trueSymbol: 'SBER',
        },
        periodicity: 1,
        interval: 'week',
        timeUnit: null,
        setSpan: {},
      },
    ],
    range: {},
    studies: {
      '‌volume‌': {
        type: 'volume',
        inputs: { id: '‌volume‌', display: '‌volume‌' },
        outputs: {
          'Up Volume': { color: '#a0a0a5' },
          'Down Volume': { color: '#a0a0a5' },
        },
        panel: '‌volume‌',
        parameters: {
          chartName: 'chart',
          editMode: true,
          panelName: '‌volume‌',
        },
      },
      '‌ma‌ (20,ema,0,1)': {
        type: 'ma',
        inputs: {
          Period: '20',
          Field: 'field',
          Type: 'exponential',
          Offset: 0,
          'MA width': 1,
          id: '‌ma‌ (20,ema,0,1)',
          display: '‌ma‌ (20,ema,0,1)',
        },
        outputs: { MA: { color: '#FF0000', width: 1 } },
        panel: 'chart',
        parameters: {
          interactiveAdd: false,
          chartName: 'chart',
          editMode: true,
          panelName: 'chart',
        },
      },
      '‌ma‌ (50,ema,0,1)': {
        type: 'ma',
        inputs: {
          Period: 50,
          Field: 'field',
          Type: 'exponential',
          Offset: 0,
          'MA width': 1,
          id: '‌ma‌ (50,ema,0,1)',
          display: '‌ma‌ (50,ema,0,1)',
        },
        outputs: { MA: { color: '#0077a1', width: 1 } },
        panel: 'chart',
        parameters: {
          interactiveAdd: false,
          chartName: 'chart',
          editMode: true,
          panelName: 'chart',
        },
      },
    },
  },
};

export const TRADE_CHARTIQ_SETTINGS = {
  customSettings: {
    panels: {
      chart: { yAxis: { zoom: 42.689075630252105 } },
      '‌volume‌': { yAxis: { zoom: 2 } },
    },
    showOHLC: true,
    showOrders: true,
    showPositions: true,
    showDividends: true,
  },
  layout: {
    interval: 'day',
    periodicity: 1,
    timeUnit: null,
    candleWidth: 8.022900763358779,
    flipped: false,
    volumeUnderlay: false,
    adj: true,
    crosshair: true,
    chartType: 'candle',
    extended: false,
    marketSessions: {},
    aggregationType: 'ohlc',
    chartScale: 'linear',
    panels: {
      chart: {
        percent: 0.8,
        display: 'SBER МБ ЦК',
        chartName: 'chart',
        index: 0,
        yAxis: { name: 'chart', position: null },
        yaxisLHS: [],
        yaxisRHS: ['chart'],
      },
      '‌volume‌': {
        percent: 0.2,
        display: '‌volume‌',
        chartName: 'chart',
        index: 1,
        yAxis: { name: '‌volume‌' },
        yaxisLHS: [],
        yaxisRHS: ['‌volume‌'],
      },
    },
    setSpan: {},
    outliers: false,
    animation: true,
    sidenav: 'sidenavOff',
    headsUp: { static: true },
    symbols: [
      {
        symbol: 'SBER МБ ЦК',
        symbolObject: {
          symbol: 'SBER МБ ЦК',
          id: 144950,
          periodicity: { period: 1, interval: 1, timeUnit: 'day' },
          trueSymbol: 'SBER',
        },
        periodicity: 1,
        interval: 'day',
        timeUnit: null,
        setSpan: {},
      },
    ],
    studies: {
      '‌volume‌': {
        type: 'volume',
        inputs: { id: '‌volume‌', display: '‌volume‌' },
        outputs: {
          'Up Volume': { color: '#898989' },
          'Down Volume': { color: '#898989' },
        },
        panel: '‌volume‌',
        parameters: {
          chartName: 'chart',
          editMode: true,
          panelName: '‌volume‌',
        },
      },
      '‌ma‌ (50,ema,0,1)': {
        type: 'ma',
        inputs: {
          Period: 50,
          Field: 'field',
          Type: 'exponential',
          Offset: 0,
          'MA width': 1,
          id: '‌ma‌ (50,ema,0,1)',
          display: '‌ma‌ (50,ema,0,1)',
        },
        outputs: { MA: { color: '#5875b7', width: 1 } },
        panel: 'chart',
        parameters: {
          interactiveAdd: false,
          chartName: 'chart',
          editMode: true,
          panelName: 'chart',
        },
      },
      '‌ma‌ (100,ema,0,1)': {
        type: 'ma',
        inputs: {
          Period: '100',
          Field: 'field',
          Type: 'exponential',
          Offset: 0,
          'MA width': 1,
          id: '‌ma‌ (100,ema,0,1)',
          display: '‌ma‌ (100,ema,0,1)',
        },
        outputs: { MA: { color: '#FF0000', width: 1 } },
        panel: 'chart',
        parameters: {
          interactiveAdd: false,
          chartName: 'chart',
          editMode: true,
          panelName: 'chart',
        },
      },
    },
  },
};

export const TRADE_CHARTIQ_SETTINGS_NO_VOLUME = {
  customSettings: {
    panels: {
      chart: { yAxis: { zoom: 42.689075630252105 } },
    },
    showOHLC: true,
    showOrders: true,
    showPositions: true,
    showDividends: true,
  },
  layout: {
    interval: 'day',
    periodicity: 1,
    timeUnit: null,
    candleWidth: 8.022900763358779,
    flipped: false,
    volumeUnderlay: false,
    adj: true,
    crosshair: true,
    chartType: 'candle',
    extended: false,
    marketSessions: {},
    aggregationType: 'ohlc',
    chartScale: 'linear',
    panels: {
      chart: {
        percent: 0.8,
        display: 'SBER МБ ЦК',
        chartName: 'chart',
        index: 0,
        yAxis: { name: 'chart', position: null },
        yaxisLHS: [],
        yaxisRHS: ['chart'],
      },
    },
    setSpan: {},
    outliers: false,
    animation: true,
    sidenav: 'sidenavOff',
    headsUp: { static: true },
    symbols: [
      {
        symbol: 'SBER МБ ЦК',
        symbolObject: {
          symbol: 'SBER МБ ЦК',
          id: 144950,
          periodicity: { period: 1, interval: 1, timeUnit: 'day' },
          trueSymbol: 'SBER',
        },
        periodicity: 1,
        interval: 'day',
        timeUnit: null,
        setSpan: {},
      },
    ],
    studies: {
      '‌ma‌ (50,ema,0,1)': {
        type: 'ma',
        inputs: {
          Period: 50,
          Field: 'field',
          Type: 'exponential',
          Offset: 0,
          'MA width': 1,
          id: '‌ma‌ (50,ema,0,1)',
          display: '‌ma‌ (50,ema,0,1)',
        },
        outputs: { MA: { color: '#5875b7', width: 1 } },
        panel: 'chart',
        parameters: {
          interactiveAdd: false,
          chartName: 'chart',
          editMode: true,
          panelName: 'chart',
        },
      },
      '‌ma‌ (100,ema,0,1)': {
        type: 'ma',
        inputs: {
          Period: '100',
          Field: 'field',
          Type: 'exponential',
          Offset: 0,
          'MA width': 1,
          id: '‌ma‌ (100,ema,0,1)',
          display: '‌ma‌ (100,ema,0,1)',
        },
        outputs: { MA: { color: '#FF0000', width: 1 } },
        panel: 'chart',
        parameters: {
          interactiveAdd: false,
          chartName: 'chart',
          editMode: true,
          panelName: 'chart',
        },
      },
    },
  },
};

export const CHARTS_WEEK_CHARTIQ_SETTINGS = {
  customSettings: {
    panels: {
      chart: {
        yAxis: {
          zoom: 39.235577377018224,
        },
      },
      '‌volume‌': {
        yAxis: {
          zoom: 1.6213308164228408,
        },
      },
      '‌macd‌ (12,26,9,1,1)': {
        yAxis: {
          zoom: 4,
        },
      },
    },
    showOHLC: true,
    showOrders: true,
    showPositions: true,
    showDividends: true,
  },
  layout: {
    interval: 'week',
    periodicity: 1,
    timeUnit: null,
    candleWidth: 8.014925373134329,
    flipped: false,
    volumeUnderlay: false,
    adj: true,
    crosshair: true,
    chartType: 'candle',
    extended: false,
    marketSessions: {},
    aggregationType: 'ohlc',
    chartScale: 'linear',
    panels: {
      chart: {
        percent: 0.64,
        display: 'SBER МБ ЦК',
        chartName: 'chart',
        index: 0,
        yAxis: {
          name: 'chart',
          position: null,
        },
        yaxisLHS: [],
        yaxisRHS: ['chart'],
      },
      '‌volume‌': {
        percent: 0.16,
        display: '‌volume‌',
        chartName: 'chart',
        index: 1,
        yAxis: {
          name: '‌volume‌',
        },
        yaxisLHS: [],
        yaxisRHS: ['‌volume‌'],
      },
      '‌macd‌ (12,26,9,1,1)': {
        percent: 0.20000000000000004,
        display: '‌macd‌ (12,26,9,1,1)',
        chartName: 'chart',
        index: 2,
        yAxis: {
          name: '‌macd‌ (12,26,9,1,1)',
        },
        yaxisLHS: [],
        yaxisRHS: ['‌macd‌ (12,26,9,1,1)'],
      },
    },
    setSpan: {},
    outliers: false,
    animation: true,
    headsUp: {
      static: true,
    },
    sidenav: 'sidenavOff',
    symbols: [
      {
        symbol: 'SBER МБ ЦК',
        symbolObject: {
          symbol: 'SBER МБ ЦК',
          id: 144950,
          periodicity: {
            period: 1,
            interval: 1,
            timeUnit: 'day',
          },
          trueSymbol: 'SBER',
        },
        periodicity: 1,
        interval: 'week',
        timeUnit: null,
        setSpan: {},
      },
    ],
    range: {},
    studies: {
      '‌volume‌': {
        type: 'volume',
        inputs: {
          id: '‌volume‌',
          display: '‌volume‌',
        },
        outputs: {
          'Up Volume': {
            color: '#898989',
          },
          'Down Volume': {
            color: '#626262',
          },
        },
        panel: '‌volume‌',
        parameters: {
          chartName: 'chart',
          editMode: true,
          panelName: '‌volume‌',
        },
      },
      '‌ma‌ (50,ema,0,1)': {
        type: 'ma',
        inputs: {
          Period: 14,
          Field: 'field',
          Type: 'exponential',
          Offset: 0,
          'MA width': 1,
          id: '‌ma‌ (50,ema,0,1)',
          display: '‌ma‌ (50,ema,0,1)',
        },
        outputs: {
          MA: {
            color: '#5875b7',
            width: 1,
          },
        },
        panel: 'chart',
        parameters: {
          interactiveAdd: false,
          chartName: 'chart',
          editMode: true,
          panelName: 'chart',
        },
      },
      '‌ma‌ (100,ema,0,1)': {
        type: 'ma',
        inputs: {
          Period: '7',
          Field: 'field',
          Type: 'exponential',
          Offset: 0,
          'MA width': 1,
          id: '‌ma‌ (100,ema,0,1)',
          display: '‌ma‌ (100,ema,0,1)',
        },
        outputs: {
          MA: {
            color: '#FF0000',
            width: 1,
          },
        },
        panel: 'chart',
        parameters: {
          interactiveAdd: false,
          chartName: 'chart',
          editMode: true,
          panelName: 'chart',
        },
      },
      '‌macd‌ (12,26,9,1,1)': {
        type: 'macd',
        inputs: {
          'Fast MA Period': 12,
          'Slow MA Period': 26,
          'Signal Period': 9,
          'MACD width': 1,
          'Signal width': 1,
          id: '‌macd‌ (12,26,9,1,1)',
          display: '‌macd‌ (12,26,9,1,1)',
        },
        outputs: {
          MACD: 'auto',
          Signal: '#FF0000',
          'Increasing Bar': '#2FC26E',
          'Decreasing Bar': '#EF3124',
        },
        panel: '‌macd‌ (12,26,9,1,1)',
        parameters: {
          chartName: 'chart',
          editMode: true,
        },
      },
    },
  },
};

export const CHARTS_HOUR_CHARTIQ_SETTINGS = {
  customSettings: {
    panels: {
      chart: {
        yAxis: {
          zoom: 39.091681616466815,
        },
      },
      '‌volume‌': {
        yAxis: {
          zoom: 1.6153846153846152,
        },
      },
    },
    showOHLC: true,
    showOrders: true,
    showPositions: true,
    showDividends: true,
  },
  layout: {
    interval: 1,
    periodicity: 60,
    timeUnit: 'minute',
    candleWidth: 8.014925373134329,
    flipped: false,
    volumeUnderlay: false,
    adj: true,
    crosshair: true,
    chartType: 'candle',
    extended: false,
    marketSessions: {},
    aggregationType: 'ohlc',
    chartScale: 'linear',
    panels: {
      chart: {
        percent: 0.8,
        display: 'SBER МБ ЦК',
        chartName: 'chart',
        index: 0,
        yAxis: {
          name: 'chart',
          position: null,
        },
        yaxisLHS: [],
        yaxisRHS: ['chart'],
      },
      '‌volume‌': {
        percent: 0.2,
        display: '‌volume‌',
        chartName: 'chart',
        index: 1,
        yAxis: {
          name: '‌volume‌',
        },
        yaxisLHS: [],
        yaxisRHS: ['‌volume‌'],
      },
    },
    setSpan: {},
    outliers: false,
    animation: true,
    headsUp: {
      static: true,
    },
    sidenav: 'sidenavOff',
    symbols: [
      {
        symbol: 'SBER МБ ЦК',
        symbolObject: {
          symbol: 'SBER МБ ЦК',
          id: 144950,
          periodicity: {
            period: 1,
            interval: 1,
            timeUnit: 'day',
          },
          trueSymbol: 'SBER',
        },
        periodicity: 60,
        interval: 1,
        timeUnit: 'minute',
        setSpan: {},
      },
    ],
    range: {},
    studies: {
      '‌volume‌': {
        type: 'volume',
        inputs: {
          id: '‌volume‌',
          display: '‌volume‌',
        },
        outputs: {
          'Up Volume': {
            color: '#898989',
          },
          'Down Volume': {
            color: '#898989',
          },
        },
        panel: '‌volume‌',
        parameters: {
          chartName: 'chart',
          editMode: true,
          panelName: '‌volume‌',
        },
      },
      '‌PSAR‌ (0.02,0.2)': {
        type: 'PSAR',
        inputs: {
          'Minimum AF': 0.02,
          'Maximum AF': 0.2,
          id: '‌PSAR‌ (0.02,0.2)',
          display: '‌PSAR‌ (0.02,0.2)',
        },
        outputs: {
          Result: 'auto',
        },
        panel: 'chart',
        parameters: {
          chartName: 'chart',
          editMode: true,
        },
      },
    },
  },
};
