import { ComponentClass, FC, SVGProps, VFC } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Typography } from '@alfalab/core-components/typography';

import styles from './ErrorStub.module.css';

export type ErrorStubProps = {
  title: string;
  description: string;
  icon: ComponentClass<SVGProps<SVGSVGElement>> | FC<SVGProps<SVGSVGElement>>;
  loading: boolean;
  onRetry: () => void;
};

export const ErrorStub: VFC<ErrorStubProps> = (props) => {
  const { title, description, icon: Icon, loading, onRetry } = props;

  return (
    <div className={styles.card}>
      <Icon className={styles.icon} color="#747474" />
      <Typography.Text
        tag="div"
        view="primary-large"
        color="tertiary-inverted"
        className={styles.title}
      >
        {title}
      </Typography.Text>
      <Typography.Text
        tag="div"
        view="secondary-large"
        color="secondary"
        className={styles.description}
      >
        {description}
      </Typography.Text>
      <ButtonDesktop
        size="xs"
        loading={loading}
        onClick={onRetry}
        className={styles.button}
      >
        Обновить
      </ButtonDesktop>
    </div>
  );
};
