import difference from 'lodash/difference';
import groupBy from 'lodash/groupBy';
import uniq from 'lodash/uniq';
import { useCallback, useMemo } from 'react';

import { useFilteredSubAccounts } from '@terminal/alfadirect/hooks';
import {
  AccountOption,
  SubAccountOption,
} from '@terminal/common/model/types/account';

import { useWidgetContext } from '../../../shared';
import { createAccountsTree } from '../lib/createAccountsTree';
import { createOptionsForSelect } from '../lib/createOptionsForSelect';
import { getRequirementBySubAccount } from '../lib/getRequirementBySubAccount';

import { useWidgetRequirement } from '../../../shared/hooks/useWidgetRequirement';

type Options = {
  isShouldBeVisible: boolean;
  isOrderBookVersion?: boolean;
  selectedSubAccounts: string[];
  searchText: string;
  onSave: (accounts: string[]) => void;
};

export const useAccountFilter = (props: Options) => {
  const {
    isShouldBeVisible,
    isOrderBookVersion,
    selectedSubAccounts,
    searchText,
    onSave,
  } = props;
  const { useTreaties } = useWidgetContext();
  const { data: treaties } = useTreaties();
  const filteredSubAccounts = useFilteredSubAccounts();
  const subAccounts = Object.keys(filteredSubAccounts);

  const isEverySubAccountSelected =
    selectedSubAccounts.length === subAccounts.length;

  const accountsTree = useMemo(() => {
    if (!isShouldBeVisible) {
      return {};
    }

    // группируем дерево отфильтрованных субаккаунтов по idAccount
    const filteredSubAccountsFlat = Object.values(filteredSubAccounts).flat();
    const accountsMap = groupBy(filteredSubAccountsFlat, 'idAccount');

    return createAccountsTree(accountsMap);
  }, [isShouldBeVisible, filteredSubAccounts]);

  const onSelect = useCallback(
    (option: AccountOption | SubAccountOption) => {
      const { value, isAccount } = option;
      let selected = selectedSubAccounts.slice();

      // Логика версии для стакана. Можно выбрать только субсчет и только 1
      if (isOrderBookVersion) {
        if (isAccount) {
          return;
        }

        onSave([value]);

        return;
      }

      // Если выбрали счет
      if (isAccount) {
        const subAccountsOfAccount = accountsTree[value].map(
          (subAccount) => subAccount.codeSubAccount
        );
        const subAccountsInSelected = selected.filter((subAccount) =>
          subAccountsOfAccount.includes(subAccount)
        );
        const isEverySubAccountSelected =
          subAccountsOfAccount.length === subAccountsInSelected.length;

        // Удаляем счет из выбранных
        selected = selected.filter((item) => item !== value);

        // Если все субсчета этого счета выбраны, удаляем все. Иначе добавляем оставшиеся
        if (isEverySubAccountSelected) {
          selected = difference(selected, subAccountsOfAccount);
        } else {
          selected = uniq([...selected, ...subAccountsOfAccount]);
        }
      }
      // Если выбрали субсчет
      else {
        const isOptionSelected = selected.includes(value);

        if (isOptionSelected) {
          selected = selected.filter((option) => option !== value);
        } else {
          selected.push(value);
        }
      }

      // Проверяем, остался ли хоть один выбранный счет, если не осталось выбранных счетов, выбираем все
      if (selected.length === 0) {
        selected = subAccounts.slice();
      }

      onSave(selected);
    },
    [accountsTree, isOrderBookVersion, onSave, selectedSubAccounts, subAccounts]
  );

  const onSelectAll = useCallback(() => {
    // запрещаем отключать "Все счета", можно только включать
    if (!isEverySubAccountSelected) {
      onSave(subAccounts);
    }
  }, [isEverySubAccountSelected, onSave, subAccounts]);

  const marginCall = useWidgetRequirement();

  const hasRequirements = useMemo(
    () =>
      Object.values(marginCall).some(
        ({ immediateRequirements, requirements }) =>
          immediateRequirements || requirements
      ),
    [marginCall]
  );
  const selectedAccRequirement = useMemo(() => {
    if (isOrderBookVersion) {
      return getRequirementBySubAccount(marginCall, selectedSubAccounts[0]);
    }
  }, [marginCall, isOrderBookVersion, selectedSubAccounts]);

  const isDifferentAccountOwners = useMemo(() => {
    const allClientNames = Object.values(accountsTree).flatMap((subAccounts) =>
      subAccounts.map((subAccount) => subAccount.nameClient)
    );

    return uniq(allClientNames).length > 1;
  }, [accountsTree]);

  const options = useMemo(() => {
    if (!isShouldBeVisible) {
      return [];
    }

    return createOptionsForSelect(
      accountsTree,
      selectedSubAccounts,
      searchText,
      marginCall,
      isDifferentAccountOwners,
      treaties
    );
  }, [
    isShouldBeVisible,
    accountsTree,
    selectedSubAccounts,
    searchText,
    marginCall,
    treaties,
    isDifferentAccountOwners,
  ]);

  return {
    options,
    selected: selectedSubAccounts,
    onSelect,
    onSelectAll,
    hasRequirements,
    selectedAccRequirement,
  };
};
