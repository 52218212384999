import {
  endOfDay,
  format,
  isBefore,
  parseISO,
  startOfDay,
  subMonths,
} from 'date-fns';
import get from 'lodash/get';
import {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Amount } from '@alfalab/core-components/amount';
import { Loader } from '@alfalab/core-components/loader';

import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';
import { BlueprintTable, CellRenderProps } from '@terminal/common/ui/Table';
import { getDefaultOperationshistorySetting } from '@terminal/core/constants/tableProps';
import { useFilterTable } from '@terminal/core/hooks/useFilterTable';
import {
  dateToRequestFormat,
  DEFAULT_VIEW_DATE_FORMAT,
} from '@terminal/core/lib/dateToRequestFormat';
import {
  getMinority,
  getStringDate,
  getStringTime,
} from '@terminal/core/lib/format';
import { Sort } from '@terminal/core/types/layout';
import { MoneyHistoryRequest } from '@terminal/core/types/money';
import {
  DateFilterType,
  DateFilterValue,
  MoneyHistoryItem,
  OperationCommonItem,
  OperationHistoryItem,
} from '@terminal/core/types/operation';
import { OrderDirectionMap } from '@terminal/core/types/order';
import { TableColumnKey } from '@terminal/core/types/tableColumn';

import { SymbolCell } from '../../../../features/SymbolCell';
import {
  defaultColumnFilterRender,
  OperationsHistoryParams,
  useWidgetContext,
} from '../../../../shared';
import { HistoryHeader } from '../HistoryHeader';
import { onTableOrder } from './helpers';

import { useSortTable } from '../../../../shared/hooks/useSortTable';

import styles from './OperationsHistory.module.css';

const MONEY_TRANSFER_TYPE_TEXT = {
  EXTRA_IN: 'Зачисление',
  EXTRA_OUT: 'Списание',
  INTRA: 'Внутренний перевод',
};

const TODAY_START = startOfDay(new Date());
const TODAY_END = endOfDay(new Date());
const defaultDateFilter: DateFilterValue = {
  type: DateFilterType['30DAYS'],
  valueFrom: {
    value: format(subMonths(TODAY_START, 1), DEFAULT_VIEW_DATE_FORMAT),
    date: subMonths(TODAY_START, 1).getTime(),
  },
  valueTo: {
    value: format(TODAY_END, DEFAULT_VIEW_DATE_FORMAT),
    date: TODAY_END.getTime(),
  },
};

export const isInvestOperation = (
  props: OperationCommonItem
): props is OperationHistoryItem => props.type === 'INVEST';

export interface Props {
  parentNodeId?: string;
  selectedSubAccounts: string[];
}

export const OperationsHistory = (props: Props) => {
  const { parentNodeId, selectedSubAccounts } = props;

  const [currentSelectedSubAccounts, setCurrentSelectedSubAccounts] = useState([
    selectedSubAccounts[0],
  ]);
  const [columnSetting, setColumnSetting] = useState(
    getDefaultOperationshistorySetting()
  );
  const [sort, setSort] = useState<Sort | undefined>();

  const [dateFilter, setDateFilter] = useState<DateFilterValue | null>(
    defaultDateFilter
  );
  const { useSettings, useMoneyHistory, useOperationsHistory } =
    useWidgetContext();
  const { objectsTable } = useAlfaDirectContext();
  const {
    defaultValues: { selectedDisplayInstrumentType },
  } = useSettings();

  useEffect(
    () =>
      setColumnSetting(
        getDefaultOperationshistorySetting(selectedDisplayInstrumentType)
      ),
    [selectedDisplayInstrumentType]
  );

  const valueFromDate = dateFilter?.valueFrom?.date ?? 0;
  const valueToDate = dateFilter?.valueTo?.date ?? Date.now();

  const historyParams = useMemo<OperationsHistoryParams>(() => {
    return {
      subAccount: currentSelectedSubAccounts[0],
      dateFrom: new Date(valueFromDate).toISOString(),
      dateTo: new Date(valueToDate).toISOString(),
    };
  }, [valueFromDate, valueToDate, currentSelectedSubAccounts]);

  const { data, isFetching } = useOperationsHistory(historyParams);

  const historyMoneyParams = useMemo<MoneyHistoryRequest>(() => {
    return {
      types: ['TRADES', 'TRANSFERS'],
      treaty: parseInt(currentSelectedSubAccounts[0].split('-')[0]),
      startDate: dateToRequestFormat(
        format(new Date(valueFromDate), DEFAULT_VIEW_DATE_FORMAT)
      ),
      endDate: dateToRequestFormat(
        format(new Date(valueToDate), DEFAULT_VIEW_DATE_FORMAT)
      ),
      page: 1,
      count: 100,
      subAccount: currentSelectedSubAccounts[0],
    };
  }, [valueFromDate, valueToDate, currentSelectedSubAccounts]);

  const {
    data: history,
    isFetching: historyPending,
    //TODO: Оставил пока так, у нас сейчас таблицы без пагинации
    // isFetchingNextPage: historyPendingNext,
    // hasNextPage: hasMoreHistory,
    // fetchNextPage: fetchMoreHistory,
  } = useMoneyHistory(historyMoneyParams);
  const historyItems = useMemo(() => {
    if (history) {
      const items = history.pages.flatMap((page) => page.transfers);

      return items.map((item) => ({
        ...item,
        operation: { idOperation: item.id, timeOperation: new Date(item.date) },
        operationDate: new Date(item.date).toDateString(),
      }));
    }

    return null;
  }, [history]);

  const tableData = useMemo(
    () => [...(data ?? []), ...(historyItems ?? [])],
    [data, historyItems]
  );

  const { filteredData, filter, onFilter } = useFilterTable({
    data: tableData,
  });
  const { sortedData, onSort } = useSortTable({
    data: filteredData,
    sort,
    setSort,
    onOrder: onTableOrder,
  });

  const localNodeUpdater = useCallback<
    ComponentProps<typeof HistoryHeader>['updateNode']
  >((_nodeId, config, _symbol) => {
    const newSubAccounts = config.tableProps?.accounts;

    if (newSubAccounts?.length) {
      setCurrentSelectedSubAccounts([newSubAccounts[0]]);
    }
  }, []);

  const cellRender: CellRenderProps<OperationCommonItem> = (
    rowIndex,
    columnIndex,
    columnKey,
    data
  ) => {
    const row = data[rowIndex];

    if (isInvestOperation(row)) {
      const { operation, object } = row;

      switch (columnKey) {
        case TableColumnKey.NameObject:
        case TableColumnKey.SymbolObject:
          return (
            <SymbolCell
              idObject={object?.idObject}
              symbolObject={object?.symbolObject}
            />
          );
        case TableColumnKey.OperationCurrency:
          return operation.instrumentValue?.unit;
        case TableColumnKey.OperationAccount:
        case TableColumnKey.MarketNameMarketBoard:
        case TableColumnKey.OperationQuantity:
          return get(data[rowIndex], columnKey);
        case TableColumnKey.OperationTime:
          return getStringTime(operation.timeOperation);
        case TableColumnKey.OperationDate:
          return getStringDate(operation.timeOperation);
        case TableColumnKey.OperationSettleDate:
          return getStringDate(get(data[rowIndex], columnKey));
        case TableColumnKey.OperationBuySell:
          return (
            <span
              className={operation.buySell === 1 ? styles.buy : styles.sell}
            >
              {OrderDirectionMap.get(operation.buySell)}
            </span>
          );
        case TableColumnKey.OperationPrice:
          const value = operation.price;
          //Подсчитываем минорные единицы
          const minority = getMinority(value);

          return (
            <Amount.Pure
              value={value * minority}
              minority={minority}
              view="withZeroMinorPart"
            />
          );
        case TableColumnKey.OperationValue:
          return (
            <div className={operation.buySell === 1 ? styles.buy : styles.sell}>
              <Amount.Pure
                value={operation.value * 100}
                minority={100}
                view="withZeroMinorPart"
              />
            </div>
          );
        case TableColumnKey.OperationIsSettled:
          return operation.settleDate &&
            isBefore(operation.settleDate, TODAY_START)
            ? 'Да'
            : 'Нет';

        default:
          return null;
      }
    } else {
      const operation = row as MoneyHistoryItem;
      const target = operation?.target || operation?.source;
      const object = objectsTable
        .toArray()
        .find(({ symbolObject }) => symbolObject === operation.currency);

      switch (columnKey) {
        case TableColumnKey.NameObject:
        case TableColumnKey.SymbolObject:
          return (
            <SymbolCell
              idObject={object?.idObject}
              symbolObject={operation.currency}
            />
          );
        case TableColumnKey.OperationCurrency:
          return operation.currency;
        case TableColumnKey.MarketNameMarketBoard:
          return target?.placeName;
        case TableColumnKey.OperationDate:
          return format(parseISO(operation.date), 'dd.LL.uuuu');
        case TableColumnKey.OperationTime:
          return format(parseISO(operation.date), 'HH:mm:ss');
        case TableColumnKey.OperationBuySell:
          return <span>{MONEY_TRANSFER_TYPE_TEXT[operation.type]}</span>;
        case TableColumnKey.OperationValue:
          return (
            <div
              className={(target?.amount ?? 0) >= 0 ? styles.buy : styles.sell}
            >
              <Amount.Pure
                value={(target?.amount ?? 0) * 100}
                minority={100}
                view="withZeroMinorPart"
              />
            </div>
          );

        default:
          return null;
      }
    }
  };

  return (
    <div className={styles.container}>
      <HistoryHeader
        updateNode={localNodeUpdater}
        nodeId={parentNodeId}
        selectedSubAccounts={currentSelectedSubAccounts}
        dateFilter={dateFilter}
        setDateFilter={setDateFilter}
      />
      {isFetching || historyPending ? (
        <Loader className={styles.loader} />
      ) : (
        <BlueprintTable<OperationCommonItem>
          filter={filter}
          onFilter={onFilter}
          sort={sort}
          onSort={onSort}
          data={sortedData}
          columnSetting={columnSetting}
          columnFilterRender={defaultColumnFilterRender}
          cellRender={cellRender}
          onReorder={setColumnSetting}
          onChangeWidth={setColumnSetting}
        />
      )}
    </div>
  );
};
