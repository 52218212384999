import { ButtonDesktop as Button } from '@alfalab/core-components/button/desktop';

import { orderTypeAbbrMap } from '@terminal/core/constants/orders';
import { OrderType } from '@terminal/core/lib/client/entities';
import { evaluatePricePosition } from '@terminal/core/lib/orderBook/evaluatePricePosition';

import { useOrderBookContext } from '../../../../context';

import styles from './OrderFormButton.module.css';

interface OrderFormButtonsProps {
  price: number;
  quantity: number;
  upSpreadPrice?: number;
  downSpreadPrice?: number;
  isDisabledButtons: boolean;
  onLMT: (buy: boolean, bestOffer: boolean, tralling: boolean) => void;
  onStop: (buy: boolean, tralling: boolean) => void;
  compactView: boolean;
}

export const OrderFormButtons = ({
  price,
  quantity,
  upSpreadPrice,
  downSpreadPrice,
  onLMT,
  onStop,
  isDisabledButtons,
  compactView,
}: OrderFormButtonsProps) => {
  const { sendTrallingOrders, stopOrderType } = useOrderBookContext();
  const isSendTrallingOrders = Boolean(sendTrallingOrders);

  const { isAboveSpread, isBelowSpread, isWithinSpread } =
    evaluatePricePosition(price, {
      upperSpreadPrice: upSpreadPrice,
      maxBuyPrice: downSpreadPrice,
    });

  const isEmpty = !upSpreadPrice && !downSpreadPrice;

  const limitOrderAbbr = isSendTrallingOrders
    ? orderTypeAbbrMap[OrderType.TRL]
    : orderTypeAbbrMap[OrderType.LMT];
  const stopOrderAbbr = isSendTrallingOrders
    ? orderTypeAbbrMap[OrderType.TRS]
    : orderTypeAbbrMap[stopOrderType || OrderType.STP];

  if (isBelowSpread) {
    return (
      <>
        <Button
          size="xxs"
          onClick={() => {
            onLMT(true, false, isSendTrallingOrders);
          }}
          block
          className={styles.buyButton}
          disabled={isDisabledButtons}
        >
          {!compactView ? 'Купить' : ''} {quantity}&nbsp;
          {limitOrderAbbr}
        </Button>
        <Button
          size="xxs"
          block
          className={styles.sellButton}
          onClick={() => onStop(false, isSendTrallingOrders)}
          disabled={isDisabledButtons}
        >
          {!compactView ? 'Продать' : ''} {quantity}&nbsp;
          {stopOrderAbbr}
        </Button>
        <Button
          size="xxs"
          onClick={() => onLMT(false, false, isSendTrallingOrders)}
          block
          className={styles.sellButton}
          disabled={isDisabledButtons}
        >
          {!compactView ? 'Продать' : ''} {quantity}&nbsp;
          {limitOrderAbbr}
        </Button>
      </>
    );
  }

  if (isAboveSpread) {
    return (
      <>
        <Button
          size="xxs"
          block
          className={styles.buyButton}
          onClick={() => {
            onLMT(true, false, isSendTrallingOrders);
          }}
          disabled={isDisabledButtons}
        >
          {!compactView ? 'Купить' : ''} {quantity}&nbsp;
          {limitOrderAbbr}
        </Button>
        <Button
          size="xxs"
          block
          className={styles.buyButton}
          onClick={() => onStop(true, isSendTrallingOrders)}
          disabled={isDisabledButtons}
        >
          {!compactView ? 'Купить' : ''} {quantity}&nbsp;
          {stopOrderAbbr}
        </Button>
        <Button
          size="xxs"
          block
          className={styles.sellButton}
          onClick={() => {
            onLMT(false, false, isSendTrallingOrders);
          }}
          disabled={isDisabledButtons}
        >
          {!compactView ? 'Продать' : ''} {quantity}&nbsp;
          {limitOrderAbbr}
        </Button>
      </>
    );
  }

  if (isWithinSpread) {
    return (
      <>
        <Button
          size="xxs"
          block
          className={styles.buyButton}
          onClick={() => {
            onLMT(true, false, isSendTrallingOrders);
          }}
          disabled={isDisabledButtons}
        >
          {!compactView && 'Купить'} {price} {limitOrderAbbr}
        </Button>
        <Button
          size="xxs"
          block
          className={styles.sellButton}
          onClick={() => {
            onLMT(false, false, isSendTrallingOrders);
          }}
          disabled={isDisabledButtons}
        >
          {!compactView && 'Продать'} {price} {limitOrderAbbr}
        </Button>
      </>
    );
  }

  return (
    <>
      <Button
        size="xxs"
        block
        className={styles.buyButton}
        onClick={() => {
          onLMT(true, true, isSendTrallingOrders);
        }}
        disabled={isDisabledButtons || isEmpty}
      >
        {compactView
          ? `Лучший ${quantity || ''} бид`
          : `Купить ${quantity || ''} Лучший бид`}
      </Button>
      <Button
        size="xxs"
        block
        className={styles.sellButton}
        onClick={() => {
          onLMT(false, true, isSendTrallingOrders);
        }}
        disabled={isDisabledButtons || isEmpty}
      >
        {compactView
          ? `Лучший ${quantity || ''} аск`
          : `Продать ${quantity || ''} Лучший аск`}
      </Button>
    </>
  );
};
