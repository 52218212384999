import {
  closestCenter,
  DndContext,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { useMemo } from 'react';

import { getIsColumnStatic } from '@terminal/core/lib/helpers/getIsColumnStatic';

import { Item } from '../Item';
import { SortableItem } from '../SortableItem';

import { SortableGridProps } from '../../types';

import styles from './SortableGrid.module.css';

export const SortableGrid = (props: SortableGridProps) => {
  const {
    items,
    flatItems,
    columnsSettingOptions,
    handleDragStart,
    handleDragEnd,
    handleDragCancel,
    handleRemove,
    handleSelection,
    activeId,
    widgetType,
  } = props;

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const activeIndex = useMemo(
    () => items.findIndex(({ id }) => id === activeId),
    [activeId, items]
  );
  const overlayColumn = items.find(({ id }) => id === activeId);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={items} strategy={rectSortingStrategy}>
        <div className={styles.doubleRowGrid}>
          {items.map((column, index) => (
            <SortableItem
              key={column.id}
              id={String(column.id)}
              column={column}
              index={index}
              onRemove={
                getIsColumnStatic(column, widgetType)
                  ? undefined
                  : () => handleRemove(column.id)
              }
              activeIndex={activeIndex}
              columnsSettingOptions={columnsSettingOptions}
              items={items}
              flatItems={flatItems}
              handleSelection={handleSelection}
              widgetType={widgetType}
            />
          ))}
        </div>
      </SortableContext>
      <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
        {activeId ? (
          <Item
            isDragging
            id={String(activeId)}
            column={overlayColumn}
            index={activeIndex}
            columnsSettingOptions={columnsSettingOptions}
            items={items}
            flatItems={flatItems}
            handleSelection={handleSelection}
            widgetType={widgetType}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};
