import React, { FC, useState } from 'react';
import { Loader } from '@alfalab/core-components/loader';

import {
  AccountInfoAI,
  AccountInfoGI,
  isFeatureHidden,
  Treaty,
  useFeatureSettings,
} from '@terminal/lk-core';
import {
  AccessLevelInfo,
  AccessList,
  ManagementResult,
  ManagementResultType,
  Subaccounts,
} from '@terminal/lk-desktop-common';

import styles from './Management.module.css';

interface ManagementProps {
  treaty: Treaty | null;
  accountInfo?: AccountInfoAI | AccountInfoGI;
  accountInfoPending: boolean;
  refetchAccountInfo: () => void;
  onSubaccountAdd: () => void;
  onSubaccountRedacted: () => void;
  withSubAccounts?: boolean;
  accessLevels: AccessLevelInfo[];
}

export const Management: FC<ManagementProps> = (props) => {
  const {
    treaty,
    accountInfo,
    accountInfoPending,
    refetchAccountInfo,
    onSubaccountAdd,
    onSubaccountRedacted,
    withSubAccounts,
    accessLevels,
  } = props;
  const [managementResult, setManagementResult] =
    useState<ManagementResultType>();

  const { data: featureSettings, isFetching: areFeatureSettingsFetching } =
    useFeatureSettings();

  const hideAttorneyCreation = isFeatureHidden(
    'create-power-of-attorney',
    featureSettings
  );

  if (accountInfoPending || areFeatureSettingsFetching) {
    return (
      <div className={styles.container}>
        <Loader />
      </div>
    );
  }

  if (!accountInfo || !treaty) {
    return null;
  }

  if (managementResult) {
    return (
      <ManagementResult
        icon={managementResult.icon}
        title={managementResult.message}
        description={managementResult.description}
        onClose={() => {
          setManagementResult(undefined);
        }}
      />
    );
  }

  return (
    <>
      <div className={styles.subaccounts}>
        <Subaccounts
          treaty={treaty}
          subaccounts={accountInfo.subAccounts}
          indLimit={accountInfo.indLimit}
          onSubaccountAdd={onSubaccountAdd}
          onSubaccountRedacted={onSubaccountRedacted}
          showSubAccountsOnTable={withSubAccounts}
        />
      </div>
      {accountInfo.treatyManagers ? (
        <div className={styles.access}>
          <AccessList
            managers={accountInfo.treatyManagers}
            treaty={treaty}
            subAccounts={accountInfo.subAccounts}
            setResult={setManagementResult}
            refetchAccountInfo={refetchAccountInfo}
            hideAttorneyCreation={hideAttorneyCreation}
            withSubAccounts={withSubAccounts}
            accessLevels={accessLevels}
          />
        </div>
      ) : null}
    </>
  );
};
