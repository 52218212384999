import { IJsonModel } from 'flexlayout-react';
import { ReactNode } from 'react';

import { ObjectGroup, OrderStatus } from '../lib/client/entities';
import { ScreenerBondEntity } from './bondScreener';
import { PUShowMode } from './subAccountPosition';
import { TableColumnKey } from './tableColumn';
import { TradeLimitsResult } from './tradeLimits';
import { SlippageType, StopOrderType } from './ui';

export enum FilterTypes {
  Text = 'text',
  Array = 'array',
  DateRange = 'dateRange',
  CalendarRange = 'calendarRange',
  ComplexDateRange = 'complexDateRange',
  NumberRange = 'numberRange',
  NumberRangeDigits = 'numberRangeDigits',
  RadioSelect = 'radioSelect',
  Toggle = 'toggle',
}

export type FilterType =
  | FilterTypes.Text
  | FilterTypes.Array
  | FilterTypes.DateRange
  | FilterTypes.CalendarRange
  | FilterTypes.NumberRange
  | FilterTypes.NumberRangeDigits
  | FilterTypes.RadioSelect
  | FilterTypes.Toggle
  | FilterTypes.ComplexDateRange;

export type MultiSelectItemObject = {
  value: string | number | boolean;
  name: string;
  content?: ReactNode;
  group?: string;
  description?: string;
};

export type FilterSettings = {
  filterItems?: string[] | MultiSelectItemObject[];
  withSearch?: boolean;
  toggleItem?: string;
  min?: number;
  max?: number;
  step?: number;
  maxDate?: Date;
};

export interface Filter {
  key: string;
  type: FilterType;
  value?: any;
  filterItems?: string[];
}

export type ColumnFilterRenderProps = (
  columnKey: TableColumnKey,
  filterType: FilterType,
  filter?: Filter,
  filterSettings?: FilterSettings,
  onFilter?: (filter?: Filter) => void
) => ReactNode;

export enum Widget {
  WATCHLIST = 'watchlist',
  TRADES = 'trades',
  ORDERBOOK = 'orderbook',
  CHART = 'chartiq',
  POSITIONS = 'positions',
  OPERATIONS = 'operations',
  ORDERS = 'orders',
  BALANCE = 'balance',
  HYBRIDORDER = 'hybridorder',
  OPERATIONS_HISTORY = 'operations_history',
  NEWS = 'news',
  INSTRUMENT_INFO = 'instrument_info',
  BOND_SCREENER = 'bond_screener',
}

export enum TradingLimitsShowMode {
  NONE = 0,
  MONEY = 1,
  // Для NPU = Проценты, для лимитов = Штуки/Лоты(см. общую настройку отображения)
  QUANTITY = 2,
  // Для NPU = Пункты
  POINTS = 3,
}

export interface TableColumnSetting {
  key: TableColumnKey;
  // Для случаев когда название колонки не является текстом
  renderHeader?: ReactNode;
  /**
   * @deprecated используйте поле `renderHeader` или key для определения имени: `@terminal/core/lib/helpers/getColumnName`
   * */
  name?: string | JSX.Element;
  /**
   * @deprecated для определения описания используйте `@terminal/core/lib/helpers/getColumnDescription`
   * */
  description?: string;
  selected?: boolean;
  cellClassName?: string;
  headerCellClassName?: string;
  filterType?: FilterType;
  filterSettings?: FilterSettings;
  width?: number;
  secondRow?: TableColumnSetting;
  isDragDisabled?: boolean;
  withSort?: boolean;
}

export interface Sort {
  key: string;
  asc: boolean;
}

export interface TableProps {
  columns?: TableColumnSetting[];
  accounts?: string[];
  orderStatus?: OrderStatus[];
  positionTypes?: ObjectGroup[];
  selectedPUMode?: PUShowMode;
  sort?: Sort;
  /**
   * @deprecated используйте Рекомендуется использовать filters и убрать использование filter для единообразия
   * */
  filter?: Filter;
  filters?: Record<string, Filter | undefined>;
}

export enum LinkName {
  YELLOW = 'yellow',
  BLUE = 'blue',
  RED = 'red',
  GREEN = 'green',
  DEFAULT = 'default',
}

export enum ElementType {
  ROW = 'row',
  TABSET = 'tabset',
  TAB = 'tab',
}

export interface IRowAttributes {
  height?: number;
  id?: string;
  type: ElementType;
  weight?: number;
  width?: number;
}

export interface IJsonRowNode extends IRowAttributes {
  children: IJsonRowNode[];
  component?: Widget;
}

export interface LimitsProps {
  showPosition?: boolean;
  showLimits?: TradingLimitsShowMode;
  showNPU?: TradingLimitsShowMode;
}

export interface ContextMenuProps {
  showBestBuy?: boolean;
  showMarketBuy?: boolean;
}

export interface BalanceSettingsProps {
  showBalance?: boolean;
  showPU: boolean;
  showGO?: boolean;
}

export interface WidgetLinkProps {
  limitsProps?: LimitsProps;
  balanceSettingsProps?: BalanceSettingsProps;
  tableProps?: TableProps;
  contextMenuProps?: ContextMenuProps;
  idFi?: number;
  symbol?: string;
  link?: LinkName;
  linkedPrice?: number;
}

export interface WidgetProps extends WidgetLinkProps {
  nodeId?: string;
}

export interface BalanceProps extends WidgetProps {}

export interface ChartProps extends WidgetProps {
  idFi: number;
  isDayTheme?: boolean;
  columnFilterRender?: ColumnFilterRenderProps;
}

export interface HybridOrderProps extends WidgetProps {
  idFi: number;
  hideActiveOrders?: boolean;
}

export interface InstrumentInfoProps extends WidgetProps {
  idFi: number;
}

export interface OperationsProps extends WidgetProps {}

export interface OrderBookProps extends WidgetProps {
  idFi: number;
  autoCenter?: boolean;
  hideForm?: boolean;
  showSpread?: boolean;
  showYield?: boolean;
  showEmptyPrice?: boolean;
  sendTrallingOrders?: boolean;
  stopOrderType?: StopOrderType;
  slippageType?: SlippageType;
  slippageValue?: number;
}

export interface OrderTableProps extends WidgetProps {}

export interface PositionProps extends WidgetProps {}

export interface BondScreenerProps extends WidgetProps {
  selectedData: ScreenerBondEntity[];
}

export interface FiHighlight {
  highlightActive: boolean;
  highlightFrom?: number;
}

export interface TradesProps extends WidgetProps {
  timer?: number;
  timerActive: boolean;
}

export interface WatchListProps extends WidgetProps {
  selectedListId?: string;
}

export type BaseWidgetConfig =
  | TradesProps
  | WatchListProps
  | OrderBookProps
  | ChartProps
  | BalanceProps
  | OperationsProps
  | PositionProps
  | WidgetLinkProps
  | HybridOrderProps
  | InstrumentInfoProps
  | BondScreenerProps;

export const LinkNamesMap = new Map<LinkName, string>([
  [LinkName.YELLOW, 'Оранжевая группа'],
  [LinkName.BLUE, 'Синяя группа'],
  [LinkName.RED, 'Красная группа'],
  [LinkName.GREEN, 'Зеленая группа'],
  [LinkName.DEFAULT, 'Без группы'],
]);

export interface Link {
  nodeIds: string[];
  tableProps?: TableProps;
  idFi?: number;
  symbol?: string;
}

export type LinkGroups = Record<string, Link>;

export type TradingLimitsProps = {
  idFi: number;
  selectedSubAccountId: number;
  selectedSubAccounts: string[];
  nodeId?: string;
  limitsProps?: LimitsProps;
  tradeLimitsLong: TradeLimitsResult | null;
  tradeLimitsShort: TradeLimitsResult | null;
};

export type TradingLimitsRenderProps = (props: TradingLimitsProps) => ReactNode;

export enum LayoutType {
  Grid = 'grid',
  Flex = 'flex',
}

export type Position = {
  x: number;
  y: number;
};

export type Size = {
  width: number;
  height: number;
};

export type FlexLayoutWidget = {
  nodeId: string;
  name: string;
  component: Widget;
  visible?: boolean;
} & BaseWidgetConfig;

export interface FlexNode {
  name?: string;
  component: Widget;
  config: FlexLayoutWidget;
  size: Size;
  position: Position;
}

export type FlexLayoutTabset = {
  id: string;
  weight: number;
  position: Position;
  size: Size;
  children: FlexLayoutWidget[];
  pinned?: boolean;
};

export type FlexLayoutModel = {
  type: LayoutType.Flex;
  layout: FlexLayoutTabset[];
};

export type GridLayoutModel = IJsonModel & { type?: LayoutType.Grid };

export type LayoutModel<T> = T extends { type?: LayoutType.Grid }
  ? GridLayoutModel
  : FlexLayoutModel;

export interface NodeProps {
  name?: string;
  widget?: Widget;
  config: WidgetLinkProps | null;
}

export interface Bound {
  xLine: number;
  yLine: number;
}

export interface Colision {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}

export type Direction =
  | 'top'
  | 'right'
  | 'bottom'
  | 'left'
  | 'topRight'
  | 'bottomRight'
  | 'bottomLeft'
  | 'topLeft';
