import cn from 'classnames';
import { useMemo, useRef, useState } from 'react';
import { TagDesktop } from '@alfalab/core-components/tag/desktop';
import { ChevronDownCompactMIcon } from '@alfalab/icons-glyph/ChevronDownCompactMIcon';

import { Dropdown } from '@terminal/common/components/Dropdown';
import { filtersByPU } from '@terminal/core/constants/filters';
import { useOnClickOutside } from '@terminal/core/hooks/useOnClickOutside';
import { PUShowMode } from '@terminal/core/types/subAccountPosition';

import { PUOptions } from '../PUOptions';

import styles from './PUFilters.module.css';

interface PUFiltersProps {
  selectedPUMode: PUShowMode;
  savePUMode: (selectedPUMode: PUShowMode) => void;
  isShowChips: boolean;
}

export const PUFilters = ({
  selectedPUMode,
  savePUMode,
  isShowChips,
}: PUFiltersProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const filtersButtonRef = useRef<HTMLButtonElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside([dropdownRef, filtersButtonRef], () => setIsOpen(false));

  const selectedOption = useMemo(
    () =>
      filtersByPU.find(({ mode }) => mode === selectedPUMode) ?? filtersByPU[0],
    [selectedPUMode]
  );

  return (
    <>
      <TagDesktop
        ref={filtersButtonRef}
        size="xs"
        view="filled"
        checked={selectedPUMode !== PUShowMode.ANY}
        className={cn(styles.puFiltersButton, {
          [styles.puFiltersButtonSmall]: !isShowChips,
        })}
        onClick={() => setIsOpen((prev) => !prev)}
        onMouseDown={(e) => e.stopPropagation()}
        rightAddons={
          <ChevronDownCompactMIcon
            height={16}
            width={16}
            className={cn(styles.chevron, { [styles.chevronUp]: isOpen })}
          />
        }
      >
        {selectedOption.name}
      </TagDesktop>
      <Dropdown
        ref={dropdownRef}
        anchorElement={filtersButtonRef.current}
        open={isOpen}
        offset={[0, 4]}
        position="bottom-start"
        popperClassName={cn(
          styles.positionTypeSelectPoppover,
          styles.puModeSelectPoppover
        )}
      >
        <PUOptions
          selectedPUMode={selectedPUMode}
          savePUMode={(mode) => {
            savePUMode(mode);
            setIsOpen(false);
          }}
        />
      </Dropdown>
    </>
  );
};
