import { TableColumnKey } from '@terminal/core/types/tableColumn';

const mapFullNames = {
  [TableColumnKey.BsName]: 'Инструмент',
  [TableColumnKey.BsUntilExpiration]: 'До погашения',
  [TableColumnKey.BsPrice]: 'Цена',
  [TableColumnKey.BsYield]: 'Доходность к погашению',
  [TableColumnKey.BsCupYield]: 'Купонная доходность',
  [TableColumnKey.BsAgencyRating]: 'Кредитный рейтинг',
  [TableColumnKey.BsCupSize]: 'Размер купона',
  [TableColumnKey.BsCouponPaymentPerYear]: 'Количество купонов в год',
  [TableColumnKey.BsSectorName]: 'Сектор эмитента',
  [TableColumnKey.BsISIN]: 'ISIN',
  [TableColumnKey.BsEmShortName]: 'Эмитент',
  [TableColumnKey.BsCountryName]: 'Страна эмитента',
  [TableColumnKey.BsNominal]: 'Номинал',
  [TableColumnKey.BsCurrency]: 'Валюта',
  [TableColumnKey.BsYieldToOffert]: 'Доходность к оферте',
  [TableColumnKey.BsIsFloatingRate]: 'Тип купона',
  [TableColumnKey.BsCupDate]: 'Дата выплаты следующего купона',
  [TableColumnKey.BsDuration]: 'Дюрация',
  [TableColumnKey.BsMatDate]: 'Срок погашения',
  [TableColumnKey.BsAmortization]: 'Амортизация',
  [TableColumnKey.BsIsOffert]: 'Оферта',
  [TableColumnKey.BsOffertType]: 'Тип оферты',
  [TableColumnKey.BsOffertDate]: 'Дата оферты',
  [TableColumnKey.BsNkd]: 'НКД',
  [TableColumnKey.BsBondTypeName]: 'Тип бумаги',
  [TableColumnKey.BsIsReplacement]: 'Замещающие облигации',
  [TableColumnKey.BsIsMortgageBonds]: 'Ипотечная бумага',
  [TableColumnKey.BsIsRestructing]: 'Реструктуризация',
  [TableColumnKey.BsRestructingDate]: 'Дата реструктуризации',
  [TableColumnKey.BsIsStructuredNote]: 'Структурные облигации',
  [TableColumnKey.BsAllowNonQualInvest]: 'Для неквалифицированных инвесторов',
  [TableColumnKey.BsOffertPrice]: 'Цена оферты',
  [TableColumnKey.BsIsSubordinatedDebt]: 'Субординированные облигации',
};

const valueType = {
  [TableColumnKey.BsPrice]: '% от номинала',
  [TableColumnKey.BsYield]: '%',
  [TableColumnKey.BsCupYield]: '%',
  [TableColumnKey.BsCupSize]: '₽',
  [TableColumnKey.BsCouponPaymentPerYear]: 'шт',
  [TableColumnKey.BsYieldToOffert]: '%',
  [TableColumnKey.BsOffertPrice]: 'Цена оферты',
};

export const getFullNameByKey = (
  key: string,
  withValueType?: boolean
): string => {
  if (!withValueType) {
    return mapFullNames[key];
  }

  return valueType[key]
    ? `${mapFullNames[key]}, ${valueType[key]}`
    : mapFullNames[key];
};
