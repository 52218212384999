interface props {
  warranty: number;
  quantity: number;
  isLotCount: boolean;
  lot: number;
}

/**
 * Формула для получения суммы из количества штук/лотов в фьючерсах
 * @param options - параметры для формулы:
 * - warranty - гарантийное обеспечение (Price * Lot *  (PriceStepCost / PriceStep) * MK)
 * - quantity - количество лотов
 * - isLotCount - измеряется ли количество в лотах
 * - lot - количество штук в лоте
 * @returns Сумму в заявке
 * */
export const getCashForFutures = (options: props) => {
  const { warranty, quantity, isLotCount, lot } = options;

  // Делим на лотность в случае, если quantity - штуки
  const currentQuantity = Math.ceil(
    (warranty / (isLotCount ? 1 : lot)) * quantity
  );

  // проверка на NaN и Infinity
  return Number.isFinite(currentQuantity) ? currentQuantity : 0;
};
