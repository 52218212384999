import { useMutation } from 'react-query';

import { sendAssignment } from '@terminal/core/lib/rest/lkSignOperation';

/**
 * @deprecated используйте аналог из lk-core
 * */
export const useSendAssignment = () => {
  const mutation = useMutation(sendAssignment);

  return mutation;
};
