import { LkSignOptions, useSignApi } from '../../sign';
import { changeOperationMode } from '../api/changeOperationMode';

import { ChangeOperationModeParams as ChangeOperationModeRequest } from '../api/types/ChangeOperationModeParams';

interface ChangeOperationModeParams {
  params: ChangeOperationModeRequest;
  signOptions?: LkSignOptions;
}

export const useChangeOperationMode = (data: ChangeOperationModeParams) => {
  return useSignApi({
    apiCall: () => changeOperationMode(data.params),
    options: data?.signOptions,
  });
};
