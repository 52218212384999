/**
 * @deprecated используйте статические ключи, например, ['lk', 'account']
 * */
export enum LkQueryKeys {
  // common
  FeatureSettings = 'lk-feature-settings',
  InitObject = 'lk-init-object',
  Treaties = 'lk-treaties',
  TreatiesByOwner = 'lk-treaties-by-owner',

  // transfers
  BankInfo = 'lk-bank-info',
  BankTemplates = 'lk-bank-templates',
  CardFee = 'lk-card-fee',
  CardList = 'lk-card-list',
  ClientAccounts = 'lk-client-accounts',
  MoneyAccountRequisites = 'lk-money-account-requisites',
  MoneyRequisitesList = 'lk-money-requisites-list',
  MoneyHistory = 'lk-money-history',
  MoneyHistorySettings = 'lk-money-history-settings',
  MoneyLimits = 'lk-money-limits',

  // add account
  AddAccountPreregister = 'lk-add-account-preregister',
  AddAccountConstraints = 'lk-add-account-constraints',
  AddAccountDocument = 'lk-add-account-document',
  AddAdvisorPreregister = 'lk-advisor-preregister',
  AddAdvisorDocuments = 'lk-advisor-documents',

  // account info
  AccountInfo = 'lk-account-info',
  TradingPlatforms = 'lk-trading-platforms',
  Attorneys = 'lk-attorneys',

  // testing
  TestingList = 'lk-testing-list',
  TestStatus = 'lk-test-status',
  StartTestSession = 'lk-start-test-session',
  TestingQuestion = 'lk-test-question',
  CheckAnswer = 'lk-test-check-answer',

  // documents
  RiskDeclarationText = 'lk-risk-declaration-text',
  ScansList = 'lk-scans-list',
  ReportsTheme = 'lk-reports-theme',
  DocumentsTheme = 'lk-documents-theme',
  DocumentsHistory = 'lk-documents-history',
  FatcaText = 'lk-fatca-text',

  //  free-orders
  FreeOrdersSettings = 'lf-free-orders-settings',
  FreeOrdersHistory = 'lf-free-orders-history',

  // corporate actions
  CorporateAction = 'lk-corporate-action',
  CorporateActionHeaders = 'lk-corporate-action-headers',
  CorporateActionHistoryHeaders = 'lk-corporate-action-history-headers',
  CorporateActionHistoryDetail = 'lk-corporate-action-history-detail',

  // risk-profile
  RiskProfile = 'lk-client-profile',
  RiskProfileAgreement = 'lk-client-profile-agreement',
  UpdateRiskProfile = 'lk-update-client-profile',

  // notifications
  Notifications = 'lk-notifications',
  NotificationsCount = 'lk-notifications-count',

  // securities
  SecuritiesActivesInSearch = 'lk-securities-actives-in-search',
  SecuritiesActivesSearch = 'lk-securities-actives-search',
  SecuritiesActives = 'lk-securities-actives',
  SecuritiesPositions = 'lk-securities-positions',
  SecuritiesActivePlaces = 'lk-securities-active-places',
  SecuritiesPlaceList = 'lk-securities-place-list',
  SecuritiesUserTypeList = 'lk-securities-user-type-list',
  SecuritiesManager = 'lk-securities-manager',
  SecuritiesDocumentTypes = 'lk-securities-document-types',
  SecuritiesCurrencyList = 'lk-securities-currency-list',
  SecuritiesConteragents = 'lk-securities-conteragents',
  SecuritiesLimitOut = 'lk-securities-limits-out',
  SecuritiesHistory = 'lk-securities-history',
  SecuritiesHistoryDetail = 'lk-securities-history-detail',
  SecuritiesHistoryDetailFile = 'lk-securities-history-detail-file',
  SecuritiesDrafts = 'lk-securities-drafts',
  SecuritiesDraftDetails = 'lk-securities-draft-details',

  // Settings
  CloudConfigList = 'lk-cloud-config-list',

  // drafts
  Drafts = 'lk-drafts',
  Draft = 'lk-draft',
}
