import React, { FC, useState } from 'react';
import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';
import { ChevronRightMIcon } from '@alfalab/icons-glyph/ChevronRightMIcon';
import { RocketMIcon } from '@alfalab/icons-glyph/RocketMIcon';

import { Drawer } from '@terminal/widgets/features/Drawer';

import { usePortfolioReportsMetrics } from '../../../../entities/PortfolioReport';
import { MarginTradeDrawer } from '../MarginTradeDrawer';

import styles from './MarginTradeBanner.module.css';

interface Props {
  isMarginTradeTestPassed: boolean;
  onClose: () => void;
}

export const MarginTradeBanner: FC<Props> = ({
  isMarginTradeTestPassed,
  onClose,
}) => {
  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false);

  const { trackClickPortfolioReportMarginalBanner } =
    usePortfolioReportsMetrics();

  const handleOpenBanner = () => {
    setDrawerOpen(true);
    trackClickPortfolioReportMarginalBanner();
  };

  return (
    <>
      <div className={styles.banner} onClick={handleOpenBanner}>
        <RocketMIcon
          width={26}
          height={24}
          color="var(--color-light-text-secondary)"
        />
        <Typography.Text tag="div" view="secondary-large">
          С плечом можно заработать
          <br />
          ещё больше
        </Typography.Text>
        <ChevronRightMIcon
          width={16}
          height={16}
          color="var(--color-light-text-secondary)"
        />
      </div>

      <Gap size="2xl" />

      <Drawer isOpen={isDrawerOpen} onClose={() => setDrawerOpen(false)}>
        <MarginTradeDrawer
          isMarginTradeTestPassed={isMarginTradeTestPassed}
          onClose={onClose}
        />
      </Drawer>
    </>
  );
};
