import { isFieldEmpty } from './isFieldEmpty';
import { isPhoneComplete } from './isPhoneComplete';
import { isSameDigits } from './isSameDigits';
import { isWrongPhoneDigitSequence } from './isWrongPhoneDigitSequence';
import { isWrongPhonePrefix } from './isWrongPhonePrefix';
import { wrapValidators } from './wrapValidators';

export function validatePhoneLoginForm(values: { phone: string }) {
  const { phone } = values;

  return {
    phone: isFieldEmpty(phone)
      ? ''
      : wrapValidators([
          isPhoneComplete,
          isSameDigits,
          isWrongPhoneDigitSequence,
          isWrongPhonePrefix,
        ])(phone),
  };
}
