import { format, parseISO } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { Link } from '@alfalab/core-components/link';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';

import { DEFAULT_VIEW_DATE_FORMAT } from '@terminal/core/lib/dateToRequestFormat';
import {
  ActiveTransfer,
  SecuritiesContragentRequisites,
  SecuritiesPlaceItem,
  SecuritiesPosition,
  SecuritiesReasonDocument,
  SecuritiesReferenceInfoRequest,
} from '@terminal/core/lib/rest/lkSecurities';

import { Plug } from '../../../../shared';
import { EXTERNAL_LINKS } from '../../../const/externalUrls';
import { isIisAccount } from '../../../utils';
import { SecurityControlSection } from '../../components/SecurityControlSection';
import { SecurityCounterpartySection } from '../../components/SecurityCounterpartySection';
import { SecurityPlaceSection } from '../../components/SecurityPlaceSection';
import { SecurityReasonSection } from '../../components/SecurityReasonSection';
import { SecurityRequisitesSection } from '../../components/SecurityRequisitesSection';
import { SecuritySection } from '../../components/SecuritySection';
import { StepFooter } from '../../components/StepFooter';
import { StepProgress } from '../../components/StepProgress';
import {
  SummaryCounterparty,
  SummaryPosition,
  SummaryReason,
  SummarySecurities,
} from '../../components/Summary';
import {
  COUNTERAGENT_TYPES,
  filtSortDocumentTypes,
  isContrTreatyRequired,
  isDocumentsRequired,
  isLegalFieldsRequired,
  OTHER_CONTERAGENT,
  SecurityDeponentPlaceCode,
  SecurityPlaceCode,
} from '../../shared';
import {
  buildTransferData,
  validateForm,
  validateSecurityStep,
  validateSourceStep,
  validationCounterpartyStep,
} from './helpers';

import { useTreaties, useTreatiesByOwner } from '../../../hooks';
import {
  useSecuritiesCurrencyList,
  useSecuritiesDocumentTypes,
  useSecuritiesManager,
  useSecuritiesPlaceList,
  useSecuritiesPositions,
  useSecuritiesReferenceInfo,
  useSecuritiesTransferOut,
  useSecuritiesUserTypeList,
} from '../../hooks';

import { TransferCompleteParams } from '../../types';

import styles from './SecuritiesOut.module.css';

const enum STEPS {
  Source = 0,
  Target = 1,
  Security = 2,
  Summary = 3,
}

const STEPS_DESCRIPTION = [
  'Шаг 1 из 4: Откуда списываем',
  'Шаг 2 из 4: Куда списываем',
  'Шаг 3 из 4: Что списываем',
  'Шаг 4 из 4: Подача поручения',
];

interface SecuritiesOutProps {
  onSuccess?: (params: TransferCompleteParams) => void;
}

export const SecuritiesOut = ({ onSuccess }: SecuritiesOutProps) => {
  const [step, setStep] = useState<STEPS>(STEPS.Source);

  const [selectedActives, setSelectedActives] = useState<ActiveTransfer[]>([]);
  const [positionFrom, setPositionFrom] = useState<SecuritiesPosition | null>(
    null
  );
  const [ownershipChange, setOwnershipChange] = useState<boolean>(false);
  const [placeTo, setPlaceTo] = useState<SecuritiesPlaceItem | null>(null);
  const [accountType, setAccountType] = useState('OWNER');
  const [counteragentType, setCounteragentType] = useState<COUNTERAGENT_TYPES>(
    COUNTERAGENT_TYPES.NOT_SELECTED
  );
  const [counterpartyTo, setCounterpartyTo] = useState('');
  const [counterpartyToOther, setCounterpartyToOther] = useState('');
  const [conteragentKey, setConteragentKey] = useState(OTHER_CONTERAGENT);
  const [requisiteKey, setRequisiteKey] = useState('');
  const [depoCodeTo, setDepoCodeTo] = useState('');
  const [depoAccountTo, setDepoAccountTo] = useState('');
  const [depoSectionTo, setDepoSectionTo] = useState('');
  const [contragentAccDepoTo, setContragentAccDepoTo] = useState('');
  const [contrAlfaBank, setContrAlfaBank] = useState(false);
  const [swift, setSwift] = useState('');
  const [persDocNumber, setPersDocNumber] = useState('');
  const [persDocDate, setPersDocDate] = useState('');
  const [persDocOrg, setPersDocOrg] = useState('');
  const [orgName, setOrgName] = useState('');
  const [orgOGRN, setOrgOGRN] = useState('');
  const [orgWhen, setOrgWhen] = useState('');
  const [orgWhom, setOrgWhom] = useState('');
  const [requisitesCorrect, setRequisitesCorrect] = useState(false);
  const [reasonDocuments, setReasonDocuments] = useState<
    SecuritiesReasonDocument[]
  >([]);
  const [reasonOther, setReasonOther] = useState('');
  const [dealSumm, setDealSumm] = useState<number | null>(0);
  const [dealCurrency, setDealCurrency] = useState('RUB');

  const marketTo = placeTo ? placeTo.placeCode : '';

  const { data: treaties } = useTreaties();
  const { isFetching: positionsPending, data: positions } =
    useSecuritiesPositions();
  const { isFetching: placeListPending, data: placeList } =
    useSecuritiesPlaceList(positionFrom?.treaty);
  const { isFetching: currencyListPending, data: currencyList } =
    useSecuritiesCurrencyList();
  const { isFetching: userTypeListPending, data: userTypeList } =
    useSecuritiesUserTypeList();
  const { isFetching: managerPending, data: manager } = useSecuritiesManager(
    positionFrom?.treaty
  );
  const { isFetching: documentTypesPending, data: documentTypes } =
    useSecuritiesDocumentTypes();

  const [debouncedContragentAccDepoTo] = useDebounce<string>(
    contragentAccDepoTo,
    500
  );
  const [debouncedDepoCodeTo] = useDebounce<string>(depoCodeTo, 500);
  const referenceInfoParams = useMemo<
    SecuritiesReferenceInfoRequest | undefined
  >(() => {
    if (positionFrom && placeTo && selectedActives.length > 0) {
      return {
        clientAccDepo: positionFrom.depoAccount,
        clientDeponetPlaceCode: positionFrom.deponetPlaceCode,
        contrDeponetPlaceCode: placeTo.deponetPlaceCode,
        isTrans: ownershipChange,
        isin: selectedActives[0].paper.isin,
        counteragentAccDepo: contrAlfaBank
          ? debouncedContragentAccDepoTo
          : undefined,
        targetDeponentCode: contrAlfaBank ? undefined : debouncedDepoCodeTo,
        transferType: 'out',
        isInternal: contrAlfaBank,
      };
    }
  }, [
    positionFrom,
    placeTo,
    ownershipChange,
    contrAlfaBank,
    debouncedContragentAccDepoTo,
    debouncedDepoCodeTo,
    selectedActives,
  ]);

  const { data: referenceInfo } =
    useSecuritiesReferenceInfo(referenceInfoParams);

  const {
    mutateAsync: securitiesTransferOut,
    isLoading: securitiesTransferOutPending,
  } = useSecuritiesTransferOut();

  const userTypeListFiltred = useMemo(() => {
    if (!userTypeList) {
      return;
    }

    const isLegal = isLegalFieldsRequired({
      manager,
      counteragentType,
      ownershipChange,
    });

    const list = userTypeList.filter((type) =>
      isLegal ? true : type.name === 'OWNER'
    );

    return list;
  }, [userTypeList, manager, counteragentType, ownershipChange]);

  // Автовыбор типа счёта
  useEffect(() => {
    if (userTypeListFiltred?.length) {
      setAccountType(userTypeListFiltred[0].name);
    }
  }, [userTypeListFiltred]);

  // При выборе реестра контрагент не может быть в нашем брокере
  useEffect(() => {
    if (marketTo === SecurityPlaceCode.REESTR) {
      setContrAlfaBank(false);
    }
  }, [marketTo]);

  // При смене позиции сбрасываем место хранения на 2-м шаге
  useEffect(() => {
    setPlaceTo(null);
  }, [positionFrom]);

  useEffect(() => {
    if (
      positionFrom &&
      positionFrom.deponetPlaceCode === SecurityDeponentPlaceCode.EUROCLEAR
    ) {
      // Переводы Euroclear только внутри брокера
      setContrAlfaBank(true);
    }
  }, [positionFrom]);

  const treaty = useMemo(() => {
    if (positionFrom && treaties) {
      return treaties.find((t) => t.treaty === positionFrom.treaty);
    }
  }, [treaties, positionFrom]);

  const { data: ownTreaties } = useTreatiesByOwner(treaty?.treaty);

  const handlePositionChange = useCallback(
    (position: SecuritiesPosition | null) => {
      setPositionFrom(position);
      setSelectedActives([]);
    },
    []
  );

  const onSaveSecurity = useCallback(
    (security: ActiveTransfer, index?: number | null) => {
      setSelectedActives((prevSecurities) => {
        const securities = [...prevSecurities];

        if ((index || index === 0) && prevSecurities[index]) {
          securities[index] = security;
        } else {
          securities.push(security);
        }

        return securities;
      });
    },
    []
  );

  const onDeleteSecuritySilent = useCallback((index: number) => {
    setSelectedActives((prevSecurities) => {
      if (!prevSecurities[index]) {
        return prevSecurities;
      }

      const securities = [...prevSecurities];

      securities.splice(index, 1);

      return securities;
    });
  }, []);

  const onReasonDocumentChange = (
    index: number,
    doc: SecuritiesReasonDocument
  ) => {
    setReasonDocuments((prevDocuments) => {
      let documents = [...prevDocuments];

      documents.splice(index, 1, doc);

      return documents;
    });
  };

  const onAddReasonDocument = (doc: SecuritiesReasonDocument) => {
    const documents = [...reasonDocuments, doc];

    setReasonDocuments(documents);
  };

  const onDeleteReasonDocument = (index: number) => {
    let documents = [...reasonDocuments];

    documents.splice(index, 1);
    setReasonDocuments(documents);
  };

  const onConteragentRequisiteChange = useCallback(
    (conteragent: string, requisites: SecuritiesContragentRequisites) => {
      setCounterpartyTo(conteragent);
      setDepoAccountTo(requisites.srcContragentAccount);
      setDepoCodeTo(requisites.srcDeponentCode || '');
      setDepoSectionTo(requisites.srcDepoAccountPart || '');
    },
    []
  );

  const availableDocumentTypes = useMemo(() => {
    if (documentTypes) {
      return filtSortDocumentTypes(documentTypes, ownershipChange);
    }
  }, [documentTypes, ownershipChange]);

  const availablePlaceList = useMemo(() => {
    if (placeList && documentTypes) {
      // Убираем реестр из списка, если выбран документ не для реестра
      const selectedDocsId = reasonDocuments.map((doc) => doc.id);
      const nonReestrDoc = documentTypes.some(
        (docType) => !docType.forReestr && selectedDocsId.includes(docType.id)
      );

      return placeList.filter(
        (place) => !nonReestrDoc || place.placeCode !== SecurityPlaceCode.REESTR
      );
    }
  }, [placeList, reasonDocuments, documentTypes]);

  // При смене прав собственности сбрасываем выбранные документы и активы
  useEffect(() => {
    setSelectedActives([]);
    setReasonDocuments([]);
  }, [ownershipChange]);

  useEffect(() => {
    // При смене прав собственности данные нужно заполнять самостоятельно
    if (ownershipChange) {
      setPersDocNumber('');
      setPersDocDate('');
      setPersDocOrg('');
      setOrgName('');
      setOrgOGRN('');
      setOrgWhen('');
      setOrgWhom('');
    } else if (manager) {
      setPersDocNumber(manager.managerDocSerNo || '');

      setPersDocDate(
        manager.managerDocDate
          ? format(parseISO(manager.managerDocDate), DEFAULT_VIEW_DATE_FORMAT)
          : ''
      );

      setPersDocOrg(manager.managerDocOrg || '');

      if (manager.requisites) {
        setOrgName(manager.requisites.orgName);
        setOrgOGRN(manager.requisites.ogrn || '');
        setOrgWhen(
          format(parseISO(manager.requisites.regDate), DEFAULT_VIEW_DATE_FORMAT)
        );
        setOrgWhom(manager.requisites.regOrg);
      } else {
        setOrgName('');
        setOrgOGRN('');
        setOrgWhen('');
        setOrgWhom('');
      }
    }
  }, [manager, ownershipChange]);

  const [dealSummRequired, dealSummShow] = useMemo(() => {
    if (!isDocumentsRequired(ownershipChange) || !documentTypes) {
      return [false, false];
    }

    const fileredTypes = documentTypes.filter((type) =>
      reasonDocuments.some((doc) => doc.id === type.id)
    );

    const required = fileredTypes.some((type) => type.isTradeVolumeRequired);

    return [
      required,
      required || fileredTypes.some((type) => type.docType === 'OTHER'),
    ];
  }, [ownershipChange, documentTypes, reasonDocuments]);

  const positionsFromList = useMemo(() => {
    if (positions && treaties) {
      return positions
        .filter((pos) => {
          const treaty = treaties.find((t) => t.treaty === pos.treaty);

          return !isIisAccount(treaty);
        })
        .filter((position) => position.existActive);
    }

    return [];
  }, [positions, treaties]);

  const otherPositions = useMemo(() => {
    if (positions && ownTreaties && treaty) {
      return positions
        .filter(
          (pos) =>
            positionFrom?.deponetPlaceCode !== placeTo?.deponetPlaceCode ||
            pos.treaty !== treaty.treaty
        )
        .filter((pos) =>
          ownTreaties.some((t) => t.isOwner && pos.treaty === t.treaty)
        );
    }

    return [];
  }, [positions, ownTreaties, treaty, positionFrom, placeTo]);

  const treatyTo = useMemo(() => {
    if (
      !isContrTreatyRequired(ownershipChange, 'out', contrAlfaBank) ||
      !placeTo ||
      !positions
    ) {
      return null;
    }

    const pos = positions.find(
      (pos) =>
        pos.placeCode === placeTo.placeCode &&
        pos.depoAccount === contragentAccDepoTo
    );

    return pos ? pos.treaty : null;
  }, [ownershipChange, contrAlfaBank, placeTo, contragentAccDepoTo, positions]);

  const formReady = validateForm({
    selectedActives,
    positionFrom,
    ownershipChange,
    placeTo,
    marketTo,
    accountType,
    counterpartyTo,
    counterpartyToOther,
    depoCodeTo,
    depoAccountTo,
    depoSectionTo,
    contragentAccDepoTo,
    treatyTo,
    contrAlfaBank,
    swift,
    persDocNumber,
    persDocDate,
    persDocOrg,
    orgName,
    orgOGRN,
    orgWhen,
    orgWhom,
    reasonDocuments,
    dealSumm,
    dealCurrency,

    requisitesCorrect,
    manager,
    counteragentType,
    availableDocumentTypes,
    dealSummRequired,
  });

  const sendTransferRequest = () => {
    if (!formReady) {
      return;
    }

    if (!placeList || !treaty) {
      return;
    }

    const target = placeList.find((place) => place.placeCode === marketTo);
    const userType = userTypeList?.find((type) => type.name === accountType);

    if (!target || !positionFrom) {
      return;
    }

    const data = buildTransferData({
      selectedActives,
      positionFrom,
      ownershipChange,
      marketTo,
      accountType,
      counterpartyTo,
      counterpartyToOther,
      depoCodeTo,
      depoAccountTo,
      depoSectionTo,
      contragentAccDepoTo,
      treatyTo,
      contrAlfaBank,
      swift,
      persDocNumber,
      persDocDate,
      persDocOrg,
      orgName,
      orgOGRN,
      orgWhen,
      orgWhom,
      reasonDocuments,
      reasonOther,
      dealSumm,
      dealCurrency,

      treaty: treaty.treaty,
      target,
      userType,
      manager,
      counteragentType,
      dealSummRequired,
    });

    if (data.length === 1) {
      securitiesTransferOut({
        params: data[0],
        signOptions: {
          successTitle: `Поручение принято к исполнению`,
          errorTitle: 'Ошибка подачи поручения',
          successCallback: (status) =>
            onSuccess &&
            onSuccess({
              type: 'success',
              status: status?.data?.orderStatus,
            }),
        },
      });
    } else {
      // Что-то не так. Пакетного списания не предусмотрено
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.progress}>
        <StepProgress
          percentage={Math.floor((step / STEPS_DESCRIPTION.length) * 100)}
          title={STEPS_DESCRIPTION[step]}
        />
      </div>
      {(() => {
        switch (step) {
          case STEPS.Source: {
            const stepReady = validateSourceStep({
              positionFrom,
              ownershipChange,
              reasonDocuments,
              dealSumm,
              dealCurrency,
              availableDocumentTypes,
              dealSummRequired,
            });

            const isTreatyOk = !(treaty?.isRepo || treaty?.isForeignMarket);

            return (
              <>
                <Space fullWidth direction="vertical" className={styles.form}>
                  <SecurityPlaceSection
                    positions={positionsFromList}
                    positionsPending={positionsPending}
                    selectedPosition={positionFrom}
                    onPositionSelect={handlePositionChange}
                  />
                  {treaty?.isRepo && (
                    <Plug
                      title="Перевод ценных бумаг на счёт РЕПО возможен только через произвольное поручение"
                      text={
                        <>
                          Обратитесь к своему менеджеру, чтобы получить
                          <br />
                          детальную информацию
                        </>
                      }
                    />
                  )}
                  {treaty?.isForeignMarket && (
                    <Plug title="Переводы ценных бумаг на счёте Иностранный рынок недоступны" />
                  )}
                  {isTreatyOk && (
                    <>
                      <SecurityReasonSection
                        ownershipChange={ownershipChange}
                        onOwnershipChange={(ownership) =>
                          setOwnershipChange(ownership)
                        }
                        reasonDocuments={reasonDocuments}
                        onReasonDocumentChange={onReasonDocumentChange}
                        onAddReasonDocument={onAddReasonDocument}
                        onDeleteReasonDocument={onDeleteReasonDocument}
                        reasonOther={reasonOther}
                        documentTypes={availableDocumentTypes}
                        documentTypesPending={documentTypesPending}
                        onReasonOtherChange={(reason) => setReasonOther(reason)}
                        operationDirection="out"
                      />
                      <SecurityControlSection
                        dealSummRequired={dealSummRequired}
                        dealSummShow={dealSummShow}
                        currencyList={currencyList}
                        currencyListPending={currencyListPending}
                        dealSumm={dealSumm}
                        onDealSummChange={(summ) => setDealSumm(summ)}
                        dealCurrency={dealCurrency}
                        onDealCurrencyChange={(currency) =>
                          setDealCurrency(currency)
                        }
                      />
                      <StepFooter
                        next={{
                          action: () => setStep(STEPS.Target),
                          disabled: !stepReady,
                        }}
                      />
                    </>
                  )}
                </Space>
              </>
            );
          }
          case STEPS.Target: {
            const stepReady = validationCounterpartyStep({
              positionFrom,
              placeTo,
              marketTo,
              accountType,
              depoAccountTo,
              depoSectionTo,
              depoCodeTo,
              contragentAccDepoTo,
              contrAlfaBank,
              counterpartyTo,
              counterpartyToOther,
              treatyTo,
              swift,
              persDocNumber,
              persDocDate,
              persDocOrg,
              orgName,
              orgOGRN,
              orgWhen,
              orgWhom,

              ownershipChange,
              requisitesCorrect,
              manager,
              counteragentType,
            });

            return (
              <Space fullWidth direction="vertical" className={styles.form}>
                <SecurityCounterpartySection
                  transferType="out"
                  title="Куда"
                  positions={otherPositions}
                  placeList={availablePlaceList}
                  placeListPending={placeListPending}
                  ownershipChange={ownershipChange}
                  userTypeList={userTypeListFiltred}
                  userTypeListPending={userTypeListPending}
                  onConteragentRequisiteChange={onConteragentRequisiteChange}
                  postionAlfa={positionFrom}
                  selectedCounterpartyPlace={placeTo}
                  onSelectedCounterpartyPlaceChange={setPlaceTo}
                  userType={accountType}
                  onUserTypeChange={setAccountType}
                  counteragentType={counteragentType}
                  onCounteragentTypeChange={setCounteragentType}
                  depoAccount={depoAccountTo}
                  onDepoAccountChange={setDepoAccountTo}
                  depoSection={depoSectionTo}
                  onDepoSectionChange={setDepoSectionTo}
                  depoCode={depoCodeTo}
                  onDepoCodeChange={setDepoCodeTo}
                  counterpartyPlace={counterpartyTo}
                  onCounterpartyPlaceChange={setCounterpartyTo}
                  counterpartyPlaceOther={counterpartyToOther}
                  onCounterpartyPlaceOtherChange={setCounterpartyToOther}
                  conteragentAccDepo={contragentAccDepoTo}
                  onConteragentAccDepoChange={setContragentAccDepoTo}
                  counteragentAlfaBank={contrAlfaBank}
                  onCounteragentAlfaBankChange={setContrAlfaBank}
                  swift={swift}
                  onSwiftChange={setSwift}
                  selectedConteragentKey={conteragentKey}
                  onConteragentKeyChange={setConteragentKey}
                  selectedRequisiteKey={requisiteKey}
                  onRequisiteKeyChange={setRequisiteKey}
                  requisitesSection={
                    <SecurityRequisitesSection
                      accountType={accountType}
                      counteragentType={counteragentType}
                      ownershipChange={ownershipChange}
                      market={marketTo}
                      manager={manager}
                      managerPending={managerPending}
                      orgName={orgName}
                      onOrgNameChange={setOrgName}
                      orgOGRN={orgOGRN}
                      onOrgOGRNChange={setOrgOGRN}
                      orgWhen={orgWhen}
                      onOrgWhenChange={setOrgWhen}
                      orgWhom={orgWhom}
                      onOrgWhomChange={setOrgWhom}
                      docNumber={persDocNumber}
                      onDocNumberChange={setPersDocNumber}
                      docDate={persDocDate}
                      onDocDateChange={setPersDocDate}
                      docWhom={persDocOrg}
                      onDocWhomChange={setPersDocOrg}
                      requisitesCorrect={requisitesCorrect}
                      onRequisitesCorrectChange={setRequisitesCorrect}
                    />
                  }
                />
                <StepFooter
                  next={{
                    action: () => setStep(STEPS.Security),
                    disabled: !stepReady,
                  }}
                  prev={{
                    action: () => setStep(STEPS.Source),
                  }}
                />
              </Space>
            );
          }
          case STEPS.Security: {
            const stepReady = validateSecurityStep({
              selectedActives,
              marketTo,
            });

            return (
              <Space fullWidth direction="vertical" className={styles.form}>
                <SecuritySection
                  type="out"
                  actives={selectedActives}
                  position={positionFrom}
                  alfaMarket={positionFrom ? positionFrom.placeCode : ''}
                  counterpartyMarket={marketTo}
                  contrAlfaBank={contrAlfaBank}
                  dealParams={true}
                  generatedReference={referenceInfo?.reference || ''}
                  onSaveSecurity={onSaveSecurity}
                  onDeleteSecuritySilent={onDeleteSecuritySilent}
                />
                <StepFooter
                  next={{
                    action: () => setStep(STEPS.Summary),
                    disabled: !stepReady,
                  }}
                  prev={{
                    action: () => setStep(STEPS.Target),
                  }}
                />
              </Space>
            );
          }
          case STEPS.Summary: {
            return (
              <Space fullWidth direction="vertical" className={styles.form}>
                <SummaryPosition
                  position={positionFrom}
                  clientReferenceInfo={
                    referenceInfo ? referenceInfo.clientRequisites : undefined
                  }
                  counteragentAlfaBank={contrAlfaBank}
                  title="Откуда"
                />
                <SummaryCounterparty
                  title="Куда"
                  selectedCounterpartyPlace={placeTo}
                  userTypeList={userTypeList}
                  userTypeListPending={userTypeListPending}
                  marketAlfa={positionFrom?.placeCode || ''}
                  accountType={accountType}
                  counteragentType={counteragentType}
                  depoAccount={depoAccountTo}
                  depoSection={depoSectionTo}
                  depoCode={depoCodeTo}
                  counterpartyPlace={counterpartyTo}
                  counterpartyPlaceOther={counterpartyToOther}
                  conteragentAccDepo={contragentAccDepoTo}
                  counteragentAlfaBank={contrAlfaBank}
                  counteragentReferenceInfo={
                    referenceInfo
                      ? referenceInfo.counteragentRequisites
                      : undefined
                  }
                  swift={swift}
                  ownershipChange={ownershipChange}
                  manager={manager}
                  docNumber={persDocNumber}
                  docDate={persDocDate}
                  docWhom={persDocOrg}
                  orgName={orgName}
                  orgOGRN={orgOGRN}
                  orgWhen={orgWhen}
                  orgWhom={orgWhom}
                />
                <SummarySecurities selectedActives={selectedActives} />
                <SummaryReason
                  documentTypes={documentTypes}
                  reasonDocuments={reasonDocuments}
                  ownershipChange={ownershipChange}
                  reasonOther={reasonOther}
                  referenceInfo={referenceInfo}
                  comissionMultiplier={selectedActives.length}
                  operationDirection="out"
                />
                <StepFooter
                  next={{
                    disabled: !formReady,
                    action: sendTransferRequest,
                    loading: securitiesTransferOutPending,
                    label: 'Подать поручение',
                    hint: (
                      <Typography.Text
                        tag="p"
                        view="secondary-small"
                        color="secondary"
                        defaultMargins={false}
                        className={styles.agree}
                      >
                        Продолжая, я подтверждаю, что ознакомлен с&nbsp;
                        <Link
                          view="secondary"
                          href={EXTERNAL_LINKS.SECURITIES_DEPO_TARIFFS}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          депозитарными тарифами
                        </Link>
                      </Typography.Text>
                    ),
                  }}
                  prev={{
                    action: () => setStep(STEPS.Security),
                  }}
                />
              </Space>
            );
          }
        }
      })()}
    </div>
  );
};
