import { sanitize } from 'dompurify';
import { useState } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Link } from '@alfalab/core-components/link';
import { Spinner } from '@alfalab/core-components/spinner';
import { Typography } from '@alfalab/core-components/typography';
import { DocumentPdfMIcon } from '@alfalab/icons-glyph/DocumentPdfMIcon';

import { EmbendedVideo, useDownloadAndOpenFile } from '../../../../shared';
import { ThemeRecommendations } from '../ThemeRecommendations';

import { BookMaterials } from '../../model/types/BookMaterials';

import styles from './ThemeDetail.module.css';

interface ThemeDetailProps {
  name: string;
  description: string;
  hasTest?: boolean;
  onTest?: () => void;
  showRecomendations?: boolean;
  passPercent?: number | null;
  recommendations?: string;
  videoMaterials?: string[];
  bookMaterials?: BookMaterials[];
  getThemeDocumentUrl: (linkId: number) => string;
}

export const ThemeDetail = ({
  name,
  description,
  hasTest = true,
  onTest,
  showRecomendations = true,
  passPercent,
  recommendations,
  videoMaterials,
  bookMaterials,
  getThemeDocumentUrl,
}: ThemeDetailProps) => {
  const [downloadingId, setDownloadingId] = useState(0);

  const { mutateAsync: downloadFile, isLoading: fileLoading } =
    useDownloadAndOpenFile();

  const handleDownloadFile = (doc: BookMaterials) => {
    const url = getThemeDocumentUrl(doc.linkId);

    setDownloadingId(doc.linkId);
    downloadFile({ url, filename: doc.name }).finally(() =>
      setDownloadingId(0)
    );
  };

  return (
    <>
      <div className={styles.head}>
        <Typography.Text className={styles.title} weight="bold">
          {name}
        </Typography.Text>
        {hasTest && (
          <div className={styles.action}>
            <ButtonDesktop
              className={styles.button}
              size="s"
              view="secondary"
              onClick={onTest}
            >
              Вопросы для самопроверки
            </ButtonDesktop>
          </div>
        )}
      </div>

      {Boolean(
        showRecomendations && passPercent !== null && recommendations
      ) && (
        <ThemeRecommendations
          percent={passPercent || 0}
          className={styles.recomendation}
          recommendations={recommendations || ''}
        />
      )}

      {videoMaterials &&
        videoMaterials.map((video) => (
          <div className={styles.video} key={`video-${video}`}>
            <EmbendedVideo src={video} width={472} height={298} />
          </div>
        ))}

      {description && (
        <Typography.Text
          className={styles.description}
          tag="div"
          view="secondary-large"
        >
          <div dangerouslySetInnerHTML={{ __html: sanitize(description) }} />
        </Typography.Text>
      )}

      {bookMaterials && bookMaterials.length > 0 && (
        <>
          <Typography.Text tag="div" className={styles.addTitle} weight="bold">
            Дополнительные материалы
          </Typography.Text>

          <div className={styles.documents}>
            <Typography.Text tag="div" view="secondary-small">
              {bookMaterials.map((doc) => (
                <div className={styles.docItem} key={`doc-${doc.linkId}`}>
                  <Link
                    leftAddons={
                      fileLoading && downloadingId === doc.linkId ? (
                        <Spinner visible className={styles.filespinner} />
                      ) : (
                        <DocumentPdfMIcon width={12} height={12} />
                      )
                    }
                    target="_blank"
                    onClick={() => handleDownloadFile(doc)}
                  >
                    {doc.name}
                  </Link>
                </div>
              ))}
            </Typography.Text>
          </div>
        </>
      )}
    </>
  );
};
