import cn from 'classnames';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Loader } from '@alfalab/core-components/loader';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';
import { ChevronRightMIcon } from '@alfalab/icons-glyph/ChevronRightMIcon';
import { MagnifierMIcon } from '@alfalab/icons-glyph/MagnifierMIcon';

import { Treaty } from '@terminal/core/lib/rest/lkCommon';

import { useDrafts } from '../hooks/useDrafts';

import styles from './DraftsList.module.css';

export interface DraftsListProps {
  treaty: Treaty | null;
  draftId?: number;
  setDraftId: (id?: number) => void;
}

export const DraftsList = (props: DraftsListProps) => {
  const { treaty, setDraftId } = props;

  const { data, isFetching } = useDrafts(treaty?.treaty);

  if (isFetching) {
    return <Loader />;
  }

  if (!data?.length) {
    return (
      <div className={styles.notFound}>
        <Space fullWidth className={styles.center} size={24}>
          <SuperEllipse size={48}>
            <MagnifierMIcon />
          </SuperEllipse>
          <Typography.Text color="secondary" weight="bold">
            У вас нет неподписанных поручений
          </Typography.Text>
        </Space>
      </div>
    );
  }

  return (
    <div>
      <div className={styles.tableHeader}>
        <Typography.Text color="primary" view="secondary-large" weight="medium">
          Тема
        </Typography.Text>
      </div>

      {(data || []).map((item, index) => (
        <ButtonDesktop
          view="ghost"
          block
          key={item.draftId}
          onClick={() => setDraftId(item.draftId)}
        >
          <div className={cn(styles.tableRow, index % 2 === 0 && styles.odd)}>
            <Typography.Text view="secondary-large">
              {item.orderDraftThemeName}
            </Typography.Text>
            <ChevronRightMIcon />
          </div>
        </ButtonDesktop>
      ))}
    </div>
  );
};
