import { EventEmitter } from 'eventemitter3';
import isNumber from 'lodash/isNumber';
import throttle from 'lodash/throttle';

import { ObjectGroup } from '../client/entities';
import { getOrderTax } from '../formulas';
import { getFullFI } from '../getFullFI';
import { FinInfoExtService } from './finInfoExt/finInfoExt';
import { QuotesService } from './quotes';
import { SubscribeReturnData } from './streaming';

import { useStore } from '../../store';
import { selectCurrencyFi } from '../../store/selectors';

import { AccountItem } from '../../types/account';
import { ECurrencyId } from '../../types/currencyId';
import { FinInfo } from '../../types/quotes';
import { FinInfoExtRecord } from './finInfoExt/types';

const THROTTLE_TIMEOUT = 1000;
const RUB_ID = ECurrencyId.RUR;
const MOEX_OTC_ST_RUB_FEE = 0.005;

//TODO: Написать тесты!
//При подаче торговой заявки нужно отображать размер брокерской комиссии которая возникнет при исполнении
//Сервис рассчитывает комиссию при покупке фин инструмента
//Алгоритм расчета описан тут - http://confluence.moscow.alfaintra.net/pages/viewpage.action?pageId=545636245
export class BrokerageFeeService extends EventEmitter {
  constructor(
    idFi: number,
    quotesService: QuotesService,
    price?: number,
    quantity?: number,
    timeout?: number,
    selectedAccount?: AccountItem
  ) {
    super();
    this.timeout = timeout || THROTTLE_TIMEOUT;
    this.idFi = idFi;
    this.quotesService = quotesService;
    this.price = price;
    this.quantity = quantity;
    this.selectedAccount = selectedAccount;
  }

  private timeout?: number;
  private idFi: number;
  //Эти айди нужны чтобы получить Last или PrevLast для расчета комиссии
  private paymentFiId?: number;
  private nominalFiId?: number;

  private quotesService;
  private quotes: Record<number, Partial<FinInfo>>;
  private finInfoExtRecord: FinInfoExtRecord = {};

  private price?: number;
  private quantity?: number;

  private selectedAccount?: AccountItem;

  private getFi(): number[] {
    if (this.paymentFiId && this.nominalFiId) {
      return [...new Set([this.idFi, this.paymentFiId, this.nominalFiId])];
    } else {
      return [this.idFi];
    }
  }

  //Получаем склейку FI с objects, market и тд
  private getExtendedFI() {
    return getFullFI(
      this.idFi,
      useStore.getState().objectsTable,
      useStore.getState().objectTypesTable,
      useStore.getState().finInstrumentsTable,
      useStore.getState().marketBoardsTable
    );
  }

  //Получаем last для валюты расчетов
  private getPaymentLast() {
    return isNumber(this.paymentFiId)
      ? this.quotes[this.paymentFiId]?.last
      : undefined;
  }

  //Получаем last для валюты номинала
  private getNominalLast() {
    return isNumber(this.nominalFiId)
      ? this.quotes[this.nominalFiId]?.last
      : undefined;
  }

  //Получаем FinInfoExt для валюты расчетов (чтобы получить PrevLast)
  private getPaymentFinInfoExt() {
    return isNumber(this.paymentFiId)
      ? this.finInfoExtRecord[this.paymentFiId]
      : undefined;
  }

  //Получаем FinInfoExt для валюты номинала (чтобы получить PrevLast)
  private getNominalFinInfoExt() {
    return isNumber(this.nominalFiId)
      ? this.finInfoExtRecord[this.nominalFiId]
      : undefined;
  }

  //Курс валюты расчетов
  private getCurrencyPayment() {
    const extendedFI = this.getExtendedFI();
    const paymentLast = this.getPaymentLast();
    const paymentFinInfoExt = this.getPaymentFinInfoExt();

    if (extendedFI) {
      if (extendedFI?.idObjectCurrency === RUB_ID) {
        return 1;
      } else if (paymentLast || paymentFinInfoExt?.prevLast) {
        return paymentLast || paymentFinInfoExt?.prevLast;
      }
    }
  }

  //Курс валюты номинала
  private getCurrencyNominal() {
    const extendedFI = this.getExtendedFI();
    const nominalLast = this.getNominalLast();
    const nominalFinInfoExt = this.getNominalFinInfoExt();

    if (extendedFI) {
      if (
        extendedFI?.idObjectFaceUnit === RUB_ID ||
        extendedFI?.idObjectCurrency === RUB_ID
      ) {
        return 1;
      } else if (nominalLast || nominalFinInfoExt?.prevLast) {
        return nominalLast || nominalFinInfoExt?.prevLast;
      }
    }
  }

  private throtledUpdater = throttle(this.updateFee, this.timeout);

  private updateFee() {
    if (isNumber(this.price) && isNumber(this.quantity)) {
      const fee = this.calculateFee(this.price, this.quantity);

      this.emit('updateFee', fee);
    }
  }

  setPrice = (price: number) => {
    this.price = price;
    this.throtledUpdater();
  };

  setQuantity = (quantity: number) => {
    this.quantity = quantity;
    this.throtledUpdater();
  };

  setAccount = (account?: AccountItem) => {
    this.selectedAccount = account;
    this.throtledUpdater();
  };

  calculateFee = (price: number, quantity: number) => {
    if (this.getUniversalMarketCode() === 'MOEX_OTC_ST_RUB') {
      return quantity * price * MOEX_OTC_ST_RUB_FEE;
    }

    const extendedFI = this.getExtendedFI();
    const currencyPayment = this.getCurrencyPayment();
    const currencyNominal =
      extendedFI?.idObjectGroup === ObjectGroup.Currency
        ? 1
        : this.getCurrencyNominal();

    if (
      extendedFI &&
      this.selectedAccount &&
      currencyPayment &&
      currencyNominal
    ) {
      const { sPBEX_FeeRate, mICEX_FeeRate, sELT_FeeRate, oTC_FeeRate } =
        this.selectedAccount;
      const { idObjectGroup, rCode, nominal } = extendedFI;

      return getOrderTax(
        price,
        quantity,
        idObjectGroup,
        rCode,
        currencyPayment,
        currencyNominal,
        nominal,
        {
          sPBEX_FeeRate,
          mICEX_FeeRate,
          sELT_FeeRate,
          oTC_FeeRate,
        }
      );
    }
  };

  //Получаем айди фин инструментов валют
  private getCurrencyIds = () => {
    const extendedFI = this.getExtendedFI();

    //Если валюта = рубль, то айдишники не нужны
    if (
      extendedFI?.idObjectCurrency === RUB_ID ||
      extendedFI?.idObjectFaceUnit === RUB_ID
    ) {
      return;
    }

    if (extendedFI?.idObjectCurrency && extendedFI?.idObjectFaceUnit) {
      const paymentFi = selectCurrencyFi(
        extendedFI.idObjectCurrency,
        extendedFI.idObjectFaceUnit,
        'currency',
        useStore.getState().finInstruments
      );
      const nominalFi = selectCurrencyFi(
        extendedFI.idObjectCurrency,
        extendedFI.idObjectFaceUnit,
        'faceunit',
        useStore.getState().finInstruments
      );

      this.paymentFiId = paymentFi?.idFI;
      this.nominalFiId = nominalFi?.idFI;
    }
  };

  private setFinInfoExt = (data: FinInfoExtRecord) => {
    this.finInfoExtRecord = data;
  };

  private getUniversalMarketCode(): string {
    const instrument = useStore
      .getState()
      .finInstrumentsTable.get('idFI', this.idFi);

    const marketBoard = useStore
      .getState()
      .marketBoardsTable.get('idMarketBoard', instrument?.idMarketBoard ?? NaN);

    return marketBoard?.universalMarketCode ?? '';
  }

  subscribe = (idFi: number): [SubscribeReturnData, Function] => {
    this.idFi = idFi;
    this.quotes = {
      [idFi]: { idFI: idFi },
    };
    this.getCurrencyIds();

    const fis = this.getFi();

    //Подписываемся на FinInfoExt
    const extUnsubscribeData = FinInfoExtService.subscribe(
      fis,
      this.setFinInfoExt
    );
    //Подписываемся на FinInfo для получение Last
    const quoteUnsubscribeData = this.quotesService.subscribeToFI(
      fis,
      (fiObjects: Record<string, Partial<FinInfo>>) => {
        const newQuotes = fis.reduce((acc, idFi) => {
          const prevData = this.quotes[idFi];
          const lastData = fiObjects[idFi];

          return { ...acc, [idFi]: { ...prevData, ...lastData } };
        }, {} as Record<number, Partial<FinInfo>>);

        this.quotes = newQuotes;
      }
    );

    return [quoteUnsubscribeData, extUnsubscribeData];
  };

  unsubscribe = (
    quoteUnsubscribeData: SubscribeReturnData,
    extUnsubscribeData: Function
  ) => {
    this.quotesService.unsubscribe(this.getFi(), quoteUnsubscribeData);
    extUnsubscribeData();
  };
}
