import { MouseEventHandler } from 'react';
import { TooltipDesktop as Tooltip } from '@alfalab/core-components/tooltip/desktop';

import { LinkName, LinkNamesMap } from '@terminal/core/types/layout';

import { ReactComponent as DefaultLink } from '../../assets/DefaultLink.svg';

import styles from './LinkIcon.module.css';

interface Props {
  linkName: LinkName;
  onClick?: MouseEventHandler<SVGSVGElement>;
  withTooltip?: boolean;
  iconSize?: number;
}

export const LinkIcon = (props: Props) => {
  const { linkName, onClick, withTooltip, iconSize = 16 } = props;

  let icon = (
    <DefaultLink
      color="var(--color-light-graphic-secondary)"
      height={iconSize}
      width={iconSize}
      onClick={onClick}
    />
  );

  switch (linkName) {
    case LinkName.BLUE:
      icon = (
        <DefaultLink
          height={iconSize}
          width={iconSize}
          color="#4F84BA"
          onClick={onClick}
        />
      );
      break;
    case LinkName.YELLOW:
      icon = (
        <DefaultLink
          height={iconSize}
          width={iconSize}
          color="#DB723C"
          onClick={onClick}
        />
      );
      break;
    case LinkName.RED:
      icon = (
        <DefaultLink
          height={iconSize}
          width={iconSize}
          color="#FF5C5C"
          onClick={onClick}
        />
      );
      break;
    case LinkName.GREEN:
      icon = (
        <DefaultLink
          height={iconSize}
          width={iconSize}
          color="#0F9C8C"
          onClick={onClick}
        />
      );
      break;
  }

  return withTooltip ? (
    <Tooltip
      view="hint"
      offset={[0, 4]}
      targetClassName={styles.tooltipTarget}
      content={LinkNamesMap.get(linkName)}
      trigger="hover"
      onOpenDelay={500}
      position="top"
      preventOverflow={false}
    >
      {icon}
    </Tooltip>
  ) : (
    icon
  );
};
