import cn from 'classnames';
import isNumber from 'lodash/isNumber';
import { CSSProperties, forwardRef } from 'react';
import { Typography } from '@alfalab/core-components/typography';
import { CrossSIcon } from '@alfalab/icons-glyph/CrossSIcon';

import { getIsColumnStatic } from '@terminal/core/lib/helpers/getIsColumnStatic';

import { ReactComponent as DragIcon } from '../../../icons/drag_s.svg';
import { ColumnSettingSelect } from '../ColumnSettingSelect';

import { ItemProps, Position } from '../../types';

import styles from './Item.module.css';

export const Item = forwardRef<HTMLDivElement, ItemProps>(
  (
    {
      id,
      withOpacity,
      isDragging,
      style,
      column,
      index,
      onRemove,
      listeners,
      insertPosition,
      columnsSettingOptions,
      handleSelection,
      items,
      flatItems,
      widgetType,
      ...props
    },
    ref
  ) => {
    const inlineStyles: CSSProperties = {
      opacity: withOpacity ? '0.5' : '1',
      cursor: isDragging ? 'grabbing' : undefined,
      boxShadow: isDragging ? 'var(--shadow-m)' : undefined,
      ...style,
    };

    return (
      <div
        ref={ref}
        style={inlineStyles}
        //Определяем нужен ли "указатель" дропа (место помечается вертикальной чертой)
        className={cn(
          styles.doubleRowSetting,
          insertPosition === Position.Before && styles.insertBefore,
          insertPosition === Position.After && styles.insertAfter
        )}
        {...props}
      >
        <div className={styles.doubleRowColumnHeader}>
          <Typography.Text
            view="secondary-small"
            color="secondary"
            weight="bold"
            className={styles.doubleRowColumnName}
          >
            {isNumber(index) ? index + 1 : ''} колонка
          </Typography.Text>
          {!getIsColumnStatic(column, widgetType) && (
            <DragIcon
              className={styles.doubleRowDragIcon}
              cursor={isDragging ? 'grabbing' : 'grab'}
              //Сейчас перенос только через иконку, если нужно будет сделать на всю колонку, то перенести listeners в нужный блок
              {...listeners}
            />
          )}

          {onRemove && (
            <CrossSIcon className={styles.deleteIcon} onClick={onRemove} />
          )}
        </div>
        <div className={styles.selectWrapper}>
          <ColumnSettingSelect
            columnsSettingOptions={columnsSettingOptions}
            column={column}
            items={items}
            flatItems={flatItems}
            handleSelection={(setting) =>
              column && handleSelection(column.id, setting)
            }
            widgetType={widgetType}
          />
          <ColumnSettingSelect
            columnsSettingOptions={columnsSettingOptions}
            column={column?.secondRow}
            items={items}
            flatItems={flatItems}
            handleSelection={(setting) =>
              column && handleSelection(column.id, setting, true)
            }
            widgetType={widgetType}
          />
        </div>
      </div>
    );
  }
);

Item.displayName = 'Item';
