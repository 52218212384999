import cn from 'classnames';
import React from 'react';

import styles from './List.module.css';

interface ListProps extends React.HTMLAttributes<HTMLUListElement> {
  children?: React.ReactNode;
  marker?: 'default' | 'mdash';
}

export const List = (props: ListProps) => {
  const { children, marker = 'default', ...restProps } = props;

  return (
    <ul
      {...restProps}
      className={cn(styles.list, {
        [styles.mdash]: marker === 'mdash',
      })}
    >
      {children}
    </ul>
  );
};
