import { format, isValid } from 'date-fns';
import { ru } from 'date-fns/locale';
import { useMemo } from 'react';
import { BaseModal } from '@alfalab/core-components/base-modal';
import { Button } from '@alfalab/core-components/button/modern';
import { Loader } from '@alfalab/core-components/loader';
import { ProgressBar } from '@alfalab/core-components/progress-bar';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';
import { SignMIcon } from '@alfalab/icons-glyph/SignMIcon';

import { useAppContext } from '@terminal/core/hooks/useAppContext';
import { getDateFromAssignment, useSignDialog } from '@terminal/lk-core';

import { ConfirmModal } from '../../../../shared/ui/ConfirmModal';
import { FormatedText } from '../../../../shared/ui/FormatedText';

import styles from './SigningDialog.module.css';

export const SigningDialog = () => {
  const {
    openDialog,
    signText,
    textPending,
    signProgress,
    signErrors,
    signing,
    confirmationDialog,

    onConfirm,
    onDecline,
    close,
    sign,
  } = useSignDialog();

  const { appLogo } = useAppContext();

  const signDate = useMemo(() => {
    if (signText) {
      return getDateFromAssignment(signText);
    }
  }, [signText]);
  const showDate = signDate && isValid(signDate) ? signDate : new Date();

  return (
    <>
      <ConfirmModal
        open={confirmationDialog.open}
        title="Внимание!"
        text={confirmationDialog.text}
        confirmLabel="Продолжить"
        confirmIcon={<CheckmarkMIcon width={18} height={18} />}
        onClose={onDecline}
        onConfirm={onConfirm}
      />

      <BaseModal open={openDialog} onClose={close} className={styles.modal}>
        <div className={styles.container}>
          <div className={styles.modalHeader}>
            <Typography.Title tag="div" view="small" className={styles.title}>
              Текстовое поручение
            </Typography.Title>
            <CrossMIcon
              className={styles.closeIcon}
              width={18}
              height={18}
              onClick={close}
            />
          </div>
          <div>
            <div className={styles.top}>
              <div className={styles.logo}>{appLogo}</div>
              <span className={styles.date}>
                {format(showDate, 'dd MMMM yyyy HH:mm', { locale: ru })}{' '}
                {signDate ? 'Мск' : ''}
              </span>
            </div>
            {textPending ? (
              <Loader />
            ) : (
              <div>
                {signProgress && (
                  <div className={styles.multiplyProgress}>
                    <Typography.Text view="secondary-large">
                      Обработно: {signProgress.signed} из {signProgress.count}
                    </Typography.Text>
                    <ProgressBar
                      view="link"
                      value={Math.ceil(
                        (signProgress.signed / signProgress.count) * 100
                      )}
                    />
                  </div>
                )}

                {signErrors.length > 0 && (
                  <div className={styles.errors}>
                    <Typography.Text tag="p" view="primary-medium">
                      В некоторых поручениях возникли ошибки:
                    </Typography.Text>
                    {signErrors.map((err, index) => (
                      <Typography.Text
                        view="secondary-small"
                        key={index}
                        tag="p"
                      >
                        {err}
                      </Typography.Text>
                    ))}
                    <div className={styles.submit}>
                      <Button size="s" block onClick={close}>
                        Закрыть
                      </Button>
                    </div>
                  </div>
                )}

                {!signProgress && signErrors.length === 0 && (
                  <Space fullWidth={true} direction="vertical">
                    <Typography.Text
                      view="primary-small"
                      className={styles.text}
                      tag="div"
                    >
                      <FormatedText text={signText || ''} />
                    </Typography.Text>

                    <div className={styles.submit}>
                      <Button
                        size="s"
                        disabled={signing}
                        loading={signing}
                        onClick={sign}
                        leftAddons={<SignMIcon width={18} height={18} />}
                      >
                        Подписать поручение
                      </Button>
                    </div>
                  </Space>
                )}
              </div>
            )}
          </div>
        </div>
      </BaseModal>
    </>
  );
};
