import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../legacy/queryKeys';
import { getFeatureSetting } from '../api/features';

/**
 * @deprecated используйте аналог из `@terminal/lk-core`
 * */
export const useFeatureSettings = () => {
  const addNotification = useNotification();

  return useQuery([LkQueryKeys.FeatureSettings], getFeatureSetting, {
    staleTime: 2 * 60 * 1000,
    onError: (error: any) => {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Ошибка запроса настроек ЛК',
        text: error?.message || '',
      });
    },
  });
};
