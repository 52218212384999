import { FC, useMemo } from 'react';
import { ButtonDesktop as Button } from '@alfalab/core-components/button/desktop';
import { Typography } from '@alfalab/core-components/typography';

import { isNotUndefined } from '@terminal/core/lib/isNotUndefined';

import styles from './FilterButtons.module.css';

interface FilterButtonsProps {
  value: any;
  onCancel: () => void;
  onSave: () => void;
}

export const FilterButtons: FC<FilterButtonsProps> = ({
  value,
  children,
  onSave,
  onCancel,
}) => {
  const isDisabled = useMemo(() => {
    if (Array.isArray(value) && value.length === 0) {
      return true;
    }

    if (typeof value === 'object' && 'from' in value && 'to' in value) {
      return value.from === '' && value.to === '';
    }

    return !isNotUndefined(value);
  }, [value]);

  return (
    <>
      {children}
      <div className={styles.buttonsWrapper}>
        <Button
          view="tertiary"
          size="xxs"
          block={true}
          onClick={onCancel}
          className={styles.customButton}
          disabled={!isNotUndefined(value)}
        >
          <Typography.Text view="secondary-large" weight="medium">
            Сбросить
          </Typography.Text>
        </Button>
        <Button
          size="xxs"
          block={true}
          onClick={onSave}
          className={styles.customButton}
          disabled={isDisabled}
        >
          <Typography.Text view="secondary-large" weight="medium">
            Применить
          </Typography.Text>
        </Button>
      </div>
    </>
  );
};
