import { useState } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { AddMIcon } from '@alfalab/icons-glyph/AddMIcon';

import { FunctionalModalSidebar } from '@terminal/common/components/FunctionalModal';
import { Portal } from '@terminal/common/components/Portal';
import {
  isFirmAccount,
  SubAccount,
  Treaty,
  useAddSubaccount,
} from '@terminal/lk-core';
import { TradingCertificateLock } from '@terminal/widgets';

import { SubaccountTable } from '../../../../entities/AccountsOperations';
import { SubaccountRedact } from '../SubaccountRedact';

import styles from './Subaccounts.module.css';

interface SubaccountsProps {
  treaty: Treaty;
  subaccounts: SubAccount[];
  indLimit: number;
  onSubaccountAdd: () => void;
  onSubaccountRedacted: () => void;
  showSubAccountsOnTable?: boolean;
}

export const Subaccounts = ({
  treaty,
  subaccounts,
  indLimit,
  onSubaccountAdd,
  onSubaccountRedacted,
  showSubAccountsOnTable = false,
}: SubaccountsProps) => {
  const [redacting, setRedacting] = useState<SubAccount | null>(null);

  const { mutateAsync: addSubaccount, isLoading: addSubaccountPending } =
    useAddSubaccount();

  const onAddSubaccount = async () => {
    addSubaccount({
      params: {
        treaty: treaty.treaty,
      },
      signOptions: {
        successTitle: 'Добавлен субсчет',
        successCallback: () => onSubaccountAdd(),
      },
    });
  };

  let availableLimit = indLimit;

  if (subaccounts && subaccounts.length) {
    for (let i = 0; i < subaccounts.length; i++) {
      availableLimit -= subaccounts[i].indLimit;
    }
  }

  const isFirm = isFirmAccount(treaty);

  return (
    <>
      <Typography.Text view="primary-small" weight="bold">
        {isFirm ? 'Параметры субсчетов' : 'Параметры счёта'}
      </Typography.Text>
      <div className={styles.list}>
        <SubaccountTable
          items={subaccounts}
          showPortfolioNumber={showSubAccountsOnTable || isFirm}
          onReadct={(subacc) => setRedacting(subacc)}
        />
      </div>
      {isFirm && (
        <TradingCertificateLock hideMode>
          <div className={styles.submit}>
            <ButtonDesktop
              dataTestId="open-new-account-btn"
              block
              size="xs"
              leftAddons={<AddMIcon width={18} height={18} />}
              loading={addSubaccountPending}
              onClick={onAddSubaccount}
            >
              Добавить субсчёт
            </ButtonDesktop>
          </div>
        </TradingCertificateLock>
      )}

      <Portal id="main-window-modals">
        <FunctionalModalSidebar
          open={Boolean(redacting)}
          backLabel="Управление счетами"
          onClose={() => setRedacting(null)}
        >
          {Boolean(redacting) && (
            <SubaccountRedact
              treaty={treaty.treaty}
              item={redacting!}
              showPortfolioNumber={isFirm}
              availableLimit={availableLimit}
              onSaved={onSubaccountRedacted}
            />
          )}
        </FunctionalModalSidebar>
      </Portal>
    </>
  );
};
