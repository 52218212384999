import { FC } from 'react';

import { AlfaDirectProvider } from '@terminal/alfadirect/provider/react';

import type { AlfaDirectAPI, WidgetApi } from '../../api';
import { Provider as WidgetsApiProvider } from '../../shared/context';

export type ContextMenuProviderProps = {
  widgetApi: WidgetApi;
  alfaDirectApi: AlfaDirectAPI;
};

// TODO: Требуется доработка таблички или меню
// blueprint v4 не видит контекста для этого был сделан этот костыль
// https://github.com/palantir/blueprint/issues/1121
// UPD: проблема исправлена в старших версиях библиотеки (приблизительно от 4.4.0)
export const ContextMenuProvider: FC<ContextMenuProviderProps> = (props) => {
  const { children, widgetApi, alfaDirectApi } = props;

  return (
    <WidgetsApiProvider value={widgetApi}>
      <AlfaDirectProvider value={alfaDirectApi}>{children}</AlfaDirectProvider>
    </WidgetsApiProvider>
  );
};
