import { z } from 'zod';

import { FEATURE_FLAG_CODE, TECH_MESSAGE_CODE } from '../model/types';

const bondScreenerDictionariesSchema = z.array(
  z.object({
    name: z.string(),
    filter: z.string(),
    values: z.array(
      z.object({
        name: z.string(),
        value: z.string(),
        group: z.string().optional(),
      })
    ),
  })
);

export const scheme = z.object({
  /**
   * Текст тех. сообщения для рабочего стола
   * */
  [TECH_MESSAGE_CODE.MAIN]: z.coerce.string().trim(),
  /**
   * Текст тех. сообщения для виджетов с торговой формой (торговый приказ, стакан, график)
   * */
  [TECH_MESSAGE_CODE.TRADE]: z.coerce.string().trim(),
  /**
   * Текст тех. сообщения для виджетов Позиции, Балансы
   * */
  [TECH_MESSAGE_CODE.POSITIONS_BALANCES]: z.coerce.string().trim(),
  /**
   * Текст тех. сообщения для окна Перевод денежных средств
   * */
  [TECH_MESSAGE_CODE.MONEY_TRANSFERS]: z.coerce.string().trim(),
  /**
   * Текст тех. сообщения для вкладки Электронная подпись в Личном кабинете терминала
   * */
  [TECH_MESSAGE_CODE.LK_ELECTRONIC_SIGNATURE]: z.coerce.string().trim(),
  /**
   * Текст тех. сообщения на экране Авторизация
   * */
  [TECH_MESSAGE_CODE.AUTHORIZATION]: z.coerce.string().trim(),
  /**
   * Текст тех. сообщения для окна Открытие счета
   * */
  [TECH_MESSAGE_CODE.OPEN_ACCOUNT]: z.coerce.string().trim(),
  /**
   * Текст тех. сообщения для Поддержки
   * */
  [TECH_MESSAGE_CODE.SUPPORT]: z.coerce.string().trim(),
  /**
   * Массив справочников для скринера облигаций
   * */
  [FEATURE_FLAG_CODE.BOND_SCREENER_DICTIONARIES]:
    bondScreenerDictionariesSchema,
  /**
   * Максимальная сила инерции при скролле графика
   * */
  [FEATURE_FLAG_CODE.CHART_SCROLL_VELOCITY]: z.coerce.number(),
  /**
   * Включает возможность добавления и использования рабочего стола с типом Гибкие окна
   * */
  [FEATURE_FLAG_CODE.DRAGGABLE_LAYOUT_ENABLED]: z.coerce.boolean(),
  /**
   * Максимально возможное количество добавленных окон в виде виджетов (групп виджетов) для 1 рабочего стола с типом Гибкие окна
   * */
  [FEATURE_FLAG_CODE.DRAGGABLE_LAYOUT_MAX_AVAILABLE_WIDGETS]: z.coerce.number(),
});
