import JSEncrypt from 'jsencrypt';
import log from 'loglevel';

import { RSA_PUB_KEY } from './const/rsaPubKey';

export async function prepareBody(
  body: unknown,
  encrypt: boolean = false
): Promise<string> {
  let data: string = JSON.stringify(body);

  if (encrypt) {
    const jsenc = new JSEncrypt();

    jsenc.setPublicKey(RSA_PUB_KEY);
    const encrupted = jsenc.encrypt(data);

    if (encrupted) {
      data = encrupted;
    } else {
      log.error('Encryption failed');
    }
  }

  return data;
}
