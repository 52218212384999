import cn from 'classnames';
import { memo, useMemo } from 'react';
import {
  AmountInput,
  AmountInputProps,
} from '@alfalab/core-components/amount-input';
import { Typography } from '@alfalab/core-components/typography';
import { CurrencyCodes, getCurrencySymbol } from '@alfalab/utils';

import { Stepper } from '@terminal/common/components/Stepper';
import { MINORITY } from '@terminal/core/constants/ui';
import { OrderType } from '@terminal/core/lib/client/entities';
import { getAssetUnits } from '@terminal/core/lib/format';
import { TradeLimitsResult } from '@terminal/core/types/tradeLimits';
import { OrderSide } from '@terminal/core/types/trading';
import { OrderDimensionEnum } from '@terminal/core/types/ui';

import { FormType, VolumeType } from '../../model';
import { AdditionalVolumeSettings } from '../AdditionalVolumeSettings';

import { useIsLotCount } from '../../../../shared/hooks/useIsLotCount';
import { useVolumeQuickControls } from '../../hooks';

import styles from './VolumeInput.module.css';

export type VolumeInputProps = Pick<
  AmountInputProps,
  'bottomAddons' | 'error'
> & {
  formType: FormType;
  orderType: OrderType;
  volumeType: VolumeType;
  quantity: number;
  idFi: number;
  side: OrderSide;
  price: number;
  tradeLimits: TradeLimitsResult | null;
  lot?: number;
  currencyCode?: CurrencyCodes;
  isHideQuickLimits?: boolean;
  selectedSubAccountId: number;
  selectedSubAccounts: string[];
  setQuantity: (quantity: number) => void;
  containerClassName?: string;
  convertedValue?: string;
};

export const VolumeInput = memo((props: VolumeInputProps) => {
  const {
    formType,
    orderType,
    isHideQuickLimits,
    lot,
    quantity,
    idFi,
    side,
    price,
    tradeLimits,
    selectedSubAccountId,
    selectedSubAccounts,
    setQuantity,
    containerClassName,
    volumeType,
    currencyCode,
    convertedValue,
    bottomAddons,
    error,
  } = props;

  const isLotCount = useIsLotCount();

  const {
    volumeAdditional,
    clearVolumeAdditional,
    volAdSuffix,
    limitsDisabled,
    positionVolumeDisabled,
    cashVolumeDisabled,
  } = useVolumeQuickControls({
    idFi,
    side,
    orderType,
    lot,
    price,
    setQuantity,
    tradeLimits,
    selectedSubAccountId,
    selectedSubAccounts,
    volumeType,
  });

  const cashStep = useMemo<number>(() => {
    if (currencyCode === 'RUB' || currencyCode === 'RUR') {
      return MINORITY * 10;
    }

    return MINORITY;
  }, [currencyCode]);

  const handleBlur = () => {
    if (lot && !isLotCount && quantity < lot) {
      setQuantity(lot);
    }
  };

  const handleAdd = () => {
    if (volumeType === VolumeType.Cash) {
      setQuantity(quantity + cashStep);
      clearVolumeAdditional();

      return;
    }

    if (isLotCount) {
      setQuantity(quantity + 1);
    } else if (lot) {
      setQuantity(quantity + lot);
    }

    clearVolumeAdditional();
  };

  const handleSub = () => {
    if (volumeType === VolumeType.Cash) {
      setQuantity(quantity > cashStep ? quantity - cashStep : quantity);
      clearVolumeAdditional();

      return;
    }

    if (isLotCount) {
      setQuantity(quantity > 1 ? quantity - 1 : quantity);
    } else if (lot) {
      setQuantity(quantity > lot ? quantity - lot : lot);
    }

    clearVolumeAdditional();
  };

  const amountInputProps = useMemo<Partial<AmountInputProps>>(() => {
    const suffix = !isHideQuickLimits ? volAdSuffix : '';

    if (volumeType === VolumeType.Volume) {
      return {
        suffix: `${
          isLotCount ? getAssetUnits(quantity, OrderDimensionEnum.LOT) : 'шт'
        } ${suffix}`,
        minority: 1,
        integersOnly: true,
      };
    }

    if (volumeType === VolumeType.Cash) {
      return {
        suffix: currencyCode
          ? `${getCurrencySymbol(currencyCode)} ${suffix}`
          : suffix,
        minority: MINORITY,
      };
    }

    return {};
  }, [
    currencyCode,
    isHideQuickLimits,
    isLotCount,
    quantity,
    volAdSuffix,
    volumeType,
  ]);

  return (
    <div className={cn(containerClassName)}>
      <AmountInput
        className={styles.customInput}
        fieldClassName={styles.customInputField}
        error={error}
        block
        value={quantity}
        onChange={(_, { value }) => {
          setQuantity(value || 0);
        }}
        onBlur={handleBlur}
        rightAddons={
          <div className={styles.priceAddons}>
            {formType === FormType.WIDGET && convertedValue && (
              <Typography.Text
                view="secondary-large"
                weight="medium"
                color="tertiary"
                className={styles.convertedValue}
              >
                {convertedValue}
              </Typography.Text>
            )}
            <Stepper
              size="s"
              onAdd={handleAdd}
              onSub={handleSub}
              className={styles.stepper}
            />
            <AdditionalVolumeSettings
              limitDisabled={limitsDisabled}
              posDisabled={positionVolumeDisabled}
              cashDisabled={cashVolumeDisabled}
              className={styles.additionalVolume}
              {...volumeAdditional}
            />
          </div>
        }
        dataTestId="priceInput"
        bottomAddons={bottomAddons}
        {...amountInputProps}
      />
    </div>
  );
});

VolumeInput.displayName = 'VolumeInput';
