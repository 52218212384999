import { Gap } from '@alfalab/core-components/gap';
import { IconButton } from '@alfalab/core-components/icon-button';
import { Typography } from '@alfalab/core-components/typography';
import { CrossSIcon } from '@alfalab/icons-glyph/CrossSIcon';
import { TileBentoMIcon } from '@alfalab/icons-glyph/TileBentoMIcon';

import styles from './PerformanceBanner.module.css';

interface Props {
  count: number;
  onClose: () => void;
}

export const PerformanceBanner = (props: Props) => {
  const { count, onClose } = props;

  return (
    <div className={styles.container}>
      <TileBentoMIcon
        width={18}
        height={18}
        color="var(--color-light-bg-quaternary-inverted)"
      />
      <Gap direction="horizontal" size="xs" />
      <Typography.Text
        view="secondary-large"
        weight="bold"
        className={styles.text}
      >
        При добавлении более {count} окон на рабочий стол, терминал может
        работать медленнее
      </Typography.Text>

      <IconButton
        size="xs"
        view="secondary"
        icon={CrossSIcon}
        onClick={onClose}
        className={styles.closeButton}
      />
    </div>
  );
};
