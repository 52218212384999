import React, { FC } from 'react';
import {
  QueryClient,
  QueryClientProvider as BaseQueryClientProvider,
} from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

export const QueryClientProvider: FC = (props) => {
  const { children } = props;

  return (
    <BaseQueryClientProvider client={queryClient}>
      {children}
    </BaseQueryClientProvider>
  );
};
