import { Gap } from '@alfalab/core-components/gap';

import { DirectionColorForm } from '../DirectionColorForm';
import { ThemeForm } from '../ThemeForm';

export const Design = () => {
  return (
    <div>
      <ThemeForm />
      <Gap size="2xl" />
      <DirectionColorForm />
    </div>
  );
};
