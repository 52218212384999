import { format } from 'date-fns';
import groupBy from 'lodash/groupBy';

import { getValueForDateRange } from '@terminal/core/lib/helpers/getValueForDateRange';
import {
  Filter,
  FilterSettings,
  MultiSelectItemObject,
} from '@terminal/core/types/layout';
import { TableColumnKey } from '@terminal/core/types/tableColumn';

export const getChipsTextByKey = (
  key: string,
  filter: Filter | undefined,
  filterSettings: FilterSettings | undefined
): string => {
  const emptyFilter = [
    TableColumnKey.BsBondTypeName,
    TableColumnKey.BsSectorName,
    TableColumnKey.BsAgencyRating,
    TableColumnKey.BsNominal,
    TableColumnKey.BsCupSize,
    TableColumnKey.BsIsFloatingRate,
    TableColumnKey.BsIsStructuredNote,
    TableColumnKey.BsIsSubordinatedDebt,
    TableColumnKey.BsMatDate,
  ].includes(key as TableColumnKey)
    ? 'Любой'
    : 'Любая';

  switch (key) {
    case TableColumnKey.BsBondTypeName:
    case TableColumnKey.BsSectorName:
    case TableColumnKey.BsCurrency:
    case TableColumnKey.BsCountryName:
    case TableColumnKey.BsOffertType:
      if (!filter) {
        return emptyFilter;
      }

      if (filter.value.length === 1) {
        return filter.value[0];
      }

      return `${filter.value[0]} + ${filter.value.length - 1}`;
    case TableColumnKey.BsAgencyRating:
      if (!filter) {
        return emptyFilter;
      }

      if (filter.value.length === 1) {
        return filter.value[0];
      }

      const groupedItems = groupBy(filterSettings!.filterItems, 'group');

      for (const groupName in groupedItems) {
        const allInGroup = groupedItems[groupName].every((item) =>
          filter.value.includes((item as MultiSelectItemObject).value)
        );

        if (
          allInGroup &&
          groupedItems[groupName].length === filter.value.length
        ) {
          return groupName;
        }
      }

      return `${filter.value[0]} + ${filter.value.length - 1}`;
    case TableColumnKey.BsYield:
    case TableColumnKey.BsPrice:
    case TableColumnKey.BsNominal:
    case TableColumnKey.BsYieldToOffert:
    case TableColumnKey.BsCupYield:
    case TableColumnKey.BsCupSize:
    case TableColumnKey.BsCouponPaymentPerYear:
      if (!filter) {
        return emptyFilter;
      }

      let chipsForDigitRange = '';

      if (filter.value.from) {
        chipsForDigitRange += `от ${filter.value.from} `;
      }

      if (filter.value.to) {
        chipsForDigitRange += `до ${filter.value.to}`;
      }

      return chipsForDigitRange;
    case TableColumnKey.BsIsFloatingRate:
      if (!filter) {
        return emptyFilter;
      }

      const currentVal = (
        filterSettings!.filterItems as MultiSelectItemObject[]
      ).find((item) => item.value === filter.value);

      return currentVal?.name ?? emptyFilter;
    case TableColumnKey.BsAmortization:
    case TableColumnKey.BsIsOffert:
    case TableColumnKey.BsIsStructuredNote:
    case TableColumnKey.BsIsReplacement:
    case TableColumnKey.BsIsSubordinatedDebt:
      if (!filter) {
        return emptyFilter;
      }

      return filter.value ? 'Да' : 'Нет';
    case TableColumnKey.BsMatDate:
      if (!filter) {
        return emptyFilter;
      }

      const valueFromRange = getValueForDateRange(
        filter.value?.dateFrom,
        filter.value?.dateTo
      );

      if (valueFromRange?.title) {
        return valueFromRange.title;
      }

      let stringOfDates = '';

      if (filter.value?.dateFrom) {
        stringOfDates += `с ${format(filter.value.dateFrom, 'dd.MM.yyyy')}`;
      }

      if (filter.value?.dateTo) {
        stringOfDates += ` по ${format(filter.value.dateTo, 'dd.MM.yyyy')}`;
      }

      return stringOfDates;
    default:
      return '';
  }
};
