import { useRef } from 'react';
import { TooltipDesktop as Tooltip } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { InformationCircleLineMIcon } from '@alfalab/icons-glyph/InformationCircleLineMIcon';

import { Dropdown } from '@terminal/common/components/Dropdown';
import { useOnClickOutside } from '@terminal/core/hooks';
import { LinkName, LinkNamesMap } from '@terminal/core/types/layout';

import { LinkIcon } from '../LinkIcon';

import styles from './Link.module.css';

interface Props {
  isOpen: boolean;
  anchor: React.RefObject<HTMLElement>;
  currentLink: LinkName;
  onClose: () => void;
  onChooseLink: (linkName: LinkName) => void;
}

export const LinkDropdown = (props: Props) => {
  const { isOpen, anchor, currentLink, onClose, onChooseLink } = props;
  //Реф для отлавливания кликов вне компонента
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(dropdownRef, () => {
    isOpen && onClose();
  });

  return (
    <Dropdown
      ref={dropdownRef}
      popperClassName={styles.popperClassName}
      anchorElement={anchor.current}
      open={isOpen}
      offset={[-8, 16]}
      position="bottom-start"
    >
      <div className={styles.titleWrapper}>
        <Typography.Text
          view="secondary-large"
          weight="bold"
          className={styles.linkTitle}
        >
          Связать виджеты
        </Typography.Text>
        <Tooltip
          view="hint"
          offset={[0, 4]}
          targetClassName={styles.tooltipTarget}
          contentClassName={styles.tooltipContent}
          content="В связанных виджетах данные
          по бумаге, выбранной в одном
          из виджетов, будут отображаться
          во всех виджетах группы"
          trigger="hover"
          position="top"
          preventOverflow={false}
        >
          <InformationCircleLineMIcon
            height={16}
            width={16}
            color="var(--color-light-text-secondary)"
          />
        </Tooltip>
      </div>

      <Dropdown.Item
        withDivider
        content={LinkNamesMap.get(currentLink)!}
        leftIcon={<LinkIcon linkName={currentLink} />}
        rightIcon={<CheckmarkMIcon height={16} width={16} />}
        onClick={onClose}
      />

      {[
        LinkName.DEFAULT,
        LinkName.BLUE,
        LinkName.GREEN,
        LinkName.RED,
        LinkName.YELLOW,
      ]
        .filter((linkName) => linkName !== currentLink)
        .map((linkName) => (
          <Dropdown.Item
            key={linkName}
            content={LinkNamesMap.get(linkName)!}
            leftIcon={<LinkIcon linkName={linkName} />}
            onClick={() => {
              onChooseLink(linkName);
              onClose();
            }}
          />
        ))}
    </Dropdown>
  );
};
