import { useMemo } from 'react';

import { useAlfaDirectContext } from '@terminal/alfadirect/provider/react';

import { getFullFI } from '../../lib/getFullFI';

// Аналогичен useFullFi, но для массива
export const useFullFIs = (idFIs: number[]) => {
  const {
    objectsTable: objects,
    objectTypesTable: objectTypes,
    finInstrumentsTable: finInstruments,
    marketBoardsTable: marketBoards,
  } = useAlfaDirectContext();

  const fullFIs = useMemo(
    () =>
      idFIs.map((idFI) =>
        getFullFI(idFI, objects, objectTypes, finInstruments, marketBoards)
      ),
    [idFIs, objects, objectTypes, finInstruments, marketBoards]
  );

  return fullFIs;
};
