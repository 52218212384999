import { getPriceError } from '@terminal/core/lib/helpers/getPriceError';

import { FormValues } from './types';

type Errors = Partial<Record<keyof FormValues, string>>;

export const validateForm = (
  values: FormValues,
  minority: number,
  priceStep: number
): Errors => {
  const { targetPrice } = values;
  const errors: Errors = {};

  if (!targetPrice) {
    errors.targetPrice = 'Неверная цена';
  } else {
    const error = getPriceError(targetPrice / minority, true, priceStep);

    if (error) {
      errors.targetPrice = error;
    }
  }

  return errors;
};
