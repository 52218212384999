/* eslint-disable custom-eslint-plugins/files-export */

import { DEFAULT_TRADE_FEED_TIMER } from './tradesFeed';

import {
  BondScreenerProps,
  FilterTypes,
  TableColumnSetting,
} from '../types/layout';
import { TableColumnKey } from '../types/tableColumn';

export const MIN_CELL_WIDTH = 50;
export const CHECBKOX_CELL_WIDTH = 32;

export const defaultBalanceColumnSetting: TableColumnSetting[] = [
  {
    key: TableColumnKey.IdAccount,
    selected: true,
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.NameBalanceGroup,
    selected: true,
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.Balance,
    selected: true,
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.NameClient,
    selected: false,
    width: 5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PrevBalance,
    selected: true,
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.InitialMargin,
    selected: true,
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.MinimumMargin,
    selected: true,
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.Money,
    selected: true,
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.InitialMoney,
    selected: true,
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.LongBalance,
    selected: true,
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.ShortBalance,
    selected: true,
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.NplRur,
    selected: true,
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.NplRurPercent,
    selected: true,
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.DailyPLRur,
    selected: true,
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.DailyPLRurPrecent,
    selected: true,
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.Requirements,
    selected: false,
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.ImmediateRequirements,
    selected: false,
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.LiquedBalance,
    selected: false,
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PortfolioValueWithOrders,
    selected: false,
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.InitialMarginWithOrders,
    selected: false,
    width: 2.5 * MIN_CELL_WIDTH,
  },
];

export const getDefaultWatchListColumnSetting = (
  firstColumn?: string
): TableColumnSetting[] => [
  firstColumn === 'name'
    ? {
        key: TableColumnKey.NameObject,
        selected: true,
        cellClassName: 'customSelection textAlignStart',
        filterType: FilterTypes.Text,
        width: 2.5 * MIN_CELL_WIDTH,
      }
    : {
        key: TableColumnKey.SymbolObject,
        selected: true,
        cellClassName: 'customSelection textAlignStart',
        filterType: FilterTypes.Text,
        width: 2.5 * MIN_CELL_WIDTH,
      },
  {
    key: TableColumnKey.CodeMarketBoard,
    selected: true,
    cellClassName: 'customSelection',
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.LastPrice,
    selected: true,
    cellClassName: 'customSelection',
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.Change,
    selected: true,
    cellClassName: 'customSelection',
    width: 1.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.ValToday,
    selected: true,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.ShortDescription,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.ISIN,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.Open,
    selected: false,
    cellClassName: 'customSelection',
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.Close,
    selected: false,
    cellClassName: 'customSelection',
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.VolToday,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.NameObjectType,
    selected: false,
    cellClassName: 'customSelection',
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PriceStep,
    selected: false,
    cellClassName: 'customSelection',
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PriceStepCost,
    selected: false,
    cellClassName: 'customSelection',
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.Lot,
    selected: false,
    cellClassName: 'customSelection',
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.MatDate,
    selected: false,
    cellClassName: 'customSelection',
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.GtBuy,
    selected: false,
    cellClassName: 'customSelection',
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.Yield,
    selected: false,
    cellClassName: 'customSelection',
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PSTNKD,
    selected: false,
    cellClassName: 'customSelection',
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.NextCoupon,
    selected: false,
    cellClassName: 'customSelection',
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.CouponRate,
    selected: false,
    cellClassName: 'customSelection',
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.CouponValue,
    selected: false,
    cellClassName: 'customSelection',
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.BuyBackDate,
    selected: false,
    cellClassName: 'customSelection',
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.DaysToBuyBack,
    selected: false,
    cellClassName: 'customSelection',
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.DaysToMat,
    selected: false,
    cellClassName: 'customSelection',
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.IdFi,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
];

export const getDefaultOrderTableColumnSetting = (
  firstColumn?: string
): TableColumnSetting[] => [
  firstColumn === 'name'
    ? {
        key: TableColumnKey.NameObject,
        selected: true,
        cellClassName: 'customSelection textAlignStart',
        filterType: FilterTypes.Text,
        width: 2.5 * MIN_CELL_WIDTH,
      }
    : {
        key: TableColumnKey.SymbolObject,
        selected: true,
        cellClassName: 'customSelection textAlignStart',
        filterType: FilterTypes.Text,
        width: 2.5 * MIN_CELL_WIDTH,
      },
  {
    key: TableColumnKey.Direction,
    selected: true,
    cellClassName: 'customSelection',
    width: 3 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.DisplayPrice,
    selected: true,
    cellClassName: 'customSelection',
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.Quantity,
    selected: true,
    cellClassName: 'customSelection',
    width: 3 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.Rest,
    selected: true,
    cellClassName: 'customSelection',
    width: 3 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.Type,
    selected: true,
    cellClassName: 'customSelection',
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.DisplayStatus,
    selected: true,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.LifeTime,
    selected: true,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.WithdrawTime,
    selected: true,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.ShortDescription,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.CodeSubAccount,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.AcceptTime,
    selected: false,
    cellClassName: 'customSelection',
    width: 3 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.AcceptDate,
    selected: false,
    cellClassName: 'customSelection',
    width: 3 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.Executed,
    selected: false,
    cellClassName: 'customSelection',
    width: 3 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.OrderNumEDocument,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.OrderLogin,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.MarketNameMarketBoard,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.ReleaseNameObject,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.StopPrice,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.LimitPrice,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.OrderClientNumEDocument,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
];

export const getDefaultOperationsColumnSetting = (
  firstColumn?: string
): TableColumnSetting[] => [
  firstColumn === 'name'
    ? {
        key: TableColumnKey.NameObject,
        selected: true,
        cellClassName: 'customSelection',
        filterType: FilterTypes.Text,
        width: 2.5 * MIN_CELL_WIDTH,
      }
    : {
        key: TableColumnKey.SymbolObject,
        selected: true,
        cellClassName: 'customSelection',
        filterType: FilterTypes.Text,
        width: 2.5 * MIN_CELL_WIDTH,
      },
  {
    key: TableColumnKey.OperationBuySell,
    selected: true,
    cellClassName: 'customSelection',
    width: 3 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.OperationQuantity,
    selected: true,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.OperationPrice,
    selected: true,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.OperationValue,
    selected: true,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.OperationTime,
    selected: true,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.OperationDate,
    selected: true,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.ShortDescription,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.ReleaseNameObject,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.MarketNameMarketBoard,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.OperationCurrency,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.OperationStatus,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.OperationAccount,
    selected: false,
    cellClassName: 'customSelection',
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.CodeSubAccount,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.OrderLogin,
    selected: false,
    cellClassName: 'customSelection',
    width: 3 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.OperationNumEDocument,
    selected: false,
    cellClassName: 'customSelection',
    width: 3 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.OrderComment,
    selected: false,
    cellClassName: 'customSelection',
    width: 3 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.OrderClientNumEDocument,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.OperationYield,
    selected: false,
    cellClassName: 'customSelection',
    width: 3 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.OperationAccruedInt,
    selected: false,
    cellClassName: 'customSelection',
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.OperationSettleDate,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.OperationDeliveryDate,
    selected: false,
    cellClassName: 'customSelection',
    width: 3 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.OperationTypeId,
    selected: false,
    cellClassName: 'customSelection',
    width: 4 * MIN_CELL_WIDTH,
  },
];

export const getDefaultPositionColumnSetting = (
  firstColumn?: string
): TableColumnSetting[] => [
  firstColumn === 'name'
    ? {
        key: TableColumnKey.NameObject,
        selected: true,
        cellClassName: 'customSelection',
        filterType: FilterTypes.Text,
        width: 2.5 * MIN_CELL_WIDTH,
      }
    : {
        key: TableColumnKey.SymbolObject,
        selected: true,
        cellClassName: 'customSelection',
        filterType: FilterTypes.Text,
        width: 2.5 * MIN_CELL_WIDTH,
      },
  {
    key: TableColumnKey.PositionTorgPos,
    selected: true,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PositionPrice,
    selected: true,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PositionConvertedPrice,
    selected: true,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PositionLiquidCost,
    selected: true,
    cellClassName: 'customSelection',
    width: 3 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PositionNplPrice,
    selected: true,
    cellClassName: 'customSelection',
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PositionNplPercent,
    selected: true,
    cellClassName: 'customSelection',
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PositionDailyPrice,
    selected: true,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PositionDailyPercent,
    selected: true,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PositionAssetsPart,
    selected: true,
    cellClassName: 'customSelection',
    width: 3 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.ShortDescription,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.CodeSubAccount,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.NameBalanceGroup,
    selected: false,
    cellClassName: 'customSelection',
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PositionNameType,
    selected: true,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.ReleaseNameObject,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PositionInPrice,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PositionCurSymbol,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PositionPriceSymbol,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PositionVariationMargin,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PositionDeposits,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PositionBackPos,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PositionBackPosCost,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PositionWithdrawal,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PositionIsMoney,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.Yield,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.NextCoupon,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PositionSessionBuyQty,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PositionSessionBuyVal,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PositionPSTNKD,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PositionNKD,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PositionLongOrders,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PositionPlanLong,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PositionShortOrders,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PositionPlanShort,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PositionSessionVal,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PositionSessionQty,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.MatDate,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PositionSessionSellQty,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PositionSessionSellVal,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PositionTorgPosCost,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PositionSubAccNalPos,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PositionTorgPosUchCost,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.PositionGO,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
];

export const defaultTradesColumnSetting: TableColumnSetting[] = [
  {
    key: TableColumnKey.TradePrice,
    selected: true,
    width: 1.5 * MIN_CELL_WIDTH,
    isDragDisabled: true,
  },
  {
    key: TableColumnKey.TradeQty,
    selected: true,
    cellClassName: 'customSelection',
    width: 1.5 * MIN_CELL_WIDTH,
    isDragDisabled: true,
  },
  {
    key: TableColumnKey.TradeBuySell,
    selected: true,
    width: MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.TradeTime,
    selected: false,
    width: 2 * MIN_CELL_WIDTH,
  },
];

export const defaultTradesFeedProps = {
  idFi: 144950,
  symbol: 'SBER',
  minimum: 0,
  timerActive: true,
  timer: DEFAULT_TRADE_FEED_TIMER,
  highlight: {},
};

export const defaultBondScreenerProps: BondScreenerProps = {
  selectedData: [],
};

export const getDefaultOperationshistorySetting = (
  firstColumn?: string
): TableColumnSetting[] => [
  firstColumn === 'name'
    ? {
        key: TableColumnKey.NameObject,
        selected: true,
        width: 2.5 * MIN_CELL_WIDTH,
        filterType: FilterTypes.Text,
      }
    : {
        key: TableColumnKey.SymbolObject,
        selected: true,
        width: 2.5 * MIN_CELL_WIDTH,
        filterType: FilterTypes.Text,
      },
  {
    key: TableColumnKey.OperationDate,
    selected: true,
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.OperationTime,
    selected: true,
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.MarketNameMarketBoard,
    selected: true,
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.OperationBuySell,
    selected: true,
    width: 3 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.OperationCurrency,
    selected: false,
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.OperationQuantity,
    selected: true,
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.OperationPrice,
    selected: true,
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.OperationValue,
    selected: true,
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.OperationIsSettled,
    selected: true,
    width: 3.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.OperationSettleDate,
    selected: true,
    width: 4 * MIN_CELL_WIDTH,
  },
];

export const defaultBondScreenerColumnSettings: TableColumnSetting[] = [
  {
    key: TableColumnKey.BsName,
    selected: true,
    cellClassName: 'customSelection',
    width: 3 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.BsUntilExpiration,
    selected: true,
    cellClassName: 'customSelection',
    width: 4 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.BsPrice,
    selected: true,
    cellClassName: 'customSelection',
    width: 1.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.BsYield,
    selected: true,
    cellClassName: 'customSelection',
    width: 1.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.BsCupYield,
    selected: true,
    cellClassName: 'customSelection',
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.BsAgencyRating,
    selected: true,
    cellClassName: 'customSelection',
    width: 1.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.BsCupSize,
    selected: true,
    cellClassName: 'customSelection',
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.BsCouponPaymentPerYear,
    selected: true,
    cellClassName: 'customSelection',
    width: 2 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.BsSectorName,
    selected: true,
    cellClassName: 'customSelection',
    width: 6.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.BsISIN,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.BsEmShortName,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.BsCountryName,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.BsNominal,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.BsCurrency,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.BsYieldToOffert,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.BsIsFloatingRate,
    selected: false,
    cellClassName: 'customSelection',
    width: 3 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.BsCupDate,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.BsDuration,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.BsMatDate,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.BsAmortization,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.BsIsOffert,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.BsOffertType,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.BsOffertDate,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.BsNkd,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.BsBondTypeName,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.BsIsReplacement,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.BsIsMortgageBonds,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.BsIsRestructing,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.BsRestructingDate,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.BsIsStructuredNote,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.BsAllowNonQualInvest,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.BsOffertPrice,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
  {
    key: TableColumnKey.BsIsSubordinatedDebt,
    selected: false,
    cellClassName: 'customSelection',
    width: 2.5 * MIN_CELL_WIDTH,
  },
];
