import { signOperation } from '@terminal/core/lib/rest/lkSignOperation';
import { LkSignOptions } from '@terminal/core/store/slices/lkSignSlice';

import { SignCodetableResponse } from './types/SignCodetableResponse';

export function signCodetable(options?: LkSignOptions) {
  return signOperation(
    '/api/settings/codetable/sign',
    'GET',
    undefined,
    options
  ) as Promise<SignCodetableResponse | undefined>;
}
