export const enum PASSWORD_CHANGE_ERROR {
  /**
   * Неправильный текущий пароль
   * */
  WRONG_PASSWORD = 2,
  /**
   * Новый пароль совпадает с текущим
   * */
  SAME_PASSWORD = 3,
}
