import { format, parseISO } from 'date-fns';

import { formatFullNameWithInitials } from '@terminal/common/shared/lib/formatFullNameWithInitials';
import { AccountConfidant } from '@terminal/core/lib/rest/lkAccountInfo';
import { Attorney } from '@terminal/lk-core';

import {
  ACCESS_LEVELS_MAP,
  AccessLevel,
} from '../../../entities/AccountsOperations';

import { AttorneyTableItem } from '../model/types/AttorneyTableItem';

const transformAttorneyToAttorneyTableItem = (
  attorney: Attorney
): AttorneyTableItem => {
  return {
    id: attorney.id,
    key: `attorney-${attorney.id}`,
    fullName: formatFullNameWithInitials(attorney.attorneyName),
    login: attorney.attorneySysName,
    account: attorney.accCode,
    endDate: format(parseISO(attorney.attorneyDateEnd), 'dd.MM.yyyy'),
    accessLevel: ACCESS_LEVELS_MAP[attorney.accessLevel as AccessLevel],
    canReject: attorney.canReject,
    canDownload: true,
  };
};

const transformManagerToAttorneyTableItem = (
  manager: AccountConfidant
): AttorneyTableItem => {
  return {
    key: `manager-${manager.sysName}-${manager.accCode}`,
    fullName: formatFullNameWithInitials(manager.fullName),
    login: manager.sysName,
    account: manager.accCode,
    endDate: format(parseISO(manager.accessDate), 'dd.MM.yyyy'),
    accessLevel: manager.accessLevel,
    canDownload: false,
  };
};

export const mergeAttorneys = (
  attorneys: Attorney[] = [],
  managers?: AccountConfidant[]
): AttorneyTableItem[] => {
  const mappedAttorneys = attorneys.map(transformAttorneyToAttorneyTableItem);

  if (!managers) {
    return mappedAttorneys;
  }

  const mappedManagers = managers.map(transformManagerToAttorneyTableItem);

  return [...mappedAttorneys, ...mappedManagers];
};
