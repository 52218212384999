import { signOperation } from '@terminal/core/lib/rest/lkSignOperation';
import { LkSignOptions } from '@terminal/core/store/slices/lkSignSlice';

import { PasswordChangeRequest } from './types/PasswordChangeRequest';

export interface PasswordChangeParams {
  params: PasswordChangeRequest;
  signOptions?: LkSignOptions;
}

export async function sendPasswordChange(data: PasswordChangeParams) {
  return await signOperation(
    '/api/settings/save/password',
    'POST',
    data.params,
    data.signOptions
  );
}
