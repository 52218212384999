import { format, startOfMonth } from 'date-fns';
import isEqual from 'lodash/isEqual';
import { useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDebounce } from 'use-debounce';
import { Loader } from '@alfalab/core-components/loader';
import { FilterMIcon } from '@alfalab/icons-glyph/FilterMIcon';

import { FunctionalModalSidebar } from '@terminal/common/components/FunctionalModal';
import { Portal } from '@terminal/common/components/Portal';
import {
  dateToRequestFormat,
  DEFAULT_VIEW_DATE_FORMAT,
} from '@terminal/core/lib/rest/lkApi';
import { Treaty } from '@terminal/core/lib/rest/lkCommon';
import {
  CorporateActionHeader,
  CorporateActionsFilter,
  CorporateActionsHeadersParams,
} from '@terminal/core/lib/rest/lkCorporateActions';

import { Result } from '../../../../shared/ui/Result';
import { MessageDetail } from '../../components/MessageDetail';
import {
  MessagesFilter,
  MessagesFilterLine,
} from '../../components/MessagesFilter';
import { MessagesList } from '../../components/MessagesList';

import { useCorporateActionHeaders } from '../../hooks';

import styles from './Messages.module.css';

interface MessagesProps {
  treaty: Treaty | null;
}

const PAGE_SIZE = 20;

export const defaultFilter: CorporateActionsFilter = {
  from: format(startOfMonth(new Date()), DEFAULT_VIEW_DATE_FORMAT),
  to: format(new Date(), DEFAULT_VIEW_DATE_FORMAT),
  search: '',
  unreadOnly: false,
};

export const Messages = ({ treaty }: MessagesProps) => {
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState<CorporateActionsFilter>(defaultFilter);
  const [openFilter, setOpenFilter] = useState(false);
  const [openId, setOpenId] = useState('');
  const [openedItems, setOpenedItems] = useState<string[]>([]);

  const messagesParams = useMemo<CorporateActionsHeadersParams>(() => {
    return {
      ...filter,
      from: dateToRequestFormat(filter.from),
      to: dateToRequestFormat(filter.to),
      treaty: treaty?.treaty || 0,
      page,
      count: PAGE_SIZE,
    };
  }, [filter, page, treaty]);

  const [debouncedMessagesParams] = useDebounce<CorporateActionsHeadersParams>(
    messagesParams,
    250
  );

  const {
    data: messages,
    isFetching: messagesPending,
    isFetchingNextPage: messagesPendingNext,
    hasNextPage: hasMoreMessages,
    fetchNextPage: fetchMoreMessages,
  } = useCorporateActionHeaders(debouncedMessagesParams);

  const messagesItems = useMemo(() => {
    if (messages) {
      const headers = messages.pages.flatMap((page) => page.headers);

      return headers.map((header) => ({
        ...header,
        isRead: header.isRead || openedItems.indexOf(header.id) >= 0,
      }));
    }

    return null;
  }, [messages, openedItems]);

  const [loaderRef, loaderInView] = useInView();

  useEffect(() => {
    if (loaderInView) {
      fetchMoreMessages();
    }
  }, [loaderInView, fetchMoreMessages]);

  // При изменении фильтра устанавливаем 1-ю страницу
  useEffect(() => {
    setPage(1);
  }, [filter]);

  const isFilterActive = useMemo<boolean>(() => {
    return !isEqual(filter, defaultFilter);
  }, [filter]);

  const onCloseFilter = () => {
    setOpenFilter(false);
  };

  const onItemOpen = (item: CorporateActionHeader) => {
    setOpenId(item.id);
    setOpenedItems((prevIds) => {
      if (prevIds.indexOf(item.id) >= 0) {
        return [...prevIds];
      } else {
        return [...prevIds, item.id];
      }
    });
  };

  const onItemClose = () => {
    setOpenId('');
  };

  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <MessagesFilterLine
          filter={filter}
          active={isFilterActive}
          onFilterOpen={() => setOpenFilter(true)}
          onSetFilter={(filter) => setFilter(filter)}
        />
      </div>

      <div className={styles.results}>
        {Boolean(messagesItems) && (
          <MessagesList messages={messagesItems} onItemOpen={onItemOpen} />
        )}

        {(messagesPending || messagesPendingNext) && (
          <Loader dataTestId="history-loader" />
        )}

        {Boolean(messagesItems && messagesItems.length === 0) &&
          !(messagesPending || messagesPendingNext) && (
            <Result
              title="Результатов нет :("
              text={<>По заданным параметрам ничего не&nbsp;найдено</>}
              icon={<FilterMIcon />}
            />
          )}

        {hasMoreMessages && !(messagesPending || messagesPendingNext) && (
          <div ref={loaderRef}></div>
        )}
      </div>

      <Portal id="main-window-modals">
        <FunctionalModalSidebar
          open={openFilter}
          backLabel="Сообщения"
          onClose={onCloseFilter}
        >
          <MessagesFilter
            filter={filter}
            onSetFilter={(filter) => setFilter(filter)}
            onResetFilter={() => setFilter(defaultFilter)}
            onClose={onCloseFilter}
          />
        </FunctionalModalSidebar>
      </Portal>

      <Portal id="main-window-modals">
        <FunctionalModalSidebar
          open={Boolean(openId)}
          backLabel="Сообщения"
          onClose={onItemClose}
        >
          <MessageDetail id={openId} />
        </FunctionalModalSidebar>
      </Portal>
    </div>
  );
};
