import { FC } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';

import { HeaderMenuItem, MainMenuType } from '@terminal/core/types/ui';

import { useWidgetContext } from '../../../../shared';
import banner from '../../assets/margin-trade-banner.jpg';
import { MARGIN_TEST_ID } from '../../lib/const';

import styles from './MarginTradeDrawer.module.css';

interface Props {
  isMarginTradeTestPassed: boolean;
  onClose: () => void;
}

export const MarginTradeDrawer: FC<Props> = ({
  isMarginTradeTestPassed,
  onClose,
}) => {
  const { setHeaderMenuOpen, setLkSubMenuId } = useWidgetContext();

  const handleAction = () => {
    onClose();

    if (isMarginTradeTestPassed) {
      setHeaderMenuOpen(HeaderMenuItem.MARGIN_CALL);
    } else {
      setHeaderMenuOpen(HeaderMenuItem.MAIN_LK);
      setLkSubMenuId({
        id: MainMenuType.QualTesting,
        options: MARGIN_TEST_ID,
      });
    }
  };

  return (
    <div className={styles.content}>
      <div>
        <img src={banner} alt="" className={styles.image} />
        <Gap size="s" />
        <Typography.Title tag="div" view="xsmall" className={styles.quaternary}>
          Инвестируйте с плечом
        </Typography.Title>
        <Gap size="xs" />
        <Typography.Text
          tag="div"
          view="secondary-large"
          className={styles.quaternary}
        >
          Результаты работы могут стать еще лучше, если использовать
          маржинальное кредитование. Альфа-Инвестиции может предоставить
          дополнительные средства для вашей успешной торговли.
          <br />
          <br />
          Используйте ваши активы эффективнее, увеличив доходность за счет
          плеча. Можно заработать больше, взяв на себя дополнительный риск.
          <br />
          <br />
          Для того, чтобы торговать с плечом, необходимо пройти тестирование.
        </Typography.Text>
      </div>

      <ButtonDesktop size="xxs" onClick={handleAction}>
        {isMarginTradeTestPassed
          ? 'К параметрам маржинальной торговли'
          : 'Пройти тестирование'}
      </ButtonDesktop>
    </div>
  );
};
