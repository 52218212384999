import { ANALYTICS, DIMENSIONS_SCHEMAS } from '../../../constants/analytics';
import { track } from '../core';

export const trackBondScreenerWidget = {
  /**
   * Открытие виджета
   * */
  add: () => {
    track(
      ANALYTICS.CATEGORY.BOND_SCREENER_WIDGET,
      ANALYTICS.ACTION.CLICK,
      ANALYTICS.LABEL.ADD
    );
  },
  /**
   * Открытие виджета из добавления инструментов
   * */
  addFromInstruments: () => {
    track(
      ANALYTICS.CATEGORY.BOND_SCREENER_WIDGET,
      ANALYTICS.ACTION.CLICK,
      ANALYTICS.LABEL.ADD_FROM_INSTRUMENTS
    );
  },
  /**
   * Применение фильтра
   * */
  applyFilter: (name: string, value: string) => {
    track(
      ANALYTICS.CATEGORY.BOND_SCREENER_WIDGET,
      ANALYTICS.ACTION.CLICK,
      ANALYTICS.LABEL.APPLY_FILTER,
      null,
      null,
      [
        {
          schema: DIMENSIONS_SCHEMAS.CUSTOM,
          data: {
            1: name,
            2: value,
          },
        },
      ]
    );
  },
  /**
   * Удаление фильтра
   * */
  removeFilter: (name: string, value: string) => {
    track(
      ANALYTICS.CATEGORY.BOND_SCREENER_WIDGET,
      ANALYTICS.ACTION.CLICK,
      ANALYTICS.LABEL.REMOVE_FILTER,
      null,
      null,
      [
        {
          schema: DIMENSIONS_SCHEMAS.CUSTOM,
          data: {
            1: name,
            2: value,
          },
        },
      ]
    );
  },
  /**
   * Добавление фильтра
   * */
  addFilter: (name: string) => {
    track(
      ANALYTICS.CATEGORY.BOND_SCREENER_WIDGET,
      ANALYTICS.ACTION.CLICK,
      ANALYTICS.LABEL.ADD_FILTER,
      null,
      null,
      [
        {
          schema: DIMENSIONS_SCHEMAS.CUSTOM,
          data: {
            1: name,
          },
        },
      ]
    );
  },
  /**
   * Клик по кнопке Добавить фильтр
   * */
  filterButton: () => {
    track(
      ANALYTICS.CATEGORY.BOND_SCREENER_WIDGET,
      ANALYTICS.ACTION.CLICK,
      ANALYTICS.LABEL.FILTER_BUTTON
    );
  },
  /**
   * Клик по Шаблоны
   * */
  openTemplate: () => {
    track(
      ANALYTICS.CATEGORY.BOND_SCREENER_WIDGET,
      ANALYTICS.ACTION.CLICK,
      ANALYTICS.LABEL.OPEN_TEMPLATE
    );
  },
  /**
   * Переход по табам внутри Шаблонов
   * */
  choseTemplateTab: (name: string) => {
    track(
      ANALYTICS.CATEGORY.BOND_SCREENER_WIDGET,
      ANALYTICS.ACTION.CLICK,
      ANALYTICS.LABEL.CHOSE_TEMPLATE_TAB,
      null,
      null,
      [
        {
          schema: DIMENSIONS_SCHEMAS.CUSTOM,
          data: {
            1: name,
          },
        },
      ]
    );
  },
  /**
   * Применение шаблона
   * */
  applyTemplate: (name: string, type: string) => {
    track(
      ANALYTICS.CATEGORY.BOND_SCREENER_WIDGET,
      ANALYTICS.ACTION.CLICK,
      ANALYTICS.LABEL.APPLY_TEMPLATE,
      null,
      null,
      [
        {
          schema: DIMENSIONS_SCHEMAS.CUSTOM,
          data: {
            1: name,
            2: type,
          },
        },
      ]
    );
  },
  /**
   * Изменение названия шаблона поиска
   * */
  changeTemplate: (name: string) => {
    track(
      ANALYTICS.CATEGORY.BOND_SCREENER_WIDGET,
      ANALYTICS.ACTION.CLICK,
      ANALYTICS.LABEL.CHANGE_TEMPLATE,
      null,
      null,
      [
        {
          schema: DIMENSIONS_SCHEMAS.CUSTOM,
          data: {
            1: name,
          },
        },
      ]
    );
  },
  /**
   * Удаление шаблона поиска
   * */
  removeTemplate: (name: string) => {
    track(
      ANALYTICS.CATEGORY.BOND_SCREENER_WIDGET,
      ANALYTICS.ACTION.CLICK,
      ANALYTICS.LABEL.REMOVE_TEMPLATE,
      null,
      null,
      [
        {
          schema: DIMENSIONS_SCHEMAS.CUSTOM,
          data: {
            1: name,
          },
        },
      ]
    );
  },
  /**
   * Cохранение поиска
   * */
  newTemplate: (name: string) => {
    track(
      ANALYTICS.CATEGORY.BOND_SCREENER_WIDGET,
      ANALYTICS.ACTION.CLICK,
      ANALYTICS.LABEL.NEW_TEMPLATE,
      null,
      null,
      [
        {
          schema: DIMENSIONS_SCHEMAS.CUSTOM,
          data: {
            1: name,
          },
        },
      ]
    );
  },
  /**
   * Удавление всех фильтров
   * */
  removeFilters: () => {
    track(
      ANALYTICS.CATEGORY.BOND_SCREENER_WIDGET,
      ANALYTICS.ACTION.CLICK,
      ANALYTICS.LABEL.REMOVE_FILTERS
    );
  },
  /**
   * Переход по табам в результате поиска
   * */
  resultTab: (name: string) => {
    track(
      ANALYTICS.CATEGORY.BOND_SCREENER_WIDGET,
      ANALYTICS.ACTION.CLICK,
      ANALYTICS.LABEL.RESULT_TAB,
      null,
      null,
      [
        {
          schema: DIMENSIONS_SCHEMAS.CUSTOM,
          data: {
            1: name,
          },
        },
      ]
    );
  },
  /**
   * Добавление в список
   * */
  addToList: (name: string) => {
    track(
      ANALYTICS.CATEGORY.BOND_SCREENER_WIDGET,
      ANALYTICS.ACTION.CLICK,
      ANALYTICS.LABEL.ADD_TO_LIST,
      null,
      null,
      [
        {
          schema: DIMENSIONS_SCHEMAS.CUSTOM,
          data: {
            1: name,
          },
        },
      ]
    );
  },
  /**
   * Очистить выбор
   * */
  removeChosen: () => {
    track(
      ANALYTICS.CATEGORY.BOND_SCREENER_WIDGET,
      ANALYTICS.ACTION.CLICK,
      ANALYTICS.LABEL.REMOVE_CHOSEN
    );
  },
  /**
   * Действия в контекстном меню
   * */
  contextMenu: (action: string, isin: string) => {
    track(
      ANALYTICS.CATEGORY.BOND_SCREENER_WIDGET,
      ANALYTICS.ACTION.CLICK,
      ANALYTICS.LABEL.CONTEXT_MENU,
      null,
      null,
      [
        {
          schema: DIMENSIONS_SCHEMAS.CUSTOM,
          data: {
            1: action,
            2: isin,
          },
        },
      ]
    );
  },
  /**
   * Клик по кнопке Настройки
   * */
  settings: () => {
    track(
      ANALYTICS.CATEGORY.BOND_SCREENER_WIDGET,
      ANALYTICS.ACTION.CLICK,
      ANALYTICS.LABEL.SETTINGS
    );
  },
  /**
   * Клик по кнопке Настроить колонки
   * */
  settingsColumns: () => {
    track(
      ANALYTICS.CATEGORY.BOND_SCREENER_WIDGET,
      ANALYTICS.ACTION.CLICK,
      ANALYTICS.LABEL.SETTINGS_COLUMNS
    );
  },
  /**
   * Поиск по колонкам (через 2с после ввода текста или очистки инпута)
   * */
  settingsColumnsSearch: () => {
    track(
      ANALYTICS.CATEGORY.BOND_SCREENER_WIDGET,
      ANALYTICS.ACTION.CLICK,
      ANALYTICS.LABEL.SETTINGS_COLUMNS_SEARCH
    );
  },
  /**
   * Сохранение выбранных колонок
   * */
  settingsColumnsSave: () => {
    track(
      ANALYTICS.CATEGORY.BOND_SCREENER_WIDGET,
      ANALYTICS.ACTION.CLICK,
      ANALYTICS.LABEL.SETTINGS_COLUMNS_SAVE
    );
  },
  /**
   * Отмена выбранных колонок
   * */
  settingsColumnsCancel: () => {
    track(
      ANALYTICS.CATEGORY.BOND_SCREENER_WIDGET,
      ANALYTICS.ACTION.CLICK,
      ANALYTICS.LABEL.SETTINGS_COLUMNS_CANCEL
    );
  },
  /**
   * Клик по кнопке Дублировать виджет
   * */
  duplicate: () => {
    track(
      ANALYTICS.CATEGORY.BOND_SCREENER_WIDGET,
      ANALYTICS.ACTION.CLICK,
      ANALYTICS.LABEL.DUPLICATE
    );
  },
  /**
   * Закрыть виджет
   * */
  close: () => {
    track(
      ANALYTICS.CATEGORY.BOND_SCREENER_WIDGET,
      ANALYTICS.ACTION.CLICK,
      ANALYTICS.LABEL.CLOSE
    );
  },
};
