import React from 'react';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkHeavyMIcon } from '@alfalab/icons-glyph/CheckmarkHeavyMIcon';

import { ReactComponent as ChainIcon } from '@terminal/common/components/icons/chain.svg';
import { ReactComponent as IcebergIcon } from '@terminal/common/components/icons/iceberg.svg';
import { ReactComponent as MoreIcon } from '@terminal/common/components/icons/more.svg';
import { orderTypeNameMap } from '@terminal/core/constants/orders';
import { plural } from '@terminal/core/lib/plural';
import { AdditionalInstruction } from '@terminal/core/types/additionalInstruction';
import { OrderSide } from '@terminal/core/types/trading';
import { OrderContent } from '@terminal/widgets/features/Orders';

import { ConditionButtonContent } from '../Condition';
import { getSLTPButtonText } from '../SLTP';
import { getBracketPercent } from '../SLTP/lib/getBracketPercent';
import { getSLTPButtonShortText } from '../SLTP/lib/getSLTPButtonShortText';

import { AdditionalInstructionView } from '../types';

import styles from './instructionsOptionsParams.module.css';

export const instructionsOptionsParams: Record<
  AdditionalInstruction,
  AdditionalInstructionView
> = {
  [AdditionalInstruction.ICEBERG]: {
    text: (short?: boolean) => (short ? 'Айсберг' : 'Айсберг заявка'),
    getButtonText: (iceberg, { isLotCount }, isShort) => {
      if (!iceberg) {
        return (
          <>
            {instructionsOptionsParams[AdditionalInstruction.ICEBERG].icon}
            {isShort ? 'Айсберг' : 'Айсберг заявка'}
          </>
        );
      }

      const buttonSuffix = isLotCount
        ? plural(['лоту', 'лота', 'лотов'], iceberg.visibleQuantity)
        : 'шт';

      return `по ${iceberg.visibleQuantity} ${buttonSuffix}`;
    },
    icon: (
      <IcebergIcon
        height={16}
        width={16}
        color="var(--color-dark-graphic-secondary)"
        className={styles.additionalOptionIcon}
      />
    ),
  },
  [AdditionalInstruction.SLTP]: {
    text: (short?: boolean) => (short ? 'СЛ и ТП' : 'Стоп-лосс и Тейк-профит'),
    getButtonText: (sltp, { price, side, priceStep, symbol }, isShort) => {
      if (!sltp || (!sltp.isSlEnabled && !sltp.isTpEnabled)) {
        return (
          <>
            {instructionsOptionsParams[AdditionalInstruction.SLTP].icon}
            {isShort ? 'СЛ и ТП' : 'Стоп-лосс и Тейк-профит'}
          </>
        );
      }

      return (
        <>
          {(isShort ? getSLTPButtonShortText : getSLTPButtonText)(
            sltp,
            price,
            side,
            priceStep,
            symbol
          )}
        </>
      );
    },
    icon: (
      <MoreIcon
        height={16}
        width={16}
        color="var(--color-dark-graphic-secondary)"
        className={styles.additionalOptionIcon}
      />
    ),
    getTooltipContent: (sltp, { price, side, priceStep, symbol }) => {
      if (!sltp) {
        return 'Добавить стоп-лосс и тейк-профит';
      }

      if (!sltp.isSlEnabled) {
        return null;
      }

      return (
        <div className={styles.sltpTooltipContent}>
          <div className={styles.sltpTooltipRow}>
            <Typography.Text
              view="secondary-small"
              tag="div"
              className={styles.graphic}
            >
              {orderTypeNameMap[sltp.slOrderType]}
            </Typography.Text>
            <Typography.Text view="secondary-small" tag="div" weight="bold">
              {side === OrderSide.BUY ? '-' : '+'}
              {getBracketPercent(
                sltp.slPriceType,
                sltp.slPrice,
                price,
                priceStep
              )}
              <span className={styles.graphic}>%</span>
            </Typography.Text>
          </div>

          {sltp.isTpEnabled && (
            <div className={styles.sltpTooltipRow}>
              <Typography.Text
                view="secondary-small"
                tag="div"
                className={styles.graphic}
              >
                Тейк-профит
              </Typography.Text>
              <Typography.Text view="secondary-small" tag="div" weight="bold">
                {side === OrderSide.BUY ? '+' : '-'}
                {getBracketPercent(
                  sltp.tpPriceType,
                  sltp.tpPrice,
                  price,
                  priceStep
                )}
                <span className={styles.graphic}>%</span>
              </Typography.Text>
            </div>
          )}
        </div>
      );
    },
  },
  [AdditionalInstruction.LINKEDORDER]: {
    text: () => 'Связанная заявка',
    getButtonText: (linkedOrder, { idFi, priceStep, symbol }) => {
      if (!linkedOrder) {
        return (
          <>
            {instructionsOptionsParams[AdditionalInstruction.LINKEDORDER].icon}
            Связанная заявка
          </>
        );
      }

      return (
        <OrderContent
          idFI={idFi}
          priceStep={priceStep || 0.01}
          currencyCode={symbol}
          order={linkedOrder}
        />
      );
    },
    icon: (
      <ChainIcon
        height={16}
        width={16}
        color="var(--color-dark-graphic-secondary)"
        className={styles.additionalOptionIcon}
      />
    ),
  },
  [AdditionalInstruction.CONDITION]: {
    text: () => 'Если выполнится условие',
    getButtonText: (condition) => {
      if (!condition) {
        return (
          <>
            {instructionsOptionsParams[AdditionalInstruction.CONDITION].icon}
            Если выполнится условие
          </>
        );
      }

      return (
        <ConditionButtonContent
          priceDirection={condition.activationPriceDirection}
          controlledIdFi={condition.idFIActivate}
          stopPrice={condition.stopPrice}
          idPriceControlType={condition.idPriceControlType}
        />
      );
    },
    icon: (
      <CheckmarkHeavyMIcon
        height={16}
        width={16}
        color="var(--color-dark-graphic-secondary)"
        className={styles.additionalOptionIcon}
      />
    ),
  },
};
