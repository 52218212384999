import {
  Action,
  Actions,
  BorderNode,
  DropInfo,
  ITabSetRenderValues,
  Layout as FlexLayout,
  Model,
  Node,
  TabNode,
  TabSetNode,
} from 'flexlayout-react';
import difference from 'lodash/difference';
import isUndefined from 'lodash/isUndefined';
import {
  forwardRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';

import { trackBondScreenerWidget } from '@terminal/core/lib/analytics/bondScreener/bondScreener';
import { logWorkspaceLoad } from '@terminal/core/lib/analytics/performance';
import { shallow, useStore } from '@terminal/core/store';
import { activeConfigurationSelector } from '@terminal/core/store/selectors/workspaceConfigurations/activeConfigurationSelector';
import {
  GridLayoutModel,
  IJsonRowNode,
  LayoutType,
  Widget,
  WidgetLinkProps,
} from '@terminal/core/types/layout';
import { useWidgetContext } from '@terminal/widgets';

import { ChartSettingsController } from '../../legacy/widgets/chart/core/ChartSettingsController';
import { WidgetPickerDropdownMenu } from '../../shared/ui/WidgetPickerDropdownMenu';
import { renderTab } from './lib/renderTab';
import { AddWidgetPlusButton } from './ui/AddWidgetPlusButton';
import { WidgetFactory } from './ui/WidgetFactory';
import {
  countRowAndCol,
  findFirstTabset,
  findLayoutWidgets,
  i18nMapper,
  isDropAllow,
} from './utils';

import { useModelMigration } from './hooks/useModelMigration';

import './assets/style/dark.css';

interface WrapperSize {
  width: number;
  height: number;
}

interface LayoutProps {
  onAddFromTabSetButton: (node: TabSetNode | BorderNode, key: Widget) => void;
  activeLayoutKey: number;
}
const ICONS = {
  more: (_, tabs) => tabs.length || null,
  close: <CrossMIcon height={18} width={18} />,
};

const FONT_STYLE = { size: '13px' };

const factoryHandler = (node: TabNode) => <WidgetFactory node={node} />;

export const Layout = forwardRef<FlexLayout, LayoutProps>((props, ref) => {
  const { activeLayoutKey, onAddFromTabSetButton } = props;

  const [setJson, unlinkFromGroup] = useStore(
    (state) => [state.setLayoutJson, state.unlinkFromGroup],
    shallow
  );

  const widgetProps = useWidgetContext();

  const { lastAutoSavedConfig } = useStore(activeConfigurationSelector);
  const { models, layouts } = lastAutoSavedConfig;

  const perfomanceMetric = useMemo(
    () => logWorkspaceLoad(activeLayoutKey),
    [activeLayoutKey]
  );

  const json = layouts[activeLayoutKey] as GridLayoutModel;
  const model = models[activeLayoutKey] ?? Model.fromJson(json);

  useEffect(() => {
    if (model.getActiveTabset()) {
      return;
    }

    const firstTabset = findFirstTabset(json.layout);

    if (firstTabset?.id) {
      model.doAction(Actions.setActiveTabset(firstTabset.id));
    }
  }, [model, json]);

  const [size, setSize] = useState<WrapperSize>({ width: 0, height: 0 });

  const { row, col } = useMemo(
    () => countRowAndCol(json.layout as IJsonRowNode),
    [json.layout]
  );

  useLayoutEffect(() => {
    const updateSize = () => {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    model.doAction(
      Actions.updateModelAttributes({
        tabSetMinHeight: size.height ? size.height / 6 : 0,
        tabSetMinWidth: size.width ? size.width / 10 : 0,
      })
    );
  }, [size, model]);

  model?.setOnAllowDrop((dragNode: Node, dropInfo: DropInfo) =>
    isDropAllow(dragNode, dropInfo, { row, col }, model.getRoot().getId())
  );

  useModelMigration();

  const onRenderTabSet = useCallback(
    (node: TabSetNode | BorderNode, renderValues: ITabSetRenderValues) => {
      const id = node.getId();
      const hasChildren = Boolean(node.getChildren().length);

      if (hasChildren) {
        renderValues.stickyButtons.push(
          <WidgetPickerDropdownMenu
            key={id}
            label="Добавить виджет"
            onPick={(widget) => onAddFromTabSetButton(node, widget)}
          >
            <AddWidgetPlusButton />
          </WidgetPickerDropdownMenu>
        );
      }
    },
    [onAddFromTabSetButton]
  );

  const onAction = useCallback(
    (action: Action) => {
      switch (action.type) {
        case Actions.DELETE_TAB:
          //@ts-expect-error
          const node: TabNode = model.getNodeById(action.data.node || '');
          const component = node.getComponent();
          const config = node.getConfig() as WidgetLinkProps;

          if (!isUndefined(config?.link)) {
            unlinkFromGroup(node.getId(), config.link);
          }

          if (component === Widget.CHART) {
            ChartSettingsController.deleteChartStoredSettings(
              action.data.node as string
            );
          }

          if (component === Widget.BOND_SCREENER) {
            trackBondScreenerWidget.close();
          }

          return action;
        default:
          return action;
      }
    },
    [model, unlinkFromGroup]
  );

  const onModelChange = useDebouncedCallback((newModel: Model) => {
    // Находим все таб бары на рабочем столе при отрисовке виджетов,
    // добавлении или удалении элементов и изменении ширины
    const tabbars = [
      ...document.querySelectorAll('.flexlayout__tabset_tabbar_outer'),
    ];

    // Считаем количество табов внутри каждого таб бара
    // Ставим или удаляем класс, который скрывает кнопку hidden tabs
    tabbars.forEach((node) => {
      const [tabsetNode, toolbarNode] = node.children;
      const toolbarClassName = 'flexlayout__tab_toolbar--single-tab';
      const childrenCount = tabsetNode.querySelectorAll(
        '.flexlayout__tab_button'
      ).length;

      if (childrenCount <= 1) {
        toolbarNode.classList.add(toolbarClassName);
      } else if (toolbarNode.classList.contains(toolbarClassName)) {
        toolbarNode.classList.remove(toolbarClassName);
      }
    });

    return setJson<LayoutType.Grid>(activeLayoutKey, {
      ...newModel.toJson(),
      type: LayoutType.Grid,
    });
  }, 500);

  const resetLoadedWidgets = widgetProps.resetLoadedWidgets;

  useLayoutEffect(() => {
    resetLoadedWidgets();
  }, [activeLayoutKey, resetLoadedWidgets]);

  useEffect(() => {
    if (perfomanceMetric.isReady()) {
      return;
    }

    const initialWidgets = findLayoutWidgets(model.getRoot().getChildren())
      .filter((i) => i.isVisible())
      .map((i) => i.getId());

    // При загруке страницы все вкладки помечены isVisible=false
    // пропускаем эту проверку до тех пор, пока все вкладки не отрисуются
    if (
      initialWidgets.length === 0 &&
      model.getRoot().getChildren().length !== 0
    ) {
      return;
    }

    if (difference(initialWidgets, widgetProps.loadedWidgets).length === 0) {
      perfomanceMetric.ready(initialWidgets.length);
    }
  }, [perfomanceMetric, widgetProps.loadedWidgets, model]);

  return (
    <FlexLayout
      ref={ref}
      model={model}
      icons={ICONS}
      factory={factoryHandler}
      onAction={onAction}
      onModelChange={onModelChange}
      onRenderTabSet={onRenderTabSet}
      onRenderTab={renderTab}
      font={FONT_STYLE}
      i18nMapper={i18nMapper}
    />
  );
});

Layout.displayName = 'Layout';
