import { TabNode } from 'flexlayout-react';
import { ITabRenderValues } from 'flexlayout-react/declarations/view/Layout';

import { useStore } from '@terminal/core/store';
import { BaseWidgetConfig, Widget } from '@terminal/core/types/layout';

import { HeaderContent, Link } from '../../../shared/ui/WidgetTab';

export const renderTab = (node: TabNode, renderValues: ITabRenderValues) => {
  const component = node.getComponent() as Widget;
  const config: null | BaseWidgetConfig = node.getConfig();
  const name = node.getName();
  const nodeId = node.getId();

  renderValues.content = (
    <HeaderContent component={component} config={config} name={name} />
  );
  renderValues.leading = node.isVisible() ? (
    <Link
      nodeId={nodeId}
      linkToGroup={useStore.getState().linkToGroup}
      config={config}
    />
  ) : null;
};
