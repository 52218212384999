import log from 'loglevel';

import { allowedOrderParamsMap } from '../../mapping/allowedOrderParamsMapping';
import {
  AllowedOrderParamEntity,
  FrontEndType,
  InitFinishedEntity,
  MessageUnitedType,
  Operation,
  ResetDataRequestEntity,
} from '../client/entities';
import { EntityType } from '../client/entityTypes';
import { getEntityIdByName } from '../client/serialization';
import AdirClientService from '../client/service';
import { DictionariesDB } from '../db/dictionaries';

import { AllowedOrderParams } from '../../types/allowedOrderParams';

export enum AllowedOrderParamsServiceEvents {
  INIT_ORDER_PARAMS = 'INIT_ORDER_PARAMS',
  ADD_OR_UPDATE_ORDER_PARAM = 'ADD_OR_UPDATE_ORDER_PARAM',
  REMOVE_ORDER_PARAM = 'REMOVE_ORDER_PARAM',
  RESET_ORDER_PARAMS = 'RESET_ORDER_PARAMS',
}

class AllowedOrderParamsService extends AdirClientService {
  initDone = false;
  initialData: AllowedOrderParams[];
  lastDataVersion: BigInt = 0n;

  override async init() {
    this.addClientListener(EntityType.AllowedOrderParamEntity, this.onEntity);
    this.addClientListener(EntityType.InitFinishedEntity, this.onInitFinished);
    this.addClientListener(EntityType.ResetDataRequestEntity, this.onReset);
  }

  public async fetch() {
    this.initDone = false;
    this.initialData = [];
    this.lastDataVersion = 0n;

    try {
      const storedData = await DictionariesDB.get<AllowedOrderParams[]>(
        'AllowedOrderParams'
      );

      if (storedData && storedData.lastDataVersion && storedData.data) {
        this.lastDataVersion = storedData.lastDataVersion;
        this.initialData = storedData.data;
      }
    } catch (e) {
      log.debug(e);
    }

    this.client.subscribe(
      FrontEndType.AuthAndOperInitServer,
      EntityType.AllowedOrderParamEntity,
      [],
      this.lastDataVersion
    );
  }

  public save = (data: AllowedOrderParams[]) => {
    DictionariesDB.save('AllowedOrderParams', this.lastDataVersion, data);
  };

  private onEntity = (message: MessageUnitedType) => {
    let maxDataVersion: BigInt = 0n;
    const params = message.data as AllowedOrderParamEntity[];

    params.forEach((param) => {
      if (param.Version > maxDataVersion) {
        maxDataVersion = param.Version;
      }

      if (
        param.Operation === Operation.Inserted ||
        param.Operation === Operation.Updated
      ) {
        if (this.initDone) {
          this.emit(
            AllowedOrderParamsServiceEvents.ADD_OR_UPDATE_ORDER_PARAM,
            allowedOrderParamsMap(param)
          );
        } else {
          this.initialData.push(allowedOrderParamsMap(param));
        }
      } else if (param.Operation === Operation.Deleted) {
        this.emit(
          AllowedOrderParamsServiceEvents.REMOVE_ORDER_PARAM,
          allowedOrderParamsMap(param)
        );
      }
    });

    if (maxDataVersion > this.lastDataVersion) {
      this.lastDataVersion = maxDataVersion;
    }
  };

  private onInitFinished = (message: MessageUnitedType) => {
    const inits = message.data as InitFinishedEntity[];

    inits.forEach((i) => {
      if (i.Command === getEntityIdByName(EntityType.AllowedOrderParamEntity)) {
        this.initDone = true;
        this.emit(
          AllowedOrderParamsServiceEvents.INIT_ORDER_PARAMS,
          this.initialData
        );
      }
    });
  };

  private onReset = (message: MessageUnitedType) => {
    const resetEntities = message.data as ResetDataRequestEntity[];

    resetEntities.forEach((entity) => {
      if (
        entity.DataType ===
        getEntityIdByName(EntityType.AllowedOrderParamEntity)
      ) {
        this.initDone = false;
        this.lastDataVersion = 0n;
        this.emit(
          AllowedOrderParamsServiceEvents.RESET_ORDER_PARAMS,
          this.initialData
        );
      }
    });
  };
}

const allowedOrderParamsService = new AllowedOrderParamsService();

export { allowedOrderParamsService as AllowedOrderParamsService };
