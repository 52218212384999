import { useCallback, useMemo, useState } from 'react';

import { Sort } from '@terminal/core/types/layout';

interface Props {
  sort?: Sort;
  setSort?: (sort?: Sort) => void;
}

// то же самое что и useSortTable, но без сортировки на стороне клиента
export const useSortTableWithoutReorder = ({ sort, setSort }: Props) => {
  const [localSort, setLocalSort] = useState<Sort | undefined>(sort);
  const isLocalSort = !setSort;
  const sortState = useMemo(
    () => (isLocalSort ? localSort : sort),
    [isLocalSort, localSort, sort]
  );

  const onSort = useCallback(
    (key: string) => {
      if (sortState?.key === key) {
        setSort
          ? setSort({ key, asc: !sortState?.asc })
          : setLocalSort({ key, asc: !sortState?.asc });
      } else {
        setSort
          ? setSort({ key, asc: true })
          : setLocalSort({ key, asc: true });
      }
    },
    [setSort, sortState]
  );

  return { sort: sortState, onSort };
};
