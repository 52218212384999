import cn from 'classnames';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import {
  MutableRefObject,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  VFC,
} from 'react';
import { Typography } from '@alfalab/core-components/typography';

import { ChatMessagesGroup } from '@terminal/chat/types';

import { MessagesGroup } from '../MessagesGroup';

import styles from './MessagesDay.module.css';

export type MessagesDayProps = {
  showDate: boolean;
  groups: ChatMessagesGroup[];
  date: string;
};

export const MessagesDay: VFC<MessagesDayProps> = (props) => {
  const { showDate, groups, date } = props;

  const observer: MutableRefObject<IntersectionObserver | undefined> = useRef();
  const dayTopRef: RefObject<HTMLDivElement> = useRef(null);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      ([e]) => {
        e.target.toggleAttribute('data-stuck', e.intersectionRatio < 1);
      },
      { threshold: [1] }
    );

    if (dayTopRef.current) {
      observer.current.observe(dayTopRef.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  const beautyDate = useMemo(() => {
    const today = new Date();
    const yesterday = new Date().setDate(today.getDate() - 1);

    switch (true) {
      case date === format(today, 'd MMMM yyyy', { locale: ru }):
        return 'Сегодня';
      case date === format(yesterday, 'd MMMM yyyy', { locale: ru }):
        return 'Вчера';
      default:
        return date;
    }
  }, [date]);

  return (
    <div>
      <div ref={dayTopRef} className={styles.dayTop} />
      <div
        className={cn(styles.dateWrapper, {
          [styles.dateWrapperVisible]: showDate,
        })}
      >
        <Typography.Text
          view="secondary-small"
          color="secondary"
          className={styles.date}
          onClick={() =>
            dayTopRef?.current?.scrollIntoView({ behavior: 'smooth' })
          }
        >
          {beautyDate}
        </Typography.Text>
      </div>
      {groups.map((group, index) => (
        <MessagesGroup key={index} messagesGroup={group} />
      ))}
    </div>
  );
};
