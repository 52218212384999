import { useAlfaDirectContext } from '@terminal/alfadirect/hooks';
import { currenciesFI } from '@terminal/core/constants/FIModal';
import { quoteLastSelector } from '@terminal/core/lib/domain/quoteSelector';
import { getCurrencyPrice } from '@terminal/core/lib/formulas';
import { getCashFromQuantity } from '@terminal/core/lib/formulas/getCashFromQuantity';
import { AccountItem } from '@terminal/core/types/account';
import { ExtendedFI } from '@terminal/core/types/extendedFI';
import { FinInfoExt } from '@terminal/core/types/quotes';
import { OrderSide } from '@terminal/core/types/trading';

import { MAX_PRICE_AMOUNT } from '../const/form';
import { VolumeType } from '../model';

import { useIsLotCount } from '../../../shared/hooks/useIsLotCount';
import { useWarranty } from '../../../shared/hooks/useWarranty';

interface Props {
  volumeType: VolumeType;
  quantity: number;
  price: number;
  idFi: number;
  side: OrderSide;
  selectedAccount?: AccountItem;
  fullFi?: ExtendedFI;
  finInfoExt?: FinInfoExt;
}

export const useCashQuantity = ({
  volumeType,
  fullFi,
  quantity,
  price,
  selectedAccount,
  finInfoExt,
  idFi,
  side,
}: Props) => {
  const isLotCount = useIsLotCount();
  const { useQuotes, useFinInfoExt } = useAlfaDirectContext();

  const warranty = useWarranty(idFi, price, {
    side,
    finInfoExt,
    idAccount: selectedAccount?.idAccount,
  });

  const currenciesQuotes = useQuotes(currenciesFI, {
    throttleTimeout: 1000,
    selector: quoteLastSelector,
  });

  const currenciesFinInfoExts = useFinInfoExt(currenciesFI);

  if (volumeType !== VolumeType.Cash) {
    return 0;
  }

  /**
   * Цена валюты номинала
   * */
  const nominalCurrencyPrice = getCurrencyPrice(
    currenciesQuotes,
    currenciesFinInfoExts,
    fullFi?.idObjectFaceUnit
  );

  /**
   * Цена валюты ликвидного рынка
   * */
  const marketCurrencyPrice = getCurrencyPrice(
    currenciesQuotes,
    currenciesFinInfoExts,
    fullFi?.idObjectCurrency
  );

  const lot = finInfoExt?.lot || 1;

  const getCashQuantity = () => {
    const currentCashQuantity = getCashFromQuantity({
      nominalCurrencyPrice,
      marketCurrencyPrice,
      price,
      nominal: fullFi?.nominal || 0,
      quantity,
      isLotCount,
      warranty,
      lot,
      nkd: finInfoExt?.accruedInt || 0,
      idObjectGroup: fullFi?.idObjectGroup || 0,
    });

    return Math.min(
      // Делим на лотность, чтобы получить сумму для штук
      Math.ceil(currentCashQuantity / (isLotCount ? lot : 1)),
      MAX_PRICE_AMOUNT
    );
  };

  return getCashQuantity();
};
