import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { LkSignOptions } from '@terminal/core/store/slices/lkSignSlice';
import { NotificationType } from '@terminal/core/types/ui';

import { signCodetable } from '../api/signCodetable';

interface CodeTableParams {
  signOptions?: LkSignOptions;
}

export const useCodeTable = (params: CodeTableParams) => {
  const addNotification = useNotification();

  return useQuery(
    ['lk', 'profile/code-table'],
    async () => signCodetable(params.signOptions),
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка получения кодов',
          text: error?.message || '',
        });
      },
    }
  );
};
