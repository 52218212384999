import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { useCallback, useRef, useState, VFC } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Gap } from '@alfalab/core-components/gap';
import { IconButton } from '@alfalab/core-components/icon-button';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { PlusMIcon } from '@alfalab/icons-glyph/PlusMIcon';

import {
  FEATURE_FLAG_CODE,
  featureFlags,
} from '@terminal/common/entities/ServiceNotifications';
import { useOnClickOutside } from '@terminal/core/hooks';
import { useAppContext } from '@terminal/core/hooks/useAppContext';
import { LayoutType } from '@terminal/core/types/layout';
import {
  Feature,
  FeatureOnboardingPopover,
  useOneTimeShowing,
} from '@terminal/widgets/features/FeatureOnboarding';

import { useThemeContext } from '../../../../shared/lib/themeContext/useThemeContext';
import { TabButton } from '../TabButton';

import styles from './CreateLayoutButton.module.css';

export type CreateLayoutButtonProps = {
  onPick: (widget: LayoutType) => void;
};

export const CreateLayoutButton: VFC<CreateLayoutButtonProps> = ({
  onPick,
}) => {
  const flexLayoutEnabled = featureFlags.get(
    FEATURE_FLAG_CODE.DRAGGABLE_LAYOUT_ENABLED
  );

  const { theme } = useThemeContext();
  const {
    animations: {
      layout: { grid, flex },
    },
  } = useAppContext();

  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  const lottieFlexRef = useRef<LottieRefCurrentProps>(null);
  const lottieGridRef = useRef<LottieRefCurrentProps>(null);

  useOnClickOutside([buttonRef, popoverRef], () => setIsOpen(false));

  const onClick = useCallback(() => {
    if (flexLayoutEnabled) {
      setIsOpen((open) => !open);
    } else {
      onPick(LayoutType.Grid);
    }
  }, [flexLayoutEnabled, onPick]);

  const { isVisible, onClose } = useOneTimeShowing(Feature.FLEX_LAYOUT);

  return (
    <>
      <TooltipDesktop
        open={isOpen && flexLayoutEnabled}
        trigger="click"
        position="bottom-start"
        offset={[0, 6]}
        contentClassName={styles.content}
        popoverClassName={styles.popover}
        arrowClassName={styles.tooltipArrow}
        content={
          <>
            <div
              className={styles.layoutBlock}
              onClick={() => onPick(LayoutType.Flex)}
              onMouseEnter={() => lottieFlexRef.current?.play()}
              onMouseLeave={() => lottieFlexRef.current?.pause()}
            >
              <Lottie
                lottieRef={lottieFlexRef}
                animationData={flex[theme]}
                autoplay={false}
              />
              <Gap size="l" />
              <Typography.Text view="secondary-large" weight="bold">
                Гибкие окна
                <Typography.Text
                  view="secondary-small"
                  weight="bold"
                  className={styles.newBadge}
                >
                  NEW
                </Typography.Text>
              </Typography.Text>
              <Gap size="xs" />
              <Typography.Text view="secondary-small" color="secondary">
                Окна можно свободно перемещать, независимо изменять их размеры
                <br />и закреплять над другими
              </Typography.Text>
            </div>
            <Gap size="m" direction="horizontal" />
            <div
              className={styles.layoutBlock}
              onClick={() => onPick(LayoutType.Grid)}
              onMouseEnter={() => lottieGridRef.current?.play()}
              onMouseLeave={() => lottieGridRef.current?.pause()}
            >
              <Lottie
                lottieRef={lottieGridRef}
                animationData={grid[theme]}
                autoplay={false}
              />
              <Gap size="l" />
              <Typography.Text view="secondary-large" weight="bold">
                Упорядоченная сетка
              </Typography.Text>
              <Gap size="xs" />
              <Typography.Text view="secondary-small" color="secondary">
                Окна выстраиваются по сетке, размеры автоматически подгоняются
                под размер экрана
              </Typography.Text>
            </div>
          </>
        }
      >
        <TabButton
          ref={buttonRef}
          icon
          label={<IconButton size="xs" view="secondary" icon={PlusMIcon} />}
          onClick={onClick}
        />
      </TooltipDesktop>
      <FeatureOnboardingPopover
        isOpen={isVisible && flexLayoutEnabled}
        anchorElement={buttonRef.current}
        onClose={onClose}
        className={styles.onboardingPopover}
      >
        <Typography.Text
          tag="div"
          view="primary-small"
          color="primary"
          weight="bold"
        >
          Свободная компоновка окон
        </Typography.Text>

        <Gap size="xs" />

        <Typography.Text tag="div" view="secondary-large" color="primary">
          С гибкими окнами теперь можно <br />
          перемещать и изменять размер окон по <br />
          вашему желанию. Вы можете выбрать <br />
          режим управления окнами при создании <br />
          рабочего стола
        </Typography.Text>

        <Gap size="m" />

        <ButtonDesktop
          size="xs"
          view="primary"
          onClick={() => {
            onClose();
            onClick();
          }}
        >
          Понятно
        </ButtonDesktop>

        <Gap size="2xs" />
      </FeatureOnboardingPopover>
    </>
  );
};
