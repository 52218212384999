import { OrderStatus } from '../../../lib/client/entities';

import { InstrumentItemsTable } from '../../../types/core';
import { OrderItem } from '../../../types/order';

/**
 * Выбирает релевантные заявки по счету
 * */
export function relevantOrdersSelector(store: {
  finInstrumentsTable: InstrumentItemsTable;
  orders: OrderItem[];
}): (idFi?: number, idSubAccount?: number) => OrderItem[] {
  return (idFi, idSubAccount) => {
    if (!idFi) {
      return [];
    }

    const { orders, finInstrumentsTable } = store;

    const relevantOrders: OrderItem[] = orders.filter((order: any) => {
      const hidden = [
        OrderStatus.Cancelled,
        OrderStatus.Filled,
        OrderStatus.Rejected,
      ];
      const isActual =
        order.withdrawTime.toISOString() > new Date().toISOString();
      const isRelevant = order.idSubAccount === idSubAccount;
      const isShouldBeShown = !hidden.includes(order.idOrderStatus);

      return isActual && isRelevant && isShouldBeShown;
    });

    const selectedIdFI = finInstrumentsTable.get('idFI', idFi);

    if (!selectedIdFI) {
      return [];
    }

    return relevantOrders.filter((order: OrderItem) => {
      return (
        order.idMarketBoard === selectedIdFI.idMarketBoard &&
        order.idObject === selectedIdFI.idObject
      );
    });
  };
}
