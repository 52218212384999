import cn from 'classnames';
import React, { useCallback } from 'react';
import { CheckboxGroupDesktop as CheckboxGroup } from '@alfalab/core-components/checkbox-group/desktop';
import { Radio } from '@alfalab/core-components/radio';
import { Space } from '@alfalab/core-components/space';
import { TooltipDesktop as Tooltip } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { InformationCircleLineSIcon } from '@alfalab/icons-glyph/InformationCircleLineSIcon';

import { isNotUndefined } from '@terminal/core/lib/isNotUndefined';
import { MultiSelectItemObject } from '@terminal/core/types/layout';

import styles from './RadioSelectFilter.module.css';

type RadioSelectFilterProps = {
  filterItems?: string[] | MultiSelectItemObject[];
  filterValue?: string | number | boolean;
  onChange: (value: string[]) => void;
};

export const RadioSelectFilter = (props: RadioSelectFilterProps) => {
  const { filterItems, filterValue, onChange } = props;

  const onRadioChange = useCallback(
    (_, payload) => {
      onChange(payload.name);
    },
    [onChange]
  );

  return (
    <Space size={0} className={styles.wrapper} fullWidth>
      <CheckboxGroup className={styles.filterItems} onChange={onRadioChange}>
        {filterItems?.map((item, index) => {
          const [name, value, description] = isNotUndefined(item?.value)
            ? [item.name, item.value, item.description]
            : [item, item];

          return (
            <Radio
              key={index}
              label={
                <>
                  <Typography.Text
                    view="secondary-large"
                    className={styles.text}
                  >
                    {name}
                  </Typography.Text>
                  {description && (
                    <Tooltip
                      contentClassName={styles.tooltipContent}
                      content={description}
                      trigger="hover"
                      position="top-start"
                      offset={[-20, 16]}
                    >
                      <InformationCircleLineSIcon
                        height={12}
                        width={12}
                        color="var(--color-dark-graphic-secondary)"
                      />
                    </Tooltip>
                  )}
                </>
              }
              contentClassName={styles.checkBoxContent}
              className={cn(styles.filterWrapper, styles.checkboxContent)}
              name={value}
              checked={filterValue === value}
              block
            />
          );
        })}
      </CheckboxGroup>
    </Space>
  );
};
