import qs from 'query-string';

import { fetchLkApi } from './fetchLkApi';
import { parseApiHttpStatusError } from './parseApiHttpStatusError';

interface FetchOptions {
  /**
   * HTTP-метод
   * @default 'GET'
   * */
  method?: Request['method'];
  /**
   * Тело запроса
   * */
  body?: unknown;
  /**
   * Передача query params при помощи библиотеки query-string
   * */
  queryParams?: Record<string, any>;
  /**
   * Опции библиотеки query-string для передачи query params
   * */
  queryParamsOptions?: qs.StringifyOptions;
  /**
   * Дополнительное шифрование
   * @default false
   * */
  encrypt?: boolean;
  /**
   * Кастомные заголовки запроса
   * */
  customHeaders?: Headers;
}

/**
 * @param url Url запроса
 * @param options FetchOptions
 * @returns
 * */
export async function fetchLkResult<TResult>(
  url: string,
  options?: FetchOptions
): Promise<TResult> {
  const {
    method = 'GET',
    body,
    encrypt = false,
    customHeaders,
    queryParams,
    queryParamsOptions,
  } = options || {};

  const response = await fetchLkApi(
    qs.stringifyUrl({ url, query: queryParams }, queryParamsOptions),
    method,
    body,
    encrypt,
    customHeaders
  );

  if (!response.ok) {
    throw await parseApiHttpStatusError(response);
  }

  try {
    return await response.json();
  } catch (error) {
    throw error;
  }
}
