import React, { useMemo, useState } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Gap } from '@alfalab/core-components/gap';
import { Grid } from '@alfalab/core-components/grid';
import { InputDesktop } from '@alfalab/core-components/input/desktop';
import {
  PasswordInput,
  PasswordInputProps,
} from '@alfalab/core-components/password-input';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';

import { useAppContext } from '@terminal/core/hooks/useAppContext';
import { useStore } from '@terminal/core/store';
import { isFeatureHidden, useFeatureSettings } from '@terminal/lk/shared';
import { PASSWORD_CHANGE_ERROR, usePasswordChange } from '@terminal/lk-core';
import { TradingCertificateLock } from '@terminal/widgets';

import styles from './PasswordChange.module.css';

export const PasswordChange = () => {
  const { terminalName } = useAppContext();

  const [passwords, setPasswords] = useState<{
    first?: string;
    second?: string;
    old?: string;
  }>({});
  const [errorOld, setErrorOld] = useState<string | null>(null);
  const [errorNew, setErrorNew] = useState<string | null>(null);
  const [errorConfirm, setErrorConfirm] = useState<string | null>(null);

  const { data: featureSettings = [] } = useFeatureSettings();

  const needOldPassword = useMemo(() => {
    return (
      featureSettings.length > 0 &&
      !isFeatureHidden('request-old-password', featureSettings)
    );
  }, [featureSettings]);

  const { isLoading, mutateAsync: sendPasswordChangeRequest } =
    usePasswordChange();

  const user = useStore.getState().user;

  const clearErrors = () => {
    setErrorNew(null);
    setErrorConfirm(null);
    setErrorOld(null);
  };

  const resetForm = () => {
    setPasswords({
      first: '',
      second: '',
      old: '',
    });
    clearErrors();
  };

  const handleChange: PasswordInputProps['onChange'] = (event, { value }) => {
    clearErrors();
    setPasswords((prev) => ({
      ...prev,
      [event.target.id]: value,
    }));
  };

  const isReady = () => {
    if (needOldPassword) {
      if (!passwords.old) {
        return false;
      }
    }

    return Boolean(passwords.first && passwords.second);
  };

  const handleSavePassword = async () => {
    if (passwords.first !== passwords.second) {
      setErrorConfirm('Пароли не совпадают');
    } else if (passwords.first === passwords.old) {
      setErrorNew('Текущий и новый пароли не должны совпадать');
    } else {
      clearErrors();

      if (passwords?.first?.trim()) {
        await sendPasswordChangeRequest({
          params: {
            password: passwords.first,
            oldPassword: needOldPassword ? passwords.old : undefined,
          },
          signOptions: {
            successTitle: 'Пароль успешно изменён',
            successText: `Пароль изменён для всех систем ${terminalName}`,
            successCallback: () => {
              resetForm();
            },
            errorCallback: (err) => {
              if (err?.code === PASSWORD_CHANGE_ERROR.WRONG_PASSWORD) {
                setErrorOld('Неверный пароль');
              } else if (err?.code === PASSWORD_CHANGE_ERROR.SAME_PASSWORD) {
                setErrorNew('Текущий и новый пароли не должны совпадать');
              }
            },
          },
        });
      }
    }
  };

  return (
    <TradingCertificateLock fullHeight>
      <div className={styles.root}>
        <Typography.Text
          view="primary-small"
          weight="bold"
          tag="p"
          defaultMargins={false}
          className={styles.title}
        >
          Данные для входа в систему
        </Typography.Text>

        <Space fullWidth size="m">
          <Grid.Row>
            <Grid.Col width="6">
              <InputDesktop
                label="Логин"
                labelView="outer"
                block
                size="s"
                disabled
                value={user?.login}
              />
            </Grid.Col>
          </Grid.Row>

          {needOldPassword && (
            <Grid.Row>
              <Grid.Col width="6">
                <PasswordInput
                  label="Текущий пароль"
                  labelView="outer"
                  placeholder="Введите текущий пароль"
                  id="old"
                  size="s"
                  block
                  value={passwords.old}
                  onChange={handleChange}
                  error={errorOld}
                />
              </Grid.Col>
            </Grid.Row>
          )}

          <Grid.Row>
            <Grid.Col width="6">
              <PasswordInput
                label="Новый пароль"
                labelView="outer"
                placeholder="Введите новый пароль"
                id="first"
                size="s"
                block
                value={passwords.first}
                onChange={handleChange}
                error={errorNew}
              />
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col width="6">
              <PasswordInput
                label="Повторите пароль"
                labelView="outer"
                placeholder="Введите новый пароль"
                id="second"
                size="s"
                block
                value={passwords.second}
                onChange={handleChange}
                error={errorConfirm}
                hint={`Пароль будет изменён для всех систем ${terminalName}`}
              />
            </Grid.Col>
          </Grid.Row>
        </Space>

        <Gap size="xl" />

        <Grid.Row>
          <Grid.Col width="6">
            <ButtonDesktop
              size="s"
              block
              leftAddons={
                <CheckmarkMIcon fill="var(--color-light-graphic-secondary)" />
              }
              disabled={!isReady()}
              onClick={handleSavePassword}
              loading={isLoading}
            >
              Сохранить
            </ButtonDesktop>
          </Grid.Col>
        </Grid.Row>
      </div>
    </TradingCertificateLock>
  );
};
