/**
 * @deprecated используйте аналог из `@terminal/lk-core`
 * */
export const formatLoginError = (error?: string, lkAuthError?: string) => {
  if (lkAuthError !== undefined) {
    return lkAuthError;
  }

  if (!error) {
    return null;
  }

  if (typeof error === 'string') {
    return error;
  } else {
    return 'Произошла ошибка при подключении к серверу.';
  }
};
