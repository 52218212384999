import log from 'loglevel';
import { GetState } from 'zustand';

import { LK_URI } from '../../env';
import { DictionariesDB } from '../../lib/db/dictionaries';
import { normalizeLkAPIURI } from '../../lib/rest/normalizeLkAPIURI';
import {
  AllowedOrderParamsService,
  AllowedOrderParamsServiceEvents,
} from '../../lib/services/allowedOrderParams';
import { CertifcatesService } from '../../lib/services/certificates';
import { CoreService } from '../../lib/services/core';
import { PortfolioService } from '../../lib/services/portfolio';
import { uiSettings } from '../../lib/services/storage';
import { fetchThroughProxy } from '../../lib/url';
import { isMarketBoardAvailable, isObjectAvailable } from '../selectors';
import { RootStore, StoreSet } from '../useStore';
import {
  createFinInstrumentsTable,
  createMarketboardsTable,
  createObjectExtsTable,
  createObjectsTable,
  createObjectTypesTable,
} from './helpers';

import { AppState } from '../../types/app';
import {
  CorePartType,
  EmitentIcons,
  InstrumentItem,
  InstrumentItemsTable,
  MarketBoardItem,
  MarketBoardItemsTable,
  ObjectExtItem,
  ObjectExtItemsTable,
  ObjectItem,
  ObjectItemsTable,
  ObjectTypeItem,
  ObjectTypeItemsTable,
} from '../../types/core';

export interface CoreSlice {
  objectsReady: boolean;
  objects: ObjectItem[];
  objectsTable: ObjectItemsTable;
  setObjects: (items: ObjectItem[]) => void;
  objectExtsReady: boolean;
  objectExts: ObjectExtItem[];
  objectExtsTable: ObjectExtItemsTable;
  setObjectExts: (items: ObjectExtItem[]) => void;
  objectTypesReady: boolean;
  objectTypes: ObjectTypeItem[];
  objectTypesTable: ObjectTypeItemsTable;
  setObjectTypes: (items: ObjectTypeItem[]) => void;
  finInstrumentsReady: boolean;
  finInstruments: InstrumentItem[];
  finInstrumentsTable: InstrumentItemsTable;
  setFinInstruments: (items: InstrumentItem[]) => void;
  marketBoardsReady: boolean;
  marketBoards: MarketBoardItem[];
  marketBoardsTable: MarketBoardItemsTable;
  setMarketBoards: (items: MarketBoardItem[]) => void;
  setCorePartReady: (part: CorePartType, isCoreReady: boolean) => void;
  initCore: () => void;
  isCoreReady: () => boolean;
  instrumentIcons: EmitentIcons;
  setInstrumentIcons: () => void;
}

export const createCoreSlice = (
  set: StoreSet,
  get: GetState<RootStore>
): CoreSlice => ({
  objectsReady: false,
  objects: [],
  objectsTable: createObjectsTable({
    isObjectAvailable,
  }),
  setObjects: (items: ObjectItem[]) => {
    set((state) => {
      state.objects = items;
      state.objectsTable.uploadData(items);
    });

    CoreService.save(CorePartType.OBJECTS, get().objects);
  },
  objectTypesReady: false,
  objectExtsReady: false,
  objectExts: [],
  objectExtsTable: createObjectExtsTable(),
  setObjectExts: (items: ObjectExtItem[]) => {
    set((state) => {
      state.objectExts = items;
      state.objectExtsTable.uploadData(items);
    });

    CoreService.save(CorePartType.OBJECT_EXTS, get().objectExts);
  },
  objectTypes: [],
  objectTypesTable: createObjectTypesTable(),
  setObjectTypes: (items: ObjectTypeItem[]) => {
    set((state) => {
      state.objectTypes = items;
      state.objectTypesTable.uploadData(items);
    });

    CoreService.save(CorePartType.OBJECT_TYPES, get().objectTypes);
  },
  finInstrumentsReady: false,
  finInstruments: [],
  finInstrumentsTable: createFinInstrumentsTable(),
  setFinInstruments: (items: InstrumentItem[]) => {
    set((state) => {
      state.finInstruments = items;
      state.finInstrumentsTable.uploadData(items);
    });

    CoreService.save(CorePartType.INSTRUMENTS, get().finInstruments);
  },
  marketBoardsReady: false,
  marketBoards: [],
  marketBoardsTable: createMarketboardsTable({ isMarketBoardAvailable }),
  setMarketBoards: (items: MarketBoardItem[]) => {
    set((state) => {
      state.marketBoards = items;
      state.marketBoardsTable.uploadData(items);
    });

    CoreService.save(CorePartType.MARKET_BOARDS, get().marketBoards);
  },
  initCore: async () => {
    if (!get().isCoreReady()) {
      await DictionariesDB.init();
      CoreService.fetch();
      CertifcatesService.fetchCertificates();
      AllowedOrderParamsService.fetch();
      AllowedOrderParamsService.on(
        AllowedOrderParamsServiceEvents.INIT_ORDER_PARAMS,
        (value) => get().initOrderParams(value)
      );
      AllowedOrderParamsService.on(
        AllowedOrderParamsServiceEvents.ADD_OR_UPDATE_ORDER_PARAM,
        (value) => get().addOrUpdateOrderParam(value)
      );
      AllowedOrderParamsService.on(
        AllowedOrderParamsServiceEvents.REMOVE_ORDER_PARAM,
        (value) => get().removeOrderParam(value)
      );
      AllowedOrderParamsService.on(
        AllowedOrderParamsServiceEvents.RESET_ORDER_PARAMS,
        () => get().resetOrderParams()
      );

      get().setInstrumentIcons();
    }
  },
  isCoreReady: () => {
    const objectsReady = get().objectsReady;
    const instrumentsReady = get().finInstrumentsReady;
    const marketBoardsReady = get().marketBoardsReady;
    const objectTypesReady = get().objectTypesReady;
    const certificatesReady = get().certificatesReady;
    const orderParamsReady = get().orderParamsReady;
    const objectExtsReady = get().objectExtsReady;

    log.debug(
      `Загрузка справочников: objects=${objectsReady} instruments=${instrumentsReady} marketBoards=${marketBoardsReady} objectTypes=${objectTypesReady} certificates=${certificatesReady} orderParams=${orderParamsReady} objectExts=${objectExtsReady}`
    );

    if (
      instrumentsReady &&
      objectsReady &&
      marketBoardsReady &&
      objectTypesReady &&
      certificatesReady &&
      orderParamsReady &&
      objectExtsReady
    ) {
      PortfolioService.request()
        .then(() => {
          get().setPortfolioReady(true);
        })
        .catch((e) => {
          log.debug(e);
        })
        .finally(() => {
          if (get().passportLogged) {
            const lastSubAccount = uiSettings.lastSelectedSubAccount;
            const subAccounts = get().subAccounts;
            const subAccountRazdels = get().subAccountRazdel;

            const setSubAccount = get().setPwaGlobalSubAccount;

            if (
              lastSubAccount &&
              subAccounts.find(
                (subAccount) =>
                  subAccount.idSubAccount === lastSubAccount.subAccountId
              )
            ) {
              setSubAccount(
                lastSubAccount.subAccountId,
                lastSubAccount.subAccountCode
              );
            } else {
              const firstSubAccount = subAccounts[0];
              const razdel = subAccountRazdels.find(
                (razdel) => razdel.idSubAccount === firstSubAccount.idSubAccount
              );

              if (razdel) {
                setSubAccount(
                  firstSubAccount.idSubAccount,
                  razdel.codeSubAccount
                );
              }
            }

            get().setAppState(AppState.READY);
          }
        });
    }

    return (
      instrumentsReady &&
      objectsReady &&
      marketBoardsReady &&
      certificatesReady &&
      objectTypesReady &&
      objectExtsReady
    );
  },
  setCorePartReady: (part: CorePartType, isReady: boolean) => {
    switch (part) {
      case CorePartType.INSTRUMENTS:
        set((state) => {
          state.finInstrumentsReady = isReady;
        });
        get().isCoreReady();
        break;
      case CorePartType.OBJECTS:
        set((state) => {
          state.objectsReady = isReady;
        });
        get().isCoreReady();
        break;
      case CorePartType.OBJECT_TYPES:
        set((state) => {
          state.objectTypesReady = isReady;
        });
        get().isCoreReady();
        break;
      case CorePartType.MARKET_BOARDS:
        set((state) => {
          state.marketBoardsReady = isReady;
        });
        get().isCoreReady();
        break;
      case CorePartType.OBJECT_EXTS:
        set((state) => {
          state.objectExtsReady = isReady;
        });
        get().isCoreReady();
        break;
      default:
        break;
    }
  },
  instrumentIcons: [],
  setInstrumentIcons: async () => {
    // https://lk.alfadirect.ru/api/logotypes/emitents?types=idobject,emcode,isin
    const response = await fetchThroughProxy(
      normalizeLkAPIURI(`${LK_URI}/api/logotypes/emitents?types=idObject,ISIN`)
    );

    if (!response.ok) {
      log.error('Не удалось загрузить список иконок', await response.text());

      return;
    }

    const data: EmitentIcons = await response.json();

    set((state) => {
      state.instrumentIcons = data;
    });
  },
});
