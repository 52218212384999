import { useMutation } from 'react-query';

import { cancelSignOperation } from '@terminal/core/lib/rest/lkSignOperation';

/**
 * @deprecated используйте аналог из lk-core
 * */
export function useSignCancel() {
  return useMutation(cancelSignOperation);
}
