import { useMemo } from 'react';

import { TableColumnSetting } from '@terminal/core/types/layout';
import { TableColumnKey } from '@terminal/core/types/tableColumn';

import { alignLeftColumns } from '../model/consts';

export const useBsColumns = (
  columns: TableColumnSetting[]
): TableColumnSetting[] => {
  const bsColumns = useMemo(() => {
    return columns.map((column) => {
      const isLeftAlignColumn = alignLeftColumns.includes(column.key);

      return {
        ...column,
        headerCellClassName: isLeftAlignColumn
          ? 'textAlignStart alignStartColumnHeader'
          : undefined,
        ...(column.key === TableColumnKey.BsIsOffert && { withSort: false }),
      };
    });
  }, [columns]);

  return bsColumns;
};
