import { hasRussianLetters } from './hasRussianLetters';
import { isFieldEmpty } from './isFieldEmpty';
import { minLength } from './minLength';
import { wrapValidators } from './wrapValidators';

type Values = {
  firstName: string;
  lastName: string;
  surName?: string;
};

export function validateFullNameLoginForm(values: Values) {
  const { firstName, lastName, surName } = values;

  return {
    firstName: wrapValidators([
      minLength(1, 'Напишите имя'),
      hasRussianLetters,
    ])(firstName),
    lastName: wrapValidators([
      minLength(1, 'Напишите фамилию'),
      hasRussianLetters,
    ])(lastName),
    surName: isFieldEmpty(surName)
      ? ''
      : wrapValidators([minLength(1), hasRussianLetters])(surName ?? ''),
  };
}
