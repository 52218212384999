// Источник: https://www.moex.com/ru/index/MOEX10/constituents от 06.12.2024
export const MOEX10List = [
  /* AFLT */ 'RU0009062285',
  /* GAZP */ 'RU0007661625',
  /* LKOH */ 'RU0009024277',
  /* NVTK */ 'RU000A0DKVS5',
  /* OZON*/ 'US69269L1044',
  /* ROSN */ 'RU000A0J2Q06',
  /* SBER */ 'RU0009029540',
  /* SNGSP*/ 'RU0009029524',
  /* T */ 'RU000A107UL4',
  /* VTBR */ 'RU000A0JP5V6',
];
