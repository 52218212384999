import isUndefined from 'lodash/isUndefined';
import keyBy from 'lodash/keyBy';
import { GetState } from 'zustand';

import { Operation } from '../../lib/client/entities';
import { getMinority } from '../../lib/format';
import { getNotificationByOrderItem } from '../../lib/services/ordersWatcher';
import { uiSettings } from '../../lib/services/storage';
import { shouldOrderStatusChangeBeNotified } from '../../lib/shouldOrderStatusChangeBeNotified';
import { updateVersionedEntities } from '../../lib/updateVersionedEntities';
import { RootStore, StoreSet } from '../useStore';

import { AccountItem } from '../../types/account';
import { InstrumentItem, MarketBoardItem } from '../../types/core';
import { OperationItem } from '../../types/operation';
import { OrderItem } from '../../types/order';
import {
  SubAccountItem,
  SubAccountsState,
  SubAccountState,
  SubGTAccountItem,
} from '../../types/subAccount';
import { SubAccountPositionItem } from '../../types/subAccountPosition';
import { SubAccountRazdelItem } from '../../types/subAccountRazdel';

export interface AccountSlice {
  accounts: AccountItem[];
  setAccounts: (newAccounts: AccountItem[]) => void;
  selectedAccount?: AccountItem;
  subAccounts: SubAccountItem[];
  setSubAccounts: (newItems: SubAccountItem[]) => void;
  selectedPartnerSubAccount: number | null;
  setSelectedPartnerSubAccount: (idSubAccount: number | null) => void;
  subAccountsState: SubAccountsState;
  setSubAccountsState: (state: SubAccountsState) => void;
  setSubAccountState: (idSubAccount: number, state: SubAccountState) => void;
  subGTAccounts: SubGTAccountItem[];
  setSubGTAccounts: (newItems: SubGTAccountItem[]) => void;
  subAccountPositions: SubAccountPositionItem[];
  setSubAccountPositions: (newPositions: SubAccountPositionItem[]) => void;
  extendSubAccountPositions: SubAccountPositionItem[];
  orders: OrderItem[];
  setOrders: (newPositions: OrderItem[]) => void;
  operations: OperationItem[];
  setOperations: (newPositions: OperationItem[]) => void;
  subAccountRazdel: SubAccountRazdelItem[];
  setSubAccountRazdel: (newItems: SubAccountRazdelItem[]) => void;
  isPortfolioReady: boolean;
  setPortfolioReady: (v: boolean) => void;
  orderNotificationsSuspended: boolean;
  setOrderNotificationsSuspended: (suspended: boolean) => void;
}

// добавлен флаг changed
// так как сервер изначально присылает данные по нескольку раз,
// а они в свою очередь используются в нескольких виджетах,
// и при открытии выполнялось большое количество не нужных рендеров
export const createAccountSlice = (
  set: StoreSet,
  get: GetState<RootStore>
): AccountSlice => ({
  accounts: [],
  setAccounts: (data: AccountItem[]) => {
    const filteredData = data.filter((ca) => !ca.microInvest);

    const newEntities = updateVersionedEntities<AccountItem>(
      get().accounts,
      filteredData,
      'idAccount'
    );

    set((state) => {
      state.accounts = newEntities;
      //TODO: Пока нет выбора аккаунта, берем последний в качестве выбранного
      state.selectedAccount = newEntities[newEntities.length - 1];
    });
  },
  subAccounts: [],
  setSubAccounts: (data: SubAccountItem[]) =>
    set((state) => {
      state.subAccounts = updateVersionedEntities<SubAccountItem>(
        get().subAccounts,
        data,
        'idSubAccount'
      );
    }),
  selectedPartnerSubAccount: null,
  setSelectedPartnerSubAccount: (idSubAccount: number | null) =>
    set((state) => {
      state.selectedPartnerSubAccount = idSubAccount;
      uiSettings.selectedPartnerSubAccount = idSubAccount;
    }),
  subAccountsState: {},
  setSubAccountsState: (subAccountsState: SubAccountsState) =>
    set((state) => {
      state.subAccountsState = subAccountsState;
    }),
  setSubAccountState: (
    idSubAccount: number,
    subAccountState: SubAccountState
  ) =>
    set((state) => {
      const cachedSubAccountState = uiSettings.subAccountsState;

      if (cachedSubAccountState) {
        cachedSubAccountState[idSubAccount] = subAccountState;
        uiSettings.subAccountsState = cachedSubAccountState;
      }

      state.subAccountsState[idSubAccount] = subAccountState;
    }),
  subGTAccounts: [],
  setSubGTAccounts: (data: SubGTAccountItem[]) => {
    set((state) => {
      state.subGTAccounts = updateVersionedEntities<SubGTAccountItem>(
        get().subGTAccounts,
        data,
        (subGTAccount) =>
          `${subGTAccount.idSubAccount}%${subGTAccount.idRazdelGroup}`
      );
    });
  },
  subAccountPositions: [],
  extendSubAccountPositions: [],
  setSubAccountPositions: (data: SubAccountPositionItem[]) => {
    const FIs = get().finInstruments;
    const MBs = get().marketBoards;
    const subAccountRazdels = get().subAccountRazdel;

    if (FIs.length > 0 && MBs.length > 0 && subAccountRazdels.length > 0) {
      const finInstrumentsMap = new Map<number, InstrumentItem[]>();

      get().finInstruments.forEach((FI) => {
        if (!finInstrumentsMap.get(FI.idObject)) {
          finInstrumentsMap.set(FI.idObject, []);
        }

        (finInstrumentsMap.get(FI.idObject) as InstrumentItem[]).push(FI);
      });

      const marketBoardsMap: Record<number, MarketBoardItem> = {};

      get().marketBoards.forEach((marketBoard) => {
        marketBoardsMap[marketBoard.idMarketBoard] = marketBoard;
      });

      const subAccountRazdelsMap: Record<number, SubAccountRazdelItem> = {};

      get().subAccountRazdel.forEach((razdel) => {
        subAccountRazdelsMap[razdel.idRazdel] = razdel;
      });

      set((state) => {
        state.subAccountPositions =
          updateVersionedEntities<SubAccountPositionItem>(
            get().subAccountPositions,
            data,
            'idPosition'
          );
      });
    } else {
      setTimeout(() => get().setSubAccountPositions(data), 200);
    }
  },
  orders: [],
  setOrders: (data: OrderItem[]) => {
    const getDefinedProps = (obj: OrderItem) =>
      Object.fromEntries(
        Object.entries(obj).filter(
          ([, value]) => !isUndefined(value) && value !== ''
        )
      );

    const ordersMap = keyBy(get().orders, 'numEDocument');

    data.forEach((entity) => {
      const deletion = entity.operation === Operation.Deleted;

      if (deletion) {
        delete ordersMap[entity.numEDocument];
      } else if (ordersMap[entity.numEDocument]) {
        const prevItem = { ...ordersMap[entity.numEDocument] };

        if (
          !get().orderNotificationsSuspended &&
          shouldOrderStatusChangeBeNotified(prevItem, entity)
        ) {
          const notification = getNotificationByOrderItem(entity, get());

          /** Таймаут нужен чтобы все нотификации добавились в очередь */
          setTimeout(() => {
            get().addNotification(notification);
          }, 0);
        }

        const newOrder = getDefinedProps(entity);

        const finInstruments = get().finInstruments;
        const idFI = finInstruments.find(
          (fi) =>
            fi.idMarketBoard === prevItem.idMarketBoard &&
            fi.idObject === prevItem.idObject
        )?.idFI;

        const finInfoExt = get().finInfoExt;

        const stepPrice = idFI ? finInfoExt[idFI]?.priceStep : null;

        const normalizedPricesOrder = stepPrice
          ? {
              ...newOrder,
              price:
                Math.round(
                  (newOrder.price as number) * getMinority(stepPrice)
                ) / getMinority(stepPrice),
            }
          : newOrder;

        ordersMap[entity.numEDocument] = Object.assign(
          prevItem,
          normalizedPricesOrder
        );
      } else {
        if (
          isUndefined(entity.operation) &&
          !get().orderNotificationsSuspended
        ) {
          const notification = getNotificationByOrderItem(entity, get());

          /** Таймаут нужен чтобы все нотификации добавились в очередь */
          setTimeout(() => {
            get().addNotification(notification);
          }, 0);
        }

        if (entity.idOrderType) {
          ordersMap[entity.numEDocument] = entity;
        }
      }
    });

    set((state) => {
      state.orders = Object.values(ordersMap);
    });
  },
  operations: [],
  setOperations: (data: OperationItem[]) => {
    set((state) => {
      state.operations = updateVersionedEntities<OperationItem>(
        get().operations,
        data,
        'idOperation'
      );
    });
  },
  subAccountRazdel: [],
  setSubAccountRazdel: (data: SubAccountRazdelItem[]) =>
    set((state) => {
      data.forEach((entity) => {
        const prevItemIndex = state.subAccountRazdel.findIndex(
          (prev) => prev.idRazdel === entity.idRazdel
        );
        const prevItem = state.subAccountRazdel[prevItemIndex];
        const deletion = entity.operation === Operation.Deleted;
        const prevItemExist = prevItemIndex !== -1;
        const versionChanged =
          !entity.version || entity.version !== prevItem?.version;

        if (deletion && prevItemExist) {
          state.subAccountRazdel.splice(prevItemIndex, 1);
        } else if (!prevItemExist) {
          state.subAccountRazdel.push(entity);
        } else if (versionChanged) {
          state.subAccountRazdel[prevItemIndex] = entity;
        }
      });
    }),
  isPortfolioReady: false,
  setPortfolioReady: (v: boolean) => {
    set((state) => {
      state.isPortfolioReady = v;
    });
  },
  orderNotificationsSuspended: false,
  setOrderNotificationsSuspended: (suspended: boolean) => {
    // Приостановка нотификаций используется при массовой оправке поручений
    // при ребалансировке портфеля
    set((state) => {
      state.orderNotificationsSuspended = suspended;
    });
  },
});
