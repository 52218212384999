import { IDBPDatabase, openDB } from 'idb';
import log from 'loglevel';
import { z } from 'zod';

/**
 * @see README.md - Обновление DB (IDB), Миграция
 * */

type TerminalDBv1 = {
  workspaceConfigurations: {
    key: number;
    value: string;
  };
  settings: {
    key: number;
    value: string;
  };
  watchLists: {
    key: number;
    value: string;
  };
};

export type TerminalDB = TerminalDBv1;

/**
 * С сервера всегда приходит только последняя версия данных, которая была сохранена
 * по этой причине важно соблюдать обратную совместимость между версиями
 * Локально мы имеем доступ к любой версии данных, которая была сохранена
 * key - это номер версии данных
 * value - это JSON строка
 * */
export class IDB {
  static readonly DB_NAME = 'app-data';
  /**
   * С какой версией работает текущая сборка приложения
   * Используется для чтения и записи данных
   * Увеличение DB_APP_VERSION вызывает миграцию в init() -> upgrade
   * Увеличивать нужно только при добавлении новых таблиц
   * */
  static readonly DB_APP_VERSION = 2;

  /**
   * Версия, которая хранится у клиента в браузере
   * Используется ТОЛЬКО для открытия базы данных
   * Может быть undefined, если база данных не создана
   * Может быть выше (если делали откат релиза)
   * Может быть ниже (если обновили схему данных в новом релизе)
   * */
  static DB_CLIENT_VERSION?: number;
  static db: IDBPDatabase<TerminalDB>;

  static async init() {
    try {
      const databases = await indexedDB.databases();
      const currentDB = databases.find((db) => db.name === IDB.DB_NAME);

      if (currentDB && currentDB.version !== undefined) {
        // Если был откат приложения и у клиента оказалась версия БД выше
        //  чем в приложении - берем ее для открытия БД
        if (IDB.DB_APP_VERSION < currentDB.version) {
          IDB.DB_CLIENT_VERSION = currentDB.version;
        }
      }

      // Если базы данных нет или нужно увеличить версию БД у клиента
      if (IDB.DB_CLIENT_VERSION === undefined) {
        IDB.DB_CLIENT_VERSION = IDB.DB_APP_VERSION;
      }

      IDB.db = await openDB<TerminalDB>(IDB.DB_NAME, IDB.DB_CLIENT_VERSION, {
        // Чтобы вызвался upgrade нужно увеличить DB_APP_VERSION
        upgrade(db, oldVersion, newVersion) {
          if (newVersion !== null) {
            for (
              let currentVersion = oldVersion;
              currentVersion < newVersion;
              currentVersion++
            ) {
              const migrationVersion = currentVersion + 1;

              try {
                switch (migrationVersion) {
                  case 1: {
                    db.createObjectStore('settings', {
                      autoIncrement: false,
                    });
                    db.createObjectStore('workspaceConfigurations', {
                      autoIncrement: false,
                    });
                    db.createObjectStore('watchLists', {
                      autoIncrement: false,
                    });
                    break;
                  }

                  case 2: {
                    db.createObjectStore('settings', {
                      autoIncrement: false,
                    });
                    db.createObjectStore('workspaceConfigurations', {
                      autoIncrement: false,
                    });
                    db.createObjectStore('watchLists', {
                      autoIncrement: false,
                    });
                    break;
                  }

                  default:
                    break;
                }
              } catch (e) {
                log.error('IDB upgrade error: ', e);
              }
            }
          }
        },
      });
    } catch (e) {
      log.error(
        `Ошибка инициализации базы данных ${IDB.DB_NAME}, DB_APP_VERSION: ${IDB.DB_APP_VERSION}, DB_CLIENT_VERSION: ${IDB.DB_CLIENT_VERSION}. `,
        e
      );
    }
  }

  static async save<T>(
    tableName: keyof TerminalDB,
    zodSchema: z.ZodSchema<T>,
    value: T
  ) {
    try {
      const parsedValue = zodSchema.parse(value);
      const json = JSON.stringify(parsedValue);

      return await IDB.db.put(tableName, json, 1);
    } catch (e: Error | any) {
      log.error(`error save to IDB, tableName: ${tableName} `, e);
    }
  }

  static async get<T>(tableName: keyof TerminalDB): Promise<T | undefined> {
    const stringData = await IDB.db.get(tableName, 1);

    if (stringData) {
      try {
        return JSON.parse(stringData);
      } catch (e) {
        log.error(`error get from IDB, tableName: ${tableName} `, e);
      }
    }
  }
}
