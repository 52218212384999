import { createContext } from 'react';

import { TableColumnSetting } from '@terminal/core/types/layout';

type TradesFeedData = {
  timer?: number;
  timerActive: boolean;
  nodeId?: string;
  idFi?: number;
  columnsSetting: TableColumnSetting[];
  saveColumnSetting: (columns: TableColumnSetting[]) => void;
};

export const TradesFeedContext = createContext<TradesFeedData | null>(null);
