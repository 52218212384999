import {
  MutableRefObject,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

const ACTIVE_TAB_MARGIN = 20;

/**
 * Хук находит табы, которые не влезают в контейнер правым краем,
 * возвращает индекс первого из них и расстояние от последнего поместившегося таба до края, чтобы переместить кнопки
 * */
export const useTabsOverflow = (
  containerNode: RefObject<HTMLDivElement>,
  tabs: number[],
  activeLayoutKey?: number
) => {
  const [overflowingIndices, setOverflowingIndices] = useState<number[]>([]);

  const updateOverflowingTabs = useCallback(() => {
    if (!containerNode.current || activeLayoutKey === undefined) {
      return;
    }

    const overflowButton = containerNode.current.children[
      containerNode.current.children.length - 1
    ] as HTMLElement;
    const children: HTMLElement[] = Array.prototype.slice.call(
      containerNode.current.children,
      0,
      -1
    );

    // Скрываем кнопку меню и показываем все табы, чтобы узнать вмещаются ли они
    overflowButton.style.display = 'none';
    children.forEach((child) => child.style.removeProperty('display'));

    // Рефлоу
    let { width: containerWidth, right: containerRight } =
      containerNode.current.getBoundingClientRect();

    // Если самый правый таб влез - меню не показываем
    if (
      children[children.length - 1].getBoundingClientRect().right <
      containerRight
    ) {
      setOverflowingIndices([]);

      return;
    }

    // Показываем меню, чтобы учитывать его размер
    overflowButton.style.removeProperty('display');

    // Рефлоу
    const { width: overflowButtonWidth } =
      overflowButton.getBoundingClientRect();

    // Пиксель, справа от которого нужно все скрывать
    const rightPxWithOverflowButton = containerRight - overflowButtonWidth;

    const activeTab = children[activeLayoutKey];
    const { width: activeTabWidth, right: activeTabRight } =
      activeTab.getBoundingClientRect();

    if (activeTabRight > rightPxWithOverflowButton) {
      // Активный таб должен всегда быть видимым, поэтому если он оверфловится,
      // посчитаем сначала его ширину, а затем попробуем вмещать остальные табы слева направо
      containerWidth -= overflowButtonWidth + ACTIVE_TAB_MARGIN;

      // Флаг говорит что не влезает вообще ничего
      let tooSmall = false;

      if (activeTabWidth > containerWidth) {
        tooSmall = true;
      }

      containerWidth -= activeTabWidth;

      for (let i = 0; i < children.length; i++) {
        if (i === activeLayoutKey && !tooSmall) {
          // Скипаем активный таб, его уже посчитали
          continue;
        }

        const { width: tabWidth } = children[i].getBoundingClientRect();

        if (tabWidth > containerWidth) {
          // Если очередной таб не влезает, скрываем его и все остальные
          for (let j = i; j < children.length; j++) {
            if (j === activeLayoutKey && !tooSmall) {
              // Скипаем активный таб, его скрывать нельзя
              continue;
            }

            children[j].style.display = 'none';
          }

          setOverflowingIndices(
            Array.from(
              { length: children.length - i },
              (_, index) => i + index
            ).filter((key) => tooSmall || key !== activeLayoutKey)
          );

          break;
        } else {
          containerWidth -= tabWidth + 2;
        }
      }
    } else {
      // Если активный таб влез, проверяем табы справа от него
      for (let i = activeLayoutKey + 1; i < children.length; i++) {
        const tabRight = children[i].getBoundingClientRect().right;

        if (tabRight > rightPxWithOverflowButton) {
          // Если очередной таб не влезает, скрываем его и все остальные
          for (let j = i; j < children.length; j++) {
            children[j].style.display = 'none';
          }

          setOverflowingIndices(
            Array.from({ length: children.length - i }, (_, index) => i + index)
          );

          break;
        }
      }
    }
  }, [activeLayoutKey, containerNode]);

  // resize event работает криво при открытии девтулзов
  const resizeObserver: MutableRefObject<ResizeObserver | undefined> = useRef();

  useEffect(() => {
    resizeObserver.current = new ResizeObserver(updateOverflowingTabs);

    resizeObserver.current.observe(document.body);

    return () => {
      if (resizeObserver.current) {
        resizeObserver.current.disconnect();
      }
    };
  }, [updateOverflowingTabs, containerNode]);

  useEffect(() => {
    updateOverflowingTabs();
  }, [updateOverflowingTabs, tabs.length, activeLayoutKey]);

  return overflowingIndices;
};
