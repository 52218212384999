import { Model } from 'flexlayout-react';
import { v4 } from 'uuid';

import { workspaceConfigurationsLSDefault } from '../../../constants/workspaceConfigurations';
import log from '../../../lib/loglevel-mobile-debug';

import {
  FlexLayoutModel,
  GridLayoutModel,
  LayoutType,
} from '../../../types/layout';
import {
  WorkspaceConfiguration,
  WorkspaceConfigurationLS,
  WorkspaceConfigurationsLS,
} from '../../../types/workspace';

const keyLS = 'WorkspaceConfiguration';

export const createLayoutModels = (
  jsons: (GridLayoutModel | FlexLayoutModel)[]
) =>
  jsons.map((json) =>
    json.type === LayoutType.Flex
      ? undefined
      : Model.fromJson(json as unknown as GridLayoutModel)
  );

export const parseWorkspaceConfig = (
  configuration: WorkspaceConfigurationLS
) => {
  return {
    name: configuration.name,
    id: configuration.id || v4(),
    type: configuration.type || 'custom',
    lastAutoSavedConfig: {
      ...configuration.lastAutoSavedConfig,
      models: createLayoutModels(configuration.lastAutoSavedConfig.layouts),
    },
    lastManualSavedConfig: {
      ...configuration.lastManualSavedConfig,
      models: createLayoutModels(configuration.lastManualSavedConfig.layouts),
    },
  };
};

export const parseWorkspaceConfigs = (
  configurations: WorkspaceConfigurationLS[]
) => {
  return configurations.map<WorkspaceConfiguration>(parseWorkspaceConfig);
};

export const getWorkspacesFromLS = () => {
  const stringConfig = localStorage.getItem(keyLS);

  if (stringConfig) {
    try {
      const parsedLS = JSON.parse(stringConfig) as WorkspaceConfigurationsLS;

      if (
        parsedLS.workspaceConfigurationActiveId >= 0 &&
        parsedLS.workspaceConfigurations.length > 0 &&
        parsedLS.workspaceConfigurationActiveId <
          parsedLS.workspaceConfigurations.length
      ) {
        return parsedLS;
      }
    } catch (e) {
      log.error('WorkspaceConfiguration is not valid object string');
    }
  }

  return workspaceConfigurationsLSDefault;
};

export const saveWorkspacesToLS = (workspaces: WorkspaceConfigurationsLS) => {
  window?.requestAnimationFrame(() => {
    localStorage.setItem(keyLS, JSON.stringify(workspaces));
  });
};
