import { useQuery } from 'react-query';

import { fetchRemoteConfig } from '../api/fetchRemoteConfig';

import {
  FetchRemoteConfigParams,
  RemoteConfig,
} from '../api/types/RemoteConfig';

export const useRemoteConfig = <T>(
  params: FetchRemoteConfigParams,
  onSuccess: (onSuccess: RemoteConfig<T>) => void
) => {
  return useQuery({
    queryKey: ['Remote_Config', 'load'],
    queryFn: () => fetchRemoteConfig(params),
    onSuccess,
  });
};
