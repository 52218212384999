// id базовых активов фьючерса (idObjectBase)
export const featuresList = [
  174435, // индекс РТС
  174412, // Brent Oil
  [174438, 174366], // USD
  285270, // Sber
  174422, // Gazprom
  [174388, 823648], // Gold
  [174407, 823650], // Silver
  [299571, 290876], // MOEX,
  414285, // SPYF (S&P500)
  550672, // Natural Gas
];
