import { useMutation } from 'react-query';

import { fetchLkResult } from '@terminal/core/lib/rest/lkApi';
import { NotificationType } from '@terminal/core/types/ui';

import { useLkContext } from '../../shared';
import { signMessage } from '../lib/signMessage';
import { useSignStore } from '../model/useSignStore';

import { SendAssignmentRequest } from '../model/types/SendAssignmentRequest';
import { SignOperationResponse } from '../model/types/SignOperationResponse';
import { SignOperationStatus } from '../model/types/SignOperationStatus';
import { SignOperationStatusResponse } from '../model/types/SignOperationStatusResponse';

const MAX_SIGN_STATUS_CHECK_COUNT = 120;

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function useSendAssignmentOperation() {
  const [
    signOptions,
    setLkSignProgress,
    setLkSignMultipleErrors,
    closeLkSignOperation,
  ] = useSignStore((state) => [
    state.lkSignOptions,
    state.setLkSignProgress,
    state.setLkSignMultipleErrors,
    state.closeLkSignOperation,
  ]);

  const { addNotification, workingCertificate } = useLkContext();

  return useMutation(
    async ({ assignment, messages, operationUrl }: SendAssignmentRequest) => {
      let status: SignOperationStatusResponse = {
        operationStatus: SignOperationStatus.Read,
        additionalMessage: null,
      };

      try {
        if (!workingCertificate) {
          throw new Error('Не найден валидный сертификат');
        }

        // Подпись составного поручения
        const errors: SignOperationResponse[] = [];
        const messagesCount = messages ? Object.keys(messages).length : 0;

        if (messages) {
          let currentNumber: number = 0;

          setLkSignProgress({
            count: messagesCount,
            signed: currentNumber,
          });

          for (let messageId in messages) {
            const signed = await signMessage(
              messages[messageId],
              workingCertificate
            );
            const serializedCommand = {
              key: messageId,
              signedMessage: signed.signedMessage,
            };

            const res = await fetchLkResult<SignOperationResponse>(
              `${operationUrl}/multiple`,
              'PUT',
              { serializedCommand: JSON.stringify(serializedCommand) }
            );

            if (res.responseMessage !== 'Success') {
              errors.push(res);
            }

            setLkSignProgress({
              count: messagesCount,
              signed: ++currentNumber,
            });
          }
        }

        const serializedCommand = await signMessage(
          assignment,
          workingCertificate
        );

        await fetchLkResult<SignOperationResponse>(
          String(operationUrl),
          'PUT',
          {
            serializedCommand: JSON.stringify(serializedCommand),
          }
        );

        for (
          let i = 0, done = false;
          i < MAX_SIGN_STATUS_CHECK_COUNT && !done;
          i++
        ) {
          status = await fetchLkResult<SignOperationStatusResponse>(
            `${operationUrl}/status`
          );

          switch (status.operationStatus) {
            // Успешное завершение
            case SignOperationStatus.Executed:
              done = true;
              break;
            // Ошибка подписи
            case SignOperationStatus.Failed:
            case SignOperationStatus.CheckSignFailed:
            case SignOperationStatus.FailedOnExecution:
              throw new Error(status.additionalMessage || '');
            // Отмена
            case SignOperationStatus.Cancelled:
              done = true;
              break;
            // Ждем дальше
            default:
              await sleep(1000);
          }
        }

        if (errors.length > 0) {
          setLkSignMultipleErrors(errors.map((err) => err.responseMessage));
        }

        if (errors.length === 0) {
          // ошибок не было, можно закрывать окно
          closeLkSignOperation();
        }

        if (messagesCount > 0 && errors.length === messagesCount) {
          if (!signOptions?.muteError) {
            addNotification({
              type: NotificationType.SYSTEM,
              badge: 'negative',
              title:
                signOptions?.errorTitle || 'Все поручения завершились ошибкой',
              text: signOptions?.errorText,
            });
          }
        } else {
          addNotification({
            type: NotificationType.SYSTEM,
            badge: 'positive',
            title: signOptions?.successTitle || 'Поручение подписано',
            text: signOptions?.successText,
          });

          if (signOptions?.successCallback) {
            signOptions.successCallback(status);
          }
        }
      } catch (err: any) {
        signOptions?.failureCallback?.(status);

        if (signOptions?.errorCallback) {
          signOptions.errorCallback({
            code: err.code || 0,
            message: err.message || '',
          });
        } else {
          if (!signOptions?.muteError) {
            addNotification({
              type: NotificationType.SYSTEM,
              badge: 'negative',
              title: signOptions?.errorTitle || 'Ошибка',
              text:
                signOptions?.errorText ||
                err?.message ||
                'Ошибка подписи поручения',
            });
          }
        }

        closeLkSignOperation();
      }
    }
  );
}
