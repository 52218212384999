import React, {
  cloneElement,
  FC,
  isValidElement,
  ReactElement,
  RefCallback,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useClickAway, useEvent } from 'react-use';
import { Popover, PopoverProps } from '@alfalab/core-components/popover';

import { Menu, MenuProps } from '../Menu';

export type DropdownMenuProps = Omit<MenuProps, 'onItemClick' | 'children'> & {
  /**
   * Элемент, при клике на который будет отображаться меню.
   * Или функция, которая возвращает такой элемент.
   * Должен принимать ref
   * */
  children:
    | ReactElement<HTMLElement>
    | ((
        ref: RefCallback<any>,
        isOpen: boolean,
        setIsOpen: (boolean) => void
      ) => ReactElement<HTMLElement>);
  /**
   * Пропсы, передаваемые в компонент Popover
   * */
  popoverProps?: Omit<PopoverProps, 'anchorElement' | 'open'>;
};

type MenuItemClickHandler = Required<MenuProps>['onItemClick'];

export const DropdownMenu: FC<DropdownMenuProps> = (props) => {
  const { children, popoverProps, ...restProps } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement>(null);
  // useState, чтобы вызывать ререндер при изменении ref
  const [buttonRef, setButtonRef] = useState<HTMLElement | null>(null);

  const handleMenuItemClick = useCallback<MenuItemClickHandler>((menuItem) => {
    if (menuItem.hideMenu) {
      setIsOpen(false);
    }
  }, []);

  const handleButtonClick = useCallback(() => {
    setIsOpen((isNowOpen) => !isNowOpen);
  }, []);

  useClickAway(menuRef, (event) => {
    if (
      buttonRef === event.target ||
      buttonRef?.contains(event.target as Node)
    ) {
      return;
    }

    setIsOpen(false);
  });

  useEvent('click', handleButtonClick, buttonRef);

  return (
    <>
      <Popover
        ref={menuRef}
        anchorElement={buttonRef}
        open={isOpen}
        offset={[0, 8]}
        position="bottom-start"
        {...popoverProps}
      >
        <Menu onItemClick={handleMenuItemClick} {...restProps} />
      </Popover>
      {isValidElement(children)
        ? cloneElement(children, { ref: setButtonRef })
        : children(setButtonRef, isOpen, setIsOpen)}
    </>
  );
};
