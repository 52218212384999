import {
  MarketBoardType,
  MarketType,
  ObjectGroup,
} from '../lib/client/entities';

import { DataTable } from '../store/entities';

export enum CorePartType {
  INSTRUMENTS = 0,
  OBJECTS = 1,
  OBJECT_TYPES = 2,
  MARKET_BOARDS = 3,
  OBJECT_EXTS = 4,
}

export interface ObjectItem {
  idObject: number;
  idObjectBase: number;
  idObjectType: { value: ObjectTypes; name: string };
  nameObject: string;
  symbolObject: string;
  descObject: string;
  matDateObject: Date;
  nominal: number;
  idObjectFaceUnit: number;
  expired?: number;
  isin?: string;
}

export type ObjectItemsTable = DataTable<
  ObjectItem,
  { idObject: 'one'; isin: 'one' },
  'isObjectAvailable'
>;

export interface MarketBoardItem {
  idGate: number;
  idMarketBoard: number;
  idMarketType: MarketType;
  idBoardType: { value: BoardType; name: string };
  type: { value: MarketBoardType; name?: string };
  codeMarketBoard: string;
  nameMarketBoard: string;
  descMargetBoard: string;
  placeCode: string;
  universalMarketCode: string;
  rCode: string;
  idObjectCurrency: number;
}

export type MarketBoardItemsTable = DataTable<
  MarketBoardItem,
  { idMarketBoard: 'one'; universalMarketCode: 'one' },
  'isMarketBoardAvailable'
>;

export interface ObjectExtItem {
  idObject: number;
  idFIBalance: number;
  idMarketBoardBalance: number;
  accruedIntT0: number;
}

export type ObjectExtItemsTable = DataTable<ObjectExtItem, { idObject: 'one' }>;

export interface InstrumentItem {
  idObject: number;
  idMarketBoard: number;
  idFI: number;
}

export type InstrumentItemsTable = DataTable<
  InstrumentItem,
  { idFI: 'one'; idObject: 'many'; idMarketBoard: 'many' }
>;

export enum ObjectTypes {
  //Кастомный айди для группировки подарочных акций
  TECH_A_GIFTS = -1,
  //Кастомный айди для группировки акций обыкновенных и привилегированных
  TECH_STOCKS = -2,
  //Кастомный айди для группировки облигаций
  TECH_BONDS = -3,
  //Кастомный айди для группировки фондов
  TECH_FUNDS = -4,
  TECH_FUTURES = -5,
  TECH_CURRENCY = -6,
  //Кастомные группы валют по рынкам
  TECH_CC_StocksAndBondsMarket = -8,
  TECH_CC_DerivativesMarket = -9,
  TECH_CC_CurrencyMarket = -7,
  //Стандартные типы
  NA = 0,
  AO = 1,
  AP = 2,
  OE = 3,
  BC = 4,
  RR = 5,
  BB = 6,
  BG = 7,
  BS = 8,
  BM = 9,
  BR = 10,
  BI = 11,
  BE = 12,
  PP = 13,
  PM = 14,
  CP = 15,
  CM = 16,
  FR = 17,
  FD = 18,
  PO = 19,
  PI = 20,
  PZ = 21,
  RA = 22,
  RG = 23,
  IF = 24,
  IV = 25,
  IT = 26,
  CC = 27,
  VL = 28,
  SF = 29,
  FC = 30,
  EK = 32,
  EM = 33,
  EG = 34,
  EA = 35,
  EZ = 36,
  VF = 37,
  VM = 38,
  MS = 39,
  BT = 40,
  BF = 41,
  ES = 42,
  EF = 43,
  RT = 44,
  ZZ = 45,
  EE = 46,
  SW = 47,
  RE = 48,
  XX = 49,
  FP = 50,
  IM = 51,
  XM = 52,
  XS = 53,
  XF = 54,
  SS = 55,
  MC = 56,
  DC = 57,
  VR = 58,
  COUNTRY = 59,
  SB = 60,
  PB = 61,
  CO = 62,
  MT = 63,
  FFI = 64,
}

export const ObjectTypesMap = new Map<number, string>([
  [ObjectTypes.TECH_A_GIFTS, 'Подарочные акции'],
  [ObjectTypes.TECH_STOCKS, 'Акции'],
  [ObjectTypes.TECH_BONDS, 'Облигации'],
  [ObjectTypes.TECH_FUNDS, 'Фонды'],
  [ObjectTypes.TECH_FUTURES, 'Фьючерсы'],
  [ObjectTypes.TECH_CURRENCY, 'Валюта'],
  [ObjectTypes.TECH_CC_StocksAndBondsMarket, 'Валюта (Рынок ценных бумаг)'],
  [ObjectTypes.TECH_CC_DerivativesMarket, 'Валюта (Срочный рынок)'],
  [ObjectTypes.TECH_CC_CurrencyMarket, 'Валюта (ВР)'],
  [ObjectTypes.NA, 'N/A'],
  [ObjectTypes.AO, 'Акции обыкновенные'],
  [ObjectTypes.AP, 'Акции привилегированные'],
  [ObjectTypes.OE, 'Опционы эмитента'],
  [ObjectTypes.BC, 'Облигации РФ корпоративные'],
  [ObjectTypes.RR, 'Депозитарные расписки РФ (рдр)'],
  [ObjectTypes.BB, 'Облигации РФ биржевые'],
  [ObjectTypes.BG, 'Облигации РФ федеральные'],
  [ObjectTypes.BS, 'Облигации РФ субфедеральные'],
  [ObjectTypes.BM, 'Облигации РФ муниципальные'],
  [ObjectTypes.BR, 'Облигации Банка России'],
  [ObjectTypes.BI, 'Облигации МФО (международных финансовых организаций)'],
  [ObjectTypes.BE, 'Еврооблигации (корпоративные)'],
  [ObjectTypes.PP, 'Пут с премией'],
  [ObjectTypes.PM, 'Пут маржируемый (без премии)'],
  [ObjectTypes.CP, 'Кол с премией'],
  [ObjectTypes.CM, 'Кол маржируемый (без премии)'],
  [ObjectTypes.FR, 'Расчетный фьючерс'],
  [ObjectTypes.FD, 'Поставочный фьючерс'],
  [ObjectTypes.PO, 'Паи открытого фонда'],
  [ObjectTypes.PI, 'Паи интервального фонда'],
  [ObjectTypes.PZ, 'Паи закрытого фонда'],
  [ObjectTypes.RA, 'Депозитарные расписки (ADR)'],
  [ObjectTypes.RG, 'Депозитарные расписки (GDR)'],
  [ObjectTypes.IF, 'Индекс фондовый биржевой'],
  [ObjectTypes.IV, 'Индекс валютный биржевой'],
  [ObjectTypes.IT, 'Индекс товарный биржевой'],
  [ObjectTypes.CC, 'Валюта'],
  [ObjectTypes.VL, 'Вексель'],
  [ObjectTypes.SF, 'Сертификат'],
  [ObjectTypes.FC, 'Товар'],
  [ObjectTypes.EK, 'Банк'],
  [ObjectTypes.EM, 'Управляющая Компания'],
  [ObjectTypes.EG, 'Страховая Компания'],
  [ObjectTypes.EA, 'Открытое Акционерное общество'],
  [ObjectTypes.EZ, 'Прочие эмитенты (ООО, ЗАО и проч.  неопознанные)'],
  [ObjectTypes.VF, 'Виртуальный фьючерс (серия опционов)'],
  [
    ObjectTypes.VM,
    'Виртуальный фьючерс маржируемый (серия маржируемых опционов)',
  ],
  [ObjectTypes.MS, 'Межконтрактный спред'],
  [ObjectTypes.BT, 'Корзина объектов (BASKET)'],
  [
    ObjectTypes.BF,
    'Еврооблигации (суверенные, субфедеральные, муниципальные, МФО )',
  ],
  [
    ObjectTypes.ES,
    'Органы управления (Федеральные, субфедеральные и муниципальные)',
  ],
  [ObjectTypes.EF, 'Финансовые компании – эмитенты Еврооблигаций'],
  [ObjectTypes.RT, 'Ставка'],
  [ObjectTypes.ZZ, 'Прочие'],
  [ObjectTypes.EE, 'Отрасль эмитента'],
  [ObjectTypes.SW, 'Торгуемый СВОП контракт'],
  [ObjectTypes.RE, 'Торгуемый РЕПО контракт'],
  [ObjectTypes.XX, 'Торгуемый TOD контракт (контракт с исполнением Т0)'],
  [ObjectTypes.FP, 'Расчетный фьючерс, цена которого указана в процентах'],
  [ObjectTypes.IM, 'Макроиндикатор'],
  [ObjectTypes.XM, 'Торгуемый TOM контракт (контракт с исполнением Т+1)'],
  [ObjectTypes.XS, 'Торгуемый SPT контракт (контракт с исполнением Т+2)'],
  [
    ObjectTypes.XF,
    'Торгуемый FWD контракт (контракт с исполнением позднее Т+2)',
  ],
  [ObjectTypes.SS, 'Торгуемый календарный спред'],
  [ObjectTypes.MC, 'Ипотечный сертификат'],
  [ObjectTypes.DC, 'Депозитный сертификат'],
  [ObjectTypes.VR, 'Варрант'],
  [ObjectTypes.COUNTRY, 'Фиктивный эмитент (страна)'],
  [ObjectTypes.SB, 'Облигации субординированные'],
  [ObjectTypes.PB, 'Паи биржевые'],
  [ObjectTypes.CO, 'Криптовалюты'],
  [ObjectTypes.MT, 'Металлы'],
  [ObjectTypes.FFI, 'ИФИ'],
]);

export enum BoardType {
  System = 0,
  Info = 1,
  Main = 2,
  Other = 3,
  Unidentified = 4,
  Special = 5,
}

export const BoardTypeMap = new Map<number, string>([
  [BoardType.System, 'Системный режим, НЕ подлежащий трансляции клиентам'],
  [BoardType.Info, 'Информационный режим'],
  [BoardType.Main, 'Основной торговый режим'],
  [BoardType.Other, 'Дополнительный торговый режим'],
  [
    BoardType.Unidentified,
    'Неопознанный режим. (сведения о режиме загружены с биржи, но статус режима не подтвержден административно)',
  ],
  [
    BoardType.Special,
    'Специальный торговый режим (операции доступны НЕ всем клиентам)',
  ],
]);

export const MarketBoardTypeMap = new Map<number, string>([
  [
    MarketBoardType.Regular,
    'основной режим торгов, анонимные торги, биржевые торги',
  ],
  [
    MarketBoardType.OTC,
    'основной режим торгов, анонимные торги, внебиржевые торги',
  ],
  [MarketBoardType.IPO, 'режим размещения'],
  [
    MarketBoardType.RPS,
    'режим адресных заявок, применим как к биржевым так и внебиржевым торгам',
  ],
  [
    MarketBoardType.REPO,
    'режим адресных заявок РЕПО, применим как к биржевым так и внебиржевым торгам',
  ],
]);

export enum MarketBoard {
  None = 0,
  CASH = 1,
  MAIN = 2,
  NEGD = 3,
  RPCB = 4,
  RPCD = 5,
  RPDD = 6,
  RPFB = 7,
  RPFD = 8,
  TRAN = 9,
  CASF = 10,
  MICI = 11,
  MICC = 12,
  EQxx = 14,
  FBCB = 28,
  FBFX = 29,
  PAUS = 30,
  PSAU = 31,
  PSxx = 34,
  RPxx = 47,
  RPFG = 48,
  RPNG = 51,
  SMAL = 52,
  RTSY = 54,
  RTIN = 55,
  RTFU = 56,
  RTOP = 57,
  RTOM = 58,
  RTS4 = 60,
  RTS3 = 61,
  RTS2 = 62,
  RTS1 = 63,
  RTS0 = 64,
  RTS5 = 65,
  RTGP = 67,
  RTCL = 68,
  OTCU = 69,
  OTCR = 70,
  MISR = 71,
  ABEQ = 72,
  RTR1 = 78,
  RTST = 79,
  DEPO = 80,
  MONY = 81,
  TRTS = 82,
  TRFU = 83,
  TROM = 84,
  TRR1 = 85,
  TRS4 = 86,
  TRS1 = 87,
  TRS0 = 88,
  TEQB = 90,
  EQDP = 91,
  TQxx = 92,
  PTxx = 93,
  AUCT = 94,
  AUBB = 95,
  EQRP = 96,
  PSRP = 97,
  PSBB = 98,
  RUSR = 99,
  RTDV = 100,
  CETS = 101,
  CNGD = 102,
  AETS = 103,
  UTUS = 104,
  CNUS = 105,
  AEUS = 106,
  TMICI = 107,
  SMICI = 108,
  TEQxxUAT = 112,
  CETS_TOD = 113,
  CETS_TOM = 114,
  SPxx = 115,
  EUCLU = 116,
  EUCLR = 117,
  EUCLE = 118,
  SPFPA = 119,
  EQeur = 120,
  EQgbp = 121,
  EQRD = 122,
  EQRE = 123,
  EQusd = 125,
  EQWP = 126,
  PScny = 127,
  PSeur = 128,
  PSgbp = 129,
  PSRD = 130,
  PSRE = 131,
  PSusd = 133,
  PTusd = 134,
  RPeur = 135,
  RPgbp = 136,
  RPusd = 137,
  TQDB = 138,
  TQusd = 139,
  FBCE = 140,
  FBCU = 141,
  PACY = 142,
  PAEU = 143,
  PAGB = 144,
  SPOB = 145,
  RFND = 146,
  RFNU = 147,
  RFNE = 148,
  EQcny = 149,
  TQXXT1 = 150,
  SPFCA = 151,
  SPFAR = 152,
  EUCLG = 155,
  EUCLF = 156,
  EUCLY = 157,
  DEPO_USD = 160,
  DEPO_EUR = 161,
  DEPO_GBP = 162,
  DEPO_CHF = 163,
  DEPO_CNY = 164,
  SPFPST0 = 256,
  SPFPST1 = 257,
  SPFPA2 = 258,
  SPFPA_E = 260,
  SPFAR_E = 261,
  SPFPST0_E = 262,
  SPFPST1_E = 263,
  SPFPST2_E = 264,
  SPFART2 = 265,
  CETS_TOD_USD = 268,
  CETS_TOM_USD = 269,
  MB_CK_EUR = 270,
  CETS_TOM_USD_TMS = 283,
  SPFPA_H = 289,
  COIN_SPOT_USD = 294,
  EQxxUAT = 300,
  TQcny = 313,
  SPFPA_R = 331,
}

// Map symbolObject = idFI
export enum FISymbol {
  SBER = 144950,
  GAZP = 146986,
  LKOH = 144942,
  ROSN = 146989,
  MOEX = 145164,
  YNDX = 162016,
  TSLA = 224425,
  AAPL = 218182,
  BAC = 218190,
  XOM = 218251,
  NFLX = 218307,
  FB = 218255,
  NVDA = 229163,
  USD = 149262,
  EUR = 149270,
  BRF2 = 682551,
  SiH2 = 621128,
  RIH2 = 621130,
}

export enum IdObjectSymbol {
  SPBE = 452346,
}

export interface ObjectTypeItem {
  idObjectType: number;
  idObjectGroup: ObjectGroup;
  codeObjectType: string;
  nameObjectType: string;
  shortNameObjectType: string;
}

export type ObjectTypeItemsTable = DataTable<
  ObjectTypeItem,
  { idObjectType: 'one' }
>;

export type EmitentIconList = {
  type: 'IdObject' | 'EmCode' | 'Isin';
  items: string[];
};

export type EmitentIcons = EmitentIconList[];
