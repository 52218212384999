import { EventEmitter } from 'eventemitter3';

import { TradePeriodType, TradeType } from './quotes';

export interface TradeInfo {
  IdFI: number;
  IdTradeType: TradeType;
  IdTradePeriodStatus: TradePeriodType;
  TradeNo: string;
  TradeTime: Date;
  BuySell: number;
  Value: number;
  Qty: BigInt;
  Price: number;
  highlight?: true;
}

export interface SubscriberData {
  updater: (trades: TradeInfo[]) => void;
  throttledUpdater: () => void;
  cache: TradeInfo[];
}

export interface SubscriberHandle {
  fi: number;
  subscriberData: SubscriberData;
}

export abstract class AbstractTradesService extends EventEmitter {
  abstract subscribeToFI(
    fi: number,
    updater: (trades: TradeInfo[]) => void,
    timeout?: number
  ): SubscriberHandle;
  abstract unsubscribe(fi: number, subscribeReturnData: SubscriberHandle): void;
}
