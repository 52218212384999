/**
 * Приводит отчество к пустой строке, если введены некорректные значений
 * @deprecated используйте аналог из `@terminal/lk-core`
 * */
export const formatSurname = (surname: string) => {
  const incorrectValues = ['нет', 'отсутствует'];

  return incorrectValues.some((word) => word === surname.toLowerCase())
    ? ''
    : surname;
};
