import cn from 'classnames';
import {
  ChangeEvent,
  forwardRef,
  KeyboardEvent,
  MouseEventHandler,
  ReactNode,
  SyntheticEvent,
  useState,
} from 'react';
import { IconButton } from '@alfalab/core-components/icon-button';
import { Typography } from '@alfalab/core-components/typography';
import { CrossMIcon } from '@alfalab/icons-glyph/CrossMIcon';

import styles from './TabButton.module.css';

interface TabButtonProps {
  label: ReactNode;
  editable?: boolean;
  closable?: boolean;
  selected?: boolean;
  rightAddons?: ReactNode;
  icon?: boolean;
  onClick?: () => void;
  onRightClick?: MouseEventHandler<HTMLDivElement>;
  onEdit?: (name: string) => void;
  onEditFinish?: () => void;
  onClose?: (event: SyntheticEvent) => void;
  className?: string;
  dataTestId?: string;
}

export const TabButton = forwardRef<HTMLDivElement, TabButtonProps>(
  (props, ref) => {
    const {
      label,
      editable = false,
      closable = false,
      selected = false,
      rightAddons,
      icon = false,
      onClick,
      onRightClick,
      onEdit,
      onEditFinish,
      onClose,
      className,
      dataTestId,
    } = props;
    const [isEditing, setIsEditing] = useState(false);

    const onTabNameDoubleClick = (event: SyntheticEvent) => {
      event.stopPropagation();

      if (editable) {
        setIsEditing(true);
      }
    };

    const onTabNameChange = (event: ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();
      onEdit?.(event.target.value);
    };

    const onTabNameBlur = () => {
      setIsEditing(false);
      onEditFinish?.();
    };

    const onNameInputKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        onTabNameBlur();
      }
    };

    return (
      <div
        data-test-id={dataTestId}
        ref={ref}
        className={cn(
          styles.layoutTab,
          !closable && !rightAddons && styles.noRightAddonsTab,
          icon && styles.iconTab,
          selected && styles.selectedLayoutTab,
          className
        )}
        onClick={onClick}
        onContextMenu={onRightClick}
      >
        <div className={styles.layoutName} onDoubleClick={onTabNameDoubleClick}>
          {isEditing && typeof label === 'string' ? (
            <input
              className={styles.layoutNameInput}
              value={label}
              size={label.length}
              onChange={onTabNameChange}
              onBlur={onTabNameBlur}
              onKeyDown={onNameInputKeyDown}
              autoFocus
            />
          ) : (
            <Typography.Text view="secondary-large" weight="bold">
              {label}
            </Typography.Text>
          )}
        </div>
        {rightAddons}
        {closable && (
          <IconButton
            size="xxs"
            view="secondary"
            icon={CrossMIcon}
            onClick={onClose}
            className={styles.closeButton}
          />
        )}
      </div>
    );
  }
);

TabButton.displayName = 'TabButton';
