import { useQuery } from 'react-query';

import { getIiaConvertStatus } from '../api/getIiaConvertStatus';

export const useIiaConvertStatus = (treaty: number | undefined) => {
  return useQuery(
    ['lk', 'iia-convert/status', { treaty }],
    async () => getIiaConvertStatus({ treaty: treaty || 0 }),
    {
      enabled: Boolean(treaty),
    }
  );
};
