import uniqBy from 'lodash/uniqBy';
import {
  InfiniteData,
  QueryFunctionContext,
  useInfiniteQuery,
} from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { LKResult } from '@terminal/core/lib/rest/lkApi';
import {
  ClientAccounts,
  getClientAccounts,
  TradeAccount,
} from '@terminal/core/lib/rest/lkMoney';
import { NotificationType } from '@terminal/core/types/ui';

import { LkQueryKeys } from '../../../legacy/queryKeys';
import { getTradeAccountKey } from '../lib';

import { useTreaties } from '../../../legacy/hooks';

/**
 * @deprecated используйте `useClientAccounts` из `@terminal/lk-core`
 * */
export const useClientAccounts = (withdraw: boolean = false) => {
  const addNotification = useNotification();

  const { data: treaties } = useTreaties();

  const firstTreaty = treaties?.length ? treaties[0].treaty : 0;

  const fetchAccounts = ({ pageParam = firstTreaty }: QueryFunctionContext) => {
    return getClientAccounts({ treaty: pageParam, withdraw });
  };

  return useInfiniteQuery(
    [LkQueryKeys.ClientAccounts, { withdraw }],
    fetchAccounts,
    {
      getNextPageParam: (_, pages) => {
        if (treaties && treaties.length > pages.length) {
          return treaties[pages.length || 0].treaty || 0;
        }
      },
      enabled: Boolean(treaties && treaties.length > 0),
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка',
          text: error?.message || 'При запросе счетов произошла ошибка',
        });
      },
    }
  );
};

/**
 * @deprecated используйте аналог из `@terminal/lk-core`
 * */
export function getAllBrokerAccountsFrom(
  result: InfiniteData<ClientAccounts & LKResult>
): TradeAccount[] {
  return uniqBy(
    result.pages.flatMap((page) => page.brokerAccounts.from),
    (acc: TradeAccount) => {
      return getTradeAccountKey(acc);
    }
  );
}

/**
 * @deprecated используйте аналог из `@terminal/lk-core`
 * */
export function getAllBrokerAccountsTo(
  result: InfiniteData<ClientAccounts & LKResult>
): TradeAccount[] {
  return uniqBy(
    result.pages.flatMap((page) => page.brokerAccounts.to),
    (acc: TradeAccount) => {
      return getTradeAccountKey(acc);
    }
  );
}

export function getAvailableBrokerAccountsForTransfer(
  fromAccount: TradeAccount | null,
  result: InfiniteData<ClientAccounts & LKResult>
): TradeAccount[] {
  if (!fromAccount) {
    return [];
  }

  const fromAccountKey = getTradeAccountKey(fromAccount);

  for (const page of result.pages) {
    const fromAccountExists = page.brokerAccounts.from.some(
      (account) => getTradeAccountKey(account) === fromAccountKey
    );

    if (fromAccountExists) {
      return page.brokerAccounts.to;
    }
  }

  return [];
}
