import { Colision, Position, Size } from '@terminal/core/types/layout';

export const adjustDrag = (
  position: Position,
  size: Size,
  colisions?: Colision
): {
  position: Position;
  size: Size;
} => {
  if (colisions) {
    let modifiedPosition = position;
    let modifiedSize = size;

    const { top, right, bottom, left } = colisions;

    // Корнер кейс когда нужно пересчитать всё
    if (
      left !== undefined &&
      right !== undefined &&
      top !== undefined &&
      bottom !== undefined
    ) {
      modifiedPosition.x = left;
      modifiedPosition.y = top;
      modifiedSize.width = right - left;
      modifiedSize.height = bottom - top;

      return { size: modifiedSize, position: modifiedPosition };
    }

    if (left !== undefined && right !== undefined) {
      // Если 2 коллизии то должны "растянуть виджет" (смещаем влево и ставим ширину как разность правого и левого пересечения)
      modifiedPosition.x = left;
      modifiedSize.width = right - left;
    } else if (left !== undefined) {
      // Если левая коллизия, то просто смещаем по оси х
      modifiedPosition.x = left;
    } else if (right !== undefined) {
      // Если правая коллизия, то нужно сместить вправо по оси х
      modifiedPosition.x += right - modifiedSize.width - modifiedPosition.x;
    }

    if (top !== undefined && bottom !== undefined) {
      modifiedPosition.y = top;
      modifiedSize.height = bottom - top;
    } else if (top !== undefined) {
      modifiedPosition.y = top;
    } else if (bottom !== undefined) {
      modifiedPosition.y += bottom - modifiedSize.height - modifiedPosition.y;
    }

    return { size: modifiedSize, position: modifiedPosition };
  } else {
    return { size, position };
  }
};
