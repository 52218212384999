import { memo } from 'react';
import { Typography } from '@alfalab/core-components/typography';

import { BrokerRecommendation, ChatMessagesGroup } from '@terminal/chat/types';
import { PUBLIC_URL } from '@terminal/core/env';
import { useStore } from '@terminal/core/store';

import { Message } from '../../../../entities/Chat';
import { BrokerRecommendationBubble } from '../BrokerRecommendationBubble';

import styles from './MessagesGroup.module.css';

export type MessagesGroupProps = {
  messagesGroup: ChatMessagesGroup;
};

export const MessagesGroup = memo<MessagesGroupProps>(function MessagesGroup(
  props
) {
  const { messagesGroup } = props;

  const myId = useStore((store) => store.credentials?.idAccount);
  const { idSender, senderNickname } = messagesGroup[messagesGroup.length - 1];

  const isMe = idSender === myId;

  return (
    <div className={styles.group}>
      {!isMe && (
        <>
          <div className={styles.avatar}>
            <img
              src={`${PUBLIC_URL}/chatAvatar.svg`}
              alt="Аватарка пользователя"
            />
          </div>
          <div className={styles.author}>
            <Typography.Text view="secondary-small" color="secondary">
              {senderNickname}
            </Typography.Text>
          </div>
        </>
      )}
      <div>
        {messagesGroup.map((message, index) => (
          <div key={`${index}-${message.idChatMessage}`}>
            <Message
              key={'message-' + message.idChatMessage}
              isMe={isMe}
              message={message}
            />
            {message.brokerRecommendations?.map(
              (recommendation: BrokerRecommendation) => (
                <BrokerRecommendationBubble
                  key={
                    'recommendation-' + recommendation.idBrokerRecommendation
                  }
                  isMe={isMe}
                  recommendation={recommendation}
                />
              )
            )}
          </div>
        ))}
      </div>
    </div>
  );
});
