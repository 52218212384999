import { Model } from 'flexlayout-react';
import { v4 } from 'uuid';

import { LayoutModel, LayoutType, LinkGroups } from '../types/layout';
import {
  WorkspaceConfigLS,
  WorkspaceConfiguration,
  WorkspaceConfigurationsLS,
} from '../types/workspace';

export const layoutTabWidth = 200;

export const initialJsons: LayoutModel<{ type: LayoutType.Grid }>[] = [
  {
    type: LayoutType.Grid,
    global: {
      splitterSize: 4,
      splitterExtra: 0,
      tabEnableRename: false,
      tabSetEnableDrag: false,
      tabSetEnableMaximize: false,
      tabSetTabStripHeight: 24,
      tabSetMinWidth: 221.33333333333334,
      tabSetMinHeight: 168.16666666666666,
    },
    borders: [],
    layout: {
      type: 'row',
      id: v4(),
      weight: 3,
      children: [],
    },
  },
];

export const initialLayoutLinks: LinkGroups[] = [
  {
    blue: {
      nodeIds: [],
    },
    green: {
      nodeIds: [],
    },
    red: {
      nodeIds: [],
    },
    yellow: {
      nodeIds: [],
    },
  },
  {
    blue: {
      nodeIds: [],
    },
    green: {
      nodeIds: [],
    },
    red: {
      nodeIds: [],
    },
    yellow: {
      nodeIds: [],
    },
  },
];

export const initialLayoutNames = ['Главный экран'];

const workspaceConfigDefault: WorkspaceConfigLS = {
  timeStamp: Date.now(),
  layouts: initialJsons,
  layoutLinks: initialLayoutLinks,
  layoutNames: initialLayoutNames,
  activeLayoutKey: 0,
  chart: {},
};

export const workspaceConfigurationDefault: WorkspaceConfiguration = {
  name: 'Конфигурация по умолчанию',
  type: 'default',
  id: v4(),
  lastAutoSavedConfig: {
    ...workspaceConfigDefault,
    models: initialJsons.map((json) => Model.fromJson(json)),
  },
  lastManualSavedConfig: {
    ...workspaceConfigDefault,
    models: initialJsons.map((json) => Model.fromJson(json)),
  },
};

export const workspaceConfigurationsLSDefault: WorkspaceConfigurationsLS = {
  workspaceConfigurationActiveId: 0,
  workspaceConfigurations: [workspaceConfigurationDefault],
};
