import {
  ConfigSize,
  ConfigStyle,
} from '@terminal/desktop/entities/OnboardingConfig';

export const MAP_STYLE_TO_WIDGETS_LIST: Record<
  ConfigSize,
  Record<ConfigStyle, string[]>
> = {
  [ConfigSize.SMALL]: {
    [ConfigStyle.BEGINNER]: [
      'Инструменты',
      'График',
      'Стакан',
      'Позиции',
      'Заявки',
      'Операции',
    ],
    [ConfigStyle.SWING]: ['Инструменты', 'График', 'Позиции', 'Операции'],
    [ConfigStyle.DAYTRADE]: [
      'Инструменты',
      'Лента сделок',
      'Стакан',
      'Торговля',
      'График ×2',
      'Позиции',
      'Операции',
    ],
    [ConfigStyle.SCALPING]: [],
  },
  [ConfigSize.MEDIUM]: {
    [ConfigStyle.BEGINNER]: [
      'Инструменты',
      'График',
      'Стакан',
      'Позиции',
      'Заявки',
      'Операции',
    ],
    [ConfigStyle.SWING]: [
      'Инструменты',
      'Позиции',
      'Заявки',
      'Стакан',
      'Торговля',
      'График',
    ],
    [ConfigStyle.DAYTRADE]: [
      'Заявки',
      'Инструменты ×2',
      'Лента сделок',
      'Стакан',
      'График ×2',
    ],
    [ConfigStyle.SCALPING]: [
      'Лента сделок ×4',
      'Стакан',
      'График ×2',
      'Инструменты',
      'Заявки',
      'Позиции',
      'Операции',
    ],
  },
  [ConfigSize.LARGE]: {
    [ConfigStyle.BEGINNER]: [],
    [ConfigStyle.SWING]: [
      'Позиции',
      'Заявки',
      'Инструменты ×2',
      'Стакан ×2',
      'График ×2',
    ],
    [ConfigStyle.DAYTRADE]: [
      'Заявки',
      'Позиции',
      'Балансы',
      'Инструменты ×5',
      'Лента сделок ×2',
      'Стакан',
      'График ×2',
    ],
    [ConfigStyle.SCALPING]: [
      'Заявки ×2',
      'Позиции ×2',
      'График ×2',
      'Инструменты ×2',
      'Лента сделок ×2',
      'Стакан ×2',
    ],
  },
};
