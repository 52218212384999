import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { ButtonDesktop } from '@alfalab/core-components/button/desktop';
import { Grid } from '@alfalab/core-components/grid';
import { InputDesktop } from '@alfalab/core-components/input/desktop';
import { Space } from '@alfalab/core-components/space';
import { Textarea } from '@alfalab/core-components/textarea';
import { TooltipDesktop } from '@alfalab/core-components/tooltip/desktop';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';
import { InformationCircleLineMIcon } from '@alfalab/icons-glyph/InformationCircleLineMIcon';

import useNotification from '@terminal/core/hooks/useNotification';
import { sendEmailConfirmSendEmail } from '@terminal/core/lib/rest/lkCommon';
import { validateEmail } from '@terminal/core/lib/validation/validateEmail';
import { NotificationType } from '@terminal/core/types/ui';
import { useInit } from '@terminal/lk/legacy/hooks';
import { Timer } from '@terminal/lk/shared';
import { useManager } from '@terminal/lk-core';

import styles from './ProfileContacts.module.css';

const REPEAT_CONFIRM_PAUSE_DURATION = 5 * 60 * 1000;
const INIT_REFETCH_INTERVAL = 5 * 1000;

export const ProfileContacts = memo(() => {
  const addNotification = useNotification();

  const {
    data: init,
    isLoading: isInitLoading,
    refetch: refetchInit,
  } = useInit();
  const {
    data: manager,
    isFetching: isManagerFetching,
    refetch: refetchManager,
  } = useManager();

  const [isPendingConfirm, setIsPendingConfirm] = useState(false);
  const [email, setEmail] = useState('');

  const [isEmailPendingConfirm, setIsEmailPendingConfirm] = useState(false);
  const [confirmPauseTargetTime, setConfirmPauseTargetTime] = useState(0);

  const isEmailChanged = email !== manager?.email;
  const isNeedEmailAccept = isEmailChanged || !init?.emailConfirmed;

  useEffect(() => {
    let intervalId: NodeJS.Timer | undefined = undefined;

    const checkEmailConfirmed = async () => {
      await Promise.all([refetchManager(), refetchInit()]);
    };

    if (isEmailPendingConfirm) {
      if (isNeedEmailAccept) {
        intervalId = setInterval(checkEmailConfirmed, INIT_REFETCH_INTERVAL);
      } else {
        setIsEmailPendingConfirm(false);
        clearInterval(intervalId);
      }
    }

    return () => clearInterval(intervalId);
  }, [isEmailPendingConfirm, refetchInit, isNeedEmailAccept, refetchManager]);

  useEffect(() => {
    if (manager?.email) {
      setEmail(manager.email);
    }
  }, [manager?.email]);

  useEffect(() => {
    refetchInit();
  }, [refetchInit]);

  const isEmailValid = useMemo(() => {
    return validateEmail(email);
  }, [email]);

  const onConfirmEmail = useCallback(async () => {
    setIsPendingConfirm(true);

    try {
      await sendEmailConfirmSendEmail({ email });

      setIsEmailPendingConfirm(true);
      setConfirmPauseTargetTime(Date.now() + REPEAT_CONFIRM_PAUSE_DURATION);
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'positive',
        title: 'Подтверждение адреса почты',
        text: `Письмо с подтверждением выслано\n на адрес ${email}`,
      });
    } catch (error: any) {
      addNotification({
        type: NotificationType.SYSTEM,
        badge: 'negative',
        title: 'Подтверждение адреса почты',
        text:
          error?.message ||
          'Не удалось отправить письмо с подтверждением.\nПопробуйте ещё раз или повторите попытку позже',
      });
    } finally {
      setIsPendingConfirm(false);
    }
  }, [setIsPendingConfirm, addNotification, email]);

  return (
    <>
      <Typography.Text
        view="primary-small"
        weight="bold"
        tag="p"
        defaultMargins={false}
        className={styles.title}
      >
        Контактные данные
      </Typography.Text>

      <Space fullWidth size="m">
        <Grid.Row>
          <Grid.Col width="12">
            <Textarea
              label="Адрес регистрации"
              labelView="outer"
              size="s"
              value={manager?.mailAddress}
              minRows={2}
              disabled
              block
            />
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col width="6">
            <InputDesktop
              label="Номер телефона"
              labelView="outer"
              value={manager?.mobilePhone}
              disabled
              block
              size="s"
            />
          </Grid.Col>
        </Grid.Row>

        <Grid.Row>
          <Grid.Col width="6">
            <InputDesktop
              label="Почта"
              labelView="outer"
              fieldClassName={
                isNeedEmailAccept ? styles.inputAttention : undefined
              }
              value={email}
              type="email"
              disabled={
                !isEmailPendingConfirm && (isInitLoading || isManagerFetching)
              }
              onChange={(e) => setEmail(e.target.value)}
              rightAddons={
                isNeedEmailAccept ? (
                  <TooltipDesktop
                    contentClassName={styles.tooltipContent}
                    position="top"
                    preventOverflow={false}
                    trigger="hover"
                    content="Укажите и подтвердите адрес электронной почты, чтобы получать справки, уведомления, информацию, предоставляемую эмитентами ЦБ, и многое другое"
                  >
                    <InformationCircleLineMIcon
                      width={20}
                      height={20}
                      color="var(--color-light-graphic-secondary)"
                    />
                  </TooltipDesktop>
                ) : null
              }
              block
              size="s"
            />
            {isNeedEmailAccept && !isEmailPendingConfirm && (
              <>
                <Typography.Text view="secondary-small" color="attention">
                  Неподтвержденный адрес
                </Typography.Text>
                <ButtonDesktop
                  className={styles.acceptEmailButton}
                  disabled={!isEmailValid}
                  loading={isPendingConfirm}
                  onClick={onConfirmEmail}
                  leftAddons={
                    <CheckmarkMIcon
                      width={15}
                      height={15}
                      color="var(--color-light-graphic-secondary)"
                    />
                  }
                  size="s"
                  block
                >
                  Подтвердить
                </ButtonDesktop>
              </>
            )}
            {isEmailPendingConfirm && (
              <>
                <Typography.Text view="secondary-small" color="attention">
                  Подтверждение отправлено
                </Typography.Text>
                <ButtonDesktop
                  className={styles.acceptEmailButton}
                  disabled={confirmPauseTargetTime > Date.now()}
                  loading={isPendingConfirm}
                  onClick={onConfirmEmail}
                  leftAddons={
                    <CheckmarkMIcon
                      width={15}
                      height={15}
                      color="var(--color-light-graphic-secondary)"
                    />
                  }
                  size="s"
                  view="tertiary"
                  block
                >
                  Отправить ещё раз
                </ButtonDesktop>
                {confirmPauseTargetTime > Date.now() && (
                  <Typography.Text view="secondary-small" color="secondary">
                    Запросить повторно можно будет через{' '}
                    <Timer targetTime={confirmPauseTargetTime} />
                  </Typography.Text>
                )}
              </>
            )}
          </Grid.Col>
        </Grid.Row>
      </Space>
    </>
  );
});

ProfileContacts.displayName = 'ProfileContacts';
