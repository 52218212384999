import { buildMarginParamsKey } from './buildMarginParamsKey';
import { mapMarginParams } from './mapMarginParams';
import { MarginParamsDTO } from './MarginParamsDTO';

import { MarginParamsMap } from '../../../types/MarginParams';

/**
 * Преобразует MarginParamsDTO  в мапу.
 * Проходит по каждому элементу в DTO и строит карту, где ключ
 * является комбинацией accountId и assetCode, а значение — отображенные параметры маржи.
 * @param {MarginParamsDTO} dto - Объект передачи данных, содержащий параметры маржи.
 * @returns {MarginParamsMap<number, string>} Карта параметров маржи, ключ которой является комбинацией кода счета и актива.
 * */
export function createMapFromMarginParams(
  dto: MarginParamsDTO
): MarginParamsMap<number, string> {
  return dto.reduce(
    (map, param) => ({
      ...map,
      [buildMarginParamsKey(param.accountId, param.assetCode)]:
        mapMarginParams(param),
    }),
    {}
  );
}
