import { useQuery } from 'react-query';

import {
  getFinResultByActGroup,
  InvestQueryKeys,
} from '@terminal/core/lib/rest/investApi';

import { useWidgetContext } from '../../../shared';
import { FinResultByInstrumentResponse } from '../model/FinResultByInstrument';
import { FinResultByInstrumentParams } from '../model/FinResultParams';

export const useFinResultByInstrument = (
  params: FinResultByInstrumentParams
) => {
  const { addNotification } = useWidgetContext();

  return useQuery<FinResultByInstrumentResponse>(
    [InvestQueryKeys.FinResultByInstrument, params],
    () => getFinResultByActGroup(params),
    {
      enabled: Boolean(params.actGroup),
      onError: (error: any) =>
        addNotification({
          badge: 'negative',
          title: 'Ошибка',
          text: error?.message || 'Произошла ошибка при запросе',
        }),
    }
  );
};
