import { InputDesktop } from '@alfalab/core-components/input/desktop';

import styles from './TextFilter.module.css';

type TextFilterProps = {
  filterValue?: string;
  onChange: (value: string) => void;
};

export const TextFilter = (props: TextFilterProps) => {
  const { filterValue, onChange } = props;

  return (
    <div className={styles.textFilter}>
      <InputDesktop
        value={filterValue}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Название содержит"
        size="s"
        block
      />
    </div>
  );
};
