import { format } from 'date-fns';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import log from 'loglevel';
import { useCallback, useEffect, useRef } from 'react';
import { UAParser } from 'ua-parser-js';
import { v4 as uuid } from 'uuid';

import { ID_DEVICE_STORAGE_KEY } from '../../constants/common';
import {
  ANALYTICS_APP_ID,
  ANALYTICS_BASE_URL,
  ANALYTICS_TOKEN,
  APP_VERSION,
  IS_ANALYTICS_ENABLED,
} from '../../env';
import { FrontEndType } from '../client/entities';

import { Widget } from '../../types/layout';

const DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSS";
const LOGGER_TITLE = 'PerfomanceAnalytics';

type WidgetsWithParams = {
  [Widget.WATCHLIST]: {
    rowsNumber: number;
  };
  [Widget.BALANCE]: {
    rowsNumber: number;
  };
  [Widget.POSITIONS]: {
    rowsNumber: number;
  };
};

export type PerformanceAnalyticsWidgetParams = {
  [key in Exclude<Widget, keyof WidgetsWithParams>]: void;
} & WidgetsWithParams;

type Config = {
  authType?: 'PIN' | 'login';
  authToken?: string; // CUS
  systemId: string;
  os: string;
  osVersion?: string;
  device: string;
  deviceId: string;
  appVersion: string;
  referenceId: string;
  platform: 'web' | 'app';
};

type EventData = {
  startMoment: string;
  endMoment: string;
  operType: string;
  operSubType?: string;
  entity?: {
    machineTime?: number;
    diskLoadRead?: number;
    diskLoadWrite?: number;
    networkUpload?: number;
    networkDownload?: number;
    memoryCurrent?: number;
    memoryMax?: number;
  };
  param?: string;
};

const store: {
  config?: Config;
  events: EventData[];
  params: {
    browser?: string;
    memoryMax: number;
  };
} = {
  events: [],
  params: {
    memoryMax: 0,
  },
};

async function sendData(data: EventData[]) {
  store.events = [];

  try {
    return await fetch(`${ANALYTICS_BASE_URL}/metrics`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + ANALYTICS_TOKEN,
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify({
        data: data.map((fields) => ({
          ...store.config,
          ...fields,
        })),
      }),
    });
  } catch (e) {
    log.error(e);
  }
}

let sendDataThrottled: ReturnType<typeof throttle<typeof sendData>>;

type PerfomanceWithMemory = {
  memory?: {
    totalJSHeapSize: number;
    usedJSHeapSize: number;
    jsHeapSizeLimit: number;
  };
};

const getCurrentMemory = (): number => {
  return (
    (window.performance as PerfomanceWithMemory).memory?.usedJSHeapSize || 0
  );
};

const LOG_MEMORY_DELAY = 3 * 60000;

function getNowDate(date = new Date()) {
  return format(date, DATE_FORMAT);
}

function pushEvent({ param, ...event }: EventData) {
  if (!IS_ANALYTICS_ENABLED || !ANALYTICS_BASE_URL || !sendDataThrottled) {
    return;
  }

  // eslint-disable-next-line no-use-before-define
  logMemory();

  const defaultParams = [
    'Optimization=true',
    `browser=${store.params.browser}`,
  ].join(',');

  const memoryCurrent = getCurrentMemory();

  if (memoryCurrent > store.params.memoryMax) {
    store.params.memoryMax = memoryCurrent;
  }

  store.events.push({
    ...event,
    entity: {
      memoryCurrent,
      memoryMax: store.params.memoryMax,
    },
    param: defaultParams + (param ? `,${param}` : ''),
  });

  sendDataThrottled([...store.events]);
}

const logMemory = debounce(() => {
  pushEvent({
    startMoment: getNowDate(),
    endMoment: getNowDate(),
    operType: 'Memory',
  });
}, LOG_MEMORY_DELAY);

function setBrowserToStore(uaParser: UAParser) {
  const browser = uaParser.getBrowser();

  store.params.browser =
    (browser.name || 'unknown') + (browser.version || 'unknown');
}

function setConfig() {
  const referenceId = uuid();
  const storage = sessionStorage;
  const deviceId = storage.getItem(ID_DEVICE_STORAGE_KEY)!;
  const uaParser = new UAParser();

  const device = uaParser.getDevice();
  const os = uaParser.getOS();

  setBrowserToStore(uaParser);

  store.config = {
    systemId: ANALYTICS_APP_ID,
    os: os.name || 'unknown',
    osVersion: os.version || 'unknown',
    device: (device.vendor || 'unknown') + (device.model || 'unknown'),
    appVersion: APP_VERSION,
    platform: 'web',
    deviceId,
    referenceId,
  };
}

function joinParams(params: Record<string, string | number> | void) {
  if (params) {
    return Object.entries(params)
      .map((param) => param.join('='))
      .join(',');
  }

  return '';
}

/**
 * Сохранение логина пользователя в конфиге
 * */
export function setAuthInfo(
  authType: Config['authType'],
  login: Config['authToken']
) {
  if (!store.config) {
    throw new Error(LOGGER_TITLE + ': store not configured');
  }

  store.config.authType = authType;
  store.config.authToken = login;
}

/**
 * Метод инициализации отправки метрик
 * */
export function initPerfomanceAnalytics(interval: number) {
  sendDataThrottled = throttle(sendData, interval);

  setConfig();
}

/**
 * Функция для регистрации метрик виджетов
 * При первом вызове регистрируется время,
 * которое будет принято за первый рендер
 * */
export function logWidget<T extends Widget>(widgetName: T, activeTab?: number) {
  const event: EventData = {
    startMoment: getNowDate(),
    endMoment: '',
    operType: `Workspace.${activeTab}.Widget.${widgetName}.Open`,
  };
  let isReady = false;

  log.debug('WidgetPerfomance firstRender', event);

  return {
    /**
     * Флаг, который обозначает была ли отправлена метрика
     * */
    isReady: () => isReady,
    /**
     * Метод регистрации начала инициализации компонента
     * */
    startInit: () => {
      if (activeTab === undefined) {
        return;
      }

      isReady = false;
      event.startMoment = getNowDate();

      log.debug('WidgetPerfomance startInit', event);
    },
    /**
     * Метод регистрации окончания инициализации компонента
     * */
    ready: (params?: PerformanceAnalyticsWidgetParams[T], endMoment?: Date) => {
      if (isReady) {
        return;
      }

      isReady = true;

      event.endMoment = getNowDate(endMoment);
      event.param = joinParams(params);

      pushEvent(event);
      log.debug('WidgetPerfomance ready', event, params);
    },
  };
}

export const useTriggerOnRender = (
  enabled: boolean,
  callback: (...args: any[]) => void
) => {
  useEffect(() => {
    if (!enabled) {
      return;
    }

    requestAnimationFrame(callback);
  }, [enabled, callback]);
};

// Хук ждет определенное событие, но если оно не происходит - отправляет сохраненное время первого рендера
export const useTriggerOnConditionOrFirstRender = (
  ready: (params?: any) => void,
  condition: boolean,
  timeout?: number
) => {
  const logTimeoutRef = useRef<NodeJS.Timeout>();

  const onRenderFunc = useCallback(() => {
    const date = new Date();

    logTimeoutRef.current = setTimeout(() => ready(date), timeout || 10000);
  }, [ready, timeout]);

  useTriggerOnRender(true, onRenderFunc);

  useEffect(() => {
    if (condition) {
      clearTimeout(logTimeoutRef.current);
      requestAnimationFrame(() => ready());
    }
  }, [condition, ready]);
};

export function logWidgetLayoutChange<T extends Widget>(
  widgetName: T,
  activeTab?: number
) {
  const events: Record<string, EventData> = {};

  return {
    start: (action: string) => {
      if (activeTab === undefined) {
        return;
      }

      events[action] = {
        startMoment: getNowDate(),
        endMoment: '',
        operType: `Workspace.${activeTab}.Widget.${widgetName}.Action.${action}`,
      };
    },
    finish: (
      action: string,
      params?: PerformanceAnalyticsWidgetParams[T],
      endMoment?: Date
    ) => {
      if (activeTab === undefined) {
        return;
      }

      const event = events[action];

      if (event) {
        event.endMoment = getNowDate(endMoment);

        event.param = joinParams(params);

        pushEvent(event);
        delete events[action];
      }
    },
  };
}

type SendProblemFramesFunc = (frames: number[]) => void;

// Собираем все кадры длиннее 32мс (если между их RAF >32мс), и отправляем массивом раз в 10с
export const logProblemFrames = () => {
  const sendProblemFrames = (): SendProblemFramesFunc => {
    const event: EventData = {
      startMoment: getNowDate(),
      endMoment: '',
      operType: '',
    };

    return (frames: number[]) => {
      event.endMoment = getNowDate();

      const min = Math.trunc(Math.min(...frames));
      const max = Math.trunc(Math.max(...frames));

      event.operType = 'Render.Delay';
      event.param = `count=${frames.length},min=${min}${
        min !== max ? `,max=${max}` : ''
      }`;

      pushEvent(event);
    };
  };

  let isStopped: boolean = false;
  let prevFrameTick: number;
  let longFramesLengths: number[] = [];
  let sendFunc: SendProblemFramesFunc;

  const pushFrames = () => {
    sendFunc?.(longFramesLengths);
    sendFunc = sendProblemFrames();
    longFramesLengths = [];
  };

  const throttledPushEvent = throttle(pushFrames, 10001);

  const measure = () => {
    const now = performance.now();

    if (prevFrameTick && now - prevFrameTick > 32) {
      longFramesLengths.push(now - prevFrameTick);
      throttledPushEvent();
    }

    prevFrameTick = now;

    if (!isStopped) {
      requestAnimationFrame(measure);
    }
  };

  return {
    start: measure,
    stop: () => {
      isStopped = true;
    },
  };
};

export const logWorkspaceLoad = (activeTab: number) => {
  const event: EventData = {
    startMoment: getNowDate(),
    endMoment: '',
    operType: `Workspace.${activeTab}.Load`,
  };
  let isReady = false;

  return {
    isReady: () => isReady,
    ready: (widgetsCount: number) => {
      isReady = true;
      event.endMoment = getNowDate();
      event.param = `widgetsCount=${widgetsCount}`;
      pushEvent(event);
    },
  };
};

export const logWebsoketReconnect = (frontEndType: FrontEndType) => {
  const event: EventData = {
    startMoment: getNowDate(),
    endMoment: '',
    operType: `Websocket.${frontEndType}.Connect`,
  };

  return {
    ready: () => {
      event.endMoment = getNowDate();
      pushEvent(event);
    },
  };
};

export const logAuthorizationPerformance = () => {
  const LOCAL_STORAGE_LOGIN_CLICKED_KEY = 'authStartedDate';
  const LOCAL_STORAGE_AUTH_START_KEY = 'authStartedDate';

  const enrichEventWithDatesAndPush = (event: EventData, lsKey: string) => {
    event.startMoment = localStorage.getItem(lsKey) || '';
    event.endMoment = getNowDate();
    pushEvent(event);

    localStorage.removeItem(lsKey);
  };

  return {
    loginClicked: () => {
      localStorage.setItem(LOCAL_STORAGE_LOGIN_CLICKED_KEY, getNowDate());
    },
    authStarted: () => {
      const uaParser = new UAParser();

      setBrowserToStore(uaParser);

      const startAuthEvent: EventData = {
        startMoment: '',
        endMoment: '',
        operType: `Authorization from Login click to start auth`,
      };

      enrichEventWithDatesAndPush(
        startAuthEvent,
        LOCAL_STORAGE_LOGIN_CLICKED_KEY
      );

      /**
       * TODO Требуется обновить способ подсчета т.к. между страницами есть этапы ввода кода и настройка терминала.
       * При анализе метрик необходимо это учитывать
       * */

      localStorage.setItem(LOCAL_STORAGE_AUTH_START_KEY, getNowDate());
    },
    workspaceLoaded: () => {
      const finishAuthEvent: EventData = {
        startMoment: '',
        endMoment: '',
        operType: `Authorization from start auth to loaded Workspace`,
      };

      enrichEventWithDatesAndPush(
        finishAuthEvent,
        LOCAL_STORAGE_AUTH_START_KEY
      );
    },
  };
};
