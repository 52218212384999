import { z } from 'zod';

import { FeatureFlags } from '@terminal/core/lib/featureFlags';

import { DEFAULT_BOND_SCREENER_DICTIONARIES } from './constants';
import { scheme } from './scheme';

import { FEATURE_FLAG_CODE, TECH_MESSAGE_CODE } from '../model/types';

type FeatureFlagsType = z.infer<typeof scheme>;

const defaultFeatureFlags: FeatureFlagsType = {
  [TECH_MESSAGE_CODE.MAIN]: '',
  [TECH_MESSAGE_CODE.TRADE]: '',
  [TECH_MESSAGE_CODE.POSITIONS_BALANCES]: '',
  [TECH_MESSAGE_CODE.MONEY_TRANSFERS]: '',
  [TECH_MESSAGE_CODE.LK_ELECTRONIC_SIGNATURE]: '',
  [TECH_MESSAGE_CODE.AUTHORIZATION]: '',
  [TECH_MESSAGE_CODE.OPEN_ACCOUNT]: '',
  [TECH_MESSAGE_CODE.SUPPORT]: '',
  [FEATURE_FLAG_CODE.BOND_SCREENER_DICTIONARIES]:
    DEFAULT_BOND_SCREENER_DICTIONARIES,
  [FEATURE_FLAG_CODE.CHART_SCROLL_VELOCITY]: 500,
  [FEATURE_FLAG_CODE.DRAGGABLE_LAYOUT_ENABLED]: true,
  [FEATURE_FLAG_CODE.DRAGGABLE_LAYOUT_MAX_AVAILABLE_WIDGETS]: 30,
};

export const featureFlags = new FeatureFlags(scheme, defaultFeatureFlags, {
  setterName: '__setGoFeatureFlags',
});
