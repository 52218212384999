import { Actions, Model } from 'flexlayout-react';

export const setWidgetTabHeight = (height: number, model?: Model) => {
  if (!model) {
    return;
  } else {
    model.doAction(
      Actions.updateModelAttributes({
        tabSetTabStripHeight: height,
      })
    );
  }
};
