import { useQuery } from 'react-query';

import useNotification from '@terminal/core/hooks/useNotification';
import { getProfileAgreement } from '@terminal/core/lib/rest/lkRiskProfile';
import { NotificationType } from '@terminal/core/types/ui';

type Options = {
  isDocumentOpen: boolean;
  isUnsigned: boolean;
};

export const useGetProfileAgreement = (options: Options) => {
  const { isUnsigned, isDocumentOpen } = options;
  const addNotification = useNotification();

  return useQuery(
    ['lk', 'riskProfile', 'agreement', isUnsigned],
    async () => getProfileAgreement(isUnsigned),
    {
      onError: (error: any) => {
        addNotification({
          type: NotificationType.SYSTEM,
          badge: 'negative',
          title: 'Ошибка загрузки справки',
          text: error?.message || '',
        });
      },
      enabled: isDocumentOpen,
    }
  );
};
